import Box from '@mui/material/Box';
import { useState, useContext, useEffect } from "react";
import _ from 'lodash';
import { useHistory } from "react-router-dom";
import { useLazyQuery } from "@apollo/client";
import AddTable from "../../screens/Colaboradores/Colaboradores/CollaboratorTab/AddTable";
import SearcherTable from "../../screens/Colaboradores/Colaboradores/CollaboratorTab/SearcherTable";
import MoneyField from "../TableBits/MoneyField";
import TableBits from "../TableBits/TableBits";
import UserField from "./UserField";
import FormatDateIngreso from "./FormatDateIngreso";
import columns from "./CandidatesColumns";
import ConvertModal from "./Modals/ConvertModal";
import DeclineModal from "./Modals/DeclineModal";
import MenuList from "./Modals/MenuList";
import CollaboratorContext from "../../context/CollaboratorContext/CollaboratorContext";
import { GET_ALL_USERS_NUEVOINGRESO } from "../../Querys/querys";
import CheckPermission from "../../helpers/Administradores/Permissions";

const CandidateTable = () => {
  const [candidatesList, setCandidatesList] = useState([]);
  const [candidatesListFilter, setCandidatesListFilter] = useState<any[]>([]);
  const { state } = useContext(CollaboratorContext);
  const history = useHistory();
  const [pagination, setPagination] = useState({
    "pageNumber": 0,
    "rowsOfPage": 10,
    "order": 'asc',
    "orderBy": 'bussinesName',
    "searchTerm": '',
  });

  const [paginationObj, setPaginationObj] = useState({
    rowsOfPage: 10,
    pageNumber: 0,
    searchTerm: "",
    order: "desc",
    orderBy: "FechaIngreso"
  });

  const [getUsers, { data: candidates }] = useLazyQuery(GET_ALL_USERS_NUEVOINGRESO, {
    fetchPolicy: 'no-cache'
  });

  const handleComponent = (e: any) => {
    history.push("/nuevoingreso/new/1");
  };

  useEffect(() => {
    if (!state.declineModal || !state.convertModal) {
      getData(pagination);
    }
  }, [state.declineModal, state.convertModal]);

  useEffect(() => {
    if (candidates && candidates.GET_ALL_USERS_NUEVOINGRESO) {
      setCandidatesList(candidates.GET_ALL_USERS_NUEVOINGRESO);
      setCandidatesListFilter(candidates.GET_ALL_USERS_NUEVOINGRESO);
    }
  }, [candidates]);

  function updatePaginationObj(paginationObj: any): any {
    let paginationCopy = {
      ...pagination,
      ...paginationObj,
    };
    setPagination(paginationCopy);
    return paginationCopy;
  }

  async function getData(paginationObj: any): Promise<any> {
    let paginationCopy = {
      ...pagination,
      ...paginationObj,
    };
    const { data } = await getUsers({
      variables: {
        "pagination": {
          ...paginationCopy
        },
        fetchPolicy: 'no-cache'
      }
    });
    const allCollaborator = data?.GET_ALL_USERS_NUEVOINGRESO;
    setCandidatesListFilter(allCollaborator);
    setCandidatesList(allCollaborator);
    return allCollaborator;
  };


  return (
    <Box display="flex" flexDirection="column" p={2}>
      <Box p={1} pb={3} display="flex" flexDirection="row">
        <Box display="flex" justifyContent="flex-start">
          <SearcherTable
            label="Buscar por nombre / sede / proyecto / puesto  "
            initState={candidatesList}
            setState={setCandidatesListFilter}
            fields={["bussinesName", "campus", "project", "client", "Puesto"]}
            // updatePaginationObj={updatePaginationObj}
            // getData={getData}
            // paginationObj={pagination}
            customSearch={(searchTerm: string) => {
              getUsers({
                variables: {
                  rowsPerPage: paginationObj.rowsOfPage,
                  pageNumber: 0,
                  searchTerm,
                  order: paginationObj.order,
                  orderBy: paginationObj.orderBy
                }
              })
              setPaginationObj({ ...paginationObj, pageNumber: 0, searchTerm });
            }}
          />
        </Box>

        <Box
          display="flex"
          flexDirection="row"
          justifyContent="flex-end"
          style={{ width: "100%" }}
        >
          {CheckPermission("Colaboradores.NuevoIngreso.Agregar") && (
            <AddTable func={handleComponent} text="Agregar nuevo ingreso" />
          )}
        </Box>
      </Box>
      <TableBits
        columns={columns}
        rows={candidatesListFilter}
        components={[
          UserField,
          null,
          null,
          null,
          FormatDateIngreso,
          null,
          MoneyField,
        ]}
        componentOptions={MenuList}
        totalRecords={candidatesListFilter?.length > 0 ? candidatesListFilter[0]?.totalRows : 0}
        getData={async (paginationObj_) => {
          setPaginationObj((val) => ({
            ...val,
            rowsPerPage: paginationObj_.rowsOfPage,
            pageNumber: paginationObj_.pageNumber,
          }))

          getUsers({
            variables: {
              rowsPerPage: paginationObj_.rowsOfPage,
              pageNumber: paginationObj_.pageNumber,
              searchTerm: paginationObj.searchTerm,
              order: paginationObj.order,
              orderBy: paginationObj.orderBy
            }
          })
        }}
        updatePaginationObj={(paginationObj_) => {
          setPaginationObj((val) => ({
            ...val,
            order: paginationObj_.order,
            orderBy: paginationObj_.orderBy
          }));
          getUsers({
            variables: {
              rowsPerPage: paginationObj.rowsOfPage,
              pageNumber: paginationObj.pageNumber,
              searchTerm: paginationObj.searchTerm,
              order: paginationObj_.order,
              orderBy: paginationObj_.orderBy
            }
          })
        }}
        customInitialOrderBy="FechaIngreso"
        customInitialOrder="desc"
        customRowsPerPage={10}
        updatePageNumber={paginationObj.pageNumber}
      />

      <DeclineModal />
      <ConvertModal />
    </Box>
  );
};

export default CandidateTable;
