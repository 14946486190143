import { useState, useContext } from "react";
import { Dialog } from "@material-ui/core";
import "../PayrollGroup.css";
import DeduccionContext from "../../../context/ConfigPayrollContext/DeduccionContext";
import { closeDeduccionModal } from "../../Team/Modals/ModalDeduccionModal";
import styles from "../../Payroll/Modals/PayrollModals.module.css";

import { useMutation } from "@apollo/client";
import { DELETE_DEDUCTIONS, GET_ALL_DEDUCTIONS, GET_DEDUCTIONS_CALENDAR } from "../../../Querys/querys";

const EliminaDeduccionModales = (props: any) => {
  const { state, dispatch } = useContext(DeduccionContext);
  const [activo, setactivo] = useState(false);
  const handleChange = (e: any) => {
    if (e.target.value === "ELIMINAR") {
      setactivo(true);
    } else {
      setactivo(false);
    }
  };

  const [deleteDeduction] = useMutation(DELETE_DEDUCTIONS, {
    refetchQueries: [
                      { query: GET_ALL_DEDUCTIONS },
                      {query: GET_DEDUCTIONS_CALENDAR},
                    ],
  });

  const handleDelete = async () => {
    try {
      await deleteDeduction({
        variables: {
          deleteDeductionId: state._id,
        },
      });

      await closeDeduccionModal(dispatch);
      setactivo(false);
      await props.getDatos();
    } catch {}
  };

  const handleClose = async () => {
    state.showEliminar = false;
    await closeDeduccionModal(dispatch);
  };
  return (
    <Dialog
      aria-labelledby="costumized-dialog-title"
      open={state.showEliminar}
      fullWidth={false}
      onClose={handleClose}
      maxWidth={"md"}
    >
      <div className={styles.em_container}>
        <div className={styles.contenedorCerrarModal}>
          <div
            className={styles.cerrarModal}
            onClick={() => handleClose()}
          ></div>
        </div>
        <div className={styles.contenedorPrincipal}>
          <div className={styles.iconoEliminar}></div>
          <div className={styles.em_titulo}>
            ¿Est&aacute;s seguro de que quieres eliminar la deducci&oacute;n?
          </div>
          <div className={styles.em_mensajePrtincipal}>
            <span>
              Una vez eliminado no podr&aacute;s recuperar la informaci&oacute;n
            </span>
            <br />
            <span className={styles.em_textoPrincipal}>
              Escribe ELIMINAR para confirmar
            </span>
          </div>
          <div>
            <input
              className={styles.input}
              type="text"
              onChange={(e) => handleChange(e)}
            />
          </div>
        </div>
      </div>
      <div className={styles.em_contenedorBotones}>
        <button
          className={styles.em_botonCancelar}
          onClick={() => handleClose()}
        >
          Cancelar
        </button>
        {activo === true ? (
          <button
            className={styles.em_botonConfirmarActivo}
            onClick={() => handleDelete()}
          >
            <div className={styles.em_iconoConfirmarEliminar}></div>
            <div>Confirmar</div>
          </button>
        ) : (
          <button className={styles.em_botonConfirmarInactivo}>
            <div className={styles.em_iconoConfirmarEliminar}></div>
            <div>Confirmar</div>
          </button>
        )}
      </div>
    </Dialog>
  );
};

export default EliminaDeduccionModales;
