import { useContext, useState } from "react";
import {
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
} from "@material-ui/core";
import Box from '@mui/material/Box';
import MoreVertIcon from "@material-ui/icons/MoreVert";
import DeduccionContext from "../../../context/ConfigPayrollContext/DeduccionContext";
import { openDeduccionModal } from "../../Team/Modals/ModalDeduccionModal";
import CheckPermission from "../../../helpers/Administradores/Permissions";

const MenuListDeduccion = (props: any) => {
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const open = Boolean(anchorEl);
  const { dispatch } = useContext(DeduccionContext);

  const OpenInfoDeduction = () => {
    openDeduccionModal(
      {
        _id: props._id,
        ConceptName: props.ConceptName,
        SATKey: props.SATKey,
        showInformacion: true,
        ISRTax: props.ISRTax,
        time: props.time,
        isMoney: props.isMoney,
        muestraCalendario: props.muestraCalendario
      },
      dispatch
    );
    setAnchorEl(null);
  };
  const abreEditaDeduccion = () => {
    openDeduccionModal(
      {
        _id: props._id,
        SATKey: props.SATKey,
        ConceptName: props.ConceptName,
        showEdit: true,
        ISRTax: props.ISRTax,
        TaxBoth: props.TaxBoth,
        time: props.time,
        isMoney: props.isMoney,
        muestraCalendario: props.muestraCalendario
      },
      dispatch
    );
    setAnchorEl(null);
  };
  const abreEliminaDeduccion = () => {
    openDeduccionModal(
      { _id: props._id, showEliminar: true, PolicyName: props.ConceptName },
      dispatch
    );
    setAnchorEl(null);
  };

  return (
    <>
      <Tooltip title="Editar" placement="right">
        <Box className="IconButtonPoints" mr={2}>
          <IconButton onClick={(e) => setAnchorEl(e.currentTarget)}>
            <MoreVertIcon style={{ color: "#fabb00" }} />
          </IconButton>
        </Box>
      </Tooltip>
      <Menu
        className="MoreVerIcon"
        anchorEl={anchorEl}
        open={open}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        onClose={() => setAnchorEl(null)}
      >
        {CheckPermission(
          "ConfiguracionNominas.Conceptos.Deducciones.Editar"
        ) && (
          <>
            <MenuItem divider button onClick={OpenInfoDeduction}>
              Información
              <Grid container item justify="flex-end">
                <img src={`/assets/svg/icono-ver.svg`} alt="Información" />
              </Grid>
            </MenuItem>

            <MenuItem divider button onClick={abreEditaDeduccion}>
              Editar&nbsp;
              <Grid container item justify="flex-end">
                <img src={`/assets/svg/icono-editar.svg`} alt="Reenviar" />
              </Grid>
            </MenuItem>
          </>
        )}
        {CheckPermission(
          "ConfiguracionNominas.Conceptos.Deducciones.Eliminar"
        ) && (
          <>
            {props.SATKey === "004" && (
              <MenuItem divider button onClick={abreEliminaDeduccion}>
                Eliminar&nbsp;
                <Grid container item justify="flex-end">
                  <img src={`/assets/svg/icono-eliminar.svg`} alt="Reenviar" />
                </Grid>
              </MenuItem>
            )}
          </>
        )}
      </Menu>
    </>
  );
};

export default MenuListDeduccion;
