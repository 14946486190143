import { Dispatch } from "react";
import { Types } from "./CentralPagosContext";

export const openDispersarCentralPagos = ({openDispersar = true}:any, dispatch: Dispatch<any>): void=>{
  console.log('Si esta entrando')
  dispatch({
    type: Types.OPEN_DISPERSAR,
    payload:{
      openDispersar
    }
  })
}

export const clearDispersar = ({}:any, dispatch: Dispatch<any>):void=>{
  console.log('Si esta entrando')
  dispatch({
    type: Types.CLEAR_DISPERSAR,
    payload:{
      openDispersar: false
    }
  })
}


export const openSinRegistros = ({ sinRegistros = true}:any, dispatch: Dispatch<any>):void=>{
  console.log('Si esta entrando')
  dispatch({
    type: Types.SIN_REGISTROS,
    payload:{
      sinRegistros: true
    }
  })
}


export const openEliminaRegistro = ({ openEliminarRegistro = true, _id=0}:any, dispatch: Dispatch<any>):void=>{
  console.log('Si esta entrando')
  dispatch({
    type: Types.ELIMINAR_REGISTRO,
    payload:{
      openEliminarRegistro: true,
      _id
    }
  })
}

export const abreSinFondos = ({ openSinFondos = true}:any, dispatch: Dispatch<any>):void=>{
  console.log('Si esta entrando')
  dispatch({
    type: Types.OPEN_SIN_FONDOS,
    payload:{
      openSinFondos: true,
    }
  })
}
export const openErrorMensaje = ({ openMensajeError = true, _id=0}:any, dispatch: Dispatch<any>):void=>{
  console.log('Si esta entrando')
  dispatch({
    type: Types.OPEN_MENSAJE_ERROR,
    payload:{
      openMensajeError: true,
      _id
    }
  })
}