import {createContext, Dispatch} from 'react';

type initialStateType = {
    _id:string,
    createModalBenefits:boolean,
    updateModalBenefits:boolean,    
    deleteModalBenefits:boolean,
    createModalUserBenefits:boolean,
    createUserBenefitsModal:boolean
}

type ModalPartial = {
    state:initialStateType,
    dispatch: Dispatch<any>
}

export const initialState = {
    _id: '',
    createModalBenefits: false,
    updateModalBenefits:false,    
    deleteModalBenefits: false,
    createModalUserBenefits:false,
    createUserBenefitsModal:false
}

export enum Types {
    CREATE_BENEFITS = "CREATE_BENEFITS",
    UPDATE_BENEFITS = "UPDATE_BENEFITS",
    DELETE_BENEFITS = "DELETE_BENEFITS",
    CREATE_USER_BENEFITS = "CREATE_USER_BENEFITS",
    CLOSE_USER_BENEFITS_MODAL = "CLOSE_USER_BENEFITS_MODAL",
    CLEAR_BENEFITS = "CLEAR_BENEFITS",

}

const ModalInitialState = {
    state:initialState,
    dispatch:() => null
}

const PrestacionContext = createContext<ModalPartial>(ModalInitialState)

export default PrestacionContext; 