import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import Switch from "../Switch";
import { useState } from 'react';
import styles from "../Administrators.module.css";

type Permission = {
  permissions:any, 
  title:string, 
  permissionName:string
}

function PermissionAccordion ({permissions, title, permissionName}:Permission){

  const [showAccordion, setShowAccordion] = useState(true);

  const handleConfirmPermission = (value: string) => {
    if (permissions) {
      const findPermisos = permissions.find((permiso: any) => {
        return permiso.name === value;
      });
      return findPermisos?.status;
    }
  };

  return (
    <Accordion
      className={
        showAccordion === true
          ? `${styles.acordionBase} `
          : `${styles.acordionBase}`
      }
    >
      <div className={styles.contenedorTitulosAccordion}>
        <div>
          <AccordionSummary
            onClick={() =>
              setShowAccordion(!showAccordion)
            }
          >
            <div className={`${styles.contenedorIconoExpande}`}>
              {showAccordion === true ? (
                <img
                  className={`${styles.iconoExpande}`}
                  src={`/assets/svg/icono-expande-arriba.svg`}
                  alt="imagen"
                />
              ) : (
                <img
                  className={`${styles.iconoExpande} ${styles.iconoAbajo}`}
                  src={`/assets/svg/icono-expande.svg`}
                  alt="imagen"
                />
              )}
            </div>
          </AccordionSummary>
        </div>
        <div className={styles.titulosAccordion}>
          <span className={styles.tituloAccordion}>
            {title}
          </span>
        </div>
        <div className={styles.contenedorSwitch}>
          <Switch
            NombreCampo={`Sidebar.${permissionName}`}
            Value={() =>
              handleConfirmPermission(
                `Sidebar.${permissionName}`
              )
            }
          />
        </div>
      </div>
    </Accordion>
  )
}

export default PermissionAccordion;