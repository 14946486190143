import styles from './Contracts.module.css'
import {TextField,Select, InputLabel,FormControl,MenuItem } from "@material-ui/core"
import {GET_USERS_COLLABORATOR_CONTRACT} from "../../Querys/querys";
import { useQuery } from "@apollo/client";
import { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import { formatterMxn } from "../../helpers/formatoMoneda";
import {Undetermined}   from './Template/ContractTemplates';
import { server } from "../../global/server";
import {NumberToLetterInt} from "../../helpers/NumberToLetter"
import {MonthName} from "../../helpers/MonthName"
import { WarningAlert } from '../../alerts/WarningAlert';
import axios from 'axios';

interface TabPanelProps{
    children?: React.ReactNode;
    index: any;
    value: any;
}

export const  Contract = (props:TabPanelProps) => {

    const [users, setUsers] = useState<any>([])
    const [type, setType] = useState<any>('')
    const [userSelected, setUserSelected] = useState<any>('')
    const [userSelectedName, setUserSelectedName] = useState<any>('')
    const [userData, setUserData] = useState<any>([])
    const [filtrado , setFiltrado] = useState<any>([])
    const [show, setShow] = useState<boolean>(false)
    const [dateOfAdmission, setDateOfAdmission] = useState<any>()
    const [dateContractDate, setDateContractDate] = useState<any>()
    const {data: resultUsers, startPolling, stopPolling} = useQuery(GET_USERS_COLLABORATOR_CONTRACT,{
        variables: { type: type? type : ''},
    
      });

    const resul = resultUsers?.GET_USERS_COLLABORATOR_CONTRACT
    const getUserSelectedData = useCallback(() => {
        if(userSelected !== '' && users){
            const data = users.filter((user:any)=> String(user.id) === userSelected)
            setUserData(data)
        }

    }, [userSelected, users])


    const filtrarCollaborator = (colaborador: string) => {

        let datosfiltrados = users.filter((lis: any) =>
            lis?.bussinesName
            .toUpperCase()
            .includes(colaborador.toString().toUpperCase())
        );
  
        if (colaborador === '') {
            datosfiltrados = users;
        }
        
        setFiltrado(datosfiltrados);
        setUserSelectedName(colaborador);
    };  
    
    useEffect(() => {
        if(userSelected !== ''){
            getUserSelectedData()
        }
    }, [userSelected, getUserSelectedData])

    useEffect(() => {
        if(resul){
            setUsers(resul)
            setFiltrado(resul)
        }
    }, [resul])

    useEffect(()=>{
        startPolling(10000);
        return () =>{
            stopPolling()
        }
    },[startPolling,stopPolling])

    let dateOfAdmissionDay  = ''
    let dateOfAdmissionMonth = ''
    let dateOfAdmissionYear = ''
    let dateOfAdmissionText = ''

    if(dateOfAdmission){
        dateOfAdmissionDay = NumberToLetterInt(moment(dateOfAdmission).format('DD'))
        
        if(dateOfAdmissionDay === 'UN'){
            dateOfAdmissionDay = 'PRIMERO'
        }
        
        dateOfAdmissionMonth = MonthName(moment(dateOfAdmission).format('MM'))
        dateOfAdmissionYear = NumberToLetterInt(moment(dateOfAdmission).format('YYYY'))
    
        dateOfAdmissionText = dateOfAdmissionDay +' DE '+ dateOfAdmissionMonth + ' DE ' + dateOfAdmissionYear
    }
    let monthRemaining = '0'
    let dayRemaining = '0'

    if(type !== 'Indeterminado' && dateContractDate && dateOfAdmission){
        monthRemaining = String(moment(dateContractDate).diff(moment(dateOfAdmission), 'months'))
        dayRemaining = String(moment(dateContractDate).diff(moment(dateOfAdmission), 'days'))
    }

    const collaboratorChoose = (user: any) => {

        if(
            (user?.notarialTestimonyDate === null || user?.notarialTestimonyDate === '' || user?.notarialTestimonyDate === undefined)
            ||
            (user?.dateOfBirth === null || user?.dateOfBirth === '' || user?.dateOfBirth === undefined)
            ||
            (user?.dateOfAdmission === null || user?.dateOfAdmission === '' || user?.dateOfAdmission === undefined)
            ||
            (user?.municipioEmpresa === null || user?.municipioEmpresa === '' || user?.municipioEmpresa === undefined)
            ||
            (user?.nameEnterprise === null || user?.nameEnterprise === '' || user?.nameEnterprise === undefined)
            ||
            (user?.legalRepresentative === null || user?.legalRepresentative === '' || user?.legalRepresentative === undefined)
            ||
            (user?.bussinesName === null || user?.bussinesName === '' || user?.bussinesName === undefined)
            ||
            (user?.notarialTestimony === null || user?.notarialTestimony === '' || user?.notarialTestimony === undefined)
            ||
            (user?.notarialTestimonyVolume === null || user?.notarialTestimonyVolume === '' || user?.notarialTestimonyVolume === undefined)
            ||
            (user?.notaryPublicNumber === null || user?.notaryPublicNumber === '' || user?.notaryPublicNumber === undefined)
            ||
            (user?.notaryPublic === null || user?.notaryPublic === '' || user?.notaryPublic === undefined)
            ||
            (user?.judicialDistrict === null || user?.judicialDistrict === '' || user?.judicialDistrict === undefined)
            ||
            (user?.taxRegime === null || user?.taxRegime === '' || user?.taxRegime === undefined)
            ||
            (user?.addressEnterprise === null || user?.addressEnterprise === '' || user?.addressEnterprise === undefined)
            ||
            (user?.Puesto === null || user?.Puesto === '' || user?.Puesto === undefined)
            ||
            (user?.nacionality === null || user?.nacionality === '' || user?.nacionality === undefined)
            ||
            (user?.gender === null || user?.gender === '' || user?.gender === undefined)
            ||
            (user?.civilStatus === null || user?.civilStatus === '' || user?.civilStatus === undefined)
            ||
            (user?.Age === null || user?.Age === '' || user?.Age === undefined)
            ||
            (user?.CURP === null || user?.CURP === '' || user?.CURP === undefined)
            ||
            (user?.RFC === null || user?.RFC === '' || user?.RFC === undefined)
            ||
            (user?.IMSS === null || user?.IMSS === '' || user?.IMSS === undefined)
            ||
            (user?.SDG === null || user?.SDG === '' || user?.SDG === undefined)
            ||
            (user?.paymentPeridicity === null || user?.paymentPeridicity === '' || user?.paymentPeridicity === undefined)
            ||
            (user?.addressCourt === null || user?.addressCourt === '' || user?.addressCourt === undefined)
            ||
            (user?.municipality === null || user?.municipality === '' || user?.municipality === undefined)
            ||
            (user?.estadoEmpresa === null || user?.estadoEmpresa === '' || user?.estadoEmpresa === undefined)

        ){
            
            WarningAlert({text:"Datos incompleto para el contrato"});
        }
        if( type === 'Determinado'){
            if(
                (user?.dateContractDate === null || user?.dateContractDate === '' || user?.dateContractDate === undefined)
            ){
                WarningAlert({text:"Datos incompleto de fecha fin para el contrato"});
            }
        }
        setShow(false)
        setUserSelectedName(user?.bussinesName)
        setUserSelected(user?.id)
        setDateOfAdmission(moment(user?.dateOfAdmission).format('YYYY-MM-DD'))
        setDateContractDate(moment(user?.dateContractDate).format('YYYY-MM-DD'))
    }

    const clearDate = (changeType: any)=>{
        if(changeType === 'Indeterminado'){
            setDateContractDate('')
        }
    }

    const exportarPDF = async (id: any, dateOfAdmissionText: string, dayRemaining: any, monthRemaining: any, type: string) => {

        const PDF: any = await axios.get(`${server}/report/ContractGenerato/${id}/${dateOfAdmissionText}/${dayRemaining}/${monthRemaining}/${type}`)
        const link = document.createElement("a");
        link.href = PDF?.data?.urlPDF;
        link.setAttribute("download", "downloadtesting.PDF");
        document.body.appendChild(link);
        link.click();
        link.remove();
    }

    return (
        <div className={styles.container}>
            <div className={styles.divContract}>
                <div className={styles.divGenerateContract}>   
                    <div className={styles.titleContract}>
                        Generación de contratos
                    </div>
                    <div className={styles.contenedorFieldset}>
                        <FormControl 
                            fullWidth 
                            size="small"
                            variant="outlined" 
                        >
                            <InputLabel id="demo-simple-select-label"
                            style={{
                                backgroundColor: "#FFFFFF"
                            }}
                            >Tipo</InputLabel>
                            <Select
                            style={{
                            textOverflow: "ellipsis" ,
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            width: "368px",
                            }}
                            labelId="demo-simple-select-label"
                            id="regimenFiscal"
                            name = "regimenFiscal"
                            value={type}
                            onChange={
                                (e)=>{
                                    setType(e.target.value)
                                    clearDate(e.target.value)
                                }
                            }
                            onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                            >
                            <MenuItem value='Determinado'>Determinado</MenuItem>
                            <MenuItem value='Indeterminado'>Indeterminado</MenuItem>
                          </Select>
                        </FormControl>
                    </div>
                    <div className={styles.contenedorFieldset}>
                        <TextField
                          fullWidth
                          name="Colaborador"
                          label="Colaborador"
                          variant="outlined"
                          size="small"
                          inputProps={{ maxLength: 30 }}
                          value={userSelectedName}
                          onKeyPress={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                          onChange={(e) => {
                            filtrarCollaborator(e.target.value)
                          }}
                          onClick={() => {
                            setShow(!show)
                          }}
                          style={{
                            width: "368px",
                        }}
                        
                        />
                        {
                            show && filtrado?.length > 0 && (
                                <div
                                className={styles.divCollaborator}
                                >
                                    {
                                        filtrado?.map((user:any)=>(
                                            <p
                                            onClick={()=>{
                                                collaboratorChoose(user)
                                            }}
                                            >{user?.bussinesName}</p>
                                        ))
                                    }
                                </div>
                            )
                        }
                        
                    </div>
                    <div className={styles.contenedorDatos}>
                        <div className={styles.titleData}>
                            <p>Datos del colaborador</p>
                        </div>
                        <div className={styles.divText}> 
                            <div
                            style={{
                                width: "50%",
                            }}
                            >
                               <p
                                style={{
                                    marginRight: "auto"
                                }}
                                >Fecha inicial</p>
                            </div>
                            <div
                            style={{
                                width: "50%",
                            }}
                            >
                                <input
                                    name="dateOfAdmission"
                                    value={dateOfAdmission}
                                    type="date"
                                    className={styles.inputFecha}
                                    onChange={({ target }) => {
                                        setDateOfAdmission(target.value);
                                    }}
                                />
                            </div>
                            
                        </div>
                        <div className={styles.divText}> 

                            <div
                            style={{
                                width: "50%",
                            }}
                            >
                               <p
                                style={{
                                    marginRight: "auto"
                                }}
                                >Fecha final</p>
                            </div>
                            <div
                            style={{
                                width: "50%",
                            }}
                            >
                                <input
                                    name="dateContractDate"
                                    value={dateContractDate}
                                    type="date"
                                    className={styles.inputFecha}
                                    onChange={({ target }) => {
                                        setDateContractDate(target.value);
                                    }}
                                    disabled={type === 'Indeterminado'? true: false}
                                />
                            </div>
                            
                        </div>
                        {userData[0]?.nominesPayroll ==='Mixto'?
                            <div className={styles.divText}> 
                                <div
                                style={{
                                    width: "50%",
                                }}
                                >
                                    <p
                                    style={{
                                        marginRight: "auto"
                                    }}
                                    >Salario diario gravable
                                    </p>
                                </div>
                                <div
                                style={{
                                    width: "50%",
                                }}
                                >
                                    <p
                                    className={styles.textUsesData}
                                    >
                                        {
                                            userData[0]?.SDG? formatterMxn(userData[0]?.SDG) : '-'
                                        }
                                    </p>
                                </div>                            
                            </div>
                         :
                                <div className={styles.divText}> 
                                <div
                                style={{
                                    width: "50%",
                                }}
                                >
                                    <p
                                    style={{
                                        marginRight: "auto"
                                    }}
                                    >Salario diario 
                                    </p>
                                </div>
                                <div
                                style={{
                                    width: "50%",
                                }}
                                >
                                    <p
                                    className={styles.textUsesData}
                                    >
                                        {
                                            userData[0]?.DS? formatterMxn(userData[0]?.DS) : '-'
                                        }
                                    </p>
                                </div>                            
                            </div>                                
                         }
                        <div className={styles.divText}> 
                            <div
                            style={{
                                width: "50%",
                            }}
                            >
                                <p
                                style={{
                                    marginRight: "auto"
                                }}
                                >Periodicidad de pago</p>
                            </div>
                            <div
                            style={{
                                width: "50%",
                            }}
                            >
                                <p
                                className={styles.textUsesData}
                                >
                                    {
                                        userData[0]?.paymentPeridicity? userData[0]?.paymentPeridicity : '-'
                                    }
                                </p>
                            </div>
                        </div>
                        <div className={styles.divText}> 
                            <div
                            style={{
                                width: "50%",
                            }}
                            >
                                <p
                                style={{
                                    marginRight: "auto"
                                }}
                                >Puesto</p>
                            </div>
                            <div
                            style={{
                                width: "50%",
                            }}
                            >
                                <p
                                className={styles.textUsesData}
                                >
                                    {
                                        userData[0]?.Puesto? userData[0]?.Puesto : '-'
                                    }
                                </p>
                            </div>                            
                            
                        </div>
                    </div>
                </div>
                <div
                className={styles.divGenerateContract}
                >   
                    <div
                    className={styles.doc}
                    >
                        {
                            type && userData[0]? (
                                <Undetermined user={userData[0]} type={type} dateContractDate={dateContractDate} dateOfAdmission={dateOfAdmission}/>
                            ):null
                        }
                    
                    </div>
                    <div>
                        <a className={styles.botonDescargar} 
                            //href={`${server}/report/ContractGenerato/${userData[0]?.id}/${dateOfAdmissionText}/${dayRemaining}/${monthRemaining}/${type}`}
                            onClick={() => {
                                exportarPDF(userData[0]?.id, dateOfAdmissionText, dayRemaining, monthRemaining, type);
                            }}
                        >
                            <p>
                                Exportar pdf
                            </p>
                        </a>
                    </div>
                </div>

            </div>
            
        </div>
    )
}