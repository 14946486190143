import { useContext } from "react";
import {
  FormControl,
  Grid,
  InputLabel,
  Select,
  TextField,
} from "@material-ui/core";
import Box from '@mui/material/Box';
import CollaboratorContext from "../../../../context/CollaboratorContext/CollaboratorContext";
import InputMask from "react-input-mask";

const EmergenciaInactivos = () => {
  const { state } = useContext(CollaboratorContext);
  const phoneCEMask = "";
  const phoneMovilMask = "";

  return (
    <>
      <div className="novalidate__border">
        <Box mt={2} mb={2}>
          <TextField
            name="emergencyContact"
            defaultValue={state.collaborator?.emergencyContact}
            autoFocus={true}
            label="Nombre del contacto de emergencia"
            variant="outlined"
            size="small"
            fullWidth={true}
            InputProps={{
              readOnly: true,
            }}
            style={{
              opacity: '0.5',
            }}
          />
        </Box>

        <Box mt={2}>
          <Grid direction="row" container spacing={2}>
            <Grid xs item>
              <FormControl variant="outlined" fullWidth={true} size="small">
                <InputLabel htmlFor="outlined-age-native-simple"
                style={{
                  opacity: '0.5',
                }}
                >
                  Parentesco
                </InputLabel>
                <Select
                  native
                  label={"Parentesco"}
                  name="ParentescoCE"
                  defaultValue={state.collaborator?.ParentescoCE || ""}
                  autoFocus={true}
                  disabled={true}
                >
                  <option
                    value={state.collaborator?.ParentescoCE || ""}
                    disabled={true}
                  >
                    {state.collaborator?.ParentescoCE || ""}
                  </option>
                  <option value="Madre">Madre</option>
                  <option value="Padre">Padre</option>
                  <option value="Hijo(a)">Hijo(a)</option>
                  <option value="Esposo(a)">Esposo(a)</option>
                  <option value="Concubino(a)">Concubino(a)</option>
                  <option value="Hermano(a)">Hermano(a)</option>
                  <option value="Novio">Novio</option>
                  <option value="Novia">Novia</option>
                  <option value="Amigo(a)">Amigo(a)</option>
                  helperText=
                  {!state.collaborator?.ParentescoCE && "Obligatorio"}
                </Select>
              </FormControl>
            </Grid>
            <Grid xs item>
              <InputMask
                mask="999 999 99 99"
                defaultValue={state.collaborator?.phoneCE || phoneCEMask}
                disabled={false}
              >
                <TextField
                  name="phoneCE"
                  autoFocus={true}
                  label="Teléfono fijo"
                  variant="outlined"
                  size="small"
                  fullWidth={true}
                  InputProps={{
                    readOnly: true,
                  }}
                  style={{
                    opacity: '0.5',
                  }}
                />
              </InputMask>
            </Grid>
          </Grid>
        </Box>

        <Box mt={2}>
          <Grid direction="row" container spacing={2}>
            <Grid xs item>
              <InputMask
                mask="999 999 99 99"
                defaultValue={state.collaborator?.cellphoneCE || phoneMovilMask}
                disabled={false}
              >
                <TextField
                  name="cellphoneCE"
                  autoFocus={true}
                  label="Teléfono móvil"
                  variant="outlined"
                  size="small"                    
                  fullWidth={true}
                  InputProps={{
                    readOnly: true,
                  }}
                  style={{
                    opacity: '0.5',
                  }}
                />
              </InputMask>
            </Grid>
            <Grid xs item>
              <TextField
                name="suburbCE"
                defaultValue={state.collaborator?.suburbCE}
                autoFocus={true}
                label="Colonia"
                variant="outlined"
                size="small"
                fullWidth={true}
                InputProps={{
                  readOnly: true,
                }}
                style={{
                  opacity: '0.5',
                }}
              />
            </Grid>
          </Grid>
        </Box>

        <Box mt={2}>
          <Grid direction="row" container spacing={2}>
            <Grid xs item>
              <TextField
                name="addressCE"
                defaultValue={state.collaborator?.addressCE}
                autoFocus={true}
                label="Calle y número"
                variant="outlined"
                size="small"
                fullWidth={true}
                InputProps={{
                  readOnly: true,
                }}
                style={{
                  opacity: '0.5',
                }}
              />
            </Grid>
          </Grid>
        </Box>
      </div>
      <div></div>
    </>
  );
};

export default EmergenciaInactivos;
