import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

const FilesTable = ({type,name,label,width,nameColumns,setNameColumns,deleteColumns,setDeleteColumns,inpuntValues,setInpuntValues}:any) => {
  
  const handleChange=(e:any)=>{
    const nameProperty : any=e.target.name
    const value        : any=e.target.value  
    const obj          : any={}
      obj[nameProperty] = value
    setInpuntValues({...inpuntValues,...obj})     
   }


  const handleEliminar=(name:any)=>{
   const newColumns=nameColumns.filter((column:any)=> column !==name )
   setNameColumns(newColumns)       
   setDeleteColumns([...deleteColumns , name])
  }

  return (
    <div className="containerComponentReport">
     <label className='labelReport'>{label}</label>
     <div className='containerInputReport'>
     <input
              className="inputInformes"
              type={type} 
              id={name} 
              name={name}
              width={width}
              
              onChange={(e) => handleChange(e)}
            />
      <DeleteOutlineIcon
        className='deleteReport' 
        sx={{ color: '#E0544B' }}
        onClick={()=> handleEliminar(name)}
      />      
     </div>
    </div>
  )
}

export default FilesTable
