import MenuHeader from "./MenuHeader";
import { useContext, useEffect, useState } from "react";
import styles from "../../styles/Header/headerStyles.module.css";
import Notifications from "./Notifications";
import ContadorNotificaciones from "./ContadorNotificaciones";
// import { useTour } from "@reactour/tour";
import LinearProgress from "@mui/material/LinearProgress";
import { Button } from "semantic-ui-react";
import PayrollProcessContext from "../../context/PayrollProcess/PayrollProcessContext";
import { closeOnboarding } from "../../context/PayrollProcess/Actions";
import MenuHelp from './MenuHelp';

const Header = ({ fromLink = false }) => {
  const [verNotificaciones, setVerNotificaciones] = useState(false);
  // const { currentStep, steps, setIsOpen } = useTour();
  const [percent, setPercent] = useState(0);
  const { state, dispatch } = useContext(PayrollProcessContext);

  const cambioEstatus = () => {
    setVerNotificaciones(!verNotificaciones);
  };

  const handleOutOfOnboard = () => {
    closeOnboarding(false, dispatch);
    // setIsOpen(false);
  };

  return (
    <header>
      <div className={styles.h_contenedor}>
        <div className={styles.h_contenedorLogo}>
          <img
            src="/assets/logo_bits_header.svg"
            alt="BITS"
            className={styles.h_imagenLogo}
          />
        </div>
        <div className={styles.h_espacio}>
          {state?.isOnboarding && (
            <div

              style={{ color: "white", display: state?.isOnboarding ? "block" : "none" }}
              className="text-center"
            >
              <p style={{ marginBottom: "0", marginLeft: "-120px" }}>
                {/* Progreso del onboarding ({currentStep + 1}/{steps.length}) */}
              </p>
              <div
                style={{
                  width: "40%",
                  display: "inline-block",
                }}
              >
                <LinearProgress variant="determinate" value={percent} />
              </div>
              <button
                className="OutOnboardingButton"
                onClick={() => handleOutOfOnboard()}
              >
                Salir del modo onboarding
              </button>
            </div>
          )}
        </div>
        {!fromLink && (
          <div className={styles.h_notificaciones}>
            <div
              className={styles.h_iconoNotificaciones}
              onClick={() => cambioEstatus()}
            ></div>
            <ContadorNotificaciones cambioEstatus={cambioEstatus} status={verNotificaciones} />
            {verNotificaciones === true ? <Notifications /> : null}
          </div>
        )}
        <div className={styles.h_menuHeader}>
          {!fromLink && (
            <MenuHelp />
          )}
          <MenuHeader fromLink={fromLink} />
        </div>
      </div>
    </header>
  );
};

export default Header;
