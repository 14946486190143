import {Types} from './CalendarsContext'

const ModalReducer = (state:any, action:any) => {
    const {payload, type} = action;
    switch(type){
        case Types.UPDATE_CALENDARS:
            return {
                ...state,
                _id:payload._id,
                updateModal:true
            }
        case Types.CREATE_CALENDARS:
            return {
                ...state,
                createModal:true
            }
        case Types.DELETE_CALENDARS:
            return{
                ...state,
                _id:payload._id,
                deleteModal:true
            }
        case Types.CREATE_EVENT:
            return{
                ...state,
                createEventModal:true
            }
        case Types.OPEN_NEW_WORK_SCHEDULE:
            return{
                ...state,
                openNewWorkSchedule:true
            }
        case Types.CLOSE_NEW_WORK_SCHEDULE:
            return{
                ...state,
                openNewWorkSchedule:false
            }
        case Types.OPEN_LIST_WORK_SCHEDULE:
            return{
                ...state,
                openListSchedule:true
            }
        case Types.CLOSE_LIST_WORK_SCHEDULE:
            return{
                ...state,
                openListSchedule:false
            }
        case Types.OPEN_LIST_USER_SCHEDULE:
            return{
                    ...state,
                    openListUserSchedule:true
            }
        case Types.CLOSE_LIST_USER_SCHEDULE:
            return{
                    ...state,
                    openListUserSchedule:false
           }
        case Types.OPEN_UPDATE_WORK_SCHEDULE:
            return{
                ...state,
                openUpdateSchedule:true,
                _id: payload._id
            }
        case Types.CLOSE_UPDATE_WORK_SCHEDULE:
            return{
                ...state,
                openUpdateSchedule:false
            }
        case Types.DELETE_WORK_SCHEDULE:
            return{
                ...state,
                deleteWorkSchedule:payload.deleteWorkSchedule
            }
        case Types.CREATE_INCIDENT:
            
            return{
                ...state,
                _id:payload._id,
                createIncidentModal:true
            }
        case Types.CLEAR_CALENDARS:
            return {
                ...state,
                _id:'',
                createModal:payload.createModal,
                updateModal:payload.updateModal,
                deleteModal:payload.deleteModal,
                createEventModal:payload.createEventModal,
                createIncidentModal:payload.createIncidentModal
            }
            case Types.CREATE_NONWORKINGDAY:
                //console.log('non reduce',payload._id)
                return{
                    ...state,
                    _id:payload._id,
                    idAux:payload.idAux,
                    createNonWorkingDayModal:true,
                    date1:payload.date1,
                    date2:payload.date2
                }
            case Types.CLEAR_NONWORKINGDAY:
                return{
                    ...state,
                    createNonWorkingDayModal:false,
                    date1:'',
                    date2:''
                }
            case Types.DELETE_NONWORKINGDAY:
                return{
                    ...state,
                    _id:payload._id,
                    idAux:payload.idAux,
                    deleteNonWorkingDayModal:true
                }
            case Types.CLEAR_DELETE_NONWORKINGDAY:
                return{
                    ...state,
                    _id:payload._id,
                    idAux:payload.idAux,
                    deleteNonWorkingDayModal:false
                }
            case Types.CREATE_EDITEVENT:
                //console.log('non reduce',payload._id)
                return{
                    ...state,
                    _id:payload._id,
                    idAux: payload.idAux,
                    createEditEventModal:true,
                    date1:payload.date1,
                    date2:payload.date2
                }
            case Types.DELETE_EDITEVENT:
                return{
                    ...state,
                    _id:payload._id,
                    idAux:payload.idAux,
                    deleteEditEventModal:true
                }
            case Types.CLEAR_EDITEVENT:
                return{
                    ...state,
                    _id:payload._id,
                    createEditEventModal:false
                }
            case Types.CLEAR_DELETE_EDITEVENT:
                return{
                    ...state,
                    _id:payload._id,
                    deleteEditEventModal:false
                }
            default:
            return state;
    }
}

export default ModalReducer;