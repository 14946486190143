import { ChangeEvent, useContext, useEffect, useState } from "react";
import {Grid, TextField, Button } from "@material-ui/core";
import Box from '@mui/material/Box';
import { Form } from "semantic-ui-react";
import CollaboratorContext from "../../../../context/CollaboratorContext/CollaboratorContext";
import { handleSetActiveStep, updateData } from "../../../../helpers/Collaborator/Collaborator";
import { useHistory, useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import InputMask from "react-input-mask";
import {
  CREATE_USERS,
  GET_ALL_USERS_NUEVOINGRESO
} from "../../../../Querys/querys";
import { useMutation } from "@apollo/client";
import { SuccessfulAlert } from "../../../../alerts/successAlerts";
import SaveIcon from "@material-ui/icons/Save";
import { AdminContext } from "../../../../context/AdminContext/AdminContext";
import CheckPermission from "../../../../helpers/Administradores/Permissions";
import {RemoveSpaces} from "../../../../helpers/RemoveSpaces"

const PersonalData = () => {
  const { state, dispatch } = useContext(CollaboratorContext);
  const { adminState } = useContext(AdminContext);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [phoneMask, setPhoneMask] = useState("");
  const history = useHistory();
  const [createUserMutation] = useMutation(CREATE_USERS, {
    refetchQueries: [{ query: GET_ALL_USERS_NUEVOINGRESO }],
  });

  const params = useParams();
  useEffect(() => {
    if ("register" in params) setDisabled(true);
  }, [params]);

  const handleChange = async (
    e: ChangeEvent<{ name: string; value: unknown }>
  ) => {
    await updateData(e, state, dispatch, 0);
  };

  const initialValues = () => {
    return {
      name: "",
      firstName: "",
      lastName: "",
      email: "",
      cellphone: "",
      userAdd: adminState?.Usuario,
    };
  };

  const formik = useFormik({
    initialValues: initialValues(),
    validationSchema: Yup.object(validationSchema()),

    onSubmit: async (formData) => {
      const info      = {...formData}
      const rightData = {...formData,
                                name:      RemoveSpaces(info.name),
                           firstName: RemoveSpaces(info.firstName),
                            lastName:  RemoveSpaces(info.lastName)                             
                      }
      let { data } = await createUserMutation({
        variables: {
          input: rightData,
        },
      });

      if (data) {
        await SuccessfulAlert({
          text: "El usuario se ha creado correctamente",
        }).finally(() => {
          //set in localstorage the activeStep
          handleSetActiveStep(1, dispatch);
          localStorage.nextStep = 1;
          history.push(`/nuevoingreso/${data.CREATE_USERS.id}/1`);
        });
      }
    },
  });

  return (
    <Form onSubmit={formik.submitForm}>
      <div className="novalidate__border">
        <Box mb={2}>
          <TextField
            type="text"
            name="name"
            defaultValue={formik.values?.name}
            label="Nombre(s)"
            variant="outlined"
            size="small"
            fullWidth={true}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            onBlur={(e) => handleChange(e)}
            InputProps={{
              readOnly: false,
            }}
            disabled={disabled}
            helperText={formik.touched.name && !formik.values?.name && "Obligatorio"}
          />
        </Box>
        <Grid direction="row" container spacing={2}>
          <Grid xs item>
            <TextField
              name="firstName"
              defaultValue={formik.values?.firstName || ""}
              label="Primer apellido"
              variant="outlined"
              size="small"
              fullWidth={true}
              onChange={formik.handleChange}
              error={formik.touched.firstName && Boolean(formik.errors.firstName)}
              onBlur={(e) => handleChange(e)}
              InputProps={{
                readOnly: false,
              }}
              disabled={disabled}
              helperText={formik.touched.firstName && !formik.values?.firstName && "Obligatorio"}
            />
          </Grid>
          <Grid xs item>
            <TextField
              name="lastName"
              defaultValue={formik.values?.lastName || ""}
              label="Segundo apellido"
              variant="outlined"
              size="small"
              fullWidth={true}
              onChange={formik.handleChange}
              error={formik.touched.lastName && Boolean(formik.errors.lastName)}
              onBlur={(e) => handleChange(e)}
              InputProps={{
                readOnly: false,
              }}
              disabled={disabled}
            />
          </Grid>
        </Grid>
        <Box mt={2}>
          <TextField
            type="email"
            name="email"
            defaultValue={formik.values?.email || ""}
            label="Email"
            variant="outlined"
            size="small"
            fullWidth={true}
            error={formik.touched.email && Boolean(formik.errors.email)}
            onChange={formik.handleChange}
            onBlur={(e) => handleChange(e)}
            InputProps={{
              readOnly: false,
            }}
            disabled={disabled}
            helperText={formik.touched.email && !formik.values?.email && "Obligatorio"}
          />
        </Box>
        <Box mt={2}>
          <InputMask
            mask="999 999 99 99"
            defaultValue={formik.values?.cellphone || phoneMask}
            onChange={(e) => {
              setPhoneMask(e.target.value);
              formik.handleChange(e);
            }}
            disabled={false}
            onBlur={(e) => handleChange(e)}
          >
            <TextField
              id="cellphone"
              type="string"
              name="cellphone"
              defaultValue={formik.values?.cellphone || ""}
              label="Teléfono celular profesional"
              variant="outlined"
              size="small"
              fullWidth={true}
              error={formik.touched.cellphone && Boolean(formik.errors.cellphone)}
              InputProps={{
                readOnly: false,
              }}
              disabled={disabled}
              helperText={formik.touched.cellphone && !formik.values?.cellphone && "Obligatorio"}
            />
          </InputMask>
        </Box>
      </div>
      {CheckPermission(
        "Colaboradores.NuevoIngreso.Ver.InformacionAlta.InformacionPersonal.Guardar"
      ) && (
        <Grid
          direction="row"
          container
          justify="flex-end"
          alignItems="center"
          style={{ marginTop: "20px" }}
        >
          <Button type="submit" className="buttonSave">
            <SaveIcon />
            &nbsp; Guardar
          </Button>
        </Grid>
      )}
    </Form>
  );
};

const validationSchema = () => {
  return {
    name: Yup.string().required("El name es requerido"),
    firstName: Yup.string().required("El apellido es un campo requerido"),
    email: Yup.string().email().required("El email es un campo obligatorio"),
    cellphone: Yup.number()
      .required("El celular es un campo obligatorio")
      .min(13, "El celular debe tener al menos 10 dígitos"),
  };
};

export default PersonalData;
