import './Organigrama.css'

const ImagenColaborador2 = (props: any) =>{
    let PrimeraLetra = props.Nombre?.charAt(0)
    let SegundaLetra = props.Apellido?.charAt(0)
    
    return(
        <div className="circulo_secundario colorBlue_secundario"
        style={{
            width: '73.55px',
            height: '73.55px'
        }}
        >
            <span className='textoCirculo'>{PrimeraLetra}{SegundaLetra}</span>
        </div>
    )
}

export default ImagenColaborador2