import {useReducer} from 'react';
import CampaignContext,{initialState} from './CampaignContext';
import CampaignReducer from './CampaignReducer'

const CampaignState = (props:any) => {
   const [state, dispatch] =useReducer(CampaignReducer,initialState)
   return (
    <CampaignContext.Provider value={{state,dispatch}}>
        {props.children}
    </CampaignContext.Provider>
  )
}

export default CampaignState