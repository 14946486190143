import { useContext } from "react";
import { AdminContext } from "../../context/AdminContext/AdminContext";

export default function CheckPermission(value: string) {
  const { adminState } = useContext(AdminContext);

  const findPermisos = adminState?.Permisos?.find((permiso: any) => {
    return permiso.name === value;
  });
  return findPermisos?.status;
}
