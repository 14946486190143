import { Grid, Menu, MenuItem } from '@material-ui/core'
import "../styles.css"
import { useContext, useState } from "react";
import {createNonWorkingDayModal, deleteNonWorkingDayModal} from '../../../context/ScheduleContext/Actions'
import CalendarsContext from "../../../context/NewCalendarContext/CalendarsContext";
import CheckPermission from '../../../helpers/Administradores/Permissions';

interface TabMeasures {
    children?: React.ReactNode;
    measures: any;
    NonWorkingDayName: any;
    calendar: any;
    idNonWorkingDay: any;
    initDate: any;
    setInitDateRecords: any;
    endDate: any;
    setEndDateRecords: any;
    type: String
    setTypeRecords: any;
};

function NonWorkingDayDivModal(props: TabMeasures){

    const { measures, NonWorkingDayName, calendar, idNonWorkingDay, initDate, setInitDateRecords, endDate, setEndDateRecords, type, setTypeRecords } = props;
    const [anchorEl, setAnchorEl] = useState<any>(null);
    const open = Boolean(anchorEl);

    const {dispatch} = useContext(CalendarsContext)
    
    
    const createModal = (id:string, createModal:boolean) => {
        setAnchorEl(null);
        createNonWorkingDayModal(calendar, id, createModal, initDate, endDate, dispatch)
    }
    
    const deleteModal = (id:string, createModal:boolean) =>{
        setAnchorEl(null);
        deleteNonWorkingDayModal(calendar, id, createModal, dispatch)
    }

    if(idNonWorkingDay === "0"){
        return <div 
        className="nonWorkingDay" 
        style={
            {
                width: measures.width,
                left: measures.left
            }
        }
        onClick={(e) => {
            setInitDateRecords(initDate)
            setEndDateRecords(endDate)
            setTypeRecords(type)
        }}
        >
            <div
            className="textCalendarDay"
            >
            {NonWorkingDayName}
            </div>
    </div>
    }else{
        return <>
        <div 
            className="nonWorkingDay" 
            style={
                {
                    width: measures.width,
                    left: measures.left
                }
            }
            onClick={(e) => {
                setAnchorEl(e.currentTarget)
            }}
            >
                <div
                className="textCalendarDay"
                >
                {NonWorkingDayName}              
                </div>
        </div>
        
        <Menu
            className="MoreVerIcon"
            anchorEl={anchorEl}
            open={open}
            transformOrigin={{ vertical: -40, horizontal: "left" }}
            onClose={() => setAnchorEl(null)}
        >
            {CheckPermission("Calendario.Ver.Editar") && (

            <MenuItem divider button 
            onClick={() => createModal(idNonWorkingDay, true)}
            >
                Editar&nbsp;
                <Grid container item justify="flex-end">
                    <img src={`/assets/svg/icono-editar.svg`} alt="Editar" />
                </Grid>
            </MenuItem>
            )}
            {CheckPermission("Calendario.Ver.Eliminar") && (
   
            <MenuItem divider button 
             onClick={() => deleteModal(idNonWorkingDay, true)}
            >
                Eliminar&nbsp;
                <Grid container item justify="flex-end" >
                    <img src={`/assets/svg/icono-eliminar.svg`} alt="Eliminar" />
                </Grid>
            </MenuItem>
            )}   
        </Menu >
        
    </>
    }

    

}

export default NonWorkingDayDivModal