import { useEffect, useState, ChangeEvent, useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Grow, Paper, Tab, Tabs } from "@material-ui/core";
import { TabContext, TabPanel } from "@material-ui/lab";
import Contacts from "./Contacts/Contacts";
import Expedient from "./Expedient/Expedient";
import Information from "./Information/Information";
import PayData from "./PayData/PayData";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CollaboratorContext, {
  Types,
} from "../../context/CollaboratorContext/CollaboratorContext";
import {
  updateCollaborator,
  fetchingAllData,
} from "../../helpers/Collaborator/Collaborator";
import PersonalData from "./PersonalData/PersonalData";
import { GET_USERS_BY_ID } from "../../Querys/querys";
import { useQuery } from "@apollo/client";
import CheckPermission from "../../helpers/Administradores/Permissions";

const Body = () => {
  const [value, setValue] = useState<string>("1");
  const params = useParams<any>();
  const history:any = useHistory();
  const { state, dispatch } = useContext(CollaboratorContext);
  const [processTab1, setProcessTab1] = useState<any>();
  const [processTab2, setProcessTab2] = useState<any>();
  const [processTab3, setProcessTab3] = useState<any>();
  const [processTab4, setProcessTab4] = useState<any>();
  const [processTab5, setProcessTab5] = useState<any>();
  const [collaborator, setCollaborator] = useState<any>();

  const handleChange = (event: ChangeEvent<{}>, newValue: string) => {
    setValue(newValue);
    history.push(`${newValue}`, history.location.state && history.location.state.from ? {from: history.location.state.from} : undefined);
  };

  const { data: resultCollaborator } = useQuery(GET_USERS_BY_ID, {
    variables: { getUsersId: params.id },
    fetchPolicy: 'no-cache'
  });

  useEffect(() => {
    async function fetchData() {
      let result: any;
      if (
        params.id !== "id" &&
        params.id !== "new" &&
        !("register" in params)
      ) {
        if (typeof resultCollaborator?.GET_USERS[0] == "undefined") {
          setCollaborator(resultCollaborator?.GET_USERS[0]);
        } else {
          result = JSON.parse(JSON.stringify(resultCollaborator?.GET_USERS[0]));
          result.new = true;
          setCollaborator(resultCollaborator?.GET_USERS[0]);
        }
      }
      if ("register" in params) {
        result = resultCollaborator?.GET_USERS; //await getCandidate(params.id, false, params.token); // From Candidate
        if (result === null) {
          return history.push("/Bienvenido"); // validate if the user completed the register
        }
      }
      await updateCollaborator(result, dispatch); //Update collaborator state
      await fetchingAllData(
        resultCollaborator?.GET_USERS,
        dispatch,
        params.tab
      );
    }
    if (resultCollaborator) {
      setValue(params.tab);

      if ("register" in params) {
        dispatch({ type: Types.UPDATE_BAND });
        document.documentElement.style.setProperty("--background", "#093c5d");
      }

      fetchData();
    }
  }, [dispatch, params, history, resultCollaborator]);

  useEffect(() => {
    if (collaborator) {
      collaborator.register_process?.forEach((element: any) => {
        const dataToSet = {
          "1": element.section_1,
          "2": element.section_2,
          "3": element.section_3,
          percent: element.percent,
          completed: element.completed,
        };
        switch (element.tab) {
          case "information":
            setProcessTab1(dataToSet);
            break;
          case "personalData":
            setProcessTab2(dataToSet);
            break;
          case "emergency":
            setProcessTab3(dataToSet);
            break;
          case "payData":
            setProcessTab4(dataToSet);
            break;
          case "expedient":
            setProcessTab5(dataToSet);
            break;
          default:
            break;
        }
      });
    }
  }, [collaborator]);

  return (
    <TabContext value={value}>
      <Tabs
        value={value}
        onChange={handleChange}
        className={
          processTab1?.completed === true
            ? `collaborator filledAll`
            : `collaborator `
        }
        scrollButtons="auto"
        variant="fullWidth"
      >
        <Tab
          icon={processTab1?.completed === true ? <CheckCircleIcon /> : ``}
          label={"Información de alta"}
          value="1"
          className={
            value === "1"
              ? "Tab__SelectedFirst Tab__Selected"
              : processTab1?.completed === true
              ? "Tab__SelectedFirst Tab__Selected Tab__background filledAll"
              : "Tab__NoSelected Tab__NoSelectedFirst"
          }
        />
        <Tab
          icon={processTab2?.completed === true ? <CheckCircleIcon /> : ""}
          label="Datos Personales"
          value="2"
          className={
            value === "2"
              ? "Tab__Selected"
              : processTab2?.completed === true
              ? "Tab__Selected Tab__background filledAll"
              : "Tab__NoSelected"
          }
          disabled={state.collaborator.own_register || processTab2?.completed === true ? false : true}
        />
        <Tab
          icon={processTab3?.completed === true ? <CheckCircleIcon /> : ""}
          label="Salud y Emergencias"
          value="3"
          className={
            value === "3"
              ? "Tab__Selected"
              : processTab3?.completed === true
              ? "Tab__Selected Tab__background filledAll"
              : "Tab__NoSelected"
          }
          disabled={state.collaborator.own_register || processTab3?.completed === true ? false : true}
        />
        <Tab
          icon={processTab4?.completed === true ? <CheckCircleIcon /> : ""}
          label="Datos para el pago"
          value="4"
          className={
            value === "4"
              ? "Tab__Selected"
              : processTab4?.completed === true
              ? "Tab__Selected Tab__background filledAll"
              : "Tab__NoSelected"
          }
          disabled={state.collaborator.own_register || processTab4?.completed === true ? false : true}
        />
        <Tab
          icon={processTab5?.completed === true ? <CheckCircleIcon /> : ""}
          label="Expediente"
          value="5"
          className={
            value === "5"
              ? "Tab__Selected Tab__Selectedlast"
              : processTab5?.completed === true
              ? "Tab__Selected Tab__background filledAll Tab__NoSelectedLast"
              : "Tab__NoSelected Tab__NoSelectedLast"
          }
          disabled={state.collaborator.own_register || processTab5?.completed === true ? false : true}
        />
      </Tabs>
      <div className="collaborator__candidate__container">
        <Grow in={true}>
          <Paper>
            <div className="lbViendo">
              <h3>
                Colaborador: {state.collaborator.name}{" "}
                {state.collaborator.firstName} {state.collaborator.lastName}
              </h3>
            </div>

            {CheckPermission(
              "Colaboradores.Colaboradores.Editar.InformacionAlta"
            ) && (
              <TabPanel value="1">
                {value === "1" && <Information process={processTab1} />}
              </TabPanel>
            )}
            {CheckPermission(
              "Colaboradores.Colaboradores.Editar.DatosPersonales"
            ) && (
              <TabPanel value="2">
                {value === "2" && <PersonalData process={processTab2} />}
              </TabPanel>
            )}
            {CheckPermission(
              "Colaboradores.Colaboradores.Editar.SaludEmergencias"
            ) && (
              <TabPanel value="3">
                {value === "3" && <Contacts process={processTab3} />}
              </TabPanel>
            )}
            {CheckPermission(
              "Colaboradores.Colaboradores.Editar.DatosParaPago"
            ) && (
              <TabPanel value="4">
                {value === "4" && <PayData process={processTab4} />}
              </TabPanel>
            )}
            {CheckPermission(
              "Colaboradores.Colaboradores.Editar.Expediente"
            ) && (
              <TabPanel value="5">
                {value === "5" && <Expedient process={processTab5} />}
              </TabPanel>
            )}
          </Paper>
        </Grow>
      </div>
    </TabContext>
  );
};

export default Body;
