import onClickOutside from "react-onclickoutside";
import style from '../../screens/BitsScreen.module.css';

function ContractList({dataContratos, history, checkContratos, color, setShow, setShowRed, setShowYellow}:any){
  
  ContractList.clickOutside = () => {
    setTimeout( () => {
      setShow(false);
      setShowRed(false);
      setShowYellow(false);
    }, 100);
  }

  return (
    <div className={style.detalleColaboladores} style={style}>
      {dataContratos.map((lis: any, key:number) => 
      (color === 'blue' ? lis.numberday >= 8 : color === 'yellow' ? lis.numberday >= 4 && lis.numberday <= 7 : lis.numberday <= 3) && (
        <div key={key}>
          <div>
            <br></br>
            <div>
              <span className={style.textognral}>
                {lis.bussinesName}
              </span>
            </div>

            <span className={style.textognralmingray}>
              Vence dentro de {lis.numberday} días
            </span>
          </div>
        </div>
      ))}
      {
        checkContratos(color) === 0 && (
          <div className={style.center}>
            <div>
              <span className={style.textognral}>
                No hay ningún contrato cerca de vencer
              </span>
            </div>
          </div>
        )
      }
      <br></br>
      <button
        onClick={() => {
          localStorage.setItem("currentPill", "1");
          localStorage.setItem(
            "currentModule",
            "Colaboradores"
            );
            localStorage.setItem("nextStep", "2");
          history.push(`/collaborators/id/0`);
        }}
        className={style.boton}
        type="button"
      >
        <span className={style.textoAgregar}>
          Ir a renovar &nbsp;
        </span>
        <span className={style.iconoAgregar}>
          <img src="/assets/svg/direc.svg" alt="add"/>
        </span>
      </button>
    </div>
  )
}

ContractList.clickOutside = () => {};

const clickOutsideConfig = {
  handleClickOutside: () => ContractList.clickOutside
};

export default onClickOutside(ContractList, clickOutsideConfig);