
import { useContext, useState } from "react";
import CalendarsContext from "../../../../context/NewCalendarContext/CalendarsContext";
import { clearNonWorkingDayModal } from "../../../../context/ScheduleContext/Actions";
import { Dialog, DialogContent, TextField } from "@material-ui/core"
import { Formik, Form} from "formik";
import styles from '../../../../components/NewCalendar/Calendars.module.css'
import * as Yup from "yup";
import { useMutation, useQuery } from "@apollo/client";
import { 
    UPDATE_NON_WORKING,
    GET_NON_WORKING,
    GET_NON_WORKING_BY_MONTH_YEAR
} from "../../../../Querys/querys";
import { SuccessfulAlert } from "../../../../alerts/successAlerts";
import { subMonths } from 'date-fns';
import moment from 'moment';
import DateRangeField from "../../../MyMood/Fields/DateRangeField";

const CreateNonWorkingDayEditModal3 = ({setDate, date, setNonWorkingDays, nonWorkingDays}:any) => { 
 
    const {state, dispatch} = useContext(CalendarsContext)
    const [showCalendario, setShowCalendario] = useState(false);
    
    const [stateCalendario, setStateCalendario] = useState([
        {
            startDate: subMonths(new  Date(parseInt(moment(state.date1).format('YYYY')), parseInt(moment(state.date1).format('MM')), parseInt(moment(state.date1).format('DD'))), 1),
            endDate: subMonths(new Date(parseInt(moment(state.date2).format('YYYY')), parseInt(moment(state.date2).format('MM')), parseInt(moment(state.date2).format('DD'))), 1),
            key: "selection"
        }]
      );
    const [upDateNonWorkingDay] = useMutation(UPDATE_NON_WORKING,{
        refetchQueries:[
                        {query:GET_NON_WORKING_BY_MONTH_YEAR, variables:{
                                                                            calendarId: parseInt(state._id), 
                                                                            month: parseInt(moment(stateCalendario[0].startDate).format('M')), 
                                                                            year: parseInt(moment(stateCalendario[0].startDate).format('YYYY'))
                                                                        }},
                        {query:GET_NON_WORKING_BY_MONTH_YEAR, variables:{
                                                                            calendarId: parseInt(state._id), 
                                                                            month: parseInt(moment(stateCalendario[0].endDate).format('M')), 
                                                                            year: parseInt(moment(stateCalendario[0].endDate).format('YYYY'))
                                                                        }},
                        {query:GET_NON_WORKING, variables:{id: parseInt(state._id)}}
                      ]
      })

    const { loading, data } =  useQuery(GET_NON_WORKING, {
        variables: {
            id: parseInt(state.idAux)
            }
    });

    if(loading) return null;
    const nonDay = data?.GET_NON_WORKING

    const initialValues = () => {
        return {
            name:nonDay?.name,
            calendarId: nonDay?.calendarId,
            initDate:nonDay?.initDate,
            endDate: nonDay?.endDate,
        }
    }

    const handleClose = ()=>{
        clearNonWorkingDayModal({},dispatch);
    }
    
    const toggleCalendar = () => {
        if(showCalendario === false){
          setShowCalendario(true);
        }
    }
    const rangeSelection = (selection: any)=>{
        setStateCalendario((current)=>current= selection);
        setShowCalendario(!showCalendario);
    }
    const dateText = () =>{
        const date =  stateCalendario.map((home: any) =>{
            if( moment(home?.startDate).format('YYYY') !== '1999'){
                return <div id="hora">
                <span 
                className={styles.textoFecha}
                >{new Date(home.startDate).toLocaleDateString()} - {new Date(home.endDate).toLocaleDateString()}</span></div>
            }else{
                setStateCalendario([{
                    startDate: new Date(nonDay?.initDate),
                    endDate: new Date(nonDay?.endDate),
                    key: "selection"
                }])
                return <div id="hora">
                <span 
                className={styles.textoFecha}
                >{new Date(nonDay?.initDate).toLocaleDateString()} - {new Date(nonDay?.endDate).toLocaleDateString()}</span></div>
            }
                

        })
        return date
    }

  return(
    <Dialog open={state.createNonWorkingDayModal} aria-labelledby="form-dialog-title" maxWidth="sm" fullWidth={true}>
        <div className={styles.dialogContainer}>
            <div className={styles.containerTitle}>
                <div className={styles.centerContainer}
                style={{
                    marginLeft:'auto',
                    marginRight:'auto'
                }}
                >
                    <h2 id="form-dialog-title" data-testid="TitleModal">Edita d&iacute;a inh&aacute;bil</h2>
                    
                </div>
                <div className={styles.contenedorCerrarModal}>
                    <div className={styles.iconoCerrarModal} onClick={(()=>handleClose())}></div>      
                </div> 
            </div>
            <DialogContent>
            <Formik
            initialValues={initialValues()}
            validationSchema={validationSchema}
            onSubmit={formData => {
                
                const idNon = Number(state.idAux)
                upDateNonWorkingDay({
                    variables: {
                        upDateNonWorkingDayId:idNon,
                        input: {
                            name: formData.name,
                            initDate: 
                                stateCalendario[0].startDate,
                            endDate: 
                                stateCalendario[0].endDate,
                            calendarId: formData.calendarId
                        },
                    },
                }).then(({data})=>{
                  SuccessfulAlert({text:"Se edito día inhábil"});
                });
                handleClose();
            }}
        >
            {({
                values,
                errors,
                touched,
                handleChange,
                handleSubmit,
            }) => (
            <Form onSubmit = {handleSubmit}>
                <TextField 
                    fullWidth 
                    className={styles.dialogFields} 
                    name="name" 
                    label="Título*" 
                    variant="outlined" 
                    value = {values.name}
                    onChange={handleChange}
                    error={touched.name && Boolean(errors.name)}
                    helperText = {touched.name && errors.name}
                    size="small" 
                    inputProps={{maxLength: 30}}
                    onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                    />
                <DateRangeField setDate={setStateCalendario} containerClass={"fieldsetInput"} daySelected1={values.initDate} daySelected2={values.endDate}></DateRangeField>
                <br></br>
                <div className={styles.centerContainer}>
                    <button className={styles.buttonCancelCalendar} onClick={() => handleClose()}>
                        <b className={styles.buttonCancelCalendarText}
                        style={{
                            textTransform: "capitalize"
                        }}
                        >Cancelar</b>
                    </button>
                    <div className={styles.conteinerSave}>
                        <button type="submit" className={styles.buttonSaveCalendar2}>
                        <b className={styles.buttonSaveCalendarText}
                        style={{
                            textTransform: "capitalize"
                        }}
                        >Editar
                        </b>
                        </button>
                    </div>
                        
                </div>

                </Form>
            )}
            </Formik>
            </DialogContent>
        </div>
    </Dialog>
  )
}


// @ts-ignore
const validationSchema = Yup.object().shape({
        name: Yup.string().required("El título es requerido")
})
// @ts-ignore


export default CreateNonWorkingDayEditModal3