import { useContext } from "react";
import { Grid, TextField } from "@material-ui/core";
import Box from '@mui/material/Box';
import CollaboratorContext from "../../../../context/CollaboratorContext/CollaboratorContext";
import InputMask from "react-input-mask";

const PersonalDataInactivos = () => {
  const { state } = useContext(CollaboratorContext);
  const phoneMask = "";

  return (
    <>
      <div className="novalidate__border">
        <Box mb={2}>
          <TextField
            type="text"
            name="name"
            defaultValue={state.collaborator.name}
            label="Nombre(s)"
            variant="outlined"
            size="small"
            fullWidth={true}
            InputProps={{
              readOnly: true,
            }}
            style={{
              opacity: '0.5',
            }}
          />
        </Box>
        <Grid direction="row" container spacing={2}>
          <Grid xs item>
            <TextField
              name="firstName"
              defaultValue={state.collaborator?.firstName || ""}
              label="Primer apellido"
              variant="outlined"
              size="small"
              fullWidth={true}
              InputProps={{
                readOnly: true,
              }}
              style={{
                opacity: '0.5',
              }}
            />
          </Grid>
          <Grid xs item>
            <TextField
              name="lastName"
              defaultValue={state.collaborator?.lastName || ""}
              label="Segundo apellido"
              variant="outlined"
              size="small"
              fullWidth={true}
              InputProps={{
                readOnly: true,
              }}
              style={{
                opacity: '0.5',
              }}
            />
          </Grid>
        </Grid>
        <Box mt={2}>
          <TextField
            type="email"
            name="email"
            defaultValue={state.collaborator?.email || ""}
            label="Email"
            variant="outlined"
            size="small"
            fullWidth={true}
            InputProps={{
              readOnly: true,
            }}
            style={{
              opacity: '0.5',
            }}
          />
        </Box>
        <Box mt={2}>
          <InputMask
            mask="999 999 99 99"
            defaultValue={state.collaborator?.cellphone || phoneMask}
          >
            <TextField
              id="cellphone"
              type="string"
              name="cellphone"
              defaultValue={state.collaborator?.Celular || ""}
              label="Teléfono celular profesional"
              variant="outlined"
              size="small"
              fullWidth={true}
              InputProps={{
                readOnly: true,
              }}
              style={{
                opacity: '0.5',
              }}
          />
          </InputMask>
        </Box>
      </div>
    </>
  );
};

export default PersonalDataInactivos;
