import { useContext, useState, useEffect, useRef} from "react"
import EmpresaContext from "../../../context/Empresa/EmpresaContext"
import {Dialog} from "@material-ui/core"
import { clearEmpresaModal } from "../../../context/Empresa/Actions"
import styles from '../../../styles/MiEmpresa/EditaEmpresa.module.css'
import { GET_ENTERPRISE,
  GET_REGISTROPATRONAL, 
  UPDATE_ENTERPRISE_WITHOUT_FILES, 
  GET_ALL_ENTERPRISE, 
  CREATE_REGISTROPATRONAL,
  DELETE_REGISTROPATRONAL,
  GET_ALL_REGIMENFISCAL,
  GET_ALL_RIESGOPUESTO
} from "../../../Querys/querys"
import { useQuery, useMutation, useLazyQuery } from "@apollo/client"
import { Formik, Form} from "formik";
import * as Yup from "yup"
import {TextField,Select, InputLabel,FormControl,MenuItem, Checkbox } from "@material-ui/core"
import FileHelperIncident from "../../Collaborator/Expedient/Fields/FileHelperIncident";
import RemoveFile from "../../../assets/svg/remove_file.svg";
import EyeIcon from "../../../assets/svg/eye.svg";
import {
  postEnterprisAWS
} from "../../../services/candidateService";
import { SuccessfulAlert } from "../../../alerts/successAlerts";
import InputMask from "react-input-mask";
import { ErrorAlert } from '../../../alerts/errorAlert';
import {
  getMunicipios,
  getStates,
} from "../../../helpers/Json/getStatesAndMunicipios";
import moment from "moment"

const EditaEmpresa = () =>{
  let totalRegimen = 0
  const {state, dispatch} = useContext(EmpresaContext)
  const [archives, setArchives] = useState<any>()
  const [archivesIMSS, setArchivesIMSS] = useState<any>()
  const [archivesSello, setArchivesSello] = useState<any>()
  const [showPassword, setShowPassword] = useState(false)
  const [uno, setUno] = useState("")
  const [dos, setDos] = useState("")
  const [fileKeyTimbre, setFileKeyTimbre] = useState<any>(undefined)
  const states = getStates('México');
  const [getRiesgo, {data}] = useLazyQuery(GET_ALL_RIESGOPUESTO, {
    fetchPolicy: 'no-cache'
  });
  const [allRiesgoPuesto, setAllRiestoPuesto] = useState([]);
  const [riskPlaced, setRiskPlaced] = useState<any>('');
  const [municipios, setMunicipios]:any[]=useState([]);

  if(fileKeyTimbre !== undefined){
    if(fileKeyTimbre){


      const reader = new FileReader();
      reader.onload = () => {

        
      };
      reader.readAsArrayBuffer(fileKeyTimbre.target.files[0]);

    }
   
    
  }
  
  const [fileCerTimbre, setFileCerTimbre] = useState(undefined)
  const [showPasswordTimbre, setShowPasswordTimbre] = useState(false)
  const [dot, setDot] = useState(0)
  const [getRegimen, {data: RegimenFiscalQuery}] = useLazyQuery(GET_ALL_REGIMENFISCAL,{
    fetchPolicy:'no-cache'
  });
  const [allRegimenFiscal, setAllRegimenFiscal] = useState([]);

  const [updateCompany] = useMutation(UPDATE_ENTERPRISE_WITHOUT_FILES, {
    refetchQueries: [
      { query: GET_ALL_ENTERPRISE },
      {query:GET_ENTERPRISE, variables:{getEnterpriseId: state._id}}
      ],
  });

  const [createRegistroPatronal] = useMutation(CREATE_REGISTROPATRONAL,
  {
    refetchQueries: [
      {query:GET_REGISTROPATRONAL, variables:{enterpriseId: parseInt(state._id)}},
      {query: GET_ALL_ENTERPRISE}
    ],
  });

  const [deleteRegistroPatronal] = useMutation(DELETE_REGISTROPATRONAL,
    {
      refetchQueries: [
        {query:GET_REGISTROPATRONAL, variables:{enterpriseId: parseInt(state._id)}}],
  });

  const insertArchive = async (idEmpresa: any) =>{

    if(archivesSello !== undefined || archivesIMSS !== undefined || archives !== undefined || fileKeyTimbre !== undefined || fileCerTimbre !== undefined){
      
      await postEnterprisAWS(
                            idEmpresa, 
                            archives, archivesIMSS, archivesSello, fileKeyTimbre, fileCerTimbre,
                            "LogoEmpresa", "IMSSEmpresa", "SELLOEmpresa", "KeyTimbreEmpresa", "CerTimbreEmpresa",
                            "LogoEmpresa", "IMSSEmpresa", "SELLOEmpresa", "KeyTimbreEmpresa", "CerTimbreEmpresa"
                            );
      
    }

    handleClose();

    SuccessfulAlert({
      title: "¡Exito!",
      text: "¡Registro editado!",
    });

  }

  const [getEnterprise,{loading,data: dataEnterprise}]= useLazyQuery(GET_ENTERPRISE,{
    fetchPolicy: 'no-cache'
  })

  const [getRegistros,{data: dataRegistroPatronal}]= useLazyQuery(GET_REGISTROPATRONAL,{
    fetchPolicy: 'no-cache'
  })
  const [registroPatronal, setRegistroPatronal]: any = useState([]);

  const [dataEnterprise2, setDataEnterprise]: any = useState({});

  useEffect(()=>{
    if(RegimenFiscalQuery && RegimenFiscalQuery?.GET_ALL_REGIMENFISCAL){
      setAllRegimenFiscal(RegimenFiscalQuery.GET_ALL_REGIMENFISCAL);
    }
  }, [RegimenFiscalQuery]);

  useEffect(()=>{
    if(data && data?.GET_ALL_RIESGOPUESTO){
      setAllRiestoPuesto(data?.GET_ALL_RIESGOPUESTO);
    }
  }, [data]);

  useEffect(()=>{
    if(dataRegistroPatronal && dataRegistroPatronal.GET_REGISTROPATRONAL){
      setRegistroPatronal(dataRegistroPatronal.GET_REGISTROPATRONAL);
    }
  }, [dataRegistroPatronal]);
  
  const initalValues = () =>{  
    return {
      nombre: "",
      razonSocial: "",
      rfc: "",
      isn: "",
      regimenFiscal: "",
      direccion: "",
      estado: "",
      codigoPostal: "",
      logo: "",
      password: "",
      municipio: "",
      CertificatePaswordTimbre: "",
      NoCertificado: "",
      userIMSS: "",
      outsourcer: false,
      legalRepresentative: "",
      notarialTestimony: "",
      notarialTestimonyVolume: "",
      notarialTestimonyDate: "",
      notaryPublicNumber: "",
      notaryPublic: "",
      judicialDistrict: "",
      addressCourt: ""
    }
  }

  const handleClose = () => {
    setArchives(undefined)
    setArchivesIMSS(undefined)
    setArchivesSello(undefined)
    setFileCerTimbre(undefined)
    setFileKeyTimbre(undefined)
    setUno("")
    setDos("")
    setDot(0)
    setRiskPlaced("")
    clearEmpresaModal({}, dispatch)
  } 

  const inputFile = () => {

    if(archives === undefined){
        return <div className={styles.contenedorInternoIconoLogoWithFile}>
            <FileHelperIncident
                name="ArchivoLogoEmpresa"
                parametrofrom="ArchivoLogoEmpresa"
                className="image-file"
                setArchives={setArchives}
                archives={archives}
                accept = ".PNG, .JGP, .png, .jpg"
            />
        </div>
    }else{
        return <div className={styles.contenedorInternoIconoLogo}>
            <FileHelperIncident
                name="ArchivoLogoEmpresa"
                parametrofrom="ArchivoLogoEmpresa"
                className="image-file"
                setArchives={setArchives}
                archives={archives}
                image={RemoveFile}
                accept = ".PNG, .JGP, .png, .jpg"
            />
        </div>
    }

    
  }

  const inputFileIMSS = () => {
  
    if(archivesIMSS === undefined){
      
        return <div >
            <FileHelperIncident
            name="ArchivoIMSSEmpresa"
            parametrofrom="ArchivoIMSSEEmpresa"
            setArchives={setArchivesIMSS}
            archives={archivesIMSS}
            accept = ".key, .KEY"
            styleF="SeleccionarArchivo"
            />
        </div>
    }else{
        return <div>
            <FileHelperIncident
                name="ArchivoIMSSEmpresa"
                parametrofrom="ArchivoIMSSEEmpresa"
                setArchives={setArchivesIMSS}
                archives={archivesIMSS}
                accept = ".key, .KEY"
                styleF="CambiarArchivo"
            />
        </div>
    }

    
  }

  const inputFileSello = () => {
  
    if(archivesSello === undefined){
      
        return <div >
            <FileHelperIncident
            name="ArchivoSelloEmpresa"
            parametrofrom="ArchivoSelloEmpresa"
            setArchives={setArchivesSello}
            archives={archivesSello}
            accept = ".PFX, .pfx"
            styleF="SeleccionarArchivo"
            />
        </div>
    }else{
        return <div>
            <FileHelperIncident
                name="ArchivoSelloEmpresa"
                parametrofrom="ArchivoSelloEmpresa"
                setArchives={setArchivesSello}
                archives={archivesSello}
                accept = ".PFX, .pfx"
                styleF="CambiarArchivo"
            />
        </div>
    }

    
  }

  const inputFileLlave = () => {
  
    if(fileKeyTimbre === undefined){
      
        return <div >
            <FileHelperIncident
            name="ArchivoKeyTimbreEmpresa"
            parametrofrom="ArchivoKeyTimbreEmpresa"
            setArchives={setFileKeyTimbre}
            archives={fileKeyTimbre}
            accept = ".key, .KEY"
            styleF="SeleccionarArchivo"
            />
        </div>
    }else{
        return <div>
            <FileHelperIncident
                name="ArchivoKeyTimbreEmpresa"
                parametrofrom="ArchivoKeyTimbreEmpresa"
                setArchives={setFileKeyTimbre}
                archives={fileKeyTimbre}
                accept = ".key, .KEY"
                styleF="CambiarArchivo"
            />
        </div>
    }

    
  }

  const inputFileCerTimbre = () => {
  
    if(fileCerTimbre === undefined){
      
        return <div >
            <FileHelperIncident
            name="ArchivoCerTimbreEmpresa"
            parametrofrom="ArchivoCerTimbreEmpresa"
            setArchives={setFileCerTimbre}
            archives={fileCerTimbre}
            accept = ".cer, .CER"
            styleF="SeleccionarArchivo"
            />
        </div>
    }else{
        return <div>
            <FileHelperIncident
                name="ArchivoCerTimbreEmpresa"
                parametrofrom="ArchivoCerTimbreEmpresa"
                setArchives={setFileCerTimbre}
                archives={fileCerTimbre}
                accept = ".cer, .CER"
                styleF="CambiarArchivo"
            />
        </div>
    }

    
  }


  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleClickShowPasswordTimbre = () => {
    setShowPasswordTimbre(!showPasswordTimbre);
  };

  const deleteReg = async(idReg: any) => {
    deleteRegistroPatronal({
      variables:{
        id:idReg
      }
    }).then(()=>{
      SuccessfulAlert({
        title: "¡Exito!",
        text: "¡Registro eliminado!",
      });
    })
  }

  const muestraRegistroPatronal = (reg: any, index: number) =>{

    const textRiskPlaced:any = allRiesgoPuesto?.filter((item: any) => item.code === reg.riskPlaced)
    totalRegimen = totalRegimen + 1
    return<div className={styles.lineaRegistro} key={index}>
      <div className={styles.lineanombreRegistro}>{reg.registroPatronal}</div>
      <div className={styles.lineaDivisionRegistro}></div>
      <div className={styles.lineaprimaRiesgo}>{reg.primaRiesgo}</div>
      <div className={styles.lineaDivisionRegistro}></div>
      <div className={styles.lineaprimaRiesgo} style={{width:"90px"}}>{textRiskPlaced[0]?.name}</div>
      <div className={styles.lineaContenedorEliminar} onClick={()=>deleteReg(reg.id)}>
        <div className={styles.iconoLineaPrimaRiesgo}></div>
      </div>
    </div>
  }

  const addRegistroPatronal = ()=>{
    
    const registro = document.querySelector("#uno")
    const riesgo = document.querySelector("#dos")
    const placed = document.querySelector("#demo-simple-select-label")
    const contenedorInputsRegistro = document.querySelector("#contenedorInputsRegistro")
    const conteinerTextAlert = document.querySelector("#conteinerTextAlert")
    const contenedorRegistroPatronal = document.querySelector("#contenedorRegistroPatronal")
    let addReg = 1
    
    if(contenedorInputsRegistro && conteinerTextAlert && contenedorRegistroPatronal){
      if(registro && riesgo && placed){
        if(registro.getAttribute('value') === "" || riesgo.getAttribute('value') === "" || riskPlaced === "" ){
          contenedorRegistroPatronal.className=styles.contenedorRegistroRed

          addReg = 0
        }else{
          if(String(registro.getAttribute('value')).length < 11 ){
            contenedorInputsRegistro.className=styles.contenedorInputsRegistroRed
            const p = document.createElement('p')
            p.textContent = "Regimen patronal 11 caracteres obligatorios."
            p.className=styles.validateText
            p.id="alertComent"
            conteinerTextAlert.appendChild(p)
            addReg = 0
  
          }else{
            const patt = new RegExp(/^[a-zA-Z]{1}$/);
            const patt2 = new RegExp(/^[0-9]{5}$/);
            const patt3 = new RegExp(/^[0-9]{2}$/);
            const patt4 = new RegExp(/^[0-9]{1}$/);
  
            if( !patt.test(String(registro.getAttribute('value')).substring(0,1)) ){
  
              contenedorInputsRegistro.className=styles.contenedorInputsRegistroRed
              const p = document.createElement('p')
              p.textContent = "Verifique registro patronal."
              p.className=styles.validateText
              p.id="alertComent"
              conteinerTextAlert.appendChild(p)
              addReg = 0
  
              
            }else{
              
              if( !patt3.test(String(registro.getAttribute('value')).substring(1,3)) ){
  
                contenedorInputsRegistro.className=styles.contenedorInputsRegistroRed
                const p = document.createElement('p')
                p.textContent = "Verifique registro patronal."
                p.className=styles.validateText
                p.id="alertComent"
                conteinerTextAlert.appendChild(p)
                addReg = 0
    
                
              }else{
                if( !patt2.test(String(registro.getAttribute('value')).substring(3,8)) ){
  
                  contenedorInputsRegistro.className=styles.contenedorInputsRegistroRed
                  const p = document.createElement('p')
                  p.textContent = "Verifique registro patronal."
                  p.className=styles.validateText
                  p.id="alertComent"
                  conteinerTextAlert.appendChild(p)
                  addReg = 0
                }else{
    
    
                  if( !patt3.test(String(registro.getAttribute('value')).substring(8,10)) ){
    
                    contenedorInputsRegistro.className=styles.contenedorInputsRegistroRed
                    const p = document.createElement('p')
                    p.textContent = "Verifique registro patronal."
                    p.className=styles.validateText
                    p.id="alertComent"
                    conteinerTextAlert.appendChild(p)
                    addReg = 0
                  }else{
    
    
    
                    if( !patt4.test(String(registro.getAttribute('value')).substring(10,11)) ){
    
                      contenedorInputsRegistro.className=styles.contenedorInputsRegistroRed
                      const p = document.createElement('p')
                      p.textContent = "Verifique registro patronal."
                      p.className=styles.validateText
                      p.id="alertComent"
                      conteinerTextAlert.appendChild(p)
                      addReg = 0
                    }
    
                  }
                }
              }
              
            }
  
          }
        }
        
        

        if(addReg === 1){
          let riesgoNumber: any
          if(riesgo){
            riesgoNumber = riesgo.getAttribute('value')
          }
          
          createRegistroPatronal({
            variables: {
              input: {
                enterpriseId: parseInt(state._id),
                registroPatronal: registro.getAttribute('value'),
                primaRiesgo: String(parseFloat(riesgoNumber)) ,
                riskPlaced: riskPlaced,
              },
            },
          }).then(()=>{
            setUno("")
            setDos("")
            setDot(0)
            setRiskPlaced("")
            SuccessfulAlert({
              title: "¡Exito!",
              text: "¡Registro agregado!",
          });
          })
        }
        

      }

    }
    
  }

  const deleteStyle = ()=>{
    const contenedorInputsRegistro = document.querySelector("#contenedorInputsRegistro")
    const conteinerTextAlert = document.querySelector("#conteinerTextAlert")
    const contenedorRegistroPatronal = document.querySelector("#contenedorRegistroPatronal")

    if(contenedorInputsRegistro && conteinerTextAlert){
      contenedorInputsRegistro.className=styles.contenedorInputsRegistro
      const p = document.querySelector('#alertComent')
      if(p){
        conteinerTextAlert.removeChild(p)
      }
    }

    if(contenedorRegistroPatronal && conteinerTextAlert){
      contenedorRegistroPatronal.className=styles.contenedorRegistro
      const p = document.querySelector('#alertComent')
      if(p){
        conteinerTextAlert.removeChild(p)
      }
    }
  }


  const upDateTotal = ((total:any)=>{
    const digitNew = total.replace(dos, "")
    
    let totalWith = ""
    if(total !== ""){
      if(total.length > dos.length  ){
        if(dos.length === 0){

          totalWith = total.replace(/\D/g, "")

        }else{
    
          if(String(digitNew) === "."){
            if(dot === 0){
              setDot(1)
              totalWith = total
            }else{
              totalWith = dos+digitNew.replace(/\D/g, "")
            }
          }else{

            if(String(dos) === "0"){
              if(String(digitNew) !== "."){
                totalWith = dos
              }else{
                totalWith = total
              }
            }else{
              totalWith = dos+digitNew.replace(/\D/g, "")
            }
          }
          
        }
      }else{
        if(String(dos.replace(total, "")) === "."){
          setDot(0)

        }
        totalWith = total
      }

    }else{
      setDot(0)

    }
    
    

    setDos(totalWith)
  })
  const updateMunicipios = (value:any)=>{
    setMunicipios( getMunicipios(value) );

  }
  const positionsSelect = ()=>{
    const select = document.getElementById("demo-simple-select-label")
    select?.setAttribute("style", "top: 0px; background-color: #F1F2F5;")
  }

  useEffect(()=>{
    if(state.updateEmpresa){
      getEnterprise({
        variables:{
          getEnterpriseId: state._id
        }
      });
      getRegimen();
      getRiesgo();
      getRegistros({
        variables:{
          enterpriseId: String(state._id) !== ''? parseInt(state._id): 0
        }
      });
    }
  },[state.updateEmpresa]);

  useEffect(()=>{
    if(dataEnterprise && dataEnterprise.GET_ENTERPRISE){
      setDataEnterprise(dataEnterprise.GET_ENTERPRISE);
    }
  },[dataEnterprise]);
  
  const formikRef:any = useRef();
  
  useEffect(()=>{
    if(formikRef.current){
      setMunicipios( getMunicipios(dataEnterprise2.state) );
      formikRef.current.setFieldValue('nombre', dataEnterprise2?.name);
      formikRef.current.setFieldValue('razonSocial', dataEnterprise2?.RazonSocial);
      formikRef.current.setFieldValue('rfc', dataEnterprise2?.taxRegime);
      formikRef.current.setFieldValue('isn', dataEnterprise2?.isn);
      formikRef.current.setFieldValue('regimenFiscal', dataEnterprise2?.propertyRegime);
      formikRef.current.setFieldValue('direccion', dataEnterprise2?.address);
      formikRef.current.setFieldValue('estado', dataEnterprise2?.state);
      formikRef.current.setFieldValue('codigoPostal', dataEnterprise2?.zipCode);
      formikRef.current.setFieldValue('logo', dataEnterprise2?.logo);
      formikRef.current.setFieldValue('password', dataEnterprise2?.CertificatePaswordIMSS);
      formikRef.current.setFieldValue('municipio', dataEnterprise2?.municipio);
      formikRef.current.setFieldValue('CertificatePaswordTimbre', dataEnterprise2?.CertificatePaswordTimbre);
      formikRef.current.setFieldValue('NoCertificado', dataEnterprise2?.NoCertificado);
      formikRef.current.setFieldValue('userIMSS', dataEnterprise2?.userIMSS);
      formikRef.current.setFieldValue('outsourcer', dataEnterprise2?.Outsourcer);
      formikRef.current.setFieldValue('legalRepresentative', dataEnterprise2?.legalRepresentative);
      formikRef.current.setFieldValue('notarialTestimony', dataEnterprise2?.notarialTestimony);
      formikRef.current.setFieldValue('notarialTestimonyVolume', dataEnterprise2?.notarialTestimonyVolume);
      formikRef.current.setFieldValue('notarialTestimonyDate', dataEnterprise2?.notarialTestimonyDate);
      formikRef.current.setFieldValue('notaryPublicNumber', dataEnterprise2?.notaryPublicNumber);
      formikRef.current.setFieldValue('notaryPublic', dataEnterprise2?.notaryPublic);
      formikRef.current.setFieldValue('judicialDistrict', dataEnterprise2?.judicialDistrict);
      formikRef.current.setFieldValue('addressCourt', dataEnterprise2?.addressCourt);
    }
  },[dataEnterprise2])
  

  return(
    <Dialog open={state.updateEmpresa && !loading} aria-labelledby="form-dialog-title" maxWidth={"md"}>
      <Formik
            initialValues={initalValues()}
            innerRef={formikRef}
            enableReinitialize={true}
            validationSchema={Yup.object(validationSchema())}
            onSubmit={formData => {
     
              if(totalRegimen > 0){
                updateCompany
                ({
                    variables: {
                      id:state._id,
                      input: {
                        name: formData.nombre,
                        taxRegime: formData.rfc,
                        isn: formData.isn,
                        propertyRegime: formData.regimenFiscal,
                        state: formData.estado,
                        address: formData.direccion,
                        zipCode: String(formData.codigoPostal),
                        CertificatePaswordIMSS:formData.password,
                        RazonSocial: formData.razonSocial,
                        municipio: formData.municipio,
                        CertificatePaswordTimbre: formData.CertificatePaswordTimbre,
                        NoCertificado: formData.NoCertificado,
                        userIMSS: formData.userIMSS,
                        outsourcers: formData.outsourcer,
                        legalRepresentative: formData.legalRepresentative,
                        notarialTestimony: formData.notarialTestimony,
                        notarialTestimonyVolume: formData.notarialTestimonyVolume,
                        notarialTestimonyDate: moment(formData.notarialTestimonyDate).format(),
                        notaryPublicNumber: formData.notaryPublicNumber,
                        notaryPublic: formData.notaryPublic,
                        judicialDistrict: formData.judicialDistrict,
                        addressCourt: formData.addressCourt
                      },
                    },
                }).then(()=>{
                  
                  if(archivesSello !== undefined || archivesIMSS !== undefined || archives !== undefined || fileKeyTimbre !== undefined || fileCerTimbre !== undefined){
                    insertArchive(parseInt(state._id))
            
                  }else{
                    handleClose();
                    SuccessfulAlert({
                        title: "¡Exito!",
                        text: "¡Registro editado!",
                    });
                  }
                  
                });
              }else{
                ErrorAlert({ text: "Agregue registro patronal." });
              }
              
            }}
        >
          {
            (
              {
                values,
                errors,
                touched,
                handleChange,
                handleSubmit,
              }
            ) => (
              <Form onSubmit={handleSubmit}>
                <div className={styles.contenedor}>
                  <div className={styles.contenedorTitulo}>
                    <div className={styles.titulo}>
                      Editar empresa
                    </div>
                    <div className={styles.iconoCerrarEmpresa} onClick={(()=> handleClose())}></div> 
                  </div>
                  <div className={styles.contenedorFormulario}>
                    <div className={styles.formularioColumnaUno}>
                      <div className={styles.contenedorFieldset}>
                        <TextField
                        fullWidth
                        className={styles.input}
                        name="nombre"
                        label="Nombre de la empresa"
                        variant="outlined"
                        value={values.nombre || ""}
                        onChange={handleChange}
                        error={touched.nombre && Boolean(errors.nombre)}
                        helperText={touched.nombre && errors.nombre}
                        size="small"
                        onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                        inputProps={{ maxLength: 50 }}
                        />
                      </div>
                      <div className={styles.contenedorFieldset}>
                        <TextField
                        fullWidth
                        className={styles.input}
                        name="razonSocial"
                        label="R&aacute;zon social"
                        variant="outlined"
                        value={values.razonSocial || ''}
                        onChange={handleChange}
                        error={touched.razonSocial && Boolean(errors.razonSocial)}
                        helperText={touched.razonSocial && errors.razonSocial}
                        size="small" 
                        onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                        inputProps={{ maxLength: 100 }}
                        />
                      </div>
                      <div className={styles.contenedorFieldset}
                      style={{
                        marginBottom:"18px"
                      }}
                      >
                        <div className={styles.contenedorInputLogo}>
                          <div className={styles.contenedorInternoLogo}>
                            <p className={styles.textInternoLogo}>Cargar imagen de logotipo …*</p>
                          </div>
                          {inputFile()}
                        </div>
                          {dataEnterprise2?.logo !== 'NA' && dataEnterprise2?.logo !== ''? (
                            <div className="flex-child">
                            <span className="span-file">
                              <a
                                className="view-document"
                                target="_blank"
                                href={dataEnterprise2?.logo}
                                rel="noreferrer"
                              >
                                <img
                                  style={{ height: "10px","marginTop":"5px" }}
                                  src={EyeIcon}
                                  alt=""
                                />
                                Ver documento
                              </a>
                            </span>
                          </div>
                          ):(
                            <div></div>
                          )
                        }
                          

                      </div>
                      <div className={styles.contenedorFieldset}>
                        <TextField
                        fullWidth
                        className={styles.input}
                        name="rfc"
                        label="RFC"
                        variant="outlined"
                        value={values.rfc || ''}
                        onChange={handleChange}
                        error={touched.rfc && Boolean(errors.rfc)}
                        helperText={touched.rfc && errors.rfc}
                        size="small" 
                        inputProps={{ maxLength: String(values.regimenFiscal) === '612'? 13: 12 }}
                        onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                        />
                      </div>
{/* 
                      <div className={styles.contenedorFieldset}>
                        <TextField
                        type={'number'}
                        fullWidth
                        className={styles.input}
                        name="isn"
                        label="Porcentaje de ISN(%)*"
                        variant="outlined"
                        value={values.isn}
                        onChange={handleChange}
                        error={touched.isn && Boolean(errors.isn)}
                        helperText={touched.isn && errors.isn}
                        size="small" 
                        inputProps={{ maxLength: 3}}
                        onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                        />
                      </div> */}
                      
                      <div className={styles.contenedorFieldset}
                      style={
                       {
                        marginBottom:"4px"
                       }
                      }
                      >
                        <FormControl 
                            fullWidth 
                            size="small"
                            variant="outlined" 
                            className={styles.dialogFields} 
                            error={touched.regimenFiscal && Boolean(errors.regimenFiscal)}
                        >
                          <InputLabel id="demo-simple-select-label"
                          style={{
                            backgroundColor: "#FFFFFF"
                          }}
                          >Regimen fiscal*</InputLabel>
                          <Select
                              style={{
                                textOverflow: "ellipsis" ,
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                width: "368px",
                              }}
                              labelId="demo-simple-select-label"
                              id="regimenFiscal"
                              name = "regimenFiscal"
                              value={values.regimenFiscal}
                              onChange={handleChange}
                              error={touched.regimenFiscal && Boolean(errors.regimenFiscal)}
                          >
                            {allRegimenFiscal?.map((Regimens:any, index: number) => {
                                return <MenuItem value={Regimens.clave} key={index}>{Regimens.Descripcion}</MenuItem>
                            })}
                          </Select>
                        </FormControl>
                      </div>
                      <div className={styles.contenedorFieldset}>
                        <TextField
                        fullWidth
                        className={styles.input}
                        name="direccion"
                        label="Direcci&oacute;n fiscal"
                        variant="outlined"
                        value={values.direccion}
                        onChange={handleChange}
                        error={touched.direccion && Boolean(errors.direccion)}
                        helperText={touched.direccion && errors.direccion}
                        size="small" 
                        onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                        inputProps={{ maxLength: 100 }}
                        />
                      </div>
                      <div className={styles.contenedorFieldset}
                      style={
                       {
                        marginBottom:"4px"
                       }
                      }
                      >
                        <FormControl 
                            fullWidth 
                            size="small"
                            variant="outlined" 
                            className={styles.dialogFields} 
                            error={touched.estado && Boolean(errors.estado)}
                        >
                          <InputLabel id="demo-simple-select-label"
                          style={{
                            backgroundColor: "#FFFFFF"
                          }}
                          >Estado*</InputLabel>
                          <Select
                              style={{
                                textOverflow: "ellipsis" ,
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                width: "368px",
                              }}
                              labelId="demo-simple-select-label"
                              id="estado"
                              name = "estado"
                              value={values.estado}
                              onChange={(e)=>{
                                updateMunicipios(e.target.value)
                                handleChange(e)
                              }}
                              error={touched.estado && Boolean(errors.estado)}
                          >
                              {states?.map((estadoMX:any, index: number) => {
                                  return <MenuItem value={estadoMX} key={index}>{estadoMX}</MenuItem>
                              })}
                          </Select>
                        </FormControl>
                      </div>
                      <div className={styles.contenedorFieldset}
                      style={
                       {
                        marginBottom:"4px"
                       }
                      }
                      >
                        <FormControl 
                            fullWidth 
                            size="small"
                            variant="outlined" 
                            className={styles.dialogFields} 
                            error={touched.municipio && Boolean(errors.municipio)}
                        >
                          <InputLabel id="demo-simple-select-label"
                          style={{
                            backgroundColor: "#FFFFFF"
                          }}
                          >Municipio*</InputLabel>
                          <Select
                              style={{
                                textOverflow: "ellipsis" ,
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                width: "368px",
                              }}
                              labelId="demo-simple-select-label"
                              id="municipio"
                              name = "municipio"
                              value={values.municipio}
                              onChange={handleChange}
                              error={touched.municipio && Boolean(errors.municipio)}
                          >
                              {municipios?.map((municipioMX:any, index: number) => {
                                  return <MenuItem value={municipioMX} key={index}>{municipioMX}</MenuItem>
                              })}
                          </Select>
                        </FormControl>
                      </div>
                      <div className={styles.contenedorFieldset}>
                        <InputMask
                        mask="99999"
                        value={values.codigoPostal || ''}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        disabled={false}
                        >
                            <TextField
                              className={styles.input}
                              name="codigoPostal"
                              error={touched.codigoPostal && Boolean(errors.codigoPostal)}
                              helperText={touched.codigoPostal && errors.codigoPostal}
                              label="Código postal"
                              variant="outlined"
                              size="small"
                              fullWidth={true}
                              id="codigoPostal"
                              onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                            />
                        </InputMask>



                      </div>
                      <div className={styles.contenedorFieldset}>
                        <TextField
                        fullWidth
                        className={styles.input}
                        name="legalRepresentative"
                        label="Representante legal*"
                        variant="outlined"
                        value={values.legalRepresentative}
                        onChange={handleChange}
                        error={touched.legalRepresentative && Boolean(errors.legalRepresentative)}
                        helperText={touched.legalRepresentative && errors.legalRepresentative}
                        size="small" 
                        onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                        inputProps={{ maxLength: 100 }}
                        />
                      </div>
                      <div className={styles.contenedorFieldset}>
                        <TextField
                        fullWidth
                        className={styles.input}
                        name="notarialTestimony"
                        label="Instrumento notarial*"
                        variant="outlined"
                        value={values.notarialTestimony}
                        onChange={handleChange}
                        error={touched.notarialTestimony && Boolean(errors.notarialTestimony)}
                        helperText={touched.notarialTestimony && errors.notarialTestimony}
                        size="small" 
                        onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                        inputProps={{ maxLength: 100 }}
                        />
                      </div>
                      <div className={styles.contenedorFieldset}>
                        <TextField
                        fullWidth
                        className={styles.input}
                        name="notarialTestimonyVolume"
                        label="Testimonio Notarial Volumen*"
                        variant="outlined"
                        value={values.notarialTestimonyVolume}
                        onChange={handleChange}
                        error={touched.notarialTestimonyVolume && Boolean(errors.notarialTestimonyVolume)}
                        helperText={touched.notarialTestimonyVolume && errors.notarialTestimonyVolume}
                        size="small" 
                        onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                        inputProps={{ maxLength: 100 }}
                        />
                      </div>
                      <div className={styles.contenedorFieldset}>
                        <TextField
                        type="date"
                        fullWidth
                        className={styles.input}
                        name="notarialTestimonyDate"
                        label="Testimonio Notarial Fecha*"
                        variant="outlined"
                        value={
                          values.notarialTestimonyDate && values.notarialTestimonyDate !== '' 
                          ? moment(values.notarialTestimonyDate).format(
                              "YYYY-MM-DD"
                            )
                          : ""
                          }
                        onChange={handleChange}
                        error={touched.notarialTestimonyDate && Boolean(errors.notarialTestimonyDate)}
                        helperText={touched.notarialTestimonyDate && errors.notarialTestimonyDate}
                        size="small" 
                        onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                        inputProps={{ maxLength: 100 }}
                        />
                      </div>
                      <div className={styles.contenedorFieldset}>
                        <TextField
                        fullWidth
                        className={styles.input}
                        name="notaryPublicNumber"
                        label="Notaria Publica Numero*"
                        variant="outlined"
                        value={values.notaryPublicNumber}
                        onChange={handleChange}
                        error={touched.notaryPublicNumber && Boolean(errors.notaryPublicNumber)}
                        helperText={touched.notaryPublicNumber && errors.notaryPublicNumber}
                        size="small" 
                        onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                        inputProps={{ maxLength: 100 }}
                        />
                      </div>
                      <div className={styles.contenedorFieldset}>
                        <TextField
                        fullWidth
                        className={styles.input}
                        name="notaryPublic"
                        label="Notario*"
                        variant="outlined"
                        value={values.notaryPublic}
                        onChange={handleChange}
                        error={touched.notaryPublic && Boolean(errors.notaryPublic)}
                        helperText={touched.notaryPublic && errors.notaryPublic}
                        size="small" 
                        onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                        inputProps={{ maxLength: 100 }}
                        />
                      </div>
                      <div className={styles.contenedorFieldset}>
                        <TextField
                        fullWidth
                        className={styles.input}
                        name="judicialDistrict"
                        label="Distrito Judicial*"
                        variant="outlined"
                        value={values.judicialDistrict}
                        onChange={handleChange}
                        error={touched.judicialDistrict && Boolean(errors.judicialDistrict)}
                        helperText={touched.judicialDistrict && errors.judicialDistrict}
                        size="small" 
                        onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                        inputProps={{ maxLength: 100 }}
                        />
                      </div>
                      <div className={styles.contenedorFieldset}>
                        <TextField
                        fullWidth
                        className={styles.input}
                        name="addressCourt"
                        label="Dirección Tribunal*"
                        variant="outlined"
                        value={values.addressCourt}
                        onChange={handleChange}
                        error={touched.addressCourt && Boolean(errors.addressCourt)}
                        helperText={touched.addressCourt && errors.addressCourt}
                        size="small" 
                        onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                        inputProps={{ maxLength: 100 }}
                        />
                      </div>
                      <div className={styles.contenedorFieldset}>
                        <Checkbox
                          checked={values.outsourcer}
                          value={values.outsourcer}
                          onChange={handleChange}
                          name="outsourcer"
                          color="primary"
                        />

                        <label style={{color:'#093c5d'}} >Outsourcer</label>
                      </div>
                    </div>
                    <div className={styles.formularioColumnaDos}>
                    <div className={styles.tituloColumnaDos}> Certificados SAT</div>
                      <div className={`${styles.contenedorArchivos} ${styles.contenedorArchivosDos}`}>
                        <div>
                        
                          <div className={styles.textoArchivos}>
                            Certificado de sello digital
                          </div>
                        
                          <div className={styles.contenedorDocumento}>
                            {dataEnterprise2?.TimbreCertificate !== 'NA' && dataEnterprise2?.TimbreCertificate !== '' && dataEnterprise2?.TimbreCertificate !== null? (
                              <div className="flex-child">
                                <span className="span-file">
                                  <a
                                    className="view-document"
                                    target="_blank"
                                    href={dataEnterprise2?.TimbreCertificate}
                                    rel="noreferrer"
                                  >
                                    <img
                                      style={{ height: "10px","marginTop":"5px" }}
                                      src={EyeIcon}
                                      alt=""
                                    />
                                    Ver documento
                                  </a>
                                </span>
                              </div>
                              ):(
                                <div></div>
                              )
                            }
                              
                          </div>
                        </div>
                        
                        <div>
                          {inputFileCerTimbre()}
                        </div>
                      </div>
                      <div className={`${styles.contenedorArchivos} ${styles.contenedorArchivosDos}`}>
                        <div>
                        
                          <div className={styles.textoArchivos}>
                          Llave del certificado de sello digital
                          </div>
                        
                          <div className={styles.contenedorDocumento}>
                            {dataEnterprise2?.Timbrekey !== 'NA' && dataEnterprise2?.Timbrekey !== '' && dataEnterprise2?.Timbrekey !== null? (
                              <div className="flex-child">
                                <span className="span-file">
                                  <a
                                    className="view-document"
                                    target="_blank"
                                    href={dataEnterprise2?.Timbrekey}
                                    rel="noreferrer"
                                  >
                                    <img
                                      style={{ height: "10px","marginTop":"5px" }}
                                      src={EyeIcon}
                                      alt=""
                                    />
                                    Ver documento
                                  </a>
                                </span>
                              </div>
                              ):(
                                <div></div>
                              )
                            }
                              
                          </div>
                        </div>
                        
                        <div>
                          {inputFileLlave()}
                        </div>
                      </div>
                      
                      <div className={styles.contenedorContrasena}>
                        <TextField
                        type={showPassword? "text" : "password"} 
                        name="CertificatePaswordTimbre"
                        value={values.CertificatePaswordTimbre || ''}
                        label="Contrase&ntilde;a certificado de sello digital"
                        variant="outlined"
                        size="small"
                        fullWidth={true}
                        onChange={handleChange}
                        className={styles.CertificatePaswordTimbre}
                        error={touched.CertificatePaswordTimbre && Boolean(errors.CertificatePaswordTimbre)}
                        InputProps={{
                          readOnly: false,
                          endAdornment: <div className={styles.iconoVerContrasena} onClick={handleClickShowPassword} ></div>,
                        }}
                        style={{
                          marginLeft: '26px',
                          marginRight: '26px'
                        }}
                        onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                        />
                      </div>
                      <div className={styles.contenedorContrasena}>
                        <InputMask
                          mask="99999999999999999999"
                          value={values.NoCertificado || ''}
                          onChange={handleChange}
                          disabled={false}
                        >
                          <TextField
                            name="NoCertificado"
                            error={
                              touched.NoCertificado && Boolean(errors.NoCertificado)
                            }
                            label="Número certificado"
                            variant="outlined"
                            size="small"
                            fullWidth={true}
                            id="NoCertificado"
                            onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                            style={{
                              marginLeft: '26px',
                              marginRight: '26px'
                            }}
                          />
                        </InputMask>
                      </div>
                      <div className={styles.tituloColumnaDos}> Certificados IMSS</div>
                      <div className={`${styles.contenedorArchivos} ${styles.contenedorArchivosDos}`}>
                        
                        <div>
                          <div className={styles.textoArchivos}>
                            Certificado de sello digital
                          </div>
                        
                          <div className={styles.contenedorDocumento}>
                            {dataEnterprise2?.FIELCertificate !== 'NA' && dataEnterprise2?.FIELCertificate !== ''? (
                              <div className="flex-child">
                                <span className="span-file">
                                  <a
                                    className="view-document"
                                    target="_blank"
                                    href={dataEnterprise2?.FIELCertificate}
                                    rel="noreferrer"
                                  >
                                    <img
                                      style={{ height: "10px","marginTop":"5px" }}
                                      src={EyeIcon}
                                      alt=""
                                    />
                                    Ver documento
                                  </a>
                                </span>
                              </div>
                              ):(
                                <div></div>
                              )
                            }
                              
                          </div>
                        </div>
                        
                        <div>
                          {inputFileSello()}
                        </div>
                      </div>
                      <div className={styles.contenedorArchivos}>
                        <div>
                          <div className={styles.textoArchivos}>
                            Llave del certificado de sello digital
                          </div>
                          <div className={styles.contenedorDocumento}>
                            {dataEnterprise2?.IMSSCertificate !== 'NA' && dataEnterprise2?.IMSSCertificate !== ''? (
                              <div className="flex-child">
                                <span className="span-file">
                                  <a
                                    className="view-document"
                                    target="_blank"
                                    href={dataEnterprise2?.IMSSCertificate}
                                    rel="noreferrer"
                                  >
                                    <img
                                      style={{ height: "10px","marginTop":"5px" }}
                                      src={EyeIcon}
                                      alt=""
                                    />
                                    Ver documento
                                  </a>
                                </span>
                              </div>
                              ):(
                                <div></div>
                              )
                            }
                            
                          </div>
                        </div>
                        {inputFileIMSS()}
                      </div>
                      <div className={styles.contenedorContrasena}>
                        <TextField
                        type="text"
                        name="userIMSS"
                        value={values.userIMSS || ''}
                        label="Usuario IMSS*"
                        variant="outlined"
                        size="small"
                        fullWidth={true}
                        onChange={handleChange}
                        error={touched.userIMSS && Boolean(errors.userIMSS)}
                        style={{
                          marginLeft: '26px',
                          marginRight: '26px'
                        }}
                        onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                        />
                      </div>
                    
                      <div className={styles.contenedorContrasena}>
                        <TextField
                        type={showPasswordTimbre? "text" : "password"} 
                        name="password"
                        value={values.password || ''}
                        label="Contrase&ntilde;a certificado de sello digital*"
                        variant="outlined"
                        size="small"
                        fullWidth={true}
                        onChange={handleChange}
                        className={styles.password}
                        error={touched.password && Boolean(errors.password)}
                        InputProps={{
                          readOnly: false,
                          endAdornment: <div className={styles.iconoVerContrasena} onClick={handleClickShowPasswordTimbre} ></div>,
                        }}
                        style={{
                          marginLeft: '26px',
                          marginRight: '26px'
                        }}
                        onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                        />
                      </div>
                      
                    
                      
                      <div
                        style={{
                          marginBottom:"10px"
                        }}
                      >
                        <div className={styles.contenedorRegistro} id="contenedorRegistroPatronal">
                          
                          <div className={styles.contenedorInputsRegistro} id="contenedorInputsRegistro">
                            <div>
                              <input 
                                type="text" 
                                placeholder="Registro patronal"
                                name="uno"
                                id="uno"
                                value={uno}
                                onChange={((e) =>{
                                  setUno(e.target.value)
                                  deleteStyle()
                                } )}
                                className={styles.inputRegistroPatronal}
                                onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                maxLength={11}
                                />
                            </div>
                            <div className={styles.lineaInputs}></div>
                            <div>
                              <input 
                                type="text" 
                                placeholder="Prima de riesgo"
                                name="dos"
                                id="dos"
                                value={dos}
                                onChange={((e) =>{
                                  upDateTotal(e.target.value)
                                  deleteStyle()
                                } )}
                                className={styles.inputPrimaRiesgo}
                                onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                />
                            </div>
                            
                            
                          </div>
                          <div className={styles.contenedorInputsRegistro2} >
                            <div className={styles.inputRegistroPatronalRiesgoPuesto}>
                              <FormControl 
                                  fullWidth 
                                  size="small"
                                  variant="outlined" 
                              >
                                <InputLabel id="demo-simple-select-label"
                                  style={{
                                    backgroundColor: "#F1F2F5",
                                    fontSize: "13px",
                                    position: "absolute",
                                    top: "-3px",
                                  }}
                                >Riesgo puesto*</InputLabel>
                                <Select
                                  style={{
                                    textOverflow: "ellipsis" ,
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    width: "160px",
                                    height: "27px",
                                    
                                  }}
                                      
                                  labelId="demo-simple-select-label"
                                  id="estado"
                                  name = "estado"
                                  value = {riskPlaced}
                                  onChange={((e)=>{
                                    positionsSelect()
                                    setRiskPlaced(e.target.value)
                                    deleteStyle()
                                  })}
                                  
                                >
                                    {allRiesgoPuesto?.map((RiesgoPuesto:any, index: number) => {
                                        return <MenuItem value={RiesgoPuesto?.code} key={index}>{RiesgoPuesto?.name}</MenuItem>
                                    })}
                                </Select>
                              </FormControl>
                            </div> 
                            <div className={styles.inputRegistroPatronalRiesgoPuesto}> 
                              <button 
                                className={styles.botonAgregarRegistroPatronal}
                                onClick={((e)=>{
                                  e.preventDefault()
                                  deleteStyle()
                                  addRegistroPatronal()
                                }
                                )}
                                >
                                Agregar
                              </button>
                            </div>
                          </div>

                        </div>
                        
                        <div id="conteinerTextAlert">
                        </div>
                      </div>
                    
                      <div className={styles.contenedorRegistros}>
                        <div>
                            {
                              
                              registroPatronal?.map((reg:any, index: number)=>(
                              
                              muestraRegistroPatronal(reg, index)
                              
                            ))}
                            
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.contenedorBotones}>
                    <button 
                    className={styles.botonCancelar}
                    onClick={() => handleClose()}
                    type="button"
                    >
                      Cancelar
                    </button>
                    <button 
                      className={styles.botonGuardar}
                      type="submit"
                      >
                      <div className={styles.iconoGuardar}></div>
                      Guardar
                    </button>
                  </div>
                </div>
              </Form>
            )
          }
          
        </Formik>
        
        
    </Dialog>
  )
}

const containsOnlyLetters = (value: any) => {
  return /^[a-zA-Z ]+$/.test(value);
};

const validationSchema = () =>{
  return{
    nombre: Yup.string().required("Obligatorio").max(100,'El tamaño maximo es 100 caracteres'),
    razonSocial: Yup.string().required("Obligatorio").max(100, 'El tamaño maximo es 100 caracteres'),
    rfc: Yup.string().required("Obligatorio"),
    isn: Yup.number().min(0, "Debe ser mayor a 0").max(100, "Debe ser menor a 100").required("Obligatorio"),
    regimenFiscal: Yup.string().required("Obligatorio"),
    direccion: Yup.string().required("Obligatorio").max(100,'El tamaño maximo es 100 caracteres'),
    estado: Yup.string().required("Obligatorio"),
    codigoPostal: Yup.string().required("Obligatorio").max(5, 'El tamaño maximo es 5 caracteres'),
    password: Yup.string().required("Obligatorio"),
    CertificatePaswordTimbre: Yup.string().required("Obligatorio"),
    NoCertificado: Yup.string().required("Obligatorio"),
    userIMSS: Yup.string().required("Obligatorio"),
    legalRepresentative: Yup.string().required("Obligatorio"),
    notarialTestimony: Yup.string().required("Obligatorio").test(
      "containsOnlyLetters",
      "Ingresar texto únicamente",
      containsOnlyLetters
    ),
    notarialTestimonyVolume: Yup.string().required("Obligatorio").test(
      "containsOnlyLetters",
      "Ingresar texto únicamente",
      containsOnlyLetters
    ),
    notaryPublicNumber: Yup.string().required("Obligatorio"),
    notaryPublic: Yup.string().required("Obligatorio"),
    judicialDistrict: Yup.string().required("Obligatorio"),
    addressCourt: Yup.string().required("Obligatorio"),
  }
}

export default EditaEmpresa 