import { useQuery } from "@apollo/client";
import { useEffect, useState, useReducer, useCallback } from "react";
import { TabPuestoContext } from "../../context/TabPuestosContext/TabPuestosContext";
import { useToggle } from "../../hooks/useToggle";
import { GET_ALL_PUESTOS_USERS_PROJECT, GET_USER_AVAILABLE } from "../../Querys/querys";
import ImagenColaborador2 from "./ImagenColaborador2";
import styles from './Organigrama.module.css'
import CheckPermission from "../../helpers/Administradores/Permissions";
import { tabPuestoReducer } from "../../context/TabPuestosContext/tabPuestoReducer";
import CrearPuestoModal from "./Modals/CrearPuestoModal";
import MenuListOrganigrama from "./Modals/MenuListOrganigrama";
import InfoPuesto from "./Modals/InfoPuesto";
import EditaPuesto2 from "./Modals/EditaPuesto2";
import EliminaPuesto from "./Modals/EliminaPuesto";
import { useHistory } from "react-router-dom";

const ListaOrganigrama4 = () =>{
    const history = useHistory();
    const {data: resultPuestos} = useQuery(GET_ALL_PUESTOS_USERS_PROJECT);
    const [totalPuestos, setTotalPuestos] = useState<any>([]);
    const [totalPuestosSinFiltro, setTotalPuestosNumSinFiltro] = useState<any>([]);
    const [allWorkAreas, setAllWorkAreas] = useState<any>([]);
    const [totalVacantes, setTotalVacantes] = useState([]);
    const [totalVacantesNotFilter, setTotalVacantesNotFilter] = useState<any>([]);
    const [inactiveOpen, setInactiveOpen] = useToggle(false);
    const [addPuestoOpen, setAddPuestoOpen] = useToggle(false);
    const [PuestoState, PuestoDispatch,] = useReducer(tabPuestoReducer, { loading: true, Puestos: [], PuestosFilter: [] });
    const {data:resultPuestosDisponibles} = useQuery(GET_USER_AVAILABLE, {
    variables: { puesto: 0 },
    });
    const disponibles = resultPuestosDisponibles?.GET_USER_AVAILABLE;
    const [save, setSave] = useState(true);
    const [searchHistory, setSearchHistory] = useState<any[]>([]);
    const obtenerDatos = useCallback(async (allPuestos): Promise<void> => {
        let workAreas: any[] = []
        for (const a of allPuestos){
            if(a?.projectId){
                const area = {
                    idArea: a?.projectId,
                    nameArea: a?.AreaProyecto,
                    colorCodeProject: a?.colorCodeProject
                }
                workAreas.push(area)
            }
        }
  
        const workAreasNotRepeated = workAreas.reduce((acc: any, current: any) => {
            const x = acc.find((item: any) => item.idArea === current.idArea && item.nameArea === current.nameArea);
            if (!x) {
                return acc.concat([current]);
            } else {
                return acc;
            }
        }, []);

        setAllWorkAreas(workAreasNotRepeated)
        
        setTotalPuestos(allPuestos)
        setTotalPuestosNumSinFiltro(allPuestos)
        setTotalVacantesNotFilter(allPuestos)

        const allVacantes = allPuestos.filter((puesto:any) => puesto.users.length === 0)
        setTotalVacantes(allVacantes)
    },[]);

    useEffect(()=>{
        if(resultPuestos && resultPuestos.GET_ALL_PUESTOS_USERS_PROJECT){
            obtenerDatos(resultPuestos.GET_ALL_PUESTOS_USERS_PROJECT)
        }
    },[resultPuestos, obtenerDatos])

    const getLowerPosition = (lowerPosition: any) =>{
        
        let lowWorkAreas: any[] = []
        let position: any[] = []

        for (const a of lowerPosition){
            if(a?.projectId){
                const area = {
                    idArea: a?.projectId,
                    nameArea: a?.AreaProyecto,
                    colorCodeProject: a?.colorCodeProject
                }
                lowWorkAreas.push(area)
            }
            
            const p = totalVacantesNotFilter.filter((item:any)=>item?.idPuesto === a.id)
            position.push(
                p[0]
            )
        }

        const lowWorkAreasNotRepeated = lowWorkAreas.reduce((acc: any, current: any) => {
            const x = acc.find((item: any) => item?.idArea === current?.idArea && item?.nameArea === current?.nameArea);
            if (!x) {
                return acc.concat([current]);
            } else {
                return acc;
            }
        }, []);

        const positionNotRepeated = position.reduce((acc: any, current: any) => {
            const x = acc.find((item: any) => item?.NombrePuesto === current?.NombrePuesto);
            if (!x) {
                return acc.concat([current]);
            } else {
                return acc;
            }
        }, []);
        
        
        return (
            <div className={styles.containderSubPosition}>
                {lowWorkAreasNotRepeated?.map((item:any)=>(
                    <>
                        <div className={styles.titleSubArea}
                        style={{backgroundColor: item?.colorCodeProject}}
                        >
                            <div className={styles.divTitleSubAreaText}>{item?.nameArea}</div>
                        </div>
                        <div className={styles.divtitleSubPosition}>
                            {
                                positionNotRepeated?.map((p:any)=>(
                                    <>
                                        {
                                            p?.projectId === item?.idArea  && (
                                                <>
                                                    <div className={styles.titleSubArea}
                                                    onClick={() => {
                                                        setSubordinates(p?.idPuesto, '0')
                                                    }}
                                                    style={{
                                                        cursor:'pointer',
                                                        backgroundColor: item?.colorCodeProject
                                                    }}

                                                    >
                                                        <div className={styles.divTitleSubAreaText}>{p?.NombrePuesto}</div>
                                                    </div>
                                                    {
                                                        p?.users?.length > 0 && (
                                                            p?.users?.map((usr:any)=>(
                                                                <>
                                                                    <div className={styles.divJobTitle} >
                                                                        <div className={styles.divSubConteinerImgJobTitle}>
                                                                            <div className={styles.divSubImgJobTitle}>
                                                                                {usr?.ImagenUsuario === undefined || usr?.ImagenUsuario === '' || usr?.ImagenUsuario ? (
                                                                                <ImagenColaborador2
                                                                                Nombre={usr?.NombreUsuario}
                                                                                Apellido={usr?.ApellidoUsuario}
                                                                                />
                                                                                ) : (
                                                                                    <img
                                                                                    src={usr?.ImagenUsuario}
                                                                                    alt="img"
                                                                                    className="imgCollaborator__BITSss"
                                                                                    />
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                        <div className={styles.containerCollaboratorName}>
                                                                            <div className={styles.divCollaboratorTitle}>
                                                                                <span className={styles.textCollaboratorTitle}>Nombre</span>
                                                                            </div>
                                                                            <div className={styles.divCollaboratorData}
                                                                            onClick={() => {
                                                                                setSubordinates(p?.idPuesto, usr?.idUsuario)
                                                                            }}
                                                                            style={{cursor: 'pointer'}}
                                                                            >
                                                                                <span className={styles.textCollaboratorData}>{usr?.NombreUsuario}</span>
                                                                            </div>
                                                                        </div>
                                                                        <div className={styles.containerNumberCollaborator}>
                                                                            <div className={styles.divCollaboratorTitle}>
                                                                                <span className={styles.textCollaboratorTitle}>Personas a su cargo</span>
                                                                            </div>
                                                                            <div className={styles.divCollaboratorData}>
                                                                                <span className={styles.textCollaboratorData}>{p?.subordinados}</span>
                                                                            </div>
                                                                        </div>
                                                                        <div className={styles.containerNumberCollaborator}>
                                                                            <div className={styles.divCollaboratorTitle}>
                                                                                <span className={styles.textCollaboratorTitle}>Puestos a su cargo</span>
                                                                            </div>
                                                                            <div className={styles.divCollaboratorData}>
                                                                                <span className={styles.textCollaboratorData}>{p?.subPuestos}</span>
                                                                            </div>
                                                                        </div>
                                                                        <div className={styles.containerSubCollaboratorName}>
                                                                            <div className={styles.divCollaboratorTitle}>
                                                                                <span className={styles.textCollaboratorTitle}>Correo</span>
                                                                            </div>
                                                                            <div className={styles.divCollaboratorData}>
                                                                                <span className={styles.textCollaboratorData}>{usr?.Usuario}</span>
                                                                            </div>
                                                                        </div>
                                                                        <div className={styles.containerSubCollaboratorName}>
                                                                            <div className={styles.divCollaboratorTitle}>
                                                                                <span className={styles.textCollaboratorTitle}>Teléfono</span>
                                                                            </div>
                                                                            <div className={styles.divCollaboratorData}>
                                                                                <span className={styles.textCollaboratorData}>{usr?.cellphone}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            ))
                                                        )
                                                    }
                                                </>
                                            )
                                        }
                                    </>
                                ))
                            }
                        </div>
                    </>
                ))}
            </div>

        )
        


    }

    const datosFiltrados = (e: any) => {
        
        if (e !== "") {
            let expresion = new RegExp(`${e}.*`, "i");
            const nuevoFiltrado : any[] = [];
            
            for (const item of totalPuestosSinFiltro){
                const aux = item?.users?.filter((usr: any) => expresion.test(usr?.NombreUsuario) );
                
                if(expresion.test(item?.AreaProyecto) || expresion.test(item?.NombrePuesto)){
                    const nuevo = {
                        AreaProyecto: item?.AreaProyecto,
                        Descripcion: item?.Descripcion,
                        ImagenUsuario: item?.ImagenUsuario,
                        NombrePuesto: item?.NombrePuesto,
                        NombreUsuario: item?.NombreUsuario,
                        PuestoSuperior: item?.PuestoSuperior,
                        higherPostId: item?.higherPostId,
                        idPuesto: item?.idPuesto,
                        idUsuario: item?.idUsuario,
                        level: item?.level,
                        lowerPosition: item?.lowerPosition,
                        projectId: item?.projectId,
                        subPuestos: item?.subPuestos,
                        subordinados: item?.subordinados,
                        users: item?.users,
                        colorCode: item?.colorCode,
                        colorCodeProject: item?.colorCodeProject,
                    }
                    nuevoFiltrado.push(nuevo)
                }
                if(aux?.length > 0 ){
                    const nuevo = {
                        AreaProyecto: item?.AreaProyecto,
                        Descripcion: item?.Descripcion,
                        ImagenUsuario: item?.ImagenUsuario,
                        NombrePuesto: item?.NombrePuesto,
                        NombreUsuario: item?.NombreUsuario,
                        PuestoSuperior: item?.PuestoSuperior,
                        higherPostId: item?.higherPostId,
                        idPuesto: item?.idPuesto,
                        idUsuario: item?.idUsuario,
                        level: item?.level,
                        lowerPosition: item?.lowerPosition,
                        projectId: item?.projectId,
                        subPuestos: item?.subPuestos,
                        subordinados: item?.subordinados,
                        users: aux,
                        colorCode: item?.colorCode,
                        colorCodeProject: item?.colorCodeProject,
                    }
                    nuevoFiltrado.push(nuevo)
                }
                
            }

            let workAreas: any[] = []

            if(nuevoFiltrado){
                for (const a of nuevoFiltrado){
                    if(a?.projectId){
                        const area = {
                            idArea: a?.projectId,
                            nameArea: a?.AreaProyecto,
                            colorCodeProject: a?.colorCodeProject
                        }
                        workAreas.push(area)
                    }
                    
                }
                const workAreasNotRepeated = workAreas.reduce((acc: any, current: any) => {
                    const x = acc.find((item: any) => item.idArea === current.idArea && item.nameArea === current.nameArea);
                    if (!x) {
                        return acc.concat([current]);
                    } else {
                        return acc;
                    }
                }, []);

                const nuevoFiltradoSinRepetir = nuevoFiltrado.reduce((acc: any, current: any) => {
                    const x = acc.find((item: any) => item.NombrePuesto === current.NombrePuesto && item.idPuesto === current.idPuesto);
                    if (!x) {
                        return acc.concat([current]);
                    } else {
                        return acc;
                    }
                }, []);
                
                setAllWorkAreas(workAreasNotRepeated)
        
                setTotalPuestos(nuevoFiltradoSinRepetir)
        


            }else{
                setAllWorkAreas([])
        
                setTotalPuestos([])
            }



        } else {
            obtenerDatos(totalPuestosSinFiltro);
        }
    }
    
    const getDisplay = (idElement: any, idOptions: any) =>{

        const e = document.getElementById(idElement)
        const o = document.getElementById(idOptions)

        if(e?.getAttribute('style') === 'display: block;'){
            o?.classList.remove(styles.imgLowerPositionOpen)
            o?.classList.add(styles.imgLowerPosition)
            e?.setAttribute('style', 'display: none;')
        } else{
            o?.classList.remove(styles.imgLowerPosition)
            o?.classList.add(styles.imgLowerPositionOpen)
            e?.setAttribute('style', 'display: block;')
        }
    }

    const getDisplayArea = (area: any) =>{

        const e = document.getElementById(area)
        if(e?.getAttribute('style') !== 'display: block;' && e?.getAttribute('style') !== 'display: none;'){
            e?.setAttribute('style', 'display: block;')
        }

        if(e?.getAttribute('style') === 'display: block;'){
            e?.setAttribute('style', 'display: none;')
        } else{
            e?.setAttribute('style', 'display: block;')
        }
    }

    const setSubordinates = (idPuesto: any, idCollaborator: any) => {

        let nuevoFiltrado : any[] = [];
        let history: any = {
            idPuesto: idPuesto,
            idCollaborator: idCollaborator,
            orden: searchHistory.length
        }
        let searc : any[] = []; 
        searc = searchHistory
        searc.push(history)
        setSearchHistory(searc)

        if (!idCollaborator || idCollaborator === '0') {
            nuevoFiltrado = totalVacantesNotFilter.filter((item: any) => item?.idPuesto === idPuesto);
        }else{
            for (const item of totalVacantesNotFilter.filter((item: any) => item?.idPuesto === idPuesto)){
                
                const aux = item?.users?.filter((usr: any) => usr?.idUsuario === idCollaborator );
                
                if(aux?.length > 0 ){
                    const nuevo = {
                        AreaProyecto: item?.AreaProyecto,
                        Descripcion: item?.Descripcion,
                        ImagenUsuario: item?.ImagenUsuario,
                        NombrePuesto: item?.NombrePuesto,
                        NombreUsuario: item?.NombreUsuario,
                        PuestoSuperior: item?.PuestoSuperior,
                        higherPostId: item?.higherPostId,
                        idPuesto: item?.idPuesto,
                        idUsuario: item?.idUsuario,
                        level: item?.level,
                        lowerPosition: item?.lowerPosition,
                        projectId: item?.projectId,
                        subPuestos: item?.subPuestos,
                        subordinados: item?.subordinados,
                        users: aux,
                        colorCode: item?.colorCode,
                        colorCodeProject: item?.colorCodeProject,
                    }
                    nuevoFiltrado.push(nuevo)
                }
                
            }
        }

        let workAreas: any[] = []

        if(nuevoFiltrado){
            for (const a of nuevoFiltrado){
                if(a?.projectId){
                    const area = {
                        idArea: a?.projectId,
                        nameArea: a?.AreaProyecto,
                        colorCodeProject: a?.colorCodeProject
                    }
                    workAreas.push(area)
                }
                
            }
            const workAreasNotRepeated = workAreas.reduce((acc: any, current: any) => {
                const x = acc.find((item: any) => item.idArea === current.idArea && item.nameArea === current.nameArea);
                if (!x) {
                    return acc.concat([current]);
                } else {
                    return acc;
                }
            }, []);

            const nuevoFiltradoSinRepetir = nuevoFiltrado.reduce((acc: any, current: any) => {
                const x = acc.find((item: any) => item.NombrePuesto === current.NombrePuesto && item.idPuesto === current.idPuesto);
                if (!x) {
                    return acc.concat([current]);
                } else {
                    return acc;
                }
            }, []);
            
            setAllWorkAreas(workAreasNotRepeated)
    
            setTotalPuestos(nuevoFiltradoSinRepetir)
            

        }else{
            setAllWorkAreas([])
    
            setTotalPuestos([])
        }

    }

    const setSubordinatesWithoutHistory = (idPuesto: any, idCollaborator: any) => {
        
        let nuevoFiltrado : any[] = [];

        if (!idCollaborator || idCollaborator === '0') {
            nuevoFiltrado = totalVacantesNotFilter.filter((item: any) => item?.idPuesto === idPuesto);
        }else{
            for (const item of totalVacantesNotFilter.filter((item: any) => item?.idPuesto === idPuesto)){
                
                const aux = item?.users?.filter((usr: any) => usr?.idUsuario === idCollaborator );
                
                if(aux?.length > 0 ){
                    const nuevo = {
                        AreaProyecto: item?.AreaProyecto,
                        Descripcion: item?.Descripcion,
                        ImagenUsuario: item?.ImagenUsuario,
                        NombrePuesto: item?.NombrePuesto,
                        NombreUsuario: item?.NombreUsuario,
                        PuestoSuperior: item?.PuestoSuperior,
                        higherPostId: item?.higherPostId,
                        idPuesto: item?.idPuesto,
                        idUsuario: item?.idUsuario,
                        level: item?.level,
                        lowerPosition: item?.lowerPosition,
                        projectId: item?.projectId,
                        subPuestos: item?.subPuestos,
                        subordinados: item?.subordinados,
                        users: aux,
                        colorCode: item?.colorCode,
                        colorCodeProject: item?.colorCodeProject,
                    }
                    nuevoFiltrado.push(nuevo)
                }
                
            }
        }

        let workAreas: any[] = []

        if(nuevoFiltrado){
            for (const a of nuevoFiltrado){
                if(a?.projectId){
                    const area = {
                        idArea: a?.projectId,
                        nameArea: a?.AreaProyecto,
                        colorCodeProject: a?.colorCodeProject
                    }
                    workAreas.push(area)
                }
                
            }
            const workAreasNotRepeated = workAreas.reduce((acc: any, current: any) => {
                const x = acc.find((item: any) => item.idArea === current.idArea && item.nameArea === current.nameArea);
                if (!x) {
                    return acc.concat([current]);
                } else {
                    return acc;
                }
            }, []);

            const nuevoFiltradoSinRepetir = nuevoFiltrado.reduce((acc: any, current: any) => {
                const x = acc.find((item: any) => item.NombrePuesto === current.NombrePuesto && item.idPuesto === current.idPuesto);
                if (!x) {
                    return acc.concat([current]);
                } else {
                    return acc;
                }
            }, []);
            
            setAllWorkAreas(workAreasNotRepeated)
    
            setTotalPuestos(nuevoFiltradoSinRepetir)
            

        }else{
            setAllWorkAreas([])
    
            setTotalPuestos([])
        }

    }

    const filterAvailableJobs = () => {
        obtenerDatos(totalVacantes)
    }

    const getBack = () => {

        if(searchHistory.length > 1){
            const idPuesto = searchHistory[searchHistory.length - 2].idPuesto
            const searc: any = searchHistory.slice(0, searchHistory.length - 1)
            setSearchHistory(searc)
            setSubordinatesWithoutHistory(idPuesto, '0')
        }else{
            history.push(`/organigrama`)
        }
    }

    return(
        <>
            <div className={styles.pp_contenedor}>
                <div className={styles.pp_contenedorBoton2}>
                    <div className={styles.containerScreenTitle}>
                        Organización
                    </div>
                    <TabPuestoContext.Provider value={{
                        inactiveOpen,
                        setInactiveOpen,
                        addPuestoOpen,
                        setAddPuestoOpen,
                        PuestoState,
                        PuestoDispatch
                    }}>
                    {CheckPermission("Organigrama.CrearPuesto") && (
                    <button onClick={setAddPuestoOpen} className="botonAgregaPuesto"
                    style={
                        {
                            marginLeft: 'auto',
                            marginRight: '0px',
                        }
                    }
                    >
                        <div className='alineacionBoton'>
                        <img src='/assets/icons/icono-crear-puesto.svg' alt="" 
                        style={{width: '25px', height: '25px'}}
                        />
                        <div className="nombrePuesto">
                            <span>Crear puesto</span>
                        </div>
                        <div className="nombrePuesto"></div>
                        </div>
                    </button>       
                    )}     
                    <CrearPuestoModal disponibles={disponibles} setSave={setSave} save={save}/>
                    </TabPuestoContext.Provider>
                </div>
            </div>   
            <div className={styles.conteinerOrganizationWhite} >
                
                <div className={styles.contenedorTitles}>  
                    <div className={styles.pc_contenedorBuscador}
                    style={{
                        marginTop: "0px",
                        marginLeft: "17.53px",
                        height: "32px",
                    }}
                    >
                        <input
                        type="text"
                        placeholder="Buscar por nombre / área / puesto"
                        className={styles.pc_inputBuscador}
                        style={{
                            height: "32px",
                        }}
                        onChange={(e) => datosFiltrados(e.target.value)}
                        ></input>
                        <div className={styles.pc_iconoBuscador}></div>
                    </div>
                    <div className={styles.contenedorTotales}>
                        <div className={styles.contenedorLista} >
                            <div className={styles.textContenedorLista}
                            style={{
                                cursor: 'pointer',
                            }}
                            onClick={()=>obtenerDatos(resultPuestos?.GET_ALL_PUESTOS_USERS_PROJECT)}
                            >
                                <b>Número de puestos:</b>{resultPuestos?.GET_ALL_PUESTOS_USERS_PROJECT?.length ?? 0}
                            </div>
                            
                        </div>
                        <div className={styles.contenedorLista} >
                            <div className={styles.textContenedorLista} 
                            style={{
                                cursor: 'pointer',
                            }}
                            onClick={()=>filterAvailableJobs()}
                            >
                                <b>Número de vacantes:</b> {totalVacantes?.length}
                            </div>
                            
                        </div>
                    </div>
                </div>
                {
                    searchHistory?.length > 0? 
                        <div className={styles.pd_barraEstado} >
                            <div
                            className={styles.pd_contenedorRegreso}
                            onClick={()=>getBack()}
                            >
                                <div className={styles.pd_botonRegreso}></div>
                                <div>Regresar puesto anterior</div>
                            </div>
                        </div>
                    :<></>
                }
                
                <div className={styles.conteinerOrganization}>
                    {allWorkAreas?.map((item:any)=>(
                        <>
                            <div className={styles.titleArea}
                            onClick={()=>getDisplayArea("areaDetail_"+item?.idArea)}
                            style={{
                                cursor: 'pointer',
                                backgroundColor: item.colorCodeProject,
                            }}
                            >
                                <span className={styles.titleAreaText}>{item?.nameArea}</span>
                            </div>
                            <div id={"areaDetail_"+item?.idArea}>
                                {totalPuestos?.map((lis:any)=>(
                                    lis?.projectId === item?.idArea && (
                                        <>
                                            {
                                                lis?.users.length > 0 ? (
                                                    lis.users.map((usr:any)=>(
                                                        <>
                                                            <div className={styles.divJobTitle}>
                                                                
                                                                <MenuListOrganigrama
                                                                    {...{
                                                                    _id: lis.idPuesto,
                                                                    NombrePuesto: lis.NombrePuesto,
                                                                    AreaProyecto: lis.AreaProyecto,
                                                                    PuestoSuperior: lis.PuestoSuperior,
                                                                    Descripcion: lis.Descripcion,
                                                                    NombrePuestoSuperior: lis.NombrePuestoSuperior,
                                                                    EliminaOrganigrama: lis?.subPuestos,
                                                                    EliminaOrganigramaUsers: lis?.users.length,
                                                                    higherPostId : lis?.higherPostId,
                                                                    projectId: lis?.projectId,
                                                                    colorCode: item.colorCodeProject,
                                                                    }}
                                                                />
                                                                <div className={styles.divConteinerImgJobTitle}>
                                                                    <div className={styles.divImgJobTitle}>
                                                                        {usr?.ImagenUsuario === undefined || usr?.ImagenUsuario === '' || usr?.ImagenUsuario === null ? (
                                                                        <ImagenColaborador2
                                                                        Nombre={usr?.NombreUsuario}
                                                                        Apellido={usr?.ApellidoUsuario}
                                                                        />
                                                                        ) : (
                                                                            
                                                                            <div className="circulo_secundario colorWhite_secundario"
                                                                            style={{
                                                                                width: '73.55px',
                                                                                height: '73.55px'
                                                                            }}
                                                                            >
                                                                                <img
                                                                                style={{
                                                                                    width: '67.78px',
                                                                                    height: '67.78px'
                                                                                }}
                                                                                src={usr?.ImagenUsuario}
                                                                                alt="img"
                                                                                className="imgCollaborator__BITSss"
                                                                                />
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                                <div className={styles.containerCollaboratorName}>
                                                                    <div className={styles.divCollaboratorTitle}>
                                                                        <span className={styles.textCollaboratorTitle}>Nombre</span>
                                                                    </div>
                                                                    <div className={styles.divCollaboratorData}>
                                                                        <span className={styles.textCollaboratorData}>{usr?.NombreUsuario}</span>
                                                                    </div>
                                                                </div>
                                                                <div className={styles.containerNumberCollaborator}>
                                                                    <div className={styles.divCollaboratorTitle}>
                                                                        <span className={styles.textCollaboratorTitle}>Personas a su cargo</span>
                                                                    </div>
                                                                    <div className={styles.divCollaboratorData}>
                                                                        <span className={styles.textCollaboratorData}>{lis?.subordinados}</span>
                                                                    </div>
                                                                </div>
                                                                <div className={styles.containerNumberCollaborator}>
                                                                    <div className={styles.divCollaboratorTitle}>
                                                                        <span className={styles.textCollaboratorTitle}>Puestos a su cargo</span>
                                                                    </div>
                                                                    <div className={styles.divCollaboratorData}>
                                                                        <span className={styles.textCollaboratorData}>{lis?.subPuestos}</span>
                                                                    </div>
                                                                </div>
                                                                <div className={styles.containerCollaboratorName}>
                                                                    <div className={styles.divCollaboratorTitle}>
                                                                        <span className={styles.textCollaboratorTitle}>Correo</span>
                                                                    </div>
                                                                    <div className={styles.divCollaboratorData}>
                                                                        <span className={styles.textCollaboratorData}>{usr?.Usuario}</span>
                                                                    </div>
                                                                </div>
                                                                <div className={styles.containerCollaboratorName}>
                                                                    <div className={styles.divCollaboratorTitle}>
                                                                        <span className={styles.textCollaboratorTitle}>Teléfono</span>
                                                                    </div>
                                                                    <div className={styles.divCollaboratorData}>
                                                                        <span className={styles.textCollaboratorData}>{usr?.cellphone}</span>
                                                                    </div>
                                                                </div>

                                                                <div className={styles.containerLowerPosition} >
                                                                    <div className={styles.imgLowerPosition}
                                                                    id={"optionsDetail_"+lis?.idPuesto+usr?.idUsuario}
                                                                    onClick={() => getDisplay("containerDetail_"+lis?.idPuesto+usr?.idUsuario, "optionsDetail_"+lis?.idPuesto+usr?.idUsuario)}
                                                                    ></div>
                                                                </div>

                                                            </div>

                                                            <div id={"containerDetail_"+lis?.idPuesto+usr?.idUsuario} 
                                                            style={{display: "none"}}
                                                            >
                                                                {
                                                                    <>
                                                                        {getLowerPosition(lis?.lowerPosition)}
                                                                    </>
                                                                }
                                                            </div>                                                            
                                                        </>
                                                    ))
                                                ):(
                                                    <>
                                                        <div className={styles.divJobTitle}>
                                                            <MenuListOrganigrama
                                                                {...{
                                                                _id: lis.idPuesto,
                                                                NombrePuesto: lis.NombrePuesto,
                                                                AreaProyecto: lis.AreaProyecto,
                                                                PuestoSuperior: lis.PuestoSuperior,
                                                                Descripcion: lis.Descripcion,
                                                                NombrePuestoSuperior: lis.NombrePuestoSuperior,
                                                                EliminaOrganigrama: lis?.subPuestos,
                                                                EliminaOrganigramaUsers: lis?.users.length,
                                                                higherPostId : lis?.higherPostId,
                                                                projectId: lis?.projectId,
                                                                colorCode: item.colorCodeProject,
                                                                }}
                                                            />
                                                            <div className={styles.divConteinerImgJobTitle}>
                                                                <div className={styles.divImgJobTitle}>
                                                                    <ImagenColaborador2
                                                                    Nombre=""
                                                                    Apellido=""
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className={styles.containerCollaboratorName}>
                                                                <div className={styles.divCollaboratorTitle}>
                                                                    <span className={styles.textCollaboratorTitle}>Nombre</span>
                                                                </div>
                                                                <div className={styles.divCollaboratorData}>
                                                                    <span className={styles.textCollaboratorData}></span>
                                                                </div>
                                                            </div>
                                                            <div className={styles.containerNumberCollaborator}>
                                                                <div className={styles.divCollaboratorTitle}>
                                                                    <span className={styles.textCollaboratorTitle}>Personas a su cargo</span>
                                                                </div>
                                                                <div className={styles.divCollaboratorData}>
                                                                    <span className={styles.textCollaboratorData}>{lis?.subordinados}</span>
                                                                </div>
                                                            </div>
                                                            <div className={styles.containerNumberCollaborator}>
                                                                <div className={styles.divCollaboratorTitle}>
                                                                    <span className={styles.textCollaboratorTitle}>Puestos a su cargo</span>
                                                                </div>
                                                                <div className={styles.divCollaboratorData}>
                                                                    <span className={styles.textCollaboratorData}>{lis?.subPuestos}</span>
                                                                </div>
                                                            </div>
                                                            <div className={styles.containerCollaboratorName}>
                                                                <div className={styles.divCollaboratorTitle}>
                                                                    <span className={styles.textCollaboratorTitle}>Correo</span>
                                                                </div>
                                                                <div className={styles.divCollaboratorData}>
                                                                    <span className={styles.textCollaboratorData}></span>
                                                                </div>
                                                            </div>
                                                            <div className={styles.containerCollaboratorName}>
                                                                <div className={styles.divCollaboratorTitle}>
                                                                    <span className={styles.textCollaboratorTitle}>Teléfono</span>
                                                                </div>
                                                                <div className={styles.divCollaboratorData}>
                                                                    <span className={styles.textCollaboratorData}></span>
                                                                </div>
                                                            </div>
                                                            <div className={styles.containerLowerPosition} >
                                                                <div className={styles.imgLowerPosition}
                                                                id={"optionsDetail_"+lis?.idPuesto}
                                                                onClick={() => getDisplay("containerDetail_"+lis?.idPuesto, "optionsDetail_"+lis?.idPuesto)}
                                                                ></div>
                                                            </div>
                                                        </div>

                                                        <div id={"containerDetail_"+lis?.idPuesto} 
                                                        style={{display: "none"}}
                                                        >
                                                            {
                                                                <>
                                                                    {getLowerPosition(lis?.lowerPosition)}
                                                                </>
                                                            }
                                                        </div>                                            

                                                        
                                                    </>
                                                )
                                            }
                                        </>
                                    )
                                ))
                                }
                            </div>
                        </>
                        
                    ))}
                </div>
                
            </div>
            <InfoPuesto />
            <EditaPuesto2  disponibles={disponibles} setSave={setSave} save={save}/>
            <EliminaPuesto />
                
        </>
    )
}

export default ListaOrganigrama4