import React, { useState, useContext } from "react";
import {
  Tooltip,
  IconButton,
  Menu,
  MenuItem,
  Grid,
} from "@material-ui/core";
import Box from '@mui/material/Box';
import style from "./TableOutsources.module.css";
import {
  GET_ALL_OUTSOURCERS,
  DELETE_OUTSOURCERS,
  GET_OUTSOURCER,
} from "../../../Querys/querys";
import Modal from "../../Portal/Modal";
import ModalEditOutsourcers from "../ModalEditOutsourcers/ModalEditOutsourcers";

import MoreVertIcon from "@material-ui/icons/MoreVert";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { useMutation } from "@apollo/client";
import { useQuery } from "@apollo/client";
import { SuccessfulAlert } from "../../../alerts/successAlerts";
import OutsourcersContext from '../../../context/Outsourcers/OutsourcersContext'
import {openEditOutsourcerModal, openDelteOutsourcerModal} from '../../../context/Outsourcers/Actions'

function OutsourcesCard({ id, logo, nombre, razonSocial }: any) {
  const { state, dispatch } = useContext(OutsourcersContext);
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const [active, setActive] = useState(false);
  const open = Boolean(anchorEl);


  return (
    <Box className={style.boxDataSourcers}>
      <Box className={style.boxLogoName}>
        {logo ? 
          <img
          src={logo}
          style={{
            width: "68px",
            height: "48px",
            borderRadius: "15%",
          }}
          /> :
          <></>
        }
        
        <Box m={1} className={style.boxText} 
        title={nombre}
        >{nombre}</Box>
      </Box>
      <Box className={style.boxRazonSocial}>
        <Box className={style.boxText} title={razonSocial} >{razonSocial}</Box>
      </Box>
    </Box>
  );
}

export default OutsourcesCard;
