import { ChangeEvent, useState } from "react";
import Box from '@mui/material/Box';
import { Grid } from "semantic-ui-react";
import CustomTabs from "../components/Collaborators/Tab/CustomTabs";
import CustomTab from "../components/Collaborators/Tab/CustomTabMain";
import CentralPagosTab from "../components/CentralPagos/CentralPagosTab";
import HistoricoCentralPagosTab from "../components/CentralPagos/HistoricoCentralPagosTab";


const CentralPagosScreen = () =>{
  const [tab, setTab] = useState(0);
  const handleChange = (event: ChangeEvent<{}>, newValue: number) => {
    setTab(newValue);
    localStorage.setItem("currentPill", String(newValue));
  };
  return(
    <>
    <div>
      <Box mt={3} ml={5} className="Title">
        Central de pagos
      </Box>
      <Box p={5} pb={3} pt={0}>
        <Grid container justify="flex-start">
          <CustomTabs
            value={tab}
            onChange={handleChange}
            aria-label="simple tabs example"
          >
            <CustomTab label="Transferencias" value={0} />
            <CustomTab label="Históricas" value={1} />
          </CustomTabs>
        </Grid>
      </Box>
      <div className="contenedor2">
        {tab === 0 && <CentralPagosTab value={tab} index={0} />}
        {tab === 1 && <HistoricoCentralPagosTab value={tab} index={1} />}
      </div>
    </div>
    </>
  )
}


export default CentralPagosScreen;
