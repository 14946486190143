import { useEffect, useState, useContext } from "react";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import { Grid, TextField, InputLabel, FormControl } from "@material-ui/core";
import { Button as ButtonReact } from "semantic-ui-react";
import { Select } from "@material-ui/core";
import { useParams } from "react-router-dom";
import RadioGroup from "@mui/material/RadioGroup";
import moment from "moment";
import { useQuery } from "@apollo/client";
import {
  GET_ALL_ENTERPRISE,
  GET_ALL_OUTSOURCERS,
  GET_ALL_PAYROLL_GROUP
} from "../../../../Querys/querys";
import CollaboratorContext from "../../../../context/CollaboratorContext/CollaboratorContext";
import { formatterMxn } from "../../../../helpers/formatoMoneda";
import { InputAdornment } from "@mui/material";
import AcceptInfoModal from "./Modals/AcceptInfoModal";
import RejectInfoModal from "./Modals/RejectInfoModal";

const HiringDataColaboradores = (props: any) => {
  const { data: resultCompany } = useQuery(GET_ALL_ENTERPRISE);
  const allCompanies = resultCompany?.GET_ALL_ENTERPRISE;
  const { data: resultOutsources } = useQuery(GET_ALL_OUTSOURCERS);
  const allOutsources = resultOutsources?.GET_ALL_OUTSOURCERS;
  const [companies, setCompanies] = useState([]);
  const [registros_patronales, setRegistrosPatronales] = useState([]);

  const { data: resultPayRollGroup } = useQuery(GET_ALL_PAYROLL_GROUP);
  const allPayRollGroups = resultPayRollGroup?.GET_ALL_PAYROLL_GROUP;

  const [tipoContratacion, setTipoContratacion] = useState("Directa");
  const [tipoEsquema, setTipoEsquema] = useState("Nomina");
  const [typeContract, setTypeContract] = useState("indefinida");
  const [employerRegister, setEmployerRegister] = useState("");
  const [idEnterprise, setIdEnterprise] = useState("");
  const [grossSalary, setGrossSalary] = useState("");
  const { state } = useContext(CollaboratorContext);
  const disabled = false;
  const [outsources, setOutsources] = useState([]);
  const [isOpenAcceptInfo, setIsOpenAcceptInfo] = useState<boolean>(false);
  const [isOpenRejectInfo, setIsOpenRejectInfo] = useState<boolean>(false);

  const params = useParams<any>();

  const handleCloseAcceptInfoModal = () => setIsOpenAcceptInfo(false);
  const handleOpenAcceptInfo = async () => setIsOpenAcceptInfo(true);

  const handleCloseRejectInfoModal = () => setIsOpenRejectInfo(false);
  const handleOpenRejectInfo = async () => setIsOpenRejectInfo(true);

  useEffect(() => {
    if (state.collaborator) {
      setTipoContratacion(state.collaborator?.tittle);
      setTipoEsquema(state.collaborator?.nominesPayroll);
      setTypeContract(state.collaborator?.typeContract);
      setEmployerRegister(state.collaborator?.EmployerRegister);
      setIdEnterprise(state.collaborator?.idEnterprise);

      let grossSalaryRes = "";
      if (state.collaborator?.typeSalary === "Bruto") {
        let x = handleSetFormat(state.collaborator?.grossSalary);
        if (x) grossSalaryRes = x;
      } else if (state.collaborator?.typeSalary === "Neto") {
        let x = handleSetFormat(state.collaborator?.netSalary);
        if (x) grossSalaryRes = x;
      }
      
      if (grossSalaryRes) {
        setGrossSalary(grossSalaryRes);
      }
    }

    if (allCompanies) {
      setCompanies(allCompanies);

      // if (state.collaborator?.tittle == "Directa") {
      for( const company of allCompanies){
        if (String(company.id) === String(state.collaborator?.idEnterprise)) {
          if (company.registro_patronal) {
            setRegistrosPatronales(company.registro_patronal);
          }
        } else {
          setRegistrosPatronales(allCompanies[0].registro_patronal);
        }
      };
    }
    if (allOutsources) {
      setOutsources(allOutsources);
    }
  }, [allCompanies, allPayRollGroups, allOutsources, state.collaborator]);

  useEffect(() => {
    //get tag body
    const body = document.getElementsByTagName("body")[0];
    //set overflow-y as important
    body.style.setProperty("overflow-y", "scroll", "important");
  }, []);

  const handleSetFormat = (value: any) => {
    
    if (typeof value === "string") {
      let valueFormatted = value.replace(/,/g, "");

      valueFormatted = parseFloat(valueFormatted).toFixed(2);
      let x = formatterMxn(valueFormatted);
      if (x === "nan") return false;
      x = x.substring(1);
      return x;
    } else {
      let x = formatterMxn(value);
      if (x === "nan") return false;
      x = x.substring(1);
      return x;
    }
  };

  return (
    <div id="overflowID">
      <AcceptInfoModal
        isOpen={isOpenAcceptInfo}
        handleClose={handleCloseAcceptInfoModal}
        collaborator={state.collaborator}
        token={params.token}
      />
      <RejectInfoModal
        isOpen={isOpenRejectInfo}
        handleClose={handleCloseRejectInfoModal}
        collaborator={state.collaborator}
      />

      <div className="novalidate__border">
        <Grid
          direction="row"
          container
          spacing={1}
          //   style={{ border: "1px solid red" }}
        >
          <Grid xs item>
            {tipoContratacion === "Directa" ? (
              <>
                <Grid spacing={3} direction="row" container>
                  <Grid xs={6} item>
                    <FormControl
                      variant="outlined"
                      fullWidth={true}
                      size="small"
                    >
                      <InputLabel htmlFor="outlined-age-native-simple">Empresa</InputLabel>
                      <Select
                      label={"Empresa"}
                        native
                        name="idEnterprise"
                        defaultValue={idEnterprise || ""}
                        autoFocus={true}
                        disabled={true}
                        style={{ height: "42px" }}
                      >
                        {companies?.map(({ name, id }: any, index: number) => (
                          <>
                            {String(idEnterprise) === String(id) ? (
                              <option key={index} value={id} selected>
                                {name}
                              </option>
                            ) : (
                              <option key={index} value={id}>
                                {name}
                              </option>
                            )}
                          </>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid xs={6} item>
                    <FormControl
                      variant="outlined"
                      fullWidth={true}
                      size="small"
                    >
                      <InputLabel htmlFor="outlined-age-native-simple">Registro patronal</InputLabel>

                      <Select
                        native
                        name="EmployerRegister"
                        // defaultValue={idEnterprise || ""}
                        autoFocus={true}
                        disabled={true}
                        style={{ height: "42px" }}
                        variant="outlined"
                        label={"Registro patronal"}
                      >
                        <option selected disabled>
                          Seleccione un registro patronal
                        </option>
                        {registros_patronales?.map(
                          ({ registroPatronal }: any, index: number) => (
                            <>
                              {String(employerRegister) === String(registroPatronal) ? (
                                <option
                                  key={index}
                                  value={registroPatronal}
                                  selected
                                >
                                  {registroPatronal}
                                </option>
                              ) : (
                                <option key={index} value={registroPatronal}>
                                  {registroPatronal}
                                </option>
                              )}
                            </>
                          )
                        )}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </>
            ) : (
              <Grid spacing={3} direction="row" container>
                <Grid xs={5} item>
                  <FormControl variant="outlined" fullWidth={true} size="small">
                    <InputLabel htmlFor="outlined-age-native-simple">
                      Empresa
                    </InputLabel>
                    <Select
                      native
                      name="idEnterprise"
                      defaultValue={idEnterprise || ""}
                      autoFocus={true}
                      disabled={true}
                      style={{ height: "42px" }}
                      variant="outlined"
                      label="Empresa"
                    >
                      <option selected disabled>
                          Seleccione un outsourcer
                        </option>
                      {outsources?.map(({ nombre, id }: any, index: number) => (
                        <>
                          {String(idEnterprise) === String(id) ? (
                            <option key={index} value={id} selected>
                              {nombre}
                            </option>
                          ) : (
                            <option key={index} value={id}>
                              {nombre}
                            </option>
                          )}
                        </>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid
                  spacing={3}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "20px",
                  }}
                ></Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </div>
      <div
        className={
          state.sections[2] >= 100 ? `validate__border` : `novalidate__border`
        }
      >
        <span>Vigencia del contrato</span>
        <Grid
          direction="row"
          container
          spacing={1}
          //   style={{ border: "1px solid red" }}
        >
          <Grid xs item>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="indefinida"
                name="typeContract"
                style={{
                  marginLeft: '6px',
                  marginTop: '5px'
                }}
              >
                {typeContract === "indefinida" && (
                  <FormControlLabel
                    control={
                      <Radio size="small" style={{ color: "#fabb00" }} />
                    }
                    label="Indefinida"
                    value="indefinida"
                    name="typeContract"
                    checked={typeContract === "indefinida" ? true : false}
                    disabled={true}
                  />
                )}
                {typeContract === "temporal" && (
                  <FormControlLabel
                    control={
                      <Radio size="small" style={{ color: "#fabb00" }} />
                    }
                    label="Temporal"
                    value="temporal"
                    name="typeContract"
                    checked={typeContract === "temporal" ? true : false}
                    disabled={true}
                  />
                )}
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
        {typeContract === "temporal" ? (
          <>
            <span>Fecha de vigencia del contrato</span>
            <TextField
              id="outlined-basic"
              variant="outlined"
              type="date"
              size="small"
              style={{ width: "100%" }}
              name="dateContractDate"
              defaultValue={
                state.collaborator
                  ? moment(state.collaborator.dateContractDate).format(
                      "YYYY-MM-DD"
                    )
                  : ""
              }
              InputProps={{
                inputProps: {
                  min: moment(state.collaborator.dateOfAdmission)
                    .add(1, "day")
                    .format("YYYY-MM-DD"),
                },
              }}
              disabled={true}
            />
          </>
        ) : (
          <></>
        )}
      </div>
      <div
        className={
          state.sections[2] >= 100 ? `validate__border` : `novalidate__border`
        }
      >
        <span>Tipo de esquema</span>
        <Grid
          direction="row"
          container
          spacing={1}
          //   style={{ border: "1px solid red" }}
        >
          <Grid xs item>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="Nomina"
                name="radio-buttons-group"
                style={{
                  marginLeft: '6px',
                  marginTop: '5px'
                }}
              >
                {tipoEsquema === "Nomina" && (
                  <FormControlLabel
                    value="Nomina"
                    control={
                      <Radio size="small" style={{ color: "#fabb00" }} />
                    }
                    label="100% Nómina"
                    checked={tipoEsquema === "Nomina" ? true : false}
                    name="nominesPayroll"
                    disabled={true}
                  />
                )}
                {tipoEsquema === "Mixto" && (
                  <FormControlLabel
                    value="Mixto"
                    control={
                      <Radio size="small" style={{ color: "#fabb00" }} />
                    }
                    label="Mixto"
                    checked={tipoEsquema === "Mixto" ? true : false}
                    name="nominesPayroll"
                    disabled={true}
                  />
                )}
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
        {/* 100% nomina */}

        {tipoEsquema === "Nomina" ? (
          <>
            <FormLabel style={{ marginBottom: "4px", fontWeight: "bold" }}>
              Ingreso Mensual
            </FormLabel>
            <Grid
              direction="row"
              container
              spacing={3}
              style={{ marginTop: "4px" }}
            >
              <Grid item xs={4}>
                <FormControl>
                  <TextField
                    style={{
                      width: "100%",
                      display: "inline-block",
                      opacity: 0.5,
                    }}
                    label="Ingreso mensual"
                    id="outlined-basic"
                    variant="outlined"
                    type="text"
                    size="small"
                    value={state.collaborator?.grossSalary}
                    name="grossSalary"
                    InputProps={{
                      readOnly: true,
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </>
        ) : (
          // Esquema Mixto
          <>
            <FormLabel style={{ marginBottom: "4px", fontWeight: "bold" }}>
              Ingreso Mensual
            </FormLabel>
            <Grid
              direction="row"
              container
              spacing={3}
              style={{
                display: "flex",
                marginTop: "2px",
              }}
            >
              <Grid item xs={4}>
                <FormControl style={{ marginBottom: "4px", opacity: 0.5 }}>
                  <TextField
                    style={{ width: "100%" }}
                    required
                    label="Ingreso mensual"
                    id="outlined-basic"
                    variant="outlined"
                    type="text"
                    size="small"
                    name="grossSalary"
                    InputProps={{
                      readOnly: true,
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                    defaultValue={grossSalary ?? 0}
                    value={grossSalary}
                    onKeyPress={(event: any) => {
                      if (!/^[0-9.]+$/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </>
        )}
      </div>
      <div className="novalidate__border">
        <Grid
          direction="row"
          container
          spacing={2}
          style={{
            // border: "1px solid red",
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <TextField
            id="outlined-basic"
            variant="outlined"
            type="text"
            size="small"
            label="Periodicidad de pago"
            defaultValue="Quincenal"
            style={{ background: "#f1f2f5" }}
            name="periodicidadDePago"
            disabled={true}
          />
        </Grid>
      </div>

      {(state.collaborator?.accept_info === null || state.collaborator?.accept_info === false)  && (
        <Grid>
          <Grid item>
            <strong>
              {" "}
              <h4 className="text-accept-information">
                ¿Confirmas que toda tu información es correcta?
              </h4>{" "}
            </strong>
          </Grid>
          <Grid item xs={12}>
            <ButtonReact
              variant="contained"
              className="btn-reject-information"
              onClick={() => {
                handleOpenRejectInfo();
              }}
              disabled={disabled}
            >
              NO, mi información no es correcta
            </ButtonReact>
          </Grid>
          <Grid item xs={12}>
            <ButtonReact
              style={{ marginTop: "10px" }}
              variant="contained"
              className="btn-accept-information"
              onClick={() => {
                handleOpenAcceptInfo();
              }}
              disabled={disabled}
            >
              SÍ, mi información esta correcta
            </ButtonReact>
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default HiringDataColaboradores;
