import { useContext } from "react";
import { createDocumentsModal } from "../../context/DocumentContext/Actions";
import DocumentsContext from "../../context/DocumentContext/DocumentsContext";
import styles from "./Documents.module.css";
import { CreateDocuments } from "./Modals/createDocuments";
import { UpdateDocuments } from "./Modals/updateDocuments";
import { DeleteDocuments } from "./Modals/deleteDocuments";
import { useQuery } from "@apollo/client";
import { GET_ALL_FILES } from "../../Querys/querys";
import { Documents } from "./Documents";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Box from '@mui/material/Box';
interface ITabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

export const AllDocuments = (props: ITabPanelProps) => {
  const { state, dispatch } = useContext(DocumentsContext);
  const colName = ["Nombre", "Tipo", "Peso máximo", "Obligatorio", "Opciones"];
  const createModal = (id: string, createModal: boolean) => {
    createDocumentsModal({ id, createModal }, dispatch);
  };
  const resultDocument = useQuery(GET_ALL_FILES);
  const allDocument = resultDocument.data?.GET_ALL_FILES;
  const activos = allDocument?.map((doct: any) => doct.status);
  const result = [activos?.filter((status: any) => status.length === 6)];
  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="flex-end"
      style={{ width: "100%" }}
    >
      <div className={styles.contenedorPrincipal}>
        <div className={styles.flexDos}>
          <div className={styles.textoTitulo}>
            Configuración de documentos / Expediente
          </div>
          <div>
            <button
              className={`${styles.botonAgregar} ${styles.flex}`}
              onClick={() => createModal("1", true)}
            >
              <span className={styles.textoBoton}>
                {" "}
                + Agregar nuevo documento
              </span>
            </button>
          </div>
        </div>
        <TableContainer className={styles.contenedorTabla}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead className={styles.contenedorTituloTabla}>
              <TableRow>
                {colName.map((col, index: number) =>
                  String(col) !== "Opciones" ? (
                    <TableCell className={styles.columnTablaText} align="left"
                    key={index}
                    >
                      <span>{col}</span>
                    </TableCell>
                  ) : (
                    <TableCell className={styles.columnTablaText} align="right"
                    key={index}
                    >
                      <span>{col}</span>
                    </TableCell>
                  )
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {result?.length >= 1 ? (
                allDocument?.map((doct: any, index: number) => {
                  return (
                    doct.status === "Activo" && (
                      <Documents
                        id={doct.id}
                        name={doct.name}
                        documentType={doct.documentType}
                        maxWeight={doct.maxWeight}
                        obligatory={doct.obligatory}
                        key={index}
                      />
                    )
                  );
                })
              ) : (
                <div
                  id="soyoyyooooo"
                  className={`${styles.filaContenidoTabla2} ${styles.flex}`}
                >
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="flex-end"
                    style={{ width: "100%" }}
                  ></Box>
                </div>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        {state.updateModal ? <UpdateDocuments /> : null}
        {state.deleteModal ? <DeleteDocuments /> : null}
        {state.createModal ? <CreateDocuments /> : null}
      </div>
    </Box>
  );
};
