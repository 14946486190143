import { useEffect } from "react";
import { Route, useParams } from "react-router-dom";

import PoliticState from "../../context/ConfigPayrollContext/PoliticState";
import ModSalarioTable from "./ModSalarioTable";

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
};

const IDSETab = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;
    const params = useParams<any>();
    useEffect(() => {
    }, [params]);
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            {...other}
        >
            {value === index && (
                <PoliticState>
                    <Route
                        component={() => (
                        <ModSalarioTable {...props} />
                        )}
                    />
                </PoliticState>
            )}
        </div>
    )
}

export default IDSETab;
