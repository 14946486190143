import {
  Theme,
  Dialog,
  withStyles,
  Select,
  TextField,
} from "@material-ui/core";
import "../Modal/CrearPayrollGroup.css";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import { useEffect, useState, useContext } from "react";
import { SuccessfulAlert } from "../../../alerts/successAlerts";
import DeduccionContext from "../../../context/ConfigPayrollContext/DeduccionContext";
import { closeDeduccionModal } from "../../Team/Modals/ModalDeduccionModal";
import { updateDeduccion } from "../../../helpers/Nomina/Deducciones";
import { ErrorAlert } from "../../../alerts/errorAlert";
import "../PayrollGroup.css";
import { useMutation } from "@apollo/client";
import { GET_ALL_DEDUCTIONS, UPDATE_DEDUCTIONS, GET_DEDUCTIONS_CALENDAR } from "../../../Querys/querys";
import IOSSwitch from "../../ConfigPayroll/Switch";
import SaveIcon from "@material-ui/icons/Save";

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const EditaDeduccion = (props: any) => {
  const [updateDeductions] = useMutation(UPDATE_DEDUCTIONS, {
    refetchQueries: [
                      { query: GET_ALL_DEDUCTIONS },
                      {query: GET_DEDUCTIONS_CALENDAR}
                    ],
  });

  const { state, dispatch } = useContext(DeduccionContext);
  const [ISRTax, setISRTax] = useState(false);
  const [classification, setClassification] = useState('Dinero');
  const [muestraCalendarioSave, setmuestraCalendarioSave] = useState(false);

  useEffect(() => {
    async function 
    fetchData() {
      setISRTax(state.ISRTax);
    }
    fetchData();
    return () => {
      fetchData();
    };
  }, [state._id, state.ISRTax]);


  useEffect(() => {
    if(state.time && !state.isMoney){
      setClassification('Tiempo')
    }else{
      setClassification('Dinero')
    }
  }, [state.time, state.isMoney])

  const onChange = async (e: any) => {
    
    let nuevoValor = e.target.value;
    if(e.target.name !== 'Classification'){
      await updateDeduccion(e, state, dispatch, e.target.name, nuevoValor);
    }else{
      setClassification(nuevoValor)
    }
  };

  const handleClose = async () => {
    await closeDeduccionModal(dispatch);
  };

  const onSumbit = async () => {
    
    if (state.ConceptName !== "" && state.SATKey !== "") {
      const idint = Number(state._id);

      updateDeductions({
        variables: {
          updateDeductionsId: idint,
          input: {
            concept_type: state.ConceptName,
            SATKey: state.SATKey,
            ISRTax: ISRTax,
            TaxBoth: false,
            classification: classification,
            muestraCalendario: muestraCalendarioSave,
          },
        },
      }).then((res) => {
        SuccessfulAlert({
          title: "¡Éxito!",
          text: "¡Se ha actualizado la deducción correctamente!",
        });
        closeDeduccionModal(dispatch);
        dispatch({ type: "CLOSE_PERCEPTION_MODAL" });
      });
      
    } else {
      return ErrorAlert({ text: "Ingresa todos los campos" });
    }
  };

  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={state.showEdit}
        fullWidth={false}
        maxWidth={"md"}
      >
        <div className="contenedorModalPerceptions">
          <p className="tituloModalPerceptions">Edita tu deducción</p>
        </div>
        <DialogContent className="ml32ModalPerceptions mr32ModalPerceptions">
          <div className="flexModalPerceptions mb24ModalPerceptions">
            <div>
              <span className="textoModal">Nombre</span>
            </div>
            <div>
              <TextField
                name="ConceptName"
                error={false}
                type="text"
                variant="outlined"
                size="small"
                className="inputModalPerceptions"
                placeholder=""
                defaultValue={state.ConceptName}
                onChange={(e) => onChange(e)}
              />
            </div>
          </div>
          <div className="flexModalPerceptions mb24ModalPerceptions">
            <div>
              <span className="textoModal">Clave SAT</span>
            </div>
            <div>
              <TextField
                error={false}
                type="text"
                variant="outlined"
                size="small"
                className="inputModalPerceptions"
                placeholder=""
                defaultValue={state.SATKey}
                disabled
                onChange={(e) => onChange(e)}
              />
            </div>
          </div>
          <div className="flexModalPerceptions mb24ModalPerceptions">
            <div>
              <span className="textoModal">Grava ISR</span>
            </div>
            <div className="mr10ModalPerceptions">
              <IOSSwitch
                NombreCampo={"ISRTax"}
                Value={state.ISRTax}
                Type="deduction"
                setISRTax={setISRTax}
              />
            </div>
          </div>
          <div className="flexModalPerceptions mb24ModalPerceptions">
            <div>
              <span className="textoModal">Clasificación</span>
            </div>
            <div
            style={{
              width: "205px",
            }}
            >
              <Select
              variant="outlined"
              native
                style={{
                  width: "100%",
                }}
                id="Classification"
                name = "Classification"
                value={classification}
                onChange={(e) => onChange(e)}
              >
                <option value="Dinero">Dinero</option>
                <option value="Tiempo">Tiempo</option>
              </Select>
            </div>
          </div>
          <div className="flexModalPerceptions mb24ModalPerceptions">
            <div>
              <span className="textoModal">Muestra Calendario</span>
            </div>
            <div className="mr10ModalPerceptions">
              <IOSSwitch 
              NombreCampo={'muestraCalendario'}
              Value={state.muestraCalendario}
              Type="deduction"
              setmuestraCalendario={setmuestraCalendarioSave}
              /> 
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <div className="contenedorBotonesPoliticsModal">
            <button
              autoFocus
              onClick={handleClose}
              className="botonbotonCancelar"
            >
              Cancelar
            </button>
            {String(state.SATKey) === '004' ?
            <button
              autoFocus
              onClick={onSumbit}
              className="botonbotonGuardar botonDoble"
            >
              <div className="contenedorIconoBotonModal">
                <SaveIcon fontSize="small" />
              </div>
              <div>Guardar</div>
            </button>
            : null}
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default EditaDeduccion;
