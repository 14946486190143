import { ChangeEvent, useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { Grid } from '@material-ui/core';

import PayrollGroupTab from '../components/ConfigPayroll/PayrollGroupTab';
import PoliticsTab from '../components/ConfigPayroll/PoliticsTab';
import ConceptsTab from '../components/ConfigPayroll/ConceptsTab';
import TablesValueTab from '../components/ConfigPayroll/TablesValueTab';

import CustomTabs from '../components/Collaborators/Tab/CustomTabs';
import CustomTab from '../components/Collaborators/Tab/CustomTabMain';
import '../components/ConfigPayroll/ConfigPayroll.css';
import CheckPermission from '../helpers/Administradores/Permissions';
import PayrollEventualGroupTab from '../components/ConfigPayroll/PayrollEventualGroupTab';


const ConfigPayrollScreenBase = () => {
    const [tab, setTab] = useState(0);

    const handleChange = (event: ChangeEvent<{}>, newValue: number) => {
        setTab(newValue)
        localStorage.setItem('currentPill', String(newValue));
    };

    useEffect(() => {
        let pill = localStorage.getItem('currentPill');
        if (pill) setTab(Number(pill));
        else setTab(0);
    }, [tab]);

    return (
        <div>
            <Box mt={3} ml={5} className="Title">
                Configuraciones
            </Box>
            <Box p={5} pb={3} pt={0}>

            <Grid
                    container
                    justify="flex-start"
                >
                    <CustomTabs
                        value={tab}
                        onChange={handleChange}
                        aria-label="simple tabs example"
                    > 
                    {CheckPermission("ConfiguracionNominas.Nomina") && ( 
                        <CustomTab label="Nómina" value={0}/>
                    )}
                    {CheckPermission("ConfiguracionNominas.Polticas") && ( 
                        <CustomTab label="Políticas" value={1}/>
                        )}    
                    {CheckPermission("ConfiguracionNominas.Conceptos") && (   
                        <CustomTab label="Conceptos" value={2}/>
                        )}               
                    {CheckPermission("ConfiguracionNominas.TablasValores") && (   
                        <CustomTab label="Tablas y valores" value={3}/>
                        )}   
                        <CustomTab label="Nóminas eventuales" value={4}/>            
                    </CustomTabs>
                </Grid>

            </Box>

            <div className="contenedor2">
                {tab === 0 && <PayrollGroupTab value={tab} index={0} />}

                {tab === 1 && <PoliticsTab value={tab} index={1} />}

                {tab === 2 && <ConceptsTab value={tab} index={2} />}

                {tab === 3 && <TablesValueTab value={tab} index={3} />}

                {tab === 4 && <PayrollEventualGroupTab value={tab} index={4} />}
            </div>
        </div>
    )
};
export default ConfigPayrollScreenBase;
