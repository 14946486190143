import styles from "../../styles/IDSE/IDSE.module.css"
import {useQuery, useMutation} from "@apollo/client"
import { GET_MODIFICACIONSALARIO,CREATE_HISTORICOSALARIO, GET_MOVIMIENTOSIDSE } from "../../Querys/querys";
import { useState,useEffect } from "react";
import {formatter} from "../../helpers/formatoMoneda"
import { SuccessfulAlert } from '../../alerts/successAlerts';

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
};

const ModSalarioTable = (props: TabPanelProps) =>{
  const { data} = useQuery(GET_MODIFICACIONSALARIO)
  const resultado = data?.GET_MODIFICACIONSALARIO

  const [filtrado, setFiltrado] =  useState<any[]>([])
  const [isCheck, setIsCheck] =  useState<any[]>([])

  const [updateSalarioBC] = useMutation(CREATE_HISTORICOSALARIO, {
    refetchQueries: [{ query: GET_MODIFICACIONSALARIO }, {query:GET_MOVIMIENTOSIDSE}],
})
  const obtenerDatos = async () => {
    await setFiltrado(resultado)
  };

  const datosFiltrados = (e:any) =>{
    if(e !== '') {
      let expresion = new RegExp(`${e}.*`, "i")
      const nuevoFiltrado = filtrado?.filter((lis:any) => expresion.test(lis.Nombre))
      setFiltrado(nuevoFiltrado)
    }else{
      obtenerDatos()
    }
  }

  const handleSelectAll = (e:any) =>{
    if(!e.target.checked){
      setIsCheck([])
    }
    let filtradoSeleccionado = (resultado.filter((lis:any)=>{
      return e.target.checked && !isCheck.includes(lis.id)
    }).map((lis:any) =>(lis.id)))

    setIsCheck(filtradoSeleccionado)
  }

  const handleClick =  (e:any) => {
    const {id, checked} = e.target
    setIsCheck((current)=> current =[...current, parseInt(id)])
    if(!checked){
      setIsCheck((current) => current = [...current,isCheck.filter((lis:any) => lis !== parseInt(id))])
    }
  }

  const ActualizarSalario = async () => {
    if(isCheck.length > 0){
     for (const collaborator of isCheck){
     const Valores = filtrado.filter((lis:any) => lis.id === collaborator)
      await updateSalarioBC({
        variables: {
          input:{
            idUser: collaborator,
            SBCAnterior:Valores[0]["SCB"],
            SBCNuevo:Valores[0]["NuevoSBC"]
          }
         },
       })
     }
    }

    SuccessfulAlert({text: "Registro actualizado con éxito"})
    setIsCheck([])
  }
  
  useEffect(()=>{
    setFiltrado(resultado)
  },[resultado, isCheck])

  return(
    <div className={styles.contenedor}>
      <div className={styles.contenedorTitulo}>
        Modificación Salario 
      </div>
      <div className={styles.contenedorAccionesTitulo}>
        <div className={styles.contenedorInput}>
          <div>
            <input 
              type="text" 
              className={styles.input} 
              placeholder="Buscar al colaborador por nombre"
              onChange={(e) => datosFiltrados(e.target.value)}/>
        </div>  
        <div className={styles.iconoBuscar}></div>
      </div>
      <div className={styles.contenedorBotones}>
        <button 
          className={styles.pc_botonDispersarInactivo}
          onClick={() =>  ActualizarSalario()}>
            <div>
            Actualizar
            </div>
            <div className={styles.pc_iconoDispersar}></div>
          </button>
        </div>
      </div>
      <div className={styles.contenedorTabla}>
        <table className={styles.tabla}>
          <thead className={styles.thead}>
            <tr>
              <td className={`${styles.tituloTabla} ${styles.primeraColumna}`}>
                <div id="colaboladores" className={styles.checkboxitem}>
                  <input
                    id="check"
                    type="checkbox"
                    onChange={(e) => handleSelectAll(e)}
                    ></input>
                      <label htmlFor="check"> Colaboradores </label>
                </div>
              </td>
              <td className={styles.tituloTabla}>SBC</td>
              <td className={styles.tituloTabla}>Horas Extras Triples</td>
              <td className={styles.tituloTabla}>Premios Puntualidad</td>
              <td className={styles.tituloTabla}>Sobre Sueldo</td>
              <td className={styles.tituloTabla}>Descanso Laborado</td>
              <td className={styles.tituloTabla}>Prima Dominical</td>
              <td className={styles.tituloTabla}>Incentivos</td>
              <td className={styles.tituloTabla}>Comisiones</td>
              <td className={styles.tituloTabla}>Viáticos gravados</td>
              <td className={styles.tituloTabla}>Nuevo SBC</td>
            </tr>
          </thead>
          <tbody>
            {filtrado?.map((lis:any)=>(
             <tr className={styles.datoTabla}>
              <td className={styles.primeraColumna}>
                <div id="colaboladores" className={styles.checkboxitem}>
                      <input
                        id={lis.id}
                        key={lis.id}
                        type="checkbox"
                        value={lis.id}
                        checked ={isCheck.includes(lis.id)}
                        onChange={(e) => handleClick(e)}
                      ></input>
                      <label htmlFor={lis.id}>  {lis.Nombre} </label>
                    </div>
                </td>
              <td>{formatter(lis.SCB)}</td>
              <td>{formatter(lis.HETriple)}</td>
              <td>{formatter(lis.Puntualidad)}</td>
              <td>{formatter(lis.SobreSueldo)}</td>
              <td>{formatter(lis.Descanso)}</td>
              <td>{formatter(lis.PrimaDominical)}</td>
              <td>{formatter(lis.Incentivos)}</td>
              <td>{formatter(lis.Comisiones)}</td>
              <td>{formatter(lis.ViaticosGravados)}</td>
              <td>{formatter(lis.NuevoSBC)}</td>
             </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default ModSalarioTable