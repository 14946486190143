import { gql } from "@apollo/client";

export const GET_ALL_DATAREPORTS = gql`
query GET_ALL_DATAREPORTS {
    GET_ALL_DATAREPORTS {
      id      
      ReportType
      ReportName
      ReportDescription
      ReportDate
      ReportSQL
      userAdd
    }
  }
`;

export const GET_DATAREPORTS = gql`
query Query($getDatareportsId: ID!) {
  GET_DATAREPORTS(id: $getDatareportsId)
}
`;

export const DELETE_DATAREPORT = gql`
mutation DELETE_DATAREPORT($Id: ID!) {
  DELETE_DATAREPORT(id: $Id)
}
`;

export const EDIT_DATAREPORT = gql`
mutation EDIT_DATAREPORT($Id: ID!, $input: edit_dataReport) {
  EDIT_DATAREPORT(id: $Id, input: $input){
    id  
  }
}
`;

export const GET_CUSTOMDATAREPORT = gql`
query GET_CUSTOMDATAREPORT($getDatareportsId: ID!) {
  GET_CUSTOMDATAREPORT(id: $getDatareportsId)
}
`;

export const GET_CUSTOM_REPORT_PREVIEW = gql`
query GET_CUSTOM_REPORT_PREVIEW($input: create_custom_report_input) {
  GET_CUSTOM_REPORT_PREVIEW(input: $input)
}
`;





