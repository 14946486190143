import {useState, useContext} from "react";
import {Dialog} from "@material-ui/core"
import styles from '../../Payroll/Modals/PayrollModals.module.css'
import CentralPagosContext from "../../../context/CentralPagos/CentralPagosContext";
import { clearDispersar } from "../../../context/CentralPagos/Actions";

const ModalMensajeError = ()=>{
  const {state, dispatch} = useContext(CentralPagosContext)

  const handleClose = () =>{
    clearDispersar({}, dispatch)
  }
  return(
    <Dialog aria-labelledby='costumized-dialog-title' open={state.openMensajeError} fullWidth={false}  onClose={handleClose} maxWidth={"md"}>
    <div className={styles.em_container}>
      <div className={styles.contenedorCerrarModal}>
        <div 
          className={styles.cerrarModal}
          onClick={()=> handleClose()}>
        </div>
      </div>
      <div className={styles.contenedorPrincipal}>
        <div className={styles.iconoEliminar}>
        </div>
      </div>
      <div className={styles.tituloAtencion}>
        ¡Atenci&oacute;n!
      </div>
      <div className={ styles.mensajeAtencion}>
        Error al realizar la dispers&iacute;on, por favor verifica que los datos sean correctos.
      </div>
      <div className={styles.contenedorBotonesAtencion}>
        <button 
          className={`${styles.botonesAtencion} ${styles.cerrarAtencion}`}
          onClick={() => handleClose()}
          >
          Cerrar
        </button>
        <button 
          className={`${styles.botonesAtencion} ${styles.continuarAtencion}`}
          onClick={() => handleClose()}
          >
          Entendido
        </button>
      </div>
    </div>
  </Dialog>
  )
}

export default ModalMensajeError