import {
    withStyles,
} from '@material-ui/core/styles';
import { Tab } from '@material-ui/core';

interface StyledTabProps {
    label: string;
    value: number;
    disabled?: boolean;
};

const CustomTab = withStyles(({
    root: {
        textTransform: 'none',
        minWidth: 165,   
        fontSize: "18px",
        color: "",
        borderBottom: "2.5px solid #d8d8d8",
        '&:hover': {
            color: '#093c5d',
            opacity: 1,
        },
        '&$selected': {
            color: '#093c5d',
        },
        '&:focus': {
            color: '#093c5d',
        },
    },
    selected: {
        fontWeight:"bold"
    },
    wrapper: {
        alignItems: "center",
        marginLeft: 0,
    },
}))((props: StyledTabProps) => <> <Tab disableRipple {...props} value={props.value}/></>);

export default CustomTab;
