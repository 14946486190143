import {useState, useContext} from "react";
import {Dialog} from "@material-ui/core"
import styles from '../../../Payroll/Modals/PayrollModals.module.css'
import { clearPayrollProcess } from "../../../../context/PayrollProcess/Actions"
import PayrollProcessContext from "../../../../context/PayrollProcess/PayrollProcessContext"
import { useHistory, useParams } from "react-router-dom";


const ResumenOutsourcers = (props:any)=>{
  const {state, dispatch} = useContext(PayrollProcessContext)
  const { id, tab } = useParams<any>();
  const history = useHistory();

  const handleClose = () =>{
    clearPayrollProcess({}, dispatch)
  }

  const iraPantalla =() =>{
    clearPayrollProcess({}, dispatch)
    history.push(`/outsorcersAutorizar/${id}/${tab}`)
  }

  return(
    <Dialog aria-labelledby='costumized-dialog-title' open={state.openModalOutsourcersResumen} fullWidth={false}  onClose={handleClose} maxWidth={"md"}>
    <div className={styles.em_container}>
      <div className={styles.contenedorCerrarModal}>
        <div 
          className={styles.cerrarModal}
          onClick={()=> handleClose()}>
        </div>
      </div>
      <div className={styles.tituloAtencion}>
        Solicitudes
      </div>
      <div className={styles.em_contenedorPrincipalOutsourcers}>
        <div className={styles.em_titulosTablaOutsourcers}>
          <div>
            Por autorizar
          </div>
          <div>
            Autorizadas
          </div>
          <div>
            Con respuesta
          </div>
        </div>
        <div className={styles.em_tablaPrincipalOutsourcers}>
          <div>
            {props.porAutorizar}
          </div>
          <div>
            {props.autorizadas}
          </div>
          <div>
            {props.conRespuesta}
          </div>
        </div>
        <div className={styles.em_tablaBotonOutsourcers}>
          <button
            className={styles.pd_botonDispersarDos}
            onClick={() => iraPantalla()}
            >
              <div>Outsourcers</div>
              <div className={styles.pc_iconoDispersar}></div>
          </button>
        </div>
      </div>

      <div className={styles.contenedorBotonesAtencion}>
        <button 
          className={`${styles.botonesAtencion} ${styles.cerrarAtencion}`}
          onClick={() => handleClose()}
          >
          Cerrar
        </button>
        <button 
          className={`${styles.botonesAtencion} ${styles.continuarAtencion}`}
          onClick={() => handleClose()}
          >
          Entendido
        </button>
      </div>
    </div>
  </Dialog>
  )
}

export default ResumenOutsourcers