import { useEffect, useState, useContext, Fragment } from "react";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import {
  Grid,
  TextField,
  InputLabel,
  Checkbox,
  Tooltip,
  FormHelperText,
} from "@material-ui/core";
import InputAdornment from "@mui/material/InputAdornment";
import { Select } from "@material-ui/core";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import { useFormik } from "formik";
import * as Yup from "yup";
import moment from "moment";
import { useQuery } from "@apollo/client";
import EyeIcon from "../../../../assets/svg/eye.svg";
import RemoveFile from "../../../../assets/svg/remove_file.svg";
import {
  GET_ALL_CALENDARS,
  GET_ALL_ENTERPRISE,
  GET_ALL_OUTSOURCERS,
  GET_ALL_PAYROLL_GROUP,
  GET_ALL_TIPOJORNADA,
} from "../../../../Querys/querys";
import CollaboratorContext from "../../../../context/CollaboratorContext/CollaboratorContext";
import { EventualPayroll } from "../../../../interfaces/TabEventualPayroll.interfaces";
import FileHelper from "../../Expedient/Fields/File_Helper";
import { formatterMxn } from "../../../../helpers/formatoMoneda";
import { withStyles, Theme } from "@material-ui/core/styles";

const HtmlTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    maxWidth: 1000,
    fontSize: theme.typography.pxToRem(14),
  },
}))(Tooltip);

const HiringDataRegister = (props: any) => {
  const { data: resultCompany } = useQuery(GET_ALL_ENTERPRISE);
  const allCompanies = resultCompany?.GET_ALL_ENTERPRISE;
  const { data: resultOutsources } = useQuery(GET_ALL_OUTSOURCERS);
  const allOutsources = resultOutsources?.GET_ALL_OUTSOURCERS;
  const [companies, setCompanies] = useState([]);
  const [archives, setArchives] = useState([]);
  const [registros_patronales, setRegistrosPatronales] = useState([]);

  const { data: resultPayRollGroup } = useQuery(GET_ALL_PAYROLL_GROUP);
  const allPayRollGroups = resultPayRollGroup?.GET_ALL_PAYROLL_GROUP;
  const [payRollGroups, setPayRollGroups] = useState([]);

  const [tipoContratacion, setTipoContratacion] = useState("Directa");
  const [tipoEsquema, setTipoEsquema] = useState("Nomina");
  const [recordableSalary, setRecordableSalary] = useState("");
  const [typeContract, setTypeContract] = useState("indefinida");
  const [typeSalary, setTypeSalary] = useState("Bruto");
  const [employerRegister, setEmployerRegister] = useState("");
  const [idEnterprise, setIdEnterprise] = useState("");
  const [idPayRollGroup, setIdPayRollGroup] = useState("");
  const { state } = useContext(CollaboratorContext);
  const disabled = false;
  const [outsources, setOutsources] = useState([]);
  const [isOwnRegister, setIsOwnRegister] = useState<boolean>(false);
  const [process1, setProcess1] = useState<boolean>(false);
  const [jornadas, setJornadas] = useState<Array<any>>([]);
  const [calendars, setCalendars] = useState<Array<any>>([]);
  const [filterCalendars, setFilterCalendars] = useState<Array<any>>([]);

  const { data: resultJornada } = useQuery(GET_ALL_TIPOJORNADA);
  const allJornadas = resultJornada?.GET_ALL_TIPOJORNADA;

  const { data: resultCalendars } = useQuery(GET_ALL_CALENDARS);
  const allCalendars = resultCalendars?.GET_ALL_CALENDARS;

  useEffect(() => {
    if (allJornadas) setJornadas(allJornadas);
    if (allCalendars) {
      setCalendars([...allCalendars]);
      setFilterCalendars([...allCalendars]);
    }
  }, [allJornadas, allCalendars]);

  const initialValues = () => {
    return {
      tittle: state.collaborator?.tittle,
      EmployerRegister: state.collaborator?.EmployerRegister,
      journey: state.collaborator?.journey,
      idJourney: state.collaborator?.idJourney,
      typeContract: state.collaborator?.typeContract,
      dateContractDate: state.collaborator?.dateContractDate,
      nominesPayroll: state.collaborator?.nominesPayroll,
      ingresoBruto: state.collaborator?.ingresoBruto,
      ingresoNeto: state.collaborator?.ingresoNeto,
      grossSalary: state.collaborator?.grossSalary,
      recordableSalary: state.collaborator?.recordableSalary,
      notRecordableSalary: state.collaborator?.notRecordableSalary,
      totalSalary: state.collaborator?.totalSalary,
      periodicidadDePago: state.collaborator?.periodicidadDePago,
      idEnterprise: state.collaborator?.idEnterprise,
      typeSalary: state.collaborator?.typeSalary,
      id_payroll_group: state.collaborator?.id_payroll_group,
      DS: state.collaborator?.DS,
      ISD: state.collaborator?.ISD,
      SDG: state.collaborator?.SDG,
      own_register: false,
      accept_info: state.collaborator?.accept_info,
      unionized: state.collaborator?.unionized,
      calendarId: state.collaborator?.calendarId,
    };
  };

  const validationSchema = () => {
    return {
      grossSalary: Yup.string().when("nominesPayroll", {
        is: "Nomina",
        then: Yup.string().required(
          "es necesario que ingrese el ingreso mensual bruto"
        ),
        otherwise(schema) {
          return schema.nullable();
        },
      }),

      DS: Yup.string()
        .required("es necesario que ingrese el salario diario")
        .nullable(),
      ISD: Yup.string()
        .required("es necesario que ingrese el salario diario integrado")
        .test(
          "compare_salary",
          "El valor del salario diario integrado no puede ser menor que el salario diario gravable",
          function (ISD: string | undefined): boolean {
            return ISD && this.parent.SDG && this.parent.nominesPayroll === 'Mixto'
              ? Number(ISD.replace(/,/g,'')) > Number(this.parent.SDG)
              : true;
          }
        )
        .nullable(),
      SDG: Yup.string().when("nominesPayroll", {
        is: "Nomina",
        then: Yup.string().notRequired().nullable(),
        otherwise: Yup.string()
          .required("es necesario que ingrese el salario diario integrado")
          .nullable(),
      }),

      idJourney: Yup.string().when("tittle", {
        is: "Directa",
        then: Yup.string().required("es necesario que ingrese la jornada"),
        otherwise: Yup.string().notRequired().nullable(),
      }),
      unionized: Yup.string().when("tittle", {
        is: "Directa",
        then: Yup.string().required(
          "es necesario que ingrese si es sindicalizado"
        ),
        otherwise: Yup.string().notRequired().nullable(),
      }),
      id_payroll_group: Yup.string().required(),
      idEnterprise: Yup.string().when("tittle", {
        is: "Directa",
        then: Yup.string().required(
          "es necesario que seleccione la empresa"
        ),
        otherwise: Yup.string().notRequired().nullable(),
      }),
      EmployerRegister: Yup.string().when("tittle", {
        is: "Directa",
        then: Yup.string().required(
          "es necesario que seleccione el registro patronal"
        ),
        otherwise: Yup.string().notRequired().nullable(),
      }),
      calendarId: Yup.string().when("tittle", {
        is: "Directa",
        then: Yup.string().required(
          "es necesario que seleccione el calendario"
        ),
        otherwise: Yup.string().notRequired().nullable(),
      }),
    };
  };

  const formik = useFormik({
    initialValues: initialValues(),
    validationSchema: Yup.object(validationSchema()),
    onSubmit: (formData) => {
    },
  });

  const handleCleanFormat = (value: string) => {
    //check if string contains .00
    if (value.includes(".00")) {
      value = value.substring(0, value.indexOf(".00"));
    }
    value = value.replace(/,/g, "");
    //parse to float and fixed to 2 decimals take the decimal after the comma
    let x = parseFloat(value).toFixed(2);
    let y = parseFloat(x);
    return y;
  };

  const handleSetFormat = (value: any, field: any) => {
    if (typeof value === "string") {
      let valueFormatted = value.replace(/,/g, "");

      valueFormatted = parseFloat(valueFormatted).toFixed(2);

      if (tipoEsquema === "Mixto") {
        let sum1 = parseFloat(formik.values.notRecordableSalary);
        let sum2 = parseFloat(formik.values.recordableSalary);
        if (!sum1) sum1 = 0;
        if (!sum2) sum2 = 0;

        let result1 = sum1 + sum2;
        let resSum = formatterMxn(result1);
        if (resSum === "nan") return false;
        resSum = resSum.substring(1);
      }

      let x = formatterMxn(valueFormatted);
      if (x === "nan") return false;
      x = x.substring(1);
      formik.setFieldValue(field, x);
    } else {
      if (tipoEsquema === "Mixto") {
        let sum1 = parseFloat(formik.values.notRecordableSalary);
        let sum2 = parseFloat(formik.values.recordableSalary);
        if (!sum1) sum1 = 0;
        if (!sum2) sum2 = 0;

        let result1 = sum1 + sum2;
        let resSum = formatterMxn(result1);
        if (resSum === "nan") return false;
        resSum = resSum.substring(1);
      }
      let x = formatterMxn(value);
      if (x === "nan") return false;
      x = x.substring(1);
      formik.setFieldValue(field, x);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
};

  useEffect(() => {
    if (state.collaborator) {
      setTipoContratacion(state.collaborator?.tittle);
      setTipoEsquema(state.collaborator?.nominesPayroll);
      setTypeContract(state.collaborator?.typeContract);
      setRecordableSalary(state.collaborator?.recordableSalary);
      setTypeSalary(state.collaborator?.typeSalary);
      setEmployerRegister(state.collaborator?.EmployerRegister);
      setIdEnterprise(state.collaborator?.idEnterprise);
      setIdPayRollGroup(state.collaborator?.id_payroll_group);
      if (state.collaborator?.archive) {
        setArchives(state.collaborator.archive);
      }
      handleSetFormat(state.collaborator?.DS, "DS");
      handleSetFormat(state.collaborator?.ISD, "ISD");
      handleSetFormat(state.collaborator?.SDG, "SDG");
      handleSetFormat(state.collaborator?.recordableSalary, "recordableSalary");
      handleSetFormat(
        state.collaborator?.notRecordableSalary,
        "notRecordableSalary"
      );
      handleSetFormat(state.collaborator?.totalSalary, "totalSalary");
      handleSetFormat(state.collaborator?.grossSalary, "grossSalary");

      if (state.collaborator?.register_process) {
        setProcess1(state.collaborator?.register_process[0].completed);
      }
    }
    if (allCompanies) {
      setCompanies(allCompanies);
      for(const company of allCompanies){
        if (String(company.id) === String(state.collaborator?.idEnterprise)) {
          if (company.registro_patronal) {
            setRegistrosPatronales(
              (current) => (current = company.registro_patronal)
            );
          }
        } else {
          setRegistrosPatronales(allCompanies[0].registro_patronal);
        }
      };
    }
    if (allOutsources) {
      setOutsources(allOutsources);
    }
    if (allPayRollGroups) {
      setPayRollGroups(allPayRollGroups);
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allCompanies, allPayRollGroups, allOutsources, state.collaborator]);

  const handleChange = async (e: any) => {};

  const cambioTipoContratacion = async (e: any) => {
    setTipoContratacion(e.target.value);
  };
  const handleTypeContract = async (e: any) => {
    setTypeContract(e.target.value);
  };
  const cambioTipoEsquema = async (e: any) => {
    const value = e.target.value;
    setTipoEsquema(value);
    setTypeSalary(value === 'Nomina' ? 'Bruto' : 'Neto');
  };
  const handleTypeSalary = async (salary: string) => {
    setTypeSalary(salary);
  };

  const handleChangeRegistroPatronal = async (id: any) => {
    for(const company of allCompanies){
      if (String(company.id) === String(id)) {
        if (company.registro_patronal) {
          setRegistrosPatronales(company.registro_patronal);
        }
      }
    };

    const filteredCalendars = calendars.filter(
      (calendar: any) => String(calendar.enterpriseId) === String(id)
    );
    setFilterCalendars(filteredCalendars);
  };

  const handleSetDS = async (e: any) => {
    if (e === "") return false;
    let value = await handleCleanFormat(e);
    value = value / 30;
    const valueRes = await handleSetFormat(value, "DS");
    return valueRes;
  };

  const handleSetNotRecordableSalary = async (itm = "0.00", itg = "0.00") => {
    
    if (itm && itg) {
      let value = await handleCleanFormat(itm);
      let value2 = await handleCleanFormat(itg);
      let valueRes = value - value2;
      let valueRes2 = await handleSetFormat(valueRes, "notRecordableSalary");
      if (valueRes2 !== false) {
        formik.setFieldValue("notRecordableSalary", valueRes2);
        document.getElementById("notRecordableSalary")?.click();
      }
    } else {
      formik.setFieldValue("notRecordableSalary", "0.00");
      document.getElementById("notRecordableSalary")?.click();
    }
  };

  return (
    <>
      <div className="novalidate__border">
        <span style={{ fontWeight: "bold" }}>Tipo de contratación</span>
        <Grid
          direction="row"
          container
          spacing={3}
        >
          <Grid xs item>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="Directa"
                name="tittle"
              >
                <FormControlLabel
                  control={<Radio size="small" style={{ color: "#fabb00" }} />}
                  label="Contratación directa"
                  value="Directa"
                  checked={tipoContratacion === "Directa" ? true : false}
                  onChange={cambioTipoContratacion}
                  name="tittle"
                  onBlur={formik.handleChange}
                />
                <FormControlLabel
                  control={<Radio size="small" style={{ color: "#fabb00" }} />}
                  label="Outsourcer"
                  value="outsource"
                  checked={tipoContratacion === "outsource" ? true : false}
                  onChange={cambioTipoContratacion}
                  name="tittle"
                  onBlur={formik.handleChange}
                />
              </RadioGroup>
            </FormControl>
            {tipoContratacion === "Directa" ? (
              <>
                <Grid spacing={3} direction="row" container>
                  <Grid xs={4} item>
                    <FormControl fullWidth={true} size="small">
                      <InputLabel>Empresa</InputLabel>

                      <Select
                        native
                        onBlur={formik.handleChange}
                        onChange={(e) => {
                          handleChangeRegistroPatronal(e.target.value);
                        }}
                        name="idEnterprise"
                        defaultValue={idEnterprise || ""}
                        autoFocus={true}
                        disabled={disabled}
                        style={{ height: "42px" }}
                        variant="outlined"
                        error={
                          formik.touched.idEnterprise &&
                          Boolean(formik.errors.idEnterprise)
                        }
                      >
                        <option selected disabled>
                          Seleccione una empresa
                        </option>
                        {companies.map(({ name, id }: any, index: number) => (
                          <>
                            {String(idEnterprise) === String(id) ? (
                              <option key={index} value={id} selected>
                                {name}
                              </option>
                            ) : (
                              <option key={index} value={id}>
                                {name}
                              </option>
                            )}
                          </>
                        ))}
                      </Select>
                      <FormHelperText style={{color: "red"}}>
                        {formik.touched.idEnterprise &&
                          !formik.values.idEnterprise &&
                          "Obligatorio"}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid xs={4} item>
                    <FormControl
                      variant="outlined"
                      fullWidth={true}
                      size="small"
                    >
                      <InputLabel>Registro patronal</InputLabel>

                      <Select
                        native
                        onBlur={formik.handleChange}
                        onChange={formik.handleChange}
                        name="EmployerRegister"
                        autoFocus={true}
                        disabled={disabled}
                        style={{ height: "42px" }}
                        variant="outlined"
                        error={
                          formik.touched.EmployerRegister &&
                          Boolean(formik.errors.EmployerRegister)
                        }
                      >
                        <option selected disabled>
                          Seleccione un registro patronal
                        </option>
                        {registros_patronales.map(
                          ({ registroPatronal }: any, index: number) => (
                            <>
                              {String(employerRegister) === String(registroPatronal) ? (
                                <option
                                  key={index}
                                  value={registroPatronal}
                                  selected
                                >
                                  {registroPatronal}
                                </option>
                              ) : (
                                <option key={index} value={registroPatronal}>
                                  {registroPatronal}
                                </option>
                              )}
                            </>
                          )
                        )}
                      </Select>
                      <FormHelperText style={{color: "red"}}>
                        {formik.touched.EmployerRegister &&
                          !formik.values.EmployerRegister &&
                          "Obligatorio"}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid xs={4} item>
                    <FormControl
                      variant="outlined"
                      fullWidth={true}
                      size="small"
                    >
                      <InputLabel>Jornada</InputLabel>
                      <Select
                        native
                        onBlur={formik.handleChange}
                        onChange={formik.handleChange}
                        name="idJourney"
                        autoFocus={true}
                        disabled={disabled}
                        style={{ height: "42px" }}
                        variant="outlined"
                        error={
                          formik.touched.idJourney &&
                          Boolean(formik.errors.idJourney)
                        }
                      >
                        <option selected disabled>
                          Seleccione una jornada
                        </option>
                        {jornadas.map(
                          ({ clave, Descripcion }: any, index: number) =>
                          String(state.collaborator?.idJourney) === String(clave) ? (
                              <option key={index} value={clave} selected>
                                {Descripcion}
                              </option>
                            ) : (
                              <option key={index} value={clave}>
                                {Descripcion}
                              </option>
                            )
                        )}
                      </Select>
                      <FormHelperText style={{color: "red"}}>
                        {formik.touched.idJourney &&
                          !formik.values.idJourney &&
                          "Obligatorio"}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                </Grid>

                <Grid
                  direction="row"
                  container
                  spacing={3}
                >
                  <Grid xs={4} item>
                    <FormControl
                      variant="outlined"
                      fullWidth={true}
                      size="small"
                    >
                      <InputLabel>Calendario</InputLabel>

                      <Select
                        native
                        onBlur={formik.handleChange}
                        onChange={formik.handleChange}
                        name="calendarId"
                        autoFocus={true}
                        disabled={disabled}
                        style={{ height: "42px" }}
                        variant="outlined"
                        error={
                          formik.touched.calendarId &&
                          Boolean(formik.errors.calendarId)
                        }
                      >
                        <option selected disabled>
                          Seleccione un calendario
                        </option>
                        {filterCalendars.map(
                          ({ name, id }: any, index: number) => (
                            <>
                              {String(state.collaborator?.calendarId) === String(id) ? (
                                <option key={index} value={id} selected>
                                  {name}
                                </option>
                              ) : (
                                <option key={index} value={id}>
                                  {name}
                                </option>
                              )}
                            </>
                          )
                        )}
                      </Select>
                      <FormHelperText style={{color: "red"}}>
                        {formik.touched.calendarId &&
                          !formik.values.calendarId &&
                          "Obligatorio"}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid xs={4} item>
                    <FormControl
                      variant="outlined"
                      fullWidth={true}
                      size="small"
                    >
                      <InputLabel>Sindicalizado</InputLabel>
                      <Select
                        native
                        onBlur={formik.handleChange}
                        onChange={formik.handleChange}
                        name="unionized"
                        autoFocus={true}
                        disabled={disabled}
                        style={{ height: "42px" }}
                        variant="outlined"
                        error={
                          formik.touched.unionized &&
                          Boolean(formik.errors.unionized)
                        }
                      >
                        <option disabled selected>
                          Seleccione una opción
                        </option>
                        <option value={"1"}>{"Si"}</option>
                        <option value={"0"}>{"No"}</option>
                      </Select>
                      <FormHelperText style={{color: "red"}}>
                        {formik.touched.unionized &&
                        formik.errors.unionized}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                </Grid>
              </>
            ) : (
              <Grid spacing={3} direction="row" container>
                <Grid xs={5} item>
                  <FormControl variant="outlined" fullWidth={true} size="small">
                    <InputLabel>Empresa</InputLabel>
                    <Select
                      native
                      onBlur={formik.handleChange}
                      name="idEnterprise"
                      onChange={formik.handleChange}
                      defaultValue={idEnterprise || ""}
                      autoFocus={true}
                      disabled={disabled}
                      style={{ height: "42px" }}
                      variant="outlined"
                      error={
                        formik.touched.idEnterprise &&
                        Boolean(formik.errors.idEnterprise)
                      }
                    >
                      <option selected disabled>
                        Seleccione una empresa
                      </option>
                      {outsources.map(({ nombre, id }: any, index: number) => (
                        <>
                          {String(idEnterprise) === String(id) ? (
                            <option key={index} value={id} selected>
                              {nombre}
                            </option>
                          ) : (
                            <option key={index} value={id}>
                              {nombre}
                            </option>
                          )}
                        </>
                      ))}
                    </Select>
                    <FormHelperText style={{color: "red"}}>
                      {formik.touched.idEnterprise &&
                      !formik.values.idEnterprise &&
                      "Obligatorio"}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid
                  spacing={3}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "20px",
                  }}
                ></Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </div>
      <div
        className={
          state.sections[2] >= 100 ? `validate__border` : `novalidate__border`
        }
      >
        <span>Vigencia del contrato</span>
        <Grid
          direction="row"
          container
          spacing={3}
        >
          <Grid xs item>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="indefinida"
                name="typeContract"
              >
                <FormControlLabel
                  control={<Radio size="small" style={{ color: "#fabb00" }} />}
                  onChange={(e) => handleTypeContract(e)}
                  label="Indefinida"
                  value="indefinida"
                  name="typeContract"
                  checked={typeContract === "indefinida" ? true : false}
                  onBlur={formik.handleChange}
                />
                <FormControlLabel
                  control={<Radio size="small" style={{ color: "#fabb00" }} />}
                  label="Temporal"
                  value="temporal"
                  onChange={(e) => handleTypeContract(e)}
                  name="typeContract"
                  checked={typeContract === "temporal" ? true : false}
                  onBlur={formik.handleChange}
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
        {typeContract === "temporal" ? (
          <>
            <span>Fecha de vigencia del contrato</span>
            <TextField
              id="outlined-basic"
              variant="outlined"
              type="date"
              size="small"
              style={{ width: "100%" }}
              name="dateContractDate"
              onChange={formik.handleChange}
              defaultValue={
                state.collaborator
                  ? moment(state.collaborator.dateContractDate).format(
                      "YYYY-MM-DD"
                    )
                  : ""
              }
              onBlur={handleChange}
              InputProps={{
                inputProps: {
                  min: moment(state.collaborator.dateOfAdmission)
                    .add(1, "day")
                    .format("YYYY-MM-DD"),
                },
              }}
            />
          </>
        ) : (
          <></>
        )}
      </div>
      <div
        className={
          state.sections[2] >= 100 ? `validate__border` : `novalidate__border`
        }
      >
        <span style={{ fontWeight: "bold" }}>Grupo de nóminas</span>
        <Grid
          spacing={3}
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "10px",
          }}
        >
          <FormControl fullWidth={true} size="small" style={{ width: "100%" }}>
            <Select
              native
              onBlur={formik.handleChange}
              name="id_payroll_group"
              onChange={formik.handleChange}
              defaultValue={idPayRollGroup || ""}
              autoFocus={true}
              disabled={disabled}
              style={{ height: "42px" }}
              variant="outlined"
            >
              {payRollGroups.map(
                ({ group_name, id }: EventualPayroll, index: number) => (
                  <>
                    {String(idPayRollGroup) === String(id) ? (
                      <option key={index} value={id} selected>
                        {group_name}
                      </option>
                    ) : (
                      <option key={index} value={id}>
                        {group_name}
                      </option>
                    )}
                  </>
                )
              )}
            </Select>
          </FormControl>
        </Grid>

        <span style={{ fontWeight: "bold" }}>Tipo de esquema</span>
        <Grid
          direction="row"
          container
          spacing={3}
        >
          <Grid xs item>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="Nomina"
                name="radio-buttons-group"
              >
                <FormControlLabel
                  value="Nomina"
                  control={<Radio size="small" style={{ color: "#fabb00" }} />}
                  label="100% Nómina"
                  checked={tipoEsquema === "Nomina" ? true : false}
                  onChange={cambioTipoEsquema}
                  name="nominesPayroll"
                  onBlur={formik.handleChange}
                />
                <FormControlLabel
                  value="Mixto"
                  control={<Radio size="small" style={{ color: "#fabb00" }} />}
                  label="Mixto"
                  checked={tipoEsquema === "Mixto" ? true : false}
                  onChange={cambioTipoEsquema}
                  name="nominesPayroll"
                  onBlur={formik.handleChange}
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>

        {tipoEsquema === "Nomina" ? (
          <>
            <FormLabel style={{ marginBottom: "4px", fontWeight: "bold" }}>
              Ingreso Mensual
            </FormLabel>
            <Grid
              direction="row"
              container
              spacing={3}
              style={{ marginTop: "4px" }}
            >
              <Grid item xs={4}>
                <FormControl>
                  <TextField
                    style={{
                      width: "100%",
                      display: "inline-block",
                    }}
                    label="Ingreso mensual"
                    id="outlined-basic"
                    variant="outlined"
                    type="text"
                    size="small"
                    value={formik.values.grossSalary}
                    name="grossSalary"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                    onKeyPress={(event: any) => {
                      if (!/^[0-9.]+$/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    onChange={(e) => {
                      formik.handleChange(e);
                      handleSetDS(e.target.value).then((res) => {
                        if (res === false) {
                          formik.setFieldValue("DS", "0.00");
                        }
                      });
                    }}
                    onBlur={() =>
                      handleSetFormat(formik.values.grossSalary, "grossSalary")
                    }
                    error={
                      formik.touched.grossSalary &&
                      Boolean(formik.errors.grossSalary)
                    }
                    helperText={
                      formik.touched.grossSalary &&
                      Boolean(formik.errors.grossSalary) &&
                      "El campo es requerido y no puede ser menor a  5186.10"
                    }
                  />
                </FormControl>
              </Grid>

              <Grid item xs={7}>
                {
                  typeSalary === "Neto" &&
                    <FormLabel  style={{ marginRight: "20px", color: "inherit" }}> Neto </FormLabel>
                }
                {
                  typeSalary === "Bruto" &&
                    <FormLabel  style={{ marginRight: "20px", color: "inherit" }}> Bruto </FormLabel>
                }
               {
                /*
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="Bruto"
                    name="typeSalary"
                    onBlur={formik.handleChange}
                  >
                    <FormControlLabel
                      value="Bruto"
                      control={
                        <Radio size="small" style={{ color: "#fabb00" }} />
                      }
                      label="Bruto "
                      name="typeSalary"
                      onChange={(e) => handleTypeSalary(e)}
                      checked={typeSalary === "Bruto" ? true : false}
                      onBlur={formik.handleChange}
                      style={{ marginRight: "20px" }}
                    />
                    <FormControlLabel
                      value="Neto"
                      control={
                        <Radio size="small" style={{ color: "#fabb00" }} />
                      }
                      label="Neto"
                      name="typeSalary"
                      onChange={(e) => handleTypeSalary(e)}
                      checked={typeSalary === "Neto" ? true : false}
                      onBlur={formik.handleChange}
                      style={{ marginLeft: "46px" }}
                    />
                  </RadioGroup>
                </FormControl>
                 */
                }
              </Grid>
            </Grid>
          </>
        ) : (
          <>
            <FormLabel style={{ marginBottom: "4px", fontWeight: "bold" }}>
              Ingreso Mensual
            </FormLabel>
            <Grid
              direction="row"
              container
              spacing={3}
              style={{
                display: "flex",
                marginTop: "2px",
              }}
            >
              <Grid item xs={4}>
                <FormControl style={{ marginBottom: "4px" }}>
                  <TextField
                    style={{ width: "100%" }}
                    required
                    label="Ingreso mensual"
                    id="outlined-basic"
                    variant="outlined"
                    type="text"
                    size="small"
                    onChange={(e) => {
                      formik.handleChange(e);
                      handleSetDS(e.target.value).then((res) => {
                        if (res === false) {
                          formik.setFieldValue("DS", "0.00");
                        }
                      });
                      handleSetNotRecordableSalary(
                        e.target.value,
                        formik.values.recordableSalary
                      );
                    }}
                    error={
                      formik.touched.grossSalary &&
                      Boolean(formik.errors.grossSalary)
                    }
                    helperText={
                      formik.touched.grossSalary &&
                      formik.errors.grossSalary &&
                      "el campo es requerido"
                    }
                    name="grossSalary"
                    onBlur={() =>
                      handleSetFormat(formik.values.grossSalary, "grossSalary")
                    }
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                    defaultValue={formik.values.grossSalary ?? 0}
                    value={formik.values.grossSalary}
                    onKeyPress={(event: any) => {
                      if (!/^[0-9.]+$/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={4}>
                <FormControl style={{ marginBottom: "4px" }}>
                  <TextField
                    style={{ width: "100%" }}
                    required
                    label="Salario diario"
                    id="outlined-basic"
                    variant="outlined"
                    type="text"
                    size="small"
                    name="DS"
                    onChange={formik.handleChange}
                    error={formik.touched.DS && Boolean(formik.errors.DS)}
                    helperText={formik.touched.DS && formik.errors.DS}
                    onBlur={() => handleSetFormat(formik.values.DS, "DS")}
                    value={formik.values.DS}
                    InputProps={{
                      readOnly: true,
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                    onKeyPress={(event: any) => {
                      if (!/^[0-9.]+$/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={4}></Grid>
            </Grid>

            <FormLabel style={{ fontWeight: "bold" }}>
              Ingreso gravable
            </FormLabel>
            <Grid
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                marginTop: "20px",
              }}
            >
              <Grid item xs={4}>
                <FormControl>
                  <TextField
                    style={{ width: "100%" }}
                    required
                    label="Ingreso mensual"
                    id="outlined-basic"
                    variant="outlined"
                    type="text"
                    size="small"
                    name="recordableSalary"
                    onChange={(e) => {
                      formik.handleChange(e);
                      handleSetNotRecordableSalary(
                        formik.values.grossSalary,
                        e.target.value
                      );
                    }}
                    error={Boolean(formik.errors.recordableSalary)}
                    helperText={
                      formik.errors.recordableSalary &&
                      "el campo es requerido y no puede ser menor a  5186.10"
                    }
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                    defaultValue={recordableSalary ?? 0}
                    value={formik.values.recordableSalary ?? 0}
                    onBlur={() =>
                      handleSetFormat(
                        formik.values.recordableSalary,
                        "recordableSalary"
                      )
                    }
                    onKeyPress={(event: any) => {
                      //check if the key is a dot
                      if (!/^[0-9.]+$/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  />
                </FormControl>
              </Grid>

              {tipoEsquema === "Mixto" && (
                <Grid item xs={4}>
                  <FormControl>
                    <TextField
                      style={{ width: "100%" }}
                      required
                      label="Salario diario gravable"
                      id="outlined-basic"
                      variant="outlined"
                      type="text"
                      size="small"
                      name="SDG"
                      onChange={formik.handleChange}
                      error={formik.touched.SDG && Boolean(formik.errors.SDG)}
                      helperText={formik.touched.SDG && formik.errors.SDG}
                      onBlur={() => handleSetFormat(formik.values.SDG, "SDG")}
                      value={formik.values.SDG}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                      }}
                      onKeyPress={(event: any) => {
                        if (!/^[0-9.]+$/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                    />
                  </FormControl>
                </Grid>
              )}
              <Grid item xs={4}>
                <FormControl>
                  <TextField
                    style={{ width: "100%" }}
                    required
                    label="Salario diario integrado"
                    id="outlined-basic"
                    variant="outlined"
                    type="text"
                    size="small"
                    name="ISD"
                    onChange={formik.handleChange}
                    error={formik.touched.ISD && Boolean(formik.errors.ISD)}
                    helperText={formik.touched.ISD && formik.errors.ISD}
                    onBlur={() => handleSetFormat(formik.values.ISD, "ISD")}
                    value={formik.values.ISD}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                    onKeyPress={(event: any) => {
                      if (!/^[0-9.]+$/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  />
                </FormControl>
              </Grid>
            </Grid>
            {tipoEsquema === "Mixto" && (
              <>
                <Grid
                  direction="row"
                  container
                  spacing={3}
                  style={{
                    display: "flex",
                    marginTop: "2px",
                  }}
                >
                  <Grid item xs={4}>
                  {
                    typeSalary === "Neto" &&
                      <FormLabel  style={{ marginRight: "20px", color: "inherit" }}> Neto </FormLabel>
                  }
                  {
                    typeSalary === "Bruto" &&
                      <FormLabel  style={{ marginRight: "20px", color: "inherit" }}> Bruto </FormLabel>
                  }
                  {/*
                    <FormControl>
                      <RadioGroup
                        row
                        defaultValue="Neto"
                        name="grabable"
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <FormControlLabel
                          value="Neto"
                          control={
                            <Radio size="small" style={{ color: "#fabb00" }} />
                          }
                          label="Neto"
                          name="typeSalary"
                          onBlur={formik.handleChange}
                          checked={typeSalary === "Neto" ? true : false}
                          onChange={(e) => handleTypeSalary(e)}
                        />
                        <FormControlLabel
                          value="Bruto"
                          control={
                            <Radio size="small" style={{ color: "#fabb00" }} />
                          }
                          label="Bruto"
                          name="typeSalary"
                          onBlur={formik.handleChange}
                          checked={typeSalary === "Bruto" ? true : false}
                          onChange={(e) => handleTypeSalary(e)}
                        />
                      </RadioGroup>
                    </FormControl>
                  */}
                  </Grid>
                </Grid>
                <FormLabel style={{ fontWeight: "bold" }}>
                  Ingreso no gravable aproximado
                </FormLabel>
                <Grid
                  direction="row"
                  container
                  spacing={3}
                  style={{
                    display: "flex",
                    marginTop: "2px",
                  }}
                >
                  <Grid item xs={4}>
                    <FormControl>
                      <TextField
                        style={{ width: "100%" }}
                        required
                        label="Total"
                        id="notRecordableSalary"
                        variant="outlined"
                        type="text"
                        size="small"
                        name="notRecordableSalary"
                        value={formik.values.notRecordableSalary}
                        onClick={formik.handleChange}
                        onBlur={formik.handleChange}
                        InputProps={{
                          readOnly: true,
                          startAdornment: (
                            <InputAdornment position="start">$</InputAdornment>
                          ),
                        }}
                        onKeyPress={(event: any) => {
                          if (!/^[0-9.]+$/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                      />
                    </FormControl>
                    {typeSalary === "Bruto" && (
                      <span className="span-file" style={{ marginTop: "2%" }}>
                        Este monto aún no tiene la deducción de impuestos
                        correspondiente
                      </span>
                    )}
                  </Grid>
                </Grid>
              </>
            )}
          </>
        )}
        <Grid
          spacing={3}
          style={{
            marginTop: "10px",
          }}
          direction="row"
          container
        >
          <Grid item xs={4}>
            <FormControl>
              <TextField
                style={{ width: "100%" }}
                required
                label="Salario diario"
                id="outlined-basic"
                variant="outlined"
                type="text"
                size="small"
                name="DS"
                onChange={formik.handleChange}
                error={formik.touched.DS && Boolean(formik.errors.DS)}
                helperText={formik.touched.DS && formik.errors.DS}
                onBlur={() => handleSetFormat(formik.values.DS, "DS")}
                value={formik.values.DS}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
                onKeyPress={(event: any) => {
                  if (!/^[0-9.]+$/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
              />
            </FormControl>
          </Grid>
          {tipoEsquema === "Nomina" && (
            <Grid item xs={4}>
              <FormControl>
                <TextField
                  style={{ width: "100%" }}
                  required
                  label="Salario diario integrado"
                  id="outlined-basic"
                  variant="outlined"
                  type="text"
                  size="small"
                  name="ISD"
                  onChange={formik.handleChange}
                  error={formik.touched.ISD && Boolean(formik.errors.ISD)}
                  helperText={formik.touched.ISD && formik.errors.ISD}
                  onBlur={() => handleSetFormat(formik.values.ISD, "ISD")}
                  value={formik.values.ISD}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                  onKeyPress={(event: any) => {
                    if (!/^[0-9.]+$/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                />
              </FormControl>
            </Grid>
          )}
        </Grid>
      </div>
      <div className="novalidate__border">
        <Grid
          direction="row"
          container
          spacing={3}
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <TextField
            disabled
            id="outlined-basic"
            variant="outlined"
            type="text"
            size="small"
            label="Periodicidad de pago"
            defaultValue="Quincenal"
            style={{ background: "#f1f2f5" }}
            name="periodicidadDePago"
            onBlur={formik.handleChange}
          />

          <Grid xs item>
            <Grid
              direction="row"
              container
              justify="flex-end"
              alignItems="center"
            >
              <label style={{ marginRight: "10px" }}>
                {" "}
                Documentos para firma
                <HtmlTooltip
                  title={
                    <Fragment>
                      Considera subir en un sólo pdf todos los documentos
                    </Fragment>
                  }
                >
                  <img
                    src="/assets/icons/PreguntaAzul.png"
                    alt="Question"
                    height="15"
                  />
                </HtmlTooltip>
              </label>
              <br />
              {/*AVISO DE RETENCIÓN  */}
              {archives?.map(
                (archive: any, index: number) =>
                  archive?.name === "DocumentosParaFirma" && (
                    <div className="flex-container">
                      <div className="flex-child">
                        <span className="span-file">
                          <a
                            className="view-document"
                            target="_blank"
                            rel="noreferrer"
                            href={archive.URL}
                          >
                            <img
                              style={{ height: "10px", marginTop: "5px" }}
                              src={EyeIcon}
                              alt=""
                            />
                            Ver documento
                          </a>
                        </span>
                      </div>

                      <div className="flex-child-2">
                        <FileHelper
                          name="DocumentosParaFirma"
                          accept=".pdf"
                          parametrofrom="collaborator"
                          idUsr={state.collaborator?.id}
                          className="image-file"
                          setArchives={setArchives}
                          archives={archives}
                          image={RemoveFile}
                          remove_file={true}
                        />
                      </div>
                    </div>
                  )
              )}
              {!state.collaborator?.CartaOferta && !process1 && (
                <div className="flex-container">
                  <div className="flex-child-2">
                  </div>
                </div>
              )}
            </Grid>
          </Grid>
        </Grid>
        {!state.collaborator?.own_register && (
          <Grid direction="row" container spacing={3}>
            <Grid
              item
              xs={12}
              style={{
                paddingLeft: "0px",
              }}
            >
              <Checkbox
                value={isOwnRegister}
                onChange={() => setIsOwnRegister(!isOwnRegister)}
                onBlur={formik.handleChange}
                name="own_register"
                color="primary"
              />

              <label htmlFor="">Continuar con el registro</label>
              <HtmlTooltip
                title={
                  <Fragment>
                    Si no deseas que el colaborador realice su registro,
                    selecciona esta opción.
                  </Fragment>
                }
              >
                <img
                  src="/assets/icons/PreguntaAzul.png"
                  alt="Question"
                  height="15"
                />
              </HtmlTooltip>
            </Grid>
          </Grid>
        )}
      </div>
    </>
  );
};

export default HiringDataRegister;
