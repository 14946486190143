import { Dispatch, ChangeEvent } from "react";
import { Types } from "./AdministratorsContext";

export const createAdministratorsModal = (
  { _id = "", createModal = true }: any,
  dispatch: Dispatch<any>
): void => {
  dispatch({
    type: Types.CREATE_ADMINISTRATORS,
    payload: {
      createModal,
    },
  });
};

export const clearAdministratorsModal = (
  {
    _id = 0,
    createModal = false,
    updateModal = false,
    Permisos = {},
    FullName,
    Usuario,
    deleteModal = false,
  }: any,
  dispatch: Dispatch<any>
): void => {
  dispatch({
    type: Types.CLEAR_ADMINISTRATORS,
    payload: {
      _id,
      Permisos,
      FullName,
      Usuario,
      createModal,
      updateModal,
      deleteModal,
    },
  });
};

export const updateAministratorsModal = (
  _id: number,
  updateModal = true,
  FullName: string,
  Usuario: string,
  img: string,
  dispatch: Dispatch<any>
): void => {
  dispatch({
    type: Types.UPDATE_ADMINISTRATORS,
    payload: {
      _id,
      updateModal,
      FullName,
      Usuario,
      img,
    },
  });
};

export const deleteAdministratorsModal = (
  { _id = "", deleteModal = true }: any,
  dispatch: Dispatch<any>
): void => {
  dispatch({
    type: Types.DELETE_ADMINISTRATORS,
    payload: {
      _id,
      deleteModal,
    },
  });
};

export const updateAdmin = async (
  e: ChangeEvent<{ name: string; value: unknown }>,
  state: any,
  dispatch: Dispatch<any>,
  Nombre: string,
  Value: boolean,
  Modulo: string
): Promise<void> => {

  switch (Modulo) {
    case "EstructuraEquipo":
      state.Permisos.Permisos.Modulos[Nombre] = Value;
      break;
    case "Colaboradores":
      state.Permisos.Permisos.Modulos[Nombre] = Value;
      break;
    case "MyMood":
      state.Permisos.Permisos.Modulos[Nombre] = Value;
      break;
    case "Notificaciones":
      state.Permisos.Permisos.Modulos[Nombre] = Value;
      break;
    case "Noticias":
      state.Permisos.Permisos.Modulos[Nombre] = Value;
      break;
    case "Calendario":
      state.Permisos.Permisos.Modulos[Nombre] = Value;
      break;
    case "Feedback":
      state.Permisos.Permisos.Modulos[Nombre] = Value;
      break;
  }
  updateAdminPermisos(state.Permisos, dispatch);
};

const updateAdminPermisos = (Permisos: any, dispatch: Dispatch<any>): void => {
  dispatch({
    type: Types.UPDATE_ADMINISTRATORS_PERMISOS,
    payload: {
      Permisos,
    },
  });
};

export { updateAdminPermisos };
