import styles from "../styles/alertLockedSystem/LockedSystem.module.css";
const LockedSystem = () => {

    return (
        
            <div className={styles.divDisabled} >
                <div className={styles.divTextDisabled} >
                <span className={styles.textDisabled} >Tu cuenta se encuentra bloqueada</span>
                </div>
                <div className={styles.divTextContact} >
                <span className={styles.textContact} >Contacta a un asesor</span>
                </div>
            </div>
        
    )

}

export default LockedSystem;