import { useState ,useEffect } from "react";
import Table from '@mui/material/Table';
import Box from '@mui/material/Box';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import "../css/TableDynamic.css";

const TableDynamic = ({report}:any) => {
 const [nameColumns,setNameColumns] = useState<Array<any>>([]);
 const [valueReport,setValueReport] = useState<Array<any>>([]);   
  
 useEffect(() => {
   const copyObj=[...report]
   const columns= copyObj.map((re:any) => {
       return(Object.keys(re))
    })    
    columns.length >0? setNameColumns(columns[0]):setNameColumns([])    
    const valuesRep= copyObj.map((data:any) => {
       return Object.values(data)      
    })  
    valuesRep.length >0? setValueReport(valuesRep):setValueReport([])
  }, [report]);
  
return (
<>
<Box className="containerTableDynamic">
<TableContainer component={Paper}>
<Table sx={{ minWidth: 650 }} aria-label="caption table">
  <TableHead>
    <TableRow className='pd_contenedorTitulos'>
     {nameColumns.length >0 ? 
      nameColumns.map((column:any) =>{
      return <TableCell className='cellSpanTitleReport' align="left">{column}</TableCell>
     })     
     :     
     <></>
    }     
    </TableRow>
  </TableHead>
  <TableBody>
     {
      valueReport.length >0 ?
       valueReport.map((row:any) => {       
        return(
        <TableRow >
          {
            row.map((cell:any, i:any) => (
                <TableCell align="left"> {cell}</TableCell>                
             ))
          }  
        </TableRow>  
        )            
       }):<></>
      } 
      </TableBody>
</Table>
</TableContainer>
</Box>  
</>
  )
}

export default TableDynamic

