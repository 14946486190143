import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import List from "../List";

import Beneficiario from "./Beneficiario";
import Emergencia from "./Emergencia";
import Enfermedad from "./Enfermedad";

import BeneficiarioNuevoIngreso from "./Candidatos/BeneficiarioNuevoIngreso";
import BeneficiarioInactivos from "./Inactivos/BeneficiarioInactivos";
import EmergenciaNuevoIngreso from "./Candidatos/EmergenciaNuevoIngreso";
import EmergenciaInactivos from "./Inactivos/EmergenciaInactivos";
import EnfermedadInactivos from "./Inactivos/EnfermedadInactivos";
import EnfermedadNuevoingreso from "./Candidatos/EnfermedadNuevoingreso";

import BeneficiarioColaboradores from "./Colaboradores/BeneficiarioColaboradores";
import EmergenciaColaboradores from "./Colaboradores/EmergenciaColaboradores";
import EnfermedadColaboradores from "./Colaboradores/EnfermedadColaboradores";


import CheckPermission from "../../../helpers/Administradores/Permissions";

const Contacts = (processProp?: any) => {
  let location = useLocation();
  let nuevoArrayEtiquetas = [];
  let nuevoArrayTablas = [];
  const [processTab, setProcessTab] = useState<any>();

  useEffect(() => {
    if (processProp) {
      setProcessTab(processProp?.process);
    }
  }, [processProp]);

  let section = location.pathname.split("/")[1];

  if (section === "collaborators-view") {
    if (
      CheckPermission(
        "Colaboradores.Inactivos.Ver.SaludEmergencias.Beneficiario"
      )
    ) {
      nuevoArrayEtiquetas.push("Beneficiario");
      nuevoArrayTablas.push(BeneficiarioInactivos);
    }
    if (
      CheckPermission(
        "Colaboradores.Inactivos.Ver.SaludEmergencias.ContactoEmergencia"
      )
    ) {
      nuevoArrayEtiquetas.push("Contacto de Emergencia");
      nuevoArrayTablas.push(EmergenciaInactivos);
    }
    if (
      CheckPermission(
        "Colaboradores.Inactivos.Ver.SaludEmergencias.InformacionMedica"
      )
    ) {
      nuevoArrayEtiquetas.push("Información Médica");
      nuevoArrayTablas.push(EnfermedadInactivos);
    }
  }

  if (section === "collaborators") {
    if (
      CheckPermission(
        "Colaboradores.Colaboradores.Editar.SaludEmergencias.Beneficiario.Ver"
      )
    ) {
      nuevoArrayEtiquetas.push("Beneficiario");
      nuevoArrayTablas.push(Beneficiario);
    }
    if (
      CheckPermission(
        "Colaboradores.Colaboradores.Editar.SaludEmergencias.ContactoEmergencia.Ver"
      )
    ) {
      nuevoArrayEtiquetas.push("Contacto de Emergencia");
      nuevoArrayTablas.push(Emergencia);
    }
    if (
      CheckPermission(
        "Colaboradores.Colaboradores.Editar.SaludEmergencias.InformacionMedica.Ver"
      )
    ) {
      nuevoArrayEtiquetas.push("Información Médica");
      nuevoArrayTablas.push(Enfermedad);
    }
  }

  if (section === "nuevoingreso") {
    if (
      CheckPermission(
        "Colaboradores.NuevoIngreso.Ver.SaludEmergencias.Beneficiario"
      )
    ) {
      nuevoArrayEtiquetas.push("Beneficiario");
      nuevoArrayTablas.push(BeneficiarioNuevoIngreso);
    }
    if (
      CheckPermission(
        "Colaboradores.NuevoIngreso.Ver.SaludEmergencias.ContactoEmergencia"
      )
    ) {
      nuevoArrayEtiquetas.push("Contacto de Emergencia");
      nuevoArrayTablas.push(EmergenciaNuevoIngreso);
    }
    if (
      CheckPermission(
        "Colaboradores.NuevoIngreso.Ver.SaludEmergencias.InformacionMedica"
      )
    ) {
      nuevoArrayEtiquetas.push("Información Médica");
      nuevoArrayTablas.push(EnfermedadNuevoingreso);
    }
  }

  if (section === "inactivos") {
    if (
      CheckPermission(
        "Colaboradores.Inactivos.Ver.SaludEmergencias.Beneficiario"
      )
    ) {
      nuevoArrayEtiquetas.push("Beneficiario");
      nuevoArrayTablas.push(BeneficiarioInactivos);
    }
    if (
      CheckPermission(
        "Colaboradores.Inactivos.Ver.SaludEmergencias.ContactoEmergencia"
      )
    ) {
      nuevoArrayEtiquetas.push("Contacto de Emergencia");
      nuevoArrayTablas.push(EmergenciaInactivos);
    }
    if (
      CheckPermission(
        "Colaboradores.Inactivos.Ver.SaludEmergencias.InformacionMedica"
      )
    ) {
      nuevoArrayEtiquetas.push("Información Médica");
      nuevoArrayTablas.push(EnfermedadInactivos);
    }
  }

  if (section === "candidate") {
    nuevoArrayEtiquetas.push("Beneficiario");
    nuevoArrayTablas.push(BeneficiarioColaboradores);
    nuevoArrayEtiquetas.push("Contacto de Emergencia");
    nuevoArrayTablas.push(EmergenciaColaboradores);
    nuevoArrayEtiquetas.push("Información Médica");
    nuevoArrayTablas.push(EnfermedadColaboradores);
  }

  return (
    <List
      title="SALUD Y EMERGENCIAS"
      labels={nuevoArrayEtiquetas}
      components={nuevoArrayTablas}
      numTab={4}
      progress={2}
      process={processTab}
    />
  );
};

export default Contacts;
