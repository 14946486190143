import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import List from "../List";
import OfertaLaboralNuevoIngreso from "./NuevoIngreso/OfertaLaboralNuevoIngreso";
import OfertaLaboralColaboradores from "./Colaboradores/OfertaLaboralColaboradores";
import OfertaLaboralInactivos from "./Inactivos/OfertaLaboralInactivos";
import OptionalDocumentsNuevoIngreso from "./NuevoIngreso/OptionalDocumentsNuevoIngreso";
import OptionalDocumentsColaboradores from "./Colaboradores/OptionalDocumentsColaboradores";
import OptionalDocumentsInactivos from "./Inactivos/OptionalDocumentsInactivos";
import PersonalDocumentsNuevoIngreso from "./NuevoIngreso/PersonalDocumentsNuevoIngreso";
import PersonalDocumentsColaboradores from "./Colaboradores/PersonalDocumentsColaboradores";
import PersonalDocumentsInactivos from "./Inactivos/PersonalDocumentsInactivos";
import CheckPermission from "../../../helpers/Administradores/Permissions";
import PersonalDocuments from "./PersonalDocuments";
import OptionalDocuments from "./OptionalDocuments";
import OfertaLaboral from "./OfertaLaboral";

const Expedient = (processProp?: any) => {
  let location = useLocation();
  let nuevoArrayEtiquetas = [];
  let nuevoArrayTablas = [];
  const [processTab, setProcessTab] = useState<any>();
  useEffect(() => {
    if (processProp) {
      setProcessTab(processProp?.process);
    }
  }, [processProp]);

  let section = location.pathname.split("/")[1];

  if (section === "collaborators-view") {
    if (
      CheckPermission("Colaboradores.Inactivos.Ver.Expediente.DatosPersonales")
    ) {
      nuevoArrayEtiquetas.push("Documentos personales");
      nuevoArrayTablas.push(PersonalDocumentsInactivos);
    }
    if (
      CheckPermission(
        "Colaboradores.Inactivos.Ver.Expediente.DocumentosOpcionales"
      )
    ) {
      nuevoArrayEtiquetas.push("Documentos complementarios");
      nuevoArrayTablas.push(OptionalDocumentsInactivos);
    }
    if (
      CheckPermission("Colaboradores.Inactivos.Ver.Expediente.OfertaLaboral")
    ) {
      nuevoArrayEtiquetas.push("Documentos para firma");
      nuevoArrayTablas.push(OfertaLaboralInactivos);
    }
  }

  if (section === "collaborators") {
    if (
      CheckPermission(
        "Colaboradores.Colaboradores.Editar.Expediente.DatosPersonales.Ver"
      )
    ) {
      nuevoArrayEtiquetas.push("Documentos personales");
      nuevoArrayTablas.push(PersonalDocuments);
    }
    if (
      CheckPermission(
        "Colaboradores.Colaboradores.Editar.Expediente.DocumentosOpcionales.Ver"
      )
    ) {
      nuevoArrayEtiquetas.push("Documentos complementarios");
      nuevoArrayTablas.push(OptionalDocuments);
    }
    if (
      CheckPermission(
        "Colaboradores.Colaboradores.Editar.Expediente.OfertaLaboral.Ver"
      )
    ) {
      nuevoArrayEtiquetas.push("Documentos para firma");
      nuevoArrayTablas.push(OfertaLaboral);
    }
  }

  if (section === "nuevoingreso") {
    if (
      CheckPermission(
        "Colaboradores.NuevoIngreso.Ver.Expediente.DocumentosPersonales"
      )
    ) {
      nuevoArrayEtiquetas.push("Documentos personales");
      nuevoArrayTablas.push(PersonalDocumentsNuevoIngreso);
    }
    if (
      CheckPermission(
        "Colaboradores.NuevoIngreso.Ver.Expediente.DocumentosOpcionales"
      )
    ) {
      nuevoArrayEtiquetas.push("Documentos complementarios");
      nuevoArrayTablas.push(OptionalDocumentsNuevoIngreso);
    }
    if (
      CheckPermission("Colaboradores.NuevoIngreso.Ver.Expediente.OfertaLaboral")
    ) {
      nuevoArrayEtiquetas.push("Documentos para firma");
      nuevoArrayTablas.push(OfertaLaboralNuevoIngreso);
    }
  }

  if (section === "inactivos") {
    if (
      CheckPermission("Colaboradores.Inactivos.Ver.Expediente.DatosPersonales")
    ) {
      nuevoArrayEtiquetas.push("Documentos personales");
      nuevoArrayTablas.push(PersonalDocumentsInactivos);
    }
    if (
      CheckPermission(
        "Colaboradores.Inactivos.Ver.Expediente.DocumentosOpcionales"
      )
    ) {
      nuevoArrayEtiquetas.push("Documentos complementarios");
      nuevoArrayTablas.push(OptionalDocumentsInactivos);
    }
    if (
      CheckPermission("Colaboradores.Inactivos.Ver.Expediente.OfertaLaboral")
    ) {
      nuevoArrayEtiquetas.push("Documentos para firma");
      nuevoArrayTablas.push(OfertaLaboralInactivos);
    }
  }

  if (section === "candidate") {
    nuevoArrayEtiquetas.push("Documentos personales");
    nuevoArrayTablas.push(PersonalDocumentsColaboradores);
    nuevoArrayEtiquetas.push("Documentos complementarios");
    nuevoArrayTablas.push(OptionalDocumentsColaboradores);
    nuevoArrayEtiquetas.push("Documentos para firma");
    nuevoArrayTablas.push(OfertaLaboralColaboradores);
  }

  return (
    <List
      title="EXPEDIENTE"
      labels={nuevoArrayEtiquetas}
      components={nuevoArrayTablas}
      numTab={6}
      progress={4}
      process={processTab}
    />
  );
};

export default Expedient;
