import "./styles.css"
import ClockCalendar from "./ClockCalendar";
import CalendarsState from "../../context/NewCalendarContext/CalendarsState";

interface TabCalendar {
  children?: React.ReactNode;
  idCalendar: any;
  nameCalendar: any
};

function Clock(props: TabCalendar) {
  const { idCalendar, nameCalendar } = props;
  return (
    <CalendarsState>
      <ClockCalendar idCalendar ={idCalendar} nameCalendar={nameCalendar}/>
    </CalendarsState>
    

  )
  
}

export default Clock;