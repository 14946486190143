import { useContext, useEffect, useState, useRef } from "react";
import EmpresaContext from "../../../context/Empresa/EmpresaContext";
import {
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
} from "@material-ui/core";
import Box from '@mui/material/Box';
import { clearEmpresaModal } from "../../../context/Empresa/Actions";
import styles from "../../../styles/MiEmpresa/CrearProyecto2.module.css";
import { UPDATE_SEDE, GET_ALL_SEDE, GET_SEDE } from "../../../Querys/querys";
import { useLazyQuery, useMutation } from "@apollo/client";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { SuccessfulAlert } from "../../../alerts/successAlerts";
import { Button, TextField } from "@material-ui/core";

const EditaProjects2 = (props: any) => {
  const { state, dispatch } = useContext(EmpresaContext);
  const { isSaving, setIsSaving } = props;

  const [createNewProyect] = useMutation(UPDATE_SEDE, {
    refetchQueries: [
      { query: GET_ALL_SEDE },
      { query: GET_SEDE, variables: { id: state._id } },
    ],
  });

  const [getSede, { loading, data:resultData }] = useLazyQuery(GET_SEDE, {
    fetchPolicy: 'no-cache'
  });

  const [sede, setSede]:any = useState({});

  useEffect(()=>{
    if(state.updateSede){
      getSede({
        variables: {
          id: state._id,
        }
      });
    }
  },[state.updateSede]);
  
  useEffect(()=>{
    if(resultData && resultData.GET_SEDE){
      setSede(resultData.GET_SEDE);
    }
  }, [resultData]);

  const initalValues = () => {
    return {
      nombre: sede?.sedeName,
    };
  };

  const handleClose = () => {
    clearEmpresaModal({}, dispatch);
  };

  const formikRef:any = useRef();


  useEffect(()=>{
    if(formikRef.current){
      formikRef.current.setFieldValue('nombre', sede?.sedeName);
    }
  },[sede]);

  return (
    <Dialog
      open={state.updateSede}
      aria-labelledby="form-dialog-title"
      onClose={handleClose}
      maxWidth={"md"}
    >
      <Formik
        initialValues={initalValues()}
        validationSchema={Yup.object(validationSchema())}
        innerRef={formikRef}
        onSubmit={(formData) => {
          createNewProyect({
            variables: {
              updateSedeId: state._id,
              input: {
                sedeName: formData.nombre,
              },
            },
          }).then(() => {
            handleClose();
            SuccessfulAlert({
              title: "¡Exito!",
              text: "¡Registro editado!",
            });
            setIsSaving(false);
          });
        }}
      >
        {({ values, errors, touched, handleChange, handleSubmit }) => (
          <Form>
            <h3 id="form-dialog-title" className="text-center">
              Editar sede de la empresa
            </h3>
            <DialogContent dividers>
              <Box display="flex" flexDirection="column">
                <Box mb={3}>
                  <Grid container spacing={1}>
                    <Grid container item xs={12} spacing={3}>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          className={styles.input}
                          name="nombre"
                          label="Nombre"
                          variant="outlined"
                          value={values.nombre || ''}
                          onChange={handleChange}
                          error={touched.nombre && Boolean(errors.nombre)}
                          helperText={touched.nombre && errors.nombre}
                          size="small"
                          inputProps={{ maxLength: 30 }}
                          onKeyPress={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </DialogContent>
            <DialogActions style={{ justifyContent: "center" }}>
              <Button autoFocus onClick={handleClose} className="buttonCancel">
                Cerrar
              </Button>
              {!isSaving ? (
                <Button
                  autoFocus
                  type="submit"
                  className="buttonSave"
                  onClick={() => {
                    setIsSaving(true);
                    handleSubmit();
                  }}
                >

                  Guardar
                </Button>
              ) : (
                <Button
                  autoFocus
                  className="buttonSave"
                  style={{
                    opacity: "0.5",
                  }}
                >

                  Guardar
                </Button>
              )}
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

const validationSchema = () => {
  return {
    nombre: Yup.string().required("El nombre es requerido"),
  };
};

export default EditaProjects2;
