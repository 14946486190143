/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useContext, useEffect } from 'react';
import { Grid } from "@material-ui/core";
import Box from '@mui/material/Box';
import { useHistory, useParams } from 'react-router-dom';
import moment from 'moment';
import { useLazyQuery, useMutation } from '@apollo/client';

import CustomTabs from '../components/Collaborators/Tab/CustomTabs';
import CustomTab from '../components/Collaborators/Tab/CustomTabMain';
import ObjectivesTable from '../components/Goals/ObjectivesTable/ObjectivesTable';
import AddTable from './Colaboradores/Colaboradores/CollaboratorTab/AddTable';
import CreateObjectiveModal from '../components/Goals/Modals/CreateObjectiveModal';
import ObjectivesContext, { Types } from '../context/ObjectiveContext/ObjectiveContext';
import AddEvidenceModal from '../components/Goals/Modals/AddEvidenceModal';
import SelectCollaborators from '../components/Goals/Modals/SelectCollaborators';
import { APPROVE_OBJECTIVES, GET_CAMPAIGN_BY_ID, NOTIFY_LEADER } from '../Querys/campaigns/querys';
import { AdminContext } from '../context/AdminContext/AdminContext';
import CampaignUsersTable from '../components/Goals/CampaignUsersTable/CampaignUsersTable';
import FiltroEstado from '../components/Campaigns/components/FiltroEstado';
import Header from '../components/Header/Header';
import { SuccessfulAlert } from '../alerts/successAlerts';

import '../components/Team/Team.css';
import styles from '../components/Goals/Objectives.module.css';

const ObjectivesScreen = () => {
  const { campaign: campaignIdFromUrl, id, user, token } =
    useParams<{ campaign: string; user: string; token: string; id: string }>();
  const history = useHistory();
  const { state, dispatch } = useContext(ObjectivesContext);
  const { adminState } = useContext(AdminContext);
  const [getCampaignById] = useLazyQuery(GET_CAMPAIGN_BY_ID, { fetchPolicy: 'no-cache' });
  const [approveObjective] = useMutation(APPROVE_OBJECTIVES);
  const [notifyLeaderMutation] = useMutation(NOTIFY_LEADER);
  const [leaderNotificationSent, setLeaderNotificationSent] = useState(false);
  const [isObjectivesApproved, setIsObjectivesApproved] = useState(false);

  const [estado, setEstado] = useState('definir')

  const getCampaignInfo = async () => {
    const { data } = await getCampaignById({
      variables: {
        id: id
      },
      // fetchPolicy: 'no-cache'
    })
    const campaignInfo = data?.GET_CAMPAIGN_BY_ID?.[0];
    setEstado(campaignInfo?.step || 'definir');
    if (campaignInfo?.id) {
      dispatch({ type: Types.SET_CAMPAIGN_INFO, payload: campaignInfo })
      if (state.rol === 'collaborator') {
        dispatch({ type: Types.SET_ACTIVE_TABLE, payload: 'collaborator' })
      } else {
        dispatch({ type: Types.SET_ACTIVE_TABLE, payload: 'campaign' })
      }
    }
  }

  useEffect(() => {
    if (id) {
      dispatch({ type: Types.SET_CAMPAIGN_ID, payload: parseInt(id, 10) })
      getCampaignInfo()
    }
  }, [id]);

  useEffect(() => {
    if (!state?.userFromLink?.id && !adminState?.id) {
      if (!state?.userFromLink?.id) {
        history.push(`/campaign/${campaignIdFromUrl}/user/${user}/token/${token}`)
      }
    }
  }, []);

  useEffect(() => {
    if (adminState?.id) {
      dispatch({ type: Types.SET_COLLABORATOR_ID, payload: parseInt(adminState?.id, 10) })
    }
  }, [adminState]);

  const openCreateModal = () => {
    dispatch({ type: Types.CREATE_MODAL })
  }

  const setActiveTable = (table: string) => {
    dispatch({ type: Types.SET_ACTIVE_TABLE, payload: table })
  }

  useEffect(() => {
    return () => {
      dispatch({ type: Types.CLEAR_OBJECTIVE_STATE });
    }
  }, []);

  const approveObjectives = async () => {
    await approveObjective({
      variables: {
        input: {
          campaignId: parseInt(state.campaign?.id as string, 10),
          collaboratorId: state.collaboratorId
        }
      }
    })
    SuccessfulAlert({ text: "Objetivos aprobados exitosamente" })
    setIsObjectivesApproved(true);
  }

  const notifyLeader = async () => {
    await notifyLeaderMutation({
      variables: {
        input: {
          campaignId: parseInt(state.campaign?.id as string, 10),
          collaboratorId: state.collaboratorId
        }
      }
    })
    SuccessfulAlert({ text: "Líder notificado exitosamente" })
    setLeaderNotificationSent(true);
  }

  const goBack = () => {
    if (state.activeTable === "collaborator") {
      if (state.rol === 'collaborator') {
        history.push(`/campaign/${campaignIdFromUrl}/user/${user}/token/${token}`)
      } else {
        setActiveTable("campaign")
      }
    } else if (state.activeTable === "campaign") {
      if (state?.userFromLink?.id) {
        history.push(`/campaign/${campaignIdFromUrl}/user/${user}/token/${token}`)
      } else {
        history.push('/campanias')
      }
      dispatch({ type: Types.RESET_OBJECTIVE_STATE })
    }
  }

  return (
    <Box style={{
      ...(campaignIdFromUrl && {
        backgroundColor: 'rgb(245, 246, 249)'
      })
    }}>
      {campaignIdFromUrl && (
        <Header fromLink />
      )}

      {
        state.rol !== 'collaborator' && (
          <>
            <Box mt={3} ml={5} className="Title">
              KPI's y bonos
            </Box>
            <Box mt={3} ml={5}>
              <Grid
                container
                justify="flex-start"
                alignItems="center"
              >
                <Grid item xs={12} md={6}>
                  <CustomTabs
                    value={state.campaign?.status === 'finalizado' ? 1 : 0}
                    onChange={() => console.log("")}
                    aria-label="campaign tabs"
                  >
                    <CustomTab label="Campañas" value={0} />
                    <CustomTab label="Históricas" value={1} />
                  </CustomTabs>
                </Grid>
              </Grid>
            </Box>
            <Box mt={3} ml={5} mr={5} className={styles.mainBox}>
              <section>
                <FiltroEstado
                  filtroEstado={estado}
                  setFiltroEstado={setEstado}
                />
              </section>
              <section className={styles.mainActionsContainer}>
                {
                  state.activeTable === "collaborator"
                  && ((state.campaign?.minObjectives || 0) <= state.collaboratorObjectivesCount)
                  && state.campaign?.status !== 'finalizado'
                  && (state.campaign?.step === 'crear' || state.campaign?.step === 'definicion')
                  && isObjectivesApproved === false && state.displayApproveObjectiveButtons && (
                    <AddTable
                      func={approveObjectives}
                      text="Aprobar objetivos"
                      noIcon
                    />
                  )
                }
                {
                  ((state.activeTable === "collaborator"
                    && (state.collaboratorObjectivesCount < (state.campaign?.maxObjectives || 0)))
                    || (state.activeTable !== 'collaborator'))
                  && state.campaign?.status !== 'finalizado'
                  && (state.campaign?.step === 'crear' || state.campaign?.step === 'definicion') && (
                    <AddTable
                      func={openCreateModal}
                      text="Crear Objetivo"
                      img="add-icon.svg"
                    />
                  )
                }
              </section>
            </Box>
          </>
        )
      }

      {
        state.rol === 'collaborator' && (
          <Box mt={3} ml={5} mr={5} className={styles.mainBoxCollaborator}>
            <Box mt={3} ml={5} className="Title">
              KPI's y bonos
            </Box>
            <section className={styles.mainActionsCollaboratorContainer} style={{
              height: '36px'
            }}>
              {
                state.activeTable === "collaborator"
                && ((state.campaign?.minObjectives || 0) <= state.collaboratorObjectivesCount)
                && state.campaign?.status !== 'finalizado'
                && (state.campaign?.step === 'crear' || state.campaign?.step === 'definicion')
                && leaderNotificationSent === false && state.displayNotifyLeaderButton && (
                  <AddTable
                    func={notifyLeader}
                    text="Notificar al líder"
                    noIcon
                  />
                )
              }
              {
                state.activeTable === "collaborator"
                && (state.collaboratorObjectivesCount < (state.campaign?.maxObjectives || 0))
                && state.campaign?.status !== 'finalizado'
                && (state.campaign?.step === 'crear' || state.campaign?.step === 'definicion') && (
                  <AddTable
                    func={openCreateModal}
                    text="Crear Objetivo"
                    img="add-icon.svg"
                  />
                )
              }
            </section>
          </Box>
        )
      }

      <section className="contenedor2">
        <Box p={1}>
          <Grid className={styles.paper}>
            <section style={{
              height: '24px',
              marginBottom: '20px'
            }}>
              <button style={{
                display: 'flex',
                alignItems: 'center',
                backgroundColor: 'transparent',
                border: 'none',
                cursor: 'pointer',
                height: '24px'
              }}
                onClick={goBack}
              >
                <img src="/assets/svg/back-button.svg" alt="Regresar" />
                <span style={{
                  fontFamily: 'Roboto',
                  fontSize: '16px',
                  // fontWeight: '400',
                  lineHeight: '19px',
                  color: '#222222',
                  marginLeft: '20px'
                }}>Regresar</span>
              </button>
            </section>
            {
              state.activeTable === "campaign" && (
                <section className={styles.campaignInfo}>
                  {
                    state.campaign?.id && (
                      <strong className={styles.collaborator}>
                        {state.campaign?.nameCampaign}
                      </strong>
                    )
                  }
                </section>
              )
            }
            {
              state.activeTable === "collaborator" && state.collaboratorName && (
                <section className={styles.campaignInfo}>
                  <button className={styles.campaignTitle} onClick={() => setActiveTable("campaign")}>
                    {state.campaign?.nameCampaign}
                  </button>
                  <span className={styles.slash}>/</span>
                  <strong className={styles.collaborator}>
                    {state.collaboratorName}
                  </strong>
                </section>
              )
            }

            {
              state.campaign?.id && (
                <section className={styles.period}>
                  Periodo: {moment(state.campaign?.startCampaign).format("DD/MM/YYYY")} - {moment(state.campaign?.endCampaign).format("DD/MM/YYYY")}
                </section>
              )
            }
            <section className={styles.tableContainer}>
              {state.activeTable === "campaign" && state.campaignId && (
                <CampaignUsersTable />
              )}
              {state.activeTable === "collaborator" && state.campaignId && (
                <ObjectivesTable />
              )}
            </section>
          </Grid>
        </Box>
      </section>

      <CreateObjectiveModal />
      <AddEvidenceModal />
      <SelectCollaborators />
    </Box>
  )
};

export default ObjectivesScreen;

