import {createContext, Dispatch} from 'react';

type InitialStateType = {
    open: boolean;
    showInformacion:boolean;
    showEdit: boolean;
    showEliminar: boolean;
    _id: string;
    value: string;
    client: string;
    title: string;
    ConceptName: string,
    SATKey: string,
    ISRTax: boolean,
    TaxBoth: boolean,
    showEliminaModal: boolean,
    gravaISR: boolean,
    time: boolean,
    isMoney: boolean,
    muestraCalendario: boolean
};

type ModalPartial = {
    state: InitialStateType,
    dispatch: Dispatch<any>,
}
const initialState = {
    open: false,
    showInformacion: false,
    showEdit: false,
    showEliminar: false,
    _id: '',
    value: '',
    client: '',
    title: '',
    ConceptName: '',
    SATKey: '',
    ISRTax: false,
    TaxBoth: false,
    showEliminaModal: false,
    gravaISR: false,
    time: false,
    isMoney: false,
    muestraCalendario: false,
}

const ModalInitialState = {
    state: initialState,
    dispatch: () => null
}

const DeduccionContext = createContext<ModalPartial>(ModalInitialState);

export default DeduccionContext;