import { Dispatch } from "react";
import {Types} from "./MyMoodContext";

export const openQuestion= ({openQuestion = true, idEncuestas_colaborador="", Encuestas=""}:any, dispatch: Dispatch<any>):void =>{
    
    dispatch({
        type:Types.OPENQUESTION,
        payload:{
            idEncuestas_colaborador,
            Encuestas,
            openQuestion,
        } 
    })
}

export const closeQuestion = ({openQuestion = false}:any, dispatch: Dispatch<any>):void =>{
    
    dispatch({
        type:Types.CLOSEQUESTION,
        payload:{
            openQuestion
        } 
    })
}

export const closeSurvey = ({newSurvey = false}:any, dispatch: Dispatch<any>):void =>{
    
    dispatch({
        type:Types.CLOSE_SURVEY,
        payload:{
            newSurvey
        } 
    })
}

export const openSurvey = ({newSurvey = true}:any, dispatch: Dispatch<any>):void =>{
    dispatch({
        type:Types.NEW_SURVEY,
        payload:{
            newSurvey
        } 
    })
}

export const closeRiskSurvey = ({newRiskSurvey = false}:any, dispatch: Dispatch<any>):void =>{
    
    dispatch({
        type:Types.CLOSE_RISK_SURVEY,
        payload:{
            newRiskSurvey
        } 
    })
}

export const openRiskSurvey = ({newRiskSurvey = true}:any, dispatch: Dispatch<any>):void =>{
    dispatch({
        type:Types.NEW_RISK_SURVEY,
        payload:{
            newRiskSurvey
        } 
    })
}

export const openTestComments= ({openComments = true, idSurvey=0, Encuestas=''}:any, dispatch: Dispatch<any>):void =>{
    dispatch({
        type:Types.OPEN_TESTCOMMENTS,
        payload:{
            idSurvey,
            openComments,
            Encuestas
        } 
    })
}

export const closeTestComments= ({openComments = false}:any, dispatch: Dispatch<any>):void =>{
    
    dispatch({
        type:Types.CLOSE_TESTCOMMENTS,
        payload:{
            openComments,
        } 
    })
}

export const openFilter = ({openFilter = true}:any, dispatch: Dispatch<any>):void =>{
    
    dispatch({
        type:Types.OPENFILTER,
        payload:{
            openFilter
        } 
    })
}