import styles from "./PayrollStyles.module.css";
import { useLazyQuery, useQuery } from "@apollo/client";
import {
  GET_PAYROLLCOLLABORATOR_IMPUESTO,
  GET_EXCEL_TAXES_REPORT_BY_PAYROLL_GROUP,
  GET_ALL_ENTERPRISE
} from "../../Querys/querys";
import { useEffect, useState, useContext } from "react";
import { formatter } from "../../helpers/formatoMoneda";
import AlertaReporte from "../Payroll/Modals/AlertaReportes";
import { openalertaReporte } from "../../context/PayrollProcess/Actions";
import PayrollProcessContext from "../../context/PayrollProcess/PayrollProcessContext";
import { server } from "../../global/server";
import {CreateExcelPayrollReportISN, CreateExcelPayrollReportINFONAVIT,CreateExcelPayrollINCIDENCIAS} from "../../components/Payroll/Modals/ExcelPayrollReport";
import axios from "axios";

const ReportsPayrollTable = () => {
  const {  dispatch } = useContext(PayrollProcessContext);
  const [mensajeAlerta, setMensajeAlerta] = useState("");
  const [datos, setDatos] = useState({
    tipoReporte: "",
    Anio: "",
    Mes: 0,
    Enterprise: 0,
  });
  const { data, refetch } = useQuery(
    GET_PAYROLLCOLLABORATOR_IMPUESTO
  );
  const resultado = data?.GET_PAYROLLCOLLABORATOR_IMPUESTO;
  
  const { data: resultCompany } = useQuery(GET_ALL_ENTERPRISE);
  const allCompanies = resultCompany?.GET_ALL_ENTERPRISE;

  const [getExcelReportByPayRollGroup] = useLazyQuery(GET_EXCEL_TAXES_REPORT_BY_PAYROLL_GROUP);
  
  useEffect(() => {}, [datos]);

  const cambioDatos = (valor: any, desde: string) => {
    if (desde === "tipoReporte") {
      setDatos({ ...datos, tipoReporte: valor.target.value });
    }
    if (desde === "Anio") {
      setDatos({ ...datos, Anio: valor.target.value });
    }
    if (desde === "Mes") {
      setDatos({ ...datos, Mes: parseInt(valor.target.value) });
    }
    if (desde === "Empresa") {
      setDatos({ ...datos, Enterprise: parseInt(valor.target.value) });
    }
  };

  const consultaReporte = () => {
    switch (true) {
      case datos.tipoReporte === "" && datos.Anio === "" && datos.Mes === 0:
        setMensajeAlerta(
          "Por favor selecciona alguno de los filtros para continuar"
        );
        openalertaReporte({ alertaReporte: true }, dispatch);
        break;
      case datos.tipoReporte === "" && datos.Anio === "" && datos.Mes !== 0:
        setMensajeAlerta("Por favor selecciona Tipo o Año para continuar");
        openalertaReporte({ alertaReporte: true }, dispatch);
        break;
      case datos.tipoReporte !== "" && datos.Anio === "" && datos.Mes !== 0:
        setMensajeAlerta("Por favor selecciona Año para continuar");
        openalertaReporte({ alertaReporte: true }, dispatch);
        break;
      case datos.tipoReporte !== "" && datos.Anio === "" && datos.Mes === 0:
        setMensajeAlerta("Por favor selecciona Año o Mes para continuar");
        openalertaReporte({ alertaReporte: true }, dispatch);
        break;
      case datos.tipoReporte === "" && datos.Anio !== "" && datos.Mes === 0:
        setMensajeAlerta("Por favor selecciona Tipo o Mes para continuar");
        openalertaReporte({ alertaReporte: true }, dispatch);
        break;
      default:
        refetch({
          input: datos,
        });
    }
  };
  const exportarExcel2 = async (TipoReporte: string, Mes: string) => {
    
    let selectedMonth = ''
    if (TipoReporte !== 'INFONAVIT'){
      const meses = [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre",
      ];
      selectedMonth = String(meses.indexOf(Mes) + 1);
    }else{
      const meses = [
        "Enero - Febrero",
        "Marzo - Abril",
        "Mayo - Junio",
        "Julio - Agosto",
        "Septiembre - Octubre",
        "Noviembre - Diciembre",
      ];
      selectedMonth = String((meses.indexOf(Mes) + 1) * 2);
    }
    
    let variables = {
      input: {
        TipoReporte,
        Anio: datos.Anio,
        Mes: selectedMonth.toString(),
        Enterprise: datos.Enterprise
      },
    };    
 
    const { data: dataReport } = await getExcelReportByPayRollGroup({variables,});
    
    const resultReport = JSON.parse(dataReport?.GET_EXCEL_TAXES_REPORT_BY_PAYROLL_GROUP);

    if (resultReport) {
      if (TipoReporte === 'ISN' || TipoReporte === 'ISR' || TipoReporte === 'IMSS'){
       
        CreateExcelPayrollReportISN(resultReport, TipoReporte);
      }
      else if(TipoReporte === 'INCIDENCIAS'){
              CreateExcelPayrollINCIDENCIAS(resultReport, TipoReporte);
      }      
      else{
        CreateExcelPayrollReportINFONAVIT(resultReport, TipoReporte);
        
      }
      
    }
  };

  const exportarPDF2 = async (TipoReporte: string, Mes: string) => {    
    //get number of month from month's name
    let selectedMonth = ''
    if (TipoReporte !== 'INFONAVIT'){
      const meses = [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre",
      ];
      selectedMonth = String(meses.indexOf(Mes) + 1);
    }else{
      const meses = [
        "Enero - Febrero",
        "Marzo - Abril",
        "Mayo - Junio",
        "Julio - Agosto",
        "Septiembre - Octubre",
        "Noviembre - Diciembre",
      ];
      selectedMonth = String((meses.indexOf(Mes) + 1) * 2);
    }
    
    const PDF: any = await axios.get(`${server}/report/pdfTaxReport/${TipoReporte}/${datos.Anio}/${selectedMonth}/${datos.Enterprise}`)
    const link = document.createElement("a");
    link.href = PDF?.data?.urlPDF;
    link.setAttribute("download", "downloadtesting.PDF");
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  return (
    <>
      <div className={styles.r_contenedorTitulos}>
        <div className={styles.r_flex}>
          <div className={styles.r_titulo}>Tipo</div>
          <div>
            <select
              name="selectTipo"
              className={styles.r_select}
              onChange={(target) => cambioDatos(target, "tipoReporte")}
            >
              <option value="">Selecciona...</option>
              <option value="IMSS">IMSS</option>
              <option value="INCIDENCIAS">INCIDENCIAS</option>
              <option value="ISR">ISR</option>
              <option value="ISN">ISN</option>
              <option value="INFONAVIT">INFONAVIT</option>
              <option value="FONACOT">FONACOT</option>
              
            </select>
          </div>
        </div>
        <div className={styles.r_flex}>
          <div className={styles.r_tituloFecha}>A&ntilde;o</div>
          <select
            name="selectTipo"
            className={styles.r_select}
            onChange={(target) => cambioDatos(target, "Anio")}
          >
            <option value="">Selecciona...</option>
            <option value="2022">2022</option>
            <option value="2023">2023</option>
            <option value="2024">2024</option>
            <option value="2025">2025</option>
            <option value="2026">2026</option>
            <option value="2027">2027</option>
            <option value="2028">2028</option>
            <option value="2029">2029</option>
          </select>
        </div>
        <div className={styles.r_flex}>
          <div className={styles.r_tituloFecha}>Mes</div>
          <select
            name="selectTipo"
            className={styles.r_select}
            onChange={(target) => cambioDatos(target, "Mes")}
          >
            <option value="0">Selecciona...</option>
            <option value="1">Enero</option>
            <option value="2">Febrero</option>
            <option value="3">Marzo</option>
            <option value="4">Abril</option>
            <option value="5">Mayo</option>
            <option value="6">Junio</option>
            <option value="7">Julio</option>
            <option value="8">Agosto</option>
            <option value="9">Septiembre</option>
            <option value="10">Octubre</option>
            <option value="11">Noviembre</option>
            <option value="12">Diciembre</option>
          </select>
        </div>
        <div className={styles.r_flex}>
          <div className={styles.r_tituloFecha}>Empresa</div>
          <select
            style={{ width: "auto" }}
            name="selectEmpresa"
            className={styles.r_select}
            onChange={(target) => cambioDatos(target, "Empresa")}
          >
           <option value="0">Selecciona...</option>
            {
              allCompanies?.map((company: any) => (
                <option value={company.id}>{company.name}</option>
              ))
            }
          </select>
        </div>
        <button
          className={styles.pc_botonCalcular}
          onClick={() => consultaReporte()}
        >
          <div className={styles.pc_iconoCalcular}></div>
          Calcular
        </button>
        <div></div>
      </div>
      <div className={styles.r_contenedorPrincipal}>
        <div className={styles.r_contenedorTarjetas}>
          {resultado?.map((lis: any) => (
            <div className={styles.r_tarjeta}>
              <div className={styles.r_barraTarjeta}></div>
              <div className={styles.r_datosTarjeta}>
                <div className={styles.r_tituloTarjeta}>
                  {
                  lis.Tipo ==='IMSS' ? 'Retenciones IMSS' : lis.Tipo
                  }
                </div>
                <div className={styles.r_subtituloTarjeta}>Mes</div>
                <div className={styles.r_datoTarjeta}>{lis.Mes}</div>
                <div className={styles.r_subtituloTarjeta}>Monto</div>
                <div className={styles.r_datoTarjeta}>
                  {formatter(lis.MontoImpuesto)}
                </div>
                <div className={styles.r_contenedorIconos}>
                  <div
                    className={styles.pt_iconoTarjetasExcel}
                    onClick={() => {
                      exportarExcel2(lis.Tipo, lis.Mes);
                    }}
                  ></div>
                  <div
                    className={styles.pt_iconoTarjetasPDF}
                    onClick={() => {
                      exportarPDF2(lis.Tipo, lis.Mes);
                    }}
                  ></div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <AlertaReporte mensaje={mensajeAlerta} />
    </>
  );
};

export default ReportsPayrollTable;
