import { useMutation, useQuery } from "@apollo/client";
import { Grid } from "@material-ui/core";
import Box from '@mui/material/Box';
import { useHistory, useParams } from "react-router-dom";
import { useState, useEffect, useCallback } from "react";
import { getDateDay, getDateYear } from "../helpers/Payroll/Payroll";
import styles from "../../src/components/Payroll/PayrollStyles.module.css"
import { 
        GET_PAYROLLSETTELEMNT_REPO_CANCEL,
        GET_SETTLEMENTCOLLABORATOR_CANCEL_TIMBRE,
        UPDATE_SETTLEMENT_COLLABORATOR_TOKENCANCELATIMBRE,
        UPDATE_SETTLMENT_COLLABORATOR_XML_ACUSE,
        GET_ALL_COMPANY,
        GET_SETTLEMENTCOLLABORATORTIMBRADO
      } from "../Querys/querys";
import CustomTabs from '../components/Collaborators/Tab/CustomTabs';
import CustomTab from '../components/Collaborators/Tab/CustomTabMain';
import {formatter} from "../helpers/formatoMoneda"
import { v4 as uuid } from "uuid";
import {
  postCancelarTimbrarNominaFiniquito
} from "../services/cfdiService";
import {
  postXMLReciboAWSTypeDate,
  postMailSettlementAcuse
} from "../services/candidateService";
import { WarningAlertTimbre } from "../alerts/WarningAlertTimbre";
import descargaXml from "../assets/svg/icono_descarga_xml.svg";
import descargaAcuse from "../assets/svg/AcuseXML.svg";
import iconoPDF from "../assets/svg/icono_descargar_pdf.svg";
import Cancel from "../assets/svg/Cancel.svg";
import CheckPermission from "../helpers/Administradores/Permissions";
import LockedSystem from "../alerts/LockedSystem";

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

const PayrollCancelTimbrarSettlement = (props: TabPanelProps) => {
  const { id, mail, all } = useParams<any>();
  const [filtrado, setFiltrado] =  useState<any[]>([])
  const [isCheck, setIsCheck] =  useState<any[]>([])
  const [timbrar, setTimbrar] =  useState(true)
  const history = useHistory();
  const [PayrollData, setPayrollData] = useState<any>([]);

  const {loading, data} = useQuery(GET_PAYROLLSETTELEMNT_REPO_CANCEL,{
    variables:{id: id}
  })
  
  const PayrollData1 = data?.GET_PAYROLLSETTELEMNT_REPO_CANCEL

  /*--------------------------sistemaActivo--------------------*/
  const { data: resultCompany, startPolling, stopPolling } = useQuery(GET_ALL_COMPANY);
  const myCompany = resultCompany?.GET_ALL_COMPANY;
  const [sistemaActivo, setSistemaActivo] = useState<any>(true);
  useEffect(()=>{
    startPolling(1000);
    return () =>{
      stopPolling()
    }
  },[startPolling,stopPolling])

  useEffect(() => {
    if(myCompany){
      setSistemaActivo(myCompany[0]?.SistemaActivo)
    }
  }, [myCompany]);
  /*--------------------------sistemaActivo--------------------*/

  useEffect(() => {

    if(PayrollData1){
      setPayrollData(PayrollData1)
    }
  }, [PayrollData1])
  

  const resultPayrollTimbrado = useQuery(GET_SETTLEMENTCOLLABORATOR_CANCEL_TIMBRE, {
    variables: { getPayrollcollaboratorTimbradoId: id },
  });

  const allPayrollTimbrado = resultPayrollTimbrado.data?.GET_SETTLEMENTCOLLABORATOR_CANCEL_TIMBRE;

  const handleChange = () =>{
  }

  const addAll = useCallback(()=>{

    let filtradoSeleccionado = (allPayrollTimbrado.filter((lis:any)=>{
      return (!lis.CancelaSATfecha || lis.CancelaSATfecha === undefined)
    }).map((lis:any) =>(lis.id)))

    setIsCheck(filtradoSeleccionado)
  },[allPayrollTimbrado])


  useEffect(() => {
    if(allPayrollTimbrado){
      setFiltrado(allPayrollTimbrado)
      if(String(all) === '1'){
        addAll();
      }
    }
    
  }, [allPayrollTimbrado, all, addAll]);


  const datosFiltrados = (e:any) =>{
    if(e !== '') {
      let expresion = new RegExp(`${e}.*`, "i")
      const nuevoFiltrado = filtrado.filter((lis:any) => expresion.test(lis.colaborator))
      setFiltrado(nuevoFiltrado)
    }else{
      setFiltrado(allPayrollTimbrado)
    }
  }

  const [updateTokenTimbre] = useMutation(UPDATE_SETTLEMENT_COLLABORATOR_TOKENCANCELATIMBRE);

  const [updateXML] = useMutation(UPDATE_SETTLMENT_COLLABORATOR_XML_ACUSE,{
    refetchQueries: [
      {query:GET_SETTLEMENTCOLLABORATOR_CANCEL_TIMBRE, variables:{getPayrollcollaboratorTimbradoId: id}},
      {query:GET_PAYROLLSETTELEMNT_REPO_CANCEL, variables:{id: id}},
      {query:GET_SETTLEMENTCOLLABORATORTIMBRADO, variables:{getSettlementPayrollcollaboratorTimbradoId: id}},
      ],
  });

  const handleSelectAll = (e:any) =>{

    if(!e.target.checked){
      setIsCheck([])
    }

    let filtradoSeleccionado = (allPayrollTimbrado.filter((lis:any)=>{
      return e.target.checked && !isCheck.includes(lis.id) && (!lis.CancelaSATfecha || lis.CancelaSATfecha === undefined)
    }).map((lis:any) =>(lis.id)))

    setIsCheck(filtradoSeleccionado)
  }

  const handleClick =  (e:any) => {
    
    const {id, checked} = e.target
    setIsCheck([...isCheck, id])
    if(!checked){
      setIsCheck(isCheck.filter((lis:any) => lis !== id))
    }

  }

  const cancelarNomina = async () =>{
    
    setTimbrar(false)
    let tokenTimbre = uuid();


    if(isCheck?.length > 0){

      for (const payrollColaboration of isCheck){
        await updateTokenTimbre({
          variables: {
            id: String(payrollColaboration),
            tokenTimbre: tokenTimbre
          },
        })
      }

      if(tokenTimbre !== undefined){
        if( tokenTimbre !== "" ){
          
          const restultSELLO = await postCancelarTimbrarNominaFiniquito(tokenTimbre);
          
          let text = ""
          let textTimbre = ""
          
          if(restultSELLO !== undefined){
  
            for (const sello of restultSELLO){
              if (sello.CodEstatus === "" || sello.CodEstatus === undefined || sello.CodEstatus === null){
                
                const restultfile = await postXMLReciboAWSTypeDate(sello.Folio, sello.Acuse, sello.Colaborador, sello.Fecha, 'AcuseFiniquito');
                updateXML
                ({
                  variables: {
                    id:sello.Folio,
                    input: {
                      AcuseCancelacion: restultfile
                    },
                  },
                }).then(()=>{
                  
                    if(String(mail) === '1'){
                      postMailSettlementAcuse(sello.Folio)
                    }
                    
                })
                textTimbre = textTimbre +"<b>Documento cancelado:</b><br/><b> "+sello.Colaborador+":</b> "+ sello.UUID+"<br/>"
  
              }else{
  
                text = text +"<b>Problema Colaborador: </b><br/><b> "+sello.Colaborador+":</b> "+ sello.CodEstatus+"<br/>"
              }
            }
    
    
            if(text !== "" || textTimbre !== ""){
              WarningAlertTimbre({
                text: textTimbre+text,
              });
            }
  
          }
          
          setTimbrar(true)
          setIsCheck([])
          
        }
      }

    }
      
  }

  return (
    <>
      {!sistemaActivo ? (
        <LockedSystem/>
      ) : null}
      <Box mt={3} ml={5} className="Title">
        Nóminas
      </Box>
      <Box p={5} pb={3} pt={0}>
        <Grid
          container
          justify="flex-start"
        >
          <CustomTabs
            value={2}
            onChange={handleChange}
            aria-label="simple tabs example"
          > 
            <CustomTab label="Incidencias" value={0} />
            {CheckPermission("Nominas.PreNomina") && (
              <CustomTab label="Pre-Nómina" value={1} />
            )}
            {CheckPermission("Nominas.FiniquitoLiquidacion") && (
            <CustomTab label="Finiquito / Liquidación" value={2} /> )}
            {CheckPermission("Nominas.Eventuales") && (
            <CustomTab label="Eventuales" value={3} />)}
             {CheckPermission("Nominas.Historicas") && (
            <CustomTab label="Históricas" value={4} />)}
             {CheckPermission("Nominas.Calculadora") && (
            <CustomTab label="Calculadora" value={5} />)}
            {CheckPermission("Nominas.Reportes") && (
            <CustomTab label="Reportes" value={6} />)}
          </CustomTabs>
        </Grid>
     </Box>
     <div className={styles.pc_contenedorPrincipal}>
        <div className={styles.pc_tituloContenedorPrincipal}>
            <div className={styles.pc_contenedorNombre}>
            <div>
                <span className={styles.pc_tituloPrincipal}>{PayrollData[0]?.group_name}</span>
            </div>
            {(loading)?
                null
                :
                <div>
                <span className={styles.pc_tituloPeriodo}>Periódo: &nbsp;{getDateDay(PayrollData[0]?.init_date)}</span>&nbsp;-&nbsp;
                <span className={styles.pc_tituloPeriodo}>{getDateDay(PayrollData[0]?.end_date)}</span>&nbsp; 
                <span className={styles.pc_tituloPeriodo}>{getDateYear(PayrollData[0]?.end_date)}</span>
                </div>
            }
            </div>

        </div>
        <div className={styles.pc_contenedorBuscadorDescargar}>
            <div className={styles.pc_contenedorBuscador}>
                <input 
                type="text" 
                placeholder="Buscar al colaborador por nombre"
                className={styles.pc_inputBuscador}
                onChange={(e) => datosFiltrados(e.target.value)}
                >
                </input>
                <div className={styles.pc_iconoBuscador}></div>
            </div>
        </div>
        <div className={styles.p_contenedorTablaDispersion}>
          <table className={styles.pd_tablaPrincipal}>
            <thead className={styles.pd_contenedorTitulos}>
                <td className={`${styles.pt_columna} ${styles.pt_primerColumna}`}>
                    <div id="colaboladores" className={styles.checkboxitem}>
                        <input
                        id="check"
                        type="checkbox"
                        onChange={(e) => handleSelectAll(e)}
                        ></input>
                        <label htmlFor="check"> Colaboradores </label>
                    </div>
                </td>
                <td className={`${styles.pt_columna} ${styles.pt_columnaTitulos}`}>Razón social emisora</td>
                <td className={`${styles.pt_columna} ${styles.pt_columnaTitulos}`}>Tipo</td>
                <td className={`${styles.pt_columna} ${styles.pt_columnaTitulos}`}>Percepciones</td>
                <td className={`${styles.pt_columna} ${styles.pt_columnaTitulos}`}>Deducciones</td>
                <td className={`${styles.pt_columna} ${styles.pt_columnaTitulos}`}>Total</td>
                <td className={`${styles.pt_columna} ${styles.pt_columnaTitulos}`}></td>
            </thead>
            <tbody className={styles.pc_contenedorTitulos}>
              {filtrado?.map((payroll:any)=>(
                <tr>
                  <td className={`${styles.pt_columnaDatos} ${styles.pt_primerColumna}`}>
                      
                      <div id="colaboladores" className={styles.checkboxitem}>
                      <input
                          id={payroll.id}
                          key={payroll.id}
                          type="checkbox"
                          checked ={isCheck?.includes(payroll.id)}
                          onChange={(e) => handleClick(e)}
                          value={payroll.id}
                          disabled={!payroll.CancelaSATfecha || payroll.CancelaSATfecha === undefined? false: true}
                      ></input>
                      <label htmlFor={payroll.id}>  {payroll.colaborator} </label>
                      
                      </div>
                      
                  </td>
                  <td className={styles.pt_columnaDatos}>{payroll?.RazonSocial}</td>
                  <td className={styles.pt_columnaDatos}>{payroll?.Tipo}</td>
                  <td className={styles.pt_columnaDatos}>{formatter(payroll.perception)}</td>
                  <td className={styles.pt_columnaDatos}>{formatter(payroll.deduction)}</td>
                  <td className={styles.pt_columnaDatos}>{formatter(payroll.total)}</td>
                  <td className={`${styles.pt_columnaDatos} ${styles.pt_columnaIconos}`}>
                    <td >
                      {payroll.xml && payroll.xml !== ""?
                            
                        <a
                            style={{ marginRight: "5px", marginLeft: "5px" }}
                            target="_blank"
                            href={payroll?.xml}
                            rel="noopener noreferrer"
                        >
                          <img
                          src={descargaXml}
                          style={{ marginRight: "auto", marginLeft: "auto" }}
                          className="cursor"
                          alt="Descargar XML"
                          />
                        </a>
                            
                      : "" }
                    </td>
                    <td >
                      {payroll.LinkPDF && payroll.LinkPDF !== ""?
                        <a
                        style={{ marginRight: "5px", marginLeft: "5px" }}
                        target="_blank"
                        href={payroll?.LinkPDF}
                        rel="noopener noreferrer"
                        >
                          <img
                              src={iconoPDF}
                              style={{ marginRight: "auto", marginLeft: "auto" }}
                              className="cursor"
                              alt="Descargar PDF"
                          />
                        </a>
                          : ""
                      }
                    </td>
                    <td >
                      {payroll.AcuseCancelacion && payroll.AcuseCancelacion !== ""?
                        <a
                        style={{ marginRight: "5px", marginLeft: "5px" }}
                        target="_blank"
                        href={payroll?.AcuseCancelacion}
                        rel="noopener noreferrer"
                        >
                          <img
                          src={descargaAcuse}
                          style={{ marginRight: "auto", marginLeft: "auto" }}
                          className="cursor"
                          alt="Descargar Acuse"
                          
                          />
                        </a>
                          : ""
                      }
                    </td>
                  
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div>
          <div className={styles.pd_contenedorBarraCancel}>
            <div className={styles.pd_barraEstado} >
              {
                sistemaActivo?
                  (isCheck?.length > 0) && timbrar?
                  <button className={styles.pd_botonCancelar}
                  onClick={() =>{
                    cancelarNomina()
                  } }
                  style={{
                      marginLeft:"auto"
                  }}
                  >
                    <img
                      src={Cancel}
                      alt="Cancelar"
                    />
                    <p>Cancelar</p>
                  </button>
                  :
                  <button className={styles.pd_botonCancelar}
                    style={{
                        marginLeft:"auto",
                        opacity:"0.5"
                    }}
                    >
                      <img
                        src={Cancel}
                        alt="Cancelar"
                      /> 
                      <p>Cancelar</p>
                    </button>
                : null
              }
                  
            </div>
          </div>
        </div>
        <div>
            <div className={styles.pd_contenedorBarraEstado}>
              <div className={styles.pd_barraEstado} >
  
                  <button className={styles.pd_botonSalir}
                  onClick={() => history.push(`/payroll`)}
                  style={{
                      marginLeft:"auto"
                  }}
                  >
                  Salir
                  </button>
              </div>
            </div>
        </div>
      </div>

    </>
  );
};

export default PayrollCancelTimbrarSettlement;