import React, { useState, useEffect, useContext, useCallback } from "react";
import { MenuItem, Select, FormControl, InputLabel } from '@material-ui/core';
import CreateWorkSchedule from "./Modals/clock/CreateWorkSchedule";
import { openNewWorkSchedule, openListWorkSchedule,openListUserCheck } from "../../context/NewCalendarContext/Actions";
import { Grid } from '@material-ui/core';
import ModalState from '../../context/ModalContext/ModalState';
import buildCalendar from "./build";
import "./styles.css";
import dayStyles from "./styles";
import styles from './Calendars.module.css';
import moment from "moment";
import WeekStyles from "./WeekStyles";
import {
    GET_NON_WORKING_BY_MONTH_YEAR,
    GET_ALL_EVENT_CALENDAR_BY_MONTH_YRAR,
    GET_PAYROLL_BY_CALENDAR_BY_MONTH_YEAR,
    GET_DEDUCTIONS_CALENDAR_MONTH_YEAR,
    GET_ALL_PERCEPTIONS_CALENDAR_MONTH_YEAR,
    GET_USERS_BYDAY
} from "../../Querys/querys";
import { useLazyQuery, useQuery } from "@apollo/client";
import { CreateEvent } from './Modals/createEvent';
import { createEventModal } from '../../context/ScheduleContext/Actions';
import CalendarsContext from "../../context/NewCalendarContext/CalendarsContext";
import EventDivModal from "./Modals/EventDivModal";
import CreateNonWorkingDayEditModal3 from '../../components/NewCalendar/Modals/NonWorking/CreateNonWorkingDayEditModal3';
import DeleteEvent from '../../components/NewCalendar/Modals/DeleteEvent';
import CreateEventEditModal2 from '../../components/NewCalendar/Modals/Event/CreateEventEditModal2';
import CheckPermission from '../../helpers/Administradores/Permissions';
import NonWorkingDayDivModal from "./Modals/NonWorkingDayDivModal";
import NonWorkingDayListModal from "./Modals/NonWorkingDayListModal";
import EventListModal from "./Modals/EventListModal";
import "./ClockCalendar.css";
import WorkScheduleList from "./Modals/clock/WorkScheduleList";
import UpdateWorkSchedule from "./Modals/clock/UpdateWorkSchedule";
//* todo crear modal para vista listadoChequeos */
import UserScheduleList from "./Modals/clock/UserScheduleList"
interface TabCalendar {
    children?: React.ReactNode;
    idCalendar: any;
    nameCalendar: any;
};

export default function ClockCalendar(props: TabCalendar) {
    const { idCalendar, nameCalendar } = props;
    let numberWeek: any;
    const [value, setValue] = useState(moment());
    const [daySelectedMonth, setdaySelectedMonth] = useState(value);
    const [selectedfiltto, setselectedfiltto] = useState(false);
    const [filtrado, setFiltrado] = useState([]);
    const [filtradoStart, setFiltradoStart] = useState([]);
    const [nonWorkingDays, setNonWorkingDays] = useState([]);
    const [calendar, setCalendar] = useState<Array<any>>([]);
    const [events, setEvents] = useState([]);
    const [allRecords, setAllRecords] = useState([]);
    const [allRecordsFilter, setAllRecordsFilter] = useState([]);
    const [userActive, setUserActive] = useState([]);
    const [initDateRecords, setInitDateRecords] = useState<any>('');
    const [endDateRecords, setEndDateRecords] = useState<any>('');
    const [typeRecords, setTypeRecords] = useState('');
    const startDayOfMonth = value.clone().startOf("month");
    const endDayOfMonth = value.clone().endOf("month");

    const {data:resultPerceptions,startPolling: startPollingPerceptions, stopPolling: stopPollingPerceptions } = useQuery(GET_ALL_PERCEPTIONS_CALENDAR_MONTH_YEAR,{
        variables: { calendarId: parseInt(idCalendar), month: parseInt(value.format('M')), year: parseInt(value.format('YYYY')) },
    
    });
    const allPerceptions = resultPerceptions?.GET_ALL_PERCEPTIONS_CALENDAR_MONTH_YEAR;

    const {data:resultDeductions, startPolling: startPollingDeductions, stopPolling: stopPollingDeduction} = useQuery(GET_DEDUCTIONS_CALENDAR_MONTH_YEAR,{
        variables: { calendarId: parseInt(idCalendar), month: parseInt(value.format('M')), year: parseInt(value.format('YYYY')) },
    
    });
    const allDeductions = resultDeductions?.GET_DEDUCTIONS_CALENDAR_MONTH_YEAR;

    let incidentName: any[] = [];

    allPerceptions?.forEach((Perception: any) => {
        const NewPerception = {
            Name: Perception.ConceptName,
            Description: Perception.descriptionPerception,
            colorCode: Perception.colorCode,
            legendTitle: Perception.legendTitle
        }
        incidentName.push(NewPerception);
    });

    allDeductions?.forEach((Deductions: any) => {
        const NewDeductions = {
            Name: Deductions.concept_type,
            Description: Deductions.descriptiondeductions,
            colorCode: Deductions.colorCode,
            legendTitle: Deductions.legendTitle
        }
        incidentName.push(NewDeductions);
    });

    const {data: resultIncident2, startPolling, stopPolling} = useQuery(GET_PAYROLL_BY_CALENDAR_BY_MONTH_YEAR,{
        variables: { calendarId: parseInt(idCalendar), month: parseInt(value.format('M')), year: parseInt(value.format('YYYY')) },
    
    });
    const allIncident2 = resultIncident2?.GET_PAYROLL_BY_CALENDAR_BY_MONTH_YEAR

    const [getEvent, { data:resultEvent }] = useLazyQuery(GET_ALL_EVENT_CALENDAR_BY_MONTH_YRAR, {
        variables: { calendarId: parseInt(idCalendar), month: parseInt(value.format('M')), year: parseInt(value.format('YYYY')) },
    });
    const allEvent = resultEvent?.GET_ALL_EVENT_CALENDAR_BY_MONTH_YRAR;

    const [getNonWorkingday, { data:resultNon_Working }] = useLazyQuery(GET_NON_WORKING_BY_MONTH_YEAR, {
        variables: { calendarId: parseInt(idCalendar), month: parseInt(value.format('M')), year: parseInt(value.format('YYYY')) },
    });
    const allNon_Working = resultNon_Working?.GET_NON_WORKING_BY_MONTH_YEAR;

    const [getUserByDay, { data:resultUserByday  }] = useLazyQuery(GET_USERS_BYDAY);
    const [usersByday, setUsersByday] = useState([]);    

    const { state, dispatch } = useContext(CalendarsContext);

     const createModalEvent = (id: string, createModal: boolean) => {
         createEventModal({ id, createModal }, dispatch)
     }

    let incidentNameMonth: any[] = [];
    let incidentNameMonthNotRepeated: any[] = [];

    incidentName?.forEach((name: any) => {
        filtrado?.forEach((incident: any) => {
            if (selectedfiltto) {
                if (name.Name === incident.incident_type_Name) {
                    const temp = {
                        Name: name.Name,
                        legendTitle: name.legendTitle,
                        colorCode: name.colorCode
                    }
                    incidentNameMonth.push(temp)
                }
            } else {
                if (name.Name === incident.incident_type_Name && moment(incident.date_Incident).format('DD') === daySelectedMonth.format('DD')) {
                    const temp = {
                        Name: name.Name,
                        legendTitle: name.legendTitle,
                        colorCode: name.colorCode
                    }
                    incidentNameMonth.push(temp)
                }
            }

        })
    });
    incidentNameMonthNotRepeated = incidentNameMonth.reduce((acc: any, current: any) => {
        const  x = acc.find((item: any) => item.Name === current.Name && item.colorCode === current.colorCode);
        if (!x) {
            return acc.concat([current]);
        }else{
            return acc;
        }

    }, [])

    let incidentNameDate: any[] = []
    filtrado?.forEach((incident: any) => {
        const nameDate = {
            Incident: incident.incident_type_Name,
            Date: incident.date_Incident,
            colorCode: incident.colorCode
        }
        incidentNameDate.push(nameDate)
    })

    const incidentNameDateNotRepeated = incidentNameDate.reduce((acc: any, current: any) => {
        const x = acc.find((item: any) => item.Incident === current.Incident && moment(item.Date).format() === moment(current.Date).format());
        if (!x) {
            return acc.concat([current]);
        } else {
            return acc;
        }
    }, []);


    const classDayBox = () => {
        document.querySelectorAll(".daySelected")?.forEach((selected: any) => {
            selected.className = "dayBox"

        })
    }

    const datosFiltrados = (e: any) => {

        if (e !== '') {
            setselectedfiltto(true)
            const nuevoFiltrado = filtradoStart.filter((lis: any) => lis.collaboratorName.toUpperCase().includes(e.toUpperCase()))
            setFiltrado(nuevoFiltrado)
            const nuevoFiltrado2 = allRecordsFilter.filter((lis: any) =>lis.name.toUpperCase().includes(e.toUpperCase()))
            setAllRecords(nuevoFiltrado2)
            const userFiltrado = userActive.filter((lis: any) =>lis.bussinesName.toUpperCase().includes(e.toUpperCase()))
            setUserActive(userFiltrado)
            
        } else {
            setAllRecords(allRecordsFilter)
            setUserActive(usersByday)
            obtenerDatos()
            getAllRecord()

        }

    }

    const datosOrder = (e: any) => {
        const incidents = [...filtrado]

        const tempAllRecords = [...allRecords]
        const tempAllRecords2 = [...allRecordsFilter]
        const incidents2 = [...filtradoStart]
        if (e === "nombre") {
            
            if (e === "Mayor") {
    
                //Datos filtrados
                const resultOrderRecord = tempAllRecords?.sort((a: any, b: any) => (
                parseInt(moment(a.initDate).format('DD')) <= parseInt(moment(b.initDate).format('DD')) ? 1 : -1
                ) )
                setAllRecords(resultOrderRecord)
                
                //Datos sin filtrados
                const resultOrderRecord2 = tempAllRecords2?.sort((a: any, b: any) => (
                parseInt(moment(a.initDate).format('DD')) <= parseInt(moment(b.initDate).format('DD')) ? 1 : -1
                ) )
                setAllRecordsFilter(resultOrderRecord2)

                //Datos filtrados
                const resultado = incidents?.sort((a: any, b: any) => (
                    parseInt(moment(a.date_Incident).format('DD')) <= parseInt(moment(b.date_Incident).format('DD')) ? 1 : -1
                ))
                setFiltrado(resultado)

                //Datos sin filtrados
                const resultado2 = incidents2?.sort((a: any, b: any) => (
                    parseInt(moment(a.date_Incident).format('DD')) <= parseInt(moment(b.date_Incident).format('DD')) ? 1 : -1
                ))
                setFiltradoStart(resultado2)

            } else {
    
                const resultOrderRecord = tempAllRecords?.sort((a: any, b: any) => (
                parseInt(moment(a.initDate).format('DD')) >= parseInt(moment(b.initDate).format('DD')) ? 1 : -1
                ) )
                setAllRecords(resultOrderRecord)

                //Datos sin filtrados
                const resultOrderRecord2 = tempAllRecords2?.sort((a: any, b: any) => (
                parseInt(moment(a.initDate).format('DD')) >= parseInt(moment(b.initDate).format('DD')) ? 1 : -1
                ) )
                setAllRecordsFilter(resultOrderRecord2)

                //Datos filtrados
                const resultado = incidents?.sort((a: any, b: any) => (
                    parseInt(moment(a.date_Incident).format('DD')) >= parseInt(moment(b.date_Incident).format('DD')) ? 1 : -1
                ))
                setFiltrado(resultado)

                //Datos sin filtrados
                const resultado2 = incidents2?.sort((a: any, b: any) => (
                    parseInt(moment(a.date_Incident).format('DD')) >= parseInt(moment(b.date_Incident).format('DD')) ? 1 : -1
                ))
                setFiltradoStart(resultado2)
            }

        } else {

            //Datos filtrados
            const resultOrderRecord = tempAllRecords?.sort((a: any, b: any) => (a.name >= b.name) ? 1 : -1)
            setAllRecords(resultOrderRecord)

            //Datos sin filtrados
            const resultOrderRecord2 = tempAllRecords2?.sort((a: any, b: any) => (a.name >= b.name) ? 1 : -1)
            setAllRecordsFilter(resultOrderRecord2)

            //Datos filtrados
            const resultado = incidents?.sort((a: any, b: any) => (a.collaboratorName >= b.collaboratorName) ? 1 : -1)
            setFiltrado(resultado)

            //Datos sin filtrados
            const resultado2 = incidents2?.sort((a: any, b: any) => (a.collaboratorName >= b.collaboratorName) ? 1 : -1)
            setFiltradoStart(resultado2)

        }
    }

    const obtenerDatos = async () => {
        setFiltrado(allIncident2)
    };

    const getAllRecord = useCallback(() => {
        const records: any = []
        allEvent.forEach((event: any) => {
            records.push({
                id: event.id,
                initDate: event.initDate,
                endDate: event.endDate,
                name: event.name,
                type: event.eventType
            })
        })
        allNon_Working.forEach((nonWorking: any) => {
            records.push({
                id: nonWorking.id,
                initDate: nonWorking.initDate,
                endDate: nonWorking.endDate,
                name: nonWorking.name,
                type: 'diainhabil'
            })
        })

        setAllRecords(records)
        setAllRecordsFilter(records)
    },[allEvent, allNon_Working])

    useEffect(() => {
        if(allEvent && allNon_Working){
            getAllRecord()
        }
    },[allEvent,allNon_Working,getAllRecord])

    useEffect(() => {
        getEvent()
        getNonWorkingday()
    },[value, getEvent, getNonWorkingday])

    useEffect(() => {
       const colaboratorByDay={
         idCalendar,
         day:`${moment(value).format('DD/MM/YYYY')}` 
        }
       getUserByDay({
        variables: {input:colaboratorByDay },
      })             
    },[])
    
    useEffect(()=>{
        if(resultUserByday && resultUserByday.GET_USERS_BYDAY){
            setUsersByday(resultUserByday.GET_USERS_BYDAY);
        }
      }, [resultUserByday]);


    useEffect(() => {
        setCalendar((current) => current = buildCalendar(value))
        setFiltrado(allIncident2)
        setFiltradoStart(allIncident2)
    }, [value, allIncident2])

    useEffect(() => {
        if (allNon_Working) {
            
            let newArray:any=[];
            let newObj={};
            const notWorkingDays=[...allNon_Working];
            // eslint-disable-next-line array-callback-return
            notWorkingDays.map((days)=>{
                newObj={...days,initDate:moment.utc(days.initDate ).format("YYYY-MM-DD"),endDate:moment.utc(days.endDate ).format("YYYY-MM-DD")}
                newArray.push(newObj)           
            })           
            setNonWorkingDays(newArray)
        }
    }, [allNon_Working])

    useEffect(() => {
        if (allEvent) {
            setEvents(allEvent)
        }
    }, [allEvent])

    const currMonthName = () => {
        return value.locale('es').format("MMMM")
    }

    const currYear = () => {
        return value.format("YYYY")
    }

    const prevMonth = () => {
        classDayBox()
        return value.clone().subtract(1, "month")
    }

    const nextMonth = () => {
        classDayBox()
        return value.clone().add(1, "month")
    }

    const IncidentInDay = (day: any, incident: any, colorCode: any) => {


        if (day.isSame(incident, "day")) {

            return <div className="circleIncident"
            style={
                {
                    backgroundColor: colorCode
                }
            }
            ></div>

        }
    }

    const clickDay = (day: any, dayOfMonth: any, endDayOfMonth: any, e: any) => {
        classDayBox()
        if ((day.isBefore(dayOfMonth, "day")) || (day.isAfter(endDayOfMonth, "day"))) {
            setAllRecords([])
            setValue(day)
            setTypeRecords('')
            setEndDateRecords('')
            setInitDateRecords('')
        } else {
            if (e.target.parentNode.className === "dayBox") {
                e.target.parentNode.className = "daySelected"
                setdaySelectedMonth(day
                    )
                setTypeRecords('')
                setEndDateRecords('')
                setInitDateRecords('')
      
            }
            if (e.target.parentNode.parentNode.className === "dayBox") {
                e.target.parentNode.parentNode.className = "daySelected"
                setdaySelectedMonth(day)
                setTypeRecords('')
                setEndDateRecords('')
                setInitDateRecords('')      
            }
        }
        
        const colaboratorByDay={
            idCalendar,
            day:`${moment(day).format('DD/MM/YYYY')}` 
           }
          getUserByDay({
           variables: {input:colaboratorByDay },
         })             
    }

    const dayNumber = (value: any) => {
        let day = value.format("D").toString();
        if (day.length === 1) {
            day = "0" + day
        }
        numberWeek = value.format('w');
        return day
    }

    const titleIncident = (name: any) => {
        return <div
            className="titleIncident"
            style={
                {
                    background: name.colorCode
                }
            }
        >{name.legendTitle}</div>
    }

    const getNonWorking = () => {
        const nonWorkingDayWeek = nonWorkingDays.filter((ithem: any) => (
            moment(ithem.initDate).format('w') === String(numberWeek) 
            ||
            moment(ithem.endDate).format('w') === String(numberWeek)
            ||
            (
                parseInt(moment(ithem.initDate).format('w')) < parseInt(numberWeek)
                &&
                parseInt(moment(ithem.endDate).format('w')) > parseInt(numberWeek)
            )
        ))

        let firstDay: any
        let lastDay: any
        let idNonWorkingDay = '0'
        let name = 'Día inhábil'
        if(nonWorkingDayWeek.length > 1){
            nonWorkingDayWeek.forEach((ithem: any) => {
                if (firstDay === undefined) {
                    firstDay = ithem.initDate
                    lastDay = ithem.endDate
                } else {
                    if (moment(ithem.initDate).isBefore(firstDay)) {
                        firstDay = ithem.initDate
                    }
                    if (moment(ithem.endDate).isAfter(lastDay)) {
                        lastDay = ithem.endDate
                    }
                }
        
            })
        }else{
            nonWorkingDayWeek.forEach((ithem: any) => {
                firstDay = ithem.initDate
                lastDay = ithem.endDate
                idNonWorkingDay = ithem.id
                name = ithem.name
        
            })
        }

        if(nonWorkingDayWeek.length > 0){
            const daynonWorking = moment(firstDay)
            const dayEndnonWorking = moment(lastDay)
            let nonWorkingWidth = ""
            let nonWorkingLeft = ""
            let varWidthStart = 0
            let varWidthEnd = 0
            let varWidth = 0
            let varLeft = 0

            if (numberWeek === daynonWorking.format('w')) {

                if (numberWeek === dayEndnonWorking.format('w')) {
                    if (dayEndnonWorking.format('dddd') === 'domingo') {
                        varWidthEnd = 1
                    }

                    if (dayEndnonWorking.format('dddd') === 'lunes') {
                        varWidthEnd = 2
                    }

                    if (dayEndnonWorking.format('dddd') === 'martes') {
                        varWidthEnd = 3
                    }

                    if (dayEndnonWorking.format('dddd') === 'miércoles') {
                        varWidthEnd = 4
                    }

                    if (dayEndnonWorking.format('dddd') === 'jueves') {
                        varWidthEnd = 5
                    }

                    if (dayEndnonWorking.format('dddd') === 'viernes') {
                        varWidthEnd = 6
                    }

                    if (dayEndnonWorking.format('dddd') === 'sábado') {
                        varWidthEnd = 7
                    }
                } else {
                    varWidthEnd = 7
                }

                if (dayEndnonWorking.format('dddd') === 'domingo') {
                    varLeft = 0
                    varWidthStart = 0
                }

                if (daynonWorking.format('dddd') === 'lunes') {
                    varLeft = 1
                    varWidthStart = 1
                }

                if (daynonWorking.format('dddd') === 'martes') {
                    varLeft = 2
                    varWidthStart = 2
                }

                if (daynonWorking.format('dddd') === 'miércoles') {
                    varLeft = 3
                    varWidthStart = 3
                }

                if (daynonWorking.format('dddd') === 'jueves') {
                    varLeft = 4
                    varWidthStart = 4
                }

                if (daynonWorking.format('dddd') === 'viernes') {
                    varLeft = 5
                    varWidthStart = 5
                }

                if (daynonWorking.format('dddd') === 'sábado') {
                    varLeft = 6
                    varWidthStart = 6
                }

                varWidth = varWidthEnd - varWidthStart
                nonWorkingWidth = "calc((100%/7)*" + varWidth.toString() + ")"
                nonWorkingLeft = "calc((100%/7)*" + varLeft.toString() + ")"

                const measuresnonWorkingDay = {
                    width: nonWorkingWidth,
                    left: nonWorkingLeft
                }

                return <NonWorkingDayDivModal measures={measuresnonWorkingDay} NonWorkingDayName={name} idNonWorkingDay={idNonWorkingDay} calendar={idCalendar} 
                initDate={firstDay} setInitDateRecords={setInitDateRecords} endDate={lastDay} setEndDateRecords={setEndDateRecords} type={'diainhabil'} setTypeRecords={setTypeRecords}
                />


            } else {

                if (numberWeek === dayEndnonWorking.format('w')) {
                    if (dayEndnonWorking.format('dddd') === 'domingo') {
                        varWidthEnd = 1
                    }

                    if (dayEndnonWorking.format('dddd') === 'lunes') {
                        varWidthEnd = 2
                    }

                    if (dayEndnonWorking.format('dddd') === 'martes') {
                        varWidthEnd = 3
                    }

                    if (dayEndnonWorking.format('dddd') === 'miércoles') {
                        varWidthEnd = 4
                    }

                    if (dayEndnonWorking.format('dddd') === 'jueves') {
                        varWidthEnd = 5
                    }

                    if (dayEndnonWorking.format('dddd') === 'viernes') {
                        varWidthEnd = 6
                    }

                    if (dayEndnonWorking.format('dddd') === 'sábado') {
                        varWidthEnd = 7
                    }

                    varWidth = varWidthEnd - varWidthStart
                    nonWorkingWidth = "calc((100%/7)*" + varWidth.toString() + ")"
                    nonWorkingLeft = "calc((100%/7)*" + varLeft.toString() + ")"

                    const measuresnonWorkingDay = {
                        width: nonWorkingWidth,
                        left: nonWorkingLeft
                    }

                    return <NonWorkingDayDivModal measures={measuresnonWorkingDay} NonWorkingDayName={name} idNonWorkingDay={idNonWorkingDay} calendar={idCalendar} 
                    initDate={firstDay} setInitDateRecords={setInitDateRecords} endDate={lastDay} setEndDateRecords={setEndDateRecords} type={'diainhabil'} setTypeRecords={setTypeRecords}
                    />

                } else {
                    varWidth = 7
                    nonWorkingWidth = "calc((100%/7)*" + varWidth.toString() + ")"
                    nonWorkingLeft = "calc((100%/7)*" + varLeft.toString() + ")"

                    const measuresnonWorkingDay = {
                        width: nonWorkingWidth,
                        left: nonWorkingLeft
                    }

                    return <NonWorkingDayDivModal measures={measuresnonWorkingDay} NonWorkingDayName={name} idNonWorkingDay={idNonWorkingDay} calendar={idCalendar} 
                    initDate={firstDay} setInitDateRecords={setInitDateRecords} endDate={lastDay} setEndDateRecords={setEndDateRecords} type={'diainhabil'} setTypeRecords={setTypeRecords}
                    />
                    }

            }
        }else{
            return ""
        }
        
    }

    const showEvent = (type: string) => {

        const eventsWeek = events.filter((ithem: any) => (
            (
                (
                    moment(ithem.initDate).format('w') === String(numberWeek) 
                    || 
                    moment(ithem.endDate).format('w') === String(numberWeek)
                    ||
                    (
                        parseInt(moment(ithem.initDate).format('w')) < parseInt(numberWeek)
                        &&
                        parseInt(moment(ithem.endDate).format('w')) > parseInt(numberWeek)
                    )
                ) && ithem.eventType === type
            )
        ))

        let firstDay: any
        let lastDay: any
        let name = type === 'evento' ? 'Evento' : 'Tarea'
        let eventType = type
        let idEvent = '0'
        if(eventsWeek.length > 1){
            eventsWeek.forEach((ithem: any) => {
                if (firstDay === undefined) {
                    firstDay = ithem.initDate
                    lastDay = ithem.endDate
                } else {
                    if (moment(ithem.initDate).isBefore(firstDay)) {
                        firstDay = ithem.initDate
                    }
                    if (moment(ithem.endDate).isAfter(lastDay)) {
                        lastDay = ithem.endDate
                    }
                }
        
            })
        }else{
            eventsWeek.forEach((ithem: any) => {
                firstDay = ithem.initDate
                lastDay = ithem.endDate
                idEvent = ithem.id
                name = ithem.name
        
            })
        }

        if(eventsWeek.length > 0){
            const dayEvent = moment(firstDay)
            const dayEndEvent = moment(lastDay)
            let eventWidth = ""
            let eventLeft = ""
            let varWidthStart = 0
            let varWidthEnd = 0
            let varWidth = 0
            let varLeft = 0

            if (numberWeek === dayEvent.format('w')) {

                if (numberWeek === dayEndEvent.format('w')) {
                    if (dayEndEvent.format('dddd') === 'domingo') {
                        varWidthEnd = 1
                    }

                    if (dayEndEvent.format('dddd') === 'lunes') {
                        varWidthEnd = 2
                    }

                    if (dayEndEvent.format('dddd') === 'martes') {
                        varWidthEnd = 3
                    }

                    if (dayEndEvent.format('dddd') === 'miércoles') {
                        varWidthEnd = 4
                    }

                    if (dayEndEvent.format('dddd') === 'jueves') {
                        varWidthEnd = 5
                    }

                    if (dayEndEvent.format('dddd') === 'viernes') {
                        varWidthEnd = 6
                    }

                    if (dayEndEvent.format('dddd') === 'sábado') {
                        varWidthEnd = 7
                    }
                } else {
                    varWidthEnd = 7
                }

                if (dayEndEvent.format('dddd') === 'domingo') {
                    varLeft = 0
                    varWidthStart = 0
                }

                if (dayEvent.format('dddd') === 'lunes') {
                    varLeft = 1
                    varWidthStart = 1
                }

                if (dayEvent.format('dddd') === 'martes') {
                    varLeft = 2
                    varWidthStart = 2
                }

                if (dayEvent.format('dddd') === 'miércoles') {
                    varLeft = 3
                    varWidthStart = 3
                }

                if (dayEvent.format('dddd') === 'jueves') {
                    varLeft = 4
                    varWidthStart = 4
                }

                if (dayEvent.format('dddd') === 'viernes') {
                    varLeft = 5
                    varWidthStart = 5
                }

                if (dayEvent.format('dddd') === 'sábado') {
                    varLeft = 6
                    varWidthStart = 6
                }

                varWidth = varWidthEnd - varWidthStart
                eventWidth = "calc((100%/7)*" + varWidth.toString() + ")"
                eventLeft = "calc((100%/7)*" + varLeft.toString() + ")"

                const measuresEvent = {
                    width: eventWidth,
                    left: eventLeft
                }

                return <EventDivModal measures={measuresEvent} EventName={name} idEvent={idEvent} calendar={idCalendar} eventType={eventType} 
                initDate={firstDay} setInitDateRecords={setInitDateRecords} endDate={lastDay} setEndDateRecords={setEndDateRecords} type={eventType} setTypeRecords={setTypeRecords}
                />


            } else {

                if (numberWeek === dayEndEvent.format('w')) {
                    if (dayEndEvent.format('dddd') === 'domingo') {
                        varWidthEnd = 1
                    }

                    if (dayEndEvent.format('dddd') === 'lunes') {
                        varWidthEnd = 2
                    }

                    if (dayEndEvent.format('dddd') === 'martes') {
                        varWidthEnd = 3
                    }

                    if (dayEndEvent.format('dddd') === 'miércoles') {
                        varWidthEnd = 4
                    }

                    if (dayEndEvent.format('dddd') === 'jueves') {
                        varWidthEnd = 5
                    }

                    if (dayEndEvent.format('dddd') === 'viernes') {
                        varWidthEnd = 6
                    }

                    if (dayEndEvent.format('dddd') === 'sábado') {
                        varWidthEnd = 7
                    }

                    varWidth = varWidthEnd - varWidthStart
                    eventWidth = "calc((100%/7)*" + varWidth.toString() + ")"
                    eventLeft = "calc((100%/7)*" + varLeft.toString() + ")"

                    const measuresEvent = {
                        width: eventWidth,
                        left: eventLeft
                    }

                    return <EventDivModal measures={measuresEvent} EventName={name} idEvent={idEvent} calendar={idCalendar} eventType={eventType} 
                    initDate={firstDay} setInitDateRecords={setInitDateRecords} endDate={lastDay} setEndDateRecords={setEndDateRecords} type={eventType} setTypeRecords={setTypeRecords}
                    />

                } else {
                    varWidth = 7
                    eventWidth = "calc((100%/7)*" + varWidth.toString() + ")"
                    eventLeft = "calc((100%/7)*" + varLeft.toString() + ")"

                    const measuresEvent = {
                        width: eventWidth,
                        left: eventLeft
                    }

                    return <EventDivModal measures={measuresEvent} EventName={name} idEvent={idEvent} calendar={idCalendar} eventType={eventType} 
                    initDate={firstDay} setInitDateRecords={setInitDateRecords} endDate={lastDay} setEndDateRecords={setEndDateRecords} type={eventType} setTypeRecords={setTypeRecords}
                    />
                }

            }

        }else{
            return ""
        }

    }

    const containerDayMonth = () => {
       if (selectedfiltto) {
            return <div className="containerDayMonth">
                <div className="dayMonth" ><b>{daySelectedMonth.format('MMMM')}</b></div>
            </div>
        } else {
            return <div className="containerDayMonth">
                <div className="dayMonth" ><b>{daySelectedMonth.format('dddd')}</b></div>
                <div className="dayMonth"><b>{daySelectedMonth.format('DD')} de {daySelectedMonth.format('MMMM')} {daySelectedMonth.format('YYYY')}</b></div>
            </div>
        }

    }

    return <>
          <div className="titleSection">     
            <Grid container direction="row">
                <Grid xs={7}>
                </Grid>
                <Grid xs={5}>
                    <div className="buttonContainer">
                    <button
                        className="buttonWorkSchedule"
                        onClick={() => openListWorkSchedule(true, dispatch)}
                    >
                        <p className="buttonWorkScheduleText"> Horarios laborales</p>
                    </button>
                    <button
                        className="buttonNewWorkSchedule"
                        onClick={() => openNewWorkSchedule(true, dispatch)}
                    >
                        <p className="buttonWorkScheduleText"> Nuevo horario laboral</p>
                    </button>
                    </div>
                    
                </Grid>
                <Grid xs={8}>
                    <p
                        className="nameCalendarClock"
                    >{nameCalendar}</p>
                </Grid>

            </Grid>
          </div>
            
          <WorkScheduleList    idCalendar={idCalendar}/>
            <CreateWorkSchedule  idCalendar={idCalendar}/>
            <UpdateWorkSchedule></UpdateWorkSchedule>
            <UserScheduleList idCalendar={idCalendar}/>

            <Grid container direction="row">
              <Grid item xs={12}>
                <Grid container direction="row">
                  <Grid xs={9} item className="RectangleClock CalendarRectangle">
                     
                      <ModalState>
                          <div className="calendar">
                              <div className="calendarHeader">
                                  <div className="calendarInput">
                                    <div className="containderImputsSearcher">
                                      <input
                                          className="inputSearcher"
                                          onChange={(e: any) => datosFiltrados(e.target.value)}
                                      />
                                    </div>
                                  </div>
                                  <div className="headerSchedule" >
                                      <div
                                          className="previous"
                                          onClick={() =>{
                                              setAllRecords([])
                                              setValue(prevMonth())
                                              setTypeRecords('')
                                              setEndDateRecords('')
                                              setInitDateRecords('')
                                          } }
                                      >{String.fromCharCode(60)}{String.fromCharCode(160)}</div>
                                      <div
                                          className="current"
                                      >
                                          {currMonthName()}{String.fromCharCode(32)}{currYear()}
                                      </div>
                                      <div
                                          className="next"
                                          onClick={() =>{
                                              setAllRecords([])
                                              setValue(nextMonth())
                                              setTypeRecords('')
                                              setEndDateRecords('')
                                              setInitDateRecords('')
                                          } }
                                      >{String.fromCharCode(160)}{String.fromCharCode(62)}</div>
                                  </div>
                                  <div className="calendarButton">
                                    <button
                                     className="buttonWorkSchedule"
                                     onClick={() => openListUserCheck(true, dispatch)}                                   
                                    >
                                    <p className="buttonWorkScheduleText">Registros de tiempos</p>
                                    </button>
                                  </div>
                              </div>
                              <div className="day-names">
                                  {
                                      ["DOM", "LUN", "MAR", "MIE", "JUE", "VIE", "SAB"].map(d => (
                                          <div className="week">{d}</div>
                                      ))
                                  }
                              </div>
                              <div className="body">
                                  {
                                      calendar.map((week) => (
                                          <div className="weekbox" >
                                              {
                                                week.map((day: any) => (
                                                      <div className={WeekStyles(day, value)}
                                                          onClick={(e: any) => clickDay(day, startDayOfMonth, endDayOfMonth, e)}
                                                      >
                                                          <div
                                                              className={dayStyles(day, value, startDayOfMonth, endDayOfMonth)}
                                                          >
                                                              <div>
                                                                  <b>
                                                                      {
                                                                          dayNumber(day)
                                                                      }
                                                                  </b>

                                                              </div>

                                                          </div>
                                                          <div className="divBetweenDayIncident"></div>
                                                          <div className="containerDivIncidents">
                                                              <div>
                                                                  <div className="divIncidents">

                                                                      {
                                                                          incidentNameDateNotRepeated?.map((incident: any, index: any) => (
                                                                              IncidentInDay(day, incident.Date, incident.colorCode)
                                                                          )

                                                                          )

                                                                      }


                                                                  </div>

                                                              </div>
                                                          </div>
                                                      </div>

                                                  ))
                                              }

                                              {
                                                  nonWorkingDays && nonWorkingDays !== undefined? getNonWorking() : ""
                                                  
                                              }

                                              {
                                                  events && events !== undefined? showEvent('tarea'): ""
                                              }
                                              {
                                                  events && events !== undefined? showEvent('evento'): ""
                                              }

                                          </div>
                                      ))
                                  }

                              </div>
                          </div>
                          {
                           state.createNonWorkingDayModal ? 
                           <CreateNonWorkingDayEditModal3 
                                   setDate={setValue} 
                                   date={value} 
                                   setNonWorkingDays={setNonWorkingDays} 
                                   nonWorkingDays={nonWorkingDays} /> 
                            : null
                          }
                      </ModalState>

                  </Grid>
                  <Grid xs={3} item className="Rectangle CalendarRectangleText">
                      <ModalState>
                          <div className="divTitleIncident">
                              {containerDayMonth()}
                              <div className="divAddIncident">
                                  {CheckPermission("Calendario.Ver.AgregarIncidencias") && (
                                      <button
                                          className="botonHeaderIncident"
                                          onClick={() => createModalEvent(idCalendar, true)}
                                      >
                                          <div className='contenedorBotonHeaderIncident'>
                                              <div className="textoBotonHeaderIncident">

                                              </div>
                                          </div>
                                      </button>
                                  )}
                              </div>
                              {state.createEventModal ? <CreateEvent idCalendar={idCalendar} setDate={setValue} daySelectedMonth={daySelectedMonth} date={value} /> : null}
                              {state.deleteEditEventModal ? <DeleteEvent calendarDate={setValue} date={value} /> : null}
                              {state.createEditEventModal ? <CreateEventEditModal2 calendarDate={setValue} setDate={setValue} date={value} /> : null}

                          </div>

                          <div className="containderImputs">
                              <div className="containderImputsSearcher">
                                  <input
                                      className="inputSearcher"
                                      onChange={(e: any) => datosFiltrados(e.target.value)}
                                  />
                              </div>
                              <div className="containderImputsFilter">
                                  <FormControl
                                      fullWidth
                                      size="small"
                                      variant="outlined"
                                      className={styles.dialogFields}
                                      style={{ width: '100%' }}
                                  >
                                      <InputLabel
                                          id="demo-simple-select-label"
                                          style={{
                                              backgroundColor: "#FFFFFF",
                                              color: "#838383",
                                              fontSize: '12px',
                                              marginTop: '-6px',
                                              marginLeft: '-7px',
                                              marginRight: '5px'
                                          }}
                                      >Ordenar</InputLabel>

                                      <Select
                                          labelId="demo-simple-select-label"
                                          id="demo-simple"
                                          name="tipo"
                                          onChange={(e: any) => datosOrder(e.target.value)}
                                          style={{ height: '23px', fontSize: '12px' }}
                                      >
                                          <MenuItem value="nombre">Ordenar por nombre</MenuItem>
                                          <MenuItem value="Menor">De antiguo a reciente</MenuItem>
                                          <MenuItem value="Mayor">De reciente a antiguo</MenuItem>

                                      </Select>

                                  </FormControl>
                              </div>                        
                          </div>
                        <div className="infoActividades">
                                {
                                usersByday?.length >0 ? <>
                                <div className="containerColaborador">                            
                                    <div className="contBtnCol">
                                    <button
                                            className="buttonColaborador"                            
                                        >
                                            <p className="buttonColaboradorText">Colaboradores Activos</p>
                                        </button>
                                    </div> 
                                    <div className="colaboradorActivo">                            
                                    {
                                        usersByday?.map((user:any) =>(
                                            <div>{user.bussinesName}</div>
                                        ))
                                    }
                                </div> 
                                </div> 
                                </>:<></> 
                                }  
                        </div>  
                          <div className="conteinerIncident">
                              {
                                  incidentNameMonthNotRepeated?.map((typeIncident: any) => (
                                      <div className="containerTitlesIncidentsMonth">

                                          {titleIncident(typeIncident)}
                                          <div className="containerCollaboratorDayIncident">
                                              {
                                                  filtrado?.map((incident: any) => (
                                                      <>
                                                          {

                                                              selectedfiltto && typeIncident.Name === incident.incident_type_Name ? (
                                                                  <div className="collaboratorDayIncident" ><b>{moment(incident.date_Incident).format('DD')}</b> {incident.collaboratorName}</div>
                                                              ) : (
                                                                  <>
                                                                      { typeIncident.Name === incident.incident_type_Name && moment(incident.date_Incident).format('DD') === (daySelectedMonth.format('DD')) && (
                                                                          <div className="collaboratorDayIncident" ><b>{moment(incident.date_Incident).format('DD')}</b> {incident.collaboratorName}</div>
                      
                                                                      )}
                                                                  </>
                                                              )
                                                              
                                                          }
                                                      </>
                                          
                                                  ))
                                              }
                                          </div>
                                      </div>

                                  ))
                              }
                              {

                                  allRecords?(
                                      allRecords?.filter((record: any) => 
                                          (record.type === "diainhabil")
                                          &&
                                          (
                                              (
                                                  daySelectedMonth.isBetween(moment(record.initDate), moment(record.endDate), 'day', '[]')
                                                  && String(initDateRecords) ===''&& String(endDateRecords) ==='' && typeRecords === ''
                                              )
                                              ||
                                              (
                                                  (
                                                      moment(record.initDate).isBetween(moment(initDateRecords), moment(endDateRecords), 'day', '[]')
                                                      ||
                                                      moment(record.endDate).isBetween(moment(initDateRecords), moment(endDateRecords), 'day', '[]')
                                                  )
                                                  &&
                                                  record.type === typeRecords
                                                  && String(initDateRecords) !==''&& String(endDateRecords) !=='' && typeRecords !== ''
                                              )
                                          )
                                        
                                      )?.length > 0 ?(
                                          <div className="containerTitlesIncidentsMonth">

                                              <div className="titleIncident"
                                                  style={
                                                      {
                                                          background: '#49A9F6'
                                                      }
                                                  }
                                              >Día inhábil</div>
                                              <div className="containerCollaboratorDayIncident">
                                                  {
                                                      allRecords?.filter((record: any) => 
                                                          record.type === "diainhabil"
                                                          &&
                                                          (
                                                              (
                                                                  daySelectedMonth.isBetween(moment(record.initDate), moment(record.endDate), 'day', '[]')
                                                                  && String(initDateRecords) ===''&& String(endDateRecords) ==='' && typeRecords === ''
                                                              )
                                                              ||
                                                              (
                                                                  (
                                                                      moment(record.initDate).isBetween(moment(initDateRecords), moment(endDateRecords), 'day', '[]')
                                                                      ||
                                                                      moment(record.endDate).isBetween(moment(initDateRecords), moment(endDateRecords), 'day', '[]')
                                                                  )
                                                                  &&
                                                                  record.type === typeRecords
                                                                  && String(initDateRecords) !==''&& String(endDateRecords) !=='' && typeRecords !== ''
                                                              )
                                                          )
                                                      )?.map((record: any) => (

                                                          <NonWorkingDayListModal initDate={record.initDate} endDate={record.endDate} name={record.name} id={record.id} calendar={idCalendar} />
                                      
                                                      ))
                                                  }
                                              </div>
                                          </div>

                                      ):<></>
                                  ):<></>
                                      

                              }
                              {
                                  allRecords?(
                                      allRecords?.filter((record: any) => 
                                          record.type === "evento"
                                          &&
                                          (
                                              (
                                                  daySelectedMonth.isBetween(moment(record.initDate), moment(record.endDate), 'day', '[]')
                                                  && String(initDateRecords) ===''&& String(endDateRecords) ==='' && typeRecords === ''
                                              )
                                              ||
                                              (
                                                  (
                                                      moment(record.initDate).isBetween(moment(initDateRecords), moment(endDateRecords), 'day', '[]')
                                                      ||
                                                      moment(record.endDate).isBetween(moment(initDateRecords), moment(endDateRecords), 'day', '[]')
                                                  )
                                                  &&
                                                  record.type === typeRecords
                                                  && String(initDateRecords) !==''&& String(endDateRecords) !=='' && typeRecords !== ''
                                              )
                                          )
                                      )?.length > 0 ?(
                                          <div className="containerTitlesIncidentsMonth">

                                              <div className="titleIncident"
                                                  style={
                                                      {
                                                          background: '#FABB00'
                                                      }
                                                  }
                                              >Evento</div>
                                              <div className="containerCollaboratorDayIncident">
                                                  {
                                                      allRecords?.filter((record: any) => 
                                                          record.type === "evento"
                                                          &&
                                                          (
                                                              (
                                                                  daySelectedMonth.isBetween(moment(record.initDate), moment(record.endDate), 'day', '[]')
                                                                  && String(initDateRecords) ===''&& String(endDateRecords) ==='' && typeRecords === ''
                                                              )
                                                              ||
                                                              (
                                                                  (
                                                                      moment(record.initDate).isBetween(moment(initDateRecords), moment(endDateRecords), 'day', '[]')
                                                                      ||
                                                                      moment(record.endDate).isBetween(moment(initDateRecords), moment(endDateRecords), 'day', '[]')
                                                                  )
                                                                  &&
                                                                  record.type === typeRecords
                                                                  && String(initDateRecords) !==''&& String(endDateRecords) !=='' && typeRecords !== ''
                                                              )
                                                          )
                                                      )?.map((record: any) => (
                                                          <EventListModal initDate={record.initDate} endDate={record.endDate} name={record.name} id={record.id} calendar={idCalendar} />
                                              
                                                      ))
                                                  }
                                              </div>
                                          </div>

                                      ):<></>
                                  ):<></>
                              }
                              {
                                  allRecords?(
                                      allRecords?.filter((record: any) => 
                                          record.type === "tarea"
                                          &&
                                          (
                                              (
                                                  daySelectedMonth.isBetween(moment(record.initDate), moment(record.endDate), 'day', '[]')
                                                  && String(initDateRecords) ===''&& String(endDateRecords) ==='' && typeRecords === ''
                                              )
                                              ||
                                              (
                                                  (
                                                      moment(record.initDate).isBetween(moment(initDateRecords), moment(endDateRecords), 'day', '[]')
                                                      ||
                                                      moment(record.endDate).isBetween(moment(initDateRecords), moment(endDateRecords), 'day', '[]')
                                                  )
                                                  &&
                                                  record.type === typeRecords
                                                  && String(initDateRecords) !==''&& String(endDateRecords) !=='' && typeRecords !== ''
                                              )
                                          )
                                      )?.length > 0 ?(
                                          <div className="containerTitlesIncidentsMonth">

                                              <div className="titleIncident"
                                                  style={
                                                      {
                                                          background: '#46CB2C'
                                                      }
                                                  }
                                              >Tareas</div>
                                              <div className="containerCollaboratorDayIncident">
                                                  {
                                                      allRecords?.filter((record: any) => 
                                                          record.type === "tarea"
                                                          &&
                                                          (
                                                              (
                                                                  daySelectedMonth.isBetween(moment(record.initDate), moment(record.endDate), 'day', '[]')
                                                                  && String(initDateRecords) ===''&& String(endDateRecords) ==='' && typeRecords === ''
                                                              )
                                                              ||
                                                              (
                                                                  (
                                                                      moment(record.initDate).isBetween(moment(initDateRecords), moment(endDateRecords), 'day', '[]')
                                                                      ||
                                                                      moment(record.endDate).isBetween(moment(initDateRecords), moment(endDateRecords), 'day', '[]')
                                                                  )
                                                                  &&
                                                                  record.type === typeRecords
                                                                  && String(initDateRecords) !==''&& String(endDateRecords) !=='' && typeRecords !== ''
                                                              )
                                                          )
                                                      )?.map((record: any) => (
                                                          <EventListModal initDate={record.initDate} endDate={record.endDate} name={record.name} id={record.id} calendar={idCalendar} />
                                              
                                                      ))
                                                  }
                                              </div>
                                          </div>

                                      ):<></>
                                  ):<></>

                                  

                              }
                          </div>
                      </ModalState>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
    </>

}