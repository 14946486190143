import { useContext, useEffect, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import {
  Checkbox,
  Dialog,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Tooltip
} from "@material-ui/core";
import { Box } from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";

import ReportContext, { Types } from "../../../context/ReportsContext/ReportContext";
import SearcherCard from "../../Campaigns/components/SearcherCard";
import Btnfilter from "../../Campaigns/components/Btnfilter";
import AddTable from "../../../screens/Colaboradores/Colaboradores/CollaboratorTab/AddTable";
import { CREATE_CUSTOM_REPORT, GET_DATACOLUMNS, GET_DATASOURCE } from "../../../Querys/querys";

import styles from "../css/CreateReports.module.css";
import { SuccessfulAlert } from "../../../alerts/successAlerts";
import { useHistory } from "react-router-dom";

const validationSchema = () => {
  return {
    reportName: Yup.string().required("Obligatorio"),
    // datasources: Yup.array().required("Obligatorio").test("dataSources", "Es necesario seleccionar al menos una fuente de datos", function (value) {
    //   return !(value === undefined || value.length === 0);
    // }),
    // rows: Yup.array().required("Obligatorio").test("areas", "Es necesario seleccionar una fila", function (value) {
    //   return !(value === undefined || value.length === 0);
    // }),
    // columns: Yup.array().required("Obligatorio").test("areas", "Es necesario seleccionar una columna", function (value) {
    //   return !(value === undefined || value.length === 0);
    // }),
    // pivot: Yup.array().required("Obligatorio").test("areas", "Es necesario seleccionar un pivote", function (value) {
    //   return !(value === undefined || value.length === 0);
    // }),
    // filters: Yup.array().required("Obligatorio").test("areas", "Es necesario seleccionar un filtro", function (value) {
    //   return !(value === undefined || value.length === 0);
    // }),
  }
};

interface Option {
  id: number;
  name: string;
  column: string;
}

interface Row {
  category: string;
  tableId: number;
  tableName: string;
  options: Option[];
}

interface FormDatasource {
  id: number;
  Name: string;
  Tables: string;
}

interface FormRow {
  id: number;
  name: string;
  column: string;
  tableId: number;
  tableName: string;
}

interface FormValues {
  reportName: string;
  datasources: FormDatasource[];
  rows: FormRow[];
  columns: FormRow[];
  pivot: FormRow[];
  filters: any[];
}

const CreateReportModal = () => {
  const history = useHistory();
  const { state, dispatch } = useContext(ReportContext);

  const { data: dataSources } = useQuery(GET_DATASOURCE);
  const { data: dataColumns } = useQuery(GET_DATACOLUMNS);
  const [createCustomReport] = useMutation<{
    variables: {
      input: {
        name: string;
        rows?: any[];
        columns?: any[];
      }
    }
  }>(CREATE_CUSTOM_REPORT);

  const formik = useFormik<FormValues>({
    initialValues: {
      reportName: '',
      datasources: [],
      rows: [],
      columns: [],
      pivot: [],
      filters: [],
    },
    enableReinitialize: true,
    validationSchema: Yup.object(validationSchema()),
    onSubmit: async (formData, { resetForm }) => {
      const name = formData.reportName;
      const rows = formData.rows.map((row) => ({
        idColumn: parseInt(row.id as any, 10),
        column: row.column,
        columnName: row.name,
        idDataSource: parseInt(dataSources.GET_DATASOURCE.find((x: any) => x.id === row.tableId)?.id, 10),
        dataSource: dataSources.GET_DATASOURCE.find((x: any) => x.Tables === row.tableName)?.Tables,
        isPivot: false,
        show: true,
      }));
      const columns = formData.columns.map((row) => ({
        idColumn: parseInt(row.id as any, 10),
        column: row.column,
        columnName: row.name,
        idDataSource: parseInt(dataSources.GET_DATASOURCE.find((x: any) => x.id === row.tableId)?.id, 10),
        dataSource: dataSources.GET_DATASOURCE.find((x: any) => x.Tables === row.tableName)?.Tables,
        isPivot: false,
        show: true,
      }));

      if (formData.pivot.length > 0) {
        formData.pivot.forEach((pivotItem) => {
          const existingRow = rows.find((row) => row.idColumn === parseInt(pivotItem?.id as any, 10));
          const existingColumn = columns.find((column) => column.idColumn === parseInt(pivotItem?.id as any, 10));

          if (existingRow) {
            existingRow.isPivot = true;
          } else if (existingColumn) {
            existingColumn.isPivot = true;
          } else if (rows?.length > 0) {
            rows.push({
              idColumn: parseInt(pivotItem?.id as any, 10),
              column: pivotItem?.column,
              columnName: pivotItem?.name,
              idDataSource: parseInt(dataSources.GET_DATASOURCE.find((x: any) => x.id === pivotItem?.tableId)?.id, 10),
              dataSource: dataSources.GET_DATASOURCE.find((x: any) => x.Tables === pivotItem?.tableName)?.Tables,
              isPivot: true,
              show: false,
            });
          } else if (columns?.length > 0) {
            columns.push({
              idColumn: parseInt(pivotItem?.id as any, 10),
              column: pivotItem?.column,
              columnName: pivotItem?.name,
              idDataSource: parseInt(dataSources.GET_DATASOURCE.find((x: any) => x.id === pivotItem?.tableId)?.id, 10),
              dataSource: dataSources.GET_DATASOURCE.find((x: any) => x.Tables === pivotItem?.tableName)?.Tables,
              isPivot: true,
              show: false,
            });
          }
        });
      }

      await createCustomReport({
        variables: {
          input: {
            name,
            ...(rows?.length > 0 && { rows }),
            ...(columns?.length > 0 && { columns }),
          }
        }
      })

      resetForm();
      dispatch({
        type: Types.CLOSE_CREATE_REPORTS
      });
      SuccessfulAlert({ text: "Se ha creado el informe correctamente" });
    }
  });

  const [columns, setColumns] = useState<Row[]>([]);
  const [rows, setRows] = useState<Row[]>([]);
  const [pivots, setPivots] = useState<Row[]>([]);
  const [filters, setFilters] = useState<any[]>([]);
  const [filteredColumns, setFilteredColumns] = useState<Row[]>([]);
  const [filteredRows, setFilteredRows] = useState<Row[]>([]);
  const [filteredPivots, setFilteredPivots] = useState<Row[]>([]);
  const [filteredFilters, setFilteredFilters] = useState<Row[]>([]);

  useEffect(() => {
    if (dataSources?.GET_DATASOURCE?.length > 0 && dataColumns?.GET_DATACOLUMNS?.length > 0) {
      const result: any = [];

      dataSources?.GET_DATASOURCE.forEach((table: any) => {
        const options = dataColumns?.GET_DATACOLUMNS
          .filter((column: any) => column.table === table.Tables)
          .map((column: any) => ({ id: column.id, name: column.Name, column: column.column }));

        console.log("options", options);
        const categoryObj = {
          category: table.Name,
          tableId: table.id,
          tableName: table.Tables,
          options: options
        };

        result.push(categoryObj);
      });

      setRows(result);
      setColumns(result);
      setPivots(result);
      // setFilteredRows(result);
      // setFilteredColumns(result);
      // setFilteredPivots(result);
    }
  }, [dataSources, dataColumns])

  const closeModal = () => {
    dispatch({
      type: Types.CLOSE_CREATE_REPORTS
    })
    formik.resetForm();
  };

  const goToPreview = () => {
    const name = formik.values.reportName;
    const rows = formik.values.rows.map((row) => ({
      idColumn: parseInt(row.id as any, 10),
      column: row.column,
      columnName: row.name,
      idDataSource: parseInt(dataSources.GET_DATASOURCE.find((x: any) => x.id === row.tableId)?.id, 10),
      dataSource: dataSources.GET_DATASOURCE.find((x: any) => x.Tables === row.tableName)?.Tables,
      isPivot: false,
      show: true,
    }));
    const columns = formik.values.columns.map((row) => ({
      idColumn: parseInt(row.id as any, 10),
      column: row.column,
      columnName: row.name,
      idDataSource: parseInt(dataSources.GET_DATASOURCE.find((x: any) => x.id === row.tableId)?.id, 10),
      dataSource: dataSources.GET_DATASOURCE.find((x: any) => x.Tables === row.tableName)?.Tables,
      isPivot: false,
      show: true,
    }));

    if (formik.values.pivot.length > 0) {
      formik.values.pivot.forEach((pivotItem) => {
        const existingRow = rows.find((row) => row.idColumn === parseInt(pivotItem.id as any, 10));
        const existingColumn = columns.find((column) => column.idColumn === parseInt(pivotItem.id as any, 10));

        if (existingRow) {
          existingRow.isPivot = true;
        } else if (existingColumn) {
          existingColumn.isPivot = true;
        } else if (rows?.length > 0) {
          rows.push({
            idColumn: parseInt(pivotItem.id as any, 10),
            column: pivotItem.column,
            columnName: pivotItem.name,
            idDataSource: parseInt(dataSources.GET_DATASOURCE.find((x: any) => x.id === pivotItem.tableId)?.id, 10),
            dataSource: dataSources.GET_DATASOURCE.find((x: any) => x.Tables === pivotItem.tableName)?.Tables,
            isPivot: true,
            show: false,
          });
        } else if (columns?.length > 0) {
          columns.push({
            idColumn: parseInt(pivotItem.id as any, 10),
            column: pivotItem.column,
            columnName: pivotItem.name,
            idDataSource: parseInt(dataSources.GET_DATASOURCE.find((x: any) => x.id === pivotItem.tableId)?.id, 10),
            dataSource: dataSources.GET_DATASOURCE.find((x: any) => x.Tables === pivotItem.tableName)?.Tables,
            isPivot: true,
            show: false,
          });
        }
      });
    }

    dispatch({
      type: Types.PREVIEW_DATA,
      payload: {
        name,
        rows,
        columns,
      }
    })
    dispatch({
      type: Types.CLOSE_CREATE_REPORTS
    })
    history.push('/informes/preview');
  }

  return (
    <Dialog
      aria-labelledby="customized-dialog-title"
      open={state.createModalReports}
      fullWidth
    >
      <div className={styles.mainBox}>
        <div className={styles.mainContainer}>
          <button
            className={styles.closeModalButton}
            onClick={closeModal}
          >
            <img src="/assets/svg/closeM.svg" alt="Cerrar" />
          </button>
        </div>
        <h2 id="form-dialog-title" className="text-center">
          Crear informe
        </h2>

        <form onSubmit={formik.handleSubmit}>
          <TextField
            type="text"
            name="reportName"
            label="Nombre del informe *"
            variant="outlined"
            size="small"
            fullWidth={true}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.reportName}
            error={formik.touched.reportName && Boolean(formik.errors.reportName)}
            helperText={formik.touched.reportName && formik.errors.reportName}
            inputProps={{ maxLength: 100 }}
          />
          <div className={styles.formControlContainer}>
            <FormControl
              size="small"
              variant="outlined"
              className="question"
              style={{
                width: '30%'
              }}
            >
              <InputLabel id="datasources" className={styles.formLabel}>
                Fuente de datos*
              </InputLabel>
              <Select
                className={styles.select}
                startAdornment={
                  <Tooltip title="Información adicional sobre las fuentes de datos">
                    <IconButton aria-label="info">
                      <img src="/assets/svg/info.svg" alt="Información" />
                    </IconButton>
                  </Tooltip>
                }
                onChange={formik.handleChange}
                value={formik.values.datasources}
                labelId="datasources"
                id="datasources"
                name="datasources"
                placeholder="Fuente de datos *"
                multiple
                renderValue={(selected: any) => selected.map((x: any) => x.Name).join(', ')}
                error={formik.touched.datasources && Boolean(formik.errors.datasources)}
              >
                <div className={styles.selectOptionsCard}>
                  {/* <SearcherCard
                    initState={datasources}
                    setState={setDatasources}
                    stateInitial={datasources}
                    setStepFilter={setDatasources}
                    stepCampaignFilter={setDatasources as any}
                    label={"Buscar"}
                    fields={['titulo']}
                    width={200}
                  />
                  <Btnfilter
                    title="Filtros"
                    option="btnFecha"
                    initState={datasources}
                    setState={setDatasources}
                    campaignFilter={datasources}
                    stateInitial={datasources}
                    setStepFilter={setDatasources}
                  /> */}
                </div>
                {
                  dataSources?.GET_DATASOURCE?.length > 0 && (
                    <MenuItem value={'all'}>
                      <Checkbox
                        style={{
                          color: "#FABB00",
                        }}
                        checked={
                          formik.values.datasources.length === dataSources?.GET_DATASOURCE?.length
                        }
                        onChange={() => {
                          if (formik.values.datasources.length === dataSources?.GET_DATASOURCE?.length) {
                            formik.setFieldValue("datasources", []);
                            setFilteredColumns([]);
                            setFilteredRows([]);
                            setFilteredPivots([]);
                          } else {
                            formik.setFieldValue("datasources", dataSources?.GET_DATASOURCE);
                            setFilteredColumns(columns);
                            setFilteredRows(rows);
                            setFilteredPivots(pivots);
                          }
                        }}
                      />
                      <ListItemText>
                        <span className={styles.checkboxLabel}>Seleccionar todo</span>
                      </ListItemText>
                    </MenuItem>
                  )
                }
                {
                  dataSources?.GET_DATASOURCE?.length > 0 && dataSources.GET_DATASOURCE.map((item: any) => (
                    <MenuItem key={item.id} value={item.id}>
                      <Checkbox
                        style={{
                          color: "#FABB00",
                        }}
                        checked={formik.values.datasources.some(((x: any) => x.id === item.id))}
                        onChange={() => {
                          if (formik.values.datasources.some(((x: any) => x.id === item.id))) {
                            const updatedSelection = formik.values.datasources.filter(
                              (tim: any) => tim.id !== item.id
                            );
                            formik.setFieldValue("datasources", updatedSelection);
                            setFilteredColumns(filteredColumns.filter((x) => x.tableId !== item.id));
                            setFilteredRows(filteredRows.filter((x) => x.tableId !== item.id));
                            setFilteredPivots(filteredPivots.filter((x) => x.tableId !== item.id));
                          } else {
                            const updatedSelection = [...formik.values.datasources, item];
                            formik.setFieldValue("datasources", updatedSelection);
                            setFilteredColumns([...filteredColumns, ...columns.filter((x) => x.tableId === item.id)]);
                            setFilteredRows([...filteredRows, ...rows.filter((x) => x.tableId === item.id)]);
                            setFilteredPivots([...filteredPivots, ...pivots.filter((x) => x.tableId === item.id)]);
                          }
                        }}
                      />
                      <ListItemText>
                        <span className={styles.checkboxLabel}>{item.Name}</span>
                      </ListItemText>
                    </MenuItem>
                  ))
                }
              </Select>

              <FormHelperText style={{ color: "red" }}>
                {formik.touched.datasources && formik.errors.datasources}
              </FormHelperText>
            </FormControl>
            <FormControl
              size="small"
              variant="outlined"
              className="question"
              style={{
                width: '30%'
              }}
            >
              <InputLabel id="rows" className={styles.formLabel}>
                Filas*
              </InputLabel>
              <Select
                className={styles.select}
                startAdornment={
                  <Tooltip title="Información adicional sobre las filas">
                    <IconButton aria-label="info">
                      <img src="/assets/svg/info.svg" alt="Información" />
                    </IconButton>
                  </Tooltip>
                }
                onChange={formik.handleChange}
                value={formik.values.rows}
                labelId="rows"
                id="rows"
                name="rows"
                placeholder="Filas *"
                multiple
                renderValue={(selected: any) => selected.map((x: any) => x.name).join(', ')}
                error={formik.touched.rows && Boolean(formik.errors.rows)}
              >
                {/* <div className={styles.selectOptionsCard}>
                  <SearcherCard
                    initState={rows}
                    setState={setRows}
                    stateInitial={rows}
                    setStepFilter={setRows}
                    stepCampaignFilter={setRows as any}
                    label={"Buscar"}
                    fields={['titulo']}
                    width={200}
                  />
                  <Btnfilter
                    title="Filtros"
                    option="btnFecha"
                    initState={rows}
                    setState={setRows}
                    campaignFilter={rows}
                    stateInitial={rows}
                    setStepFilter={setRows}
                  />
                </div> */}
                {filteredRows.map((item) => (
                  <Box>
                    <div
                      className={styles.checkboxLabel}
                      style={{
                        marginTop: '1rem',
                        paddingLeft: '1rem',
                      }}>{item.category}</div>
                    {
                      item.options.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                          <Checkbox
                            style={{
                              color: "#FABB00",
                            }}
                            checked={formik.values.rows.some(((x) => x.id === option.id))}
                            onChange={() => {
                              if (formik.values.rows.some(((x) => x.id === option.id))) {
                                const updatedSelection = formik.values.rows.filter(
                                  (itm) => itm.id !== option.id
                                );
                                formik.setFieldValue("rows", updatedSelection);
                              } else {
                                const updatedSelection = [
                                  ...formik.values.rows,
                                  { ...option, tableName: item.tableName, tableId: item.tableId }
                                ];
                                formik.setFieldValue("rows", updatedSelection);
                              }
                            }}
                          />
                          <ListItemText>
                            <span className={styles.checkboxLabel}>{option.name}</span>
                          </ListItemText>
                        </MenuItem>
                      ))
                    }
                  </Box>
                ))}
              </Select>

              <FormHelperText style={{ color: "red" }}>
                {formik.touched.rows && formik.errors.rows}
              </FormHelperText>
            </FormControl>
            <FormControl
              size="small"
              variant="outlined"
              className="question"
              style={{
                width: '30%'
              }}
            >
              <InputLabel id="columns" className={styles.formLabel}>
                Columnas*
              </InputLabel>
              <Select
                className={styles.select}
                startAdornment={
                  <Tooltip title="Información adicional sobre las columnas">
                    <IconButton aria-label="info">
                      <img src="/assets/svg/info.svg" alt="Información" />
                    </IconButton>
                  </Tooltip>
                }
                onChange={formik.handleChange}
                value={formik.values.columns}
                labelId="columns"
                id="columns"
                name="columns"
                placeholder="Columnas *"
                multiple
                renderValue={(selected: any) => selected.map((x: any) => x.name).join(', ')}
                error={formik.touched.columns && Boolean(formik.errors.columns)}
              >
                {/* <div className={styles.selectOptionsCard}>
                  <SearcherCard
                    initState={columns}
                    setState={setColumns}
                    stateInitial={columns}
                    setStepFilter={setColumns}
                    stepCampaignFilter={setColumns as any}
                    label={"Buscar"}
                    fields={['titulo']}
                    width={200}
                  />
                  <Btnfilter
                    title="Filtros"
                    option="btnFecha"
                    initState={columns}
                    setState={setColumns}
                    campaignFilter={columns}
                    stateInitial={columns}
                    setStepFilter={setColumns}
                  />
                </div> */}
                {filteredColumns.map((item) => (
                  <Box>
                    <div
                      className={styles.checkboxLabel}
                      style={{
                        marginTop: '1rem',
                        paddingLeft: '1rem',
                      }}>{item.category}</div>
                    {
                      item.options.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                          <Checkbox
                            style={{
                              color: "#FABB00",
                            }}
                            checked={formik.values.columns.some(((x) => x?.id === option?.id))}
                            onChange={() => {
                              if (formik.values.columns.some(((x) => x?.id === option?.id))) {
                                const updatedSelection = formik.values.columns.filter(
                                  (itm) => itm?.id !== option?.id
                                );
                                formik.setFieldValue("columns", updatedSelection);
                              } else {
                                const updatedSelection = [
                                  ...formik.values.columns,
                                  { ...option, tableName: item.tableName, tableId: item.tableId }
                                ];
                                formik.setFieldValue("columns", updatedSelection);
                              }
                            }}
                          />
                          <ListItemText>
                            <span className={styles.checkboxLabel}>{option.name}</span>
                          </ListItemText>
                        </MenuItem>
                      ))
                    }
                  </Box>
                ))}
              </Select>

              <FormHelperText style={{ color: "red" }}>
                {formik.touched.columns && formik.errors.columns}
              </FormHelperText>
            </FormControl>
          </div>
          <div className={styles.formControlContainer}>
            <FormControl
              size="small"
              variant="outlined"
              className="question"
              style={{
                width: '48%'
              }}
            >
              <InputLabel id="pivots" className={styles.formLabel}>
                Campos Pivote*
              </InputLabel>
              <Select
                className={styles.select}
                startAdornment={
                  <Tooltip title="Información adicional sobre las fuentes de datos">
                    <IconButton aria-label="info">
                      <img src="/assets/svg/info.svg" alt="Información" />
                    </IconButton>
                  </Tooltip>
                }
                onChange={formik.handleChange}
                value={formik.values.pivot}
                labelId="pivot"
                id="pivot"
                name="pivot"
                placeholder="Campos Pivote *"
                multiple
                renderValue={(selected: any) => selected.map((x: any) => x?.name).join(', ')}
                error={formik.touched.pivot && Boolean(formik.errors.pivot)}
              >
                {/* <div className={styles.selectOptionsCard}>
                  <SearcherCard
                    initState={pivots}
                    setState={setPivots}
                    stateInitial={pivots}
                    setStepFilter={setPivots}
                    stepCampaignFilter={setPivots as any}
                    label={"Buscar"}
                    fields={['titulo']}
                    width={200}
                  />
                  <Btnfilter
                    title="Filtros"
                    option="btnFecha"
                    initState={pivots}
                    setState={setPivots}
                    campaignFilter={pivots}
                    stateInitial={pivots}
                    setStepFilter={setPivots}
                  />
                </div> */}
                {filteredPivots.map((item) => (
                  <Box>
                    <div
                      className={styles.checkboxLabel}
                      style={{
                        marginTop: '1rem',
                        paddingLeft: '1rem',
                      }}>{item.category}</div>
                    {
                      item.options.map((option) => (
                        <MenuItem key={option?.id} value={option?.id}>
                          <Checkbox
                            style={{
                              color: "#FABB00",
                            }}
                            checked={formik.values.pivot.some(((x) => x?.id === option?.id))}
                            onChange={() => {
                              if (formik.values.pivot.some(((x) => x?.id === option?.id))) {
                                const updatedSelection = formik.values.pivot.filter(
                                  (itm) => itm.id !== option?.id
                                );
                                formik.setFieldValue("pivot", updatedSelection);
                              } else {
                                const updatedSelection = [
                                  ...formik.values.pivot,
                                  { ...option, tableName: item.tableName, tableId: item.tableId }
                                ];
                                formik.setFieldValue("pivot", updatedSelection);
                              }
                            }}
                          />
                          <ListItemText>
                            <span className={styles.checkboxLabel}>{option?.name}</span>
                          </ListItemText>
                        </MenuItem>
                      ))
                    }
                  </Box>
                ))}
              </Select>

              <FormHelperText style={{ color: "red" }}>
                {formik.touched.pivot && formik.errors.pivot}
              </FormHelperText>
            </FormControl>
            <FormControl
              size="small"
              variant="outlined"
              className="question"
              style={{
                width: '48%'
              }}
            >
              <InputLabel id="filters" className={styles.formLabel}>
                Aplicar filtros*
              </InputLabel>
              <Select
                className={styles.select}
                startAdornment={
                  <Tooltip title="Información adicional sobre los filtros">
                    <IconButton aria-label="info">
                      <img src="/assets/svg/info.svg" alt="Información" />
                    </IconButton>
                  </Tooltip>
                }
                onChange={formik.handleChange}
                value={formik.values.filters}
                labelId="filters"
                id="filters"
                name="filters"
                placeholder="Filtros *"
                multiple
                renderValue={(selected: any) => selected.join(', ')}
                error={formik.touched.filters && Boolean(formik.errors.filters)}
              >
                <div className={styles.selectOptionsCard}>
                  <SearcherCard
                    initState={filters}
                    setState={setFilters}
                    stateInitial={filters}
                    setStepFilter={setFilters}
                    stepCampaignFilter={setFilters as any}
                    label={"Buscar"}
                    fields={['titulo']}
                    width={200}
                  />
                  <Btnfilter
                    title="Filtros"
                    option="btnFecha"
                    initState={filters}
                    setState={setFilters}
                    campaignFilter={filters}
                    stateInitial={filters}
                    setStepFilter={setFilters}
                  />
                </div>
                {filters.map((item) => (
                  <Box>
                    <div
                      className={styles.checkboxLabel}
                      style={{
                        marginTop: '1rem',
                        paddingLeft: '1rem',
                      }}>{item.category}</div>
                    {
                      item.options.map((option: any) => (
                        <MenuItem key={option.id} value={option.name}>
                          <Checkbox
                            className={styles.checkbox}
                            checked={formik.values.filters.includes(option.name as never)}
                            onChange={() => {
                              const value = option.name;
                              if (formik.values.filters.includes(value as never)) {
                                const updatedSelection = formik.values.filters.filter(
                                  (itm) => itm !== value
                                );
                                formik.setFieldValue("filters", updatedSelection);
                              } else {
                                const updatedSelection = [...formik.values.filters, value];
                                formik.setFieldValue("filters", updatedSelection);
                              }
                            }}
                          />
                          <ListItemText>
                            <span className={styles.checkboxLabel}>{option.name}</span>
                          </ListItemText>
                        </MenuItem>
                      ))
                    }
                  </Box>
                ))}
              </Select>

              <FormHelperText style={{ color: "red" }}>
                {formik.touched.filters && formik.errors.filters}
              </FormHelperText>
            </FormControl>
          </div>
          <div className={styles.formControlContainer}>
            <button className={styles.cancelButton}
              type="button" onClick={closeModal}>Cancelar</button>
            <AddTable
              func={() => console.log('func')}
              text="Crear informe"
              img="report-icon.svg"
              type="submit"
            />
            <AddTable
              func={goToPreview}
              text="Vista previa"
              img="report-icon.svg"
              type="button"
            />
          </div>
        </form>
      </div>
    </Dialog>
  )
}

export default CreateReportModal;
