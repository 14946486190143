import {
  Dialog
} from '@material-ui/core';
import { useContext, useState, useEffect } from 'react';
import { SuccessfulAlert } from '../../../alerts/successAlerts';
import { TabPayrollGroupContext } from '../../../context/ConfigPayrollContext/TabPayrollGroupContext';
import SaveIcon from '@material-ui/icons/Save';
import { useMutation, useLazyQuery } from "@apollo/client";
import { GET_ALL_PAYROLL_GROUP, CREATE_PAYROLL_GROUP, GET_ENTERPRISE_SELECT, GET_PAYROLLGROUP_POLITIC } from "../../../Querys/querys";
import {useFormik} from "formik";
import * as Yup from "yup";
import { Form } from "semantic-ui-react";
import styles from '../../../styles/grupoNomina/CrearGrupoNomina.module.css'

const CreaPayrollGroup = (props: any) => {
  const[getEnterprise,{data}] = useLazyQuery(GET_ENTERPRISE_SELECT,{});
  const [company, setCompany] = useState([]);
  const [outsourcers, setOutsourcers] = useState([]);
  const [createNewPayrollGroup] = useMutation(CREATE_PAYROLL_GROUP, {
    refetchQueries: [
                      { query: GET_ALL_PAYROLL_GROUP },
                      { query: GET_PAYROLLGROUP_POLITIC }                 
                    ],
  });

  const { addPayrollGroupOpen, setAddPayrollGroupOpen } = useContext(TabPayrollGroupContext);

  const handleClose = async () => {
    setAddPayrollGroupOpen();
  }

useEffect(()=>{
  if(addPayrollGroupOpen){
    getEnterprise();
  }
},[addPayrollGroupOpen]);

useEffect(()=>{
  if(data && data.GET_ENTERPRISE_SELECT){
    setCompany(data.GET_ENTERPRISE_SELECT);
    setOutsourcers(data.GET_ENTERPRISE_SELECT.filter((enterprise:any)=>{
      return enterprise.Outsourcer;
    }));
  }
},[data]);

const initialValues = () =>{
  return{
    GroupName: "",
    CompanyName: "",
    PayrollPeriod: "",
    outsourcer: ""
  }
}

const validationSchema = () =>{
  return{
    GroupName: Yup.string().required("El nombre es requerido").max(100,'Maximo 100 caracteres'),
    CompanyName: Yup.string().required("Por favor seleccione una opción"),
    PayrollPeriod: Yup.string().required("Por favor seleccione una opción")
  }
}

  const formik = useFormik({
    initialValues: initialValues(),
    validationSchema: Yup.object(validationSchema()),
    onSubmit:(formData,reset)=>{
      createNewPayrollGroup({
        variables: {
          input: {
            group_name: formData.GroupName,
            company_name: formData.CompanyName,
            payroll_period: formData.PayrollPeriod,
            idOutsourcer: formData.outsourcer ? Number(formData.outsourcer) : null
          },
        },
      }).then(()=>{
      SuccessfulAlert({text: "Registro creado con éxito"})
      formik.resetForm();
      handleClose()
      
      });
    }
  });

  return(
    <div>
      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={addPayrollGroupOpen} fullWidth={false} maxWidth={"md"}>
      <Form onSubmit = {formik.handleSubmit}>
        <div className={styles.titulo}>
          <p>Crear grupo de nómina</p>
        </div>

          <div className={styles.fila}>
            <div className={styles.tituloFila}>
              <label htmlFor="GroupName">Nombre de grupo de nómina</label>
            </div>
            <div>
              <input
                name="GroupName"
                className={styles.input}
                value={formik.values.GroupName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <div className={styles.contenedorError}>
                <span>{formik.touched.GroupName && formik.errors.GroupName}</span>
              </div>
            </div>
          </div>
          <div className={styles.fila}>
            <div className={styles.tituloFila}>
              <span>Razón social</span>
            </div>
            <div>
              <select
                name="CompanyName"
                value={formik.values.CompanyName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className={styles.select}>
                  <option value="" className="optionSelect" disabled>Selecciona una empresa</option>
                    {
                     company?.map((company:any) => <option className="optionSelect" key={company?.id} value={company?.name}>{company?.name}</option>)
                    }
                </select>
                <div className={styles.contenedorErrorSelect}>                    
                  <span>{formik.touched.CompanyName && formik.errors.CompanyName}</span>
              </div>
            </div>
          </div>
          <div className={styles.fila}>
            <div className={styles.tituloFila}>
              <span>Outsourcer</span>
            </div>
            <div>
              <select
                name="outsourcer"
                value={formik.values.outsourcer || ''}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className={styles.select}>
                  <option value="" className="optionSelect" disabled>Selecciona una empresa</option>
                    {
                     outsourcers?.map((company:any) => <option className="optionSelect" key={company?.id} value={company?.id}>{company?.name}</option>)
                    }
                </select>
               <div className={styles.contenedorErrorSelect}>                    
                  <span>{formik.touched.outsourcer && formik.errors.outsourcer}</span>
              </div>
            </div>
          </div>
          <div className={styles.fila}>
            <div className={styles.tituloFila}>
              <span>Periodo de nómina</span>
            </div>
            <div>
              <select
                name="PayrollPeriod"
                value={formik.values.PayrollPeriod}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className={styles.select}>
                  <option value=""  className="optionSelect" disabled>Selecciona el periodo</option>
                  <option value="Semanal">Semanal</option>
                  <option value="Catorcenal">Catorcenal</option>
                  <option value="Quincenal">Quincenal</option>
                  <option value="Mensual">Mensual</option>
                </select>
                <div className={styles.contenedorErrorSelect}>
                  <span>{formik.touched.PayrollPeriod && formik.errors.PayrollPeriod}</span>
                </div>
            </div>
          </div>          
        <div >
          <div className={styles.contenedorBotones}>
              <button autoFocus onClick={handleClose} className={styles.botonCancelar} type="button">
                  Cancelar
              </button>
              <button className={styles.botonGuardar} type="submit">
              <div className="contenedorIconoBotonModal">
                <SaveIcon fontSize="small"/>
              </div>
              <div>
                Guardar
              </div>
              </button>
          </div>
          </div>
          </Form>
      </Dialog>
    </div>
  )
}

export default CreaPayrollGroup


