import { ChangeEvent, useState, useEffect } from "react";
import Box from '@mui/material/Box';
import { Grid } from "@material-ui/core";
import IncidentPayrollTab from "../components/Payroll/IncidentPayrollTab";
import ActivePayrollTab from "../components/Payroll/ActivePayrollTab";
import SettlementPayrollTab from "../components/Payroll/settlementPayrollTab";
import HistoricalPayrollTab from "../components/Payroll/HistoricalPayrollTab";
import CustomTabs from "../components/Collaborators/Tab/CustomTabs";
import CustomTab from "../components/Collaborators/Tab/CustomTabMain";
import "../components/Collaborators/collaborator.css";
import ReportsPayrollTab from "../components/Payroll/ReportsPayrollTab";
import CalculadoraTab from "../components/Payroll/CalculadoraTab";
import "../components/ConfigPayroll/ConfigPayroll.css";
import EventualTab from "../components/Payroll/EventualTab";
import CheckPermission from "../helpers/Administradores/Permissions";
import { GET_ALL_COMPANY } from "../Querys/querys";
import { useQuery } from "@apollo/client";
import LockedSystem from "../alerts/LockedSystem";

const PayrollScreenBase = () => {
  const [tab, setTab] = useState(0);
  const handleChange = (event: ChangeEvent<{}>, newValue: number) => {
    setTab(newValue);
    localStorage.setItem("currentPill", String(newValue));
  };

  useEffect(() => {
    let pill = localStorage.getItem("currentPill");
    if (pill) setTab(Number(pill));
    else setTab(0);
  }, [tab]);

  /*--------------------------sistemaActivo--------------------*/
  const { data: resultCompany, startPolling, stopPolling } = useQuery(GET_ALL_COMPANY);
  const myCompany = resultCompany?.GET_ALL_COMPANY;
  const [sistemaActivo, setSistemaActivo] = useState<any>(true);
  
  useEffect(()=>{
    startPolling(1000);
    return () =>{
      stopPolling()
    }
  },[startPolling,stopPolling])

  useEffect(() => {
    if(myCompany){
      setSistemaActivo(myCompany[0]?.SistemaActivo)
    }
  }, [myCompany]);
  /*--------------------------sistemaActivo--------------------*/

  return (
    <div>
      {!sistemaActivo ? (
        <LockedSystem/>
      ) : null}
      <Box mt={3} ml={5} className="Title">
        Nóminas
      </Box>
      <Box p={5} pb={3} pt={0}>
        <Grid container justify="flex-start">
          <CustomTabs
            value={tab}
            onChange={handleChange}
            aria-label="simple tabs example"
          >
            <CustomTab label="Incidencias" value={0} />
            {CheckPermission("Nominas.PreNomina") && (
              <CustomTab label="Pre-Nómina" value={1} />
            )}
            {CheckPermission("Nominas.FiniquitoLiquidacion") && (
            <CustomTab label="Finiquito / Liquidación" value={2} /> )}
            {CheckPermission("Nominas.Eventuales") && (
            <CustomTab label="Eventuales" value={3} />)}
             {CheckPermission("Nominas.Historicas") && (
            <CustomTab label="Históricas" value={4} />)}
             {CheckPermission("Nominas.Calculadora") && (
            <CustomTab label="Calculadora" value={5} />)}
            {CheckPermission("Nominas.Reportes") && (
            <CustomTab label="Reportes" value={6} />)}
          </CustomTabs>
        </Grid>
      </Box>
      <div className="contenedor2">
        {tab === 0 && <IncidentPayrollTab value={tab} index={0} sistemaActivo={sistemaActivo} />}
        {tab === 1 && <ActivePayrollTab value={tab} index={1} sistemaActivo={sistemaActivo} />}
        {tab === 2 && <SettlementPayrollTab value={tab} index={2} sistemaActivo={sistemaActivo} />}
        {tab === 3 && <EventualTab value={tab} index={3} sistemaActivo={sistemaActivo} />}
        {tab === 4 && <HistoricalPayrollTab value={tab} index={4} />}
        {tab === 5 && <CalculadoraTab value={tab} index={5} />}
        {tab === 6 && <ReportsPayrollTab value={tab} index={6} />}
      </div>
    </div>
  );
};

export default PayrollScreenBase;
