import { Grid, FormHelperText } from "@material-ui/core";
import Box from '@mui/material/Box';
import { useState } from 'react';
import './ModalPolitics.css';

interface Holidays {
  days: string;
  year: string;
  id?: string;
  _id: string;
  [key:string]:any;
}

function HolidaysList({politic, holidays}:{politic:any, holidays:Holidays[]}){

  return (
    <div style={{marginBottom:50}}>
        <Grid container justify="center">
          <Grid item xs={12} md={10}>
            {
              politic && (

                <Grid container className="table-days-container">
                  <Grid xs={12}>
                    <div className="table-header-container">
                      <Grid container>
                        <Grid xs={3} md={5}>
                          <div className="table-header table-border-right">
                            <p>Años trabajados</p>
                          </div>
                        </Grid>
                        <Grid xs={9} md={7}>
                          <div className="table-header">
                            <p>D&iacute;as de vacaciones</p>
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                  </Grid>
                  { holidays.map((holiday:Holidays, key: number)=>(
                    <Grid item xs={12} md={12} key={key}>
                      <HolidayRow holiday={holiday} key={ holiday.id ? holiday.id : holiday._id}></HolidayRow>
                    </Grid>
                  )) } 
                </Grid>
              )
            }
          </Grid>
        </Grid>
    </div>
  )
}

function HolidayRow({holiday}:{holiday:Holidays}){

  const [days, setDays] = useState(holiday.days);

  const onChange = (e:any) => {
    const value = Math.floor(Number(e.target.value))
    holiday[e.target.name] = value
    holiday['changed'] = true;
    setDays(String(value));
  }
  
  return (
    <Box>
      <Grid container alignItems="center" className="politic-table-row">
        <Grid item xs={3} md={5} className="politic-table-padding-0">
          <p className="year-label">{holiday.year}</p>
        </Grid>
        <Grid item xs={9} md={7} className="politic-table-padding-0">
          <input className="input-field" name="days" type="number" onChange={onChange} value={days}></input>
          <FormHelperText style={{textAlign:'center',color: "red"}}>
            {holiday.error &&
              holiday.error !== '' &&
              holiday.error}
          </FormHelperText>
        </Grid>
      </Grid>
    </Box>
  )
}

export default HolidaysList