/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import Box from '@mui/material/Box';

import SearcherTable from "../../../screens/Colaboradores/Colaboradores/CollaboratorTab/SearcherTable";
import TableBits from "../../TableBits/TableBits";
import ObjectiveRowOptions from "../Modals/ObjectiveRowOptions";
import objectiveTableColumns from "./ObjectiveTableColumn";
import { GET_ALL_USER_CAMPAIGN_OBJECTIVES } from "../../../Querys/querys";
import ObjectivesContext, { Types } from "../../../context/ObjectiveContext/ObjectiveContext";
import Btnfilter from "../../Campaigns/components/Btnfilter";

import styles from "../Objectives.module.css";

const ObjectivesTable = () => {
  const { state, dispatch } = useContext(ObjectivesContext);
  const { data, refetch } = useQuery(GET_ALL_USER_CAMPAIGN_OBJECTIVES, {
    variables: {
      input: {
        campaignId: state.campaignId,
        collaboratorId: state.collaboratorId,
      }
    },
    pollInterval: 1000,
  });
  const [objectivesListFilter, setObjectivesListFilter] = useState([]);

  useEffect(() => {
    if (state.campaignId !== 0 && state.collaboratorId !== 0) {
      refetch({
        input: {
          campaignId: state.campaignId,
          collaboratorId: state.collaboratorId,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.campaignId, state.collaboratorId]);

  useEffect(() => {
    if (data?.GET_ALL_USER_CAMPAIGN_OBJECTIVES) {
      setObjectivesListFilter(data.GET_ALL_USER_CAMPAIGN_OBJECTIVES.map((x: any) =>
        ({ ...x, progress: x.progress ? `${x.progress} / ${Math.floor(parseFloat(x.progress || "0") / parseInt(x.metricObjective, 10) * 100)} %` : '0' })
      ));
      dispatch({ type: Types.SET_COLLABORATOR_OBJECTIVES_COUNT, payload: data.GET_ALL_USER_CAMPAIGN_OBJECTIVES.length });
      const allObjectivesAreApproved = data.GET_ALL_USER_CAMPAIGN_OBJECTIVES.every((objective: any) => objective.objetiveSteps === 'Aprobada');
      const allObjectivesAreNotified = data.GET_ALL_USER_CAMPAIGN_OBJECTIVES.every((objective: any) => objective.isLeaderNotified);
      dispatch({ type: Types.SET_DISPLAY_NOTIFY_LEADER_BUTTON, payload: !allObjectivesAreNotified });
      dispatch({ type: Types.SET_DISPLAY_APPROVE_OBJECTIVE_BUTTONS, payload: !allObjectivesAreApproved });
    }
  }, [data]);

  return (
    <Box display="flex" flexDirection="column" p={2}>
      <Box p={1} pb={3} display="flex" flexDirection="row">
        <Box display="flex" justifyContent="flex-start">
          <SearcherTable
            label="Buscar objetivos ..."
            initState={data?.GET_ALL_USER_CAMPAIGN_OBJECTIVES || [] as any}
            setState={setObjectivesListFilter}
            fields={["ObjectiveName", "nameIndicator", "metricIndicator"]}
          />
        </Box>

        <Box
          display="flex"
          flexDirection="row"
          justifyContent="flex-end"
          className={styles.filterContainer}
        >
          <Btnfilter
            title="Tipo de indicador"
            option="metricIndicator"
            setState={setObjectivesListFilter}
            // campaignFilter={campaignFilter}
            setStepFilter={setObjectivesListFilter}
            stateInitial={data?.GET_ALL_USER_CAMPAIGN_OBJECTIVES}
            filterCollaborators
          />
        </Box>
      </Box>
      {
        data?.GET_ALL_USER_CAMPAIGN_OBJECTIVES?.length === 0 && (
          <div className={styles.noObjectives}>
            <div className={styles.noObjectivesContainer}>
              <div className={styles.noObjectivesText}>Comienza a crear objetivos para </div>
              <div className={styles.noObjectivesText}>alcanzar todas las metas de la organización</div>
            </div>
          </div>
        )
      }
      {
        objectivesListFilter?.length > 0 && (
          <TableBits
            columns={objectiveTableColumns}
            rows={objectivesListFilter}
            componentOptions={ObjectiveRowOptions}
            skipPermission
            skipSort
          />
        )
      }
    </Box>
  );
};

export default ObjectivesTable;