import './style.css'

const CollaboratorIconIMG = (props: any) =>{
    const ImagenUsuario = props.ImagenUsuario
    const width = props.width
    const height = props.height
    const widthImg = props.widthImg
    const heightImg = props.heightImg
    
    return(
        <div className="circulo_secundario colorWhite_secundario"
        style={{
            width: width,
            height: height
        }}
        >
            <img
            style={{
                width: widthImg,
                height: heightImg
            }}
            src={ImagenUsuario}
            alt="img"
            className="imgCollaborator__BITSss"
            />
        </div>
    )
}

export default CollaboratorIconIMG