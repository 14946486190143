import moment from 'moment';

const ScheduleThursdayField = ({HorarioJueves}:any) => {
    const dateSplit = HorarioJueves ? HorarioJueves.split(' - ') : null;
    return (
        <div>
            <label>{ dateSplit ? moment(dateSplit[0]).format('hh:mm A') +" - "+moment(dateSplit[1]).format('hh:mm A') : ''}</label>
        </div>
    )
}

export default ScheduleThursdayField
