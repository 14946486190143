import { downloadExcel } from "react-export-table-to-excel";

export const DownloadExcel=(nameFile:any,nameSheet:any,header:any,body:any)=> {
     downloadExcel({
      fileName: nameFile,
      sheet: nameSheet,
      tablePayload: {
        header,      
        body: body 
      }
    });
  }