import { useContext, useEffect, useState } from "react";
import {
  Checkbox,
  FormControlLabel,
  Grid
} from "@material-ui/core";
import Box from '@mui/material/Box';
import CollaboratorContext from "../../../../context/CollaboratorContext/CollaboratorContext";
import EyeIcon from "../../../../assets/svg/eye.svg";

const OptionalDocumentsInactivos = () => {
  const { state } = useContext(CollaboratorContext);
  const [archives, setArchives] = useState([]);
  const [isAvisoRetencion, setIsAvisoRetencion] = useState(false);
  const [isCartaGMM, setIsCartaGMM] = useState(false);


  useEffect(() => {
    if (state.collaborator) {
      setIsAvisoRetencion(state.collaborator.isAvisoRetencion);
      setIsCartaGMM(state.collaborator.isCartaGMM);
      if (state.collaborator?.archive) {
        setArchives(state.collaborator.archive);
      }
    }
  }, [state.collaborator]);

  

  return (
      <div className="novalidate__border">
        <Grid direction="row" container>
          <Grid xs={6} item className="aviso-retencion">
            {/*AVISO DE RETENCIÓN  */}
            {archives?.map(
              (archive: any, index: number) =>
                archive?.name === "AvisoRetencion_PDF" && (
                  <div className="flex-container">
                    <div className="flex-child">
                      <label>Aviso de retención</label>
                      <span className="span-file">
                        (En caso de tener crédito de Infonavit)
                      </span>
                      <span className="span-file">
                        <a
                          className="view-document"
                          target="_blank"
                          rel="noreferrer"
                          href={archive.URL}
                        >
                          <img
                            style={{ height: "10px", marginTop: "5px" }}
                            src={EyeIcon}
                            alt=""
                          />
                          Ver documento
                        </a>
                      </span>
                    </div>
                    
                  </div>
                )
            )}

            {!state.collaborator?.AvisoRetencion_PDF && (
              <div className="flex-container">
                <div className="flex-child">
                  <label>Aviso de retención</label>
                  <span className="span-file">
                    (En caso de tener crédito de Infonavit)
                  </span>
                </div>

                
              </div>
            )}
            <br />
            <div className="">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isAvisoRetencion}
                    value={isAvisoRetencion}
                    name="isAvisoRetencion"
                    color="primary"
                    disabled={ true }
                  />
                }
                label="Seleccione esta opción si no cuenta con el documento"
              />
            </div>
          </Grid>
          <Grid xs={6} item className="aviso-retencion">
            <Box ml={1}>
              {archives?.map(
                (archive: any, index: number) =>
                  archive?.name === "CartaGMM_PDF" && (
                    <div className="flex-container" key={index}>
                      <div className="flex-child">
                        <label>
                          Carta de Antigüedad de Seguro de Gastos Médicos
                          Mayores
                        </label>
                        <span className="span-file">
                          En caso de contar con algún plan de la prestación de
                          SGMM, deberás entregar dentro del plazo de 30 días
                          posterior a fecha de baja)
                        </span>
                        <span className="span-file">
                          <a
                            className="view-document"
                            target="_blank"
                            rel="noreferrer"
                            href={archive.URL}
                          >
                            <img
                              style={{ height: "10px", marginTop: "5px" }}
                              src={EyeIcon}
                              alt=""
                            />
                            Ver documento
                          </a>
                        </span>
                      </div>

                      
                    </div>
                  )
              )}

              {!state.collaborator?.CartaGMM_PDF && (
                <div className="flex-container">
                  <div className="flex-child">
                    <label>
                      Carta de Antigüedad de Seguro de Gastos Médicos Mayores
                    </label>
                    <span className="span-file">
                      (En caso de contar con algún plan de la prestación de SGMM,
                      deberás entregar dentro del plazo de 30 días posterior a
                      fecha de baja)
                    </span>
                  </div>

                  
                </div>
              )}
              <br />
              <FormControlLabel
                control={
                  <Checkbox
                    //checked={(state.collaborator.Archivos.CartaGMM_PDF?.toString() == 'NA')}
                    checked={isCartaGMM}
                    value={isCartaGMM}
                    onChange={() => setIsCartaGMM(!isCartaGMM)}
                    
                    name="isCartaGMM"
                    color="primary"
                    disabled={ true }
                  />
                }
                label="Seleccione esta opción si no cuenta con el documento"
              />
            </Box>
          </Grid>
        </Grid>
      </div>
      
  
  );
};

export default OptionalDocumentsInactivos;
