import { useEffect, useCallback } from 'react';
import {
    Grid
} from '@material-ui/core';
import Box from '@mui/material/Box';
import _ from 'lodash';
import { styleCollaboratorTab as style } from './styleSheet';
import { Dispatch, SetStateAction, useState } from 'react';

interface Props<T> {
    initState: Array<T>;
    setState: Dispatch<SetStateAction<T[]>> | ((a: T[]) => void);
    label?: string;
    fields?: Array<string>;
    width?: number,
    updatePaginationObj? : (paginationObj: any) => any
    getData? : (paginationObj: any) => Promise<any>,
    paginationObj? : any
    customSearch?: any
}

const SearcherTable = <T extends Object>({
    initState,
    setState,
    label = "Buscar",
    fields = [],
    width = 420,
    updatePaginationObj,
    getData,
    paginationObj={},
    customSearch
}: Props<T>) => {
    const [text, setText] = useState('');

    const getRows : any = useCallback(async (paginationObj?:any) => {
            let dataFiltered;

            if (text === "") {
                dataFiltered = initState;
            } else {
                dataFiltered = initState.filter((e) => {
                    const value: { [unit: string]: any } = e;
                
                    let itemData = ``;
                    if (fields.length > 0) {
                        fields.forEach((field) => {
                            if(value[field] && typeof value[field] === 'string'){
                                itemData += `${value[field].toUpperCase()} `;
                            }
                        });
                    } else {
                        const fieldsElement = Object.keys(e);
                        fieldsElement.forEach((field) => {
                            if(value[field] && typeof value[field] === 'string'){
                                itemData += `${value[field].toUpperCase()} `;
                            }
                        });
                    }
                    return itemData.indexOf(text.toUpperCase()) > -1;
                });
            }
            setState([...dataFiltered]);
        
    }, [text]);
    

    const handleChange = (text: string) => {
        if (customSearch) {
            setText(text);
            customSearch(text);
            return;
        }
        if ( updatePaginationObj ) {
            updatePaginationObj({ searchTerm: text })
        }
        setText(text);
    };

    useEffect(() => {
        if (customSearch) return;
        const pagination = {
          ...paginationObj,
          searchTerm: text,
        };
        getRows(pagination).then();
      }, [ text,getRows ])
      

    return (
        <Grid item>
            <Box >

                <Grid
                    container
                    direction="row"
                    style={{...style.containerSearcher, width: width}}
                >

                    <Grid item >
                        <input
                            style={{...style.inputSearcher, width: (width - 100)}}
                            className="collaboratorTab__searcher"
                            placeholder={label}
                            onChange={(e) => handleChange(e.target.value)}

                        />
                    </Grid>

                    <Grid item style={{
                        width: "20px",
                        padding: "0 20px 0 40px",
                        color: "#7f7f81",
                        fontStyle: "bold",
                    }}>
                        <img
                            src="/assets/icons/icono-buscar.svg"
                            alt="Buscar"
                        />
                    </Grid>

                </Grid>
            </Box>
        </Grid>

    )
};

export default SearcherTable
