import { MenuItem, Menu, Fade } from "@material-ui/core";
import Box from '@mui/material/Box';
import MoreVertIcon from "@material-ui/icons/MoreVert";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import { MouseEvent, useContext, useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import { DELETE_WORKINGHOURS,GET_ALL_WORKINGHOURS } from "../../../../../Querys/querys";
import { useMutation } from "@apollo/client";
import { WarningAlert } from "../../../../../alerts/WarningAlert";
import { SuccessfulAlert } from "../../../../../alerts/successAlerts";
import CalendarsContext from "../../../../../context/NewCalendarContext/CalendarsContext";
import { openUpdateWorkSchedule, deleteWorkSchedule } from "../../../../../context/NewCalendarContext/Actions";

const ScheduleOptionsField = (props: any) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [deleteWorkingHour, {data:resultDelete}] = useMutation(DELETE_WORKINGHOURS, {
    refetchQueries: [
        { query: GET_ALL_WORKINGHOURS }
    ],
  });
  const {state, dispatch} = useContext(CalendarsContext);

  const handleOpen = (e: MouseEvent<HTMLElement>) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(()=>{
    if(resultDelete && resultDelete.DELETE_WORKINGHOURS){
      handleClose();
      SuccessfulAlert({text:'Jornada eliminada con éxito'});
      deleteWorkSchedule( !state.deleteWorkSchedule ,dispatch);
    }
  }, [resultDelete]);

  const removeWorkingHour = async () => {
    let response = await WarningAlert({ text: "¿Deseas eliminar la jornada laboral?", showDenyButton: true, confirmButtonText: "Eliminar" });
    if (response.isConfirmed) {
        await deleteWorkingHour({
            variables:{
              id: props.id
            }
        });
    }       
  }

  const openUpdate = () => {
    handleClose(); 
    openUpdateWorkSchedule(true, props.id, dispatch);
  }

  return (
    <div>
      <Box>
        <Tooltip title="Opciones" placement="right">
          <Box className="IconButtonPoints">
            <IconButton
              onClick={handleOpen}
              aria-controls="fade-menu"
              aria-haspopup="true"
            >
              <MoreVertIcon style={{ color: "#fabb00" }} />
            </IconButton>
          </Box>
        </Tooltip>
      </Box>
      <Menu
        id="fade-menu"
        anchorEl={anchorEl}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        keepMounted
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        <MenuItem
          onClick={() =>{ openUpdate() }}
        >
          Editar&nbsp;
          <Grid container item justify="flex-end">
            <img src="/assets/svg/icono-editar.svg" alt="Editar" />
          </Grid>
        </MenuItem>
        <MenuItem
          divider
          onClick={() => { removeWorkingHour() }}
        >
          Eliminar&nbsp;
          <Grid container item justify="flex-end">
            <img src="/assets/svg/icono-eliminar.svg" alt="Eliminar" />
          </Grid>
        </MenuItem>
      </Menu>
    </div>
  );
};

export default ScheduleOptionsField;
