import moment from 'moment';

const ScheduleWednesdayField = ({HorarioMiercoles}:any) => {
    const dateSplit = HorarioMiercoles ? HorarioMiercoles.split(' - ') : null;
    return (
        <div>
            <label>{ dateSplit ? moment(dateSplit[0]).format('hh:mm A') +" - "+moment(dateSplit[1]).format('hh:mm A') : ''}</label>
        </div>
    )
}

export default ScheduleWednesdayField
