import { ChangeEvent, useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { Grid } from '@material-ui/core';
import InactivosCollaboratorTab from '../components/Collaborators/InactivosCollaboratorTab';
import ModalState from '../context/ModalContext/ModalState';
import CustomTabs from '../components/Collaborators/Tab/CustomTabs';
import CustomTab from '../components/Collaborators/Tab/CustomTabMain';
import '../components/Collaborators/collaborator.css';
import CheckPermission from '../helpers/Administradores/Permissions';

const InactivosScreen = () => {
    const [tab, setTab] = useState(0);

    const handleChange = (event: ChangeEvent<{}>, newValue: number) => {
        setTab(newValue)
        localStorage.setItem('currentPill', String(newValue));
    };

    useEffect(() => {
        let pill = localStorage.getItem('currentPill');
        if (pill) setTab(Number(pill));
        else setTab(0);
    }, [tab]);

    return (
        <div>
            <Box mt={3} ml={5} className="Title">
                Colaboradores
            </Box>
            <Box p={5} pb={3} pt={0}>

                <Grid
                    container
                    justify="flex-start"
                >
                    <CustomTabs
                        value={tab}
                        onChange={handleChange}
                        aria-label="simple tabs example"
                    > 
                      {CheckPermission("Colaboradores.NuevoIngreso") && (
                        <CustomTab label="Nuevo Ingreso" value={0} />
                        )}
                        {CheckPermission("Colaboradores.Colaboradores") && (
                        <CustomTab label="Colaboradores" value={1} />
                        )}
                        {CheckPermission("Colaboradores.Declinados") && (
                        <CustomTab label="Declinados" value={2} />
                        )}
                        {CheckPermission("Colaboradores.Inactivos") && (
                        <CustomTab label="Inactivos" value={3} />
                        )}
                    </CustomTabs>
                </Grid>

            </Box>

            <div className="collaborator__container">
                {}
                {tab === 0 && <InactivosCollaboratorTab value={tab} index={0} />}
                {tab === 1 && <InactivosCollaboratorTab value={tab} index={1} />}
                {tab === 2 && <InactivosCollaboratorTab value={tab} index={2} />}
                <ModalState>
                    {tab === 3 && <InactivosCollaboratorTab value={tab} index={3} />}
                    {tab === 4 && <InactivosCollaboratorTab value={tab} index={4} />}
                </ModalState>
            </div>
        </div>
    )
};

export default InactivosScreen;
