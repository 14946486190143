
import moment from 'moment';

const ScheduleFridayField = ({HorarioViernes}:any) => {
    const dateSplit = HorarioViernes ? HorarioViernes.split(' - ') : null;
    return (
        <div>
            <label>{ dateSplit ? moment(dateSplit[0]).format('hh:mm A') +" - "+moment(dateSplit[1]).format('hh:mm A') : ''}</label>
        </div>
    )
}

export default ScheduleFridayField
