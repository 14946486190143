import HistoricoCentralPagos from "./HistoricoCentralPagosTable";

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

const HistoricoCentralPagosTab = (props: TabPanelProps) =>{
  const { children, value, index, ...other } = props;
  return(
    <HistoricoCentralPagos />
  )
}

export default HistoricoCentralPagosTab