import { useContext, useState } from "react";
import {
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  Select,
  TextField,
  Button,
} from "@material-ui/core";
import Box from '@mui/material/Box';
import CollaboratorContext from "../../../../context/CollaboratorContext/CollaboratorContext";
import { SuccessfulAlert } from "../../../../alerts/successAlerts";
import SaveIcon from "@material-ui/icons/Save";
import { useMutation } from "@apollo/client";
import { GET_USERS_BY_ID, UPDATE_USERS } from "../../../../Querys/querys";
import InputMask from "react-input-mask";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Form } from "semantic-ui-react";
import { handleSetActiveStep } from "../../../../helpers/Collaborator/Collaborator";

const EmergenciaColaboradores = () => {
  const { state, dispatch } = useContext(CollaboratorContext);
  const [phoneCEMask, setphoneCEMask] = useState("");
  const [phoneMovilMask, setPhoneMovilMask] = useState("");

  const [updateColaboradores] = useMutation(UPDATE_USERS, {
    refetchQueries: [
      {
        query: GET_USERS_BY_ID,
        variables: { getUsersId: state.collaborator.id },
      },
    ],
  });

  const handleChange = async (e: any) => {
  };

  const initialValues = () => {
    return {
      emergencyContact: state.collaborator?.emergencyContact ?? "",
      ParentescoCE: state.collaborator?.ParentescoCE ?? "",
      phoneCE: state.collaborator?.phoneCE ?? "",
      cellphoneCE: state.collaborator?.cellphoneCE ?? "",
      suburbCE: state.collaborator?.suburbCE ?? "",
      addressCE: state.collaborator?.addressCE ?? "",
    };
  };

  const formik = useFormik({
    initialValues: initialValues(),
    validationSchema: Yup.object(validationSchema()),

    onSubmit: async (formData) => {
      updateColaboradores({
        variables: {
          updateUsersId: state.collaborator?.id,
          input: formData,
          process: {
            tab: "emergency",
            section: "section_2",
          },
        },
      }).then((res) => {
        SuccessfulAlert({ text: "Se actualizó correctamente" });
        handleSetActiveStep(2, dispatch);
      });
    },
  });

  return (
    <Form onSubmit={formik.handleSubmit}>
      <div className="novalidate__border">
        <Box mt={2} mb={2}>
          <TextField
            name="emergencyContact"
            defaultValue={state.collaborator?.emergencyContact}
            autoFocus={true}
            label="Nombre del contacto de emergencia"
            variant="outlined"
            size="small"
            fullWidth={true}
            onBlur={(e) => handleChange(e)}
            InputProps={{
              readOnly: false,
            }}
            error={formik.touched.emergencyContact && Boolean(formik.errors.emergencyContact)}
            helperText={formik.touched.emergencyContact && formik.errors.emergencyContact}
            onChange={formik.handleChange}
          />
        </Box>

        <Box mt={2}>
          <Grid direction="row" container spacing={2}>
            <Grid xs item>
              <FormControl variant="outlined" fullWidth={true} size="small">
                <InputLabel htmlFor="outlined-age-native-simple">
                  Parentesco
                </InputLabel>
                <Select
                  native
                  onBlur={(e) => handleChange(e)}
                  label={"Parentesco"}
                  name="ParentescoCE"
                  defaultValue={state.collaborator?.ParentescoCE || ""}
                  autoFocus={true}
                  error={formik.touched.ParentescoCE && Boolean(formik.errors.ParentescoCE)}
                  onChange={formik.handleChange}
                >
                  <option
                    value={state.collaborator?.ParentescoCE || ""}
                    disabled={true}
                  >
                    {state.collaborator?.ParentescoCE || ""}
                  </option>
                  <option value="Madre">Madre</option>
                  <option value="Padre">Padre</option>
                  <option value="Hijo(a)">Hijo(a)</option>
                  <option value="Esposo(a)">Esposo(a)</option>
                  <option value="Concubino(a)">Concubino(a)</option>
                  <option value="Hermano(a)">Hermano(a)</option>
                  <option value="Novio">Novio</option>
                  <option value="Novia">Novia</option>
                  <option value="Amigo(a)">Amigo(a)</option>
                  
                </Select>
                <FormHelperText>
                  {formik.touched.ParentescoCE && formik.errors.ParentescoCE}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid xs item>
              <InputMask
                mask="999 999 99 99"
                defaultValue={state.collaborator?.phoneCE || phoneCEMask}
                onChange={(e) => {
                  setphoneCEMask(e.target.value);
                  handleChange(e);
                }}
                disabled={false}
                onBlur={formik.handleChange}
              >
                <TextField
                  name="phoneCE"
                  autoFocus={true}
                  label="Teléfono fijo"
                  variant="outlined"
                  size="small"
                  fullWidth={true}
                  error={formik.touched.phoneCE && Boolean(formik.errors.phoneCE)}
                  helperText={formik.touched.phoneCE && formik.errors.phoneCE}
                />
              </InputMask>
            </Grid>
          </Grid>
        </Box>

        <Box mt={2}>
          <Grid direction="row" container spacing={2}>
            <Grid xs item>

              <InputMask
                mask="999 999 99 99"
                defaultValue={state.collaborator?.cellphoneCE || phoneMovilMask}
                onChange={(e) => {
                  setPhoneMovilMask(e.target.value);
                  handleChange(e);
                }}
                disabled={false}
                onBlur={formik.handleChange}
              >
                <TextField
                  name="cellphoneCE"
                  autoFocus={true}
                  label="Teléfono móvil"
                  variant="outlined"
                  size="small"
                  fullWidth={true}
                  helperText={
                    formik.touched.cellphoneCE &&
                    formik.errors.cellphoneCE
                  }
                  error={formik.touched.cellphoneCE && Boolean(formik.errors.cellphoneCE)}
                />
              </InputMask>
            </Grid>
            <Grid xs item>
              <TextField
                name="suburbCE"
                defaultValue={state.collaborator?.suburbCE}
                autoFocus={true}
                label="Colonia"
                variant="outlined"
                size="small"
                fullWidth={true}
                onBlur={(e) => handleChange(e)}
                InputProps={{
                  readOnly: false,
                }}
                helperText={formik.touched.suburbCE && formik.errors.suburbCE}
                error={formik.touched.suburbCE && Boolean(formik.errors.suburbCE)}
                onChange={formik.handleChange}
              />
            </Grid>
          </Grid>
        </Box>

        <Box mt={2}>
          <Grid direction="row" container spacing={2}>
            <Grid xs item>
              <TextField
                name="addressCE"
                defaultValue={state.collaborator?.addressCE}
                autoFocus={true}
                label="Calle y número"
                variant="outlined"
                size="small"
                fullWidth={true}
                onBlur={(e) => handleChange(e)}
                InputProps={{
                  readOnly: false,
                }}
                helperText={formik.touched.addressCE && formik.errors.addressCE}
                error={formik.touched.addressCE && Boolean(formik.errors.addressCE)}
                onChange={formik.handleChange}
              />
            </Grid>
          </Grid>
        </Box>
      </div>
      <div>
      </div>

      <Grid
        direction="row"
        container
        justify="flex-end"
        alignItems="center"
        style={{ marginTop: "20px" }}
      >
        <Button type="submit" className="buttonSave">
          <SaveIcon />
          &nbsp; Guardar
        </Button>
      </Grid>
    </Form>
  );
};

const validationSchema = () => {
  return {
    emergencyContact: Yup.string().required("Obligatorio"),
    ParentescoCE: Yup.string().required("Obligatorio"),
    phoneCE: Yup.string().required("Obligatorio"),
    cellphoneCE: Yup.string().required("Obligatorio"),
    suburbCE: Yup.string().required("Obligatorio"),
    addressCE: Yup.string().required("Obligatorio"),
  };
};

export default EmergenciaColaboradores;
