import { Grid } from "@material-ui/core";
import { postFileProcessIncident } from "../../../../services/IncidentService";
import { SuccessfulAlert } from '../../../../alerts/successAlerts';

const FileIncidentReport = ({
  accept = ".xls , .xlsx",
  disabled = false
}: any) => {
  const uploadFileProcessIncident = async (e: any): Promise<void> => {
    let result = await postFileProcessIncident(e);
    if (result) {
        SuccessfulAlert({ title: "¡Exito!", text: "¡Se ha cargado el archivo correctamente!" })
    }
  };

  return (
    <Grid>
      <label className="botonSubirDocumento">
        <input
          type="file"
          accept={accept}
          disabled={disabled}
          onChange={(e) => {
            uploadFileProcessIncident(e)
            e.target.value = "";
          }}
        />
        Cargar Layout
      </label>
    </Grid>
  );
};

export default FileIncidentReport;
