/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useLazyQuery } from "@apollo/client";
import { Box, Grid } from "@material-ui/core";

import Header from "../../components/Header/Header";
import { GET_CAMPAIGN_BY_TOKEN } from "../../Querys/querys";
import ObjectivesContext from "../../context/ObjectiveContext/ObjectiveContext";
import { Types } from "../../context/ObjectiveContext/ObjectiveContext";
import CollaboratorCampaignCard from "../../components/Campaigns/collaboratorCampaignCard/CollaboratorCampaignCard";
import Btnfilter from "../../components/Campaigns/components/Btnfilter";
import SearcherCard from "../../components/Campaigns/components/SearcherCard";
import FechaRango from "../../components/Campaigns/modals/FechaRango";
import CampaignState from "../../context/CampaignsContext/CampaignState";
import { ErrorAlert } from "../../alerts/errorAlert";

const CampaignOpenScreen = () => {
  const { campaign, user, token } = useParams<{ campaign: string; user: string; token: string }>();
  const [verifyTokenQuery] = useLazyQuery(GET_CAMPAIGN_BY_TOKEN, { fetchPolicy: 'no-cache' });
  const { dispatch } = useContext(ObjectivesContext);
  const [campaigns, setCampaigns] = useState([]);
  const [campaignFilter, setcampaignFilter] = useState([])
  const [stepFilter, setStepFilter] = useState([])
  const [isInvalidToken, setIsInvalidToken] = useState(false);

  const interval = useRef<any>(null);

  const verifyToken = async () => {
    try {
      if (isInvalidToken) return;
      const result = await verifyTokenQuery({
        variables: {
          campaignId: parseInt(campaign, 10),
          userId: parseInt(user, 10),
          token
        },
        // fetchPolicy: 'no-cache'
      });
      if (result?.error) throw new Error('Error at verifiying token');
      const userData = result.data?.GET_CAMPAIGN_BY_TOKEN?.user;
      const campaigns = result.data?.GET_CAMPAIGN_BY_TOKEN?.campaigns;
      dispatch({
        type: Types.SET_USER_FROM_LINK,
        payload: userData
      })
      dispatch({
        type: Types.SET_IS_FROM_LINK,
        payload: true
      })
      dispatch({
        type: Types.SET_CAMPAIGN_ID,
        payload: parseInt(campaign, 10)
      })
      dispatch({
        type: Types.SET_ROL,
        payload: 'collaborator'
      })
      setCampaigns(campaigns);
      setStepFilter(campaigns);
    } catch (error: any) {
      if (error.message === 'Error at verifiying token') {
        setIsInvalidToken(true);
        clearInterval(interval?.current);
        ErrorAlert({ text: 'Lo sentimos, la campaña a la que intentas acceder ya no está activa. Por favor, comunúcate con el administrador o líder correspondiente para obtener más información o para verificar si hay nuevas campañas disponibles. Gracias por tu comprensión.' });
      }
    }
  }

  useEffect(() => {
    if (campaign && user && token) {
      verifyToken()
      interval.current = setInterval(verifyToken, 3000)
      return () => clearInterval(interval?.current)
    }
  }, [campaign, token, user])

  return (
    <CampaignState>
      {isInvalidToken && (<div />)}
      {
        !isInvalidToken && (
          <>
            <Header fromLink />
            <div style={{
              backgroundColor: "#F5F6F9",
              minHeight: "calc(100vh - 74px)",
              padding: '50px 50px 0px 50px'
            }}>
              <Box mt={3} ml={5} className="Title" mb={2}>
                KPI's y bonos
              </Box>
              <div style={{
                backgroundColor: "#FFFFFF",
                borderRadius: "10px",
                padding: "20px",
                marginTop: "20px",
                height: "100%"
              }}>
                <Grid container className="cardsCampaign">
                  <Grid container className="screenBtns" style={{ marginBottom: '20px' }}>
                    <Grid className="findCampaign" style={{ marginLeft: 0, marginTop: 0 }}>
                      <SearcherCard
                        initState={campaigns}
                        setState={setCampaigns}
                        stateInitial={stepFilter}
                        setStepFilter={setStepFilter}
                        stepCampaignFilter={campaigns}
                        label={"Buscar"}
                        fields={['nameCampaign']}
                        width={400}
                      />
                    </Grid>
                    <Grid className="filtersCampaign" style={{ marginTop: 0 }}>
                      <Btnfilter title="Fecha" option="btnFecha"
                        initState={campaigns} setState={setCampaigns}
                        campaignFilter={campaignFilter} stateInitial={campaign}
                        setStepFilter={setStepFilter} />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    {
                      stepFilter?.length && stepFilter?.map((campaignProp: any) => (
                        <Grid item key={campaignProp.id}>
                          <CollaboratorCampaignCard
                            campaign={{
                              ...campaignProp,
                              objetivosIndiceExito: campaignProp.realProgress,
                              countColaboradores: campaignProp.totalCollaborators,
                              countObjetivos: campaignProp.objetives,
                              maxObjectives: campaignProp.maxObjectives,
                              minObjectives: campaignProp.minObjectives,
                              objetivosProgresoEsperado: campaignProp.expectedProgress,
                              objetivosProgresoReal: campaignProp.realProgress,
                              countObjetivosEvaluados: campaignProp.objetives,
                              fromLink: true,
                              isLeader: campaignProp.isLeader,
                            }}
                          />
                        </Grid>
                      ))
                    }
                  </Grid>
                </Grid>
              </div>
              <FechaRango
                setState={setcampaignFilter}
                setStepFilter={setStepFilter}
                byLink
                campaignId={parseInt(campaign, 10)}
                userId={parseInt(user, 10)}
                token={token}
              />
            </div>
          </>
        )
      }
    </CampaignState>
  );
};

export default CampaignOpenScreen;