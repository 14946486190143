import '../ConfigPayroll/PayrollGroup.css'
import './tableISR.css'
import { useQuery } from "@apollo/client";
import { GET_ALL_TABLEISN } from "../../Querys/querys";
import './TablesValues.css'

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
};

const TableISNTable = (props: TabPanelProps) =>{

  const resulttableISN = useQuery(GET_ALL_TABLEISN);
  const tableISN = resulttableISN.data?.GET_ALL_TABLEISN;

    
    return(
        <>
          <div className="contenedorHeader">
            <div>
              <span className="tituloHeader">Impuesto sobre nómina</span>
            </div>
          </div>
          <div className="contenedorTabla">
            {tableISN?.map((lis:any)=>
              <div>
                <div className="table-row">
                  <div className="d-flex-grow">
                    <span className="fw-bold" style={{marginRight: "60px"}}>Empresa:</span>
                    <span>{lis.RazonSocial}</span>
                  </div>
                  
                  <div style={{ width: "150px" }}>
                      <span className="fw-bold" style={{marginRight: "60px"}}>Porcentaje:</span>
                      <span>{lis.Porcentaje}</span>
                  </div>
                </div>
              </div>
            )}
        </div>
        </>
      )
}

export default TableISNTable