import { Theme, Dialog, withStyles, TextField, FormControl, InputLabel, Select } from "@material-ui/core";
import Box from '@mui/material/Box';
import Checkbox from "@mui/material/Checkbox";
import "../Modal/CrearPayrollGroup.css";
import MuiDialogContent from "@material-ui/core/DialogContent";
import { useContext, useState } from "react";
import { SuccessfulAlert } from "../../../alerts/successAlerts";
import { ErrorAlert } from "../../../alerts/errorAlert";
import { TabPoliticContext } from "../../../context/ConfigPayrollContext/TabPoliticContext";
import "../Politic.css";
import SaveIcon from "@material-ui/icons/Save";
import { useMutation, useQuery } from "@apollo/client";
import {
  GET_ALL_POLITIC,
  CREATE_POLITIC,
  GET_PAYROLLGROUP_POLITIC,
} from "../../../Querys/querys";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Grid } from "semantic-ui-react";
import { formatterMxn } from "../../../helpers/formatoMoneda";
import InputAdornment from "@mui/material/InputAdornment";
import { WarningAlert } from "../../../alerts/WarningAlert";

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);


const CreaPolitica = (props: any) => {
  const [isPantryValue, setIsPantryValue] = useState(false);
  const [isSavingFund, setIsSavingFund] = useState(false);
  const [isRestaurantValue, setIsRestaurantValue] = useState(false);
  const [isBonus, setIsBonus] = useState(false);
  const [pantry_value, setPantry_value] = useState(false);
  const [restaurant_value, setRestaurant_value] = useState(false);
  const [createNewPolitic] = useMutation(CREATE_POLITIC, {
    refetchQueries: [{ query: GET_ALL_POLITIC },
                     { query: GET_PAYROLLGROUP_POLITIC }],
  });
  const { addPoliticOpen, setAddPoliticOpen } = useContext(TabPoliticContext);
  const handleClose = async () => {
    setAddPoliticOpen();
  };
  const { data } = useQuery(GET_PAYROLLGROUP_POLITIC);
  const payrollGroup = data?.GET_PAYROLLGROUP_POLITIC;
  const handleSetFormat = (value: any, field: any) => {
    if (typeof value === "string") {
      // eslint-disable-next-line no-useless-escape
      let valueFormatted = value.replace(/\,/g, "");

      valueFormatted = parseFloat(valueFormatted).toFixed(2);

      let x = formatterMxn(valueFormatted);
      if (x === "nan") return "";
      x = x.substring(1);
      return x;
    }
  };

  const initialValues = () => {
    return {
      policy_name: "",
      pantry_value_type: "No Aplica",
      saving_fund_type: "No Aplica",
      restaurant_value_type: "No Aplica",
      vacation_bonus_type: "No Aplica",
      anniversary_vacation_premium: false,
      pantry_value: false,
      saving_fund: false,
      restaurant_value: false,
      absence_discount: false,
      disability_discount: false,
      economic_days: 0,
      DiasAguinaldo: 0,
      pantry_value_cap: "",
      saving_fund_cap: "",
      restaurant_value_cap: "",
      pantry_type: "Diario",
      saving_type: "Diario",
      restaurant_type: "Diario",
      idPayrollGroup: "",
      vacation_bonus_cap: "",
      vacation_bonus: false,
    };
  };

  const handleCleanFormat = (value: string) => {
    //check if string contains .00
    if (value.includes(".00")) {
      value = value.substring(0, value.indexOf(".00"));
    }
    // eslint-disable-next-line no-useless-escape
    value = value.replace(/\,/g, "");
    // value = value.replace(/[^0-9]/g, "");
    //parse to float and fixed to 2 decimals take the decimal after the comma
    let x = parseFloat(value).toFixed(2);
    let y = parseFloat(x);
    return y;
  };

  const handleSetLimitPantryValue: any = (value: string) => {
    let UMA = 96.22;
    //Get 40% of UMA
    let limit = UMA * 0.4;
    switch (value) {
      case "Diario":
        limit = UMA * 0.4;;
        break;
      case "Quincenal":
        limit = limit * 15;
        break;
      case "Mensual":
        limit = limit * 30;
        break;
      default:
        limit = UMA * 0.4;;
        break;
    }
    //Parse to string limit
    let limitString = limit.toString();
    let res = handleSetFormat(limitString, "pantry_value_cap");
    return Promise.resolve(res);
  };
  const handleSetLimitRestaurantValue: any = (value: string) => {
    let UMA = 96.22;
    //Get 40% of UMA
    let limit = UMA * 0.4;
    switch (value) {
      case "Diario":
        limit = UMA = 96.22;
        break;
      case "Quincenal":
        limit = limit * 15;
        break;
      case "Mensual":
        limit = limit * 30;
        break;
      default:
        limit = UMA = 96.22;
        break;
    }
    //Parse to string limit
    let limitString = limit.toString();
    let res = handleSetFormat(limitString, "restaurant_value_cap");
    return Promise.resolve(res);
  };

  const upDateTotal = (total: any) => {
    total = total.replace(/\..*/, "");
    //remove all especial characters and letters from string
    total = total.replace(/[^0-9]/g, "");
    //parse to int and check if is bigger than 100
    let x = parseInt(total);
    if (x > 100) {
      WarningAlert({ text: "El total no puede ser mayor a 100%" });
      return "";
    } else {
      return total;
    }
  };

  const upDateTotalSavingFound = (total: any) => {
    total = total.replace(/\..*/, "");
    //remove all especial characters and letters from string
    total = total.replace(/[^0-9]/g, "");
    //parse to int and check if is bigger than 100
    let x = parseInt(total);
    if (x > 13) {
      WarningAlert({ text: "El total no puede ser mayor a 13%" });
      return "";
    } else {
      return total;
    }
  };

  const ContError={
     height: "15px"    
  }
    
  const spanError= {
    fontFamily: "Roboto",
    fontWeight: 500,
    fontSize: "13px",
    lineHeight: "15.23px",
    letterSpacing: "0.36px",
    color: "#d31313"  
  }

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={addPoliticOpen}
      fullWidth={false}
      maxWidth={"md"}
    >
      <div className="contenedorModalPolitics mt-10PoliticsModal">
        <p className="tituloModalPolitics">Crear nueva política</p>
      </div>
      <DialogContent className="dialogModalPolitics">
        <Formik
          initialValues={initialValues()}
          validationSchema={validationSchema}
          onSubmit={async (formData: any) => {
            formData.economic_days = parseInt(formData.economic_days);
            formData.DiasAguinaldo = parseInt(formData.DiasAguinaldo);
            formData.idPayrollGroup = parseInt(formData.idPayrollGroup);
            if (String(formData.pantry_value_type) === "Fijo") {
              formData.pantry_value_cap = handleCleanFormat(
                formData.pantry_value_cap
              );
            } else if (String(formData.pantry_value_type) === "Porcentual") {
              formData.pantry_value_cap = parseInt(formData.pantry_value_cap);
            } else {
              formData.pantry_value_cap = parseInt("0");
            }

            if (String(formData.saving_fund_type) === "Fijo") {
              formData.saving_fund_cap = handleCleanFormat(
                formData.saving_fund_cap
              );
            } else if (String(formData.saving_fund_type) === "Porcentual") {
              formData.saving_fund_cap = parseInt(formData.saving_fund_cap);
            } else {
              formData.saving_fund_cap = parseInt("0");
            }

            if (String(formData.restaurant_value_type) === "Fijo") {
              formData.restaurant_value_cap = handleCleanFormat(
                formData.restaurant_value_cap
              );
            } else if (String(formData.restaurant_value_type) === "Porcentual") {
              formData.restaurant_value_cap = parseInt(
                formData.restaurant_value_cap
              );
            } else {
              formData.restaurant_value_cap = parseInt("0");
            }

            if (String(formData.vacation_bonus_type) === "Porcentual") {
              formData.vacation_bonus_cap = parseInt(formData.vacation_bonus_cap);
            } else {
              formData.vacation_bonus_cap = parseInt("0");
            }
            let { data } = await createNewPolitic({
              variables: {
                input: formData,
              },
            });
            if (data) {
              SuccessfulAlert({
                title: "¡Exito!",
                text: "¡Se ha añadido la política correctamente!",
              });
            } else {
              ErrorAlert({
                title: "¡Error!",
                text: "¡No se ha podido añadir la política!",
              });
            }

            handleClose();
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            setFieldValue,
            isSubmitting,
          }) => (
            <Form onSubmit={handleSubmit} className="form-without-css">
              <Box mb={2}>
                <Grid xs item>
                  <TextField
                 // error={touched.policy_name && Boolean(errors.policy_name)}
                    name="policy_name"
                    label="Nombre"
                    value={values.policy_name}
                    variant="outlined"
                    size="small"
                    fullWidth={true}
                    onChange={handleChange}
                  />
                </Grid>
                {errors.policy_name && touched.policy_name ? (
               <div style={ContError}><span style={spanError}>{errors.policy_name}</span></div>
           ) : null}
              </Box>
              <Grid xs item>
                <Box mb={1}>
                  <TextField
                    error={
                      touched.economic_days && Boolean(errors.economic_days)
                    }                    
                    name="economic_days"
                    label="Días económicos"
                    value={values.economic_days}
                    variant="outlined"
                    size="small"
                    fullWidth={true}
                    onChange={handleChange}
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  />
                </Box>
              </Grid>
              <Grid xs item>
                <Box mb={1}>
                  <TextField
                    error={
                      touched.DiasAguinaldo && Boolean(errors.DiasAguinaldo)
                    }
                    name="DiasAguinaldo"
                    label="Días aguinaldo"
                    value={values.DiasAguinaldo}
                    variant="outlined"
                    size="small"
                    fullWidth={true}
                    onChange={handleChange}
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  />
                </Box>
              </Grid>
              <Grid>
                <Box
                
                >
                  <div
                  style={{
                    marginTop: "16px",
                  }}
                  >
                    <FormControl
                      variant="outlined"
                      fullWidth={true}
                      size="small"
                    >
                      <InputLabel
                      style={{
                        backgroundColor: "white",
                      }}
                      
                      >Grupo de Nomina</InputLabel>
                      <Select
                        native
                        onChange={handleChange}
                        name="idPayrollGroup"
                        variant="outlined"
                        error={
                          touched.idPayrollGroup && Boolean(errors.idPayrollGroup)
                        }
                      >
                        <option value={0} disabled={true} selected>
                        Selecciona un Grupo de Nómina
                        </option>
                        {payrollGroup?.map((payrollGroup: any) => (
                          <option
                            className="optionSelect"
                            key={payrollGroup?.id}
                            value={payrollGroup?.id}
                          >
                            {payrollGroup?.group_name}
                          </option>
                        ))}
                      </Select>
                    </FormControl>                    
                  </div>
                  {errors.idPayrollGroup && touched.idPayrollGroup ? (
               <div style={ContError}><span style={spanError}>{errors.idPayrollGroup}</span></div>
           ) : null}
                </Box>
              </Grid>
              <Grid xs item>
                <Box mb={1}>
                  <Checkbox
                    checked={values.anniversary_vacation_premium ? true : false}
                    value={values.anniversary_vacation_premium}
                    onChange={handleChange}
                    name="anniversary_vacation_premium"
                    color="primary"
                  />

                  <span className="textoModalPolitics">
                    Prima vacacional al aniversario
                  </span>
                </Box>
              </Grid>
              <Grid xs item>
                <Box mb={2}>
                  <Box mb={1}>
                    <div style={{ display: "flex" }}>
                      <span className="subtituloModalPolitics">
                        Vales de despensa
                      </span>
                      <div className="iconoAtencion">¡</div>
                    </div>
                  </Box>

                  <div style={{ display: "flex" }}>
                    <select
                      value={values.pantry_value_type}
                      onChange={(e) => {
                        if (values.pantry_value) {
                          e.preventDefault();
                        } else {
                          if (e.target.value === "No Aplica") {
                            setFieldValue("pantry_value_cap", "");
                          }
                          handleChange(e);
                        }
                      }}
                      name="pantry_value_type"
                      className="selectModalPolitics"
                    >
                      <option
                        value="Selecciona una de las categorias"
                        selected
                        disabled
                      >
                        Selecciona una de las categorías
                      </option>
                      <option value="No Aplica">No Aplica</option>
                      <option value="Porcentual">Porcentual</option>
                      <option value="Fijo">Fijo</option>
                    </select>

                    <div>
                      {values.pantry_value_type === "Porcentual" ? (
                        <TextField
                          name="pantry_value_cap"
                          type="text"
                          className="inputMedioModalPolitics"
                          value={values.pantry_value_cap}
                          size="small"
                          fullWidth={true}
                          error={
                            touched.pantry_value_cap ||
                            (String(errors.pantry_value_cap) ===
                              "El valor no debe ser mayor a 100" &&
                              Boolean(errors.pantry_value_cap))
                          }
                          helperText={
                            String(errors.pantry_value_cap) ===
                              "El valor no debe ser mayor a 100" &&
                            "El valor no debe ser mayor a 100"
                          }
                          onChange={(e) => {
                            let res = upDateTotal(e.target.value);
                            setFieldValue("pantry_value_cap", res);
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="start">
                                %
                              </InputAdornment>
                            ),
                          }}
                          disabled={isPantryValue}
                        />
                      ) : (
                        <TextField
                          name="pantry_value_cap"
                          type="text"
                          className="inputMedioModalPolitics"
                          value={values.pantry_value_cap}
                          size="small"
                          fullWidth={true}
                          onChange={(e) => {
                            if (values.pantry_value_type === "Fijo") {
                              handleChange(e);
                            }
                          }}
                          onBlur={() => {
                            if (values.pantry_value_type === "Fijo") {
                              let a = handleSetFormat(
                                values.pantry_value_cap,
                                "pantry_value_cap"
                              );
                              setFieldValue("pantry_value_cap", a);
                            }
                          }}
                          onKeyPress={(event: any) => {
                            if (!/^[0-9.]+$/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          error={
                            touched.pantry_value_cap &&
                            Boolean(errors.pantry_value_cap)
                          }
                          InputProps={{
                            startAdornment: values.pantry_value_type ===
                              "Fijo" && (
                              <InputAdornment position="start">
                                $
                              </InputAdornment>
                            ),
                          }}
                          disabled={isPantryValue}
                        />
                      )}
                    </div>
                    <div>
                      <select
                        onChange={(e) => {
                          handleChange(e);
                          if (pantry_value) {
                            handleSetLimitPantryValue(e.target.value).then(
                              (res: any) => {
                                setFieldValue("pantry_value_cap", res);
                                setFieldValue("pantry_value_type", "Fijo");
                              }
                            );
                          }
                        }}
                        onBlur={(e) => {}}
                        name="pantry_type"
                        className="selectModalPolitics"
                      >
                        <option
                          value="Selecciona una de las categorias"
                          disabled
                        >
                          Selecciona una de las categorías
                        </option>
                        <option value="Diario">Diario</option>
                        <option value="Quincenal">Quincenal</option>
                        <option value="Mensual">Mensual</option>
                      </select>
                    </div>
                  </div>
                  <Box mt={1}>
                    <Checkbox
                      checked={values.pantry_value ? true : false}
                      value={values.pantry_value}
                      onChange={(e) => {
                        handleChange(e);
                        if (e.target.checked) {
                          handleSetLimitPantryValue().then((res: any) => {
                            setFieldValue("pantry_value_cap", res);
                            setFieldValue("pantry_value_type", "Fijo");
                          });
                          setPantry_value(true);
                          setIsPantryValue(true);
                        } else {
                          setFieldValue("pantry_value_cap", "");
                          setFieldValue("pantry_value_type", "No Aplica");
                          setPantry_value(false);
                          setIsPantryValue(false);
                        }
                      }}
                      name="pantry_value"
                      color="primary"
                    />

                    <span className="textoModalPolitics">
                      Tope de vales de despensa
                    </span>
                  </Box>
                </Box>
              </Grid>

              <Grid xs item>
                <Box mb={1}>
                  <Box mb={1}>
                    <div style={{ display: "flex" }}>
                      <span className="subtituloModalPolitics">
                        Fondo de ahorro
                      </span>
                      <div className="iconoAtencion">¡</div>
                    </div>
                  </Box>
                  <div style={{ display: "flex" }}>
                    <select
                      value={values.saving_fund_type}
                      onChange={(e) => {
                        if (values.saving_fund) {
                          e.preventDefault();
                        } else {
                          if (e.target.value === "No Aplica") {
                            setFieldValue("saving_fund_cap", "");
                          }
                          handleChange(e);
                        }
                      }}
                      name="saving_fund_type"
                      className="selectModalPolitics"
                    >
                      <option value="Selecciona una de las categorias" disabled>
                        Selecciona una de las categorías
                      </option>
                      <option value="No Aplica">No Aplica</option>
                      <option value="Porcentual">Porcentual</option>
                      <option value="Fijo">Fijo</option>
                    </select>
                    <div>
                      {values.saving_fund_type === "Porcentual" ? (
                        <TextField
                          name="saving_fund_cap"
                          type="text"
                          className="inputMedioModalPolitics"
                          value={values.saving_fund_cap}
                          size="small"
                          fullWidth={true}
                          error={
                            touched.saving_fund_cap ||
                            (String(errors.saving_fund_cap) ===
                              "El valor no debe ser mayor a 13" &&
                              Boolean(errors.saving_fund_cap))
                          }
                          helperText={
                            String(errors.saving_fund_cap) ===
                              "El valor no debe ser mayor a 13" &&
                            "El valor no debe ser mayor a 13"
                          }
                          onChange={(e) => {
                            let res = upDateTotalSavingFound(e.target.value);
                            setFieldValue("saving_fund_cap", res);
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="start">
                                %
                              </InputAdornment>
                            ),
                          }}
                          disabled={isSavingFund}
                        />
                      ) : (
                        <TextField
                          name="saving_fund_cap"
                          type="text"
                          className="inputMedioModalPolitics"
                          value={values.saving_fund_cap}
                          size="small"
                          fullWidth={true}
                          onChange={(e) => {
                            if (values.saving_fund_type === "Fijo") {
                              handleChange(e);
                            }
                          }}
                          onBlur={() => {
                            if (values.saving_fund_type === "Fijo") {
                              let a = handleSetFormat(values.saving_fund_cap,"saving_fund_cap");
                              setFieldValue("saving_fund_cap", a);
                            }
                          }}
                          onKeyPress={(event: any) => {
                            if (!/^[0-9.]+$/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          error={
                            touched.saving_fund_cap &&
                            Boolean(errors.saving_fund_cap)
                          }
                          InputProps={{
                            startAdornment: values.saving_fund_type ===
                              "Fijo" && (
                              <InputAdornment position="start">
                                $
                              </InputAdornment>
                            ),
                          }}
                          disabled={isSavingFund}
                        />
                      )}
                    </div>
                    <div>
                      <select
                        onChange={handleChange}
                        name="saving_type"
                        className="selectModalPolitics"
                      >
                        <option
                          value="Selecciona una de las categorias"
                          disabled
                        >
                          Selecciona una de las categorías
                        </option>
                        <option value="Diario">Diario</option>
                        <option value="Quincenal">Quincenal</option>
                        <option value="Mensual">Mensual</option>
                      </select>
                    </div>
                  </div>
                  <Box mt={1}>
                    <Checkbox
                      checked={values.saving_fund ? true : false}
                      value={values.saving_fund}
                      onChange={(e) => {
                        handleChange(e);
                        if (e.target.checked) {
                          setFieldValue("saving_fund_cap", 13);
                          setFieldValue("saving_fund_type", "Porcentual");
                          setIsSavingFund(true);
                        } else {
                          setFieldValue("saving_fund_cap", "");
                          setFieldValue("saving_fund_type", "No Aplica");
                          setIsSavingFund(false);
                        }
                      }}
                      name="saving_fund"
                      color="primary"
                    />

                    <span className="textoModalPolitics">
                      Tope de fondo de ahorro
                    </span>
                  </Box>
                </Box>
              </Grid>

              <Grid xs item>
                <Box mb={1}>
                  <Box mb={1}>
                    <div style={{ display: "flex" }}>
                      <span className="subtituloModalPolitics">
                        Vales de restaurante
                      </span>
                      <div className="iconoAtencion">¡</div>
                    </div>
                  </Box>

                  <div style={{ display: "flex" }}>
                    <select
                      value={values.restaurant_value_type}
                      onChange={(e) => {
                        if (values.restaurant_value) {
                          e.preventDefault();
                        } else {
                          if (e.target.value === "No Aplica") {
                            setFieldValue("restaurant_value_cap", "");
                          }
                          handleChange(e);
                        }
                      }}
                      name="restaurant_value_type"
                      className="selectModalPolitics"
                    >
                      <option value="Selecciona una de las categorias" disabled>
                        Selecciona una de las categorías
                      </option>
                      <option value="No Aplica">No Aplica</option>
                      <option value="Porcentual">Porcentual</option>
                      <option value="Fijo">Fijo</option>
                    </select>
                    <div>
                      {values.restaurant_value_type === "Porcentual" ? (
                        <TextField
                          name="restaurant_value_cap"
                          type="text"
                          className="inputMedioModalPolitics"
                          value={values.restaurant_value_cap}
                          size="small"
                          fullWidth={true}
                          error={
                            touched.restaurant_value_cap ||
                            (String(errors.restaurant_value_cap) ===
                              "El valor no debe ser mayor a 100" &&
                              Boolean(errors.restaurant_value_cap))
                          }
                          helperText={
                            String(errors.restaurant_value_cap) ===
                              "El valor no debe ser mayor a 100" &&
                            "El valor no debe ser mayor a 100"
                          }
                          onChange={(e) => {
                            let res = upDateTotal(e.target.value);
                            setFieldValue("restaurant_value_cap", res);
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="start">
                                %
                              </InputAdornment>
                            ),
                          }}
                          disabled={isRestaurantValue}
                        />
                      ) : (
                        <TextField
                          name="restaurant_value_cap"
                          type="text"
                          className="inputMedioModalPolitics"
                          value={values.restaurant_value_cap}
                          size="small"
                          fullWidth={true}
                          onChange={(e) => {
                            if (values.restaurant_value_type === "Fijo") {
                              handleChange(e);
                            }
                          }}
                          onBlur={() => {
                            if (values.restaurant_value_type === "Fijo") {
                              let a = handleSetFormat(
                                values.restaurant_value_cap,
                                "restaurant_value_cap"
                              );
                              setFieldValue("restaurant_value_cap", a);
                            }
                          }}
                          onKeyPress={(event: any) => {
                            if (!/^[0-9.]+$/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          error={
                            touched.restaurant_value_cap &&
                            Boolean(errors.restaurant_value_cap)
                          }
                          InputProps={{
                            startAdornment: values.restaurant_value_type ===
                              "Fijo" && (
                              <InputAdornment position="start">
                                $
                              </InputAdornment>
                            ),
                          }}
                          disabled={isRestaurantValue}
                        />
                      )}
                    </div>
                    <div>
                      <select
                        onChange={(e) => {
                          handleChange(e);
                          if (restaurant_value) {
                            handleSetLimitRestaurantValue(e.target.value).then(
                              (res: any) => {
                                setFieldValue("restaurant_value_cap", res);
                                setFieldValue("restaurant_value_type", "Fijo");
                              }
                            );
                          }
                        }}
                        name="restaurant_type"
                        className="selectModalPolitics"
                      >
                        <option
                          value="Selecciona una de las categorias"
                          disabled
                        >
                          Selecciona una de las categorías
                        </option>
                        <option value="Diario">Diario</option>
                        <option value="Quincenal">Quincenal</option>
                        <option value="Mensual">Mensual</option>
                      </select>
                    </div>
                  </div>
                  <Box mt={1}>
                    <Checkbox
                      checked={values.restaurant_value ? true : false}
                      value={values.restaurant_value}
                      onChange={(e) => {
                        handleChange(e);
                        if (e.target.checked) {
                          handleSetLimitRestaurantValue().then((res: any) => {
                            setFieldValue("restaurant_value_cap", res);
                            setFieldValue("restaurant_value_type", "Fijo");
                          });
                          setIsRestaurantValue(true);
                          setRestaurant_value(true);
                        } else {
                          setFieldValue("restaurant_value_cap", "");
                          setFieldValue("restaurant_value_type", "No Aplica");
                          setRestaurant_value(false);
                          setIsRestaurantValue(false);
                        }
                      }}
                      name="restaurant_value"
                      color="primary"
                    />

                    <span className="textoModalPolitics">
                      Tope de vales de restaurante
                    </span>
                  </Box>
                </Box>
              </Grid>

              <Grid xs item>
                <Box mb={1}>
                  <Box mb={1}>
                    <div style={{ display: "flex" }}>
                      <span className="subtituloModalPolitics">
                        Prima vacacional
                      </span>
                      <div className="iconoAtencion">¡</div>
                    </div>
                  </Box>

                  <div style={{ display: "flex" }}>
                    <select
                      value={values.vacation_bonus_type}
                      onChange={(e) => {
                        if (values.vacation_bonus) {
                          e.preventDefault();
                        } else {
                          if (e.target.value === "No Aplica") {
                            setFieldValue("vacation_bonus_cap", "");
                          }
                          handleChange(e);
                        }
                      }}
                      name="vacation_bonus_type"
                      className="selectModalPolitics"
                    >
                      <option value="Selecciona una de las categorias" disabled>
                        Selecciona una de las categorías
                      </option>
                      <option value="No Aplica">No Aplica</option>
                      <option value="Porcentual">Porcentual</option>
                    </select>
                    <div>
                      {values.vacation_bonus_type === "Porcentual" ? (
                        <TextField
                          name="vacation_bonus_cap"
                          type="text"
                          className="inputMedioModalPolitics"
                          value={values.vacation_bonus_cap}
                          size="small"
                          fullWidth={true}
                          error={
                            touched.vacation_bonus_cap ||
                            (String(errors.vacation_bonus_cap) ===
                              "El valor no debe ser mayor a 100" &&
                              Boolean(errors.vacation_bonus_cap))
                          }
                          helperText={
                            String(errors.vacation_bonus_cap) ===
                              "El valor no debe ser mayor a 100" &&
                            "El valor no debe ser mayor a 100"
                          }
                          onChange={(e) => {
                            let res = upDateTotal(e.target.value);
                            setFieldValue("vacation_bonus_cap", res);
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="start">
                                %
                              </InputAdornment>
                            ),
                          }}
                          disabled={isBonus}
                        />
                      ) : (
                        <TextField
                          name="vacation_bonus_cap"
                          type="text"
                          className="inputMedioModalPolitics"
                          value={values.vacation_bonus_cap}
                          size="small"
                          fullWidth={true}
                          onChange={(e) => {
                            if (values.vacation_bonus_type === "Fijo") {
                              handleChange(e);
                            }
                          }}
                          onBlur={() => {
                            if (values.vacation_bonus_type === "Fijo") {
                              let a = handleSetFormat(
                                values.vacation_bonus_cap,
                                "vacation_bonus_cap"
                              );
                              setFieldValue("vacation_bonus_cap", a);
                            }
                          }}
                          onKeyPress={(event: any) => {
                            if (!/^[0-9.]+$/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          error={
                            touched.vacation_bonus_cap &&
                            Boolean(errors.vacation_bonus_cap)
                          }
                          InputProps={{
                            startAdornment: values.vacation_bonus_type ===
                              "Fijo" && (
                              <InputAdornment position="start">
                                $
                              </InputAdornment>
                            ),
                          }}
                          disabled={isBonus}
                        />
                      )}
                    </div>
                  </div>
                  <Box mt={1}>
                    <Checkbox
                      checked={values.vacation_bonus ? true : false}
                      value={values.vacation_bonus}
                      onChange={(e) => {
                        handleChange(e);
                        if (e.target.checked) {
                          setFieldValue("vacation_bonus_cap", 25);
                          setFieldValue("vacation_bonus_type", "Porcentual");

                          setIsBonus(true);
                        } else {
                          setFieldValue("vacation_bonus_cap", "");
                          setFieldValue("vacation_bonus_type", "No Aplica");
                          setIsBonus(false);
                        }
                      }}
                      name="vacation_bonus"
                      color="primary"
                    />

                    <span className="textoModalPolitics">
                      Minima prima vacacional
                    </span>
                  </Box>
                </Box>
              </Grid>

              <Grid xs item>
                <Box mb={2}>
                  <Box mb={1}>
                    <div style={{ display: "flex" }}>
                      <span className="subtituloModalPolitics">
                        Descuento para cálculos
                      </span>
                      <div className="iconoAtencion">¡</div>
                    </div>
                  </Box>

                  <Box>
                    <Checkbox
                      checked={values.absence_discount ? true : false}
                      value={values.absence_discount}
                      onChange={handleChange}
                      name="absence_discount"
                      color="primary"
                    />

                    <span className="textoModalPolitics">
                      Se descuentan faltas
                    </span>
                  </Box>

                  <Box>
                    <Checkbox
                      checked={values.disability_discount ? true : false}
                      value={values.disability_discount}
                      onChange={handleChange}
                      name="disability_discount"
                      color="primary"
                    />

                    <span className="textoModalPolitics">
                      Se descuentan incapacidades
                    </span>
                  </Box>
                </Box>
              </Grid>
              <div className="contenedorBotonesPoliticsModal">
                <button onClick={handleClose} className="botonbotonCancelar">
                  Cancelar
                </button>
                <button type="submit" className="botonbotonGuardar botonDoble" disabled={isSubmitting}>
                  <div className="contenedorIconoBotonModal">
                    <SaveIcon fontSize="small" />
                  </div>
                  <div>Guardar</div>
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

const validationSchema = Yup.object().shape({
  policy_name: Yup.string().required("El campo es requerido")
              .max(40,"Este texto sobrepasa los 40 caracteres"),
           
  economic_days: Yup.string().required("El campo es requerido"),
  DiasAguinaldo: Yup.string().required("El campo es requerido"),   
  idPayrollGroup: Yup.string().required("El campo es requerido"),
  //  if pantry_value_type is porcentual then pantry_value_cap is required and not must be major than 100

  //if pantry_value_type is porcentual then pantry_value_cap is required and not must be major than 100
  pantry_value_cap: Yup.string().when("pantry_value_type", {
    is: "No Aplica",
    then: Yup.string().notRequired(),
    otherwise: Yup.string().required("El campo es requerido"),
  }),
  saving_fund_cap: Yup.string().when("saving_fund_type", {
    is: "No Aplica",
    then: Yup.string().notRequired(),
    otherwise: Yup.string().required("El campo es requerido"),
  }),
  restaurant_value_cap: Yup.string().when("restaurant_value_type", {
    is: "No Aplica",
    then: Yup.string().notRequired(),
    otherwise: Yup.string().required("El campo es requerido"),
  }),
  vacation_bonus_cap: Yup.string().when("vacation_bonus_type", {
    is: "No Aplica",
    then: Yup.string().notRequired(),
    otherwise: Yup.string().required("El campo es requerido"),
  }),
});

export default CreaPolitica;
