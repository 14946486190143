import axios from "axios";
import { ErrorAlert } from "../alerts/errorAlert";
import { LoadingAlertGiff } from "../alerts/loadingAlerts";
import { serverCFDI } from '../global/server';
const serverError = "Ocurrió un error con el servidor.";

export const postTimbrarNomina = async (tokenTimbre:any) => {
    try {
        LoadingAlertGiff({ title: "Timbrado en proceso. No actualices ni cierres la pantalla hasta que el proceso haya terminado." });
        const result = await axios.post(`${serverCFDI}/nomina/nomina/${tokenTimbre}`)

        return result?.data

    } catch (err) {
        console.log(err);
        ErrorAlert({ text: serverError });
        return []
    }
};


export const postCancelarTimbrarNomina = async (tokenTimbre:any) => {
    try {
        
        LoadingAlertGiff({ title: "Cancelando timbre. No actualices ni cierres la pantalla hasta que el proceso haya terminado." });
        const result = await axios.post(`${serverCFDI}/nomina/cancelaNomina/${tokenTimbre}`)
        return result?.data

    } catch (err) {
        console.log(err);
        ErrorAlert({ text: serverError });
        return []
    }
};

export const postCancelarTimbrarNominaEventual = async (tokenTimbre:any) => {
    try {

        LoadingAlertGiff({ title: "Cancelando timbre. No actualices ni cierres la pantalla hasta que el proceso haya terminado." });
        const result = await axios.post(`${serverCFDI}/nomina/cancelaNominaEventual/${tokenTimbre}`)
        return result?.data

    } catch (err) {
        console.log(err);
        ErrorAlert({ text: serverError });
        return []
    }
};

export const postTimbrarNominaFiniquito = async (tokenTimbre:any) => {
    try {
        LoadingAlertGiff({ title: "Timbrado en proceso. No actualices ni cierres la pantalla hasta que el proceso haya terminado." });
        const result = await axios.post(`${serverCFDI}/nomina/settlement/${tokenTimbre}`)
        return result?.data

    } catch (err) {
        console.log(err);
        ErrorAlert({ text: serverError });
        return []
    }
};

export const postTimbrarNominaEventual = async (tokenTimbre:any) => {
    try {
        LoadingAlertGiff({ title: "Timbrado en proceso. No actualices ni cierres la pantalla hasta que el proceso haya terminado." });
        const result = await axios.post(`${serverCFDI}/nomina/getEventual/${tokenTimbre}`)
        return result?.data

    } catch (err) {
        console.log(err);
        ErrorAlert({ text: serverError });
        return []
    }
};

export const postCancelarTimbrarNominaFiniquito = async (tokenTimbre:any) => {
    try {

        LoadingAlertGiff({ title: "Cancelando timbre. No actualices ni cierres la pantalla hasta que el proceso haya terminado." });
        const result = await axios.post(`${serverCFDI}/nomina/cancelaNominaSettlement/${tokenTimbre}`)
        return result?.data

    } catch (err) {
        console.log(err);
        ErrorAlert({ text: serverError });
        return []
    }
};