import { Grid } from '@material-ui/core';
import Card from '@mui/material/Card';
import Paper from '@mui/material/Paper';
import CardContent from '@mui/material/CardContent';
import { MenuCalendar } from './MenuListCalendar';
import styles from './Calendars.module.css'
import { useLazyQuery } from "@apollo/client";
import { GET_PAYROLL_BY_CALENDAR } from "../../Querys/querys";
import { useHistory } from "react-router-dom";
import CalendarName from './CalendarName'
import { useEffect } from "react";
import CheckPermission from '../../helpers/Administradores/Permissions';

export const CalendarInfo = ({name, enterprise, _id, eventNonworkingday}:any) => {
    let showDelete = 1;
    const history = useHistory();
    const [getIncident, { data }] = useLazyQuery(GET_PAYROLL_BY_CALENDAR, {variables:{id:parseInt(_id)}});
    const allCalendar = data?.GET_PAYROLL_BY_CALENDAR;
    const incidentCount = allCalendar?.length;

    useEffect(() => {
        getIncident()
    }, [getIncident]);
    
    if(eventNonworkingday !== undefined){
        if(incidentCount > 0 || eventNonworkingday[0]?.accumulatedNotStarted > 0){
            showDelete = 0;
        }
    }
    
    return (
        <Grid item xs={6} sm={4} >
            <Paper elevation= {4}>
                <Card>
                
                    <CardContent>
                        <div className={styles.conteinerCalendar}
                        style={{
                            height: "212px"
                        }}
                        >
                            <div className={styles.conteinerCardNewCalendar}>
                                <Grid container>

                                    <div className={styles.conteinerCalendarName}
                                    title={name}
                                    >
                                        {CheckPermission("Calendario.Ver") && (
                                        <span 
                                        onClick = {() => history.push(`/Schedule2/${_id}`)} 
                                        className={styles.tituloNombreCard}>
                                           <CalendarName name={name} />
                                        </span>
                                        )}
                                    </div>
                                        

                                </Grid>
                                <Grid container>                            
                                    <Grid xs={12} className={styles.centerContainer}>
                                        <span className={styles.tituloRazon}>Razón social</span>
                                    </Grid>
                                </Grid>
                                <Grid container>                            
                                    <Grid xs = {12} className={styles.centerContainer}>
                                        <span className={styles.tituloEnterNewCalendar}
                                        style={{
                                            height:"25px"
                                        }}
                                        title={enterprise}
                                        >{enterprise}</span>
                                    </Grid>
                                </Grid>
                                <Grid container
                                    justify="center" alignItems="center"
                                    style={{marginLeft: "10x"}}
                                >                            
                                    <Grid xs={6} >
                                        <div className={styles.centerContainerCount}>
                                            <Grid container>
                                                <Grid xs={12} className={styles.centerContainer}>
                                                    <span className={styles.tituloEvent}>Incidencias activas</span>
                                                </Grid>
                                                <Grid xs={12} className={styles.centerContainer}>
                                                    <span className={styles.tituloEventNumber}>{incidentCount}</span>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </Grid>
                                    <div>
                                        
                                    </div>
                                    <Grid xs = {6} >
                                        <div className={styles.centerContainerCount}>
                                                <Grid container>
                                                    <Grid xs={12} className={styles.centerContainer}>
                                                        <span className={styles.tituloEvent}>Eventos registrados</span>
                                                    </Grid>
                                                    <Grid xs={12} className={styles.centerContainer}>
                                                        <span className={styles.tituloEventNumber}>{
                                                            eventNonworkingday?.map((e:any) =>{
                                                               
                                                                return e.accumulated
                                                            })
                                                        }</span>
                                                    </Grid>
                                                </Grid>
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                            <div className={ styles.conteinerMenuNewCalendar}>
                                    <MenuCalendar _id={_id} showDelete={showDelete} />
                            </div>
                        </div>
                    </CardContent>
                </Card>
            </Paper>
        </Grid>
    )
}
