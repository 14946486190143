import { useContext } from "react";
import EmpresaContext from "../../../context/Empresa/EmpresaContext";
import {
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
} from "@material-ui/core";
import Box from '@mui/material/Box';
import { clearEmpresaModal } from "../../../context/Empresa/Actions";
import { CREATE_SEDE, GET_ALL_SEDE, GET_SEDE } from "../../../Querys/querys";
import { useMutation } from "@apollo/client";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { SuccessfulAlert } from "../../../alerts/successAlerts";
import { Button, TextField } from "@material-ui/core";

const CrearSede2 = (props: any) => {
  const { state, dispatch } = useContext(EmpresaContext);
  const { isSaving, setIsSaving } = props;

  const [createNewProyect] = useMutation(CREATE_SEDE, {
    refetchQueries: [
      { query: GET_ALL_SEDE },
      { query: GET_SEDE, variables: { id: state._id } },
    ],
  });

  const initalValues = () => {
    return {
      nombre: "",
    };
  };

  const handleClose = () => {
    clearEmpresaModal({}, dispatch);
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={state.createSede}
      maxWidth={"md"}
    >
      <Formik
        initialValues={initalValues()}
        validationSchema={Yup.object(validationSchema())}
        onSubmit={(formData) => {
          createNewProyect({
            variables: {
              input: {
                sedeName: formData.nombre,
              },
            },
          }).then(() => {
            handleClose();
            SuccessfulAlert({
              title: "¡Exito!",
              text: "¡Registro agregado!",
            });
            setIsSaving(false);
          });
        }}
      >
        {({ values, errors, touched, handleChange, handleSubmit }) => (
          <Form>
            <h3 id="form-dialog-title" className="text-center">
              Agregar sede para la empresa
            </h3>
            <DialogContent dividers>
              <Box display="flex" flexDirection="column">
                <Box mb={3}>
                  <Grid container spacing={1}>
                    <Grid container item xs={12} spacing={3}>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          name="nombre"
                          label="Nombre"
                          variant="outlined"
                          value={values.nombre}
                          onChange={handleChange}
                          error={touched.nombre && Boolean(errors.nombre)}
                          helperText={touched.nombre && errors.nombre}
                          size="small"
                          onKeyPress={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </DialogContent>
            <DialogActions style={{ justifyContent: "center", display: "flex" }}>
              <Button autoFocus onClick={handleClose} className="buttonCancel">
                Cerrar
              </Button>
              {!isSaving ? (
                <Button
                  autoFocus
                  type="submit"
                  className="buttonSave"
                  onClick={() => {
                    setIsSaving(true);
                    handleSubmit();
                  }}
                >
                  Guardar
                </Button>
              ) : (
                <Button
                  autoFocus
                  className="buttonSave"
                  style={{
                    opacity: "0.5",
                  }}
                >
                  Guardar
                </Button>
              )}
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

const validationSchema = () => {
  return {
    nombre: Yup.string().required("El nombre es requerido"),
  };
};

export default CrearSede2;
