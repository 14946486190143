import CentralPagosTable from "./CentalPagosTable";

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

const CentralPagosTab = (props: TabPanelProps) =>{
  const { children, value, index, ...other } = props;
  return(
    <CentralPagosTable/>
  )
}

export default CentralPagosTab