import { useContext } from "react";

import ReportContext, { Types } from "../../../context/ReportsContext/ReportContext";
import Grap from "../../../assets/svg/ReportsBtn.svg";

const BtnCreateReport = () => {
  const { dispatch } = useContext(ReportContext);

  const openCreateReportModal = () => {
    dispatch({ type: Types.CREATE_REPORTS })
  }

  return (
    <button
      id="addPrestacion"
      className="btnNuevaPrestacion"
      onClick={openCreateReportModal}
    >
      <div className="alineacionBoton">
        <img src={Grap} alt="Agregar Prestación" />
        <div className="nombrePrestacion">
          <b><span>Crear informe</span> </b>
        </div>
      </div>
    </button>
  )
}

export default BtnCreateReport
