import { useState, useEffect, useContext } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import moment from "moment";
import { useHistory, useParams } from 'react-router-dom';
import "../css/CardCampaigns.css"
import ObjectivesContext, { Types } from '../../../context/ObjectiveContext/ObjectiveContext';

interface CampaignFromLink {
  id: number
  nameCampaign: string
  startCampaign: string
  endCampaign: string
  step: string
  remandingDays: number
  objetivesComplte: number
  objetives: number
  campaignBond: string
  maxObjectives: number
  minObjectives: number
  isLeader: boolean
}

const CollaboratorCampaignCard = ({ campaign }: { campaign: CampaignFromLink }) => {
  const {
    id,
    nameCampaign,
    startCampaign,
    endCampaign,
    step,
    remandingDays,
    objetivesComplte,
    objetives,
    campaignBond,
    maxObjectives,
    minObjectives,
    isLeader,
  } = campaign
  const { campaign: campaignIdFromUrl, user, token } = useParams<{ campaign: string; user: string; token: string }>();
  const dateInitFormat = moment(startCampaign).utc().format('DD/MM/YYYY');
  const dateEndFormat = moment(endCampaign).utc().format('DD/MM/YYYY');
  const [stepValue, setStepValue] = useState("")
  const history = useHistory();
  const { dispatch } = useContext(ObjectivesContext);

  const stepText = (step: string) => {
    switch (step) {
      case "crear":
        return "Creación"
      case "definicion":
        return "Definición"
      case "evaluar":
        return "Evaluación"
      case "calcular":
        return "Calcular"
      case "revisar":
        return "Revisión"
      default:
        return step
    }
  }

  useEffect(() => {
    if (stepValue === "") {
      setStepValue(step)
    }
  }, [step, stepValue]);

  const goToObjectives = () => {
    dispatch({
      type: Types.SET_CAMPAIGN_INFO,
      payload: campaign
    })
    dispatch({
      type: Types.SET_ROL,
      payload: isLeader ? 'leader' : 'collaborator'
    })
    dispatch({
      type: Types.SET_CAMPAIGN_ID,
      payload: id
    })
    if (isLeader) {
      history.push(`/campaign/${campaignIdFromUrl}/user/${user}/token/${token}/campaignId/${id}/info`)
    } else {
      dispatch({
        type: Types.SET_COLLABORATOR_ID,
        payload: parseInt(user, 10)
      });
      history.push(`/campaign/${campaignIdFromUrl}/user/${user}/token/${token}/campaignId/${id}/userId/${user}`)
    }
  }

  return (
    <>
      <Card className='cardCampaign'>
        <CardContent className="contentCampaign">
          <div>
            <div style={{ textAlign: 'center' }}><span className='infoCampaignCenter'>{stepText(step)}</span></div>
            <div style={{ textAlign: 'center' }}><span className='infoBonoCenter'>{campaignBond === "true" ? "Con bono" : "Sin bono"}</span></div>
          </div>
          <div className='infoCard'>
            <div><span className='titleCampaign'>{nameCampaign}</span></div>
            <div><span className='periodoCampaign'>{`Periodo: ${dateInitFormat} - ${dateEndFormat}`} </span></div>
          </div>
          <div className='definirCard' onClick={goToObjectives} style={{ cursor: 'pointer', marginTop: 0 }}>
            <div className='definirContain'>
              <div><span className='remandingDays'>{remandingDays > 0 ? remandingDays : 0} días restantes</span></div>
              {/* {(step === 'crear' || step === 'definicion') && (
                <div><span className='obtevosTxt'>{`${objetives} de ${maxObjectives}`}</span></div>
              )}
              {(step !== 'crear' && step !== 'definicion') && (
                <div><span className='obtevosTxt'>{`${objetivesComplte} de ${objetives}`}</span></div>
              )} */}
              <div><span className='obtevosTxt'>{`${objetivesComplte} de ${objetives}`}</span></div>
              <div><span className='msjDefinir'>Objetivos</span> </div>
            </div>
          </div>
        </CardContent>
      </Card>
    </>
  )
}

export default CollaboratorCampaignCard

