import List from '../List';
import HiringDataRegister from '../Components/HiringDataRegister';
import InputDataRegister from '../Components/InputDataRegister';
import PersonalData from '../Components/PersonalData';

const Information = () => {
    let nuevoArrayEtiquetas =[]
    let nuevoArrayTablas = []
    
    nuevoArrayEtiquetas.push('Información personal')
    nuevoArrayTablas.push(PersonalData)
    nuevoArrayEtiquetas.push('Datos de ingreso')
    nuevoArrayTablas.push(InputDataRegister)
    nuevoArrayEtiquetas.push('Datos de contratación')
    nuevoArrayTablas.push(HiringDataRegister)


    return (
        <List
            title="INFORMACIÓN DE ALTA"
            labels={nuevoArrayEtiquetas}
            components={nuevoArrayTablas}
            numTab={2}
            progress={0}
        />
    )
}

export default Information;