import { formatterMxn } from "../../helpers/formatoMoneda";
import { Collaborator } from "../../interfaces/TabCollaborator.interfaces";

const MoneyField = ({ grossSalary }: Collaborator) => {
  return (
    <div>
      <label>{grossSalary && formatterMxn(grossSalary)}</label>
    </div>
  );
};

export default MoneyField;
