import { useContext, useState} from "react"
import PayrollProcessContext from "../../../../context/PayrollProcess/PayrollProcessContext";
import {Dialog} from "@material-ui/core"
import {
    closeDeleteIncident
} from "../../../../context/PayrollProcess/Actions";
import styles from './DeleteIncident.module.css'
import { 
  DELETE_INCIDENT, 
  GET_PAYROLLCOLLABORATOR, 
  GET_INCIDENT_PAYROLL, 
  GET_COLLABORATORS_DISPERSION,
  GET_PAYROLLCOLLABORATOR_EXCELL_CALCULO
} from "../../../../Querys/querys";
import { useMutation } from "@apollo/client"
import { SuccessfulAlert } from "../../../../alerts/successAlerts"
import { AdminContext } from "../../../../context/AdminContext/AdminContext";

const DeleteIncident = () =>{
  const {state, dispatch} = useContext(PayrollProcessContext)
  const {adminState} = useContext(AdminContext)
  const [activo, setactivo] = useState(false)
  const handleChange = (e: any) =>{ 
      if(e.target.value === 'ELIMINAR'){
          setactivo(true)
      }else{
          setactivo(false)
      }
  }

  const [deleteProject] = useMutation(DELETE_INCIDENT,{
    refetchQueries:[{ 
                        query: GET_INCIDENT_PAYROLL
                        , variables: {
                                        idPayroll: state.idPayroll
                                    }
                    },
                    {
                        query: GET_PAYROLLCOLLABORATOR,
                        variables: { getPayrollcollaboratorId: state.idPayroll },
                    },
                    { 
                      query: GET_COLLABORATORS_DISPERSION
                      , variables: {
                                    getCollaboratorsDispersionId: state.idPayroll
                                  }
                    },
                    {
                      query:GET_PAYROLLCOLLABORATOR_EXCELL_CALCULO,
                      variables: { getPayrollcollaboratorExcellCalculoId: String(state.idPayroll) }

                    }
                ]
  })
  
  const handleClose = () => {
    closeDeleteIncident({}, dispatch)
  } 

  const handleDelete = () =>{
    deleteProject({
      variables: {
        id: state.idDeleteIncident,
        input: {
            userDelete: adminState?.Usuario,
            idPayroll: state.idPayroll
        }
      }
    })
    SuccessfulAlert({text:'Registro Actualizado'})
    handleClose() 
  }

  return(
    <Dialog open={state.showDeleteIncident} aria-labelledby="form-dialog-title" onClose={handleClose} maxWidth={"md"}>
        <div className={styles.em_container}>
          <div className={styles.contenedorCerrarModal}>
            <div 
              className={styles.cerrarModal}
              onClick={()=> handleClose()}>
            </div>
          </div>
          <div className={styles.contenedorPrincipal}>
            <div className={styles.iconoEliminar}>
            </div>
            <div className={styles.em_titulo}>
              ¿Est&aacute;s seguro de que quieres eliminar el registro?
            </div>
            <div className={styles.em_mensajePrtincipal}>
                <span>Una vez eliminado no podr&aacute;s recuperar la informaci&oacute;n</span>
                <br />
                <span className={styles.em_textoPrincipal}>Escribe ELIMINAR para confirmar</span>
            </div>
            <div>
              <input 
                className={styles.input}
                type="text"
                onChange={(e) => handleChange(e)}
              />
            </div>
          </div>
        </div>
        <div className={styles.em_contenedorBotones}>
              <button 
                className={styles.em_botonCancelar}
                onClick={()=> handleClose()}
              >
                Cancelar
              </button>
              {(activo === true ? 
                <button 
                  className={styles.em_botonConfirmarActivo}
                  onClick={() => handleDelete()}
                  >
                  <div className={styles.em_iconoConfirmarEliminar}></div>
                    <div>
                      Confirmar
                    </div>
                </button>
              :
                <button className={styles.em_botonConfirmarInactivo}>
                  <div className={styles.em_iconoConfirmarEliminar}></div>
                    <div>
                      Confirmar
                    </div>
                </button>
              )}

            </div>
    </Dialog>
  )
}

export default DeleteIncident