import { useContext, useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  Grid,
  TextField,
} from "@material-ui/core";
import MuiDialogActions from "@material-ui/core/DialogActions";
import CollaboratorContext, {
  Types,
} from "../../../context/CollaboratorContext/CollaboratorContext";
import { ErrorAlert } from "../../../alerts/errorAlert";
import { WarningAlert } from "../../../alerts/WarningAlert";
import {
  GET_ALL_USERS_DECLINADOS,
  GET_ALL_USERS_NUEVOINGRESO,
  DECLINE_USERS
} from "../../../Querys/querys";
import { useMutation } from "@apollo/client";
const DeclineModal = () => {
  const { state, dispatch } = useContext(CollaboratorContext);
  const [comment, setComment] = useState<string>("");

  const [declineUsers, {data:loadingDeclined}] = useMutation(DECLINE_USERS, {
    refetchQueries: [
      { query: GET_ALL_USERS_NUEVOINGRESO },
      { query: GET_ALL_USERS_DECLINADOS },
    ],
  });

  const rejectData = async (): Promise<void> => {
    let result = await WarningAlert({
      title: "¡Atención!",
      text: "¿Estás seguro de declinar a este nuevo ingreso?",
      showDenyButton: true,
      confirmButtonText: "Declinar",
    });
    if (result.isConfirmed) {
      if (comment) {
        declineUsers({
          variables: {
            declineUsersId: state._id,
            input: {
              comment: comment,
            }
          },
        });

      } else ErrorAlert({ text: "Ingrese su comentario" });
    }
    setComment("");
  };

useEffect(()=>{
  if(loadingDeclined){
    dispatch({ type: Types.CANCEL_DECLINE_MODAL });
  }
},[loadingDeclined])

  return (
    <Dialog
      aria-labelledby="customized-dialog-title"
      open={state.declineModal}
      fullWidth={true}
    >
      <h2 id="form-dialog-title" className="text-center">
        Declinar Nuevo Ingreso
      </h2>
      <DialogContent>
        <Grid direction="row" container justify="center" alignItems="center">
          <Grid item xs>
            <TextField
              variant="outlined"
              type="text"
              InputLabelProps={{ shrink: false }}
              name="Comentario"
              size="small"
              fullWidth={true}
              multiline
              rows={4}
              placeholder="Detalla porque se quiere declinar al nuevo ingreso"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
            />
          </Grid>
        </Grid>
      </DialogContent>

      <MuiDialogActions>
        <Button
          className="buttonCancel"
          onClick={() => dispatch({ type: Types.CANCEL_DECLINE_MODAL })}
        >
          Cerrar
        </Button>
        <Button className="buttonSave" onClick={rejectData}>
          Declinar
        </Button>
      </MuiDialogActions>
    </Dialog>
  );
};

export default DeclineModal;
