import { useQuery, useLazyQuery } from "@apollo/client";
import { useEffect, useState, useCallback } from "react";
import { PieChart, Pie, Cell } from "recharts";
import { GET_COUNT_COMMENT } from "../../Querys/querys";
import style from "../../screens/BitsScreen.module.css";

const PieChartData = () => {
  const [getDatos, {data:resultMood, startPolling, stopPolling}] = useLazyQuery(GET_COUNT_COMMENT,{
    fetchPolicy:'no-cache'
  })

  const data = resultMood?.GET_COUNT_COMMENT;
  const COLORS = [
    {
      color: "#9C54E5",
      name: 'Aburrido'
    }, 
    {
      color: "#FB337B",
      name: 'Enfadado'
    }, 
    {
     color: "#367EEA",
     name: 'Motivado'
    }, 
    {
      color: "#FEC104",
      name: 'Presionado'
    }, 
    {
      color: "#42DBBE",
      name: 'Productivo'
    }];
  const [total, setTotal] = useState(0);

  const actualizaTotal = useCallback(( ) =>{
    let arrayTotales: any[] = []
    arrayTotales= data?.map((lis:any) =>(lis.value))
    const suma = (acc:any, curr:any) => acc +curr
    if(arrayTotales!== undefined ){
      if(arrayTotales.length > 0){
        setTotal(arrayTotales?.reduce(suma))
      }    
    }
  },[data]);

  useEffect(() => {
    actualizaTotal();
      startPolling(2000)
    return () =>{
        actualizaTotal()
        stopPolling()
    }
  }, [actualizaTotal]);


  const getColor = (entry:any) => {
    const selectedColor = COLORS.filter((color:any)=>{
      return color.name === entry.name;
    });
    return selectedColor[0] ? selectedColor[0].color : ''
  }


  return (
    <>
      <div className={style.divPieChartPrincipal}>
        <div className={style.divPieChart}>
          <span className={style.textoTotal}>{total}</span>
          <br></br>
          <span className={style.textognral}>{ total > 1 || total === 0 ? 'Respuestas' : 'Respuesta'}</span>
        </div>

        <PieChart width={200} height={200}>
          <Pie data={data} innerRadius={60} outerRadius={78} dataKey="value">
            {data?.map((entry: any, index: number) => (
              <Cell
                stroke="none"
                key={`cell-${index}`}
                fill={ getColor(entry) }
              />
            ))}
          </Pie>
        </PieChart>
      </div>
    </>
  );
};

export default PieChartData;
