import { Fragment, useContext, useEffect, useState } from "react";
import { Grid, Button, Tooltip } from "@material-ui/core";
import CollaboratorContext from "../../../../context/CollaboratorContext/CollaboratorContext";
import SaveIcon from "@material-ui/icons/Save";
import EyeIcon from "../../../../assets/svg/eye.svg";
import RemoveFile from "../../../../assets/svg/remove_file.svg";
import DownloadIcon from "../../../../assets/svg/download_files_arrow.svg";
import { WarningAlert } from "../../../../alerts/WarningAlert";
import { server } from "../../../../global/server";
import { useMutation, useQuery } from "@apollo/client";
import { GET_USERS_BY_ID, UPDATE_USERS } from "../../../../Querys/querys";
import FileOwn from "../Fields/File_Helper_Own_Register";
import { withStyles, Theme } from "@material-ui/core/styles";
import DataSavedModal from "../../Information/Colaboradores/Modals/DataSavedModal";
import { postFisnishMeilCandidate } from "../../../../services/candidateService";
import axios from "axios";

const HtmlTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    maxWidth: 1000,
    fontSize: theme.typography.pxToRem(14),
  },
}))(Tooltip);

const OfertaLaboralColaboradores = () => {
  const { state } = useContext(CollaboratorContext);
  const [archives, setArchives] = useState([]);
  const [CartaOferta, setCartaOferta] = useState<any>({});
  const [isOpenDataSaved, setIsOpenDataSaved] = useState<boolean>(false);

  const handleCloseDataSaved = () => setIsOpenDataSaved(false);

  const [updateColaboradores] = useMutation(UPDATE_USERS, {
    refetchQueries: [
      {
        query: GET_USERS_BY_ID,
        variables: { getUsersId: state.collaborator.id },
      },
    ],
  });

  const {data:resultCollaborator} = useQuery(GET_USERS_BY_ID, {
    variables: { getUsersId: state.collaborator.id },
  }); 

  const user = resultCollaborator?.GET_USERS[0];

  useEffect(() => {
    if (state.collaborator?.archive) {
      //get archive where name is CartaOferta
      const carta = state.collaborator.archive.find(
        (archive: any) => archive.name === "DocumentosParaFirma"
      );
      if (carta) {
        setCartaOferta(carta);
      }
      setArchives(state.collaborator.archive);
    }
  }, [state.collaborator]);

  const handleSendAndFinsh = async () => {
    await updateColaboradores({
      variables: {
        updateUsersId: state.collaborator?.id,
        input: {
          done: true,
        },
      },
    }).then(() => {
      postFisnishMeilCandidate(user?.email);
      setIsOpenDataSaved(true);
    });

  };

  const handleWarningAlert = async () => {
    await WarningAlert({ text: "Aún no se ha subido el documento para firma." });
  };

  const exportarPDF = async (id: any) => {

    const PDF: any = await axios.get(`${server}/report/pdfCollaborators/${id}`)
    const link = document.createElement("a");
    link.href = PDF?.data?.urlPDF;
    link.setAttribute("download", "downloadtesting.PDF");
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  return (
    <>
      <DataSavedModal
        isOpen={isOpenDataSaved}
        handleClose={handleCloseDataSaved}
      />
      <div className="novalidate__border" style={{ width: "fit-content" }}>
        <Grid direction="row" container>
          <Grid xs={12} item>
            {/*AVISO DE RETENCIÓN  */}
            {archives?.map(
              (archive: any, index: number) =>
                archive?.name === "CartaOfertaFirmada" && (
                  <div className="flex-container">
                    <div className="flex-child-2">
                      {/* button with image like text */}

                      <a
                        className="view-document"
                        target="_blank"
                        rel="noreferrer"
                        href={CartaOferta?.URL}
                      >
                        <img
                          src={DownloadIcon}
                          style={{ marginRight: "15px" }}
                          className="cursor"
                          alt="DownloadFile"
                        />
                      </a>

                      <>
                        <FileOwn
                          name="CartaOfertaFirmada"
                          accept=".pdf"
                          parametrofrom="collaborator"
                          idUsr={state.collaborator?.id}
                          className="image-file"
                          setArchives={setArchives}
                          archives={archives}
                          image={RemoveFile}
                          remove_file={true}
                          section="3"
                        />
                      </>
                    </div>
                    <div className="flex-child">
                      <label style={{ marginRight: "1%" }}>
                        Documentos para firma
                      </label>
                      <HtmlTooltip
                        title={
                          <Fragment>
                            Considera subir en un sólo pdf todos los documentos
                          </Fragment>
                        }
                      >
                        <img
                          src="/assets/icons/PreguntaAzul.png"
                          alt="Question"
                          height="15"
                        />
                      </HtmlTooltip>
                      <span className="span-file">(PDF)</span>
                      <span className="span-file">
                        <a
                          className="view-document"
                          target="_blank"
                          rel="noreferrer"
                          href={archive.URL}
                        >
                          <img
                            style={{ height: "10px", marginTop: "5px" }}
                            src={EyeIcon}
                            alt=""
                          />
                          Ver documento
                        </a>
                      </span>
                    </div>
                  </div>
                )
            )}

            {!state.collaborator?.CartaOfertaFirmada && (
              <div className="flex-container">
                <div className="flex-child-2">
                  {state.collaborator.CartaOferta ? (
                    <a
                      className="view-document"
                      target="_blank"
                      rel="noreferrer"
                      href={CartaOferta?.URL}
                    >
                      <img
                        src={DownloadIcon}
                        style={{ marginRight: "15px" }}
                        className="cursor"
                        alt="DownloadFile"
                      />
                    </a>
                  ) : (
                    <Button className="view-document" onClick={handleWarningAlert}>
                      <img
                        src={DownloadIcon}
                        style={{ marginRight: "15px" }}
                        className="cursor"
                        alt="DownloadFile"
                      />
                    </Button>
                  )}

                  <>
                    <FileOwn
                      name="CartaOfertaFirmada"
                      accept=".pdf"
                      parametrofrom="collaborator"
                      idUsr={state.collaborator?.id}
                      className="image-file"
                      setArchives={setArchives}
                      archives={archives}
                      style={{ marginRight: "15px" }}
                      section="3"
                    />
                  </>
                </div>

                <div className="flex-child">
                  <label style={{ marginRight: "1%" }}>
                    Documentos para firma
                  </label>
                  <HtmlTooltip
                    title={
                      <Fragment>
                        Considera subir en un sólo pdf todos los documentos
                      </Fragment>
                    }
                  >
                    <img
                      src="/assets/icons/PreguntaAzul.png"
                      alt="Question"
                      height="15"
                    />
                  </HtmlTooltip>
                  <span className="span-file">(PDF)</span>
                </div>
              </div>
            )}
          </Grid>
        </Grid>
        <hr />
        <Grid direction="row" container>
          <Grid xs={12} item>
            {/*DECLARACION DE INFORMACION  */}
            {archives?.map(
              (archive: any, index: number) =>
                archive?.name === "AGREEMENT_PDF" && (
                  <div className="flex-container">
                    <div className="flex-child-2">
                      <a
                        className="view-document"
                        target="_blank"
                        rel="noreferrer"
                        //href={`${server}/report/pdfCollaborators/${state.collaborator.id}`}
                        onClick={() => {
                          exportarPDF(state.collaborator.id);
                        }}
                      >
                        <img
                          src={DownloadIcon}
                          style={{ marginRight: "15px" }}
                          className="cursor"
                          alt="DownloadFile"
                        />
                      </a>

                      <FileOwn
                        name="AGREEMENT_PDF"
                        accept=".pdf"
                        parametrofrom="collaborator"
                        idUsr={state.collaborator?.id}
                        className="image-file"
                        setArchives={setArchives}
                        archives={archives}
                        image={RemoveFile}
                        remove_file={true}
                        section="3"
                      />
                    </div>
                    <div className="flex-child">
                      <label>Declaración de Información</label>
                      <span className="span-file">(PDF)</span>
                      <span className="span-file">
                        <a
                          className="view-document"
                          target="_blank"
                          rel="noreferrer"
                          href={archive.URL}
                        >
                          <img
                            style={{ height: "10px", marginTop: "5px" }}
                            src={EyeIcon}
                            alt=""
                          />
                          Ver documento
                        </a>
                      </span>
                    </div>
                  </div>
                )
            )}

            {!state.collaborator?.AGREEMENT_PDF && (
              <div className="flex-container">
                <div className="flex-child-2">
                  <a
                    className="view-document"
                    target="_blank"
                    rel="noreferrer"
                    //href={`${server}/report/pdfCollaborators/${state.collaborator.id}`}
                    onClick={() => {
                      exportarPDF(state.collaborator.id);
                    }}
                  >
                    <img
                      src={DownloadIcon}
                      style={{ marginRight: "15px" }}
                      className="cursor"
                      alt="DownloadFile"
                    />
                  </a>

                  <FileOwn
                    name="AGREEMENT_PDF"
                    accept=".pdf"
                    parametrofrom="collaborator"
                    idUsr={state.collaborator?.id}
                    className="image-file"
                    setArchives={setArchives}
                    archives={archives}
                    style={{ marginRight: "15px" }}
                    section="3"
                  />
                </div>
                <div className="flex-child">
                  <label>Declaración de Información</label>
                  <span className="span-file">(PDF)</span>
                </div>
              </div>
            )}
          </Grid>
        </Grid>
      </div>
      <div>
        {state.collaborator?.register_process[0].completed &&
          state.collaborator?.register_process[1].completed &&
          state.collaborator?.register_process[2].completed &&
          state.collaborator?.register_process[3].completed &&
          state.collaborator?.register_process[4].completed && (
            <Grid
              direction="row"
              container
              justify="flex-end"
              alignItems="center"
              style={{ marginTop: "20px" }}
            >
              <Button onClick={handleSendAndFinsh} className="buttonSave saveCollaborator">
                <SaveIcon />
                &nbsp; Enviar y terminar el registro
              </Button>
            </Grid>
          )}
      </div>
    </>
  );
};

export default OfertaLaboralColaboradores;
