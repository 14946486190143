/* eslint-disable array-callback-return */
import { useContext,useState,useEffect,useRef } from "react";
import { useQuery,useLazyQuery,useMutation } from "@apollo/client";
import PrestacionContext from '../../../context/PrestacionesContext/PrestacionContext';
import {createUserBenefitsModal, clearBenefitsModal } from "../../../context/PrestacionesContext/Actions";
import { 
       GET_ALL_TIPO_PRESTACIONES,
       GET_USERS_BY_CAMPUS_OR_PROJECT,   
       GET_ALL_TIPO_RESTRICCIONES_PRESTACIONES,
       CREATE_PRESTACION,
       GET_ALL_PRESTACIONES       
     } from "../../../Querys/prestaciones/querys";
import {GET_ALL_PROJECT, GET_ALL_SEDE } from "../../../Querys/querys";     
import {
     Dialog,
     DialogTitle,
     DialogContent,  
     TextField,
     FormControl,
     FormHelperText,
     InputLabel,
     Select ,
     Checkbox,
     ListItemText,
     MenuItem ,     
  } from '@material-ui/core';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Form } from "semantic-ui-react";
import UploadImage from "../../../assets/svg/uploadImage.svg";
import FlechaPrestacionDer from "../../../assets/svg/flechaPrestacionDer.svg";
import Minus from "../../../assets/svg/minus.svg";
import Plus from "../../../assets/svg/plus.svg";
import Add from "../../../assets/svg/addPrestacion.svg";
import AdminColaboradoresPrestacion from "./AdminColaboradoresPrestacion";
import { useFormik } from "formik";
import { postFileCloudPrestacion } from "../../../services/candidateService";
import GreenSwitchPrestacion from "./GreenSwitchPrestacion";
import * as Yup from "yup";
import { SuccessfulAlert } from "../../../alerts/successAlerts";
import { ErrorAlert } from "../../../alerts/errorAlert";
import { v4 as uuidv4} from 'uuid'
import "../css/ModalPrestacion.css"
import CircularProgress from '@mui/material/CircularProgress';
import Fade from '@mui/material/Fade'

const CreateBenefits = ({setInfoImg}:any) => {
  const {state, dispatch} = useContext(PrestacionContext);  
  const [tipoPrestacion, setTipoPrestacion] = useState<Array<any>>([]);  
  const [sedes, setSedes] = useState<any>([])
  const [projects, setProjects] = useState<Array<any>>([]);
  const [selectedAllSedes, setSelectedAllSedes] = useState<String[]>([]);
  const [selectedAllProjects, setSelectedAllProjects] = useState<String[]>([]); 
  const [selectedUser, setSelectedUser] = useState<String[]>([]); 
  const [archives, setArchives] = useState<any>("")
  const [txtImagen,setTxtImagen]=useState("Subir Imagen")
  const [evaluable,setEvaluable]=useState(false)
  const {data: CatPrestaciones} = useQuery(GET_ALL_TIPO_PRESTACIONES);
  const { data: resultSede } = useQuery(GET_ALL_SEDE);
  const allSede = resultSede?.GET_ALL_SEDE;
  const { data: resultProject } = useQuery(GET_ALL_PROJECT);
  const allProject = resultProject?.GET_ALL_PROJECT;
  const {data: RestriccionPrestaciones} = useQuery(GET_ALL_TIPO_RESTRICCIONES_PRESTACIONES);      
  const restriccionPrestacion = RestriccionPrestaciones?.GET_ALL_TIPO_RESTRICCIONES_PRESTACIONES;  
  const [arrayRestriccion,setArrayRestriccion] = useState<any[]>([]);  
  const [getUserByCampusProject ] = useLazyQuery(GET_USERS_BY_CAMPUS_OR_PROJECT);
  const [usersByCampusProject, setUsersByCampusProject] = useState<Array<any>>([]);  
  const [colaboradoresBD,setColaboradoresBD]=useState<Array<any>>([]);
  const [loading, setLoading] = useState(false);
  const [respImg, setResImg] = useState({});
  const [counterTxt, setCounterTxt] = useState(0);
  const inputRef:any = useRef(null);
  
  const initalValues = () =>{
    return{
      prestacion: "",
      idTipoPrestacion: "",
      sede: "",
      area: "",  
      descripcion:"",    
    }
  }

  const validationSchema = () =>{
    return{
        prestacion: Yup.string().required("Obligatorio"),
        idTipoPrestacion: Yup.number().required("Obligatorio"),
        sede: Yup.array().required("Obligatorio").test("sede", "Es necesario seleccionar un proyecto", function(value){
          if((value === undefined || value.length === 0)){
              return false;
          }else{
              return true;
          }
         }),       
         area: Yup.array().required("Obligatorio").test("area", "Es necesario seleccionar una sede", function(value){
          if((value === undefined || value.length === 0)){
              return false;
          }else{
              return true;
          }
      }),
    }
  }

  const [createPrestacion] = useMutation(CREATE_PRESTACION, {
    refetchQueries: [
        { query: GET_ALL_PRESTACIONES }
    ],
  });
   const formik = useFormik({
    initialValues: initalValues(),
    validationSchema: Yup.object(validationSchema()),
    onSubmit: async (formData, { resetForm }) =>{
    let flag = false;
    if(selectedUser.length ===0){
        ErrorAlert({text:'Es necesario agregar usuarios a la prestacion'});
        return
    }   
    let arrayUserSel:any=[];
    let objUserSel:{};
    selectedUser.map((selUser:any) => {
        objUserSel ={ id:selUser.id  }
        arrayUserSel.push(objUserSel)
     })
    const clearArrayRestriccion= [...arrayRestriccion];
    for(const item of clearArrayRestriccion){
      delete item?.keyTemp;     
    }   
   
    const newInfo={...formData, evaluable, 
                               usuarios:arrayUserSel,
                               restricciones:clearArrayRestriccion,
                               sede:selectedAllSedes,
                               area:selectedAllProjects}
    console.log(newInfo)                               
     
    if(flag){
        ErrorAlert({text:'Es necesario completar todos los campos'});
      }
      else{
         try{
           let { data } = await createPrestacion({
             variables: {
               input: newInfo,
             },
           });          
          if( archives !==''){
           const resImg=await postFileCloudPrestacion(archives,  data.CREATE_PRESTACION.id, "IMG_PRESTACION", "IMG_PRESTACION", false);
           setResImg(resImg)             
          }              
          setArrayRestriccion([]);               
          setSelectedAllSedes([]);
          setSelectedAllProjects([]);  
          setUsersByCampusProject([]);
          setSelectedUser([]);
          setTxtImagen('Subir Imagen');         
          setEvaluable(false);
          setCounterTxt(0);  
          resetForm()
          clearBenefitsModal(false, dispatch)       
          SuccessfulAlert({ text: "Prestación creada correctamente." });    
         }catch(e){
          ErrorAlert({text:'Ocurrió un error al crear la prestación'});
          console.log("error")
        }
      }      
    }
  });

  useEffect(() => {
    if(Object.keys(respImg).length >0 ){
         setInfoImg(respImg)  
    }
}, [respImg, setInfoImg]);  

  useEffect(() => {
        if(CatPrestaciones && CatPrestaciones.GET_ALL_TIPO_PRESTACIONES){
             setTipoPrestacion(CatPrestaciones.GET_ALL_TIPO_PRESTACIONES);
        }
    }, [CatPrestaciones]);   

   useEffect(() => {
      if(allSede){
        setSedes(allSede)
      }
  }, [allSede]);    

  useEffect(() => {
    if (allProject){ 
       setProjects(allProject);}
}, [allProject]);

  const onChangeLocation = (e:any) => {
    if(e.target.value.indexOf('Todas') > -1){
        if(sedes.length === selectedAllSedes.length){
            setSelectedAllSedes([]);
        }else{
            let filtered = sedes.map((item:any)=> item.sedeName);
            setSelectedAllSedes(filtered);
        }
    }else{
        setSelectedAllSedes(e.target.value);
    }
   }

   const onChangeProject = (e:any) => {
      if(e.target.value.indexOf('Todas') > -1){
        if(projects.length === selectedAllProjects.length){
            setSelectedAllProjects([]);
        }else{
          let filtered = projects.map((item:any)=> item.proyectNameAndClient);
             setSelectedAllProjects(filtered);
        }
    }else{
      setSelectedAllProjects(e.target.value);      
    }
}
 
const onChangeImage = (e: any) => {
        setTxtImagen(e.target.files[0].name);
        setArchives(e.target.files[0]);        
    }   

const handleAdminColaborador= async ()=>{
    setColaboradoresBD([])
    if(selectedAllSedes.length === 0 || selectedAllProjects.length === 0 ){
          ErrorAlert({text:'Seleccione el Area y Sede'});
          return
        }else
        { 
         setUsersByCampusProject([])
         setLoading(true)
         const proyectsName= selectedAllProjects.map((name:any)=>{
            const nameSplit=name.split('/');
            return nameSplit[0]
         })       
         let { data } = await getUserByCampusProject({
              variables: { 
                  input: {
                      sede:selectedAllSedes,
                      projects:proyectsName
                     },
                  }
              })   
            
            if(data.GET_USERS_BY_CAMPUS_OR_PROJECT.length>0){    
                setUsersByCampusProject(data.GET_USERS_BY_CAMPUS_OR_PROJECT);            
                setLoading(false)
                createUserBenefitsModal(true, dispatch)             
             } else{
              setLoading(false)
              ErrorAlert({text:'No hay colaboradores con los valores seleccionados'});
             }  
           }       
        }

 const handleAgregarRestriccion=()=>{
        const key = uuidv4();
        const objRestricion={ 
                 idTipoRestriccion:'',
                 salInicial: '',
                 salTope:'', 
                 tipoContrato:'',
                 meses:'',
                 otro:''   ,            
                 keyTemp:key                         
               }
        setArrayRestriccion([...arrayRestriccion, objRestricion])       
    }

  const onChangeRestriccion = (e:any,index:any) => {
         const data = [...arrayRestriccion];
               data[index]['idTipoRestriccion'] = e.target.value;
               setArrayRestriccion(data)         
    }

  const handleChangeRes=(e:any,field:any,index:any)=>{
     const data = [...arrayRestriccion];
        switch (field) {
            case "salarioBase":
                data[index]['salInicial'] = e.target.value;
                setArrayRestriccion(data)   
            break;
            case "salrioLimite":
                data[index]['salTope'] = e.target.value;
                 setArrayRestriccion(data)   
             break;
            case "tipoContrato":
                data[index]['tipoContrato'] = e.target.value;
                 setArrayRestriccion(data)   
             break; 
             case "meses":
                data[index]['meses'] = e.target.value;
                 setArrayRestriccion(data)   
             break;       
             case "otro":
                data[index]['otro'] = e.target.value;
                 setArrayRestriccion(data)   
             break;        
        }    
    }

  const eliminarRestriccion=(key:any)=>{
     const eliminaRestriccion = arrayRestriccion.filter((res) => { 
            return res.keyTemp !== key             
              });
         setArrayRestriccion(eliminaRestriccion);              
   }
 
  const counterArea=(e:any)=>{
      setCounterTxt(e.length)
  }

  const resetStates=()=>{
        setSelectedUser([])        
        setArrayRestriccion([]);    
        setSelectedAllSedes([]);
        setSelectedAllProjects([]);    
        setUsersByCampusProject([]); 
        setSelectedUser([]);       
        setTxtImagen('Subir Imagen');           
        setEvaluable(false)
        setLoading(false)
        setCounterTxt(0)
    }
 
  return (
    <>
    <Dialog open={state.createModalBenefits} 
           aria-labelledby="form-dialog-title" 
           className="dialogPrestacion"         
           >
        <IconButton aria-label="close" onClick={(()=> {  formik.resetForm();
                                                          resetStates();
                                                          clearBenefitsModal(false, dispatch)} )}
          sx={{position: 'absolute', right: 8, top: 8,color: (theme) => theme.palette.grey[500], }}
           >
          <CloseIcon />
        </IconButton>     
       <DialogContent  >
          <Form onSubmit={formik.handleSubmit} className="containerMdalPrestacion" >
            <DialogTitle ><span className="titlePrestacion">Crear prestación</span></DialogTitle> 
            <DialogContent className="oneDivPrestacion">
              <TextField
                      type="text"
                      name="prestacion"
                      label="Nombre de la prestación *"
                      variant="outlined"
                      size="small"                                            
                      style = {{width: 465,fontFamily:'Roboto',fontStyle: 'italic'  }}                     
                      inputProps={{ maxLength: 100 }}                     
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                      error={formik.touched.prestacion && Boolean(formik.errors.prestacion)}
                      helperText={formik.touched.prestacion && formik.errors.prestacion} 
                    />         
             </DialogContent>
             <DialogContent className="twoInputsPrestacion">
                <DialogContent className="categoriaPrestacion">
                  <FormControl 
                        fullWidth 
                        size="small"
                        variant="outlined"                      
                        error={formik.touched.idTipoPrestacion && Boolean(formik.errors.idTipoPrestacion)}
                      >
                      <InputLabel id="demo-simple-select-label" style={{  backgroundColor: "#FFFFFF" }}> Categoria *</InputLabel>
                          <Select
                            style={{
                              fontFamily:'Roboto',
                              fontStyle: 'italic',
                              textOverflow: "ellipsis" ,
                              whiteSpace: "nowrap",
                              overflow: "hidden",   
                              width:"225px"  , 
                              height:"40px"                            
                              }}                     
                            labelId="demo-simple-select-label"
                            id="idTipoPrestacion"
                            name="idTipoPrestacion"
                            value={formik.values.idTipoPrestacion}
                            onChange={(e) => {formik.handleChange(e)}}                         
                          >
                            {
                              tipoPrestacion?.map((tipo:any) => {
                              return <MenuItem value={tipo.id} key={tipo.id}>{tipo.descripcion}</MenuItem>
                              })
                            }
                          </Select>   
                          <FormHelperText style={{color: "red"}}>
                              {formik.touched.idTipoPrestacion && formik.errors.idTipoPrestacion}
                          </FormHelperText>                        
                  </FormControl>
                </DialogContent>  
                <DialogContent className="empresaSede">
                <FormControl 
                   fullWidth 
                   size="small"
                   variant="outlined" 
                   className="question" 
                  >
                 <InputLabel id="demo-simple-select-label"  style={{backgroundColor: "#FFFFFF"}}>
                   Sede*
                  </InputLabel>
                  <Select
                     style={{                      
                      fontFamily:'Roboto',
                      fontStyle: 'italic',
                      textOverflow: "ellipsis" ,
                      whiteSpace: "nowrap",
                      overflow: "hidden",   
                      width:"225px"  , 
                      height:"40px"    
                        }}
                     value={selectedAllSedes}
                     onChange={(e) => {
                               formik.handleChange(e);
                                onChangeLocation(e);
                               }
                              }
                     labelId="demo-simple-select-label"
                     id="sede"
                     name = "sede"
                     multiple
                     renderValue={(selected:any) => selected.join(',')}
                     error={formik.touched.sede && Boolean(formik.errors.sede)}
                    >
                     <MenuItem key={0} value={'Todas'}>
                        <Checkbox checked={selectedAllSedes.length === sedes.length} style ={{
                                      color: "#FABB00",
                                    }} />
                        <ListItemText primary={"Todas"} />
                        </MenuItem>
                            {
                              sedes.map((item:any) => (
                               <MenuItem key={item.id} value={item.sedeName}>
                                <Checkbox 
                                     checked={selectedAllSedes.indexOf(item.sedeName) > -1} 
                                     style ={{
                                      color: "#FABB00",
                                    }}
                                     />
                                  <ListItemText primary={item.sedeName} />
                                </MenuItem>
                              ))
                            }                                           
                        </Select>
                          <FormHelperText style={{color: "red"}}>
                             {formik.touched.sede && formik.errors.sede}
                          </FormHelperText>
                    </FormControl>
                </DialogContent>   
             </DialogContent>             
             <DialogContent className="twoInputsPrestacion">
               <DialogContent className="companyArea" > 
                   <FormControl 
                    fullWidth 
                    size="small"
                    variant="outlined" 
                    className="question" 
                     error={formik.touched.area && Boolean(formik.errors.area)}
                     >
                    <InputLabel id="demo-simple-select-label"
                     style={{ backgroundColor: "#FFFFFF"}}
                    >&Aacute;rea o Proyecto*</InputLabel>
                    <Select
                      style={{
                        fontFamily:'Roboto',
                        fontStyle: 'italic',
                        textOverflow: "ellipsis" ,
                        whiteSpace: "nowrap",
                        overflow: "hidden",   
                        width:"225px"  , 
                        height:"40px"                                          
                          }}
                       value={selectedAllProjects}
                      onChange={
                              (e) => {
                                   formik.handleChange(e);
                                    onChangeProject(e);
                                    }
                               }
                      labelId="demo-simple-select-label"
                      id="area"
                      name = "area"
                      multiple
                      renderValue={(selected:any) => selected.join(',')}
                      error={formik.touched.area && Boolean(formik.errors.area)}
                    >
                    <MenuItem key={0} value={'Todas'}>
                     <Checkbox 
                          checked={selectedAllProjects.length === projects.length} 
                          style ={{
                            color: "#FABB00",
                          }}
                          />
                       <ListItemText primary={"Todas"} />
                       </MenuItem>
                       {
                         projects.map((project:any) => (
                          <MenuItem key={project.id} value={project.proyectNameAndClient}>
                           <Checkbox checked={selectedAllProjects.indexOf(project.proyectNameAndClient) > -1} style ={{
                                      color: "#FABB00",
                                    }}/>
                           <ListItemText primary={project.proyectNameAndClient} />
                           </MenuItem>
                          ))
                      }
                      </Select>
                      <FormHelperText style={{color: "red"}}>
                       {formik.touched.area && formik.errors.area}
                      </FormHelperText>
                   </FormControl>     
               </DialogContent>     
               <DialogContent className="containerUploadImgPrestacion">                   
                    <label className="custom-file-upload-image-prestacion" id="lblImage">
                          <p className="textLblimg"> {txtImagen }</p>
                           <input ref={inputRef}
                            type="file" name="Image"
                            className="inputFilePrestacion"                           
                            onChange={(e) => onChangeImage(e)} 
                            accept=".png,.jpg,.jpeg,.svg" />
                           <img id="imgPrestacion" src={UploadImage} alt="imgPrestacion"  />                          
                    </label>             
               </DialogContent>                  
             </DialogContent>
           <DialogContent className="twoInputsPrestacionAdmin">
               <DialogContent className="btnContainerAdminColaborador">
                        <button className="btnAdminColaborador" type="button"
                        onClick={() => handleAdminColaborador()}     >
                           {`Administrar colaboradores (${selectedUser.length})`}
                        </button> 
                </DialogContent>                     
                <Fade
                        in={loading}
                        style={{
                          transitionDelay: loading ? '900ms' : '0ms',
                        }}
                        unmountOnExit >
                      <CircularProgress  />
                 </Fade>        
                    <DialogContent className="evaluable">
                       <div><span className="evaluableTxt">Evaluable</span></div>
                       <div className="switchPrestacionEv"><GreenSwitchPrestacion  valor={evaluable} setValor={setEvaluable}/></div>
                   </DialogContent>
               </DialogContent>
                <DialogContent className="oneDivPrestacion">
               <div className="restriccion">
                <button className="btnRestriccion" type="button"  onClick={(() =>{ handleAgregarRestriccion();} )}>
                      <div className="infobtnRestriccion">
                        <div><span className="txtbtnRestriccion"> Agregar restricción</span></div>            
                        <div><img src={Plus} alt="plus"/> </div> 
                     </div>  
                </button>                  
                </div> 
              </DialogContent>
              {arrayRestriccion.length >0 ?
               arrayRestriccion.map((res:any, key:number)=>(
                <DialogContent className="oneDivPrestacion">
                  <div className="containerRestriciones" key={res.id} >
                    <div className="selectRestriccion">
                     <FormControl 
                        fullWidth 
                        size="small"
                        variant="outlined"                       
                        >
                        <InputLabel id="demo-simple-select-label"
                         key = {uuidv4()}
                         style={{ backgroundColor: "#FFFFFF" }}
                         >seleccionar *</InputLabel>
                         <Select
                           style={{
                                fontFamily:'Roboto',
                                fontStyle: 'italic',
                                textOverflow: "ellipsis" ,
                                whiteSpace: "nowrap",
                                overflow: "hidden",  
                                width:"170px", 
                                height:"40px",                                                               
                            }}                     
                            labelId="demo-simple-select-label"
                            id="idTipoRestriccion"
                            name="idTipoRestriccion"
                            value = {res.idTipoRestriccion}
                            onChange={(e:any)=>{
                                onChangeRestriccion(e,key);                                                              
                             }}
                            >
                            {
                             restriccionPrestacion?.map((restriccion:any,index:number) => {
                                     return <MenuItem value={restriccion.id} 
                                                      key={restriccion?.clave}>
                                                      {restriccion?.descripcion}
                                     </MenuItem>
                                    })
                            }
                            </Select>                           
                        </FormControl>
                    </div> 
                    {        
                     res?.idTipoRestriccion === '1' ?
                     <>                   
                      <div className="sueldoBase">
                       <div>
                        <TextField
                            type="number"
                            name="salarioBase"
                            label="Base*"
                            variant="outlined"
                            size="small"
                            fullWidth={false}
                            value = {res.salInicial || ''}
                            style={{
                                width: "120px",   
                                fontFamily:'Roboto',
                                fontStyle: 'italic',                             
                            }}    
                            onBlur={formik.handleBlur}
                            onChange={(e:any)=>{
                                handleChangeRes(e,'salarioBase',key);                                                                
                               }}                         
                            inputProps={{ maxLength: 100 }}
                            onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}                           
                            />
                       </div>
                        <div>
                              <img  src={FlechaPrestacionDer} alt="imgFlecha"  />
                        </div>
                        <div>
                          <TextField
                            type="number"
                            name="salrioLimite"
                            label="Limite*"
                            variant="outlined"
                            size="small"   
                            value = {res.salTope || ''}                        
                            fullWidth={false}
                            style={{
                                width: "120px",   
                                fontFamily:'Roboto',
                                fontStyle: 'italic',                                  
                            }}    
                            onBlur={formik.handleBlur}
                            onChange={(e:any)=>{
                                handleChangeRes(e,'salrioLimite',key);                                                                
                               }}   
                            className="prestacion"
                            inputProps={{ maxLength: 100 }}                           
                            />
                            </div>                                                    
                      </div>
                     </>
                     : res?.idTipoRestriccion === '2'?<>
                     <div className="selectContrato">
                     <FormControl 
                        fullWidth 
                        size="small"
                        variant="outlined"                        
                        >
                        <InputLabel id="demo-simple-select-label"
                         style={{ backgroundColor: "#FFFFFF" }}
                         >seleccionar *</InputLabel>
                         <Select
                           style={{
                                textOverflow: "ellipsis" ,
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                width: "255px",                                      
                                fontFamily:'Roboto',
                                fontStyle: 'italic',                             
                            }}                     
                            labelId="demo-simple-select-label"
                            id="tipoContrato"
                            name="tipoContrato"                            
                            onChange={(e:any)=>{
                                handleChangeRes(e,'tipoContrato',key);                                                                
                               }}  
                            >
                             <MenuItem  value="Determinado">Determinado  </MenuItem>
                             <MenuItem  value="Indeterminado" >Indeterminado </MenuItem>                            
                            </Select>                           
                        </FormControl>
                     </div> 
                     </>:
                      res?.idTipoRestriccion === '3'?<>
                       <div className="MesesRestricccion">
                        <div>
                        <TextField
                            type="number"
                            name="Meses"
                            label="Meses*"
                            variant="outlined"
                            value = {res.meses || ''}    
                            size="small"
                            fullWidth={false}
                            style={{
                              fontFamily:'Roboto',
                              fontStyle: 'italic',   
                              width: "255px",                                                                
                            }}    
                            onBlur={formik.handleBlur}
                            onChange={(e:any)=>{
                                handleChangeRes(e,'meses',key);                                                                
                               }}                              
                            inputProps={{ maxLength: 100 }}                           
                            />
                        </div>                                    
                     </div>                      
                      </>: res?.idTipoRestriccion === '4'?<>
                      <div className="MesesRestricccion">
                       <div>
                        <TextField
                            type="text"
                            name="otro"
                            label="Otro*"
                            variant="outlined"
                            size="small"
                            fullWidth={false}
                            value = {res.otro || ''}   
                            style={{
                              fontFamily:'Roboto',
                              fontStyle: 'italic',   
                              width: "255px",                                                                                               
                            }}    
                            onBlur={formik.handleBlur}
                            onChange={(e:any)=>{
                                handleChangeRes(e,'otro',key);                                                                
                               }}  
                            inputProps={{ maxLength: 100 }}                           
                            />
                           </div>                                    
                     </div>            
                      </>: <> <div className="mensajeInfo"><span className="txtAlertaRestriccion">Debes seleccionar el tipo de restricción</span></div></>
                    }
                    <div className="delREstriccion" 
                            onClick={()=>{
                            eliminarRestriccion(res.keyTemp);                                                        
                        }} >                     
                       <img  src={Minus} alt="minus"  />
                    </div>
                 </div>    
                 </DialogContent>                                
               )
              ) 
              :<></>                
              }  
              <DialogContent className="oneTxtPrestacion">
              <TextField
                  id="outlined-multiline-static"
                  label="Descripción de la prestación"
                  multiline                  
                  name="descripcion"
                  onBlur={formik.handleBlur}
                  onChange={(e:any)=>{
                    formik.handleChange(e);
                    counterArea(e.target.value);                                                                
                   }} 
                  rows={5}
                  style = {{width: 465,fontFamily:'Roboto',fontStyle: 'italic' }}
                  variant="outlined"
                  inputProps={{ maxLength: 150 }}                     
                /> 
                <div className="divCounterArea"><label className="txtConterPres">{`${counterTxt}/150`}</label></div>
              </DialogContent> 
              <DialogContent className="twoBtnsPrestacion">
              <div className="containerButtons">
                <div className="containerCancelPreestacion">
                    <button className="btnCancelPrestacion" type="button" 
                    onClick={(() =>{
                        formik.resetForm();
                        resetStates();
                        clearBenefitsModal(false, dispatch); 
                            }
                            )}>
                        Cancelar
                    </button>             
                </div>    
              <div className="containerAddPrestacion">
                <button className="btnAddPrestacion" type="submit" >
                      <div className="infobtnAddRestriccion">
                        <div><img src={Add} alt="add"/></div>            
                        <div><span className="txtBtnAdd">Crear prestación </span> </div> 
                     </div>                   
              </button>             
            </div>    
          </div> 
              </DialogContent>              
           </Form>                  
        </DialogContent>       
    </Dialog>    
    {usersByCampusProject.length>0?                          
      <AdminColaboradoresPrestacion 
        users={usersByCampusProject}
        userSelect={selectedUser}
        setSelectedUser={setSelectedUser}
        colaboradoresBD={colaboradoresBD}
      /> :<></>
    }
    </>  
  )
}

export default CreateBenefits

