import { useContext, useState } from "react";
import PrestacionContext from '../../context/PrestacionesContext/PrestacionContext';
import {updateBenefitsModal} from '../../context/PrestacionesContext/Actions'
import { WarningAlert } from '../../alerts/WarningAlert';
import { IconButton, Menu, MenuItem } from "@material-ui/core";
import Box from '@mui/material/Box';
import MoreVertIcon from '@material-ui/icons/MoreVert';
// import VisibilityIcon from '@mui/icons-material/Visibility';
import { DELETE_PRESTACION, GET_ALL_PRESTACIONES } from "../../Querys/prestaciones/querys";
import { useMutation} from "@apollo/client";
import CheckPermission from "../../helpers/Administradores/Permissions";

export const MenuPrestacion = (props: any) => {
    const {dispatch} = useContext(PrestacionContext)
    
    const [anchorEl, setAnchorEl] = useState<any>(null);
    const open = Boolean(anchorEl);   
    const openMenu = (e: any): void => {
      setAnchorEl(e.currentTarget);
    };
    const [deletePrestacion] = useMutation(DELETE_PRESTACION, {
      refetchQueries: [
          { query: GET_ALL_PRESTACIONES }
      ],
  });

    // const verPrestacion = (id:string, verModal: boolean) =>{
    //   setAnchorEl(null);
    //   console.log(id, verModal)
    //   // updateCalendarsModal(id, updateModal, dispatch)
    // }

    const updateModal = (id:string, updateModal: boolean) =>{
      setAnchorEl(null);    
      updateBenefitsModal(id, updateModal, dispatch)      
    }

    const deleteModal =async (id:any ) =>{
      setAnchorEl(null);          
       let response = await WarningAlert({ text: "¿Deseas eliminar la prestación?", showDenyButton: true, confirmButtonText: "Eliminar" });
        if (response.isConfirmed) {   
             await deletePrestacion({
                 variables:{
                    deletePrestacionId:parseInt(id)
                 }
             });
        }
    }

    const menuDelete = ()=>{
         return <MenuItem onClick={() => deleteModal(props.id )}>
                <div className="contenedorTextoMenuPrestacion" >
                    <span className="textoMenuEliminarPrestacion">Eliminar</span>
                </div>
                <div className="imgDeletePres">
                    <img 
                     className="iconoMenuEliminarPrestacion"
                     src="/assets/svg/icono-eliminar.svg" alt="Eliminar" />
                </div>
            </MenuItem>        
          }  
    return (
        <>
          <Box mr={2} 
             className="menuListNewPrestacion">
                <IconButton onClick={openMenu}
                   className="menuListOptionNewPrestacion"
                 >
                  <MoreVertIcon  className="moreOptionNewPrestacion"  />  
                  </IconButton>
                </Box>
            <Menu
                className="MoreVerIcon"
                anchorEl={anchorEl}
                open={open}
                transformOrigin={{ vertical: "top", horizontal: "right" }}
                onClose={() => setAnchorEl(null)}>
                {/* {CheckPermission("Calendario.AgregarDiaInhabil") && (
                <MenuItem 
                       onClick={() => verPrestacion(props.id, true)}
                     >
                    <div   className="contenedorTextoMenuPrestacion" >
                        <span className="textoMenuEditarPrestacion" >Información</span>
                    </div>
                    <div>
                        <VisibilityIcon className="iconoMenuViewPrestacion"                        />
                    </div>
                </MenuItem>
                )} */}
                {CheckPermission("Calendario.EditarCalendario") && (
                <MenuItem onClick={() => updateModal(props.id, true)}>
                    <div  className="contenedorTextoMenuPrestacion" >
                        <span className="textoMenuEditarPres" >Editar</span>
                    </div>
                    <div  className="imgEditaPres" >
                        <img className="iconoMenuPres"   src="/assets/svg/icono-editar.svg" alt="Editar" />
                    </div>
                </MenuItem>
                )}
                {CheckPermission("Calendario.EliminarCalendario") && ( 
                     menuDelete()             
                 )}
            </Menu>   
           
        </>
    )
}