import {Types} from './CentralPagosContext'

const ModalReducer = (state:any, action:any) =>{
  const {payload, type} = action;
  switch(type){
    case Types.CLEAR_DISPERSAR:
      return{
        ...state,
        openDispersar: false,
        openSinRegistros: false,
        openEliminarRegistro: false,
        openSinFondos: false,
        openMensajeError: false
      }
    case Types.OPEN_DISPERSAR:
      return{
        ...state,
        openDispersar: true
      }
    case Types.SIN_REGISTROS:
      return{
        ...state,
        openSinRegistros: true
      }
    case Types.ELIMINAR_REGISTRO:
      return{
        ...state,
        openEliminarRegistro: true,
        _id: payload._id
      }
    case Types.OPEN_SIN_FONDOS:
      return{
        ...state,
        openSinFondos: true
      }
    case Types.OPEN_MENSAJE_ERROR:
      return{
        ...state,
        openMensajeError: true,
        _id: payload._id
      }
    default:
      return state 
  }
}

export default ModalReducer