import { useContext, useEffect, useState } from "react";
import {
  Checkbox,
  FormControlLabel,
  Grid,
  Button,
  TextField,
  FormHelperText
} from "@material-ui/core";
import Box from '@mui/material/Box';
import CollaboratorContext from "../../../../context/CollaboratorContext/CollaboratorContext";
import { SuccessfulAlert } from "../../../../alerts/successAlerts";
import EyeIcon from "../../../../assets/svg/eye.svg";
import RemoveFile from "../../../../assets/svg/remove_file.svg";
import FileOwn from "../Fields/File_Helper_Own_Register";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Form } from "semantic-ui-react";
import { useMutation } from "@apollo/client";
import { GET_USERS_BY_ID, UPDATE_USERS } from "../../../../Querys/querys";
import SaveIcon from "@material-ui/icons/Save";
import {
  handleSetActiveStep
} from "../../../../helpers/Collaborator/Collaborator";
import { formatterMxn } from "../../../../helpers/formatoMoneda";
import InputAdornment from "@mui/material/InputAdornment";

const OptionalDocumentsColaboradores = () => {
  const { state, dispatch } = useContext(CollaboratorContext);
  const [archives, setArchives]:any = useState([]);
  const [isAvisoRetencion, setIsAvisoRetencion] = useState(false);
  const [isCartaGMM, setIsCartaGMM] = useState(false);
  const [errorSecure, setErrorSecure] = useState(false);
  const [errorInfonavit, setErrorInfonavit] = useState(false);
  const [updateColaboradores] = useMutation(UPDATE_USERS, {
    refetchQueries: [
      {
        query: GET_USERS_BY_ID,
        variables: { getUsersId: state.collaborator.id },
      },
    ],
  });

  const initialValues = () => {
    return {
      isAvisoRetencion: state.collaborator?.isAvisoRetencion ?? "",
      isCartaGMM: state.collaborator?.isCartaGMM ?? "",
      INFONAVIT: state.collaborator?.INFONAVIT ?? "",
      Periodicity_INFONAVIT: "Mensual",
    };
  };
  const validationSchema = () => {
    return {
      // INFONAVIT IS REQUIRED IF isAvisoRetencion IS FALSE
      INFONAVIT: Yup.string().when("isAvisoRetencion", {
        is: true,
        then: Yup.string().nullable(),
        otherwise: Yup.string().required("Este campo es requerido"),
      }),
    };
  };

  const formik = useFormik({
    initialValues: initialValues(),
    validationSchema: Yup.object(validationSchema()),
    onSubmit: (formData) => {
      if (formData.isAvisoRetencion === true) {
        formData.isAvisoRetencion = true;
      } else {
        formData.isAvisoRetencion = false;
      }
      if (formData.isCartaGMM === true) {
        formData.isCartaGMM = true;
      } else {
        formData.isCartaGMM = false;
      }
      if (formData.INFONAVIT === "" || isAvisoRetencion) {
        formData.INFONAVIT = null;
        formData.Periodicity_INFONAVIT = "";
      }
      if (formData.INFONAVIT) {
        formData.INFONAVIT = handleCleanFormat(formData.INFONAVIT);
      }
      setErrorInfonavit(false);
      setErrorSecure(false);
      let exists_secure = false;
      let exists_infonavit = false;
      for(const archive of archives){
        if(archive.name === "CartaGMM_PDF"){
          exists_secure = true;
        }
        if(archive.name === "AvisoRetencion_PDF"){
          exists_infonavit = true;
        }
      }
      let errors = 0;
      if(!formData.isAvisoRetencion && !exists_infonavit){
        setErrorInfonavit(true);
        errors++
      }
      if(!formData.isCartaGMM && !exists_secure){
        setErrorSecure(true);
        errors++
      }
      if(errors === 0){
        setErrorSecure(false);
        updateColaboradores({
          variables: {
            updateUsersId: state.collaborator?.id,
            input: formData,
          },
        }).then(() => {
          handleSetFormat(formData.INFONAVIT, "INFONAVIT");
          SuccessfulAlert({ text: "Se actualizó correctamente" });
          //if (state?.collaborator?.register_process[4].section_2) {
            handleSetActiveStep(2, dispatch);
          //}
        });
      }
    },
  });

  const handleSetFormat = (value: any, field: any) => {
    //parse value to string if is number
    if (typeof value === "number") {
      value = value.toString();
    }

    if (typeof value === "string") {
      let valueFormatted = value.replace(/,/g, "");

      valueFormatted = parseFloat(valueFormatted).toFixed(2);

      let x = formatterMxn(valueFormatted);
      if (x === "nan") return false;
      x = x.substring(1);
      formik.setFieldValue(field, x);
      return x;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  useEffect(() => {
    if (state.collaborator) {
      setIsAvisoRetencion(state.collaborator.isAvisoRetencion);
      setIsCartaGMM(state.collaborator.isCartaGMM);
      handleSetFormat(state.collaborator?.INFONAVIT, "INFONAVIT");
      if (state.collaborator?.archive) {
        setArchives(state.collaborator.archive);
      }
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.collaborator]);

  const handleCleanFormat = (value: string) => {
    //check if string contains .00
    if (value.includes(".00")) {
      value = value.substring(0, value.indexOf(".00"));
    }
    value = value.replace(/,/g, "");
    //parse to float and fixed to 2 decimals take the decimal after the comma
    let x = parseFloat(value).toFixed(2);
    let y = parseFloat(x);
    return y;
  };

  return (
    <Form onSubmit={formik.handleSubmit}>
      <div className="novalidate__border">
        <Grid direction="row" container>
          <Grid xs={6} item className="aviso-retencion">
            {/*AVISO DE RETENCIÓN  */}
            {archives?.map(
              (archive: any, index: number) =>
                archive?.name === "AvisoRetencion_PDF" && (
                  <>
                    <div className="flex-container">
                      <div className="flex-child">
                        <label>Aviso de retención</label>
                        <span className="span-file">
                          (En caso de tener crédito de Infonavit)
                        </span>
                        <span className="span-file">
                          <a
                            className="view-document"
                            target="_blank"
                            rel="noreferrer"
                            href={archive.URL}
                          >
                            <img
                              style={{ height: "10px", marginTop: "5px" }}
                              src={EyeIcon}
                              alt=""
                            />
                            Ver documento
                          </a>
                        </span>
                      </div>

                      <div className="flex-child-2">
                        <FileOwn
                          name="AvisoRetencion_PDF"
                          accept=".pdf"
                          parametrofrom="collaborator"
                          idUsr={state.collaborator?.id}
                          className="image-file"
                          setArchives={setArchives}
                          archives={archives}
                          image={RemoveFile}
                          remove_file={true}
                          disabled={isAvisoRetencion ? true : false}
                          section="2"
                        />
                      </div>
                    </div>

                    <div style={{ marginTop: "2%" }}>
                      <div>
                        <span className="span-file">
                          <TextField
                            style={{
                              width: "100%",
                              display: "inline-block",
                            }}
                            label="INFONAVIT"
                            id="outlined-basic"
                            variant="outlined"
                            type="text"
                            size="small"
                            value={formik.values.INFONAVIT}
                            name="INFONAVIT"
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  $
                                </InputAdornment>
                              ),
                            }}
                            onKeyPress={(event: any) => {
                              if (!/^[0-9.]+$/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                            onChange={(e) => {
                              formik.handleChange(e);
                            }}
                            onBlur={() =>
                              handleSetFormat(
                                formik.values.INFONAVIT,
                                "INFONAVIT"
                              )
                            }
                            error={
                              formik.touched.INFONAVIT &&
                              Boolean(formik.errors.INFONAVIT)
                            }
                            helperText={
                              formik.touched.INFONAVIT &&
                              Boolean(formik.errors.INFONAVIT) &&
                              "El campo es requerido, si no tiene crédito de INFONAVIT, seleccione la opción de abajo"
                            }
                            disabled={isAvisoRetencion ? true : false}
                          />
                        </span>
                        {
                          errorInfonavit && (
                            <FormHelperText style={{color: 'red'}}>
                              Es necesario subir un archivo, si no cuenta con un crédito de INFONAVIT, seleccione la opción de abajo
                            </FormHelperText>
                          )
                        }
                      </div>
                    </div>
                  </>
                )
            )}

            {!state.collaborator?.AvisoRetencion_PDF && (
              <>
                <div className="flex-container">
                  <div className="flex-child">
                    <label>Aviso de retención</label>
                    <span className="span-file">
                      (En caso de tener crédito de Infonavit)
                    </span>
                  </div>

                  <div className="flex-child-2">
                    <FileOwn
                      name="AvisoRetencion_PDF"
                      accept=".pdf"
                      parametrofrom="collaborator"
                      idUsr={state.collaborator?.id}
                      className="image-file"
                      setArchives={setArchives}
                      archives={archives}
                      disabled={isAvisoRetencion ? true : false}
                      section="2"
                    />
                  </div>
                </div>
                <div style={{ marginTop: "2%" }}>
                  <div>
                    <span className="span-file">
                      <TextField
                        style={{
                          width: "100%",
                          display: "inline-block",
                        }}
                        label="INFONAVIT"
                        id="outlined-basic"
                        variant="outlined"
                        type="text"
                        size="small"
                        value={formik.values.INFONAVIT}
                        name="INFONAVIT"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">$</InputAdornment>
                          ),
                        }}
                        onKeyPress={(event: any) => {
                          if (!/^[0-9.]+$/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        onChange={(e) => {
                          formik.handleChange(e);
                        }}
                        onBlur={() =>
                          handleSetFormat(formik.values.INFONAVIT, "INFONAVIT")
                        }
                        error={
                          formik.touched.INFONAVIT &&
                          Boolean(formik.errors.INFONAVIT)
                        }
                        helperText={
                          formik.touched.INFONAVIT &&
                          Boolean(formik.errors.INFONAVIT) &&
                          "El campo es requerido, si no tiene crédito de INFONAVIT, seleccione la opción de abajo"
                        }
                        disabled={isAvisoRetencion ? true : false}
                      />
                    </span>
                    {
                      errorInfonavit && (
                        <FormHelperText style={{color: 'red'}}>
                          Es necesario subir un archivo, si no cuenta con un crédito de INFONAVIT, seleccione la opción de abajo
                        </FormHelperText>
                      )
                    }
                  </div>
                </div>
              </>
            )}
            <br />
            <div className="">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isAvisoRetencion}
                    value={isAvisoRetencion}
                    onChange={() => setIsAvisoRetencion(!isAvisoRetencion)}
                    onBlur={formik.handleChange}
                    name="isAvisoRetencion"
                    color="primary"
                  />
                }
                label="Seleccione esta opción si no cuenta con el documento"
              />
            </div>
          </Grid>
          <Grid xs={6} item className="aviso-retencion">
            <Box ml={1}>
              {archives?.map(
                (archive: any, index: number) =>
                  archive?.name === "CartaGMM_PDF" && (
                    <div className="flex-container">
                      <div className="flex-child">
                        <label>
                          Carta de Antigüedad de Seguro de Gastos Médicos
                          Mayores
                        </label>
                        <span className="span-file">
                          En caso de contar con algún plan de la prestación de
                          SGMM, deberás entregar dentro del plazo de 30 días
                          posterior a fecha de baja)
                        </span>
                        <span className="span-file">
                          <a
                            className="view-document"
                            target="_blank"
                            rel="noreferrer"
                            href={archive.URL}
                          >
                            <img
                              style={{ height: "10px", marginTop: "5px" }}
                              src={EyeIcon}
                              alt=""
                            />
                            Ver documento
                          </a>
                        </span>
                      </div>

                      <div className="flex-child-2">
                        <FileOwn
                          name="CartaGMM_PDF"
                          accept=".pdf"
                          parametrofrom="collaborator"
                          idUsr={state.collaborator?.id}
                          className="image-file"
                          setArchives={setArchives}
                          archives={archives}
                          image={RemoveFile}
                          remove_file={true}
                          disabled={isCartaGMM ? true : false}
                          section="2"
                        />
                      </div>
                    </div>
                  )
              )}

              {!state.collaborator?.CartaGMM_PDF && (
                <div className="flex-container">
                  <div className="flex-child">
                    <label>
                      Carta de Antigüedad de Seguro de Gastos Médicos Mayores
                    </label>
                    <span className="span-file">
                      (En caso de contar con algún plan de la prestación de SGMM,
                      deberás entregar dentro del plazo de 30 días posterior a
                      fecha de baja)
                    </span>
                  </div>

                  <div className="flex-child-2">
                    <FileOwn
                      name="CartaGMM_PDF"
                      accept=".pdf"
                      parametrofrom="collaborator"
                      idUsr={state.collaborator?.id}
                      className="image-file"
                      setArchives={setArchives}
                      archives={archives}
                      disabled={isCartaGMM ? true : false}
                      section="2"
                    />
                  </div>
                </div>
              )}
              <br />
              {
                errorSecure && (
                  <FormHelperText style={{color: 'red'}}>
                    Es necesario subir un archivo, si no cuenta con un seguro anterior, seleccione la opción de abajo
                  </FormHelperText>
                )
              }
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isCartaGMM}
                    value={isCartaGMM}
                    onChange={() => setIsCartaGMM(!isCartaGMM)}
                    onBlur={formik.handleChange}
                    name="isCartaGMM"
                    color="primary"
                  />
                }
                label="Seleccione esta opción si no cuenta con el documento"
              />
            </Box>
          </Grid>
        </Grid>
      </div>

      <Grid
        direction="row"
        container
        justify="flex-end"
        alignItems="center"
        style={{ marginTop: "20px" }}
      >
        <Button type="submit" className="buttonSave">
          <SaveIcon />
          &nbsp; Guardar
        </Button>
      </Grid>
    </Form>
  );
};

export default OptionalDocumentsColaboradores;
