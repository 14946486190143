import ProcesoNomina from "../assets/onboarding/ProcesoNomina.gif";
import Calendario from "../assets/onboarding/Calendario.gif";
import GruposNomina from "../assets/onboarding/GruposNomina.gif";
import SeleccionColaboradores from "../assets/onboarding/SeleccionColaboradores.gif";
import IncidenciasColaboradores from "../assets/onboarding/IncidenciasColaboradores.gif";
import ProcesoDispersion from "../assets/onboarding/ProcesoDispersion.gif";
import styles from "../components/Payroll/PayrollStyles.module.css";
import { closeOnboarding } from "../context/PayrollProcess/Actions";
import { useContext } from "react";
import PayrollProcessContext from "../context/PayrollProcess/PayrollProcessContext";

export default function MySteps() {
  const { dispatch } = useContext(PayrollProcessContext);
  const steps = [
    {
      selector: "#payroll_1",
      content: ({ setIsOpen, setCurrentStep }: any) => (
        <>
          <div className="text-center align-item-center">
            <p className="onboard-title">
              <strong>Nueva pre-nómina</strong>
            </p>
            <p>Da clic para crear tu primera pre-nomina.</p>
            <button
              className={styles.pp_botonCrear}
              style={{ marginLeft: "10px", display: "initial" }}
              onClick={() => {
                setIsOpen(false);
                setCurrentStep(1);
              }}
            >
              Entendido
            </button>
          </div>
        </>
      ),
    },
    {
      selector: "#payroll_2",
      content: ({ setCurrentStep }: any) => (
        <div className="text-center">
          <img src={Calendario} style={{ width: "100%" }} alt="200" />
          <p className="onboard-title">
            <strong>Selección de periodo</strong>
          </p>
          <p>
            Elije mes y año o cambia de mes con los botones laterales. Elije el
            día de inicio y, sin solar el clic arrastra el cursor hasta el día
            final del periodo. El número de días debe coincidir con el periodo
            seleccionado.
          </p>
          <button
            className={styles.pp_botonCrear}
            style={{ marginLeft: "10px", display: "initial" }}
            onClick={() => setCurrentStep(2)}
          >
            Siguiente
          </button>
        </div>
      ),
    },
    {
      selector: "#payroll_3",
      content: ({ setCurrentStep, setIsOpen }: any) => (
        <div className="text-center">
          <img src={GruposNomina} style={{ width: "100%" }} alt="200" />
          <p className="onboard-title">
            <strong>Grupos de nómina</strong>
          </p>
          <p>
            Por medio de las flechas pon del lado derecho los grupos de nómina
            que quieras crear. Utiliza las flechas centrales para quitar o
            seleccionar todos los grupos.
          </p>
          <button
            className={styles.pp_botonCrear}
            style={{ marginLeft: "10px", display: "initial" }}
            onClick={() => {
              setIsOpen(false);
              setCurrentStep(3);
            }}
          >
            Entendido
          </button>
        </div>
      ),
    },
    {
      selector: "#payroll_4",
      content: ({ setCurrentStep }: any) => (
        <div className="text-center">
          <img src={ProcesoNomina} style={{ width: "70%" }} alt="200" />
          <p className="onboard-title">
            <strong>Indicador de progreso</strong>
          </p>
          <p>
            Te mostrará el número de nóminas y en qué parte del proceso se
            encuentran.
          </p>
          <button
            className={styles.pp_botonCrear}
            style={{ marginLeft: "10px", display: "initial" }}
            onClick={() => {
              setCurrentStep(4);
            }}
          >
            Siguiente
          </button>
        </div>
      ),
    },
    {
      selector: "#payroll_5",
      content: ({ setIsOpen, setCurrentStep }: any) => (
        <div className="text-center">
          <p className="onboard-title">
            <strong>Iniciar el proceso</strong>
          </p>
          <p>
            Da clic en "Continuar" para empezar el proceso de la nómina
            seleccionada.
          </p>
          <button
            className={styles.pp_botonCrear}
            style={{ marginLeft: "10px", display: "initial" }}
            onClick={() => {
              setIsOpen(false);
              setCurrentStep(5);
            }}
          >
            Entendido
          </button>
        </div>
      ),
    },
    {
      selector: "#payroll_6",
      content: ({ setIsOpen, setCurrentStep }: any) => (
        <div className="text-center">
          <p className="onboard-title">
            <strong>Agregar incidencia</strong>
          </p>
          <p>Da clic para agregar incidencia a uno o más colaboradores</p>
          <button
            className={styles.pp_botonCrear}
            style={{ marginLeft: "10px", display: "initial" }}
            onClick={() => {
              setIsOpen(false);
              setCurrentStep(6);
            }}
          >
            Entendido
          </button>
        </div>
      ),
    },
    {
      selector: "#payroll_7",
      content: ({ setCurrentStep }: any) => (
        <div className="text-center">
          <img
            src={SeleccionColaboradores}
            style={{ width: "70%" }}
            alt="200"
          />
          <p className="onboard-title">
            <strong>Selección de colaboradores</strong>
          </p>
          <p>
            Selecciona los colaboradores cuya nómina quieras dispesar. Puedes
            seleccionar todos con el cuadro superior.
          </p>
          <button
            className={styles.pp_botonCrear}
            style={{ marginLeft: "10px", display: "initial" }}
            onClick={() => {
              setCurrentStep(7);
            }}
          >
            Entendido
          </button>
        </div>
      ),
    },
    {
      selector: "#payroll_8",
      content: ({ setIsOpen, setCurrentStep }: any) => (
        <div className="text-center">
          <img
            src={IncidenciasColaboradores}
            style={{ width: "70%" }}
            alt="200"
          />
          <p className="onboard-title">
            <strong>Selección rápida</strong>
          </p>
          <p>
            Selecciona a todos los colaboradores por medio del signo de más y
            deseleccionarlos por medio del signo de menos.
          </p>
          <button
            className={styles.pp_botonCrear}
            style={{ marginLeft: "10px", display: "initial" }}
            onClick={() => {
              setIsOpen(false);
              setCurrentStep(8);
            }}
          >
            Entendido
          </button>
        </div>
      ),
    },
    {
      selector: "#payroll_9",
      content: ({ setCurrentStep }: any) => (
        <div className="text-center">
          <img src={ProcesoDispersion} style={{ width: "70%" }} alt="200" />
          <p className="onboard-title">
            <strong>Indicadores de progreso</strong>
          </p>
          <p>
            La nómina de cada colaborador mostrará dónde se encuentra en el
            proceso. En caso de que haya algún error podrás visualizarlo y
            reintentar la dispersión.
          </p>
          <button
            className={styles.pp_botonCrear}
            style={{ marginLeft: "10px", display: "initial" }}
            onClick={() => {
              setCurrentStep(9);
            }}
          >
            Entendido
          </button>
        </div>
      ),
    },
    {
      selector: "#payroll_10",
      content: ({ setIsOpen, setCurrentStep }: any) => (
        <div className="text-center">
          <p className="onboard-title">
            <strong>Ir al siguiente paso</strong>
          </p>
          <p>
            Los botones de ir al siguiente paso se habilitarán hasta que se haya
            completado el proceso actual.
          </p>
          <button
            className={styles.pp_botonCrear}
            style={{ marginLeft: "10px", display: "initial" }}
            onClick={() => {
              window.location.reload();
              setIsOpen(false);
              setCurrentStep(0);
              closeOnboarding(false, dispatch);
            }}
          >
            Entendido
          </button>
        </div>
      ),
    },
  ];
  return steps;
}
