import { useContext, useState } from "react";
import CalendarsContext from "../../../../context/NewCalendarContext/CalendarsContext";
import { clearCalendarsModal } from "../../../../context/NewCalendarContext/Actions";
import { Dialog, DialogContent, TextField  } from "@material-ui/core"
import { Formik, Form} from "formik";
import styles from '../../../../components/NewCalendar/Calendars.module.css';
import * as Yup from "yup";
import { useMutation } from "@apollo/client";
import { 
    CREATE_NON_WORKING,
    GET_COUNT_EVENT_NONWORKINGDAY
} from "../../../../Querys/querys";
import { SuccessfulAlert } from "../../../../alerts/successAlerts";
import { addDays } from 'date-fns';
import { subMonths } from 'date-fns';
import { DateRange } from 'react-date-range';
import { es } from 'date-fns/locale';
import DateRangeField from "../../../MyMood/Fields/DateRangeField";

const CreateNonWorkingDayCalendarModal = () => {

    const {state, dispatch} = useContext(CalendarsContext)
    const [showCalendario, setShowCalendario] = useState(false);
    const [stateCalendario, setStateCalendario] = useState([
        {
          startDate: subMonths(new Date(), 0),
          endDate: addDays(new Date(), 0),
          key: "selection"
        }]
      );
    const [createNewNonWorkingDay] = useMutation(CREATE_NON_WORKING, {
        refetchQueries:[{query:GET_COUNT_EVENT_NONWORKINGDAY}],
    })

    const handleClose = ()=>{
        clearCalendarsModal({}, dispatch);
    }
    const initialValues = () => {
        return {
            name: ""
        }
    }
    const toggleCalendar = () => {
        if(showCalendario === false){
          setShowCalendario(true);
        }
    }
    const rangeSelection = (selection: any)=>{
        setStateCalendario(selection);
        setShowCalendario(!showCalendario);
    }
  return(
    <Dialog open={state.createIncidentModal} aria-labelledby="form-dialog-title" maxWidth="sm" fullWidth={true}>
        <div className={styles.dialogContainer}>
            <div className={styles.containerTitle}>
                <div className={styles.centerContainer}
                style={{
                    marginLeft:'auto',
                    marginRight:'auto'
                }}
                >
                    <h2 id="form-dialog-title" data-testid="TitleModal">Agregar día inhábil</h2>
                    
                </div>
                <div className={styles.contenedorCerrarModal}>
                    <div className={styles.iconoCerrarModal} onClick={(()=>handleClose())}></div>      
                </div> 
            </div>
            <DialogContent>
            <Formik
            initialValues={initialValues()}
            validationSchema={validationSchema}
            onSubmit={formData => {
                createNewNonWorkingDay({
                    variables: {
                        input: {
                            name: formData.name,
                            calendarId: parseInt(state._id),
                            initDate: stateCalendario[0].startDate,
                            endDate: stateCalendario[0].endDate
                        },
                    },
                }).then(()=>{
                    SuccessfulAlert({text:"Se agregó día inhábil"});
                });
                handleClose();
            }}
        >
            {({
                values,
                errors,
                touched,
                handleChange,
                handleSubmit,
            }) => (
            <Form onSubmit = {handleSubmit}>
                <TextField 
                    fullWidth 
                    className={styles.dialogFields} 
                    name="name" 
                    label="Título*" 
                    variant="outlined" 
                    value = {values.name}
                    onChange={handleChange}
                    error={touched.name && Boolean(errors.name)}
                    helperText = {touched.name && errors.name}
                    size="small" 
                    inputProps={{maxLength: 30}}
                    onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                    />
                <DateRangeField setDate={setStateCalendario} containerClass={"fieldsetInput"}></DateRangeField>
                <br></br>
                <div className={styles.centerContainer}>
                    <button className={styles.buttonCancelCalendar} onClick={() => handleClose()}>
                        <b className={styles.buttonCancelCalendarText}
                        style={{
                            textTransform: "capitalize"
                        }}
                        >Cancelar</b>
                    </button>
                    <div className={styles.conteinerSave}>
                        <button type="submit" className={styles.buttonSaveCalendar2}>
                        <b className={styles.buttonSaveCalendarText}
                        style={{
                            textTransform: "capitalize"
                        }}
                        >Crear
                        </b>
                        </button>
                    </div>
                        
                </div>

                </Form>
            )}
            </Formik>
            </DialogContent>
        </div>
    </Dialog>
  )
}


// @ts-ignore
const validationSchema = Yup.object().shape({
        name: Yup.string().required("El título es requerido")
})
// @ts-ignore


export default CreateNonWorkingDayCalendarModal