import './style.css'

const CollaboratorIconText = (props: any) =>{
    const PrimeraLetra = props.Nombre?.charAt(0)
    const SegundaLetra = props.Apellido?.charAt(0)
    const width = props.width
    const height = props.height

    
    return(
        <div className="circulo_secundario colorBlue_secundario"
        style={{
            width: width,
            height: height
        }}
        >
            <span className='textoCirculo'>{PrimeraLetra}{SegundaLetra}</span>
        </div>
    )
}

export default CollaboratorIconText