import {
  IconButton,
  Tooltip,
  Menu,
  MenuItem,
  Grid,
} from "@material-ui/core";
import Box from '@mui/material/Box';
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import CollaboratorContext, {
  Types,
} from "../../../context/CollaboratorContext/CollaboratorContext";
import CheckPermission from "../../../helpers/Administradores/Permissions";
import { postMeilCandidate } from "../../../services/candidateService";
import { SuccessfulAlert } from "../../../alerts/successAlerts";
import ResendIcon from '../../../assets/svg/icono-sendemail.svg';

const MenuList = (props: any) => {

  const { dispatch } = useContext(CollaboratorContext);
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const open = Boolean(anchorEl);
  const history = useHistory();

  const enviarCorreo = async () => {
    
    await postMeilCandidate(props.email,props.token)

    SuccessfulAlert({text: "¡Correo enviado!"})
  };

  const openModalDecline = () => {
    dispatch({ type: Types.DECLINE_MODAL, payload: { _id: props.id } });
    setAnchorEl(null);
  };

  const openModalConvert = () => {
    dispatch({
      type: Types.CONVERT_MODAL,
      payload: {
        _id: `${props.id}/${props.dateOfAdmission}/${props.bussinesName}`,
      },
    });
    setAnchorEl(null);
  };

  return (
    <>
      <Tooltip title="Editar" placement="right">
        <Box className="IconButtonPoints" mr={2}>
          <IconButton onClick={(e) => setAnchorEl(e.currentTarget)}>
            <MoreVertIcon style={{ color: "#fabb00" }} />
          </IconButton>
        </Box>
      </Tooltip>
      <Menu
        className="MoreVerIcon"
        anchorEl={anchorEl}
        open={open}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        onClose={() => setAnchorEl(null)}
      >
        {CheckPermission("Colaboradores.NuevoIngreso.Convertir") && (
          <MenuItem
            divider
            button
            onClick={openModalConvert}
            disabled={props.done ? false : true}
          >
            Dar de alta&nbsp;
            <Grid container item justify="flex-end">
              <img src={`/assets/svg/icono-convertir.svg`} alt="Dar de alta" />
            </Grid>
          </MenuItem>
        )}

        {CheckPermission("Colaboradores.NuevoIngreso.Ver") && (
          <MenuItem
            button
            divider
            onClick={() => history.push(`/nuevoingreso/${props.id}/1`)}
          >
            Editar&nbsp;
            <Grid container item justify="flex-end">
            <img src="/assets/svg/icono-editar.svg" alt="Editar" />
            </Grid>
          </MenuItem>
        )}

        {CheckPermission("Colaboradores.NuevoIngreso.Declinar") && (
          <MenuItem button onClick={openModalDecline}>
            Declinar&nbsp;
            <Grid container item justify="flex-end">
              <img src="/assets/svg/icono-declinar.svg" alt="Declinar" />
            </Grid>
          </MenuItem>
        )}

        { (CheckPermission("Colaboradores.NuevoIngreso.Declinar") && props.token && !props.done) && (
          <MenuItem button onClick={() => { enviarCorreo() }} title="Reenviar correo electronico al colaborador">
            Reenviar&nbsp;
            <Grid container item justify="flex-end">
              <img src={ResendIcon} alt="Declinar" />
            </Grid>
          </MenuItem>
        )}

      </Menu>
    </>
  );
};

export default MenuList;
