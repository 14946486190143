import { Grid } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { GET_ALL_MYMOOD, GET_USER_COUNT, GET_TODAY_MYMOOD } from "../../../Querys/querys";
import { endOfDay, startOfDay } from "date-fns";

const Stadisticts = ({isOpen}:{isOpen:boolean}) => {

    const [checkMyMood, { data: resultMyMood }] = useLazyQuery(GET_ALL_MYMOOD, {
        fetchPolicy: 'no-cache'
    });
    const [checkUserCount, { data: resultUsers }] = useLazyQuery(GET_USER_COUNT, {
        fetchPolicy: 'no-cache'
    });
    const [checkTodayMood, { data: resultTodayMood }] = useLazyQuery(GET_TODAY_MYMOOD, {
        variables:{
            startDate: startOfDay(new Date()),
            endDate: endOfDay(new Date())
        },
        fetchPolicy: 'no-cache'
    });
    const [collaborators, setCollaborators] = useState(0);
    const [myMoods, setMyMoods] = useState(0);
    const [myMoodCurrent, setMyMoodCurrent] = useState(0);

    useEffect(()=>{
        if(isOpen === false){
            checkMyMood();
            checkUserCount();
            checkTodayMood();
        }
    },[isOpen]);

    useEffect(() => {
        if(resultMyMood && resultMyMood.GET_ALL_MYMOOD){
            setMyMoods( resultMyMood.GET_ALL_MYMOOD.length );
        }
    }, [resultMyMood]);

    useEffect(() => {
        if(resultUsers && resultUsers.GET_USER_COUNT && resultUsers.GET_USER_COUNT[0]){
            setCollaborators( resultUsers.GET_USER_COUNT[0].UsuarioTotal );
        }
    }, [resultUsers]);

    useEffect(() => {
        if(resultTodayMood && resultTodayMood.GET_TODAY_MYMOOD){
            setMyMoodCurrent( resultTodayMood.GET_TODAY_MYMOOD.length );
        }
    }, [resultTodayMood]);

    return (
        <Grid direction="row" container item className="Rectangle">
            <Grid xs item className="Stadistics">
                <div className="BorderRight">
                    <span id="text" className="color">Colaboradores</span>
                    <span id="number" className="color">{collaborators}</span>
                </div>
            </Grid>
            <Grid xs item className="Stadistics">
                <div className="BorderRight">
                    <span id="text" className="color">Estados Totales</span>
                    <span id="number" className="color">{myMoods}</span>
                </div>
            </Grid>
            <Grid xs item className="Stadistics">
                <div>
                    <span id="text" className="color">Estados de Hoy</span>
                    <span id="number" className="color">{myMoodCurrent}</span>
                </div>
            </Grid>
        </Grid>
    )
}

export default Stadisticts;