import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import List from "../List";
import AddressData from "./AddressData";
import IdentityData from "./IdentityData";
import InformationData from "./InformationData";
import AddressDataNuevoIngreso from "./Candidatos/AddressDataNuevoIngreso";
import IdentityDataNuevoIngreso from "./Candidatos/IdentityDataNuevoIngreso";
import InformationDataNuevoIngreso from "./Candidatos/InformationDataNuevoIngreso";
import AddressDataColaboradores from "./Colaboradores/AddressDataColaboradores";
import IdentityDataColaboradores from "./Colaboradores/IdentityDataColaboradores";
import InformationDataColaboradores from "./Colaboradores/InformationDataColaboradores";
import AddressDataInactivos from "./Inactivos/AddressDataInactivos";
import IdentityDataInactivos from "./Inactivos/IdentityDataInactivos";
import InformationDataInactivos from "./Inactivos/InformationDataInactivos";
import CheckPermission from "../../../helpers/Administradores/Permissions";

const PersonalData = (processProp?: any) => {
  let location = useLocation();
  let nuevoArrayEtiquetas = [];
  let nuevoArrayTablas = [];
  const [processTab, setProcessTab] = useState<any>();
  useEffect(() => {
    if (processProp) {
      setProcessTab(processProp?.process);
    }
  }, [processProp]);
  let section = location.pathname.split("/")[1];

  if (section === "collaborators-view") {
    if (
      CheckPermission(
        "Colaboradores.Inactivos.Ver.DatosPersonales.DatosDomicilio"
      )
    ) {
      nuevoArrayEtiquetas.push("Datos del domicilio");
      nuevoArrayTablas.push(AddressDataInactivos);
    }
    if (
      CheckPermission(
        "Colaboradores.Inactivos.Ver.DatosPersonales.DatosPersonales"
      )
    ) {
      nuevoArrayEtiquetas.push("Datos personales");
      nuevoArrayTablas.push(InformationDataInactivos);
    }
    if (
      CheckPermission(
        "Colaboradores.Inactivos.Ver.DatosPersonales.DatosIdentidad"
      )
    ) {
      nuevoArrayEtiquetas.push("Datos de Identidad");
      nuevoArrayTablas.push(IdentityDataInactivos);
    }
  }


  if (section === "collaborators") {
    if (
      CheckPermission(
        "Colaboradores.Colaboradores.Editar.DatosPersonales.DatosDomicilio.Ver"
      )
    ) {
      nuevoArrayEtiquetas.push("Datos del domicilio");
      nuevoArrayTablas.push(AddressData);
    }
    if (
      CheckPermission(
        "Colaboradores.Colaboradores.Editar.DatosPersonales.DatosPersonales.Ver"
      )
    ) {
      nuevoArrayEtiquetas.push("Datos personales");
      nuevoArrayTablas.push(InformationData);
    }

    if (
      CheckPermission(
        "Colaboradores.Colaboradores.Editar.DatosPersonales.DatosIdentidad.Ver"
      )
    ) {
      nuevoArrayEtiquetas.push("Datos de Identidad");
      nuevoArrayTablas.push(IdentityData);
    }
  }

  if (section === "nuevoingreso") {
    if (
      CheckPermission(
        "Colaboradores.NuevoIngreso.Ver.DatosPersonales.DatosDomicilio"
      )
    ) {
      nuevoArrayEtiquetas.push("Datos del domicilio");
      nuevoArrayTablas.push(AddressDataNuevoIngreso);
    }

    if (
      CheckPermission(
        "Colaboradores.NuevoIngreso.Ver.DatosPersonales.DatosPersonales"
      )
    ) {
      nuevoArrayEtiquetas.push("Datos personales");
      nuevoArrayTablas.push(InformationDataNuevoIngreso);
    }
    if (
      CheckPermission(
        "Colaboradores.NuevoIngreso.Ver.DatosPersonales.DatosIdentidad"
      )
    ) {
      nuevoArrayEtiquetas.push("Datos de Identidad");
      nuevoArrayTablas.push(IdentityDataNuevoIngreso);
    }
  }

  if (section === "inactivos") {
    if (
      CheckPermission(
        "Colaboradores.Inactivos.Ver.DatosPersonales.DatosDomicilio"
      )
    ) {
      nuevoArrayEtiquetas.push("Datos del domicilio");
      nuevoArrayTablas.push(AddressDataInactivos);
    }
    if (
      CheckPermission(
        "Colaboradores.Inactivos.Ver.DatosPersonales.DatosPersonales"
      )
    ) {
      nuevoArrayEtiquetas.push("Datos personales");
      nuevoArrayTablas.push(InformationDataInactivos);
    }
    if (
      CheckPermission(
        "Colaboradores.Inactivos.Ver.DatosPersonales.DatosIdentidad"
      )
    ) {
      nuevoArrayEtiquetas.push("Datos de Identidad");
      nuevoArrayTablas.push(IdentityDataInactivos);
    }
  }

  if (section === "candidate") {
    nuevoArrayEtiquetas.push("Datos del domicilio");
    nuevoArrayTablas.push(AddressDataColaboradores);
    nuevoArrayEtiquetas.push("Datos personales");
    nuevoArrayTablas.push(InformationDataColaboradores);
    nuevoArrayEtiquetas.push("Datos de Identidad");
    nuevoArrayTablas.push(IdentityDataColaboradores);
  }

  return (
    <List
      title="DATOS PERSONALES"
      labels={nuevoArrayEtiquetas}
      components={nuevoArrayTablas}
      numTab={3}
      progress={1}
      process={processTab}
    />
  );
};

export default PersonalData;
