import {useReducer} from 'react'
import CalendlyContext, {initialState}  from './CalendlyContext'
import  CalendlyReduce  from './CalendlyReduce'

const CalendlyState = (props:any) => {
  const [state, dispatch] = useReducer(CalendlyReduce, initialState)
  return(
    <CalendlyContext.Provider value={{state, dispatch}}>
      {props.children}
    </CalendlyContext.Provider>
  )
}

export default CalendlyState