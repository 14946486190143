

const TreeView = () => {
  return (
    <div className='selectoresReport' >
      <div>You can use the overflow property when you want to have better control of the layout. 
        The overflow property specifies what happens if content overflows an element's box.</div>
      <div>selector arbol</div>
      <div>selector arbol</div>
      <div>selector arbol</div>
      <div>selector arbol</div>
      <div>selector arbol</div>
      <div>selector arbol</div>
      <div>selector arbol</div>
      <div>selector arbol</div>
      <div>selector arbol</div>
      <div>selector arbol</div>
      <div>selector arbol</div>
      <div>selector arbol</div>
      <div>selector arbol</div>
      <div>selector arbol</div>
      <div>selector arbol</div>
      <div>selector arbol</div>
      <div>selector arbol</div>
      <div>selector arbol</div>
      <div>selector arbol</div>
      <div>selector arbol</div>
      <div>selector arbol</div>
      <div>selector arbol</div>
      <div>selector arbol</div>
      <div>selector arbol</div>
      <div>selector arbol</div>
      <div>selector arbol</div>
      <div>selector arbol</div>
      <div>selector arbol</div>
      <div>selector arbol</div>
      <div>selector arbol</div>
      <div>selector arbol</div>
      <div>selector arbol</div>
      <div>selector arbol</div>
      <div>selector arbol</div>
    </div>
  )
}

export default TreeView
