import TableDynamic from "./TableDynamic"

const TableReportIndividual = ({report}:any) => {
  return (
    <>
      <TableDynamic report={report}/>
    </>
  )
}

export default TableReportIndividual
 