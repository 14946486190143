import { Fragment, useContext } from "react";
import { Grid, TextField, Tooltip } from "@material-ui/core";
import Box from '@mui/material/Box';
import CollaboratorContext, {
} from "../../../../context/CollaboratorContext/CollaboratorContext";
import { withStyles, Theme } from "@material-ui/core/styles";
import InputMask from "react-input-mask";

const HtmlTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    maxWidth: 1000,
    fontSize: theme.typography.pxToRem(14),
  },
}))(Tooltip);

const IdentityDataInactivos = () => {
  const { state } = useContext(CollaboratorContext);

  return (
    <div className="novalidate__border">
      <Grid direction="row" container spacing={2}>
        <Grid xs item direction="row" container alignItems="center">
          <Grid xs={11} item>
            <InputMask
              mask="99999999999"
              defaultValue={state.collaborator?.IMSS}
            >
              <TextField
                name="IMSS"
                label="NSS"
                variant="outlined"
                size="small"
                fullWidth={true}
                InputProps={{
                  readOnly: true,
                }}
                style={{
                  opacity: '0.5',
                }}
              />
            </InputMask>
          </Grid>
          <Grid xs={1} item>
            <Box ml={1}>
              <HtmlTooltip
                title={
                  <Fragment>
                    Si no estas asociado, tramita tu número en linea
                  </Fragment>
                }
              >
                <img
                  src="/assets/icons/PreguntaAzul.png"
                  alt="Question"
                  height="20"
                />
              </HtmlTooltip>
            </Box>
          </Grid>
        </Grid>
        <Grid xs item>
          <TextField
            name="CURP"
            defaultValue={state.collaborator?.CURP || ""}
            label="CURP"
            variant="outlined"
            size="small"
            fullWidth={true}
            InputProps={{
              readOnly: true,
            }}
            style={{
              opacity: '0.5',
            }}
          />
        </Grid>
      </Grid>
      <Grid direction="row" container spacing={2}>
        <Grid xs item>
          <TextField
            name="RFC"
            defaultValue={state.collaborator?.RFC || ""}
            label="RFC"
            variant="outlined"
            size="small"
            fullWidth={true}
            InputProps={{
              readOnly: true,
            }}
            style={{
              opacity: '0.5',
            }}
          />
        </Grid>
        <Grid xs item direction="row" container alignItems="center">
          <Grid xs={10} item>
            <TextField
              name="ClaveElectoralPasaporte"
              defaultValue={state.collaborator?.ClaveElectoralPasaporte || ""}
              label="Clave de elector o pasaporte"
              variant="outlined"
              size="small"
              fullWidth={true}
              InputProps={{
                readOnly: true,
              }}
              helperText={
                !state.collaborator?.ClaveElectoralPasaporte && "Obligatorio"
              }
              style={{
                opacity: '0.5',
              }}
            />
          </Grid>
          <Grid xs item>
            <Box ml={2}>
              <HtmlTooltip
                title={
                  <Fragment>
                    <img src="/assets/INE.png" alt="Question" height="500" />
                  </Fragment>
                }
              >
                <img
                  src="/assets/icons/PreguntaAzul.png"
                  alt="Question"
                  height="20"
                />
              </HtmlTooltip>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Grid direction="row" container spacing={2}>
        <Grid xs={6} item>
          <TextField
            name="ZCSAT"
            defaultValue={state.collaborator?.ZCSAT || ""}
            label="C.P. SAT"
            variant="outlined"
            size="small"
            fullWidth={true}
            InputProps={{
              readOnly: true,
            }}
            style={{
              opacity: '0.5',
            }}
          />
        </Grid>
      </Grid>

      <Box>
        <Grid direction="row" container spacing={2}>
          <Grid xs item direction="row" container alignItems="center">
            <Grid xs={11} item>
              <TextField
                type="number"
                name="workPermission"
                defaultValue={state.collaborator?.workPermission || ""}
                label="Permiso de trabajo(extranjeros)"
                variant="outlined"
                size="small"
                fullWidth={true}
                InputProps={{
                  readOnly: true,
                }}
                style={{
                  opacity: '0.5',
                }}
              />
            </Grid>
            <Grid xs={1} item>
              <Box ml={1}>
                <HtmlTooltip
                  title={
                    <Fragment>
                      <img
                        src="/assets/Permiso_De_Trabajo.png"
                        alt="Question"
                        height="500"
                      />
                    </Fragment>
                  }
                >
                  <img
                    src="/assets/icons/PreguntaAzul.png"
                    alt="Question"
                    height="20"
                  />
                </HtmlTooltip>
              </Box>
            </Grid>
          </Grid>
          <Grid xs item>
            <TextField
              type="number"
              name="creditoInfonavit"
              defaultValue={state.collaborator?.creditoInfonavit || ""}
              label="Crédito infonavit"
              variant="outlined"
              size="small"
              fullWidth={true}
              InputProps={{
                readOnly: true,
              }}
              style={{
                opacity: '0.5',
              }}
            />
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default IdentityDataInactivos;
