import React, { useEffect, useState } from "react";
import style from "../../screens/BitsScreen.module.css"
const FechaDashboard = () => {

  const [fechaActual, setFechaActual] = useState(new Date());

  useEffect(() => {
    const interval = setInterval(() => {
      setFechaActual(new Date());
    }, 1000);
    return () => clearInterval(interval);
  },[]);

  const formatoFecha = fechaActual.toLocaleDateString("es-ES", {
    weekday: "long",
    day: "numeric",
    month: "long",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
  });

  return(
    <div className={style.divazul}>
      <span className={style.textognral}>Hoy es</span>
      <br></br>
      <span className={style.textognralmayor}>
        {formatoFecha}
      </span>
    </div>
  )
};

export default FechaDashboard;