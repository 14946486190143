import { Route } from "react-router-dom";
import PayrollGroupState from "../../context/ConfigPayrollContext/PayrollGroupState";
import '../ConfigPayroll/PayrollGroup.css'
import PayrollEventualGroupTable from "./PayrollEventualGroupTable";

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
};

const PayrollEventualGroupTab = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            {...other}
        >
            {value === index && (
                <PayrollGroupState>
                    <Route
                        component={() => (
                        <PayrollEventualGroupTable {...props} />
                        )}
                    />
                </PayrollGroupState>
            )}
        </div>
    )
}

export default PayrollEventualGroupTab;
