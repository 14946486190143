import { Dispatch } from "react";
import { Types } from "./CalendlyContext";

export const openCreateCalendlyModal = ({createCalendly = true}:any, dispatch: Dispatch<any>): void =>{
    dispatch({
    
        type:Types.CREATE_CALENDLY,
        payload:{
            createCalendly
        }
      })
}

export const clearCalendlyModal = ({createCalendly = false}:any, dispatch: Dispatch<any>): void =>{
    dispatch({
    
        type:Types.CLEAR_CALENDLY,
        payload:{
            createCalendly
        }
      })
}
