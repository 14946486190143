import { Dispatch } from "react";
import { Types } from "./OutsourcersContext";

export const openCreateOutsourcerModal = ({createOutsourcers = true}:any, dispatch: Dispatch<any>): void =>{
  
  dispatch({
    
    type:Types.CREATE_OUTSOURCERS,
    payload:{
      createOutsourcers
    }
  })
}
  
export const openEditOutsourcerModal = ({_id = "", editOutsourcers = true}:any, dispatch: Dispatch<any>): void =>{
  
  dispatch({
    
    type:Types.CREATE_EDITOUTSOURCERS,
    payload:{
      _id,
      editOutsourcers
    }
  })
}

export const openDelteOutsourcerModal = ({_id = "", deleteOutsourcers = true}:any, dispatch: Dispatch<any>): void =>{
  
  dispatch({
    
    type:Types.CREATE_DELETEOUTSOURCERS,
    payload:{
      _id,
      deleteOutsourcers
    }
  })
}

export const clearOutsourcerModal = ({createOutsourcers = false, editOutsourcers = false, deleteOutsourcers = false, state}:any, dispatch: Dispatch<any>): void =>{
  dispatch({
    type:Types.CLEAR_OUTSOURCERS,
    payload:{
      state,
      createOutsourcers,
      editOutsourcers,
      deleteOutsourcers
    }
  })
}


