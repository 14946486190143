/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import { Grid } from "@material-ui/core";
import SearcherCard from "./components/SearcherCard";
import {
  GET_HISTORICAL_CAMPAIGNS
} from "../../Querys/campaigns/querys";
import Btnfilter from "./components/Btnfilter";
import FechaRango from "./modals/FechaRango"
import "./css/Campaigns.css"
import CreateDuplicateCampaigns from "./modals/CreateDuplicateCampaigns";
import HistoricalCard from "./HistoricalCard";

export interface HistoricalCampaign {
  id: number
  nameCampaign: string
  startCampaign: string
  endCampaign: string
  step: string
  campaignBond: string
  descriptionBond: string
  minObjectives: number
  maxObjectives: number
  minQualification: number
  descriptionCampaign: string
  active: string
  remandingDays: number
  expectedProgress: number
  createdObjectives: number
  finishedObjectives: number
  areas: {
    id: number
    area: string
  }[]
  sedes: {
    id: number
    sede: string
  }[]
  totalCollaborators: number
  realProgress: number
}

interface HistoricalCampaignQuery {
  GET_HISTORICAL_CAMPAIGNS: HistoricalCampaign[];
}

const HistoricalCampaigns = () => {
  const [campaignFilter, setcampaignFilter] = useState<HistoricalCampaign[]>([]);
  const [campaigns, setCampaigns] = useState<HistoricalCampaign[]>([]);
  const [campaign, setCampaign] = useState<HistoricalCampaign[]>([]);
  const { data: campaignsInfo, loading } = useQuery<HistoricalCampaignQuery>(GET_HISTORICAL_CAMPAIGNS);
  const [stepFilter, setStepFilter] = useState<HistoricalCampaign[]>([]);
  const [stepCampaignFilter, setCampaignStepFilter] = useState<HistoricalCampaign[]>([]);

  useEffect(() => {
    if (campaignsInfo && campaignsInfo.GET_HISTORICAL_CAMPAIGNS) {
      console.log(campaignsInfo.GET_HISTORICAL_CAMPAIGNS)
      setCampaigns(campaignsInfo.GET_HISTORICAL_CAMPAIGNS);
      setcampaignFilter(campaignsInfo.GET_HISTORICAL_CAMPAIGNS);
      setCampaign(campaignsInfo.GET_HISTORICAL_CAMPAIGNS);
      setStepFilter(campaignsInfo.GET_HISTORICAL_CAMPAIGNS);
      setCampaignStepFilter(campaignsInfo.GET_HISTORICAL_CAMPAIGNS);
    }
  }, [campaignsInfo]);

  useEffect(() => {
    if (campaignFilter.length > 0) {
      const orderFilter = [...campaignFilter]
      orderFilter.sort((a: any, b: any) => { return (a.id - b.id) })
      setCampaigns(orderFilter);
    }
    //  cambioStep()
  }, [campaignFilter]);

  // useEffect(()=>{
  //     cambioStep()  
  //     setCounter(stepFilter.length)
  // },[filtroEstado])

  // useEffect(() => {
  //   dispatch({ type: Types.CLEAR_OBJECTIVE_STATE });
  // }, []);

  return (
    <>
      {(loading === true) ?
        <div className="c_contenedorPrincipal">
          <div className="contenedorLoadingPrincipal">
            <div className="loadingPrincipal"></div>
          </div>
        </div>
        :
        <div className="c_contenedorPrincipal">
          <div className="c_contenedorTitulo">
            <h2 style={{
              fontFamily: "Roboto",
              fontSize: "24px",
              fontWeight: 700,
              lineHeight: "28px",
              letterSpacing: "0.6666666865348816px",
              textAlign: "left",
              marginLeft: "20px",
            }}>Históricas</h2>
          </div>
          <Grid container className="screenBtns">
            <Grid className="findCampaign">
              <SearcherCard
                initState={campaignsInfo?.GET_HISTORICAL_CAMPAIGNS as any}
                setState={setcampaignFilter}
                stateInitial={campaigns}
                setStepFilter={setCampaigns as any}
                stepCampaignFilter={campaignsInfo?.GET_HISTORICAL_CAMPAIGNS as any}
                label={"Buscar"}
                fields={['nameCampaign']}
                width={400}
              />
            </Grid>
            <Grid className="filtersCampaign">
              <Btnfilter
                title="Fecha"
                option="btnFecha"
                setState={setCampaigns}
                campaignFilter={campaignFilter}
                stateInitial={campaignsInfo?.GET_HISTORICAL_CAMPAIGNS as any}
                setStepFilter={setStepFilter}
              />
              <Btnfilter
                title="Sede"
                option="campus"
                setState={setCampaigns}
                campaignFilter={campaignFilter}
                stateInitial={campaignsInfo?.GET_HISTORICAL_CAMPAIGNS as any}
                setStepFilter={setStepFilter}
              />
              <Btnfilter
                title="Área"
                option="area"
                setState={setCampaigns}
                campaignFilter={campaignFilter}
                stateInitial={campaignsInfo?.GET_HISTORICAL_CAMPAIGNS as any}
                setStepFilter={setStepFilter}
              />
            </Grid>
          </Grid>
          <Grid container className="cardsCampaign" >
            <Grid container spacing={2}>
              {
                campaigns?.map((campaign: HistoricalCampaign) => (
                  <Grid item key={campaign.id}>
                    <HistoricalCard
                      campaign={campaign}
                    />
                  </Grid>
                ))
              }
            </Grid>
          </Grid>
        </div>
      }

      <FechaRango
        setState={setcampaignFilter}
        setStepFilter={setCampaigns}
        historicalCampaigns
      />
      <CreateDuplicateCampaigns />
    </>

  )
}

export default HistoricalCampaigns
