import { Collaborator } from '../../interfaces/TabCollaborator.interfaces';
import moment from 'moment';
moment.locale('es-mx');
const FechaIngresoField = ({ FechaIngreso }: Collaborator) => {
    const dateFormat = moment.utc(FechaIngreso).format('L')
    return (
        <div>
            <label>{FechaIngreso ? dateFormat : ''}</label>
        </div>
    )
}

export default FechaIngresoField


