import { useContext, useState } from "react";
import AdministratorsContext from '../../context/AdministratorsContext/AdministratorsContext'
import { updateAministratorsModal, deleteAdministratorsModal } from '../../context/AdministratorsContext/Actions'
import { Tooltip, IconButton, Menu, MenuItem } from "@material-ui/core";
import Box from '@mui/material/Box';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import styles from './Administrators.module.css'

export const MenuAdministrators = (props:any) =>{
  const {dispatch} = useContext(AdministratorsContext)
  const {_id:userAdminId} = props

  const [anchorEl, setAnchorEl] = useState<any>(null);
  const open = Boolean(anchorEl);
  const openMenu = (e: any): void => {
    setAnchorEl(e.currentTarget);
  };

  const updateModal = (id:string, updateModal: boolean,FullName:string, Usuario:string, img:string ) =>{
    
    setAnchorEl(null);
    updateAministratorsModal(parseInt(id), updateModal,FullName,Usuario,img ,dispatch)
  }

  const deleteModal = (id:string, deleteModal: boolean) =>{
    setAnchorEl(null);
    deleteAdministratorsModal({_id:id, deleteModal}, dispatch)
  }

  return(
    <>
      <Tooltip title="Editar" placement="right" >
          <Box className="IconButtonPoints" mr={2}>
              <IconButton onClick={openMenu}>
                  <MoreVertIcon style={{ color: "#fabb00" }} />
              </IconButton>
          </Box>
      </Tooltip>
        <Menu                
          className="MoreVerIcon"
          anchorEl={anchorEl}
          open={open}
          transformOrigin={{ vertical: "top", horizontal: "right" }}
          onClose={() => setAnchorEl(null)}>
            <MenuItem onClick={() => updateModal(props._id, false, props.FullName, props.Usuario,props.img)}>
              <div className={styles.contenedorTextoMenu}>
                <span className={styles.textoMenuEditar}>Editar</span>
              </div>
              <div>
                <img className={styles.iconoMenu} src="/assets/svg/icono-editar.svg" alt="Editar" />
              </div>
            </MenuItem>
            <MenuItem onClick={()=> deleteModal(userAdminId, true)}>
              <div className={styles.contenedorTextoMenu}>
                <span className={styles.textoMenuEliminar}>Eliminar</span>
              </div>
              <div>
                <img className={styles.iconoMenuEliminar} src="/assets/svg/icono-eliminar-admin.svg" alt="Eliminar" />
              </div>
            </MenuItem>
        </Menu>

    </>
  )
}