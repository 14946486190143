import { useContext, useEffect, useState } from "react";
import {
  FormControl,
  InputLabel,
  Select,
  TextField,
  Grid,
  Grow,
  Paper,
  FormHelperText,
  Button,
} from "@material-ui/core";
import Box from '@mui/material/Box';
import CollaboratorContext from "../../../../context/CollaboratorContext/CollaboratorContext";
import { WarningAlert } from "../../../../alerts/WarningAlert";
import { SuccessfulAlert } from "../../../../alerts/successAlerts";
import SaveIcon from "@material-ui/icons/Save";
import { useMutation, useQuery } from "@apollo/client";
import {
  GET_USERS_BY_ID,
  UPDATE_USERS,
  GET_ALL_BANK,
} from "../../../../Querys/querys";
import InputMask from "react-input-mask";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Form } from "semantic-ui-react";
import EyeIcon from "../../../../assets/svg/eye.svg";
import RemoveFile from "../../../../assets/svg/remove_file.svg";
import FileOwn from "../../Expedient/Fields/File_Helper_Own_Register";
import { useHistory } from "react-router-dom";
import { handleSetActiveStep } from "../../../../helpers/Collaborator/Collaborator";

const BankDataColaboradores = () => {
  const { state, dispatch } = useContext(CollaboratorContext);
  const [clabe, setClabe] = useState();
  const [badClabe, setBadClabe] = useState(false);
  const [clabeMask, setClabeMask] = useState("");
  const [accountMask, setAccountMask] = useState("");
  const [archives, setArchives] = useState([]);
  const [banks, setBanks] = useState<any>([]);
  const [updateState, setUpdateState] = useState(false);
  const history = useHistory();

  const [updateColaboradores] = useMutation(UPDATE_USERS, {
    refetchQueries: [
      {
        query: GET_USERS_BY_ID,
        variables: { getUsersId: state.collaborator.id },
      },
    ],
  });

  const { data: dataBank } = useQuery(GET_ALL_BANK);
  const bancos = dataBank?.GET_ALL_BANK;

  useEffect(() => {
    if (state.collaborator?.archive) {
      setArchives(state.collaborator.archive);
    }
  }, [state.collaborator]);

  useEffect(() => {
    if (bancos) {
      setBanks(bancos);
      if (state.collaborator?.id_Bank) {
        const result = bancos.filter(
          (object: any) => Number(state.collaborator?.id_Bank) === Number(object.id)
        );
        setClabe(result[0]?.clabe);
      }
    }
  }, [bancos, state.collaborator]);

  useEffect(() => {
    if(updateState) setUpdateState(false)
  }, [updateState])

  const handleChange = async (e: any) => {
    // await updateData(e, state, dispatch, 0);
  };

  const onHandleBank = async (e: any) => {
    const result = await banks.filter(
      (object: any) => e.target.value === object.id
    );
    setClabe(result[0]?.clabe);

  };

  const onHandleClabe = async (e: any) => {
    if (e.target.value.length >= 3) {
      //e.target.value = e.target.value.substring(0, e.target.value.length -1 );
      if (e.target.value.substr(0, 3) !== clabe) {
        setBadClabe(true);
        return await WarningAlert({
          text: "El formato de tu clabe no es la correcta.",
        });
      } else {
        setBadClabe(false);
      }
    }
  };

  const initialValues:any = () => {
    return {
      bank: state.collaborator?.bank ?? "",
      accountNumber: state.collaborator?.accountNumber ?? "",
      clabeNum: state.collaborator?.clabeNum ?? "",
      id_Bank: state.collaborator?.id_Bank ?? "",
    };
  };

  const checkBankLimit = () => {
    const bankData = banks.filter((bank:any)=>{
      return Number(bank.id) === Number(state.collaborator?.id_Bank);
    });
    return bankData && bankData[0] && bankData[0].name.toLowerCase() == 'azteca' ? 14 : 12;
  }

  const formik = useFormik({
    initialValues: initialValues(),
    validationSchema: Yup.object(validationSchema()),

    onSubmit: async (formData) => {
      if (badClabe) {
        return await WarningAlert({
          text: "El formato de tu clabe no es la correcta.",
        });
      }

      if (!state.collaborator?.CuentaBancaria_PDF) {
        return await WarningAlert({
          text: "Debes subir un archivo de cuenta bancaria.",
        });
      }

      const bank = bancos.find((p: any) => {
          return p.id === formData.id_Bank;
      });
      formData.id_Bank = parseInt(formData.id_Bank);
      formData.bank = bank.name;

      updateColaboradores({
        variables: {
          updateUsersId: state.collaborator?.id,
          input: formData,
          process: {
            tab: "payData",
            section: "section_1",
          },
        },
      }).then((res) => {
        SuccessfulAlert({ text: "Se actualizó correctamente" }).then(() => {
          history.push("5");
          handleSetActiveStep(0, dispatch);
        });
      });
    },
  });

  return (
    <Form onSubmit={formik.handleSubmit}>
      <Grow in={true}>
        <Paper>
          <div className="novalidate__border">
            <Box mt={2} mb={2}>
              {/* { (reCheck == "true" || reCheck == null) && */}
              <FormControl variant="outlined" fullWidth={true} size="small">
                <InputLabel htmlFor="outlined-age-native-simple">
                  Banco
                </InputLabel>
                <Select
                  native
                  onBlur={formik.handleChange}
                  onChange={onHandleBank}
                  label={"Banco"}
                  name="id_Bank"
                  defaultValue={state.collaborator?.id_Bank || ""}
                  autoFocus={true}
                  error={formik.touched.id_Bank && Boolean(formik.errors.id_Bank)}
                >
                  <option
                    value={state.collaborator?.id_Bank || ""}
                    disabled={true}
                  >
                    {state.collaborator?.bank || ""}
                  </option>
                  {banks.map(({ id,name }: any, index: number) => (
                    <option key={index} value={id}>
                      {name}
                    </option>
                  ))}
                </Select>
                <FormHelperText style={{color: "red"}}>
                  {formik.touched.id_Bank && formik.errors.id_Bank}
                </FormHelperText>
              </FormControl>
            </Box>

            <Box mt={2} mb={2}>
              {/* { (reCheck == "true" || reCheck == null) && */}

              <TextField
                name="accountNumber"
                label="Número de cuenta bancaria"
                variant="outlined"
                size="small"
                fullWidth={true}
                error={formik.touched.accountNumber && Boolean(formik.errors.accountNumber)}
                helperText={formik.touched.accountNumber && formik.errors.accountNumber}
                defaultValue={state.collaborator?.accountNumber || accountMask}
                value={state.collaborator?.accountNumber || accountMask}
                onChange={(e) => {
                  setAccountMask(e.target.value);
                  formik.handleChange(e);
                  handleChange(e);
                }}
                disabled={false}
                onBlur={formik.handleChange}
                onKeyPress={(event: any) => {
                  if (!/^[0-9.]+$/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                inputProps={{
                  maxLength: checkBankLimit(),
                  minLength: 9,
                }}
              />
            </Box>

            <Box mt={2} mb={2}>
              {/* { (reCheck == "true" || reCheck == null) && */}

              <InputMask
                mask="999999999999999999"
                defaultValue={state.collaborator?.clabeNum || clabeMask}
                onChange={(e) => {
                  setClabeMask(e.target.value);
                  formik.handleChange(e);
                  handleChange(e);
                }}
                disabled={false}
                onBlur={(e) => {
                  onHandleClabe(e);
                }}
              >
                <TextField
                  name="clabeNum"
                  label="Número de clabe bancaria"
                  variant="outlined"
                  size="small"
                  fullWidth={true}
                  error={formik.touched.clabeNum && Boolean(formik.errors.clabeNum)}
                  helperText={formik.touched.clabeNum && formik.errors.clabeNum}
                />
              </InputMask>
            </Box>

            <Grid direction="row" container>
              <Grid xs item direction="row" container justify="flex-end">
                {/*DOCUMENTO OFICIAL DEL BANCO  */}

                <>
                  {archives?.map(
                    (archive: any, index: number) =>
                      archive?.name === "CuentaBancaria_PDF" && (
                        <div className="flex-container">
                          <div className="flex-child">
                            <label>Subir documento Oficial del Banco</label>
                            <span className="span-file">
                              <a
                                className="view-document"
                                target="_blank"
                                rel="noreferrer" 
                                href={archive.URL}
                              >
                                <img
                                  style={{ height: "10px", marginTop: "5px" }}
                                  src={EyeIcon}
                                  alt=""
                                />
                                Ver documento
                              </a>
                            </span>
                          </div>

                          <div className="flex-child-2">
                            <FileOwn
                              name="CuentaBancaria_PDF"
                              accept=".pdf"
                              parametrofrom="collaborator"
                              idUsr={state.collaborator?.id}
                              className="image-file"
                              setArchives={setArchives}
                              archives={archives}
                              image={RemoveFile}
                              remove_file={true}
                              setUpdateState={setUpdateState}
                              updateSate={updateState}
                            />
                          </div>
                        </div>
                      )
                  )}

                  {!state.collaborator?.CuentaBancaria_PDF && (
                    <div className="flex-container">
                      <div className="flex-child">
                        <label>Subir documento Oficial del Banco</label>
                      </div>

                      <div className="flex-child-2">
                        <FileOwn
                          name="CuentaBancaria_PDF"
                          accept=".pdf"
                          parametrofrom="collaborator"
                          idUsr={state.collaborator?.id}
                          className="image-file"
                          setArchives={setArchives}
                          archives={archives}
                        />
                      </div>
                    </div>
                  )}
                </>
              </Grid>
            </Grid>
          </div>
        </Paper>
      </Grow>
      <div></div>

      <Grid
        direction="row"
        container
        justify="flex-end"
        alignItems="center"
        style={{ marginTop: "20px" }}
      >
        <Button type="submit" className="buttonSave">
          <SaveIcon />
          &nbsp; Guardar
        </Button>
      </Grid>
    </Form>
  );
};

const validationSchema = () => {
  return {
    id_Bank: Yup.string().required("Obligatorio"),
    accountNumber: Yup.string().min(9, "Debe contener al menos 9 digitos").required("Obligatorio"),
    clabeNum: Yup.string().min(18).required("Obligatorio").test(
      "check_clabe",
      "El formato de la clabe no es correcta",
      function (clabeNum: string | undefined): boolean {
        console.log(clabeNum, clabeNum?.includes('_'));
        return clabeNum?.includes('_') ? false : true
      }
    ),
  };
};

export default BankDataColaboradores;
