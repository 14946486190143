import { useContext, useState, useEffect, useRef } from "react";
import EmpresaContext from "../../../context/Empresa/EmpresaContext";
import {
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
} from "@material-ui/core";
import Box from '@mui/material/Box';
import { clearEmpresaModal } from "../../../context/Empresa/Actions";
import styles from "../../../styles/MiEmpresa/CrearProyecto2.module.css";
import {
  UPDATE_PROJECT,
  GET_ALL_PROJECT,
  GET_PROJECT,
  GET_ALL_PUESTOS_USERS_PROJECT
} from "../../../Querys/querys";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { SuccessfulAlert } from "../../../alerts/successAlerts";
import { Button, TextField } from "@material-ui/core";

const EditaProjects2 = () => {
  const { state, dispatch } = useContext(EmpresaContext);

  const [createNewProyect] = useMutation(UPDATE_PROJECT, {
    refetchQueries: [
      { query: GET_ALL_PROJECT },
      { query: GET_PROJECT, variables: { id: state._id } },
      { query: GET_ALL_PUESTOS_USERS_PROJECT}
    ],
  });

  const [getProject, { data }] = useLazyQuery(GET_PROJECT, {
    fetchPolicy: 'no-cache'
  });

  const [project, setProject]:any = useState({});

  useEffect(()=>{
    if(state.updateProject){
      getProject({
        variables: {
          id: state._id,
        }
      })
    }
  },[state.updateProject]);

  useEffect(()=>{
    if(data && data.GET_PROJECT){
      setProject(data.GET_PROJECT);
    }
  },[data]);

  const initalValues = () => {
    return {
      nombre: project?.proyectName,
      cliente: project?.client,
    };
  };

  const handleClose = () => {
    clearEmpresaModal({}, dispatch);
  };

  const formikRef:any = useRef();


  useEffect(()=>{
    if(formikRef.current){
      formikRef.current.setFieldValue('nombre', project?.proyectName);
      formikRef.current.setFieldValue('cliente', project?.client);
    }
  },[project])

  return (
    <Dialog
      open={state.updateProject}
      aria-labelledby="form-dialog-title"
      onClose={handleClose}
      maxWidth={"md"}
    >
      <Formik
        initialValues={initalValues()}
        innerRef={formikRef}
        validationSchema={Yup.object(validationSchema())}
        onSubmit={(formData) => {
          createNewProyect({
            variables: {
              updateProjectId: state._id,
              input: {
                proyectName: formData.nombre,
                client: formData.cliente,
              },
            },
          }).then(() => {
            handleClose();
            SuccessfulAlert({
              title: "¡Exito!",
              text: "¡Registro editado!",
            });
          });
        }}
      >
        {({ values, errors, touched, handleChange, handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <h3 id="form-dialog-title" className="text-center">
              Editar &Aacute;rea o Proyecto
            </h3>
            <DialogContent dividers>
              <Box display="flex" flexDirection="column">
                <Box mb={3}>
                  <Grid container spacing={1}>
                    <Grid container item xs={12} spacing={3}>
                      <Grid item xs={12} style={{ marginBottom: "10px" }}>
                        <TextField
                          fullWidth
                          className={styles.input}
                          name="nombre"
                          label="Nombre"
                          variant="outlined"
                          value={values.nombre || ''}
                          onChange={handleChange}
                          error={touched.nombre && Boolean(errors.nombre)}
                          helperText={touched.nombre && errors.nombre}
                          size="small"
                          inputProps={{ maxLength: 50 }}
                          onKeyPress={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          className={styles.input}
                          name="cliente"
                          label="Sub &Aacute;rea / Sub Proyecto"
                          variant="outlined"
                          value={values.cliente || ''}
                          onChange={handleChange}
                          error={touched.cliente && Boolean(errors.cliente)}
                          helperText={touched.cliente && errors.cliente}
                          size="small"
                          inputProps={{ maxLength: 50 }}
                          onKeyPress={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </DialogContent>
            <DialogActions style={{ justifyContent: "center" }}>
              <Button autoFocus onClick={handleClose} className="buttonCancel">
                Cerrar
              </Button>
              <Button autoFocus type="submit" className="buttonSave">
                Guardar
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

const validationSchema = () => {
  return {
    nombre: Yup.string().required("El nombre es requerido"),
  };
};

export default EditaProjects2;
