import { useContext, useState, useEffect } from "react";
import {
  FormControl,
  Grid,
  InputLabel,
  Select,
  TextField,
} from "@material-ui/core";
import Box from '@mui/material/Box';
import CollaboratorContext from "../../../../context/CollaboratorContext/CollaboratorContext";
import { paisesEstados } from "../../../../helpers/Json/paises-estados";
import {
  getMunicipios,
  getStates,
} from "../../../../helpers/Json/getStatesAndMunicipios";
import moment from "moment";
import InputMask from "react-input-mask";

const BeneficiarioInactivos = () => {
  const { state } = useContext(CollaboratorContext);
  const [states, setState] = useState<Array<string>>([]);
  const [municipios, setMunicipios] = useState<Array<string>>([]);
  const [estado, setEstado] = useState<string>("");
  const cpMask = "";

  useEffect(() => {
    if (state.collaborator?.benefitiaryCountry) {
      setState(getStates(state.collaborator?.benefitiaryCountry));
    }
    if (state.collaborator?.benefitiaryState) {
      setMunicipios(getMunicipios(state.collaborator?.benefitiaryState));
    }
    if (state.collaborator) {
      if (state.collaborator.benefitiaryState) {
        setEstado(state.collaborator.benefitiaryState);
      }
    }
  }, [state.collaborator]);

  return (
    <>
      <div className="novalidate__border">
        <Grid direction="row" container spacing={2}>
          <Grid xs item>
            <TextField
              id="benefitiary"
              name="benefitiary"
              defaultValue={state.collaborator?.benefitiary}
              autoFocus={true}
              label="Nombre del beneficiario del seguro"
              variant="outlined"
              size="small"
              fullWidth={true}
              InputProps={{
                readOnly: true,
              }}
              helperText={!state.collaborator?.benefitiary && "Obligatorio"}
              style={{
                opacity: '0.5',
              }}
            />
          </Grid>
          <Grid xs item>
            <FormControl variant="outlined" fullWidth={true} size="small">
              <InputLabel htmlFor="outlined-age-native-simple"
              
              style={{
                opacity: '0.5',
              }}
              >
                Parentesco
              </InputLabel>
              <Select
                native
                label={"Parentesco"}
                name="ParentescoB"
                defaultValue={state.collaborator?.ParentescoB || ""}
                autoFocus={true}
                disabled={true}
              >
                <option
                  value={state.collaborator?.ParentescoB || ""}
                  disabled={true}
                >
                  {state.collaborator?.ParentescoB || ""}
                </option>
                <option value="Madre">Madre</option>
                <option value="Padre">Padre</option>
                <option value="Hijo(a)">Hijo(a)</option>
                <option value="Esposo(a)">Esposo(a)</option>
                <option value="Concubino(a)">Concubino(a)</option>
                <option value="Hermano(a)">Hermano(a)</option>
                <option value="Novio">Novio</option>
                <option value="Novia">Novia</option>
                <option value="Amigo(a)">Amigo(a)</option>
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        <Box mt={2}>
          <Grid
            direction="row"
            container
            justify="flex-start"
            alignItems="center"
          >
            <Grid xs item>
              <span className="Fecha-de-nacimiento">Fecha de nacimiento</span>
            </Grid>
            <Grid xs item container justify="flex-end" alignItems="center">
              <TextField
                type="date"
                defaultValue={
                  moment(state.collaborator?.benefitiaryDateOfBirth).format(
                    "YYYY-MM-DD"
                  ) ?? ""
                }
                name="benefitiaryDateOfBirth"
                variant="outlined"
                size="small"
                InputProps={{
                  readOnly: true,
                  inputProps: {
                    max: moment().format("YYYY-MM-DD"),
                  },
                }}
                style={{
                  opacity: '0.5',
                }}
              />
            </Grid>
          </Grid>
        </Box>

        <Box mt={2}>
          <Grid direction="row" container spacing={2}>
            <Grid xs item>
              <TextField
                name="benefitiaryCURP"
                defaultValue={state.collaborator?.benefitiaryCURP}
                label="CURP"
                variant="outlined"
                size="small"
                fullWidth={true}
                InputProps={{
                  readOnly: true,
                }}
                style={{
                  opacity: '0.5',
                }}
              />
            </Grid>
            <Grid xs item>
              <InputMask
                mask="99999"
                defaultValue={state.collaborator?.benefitiaryZC || cpMask}
                disabled={false}
              >
                <TextField
                  name="benefitiaryZC"
                  label="Código postal"
                  variant="outlined"
                  size="small"
                  fullWidth={true}
                  InputProps={{
                  readOnly: true,
                  }}
                  style={{
                    opacity: '0.5',
                  }}
                />
              </InputMask>
            </Grid>
          </Grid>
        </Box>

        <Box mt={2} mb={2}>
          <TextField
            name="benefitiaryAddress"
            defaultValue={state.collaborator?.benefitiaryAddress}
            autoFocus={true}
            label="Dirección, calle y número"
            variant="outlined"
            size="small"
            fullWidth={true}
            InputProps={{
              readOnly: true,
            }}
            style={{
              opacity: '0.5',
            }}
          />
        </Box>

        <Grid direction="row" container spacing={2}>
          <Grid xs item>
            <TextField
              name="benefitiarySuburb"
              defaultValue={state.collaborator?.benefitiarySuburb}
              autoFocus={true}
              label="Colonia"
              variant="outlined"
              size="small"
              fullWidth={true}
              InputProps={{
                readOnly: true,
              }}
              style={{
                opacity: '0.5',
              }}
            />
          </Grid>
          <Grid xs item>
            <FormControl variant="outlined" fullWidth={true} size="small">
              <InputLabel htmlFor="outlined-age-native-simple"
              style={{
                opacity: '0.5',
              }}
              >País</InputLabel>
              <Select
                native
                label={"País"}
                name="benefitiaryCountry"
                defaultValue={state.collaborator?.benefitiaryCountry || ""}
                autoFocus={true}
                disabled={true}
              >
                <option
                  value={state.collaborator?.benefitiaryCountry || ""}
                  disabled={true}
                >
                  {state.collaborator?.benefitiaryCountry || ""}
                </option>
                {paisesEstados.map(({ country }: any, index: number) => (
                  <option key={index} value={country}>
                    {country}
                  </option>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        <Box mt={2}>
          <Grid direction="row" container spacing={2}>
            <Grid xs item>
              <FormControl variant="outlined" fullWidth={true} size="small">
                <InputLabel htmlFor="outlined-age-native-simple"
                style={{
                  opacity: '0.5',
                }}
                >
                  Estado
                </InputLabel>
                <Select
                  native
                  disabled={true}
                  label={"Estado"}
                  name="benefitiaryState"
                  value={estado}
                >
                  <option
                    value={state.collaborator?.benefitiaryState || ""}
                    disabled={true}
                  >
                    {state.collaborator?.benefitiaryState || ""}
                  </option>
                  {states.map((state: string, index: number) => (
                    <option key={index} value={`${state}`}>
                      {state}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid xs item>
              <FormControl variant="outlined" fullWidth={true} size="small">
                <InputLabel htmlFor="outlined-age-native-simple"
                style={{
                  opacity: '0.5',
                }}
                >
                  Municipio
                </InputLabel>
                <Select
                  native
                  disabled={true}
                  label={"Municipio"}
                  name="benefitiaryMunicipality"
                  defaultValue={
                    state.collaborator?.benefitiaryMunicipality || ""
                  }
                  autoFocus={true}
                >
                  <option
                    value={state.collaborator?.benefitiaryMunicipality || ""}
                    disabled={true}
                  >
                    {state.collaborator?.benefitiaryMunicipality || ""}
                  </option>
                  {municipios?.map((municipio: any, index: number) => (
                    <option key={index} value={municipio}>
                      {municipio}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Box>
      </div>
      <div></div>
    </>
  );
};

export default BeneficiarioInactivos;
