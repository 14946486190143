export const RemoveSpaces = (field:string) =>{
    const fieldSplit:any = field.split(' ')
    const arrayField:any = [];
    fieldSplit.forEach( (element: string) => {
      if(element !==''){
        arrayField.push(element)
      }
    });    
    const fieldJoin =arrayField.join(' ');
    return fieldJoin;
}