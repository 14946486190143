import style from '../../styles/Button.module.css'

interface ButtonProps {
  text: string;
  icon?: string;
  func: any;
  disabled?: boolean;
}

const Button = ({ text, icon, func, disabled = false }: ButtonProps) => {
  return (
    <button
      onClick={func}
      type="button"
      className={`${style.button} ${disabled ? style.disabled : ''}`}
      disabled={disabled}>
      {icon && (
        <img src={icon} alt={text} className={style.img} />
      )}
      <span className={style.text}>{text}</span>
    </button>
  )
}
export default Button