import { useState, useContext, useEffect } from "react";
import { IconButton, Menu, MenuItem, Tooltip } from "@material-ui/core";
import Box from '@mui/material/Box';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import styles from '../../styles/Header/headerStyles.module.css'
import { useHistory } from "react-router-dom"
import {useMutation} from "@apollo/client"
import { DELETE_NOTIFICATION} from "../../Querys/querys";
import { AdminContext } from "../../context/AdminContext/AdminContext";

const MenuListSecundario = ({Desde, idNotificacion, toggleList, idRegistro, collaborator, payrollGroup, eventualGroup}:any) => {
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const open = Boolean(anchorEl);
  const history = useHistory();
  const { adminState } = useContext(AdminContext);
  const [deleteNotification, {data:deleteResponse}] = useMutation(DELETE_NOTIFICATION);

  let ruta = ""

  if(Desde === "AtencionAzul"){
    ruta = '/payroll'
  }

  const eliminaNotificacion = () =>{
    deleteNotification({
      variables:{
        deleteNotificationId:idNotificacion,
        id: adminState ? adminState.id : 0
      }
    });
  }
  
  useEffect(()=>{
    if(deleteResponse && deleteResponse.DELETE_NOTIFICATION){
      toggleList();
      setAnchorEl(null);
    }
  }, [deleteResponse]);

  
  return(
  <>
    <Tooltip title="Editar" placement="right">
      <Box className="IconButtonPoints" mr={1}>
        <IconButton onClick={(e) => setAnchorEl(e.currentTarget)}>
          <MoreVertIcon style={{ color: "#D6D6D6" }} />
        </IconButton>
      </Box>
    </Tooltip>
    <Menu
      className="MoreVerIcon"
      anchorEl={anchorEl}
      open={open}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      onClose={() => setAnchorEl(null)}
      PaperProps={{
        style: {
          maxHeight: '160px',
          borderRadius: '10px',
          border: '1px solid #D6D6D6'
        },
      }}
      >
        <MenuItem 
          divider 
          button
          onClick={() => eliminaNotificacion()}
          >
          <div className={styles.mn_contenedor}>
            <div className={styles.mn_iconoEliminarTodas}></div>
            <div className={styles.mn_texto}>Eliminar notificac&iacute;on</div>
          </div>
        </MenuItem>
        {
          Desde === 'EventualTerminada' && (
            <MenuItem  button onClick={() =>{ localStorage.currentPill='3'; history.push('/InitEventualPayroll/'+idRegistro+'/'+eventualGroup) }}>
              <div className={styles.mn_contenedor}>
                <div className={styles.mn_iconoIralProceso}></div>
                <div className={styles.mn_texto}>Ir al proceso</div>
              </div>
            </MenuItem>
          )
        }
        {
          Desde === 'Contract' && (
            <MenuItem  button onClick={() => history.push('/collaborators/'+idRegistro+'/1') }>
              <div className={styles.mn_contenedor}>
                <div className={styles.mn_iconoIralProceso}></div>
                <div className={styles.mn_texto}>Ir al proceso</div>
              </div>
            </MenuItem>
          )
        }
        {
          Desde === 'ContractExpired' && (
            <MenuItem  button onClick={() => history.push('/collaborators/'+idRegistro+'/1') }>
              <div className={styles.mn_contenedor}>
                <div className={styles.mn_iconoIralProceso}></div>
                <div className={styles.mn_texto}>Ir al proceso</div>
              </div>
            </MenuItem>
          )
        }
        {
          Desde === 'ColaboradorAlta' && (
            <MenuItem  button onClick={() => history.push('/collaborators/'+idRegistro+'/1') }>
              <div className={styles.mn_contenedor}>
                <div className={styles.mn_iconoIralProceso}></div>
                <div className={styles.mn_texto}>Ir al proceso</div>
              </div>
            </MenuItem>
          )
        }
        {
          Desde === 'ColaboradorBaja' && (
            <MenuItem  button onClick={() => history.push('/inactivos/'+idRegistro+'/1') }>
              <div className={styles.mn_contenedor}>
                <div className={styles.mn_iconoIralProceso}></div>
                <div className={styles.mn_texto}>Ir al proceso</div>
              </div>
            </MenuItem>
          )
        }
        {
          Desde === 'Incidencia' && (
            <MenuItem  button onClick={() => { localStorage.currentPill='0'; history.push('/payroll') } }>
              <div className={styles.mn_contenedor}>
                <div className={styles.mn_iconoIralProceso}></div>
                <div className={styles.mn_texto}>Ir al proceso</div>
              </div>
            </MenuItem>
          )
        }
        {
          Desde === 'NominaTerminada' && (
            <MenuItem  button onClick={() => { localStorage.currentPill='1'; history.push('/InitPayroll/'+idRegistro+'/'+payrollGroup) } }>
              <div className={styles.mn_contenedor}>
                <div className={styles.mn_iconoIralProceso}></div>
                <div className={styles.mn_texto}>Ir al proceso</div>
              </div>
            </MenuItem>
          )
        }
        {
          Desde === 'FiniquitoTerminado' && (
            <MenuItem  button onClick={() => { localStorage.currentPill='2'; history.push('/InitSettlement/'+idRegistro+'/'+collaborator) } }>
              <div className={styles.mn_contenedor}>
                <div className={styles.mn_iconoIralProceso}></div>
                <div className={styles.mn_texto}>Ir al proceso</div>
              </div>
            </MenuItem>
          )
        }
      </Menu >
    </>
)
}

export default MenuListSecundario