import { ChangeEvent, useContext, useState } from "react";
import CalendarsContext from "../../../context/NewCalendarContext/CalendarsContext";
import {Dialog, DialogContent, Tabs, Tab } from "@material-ui/core"
import styles from '../Calendars.module.css';
import {Events} from './event';

interface TabCalendar {
  children?: React.ReactNode;
  idCalendar: any;
  setDate: any;
  daySelectedMonth: any;
  date: any
};

export const CreateEvent = (props: TabCalendar) => {
  const { idCalendar, setDate, daySelectedMonth, date } = props;
  const {state} = useContext(CalendarsContext);
  const [tabSelect, setTabSelect] = useState(0);
  const [showSeleccionados, setShowColaboradoresSeleccionado] = useState(false);
  const [showE, setShowE] = useState(false);
  const handleChange = (event: ChangeEvent<{}>, newValue: number) => {

    setTabSelect(newValue)
    
  };

  const showHiddenAreas = async (e: any) => {

    if(
      e.target.parentNode.className !== styles.DivSeleccionadosCollaborators
      &&
      e.target.parentNode.className !== styles.checkboxitemSelecionados
      &&
      e.target.parentNode.className !== styles.inputSelecionados
      &&
      e.target.parentNode.className !== styles.labelSelecionado
      &&
      e.target.parentNode.className !== styles.contenedorLista
      &&
      e.target.parentNode.className !== styles.listaNombres
      &&
      e.target.parentNode.className !== styles.labelSelecionados
      &&
      e.target.parentNode.className !== styles.botonEliminarList
      &&
      e.target.parentNode.className !== styles.EliminarImg
      &&
      e.target.parentNode.className !== ''
      &&
      e.target.parentNode.className !== styles.contenedorTotalesText
      &&
      e.target.parentNode.className !== styles.divtotalesSelecionados
      &&
      e.target.parentNode.className !== styles.textoDivTotalesSelecionados
    ){
      setShowColaboradoresSeleccionado(false);
    }

    if(
      e.target.parentNode.className !== styles.DivSeleccionadosUniverso
      &&
      e.target.parentNode.className !== styles.contenedorListaAvailableCollaborators
      &&
      e.target.parentNode.className !== styles.listaNombresAvailableCollaborators
      &&
      e.target.parentNode.className !== styles.checkboxitemAvailableCollaborators
      &&
      e.target.parentNode.className !== styles.proyectName
      &&
      e.target.parentNode.className !== styles.botonAgregar
      &&
      e.target.parentNode.className !== styles.pc_iconoAgregarDos
      &&
      e.target.parentNode.className !== styles.textoAgrega
      &&
      e.target.parentNode.className !== styles.contenedorSelecioneColaborador
      &&
      e.target.parentNode.className !== ''
      &&
      e.target.parentNode.className !== styles.divtotalesUniverso
      &&
      e.target.parentNode.className !== styles.textoDivTotales
    ){
      setShowE(false);
    }
  } 
        
  return(
    <Dialog open={state.createEventModal} aria-labelledby="form-dialog-title" maxWidth="sm" fullWidth={true}
    onClick={(e) => {
      showHiddenAreas(e)
    }}
    >
      <div>
        <Tabs
          value={tabSelect}
          onChange={handleChange}
          aria-label="basic tabs example"
          variant="fullWidth"
          TabIndicatorProps={{
            style: {
                display: "none",
            },
          }} 
        centered>
          <Tab 
            label="Eventos" 
            value={0}
            className = {styles.selectedTabEvent }
            disabled={true}
          />
        </Tabs>
      </div> 
      <DialogContent>
        {
          tabSelect === 0 ? 
          <Events 
          idCalendar ={idCalendar} 
          setDate={setDate} 
          daySelectedMonth={daySelectedMonth} 
          date={date} 
          showSeleccionados={showSeleccionados} 
          setShowColaboradoresSeleccionado={setShowColaboradoresSeleccionado}
          showE={showE}
          setShowE={setShowE}
          /> 
          : <Events 
            idCalendar ={idCalendar} 
            setDate={setDate} 
            daySelectedMonth={daySelectedMonth} 
            date={date}
            showSeleccionados={showSeleccionados} 
            setShowColaboradoresSeleccionado={setShowColaboradoresSeleccionado}
            showE={showE}
            setShowE={setShowE}
            />
        }
      </DialogContent>
    </Dialog>
  )
}

