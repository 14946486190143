import style from "../../screens/BitsScreen.module.css";

export const  CancellationCollaborators = ({ percentage = 0, tipo = '' }) => {
  const strokeWidth = 10;
  const radius = 50 - strokeWidth / 2;
  const pathDescription = `
      M 50,50 m 0,-${radius}
      a ${radius},${radius} 0 1 1 0,${2 * radius}
      a ${radius},${radius} 0 1 1 0,-${2 * radius}
    `;

  const diameter = Math.PI * 2 * radius;
  const progressStyle = {
    stroke: "#4FAEEB",

    strokeDasharray: `${diameter}px ${diameter}px`,
    strokeDashoffset: `${((100 - percentage) / 100) * diameter}px`,
  };

  return (
    <svg
      id="progressbarcircle"
      className={style.CircularProgressbar}
      viewBox="0 0 100 100"
      width={150}
      height={150}
    >
      <path
        className="CircularProgressbar-trail"
        d={pathDescription}
        strokeWidth={strokeWidth}
        fillOpacity={0}
        style={{
          stroke: "#2186C6",
        }}
      />

      <path
        className="CircularProgressbar-path"
        d={pathDescription}
        strokeWidth={strokeWidth}
        fillOpacity={0}
        style={progressStyle}
      />

      <text
        className="CircularProgressbar-text"
        x={50}
        y={50}
        style={{
          fill: "#4FAEEB",
          fontSize: "20px",
          dominantBaseline: "central",
          textAnchor: "middle",
          stroke: "1px",
        }}
      >
        {`${percentage}`}%
        {/* salto de linea */}
        <tspan x="50" dy="1.5em" style={{fontSize: "10px",fill: "#303030"}}>{tipo}</tspan>
        
        
       
      </text>
    </svg>
  );
};
export const  IncidentsCollaborators = ({ percentage = 0 }) => {
  const strokeWidth = 10;
  const radius = 50 - strokeWidth / 2;
  const pathDescription = `
      M 50,50 m 0,-${radius}
      a ${radius},${radius} 0 1 1 0,${2 * radius}
      a ${radius},${radius} 0 1 1 0,-${2 * radius}
    `;

  const diameter = Math.PI * 2 * radius;
  const progressStyle = {
    stroke: "#FABB00",

    strokeDasharray: `${diameter}px ${diameter}px`,
    strokeDashoffset: `${((100 - percentage) / 100) * diameter}px`,
  };

  return (
    <svg
      id="progressbarcircle"
      className={style.CircularProgressbar}
      viewBox="0 0 100 100"
      width={150}
      height={150}
    >
      <path
        className="CircularProgressbar-trail"
        d={pathDescription}
        strokeWidth={strokeWidth}
        fillOpacity={0}
        style={{
          stroke: "#2186C6",
        }}
      />

      <path
        className="CircularProgressbar-path"
        d={pathDescription}
        strokeWidth={strokeWidth}
        fillOpacity={0}
        style={progressStyle}
      />

      <text
        className="CircularProgressbar-text"
        x={50}
        y={40}
        style={{
          fill: "#FABB00",
          fontSize: "20px",
          dominantBaseline: "central",
          textAnchor: "middle",
          stroke: "1px",
        }}
      >
        {`${percentage}`}%
        {/* salto de linea */}
        <tspan x="50" dy="2em" style={{fontSize: "8px",fill: "#303030"}}>Colaboradores con</tspan>
        <tspan x="50" dy="1.5em" style={{fontSize: "8px",fill: "#303030"}}>incidencias</tspan>
        
        
       
      </text>
    </svg>
  );
};

