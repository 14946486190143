import { useState, useEffect, useContext } from "react";
import Box from '@mui/material/Box';
import styles from "../../src/components/Payroll/PaymentsStyles.module.css";
import { useMutation, useQuery } from "@apollo/client";
import {
  GET_LAST_BILL,
  GET_ALL_PAYMENTS,
  SEND_DISPERSION_FACTURACION_PAGOS
} from "..//Querys/querys";
import iconoPDF from "../assets/svg/icono_descargar_pdf.svg";
import descargaXml from "../assets/svg/icono_descarga_xml.svg";
import { formatter } from "../helpers/formatoMoneda";
import PayrollProcessContext from "../context/PayrollProcess/PayrollProcessContext";
import AceptarDispersarFacturacionPagos from "../components/Payroll/Modals/AceprtarDispersarFacturacionPagos";
import { openAceptarDispersarFacturacionPagos } from "../context/PayrollProcess/Actions";
import { SuccessfulAlert } from "../alerts/successAlerts";


export const InitPayroll = () => {
  const {dispatch} = useContext(PayrollProcessContext)
  const [filtrado, setFiltrado] = useState([]);
  const resultPayrollCollaborator = useQuery(GET_ALL_PAYMENTS);
  const { loading, data } = useQuery(GET_LAST_BILL);
  const bill = data?.GET_LAST_BILL;
  const allPayrollCollaborator = resultPayrollCollaborator.data?.GET_ALL_PAYMENTS;
  const [sendDispersion] = useMutation(SEND_DISPERSION_FACTURACION_PAGOS,{
    refetchQueries:[
      {query: GET_ALL_PAYMENTS}
    ]
  })

  const aceptaDispersion = async(id:any) =>{
    let Array:any = []
    Array.push({ id: parseInt(id) })
    await sendDispersion({
      variables:{
        sendDispersionFacturacionPagosId: Array
      }
    })
    SuccessfulAlert({text: "Registros enviados, por favor espera la respuesta"})
  }

  useEffect(() => {
    setFiltrado(allPayrollCollaborator);
  }, [allPayrollCollaborator]);

  const datosFiltrados = (e: any) => {
    if (e !== "") {
      let expresion = new RegExp(`${e}.*`, "i");
      const nuevoFiltrado = filtrado.filter((lis: any) =>
        expresion.test(lis.Mes)
      );
      setFiltrado(nuevoFiltrado);
    } else {
      setFiltrado(allPayrollCollaborator);
    }
  };


  const abrePagosFacturacion = (id:any) =>{
    openAceptarDispersarFacturacionPagos({aceptarDispersarFacturacionPagos:true, id}, dispatch)
  }

  return (
    <>
      {
        loading ? null : 
        String(bill?.SistemaActivo) === '0' ? <div className={styles.divDisabled} >
          <div className={styles.divTextDisabled} >
            <span className={styles.textDisabled} >Tu cuenta se encuentra bloqueada</span>
          </div>
          <div className={styles.divTextContact} >
            <span className={styles.textContact} >Contacta a un asesor</span>
          </div>
        </div>: null

      }
      
      <Box mt={3} ml={5} className="Title">
        Facturación y pagos
      </Box>
      {(loading === true )?
      <div className={`${styles.pc_contenedorPrincipal} ${styles.tituloFacturacionPagos}`}>
          <div className={styles.contenedorLoading}>
            <div className={styles.loading}></div>
          </div>
        </div>
      :
      <div className={`${styles.pc_contenedorPrincipal} ${styles.tituloFacturacionPagos}`}>
          <>
            <div className={styles.pc_tituloContenedorPrincipal}>
              <div className={styles.pc_contenedorNombre}>
                <div>
                  <span className={styles.pc_tituloPrincipal}>{bill?.Company}</span>
                </div>
              </div>
            </div>
            <div className={styles.pc_contenedorBuscadorDescargar}>
              <div className={styles.pc_contenedorBuscador}>
                <input
                  type="text"
                  placeholder="Buscar mes factura"
                  className={styles.pc_inputBuscador}
                  onChange={(e) => datosFiltrados(e.target.value)}
                ></input>
                <div className={styles.pc_iconoBuscador}></div>
              </div>
              <div className={styles.divSaldo} >
                <div className={styles.divAllSaldo}>
                  <div className={styles.textSaldo}>
                    Saldo cuenta STP
                  </div>
                  <div className={styles.saldoSTP}>
                      <span className={styles.impSaldoSTP}>{formatter(bill?.saldo)}</span>
                  </div>
                </div>
                <div className={styles.divAllSaldo}>
                  <div className={styles.divImpUser}>
                    <div className={styles.divTextImpAdeudo}>
                      <div className={styles.divTextAdeudo}> 
                        <span className={styles.textAdeudo}>Adeudo actual</span>
                      </div>
                      <div className={styles.divAdeudo}> 
                        <span className={styles.impAdeudo}>{formatter(bill?.TotalPago)}</span>
                      </div>
                    </div>
                    <div className={styles.divTextImpAdeudo}>
                      <div className={styles.divTextAdeudo}> 
                        <span className={styles.textAdeudo}>Precio por usuario</span>
                      </div>
                      <div className={styles.divAdeudo}> 
                        <span className={styles.impAdeudo}>{formatter(bill?.CostoColaborador)}</span>
                      </div>
                    </div>
                  </div>
                  
                </div>
                <div className={styles.divAllSaldo}>
                  <div className={styles.divTextDuracion}>
                    <span className={styles.textDuracion}>Duración del contrato</span>
                  </div>
                  <div className={styles.divTextDuracionFecha}>
                    <div className={styles.divTextFecha}>
                      <span className={styles.textFecha}>{bill?.dateStart}</span>
                    </div>
                    <div className={styles.div_Fecha}>
                      <span className={styles.textFecha}>-</span>
                    </div>
                    <div className={styles.divTextFecha}>
                      <span className={styles.textFecha}>{bill?.dateEnd}</span>
                    </div>
                  </div>
                </div>
              </div>
              
            </div>
          </>
        <div className={styles.pc_contenedorTabla}>
          <table className={styles.pc_tablaPrincipal}>
            <thead className={styles.pd_contenedorTitulos}>
              <td className={styles.pc_columnaTitulos}>Mes</td>
              <td className={styles.pc_columnaTitulos}>Total</td>
              <td className={styles.pc_columnaTitulos}>Usuarios</td>
              <td className={styles.pc_columnaTitulos}
              style={{textAlign: "center"}}
              >Desglose de usuarios</td>
              <td className={styles.pc_columnaTitulos}
              style={{textAlign: "center"}}
              >PDF</td>
              <td className={styles.pc_columnaTitulos}
              style={{textAlign: "center"}}
              >XML</td>
              <td className={styles.pc_columnaTitulos}></td>
            </thead>
            <tbody>
              {filtrado?.map((item: any) => (
                <tr>
                  <td className={styles.pc_columnaDatos}>
                    {item?.Mes}
                  </td>
                  <td className={styles.pc_columnaDatos}>
                    {formatter(item?.Total)}
                  </td>
                  <td className={styles.pc_columnaDatos}>
                    {item?.TotalCollaborators}
                  </td>
                  <td className={styles.pc_columnaDatos}>
                    <div className={styles.divDoc}>
                      <a
                        style={{ marginRight: "auto", marginLeft: "auto" }}
                        target="_blank"
                        href={item?.factura}
                        rel="noopener noreferrer"
                      >
                        <img
                          src={iconoPDF}
                          style={{ marginRight: "auto", marginLeft: "auto" }}
                          className="cursor"
                          alt="DownloadFile"
                          
                        />
                      </a>
                    </div>
                      
                  </td>
                  <td className={styles.pc_columnaDatos}>
                    <div className={styles.divDoc}>
                      <a
                        style={{ marginRight: "auto", marginLeft: "auto" }}
                        target="_blank"
                        href={item?.factura}
                        rel="noopener noreferrer"
                      >
                        <img
                          src={iconoPDF}
                          style={{ marginRight: "auto", marginLeft: "auto" }}
                          className="cursor"
                          alt="DownloadFile"
                        />
                      </a>
                    </div>
                      
                  </td>
                  <td className={styles.pc_columnaDatos}>
                  <div className={styles.divDoc}>
                    <a
                      style={{ marginRight: "auto", marginLeft: "auto" }}
                      target="_blank"
                      href={item?.XML}
                      rel="noopener noreferrer"
                    >
                      <img
                        src={descargaXml}
                        style={{ marginRight: "auto", marginLeft: "auto" }}
                        className="cursor"
                        alt="Descargar XML"
                        
                      />
                    </a>
                  </div>
                    
                  </td>
                  <td className={styles.pc_columnaDatos}>
                    {item?.statusDispersion !== 'LIQUIDADO' ? 
                      <div className={styles.divButtonPay} >
                        <button
                          className={styles.botonAgregarPago}
                          onClick={() => aceptaDispersion(item.id)}
                        >
                          <div className={styles.iconoAgegarPago}></div>
                          <div className={styles.textoBotonAgregarEmpresa}>
                            Pagar
                          </div>            
                        </button>
                      </div>:<div className={styles.divButtonPay} >
                      <button
                        className={styles.botonPagado}
                      >
                        <div className={styles.iconoPagado}></div>
                        <div className={styles.textoBotonPagado}>
                          Pagado
                        </div>            
                      </button>
                    </div>
                    }
                      
                    </td>
                </tr>
              ))}
              
            </tbody>
          </table>
        </div>
      </div>
    }
      <AceptarDispersarFacturacionPagos />
    </>
  );
};

export default InitPayroll;
