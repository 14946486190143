import { useMutation } from "@apollo/client";
import { Grid } from "@material-ui/core";
import { useContext } from "react";
import { ErrorAlert } from "../../../../alerts/errorAlert";
import { SuccessfulAlert } from "../../../../alerts/successAlerts";
import { WarningAlert } from "../../../../alerts/WarningAlert";
import {
  GET_USERS_BY_ID,
  UPDATE_USERS,
} from "../../../../Querys/querys";
import { postFileAWS } from "../../../../services/candidateService";
import DefaultImage from "../../../../assets/svg/icono-subir-archivo-azul.svg";
import CollaboratorContext, {Types} from "../../../../context/CollaboratorContext/CollaboratorContext";
import { updateCollaborator } from "../../../../helpers/Collaborator/Collaborator";

const File_Own = ({
  name,
  accept = ".pdf",
  parametrofrom = "",
  idUsr = "",
  image = DefaultImage,
  remove_file = false,
  setArchives,
  archives = [],
  disabled = false,
  section,
  setUpdateState,
  updateSate,
}: any) => {
  const { state, dispatch } = useContext(CollaboratorContext);
  let myFiles = [...archives];
  const [updateColaboradores] = useMutation(UPDATE_USERS, {
    refetchQueries: [
      {
        query: GET_USERS_BY_ID,
        variables: { getUsersId: state.collaborator.id },
      },
    ],
  });

  if (accept === ".jpg" || accept === ".png" || accept === ".jpeg") {
    accept = "image/*";
  }

  const uploadFileAws = async (e: any): Promise<void> => {
    dispatch({
      type: Types.LOAD_DATA,
      payload: {
        loading:true
      }
    });
    let result = await postFileAWS(
      e,
      idUsr,
      parametrofrom,
      name,
      remove_file,
      section
    );
    //if result is a string
    if ("URL" in result) {
      // state.collaborator[result.name] = result.id;
      //comparate archives with result and if exist update it
      let archive = myFiles.find((archive: any) => archive.name === result.name);
      if (archive) {
        //delete index where archives name is the same as result name
        let index = myFiles.indexOf(archive);
        myFiles.splice(index, 1);
        //add result to archives
        myFiles.push(result);

        //update state
        setArchives(myFiles);
         updateCollaborator(state.collaborator, dispatch);
        await updateColaboradores({
          variables: {
            updateUsersId: state.collaborator?.id,
            input: {},
          },
        });
      }
      //if not exist add it
      else {
        setArchives([...myFiles, result]);
        await updateColaboradores({
          variables: {
            updateUsersId: state.collaborator?.id,
            input: {},
          },
        });
      }
      if (result.processFlag_1) {
        await updateColaboradores({
          variables: {
            updateUsersId: state.collaborator?.id,
            input: {},
            process: {
              tab: "expedient",
              section: "section_1",
            },
          },
        });
      }
      if (result.processFlag_2) {
        await updateColaboradores({
          variables: {
            updateUsersId: state.collaborator?.id,
            input: {},
            process: {
              tab: "expedient",
              section: "section_2",
            },
          },
        });
      }
      if (result.processFlag_3) {
        await updateColaboradores({
          variables: {
            updateUsersId: state.collaborator?.id,
            input: {},
            process: {
              tab: "expedient",
              section: "section_3",
            },
          },
        });
      }
      
      await SuccessfulAlert({ text: "Archivo actualizado exitosamente" });
    } else await ErrorAlert({ text: "Archivo no agregado" });
  };

  const deleteFile = async (e: any): Promise<void> => {
    let result = await WarningAlert({
      text: "¿Seguro que deseas eliminar el archivo?",
      showDenyButton: true,
    });
    if (result.isConfirmed) {
      dispatch({
        type: Types.LOAD_DATA,
        payload: {
          loading:true
        }
      });
      let result = await postFileAWS(
        e,
        idUsr,
        parametrofrom,
        name,
        remove_file,
        section
      );
      
      if (result) {
        setArchives(result.archives);
      } else {
        setArchives([]);
      }
      let collaborator = { ...state.collaborator };

      if (result.name === "CuentaBancaria_PDF" || result.name === 'CartaGMM_PDF' || result.name === "AvisoRetencion_PDF" || result.name === "AGREEMENT_PDF" || result.name === "CartaOfertaFirmada") {
        collaborator[result.name] = null;
        collaborator.archive = result.archives;
        state.collaborator = collaborator;
      }
      //this setUpadteSatate is for update the state of the component and appear the button to upload the file after delete it
      if (updateSate === false) {
        setUpdateState(!updateSate);
      }
      updateCollaborator(state.collaborator, dispatch);
      SuccessfulAlert({ text: "Archivo eliminado exitosamente" });
    } else await ErrorAlert({ text: "Archivo no eliminado" });
  };

  return (
    <Grid>
      <label className="">
        {remove_file ? (
          <input
            type="file"
            name={name}
            accept={accept}
            disabled={disabled}
            onClick={(e) => {
              e.preventDefault();
              deleteFile(e);
            }}
          />
        ) : (
          <input
            type="file"
            name={name}
            accept={accept}
            disabled={disabled}
            onChange={(e) => uploadFileAws(e)}
          />
        )}
        <img src={image} alt="file upload" className={disabled ? "" : "image-file"} />
      </label>
    </Grid>
  );
};

export default File_Own;
