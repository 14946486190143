import { useContext, useState, useEffect } from "react";
import CalendarsContext from "../../../../context/NewCalendarContext/CalendarsContext";
import {Dialog, DialogContent, DialogActions } from "@material-ui/core"
import { closeListWorkSchedule } from "../../../../context/NewCalendarContext/Actions";
import { GET_ALL_WORKINGHOURS,GET_WORKINGHOURS_BYIDCALENDAR } from "../../../../Querys/querys";
import { useLazyQuery } from "@apollo/client";
import '../../ClockCalendar.css';
import TableBits from "../../../TableBits/TableBits";
import { TBColumn } from "../../../../interfaces/TableBits.interfaces";
import ScheduleSundayField from "./fields/ScheduleSundayField";
import ScheduleMondayField from "./fields/ScheduleMondayField";
import ScheduleTuesdayField from "./fields/ScheduleTuesdayField";
import ScheduleWednesdayField from "./fields/ScheduleWednesdayField";
import ScheduleThursdayField from "./fields/ScheduleThursdayField";
import ScheduleFridayField from "./fields/ScheduleFridayField";
import ScheduleSaturdayField from "./fields/ScheduleSaturdayField";
import ScheduleOptionsField from "./fields/ScheduleOptionsField";

const columns: TBColumn[] = [
  { id: "Nombre",label: "Nombre", align: "left" },
  { id: "TipodeJornada", label: "Tipo de jornada", align: "left" },
  { id: "Semana", label: "Semana", align: "left" },
  { id: "HorarioLunes", label: "Horario lunes", align: "left" },
  { id: "HorarioMartes", label: "Horario martes", align: "left" },
  { id: "HorarioMiercoles", label: "Horario miercoles", align: "left" },
  { id: "HorarioJueves", label: "Horario jueves", align: "left" },
  { id: "HorarioViernes", label: "Horario viernes", align: "left" },
  { id: "HorarioSabado", label: "Horario sabado", align: "left" },
  { id: "HorarioDomingo", label: "Horario domingo", align: "left" },
];

function WorkScheduleList({idCalendar}:any){

  const {state, dispatch} = useContext(CalendarsContext);
  const [schedules, setSchedules] = useState([]);
  const [getSchedules, {data:resultSchedules}] = useLazyQuery(GET_WORKINGHOURS_BYIDCALENDAR, {
      fetchPolicy: 'no-cache'
  });

  useEffect(()=>{
    if(state.openListSchedule){
      getSchedules({variables:{id:parseInt(idCalendar)}});
    }
  }, [getSchedules, idCalendar, state.openListSchedule]);

  useEffect(()=>{
    getSchedules({variables:{id:parseInt(idCalendar)}});
  }, [getSchedules, idCalendar, state.deleteWorkSchedule]);

  useEffect(()=>{
    if(!state.openUpdateSchedule){
      getSchedules({variables:{id:parseInt(idCalendar)}});
    }
  }, [getSchedules, idCalendar, state.openUpdateSchedule]);

  useEffect(()=>{
    if(resultSchedules && resultSchedules.GET_WORKINGHOURS_BYIDCALENDAR){
      setSchedules(resultSchedules.GET_WORKINGHOURS_BYIDCALENDAR);
    }
  }, [resultSchedules]);

  return (
    <Dialog open={state.openListSchedule} aria-labelledby="form-dialog-title" maxWidth="xl" fullWidth={true}>
        <div className="dialogContainer">
          <div className="containerTitle">
              <div className="centerContainer"
              style={{
                  marginLeft:'auto',
                  marginRight:'auto'
              }}
              >
                  <h2 id="form-dialog-title" data-testid="TitleModal">Horarios laborales</h2>
                  
              </div>
              <div className="contenedorCerrarModal">
                  <div className="iconoCerrarModal" onClick={(()=>closeListWorkSchedule(false, dispatch))}></div>      
              </div> 
          </div>
          <DialogContent>
            <TableBits
              columns={columns}
              rows={schedules}
              components={
                [
                  null,
                  null,
                  null,
                  ScheduleMondayField,
                  ScheduleTuesdayField,
                  ScheduleWednesdayField,
                  ScheduleThursdayField,
                  ScheduleFridayField,
                  ScheduleSaturdayField,
                  ScheduleSundayField
                ]
              }
              componentOptions={ScheduleOptionsField}
            />
          </DialogContent>
          <DialogActions>
              <button className="botonCancelar" type="button" onClick={
                  (()=>closeListWorkSchedule(false, dispatch))
              }>
                  Cancelar
              </button>
          </DialogActions>
        </div>
    </Dialog>
  )
}

export default WorkScheduleList;