import { useContext, useEffect, useState } from "react";
import {
  FormControl,
  Grid,
  InputLabel,
  Select,
  TextField,
} from "@material-ui/core";
import Box from '@mui/material/Box';
import { useQuery } from "@apollo/client";
import moment from "moment";
import CollaboratorContext from "../../../../context/CollaboratorContext/CollaboratorContext";
import { IProject } from "../../../../interfaces/Project";
import {
  GET_ALL_PROJECT,
  GET_ALL_PUESTOS,
} from "../../../../Querys/querys";

const InputDataColaboradores = () => {
  const { state } = useContext(CollaboratorContext);
  const [projects, setProjects] = useState<Array<IProject>>([]);
  const [puestos, setPuestos] = useState<Array<any>>([]);

  const { data: resultProject } = useQuery(GET_ALL_PROJECT);
  const allProject = resultProject?.GET_ALL_PROJECT;

  const { data: resultPuestos } = useQuery(GET_ALL_PUESTOS);
  const allPuestos = resultPuestos?.GET_ALL_PUESTOS;

  useEffect(() => {
    if (allProject) setProjects(allProject);
    if (allPuestos) setPuestos(allPuestos);
  }, [allProject, allPuestos]);

  const handleChange = async (e: any) => {
    // await updateData(e, state, dispatch, 0);
  };

  return (
    <div
      className={
        state.sections[1] >= 100 ? `validate__border` : `novalidate__border`
      }
    >
      <Grid direction="row" container justify="flex-start" alignItems="center">
        <Grid xs item>
          <span className="Fecha-de-nacimiento">Fecha de ingreso</span>
        </Grid>
        <Grid xs item container justify="flex-end" alignItems="center">
          <TextField
            type="date"
            defaultValue={
              state.collaborator
                ? moment(state.collaborator.dateOfAdmission).format(
                    "YYYY-MM-DD"
                  )
                : ""
            }
            autoFocus={true}
            name="dateOfAdmission"
            variant="outlined"
            size="small"
            InputProps={{
              readOnly: false,
            }}
            disabled={true}
          />
        </Grid>
      </Grid>

      <Box mb={2} mt={2}>
        <FormControl variant="outlined" fullWidth={true} size="small">
          <TextField
            type="text"
            name="officePlace"
            label="Lugar de trabajo"
            defaultValue={state.collaborator?.officePlace}
            variant="outlined"
            size="small"
            fullWidth={true}
            InputProps={{
              readOnly: true,
            }}
            style={{ opacity:0.5 }}
          />
        </FormControl>
      </Box>

      <Grid direction="row" container spacing={2}>
        <Grid xs item>
          <FormControl variant="outlined" fullWidth={true} size="small">
            <InputLabel htmlFor="outlined-age-native-simple">
              Selecciona área o proyecto
            </InputLabel>
            <Select
              native
              onBlur={(e) => handleChange(e)}
              label={"Selecciona área o proyecto"}
              name="idProject"
              defaultValue={state.collaborator.idProject || ""}
              autoFocus={true}
              disabled={true}
            >
              <option value={""} disabled={true}>
                {""}
              </option>
              {projects.map(({ id, proyectNameAndClient }: any, index: number) =>
                String(state.collaborator?.idProject) === String(id) ? (
                  <option key={index} value={id} selected>
                    {proyectNameAndClient}
                  </option>
                ) : (
                  <option key={index} value={id}>
                    {proyectNameAndClient}
                  </option>
                )
              )}
            </Select>
          </FormControl>
        </Grid>
        <Grid xs item>
          <FormControl variant="outlined" fullWidth={true} size="small">
            <InputLabel htmlFor="outlined-age-native-simple">Puesto</InputLabel>
            <Select
              native
              label={"Puesto"}
              defaultValue={""}
              name="Puesto"
              autoFocus={true}
              disabled={true}
            >
              <option value="" selected disabled>
                {" "}
              </option>

              {puestos.map(({ idPuesto, NombrePuesto }: any, index: number) =>
                String(state.collaborator?.puestoId) === String(idPuesto) ? (
                  <option key={index} value={idPuesto} selected>
                    {NombrePuesto}
                  </option>
                ) : (
                  <option key={index} value={idPuesto}>
                    {NombrePuesto}
                  </option>
                )
              )}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </div>
  );
};

export default InputDataColaboradores;
