import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import List from "../List";
import HiringData from "./HiringData";
import InputData from "./InputData";
import PersonalData from "./PersonalData";
import HiringDataNuevoIngreso from "./Candidatos/HiringDataNuevoIngreso";
import HiringDataColaboradores from "./Colaboradores/HiringDataColaboradores";
import HiringDataInactivos from "./Inactivos/HiringDataInactivos";
import InputDataNuevoIngreso from "./Candidatos/InputDataNuevoIngreso";
import InputDataColaboradores from "./Colaboradores/InputDataColaboradores";
import InputDataInactivos from "./Inactivos/InputDataInactivos";
import PersonalDataNuevoIngreso from "./Candidatos/PersonalDataNuevoIngreso";
import PersonalDataInactivos from "./Inactivos/PersonalDataInactivos";
import PersonalDataColaboradores from "./Colaboradores/PersonalDataColaboradores";
import CheckPermission from "../../../helpers/Administradores/Permissions";

const Information = (processProp?: any, collaborator?: any) => {
  let location = useLocation();
  let nuevoArrayEtiquetas = [];
  let nuevoArrayTablas = [];
  const [processTab, setProcessTab] = useState<any>();
  useEffect(() => {
    if (processProp) {
      setProcessTab(processProp?.process);
    }
  }, [processProp]);

  //extract the first part of the url
  let section = location.pathname.split("/")[1];

  if (section === "collaborators-view") {
    if (
      CheckPermission(
        "Colaboradores.Inactivos.Ver.InformacionAlta.InformacionPersonal"
      )
    ) {
      nuevoArrayEtiquetas.push("Información Personal");
      nuevoArrayTablas.push(PersonalDataInactivos);
    }
    if (
      CheckPermission(
        "Colaboradores.Inactivos.Ver.InformacionAlta.DatosIngreso"
      )
    ) {
      nuevoArrayEtiquetas.push("Datos de Ingreso");
      nuevoArrayTablas.push(InputDataInactivos);
    }
    if (
      CheckPermission(
        "Colaboradores.Inactivos.Ver.InformacionAlta.DatosContratacion"
      )
    ) {
      nuevoArrayEtiquetas.push("Datos de Contratación");
      nuevoArrayTablas.push(HiringDataInactivos);
    }
  }

  if (section === "collaborators") {
    if (
      CheckPermission(
        "Colaboradores.Colaboradores.Editar.InformacionAlta.InformacionPersonal.Ver"
      )
    ) {
      nuevoArrayEtiquetas.push("Información Personal");
      nuevoArrayTablas.push(PersonalData);
    }
    if (
      CheckPermission(
        "Colaboradores.Colaboradores.Editar.InformacionAlta.DatosIngreso.Ver"
      )
    ) {
      nuevoArrayEtiquetas.push("Datos de Ingreso");
      nuevoArrayTablas.push(InputData);
    }
    if (
      CheckPermission(
        "Colaboradores.Colaboradores.Editar.informcaionAlta.DatosContratacion.Ver"
      )
    ) {
      nuevoArrayEtiquetas.push("Datos de Contratación");
      nuevoArrayTablas.push(HiringData);
    }
  }

  if (section === "nuevoingreso") {
    if (
      CheckPermission(
        "Colaboradores.NuevoIngreso.Ver.InformacionAlta.InformacionPersonal"
      )
    ) {
      nuevoArrayEtiquetas.push("Información Personal");
      nuevoArrayTablas.push(PersonalDataNuevoIngreso);
    }
    if (
      CheckPermission(
        "Colaboradores.NuevoIngreso.Ver.InformacionAlta.DatosIngreso"
      )
    ) {
      nuevoArrayEtiquetas.push("Datos de Ingreso");
      nuevoArrayTablas.push(InputDataNuevoIngreso);
    }
    if (
      CheckPermission(
        "Colaboradores.NuevoIngreso.Ver.InformacionAlta.DatosContratacion"
      )
    ) {
      nuevoArrayEtiquetas.push("Datos de Contratación");
      nuevoArrayTablas.push(HiringDataNuevoIngreso);
    }
  }

  if (section === "inactivos") {
    if (
      CheckPermission(
        "Colaboradores.Inactivos.Ver.InformacionAlta.InformacionPersonal"
      )
    ) {
      nuevoArrayEtiquetas.push("Información Personal");
      nuevoArrayTablas.push(PersonalDataInactivos);
    }
    if (
      CheckPermission(
        "Colaboradores.Inactivos.Ver.InformacionAlta.DatosIngreso"
      )
    ) {
      nuevoArrayEtiquetas.push("Datos de Ingreso");
      nuevoArrayTablas.push(InputDataInactivos);
    }
    if (
      CheckPermission(
        "Colaboradores.Inactivos.Ver.InformacionAlta.DatosContratacion"
      )
    ) {
      nuevoArrayEtiquetas.push("Datos de Contratación");
      nuevoArrayTablas.push(HiringDataInactivos);
    }
  }

  if (section === "candidate") {
    nuevoArrayEtiquetas.push("Información Personal");
    nuevoArrayTablas.push(PersonalDataColaboradores);
    nuevoArrayEtiquetas.push("Datos de Ingreso");
    nuevoArrayTablas.push(InputDataColaboradores);
    nuevoArrayEtiquetas.push("Datos de Contratación");
    nuevoArrayTablas.push(HiringDataColaboradores);
  }

  return (
    <List
      title="INFORMACIÓN DE ALTA"
      labels={nuevoArrayEtiquetas}
      components={nuevoArrayTablas}
      numTab={2}
      progress={0}
      process={processTab}
    />
  );
};

export default Information;
