import { Grid, Link, } from '@material-ui/core';
import Box from '@mui/material/Box';
import { styleCollaboratorTab as style } from './styleSheet';
import {CreateExcelFile} from './ExcelFunctionality';

interface IProps {
    link: any
    type: string
}

const ReportTable =  ({ link, type}: IProps) => {

    const handleExport = () => {
        CreateExcelFile(link, type);
    }


    return (
        <Grid
            item
            style={{ alignSelf: "center" }}
        >
            <Box>
                <Link href="#">
                    <button className="buttonContent" style={{ width: '73%', marginRight: '30px'}} onClick={()=> handleExport()}>
                        <Grid
                            container
                            direction="row"
                            style={style.containerFilter}
                            justify="space-around"
                        >
                            <Grid item >
                                <div style={{marginLeft: "-50px", marginRight: "-70px", marginTop: '5px'}}>
                                    {/* <img src="/assets/icons/icono-repo.svg" alt="Reporte"/> */}
                                </div>
                            </Grid>
                            <Grid
                                item
                                style={{
                                    alignSelf: "center",
                                    fontSize: "14px",
                                    margin: "0",
                                    color: "#093C5D",
                                    fontWeight: 'bold'
                                }}
                            >
                                Descarga Reporte
                            </Grid>
                        </Grid>
                    </button>
                </Link>
            </Box>
        </Grid>
    )
}




export default ReportTable;
