import { useContext, useState } from "react";
import Switch from "@mui/material/Switch";
import PerceptionContext from "../../context/ConfigPayrollContext/PerceptionContext";
import { FormControlLabel } from "@mui/material";
import { updatePerception } from "../../helpers/Nomina/Perceptions";

const IOSSwitch = (props: any) => {
  const [value, setValue] = useState(props.Value);
  const { state, dispatch } = useContext(PerceptionContext);

  const handleChange = async (event: any) => {
    
    if (value === true) {
      let nuevoValor = false;
      setValue(nuevoValor);
      if (String(props.Type) === "deduction") {
        if (props?.setISRTax) {
          props.setISRTax(false);
        } else if (props?.setTaxBoth) {
          props.setTaxBoth(false);
        } else if (props?.setmuestraCalendario) {
          props.setmuestraCalendario(false);
        }
      } else {
        await updatePerception(
          event,
          state,
          dispatch,
          props.NombreCampo,
          nuevoValor
        );
      }
    } else {
      let nuevoValor = true;
      setValue(nuevoValor);
      if (String(props.Type) === "deduction") {
        if (props?.setISRTax) {
          props.setISRTax(true);
        } else if (props?.setTaxBoth) {
          props.setTaxBoth(true);
        }  else if (props?.setmuestraCalendario) {
          props.setmuestraCalendario(true);
        }
      } else {
        await updatePerception(
          event,
          state,
          dispatch,
          props.NombreCampo,
          nuevoValor
        );
      }
    }
  };

  return (
    <div>
      <FormControlLabel
        disabled={props.disabled}
        control={
          <Switch
            checked={value ? true : false}
            onChange={handleChange}
            name="switch"
          />
        }
        label=""
      />
    </div>
  );
};
export default IOSSwitch;
