/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useContext } from "react";
import { useHistory, useParams } from 'react-router-dom';
import {
  GET_ALL_DATAREPORTS,
  GET_DATAREPORTS,
  GET_CUSTOMDATAREPORT,
  GET_CUSTOM_REPORT_PREVIEW
} from "../../Querys/reports/querys";
import { useQuery, useLazyQuery, useMutation } from "@apollo/client";
import ReportIndividual from "./ReportIndividual";
import { Grid } from "@material-ui/core";
import Box from '@mui/material/Box';
import CachedIcon from '@mui/icons-material/Cached';
import GridOnIcon from '@mui/icons-material/GridOn';
import SendIcon from '@mui/icons-material/Send';
import DownExcel from "../../assets/icons/downExcel.svg";
import TuneIcon from '@mui/icons-material/Tune';
import { DownloadExcel } from "../../helpers/DownloadExcel";
import { CREATE_CUSTOM_REPORT, GET_ALL_CUSTOMREPORTS } from "../../Querys/querys";
import ReportContext from "../../context/ReportsContext/ReportContext";
import AddTable from "../../screens/Colaboradores/Colaboradores/CollaboratorTab/AddTable";
import { SuccessfulAlert } from "../../alerts/successAlerts";

const ReportInfo = () => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const { state, dispatch } = useContext(ReportContext);
  const [infoReport, setInfoReport] = useState<Array<any>>([]);
  const [report, setReport] = useState<Array<any>>([]);
  const [viewFilter, setViewFilter] = useState(true)
  const [selViewFilter, setSelViewFilter] = useState('#093C5D')
  const [viewSquare, setViewSquare] = useState(false)
  const [selViewSquare, setSelViewSquare] = useState('#707070')
  const [deleteColumns, setDeleteColumns] = useState([]);
  const [inpuntValues, setInpuntValues] = useState({});
  const [getReportSingle] = useLazyQuery(GET_DATAREPORTS);
  const [getCustomReportPreview] = useLazyQuery(GET_CUSTOM_REPORT_PREVIEW);
  const { data: reportsInfo } = useQuery(GET_ALL_DATAREPORTS,
    {
      fetchPolicy: 'no-cache',
      skip: id.includes('custom-') && id.includes('preview')
    }
  );
  const { data: customReportsInfo } = useQuery(GET_ALL_CUSTOMREPORTS,
    {
      fetchPolicy: 'no-cache',
      skip: !id.includes('custom-') && id.includes('preview')
    }
  );
  const { data: reportIndividual, loading } = useQuery(GET_DATAREPORTS, {
    variables: { getDatareportsId: id },
    skip: id.includes('custom-') && id.includes('preview'),
    fetchPolicy: 'no-cache'
  });
  const { data: customReportIndividual, loading: loadingCustomReport } = useQuery(GET_CUSTOMDATAREPORT, {
    variables: { getDatareportsId: id?.replace('custom-', '') },
    skip: !id.includes('custom-') && id.includes('preview'),
    fetchPolicy: 'no-cache'
  });
  const [createCustomReport] = useMutation<{
    variables: {
      input: {
        name: string;
        rows?: any[];
        columns?: any[];
      }
    }
  }>(CREATE_CUSTOM_REPORT);

  const filterMyReports = (arrayReports: any) => {
    let reports = arrayReports.filter((report: any) => {
      return report.id === id
    }
    )
    return reports
  }

  const getPreviewInfo = async () => {
    let { data } = await getCustomReportPreview({
      variables: { input: state.previewData },
    })
    if (data?.GET_CUSTOM_REPORT_PREVIEW?.length > 0) {
      const reportJson = JSON.parse(data.GET_CUSTOM_REPORT_PREVIEW)
      setReport(reportJson);
    }
  }

  useEffect(() => {
    if (state.previewData) {
      getPreviewInfo();
    }
  }, [state.previewData])

  useEffect(() => {
    if (reportsInfo && reportsInfo.GET_ALL_DATAREPORTS) {
      setInfoReport(filterMyReports(reportsInfo.GET_ALL_DATAREPORTS));
    }
  }, [reportsInfo]);

  useEffect(() => {
    if (customReportsInfo && customReportsInfo.GET_ALL_CUSTOMREPORTS) {
      setInfoReport(filterMyReports(customReportsInfo.GET_ALL_CUSTOMREPORTS.map((customReport: any) => ({
        ReportDate: customReport.dateAdd,
        ReportDescription: "",
        ReportName: customReport.name,
        ReportSQL: customReport.sqlQueryRows + ';' + customReport.sqlQueryColumns,
        ReportType: "Personalizado",
        id: 'custom-' + customReport.id,
        userAdd: "custom_report"
      }))));
    }
  }, [customReportsInfo]);

  useEffect(() => {
    if (reportIndividual && reportIndividual.GET_DATAREPORTS) {
      const objReport = JSON.parse(reportIndividual.GET_DATAREPORTS);
      setReport(objReport)
    }
  }, [reportIndividual]);

  useEffect(() => {
    if (customReportIndividual && customReportIndividual.GET_CUSTOMDATAREPORT) {
      const objReport = JSON.parse(customReportIndividual.GET_CUSTOMDATAREPORT);
      setReport(objReport)
    }
  }, [customReportIndividual]);

  const handleViewFilter = () => {
    viewFilter === true ? setViewFilter(false) : setViewFilter(true);
    viewFilter === true ? setSelViewFilter('#707070') : setSelViewFilter("#093C5D");
  }

  const handleDownExcel = () => {
    if (report.length > 0) {
      const columns = report.map((re: any) => {
        return (Object.keys(re))
      });

      const valuesRep = report.map((data: any) => {
        return Object.values(data)
      });
      let nameFile = infoReport[0]?.ReportName
      let sheetName = infoReport[0]?.ReportName
      DownloadExcel(nameFile, sheetName, columns[0], valuesRep);
    }
  }

  const handleViewSquare = () => {
    viewSquare === false ? setViewSquare(true) : setViewSquare(false);
    viewSquare === false ? setSelViewSquare("#093C5D") : setSelViewSquare('#707070');
  }

  const handleReload = async () => {
    setDeleteColumns([]);
    setInpuntValues({});
    let { data } = await getReportSingle({
      variables: { getDatareportsId: id },
    })
    if (data?.GET_DATAREPORTS?.length > 0) {
      const reportJson = JSON.parse(data.GET_DATAREPORTS)
      setReport(reportJson);
    }
  }

  const goBack = () => {
    dispatch({
      type: 'PREVIEW_DATA',
      payload: null
    })
    history.push('/informes')
  }

  const createReport = async () => {
    await createCustomReport({
      variables: {
        input: {
          name: state.previewData?.name,
          ...(state.previewData?.rows?.length > 0 && { rows: state.previewData?.rows }),
          ...(state.previewData?.columns?.length > 0 && { columns: state.previewData?.columns }),
        }
      }
    })
    await SuccessfulAlert({ text: "Se ha creado el informe correctamente" });
    goBack()
  }

  return (
    <div>
      <Box mt={3} ml={5} className="TitleReportSingle" mb={2} p={5} pb={3} pt={0}>
        <Grid
          container
          justify="flex-start"
          alignItems="center"
        >
          <Grid className="btnsInformes">
            <Grid className="btnsInformesFiltros" >
              {state.previewData && <>
                {`Personalizado / ${state.previewData?.name}`}
              </>}
              {state.previewData === null && (
                <>
                  {`${infoReport[0]?.ReportType} / ${infoReport[0]?.ReportName}`}
                </>
              )}
            </Grid>
            {
              state.previewData === null && (
                <Grid className="actionsReports" >
                  <TuneIcon
                    className="pointR"
                    sx={{ color: selViewFilter }}
                    onClick={() => handleViewFilter()}
                  />
                  <img
                    className='downExcelReport'
                    src={DownExcel}
                    onClick={() => handleDownExcel()
                    }
                    alt="descargar excel" />
                  <CachedIcon
                    className="pointR"
                    sx={{ color: '#093C5D' }}
                    onClick={() => handleReload()}
                  />
                  <div className="r-line"></div>
                  <GridOnIcon
                    className="pointR"
                    sx={{ color: selViewSquare }}
                    onClick={() => handleViewSquare()}
                  />
                  <div className="r-line"></div>
                  <SendIcon
                    className="senR"
                    sx={{ color: '#093C5D' }}
                  />
                </Grid>
              )
            }
            {
              state.previewData && (
                <div style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '20px',
                }}>
                  <button style={{
                    width: '168px',
                    height: '38px',
                    borderRadius: '6px',
                    background: 'white',
                    border: '1px solid #c7ccdc',
                    fontFamily: 'Roboto, sans-serif',
                    fontSize: '14px',
                    // fontWeight: '700',
                    lineHeight: '16px',
                    letterSpacing: '0px',
                    color: '#093c5d',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    cursor: 'pointer'
                  }}
                    type="button" onClick={goBack}>Regresar</button>
                  <AddTable
                    func={createReport}
                    text="Crear informe"
                    img="report-icon.svg"
                    type="button"
                  />
                </div>
              )
            }
          </Grid>
        </Grid>
      </Box>
      <div className="contenedorReportSingle">
        <ReportIndividual
          report={report}
          setReport={setReport}
          viewFilter={viewFilter}
          viewSquare={viewSquare}
          loading={loading}
          setDeleteColumns={setDeleteColumns}
          deleteColumns={deleteColumns}
          inpuntValues={inpuntValues}
          setInpuntValues={setInpuntValues}
        />
      </div>
    </div>
  )
}

export default ReportInfo
