import styles from './LoadingStyles.module.css';

export const LoadingBits = () =>{
  return <div className={styles.pc_contenedorPrincipal+" "+styles.tituloFacturacionPagos}>
          <div className={styles.contenedorLoading}>
            <div className={styles.loading}></div>
          </div>
        </div>
}

export const LoadingSmallBits = () =>{
  return <div className={styles.pc_contenedorPrincipalSmall+" "+styles.tituloFacturacionPagos}>
          <div className={styles.contenedorLoading}>
            <div className={styles.loadingSmall}></div>
          </div>
        </div>
}