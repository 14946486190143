import { useContext, Fragment } from "react";
import { TextField, Tooltip } from "@material-ui/core";
import Box from '@mui/material/Box';
import { withStyles, Theme } from "@material-ui/core/styles";
import CollaboratorContext from "../../../../context/CollaboratorContext/CollaboratorContext";

const HtmlTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(18),
    border: "1px solid #dadde9",
  },
}))(Tooltip);

const EnfermedadInactivos = () => {
  const { state } = useContext(CollaboratorContext);

  return (
    <>
      <div className="novalidate__border">
        <h6 id="form-dialog-title" className="form-dialog-title-class">
          Declaro a continuación mi estado de salud actual…
          <HtmlTooltip
            title={
              <Fragment>En caso de no contar con alguno escribe 'Ninguno'</Fragment>
            }
          >
            <img
              src="/assets/icons/PreguntaAzul.png"
              alt="Question"
              height="15"
            />
          </HtmlTooltip>
        </h6>
        <Box mt={2} mb={2}>
          <TextField
            name="Alergias"
            defaultValue={state.collaborator?.Alergias}
            autoFocus={true}
            label="Alergías"
            variant="outlined"
            size="small"
            fullWidth={true}
            InputProps={{
              readOnly: true,
            }}
            style={{
              opacity: '0.5',
            }}
          />
        </Box>

        <Box mt={2} mb={2}>
          <TextField
            name="PadEnfer"
            defaultValue={state.collaborator?.PadEnfer}
            autoFocus={true}
            label="Padecimientos / Enfermedades físicos y mentales"
            variant="outlined"
            size="small"
            fullWidth={true}
            InputProps={{
              readOnly: true,
            }}
            style={{
              opacity: '0.5',
            }}
          />
        </Box>

        <Box mt={2} mb={2}>
          <TextField
            name="Cirugias"
            defaultValue={state.collaborator?.Cirugias}
            autoFocus={true}
            label="Cirugías"
            variant="outlined"
            size="small"
            fullWidth={true}
            InputProps={{
              readOnly: true,
            }}
            style={{
              opacity: '0.5',
            }}
          />
        </Box>

        <Box mt={2} mb={2}>
          <TextField
            name="TraMeRe"
            defaultValue={state.collaborator?.TraMeRe}
            autoFocus={true}
            label="Tratamientos médicos o rehabilitaciones"
            variant="outlined"
            size="small"
            fullWidth={true}
            InputProps={{
              readOnly: true,
            }}
            style={{
              opacity: '0.5',
            }}
          />
        </Box>
      </div>
      <div></div>
    </>
  );
};

export default EnfermedadInactivos;
