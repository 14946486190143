import {createContext, Dispatch} from 'react';

type InitialStateType = {
    open: boolean;
    showEdit: boolean;
    showEliminar: boolean;
    showHolidays: boolean;
    _id: string;
    value: string;
    client: string;
    title: string;
    PolicyName: string,
    EconomicDays: string,
    AnniversaryVacationPremium: string,
    PantryValueType: string,
    PantryValueCap: string,
    PantryValue: string,
    SavingsFundType: string,
    SavingsFundCap: string,
    SavingsFund: string,
    RestaurantValue: string,
    RestaurantValueType: string,
    RestaurantValueCap: string,
    AbsenceDiscount: boolean,
    DisabilityDiscount: string,
    VoucherCost: string,
    DiscountDay: string,
    SeniorityDate: string,
    ContractStartDate: string,
    PantryType: string,
    SavingsType: string,
    RestaurantType: string,
    idPayrollGroup: string,
    VacationBonusType: string,
    VacationBonus: string,
    VacationBonusCap: string,
    DiasAguinaldo: string
};

type ModalPartial = {
    state: InitialStateType,
    dispatch: Dispatch<any>,
}
export const initialState = {
    open: false,
    showEdit: false,
    showEliminar: false,
    showHolidays: false,
    _id: '',
    value: '',
    client: '',
    title: '',
    PolicyName: '',
    EconomicDays: '',
    AnniversaryVacationPremium: '',
    PantryValueType: '',
    PantryValueCap: '',
    PantryValue: '',
    SavingsFundType: '',
    SavingsFundCap: '',
    SavingsFund: '',
    RestaurantValue: '',
    RestaurantValueType: '',
    RestaurantValueCap: '',
    AbsenceDiscount: false,
    DisabilityDiscount: '',
    VoucherCost: '',
    DiscountDay: '',
    SeniorityDate: '',
    ContractStartDate: '',
    PantryType: '',
    SavingsType: '',
    RestaurantType: '',
    idPayrollGroup: '',
    VacationBonusType: '',
    VacationBonus: '',
    VacationBonusCap: '',
    DiasAguinaldo: '',
}

const ModalInitialState = {
    state: initialState,
    dispatch: () => null
}

const PoliticContext = createContext<ModalPartial>(ModalInitialState);

export default PoliticContext;