import MyMoodContent from '../components/MyMood/Content';
import MyMoodState from '../context/MyMoodContext/MyMoodState';


const MyMoodScreen = () => {

    return (
        <div>
            <MyMoodState>
                <MyMoodContent></MyMoodContent>
            </MyMoodState>
        </div>
    )
};

export default MyMoodScreen;
