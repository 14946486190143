import { useContext } from "react";
import {
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  Select,
  TextField,
} from "@material-ui/core";
import Box from '@mui/material/Box';
import CollaboratorContext from "../../../../context/CollaboratorContext/CollaboratorContext";
import { paisesEstados } from "../../../../helpers/Json/paises-estados";
import moment from "moment";

const InformationDataInactivos = () => {
  const { state } = useContext(CollaboratorContext);

  return (
    <div className="novalidate__border">
      <Box mb={2}>
        <FormControl variant="outlined" fullWidth={true} size="small">
          <InputLabel htmlFor="outlined-age-native-simple"
          style={{
            opacity: '0.5',
          }}
          >
            Nivel de estudios
          </InputLabel>
          <Select
            native
            label={"NivelEstudios"}
            name="educationalLevel"
            defaultValue={state.collaborator?.educationalLevel || ""}
            autoFocus={true}
            disabled={true}
          >
            <option
              value={state.collaborator?.educationalLevel || ""}
              disabled={true}
            >
              {state.collaborator?.educationalLevel || ""}
            </option>
            <option value={"SECUNDARIA"}>SECUNDARIA</option>
            <option value={"PREPARATORIA"}>PREPARATORIA</option>
            <option value={"LICENCIATURA"}>LICENCIATURA</option>
            <option value={"POSGRADO"}>POSGRADO</option>
          </Select>
          <FormHelperText>
            {!state.collaborator?.educationalLevel && "Obligatorio"}
          </FormHelperText>
        </FormControl>
      </Box>
      <Grid direction="row" container spacing={2}>
        <Grid xs item container justify="flex-start" alignItems="center">
          <span className="Fecha-de-nacimiento">Fecha de nacimiento</span>
        </Grid>
        <Grid
          xs
          item
          direction="row"
          container
          justify="flex-end"
          alignItems="center"
        >
          <TextField
            type="date"
            name="dateOfBirth"
            defaultValue={
              moment(state.collaborator?.dateOfBirth).format("YYYY-MM-DD") ?? ""
            }
            variant="outlined"
            size="small"
            //onChange={(e) => validDate(e)}
            InputProps={{
              readOnly: true,
              inputProps: {
                max: moment().format("YYYY-MM-DD"),
              },
            }}
            autoFocus={true}
            style={{
              opacity: '0.5',
            }}
          />
        </Grid>
      </Grid>

      <Box mt={2} mb={2}>
        <FormControl variant="outlined" fullWidth={true} size="small">
          <InputLabel htmlFor="outlined-age-native-simple"
          style={{
            opacity: '0.5',
          }}
          >
            Lugar de nacimiento
          </InputLabel>
          <Select
            native
            label={"LugarNacimiento"}
            name="placeOfBirth"
            defaultValue={state.collaborator?.placeOfBirth || ""}
            disabled={true}
          >
            <option
              value={state.collaborator?.placeOfBirth || ""}
              disabled={true}
            >
              {state.collaborator?.placeOfBirth || ""}
            </option>
            {paisesEstados.map((country: any, index: number) => (
              <option key={index} value={country.country}>
                {country.country}
              </option>
            ))}
          </Select>
          <FormHelperText>
            {!state.collaborator?.placeOfBirth && "Obligatorio"}
          </FormHelperText>
        </FormControl>
      </Box>

      <Grid direction="row" container spacing={2}>
        <Grid xs item>
          <FormControl variant="outlined" fullWidth={true} size="small">
            <InputLabel htmlFor="outlined-age-native-simple"
            style={{
              opacity: '0.5',
            }}
            >Género</InputLabel>
            <Select
              native
              label={"Género"}
              name="gender"
              defaultValue={state.collaborator?.gender || ""}
              autoFocus={true}
              disabled={true}
            >
              <option value={state.collaborator?.gender || ""} disabled={true}>
                {state.collaborator?.gender || ""}
              </option>
              <option value={"Masculino"}>Masculino</option>
              <option value={"Femenino"}>Femenino</option>
            </Select>
            <FormHelperText>
              {!state.collaborator?.gender && "Obligatorio"}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid xs item>
          <FormControl variant="outlined" fullWidth={true} size="small">
            <InputLabel htmlFor="outlined-age-native-simple"
            style={{
              opacity: '0.5',
            }}
            >
              Estado civil
            </InputLabel>
            <Select
              native
              label={"EstadoCivil"}
              name="civilStatus"
              defaultValue={state.collaborator?.civilStatus || ""}
              autoFocus={true}
              disabled={true}
            >
              <option
                value={state.collaborator?.civilStatus || ""}
                disabled={true}
              >
                {state.collaborator?.civilStatus || ""}
              </option>
              <option value={"Soltero(a)"}>Soltero(a)</option>
              <option value={"Casado(a)"}>Casado(a)</option>
              <option value={"Unión Libre"}>Unión Libre</option>
            </Select>
            <FormHelperText>
              {!state.collaborator?.civilStatus && "Obligatorio"}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid xs item>
          <FormControl variant="outlined" fullWidth={true} size="small">
            <TextField
              type="number"
              name="children"
              defaultValue={
                state.collaborator?.children ? state.collaborator?.children : 0
              }
              autoFocus={true}
              label="Hijos"
              variant="outlined"
              size="small"
              fullWidth={true}
              InputProps={{
                readOnly: true,
              }}
              style={{
                opacity: '0.5',
              }}
            />
          </FormControl>
        </Grid>
      </Grid>
    </div>
  );
};

export default InformationDataInactivos;
