import {useEffect, useState} from "react";
import '../ConfigPayroll/PayrollGroup.css'
import { FormControl, Grid, TextField } from "@material-ui/core";
import { useMutation, useQuery } from "@apollo/client";
import { GET_ALL_EVENTUALPAYROLL_SETTINGS, UPDATE_CALENDAR_EVENTUAL_PAYROLL } from "../../Querys/querys";
import saveIcon from "../../assets/svg/Save_Icon.svg";
import { SuccessfulAlert } from "../../alerts/successAlerts";
import { format } from "date-fns";
import { ErrorAlert } from "../../alerts/errorAlert";
import moment from "moment";


interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
};

const PayrollEventualGroupTable = (props: TabPanelProps) =>{
    const [payroll, setPayroll] = useState<any>([]);
    const resultPayrollGroup = useQuery(GET_ALL_EVENTUALPAYROLL_SETTINGS);
    const allPayrollGroup = resultPayrollGroup.data?.GET_ALL_EVENTUALPAYROLL_SETTINGS;

    const [update] = useMutation(UPDATE_CALENDAR_EVENTUAL_PAYROLL,{
      refetchQueries:[{query: GET_ALL_EVENTUALPAYROLL_SETTINGS}]
    })

    useEffect(()=>{
        if(allPayrollGroup){
            setPayroll(allPayrollGroup)
        }
    },[allPayrollGroup])

    const setDate = (field:string, value: any, id: any) => {
      const newPayroll: any[] = [];
      for(const item of payroll){
        const newData = JSON.parse(JSON.stringify(item));
        if(item.id === id){
          newData[field] = value;
        }
        newPayroll.push(newData);
      }
      setPayroll(newPayroll)
    }

    const updatePayroll = (id: any) => {
      payroll?.forEach((item: any) => {
        if(item.id === id){
          if(!item.initDate || !item.endDate || !item.applyDate){
            ErrorAlert({
              text: 'Es necesario completar los campos para editar la nómina.'
            });
          }else if(new Date(item.endDate).getTime() < new Date(item.initDate).getTime()){
            ErrorAlert({
              text: 'La fecha de fin no puede ser menor a la fecha de inicio.'
            });
          }else{
            update({
              variables:{
                id: item.id,
                input:{
                  initDate: moment(item.initDate).toISOString(),
                  endDate: moment(item.endDate).toISOString(),
                  applyDate: moment(item.applyDate).toISOString()
                }
              }
            }).then((res: any) => {
              SuccessfulAlert({
                title: "¡Exito!",
                text: "¡Registro editado!",
              });
            })
          }

        }
      })
    }


    return(
      <>
        <div className="contenedorPrincipal">
          <div className="contenedorHeader">
            <div>
              <span className="tituloHeader">Nóminas eventuales</span>
            </div>
            <div>

            </div>
          </div>
          <div className="contenedorTabla">
            <div className="contenedorTituloTabla">
              <Grid container spacing={1}>
                <Grid item xs={3} className="tituloTabla">
                  <span className="textotituloTabla">Nombre</span>
                </Grid>
                <Grid item xs={2} className="tituloTabla titleCenter"> 
                  <span className="textotituloTabla">Fecha Inicio</span>
                </Grid>
                <Grid item xs={2} className="tituloTabla titleCenter">
                  <span className="textotituloTabla">Fecha fin</span>
                </Grid>
                <Grid item xs={2} className="tituloTabla titleCenter"> 
                  <span className="textotituloTabla">Fecha aplicar</span>
                </Grid>
                <Grid item xs={3} className="tituloTabla titleCenter">
                  <span className="textotituloTabla">Editar</span>
                </Grid>
              </Grid>
            </div>
            <div>
                <ul>
                  {payroll?.map((lis:any, index: any)=>
                    <li className="listaTabla" key={lis.id}>
                      <Grid container spacing={1}>
                        <Grid item xs={3} className="tituloTabla">
                            <div className="tablaColumnaFlex">
                              <span className="textoTabla">{lis.Reason}</span>
                            </div>
                        </Grid>
                        <Grid item xs={2} className="tituloTabla">
                          <div className="tablaColumnaFlex">
                            <FormControl 
                                fullWidth 
                                size="small"
                                variant="outlined" 
                            >
                              <TextField
                                type="date"
                                autoFocus={true}
                                onChange={(e)=>{
                                  setDate('initDate', e.target.value, lis.id);
                                }}
                                defaultValue={ lis.initDate ? format(new Date(lis.initDate), 'yyyy-MM-dd')  : '' }
                                name="initDate"
                                variant="outlined"
                                size="small"
                                
                                InputProps={{
                                  readOnly: false,
                                }}
                                inputProps={{ min: moment().startOf('year').subtract(5, 'years').format('yyyy-MM-DD'), max: moment().endOf('year').add(5, 'years').format('yyyy-MM-DD')}}
                              />
                            </FormControl>
                          </div>
                        </Grid>
                        <Grid item xs={2} className="tituloTabla">
                          <div className="tablaColumnaFlex">             
                            <FormControl 
                                fullWidth 
                                size="small"
                                variant="outlined" 
                            >
                              <TextField
                                type="date"
                                autoFocus={true}
                                onChange={(e)=>{
                                  setDate('endDate', e.target.value, lis.id);
                                }}
                                defaultValue={ lis.endDate ? format(new Date(lis.endDate), 'yyyy-MM-dd')  : '' }
                                name="endDate"
                                variant="outlined"
                                size="small"
                                InputProps={{
                                  readOnly: false,
                                }}
                                inputProps={{ min: moment().startOf('year').subtract(5, 'years').format('yyyy-MM-DD'), max: moment().endOf('year').add(5, 'years').format('yyyy-MM-DD')}}
                              />
                            </FormControl>
                          </div>
                        </Grid>
                        <Grid item xs={2} className="tituloTabla">
                          <div className="tablaColumnaFlex">   
                            <FormControl 
                              fullWidth 
                              size="small"
                              variant="outlined" 
                            >
                              <TextField
                                type="date"
                                autoFocus={true}
                                onChange={(e)=>{
                                  setDate('applyDate', e.target.value, lis.id);
                                }}
                                defaultValue={ lis.applyDate ? format(new Date(lis.applyDate), 'yyyy-MM-dd')  : '' }
                                name="applyDate"
                                variant="outlined"
                                size="small"
                                InputProps={{
                                  readOnly: false,
                                }}
                                inputProps={{ min: moment().startOf('year').subtract(5, 'years').format('yyyy-MM-DD'), max: moment().endOf('year').add(5, 'years').format('yyyy-MM-DD')}}
                              />
                            </FormControl>
                          </div>
                        </Grid>
                        <Grid item xs={3} className="tituloTabla">
                          <div className="tablaColumnaFlex">
                            <a
                              style={{ 
                                      marginRight: "5px", 
                                      marginLeft: "5px",
                                      cursor: "pointer",
                                    }}
                              target="_blank"
                              className="imgSave"
                              onClick={(e) => {
                                e.preventDefault();
                                updatePayroll(lis.id);
                              }}
                              href="/#"

                            >
                              <img
                                src={saveIcon}
                                style={{ marginRight: "auto", marginLeft: "auto" }}
                                
                                alt="DownloadFile"
                              />
                            </a>
                          </div>
                        </Grid>
                        
                      </Grid>
                    </li>  
                  )}
                </ul>
            </div>
          </div>
        </div>  
      </>
    )
}

export default PayrollEventualGroupTable