import { Grid, Box, Input, Select, FormControl, TextField, MenuItem, SelectChangeEvent } from "@mui/material";
import { GET_ALL_ENTERPRISE, CREATE_TRANSACTION } from "../../Querys/querys";
import { useFormik } from "formik";
import { Form } from "semantic-ui-react";
import React, { useState } from 'react';
import { useMutation, useQuery } from "@apollo/client";
import "./PaymentsCentral.css";
import { IEnterprise } from '../../interfaces/Enterprise';
import { Transaction } from '../../interfaces/Transaction';
import { SuccessfulAlert } from "../../alerts/successAlerts";
import SearchIcon from '@mui/icons-material/Search';

function PaymentsCentralHistory () {

  const {data: company_data} = useQuery(GET_ALL_ENTERPRISE);
  const enterprises = company_data?.GET_ALL_ENTERPRISE;
  const [selectedEnterprise, setSelectedEnterprise] = useState('');
  const [tableRows, setTableRows] = useState<Transaction[]>([]);
  const [transactions, setTransactions] = useState<Transaction[]>([]);

  const onSelectEnterprise = ( e: SelectChangeEvent ) => {
    setSelectedEnterprise(e.target.value)
  }

  const getTransactions = () => {

  }

  const search = (e:React.ChangeEvent<HTMLInputElement>) => {
    if(e.target.value !== '') {
      let expression = new RegExp(`${e}.*`, "i")
      const nuevoFiltrado = tableRows?.filter((item:any) => expression.test(item.fullname))
      setTableRows(nuevoFiltrado)
    }else{
      getTransactions()
    }
  }

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Box className="header-container">
            <FormControl fullWidth={true}>
              <Input id="search" name="search" onChange={search} placeholder="Buscar por nombre" endAdornment={ <SearchIcon></SearchIcon> }></Input>
            </FormControl>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box className="header-container">
            <p className="header-title">Selecciona una empresa:</p>
            <FormControl fullWidth={true}>
              <Select name="company" onChange={onSelectEnterprise} value={ selectedEnterprise }>
                <MenuItem></MenuItem>
                {
                  enterprises?.map((enterprise:IEnterprise,key:number)=>(
                    <MenuItem value={enterprise.id} key={enterprise.id}>{ enterprise.name }</MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          </Box>
        </Grid>
        <Grid item xs={12} md={12}>
          <div className={"table-container"}>
            <table className={"table"}>
              <thead className={"table-header"}>
                <tr>
                  <td className={"table-title"}>SBC</td>
                  <td className={"table-title"}>Movimiento</td>
                  <td className={"table-title"}>Fecha del movimiento</td>
                  <td className={"table-title"}>Estatus</td>
                </tr>
              </thead>
              <tbody>
                {tableRows?.map((item:Transaction, key:number)=>(
                <tr className={"table-row"} key={key}>
                  <td>{}</td>
                  <td>{}</td>
                  <td>{}</td>
                  <td>{}</td>
                </tr>
                ))}
              </tbody>
            </table>
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

export default PaymentsCentralHistory;