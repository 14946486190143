import {useState, useContext} from "react";
import {Dialog} from "@material-ui/core"
import styles from './PayrollModals.module.css'
import { clearPayrollProcess } from "../../../context/PayrollProcess/Actions";
import PayrollProcessContext from "../../../context/PayrollProcess/PayrollProcessContext";
import { useMutation } from "@apollo/client";
import { SEND_DISPERSION_EVENTUAL,GET_EVENTUALPAYROLL } from "../../../Querys/querys";
import { SuccessfulAlert } from "../../../alerts/successAlerts";

const AceptarDispersarIndividualEventual = (props:any) =>{
  const {totalesDispersar, totales, idRegistro} = props
  const {state, dispatch} = useContext(PayrollProcessContext)
  const [activo, setactivo] = useState(false)
  const [mensaje, setMensaje] = useState('Uno')

  const [sendDispersion] = useMutation(SEND_DISPERSION_EVENTUAL,{
    refetchQueries:[
      {query:GET_EVENTUALPAYROLL, variables:{getEventualPayroll: idRegistro}}
    ]
  })

  const handleClose = () =>{
    clearPayrollProcess({}, dispatch)
  }

  const handleChange = (e: any) =>{
    if(e.target.value === 'CONFIRMAR'){
        setactivo(true)
    }else{
        setactivo(false)
    }
  }

  const agregaSeleccionados = async() =>{
    let Array:any = []
      Array.push({ id: parseInt(state._id) })
    await sendDispersion({
      variables:{
        sendDispersionEventualId: Array
      }
    })
    props.reinicia()
    props.setProcesoDispersion(false)

    setactivo(false)
    SuccessfulAlert({text: "Registro Dispersado"})
    setMensaje((current) => current ='Uno' )
    props.updateDataAfterDispersar();
    handleClose()
  }

  return(
    <Dialog aria-labelledby='costumized-dialog-title' open={state.aceptarDispersarIndividual} fullWidth={false}  onClose={handleClose} maxWidth={"md"}>
      <div className={styles.contenedorPrincipal}>
        <div className={styles.contenedorCerrarModal}>
          {mensaje === 'Tres'?
          null
          :
          <div
          className={styles.cerrarModal}
          onClick={()=> handleClose()}
        >
        </div>
          }

        </div>
        <div className={styles.contenedorPrincipal}>
          <div className={styles.fim_iconoPrincipal}>
            <div className={styles.fim_iconoComplemento}>
            </div>
          </div>
        </div>
        <div className={styles.tituloAtencion}>
          ¡Atenci&oacute;n!
        </div>
        {mensaje === 'Uno'?
          <>
            <div className={styles.em_mensajePrtincipal}>
              Est&aacute;s por dispersar n&oacute;mina a {totalesDispersar}/{totales} colaboradores
            </div>
          <div className={styles.em_mensajePrtincipal}>
            Escribe CONFIRMAR para continuar
          </div>
          <div>
              <input
                className={styles.input}
                type="text"
                onChange={(e) => handleChange(e)}
              />
          </div>
          </>
          :null
          }
          {mensaje === 'Dos'?
            <>
              <div className={styles.em_mensajePrtincipal}>
               Dispersi&oacute;n en proceso, no actualices ni cierres la pantalla
              </div>
              <div className={styles.em_mensajePrtincipal}>
              hasta que el proceso haya terminado.
              </div>
              <div className={styles.contenedorLoadingDispersion}>
                <div className={styles.loading}></div>
              </div>
            </>
          : null
          }
        <div className={styles.em_contenedorBotones}>
              {mensaje === 'Dos'?
              null
              :
              <button
                className={styles.em_botonCancelar}
                onClick={()=> handleClose()}
              >
                Cancelar
              </button>
              }
              {mensaje === 'Uno'?
                (activo === true ?
                  <button 
                    className={`${styles.botonesAtencion} ${styles.continuarAtencion}`}
                    onClick={()=> agregaSeleccionados()}
                  >
                    <div>
                      Confirmar
                    </div>
                  </button>
                  :
                  <button
                    className={`${styles.botonesAtencion} ${styles.continuarAtencionInactivo}`}
                  >
                    <div>
                      Confirmar
                    </div>
                  </button>
                )
                :null
              }
            </div>
      </div>
    </Dialog>
  )
}

export default AceptarDispersarIndividualEventual