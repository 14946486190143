import { useQuery } from "@apollo/client";
import { Grid } from "@material-ui/core";
import Box from '@mui/material/Box';
import { useHistory, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import styles from "../../src/components/Payroll/PayrollStyles.module.css"
import { 
        GET_EVENTUALPAYROLLFINISH,
        GET_EVENTUALCOLLABORATOR_FINISH
      } from "../Querys/querys";
import CustomTabs from '../components/Collaborators/Tab/CustomTabs';
import CustomTab from '../components/Collaborators/Tab/CustomTabMain';
import {GeneralPieChart} from "../components/Dashboard/GeneralPieChart";
import { formatterMxn } from "../helpers/formatoMoneda";
import CheckPermission from "../helpers/Administradores/Permissions";

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

const PayrollTerminar = (props: TabPanelProps) => {
  const { id, tab } = useParams<any>();
  const history = useHistory();

  const {data} = useQuery(GET_EVENTUALPAYROLLFINISH,{
    variables:{getEventualPayrollId: id}
  })

  const payrollData = data?.GET_EVENTUALPAYROLLFINISH
  const [payroll, setPayroll] = useState<any>(payrollData)

  const {data: dataFinish} = useQuery(GET_EVENTUALCOLLABORATOR_FINISH,{ variables:{id: id}})

  const graphData = dataFinish?.GET_EVENTUALCOLLABORATOR_FINISH
  const [graph, setGraph] = useState<any>(graphData)

  const handleChange = () =>{}

  useEffect(() => {
    if(graphData){
      setGraph(graphData[0])
    }

  }, [graphData])

  useEffect(() => {
    if(payrollData){
      setPayroll(payrollData[0])
    }
  }, [payrollData])

  return (
    <>
      <Box mt={3} ml={5} className="Title">
        Nóminas
      </Box>
      <Box p={5} pb={3} pt={0}>
        <Grid
          container
          justify="flex-start"
        >
          <CustomTabs
            //Tiene que ser 3 para que se muestre la pestaña de Eventuales
            value={3}
            onChange={handleChange}
            aria-label="simple tabs example"
          > 
            <CustomTab label="Incidencias" value={0} />
            {CheckPermission("Nominas.PreNomina") && (
              <CustomTab label="Pre-Nómina" value={1} />
            )}
            {CheckPermission("Nominas.FiniquitoLiquidacion") && (
            <CustomTab label="Finiquito / Liquidación" value={2} /> )}
            {CheckPermission("Nominas.Eventuales") && (
            <CustomTab label="Eventuales" value={3} />)}
             {CheckPermission("Nominas.Historicas") && (
            <CustomTab label="Históricas" value={4} />)}
             {CheckPermission("Nominas.Calculadora") && (
            <CustomTab label="Calculadora" value={5} />)}
            {CheckPermission("Nominas.Reportes") && (
            <CustomTab label="Reportes" value={6} />)}
          </CustomTabs>
        </Grid>
      </Box>
      <div className={styles.pc_contenedorTitulo}>
        <div className={styles.pc_contenedorProceso}>
          <div>
            <span className={styles.pc_tituloProceso}>Calcular</span>
            {
              payroll?.payroll_type !== "Vales de Despensa"? <span className={styles.pc_tituloProceso}>Dispersar</span>: null
            }
            
            <span className={styles.pc_tituloProceso}>Timbrar</span>
            <span className={styles.pc_tituloProceso}>Terminar</span>
          </div>
          <div className={styles.pc_contenedorIconoSeguimiento}>
            <div className={styles.pc_circuloAmarillo}>
              <div className={styles.pd_iconoCompleto}></div>
            </div>
            <div className={styles.pc_lineaSeguimientoCompleto}></div>
            <div className={styles.pc_circuloAmarillo}>
              <div className={styles.pd_iconoCompleto}></div>
            </div>
            {
              payroll?.payroll_type !== "Vales de Despensa"? <>
              <div className={styles.pc_lineaSeguimientoCompleto}></div>
              <div className={styles.pc_circuloAmarillo}>
                <div className={styles.pd_iconoCompleto}></div>    
              </div>
              </>: null
            }
            
            <div className={styles.pc_lineaSeguimientoCompleto}></div>
            <div className={styles.pc_circuloAmarillo}>
            <div className={styles.pc_circuloBlanco}></div> 
            </div>
          </div>
        </div>
        <div className={styles.pc_contenedorBotones}></div>
      
      </div>
      <div className={styles.pc_tituloContenedorPrincipal}>
        <div className={styles.pc_contenedorNombre}
        style={{
          marginRight: "auto",
          marginLeft: "auto",
          textAlign: "center",
        }}
        >
          <div>
            <span className={styles.pc_tituloPrincipal}>{tab} {payroll?.pantryVouchersMonth} {payroll?.company_name}</span>
          </div>
 
        </div>
      </div>
      <div className={styles.p_contenedorTablaDispersion}>
        <>
          <Grid direction="row" container justify="flex-start">
            <Grid item xs={12} sm={6} md={6} lg={6} spacing={5}
            className={styles.divGraphicEventual}
            >
              <div className={styles.divGraphicConteiner}>
                <Box
                  className={styles.divGraphic}
                  style={{
                    paddingTop: "30px",
                    height: "320px",
                  }}
                >
                  <Grid
                    direction="row"
                    container
                    justify="flex-start"
                    alignItems="center"
                  >
                    <Grid
                      className={styles.alineacionTituloGraficas}
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                    >
                      <div
                      style={{
                        marginBottom: "10px",
                      }}
                      >
                        <span
                          className={styles.tituloGraficasEventual}
                        >
                          {payroll?.payroll_type}{ " " }{"timbrados"}
                        </span>
                      </div>
                    </Grid>
                    <Grid
                      className={styles.alineacionTituloGraficas}
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                    >
                      <span className={styles.tituloGraficasEventualCantidad}>{graph?.timbresPayroll}</span>
                    </Grid>
                    <Grid
                      className={styles.alineacionTituloGraficas}
                      item
                      xs={6}
                      sm={6}
                      md={6}
                      lg={6}
                      xl={6}
                    >
                      <div>
                        <GeneralPieChart 
                        percentage={ graph?.timbresCollaboratorsPercent } 
                        tipo={'Colaboradores'} 
                        number={String(graph?.timbresPayroll)} 
                        textSize={'12px'} color={'#4FAEEB'} 
                        y={45}
                        />
                      </div>
                    </Grid>
                    <Grid
                      className={styles.alineacionTituloGraficas}
                      item
                      xs={6}
                      sm={6}
                      md={6}
                      lg={6}
                      xl={6}
                    >
                      <div>
                        <GeneralPieChart 
                        percentage={graph?.totalTimbradoPercent} 
                        number={formatterMxn(graph?.totalTimbrado)} 
                        textSize={'12px'} 
                        color={'#4FAEEB'}
                        y={52}
                        />
                      </div>
                    </Grid>
                    <Grid
                      className={styles.alineacionTituloGraficas}
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                    >
                      <div
                      style={{
                        width: "80%",
                        marginLeft: "auto",
                        marginRight: "auto",
                      }}
                      > 
                        <hr
                        style={{
                          borderColor: "#C7CCDC",
                          opacity: "0.5",
                        }}
                        />
                      </div>
                    </Grid>
                    <Grid
                      className={styles.alineacionTituloGraficas}
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                    >
                      <div
                      style={{
                        marginBottom: "10px",
                      }}
                      >
                        <span
                          className={styles.tituloGraficasEventual}
                        >
                          Pendientes de timbrado
                        </span>
                      </div>
                    </Grid>
                    <Grid
                      className={styles.alineacionTituloGraficas}
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                    >
                      <span className={styles.tituloGraficasEventualCantidadAmarillo}>{graph?.withoutTimbre}</span>
                    </Grid>
                  </Grid>
                </Box>
              </div>
            </Grid>
          </Grid>
      
        </>
       
      </div>
      <div>
        <div className={styles.pd_contenedorBarraEstado}>
          <div className={styles.pd_barraEstado} >
            <div className={styles.pd_contenedorRegreso}
              onClick={() => history.push(`/EventualPayrollTimbrar/${id}/${tab}`)}>
              <div className={styles.pd_botonRegreso}></div>
              <div>Regresar a "Timbrar"</div>
            </div>
            <button className={styles.pd_botonSalir}
              onClick={() => history.push(`/payroll`)}
            >
              Salir
            </button>
          </div>
        </div>
      </div>

    </>
  );
};

export default PayrollTerminar;
