import { createContext, Dispatch } from 'react';
import { Objective } from '../../interfaces/Objective.interface';
import { Campaign } from '../../interfaces/Campaign.interface';

type InitialStateType = {
  createModal: boolean;
  editModal: boolean;
  duplicateModal: boolean;
  selectCollaboratorModal: boolean;
  addEvidenceModal: boolean;
  campaignId: number | null;
  collaboratorId: number | null;
  collaboratorsIds: number[];
  collaboratorName: string | null;
  objectives: Objective[];
  selectedObjective: Objective | null;
  campaign: Campaign | null;
  activeTable: "campaign" | "collaborator";
  evaluateObjectiveModal: boolean;
  collaboratorObjectivesCount: number;
  userFromLink: {
    id: number;
    name: string;
    image: string;
  },
  isFromLink: boolean;
  rol: string;
  displayNotifyLeaderButton?: boolean;
  displayApproveObjectiveButtons?: boolean;
};

type ModalPartial = {
  state: InitialStateType,
  dispatch: Dispatch<any>,
}

export const initialState: InitialStateType = {
  createModal: false,
  editModal: false,
  duplicateModal: false,
  selectCollaboratorModal: false,
  addEvidenceModal: false,
  objectives: [],
  campaignId: 0,
  collaboratorId: 0,
  collaboratorsIds: [],
  collaboratorName: "",
  selectedObjective: null,
  campaign: null,
  activeTable: "campaign",
  evaluateObjectiveModal: false,
  collaboratorObjectivesCount: 0,
  userFromLink: {
    id: 0,
    name: "",
    image: "",
  },
  isFromLink: false,
  rol: 'collaborator',
  displayNotifyLeaderButton: false,
  displayApproveObjectiveButtons: false,
}

export enum Types {
  CREATE_MODAL = 'CREATE_MODAL',
  CANCEL_CREATE_MODAL = 'CANCEL_CREATE_MODAL',
  EDIT_MODAL = 'EDIT_MODAL',
  CANCEL_EDIT_MODAL = 'CANCEL_EDIT_MODAL',
  DUPLICATE_MODAL = 'DUPLICATE_MODAL',
  CANCEL_DUPLICATE_MODAL = 'CANCEL_DUPLICATE_MODAL',
  SELECT_COLLABORATOR_MODAL = 'SELECT_COLLABORATOR_MODAL',
  CANCEL_SELECT_COLLABORATOR_MODAL = 'CANCEL_SELECT_COLLABORATOR_MODAL',
  ADD_EVIDENCE_MODAL = 'ADD_EVIDENCE_MODAL',
  CANCEL_ADD_EVIDENCE_MODAL = 'CANCEL_ADD_EVIDENCE_MODAL',
  SET_SELECTED_OBJECTIVE = 'SET_SELECTED_OBJECTIVE',
  CLEAR_SELECTED_OBJECTIVE = 'CLEAR_SELECTED_OBJECTIVE',
  SET_CAMPAIGN_ID = 'SET_CAMPAIGN_ID',
  SET_CAMPAIGN_INFO = 'SET_CAMPAIGN_INFO',
  SET_COLLABORATOR_OBJECTIVES_COUNT = 'SET_COLLABORATOR_OBJECTIVES_COUNT',
  SET_COLLABORATOR_ID = 'SET_COLLABORATOR_ID',
  SET_ACTIVE_TABLE = 'SET_ACTIVE_TABLE',
  EVALUATE_OBJECTIVE_MODAL = 'EVALUATE_OBJECTIVE_MODAL',
  CANCEL_EVALUATE_OBJECTIVE_MODAL = 'CANCEL_EVALUATE_OBJECTIVE_MODAL',
  SET_COLLABORATOR_NAME = 'SET_COLLABORATOR_NAME',
  SET_COLLABORATORS_IDS = 'SET_COLLABORATORS_IDS',
  CLEAR_OBJECTIVE_STATE = 'CLEAR_OBJECTIVE_STATE',
  SET_USER_FROM_LINK = 'SET_USER_FROM_LINK',
  SET_IS_FROM_LINK = 'SET_IS_FROM_LINK',
  SET_ROL = 'SET_ROL',
  SET_DISPLAY_NOTIFY_LEADER_BUTTON = 'SET_DISPLAY_NOTIFY_LEADER_BUTTON',
  SET_DISPLAY_APPROVE_OBJECTIVE_BUTTONS = 'SET_DISPLAY_APPROVE_OBJECTIVE_BUTTONS',
  RESET_OBJECTIVE_STATE = 'RESET_OBJECTIVE_STATE'
};

const ObjectivesIntialState = {
  state: initialState,
  dispatch: () => null
}

export const ObjectivesContext = createContext<ModalPartial>(ObjectivesIntialState);

export default ObjectivesContext;