import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import "./Header.css"
const styles = () => ({
    badge: {
        backgroundColor: "#6dd400",
    }
});

const UserHeader = ({ classes }: any) => {
    return (
        <div className="help"></div>
    )
};

UserHeader.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(UserHeader);