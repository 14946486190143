import { useContext, useEffect, useRef, useState } from 'react';
import { Dialog, DialogContent, FormControl, Grid, InputLabel, Select, TextField } from '@material-ui/core';
import { useMutation, useQuery } from '@apollo/client';

import styles from '../Objectives.module.css';
import ObjectivesContext, { Types } from "../../../context/ObjectiveContext/ObjectiveContext";
import AddTable from '../../../screens/Colaboradores/Colaboradores/CollaboratorTab/AddTable';
import Button from '../../Button/Button';
import { GET_OBJECTIVE_EVIDENCE, UPDATE_CAMPAIGN_OBJECTIVE } from '../../../Querys/querys';
import { uploadEvidenceFile } from '../../../services/objectiveService';
import moment from 'moment';

const sanitize = require("sanitize-filename");

const AddEvidenceModal = () => {
  const { state, dispatch } = useContext(ObjectivesContext);
  const [editObjective] = useMutation(UPDATE_CAMPAIGN_OBJECTIVE);
  const { data, refetch } = useQuery(GET_OBJECTIVE_EVIDENCE, {
    variables: {
      input: {
        id: parseInt(state?.selectedObjective?.id as any, 10),
      }
    },
    pollInterval: 2000,
    fetchPolicy: "no-cache",
    skip: state?.selectedObjective?.id === undefined,
  })

  useEffect(() => {
    if (state?.selectedObjective?.id) {
      refetch({
        input: {
          id: parseInt(state?.selectedObjective?.id as any, 10),
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.selectedObjective?.id]);

  const [form, setForm] = useState<any>({
    name: "",
    nameIndicator: "",
    metricObjective: "",
    metricIndicator: "",
    weight: "",
    description: "",
    progress: 0,
  });
  const [result, setResult] = useState(0);
  const [evaluation, setEvaluation] = useState<string>("");

  useEffect(() => {
    if (state.selectedObjective) {
      setForm({
        name: state.selectedObjective.ObjectiveName,
        nameIndicator: state.selectedObjective.nameIndicator,
        metricObjective: state.selectedObjective.metricObjective,
        metricIndicator: state.selectedObjective.metricIndicator,
        weight: state.selectedObjective.weight?.toString() + " %",
        description: state.selectedObjective.descriptionIndicator || "",
        progress: state.selectedObjective.progress || 0,
      });
      setEvaluation(state.selectedObjective.evaluation || "");
      setResult(state.selectedObjective.result || 0);
    }
  }, [state.selectedObjective]);

  const cancelButton = () => {
    dispatch({ type: Types.CLEAR_SELECTED_OBJECTIVE });
    dispatch({ type: Types.CANCEL_ADD_EVIDENCE_MODAL });
    dispatch({ type: Types.CANCEL_EVALUATE_OBJECTIVE_MODAL });
    setForm({
      name: "",
      nameIndicator: "",
      metricObjective: "",
      metricIndicator: "",
      weight: "",
      description: "",
    });
  }

  const updateProgressForm = (e: any) => {
    let value = parseInt(e.target.value, 10);
    if (isNaN(value) || value < 0) value = 0;

    const objective = state.selectedObjective?.metricObjective;
    if (value > (objective as any)) value = objective as number;

    const resultValue = value / (objective as number);
    setForm({ ...form, progress: value });
    if (resultValue > 1) {
      setResult(100);
      return;
    }
    setResult(Math.floor(resultValue * 100));
  }

  const updateProgress = () => {
    editObjective({
      variables: {
        input: {
          id: parseInt(state?.selectedObjective?.id as any, 10),
          progress: parseInt(form.progress, 10),
          result: result,
        }
      }
    });
    dispatch({ type: Types.CANCEL_ADD_EVIDENCE_MODAL });
    dispatch({ type: Types.CLEAR_SELECTED_OBJECTIVE });
    setForm({
      name: "",
      nameIndicator: "",
      metricObjective: "",
      metricIndicator: "",
      weight: "",
      description: "",
    });
  }

  const inputFileRef: any = useRef(null);

  const onChangeImage = (e: any) => {
    uploadEvidenceFile(
      e.target.files[0],
      state.selectedObjective?.id as any,
      state.selectedObjective?.idCollaborator?.toString() as any,
      sanitize(e.target.files[0].name),
    );
  }

  const uploadEvidence = () => {
    inputFileRef.current.click();
  }

  const sendReminder = () => {
    console.log("sendReminder");
  }

  const evaluateObjective = (e: any) => {
    setEvaluation(e.target.value);
    editObjective({
      variables: {
        input: {
          id: parseInt(state?.selectedObjective?.id as any, 10),
          evaluation: e.target.value,
        }
      }
    });
  }

  const getFileType = (fileName: string) => {
    if (fileName.includes('.pdf')) return '/assets/icons/icon-pdf.svg'
    if (fileName.includes('.jpg')) return '/assets/icons/icon-jpg.svg'
    if (fileName.includes('.jpeg')) return '/assets/icons/icon-jpg.svg'
    if (fileName.includes('.png')) return '/assets/icons/icon-png.svg'
    if (fileName.includes('.mp4')) return '/assets/icons/icon-mp4.svg'
    if (fileName.includes('.zip')) return '/assets/icons/icon-zip.svg'
    if (fileName.includes('.docx')) return '/assets/icons/icon-doc.svg'
    if (fileName.includes('.xlsx')) return '/assets/icons/icon-xlsx.svg'
    return '/assets/icons/icon-pdf.svg'
  }

  return (
    <Dialog
      aria-labelledby="customized-dialog-title"
      open={state.addEvidenceModal || state.evaluateObjectiveModal}
      fullWidth={true}
    >
      <h2 id="form-dialog-title" className="text-center">
        {form.name}
      </h2>

      <DialogContent>
        <Grid direction="row" container spacing={2}>
          <Grid xs item>
            <TextField
              name="metricName"
              defaultValue={form.nameIndicator}
              label="Nombre del indicador"
              variant="outlined"
              size="small"
              fullWidth={true}
              InputProps={{
                readOnly: true,
              }}
              disabled
            />
          </Grid>
          <Grid xs item>
            <TextField
              name="metricName"
              defaultValue={form.metricIndicator}
              label="Métrica objetivo"
              variant="outlined"
              size="small"
              fullWidth={true}
              InputProps={{
                readOnly: true,
              }}
              disabled
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogContent>
        <Grid direction="row" container spacing={2}>
          <Grid xs item>
            <TextField
              name="metricName"
              defaultValue={form.metricObjective}
              label="Indicador"
              variant="outlined"
              size="small"
              fullWidth={true}
              InputProps={{
                readOnly: true,
              }}
              disabled
            />
          </Grid>
          <Grid xs item>
            <TextField
              name="metricName"
              defaultValue={form.weight}
              label="Peso"
              variant="outlined"
              size="small"
              fullWidth={true}
              InputProps={{
                readOnly: true,
              }}
              disabled
            />
          </Grid>
        </Grid>
      </DialogContent>

      {
        state.evaluateObjectiveModal && (
          <DialogContent>
            <Grid direction="row" container spacing={2}>
              <Grid xs item>
                <TextField
                  name="progress"
                  defaultValue={form.progress}
                  label="Progreso"
                  variant="outlined"
                  size="small"
                  fullWidth={true}
                  InputProps={{
                    readOnly: true,
                  }}
                  disabled
                />
              </Grid>
              <Grid xs item>
                <TextField
                  name="result"
                  defaultValue={parseInt(form.progress, 10) / parseInt(form.metricObjective, 10) * 100 + " %"}
                  label="Resultado"
                  variant="outlined"
                  size="small"
                  fullWidth={true}
                  InputProps={{
                    readOnly: true,
                  }}
                  disabled
                />
              </Grid>
            </Grid>
          </DialogContent>
        )
      }

      <DialogContent>
        <Grid xs item>
          <TextField
            name="name"
            value={form.description}
            label="Descripción del objetivo"
            variant="outlined"
            size="small"
            fullWidth={true}
            InputProps={{
              readOnly: true,
            }}
            disabled
          />
        </Grid>
      </DialogContent>

      <DialogContent>
        <h4 id="form-dialog-title" className="text-center">
          Evidencias
          {data?.GET_OBJECTIVE_EVIDENCE?.length > 0 && (
            <span>{` (${data?.GET_OBJECTIVE_EVIDENCE?.length})`}</span>
          )}
        </h4>
        <table className={styles.evidenceTable}>
          <thead className={styles.evidenceTableHead}>
            <tr>
              <th>Nombre</th>
              <th>Fecha</th>
              <th>Archivo</th>
            </tr>
          </thead>
          <tbody className={styles.evidenceTableBody}>
            {
              data?.GET_OBJECTIVE_EVIDENCE?.map((evidence: any) => (
                <tr className={styles.evidenceTr}>
                  <td className={styles.evidenceTableBodyRow}>{evidence.name}</td>
                  <td className={styles.evidenceTableBodyRow}>{moment(parseInt(evidence.date, 10)).format("DD/MM/YY")}</td>
                  <td className={styles.evidenceTableBodyRowIcon} onClick={() => window.open(evidence.URL, "blank")}>
                    <img src={getFileType(evidence.URL)} alt="Archivo" className={styles.evidenceTableImage} />
                  </td>
                </tr>
              ))
            }
          </tbody>
        </table>
      </DialogContent>

      {
        state.addEvidenceModal && (
          <>
            <input
              type="file"
              ref={inputFileRef}
              name="Image"
              onChange={(e) => onChangeImage(e)}
              accept=".pdf, .jpg, .jpeg, .png, .docx, .xlsx, .mp4, .zip"
            />

            <DialogContent className={styles.evidenceActions}>
              <AddTable
                func={uploadEvidence}
                text="Cargar Evidencia"
                img="upload-file-blue.svg"
              />
              <Grid xs item>
                <TextField
                  name="metricName"
                  defaultValue={form.progress}
                  label="Progreso"
                  variant="outlined"
                  size="small"
                  type='number'
                  onChange={updateProgressForm}
                  InputProps={{
                    readOnly: false,
                  }}
                />
              </Grid>
              <span className={styles.progress}>
                {result} %
              </span>
            </DialogContent>

            <DialogContent className={styles.evidenceCancelContainer}>
              <button
                onClick={cancelButton}
                className={styles.evidenceCancelButton}
              >Cancelar</button>
              <Button text="Aceptar" func={updateProgress} />
            </DialogContent>
          </>
        )
      }

      {
        state.evaluateObjectiveModal && (
          <DialogContent style={{
            padding: '8px 30%'
          }}>
            <FormControl
              fullWidth
              size="small"
              variant="outlined"
              className="question"
              >
              <InputLabel id="demo-simple-select-label"
                className={styles.selectLabel}>Evaluar objetivo</InputLabel>
              <Select
                readOnly={state?.campaign?.status === 'finalizado' || state?.rol === 'collaborator'}
                disabled={state?.campaign?.status === 'finalizado' || state?.rol === 'collaborator'}
                className={styles.select}
                value={evaluation}
                onChange={evaluateObjective}
                labelId="demo-simple-select-label"
                id="evaluate"
                name="metricIndicator"
              >
                <option value="Cumplido">Cumplido</option>
                <option value="No cumplido">No cumplido</option>
                <option value="Requiere más información">Requiere más información</option>
                <option value="En espera">En espera</option>
              </Select>
            </FormControl>
          </DialogContent>
        )
      }

      {
        state.evaluateObjectiveModal && (
          <DialogContent className={styles.evidenceCancelContainer}>
            <button
              onClick={cancelButton}
              className={styles.evidenceCancelButton}
            >{state?.campaign?.status === 'finalizado' ? 'Cerrar' : 'Cancelar'}</button>
            {
              state?.campaign?.status !== 'finalizado' && (
                <AddTable
                  func={sendReminder}
                  text="Recordatorio"
                  img="add-icon.svg"
                />
              )
            }
          </DialogContent>
        )
      }

    </Dialog>
  );
};

export default AddEvidenceModal;
