import {
  useEffect,
  useState,
  useContext,
  SetStateAction,
} from "react";
import {
  DialogActions
} from "@material-ui/core";
import {  Dialog } from "@material-ui/core";
import style from "./CrearIncidencias.module.css";
import {
  CREATE_PAYROLL_INCIDENT,
  GET_PAYROLLCOLLABORATOR,
  GET_INCIDENT_PAYROLL,
  GET_COLLABORATORS_DISPERSION,
  GET_PAYROLLCOLLABORATOR_EXCELL_CALCULO,
  GET_ALL_SCHEDULE_BY_MONTH_YEAR,
  GET_ALL_PERCEPTIONS_INCIDENCIAS,
  GET_ALL_DEDUCTIONS_INCIDENCIAS,
  GET_ALL_DISPERSIONLAYOUT
} from "../../../../Querys/querys";
import { useMutation, useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { SuccessfulAlert } from "../../../../alerts/successAlerts";
import { useForm } from "../../../../hooks/useForm";
import { IncidenciaModal } from "../../../../interfaces/TabPayroll.interfaces";
import DeduccionContext from "../../../../context/ConfigPayrollContext/DeduccionContext";
import SaveIcon from "@material-ui/icons/Save";
import PayrollProcessContext from "../../../../context/PayrollProcess/PayrollProcessContext";
import {
  clearPayrollProcess,
  createincidenciaModal,
} from "../../../../context/PayrollProcess/Actions";

import { postFileIncidentAWS } from "../../../../services/candidateService";
import File_Helper_Incident from "../../../../components/Collaborator/Expedient/Fields/File_Helper_Incident";
import RemoveFile from "../../../../assets/svg/remove_file.svg";
import moment from "moment";
import Checkbox from "@mui/material/Checkbox";
import { ErrorAlert } from "../../../../alerts/errorAlert";
let fechas = "";

const CrearIncidencias = (props: any) => {
  const { id } = useParams<any>();
  const { state: payrollState, dispatch: payrollDispatch } = useContext(
    PayrollProcessContext
  );
  const [seleccionados, setSeleccionados] = useState<any[]>([]);
  const [estadoInicial, setEstadoInicial] = useState<any[]>([]);
  const [estadoFiltrado, setEstadoFiltrado] = useState<any[]>([]);
  const { dispatch } = useContext(DeduccionContext);
  const [seleccionadosConcepto, setSeleccionadosConcepto] = useState<any[]>([]);
  const [estadoInicialConcepto, setEstadoInicialConcepto] = useState<any[]>([]);
  
  const [seleccionadosFecha, setSeleccionadosFecha] = useState<any[]>([]);
  const [show, setShow] = useState(false);
  const [showC, setShowColaboradores] = useState(false);
  const [showSeleccionados, setShowColaboradoresSeleccionado] = useState(false);
  const [showCF, setShowFechas] = useState(false);
  const resultPayrollCollaborator = useQuery(GET_PAYROLLCOLLABORATOR, {
    variables: { getPayrollcollaboratorId: id },
  });
  const allPayrollCollaborator2 =resultPayrollCollaborator.data?.GET_PAYROLLCOLLABORATOR
  const allPayrollCollaborator = allPayrollCollaborator2?.filter((item:any) => (!item.UUID && item.UUID == "") || item.dispersionStatus !== "LIQUIDADO")
  const resultDeductions = useQuery(GET_ALL_DEDUCTIONS_INCIDENCIAS);
  const resultallDeductions = resultDeductions.data?.GET_ALL_DEDUCTIONS_INCIDENCIAS;

  const resultPerceptions = useQuery(GET_ALL_PERCEPTIONS_INCIDENCIAS);
  const resultallPerceptions = resultPerceptions.data?.GET_ALL_PERCEPTIONS_INCIDENCIAS;
  const [isCheckI, setIsCheckI] = useState<any[]>([]);
  const [isCheckE, setIsCheckE] = useState<any[]>([]);
  const [archives, setArchives] = useState<any>();
  const [totalCantidad, setTotalCantidad] = useState("$");
  const [dot, setDot] = useState(0)
  const [zero, setZero] = useState(0)
  const [hours, setHours] = useState("")
  const [dotHours, setDotHours] = useState(0)
  const [days, setDays] = useState("")
  const [dotDays, setDotDays] = useState(1)
  const [save, setSave] = useState(props.saveIncident);
  const [nameConcept, setNameConcept] = useState("");

  useEffect(() => {
    initData();
  }, [payrollState]);

  useEffect(() => {
    setSave(props.saveIncident);
  }, [props.saveIncident]);

  const initData = async () => {
    setEstadoInicial(allPayrollCollaborator);
    setEstadoFiltrado(allPayrollCollaborator);
  };

  const [createPayRollIncident] = useMutation(CREATE_PAYROLL_INCIDENT, {
    refetchQueries: [
      {
        query: GET_PAYROLLCOLLABORATOR,
        variables: { getPayrollcollaboratorId: id }
      },
      { 
        query: GET_INCIDENT_PAYROLL
        , variables: {
                        idPayroll: parseInt(id)
                    }
      },
      { 
        query: GET_COLLABORATORS_DISPERSION
        , variables: {
                      getCollaboratorsDispersionId: parseInt(id)
                    }
      },
      {
        query:GET_PAYROLLCOLLABORATOR_EXCELL_CALCULO,
        variables: { getPayrollcollaboratorExcellCalculoId: id }
      },
      {
        query:GET_ALL_SCHEDULE_BY_MONTH_YEAR, variables: {  month: parseInt(moment().format('MM')), year: parseInt(moment().format('YYYY')) },
      },
      {
        query:GET_ALL_DISPERSIONLAYOUT,
        variables: { getAllDispersionlayoutId: id }
      }      
    ],
  });

  const {
    Incident_type,
    InitDate,
    Taxable,
    NotTaxable,
    Mixed,
    TipoIncP,
    TipoIncC,
    idConcept,
    Dias,
    Horas,
    Comentarios,
    Periodico,
    FechaPeriodica,
    onChange,
    formulario,
    reset,
  } = useForm<IncidenciaModal>({
    idPayroll: 0,
    idCollaborator: 0,
    Incident_type: "Tiempo",
    InitDate: "",
    EndDate: "",
    Total: 0,
    Taxable: false,
    NotTaxable: false,
    Mixed: false,
    TipoIncP: false,
    TipoIncC: false,
    idConcept: 0,
    Dias: 0,
    Horas: 0,
    Comentarios: "",
    typeconcept: "",
    Periodico: false,
    FechaPeriodica: "",
  });
  const validate = (name: any, text = "") => {
    setClass(name)
    if (name === "comentarioFieldset") {
      const inputComentarios = document.querySelectorAll("#comentarioFieldset");
      inputComentarios.forEach((coment) => {
        const p = document.createElement("p");
        p.textContent = "Comentario no debe llevar solo numeros.";
        p.className = style.validateTextComentatio;
        p.id = "alertComent";

        coment.className = style.fieldsetValidate;
        coment.appendChild(p);
      });
    }

    if (name === "divCollaborators") {
      const divCollaborators = document.querySelectorAll("#divCollaborators");
      divCollaborators.forEach((collaborator) => {
        const p = document.createElement("p");
        p.textContent = "Seleccione colaboradores.";
        p.className = style.validateTextCollaborator;
        p.id = "alertCollaborator";

        collaborator.appendChild(p);
      });
    }

    if (name === "conteinerPD") {
      const conteinerPD = document.querySelectorAll("#conteinerPD");
      conteinerPD.forEach((PD) => {
        const p = document.createElement("p");
        p.textContent = "Seleccione percepción o deducción.";
        p.className = style.validateText;
        p.id = "alertPD";
        PD.appendChild(p);
      });
    }

    if (name === "conteinerDates") {
      const conteinerDates = document.querySelectorAll("#conteinerDates");
      conteinerDates.forEach((date) => {
        const p = document.createElement("p");
        p.textContent = text;
        p.className = style.validateText;
        p.id = "alertDate";
        date.appendChild(p);
      });
    }

    if (name === "conteinerGNT") {
      const conteinerGNT = document.querySelectorAll("#conteinerGNT");
      conteinerGNT.forEach((GNT) => {
        const p = document.createElement("p");
        p.textContent = "Seleccione gravable, no gravable o total.";
        p.className = style.validateText;
        p.id = "alertGNT";
        GNT.appendChild(p);
      });
    }

    if (name === "conceptField") {
      const conceptField = document.querySelectorAll("#conceptField");
      conceptField.forEach((concept) => {
        const p = document.createElement("p");
        p.textContent = "Seleccione concepto.";
        p.className = style.validateSelect;
        p.id = "alertConcept";
        concept.className = style.fieldsetValidateSelect;
        concept.appendChild(p);
      });
    }

    if (name === "conteinerPeriodicity") {
      const conteinerPeriodicity = document.querySelectorAll(
        "#conteinerPeriodicity"
      );
      conteinerPeriodicity.forEach((Periodicity) => {
        const p = document.createElement("p");
        p.textContent = "Seleccione fecha fin.";
        p.className = style.validateText;
        p.id = "alertPeriodicity";

        Periodicity.appendChild(p);
      });
    }

    if (name === "amountFieldset") {
      const amountFieldset = document.querySelectorAll("#amountFieldset");
      amountFieldset.forEach((amount) => {
        const p = document.createElement("p");
        p.textContent = "Seleccione cantidad.";
        p.className = style.validateTextComentatio;
        p.id = "alertAmount";
        const imputTotal = document.querySelector("#imputTotal");
        if(imputTotal){
          imputTotal.className = style.inputUpdateValida;
        }
        amount.className = style.fieldsetValidateSelect;
        amount.appendChild(p);
      });
    }
  };

  const setClass = (name: any) => {
    if (name === "comentarioFieldset") {
      const inputComentarios = document.querySelectorAll("#comentarioFieldset");
      inputComentarios.forEach((coment) => {
        coment.className = style.fieldsetUpdateIncident;
        const p = document.querySelector("#alertComent");
        if (p) {
          coment.removeChild(p);
        }
      });
    }

    if (name === "divCollaborators") {
      const divCollaborators = document.querySelectorAll("#divCollaborators");
      divCollaborators.forEach((collaborator) => {
        const p = document.querySelector("#alertCollaborator");
        if (p) {
          collaborator.removeChild(p);
        }
      });
    }

    if (name === "conteinerPD") {
      const conteinerPD = document.querySelectorAll("#conteinerPD");
      conteinerPD.forEach((PD) => {
        const p = document.querySelector("#alertPD");
        if (p) {
          PD.removeChild(p);
        }
      });
    }

    if (name === "conteinerDates") {
      const conteinerDates = document.querySelectorAll("#conteinerDates");
      conteinerDates.forEach((date) => {
        const p = document.querySelector("#alertDate");
        if (p) {
          date.removeChild(p);
        }
      });
    }

    if (name === "conteinerGNT") {
      const conteinerGNT = document.querySelectorAll("#conteinerGNT");
      conteinerGNT.forEach((GNT) => {
        const p = document.querySelector("#alertGNT");
        if (p) {
          GNT.removeChild(p);
        }
      });
    }

    if (name === "conceptField") {
      const conceptField = document.querySelectorAll("#conceptField");
      conceptField.forEach((concept) => {
        const p = document.querySelector("#alertConcept");
        if (p) {
          concept.className = style.fieldsetNombreIncident;
          concept.removeChild(p);
        }
      });
    }

    if (name === "conteinerPeriodicity") {
      const conteinerPeriodicity = document.querySelectorAll(
        "#conteinerPeriodicity"
      );
      conteinerPeriodicity.forEach((Periodicity) => {
        const p = document.querySelector("#alertPeriodicity");
        if (p) {
          Periodicity.removeChild(p);
        }
      });
    }

    if (name === "amountFieldset") {
      const amountFieldset = document.querySelectorAll("#amountFieldset");
      amountFieldset.forEach((amount) => {
        const p = document.querySelector("#alertAmount");
        const imputTotal = document.querySelector("#imputTotal");
        if(imputTotal){
          imputTotal.className = style.inputUpdate;
        }
        amount.className = style.fieldsetUpdateIncident;
        if (p) {
          amount.removeChild(p);
        }
      });
    }
  };

  const validatePeriodico = () => {
    if (String(Periodico) === "false" ) {
      return true;
    } else {
      if (FechaPeriodica === "") {
        return false;
      } else {
        return true;
      }
    }
  };

  const handleClose = () => {
    reset();
    formulario.Taxable = false;
    formulario.NotTaxable = false;
    formulario.Mixed = false;
    formulario.TipoIncC = false;
    formulario.TipoIncP = false;
    formulario.Periodico = false;
    setSeleccionados([]);
    setEstadoInicial(allPayrollCollaborator);
    setEstadoFiltrado(allPayrollCollaborator);
    setSeleccionadosConcepto([]);
    setSeleccionadosFecha([]);
    clearPayrollProcess({}, payrollDispatch);
    setArchives(undefined);
    setShow(false)
    setTotalCantidad("")
    setHours("")
    setDot(0)
    setDotHours(0)
    setDays("")
    setDotDays(1)
    setEstadoInicialConcepto([])
    props.setSaveIncident(true)
  };

  const insertArchive = async (idIncident: any) => {
    const restult = await postFileIncidentAWS(
      archives,
      idIncident,
      "ArchivoIncidencias",
      "ArchivoIncidencias",
      false
    );
    handleClose();

    await SuccessfulAlert({
      title: "¡Exito!",
      text: "¡Se ha añadido documento!",
    });
  };

  const validateDates = (days: any, hours: any, type:any)=>{
    let VarValidate: boolean;

    if(type !== "Dinero"){
      if(parseInt(days) > 0 && parseFloat(hours) == 0){
        if(parseInt(days) === seleccionadosFecha.length){
          VarValidate = true
        }else{
          validate("conteinerDates", "Las fechas seleccionadas no corresponden a los días.");
          VarValidate = false
        }
      }else{
        if(parseInt(days) == 0 && parseFloat(hours) > 0){
          VarValidate = true
        }else{
          validate("conteinerDates", "Seleccione dias u horas. No ambos.");
          VarValidate = false
        }
      }
      
    }else{
      VarValidate = true
    }

    return VarValidate

  }
  const disableSave = async () => {
    
    await props.setSaveIncident((current:boolean) => current = false)
    await onSumbit() 
  }
  const onSumbit = async () => {
    
    if (
      formulario.Comentarios != "" && formulario.Comentarios.replace(/([0-9])/g, "") == ""
    ) {
      
      validate("comentarioFieldset");
      props.setSaveIncident(true)
    } else {
      if (id !== 0 && seleccionados?.length > 0) {
        if (TipoIncP !== false || TipoIncC !== false) {
          if (Taxable !== false || NotTaxable !== false || Mixed !== false) {
            if (idConcept !== 0) {
              
              if (validatePeriodico()) {
                {
                  if (
                    validateDates(formulario.Dias.toString(), formulario.Horas.toString(), formulario.Incident_type)
                  ) {
                    if (formulario.Incident_type === "Dinero") {
                      if ( (totalCantidad.replace("$", "").replaceAll(",", "") !== "" && totalCantidad.replace("$", "").replaceAll(",", "") !== "0") || (nameConcept == 'Prima Dominical') ) {
                        const totalImpo = parseFloat(
                          totalCantidad.replace("$", "").replaceAll(",", "")
                        );
                        seleccionados?.map((lis: any) =>
                          createPayRollIncident({
                            variables: {
                              input: {
                                idPayroll: parseInt(id),
                                idCollaborator: parseInt(lis.id_Collaborator),
                                Incident_type: formulario.Incident_type,
                                InitDate: moment(formulario.InitDate).toISOString(),
                                Total: totalImpo,
                                Taxable:
                                  Taxable.toString() === "true"
                                    ? true
                                    : false,
                                NotTaxable:
                                  NotTaxable.toString() === "true"
                                    ? true
                                    : false,
                                Mixed:
                                  Mixed.toString() === "true"
                                    ? true
                                    : false,
                                idConcept: parseInt(
                                  formulario.idConcept.toString()
                                ),
                                Horas: 0,
                                Dias: 0,
                                Comentarios: formulario.Comentarios,
                                TypeConcept: formulario.typeconcept,
                                Periodico:
                                  formulario.Periodico.toString() === "true"
                                    ? true
                                    : false,
                                FechaPeriodica:
                                  formulario.FechaPeriodica === ""
                                    ? null
                                    : formulario.FechaPeriodica,
                              },
                            },
                          }).then((data) => {
                            if (archives !== undefined) {
                              insertArchive(
                                data?.data.CREATE_PAYROLL_INCIDENT.id
                              );
                            } else {
                              handleClose();
                              SuccessfulAlert({
                                title: "¡Exito!",
                                text: "¡Se ha añadido la incidencia!",
                              });
                            }
                          })
                        );
                      } else {
                        validate("amountFieldset");
                      }
                    } else {
                      seleccionados?.map((lis: any) =>{
                        if (seleccionadosFecha.length > 0){
                            seleccionadosFecha?.map((fecha: any) => {
                              if (nameConcept === 'Prima Dominical' && new Date(fecha.fecha).getDay() !== 6) {
                                ErrorAlert({ text: "¡Para Prima Dominical debes seleccionar días de domingo!." });
                                props.setSaveIncident(true)
                                return;
                              }

                              createPayRollIncident({
                                variables: {
                                  input: {
                                    idPayroll: parseInt(id),
                                    idCollaborator: parseInt(lis.id_Collaborator),
                                    Incident_type: formulario.Incident_type,
                                    InitDate: fecha.fecha !== undefined && fecha.fecha !== '' ?  moment(fecha.fecha).toISOString() : null,
                                    Total: 0,
                                    Taxable:
                                      Taxable.toString() === "true"
                                        ? true
                                        : false,
                                    NotTaxable:
                                      NotTaxable.toString() === "true"
                                        ? true
                                        : false,
                                    Mixed:
                                      Mixed.toString() === "true"
                                        ? true
                                        : false,
                                    idConcept: parseInt(
                                      formulario.idConcept.toString()
                                    ),
                                    Horas: 0,
                                    Dias: 1,
                                    Comentarios: formulario.Comentarios,
                                    TypeConcept: formulario.typeconcept,
                                    Periodico:
                                      formulario.Periodico.toString() === "true"
                                        ? true
                                        : false,
                                    FechaPeriodica:
                                      formulario.FechaPeriodica === ""
                                        ? null
                                        : formulario.FechaPeriodica,
                                  },
                                },
                              }).then((data) => {
                                if (archives !== undefined) {
                                  insertArchive(
                                    data?.data.CREATE_PAYROLL_INCIDENT.id
                                  );
                                } else {
                                  handleClose();
                                  SuccessfulAlert({
                                    title: "¡Exito!",
                                    text: "¡Se ha añadido la incidencia!",
                                  });
                                }
                              })
                            })
                        }else{
                          createPayRollIncident({
                            variables: {
                              input: {
                                idPayroll: parseInt(id),
                                idCollaborator: parseInt(lis.id_Collaborator),
                                Incident_type: formulario.Incident_type,
                                InitDate: null,
                                Total: 0,
                                Taxable:
                                  Taxable.toString() === "true"
                                    ? true
                                    : false,
                                NotTaxable:
                                  NotTaxable.toString() === "true"
                                    ? true
                                    : false,
                                Mixed:
                                  Mixed.toString() === "true"
                                    ? true
                                    : false,
                                idConcept: parseInt(
                                  formulario.idConcept.toString()
                                ),
                                Horas: parseFloat(formulario.Horas.toString()),
                                Dias: 0,
                                Comentarios: formulario.Comentarios,
                                TypeConcept: formulario.typeconcept,
                                Periodico:
                                  formulario.Periodico.toString() === "true"
                                    ? true
                                    : false,
                                FechaPeriodica:
                                  formulario.FechaPeriodica === ""
                                    ? null
                                    : formulario.FechaPeriodica,
                              },
                            },
                          }).then((data) => {
                            if (archives !== undefined) {
                              insertArchive(
                                data?.data.CREATE_PAYROLL_INCIDENT.id
                              );
                            } else {
                              handleClose();
                              SuccessfulAlert({
                                title: "¡Exito!",
                                text: "¡Se ha añadido la incidencia!",
                              });
                            }
                          })
                        }
                      });
                    }
                  }else{
                    props.setSaveIncident(true)
                  }
                }
              } else {
                validate("conteinerPeriodicity");
                props.setSaveIncident(true)
              }
            } else {
              validate("conceptField");
              props.setSaveIncident(true)
            }
          } else {
            validate("conteinerGNT");
            props.setSaveIncident(true)
          }
        } else {
          validate("conteinerPD");
          props.setSaveIncident(true)
        }
      } else {
        validate("divCollaborators");
        props.setSaveIncident(true)
      }
    }
    
  };


  const EliminardeListaFechas = () => {
    const checkedBoxes = document.querySelectorAll(
      '#fechas > [type="checkbox"]:checked'
    );
  
    let dateToDelete: { fecha: string; id: string }[] = [];
    checkedBoxes.forEach((checkElement) => {
      dateToDelete.push({
        fecha: checkElement.className,
        id: checkElement.className,
      });
    });

    const FechasNuevas = seleccionadosFecha.filter((fecha) => {
      return !dateToDelete
        .map((date) => {
          return date.id;
        })
        .includes(fecha?.id);
    });

    setSeleccionadosFecha([...FechasNuevas]);
  };

  const EliminardeLista = () => {

    const checkedBoxes = document.querySelectorAll(
      '#colaboladores > [type="checkbox"]:checked'
    );
    let workerToDelete: { id_Collaborator: string; colaborator: string }[] = [];
    checkedBoxes.forEach((checkElement) => {
      workerToDelete.push({
        id_Collaborator: checkElement.attributes[2].value,
        colaborator: checkElement.className,
      });
    });

    const seleccionadosEliminar = seleccionados.filter((persona) => {
      return workerToDelete
        .map((worker) => {
          return worker.id_Collaborator;
        })
        .includes(String(persona?.id_Collaborator));
    });

    setEstadoFiltrado([...estadoFiltrado, ...seleccionadosEliminar]);
    setEstadoInicial([...estadoInicial, ...seleccionadosEliminar]);

    const nuevoSeleccionadas = seleccionados.filter((persona) => {
      return !workerToDelete
        .map((worker) => {
          return worker.id_Collaborator;
        })
        .includes(String(persona?.id_Collaborator));
    });

    setSeleccionados(nuevoSeleccionadas);
    setIsCheckI([]);
    setIsCheckE([]);
  };

  const AgregarLista = () => {
    
    const checkedBoxes = document.querySelectorAll(
      '#UniversoI > [type="checkbox"]:checked'
    );
    
    let workerToAdd: { id_Collaborator: string }[] = [];

    checkedBoxes.forEach((checkElement) => {
      workerToAdd.push({
        id_Collaborator: checkElement.attributes[2].value,
      });
    });
    
    const seleccionadosSeleccionar = estadoInicial?.filter((persona) => {
      return workerToAdd
        .map((worker) => {
          return worker.id_Collaborator;
        })
        .includes(String(persona?.id_Collaborator));
    });
    setSeleccionados([...seleccionados, ...seleccionadosSeleccionar]);
    const nuevoUniverso = estadoInicial?.filter((persona) => {
      return !workerToAdd
        .map((worker) => {
          return worker.id_Collaborator;
        })
        .includes(String(persona?.id_Collaborator));
    });
    setEstadoFiltrado([...nuevoUniverso]);
    setEstadoInicial([...nuevoUniverso]);
    setIsCheckI([]);
    setIsCheckE([]);
  };

  const agregaConcepto = (concepto: any) => {
    setSeleccionadosConcepto([
      ...seleccionadosConcepto,
      { id: concepto[0], nombre: concepto[1], tipo: concepto[3] },
    ]);
    const nuevoSeleccionadasConcepto = estadoInicialConcepto.filter(
      (lis) => lis?.id_Collaborator !== concepto[0] && lis?.__typename !== concepto[3]
    );
    setEstadoInicialConcepto(nuevoSeleccionadasConcepto);
  };

  const agregarFecha = () => {
    
    let valor = formulario.InitDate;
    let horas = formulario.Horas;
    let dias = formulario.Dias;

    const fechas_agregadas = seleccionadosFecha.filter(
      (lis) => lis?.fecha === valor
    );

    if (
      valor.length > 1 &&
      seleccionadosFecha.length < dias &&
      fechas_agregadas.length === 0
    ) {
      setSeleccionadosFecha([
        ...seleccionadosFecha,
        { fecha: formulario.InitDate, id: formulario.InitDate, Horas: horas },
      ]);
      fechas = fechas += "Horas:" + horas + "/" + valor + ",";
    }
  };

  const onChange2 = (e: any) => {
    let seleccionado = e.nativeEvent.target;

    var index = e.nativeEvent.target.selectedIndex;
    let text = e.nativeEvent.target[index].text;
    let tipo = e.nativeEvent.target[index].className;
    const datos = [e.target.value, text, tipo];
    agregaConcepto(datos);
  };

  const filtrarConcepto = (tipo: any) => {
    if (tipo === "Percepcion") {
      formulario.typeconcept = "Percepcion";
      setEstadoInicialConcepto(resultallPerceptions);
    } else {
      formulario.typeconcept = "Deduccion";
      setEstadoInicialConcepto(resultallDeductions);
    }
  };

  const filtrarConceptoPercepcionDeduccion = (tipo: any) => {
    let concepto_tipo: SetStateAction<any[]> = [];

    if (tipo === "Percepcion") {
      formulario.TipoIncC = false;
      formulario.TipoIncP = true;
      formulario.typeconcept = "Percepcion";
      if (formulario.Incident_type === "Tiempo") {
        concepto_tipo = resultallPerceptions.filter(
          (lis: { time: any }) => lis?.time
        );
        setEstadoInicialConcepto(concepto_tipo);
      } else {
        setEstadoInicialConcepto(resultallPerceptions);
      }
    }

    if (tipo === "Deduccion") {
      formulario.TipoIncP = false;
      formulario.TipoIncC = true;
      formulario.typeconcept = "Deduccion";
      setEstadoInicialConcepto(resultallDeductions);
      if (formulario.Incident_type === "Tiempo") {
        concepto_tipo = resultallDeductions.filter(
          (lis: { time: any }) => lis?.time
        );
        setEstadoInicialConcepto(concepto_tipo);
      } else {
        setEstadoInicialConcepto(resultallDeductions);
      }
    }
  };

  const filtrarTipoInc = (tipo: string) => {
    let concepto_tipo: SetStateAction<any[]> = [];
    formulario.Incident_type = tipo;
    filtrarConcepto(formulario.typeconcept);
    if (tipo === "Tiempo" && formulario.typeconcept === "Percepcion") {
      concepto_tipo = resultallPerceptions.filter(
        (lis: { time: any }) => lis?.time
      );
      setEstadoInicialConcepto(concepto_tipo);
    }
    if (tipo === "Tiempo" && formulario.typeconcept === "Deduccion") {
      concepto_tipo = resultallDeductions.filter(
        (lis: { time: any }) => lis?.time
      );
      setEstadoInicialConcepto(concepto_tipo);
    }

  };

  const filtrarTipo2 = (tipo: any) => {
    let concepto_tipo: SetStateAction<any[]> = [];
    filtrarConceptoPercepcionDeduccion(formulario.typeconcept);

    if (tipo === "Taxable") {
      formulario.NotTaxable = false;
      formulario.Mixed = false;

      formulario.Taxable = true;

      concepto_tipo = estadoInicialConcepto.filter(
        (lis: { ISRTax: any }) => lis?.ISRTax
      );
      setEstadoInicialConcepto(concepto_tipo);
    }

    if (tipo === "NotTaxable") {
      formulario.Mixed = false;

      formulario.Taxable = false;
      formulario.NotTaxable = true;
      concepto_tipo = estadoInicialConcepto.filter(
        (lis: { ISRTax: any }) => !lis?.ISRTax
      );

      setEstadoInicialConcepto(concepto_tipo);
    }

    if (tipo === "Mixed") {
      formulario.Taxable = false;
      formulario.NotTaxable = false;
      formulario.Mixed = true;
      concepto_tipo = estadoInicialConcepto.filter(
        (lis: { TaxBoth: any }) => lis?.TaxBoth
      );

      setEstadoInicialConcepto(concepto_tipo);
    }
  };

  const changeTaxable = (tipo: any) => {

    if (tipo === "Taxable") {
      formulario.NotTaxable = false;
      formulario.Mixed = false;
      formulario.Taxable = true;
    }

    if (tipo === "NotTaxable") {
      formulario.Mixed = false;
      formulario.Taxable = false;
      formulario.NotTaxable = true;
    }

    if (tipo === "Mixed") {
      formulario.Taxable = false;
      formulario.NotTaxable = false;
      formulario.Mixed = true;
    }
  };

  const filtrarTipo = (tipo: any) => {
    let concepto_tipo: SetStateAction<any[]> = [];
    filtrarConcepto(formulario.typeconcept);
    if (tipo === "Taxable") {
      concepto_tipo = estadoInicialConcepto.filter(
        (lis: { ISRTax: any }) => lis?.ISRTax
      );
    }
    if (tipo === "NotTaxable") {
      concepto_tipo = estadoInicialConcepto.filter(
        (lis: { ISRTax: any }) => !lis?.ISRTax
      );
    }
    if (tipo === "Mixed") {
      concepto_tipo = estadoInicialConcepto.filter(
        (lis: { TaxBoth: any }) => lis?.TaxBoth
      );
    }
    setEstadoInicialConcepto(concepto_tipo);
  };

  const filtrarCollaborator = (colaborador: string) => {

    let datosfiltrados = estadoInicial.filter((lis) =>
      lis?.colaborator
        .toUpperCase()
        .includes(colaborador.toString().toUpperCase())
    );
    if (datosfiltrados.length <= 0) {
      datosfiltrados = estadoInicial;
    }
    setEstadoFiltrado(datosfiltrados);
  };
  const createModal = (id: string, createModal: boolean) => {
    createincidenciaModal({ id, createModal }, dispatch);
  };

  const handleCancel = () => {
    
    reset();
    formulario.Taxable = false;
    formulario.NotTaxable = false;
    formulario.Mixed = false;
    formulario.TipoIncC = false;
    formulario.TipoIncP = false;
    formulario.Periodico = false;
    setSeleccionados([]);
    setEstadoInicial(allPayrollCollaborator);
    setEstadoFiltrado(allPayrollCollaborator);
    setSeleccionadosConcepto([]);
    setSeleccionadosFecha([]);
    setArchives(undefined);
    setTotalCantidad("")

    setHours("")
    setDot(0)
    setDotHours(0)
    setDays("")
    setDotDays(1)
    setEstadoInicialConcepto([])
    props.setSaveIncident(true)
  };

  const handleSelectAll = (e: any) => {
    setIsCheckI([]);
    setIsCheckE([]);

    let filtradoSeleccionado = estadoFiltrado
      .filter((lis: any) => {
        return e.target.checked && !isCheckI.includes(lis.id_Collaborator);
      })
      .map((lis: any) => lis.id_Collaborator);

    setIsCheckI(filtradoSeleccionado);
  };

  const handleSelectEliminar = (e: any) => {
    setIsCheckI([]);
    setIsCheckE([]);
    let filtradoSeleccionado = seleccionados
      .filter((lis: any) => {
        return e.target.checked && !isCheckE.includes(lis.id_Collaborator);
      })
      .map((lis: any) => lis.id_Collaborator);
    setIsCheckE(filtradoSeleccionado);
  };

  const handleClickI = (e: any, id:any) => {
 
    setIsCheckI([...isCheckI, id]);
    
    if (!e.target.checked) {
      setIsCheckI(isCheckI.filter((lis: any) => lis !== id));
    }
    
  };

  const handleClickE = (e: any, id: any) => {
    setIsCheckE([...isCheckE, id]);
    if (!e.target.checked) {
      setIsCheckE(isCheckE.filter((lis: any) => lis !== id));
    }
  };
  const upDateTotal = ((total:any)=>{
    const digitNew = total.replace(totalCantidad, "")
    
    let totalWith = ""
    if(total !== ""){
      if(total.length > totalCantidad.length  ){
        if(totalCantidad.length == 0){

          totalWith = total.replace(/\D/g, "")

        }else{
    
          if(digitNew == "."){
            if(dot == 0){
              setDot(1)
              totalWith = total
            }else{
              totalWith = totalCantidad+digitNew.replace(/\D/g, "")
            }
          }else{

            if(totalCantidad == "0"){
              if(digitNew != "."){
                totalWith = totalCantidad
              }else{
                totalWith = total
              }
            }else{
              totalWith = totalCantidad+digitNew.replace(/\D/g, "")
            }
          }
          
        }
      }else{
        if(totalCantidad.replace(total, "") == "."){
          setDot(0)
          setZero(0)
        }
        totalWith = total
      }

    }else{
      setDot(0)
      setZero(0)
    }
    
    

    setTotalCantidad(totalWith)
  })

  const inputFile = () => {
    if (archives === undefined) {
      return (
        <div className={style.inputFileCalendarIncident}>
          <File_Helper_Incident
            name="ArchivoIncidencias"
            parametrofrom="ArchivoIncidencias"
            className="image-file"
            setArchives={setArchives}
            archives={archives}
            idIncident="0"
          />
        </div>
      );
    } else {
      return (
        <div className={style.inputFileCalendarIncident} style={{bottom:"28px"}} >
          <File_Helper_Incident
            name="ArchivoIncidencias"
            parametrofrom="ArchivoIncidencias"
            className="image-file"
            setArchives={setArchives}
            archives={archives}
            idIncident="0"
            image={RemoveFile}
          />
        </div>
      );
    }
  };

  const tipoTiempoDinero = (tipo: any)=>{
    const radiototal =  document.getElementById("ritemc")

    formulario.Horas = 0;
    formulario.Dias = 0;
    formulario.FechaPeriodica = "";
    setTotalCantidad("")
    setSeleccionadosFecha([]);
    if (tipo == "Tiempo") {      
      setShow(false);
      radiototal?.removeAttribute("disabled")
      
    } else {
      setShow(true);
      
      radiototal?.setAttribute("disabled", "disabled")
      if(formulario.Mixed){
        formulario.Mixed = false
      }
    }

  }

  const upDays = ((total:any)=>{
    
    const digitNew = total.replace(days, "")
    
    let totalWith = ""
      if(total.length > days.length  ){
        if(days.length == 0){

          totalWith = total.replace(/\D/g, "")

        }else{
    
          if(digitNew == "."){
            if(dotDays == 0){
              setDotDays(1)
              totalWith = total
            }else{
              totalWith = days+digitNew.replace(/\D/g, "")
            }
          }else{

            if(days == "0"){
              if(digitNew != "."){
                totalWith = days
              }else{
                totalWith = total
              }
            }else{
              totalWith = days+digitNew.replace(/\D/g, "")
            }
          }
          
        }
      }else{
        totalWith = total
      }
    
    
    setDays(totalWith)
    return totalWith
  })

  const upHora = ((total:any)=>{
    
    const digitNew = total.replace(hours, "")
    
    let totalWith = ""
    if(total !== ""){
      if(total.length > hours.length  ){
        if(hours.length == 0){

          totalWith = total.replace(/\D/g, "")

        }else{
    
          if(digitNew == "."){
            if(dotHours == 0){
              setDotHours(1)
              totalWith = total
            }else{
              totalWith = hours+digitNew.replace(/\D/g, "")
            }
          }else{

            if(hours == "0"){
              if(digitNew != "."){
                totalWith = hours
              }else{
                totalWith = total
              }
            }else{
              totalWith = hours+digitNew.replace(/\D/g, "")
            }
          }
          
        }
      }else{
        if(hours.replace(total, "") == "."){
          setDotHours(0)
          
        }
        totalWith = total
      }

    }else{
      setDotHours(0)
      
    }
    
    
    setHours(totalWith)
    return totalWith
  })

  const compareClassName = async (varClass:any)=>{
    if(
      varClass !== style.DivSeleccionadosUniverso 
      && varClass !== style.fieldsetUpdateFiltro
      && varClass !== style.checkboxitemColaboradores
      && varClass !== style.inputUpdateColaboradores
      && varClass !== style.contenedorListaColaboradores
      && varClass !== style.listaNombresColaboradores
      && varClass !== style.listaNombresColaboradores
      && varClass !== style.contenedorColaboradoresDisponibles
      && varClass !== style.botonAgregarColaborador
      && varClass !== style.pc_iconoAgregarDosColaborador
      && varClass !== style.textoAgregarColaborador
    ){
      return true
    }else{
      return false
    }
  }

  const actionCompareClassNameSelected = async (varClass:any, varClass2:any)=>{
    if(
      varClass2 == style.textoDivTotalesSeleccionados 
      || 
      varClass == style.divtotalesSeleccionados
      
    ){
      return true
    }else{
      return false
    }
  }

  const compareClassNameSelected = async (varClass:any)=>{
    if(
      varClass !== style.DivSeleccionadosIncidencias
      && varClass !== style.checkboxitemSelecionados
      && varClass !== style.contenedorListaSeleccionados
      && varClass !== style.botonEliminarListSeleccionados
      && varClass !== style.EliminarImgSeleccionados
      && varClass !== style.textognral
      && varClass !== style.contenedortotalesSeleccionados
      && varClass !== style.listaNombresSeleccionados
    ){
      return true
    }else{
      return false
    }
  }

  const showHiddenColaboradores = async (e: any) =>{
    
    if(e.target.parentNode.className !== "MuiDialog-root"){

      if(e?.target.parentNode.className == style.contenedortotalesIncidencias 
        || e?.target.parentNode.className == style.textoDivTotales
        || e?.target.parentNode.className == style.divtotalesUniverso
      ){
        setShowColaboradores(!showC);
      }else{

        const varClass = await compareClassName(e.target.parentNode.className)
        if(varClass == true){
          if(showC){
            setShowColaboradores(false);
          }
        }
          
        
      }

      const varShow = await actionCompareClassNameSelected(e?.target.parentNode.className, e?.target?.firstChild?.className)
      if(varShow){
        setShowColaboradoresSeleccionado(!showSeleccionados);
      }else{
        
        const varClass = await compareClassNameSelected(e?.target.parentNode.className)
        if(varClass == true){
          if(showSeleccionados){
            setShowColaboradoresSeleccionado(false);
          }
        }  
      }
    }
  }

  const setOffImport = (id: any) =>{

    const concept = estadoInicialConcepto.filter((item:any)=>item.id == id)
    if (concept[0]?.ConceptName == 'Prima Dominical'){
      setNameConcept(concept[0]?.ConceptName)
      setTotalCantidad("0")
      document.getElementById('imputTotal')?.setAttribute('disabled', 'true')
    }else{
      setNameConcept('')
      document.getElementById('imputTotal')?.removeAttribute('disabled')
    }

  }

  return (
    <Dialog
      open={payrollState.createIncidencia}
      fullWidth={false}
      maxWidth={"md"}
      onClick={(e) => {
        showHiddenColaboradores(e)
      }}
    >
      <div className={style.containerTitle}>
          <div className={style.centerContainer}
          style={{
              marginLeft:'auto',
              marginRight:'auto'
          }}
          >
              <h2 id="form-dialog-title" data-testid="TitleModal">Agregar Incidencia</h2>
              
          </div>
          <div className={style.contenedorCerrarModal}>
              <div className={style.iconoCerrarModal} onClick={(()=>handleClose())}></div>      
          </div> 
      </div>
      <div className={style.contenedorPrincipalIncidencia}>
        <div className={style.contenedorColaboradoresDisponibles}>
          <div className={style.contenedortotalesIncidencias} 
          style={{
            width:"100%"
          }}
          >
            <button
              id="payroll_7"
              className={style.divtotalesUniverso}
              onClick={() => {
                setClass("divCollaborators");
              }}
            >
              <span className={style.textoDivTotales}>
                Seleccionar Colaboladores ({estadoFiltrado?.length})
              </span>
            </button>

            
          </div>
          {showC && estadoFiltrado.length > 0 ? (
            <div className={style.DivSeleccionadosUniverso}>
              <fieldset className={style.fieldsetUpdateFiltro}>
                <div className={style.checkboxitemColaboradores}>
                  <input
                    type="checkbox"
                    id="erer"
                    onChange={(e) => handleSelectAll(e)}
                  ></input>
                  <label htmlFor="erer"> </label>
                </div>

                <input
                  className={style.inputUpdateColaboradores}
                  type="text"
                  id="txtFiltro"
                  placeholder="Colaboradores"
                  onChange={(e) => filtrarCollaborator(e.target.value)}
                />
                <img src={`/assets/svg/find.svg`} alt="Buscar" />
              </fieldset>
              <ul className={style.contenedorListaColaboradores}>
                {estadoFiltrado.map((lis: any) => (
                  <li className={style.listaNombresColaboradores}>
                    <div id="UniversoI" className={style.checkboxitemColaboradores}>
                      <input
                        key={"Colaborador"+lis.colaborator}
                        id={"Colaborador"+lis.colaborator}
                        type="checkbox"
                        value={lis.id_Collaborator}
                        checked={isCheckI.includes(lis.id_Collaborator)}
                        className={lis.colaborador}
                        onClick={(e) => handleClickI(e, lis.id_Collaborator)}
                      ></input>
                      <label htmlFor={"Colaborador"+lis.colaborator}> {lis.colaborator}</label>
                    </div>
                  </li>
                ))}
              </ul>
              <br></br>
              <button className={style.botonAgregarColaborador} onClick={AgregarLista}>
                <div className={style.pc_iconoAgregarDosColaborador}></div>
                <span className={style.textoAgregarColaborador}>Agregar</span>
              </button>
            </div>
          ) : (
            ""
          )}
        </div>
          
        <div id="divCollaborators">
          <div id="payroll_8" className={style.contenedortotalesSeleccionados}>
            <button
              className={style.divtotalesSeleccionados}
              onClick={() => {
              }}
            >
              <span className={style.textoDivTotalesSeleccionados}>
                {" "}
                Ver Seleccionados ({seleccionados.length}){" "}
              </span>
            </button>

            {showSeleccionados ? (
              <div className={style.DivSeleccionadosIncidencias}>
                {seleccionados.length > 0 ? (
                  <div className={style.checkboxitemSelecionados}>
                    <input
                      type="checkbox"
                      id="eliminarTodos"
                      onChange={(e) => handleSelectEliminar(e)}
                    ></input>
                    <label htmlFor="eliminarTodos"> Seleccionar Todos </label>
                  </div>
                ) : (
                  ""
                )}

                <ul className={style.contenedorListaSeleccionados}>
                  {seleccionados.map((lis: any) => (
                    <li className={style.listaNombresSeleccionados}>
                      <div id="colaboladores" className={style.checkboxitemSelecionados}>
                        <input
                          id={"Selected"+lis.colaborator}
                          key={"Selected"+lis.colaborator}
                          checked={isCheckE.includes(lis.id_Collaborator)}
                          type="checkbox"
                          value={lis.id_Collaborator}
                          onClick={(e) => handleClickE(e, lis.id_Collaborator)}
                          className={lis.colaborador}
                        ></input>
                        <label htmlFor={"Selected"+lis.colaborator}> {lis.colaborator}</label>
                      </div>
                    </li>
                  ))}
                </ul>
                {seleccionados.length > 0 ? (
                  <button
                    className={style.botonEliminarListSeleccionados}
                    onClick={EliminardeLista}
                  >
                    <img
                      src="/assets/icons/eliminar-lista.svg"
                      alt="Eliminar"
                      className={style.EliminarImgSeleccionados}
                    />
                    Eliminar
                  </button>
                ) : (
                  <label className={style.textognral}>
                    {" "}
                    Agregar Colaboladores{" "}
                  </label>
                )}
              </div>
            ) : (
              ""
            )}
          </div>
        </div>

        <div className={style.radioitemIncidencias}>
          <input
            type="radio"
            name="concepto"
            id="typeconcepta"
            checked={TipoIncP}
            onChange={(e) => {
              setClass("conteinerPD");
              filtrarConceptoPercepcionDeduccion("Percepcion");
              onChange(e.target.checked.toString(), "TipoIncP");
            }}
          />
          <label htmlFor="typeconcepta"> Percepci&oacute;n</label>
        </div>

        <div className={style.radioitemIncidencias}>
          <input
            type="radio"
            name="concepto"
            id="typeconceptb"
            checked={TipoIncC}
            onChange={(e) => {
              setClass("conteinerPD");
              filtrarConceptoPercepcionDeduccion("Deduccion");
              onChange(e.target.checked.toString(), "TipoIncC");
            }}
          />
          <label htmlFor="typeconceptb"> Deducci&oacute;n </label>
        </div>
        
        <div id="conteinerPD"></div>
        <fieldset className={style.fieldsetNombreIncidencias}>
          <legend className={style.tituloFieldsetNombre}>
            Tipo Incidencia *
          </legend>
          <select
            id="SeleccionarTipoIncidencia"
            className={style.selectNombreIncidencias}
            name="Incident_type"
            value={Incident_type}
            onChange={({ target }) => {
              filtrarTipoInc(target.value);
              tipoTiempoDinero(target.value)
              
            }}
          >
            <option value={"0"} disabled selected>
              Tipo de incidencia *
            </option>
            <option key="Dinero" value="Dinero">
              Dinero
            </option>
            <option key="Tiempo" value="Tiempo">
              Tiempo
            </option>
          </select>
        </fieldset>
        {!show ? (
          <div>
            <div className={style.fechasIncidencias}>
              <div>
                <span className={style.textoFecha}> D&iacute;as </span>
              </div>
              <div>
                <input
                  name="Dias"
                  id="Dias"
                  type="text"
                  value={Dias}
                  className={style.inputFechasIncidencias}
                  onChange={({ target }) =>{
                    setClass("conteinerDates");
                    onChange(upDays(target.value) as string, "Dias")
                  }
                    
                  }
                />
              </div>
              <div>
                <span className={style.textoFecha}> Horas </span>
              </div>
              <div>
                <input
                  name="Horas"
                  type="text"
                  value={Horas}
                  className={style.inputFechasIncidencias}
                  onChange={({ target }) =>{
                    setClass("conteinerDates");
                    onChange(upHora(target.value) as string, "Horas")
                  }
                    
                  }
                ></input>
              </div>
              <div>
                <span className={style.textoFecha}>Fecha *</span>
              </div>
              <div>
                <input
                  name="InitDate"
                  type="date"
                  value={InitDate}
                  className={style.inputFecha}
                  onChange={({ target }) =>
                    onChange(target.value as string, "InitDate")
                  }
                />
              </div>
              <div className={style.contenedorFlecha}>
                <button
                  className={style.botonAgregarFecha}
                  onClick={() => {
                    setClass("conteinerDates");
                    agregarFecha();
                  }}
                  type="button"
                >
                  <span className={style.iconoAgregar}>+</span>
                </button>
              </div>
            </div>
            <div id="conteinerDates">
              <div className={style.contenedortotalesIncident}>
                <button
                  className={style.divtotales}
                  onClick={() => {
                    setShowFechas(!showCF);
                  }}
                >
                  <span className={style.textoDivTotales}>
                    Ver Fechas ({seleccionadosFecha.length})
                  </span>
                </button>
                {showCF ? (
                  <div className={style.DivSeleccionados}>
                    <ul className={style.contenedorLista}>
                      {seleccionadosFecha?.map((lis: any, index) => (
                        <li className={style.listaNombres}>
                          <div id="fechas" className={style.checkboxitem}>
                            <input
                              id={index.toString()}
                              key={index}
                              type="checkbox"
                              value={index}
                              className={lis.fecha}
                            ></input>
                            <label htmlFor={index.toString()}>
                              {"Horas: " + lis.Horas + "   Fecha: " + lis.fecha}
                            </label>
                          </div>
                        </li>
                      ))}
                    </ul>
                    <button
                      className={style.botonEliminarList}
                      onClick={EliminardeListaFechas}
                    >
                      <img
                        src="/assets/icons/eliminar-lista.svg"
                        alt="Eliminar"
                        className={style.EliminarImg}
                      />
                      Eliminar
                    </button>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        ) : (
          <div>
           <div className={style.fechasIncidencias}>
            <div>
              <span className={style.textoFecha}>Fecha *</span>
            </div>
            <div>
              <input
                name="InitDate"
                type="date"
                value={InitDate}
                className={style.inputFecha}
                onChange={({ target }) =>
                  onChange(target.value as string, "InitDate")
                }
              />
            </div>
          </div>
          <fieldset className={style.fieldsetUpdateIncident} id="amountFieldset">
            <legend className={style.tituloFieldsetNombre}>Cantidad</legend>
            <input
              className={style.inputUpdate}
              value={totalCantidad}
              name="Total"
              type="text"
              id="imputTotal"
              onChange={({ target }) => {
                props.setSaveIncident(true)
                setClass("amountFieldset");
                upDateTotal(target.value);
              }}
            />
          </fieldset>
          </div>
        )}
        <div id="conteinerGNT">
          <div className={style.radioitemincident} >
            <input
              type="radio"
              name="tx"
              id="ritema"
              checked={Taxable}
              onChange={(e) => {
                setClass("conteinerGNT");
                changeTaxable("Taxable")
                onChange(e.target.checked.toString(), "Taxable");
              }}
            />
            <label htmlFor="ritema"> Gravable </label>
          </div>

          <div className={style.radioitemincident}>
            <input
              type="radio"
              name="tx"
              id="ritemb"
              checked={NotTaxable}
              onChange={(e) => {
                setClass("conteinerGNT");
                changeTaxable("NotTaxable")
                onChange(e.target.checked.toString(), "NotTaxable");
              }}
            />
            <label htmlFor="ritemb"> No Gravable </label>
          </div>
          <div className={style.radioitemincident}>
            <input
              type="radio"
              name="tx"
              id="ritemc"
              checked={Mixed}
              onChange={(e) => {
                setClass("conteinerGNT");
                changeTaxable("Mixed")
                onChange(e.target.checked.toString(), "Mixed");
              }}
            />
            <label htmlFor="ritemc"> Total </label>
          </div>
        </div>
          
            
        <fieldset className={style.fieldsetNombreIncident} id="conceptField">
          <legend className={style.tituloFieldsetNombre}>Concepto *</legend>
          <select
            className={style.selectNombreIncident}
            value={idConcept}
            onChange={(e) => {
              setClass("conceptField");
              onChange(e.target.value as string, "idConcept");
              setOffImport(e.target.value)
            }}
          >
            <option value={"" || ""}>{""}</option>
            {estadoInicialConcepto?.map((lis: any) => (
              <option
                className={lis.__typename}
                key={lis.id + lis.__typename}
                value={lis?.id}
              >
                {lis.ConceptName?.length ? lis.ConceptName : lis.concept_type}
              </option>
            ))}
          </select>
        </fieldset>

        <div>
          <ul className={style.contenedorLista}>
            {seleccionadosConcepto.map((lis: any) => (
              <li className={style.listaNombres}>
                <div className={style.nombre}> {lis.nombre} </div>
              </li>
            ))}
          </ul>
        </div>
        <div className={style.conteinerFile}>
          <fieldset
            className={style.fieldsetUpdateIncidentFiles}
          >
            <legend className={style.tituloFieldsetNombre}>Archivo</legend>
            <input
              className={style.inputUpdateFile}
              name="Archivo"
              type="text"
              disabled={true}
              readOnly={true}
            />
            {inputFile()}
          </fieldset>
        </div>

        <fieldset className={style.fieldsetUpdateIncident} id="comentarioFieldset">
          <legend className={style.tituloFieldsetNombre}>Comentarios</legend>
          <input
            className={style.inputUpdate}
            name="Comentarios"
            type="text"
            value={Comentarios}
            onChange={({ target }) => {
              setClass("comentarioFieldset");
              onChange(target.value, "Comentarios");
            }}
          />
        </fieldset>
        <div className={style.radioitemincident}
        style={{marginTop: "30px"}}>
        
          Informaci&oacute;n periodicamente
        </div>
        <div className={style.fechasIncidencias}
        style={{marginTop: "5px"}}
        >
          <div className={style.radioitem}>
            <Checkbox
              checked={String(formulario.Periodico) == 'true' ? true : false}
              value={true}
              onChange={(e) => {
                setClass("conteinerPeriodicity");
                onChange(e.target.checked.toString(), "Periodico")
              }}
              name="txpe"
            />
          </div>
          <div style={{ marginTop: "15px" }} className={style.radioitem}>
            <span>Fecha Fin</span>
          </div>
          <div className={style.radioitem}>
            <input
              name="FechaPeriodica"
              value={FechaPeriodica}
              type="date"
              className={style.inputFecha}
              onChange={({ target }) => {
                setClass("conteinerPeriodicity");
                onChange(target.value as string, "FechaPeriodica");
              }}
            />
          </div>
        </div>
        <div id="conteinerPeriodicity"></div>

        <DialogActions className={style.contenedorAcciones}>
          <div className={style.contenedorBotones}>
            <div className={style.BotonesAcciones}>
              <button className={style.botonCancelar} onClick={handleClose}>
                Cancelar
              </button>
              {props.saveIncident?
                <button className={style.botonAgregar} onClick={()=>{
                  disableSave()
                
                } }>
                  <div className={style.iconoAgregar}>
                    <SaveIcon />
                  </div>
                  <div>
                    <span className={style.textoAgregar}>Guardar</span>
                  </div>
                </button>
              :
                <button className={style.botonAgregar}
                style={{
                  opacity: "0.5"
                }}
                >
                  <div className={style.iconoAgregar}>
                    <SaveIcon />
                  </div>
                  <div>
                    <span className={style.textoAgregar}>Guardar</span>
                  </div>
                </button>
              }
            </div>
          </div>
          <div>
            <button className={style.botonNuevo} onClick={handleCancel}>
              <div>
                <span className={style.iconoAgregar}>+</span>
              </div>
            </button>
          </div>
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default CrearIncidencias;
