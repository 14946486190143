import {Types} from './MyMoodContext'

const ModalReducer = (state:any, action:any) => {
    const {payload,type} = action;
    
    switch(type){
        case Types.OPENQUESTION:
            return {
                ...state,
                idEncuestas_colaborador: payload.idEncuestas_colaborador,
                Encuestas: payload.Encuestas,
                openQuestion:true
            }
        case Types.CLOSEQUESTION:
            return {
                ...state,
                openQuestion:false,
                openFilter:false,
                openComments: false
            }
        case Types.NEW_SURVEY:
            return {
                ...state,
                newSurvey:true
            }
        case Types.CLOSE_SURVEY:
            return {
                ...state,
                newSurvey:false,
            }
        case Types.NEW_RISK_SURVEY:
            return {
                ...state,
                newRiskSurvey:true
            }
        case Types.CLOSE_RISK_SURVEY:
            return {
                ...state,
                newRiskSurvey:false,
            }
        case Types.OPEN_TESTCOMMENTS:
            return {
                ...state,
                openComments:true,
                idSurvey: payload.idSurvey,
                Encuestas: payload.Encuestas
            }
        case Types.CLOSE_TESTCOMMENTS:
            return {
                ...state,
                openComments:false
            }
        case Types.OPENFILTER:
            return {
                ...state,
                openFilter:true
            }
        default:
        return state;
    }
}

export default ModalReducer;