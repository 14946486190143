import { useContext, useEffect, useState } from 'react'
import { useQuery } from '@apollo/client'
import { formatter } from '../../helpers/formatoMoneda'
import { GET_ENTERPRISE_SELECT, GET_TRANSACTIONS_HISTORICAS } from '../../Querys/querys'
import styles from '../../styles/CentralPagos/HistoricoCentralPagos.module.css'
import { server } from "../../global/server";


const HistoricoCentralPagosTable =()=>{
  const [inputTotalValue, setinputTotalValue] = useState(0);
  const [empresa, setEmpresa] =  useState("")
  const [filtro, setFiltro] =  useState("")
  const {loading:loadingListaEmpresa, error:errorListaEmpresa, data:dataListaEmpresa} = useQuery(GET_ENTERPRISE_SELECT,{})
  const dataEmpresaSelect = dataListaEmpresa?.GET_ENTERPRISE_SELECT

  const {loading:loadingHistorico, error:errorHistorico, data: dataHistorico,refetch} = useQuery(GET_TRANSACTIONS_HISTORICAS,{
    variables:{
      input:{
        filtro: filtro,
        idEmpresa: empresa
      }
    }
  })
  const datosHistorico = dataHistorico?.GET_TRANSACTIONS_HISTORICAS
  
  const datosIniciales = () =>{
    if(empresa === ""){
      setEmpresa((current) => current = dataEmpresaSelect["0"]["id"])
    }
  }

  const cambioEstado = (estado:any) =>{
    setEmpresa((current) => current = estado)
  }

  const cambioFiltro = (e:any) =>{
    setFiltro((current) => current = e.target.value)
  }

  useEffect(()=>{
    if(dataHistorico){
      datosIniciales()

    }
  },[dataHistorico])

  useEffect(()=>{
    console.log('Estoy entrando')
  },[empresa, filtro])


  console.log('Esta es la empresa seleccionada',filtro)

  return(
    <>
      <div className={styles.contenedorFiltros}>
        <div className={styles.contenedorFiltro}>
          <input 
            placeholder='Buscar'
            className={styles.filtro}
            onChange={(e) => cambioFiltro(e)}
            />
          <div className={styles.iconoBuscador}></div>
        </div>
        <div>
          <select 
            className={styles.select}
            onChange={(e) => cambioEstado(e.target.value)}
            >
          {dataEmpresaSelect?.map((empresa:any)=>(
              <option 
                className={styles.optionEmpresa} 
                value={empresa?.id}
                >{empresa?.name}</option>
            ))}
          </select>
        </div>
      </div>
      <div className={styles.contenedorCentral}>
        <table className={styles.tabla}>
          <thead>
            <tr className={styles.tituloTabla}>
              <td>#</td>
              <td>Ordenante</td>
              <td>Beneficiario</td>
              <td>CLABE</td>
              <td>Banco</td>
              <td>Dispersi&oacute;n</td>
              <td>Comisi&oacute;n</td>
              <td>Total</td>
              <td>Comprobante</td>
            </tr>
          </thead>
          <tbody>
            {datosHistorico?.map((datos:any, index:any)=>(
            <tr className={styles.datosTabla}>
              <td>{index + 1}</td>
              <td>{datos?.enterprise}</td>
              <td>{datos?.fullname}</td>
              <td>{datos?.clabe}</td>
              <td>{datos?.Banco}</td>
              <td>{formatter(datos?.transactionTotal)}</td>
              <td>{formatter(datos?.comission)}</td>
              <td>{formatter(datos?.montoDescontar)}</td>
              <td className={styles.centrado}>
                <a
                  target="_blank"
                  href={`${server}/report/cepDispersion/${datos.idDispersion}`}
                >                  
                  <div className={styles.iconopdf}></div>
                </a>               
              </td>
          </tr>        
            ))}
          </tbody>
        </table>
      </div>
    </>
  )
}

export default HistoricoCentralPagosTable
