import FilterContainer from "./components/FilterContainer"
import SquareReport from "./components/SquareReport";
import TableReportIndividual from "./components/TableReportIndividual"
import "./css/FilterConteiner.css";
const ReportIndividual = ({report,setReport,viewFilter,viewSquare,loading,setDeleteColumns,deleteColumns,inpuntValues,setInpuntValues}:any) => {
  return (
    <>
    {(loading === true) ?
        <div className="c_contenedorPrincipal">
          <div className="contenedorLoadingPrincipal">
            <div className="loadingPrincipal"></div>
          </div>
        </div>
        :
        <div className="contenedorReportIndividual">
         {viewFilter?
         <FilterContainer
           report={report}
           setReport={setReport}
           setDeleteColumns={setDeleteColumns}
           deleteColumns={deleteColumns}
           inpuntValues     ={inpuntValues} 
           setInpuntValues  ={setInpuntValues}
         />:<></>
         }
         {
          viewSquare !== true ? 
          <TableReportIndividual
           report={report}
           />         
           :
           <SquareReport
            report={report}
           />           
         }
        
       </div>     
     }    
    </>    
  )
}

export default ReportIndividual
