import {useEffect} from 'react';
import styles from "../../styles/IDSE/Confronta.module.css";
import FileConfronta from "../Collaborator/Expedient/Fields/File_Confronta";
import { useState } from "react";
import TableComponent from "./TableComponent";
import {GET_ENTERPRISE_SELECT,
        GET_CONFRONTA,
        GET_IDSE_CONFRONTA,
        GET_IDSEBIMESTRE_CONFRONTA,
        GET_SUA_CONFRONTA
} from "../../Querys/querys";
import { useQuery } from "@apollo/client";

const ConfrontaTable = (props: any) => {
  const [updateState, setUpdateState] = useState(false);
  const [systemData, setSystemData] = useState([]);
  const [suaData, setSuaData] = useState([]);
  const [idseData, setIdseData] = useState([]);
  const [idseBimestreData, setIdseBimestreData] = useState([]);
  const{data} = useQuery(GET_ENTERPRISE_SELECT,{})
  const company = data?.GET_ENTERPRISE_SELECT
  const [datos, setDatos] = useState({
    Empresa: "",
    Bimestre: "",
  });


  const {data: dataConfronta, loading: loadingConfronta, startPolling: startPollingConfronta, stopPolling: stopPollingConfronta} = useQuery(GET_CONFRONTA);
  const allConfronta = dataConfronta?.GET_CONFRONTA;

  const {data: dataIDSEConfronta, loading: loadingIDSEConfronta, startPolling: startPollingIdse, stopPolling: stopPollingIDSE} = useQuery(GET_IDSE_CONFRONTA);
  const allIDSEConfronta = dataIDSEConfronta?.GET_IDSE_CONFRONTA;

  const {data: dataIDSEBimestreConfronta, loading: loadingIDSEBimestreConfronta, startPolling: startPollingIDSEBimestre, stopPolling: stopPollingIDSEBimestre} = useQuery(GET_IDSEBIMESTRE_CONFRONTA);
  const allIDSEBimestreConfronta = dataIDSEBimestreConfronta?.GET_IDSEBIMESTRE_CONFRONTA;

  const {data: dataSUAConfronta, loading: loadingSUAConfronta, startPolling: startPollingSUA, stopPolling: stopPollingSUA} = useQuery(GET_SUA_CONFRONTA);
  const allSUAConfronta = dataSUAConfronta?.GET_SUA_CONFRONTA;


  useEffect(() => {
    if(loadingConfronta === false && allConfronta){
      setSystemData(allConfronta);
    }
    
    startPollingConfronta(1000);
    return () =>{
      stopPollingConfronta()
    }
  }, [allConfronta, startPollingConfronta, stopPollingConfronta,dataConfronta, loadingConfronta]);

  useEffect(() => {
    if(loadingIDSEConfronta === false && allIDSEConfronta){
      setIdseData(allIDSEConfronta);
    }
    
    startPollingIdse(1000);
    return () =>{
      stopPollingIDSE()
    }
  }, [allIDSEConfronta, startPollingIdse, stopPollingIDSE,dataIDSEConfronta, loadingIDSEConfronta]);

  useEffect(() => {
    if(loadingIDSEBimestreConfronta === false && allIDSEBimestreConfronta){
      setIdseBimestreData(allIDSEBimestreConfronta);
    }
    
    startPollingIDSEBimestre(1000);
    return () =>{
      stopPollingIDSEBimestre()
    }
  }, [allIDSEBimestreConfronta, startPollingIDSEBimestre, stopPollingIDSEBimestre,dataIDSEBimestreConfronta, loadingIDSEBimestreConfronta]);

  useEffect(() => {
    if(loadingSUAConfronta === false && allSUAConfronta){
      setSuaData(allSUAConfronta);
    }
    
    startPollingSUA(1000);
    return () =>{
      stopPollingSUA()
    }
  }, [allSUAConfronta, startPollingSUA, stopPollingSUA,dataSUAConfronta, loadingSUAConfronta]);


  const cambioDatos = (valor: any, desde: string) => {
    if (desde === "Empresa") {
      setDatos({ ...datos, Empresa: valor.target.value });
    }
    if (desde === "Bimestre") {
      setDatos({ ...datos, Bimestre: valor.target.value });
    }
  };

  useEffect(() => {
    setUpdateState((current) => current = !updateState);
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [systemData, suaData, idseData,idseBimestreData]);
  

  return (
    <>
      <div className={styles.contenedorOpciones}>
      <div className={styles.r_titulo}>Raz&oacute;n Social</div>
          <div>
            <select
              name="selectTipo"
              className={styles.r_select}
              onChange={(target) => cambioDatos(target, "Empresa")}
            >
              <option value="">Selecciona...</option>
              {
                company?.map((company:any) => <option className="optionSelect" key={company?.id} value={company?.id}>{company?.name}</option>)
              }
            </select>
          </div>
        <div className={styles.r_flex}>
          <div className={styles.r_tituloFecha}>Bimestre</div>
          <select
            name="selectTipo"
            className={styles.r_select}
            onChange={(target) => cambioDatos(target, "Bimestre")}
          >
            <option value="">Selecciona...</option>
            <option value="1">Enero - Febrero</option>
            <option value="2">Marzo - Abril</option>
            <option value="3">Mayo - Junio</option>
            <option value="4">Julio - Agosto</option>
            <option value="5">Septiembre - Octubre</option>
            <option value="6">Noviembre - Diciembre</option>
          </select>
        </div>
        <div style={{ marginRight: "3%", marginLeft: "20px" }}>
          <FileConfronta
            name={"IDSE"}
            Empresa={datos.Empresa}
            Bimestre={datos.Bimestre}
          />
        </div>
        <div>
          <FileConfronta
            name={"SUA"}
            Empresa={datos.Empresa}
            Bimestre={datos.Bimestre}
          />
        </div>
      </div>
      
      <div className={styles.contenedor}>
        <div className={styles.contenedorTabla}>
          <h3>Sistema</h3>
          <TableComponent rows={systemData} />
        </div>

        {suaData.length > 0 && (
          <div className={styles.contenedorTabla}>
            <h3>SUA</h3>
            <TableComponent rows={suaData} />
          </div>
        )}

        {idseData.length > 0 && (
          <div className={styles.contenedorTabla}>
            <h3>IDSE</h3>
            <TableComponent rows={idseData} />
          </div>
        )}

        {idseBimestreData.length > 0 && (
          <div className={styles.contenedorTabla}>
            <h3>IDSE Bimestre</h3>
            <TableComponent rows={idseBimestreData} />
          </div>
        )}

        {/* {idseData.map((tableRows:any,key:number)=>(
            <div className={styles.contenedorTabla}>
              {
                key == 0 ? (
                  <h3>IDSE</h3>
                ):(
                  <h3>IDSE Bimestre</h3>
                )
              }
              <TableComponent rows={tableRows} key={key}/>
            </div>
          ))
        } */}

      </div>
    </>
  );
};

export default ConfrontaTable;
