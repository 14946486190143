import { useQuery } from "@apollo/client";
import './TablesValues.css'
import {
  GET_REFERENCE_VALUE
} from "../../Querys/querys";

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
};

const TablesValueTable = (props: TabPanelProps) =>{

    const resultReference = useQuery(GET_REFERENCE_VALUE);

    const allReference = resultReference.data?.GET_REFERENCE_VALUE;

    return(
      <>
        <div className="contenedorHeader">
          <div>
              <span className="tituloHeader">Valores de Referencia</span>
          </div>
        </div>
        <div className="contenedorTabla">
            {allReference?.map((lis:any)=>
              <div>
                <div className="table-row">
                  <div className="d-flex-grow">
                    <span className="tableValuesTextoPrimerElemento">Salario Mínimo Zona Libre de la frontera:</span>
                  </div>
                  
                  <div style={{ width: "150px" }}>
                      <span className="fw-bold" style={{marginRight: "60px"}}>Valor</span>
                      <span>{lis.SalarioMinimoFrontera}</span>
                  </div>
                </div>

                <div className="table-row">
                  <div className="d-flex-grow">
                    <span className="tableValuesTextoPrimerElemento">Salario Mínimo Área General:</span>
                  </div>
                  <div style={{ width: "150px" }}>
                      <span className="fw-bold" style={{marginRight: "60px"}}>Valor</span>
                      <span>{lis.SalarioMinimo}</span>  
                  </div>
                </div>

                <div className="table-row">
                  <div className="d-flex-grow">
                    <span className="tableValuesTextoPrimerElemento">UMA:</span>
                  </div>

                  <div style={{ width: "150px" }}>
                      <span className="fw-bold" style={{marginRight: "60px"}}>Valor</span>
                      <span>{lis.UMA}</span>
                  </div>
                </div>

                <div className="table-row">
                  <div className="d-flex-grow">
                    <span className="tableValuesTextoPrimerElemento">Valor de descuento infonavit 2023:</span>
                  </div>

                  <div style={{ width: "150px" }}>
                      <span className="fw-bold" style={{marginRight: "60px"}}>Valor</span>
                      <span>{lis.DescuentoInfonavit}</span>
                  </div>
                </div>
              </div>
            )}
        </div>
      </>
    )
}

export default TablesValueTable