import {useReducer} from 'react';
import ReportContext,{initialState} from './ReportContext';
import ReportReducer from './ReportReducer'

const ReportState = (props:any) => {
   const [state, dispatch] =useReducer(ReportReducer,initialState)
   return (
    <ReportContext.Provider value={{state,dispatch}}>
        {props.children}
    </ReportContext.Provider>
  )
}

export default ReportState