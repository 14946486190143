import { useQuery } from "@apollo/client";
import { Grid } from "@material-ui/core";
import Box from '@mui/material/Box';
import { useHistory, useParams } from "react-router-dom";
import { useState, useEffect, useCallback } from "react";
import styles from "../../src/components/Payroll/PayrollStyles.module.css"
import { 
        GET_SETTLEMENTCOLLABORATOR,
        GET_PAYROLLSETTLEMENTGRAPHIC_FINISH
      } from "../Querys/querys";
import CustomTabs from '../components/Collaborators/Tab/CustomTabs';
import CustomTab from '../components/Collaborators/Tab/CustomTabMain';
import { GeneralPieChart } from "../components/Dashboard/GeneralPieChart";
import CheckPermission from "../helpers/Administradores/Permissions";

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

const PayrollTerminarFiniquito = (props: TabPanelProps) => {
  const { id, tab } = useParams<any>();
  const history = useHistory();
  const [impoTotal,setImpoTotal] = useState(0) //Importe total de la nómina
  const {data: dataFinish} = useQuery(GET_PAYROLLSETTLEMENTGRAPHIC_FINISH,{ variables:{id: id}})
  const resultSettlementCollaborator = useQuery(GET_SETTLEMENTCOLLABORATOR, {
    variables: { getSettlementPayrollcollaboratorId: id },
  });
  const allSettlementCollaborator = resultSettlementCollaborator.data?.GET_SETTLEMENTCOLLABORATOR;
  const graphData = dataFinish?.GET_PAYROLLSETTLEMENTGRAPHIC_FINISH
  const [graph, setGraph] = useState<any>(graphData)

  const handleChange = () =>{}

  const datosTotal = useCallback(() => {
    let total = 0;
    allSettlementCollaborator?.forEach((item: any) => {
      total += item.total;
    });
    setImpoTotal(total);
  },[allSettlementCollaborator])


  useEffect(() => {
    if(allSettlementCollaborator){
      datosTotal()
    }
  }, [allSettlementCollaborator, datosTotal]);

  useEffect(() => {
    if(graphData){
      setGraph(graphData[0])
    }

  }, [graphData])


  return (
    <>
      <Box mt={3} ml={5} className="Title">
        Nóminas
      </Box>
      <Box p={5} pb={3} pt={0}>
        <Grid
          container
          justify="flex-start"
        >
          <CustomTabs
            value={tab}
            onChange={handleChange}
            aria-label="simple tabs example"
          > 
            <CustomTab label="Incidencias" value={0} />
            {CheckPermission("Nominas.PreNomina") && (
              <CustomTab label="Pre-Nómina" value={1} />
            )}
            {CheckPermission("Nominas.FiniquitoLiquidacion") && (
            <CustomTab label="Finiquito / Liquidación" value={2} /> )}
            {CheckPermission("Nominas.Eventuales") && (
            <CustomTab label="Eventuales" value={3} />)}
             {CheckPermission("Nominas.Historicas") && (
            <CustomTab label="Históricas" value={4} />)}
             {CheckPermission("Nominas.Calculadora") && (
            <CustomTab label="Calculadora" value={5} />)}
            {CheckPermission("Nominas.Reportes") && (
            <CustomTab label="Reportes" value={6} />)}
          </CustomTabs>
        </Grid>
      </Box>
      <div className={styles.pc_contenedorTitulo}>
        <div className={styles.pc_contenedorProceso}>
          <div>
            <span className={styles.pc_tituloProceso}>Calcular</span>
            <span className={styles.pc_tituloProceso}>Dispersar</span>
            <span className={styles.pc_tituloProceso}>Timbrar</span>
            <span className={styles.pc_tituloProceso}>Terminar</span>
          </div>
          <div className={styles.pc_contenedorIconoSeguimiento}>
            <div className={styles.pc_circuloAmarillo}>
              <div className={styles.pd_iconoCompleto}></div>
            </div>
            <div className={styles.pc_lineaSeguimientoCompleto}></div>
            <div className={styles.pc_circuloAmarillo}>
              <div className={styles.pd_iconoCompleto}></div>
            </div>
            <div className={styles.pc_lineaSeguimientoCompleto}></div>
            <div className={styles.pc_circuloAmarillo}>
              <div className={styles.pd_iconoCompleto}></div>    
            </div>
            <div className={styles.pc_lineaSeguimientoCompleto}></div>
            <div className={styles.pc_circuloAmarillo}>
            <div className={styles.pc_circuloBlanco}></div> 
            </div>
          </div>
        </div>
        <div className={styles.pc_contenedorBotones}></div>
      
      </div>
      <div className={styles.pc_tituloContenedorPrincipal}>
        <div className={styles.pc_contenedorNombre}
        style={{
          marginRight: "auto",
          marginLeft: "auto",
          textAlign: "center",
        }}
        >
          <div>
            <span className={styles.pc_tituloPrincipal}>{tab}</span>
          </div>
 
        </div>
      </div>
      <div className={styles.p_contenedorTablaDispersion}>
        <>
          <Grid direction="row" container justify="flex-start">
            <Grid item xs={12} sm={6} md={6} lg={6} spacing={5}
            className={styles.divGraphicEventual}
            >
              <div className={styles.divGraphicConteiner}>
                <Box
                  className={styles.divGraphic}
                  style={{
                    paddingTop: "30px",
                    height: "320px",
                  }}
                >
                  <Grid
                    direction="row"
                    container
                    justify="flex-start"
                    alignItems="center"
                  >
                    <Grid
                      className={styles.alineacionTituloGraficas}
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                    >
                      <div
                      style={{
                        marginBottom: "10px",
                      }}
                      >
                        <span
                          className={styles.tituloGraficasEventual}
                        >
                          Numero de baja del mes
                        </span>
                      </div>
                    </Grid>
                    <Grid
                      className={styles.alineacionTituloGraficas}
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                    >
                      <span className={styles.tituloGraficasEventualCantidad}>{graph?.ColaboradoresBajaMes}</span>
                    </Grid>
                    <Grid
                      className={styles.alineacionTituloGraficas}
                      item
                      xs={6}
                      sm={6}
                      md={6}
                      lg={6}
                      xl={6}
                    >
                      <div>
                        <GeneralPieChart percentage={ graph?.workedDaysPercentage } tipo={'Días trabajados'} number={graph?.workedDays}
                         textSize={'12px'} color={'#4FAEEB'}
                         y={45}
                        />
                      </div>
                    </Grid>
                    <Grid
                      className={styles.alineacionTituloGraficas}
                      item
                      xs={6}
                      sm={6}
                      md={6}
                      lg={6}
                      xl={6}
                    >
                      <div>
                        <GeneralPieChart percentage={graph?.PorcentajeBajas} number={graph?.PorcentajeBajas} percentageSymbol={'%'}
                        textSize={'12px'} 
                        color={'#4FAEEB'}
                        tipo={'Rotación'}
                        y={45}
                        />
                      </div>
                    </Grid>
                    <Grid
                      className={styles.alineacionTituloGraficas}
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                    >
                      <div
                      style={{
                        width: "80%",
                        marginLeft: "auto",
                        marginRight: "auto",
                      }}
                      > 
                        <hr
                        style={{
                          borderColor: "#C7CCDC",
                          opacity: "0.5",
                        }}
                        />
                      </div>
                    </Grid>
                    <Grid
                      className={styles.alineacionTituloGraficas}
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                    >
                      <div
                      style={{
                        marginBottom: "10px",
                      }}
                      >
                        <span
                          className={styles.tituloGraficasEventual}
                        >
                          Numero de baja del año
                        </span>
                      </div>
                    </Grid>
                    <Grid
                      className={styles.alineacionTituloGraficas}
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                    >
                      <span className={styles.tituloGraficasEventualCantidadAmarillo}>{graph?.ColaboradoresBajaAnio}</span>
                    </Grid>
                  </Grid>
                </Box>
              </div>
            </Grid>
          </Grid>
      
        </>
       
      </div>
      {allSettlementCollaborator?
        <div>
          <div className={styles.pd_contenedorBarraEstado}>
            <div className={styles.pd_barraEstado} >
              <div className={styles.pd_contenedorRegreso}>
                {
                  impoTotal < 0? 
                    <div
                    onClick={() => history.push(`/InitSettlement/${id}/${tab}`)}
                    style={{
                      display: "flex",
                    }}
                    >
                      <div className={styles.pd_botonRegreso}></div>
                      <div>Regresar a "Calcular"</div>
                    </div>
                    
                  : 
                  <div
                  onClick={() => history.push(`/SettlementTimbrar/${id}/${tab}`)}
                  style={{
                    display: "flex",
                  }}
                  >
                    <div className={styles.pd_botonRegreso}></div>
                    <div>Regresar a "Timbrar"</div>
                  </div>
                }
              </div>
              <button className={styles.pd_botonSalir}
                onClick={() => history.push(`/payroll`)}
              >
                Salir
              </button>
            </div>
          </div>
        </div>
        :null
      }
    </>
  );
};

export default PayrollTerminarFiniquito;
