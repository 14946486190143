import { SetStateAction,useContext, useEffect, useState } from "react";
import CalendarsContext from "../../../context/NewCalendarContext/CalendarsContext";
import { clearCalendarsModal } from "../../../context/NewCalendarContext/Actions";
import {FormHelperText, TextField,Select, InputLabel,FormControl,MenuItem } from "@material-ui/core"
import { Formik, Form} from "formik";
import styles from '../Calendars.module.css'
import * as Yup from "yup";
import { 
    GET_ALL_PROJECT, 
    CREATE_EVENT, 
    GET_COUNT_EVENT_NONWORKINGDAY, 
    CREATE_ENTERPRISEEVENT,
    GET_ALL_EVENT_CALENDAR_BY_MONTH_YRAR
} from "../../../Querys/querys";
import { useMutation, useQuery } from "@apollo/client";
import { subMonths } from 'date-fns';
import { SuccessfulAlert } from "../../../alerts/successAlerts";
import moment from 'moment';
import { AdminContext } from "../../../context/AdminContext/AdminContext";
import DateRangeField from "../../MyMood/Fields/DateRangeField";

export const Events = ({idCalendar, daySelectedMonth, showSeleccionados, setShowColaboradoresSeleccionado, showE, setShowE}:any) => {

    const {dispatch} = useContext(CalendarsContext)
    const {adminState} = useContext(AdminContext)
    const handleClose = ()=>{
        clearCalendarsModal({}, dispatch);
    }
    const [seleccionados, setSeleccionados] = useState<any[]>([]);
    
    const [estadoFiltrado, setEstadoFiltrado] = useState<any[]>([]);
    const [isCheck, setIsCheck] = useState<any[]>([]);
    
    const [isCheckE, setIsCheckE] = useState<any[]>([]);
    const [stateCalendario, setStateCalendario] = useState([
        {
          startDate: subMonths(new  Date(parseInt(moment(daySelectedMonth).format('YYYY')), parseInt(moment(daySelectedMonth).format('MM')), parseInt(moment(daySelectedMonth).format('DD'))), 1),
          endDate: subMonths(new Date(parseInt(moment(daySelectedMonth).format('YYYY')), parseInt(moment(daySelectedMonth).format('MM')), parseInt(moment(daySelectedMonth).format('DD'))), 1),
          key: "selection"
        }]
      );
    const [createNewEvent] = useMutation(CREATE_EVENT,{
    refetchQueries:[
                    {query:GET_COUNT_EVENT_NONWORKINGDAY},
                    {query:GET_ALL_EVENT_CALENDAR_BY_MONTH_YRAR, variables:{
                        calendarId: parseInt(idCalendar), 
                        month: parseInt(moment(stateCalendario[0].startDate).format('M')), 
                        year: parseInt(moment(stateCalendario[0].startDate).format('YYYY'))
                    }},
                    {query:GET_ALL_EVENT_CALENDAR_BY_MONTH_YRAR, variables:{
                        calendarId: parseInt(idCalendar), 
                        month: parseInt(moment(stateCalendario[0].endDate).format('M')), 
                        year: parseInt(moment(stateCalendario[0].endDate).format('YYYY'))
                    }},
                ],
    });
    const [createNewEnterpriseEvent] = useMutation(CREATE_ENTERPRISEEVENT,{
        refetchQueries:[
                    {query:GET_COUNT_EVENT_NONWORKINGDAY},
                    {query:GET_ALL_EVENT_CALENDAR_BY_MONTH_YRAR, variables:{
                        calendarId: parseInt(idCalendar), 
                        month: parseInt(moment(stateCalendario[0].startDate).format('M')), 
                        year: parseInt(moment(stateCalendario[0].startDate).format('YYYY'))
                    }},
                    {query:GET_ALL_EVENT_CALENDAR_BY_MONTH_YRAR, variables:{
                        calendarId: parseInt(idCalendar), 
                        month: parseInt(moment(stateCalendario[0].endDate).format('M')), 
                        year: parseInt(moment(stateCalendario[0].endDate).format('YYYY'))
                    }},
                ],
    });

    const { data }  = useQuery(GET_ALL_PROJECT);
    const [allEnterprise, setAllEnterprise] = useState([]);

    useEffect(() => {
        if(data && data?.GET_ALL_PROJECT){
            const dataProject = data.GET_ALL_PROJECT;
            setAllEnterprise(dataProject);
            setEstadoFiltrado(dataProject);
        }
    }, [data]);
    
    const initialValues = () => {
        return {
            tipo: "",
            titulo: "",
            descripcion:"",
            area:""
        }
    }

    const AllSelect = () => {
        
        const nuevoSeleccionadas: SetStateAction<any[]> = [];
        setEstadoFiltrado(nuevoSeleccionadas);
        setSeleccionados(allEnterprise);
        setIsCheck([]);
    };

    const AllDelete = () => {
        
        const nuevoSeleccionadas: SetStateAction<any[]> = [];
        setSeleccionados(nuevoSeleccionadas);
        setEstadoFiltrado(allEnterprise);
        setIsCheckE([]);
    };

    const handleClick = (e: any) => {

        const { id, checked } = e.target;
        setIsCheck([...isCheck, id]);
        if (!checked) {
          setIsCheck(isCheck.filter((lis: any) => lis !== id));
        }
    };

    const AgregarLista = () => {
        
        const checkedBoxes = document.querySelectorAll(
          '#Universo > [type="checkbox"]:checked'
        );
        let workerToAdd: { id: string }[] = [];
    
        checkedBoxes.forEach((checkElement) => {
          workerToAdd.push({
            id: checkElement.id,
          });
        });
    
        const seleccionadosSeleccionar = allEnterprise.filter((persona: any) => {
          return workerToAdd
            .map((worker) => {
              return worker.id;
            })
            .includes(persona?.id);
        });
    
        setSeleccionados([...seleccionados, ...seleccionadosSeleccionar]);
        const nuevaSeleccionados = [...seleccionados, ...seleccionadosSeleccionar]
        const nuevoUniverso = allEnterprise.filter((persona: any) => {
          return !nuevaSeleccionados
            .map((worker) => {
              return worker.id;
            })
            .includes(persona?.id);
        });
      
        setEstadoFiltrado([...nuevoUniverso]);
        setIsCheck([]);
        setIsCheckE([]);
    };

    const handleSelectEliminar = (e: any) => {
    
        setIsCheck([]);
        setIsCheckE([]);
    
        let filtradoSeleccionado = seleccionados?.filter((lis: any) => {
            return e.target.checked && !isCheckE.includes(lis.id);
          })
          .map((lis: any) => lis.id);
        setIsCheckE(filtradoSeleccionado);
    
        
    };
    const handleClickE = (e: any) => {
    
        const { id, checked } = e.target;
        setIsCheckE([...isCheckE, id]);
        if (!checked) {
          setIsCheckE(isCheckE?.filter((lis: any) => lis !== id));
        }
        
    };

    const EliminardeLista = () => {
    
        const checkedBoxes = document.querySelectorAll(
          '#colaboladores > [type="checkbox"]:checked'
        );
        let workerToDelete: { id: string; colaborator: string }[] = [];
        checkedBoxes.forEach((checkElement) => {
          workerToDelete.push({
            id: checkElement.id,
            colaborator: checkElement.className,
          });
        });
    
        const seleccionadosEliminar = seleccionados.filter((persona) => {
          return workerToDelete?.map((worker) => {
              return worker.id;
            })?.includes(persona?.id);
        });
    
        setEstadoFiltrado([...estadoFiltrado, ...seleccionadosEliminar]);
    
        const nuevoSeleccionadas = seleccionados?.filter((persona) => {
          return !workerToDelete?.map((worker) => {
              return worker.id;
            })?.includes(persona?.id);
        });
      
        setSeleccionados(nuevoSeleccionadas);
        setIsCheck([]);
        setIsCheckE([]);
        
    
    };

    return (
        <Formik
            initialValues={initialValues()}
            validationSchema={validationSchema}
            onSubmit={formData => {
                createNewEvent
                ({
                    variables: {
                        input: {
                            name: formData.titulo,
                            calendarId: parseInt(idCalendar), 
                            description:formData.descripcion,
                            initDate:stateCalendario[0].startDate,
                            endDate:stateCalendario[0].endDate,
                            projectId:0,
                            eventType:formData.tipo
                        },
                    },
                }).then((result: any)=>{
                    
                    if(seleccionados.length > 0){
                        for(const list of seleccionados){
                            createNewEnterpriseEvent
                            ({
                                variables: {
                                    input: {
                                        enterpriseid:parseInt(list.id),
                                        eventid:parseInt(result?.data?.CREATE_EVENT?.id),
                                        userAdd:adminState?.Usuario
                                        
                                    },
                                },
                            })
                        }
                    }else{
                        
                        for(const list of estadoFiltrado){
                            createNewEnterpriseEvent
                            ({ 
                                variables: {
                                    input: {
                                        enterpriseid:parseInt(list.id),
                                        eventid:parseInt(result?.data?.CREATE_EVENT?.id),
                                        userAdd:adminState?.Usuario
                                        
                                    },
                                },
                            })
                        }
                    }
                    SuccessfulAlert({text:"Evento creado con éxito"});
                });
                handleClose();
            }}
            
        >
            {({
                values,
                errors,
                touched,
                handleChange,
                handleSubmit,
            }) => (
                <Form onSubmit={handleSubmit} >
                    <div className={styles.centerContainer}>
                        <h2 id="form-dialog-title" data-testid="TitleModal">Agregar evento</h2>
                    </div>  
                    <FormControl
                        fullWidth
                        size="small"
                        variant="outlined"
                        className={styles.dialogFields}
                        error={touched.tipo && Boolean(errors.tipo)}
                    >
                        <InputLabel
                        id="demo-simple-select-label"
                        style={{
                            backgroundColor : "#FFFFFF",
                            color: "#838383"
                        }}
                        >Tipo de evento</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            name="tipo"
                            value={values.tipo}
                            onChange={handleChange}
                        >
                            <MenuItem value="evento">Evento</MenuItem>
                            <MenuItem value="tarea">Tarea</MenuItem>
                        </Select>
                        <FormHelperText>{touched.tipo && errors.tipo}</FormHelperText>
                    </FormControl>
                    <TextField
                        fullWidth
                        className={styles.dialogFields}
                        name="titulo"
                        label="Titulo"
                        variant="outlined"
                        value={values.titulo}
                        onChange={handleChange}
                        error={touched.titulo && Boolean(errors.titulo)}
                        helperText={touched.titulo && errors.titulo}
                        size="small" />
                    <TextField
                        fullWidth
                        className={styles.dialogFields}
                        name="descripcion"
                        label="Descripción"
                        variant="outlined"
                        value={values.descripcion}
                        multiline
                        rows={4}
                        onChange={handleChange}
                        error={touched.descripcion && Boolean(errors.descripcion)}
                        helperText={touched.descripcion && errors.descripcion}
                        size="small" />
     
                    <DateRangeField setDate={setStateCalendario} containerClass={"fieldsetInput"} daySelected1 ={daySelectedMonth} daySelected2={daySelectedMonth}></DateRangeField>

                    <div className={styles.contenedorColaborator}>
                        <div className={styles.contenedorSelecioneColaborador}>
                            <button
                                className={styles.divtotalesUniverso}
                                onClick={(editar) => {
                                    editar.preventDefault()
                                    setShowE(!showE);
                                }}
                            >
                                <span className={styles.textoDivTotales}>
                    
                                Seleccionar &Aacute;rea ({estadoFiltrado?.length})
                                </span>
                            </button>
                        
                        
                        </div>
                        <div className={styles.contenedortotales}>
                        <button className={styles.divtotalesyellow} onClick={(e)=>{
                            AllSelect()
                            e.preventDefault()
                        }}>
                            {" "}
                            +{" "}
                        </button>
                        <div className={styles.contenedorTotalesText}>
                            <button className={styles.divtotalesSelecionados} 
                            onClick={(e) => {
                                e.preventDefault()
                                setShowColaboradoresSeleccionado(!showSeleccionados);
                            }}
                            >
                            <span className={styles.textoDivTotalesSelecionados}>
                            {" "}Ver Seleccionados ({seleccionados?.length}){" "}
                            </span>
                            </button>
                        </div>
                            
                        <button className={styles.divtotalesyellowl} onClick={(e)=>{
                            AllDelete()
                            e.preventDefault()
                        }}>
                            {" "}
                            -{" "}
                        </button>
                        
                        </div>
                        
                    </div>
                    {showE && estadoFiltrado?.length > 0 ? (
                        <div className={styles.DivSeleccionadosUniverso}>
                            <ul className={styles.contenedorListaAvailableCollaborators}>
                                {estadoFiltrado?.map((lis: any) => (
                                <li className={styles.listaNombresAvailableCollaborators}>
                                    <div id="Universo" className={styles.checkboxitemAvailableCollaborators}>
                                        <input
                                            id={lis.id}
                                            key={lis.id}
                                            type="checkbox"
                                            value={lis.id}
                                            checked={isCheck.includes(lis.id)}
                                            className={styles.userName}
                                            onClick={(e) => handleClick(e)}     
                                        ></input>
                                        <label htmlFor={lis.id} className={styles.proyectName}> {lis.proyectName}</label>
                                    </div>
                                </li>
                                ))}
                            </ul>
                            <br></br>
                            <button className={styles.botonAgregar} onClick={(e)=>{
                                AgregarLista()
                                e.preventDefault()
                            }}>
                               
                                <span className={styles.textoAgregar}>Agregar</span>
                            </button>
                        </div>
                    ) : (
                        ""
                    )}
                    {showSeleccionados ? (
                        <div className={styles.DivSeleccionadosCollaborators}>
                            {seleccionados?.length > 0 ? (
                                <div className={styles.checkboxitemSelecionados}>
                                <input
                                    type="checkbox"
                                    id="eliminarTodos"
                                    className={styles.inputSelecionados}
                                    onChange={(e) => handleSelectEliminar(e)}
                                ></input>
                                <label htmlFor="eliminarTodos" className={styles.labelSelecionados}> Seleccionar Todos </label>
                                </div>
                            ) : (
                                ""
                            )}

                            <ul className={styles.contenedorLista}>
                                {seleccionados?.map((lis: any) => (
                                <li className={styles.listaNombres}>
                                    <div id="colaboladores" className={styles.checkboxitemSelecionados}>
                                    <input
                                        id={lis.id}
                                        key={lis.id}
                                        checked={isCheckE.includes(lis.id)}
                                        type="checkbox"
                                        value={lis.id}
                                        onClick={(e) => handleClickE(e)}
                                        className={lis.proyectName}
                                    ></input>
                                    <label htmlFor={lis.id} className={styles.labelSelecionados} > {lis.proyectName}</label>
                                    </div>
                                </li>
                                ))}
                            </ul>
        
                            <button
                            className={styles.botonEliminarList}
                            onClick={(e) => {
                                EliminardeLista()
                                e.preventDefault()
                            }}
                            >
                            <img
                                src="/assets/icons/eliminar-lista.svg"
                                alt="Eliminar"
                                className={styles.EliminarImg}
                            />
                            Eliminar
                            </button>

                        </div>
                    ) : (
                        ""
                    )}
                   

                    <div className={styles.centerContainer}>
                    <button className={styles.buttonCancelCalendar} onClick={() => handleClose()}>
                        <b className={styles.buttonCancelCalendarText}
                        style={{
                            textTransform: "capitalize",
                            marginBottom: "15px"
                        }}
                        >Cancelar</b>
                    </button>
                    <div className={styles.conteinerSave}>
                        <button type="submit" className={styles.buttonSaveCalendar2}>
                        <b className={styles.buttonSaveCalendarText}
                        style={{
                            textTransform: "capitalize",
                            marginBottom: "15px"
                        }}
                        >Crear
                        </b>
                        </button>
                    </div>
                        
                </div>

                </Form>
            )}
        </Formik>
    )
}


// @ts-ignore
const validationSchema =  Yup.object().shape({
        tipo: Yup.string().required("El tipo de evento es requerido"),
        titulo:Yup.string().required("El titulo es requerido"),
})
// @ts-ignore
