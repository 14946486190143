import {createContext, Dispatch} from 'react';

type initialStateType = {
    openQuestion:boolean,
    idEncuestas_colaborador:String,
    Encuestas: String,
    openFilter:boolean,
    openComments:boolean,
    idSurvey: number,
    newSurvey: boolean,
    newRiskSurvey: boolean
}

type ModalPartial = {
    state:initialStateType,
    dispatch: Dispatch<any>
}

export const initialState = {
    openQuestion:false,
    idEncuestas_colaborador: "0",
    Encuestas: "",
    openFilter:false,
    openComments:false,
    idSurvey: 0,
    newSurvey: false,
    newRiskSurvey: false
}

export enum Types {
    OPENQUESTION = "OPENQUESTION",
    CLOSEQUESTION = "CLOSEQUESTION",
    OPENFILTER = "OPENFILTER",
    CLOSE_TESTCOMMENTS = "CLOSE_TESTCOMMENTS",
    OPEN_TESTCOMMENTS = "OPEN_TESTCOMMENTS",
    NEW_SURVEY = "NEW_SURVEY",
    CLOSE_SURVEY = "CLOSE_SURVEY",
    NEW_RISK_SURVEY = "NEW_RISK_SURVEY",
    CLOSE_RISK_SURVEY = "CLOSE_RISK_SURVEY"
}

const ModalInitialState = {
    state:initialState,
    dispatch:() => null
}

const MyMoodContext = createContext<ModalPartial>(ModalInitialState)

export default MyMoodContext; 