import TableReport from "./components/TableReport";

const MyReports = ({reports,loading}:any) => {
 return (
    <>
    {(loading === true) ?
        <div className="c_contenedorPrincipalReport">
          <div className="contenedorLoadingPrincipal">
            <div className="loadingPrincipal"></div>
          </div>
        </div>
        :
        <div className="c_contenedorPrincipalReport">
           <div className="c_contenedorPrincipalTableReport">
            <TableReport
            reports={reports}
            />
            </div> 
        </div>     
     }    
    </>    
  )
}

export default MyReports
