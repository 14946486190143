import { Types } from "./CalendlyContext";

const CalendlyReducer = (state:any, action: any) =>{
    const {type} = action;
    switch(type){
        case Types.CREATE_CALENDLY:
          return {
            ...state,
            createCalendly: true
          }
      case Types.CLEAR_CALENDLY:
        return {
          ...state,
          createCalendly: false
        }
    }
}

export default CalendlyReducer