import "./styles.css"
const IncidentNameText = ({incidentName}: any) =>{

    return (
            <div className="ContainerIncidentTitle" >
                
                {
                    incidentName?.map((name: any) =>(
                        <div className="incidentContainer">
                            <div className="incidentName">
                                <div className="circleIncident"
                                style={{
                                    backgroundColor: name.colorCode,
                                }}
                                ></div>
                                <div className="incidentText"><p>{name.legendTitle}</p></div>
                            </div>
                        </div>
                    ))
                }
                
            </div>
    )
}

export default IncidentNameText