import { createContext,Dispatch } from "react"

type initialStateType  ={
    _id: string,
    createCalendly: boolean,
}

type ModalPartial = {
    state: initialStateType,
    dispatch: Dispatch<any>
}

export const initialState ={
    _id: '',
    createCalendly: false,
}

export enum Types{
    CREATE_CALENDLY = "CREATE_CALENDLY",
    CLEAR_CALENDLY = "CLEAR_CALENDLY",
}

const ModalInitialState ={
    state: initialState,
    dispatch:() => null
}

const CalendlyContext = createContext<ModalPartial>(ModalInitialState)
export default CalendlyContext