import {
  Theme,
  Dialog,
  withStyles,
  TextField,
} from "@material-ui/core";
import Box from '@mui/material/Box';
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import {
  rejectInfoEmail,
} from "../../../../../services/candidateService";
import { SuccessfulAlert } from "../../../../../alerts/successAlerts";
import { useState } from "react";
import { useMutation } from "@apollo/client";
import { UPDATE_USERS } from "../../../../../Querys/querys";
import ShapeBlueEmail from "../../../../../assets/svg/ShapeBlueEmail.svg";

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const RejectInfoModal = (props: any) => {
  let { collaborator, handleClose, isOpen } = props;
  const [comment, setComment] = useState("");

  const [updateColaboradores] = useMutation(UPDATE_USERS);

  const handleRejectInfo = async () => {
    handleClose();
    await rejectInfoEmail(
      collaborator?.userAdd,
      collaborator?.fullName,
      collaborator?.id,
      comment
    );
    await updateColaboradores({
      variables: {
        updateUsersId: collaborator?.id,
        input: {
          token: "rejected",
          accept_info: true,
        },
      },
    });
    SuccessfulAlert({ text: "Se han enviado tus comentarios" }).then(() => {
      window.location.href = "/";
    });
  };

  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={isOpen}
        fullWidth={false}
        maxWidth={"md"}
        style={{"borderRadius": "3% !important"}}
      >
        <div className="contenedorModalPerceptions">
          <p className="tituloModalPerceptions">Información incorrecta</p>
        </div>
        <DialogContent className="ml32ModalPerceptions mr32ModalPerceptions">
          <Box mb={2}>
            <TextField
              error={false}
              type="text"
              variant="outlined"
              multiline
              rows={4}
              size="small"
              value={comment}
              className="espacioTextArea"
              placeholder="Describa cuál o cuáles son los datos que no son correctos…"
              onChange={({ target }) => setComment(target.value)}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <div className="contenedorBotonesPoliticsModal">
            <button
              autoFocus
              onClick={handleClose}
              className="botonbotonCancelar cursor"
            >
              Cancelar
            </button>
            <button
              autoFocus
              onClick={handleRejectInfo}
              className="botonbotonGuarda2 botonDoble cursor"
            >
              <div className="contenedorIconoBotonModal"></div>
              <div style={{"display":"flex"}}>
                <img src={ShapeBlueEmail} alt="" />
              </div>
              <div>
                <span>Enviar comentario</span>
              </div>
            </button>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default RejectInfoModal;
