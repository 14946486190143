import moment from 'moment';
moment.locale('es-mx');

const FormatDate = ({ dateOfAdmission, FechaIngreso }: any) => {
    const dateFormat = moment.utc(FechaIngreso ?? dateOfAdmission).format('L')
    return (
        <span>
            {(FechaIngreso || dateOfAdmission) ? dateFormat : ''}
        </span>
    )
}

export default FormatDate;





