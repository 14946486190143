import { Theme, Dialog, withStyles } from "@material-ui/core";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import { useHistory } from "react-router-dom";
import ReadyLogo from "../../../../../assets/svg/ReadyLogo.svg";

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const ReadyModal = (props: any) => {
  let { handleClose, isOpen } = props;

  let history = useHistory();

  const handleAcceptInfo = async () => {
    handleClose();
    history.push("2");
  };

  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={isOpen}
        fullWidth={true}
        maxWidth={"sm"}
        style={{ borderRadius: "3% !important" }}
        disableBackdropClick 
      >
        <div className="contenedorModalPerceptions2">
          <img src={ReadyLogo} alt="Ready logo" style={{ paddingTop: "5%" }} />
        </div>
        <div>
          <div className="contenedorModalPerceptions2">
            <p className="tituloModalPerceptions">¡Listo!</p>
          </div>
          <DialogContent className="ml32ModalPerceptions" style={{"paddingTop":"0"}}>
            <div className="contenedorModalPerceptions2 ">
              <span style={{ textAlign: "center", color: "#222222" }}>
                Puedes continuar con tu registro
              </span>
            </div>
          </DialogContent>
          <DialogActions>
            <div className="contenedorBotonesPoliticsModal">
              <button
                autoFocus
                onClick={handleAcceptInfo}
                className="botonbotonGuardar cursor"
                style={{ "marginLeft": "0%" }}
              >
                <div className="contenedorIconoBotonModal"></div>
                <div> Entendido</div>
              </button>
            </div>
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
};

export default ReadyModal;
