import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import List from "../List";
import BankData from "./BankData";
import BankDataColaboradores from "./Colaboradores/BankDataColaboradores";
import BankDataInactivos from "./Inactivos/BankDataInactivos";
import BankDataNuevoIngreso from "./Candidatos/BankDataNuevoIngreso";
import CheckPermission from "../../../helpers/Administradores/Permissions";

const PayData = (processProp?: any) => {
  let location = useLocation();
  let nuevoArrayEtiquetas = [];
  let nuevoArrayTablas = [];
  const [processTab, setProcessTab] = useState<any>();
  useEffect(() => {
    if (processProp) {
      setProcessTab(processProp?.process);
    }
  }, [processProp]);

  let section = location.pathname.split("/")[1];

  if (section === "collaborators-view") {
    if (
      CheckPermission(
        "Colaboradores.Inactivos.Ver.DatosParaPago.DatosBancarios"
      )
    ) {
      nuevoArrayEtiquetas.push("Datos Bancarios");
      nuevoArrayTablas.push(BankDataInactivos);
    }
  }

  if (section === "collaborators") {
    if (
      CheckPermission(
        "Colaboradores.Colaboradores.Editar.DatosParaPago.DatosBancarios.Ver"
      )
    ) {
      nuevoArrayEtiquetas.push("Datos Bancarios");
      nuevoArrayTablas.push(BankData);
    }
  }

  if (section === "nuevoingreso") {
    if (
      CheckPermission(
        "Colaboradores.NuevoIngreso.Ver.DatosParaPago.DatosBancarios"
      )
    ) {
      nuevoArrayEtiquetas.push("Datos Bancarios");
      nuevoArrayTablas.push(BankDataNuevoIngreso);
    }
  }

  if (section === "inactivos") {
    if (
      CheckPermission(
        "Colaboradores.Inactivos.Ver.DatosParaPago.DatosBancarios"
      )
    ) {
      nuevoArrayEtiquetas.push("Datos Bancarios");
      nuevoArrayTablas.push(BankDataInactivos);
    }
  }

  if (section === "candidate") {
    nuevoArrayEtiquetas.push("Datos Bancarios");
    nuevoArrayTablas.push(BankDataColaboradores);
  }

  return (
    <List
      title="DATOS BANCARIOS"
      labels={nuevoArrayEtiquetas}
      components={nuevoArrayTablas}
      numTab={5}
      progress={3}
      process={processTab}
    />
  );
};

export default PayData;
