import { ChangeEvent, Fragment, useContext, useState } from "react";
import { Grid, TextField, Tooltip, Button } from "@material-ui/core";
import Box from '@mui/material/Box';
import CollaboratorContext from "../../../context/CollaboratorContext/CollaboratorContext";
import { handleSetActiveStep, updateData } from "../../../helpers/Collaborator/Collaborator";
import { withStyles, Theme } from "@material-ui/core/styles";
import { WarningAlert } from "../../../alerts/WarningAlert";
import InputMask from "react-input-mask";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useMutation } from "@apollo/client";
import { Form } from "semantic-ui-react";
import { GET_USERS_BY_ID, UPDATE_USERS, GET_ALL_USERS_COLLABORATOR, GET_EXCEL_ACTIVE_USER } from "../../../Querys/querys";
import { SuccessfulAlert } from "../../../alerts/successAlerts";
import SaveIcon from "@material-ui/icons/Save";
import CheckPermission from "../../../helpers/Administradores/Permissions";
import { useHistory } from "react-router-dom";
import { validateCURPFormat, validateNSSFormat, validateRFCFormat } from "../../../helpers/Collaborator/Fields";

const HtmlTooltipImage = withStyles((theme: Theme) => ({
  tooltip: {
    maxWidth: 640,
    maxHeight:570,
    width:640,
    height:570,
    marginRight:0,
    fontSize: theme.typography.pxToRem(14),
  },
}))(Tooltip);

const HtmlTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    maxWidth: 500,
    fontSize: theme.typography.pxToRem(14),
  },
}))(Tooltip);

const IdentityData = () => {
  const { state, dispatch } = useContext(CollaboratorContext);
  const [nssMask, setNssMask] = useState("");
  const [curp, setCurp] = useState("");
  const [rfc, setRFC] = useState("");
  const history = useHistory();

  const [updateColaboradores] = useMutation(UPDATE_USERS, {
    refetchQueries: [
      {
        query: GET_USERS_BY_ID,
        variables: { getUsersId: state.collaborator.id },
      },
      { query: GET_ALL_USERS_COLLABORATOR },
      { query: GET_EXCEL_ACTIVE_USER }
    ],
  });

  const handleChange = async (
    e: ChangeEvent<{ name: string; value: unknown }>
  ) => {
    await updateData(e, state, dispatch, 1);
  };

  const handleChangeCurp = async (
    e: ChangeEvent<{ name: string; value: unknown }>
  ) => {
    await updateData(e, state, dispatch, 1);
    if (state.collaborator?.CURP !== undefined) {
      const re =
        /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/;
      const validado = re.test(state.collaborator?.CURP);
      if (!validado) {
        return await WarningAlert({
          text: "El formato de tu CURP no es la correcta.",
        });
      }
    }
  };

  const handleChangeRfc = async (
    e: ChangeEvent<{ name: string; value: unknown }>
  ) => {
    await updateData(e, state, dispatch, 1);
    if (state.collaborator?.RFC !== undefined) {
      const re =
        /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/;
      const validado = state.collaborator?.RFC.toLocaleUpperCase().match(re);
      if (!validado) {
        return await WarningAlert({
          text: "El formato de tu RFC no es la correcta.",
        });
      }
    }
  };

  const initialValues = () => {
    return {
      IMSS: state.collaborator?.IMSS ?? "",
      CURP: state.collaborator?.CURP ?? "",
      RFC: state.collaborator?.RFC ?? "",
      ClaveElectoralPasaporte:
        state.collaborator?.ClaveElectoralPasaporte ?? "",
      creditoInfonavit: state.collaborator?.creditoInfonavit ?? "",
      workPermission: state.collaborator?.workPermission ?? "",
      ZCSAT: state.collaborator?.ZCSAT ?? "",
    };
  };

  const formik = useFormik({
    initialValues: initialValues(),
    validationSchema: Yup.object(validationSchema()),

    onSubmit: async (formData) => {
      //formData.creditoInfonavit = formData.creditoInfonavit.toString();
      //formData.workPermission = formData.workPermission.toString();
      formData.RFC = formData.RFC.toUpperCase();
      formData.CURP = formData.CURP.toUpperCase();

      if (formData.workPermission === ''){
        formData.workPermission = '0'
      }
      if (formData.creditoInfonavit === ''){
        formData.creditoInfonavit = '0'
      }

      updateColaboradores({
        variables: {
          updateUsersId: state.collaborator?.id,
          input: formData,
          process: {
            tab: "personalData",
            section: "section_3",
          },
        },
      }).then((res) => {
        SuccessfulAlert({ text: "Se actualizó correctamente" }).then(() => {
          history.push("3");
          handleSetActiveStep(0, dispatch);
        });
      });
    },
  });

  return (
    <Form onSubmit={formik.handleSubmit}>
      <div className="novalidate__border">
        <Grid direction="row" container spacing={2}>
          <Grid xs item direction="row" container alignItems="center">
            <Grid xs={11} item>
              <InputMask
                mask="99999999999"
                defaultValue={state.collaborator?.IMSS || nssMask}
                onChange={(e) => {
                  setNssMask(e.target.value);
                  handleChange(e);
                }}
                disabled={false}
                onBlur={formik.handleChange}
              >
                <TextField
                  name="IMSS"
                  label="NSS"
                  style={{width: '240px'}}
                  variant="outlined"
                  size="small"
                  fullWidth={true}
                  error={formik.touched.IMSS && Boolean(formik.errors.IMSS)}
                  helperText={formik.touched.IMSS && formik.errors.IMSS}
                />
              </InputMask>
            </Grid>
            <Grid xs={1} item>
              <Box ml={1}>
                <HtmlTooltip
                  title={
                    <Fragment>
                      Si no estas asociado, tramita tu número en linea
                    </Fragment>
                  }
                >
                  <img
                    src="/assets/icons/PreguntaAzul.png"
                    alt="Question"
                    height="20"
                  />
                </HtmlTooltip>
              </Box>
            </Grid>
          </Grid>
          <Grid xs item>
            <InputMask
                mask="aaaa999999aaa*****"
                defaultValue={state.collaborator?.CURP || curp}
                onChange={(e) => {
                  setCurp(e.target.value);
                }}
                disabled={false}
                onBlur={(e) => { handleChangeCurp(e); formik.handleChange(e)}}
            >
              <TextField
                name="CURP"
                label="CURP"
                variant="outlined"
                size="small"
                style={{width: '240px'}}
                error={formik.touched.CURP && Boolean(formik.errors.CURP)}
                helperText={
                  (formik.touched.CURP && formik.errors.CURP)
                }
              />
            </InputMask>
          </Grid>
        </Grid>
        <Grid direction="row" container spacing={2}>
          <Grid xs item>
            <InputMask
                mask="aaaa999999***"
                defaultValue={state.collaborator?.RFC || rfc}
                onChange={(e) => {
                  setRFC(e.target.value);
                }}
                disabled={false}
                onBlur={(e) => { handleChangeRfc(e); formik.handleChange(e)}}
            >
              <TextField
                name="RFC"
                label="RFC"
                variant="outlined"
                size="small"
                style={{width: '240px'}}
                error={formik.touched.RFC && Boolean(formik.errors.RFC)}
                helperText={formik.touched.RFC && formik.errors.RFC}
              />
            </InputMask>
          </Grid>
          <Grid xs item direction="row" container alignItems="center">
            <Grid xs={10} item>
              <TextField
                name="ClaveElectoralPasaporte"
                defaultValue={state.collaborator?.ClaveElectoralPasaporte || ""}
                label="Clave de elector o pasaporte"
                variant="outlined"
                size="small"
                style={{width: '240px'}}
                onBlur={(e) => handleChange(e)}
                InputProps={{
                  readOnly: false,
                }}
                error={formik.touched.ClaveElectoralPasaporte && Boolean(formik.errors.ClaveElectoralPasaporte)}
                onChange={formik.handleChange}
                helperText={
                  formik.touched.ClaveElectoralPasaporte &&
                  formik.errors.ClaveElectoralPasaporte
                }
              />
            </Grid>
            <Grid xs item style={{marginLeft: '8px'}}>
              <Box ml={2}>
                <HtmlTooltipImage
                  title={
                    <Fragment>
                      <img src="/assets/INE.png" alt="Question" style={{width:'100%', height:'auto', display:'block', margin:'auto'}} />
                    </Fragment>
                  }
                >
                  <img
                    src="/assets/icons/PreguntaAzul.png"
                    alt="Question"
                    height="20"
                  />
                </HtmlTooltipImage>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid direction="row" container spacing={2}>
          <Grid xs item>
          <InputMask
            mask="99999"
            defaultValue={state.collaborator?.ZCSAT}
            onChange={(e) => {
              handleChange(e);
            }}
            disabled={false}
            onBlur={formik.handleChange}
          >
            <TextField
              name="ZCSAT"
              label="C.P. SAT"
              variant="outlined"
              size="small"
              style={{width: '240px'}}
              error={formik.touched.ZCSAT && Boolean(formik.errors.ZCSAT)}
              helperText={formik.touched.ZCSAT && formik.errors.ZCSAT}
            />
          </InputMask>
          </Grid>
          
        </Grid>
        <Box>
          <Grid direction="row" container spacing={2}>
            <Grid xs item direction="row" container alignItems="center">
              <Grid xs={11} item>
                <TextField
                  type="number"
                  name="workPermission"
                  defaultValue={state.collaborator?.workPermission || ""}
                  label="Permiso de trabajo(extranjeros)"
                  variant="outlined"
                  size="small"
                  fullWidth={true}
                  onChange={formik.handleChange}
                  InputProps={{
                    readOnly: false,
                  }}
                />
              </Grid>
              <Grid xs={1} item>
                <Box ml={1}>
                  <HtmlTooltipImage
                    title={
                      <Fragment>
                        <img
                          src="/assets/Permiso_De_Trabajo.png"
                          alt="Question"
                          style={{width:'100%', height:'auto', display:'block', margin:'auto'}}
                        />
                      </Fragment>
                    }
                  >
                    <img
                      src="/assets/icons/PreguntaAzul.png"
                      alt="Question"
                      height="20"
                    />
                  </HtmlTooltipImage>
                </Box>
              </Grid>
            </Grid>

            <Grid xs item>
              <TextField
                type="number"
                name="creditoInfonavit"
                defaultValue={state.collaborator?.creditoInfonavit || ""}
                label="Crédito infonavit"
                variant="outlined"
                size="small"
                fullWidth={true}
                onChange={formik.handleChange}
                InputProps={{
                  readOnly: false,
                }}
              />
            </Grid>
          </Grid>
        </Box>
      </div>
      {CheckPermission(
        "Colaboradores.Colaboradores.Editar.DatosPersonales.DatosIdentidad.Guardar"
      ) && (
        <Grid
          direction="row"
          container
          justify="flex-end"
          alignItems="center"
          style={{ marginTop: "20px" }}
        >
          <Button type="submit" className="buttonSave">
            <SaveIcon />
            &nbsp; Guardar
          </Button>
        </Grid>
      )}
    </Form>
  );
};

const validationSchema = () => {
  return {
    IMSS: Yup.string().required("Obligatorio").test(
      "check_nss",
      "El formato del NSS no es correcto",
      function (IMSS: string | undefined): boolean {
        return validateNSSFormat(IMSS || '') ? true : false
      }
    ),
    CURP: Yup.string()
      .required("Obligatorio")
      .min(18, "CURP debe contener 18 caracteres")
      .max(18, "CURP no puede tener más de 18 caracteres")
      .test(
        "check_curp",
        "El formato del CURP no es correcto",
        function (CURP: string | undefined): boolean {
          return validateCURPFormat(CURP || '') ? true : false
        }
      ),
    RFC: Yup.string().required("Obligatorio")
      .test(
        "check_rf",
        "El formato del RFC no es correcto",
        function (RFC: string | undefined): boolean {
          return validateRFCFormat(RFC || '') ? true : false
        }
      ),
    ClaveElectoralPasaporte: Yup.string().required(
      "Obligatorio"
    ),
    ZCSAT: Yup.string().required("Obligatorio")
  };
};

export default IdentityData;
