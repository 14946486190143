import styles from "../../styles/IDSE/IDSE.module.css"
import {useQuery} from "@apollo/client"
import { GET_MOVIMIENTOSIDSE, GET_RESULTADO_REPORTE_IDSE } from "../../Querys/querys";
import { useState,useEffect, useContext } from "react";
import {formatter} from "../../helpers/formatoMoneda"
import PayrollProcessContext from "../../context/PayrollProcess/PayrollProcessContext";
import { openalertaEnviarIDSE,openAlertaEnvioIDSEDos,openMensajeNoProcesado,openMovimientoIDSEEXTERNO } from "../../context/PayrollProcess/Actions";
import EnviarIDSEModal from "./Modals/EnviarIDSEModal";
import DescartarIDSEModal from "./Modals/DescartarEnviarModal";
import EnviarMovimientoModal from "./Modals/EnviarMovimientoModal";
import EnvioMovimientoDescartado from "./Modals/EnvioMovimientoDescartado";
import MensajeNoProcesado from "./Modals/MensajeNoProcesado";
import { CreateExcelIDSE } from "../../helpers/ReporteExcel/CreateExcelIDSE";
import CheckPermission from "../../helpers/Administradores/Permissions";


interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
};

const IDSETable = (props: TabPanelProps) =>{
  const {dispatch} = useContext(PayrollProcessContext)
  const [filtrado, setFiltrado] =  useState<any[]>([])
  const [isCheck, setIsCheck] =  useState<any[]>([])

  const {data, startPolling, stopPolling} = useQuery(GET_MOVIMIENTOSIDSE)
  const resultado = data?.GET_MOVIMIENTOSIDSE

  const {data:dataReporteExcel} = useQuery(GET_RESULTADO_REPORTE_IDSE,{})

  const datosReporte = dataReporteExcel?.GET_RESULTADO_REPORTE_IDSE

  const obtenerDatos = async () => {
    await setFiltrado(resultado)
  };

  const datosFiltrados = (e:any) =>{
    if(e !== '') {
      let expresion = new RegExp(`${e}.*`, "i")
      const nuevoFiltrado = filtrado?.filter((lis:any) => expresion.test(lis.Nombre))
      setFiltrado(nuevoFiltrado)
    }else{
      obtenerDatos()
    }
  }

  const handleOnExport = (Tipo: any)=>{
    CreateExcelIDSE(datosReporte)
  }
  
  const handleSelectAll = (e:any) =>{
    if(!e.target.checked){
      setIsCheck([])
    }
    let filtradoSeleccionado = (resultado.filter((lis:any)=>{
      return e.target.checked && !isCheck.includes(lis.idMovimiento) && (lis.Externo === false && lis.solicitudIDSE === false)
    }).map((lis:any) =>(lis.idMovimiento)))
    setIsCheck(filtradoSeleccionado)
  }

  const handleClick =  (e:any) => {
    const {id, checked} = e.target
    setIsCheck([...isCheck, parseInt(id)])
    if(!checked){
      setIsCheck(isCheck.filter((lis:any) => lis !== parseInt(id)))
    }

  }

  const enviarIDSE = () =>{
    openalertaEnviarIDSE({alertaEnviarIDSE:true }, dispatch);
  }

  const enviarIDSEIndividual = (idMovimiento:any) =>{
    openAlertaEnvioIDSEDos({alertaEnviarIDSE:true,idMovimiento }, dispatch);
  }

  const enviarIDSEExterno = (idMovimiento:any) =>{
    openMovimientoIDSEEXTERNO({alertaEnvioIDSEExterno:true,idMovimiento}, dispatch);
  }

  const abreNoProcesado = (Comentario:string) =>{
    openMensajeNoProcesado({alertaNoProcesado:true,Comentario}, dispatch);
  }

  useEffect(()=>{
    startPolling(1000)
    setFiltrado(current => current = resultado)
    return() => {
      stopPolling()
    }
  },[resultado, isCheck, startPolling, stopPolling])
  
  console.log('Este es el resultado', resultado)

  return(
    <div className={styles.contenedor}>
      <div className={styles.contenedorTitulo}>                                  
        Movimientos IDSE
      </div>
    
      <div className={styles.contenedorAccionesTitulo}>
        <div className={styles.contenedorInput}>
          <div>
            <input
              type="text"
              className={styles.input}
              placeholder="Buscar al colaborador por nombre"
              onChange={(e) => datosFiltrados(e.target.value)}/>
        </div>
        <div className={styles.iconoBuscar}></div>
      </div>

      <div className={styles.contenedorBotones}>
        <button 
          className={styles.botonDescargar}
          onClick={handleOnExport}
          >
          <div className={styles.iconoDescargar}></div>
          <div>
            Descargar reporte
          </div>
        </button>
        {
          CheckPermission("IDSE.IDSE.Enviar") && (
            <button
              className={styles.pc_botonDispersarInactivo}
              onClick={() =>  enviarIDSE()}>
                <div>
                Enviar
                </div>
                <div className={styles.pc_iconoDispersar}></div>
            </button>
          )
        }
        </div>
      </div>
      <div className={styles.contenedorEstatus}>
        <div className={styles.contenedorInternoEstatus}>
          <div className={styles.estatusAceptado}></div>
          <div className={styles.tituloEstatus}>
            Aceptado
          </div>
        </div>
        <div className={styles.contenedorInternoEstatus}>
          <div className={styles.estatusRechazado}></div>
          <div className={styles.tituloEstatus}>
            Rechazado
          </div>
        </div>
      </div>
      <div className={styles.contenedorTabla}>
        <table className={styles.tabla}>
          <thead className={styles.thead}>
            <tr>
              <td className={`${styles.tituloTabla} ${styles.primeraColumna}`}>
                <div id="colaboladores" className={styles.checkboxitem}>
                  <input
                    id="check"
                    type="checkbox"
                    onChange={(e) => handleSelectAll(e)}
                    ></input>
                      <label htmlFor="check"> Colaboradores </label>
                </div>
              </td>
              <td className={styles.tituloTabla}>NSS</td>
              <td className={styles.tituloTabla}>SBC</td>
              <td className={styles.tituloTabla}>Movimiento</td>
              <td className={styles.tituloTabla}>Fecha del movimiento</td>
              <td className={styles.tituloTabla}>Acciones</td>
              <td className={styles.tituloTabla}>Respuesta Envio</td>
              <td className={styles.tituloTabla}>Respuesta IDSE</td>
              <td className={styles.tituloTabla}>Estatus</td>
            </tr>
          </thead>
          <tbody>
            {filtrado?.map((lis:any)=>(
            <tr className={(lis.Respuesta) === '' || (lis.Respuesta) === 'Externo' || (lis.Respuesta) === 'Esperando respuesta' || (lis.Respuesta === 'Enviado')? styles.registroBase: (lis.Respuesta === 'Aceptado')? styles.registroAceptado: styles.registroRechazado}>
              <td className={styles.primeraColumna}>
                <div id="colaboladores" className={styles.checkboxitem}>
                      <input
                        id={lis.idMovimiento}
                        key={lis.idMovimiento}
                        type="checkbox"
                        value={lis.idMovimiento}
                        checked ={isCheck.includes(lis.idMovimiento)}
                        onChange={(e) => handleClick(e)}
                        disabled={(lis.solicitudIDSE === true || lis.Externo === true)?true:false}
                      ></input>
                      <label htmlFor={lis.idMovimiento}>  {lis.Nombre} </label>
                    </div>
                </td>
              <td>{lis.IMSS}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
              <td>{formatter(lis.SCB)}</td>
              <td>{lis.Tipo}</td>
              <td>{lis.Fecha}</td>
              <td className={styles.contenedorBotonesEnvio}>
                {( (lis.solicitudIDSE === false && lis.Externo === false) || lis.Respuesta ==='No procesado' )?
                <>
                  {(lis.Respuesta !== 'No procesado' && CheckPermission("IDSE.IDSE.Enviar"))?
                    <button
                      className={styles.botonEnviarIDSE}
                    onClick={() =>  enviarIDSEIndividual(lis.idMovimiento)}
                    >
                      Enviar
                      <div className={styles.iconoEnviarIDSE}></div>
                    </button>
                  :
                  null}
                  {
                    CheckPermission("IDSE.IDSE.Descartar") && (
                      <button
                        className={styles.botonDescartarIDSE}
                        onClick={() =>  enviarIDSEExterno(lis.idMovimiento)}
                      >
                        Descartar
                        <div className={styles.iconoDescartar}></div>
                      </button>
                    )
                  }
                </>
                : null
                }

              </td>
              <td className={styles.columnaDatosCentrados}>
              {(lis.Respuesta === 'EnProceso' || lis.Respuesta === 'No procesado' || lis.Respuesta === '' || lis.Respuesta ==='Esperando respuesta' || lis.Respuesta ==='Externo' || lis.respuestaIDSEDos ==='')?
                null:
                <a
                  href={lis.respuestaIDSEDos}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.botonDescargarDocumento}
                  >
                  <div className={styles.iconoDescargarIDSE}></div>
                  Descargar documento
                </a>
              }
              </td>
              <td className={styles.columnaDatosCentrados}>
              {(lis.Respuesta === 'EnProceso' || lis.Respuesta === 'No procesado' || lis.Respuesta === '' || lis.Respuesta ==='Esperando respuesta' || lis.Respuesta ==='Externo' || lis.respuestaIDSE==='')?
                null:
                <a
                  href={lis.respuestaIDSE}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.botonDescargarDocumento}
                  >
                  <div className={styles.iconoDescargarIDSE}></div>
                  Descargar documento
                </a>
              }
              </td>
              <td className={styles.columnaEstatus}>
                {lis.Respuesta}
                {(lis.noProcesado === true)?
                  <div
                    onClick={() => abreNoProcesado(lis.Comentario)}
                  className={styles.iconoNoProcesado}>?</div>
                  :null
                }
              </td>
             </tr>
            ))}
          </tbody>
        </table>
      </div>
      <EnviarIDSEModal
        seleccionados={isCheck}/>
      <DescartarIDSEModal/>
      <EnviarMovimientoModal />
      <EnvioMovimientoDescartado />
      <MensajeNoProcesado/>
    </div>
  )
}

export default IDSETable