import { TBColumn } from "../../../interfaces/TableBits.interfaces";

const objectiveTableColumns: TBColumn[] = [
  {
    id: 'ObjectiveName',
    label: 'Nombre del objetivo',
    align: 'left',
  },
  {
    id: 'nameIndicator',
    label: 'Indicador',
    align: 'left',
  },
  {
    id: 'metricIndicator',
    label: 'Tipo de indicador',
    align: 'left',
  },
  {
    id: 'metricObjective',
    label: 'Metrica objetivo',
    align: 'left',
  },
  {
    id: 'weight',
    label: 'Peso',
    align: 'left',
  },
  {
    id: 'evidenceCount',
    label: 'Evidencias',
    align: 'left',
  },
  {
    id: 'progress',
    label: 'Progreso',
    align: 'left',
  }
]

export default objectiveTableColumns;