import React from "react";
import Box from '@mui/material/Box';
import style from "./TableOutsources.module.css";
import { useQuery } from "@apollo/client";
import { GET_ENTERPRISE_OUTSOURCER } from "../../../Querys/querys";

import OutsourcesCard from "./OutsourcesCard";
import ModalEditOutsourcers2 from "../ModalEditOutsourcers/ModalEditOutsourcers2";

function TableOutsorces() {
  const resultOutsources = useQuery(GET_ENTERPRISE_OUTSOURCER);
  const allOutsources = resultOutsources.data?.GET_ENTERPRISE_OUTSOURCER;

  return (
    <Box className={style.boxTable}>
      <Box className={style.boxTitle}>
        <Box className={style.tituloUno} ml={1}>Outsourcer</Box>
        <Box className={style.tituloUno}>Razón social</Box>
      </Box>
      <Box className={style.boxOutsoursers}>
        {/* A aprtir de aquí se debe iterar */}
        {allOutsources?.map((c: any) => {
          return (
             (
              <OutsourcesCard
                id={c.id}
                logo=''
                nombre={c.name}
                razonSocial={c.RazonSocial}
              />
            )
          );
        })}
      </Box>
      
    </Box>
  );
}

export default TableOutsorces;
