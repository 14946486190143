import {useState, useContext} from "react";
import {Dialog} from "@material-ui/core"
import styles from './PayrollModals.module.css'
import { clearPayrollProcess } from "../../../context/PayrollProcess/Actions";
import PayrollProcessContext from "../../../context/PayrollProcess/PayrollProcessContext";
import { useMutation } from "@apollo/client";
import { SEND_DISPERSION } from "../../../Querys/querys";
import { SuccessfulAlert } from "../../../alerts/successAlerts";

const AceptarDispersarIndividual = (props:any) =>{
  const {totalesDispersar, totales, seleccionados} = props
  const {state, dispatch} = useContext(PayrollProcessContext)
  const [activo, setactivo] = useState(false)
  const [activoMensajeDos, setactivoMensajeDos] = useState(false)
  const [mensajeDos, setMensajeDos] = useState(false)

  const [sendDispersion] = useMutation(SEND_DISPERSION,{
  })

  const handleClose = () =>{
    clearPayrollProcess({}, dispatch)
  }

  const handleChange = (e: any) =>{
    if(e.target.value === 'CONFIRMAR'){
        setactivo(true)
    }else{
        setactivo(false)
    }
  }

  const handleChangenMensajeDos = (e: any) =>{
    if(e.target.value === 'CONFIRMAR'){
        setactivoMensajeDos(true)
    }else{
        setactivoMensajeDos(false)
    }
  }

  const handelMensajeDos =()=>{
    setMensajeDos(!mensajeDos)
  }


  const agregaSeleccionados = async() =>{
    let Array:any = []

      Array.push({ id: parseInt(state._id) })
    
    await sendDispersion({
      variables:{
        sendDispersionId: Array
      }
    })
    setactivo(false)
    setactivoMensajeDos(false)
    SuccessfulAlert({text: "Registro Dispersado"})
    handleClose()
  }

  return(
    <Dialog aria-labelledby='costumized-dialog-title' open={state.aceptarDispersarIndividual} fullWidth={false}  onClose={handleClose} maxWidth={"md"}>
      <div className={styles.contenedorPrincipal}>
        <div className={styles.contenedorCerrarModal}>
          <div
            className={styles.cerrarModal}
            onClick={()=> handleClose()}
          >
          </div>
        </div>
        <div className={styles.contenedorPrincipal}>
          <div className={styles.fim_iconoPrincipal}>
            <div className={styles.fim_iconoComplemento}>
            </div>
          </div>
        </div>
        <div className={styles.tituloAtencion}>
          ¡Atenci&oacute;n!
        </div>
        {mensajeDos === false?
          <>
            <div className={styles.em_mensajePrtincipal}>
              Est&aacute;s por dispersar n&oacute;mina a {totalesDispersar}/{totales} colaboradores
            </div>
          <div className={styles.em_mensajePrtincipal}>
            Escribe CONFIRMAR para continuar
          </div>
          <div>
              <input
                className={styles.input}
                type="text"
                onChange={(e) => handleChange(e)}
              />
          </div>
          </>
        :
          <>
            <div className={styles.em_mensajePrtincipal}>
              Recuerda que este movimiento genera un costo de servicio de
            </div>
            <div className={styles.em_mensajePrtincipal}>
            $0.00+IVA
            </div>
                <div className={styles.em_mensajePrtincipal}>
                  Escribe CONFIRMAR para continuar
                </div>
            <div>
              <input
                className={styles.input}
                type="text"
                onChange={(e) => handleChangenMensajeDos(e)}
              />
            </div>
          </>
        }
        <div className={styles.em_contenedorBotones}>
              <button
                className={styles.em_botonCancelar}
                onClick={()=> handleClose()}
              >
                Cancelar
              </button>
              {mensajeDos === true?
                (activoMensajeDos === true ?
                  <button className={`${styles.botonesAtencion} ${styles.continuarAtencion}`}
                    onClick={()=> agregaSeleccionados()}
                  >
                    <div>
                      Confirmar
                    </div>
                  </button>
                :
                  <button
                    className={`${styles.botonesAtencion} ${styles.continuarAtencionInactivo}`}
                  >
                    <div>
                      Confirmar
                    </div>
                  </button>
                )
              :
                (activo === true ?
                  <button className={`${styles.botonesAtencion} ${styles.continuarAtencion}`}
                  onClick={()=>handelMensajeDos()}
                  >
                    <div>
                      Confirmar
                    </div>
                  </button>
              :
                <button
                  className={`${styles.botonesAtencion} ${styles.continuarAtencionInactivo}`}
                  >
                    <div>
                      Confirmar
                    </div>
                </button>
              )
            }


            </div>
      </div>
    </Dialog>
  )
}

export default AceptarDispersarIndividual