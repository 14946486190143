/* eslint-disable array-callback-return */
import { useContext,  useState,useEffect } from "react";
import ReportContext from "../../../context/ReportsContext/ReportContext";
import { clearReportsModal } from "../../../context/ReportsContext/Actions"
import { Dialog, DialogContent } from "@material-ui/core"
import { Formik, Form } from "formik";
import styles from '../../../components/NewCalendar/Calendars.module.css'
import { addDays, format,subMonths} from 'date-fns';
import DateRangeField from "../../MyMood/Fields/DateRangeField";

const DateRangeReport = ({
  tabSelect,
  initAllReports,
  setAllReports,
  initMyReports,
  setMyReports,

}: any) => {
  const { state, dispatch } = useContext(ReportContext)
  const [stateCalendario, setStateCalendario] = useState([
    {
      startDate: subMonths(new Date(), 0),
      endDate: addDays(new Date(), 0),
      key: "selection"
    }]
  );  

  const handleClose = () => {
     clearReportsModal(false, dispatch)
  }
  
  const initialValues = () => {
    return {
      name: ""
    }
  }

  return (
    <Dialog
      open={state.findModalReports}
      aria-labelledby="form-dialog-title" maxWidth="sm" fullWidth={true}>
      <div className={styles.dialogContainer}>
        <div className={styles.containerTitle}>
          <div className={styles.centerContainer}
            style={{
              marginLeft: 'auto',
              marginRight: 'auto'
            }}
          >
            <h2 id="form-dialog-title" data-testid="TitleModal">Seleccione el rango</h2>
          </div>
          <div className={styles.contenedorCerrarModal}>
            <div className={styles.iconoCerrarModal} onClick={(() => handleClose())}></div>
          </div>
        </div>
        <DialogContent>
          <Formik
            initialValues={initialValues()}
            onSubmit={ () => {
              const tabReport=[0,1];
              const startDate=format(stateCalendario[0].startDate, 'yyyy-MM-dd');                         
              const endDate  =format(stateCalendario[0].endDate, 'yyyy-MM-dd');
              if(tabSelect===tabReport[0]){
                const reports = [...initAllReports]
                let filterDate = reports.filter((report:any) =>{
                const splitDate=report.ReportDate.split('T')
                return splitDate[0] >= startDate && splitDate[0] <= endDate                                                
                  })                            
                setAllReports(filterDate)
              }
              
              if(tabSelect===tabReport[1]){
                const reports = [...initMyReports]
                let filterDate = reports.filter((report:any) =>{
                const splitDate=report.ReportDate.split('T')
                 if(splitDate[0] >= startDate && splitDate[0] <= endDate ){
                       return report
                      }
                  })              
                setMyReports(filterDate)
              }
              handleClose();
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleSubmit,
            }) => (
              <Form onSubmit={handleSubmit}>
                <DateRangeField setDate={setStateCalendario} containerClass={"fieldsetInput"}></DateRangeField>
                <br></br>
                <div className={styles.centerContainer}>
                  <button 
                     type="button" 
                     className={styles.buttonCancelCalendar}
                     onClick={() => handleClose()}>
                     <b className={styles.buttonCancelCalendarText}
                      style={{
                        textTransform: "capitalize"
                      }}
                    >Salir</b>
                  </button>
                  <div className={styles.conteinerSave}>
                    <button type="submit" className={styles.buttonSaveCalendar2}>
                      <b className={styles.buttonSaveCalendarText}
                        style={{
                          textTransform: "capitalize"
                        }}
                      >Filtrar
                      </b>
                    </button>
                  </div>
                </div>

              </Form>
            )}
          </Formik>
        </DialogContent>
      </div>
    </Dialog>
  )
}

export default DateRangeReport