import Box from '@mui/material/Box';
import 'moment/locale/es-mx';
import moment from 'moment';

const TitleField = ({ autor, user }:any) => {
    moment.locale('es-mx');

    return (
        <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
        >
            <Box
                display="flex"
                flexDirection="column"
                justifyContent="flex-start"
                className="boxData__userField"
            >
                <b>{ user && user.Fullname ? user.Fullname : autor}</b>
            </Box>

        </Box>
    )
}

export default TitleField;
