import React, { useState, useContext, useEffect } from "react";
import styles from "./MisOutsourcers.module.css";
import Box from '@mui/material/Box';
import InfoIcon from "@mui/icons-material/Info";
import TableOutSources from "./TableOutsources/TableOutsorces";
import { useQuery } from "@apollo/client";
import { GET_ENTERPRISE_OUTSOURCER } from "../../Querys/querys";
import OutsourcersContext from '../../context/Outsourcers/OutsourcersContext';
import DeleteOutsources from '../../components/MisOutsourcers/ModalDeleteOutsources/DeleteOutsources';

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
};

export const MisOutSourcers = (props: TabPanelProps) => {
  const { state, dispatch } = useContext(OutsourcersContext);
  const [active, setActive] = useState(false);
  const [nuevo, setNuevo] = useState(true)
  const [resultado, setResultado]= useState<any[]>([])

  const toggle = () => {
    setActive(!active);
  };
  const {data: dataOutsources, startPolling, stopPolling} = useQuery(GET_ENTERPRISE_OUTSOURCER);
  const allOutsources = dataOutsources?.GET_ENTERPRISE_OUTSOURCER;
  const activos = allOutsources?.map((outsorcer: any) => outsorcer.status);
  const result = allOutsources

  useEffect(()=>{
    startPolling(1000);
    return () =>{
    stopPolling()
      }
    },[startPolling,stopPolling])

  const getDatos =()=>{
    setResultado(dataOutsources?.GET_ENTERPRISE_OUTSOURCER)
  }

  return (
    <Box className={styles.misOutSourcersGeneralDiv}>
      {/*}
      <Modal active={active} toggle={toggle}>
        <ModalAddOutsourcers toggle={toggle} />
      </Modal>
      {*/}
      <Box className={styles.titleButonBox}>
        <Box className={styles.title}>Mis Outsourcers</Box>
      </Box>
      {result?.length >= 1 ? (
        <Box className={styles.cajaTabla}>
          <TableOutSources />
        </Box>
      ) : (
        <Box className={styles.boxEmptyNote}>
          No tienes outsourcers para tu compañía
          <InfoIcon sx={{ width: 13, margin: 1 }} />
        </Box>
      )}
      {/*<ModalAddOutsourcers2/>
      <ModalEditOutsourcers2/>*/}
      <DeleteOutsources/>
    </Box>
  );
};

export default MisOutSourcers;
