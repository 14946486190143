import {  useEffect, useState } from "react";
import { useQuery} from "@apollo/client";
import { 
    GET_ALL_PRESTACIONES
  } from "../../Querys/prestaciones/querys";
import Box from '@mui/material/Box';
import {
  Grid
} from '@material-ui/core';
import TablePrestacion from "./TablePrestacion";
import { Benefit } from '../../interfaces/Benefit.interface';
import { PrestacionInfoCard } from "./PrestacionInfoCard";
import SearcherTablePrestacion from "./SearcherTablePrestacion";
import FiltrarIcon from "../../assets/svg/Filtrar.svg";

//import CheckPermission from "../../helpers/Administradores/Permissions";
const BodyPrestacion = ({viewSquare, viewList,infoImg }:any) => { 
  const [prestacionFilter, setPrestacionFilter] = useState<Benefit[]>([]);
  const [prestaciones, setPrestaciones] = useState<Benefit[]>([]);
  const [prestacion, setPrestacion] = useState<Benefit[]>([]);
  
  const {data: prestacionesInfo,loading} = useQuery(GET_ALL_PRESTACIONES);

  useEffect(() => {
       if(prestacionesInfo && prestacionesInfo.GET_ALL_PRESTACIONES){
        if(Object.keys(infoImg).length>0){
          const img ={...infoImg}
          const prestaciones = [...prestacionesInfo.GET_ALL_PRESTACIONES]
          const arrayPrestacion:any=[];
          // eslint-disable-next-line array-callback-return
          prestaciones.map((prestacion)=>{
          if(prestacion.id === img.idPrestacion ){
             const res= {...prestacion, URL:img.URL}
             arrayPrestacion.push(res)
          }
          else{
            arrayPrestacion.push(prestacion)
          }         
         }) 
           setPrestaciones(arrayPrestacion); 
           setPrestacionFilter(arrayPrestacion);              
           setPrestacion(arrayPrestacion);                       
        } 
         else{  
             setPrestaciones(prestacionesInfo.GET_ALL_PRESTACIONES); 
             setPrestacionFilter(prestacionesInfo.GET_ALL_PRESTACIONES);              
             setPrestacion(prestacionesInfo.GET_ALL_PRESTACIONES);            
          }
        }
    }, [prestacionesInfo,infoImg]);

  useEffect(() => {     
      if(prestacionFilter.length>0){
        const ordreFilter=[...prestacionFilter]
              ordreFilter.sort((a:any, b:any)=>{ return (a.id- b.id)})
              setPrestaciones(ordreFilter);                       
      }      
  }, [prestacionFilter]);
 
   const filtrar=()=>{
        const prestacion=[...prestaciones]
        const nuevoFiltrado = prestacion.sort((x:any,y:any)=> x.Titulo.localeCompare(y.Titulo));
        setPrestaciones(nuevoFiltrado)     

    }
    return (
      <>
      {(loading === true) ?
        <div className="c_contenedorPrincipal">
          <div className="contenedorLoadingPrincipal">
            <div className="loadingPrincipal"></div>
          </div>
        </div>
        :
        <Box p={2} >
          <div className="containerBtnFindFilter">    
            <div>
            <SearcherTablePrestacion
                         initState={prestaciones}
                         setState={setPrestacionFilter}   
                         stateInitial={prestacion}                      
                         label={"Buscar por prestación"}
                         fields={['titulo']}
                         width={400}
                    />
              </div>            
                <div className="">
                  <button
                    id="addPrestacion"
                    onClick={() => filtrar()}     
                    className="btnFiltrar"
                  >
                   <div className="alineacionBoton">
                      <img src={FiltrarIcon} alt="filtrar" />
                  <div className="nombrePrestacion">
                    <b>
                      <span>Filtrar</span>
                    </b>
                  </div>
                      </div>
                 </button>
               </div>            
            </div>
            {viewSquare &&  prestaciones?.length >= 1 ?
             (            
                  <Grid className="infoPrestacion" >   
                  <Grid className="exCont">
                  { prestaciones?.map((prestacion:any) => {
                      return (                  
                        <PrestacionInfoCard
                            key={prestacion.id}
                            prestacion={prestacion}
                          
                          /> 
                    
                        )
                    })                
                  }
                  </Grid>
                  </Grid>                          
                  )
                  :
                  viewList   ?
                  (
                    <Grid> 
                      {
                        <TablePrestacion 
                        prestaciones={prestaciones}
                        />          
                      }           
                    </Grid > )   
                    :(
                      <Grid   container justify="flex-start" > 
                      <span>Aún no tienes prestaciones registradas </span> 
                      </Grid>
                      )    
            }           
        </Box>  
      }    
      </>     
    )
}

export default BodyPrestacion;
