import { useContext } from "react";
import CampaignContext from "../../../context/CampaignsContext/CampaignContext"
import {createCampaignsModal} from "../../../context/CampaignsContext/Actions"
import AddIcon from "../../../assets/svg/icono_agregar_blue.svg";

const BtnCreate = () => {
  const {dispatch} = useContext(CampaignContext);

  return (
    <button
                            id="addPrestacion"
                            onClick={() => createCampaignsModal("0",true, dispatch)}  
                            className="btnNuevaPrestacion"
                        >
                        <div className="alineacionBoton">
                        <img src={AddIcon} alt="Agregar Prestación" />
                        <div className="nombrePrestacion">
                         <b><span>Nueva Campaña</span> </b>
                        </div>
                         </div>
    </button> 
  )
}

export default BtnCreate
