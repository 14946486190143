import { Grid, Box, Select, FormControl, TextField, MenuItem, SelectChangeEvent } from "@mui/material";
import { GET_ALL_ENTERPRISE, GET_ALL_COMPANY } from "../../Querys/querys";
import { useState } from 'react';
import { useQuery } from "@apollo/client";
import "./PaymentsCentral.css";
import { IEnterprise } from '../../interfaces/Enterprise';
import PaymentsCentralForm from "./PaymentsCentralForm";
import PaymentsCentralTable from "./PaymentsCentralTable";

function PaymentsCentralTransfers () {

  const {data: enterpriseData} = useQuery(GET_ALL_ENTERPRISE);
  const enterprises = enterpriseData?.GET_ALL_ENTERPRISE;
  const { data: resultCompany } = useQuery(GET_ALL_COMPANY);
  const company = resultCompany?.GET_ALL_COMPANY;
  const [selectedEnterprise, setSelectedEnterprise] = useState('');

  const onSelectEnterprise = ( e: SelectChangeEvent ) => {
    setSelectedEnterprise(e.target.value)
  }

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Box className="header-container">
            <p className="header-title">Lista de empresas</p>
            <FormControl fullWidth={true}>
              <Select name="company" onChange={onSelectEnterprise} value={ selectedEnterprise }>
                <MenuItem></MenuItem>
                {
                  enterprises?.map((enterprise:IEnterprise,key:number)=>(
                    <MenuItem value={enterprise.id} key={enterprise.id}>{ enterprise.name }</MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box className="header-container">
            <p className="header-title">Saldo STP:</p>
          </Box>
        </Grid>
        <Grid item xs={12} md={12}>
          {
            selectedEnterprise != '' && (
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <PaymentsCentralForm company={company[0]} enterprise={selectedEnterprise}></PaymentsCentralForm>
                </Grid>
                <Grid item xs={12}>
                  <PaymentsCentralTable></PaymentsCentralTable>
                </Grid>
              </Grid>
            )
          }
        </Grid>
      </Grid>
    </div>
  )
}

export default PaymentsCentralTransfers;