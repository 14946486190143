import { useContext } from "react";
import EmpresaContext from "../../../context/Empresa/EmpresaContext";
import { Dialog, DialogActions, DialogContent, Grid } from "@material-ui/core";
import Box from '@mui/material/Box';
import { clearEmpresaModal } from "../../../context/Empresa/Actions";
import styles from "../../../styles/MiEmpresa/CrearProyecto2.module.css";
import { CREATE_PROJECT, GET_ALL_PROJECT, GET_ALL_PUESTOS_USERS_PROJECT } from "../../../Querys/querys";
import { useMutation } from "@apollo/client";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { SuccessfulAlert } from "../../../alerts/successAlerts";
import {
  Button,
  TextField,
} from "@material-ui/core";

const CrearProyecto2 = () => {
  const { state, dispatch } = useContext(EmpresaContext);

  const [createNewProyect] = useMutation(CREATE_PROJECT, {
    refetchQueries: [
                      { query: GET_ALL_PROJECT },
                      { query: GET_ALL_PUESTOS_USERS_PROJECT}
                    ],
  });

  const initalValues = () => {
    return {
      nombre: "",
      cliente: "",
    };
  };

  const handleClose = () => {
    clearEmpresaModal({}, dispatch);
  };

  return (
    <Dialog
      open={state.createProject}
      aria-labelledby="form-dialog-title"
      onClose={handleClose}
      maxWidth={"md"}
    >
      <Formik
        initialValues={initalValues()}
        validationSchema={Yup.object(validationSchema())}
        onSubmit={(formData) => {
          createNewProyect({
            variables: {
              input: {
                proyectName: formData.nombre,
                client: formData.cliente,
              },
            },
          }).then(() => {
            handleClose();
            SuccessfulAlert({
              title: "¡Exito!",
              text: "¡Registro agregado!",
            });
          });
        }}
      >
        {({ values, errors, touched, handleChange, handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <h3 id="form-dialog-title" className="text-center">
              Agregar &aacute;rea o proyecto
            </h3>
            <DialogContent dividers>
              <Box display="flex" flexDirection="column">
                <Box mb={3}>
                  <Grid container spacing={1}>
                    <Grid container item xs={12} spacing={3}>
                      <Grid item xs={12} style={{"marginBottom":"10px"}}>
                        <TextField
                          fullWidth
                          className={styles.input}
                          name="nombre"
                          label="Nombre"
                          variant="outlined"
                          value={values.nombre}
                          onChange={handleChange}
                          error={touched.nombre && Boolean(errors.nombre)}
                          helperText={touched.nombre && errors.nombre}
                          size="small"
                          inputProps={{ maxLength: 50 }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          className={styles.input}
                          name="cliente"
                          label="Sub &Aacute;rea/Sub Proyecto"
                          variant="outlined"
                          value={values.cliente}
                          onChange={handleChange}
                          error={touched.cliente && Boolean(errors.cliente)}
                          helperText={touched.cliente && errors.cliente}
                          size="small"
                          inputProps={{ maxLength: 50 }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </DialogContent>
            <DialogActions style={{ justifyContent: "center" }}>
              <Button autoFocus onClick={handleClose} className="buttonCancel">
                Cerrar
              </Button>
              <Button autoFocus type="submit" className="buttonSave">
                Guardar
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

const validationSchema = () => {
  return {
    nombre: Yup.string().required("El nombre es requerido"),
  };
};

export default CrearProyecto2;
