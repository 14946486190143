import { useEffect, useState } from "react";
import TabsUnstyled from "@mui/base/TabsUnstyled";
import TabsListUnstyled from "@mui/base/TabsListUnstyled";
import TabPanelUnstyled from "@mui/base/TabPanelUnstyled";
import TabUnstyled from "@mui/base/TabUnstyled";
import '../ConfigPayroll/Concepts.css'
import HistoricPayrollTable from "./HistoricPayrollTable";
import PayrollState from "../../context/PayrollContext/PayrollState";
import PayrollProcessState from "../../context/PayrollProcess/PayrollProcessState";
import {  GET_PAYROLLYEAR, GET_PAYROLLHISTORIC } from "../../Querys/querys";
import { useLazyQuery, useQuery } from "@apollo/client";
interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
};

const ConceptTab = (props: TabPanelProps) => {
  const [activo, setActivo] = useState('2023')
  let indexPayroll = -1
  const[historic, setHistoric] = useState<any>([])
  
  const resulYearPayroll= useQuery(GET_PAYROLLYEAR);
  const yearPayroll = resulYearPayroll.data?.GET_PAYROLLYEAR;
  const [year, setYear] = useState(yearPayroll);
  const [allHistoric, setAllHistoric] = useState<any>([]);
  const [loader, setLoader] = useState(false);

  const [getHistoric] = useLazyQuery(
    GET_PAYROLLHISTORIC,
    {
      variables: { init_date: activo },
      fetchPolicy: "no-cache"
    }
  );

  const getHistoricalPayroll = async () => {
    try {
      setLoader(true)
      const historical = await getHistoric({
        variables: { init_date: activo },
      });
      setAllHistoric(historical?.data?.GET_PAYROLLHISTORIC);
      setHistoric(historical?.data?.GET_PAYROLLHISTORIC);
      setLoader(false)
    } catch (error) {
      console.error(error)
      setLoader(false)
    }
  }

  useEffect(() => {
    getHistoricalPayroll()
  }, [activo])

  useEffect(()=>{
    if(yearPayroll){
      setYear(yearPayroll)
    }
  },[yearPayroll])

  const datosFiltrados = (e: any) =>{
    
    if(e !== '') {
        let expresion = new RegExp(`${e}.*`, "i")

        const nuevoFiltradoHistoric: any[] = []
        let filtetHistoricNotRepeated: any[]=[]
        let foundHistoric: any ={}

        allHistoric.forEach((lis:any) =>{
          lis.Colaborators.forEach((col:any) =>{
            if(expresion.test(col.colaborator) || expresion.test(col.email)){
              nuevoFiltradoHistoric.push(lis) 
            }
          })
        })

        filtetHistoricNotRepeated = nuevoFiltradoHistoric.filter( (element: any) =>{
          var exists = !foundHistoric[element.id];
          foundHistoric[element.id] = true;
          return exists;
        })

        setHistoric(filtetHistoricNotRepeated)
    }else{
      setHistoric(allHistoric)
    }
    
  }

  const showPayrollCall = (y: any, allHistoric_: any[])=>{
    indexPayroll = indexPayroll + 1
    return <TabPanelUnstyled value={indexPayroll}><HistoricPayrollTable {...props} year={y?.year} filtro={''} allHistoric ={allHistoric_} /></TabPanelUnstyled>
  }
  

  return (
    <>
      <TabsUnstyled defaultValue={0} className="mainConceptsHeader">
        <div className="conceptsHeaders2">
          <div className="concepTitle">
            <p>
              Períodos
            </p>
          </div>
          <div className="concepConteinerYears" >
            <div className="concepYear">
              <TabsListUnstyled>
                {
                  year?.map((y: any) =>(
                    <TabUnstyled
                      id="botonHeader"
                      className={`configBotonHeader2 ${ activo === y?.year ? "activo" : "desactivo"}`}
                      onClick={(e)=> setActivo(y?.year)}>
                        <b
                        style={{
                          cursor: "pointer"
                        }}
                        >
                          {y?.year}
                        </b>
                      </TabUnstyled>
                  ))
                }
                
              </TabsListUnstyled>
            </div>
              
          </div>
            
          <div className="containderImputs-playroll">
            <div className="containderImputsSearcher-playroll">
                <input 
                className="inputSearcher-playroll"
                placeholder='Buscar al colaborador por nombre o email'
                onChange={(e: any) => datosFiltrados(e.target.value)}
                />
                <div className="buttonSearch">
                  <p>Buscar</p>
                </div>
            </div>
          </div>
        </div>
        {
          loader && (
            <div className="loading_historical_container">
                <div className="loading_historical" />
            </div>
          )
        }
        <PayrollProcessState>
          <PayrollState>
            {
              !loader && year?.map((y: any) =>(
                showPayrollCall(y, historic)
              ))
            }
          </PayrollState>
        </PayrollProcessState>
      </TabsUnstyled>
    </>
  )
} 

export default ConceptTab;
