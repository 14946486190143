import ContractsState from '../context/ContractsContext/ContractsState';
import {ShowContracts} from '../components/Contracts/showContracts'

const ContractsScreen = () => {
    return (
    <div>
        <ContractsState>
            <ShowContracts/>
        </ContractsState>
    </div>
        

    )
};

export default ContractsScreen;