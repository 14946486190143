
import { Grid, Menu, MenuItem } from '@material-ui/core'
import "../styles.css"
import { useContext, useState } from "react";
import {
    createEditEventModal,
    deleteEditEventModal
} from '../../../context/ScheduleContext/Actions'
import CalendarsContext from "../../../context/NewCalendarContext/CalendarsContext";
import moment from 'moment';
import CheckPermission from '../../../helpers/Administradores/Permissions';

interface TabMeasures {
    children?: React.ReactNode;
    initDate: any;
    endDate: any;
    name: String;
    id: any;
    calendar: any;
};

function EventListModal(props: TabMeasures){

    const { initDate, endDate, name, id, calendar } = props;
    const [anchorEl, setAnchorEl] = useState<any>(null);
    const open = Boolean(anchorEl);
    const {dispatch} = useContext(CalendarsContext)
    
    
    const createModal = (id:string, createModal:boolean) => {
        setAnchorEl(null);
        createEditEventModal(calendar, id, createModal, initDate, endDate, dispatch);
    }
    
    const deleteModal = (id:string, createModal:boolean) =>{
        setAnchorEl(null);
        deleteEditEventModal(calendar, id, createModal, dispatch)
    }

    return <>
        <>
            <div className="collaboratorDayIncident" 
            style={{
                cursor: 'pointer'
            }}
            onClick={(e) => {
                setAnchorEl(e.currentTarget)
            }}
            ><b>Inicio: {moment(initDate).format('DD')}</b> <b>Fin: {moment(endDate).format('DD')}</b> {name}</div>
        </>
        <Menu
            className="MoreVerIcon"
            anchorEl={anchorEl}
            open={open}
            transformOrigin={{ vertical: -40, horizontal: "left" }}
            onClose={() => setAnchorEl(null)}
        >
            {
                CheckPermission("Calendario.Ver.Editar") && (
                    <MenuItem divider button 
                    onClick={() => createModal(id, true)}
                    >
                        Editar&nbsp;
                        <Grid container item justify="flex-end">
                            <img src={`/assets/svg/icono-editar.svg`} alt="Editar" />
                        </Grid>
                    </MenuItem>
                )
            }
            {
                CheckPermission('Calendario.Ver.Eliminar') && (
                    <MenuItem divider button 
                    onClick={() => deleteModal(id, true)}
                    >
                        Eliminar&nbsp;
                        <Grid container item justify="flex-end" >
                            <img src={`/assets/svg/icono-eliminar.svg`} alt="Eliminar" />
                        </Grid>
                    </MenuItem>
                )
            }
        </Menu >
        
    </>    

}

export default EventListModal