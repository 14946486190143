import { useContext, useState } from "react";
import { Grid, IconButton, Menu, MenuItem, Tooltip } from "@material-ui/core";
import Box from '@mui/material/Box';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import EmpresaContext from "../../../../../context/Empresa/EmpresaContext";
import { openUpdateEmpresaModal, opendDeleteEnterpriseModal} from "../../../../../context/Empresa/Actions";


const MenuListEmpresa = (props:any) => {
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const open = Boolean(anchorEl);
  const { dispatch } = useContext(EmpresaContext); 

  const abreEditaEmpresa = ()=>{
    openUpdateEmpresaModal({updateEmpresa: true, _id:props.id}, dispatch)
  }

  const abreElimina = ()=>{
    opendDeleteEnterpriseModal({deleteEmpresa: true, _id:props.id}, dispatch)
  }

  const menuDelete = ()=>{
    if (props.Users === 0){
      return <MenuItem divider button onClick={() =>abreElimina()}>
        Eliminar&nbsp;
        <Grid container item justify="flex-end">
          <img src={`/assets/svg/icono-eliminar.svg`} alt="Reenviar" />
        </Grid>
      </MenuItem>
    }else{
      return <MenuItem divider button 
      style={{
        opacity: "0.5",
        cursor: "context-menu"
      }}
      >
        Eliminar&nbsp;
        <Grid container item justify="flex-end">
          <img src={`/assets/svg/icono-eliminar.svg`} alt="Reenviar" />
        </Grid>
      </MenuItem>
    }
    
  }

  return( 
    <>
      <Tooltip title="Editar" placement="right">
        <Box className="IconButtonPoints" mr={2}>
          <IconButton onClick={(e) => setAnchorEl(e.currentTarget)}>
            <MoreVertIcon style={{ color: "#fabb00" }} />
          </IconButton>
        </Box>
      </Tooltip>
      <Menu
        className="MoreVerIcon"
        anchorEl={anchorEl}
        open={open}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem divider button onClick={() => abreEditaEmpresa()}>
          Editar&nbsp;
          <Grid container item justify="flex-end">
            <img src={`/assets/svg/icono-editar.svg`} alt="Reenviar" />
          </Grid>
        </MenuItem>

        {menuDelete()}
      </Menu >
    </>
  )
}

export default MenuListEmpresa