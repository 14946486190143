import { useContext, useEffect, useState } from "react";
import { Grid, Button } from "@material-ui/core";
import CollaboratorContext from "../../../../context/CollaboratorContext/CollaboratorContext";
import EyeIcon from "../../../../assets/svg/eye.svg";
import DownloadIcon from "../../../../assets/svg/download_files_arrow.svg";
import { WarningAlert } from "../../../../alerts/WarningAlert";
import { server } from "../../../../global/server";
import axios from "axios";

const OfertaLaboralInactivos = () => {
  const { state } = useContext(CollaboratorContext);
  const [archives, setArchives] = useState([]);
  const [CartaOferta, setCartaOferta] = useState<any>({});

  useEffect(() => {
    if (state.collaborator?.archive) {
      //get archive where name is CartaOferta
      const carta = state.collaborator.archive.find(
        (archive: any) => archive.name === "DocumentosParaFirma"
      );
      if (carta) {
        setCartaOferta(carta);
      }
      setArchives(state.collaborator.archive);
    }
  }, [state.collaborator]);

  const handleWarningAlert = async () => {
    await WarningAlert({ text: "Aún no se ha subido el documento para firma." });
  };

  const exportarPDF = async (id: any) => {
    
    const PDF: any = await axios.get(`${server}/report/pdfCollaborators/${id}`)
    const link = document.createElement("a");
    link.href = PDF?.data?.urlPDF;
    link.setAttribute("download", "downloadtesting.PDF");
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  return (
    <>
      <div className="novalidate__border" style={{ width: "fit-content" }}>
        <Grid direction="row" container>
          <Grid xs={12} item>
            {/*AVISO DE RETENCIÓN  */}
            {archives?.map(
              (archive: any, index: number) =>
                archive?.name === "CartaOfertaFirmada" && (
                  <div className="flex-container">
                    <div className="flex-child-2">
                      {/* button with image like text */}

                      <a
                        className="view-document"
                        target="_blank"
                        rel="noreferrer"
                        href={CartaOferta?.URL}
                      >
                        <img
                          src={DownloadIcon}
                          style={{ marginRight: "15px" }}
                          className="cursor"
                          alt="DownloadFile"
                        />
                      </a>
                    </div>
                    <div className="flex-child">
                      <label>Documento para firma firmado</label>
                      <span className="span-file">(PDF)</span>
                      <span className="span-file">
                        <a
                          className="view-document"
                          target="_blank"
                          rel="noreferrer"
                          href={archive.URL}
                        >
                          <img
                            style={{ height: "10px", marginTop: "5px" }}
                            src={EyeIcon}
                            alt=""
                          />
                          Ver documento
                        </a>
                      </span>
                    </div>
                  </div>
                )
            )}

            {!state.collaborator?.CartaOfertaFirmada && (
              <div className="flex-container">
                <div className="flex-child-2">
                  {state.collaborator.CartaOferta ? (
                    <a
                      className="view-document"
                      target="_blank"
                      rel="noreferrer"
                      href={CartaOferta?.URL}
                    >
                      <img
                        src={DownloadIcon}
                        style={{ marginRight: "15px" }}
                        className="cursor"
                        alt="DownloadFile"
                      />
                    </a>
                  ) : (
                    <Button className="view-document" onClick={handleWarningAlert}>
                      <img
                        src={DownloadIcon}
                        style={{ marginRight: "15px" }}
                        className="cursor"
                        alt="DownloadFile"
                      />
                    </Button>
                  )}
                </div>
                <div className="flex-child">
                  <label>Documento para firma firmado</label>
                  <span className="span-file">(PDF)</span>
                </div>
              </div>
            )}
          </Grid>
        </Grid>
        <hr />
        <Grid direction="row" container>
          <Grid xs={12} item>
            {/*DECLARACION DE INFORMACION  */}
            {archives?.map(
              (archive: any, index: number) =>
                archive?.name === "AGREEMENT_PDF" && (
                  <div className="flex-container">
                    <div className="flex-child-2">
                      <a
                        className="view-document"
                        target="_blank"
                        rel="noreferrer"
                        onClick={() => {
                          exportarPDF(state.collaborator.id);
                        }}
                      >
                        <img
                          src={DownloadIcon}
                          style={{ marginRight: "15px" }}
                          className="cursor"
                          alt="DownloadFile"
                        />
                      </a>
                    </div>
                    <div className="flex-child">
                      <label>Declaración de Información</label>
                      <span className="span-file">(PDF)</span>
                      <span className="span-file">
                        <a
                          className="view-document"
                          target="_blank"
                          rel="noreferrer"
                          href={archive.URL}
                        >
                          <img
                            style={{ height: "10px", marginTop: "5px" }}
                            src={EyeIcon}
                            alt=""
                          />
                          Ver documento
                        </a>
                      </span>
                    </div>
                  </div>
                )
            )}

            {!state.collaborator?.AGREEMENT_PDF && (
              <div className="flex-container">
                <div className="flex-child-2">
                  <a
                    className="view-document"
                    target="_blank"
                    rel="noreferrer"
                    onClick={() => {
                      exportarPDF(state.collaborator.id);
                    }}
                  >
                    <img
                      src={DownloadIcon}
                      style={{ marginRight: "15px" }}
                      className="cursor"
                      alt="DownloadFile"
                    />
                  </a>
                </div>
                <div className="flex-child">
                  <label>Declaración de Información</label>
                  <span className="span-file">(PDF)</span>
                </div>
              </div>
            )}
          </Grid>
        </Grid>
      </div>
      <div></div>
    </>
  );
};

export default OfertaLaboralInactivos;
