/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useContext } from "react";
import { cloneDeep } from "lodash";
import EliminaPayroll from "./Modals/EliminaPayroll";
import CrearPayrollsModal from "./Modals/CrearPayrollsModal";
import {
  createPrenominaModal,
  deletePayrollProcess,
  openOnboarding
} from "../../context/PayrollProcess/Actions";
import PayrollProcessContext, { Types } from "../../context/PayrollProcess/PayrollProcessContext";
import { useHistory } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import { GET_ALL_PAYROLL, UPDATE_PAYROLL_STATE, GET_ALL_PAYROLLREPORT } from "../../Querys/querys";
import styles from "./PayrollStyles.module.css";
import { getDateDay } from "../../helpers/Payroll/Payroll";
import { formatter } from "../../helpers/formatoMoneda";
import { useTour } from "@reactour/tour";
import Fab from "@mui/material/Fab";
import CheckPermission from '../../helpers/Administradores/Permissions';
import { CreateExcelFilePayrollReport } from "../../components/Payroll/Modals/ExcelPayroll";
interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
  sistemaActivo: boolean;
}

const ActivePayrollTable = (props: TabPanelProps) => {
  const { sistemaActivo } = props;
  const { setCurrentStep, setIsOpen } = useTour();
  const { data: resultPayroll, startPolling, stopPolling } = useQuery(GET_ALL_PAYROLL);
  const allPayroll = resultPayroll?.GET_ALL_PAYROLL;
  const { data: resultPayrollReport, startPolling: startPollingReport, stopPolling: stopPollingReport } = useQuery(GET_ALL_PAYROLLREPORT);
  const allPayrollReport = resultPayrollReport?.GET_ALL_PAYROLLREPORT;
  const history = useHistory();
  const { state, dispatch } = useContext(PayrollProcessContext);
  const [displayedPlayroll, setDisplayedPayroll] = useState<any[]>([])

  useEffect(() => {
    const allPayrollData = allPayroll.map((lis: any) => ({ ...lis, inProgress: false, error: null }))
    if (state?.inProgressPayroll?.length === 0) {
      setDisplayedPayroll(allPayrollData)
      return
    }

    const allPayrollCopy = cloneDeep(allPayrollData)
    const inProgressPayrollTemporal = cloneDeep(state?.inProgressPayroll)
    const hideDeletedElements = inProgressPayrollTemporal.filter((el) => !el.deleted)
    const inProgressPayrollCopy = hideDeletedElements?.map((el: any) => {
      const idx = allPayrollCopy.findIndex((pr: any) => pr.uuid === el.uuid)
      if (idx < 0) return el
      const newEl = allPayrollCopy.splice(idx, 1);
      return { ...newEl[0], inProgress: el.inProgress, error: el.error };
    })

    setDisplayedPayroll([...inProgressPayrollCopy, ...allPayrollCopy])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.inProgressPayroll, allPayroll])

  useEffect(() => {
    startPolling(2000);
    startPollingReport(2000);
    return () => {
      stopPolling()
      stopPollingReport()
    }
  }, [allPayroll, allPayrollReport, startPolling, stopPolling, startPollingReport, stopPollingReport]);

  useEffect(() => {
    dispatch({
      type: Types.UPDATE_IN_PROGRESS_PAYROLL,
      payload: []
    })
  }, [])

  const openCreatePrenomina = () => {
    createPrenominaModal({ id_: "", createPrenomina: true }, dispatch);
    setTimeout(() => {
      if (state?.isOnboarding) {
        setCurrentStep(1);
        setIsOpen(true);
      }
    }, 300);
  };

  const eliminaPayroll = (payroll: any) => {
    deletePayrollProcess({ _id: payroll.id, deleteModal: true }, dispatch);
  };

  const [updatePayroll] = useMutation(UPDATE_PAYROLL_STATE);

  const updateState = (id: any) => {
    updatePayroll({
      variables: {
        input: {
          id: id,
          statusProgress: "Calcular",
        },
      },
    });
    if (state?.isOnboarding) {
      setCurrentStep(5);
      setTimeout(() => {
        setIsOpen(true);
      }, 2500);
    }
  };

  const openOnboard = () => {
    openOnboarding(true, dispatch);
    setCurrentStep(0);
    setIsOpen(true);
  };

  const handleOnExport = () => {
    CreateExcelFilePayrollReport(allPayrollReport);
  };

  const deletePayroll = (payroll: any) => {
    return <>
      {payroll.payrollCompleted === 0 || isActionsDisabled(payroll) ?
        <div
          className={isActionsDisabled(payroll)
            ? styles.pp_contenedorIconoEliminarDisabled
            : styles.pp_contenedorIconoEliminar}
          onClick={() => {
            if (isActionsDisabled(payroll)) return;
            eliminaPayroll(payroll)
          }}
        >
          <img
            className={styles.pp_iconoEliminar}
            src={isActionsDisabled(payroll)
              ? "/assets/svg/icono_eliminar-nomina-disabled.svg"
              : "/assets/svg/icono_eliminar-nomina.svg"}
            alt=""
          ></img>
          <span className={styles.pp_textoEliminar}>Eliminar</span>
        </div>
        : <div></div>
      }
    </>
  }

  const isActionsDisabled = (payroll: any) => {
    return (payroll.inProgress === true) || (payroll.inProgress === false && payroll.error)
  }

  const isFailedPayroll = (payroll: any) => {
    return payroll.inProgress === false && payroll.error
  }

  const deleteFailedPayrollFromList = (payroll: any) => {
    const inProgressPayroll = state?.inProgressPayroll.filter(lis => lis.uuid !== payroll.uuid)
    dispatch({
      type: Types.UPDATE_IN_PROGRESS_PAYROLL,
      payload: inProgressPayroll
    })
  }

  const deleteFailedPayroll = (payroll: any) => {
    return <div
      className={styles.pp_contenedorIconoEliminar}
      onClick={() => deleteFailedPayrollFromList(payroll)}
    >
      <img
        className={styles.pp_iconoEliminar}
        src="/assets/svg/icono_eliminar-nomina.svg"
        alt=""
      ></img>
      <span className={styles.pp_textoEliminar}>Descartar</span>
    </div>
  }

  return (
    <>
      <div className={styles.pp_contenedorReporte}>
        <div className={styles.pp_contenedorBotonReporte}>
          {(CheckPermission("Nominas.PreNomina.Crear")) && (
            <button
              id="payroll_1"
              className={styles.pp_botonCrear}
              onClick={() => handleOnExport()}>
              <div>Descargar Reporte</div>
            </button>
          )}
        </div>
      </div>
      <div className={styles.pp_contenedor}>
        <div className={styles.pp_contenedorBoton}>
          {(CheckPermission("Nominas.PreNomina.Crear") && sistemaActivo) && (
            <button
              id="payroll_1"
              className={styles.pp_botonCrear}
              onClick={() => openCreatePrenomina()}>
              <div>
                <span className={styles.pp_signo}>+</span>
              </div>
              <div>Nueva pre-nómina</div>
            </button>
          )}
        </div>
      </div>
      <div className={styles.pp_contenedorLista}>
        {displayedPlayroll?.map(
          (lis: any, key: any) =>
            lis.status === "Activo" && (
              <div className={styles.pp_lista} key={lis.id}>
                <div className={styles.pp_ColumnaUnoLista}>
                  <div className={styles.pp_tituloGrupo}>
                    <span>{lis.group_name}</span>
                  </div>
                  <div className={styles.pp_tituloGrupo}>
                    <span>
                      {lis.frecuency_payment} {getDateDay(lis.init_date)}{" "}
                      {getDateDay(lis.end_date)}
                    </span>
                  </div>
                  <div className={styles.pp_tituloGrupo}>
                    <span>
                      {lis.payroll_type}
                    </span>
                  </div>
                  <div className={styles.pp_tituloGrupo}>
                    <span>
                      {lis.Company}
                    </span>
                  </div>
                  {lis.statusProgress === "Creación" ? (
                    <>
                      <div className={styles.pp_procesoCalcular}>
                        <span>Calcular</span>
                      </div>
                      <div
                        id={key === 0 ? "payroll_4" : ""}
                        className={styles.pp_contenedorBarras}
                      >
                        <div
                          className={styles.pc_circuloGris}
                        ></div>
                        <div
                          className={styles.pc_lineaSeguimiento}
                        ></div>
                        <div
                          className={styles.pc_circuloGris}
                        ></div>
                        <div
                          className={styles.pc_lineaSeguimiento}
                        ></div>
                        <div
                          className={styles.pc_circuloGris}
                        ></div>
                        <div className={styles.pc_lineaSeguimientoPrincipal}></div>
                        <div className={styles.pc_circuloGris}></div>
                      </div>
                    </>
                  ) : null}
                  {lis.statusProgress === "Calcular" ? (
                    <>
                      <div className={styles.pp_procesoCalcular}>
                        <span>{lis.statusProgress}</span>
                      </div>
                      <div
                        id={key === 0 ? "payroll_4" : ""}
                        className={styles.pp_contenedorBarras}
                      >
                        <div className={styles.pc_circuloAmarillo}>
                          <div
                            className={styles.pc_circuloBlanco}
                          ></div>
                        </div>
                        <div
                          className={styles.pc_lineaSeguimiento}
                        ></div>
                        <div
                          className={styles.pc_circuloGris}
                        ></div>
                        <div
                          className={styles.pc_lineaSeguimiento}
                        ></div>
                        <div
                          className={styles.pc_circuloGris}
                        ></div>
                        <div className={styles.pc_lineaSeguimientoPrincipal}></div>
                        <div className={styles.pc_circuloGris}></div>
                      </div>
                    </>
                  ) : null}
                  {lis.statusProgress === "Dispersar" ? (
                    <>
                      <div className={styles.pp_procesoCalcular}>
                        <span>{lis.statusProgress}</span>
                      </div>
                      <div
                        id={key === 0 ? "payroll_4" : ""}
                        className={styles.pp_contenedorBarras}
                      >
                        <div className={styles.pc_circuloAmarillo}>
                          <div
                            className={styles.pd_iconoCompleto}
                          ></div>
                        </div>
                        <div
                          className={
                            styles.pc_lineaSeguimientoCompleto
                          }
                        ></div>
                        <div className={styles.pc_circuloAmarillo}>
                          <div
                            className={styles.pc_circuloBlanco}
                          ></div>
                        </div>
                        <div
                          className={styles.pc_lineaSeguimiento}
                        ></div>
                        <div
                          className={styles.pc_circuloGris}
                        ></div>
                        <div className={styles.pc_lineaSeguimientoPrincipal}></div>
                        <div className={styles.pc_circuloGris}></div>
                      </div>
                    </>
                  ) : null}
                  {lis.statusProgress === "Timbrar" ? (
                    <>
                      <div className={styles.pp_procesoCalcular}>
                        <span>{lis.statusProgress}</span>
                      </div>
                      <div
                        id={key === 0 ? "payroll_4" : ""}
                        className={styles.pp_contenedorBarras}>
                        <div className={styles.pc_circuloAmarillo}>
                          <div
                            className={styles.pd_iconoCompleto}></div>
                        </div>
                        <div
                          className={
                            styles.pc_lineaSeguimientoCompleto
                          }
                        ></div>
                        <div className={styles.pc_circuloAmarillo}>
                          <div
                            className={styles.pd_iconoCompleto}
                          ></div>
                        </div>
                        <div
                          className={
                            styles.pc_lineaSeguimientoCompleto
                          }
                        ></div>
                        <div className={styles.pc_circuloAmarillo}>
                          <div
                            className={styles.pc_circuloBlanco}
                          ></div>
                        </div>
                        <div className={styles.pc_lineaSeguimientoPrincipal}></div>
                        <div className={styles.pc_circuloGris}></div>
                      </div>
                    </>
                  ) : null}
                  {lis.statusProgress === "Terminado" ? (
                    <>
                      <div className={styles.pp_procesoCalcular}>
                        <span>{lis.statusProgress}</span>
                      </div>
                      <div
                        id={key === 0 ? "payroll_4" : ""}
                        className={styles.pp_contenedorBarras}
                      >
                        <div className={styles.pc_circuloAmarillo}>
                          <div
                            className={styles.pd_iconoCompleto}
                          ></div>
                        </div>
                        <div
                          className={
                            styles.pc_lineaSeguimientoCompleto
                          }
                        ></div>
                        <div className={styles.pc_circuloAmarillo}>
                          <div
                            className={styles.pd_iconoCompleto}
                          ></div>
                        </div>
                        <div
                          className={
                            styles.pc_lineaSeguimientoCompleto
                          }
                        ></div>
                        <div className={styles.pc_circuloAmarillo}>
                          <div
                            className={styles.pd_iconoCompleto}
                          ></div>
                        </div>
                        <div className={styles.pc_lineaSeguimientoCompleto}></div>
                        <div className={styles.pc_circuloAmarillo}>
                          <div
                            className={styles.pc_circuloBlanco}
                          ></div>
                        </div>
                      </div>
                    </>
                  ) : null}
                </div>
                <div className={styles.pp_ColumnaDosLista}>
                  {lis.inProgress === true && (
                    <div className={styles.pp_inProgressPayroll}>
                      Creación de nueva nómina en proceso
                    </div>
                  )}
                  {lis.inProgress === false && lis.error && (
                    <div className={styles.pp_errorPayroll}>{lis.error}</div>
                  )}
                  {lis.inProgress === false && lis.error === null && lis.total !== null && (
                    <>
                      <div
                        className={`${styles.pp_columnaInterna} ${styles.pp_internaUno}`}
                      >
                        <div>
                          <span className={styles.pp_textoInterno}>Empleados</span>
                        </div>
                        <div>
                          <span className={styles.pp_valorInterno}>
                            {lis.employees}
                          </span>
                        </div>
                      </div>
                      <div
                        className={`${styles.pp_columnaInterna} ${styles.pp_internaUno}`}
                      >
                        <div>
                          <span className={styles.pp_textoInterno}>
                            Percepciones
                          </span>
                        </div>
                        <div>
                          <span className={styles.pp_valorInterno}>
                            {formatter(lis.perception)}
                          </span>
                        </div>
                      </div>
                      <div
                        className={`${styles.pp_columnaInterna} ${styles.pp_internaUno}`}
                      >
                        <div>
                          <span className={styles.pp_textoInterno}>
                            Deducciones
                          </span>
                        </div>
                        <div>
                          <span className={styles.pp_valorInterno}>
                            {formatter(lis.deduction)}
                          </span>
                        </div>
                      </div>
                      <div
                        className={`${styles.pp_columnaInterna} ${styles.pp_internaUno}`}
                      >
                        <div>
                          <span className={styles.pp_textoInterno}>Total</span>
                        </div>
                        <div>
                          <span className={styles.pp_valorInterno}>
                            {formatter(lis.total)}
                          </span>
                        </div>
                      </div>
                    </>
                  )}
                </div>
                <div className={styles.pp_ColumnaTresLista}>
                  {!isFailedPayroll(lis) && (
                    <div>
                      {CheckPermission("Nominas.PreNomina.Ver") && (
                        <button
                          id="payroll_5"
                          className={isActionsDisabled(lis) ? styles.pp_botonComenzar_disabled : styles.pp_botonComenzar}
                          disabled={isActionsDisabled(lis)}
                          onClick={() => {
                            if (isActionsDisabled(lis)) return
                            updateState(lis.id);
                            history.push(
                              `/InitPayroll/${lis.id}/${lis.group_name}`
                            );
                          }}
                        >
                          Continuar
                        </button>)}
                    </div>
                  )}
                  {isFailedPayroll(lis) && (
                    deleteFailedPayroll(lis)
                  )}
                  {(CheckPermission("Nominas.PreNomina.Eliminar") && sistemaActivo) && !isFailedPayroll(lis) && (
                    deletePayroll(lis)
                  )}
                </div>
              </div>
            )
        )}
      </div>
      <Fab
        className="question_board"
        onClick={openOnboard}
        size="small"
        color="primary"
        aria-label="add"
      >
        <img src="/assets/icons/Vectorr.svg" alt="Onboarding" />
      </Fab>
      <CrearPayrollsModal />
      <EliminaPayroll />
    </>
  );
};

export default ActivePayrollTable;
