const Excel = require("exceljs");

export const CreateExcelReportePolizaA = (data: any) => {
  const workbook = new Excel.Workbook();
  const ReporteNominaP= workbook.addWorksheet("Reporte Nomina");

  const width = 40;

  const header = {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: "002060" },
  };
  const headerFont = {
    name: "Arial",
    color: { argb: "FFFFFF" },
    family: 2,
    size: 11,
    bold: true,
  };

  ReporteNominaP.columns = [
    { header: "CONCEPTO", key: "concep", width },
    { header: "CUENTA CONTABLE", key: "cuentaCon", width },
    { header: "MONTO", key: "mont", width },
  ];

  let letter = 65;
  ReporteNominaP.columns.forEach(() => {
    ReporteNominaP.getCell(`${String.fromCharCode(letter)}1`).fill = header;
    ReporteNominaP.getCell(`${String.fromCharCode(letter)}1`).font = headerFont;
    ReporteNominaP.getCell(`${String.fromCharCode(letter)}1`).alignment = {
      horizontal: "center",
    };
    letter++;
  });


  data.forEach((item: any) => {

    ReporteNominaP.addRow({
      concep: item.Concepto,
      cuentaCon: item.CuentaContable,
      mont: item.Monto,
    });
  });

  workbook.xlsx.writeBuffer().then(function (data: Blob) {
    const blob = new Blob([data],
      { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const url = window.URL.createObjectURL(blob);
    const anchor = document.createElement('a');
    anchor.href = url;
    anchor.download = 'ReporteNominaPoliza.xlsx';
    anchor.click();
    window.URL.revokeObjectURL(url);
  });
};