import { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import Box from '@mui/material/Box';
import './MyMood.css';
import Body from "./Body";
import BodyStadisticts from "./BodyStadisticts";
import { useLocation, useHistory } from 'react-router-dom'

const MyMood = (props: any) => {
    const [open, setOpen] = useState<boolean>(false);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const location = useLocation();
    const navigate = useHistory();

    const onHandleChange = () => {
        if(location.pathname.toLocaleLowerCase() === '/mymood'){
            navigate.push('/mymood/stats');
        }else{
            navigate.push('/mymood');
        }
    }

    useEffect(()=>{
        if(location.pathname.toLocaleLowerCase() === '/mymood'){
            setOpen(false);
        }else{
            setOpen(true);
        }
        setIsOpen(true);
    }, [location]);
    
    return (
        <div>
            <Box p={1}>
                <Grid style={{ background: 'white' }}>
                    {
                        isOpen && (
                            <Box display="flex" flexDirection="column" p={2}>
                                {
                                    open ? <BodyStadisticts onHandleChange={onHandleChange} /> : <Body onHandleChange={onHandleChange} />
                                }
                            </Box>
                        )
                    }
                </Grid>
            </Box>
        </div>
    )
};

export default MyMood;
