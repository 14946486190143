//import { useContext } from "react";
// import CampaignContext from "../../../context/CampaignsContext/CampaignContext"
// import {createCampaignsModal} from "../../../context/CampaignsContext/Actions"
import iconReport from "../../../assets/icons/add-report.svg";

const BtnCreateReport = ({alt,title,action}:any) => {
 // const {dispatch} = useContext(CampaignContext);

  const handleAccion=()=>{
    const arrayAction=["edit","see"]
    if(action === arrayAction[0]){
      console.log("editar")
    }

    if(action === arrayAction[1]){
      console.log("see")
    }

  }
  return (
    <button
        id="addPrestacion"
        type="button"
        onClick={() => handleAccion()}  
        className="btnNuevaPrestacion"
     >
      <div className="alineacionBoton">
        <img src={iconReport} alt={alt}/>
        <div className="nombrePrestacion">
        <b><span>{title}</span> </b>
        </div>
      </div>
    </button> 
  )
}

export default BtnCreateReport
