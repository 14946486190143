import {useReducer} from 'react';
import MyMoodContext, {initialState} from "./MyMoodContext";
import MyMoodReducer from './MyMoodReducer';

const ContractsState = (props:any) => {
    const [state,dispatch] = useReducer(MyMoodReducer, initialState)
    return (
        <MyMoodContext.Provider value={{state,dispatch}}>
                  {props.children}
        </MyMoodContext.Provider>
    )
}

export default ContractsState;