import { useContext, useState } from "react";
import {
    Grid,
    Menu,
    MenuItem,
} from "@material-ui/core";
import OrganigramaContext from "../../../context/OrganigramaContext/OrganigramaContext";
import { openOrganigramaModal } from "../../Team/Modals/ModalOrganigramaModal";
import CheckPermission from "../../../helpers/Administradores/Permissions";
import styles from '../Organigrama.module.css'

const MenuListOrganigrama = (props: any) => {
    const [anchorEl, setAnchorEl] = useState<any>(null);
    const open = Boolean(anchorEl);
    const { dispatch } = useContext(OrganigramaContext);

    const abreInfoPuesto = () => {
        openOrganigramaModal(
            {
                _id: props._id,
                NombrePuesto: props.NombrePuesto,
                AreaProyecto: props.AreaProyecto,
                PuestoSuperior: props.PuestoSuperior,
                Descripcion: props.Descripcion,
                showInformacion: true,
                projectId: props.projectId,
                higherPostId: props.higherPostId,
            },
            dispatch
        );
        setAnchorEl(null);
    };
    const abreEditaPuesto = () => {
        openOrganigramaModal(
            {
                _id: props._id,
                NombrePuesto: props.NombrePuesto,
                AreaProyecto: props.AreaProyecto,
                PuestoSuperior: props.PuestoSuperior,
                Descripcion: props.Descripcion,
                showEdit: true,
                projectId: props.projectId,
                higherPostId: props.higherPostId,
            },
            dispatch
        );
        setAnchorEl(null);
    };
    const abreEliminaPuesto = () => {
        openOrganigramaModal(
            { _id: props._id, showEliminar: true, NombrePuesto: props.NombrePuesto },
            dispatch
        );
        setAnchorEl(null);
    };

    const menuDelete = () => {
        if (CheckPermission("Organigrama.EliminarPuesto"))
        {
            if (props.EliminaOrganigrama === 0 && props.EliminaOrganigramaUsers === 0) {
                return <MenuItem divider button onClick={abreEliminaPuesto}>
                        Eliminar Puesto&nbsp;
                        <Grid container item justify="flex-end">
                            <img src={`/assets/svg/icono-eliminar.svg`} alt="Reenviar" />
                        </Grid>
                    </MenuItem>
            } else {
                return <MenuItem
                        divider
                        button
                        style={{
                            opacity: "0.5",
                            cursor: "context-menu",
                        }}
                    >
                        Eliminar Puesto&nbsp;
                        <Grid container item justify="flex-end">
                            <img src={`/assets/svg/icono-eliminar.svg`} alt="Reenviar" />
                        </Grid>
                    </MenuItem>
            }
        }else{
            return<></>
        }
    }
        return (
            <>
                <div className={styles.divTextJobTitle}
                onClick={(e) => setAnchorEl(e.currentTarget)}
                style={{
                    backgroundColor: props?.colorCode,
                }}
                >
                    <div
                    style={{
                        width: "139px",
                    }}
                    >
                        <div className={styles.textJobTitle} title={props?.NombrePuesto}>{props?.NombrePuesto}</div>
                    </div>
                </div>
                <Menu
                    className="MoreVerIcon"
                    anchorEl={anchorEl}
                    open={open}
                    transformOrigin={{ vertical: "top", horizontal: "right" }}
                    onClose={() => setAnchorEl(null)}
                >
                    <MenuItem divider button onClick={abreInfoPuesto}>
                        Información&nbsp;
                        <Grid container item justify="flex-end">
                            <img src={`/assets/svg/icono-ver.svg`} alt="Reenviar" />
                        </Grid>
                    </MenuItem>
                    {CheckPermission("Organigrama.EditarPuesto") && (
                        <MenuItem divider button onClick={abreEditaPuesto}>
                            Editar&nbsp;
                            <Grid container item justify="flex-end">
                                <img src={`/assets/svg/icono-editar.svg`} alt="Reenviar" />
                            </Grid>
                        </MenuItem>
                    )}
                     {menuDelete()}
                </Menu>
            </>
        );
    };
export default MenuListOrganigrama;
