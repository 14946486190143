import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins:false,
  scales: {
    y: {
      display: false
    },
    x:{
      display:true,
      color:"#303030"
    },
    
},
backgroundColor:"#2FAEFF"
};

const labels = ['Total', 'IMSS', 'INFONAVIT', 'ISR'];
const colors = ["#2698E0","#2698E0","#2FAEFF","#2FAEFF"]

export default function Taxes(props: any) {
  const data = {
    labels,
    datasets: [
      {
        label: '$',
        data: props.dataTableTaxes ,
        backgroundColor: colors,
      }
    ]
  };
  return <Bar options={options} data={data} type={undefined}
  
  />;
}
