import { ChangeEvent, useContext, useEffect, useState } from "react";
import { Grid, TextField, Button } from "@material-ui/core";
import Box from '@mui/material/Box';
import { Form } from "semantic-ui-react";
import CollaboratorContext from "../../../../context/CollaboratorContext/CollaboratorContext";
import { handleSetActiveStep, updateData } from "../../../../helpers/Collaborator/Collaborator";
import { useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import InputMask from "react-input-mask";
import {
  GET_ALL_USERS_NUEVOINGRESO,
  GET_USERS_BY_ID,
  UPDATE_USERS,
} from "../../../../Querys/querys";
import { useMutation } from "@apollo/client";
import { SuccessfulAlert } from "../../../../alerts/successAlerts";
import SaveIcon from "@material-ui/icons/Save";
import CheckPermission from "../../../../helpers/Administradores/Permissions";
import {RemoveSpaces} from "../../../../helpers/RemoveSpaces"

const PersonalDataNuevoIngreso = () => {
  const { state, dispatch } = useContext(CollaboratorContext);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [phoneMask, setPhoneMask] = useState("");

  const [updateColaboradores] = useMutation(UPDATE_USERS, {
    refetchQueries: [
      {
        query: GET_USERS_BY_ID,
        variables: { getUsersId: state.collaborator.id },
      },
      {query: GET_ALL_USERS_NUEVOINGRESO}
    ],
  });

  const params = useParams();
  useEffect(() => {
    if ("register" in params) setDisabled(true);
  }, [params]);

  const handleChange = async (
    e: ChangeEvent<{ name: string; value: unknown }>
  ) => {
    await updateData(e, state, dispatch, 0);
  };

  const initialValues = () => {
    return {
      name: state.collaborator?.name || "",
      firstName: state.collaborator?.firstName || "",
      lastName: state.collaborator?.lastName || "",
      email: state.collaborator?.email || "",
      cellphone: state.collaborator?.cellphone || "",
      accept_info: state.collaborator?.accept_info,
    };
  };

  const formik = useFormik({
    initialValues: initialValues(),
    validationSchema: Yup.object(validationSchema()),

    onSubmit: async (formData) => {
      const info      = {...formData}
      const rightData = {...formData,
                                name:      RemoveSpaces(info.name),
                           firstName: RemoveSpaces(info.firstName),
                            lastName:  RemoveSpaces(info.lastName),
                         accept_info: state.collaborator?.accept_info                             
                      }                     
     // formData.accept_info = state.collaborator?.accept_info;
       updateColaboradores({
        variables: {
          updateUsersId: state.collaborator?.id,
          input: rightData,
          process: {
            tab: "information",
            section: "section_1",
          },
        },
      }).then((res) => {
        handleSetActiveStep(1, dispatch);
        SuccessfulAlert({ text: "Se actualizó correctamente" });
      });
    },
  });

  return (
    <Form onSubmit={formik.submitForm}>
      <div className="novalidate__border">
        <Box mb={2}>
          <TextField
            type="text"
            name="name"
            defaultValue={state.collaborator.name}
            label="Nombre(s)"
            variant="outlined"
            size="small"
            fullWidth={true}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            onBlur={(e) => handleChange(e)}
            InputProps={{
              readOnly: false,
            }}
            disabled={disabled}
            helperText={formik.touched.name && !formik.values?.name && "Obligatorio"}
          />
        </Box>
        <Grid direction="row" container spacing={2}>
          <Grid xs item>
            <TextField
              name="firstName"
              defaultValue={state.collaborator?.firstName || ""}
              label="Primer apellido"
              variant="outlined"
              size="small"
              fullWidth={true}
              onChange={formik.handleChange}
              error={formik.touched.firstName && Boolean(formik.errors.firstName)}
              onBlur={(e) => handleChange(e)}
              InputProps={{
                readOnly: false,
              }}
              disabled={disabled}
              helperText={formik.touched.firstName && !formik.values?.firstName && "Obligatorio"}
            />
          </Grid>
          <Grid xs item>
            <TextField
              name="lastName"
              defaultValue={state.collaborator?.lastName || ""}
              label="Segundo apellido"
              variant="outlined"
              size="small"
              fullWidth={true}
              onChange={formik.handleChange}
              error={formik.touched.lastName && Boolean(formik.errors.lastName)}
              onBlur={(e) => handleChange(e)}
              InputProps={{
                readOnly: false,
              }}
              disabled={disabled}
              helperText={formik.touched.lastName && !formik.values?.lastName && "Obligatorio"}
            />
          </Grid>
        </Grid>
        <Box mt={2}>
          <TextField
            type="email"
            name="email"
            defaultValue={state.collaborator?.email || ""}
            label="Email"
            variant="outlined"
            size="small"
            fullWidth={true}
            error={formik.touched.email && Boolean(formik.errors.email)}
            onChange={formik.handleChange}
            onBlur={(e) => handleChange(e)}
            InputProps={{
              readOnly: false,
            }}
            disabled={disabled}
            helperText={formik.touched.email && !formik.values?.email && "Obligatorio"}
          />
        </Box>
        <Box mt={2}>
          <InputMask
            mask="999 999 99 99"
            defaultValue={state.collaborator?.cellphone || phoneMask}
            onChange={(e) => {
              setPhoneMask(e.target.value);
              formik.handleChange(e);
            }}
            disabled={false}
            onBlur={(e) => handleChange(e)}
          >
            <TextField
              id="cellphone"
              type="string"
              name="cellphone"
              defaultValue={state.collaborator?.Celular || ""}
              label="Teléfono celular profesional"
              variant="outlined"
              size="small"
              fullWidth={true}
              error={formik.touched.cellphone && Boolean(formik.errors.cellphone)}
              InputProps={{
                readOnly: false,
              }}
              disabled={disabled}
              helperText={formik.touched.cellphone && !formik.values?.cellphone && "Obligatorio"}
            />
          </InputMask>
        </Box>
      </div>
      {CheckPermission(
        "Colaboradores.NuevoIngreso.Ver.InformacionAlta.InformacionPersonal.Guardar"
      ) && (
        <Grid
          direction="row"
          container
          justify="flex-end"
          alignItems="center"
          style={{ marginTop: "20px" }}
        >
          <Button type="submit" className="buttonSave">
            <SaveIcon />
            &nbsp; Guardar
          </Button>
        </Grid>
      )}
    </Form>
  );
};

const validationSchema = () => {
  return {
    name: Yup.string().required("El name es requerido"),
    firstName: Yup.string().required("El apellido es un campo requerido"),
    email: Yup.string().email().required("El email es un campo obligatorio"),
    cellphone: Yup.number()
      .required("El celular es un campo obligatorio")
      .min(13, "El celular debe tener al menos 10 dígitos"),
  };
};

export default PersonalDataNuevoIngreso;
