import { createContext, Dispatch } from 'react';
import { AdminTypes } from './AdminTypes';

export type AdminProps = {
    id: string,
    Nombre: string,
    Usuario: string,
    image: string,
    logged: boolean,
    loading: boolean,
    Cargando: boolean,
    Proyectos:{
        idProyectos: string,
        Proyectos: string
    },
    Permisos: any,
    PermisosContex: any

};

type PayloadAdmin =
    | AdminProps;

export interface DispatchAdmin {
    type: AdminTypes
    payload?: PayloadAdmin
};

type AdminPartial = {
    adminState: AdminProps,
    adminDispatch: Dispatch<DispatchAdmin>
};



export const AdminContext = createContext<Partial<AdminPartial>>({});