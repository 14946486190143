const Excel = require("exceljs");

export const CreateExcelRelogChecador = (data: any,TipoReporte:any) => {
    const workbook = new Excel.Workbook();
    const ReporteRelojChecador= workbook.addWorksheet("Reporte Registros de tiempos");
    const width = 45;

    const header = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "002060" },
    };
    const headerFont = {
        name: "Arial",
        color: { argb: "FFFFFF" },
        family: 2,
        size: 11,
        bold: true,
    };

    ReporteRelojChecador.columns = [
        { header: "TIPO CHECK", key: "TipoChecada", width },
        { header: "NOMBRE", key: "Nombre", width },
        { header: "AREA O PROYECTO", key: "Area", width },
        { header: "SEDE", key: "Sede", width },
        { header: "HORARIO LABORAL", key: "HorarioLaboral", width },
        { header: "HORA", key: "Hora", width },
        { header: "UBICACIÓN", key: "Ubicacion", width },
        { header: "T. TRABAJADO", key: "Acumulado", width },
        { header: "RETARDOS", key: "Diferencia", width },
        
    ];
    
    let numletter = 65;
    let numletter2 = 65;
    let letter = "";
    ReporteRelojChecador.columns.forEach(() => {
        if(numletter > 90){
            letter = 'A'+String.fromCharCode(numletter2)
            numletter2++;
        }else{
            letter = String.fromCharCode(numletter)
        }
        ReporteRelojChecador.getCell(`${letter}1`).fill = header;
        ReporteRelojChecador.getCell(`${letter}1`).font = headerFont;
        ReporteRelojChecador.getCell(`${letter}1`).alignment = {
        horizontal: "center",
        };        
        numletter++;        
    });
     data.forEach((item: any) => {
         ReporteRelojChecador.addRow({
            TipoChecada: item?.TipoChecada,
            Nombre: item?.Nombre,
            Area: item?.Area,
            Sede: item?.Sede,         
            HorarioLaboral: item?.HorarioLaboral,
            Hora: item?.Hora,
            Ubicacion: item?.Ubicacion,
            Acumulado: item?.Acumulado,
            Diferencia: item?.Diferencia,
        });
    });    
    
    const nameReport = TipoReporte; 
    workbook.xlsx.writeBuffer().then(function (data: Blob) {
        const blob = new Blob([data],
        { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement('a');
        anchor.href = url;
        anchor.download = nameReport;
        anchor.click();
        window.URL.revokeObjectURL(url);
    });
};