import { useState, ChangeEvent } from "react";
import { Grid } from "@material-ui/core";
import Box from '@mui/material/Box';
import CustomTabs from "../Collaborators/Tab/CustomTabs";
import CustomTab from '../Collaborators/Tab/CustomTabMain';
import CreateCampaigns from "./modals/CreateCampaigns";
import Campaigns from "./Campaigns";
import BtnCreate from "./components/BtnCreate";
import HistoricalCampaigns from "./HistoricalCampaigns";

const CampaignContent = () => {
  const [tab, setTab] = useState(0);

  const handleChange = (event: ChangeEvent<{}>, newValue: number) => {
    setTab(newValue)
    localStorage.setItem('currentPill', String(newValue));
  };
  return (
    <div>
      <Box mt={3} ml={5} className="Title" mb={2}>
        KPI'S y bonos
      </Box>
      <Box p={5} pb={3} pt={0}>
        <Grid
          container
          justify="flex-start"
          alignItems="center"
          spacing={2}
        >
          <Grid item xs={12} md={6}>
            <CustomTabs
              value={tab}
              onChange={handleChange}
              aria-label="simple tabs example"
            >
              <CustomTab label="Campañas" value={0} />
              <CustomTab label="Históricas" value={1} />
            </CustomTabs>
          </Grid>
          <Grid item xs={12} md={6}>
            {
              tab === 1 && (
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="flex-end"
                  style={{ width: "100%" }}
                >
                  <BtnCreate />
                </Box>
              )
            }
          </Grid>
        </Grid>

      </Box>
      <div className="contenedor2">
        {tab === 0 && <Campaigns />}
        {tab === 1 && <HistoricalCampaigns />}
      </div>
      <CreateCampaigns />
    </div>

  )
}

export default CampaignContent
