import {Dialog} from "@material-ui/core"
import { useContext} from "react"
import CalendlyContext from "../../../context/Calendly/CalendlyContext"
import { clearCalendlyModal } from "../../../context/Calendly/Actions"
import styles from "../../../styles/Calendly/Calendly.module.css"
const ShowCalendly = (props:any) => {

    const {state, dispatch} = useContext(CalendlyContext)
    const handleClose = () => {
        clearCalendlyModal({}, dispatch)
    } 
    
    return(
        <Dialog open={state.createCalendly} aria-labelledby="form-dialog-title" onClose={handleClose} maxWidth={"md"} >
            <div className={styles.divscrollcon}>
                <div className={styles.contenedorTitulo}>
                    <div className={styles.tituloCrearEmpresa}>
                    Agregar reunión
                    </div>
                    <div className={styles.contenedorCerrarModal} onClick={(()=> handleClose())}></div> 
                </div>
                <div className={styles.contenedorTabs}>
                    <iframe title="Calendario" style={{width:"100%", height: '580px', borderStyle: 'hidden'}}
                      src="https://calendar.app.google/tyqzSD1NE6mUhVMv9">                     
                    </iframe>
                </div>
                <div className={styles.contenedorBotones}>
                    <button 
                    className={styles.botonCancelar}
                    onClick={() =>{
                        props.setCalendly("1")
                        handleClose()
                    } }
                    type="button"
                    >
                    Cerrar
                    </button>
                    

                </div>
            </div>
            
        </Dialog>
    )
}

export default ShowCalendly