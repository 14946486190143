
import { SetStateAction,useContext, useEffect, useState } from "react";
import CalendarsContext from "../../../../context/NewCalendarContext/CalendarsContext";
import { clearEditEventModal } from "../../../../context/NewCalendarContext/Actions";
import {FormHelperText, Button, Dialog, DialogContent, TextField,Select, InputLabel,FormControl,MenuItem } from "@material-ui/core"
import { Formik, Form} from "formik";
import styles from '../../../../components/NewCalendar/Calendars.module.css';
import * as Yup from "yup";
import { useMutation, useQuery } from "@apollo/client";
import { 
    GET_ALL_PROJECT, 
    GET_EVENT, 
    UPDATE_EVENT, 
    GET_COUNT_EVENT_NONWORKINGDAY, 
    GET_ENTERPRISE_EVENT,
    UPDATE_PENTERPRISE_EVENT,
    GET_ALL_EVENT_CALENDAR_BY_MONTH_YRAR
} from "../../../../Querys/querys";
import { SuccessfulAlert } from "../../../../alerts/successAlerts";
import { subMonths } from 'date-fns';
import moment from 'moment';
import { AdminContext } from "../../../../context/AdminContext/AdminContext";
import DateRangeField from "../../../MyMood/Fields/DateRangeField";

const CreateEventEditModal2 = ({setDate, date}:any) => { 
 
    const {state, dispatch} = useContext(CalendarsContext);
    const {adminState} = useContext(AdminContext);
    const [showCalendario, setShowCalendario] = useState(false);
    const [stateCalendario, setStateCalendario] = useState([
        {
            startDate: subMonths(new  Date(parseInt(moment(state.date1).format('YYYY')), parseInt(moment(state.date1).format('MM')), parseInt(moment(state.date1).format('DD'))), 1),
            endDate: subMonths(new Date(parseInt(moment(state.date2).format('YYYY')), parseInt(moment(state.date2).format('MM')), parseInt(moment(state.date2).format('DD'))), 1),
            key: "selection"
        }]
    );
    const [upDateNewEvent] = useMutation(UPDATE_EVENT, {
        refetchQueries: [
            {query:GET_EVENT, variables:{ id: parseInt(state.idAux)}},
            {query:GET_ENTERPRISE_EVENT, variables:{ eventid: parseInt(state.idAux)}},
            {query:GET_COUNT_EVENT_NONWORKINGDAY},
            {query:GET_ALL_EVENT_CALENDAR_BY_MONTH_YRAR, variables:{
                calendarId: parseInt(state._id), 
                month: parseInt(moment(stateCalendario[0].startDate).format('M')), 
                year: parseInt(moment(stateCalendario[0].startDate).format('YYYY'))
            }},
            {query:GET_ALL_EVENT_CALENDAR_BY_MONTH_YRAR, variables:{
                calendarId: parseInt(state._id), 
                month: parseInt(moment(stateCalendario[0].endDate).format('M')), 
                year: parseInt(moment(stateCalendario[0].endDate).format('YYYY'))
            }},
        ],
    })
    const [upDataProjectEvent] = useMutation(UPDATE_PENTERPRISE_EVENT, {
        refetchQueries: [
            {query:GET_EVENT, variables:{ id: parseInt(state.idAux)}},
            {query:GET_ENTERPRISE_EVENT, variables:{ eventid: parseInt(state.idAux)}},
            {query:GET_COUNT_EVENT_NONWORKINGDAY},
            {query:GET_ALL_EVENT_CALENDAR_BY_MONTH_YRAR, variables:{
                calendarId: parseInt(state._id), 
                month: parseInt(moment(stateCalendario[0].startDate).format('M')), 
                year: parseInt(moment(stateCalendario[0].startDate).format('YYYY'))
            }},
            {query:GET_ALL_EVENT_CALENDAR_BY_MONTH_YRAR, variables:{
                calendarId: parseInt(state._id), 
                month: parseInt(moment(stateCalendario[0].endDate).format('M')), 
                year: parseInt(moment(stateCalendario[0].endDate).format('YYYY'))
            }},
        ],
    })

    const { data:dataProject }  = useQuery(GET_ALL_PROJECT);
    const [allEnterprise, setAllEnterprise] = useState([]);
    const [showE, setShowE] = useState(false);
    const [estadoFiltrado, setEstadoFiltrado] = useState<any[]>([]);
    const [showSeleccionados, setShowColaboradoresSeleccionado] = useState(false);
    const [seleccionados, setSeleccionados] = useState<any[]>([]);
    const [isCheck, setIsCheck] = useState<any[]>([]);
    const [isCheckE, setIsCheckE] = useState<any[]>([]);

    const { data: dataEE } =  useQuery(GET_ENTERPRISE_EVENT, {
        variables: {
            eventid: parseInt(state.idAux)
            }
    });
    const [allEnterpriseEvent, setAllEnterpriseEvent] = useState([]);
    const { loading, data } =  useQuery(GET_EVENT, {
        variables: {
            id: parseInt(state.idAux)
            }
    });

    const allEvent: any = data?.GET_EVENT;

    useEffect(() => {
        if(dataProject && dataProject.GET_ALL_PROJECT && dataEE && dataEE.GET_ENTERPRISE_EVENT){
            const data = dataProject.GET_ALL_PROJECT;
            const dataEvent = dataEE.GET_ENTERPRISE_EVENT;
            const enterpriseNotInEvent = data?.filter((enterprise:any) => !dataEvent?.find((enterpriseEvent:any) => enterpriseEvent.id === enterprise.id));
            setAllEnterprise(data);
            setAllEnterpriseEvent(dataEvent);
            setSeleccionados(dataEvent);
            setEstadoFiltrado(enterpriseNotInEvent);
        }
    }, [dataProject, dataEE]);

    if(loading) return null;

    const initialValues = () => {
        return {
            tipo: allEvent?.eventType,
            titulo: allEvent?.name,
            descripcion: allEvent?.description,
            area: allEvent?.projectId,
            calendarId: allEvent?.calendarId
        }
    }

    const dateText = () =>{

        const date =  stateCalendario.map((home: any) =>{
            if( moment(home?.startDate).format('YYYY') !== '1999'){
                return <div id="hora">
                <span 
                className={styles.textoFecha}
                >{new Date(home.startDate).toLocaleDateString()} - {new Date(home.endDate).toLocaleDateString()}</span></div>
            }else{
                setStateCalendario([{
                    startDate: new Date(allEvent?.initDate),
                    endDate: new Date(allEvent?.endDate),
                    key: "selection"
                }])
                return <div id="hora">
                <span 
                className={styles.textoFecha}
                >{new Date(allEvent?.initDate).toLocaleDateString()} - {new Date(allEvent?.endDate).toLocaleDateString()}</span></div>
            }
                

        })
        return date
    }

    const handleClose = ()=>{
        clearEditEventModal({},dispatch);
    }
    
    const toggleCalendar = () => {
    
        if(showCalendario === false){
          setShowCalendario(true);
        }
    }
    const rangeSelection = (selection: any)=>{
        setStateCalendario((current)=>current= selection);
        setShowCalendario(!showCalendario);
    }
    const AllSelect = () => {
        
        const nuevoSeleccionadas: SetStateAction<any[]> = [];
        setEstadoFiltrado(nuevoSeleccionadas);
        setSeleccionados(allEnterprise);
        setIsCheck([]);
    };

    const AllDelete = () => {
        
        const nuevoSeleccionadas: SetStateAction<any[]> = [];
        setSeleccionados(nuevoSeleccionadas);
        setEstadoFiltrado(allEnterprise);
        setIsCheckE([]);
    };

    const handleClick = (e: any) => {

        const { id, checked } = e.target;
        setIsCheck([...isCheck, id]);
        if (!checked) {
          setIsCheck(isCheck.filter((lis: any) => lis !== id));
        }
    };

    const AgregarLista = () => {
        
        const checkedBoxes = document.querySelectorAll(
          '#Universo > [type="checkbox"]:checked'
        );
        let workerToAdd: { id: string }[] = [];
    
        checkedBoxes.forEach((checkElement) => {
          workerToAdd.push({
            id: checkElement.id,
          });
        });
    
        const seleccionadosSeleccionar = allEnterprise.filter((persona: any) => {
          return workerToAdd
            .map((worker) => {
              return worker.id;
            })
            .includes(persona?.id);
        });
    
        setSeleccionados([...seleccionados, ...seleccionadosSeleccionar]);
        const nuevaSeleccionados = [...seleccionados, ...seleccionadosSeleccionar]
        const nuevoUniverso = allEnterprise.filter((persona: any) => {
          return !nuevaSeleccionados
            .map((worker) => {
              return worker.id;
            })
            .includes(persona?.id);
        });
      
        setEstadoFiltrado([...nuevoUniverso]);
        setIsCheck([]);
        setIsCheckE([]);
    };

    const handleSelectEliminar = (e: any) => {
    
    
        setIsCheck([]);
        setIsCheckE([]);
    
        let filtradoSeleccionado = seleccionados?.filter((lis: any) => {
            return e.target.checked && !isCheckE.includes(lis.id);
          })
          .map((lis: any) => lis.id);
        setIsCheckE(filtradoSeleccionado);
    
        
    };
    const handleClickE = (e: any) => {
        
        const { id, checked } = e.target;
        setIsCheckE([...isCheckE, id]);
        if (!checked) {
          setIsCheckE(isCheckE?.filter((lis: any) => lis !== id));
        }
        
    };
    const EliminardeLista = () => {
 
        const checkedBoxes = document.querySelectorAll(
          '#colaboladores > [type="checkbox"]:checked'
        );
        let workerToDelete: { id: string; colaborator: string }[] = [];
        checkedBoxes.forEach((checkElement) => {
          workerToDelete.push({
            id: checkElement.id,
            colaborator: checkElement.className,
          });
        });
    
        const seleccionadosEliminar = seleccionados.filter((persona) => {
          return workerToDelete?.map((worker) => {
              return worker.id;
            })?.includes(persona?.id);
        });
    
        setEstadoFiltrado([...estadoFiltrado, ...seleccionadosEliminar]);
    
        const nuevoSeleccionadas = seleccionados?.filter((persona) => {
          return !workerToDelete?.map((worker) => {
              return worker.id;
            })?.includes(persona?.id);
        });
      
        setSeleccionados(nuevoSeleccionadas);
        setIsCheck([]);
        setIsCheckE([]);
        
    
    };

    const showHiddenAreas = async (e: any) => {

        if(
          e.target.parentNode.className !== styles.DivSeleccionadosCollaborators
          &&
          e.target.parentNode.className !== styles.checkboxitemSelecionados
          &&
          e.target.parentNode.className !== styles.inputSelecionados
          &&
          e.target.parentNode.className !== styles.labelSelecionado
          &&
          e.target.parentNode.className !== styles.contenedorLista
          &&
          e.target.parentNode.className !== styles.listaNombres
          &&
          e.target.parentNode.className !== styles.labelSelecionados
          &&
          e.target.parentNode.className !== styles.botonEliminarList
          &&
          e.target.parentNode.className !== styles.EliminarImg
          &&
          e.target.parentNode.className !== ''
          &&
          e.target.parentNode.className !== styles.contenedorTotalesText
          &&
          e.target.parentNode.className !== styles.divtotalesSelecionados
          &&
          e.target.parentNode.className !== styles.textoDivTotalesSelecionados
        ){
          setShowColaboradoresSeleccionado(false);
        }
    
        if(
          e.target.parentNode.className !== styles.DivSeleccionadosUniverso
          &&
          e.target.parentNode.className !== styles.contenedorListaAvailableCollaborators
          &&
          e.target.parentNode.className !== styles.listaNombresAvailableCollaborators
          &&
          e.target.parentNode.className !== styles.checkboxitemAvailableCollaborators
          &&
          e.target.parentNode.className !== styles.proyectName
          &&
          e.target.parentNode.className !== styles.botonAgregar
          &&
          e.target.parentNode.className !== styles.pc_iconoAgregarDos
          &&
          e.target.parentNode.className !== styles.textoAgrega
          &&
          e.target.parentNode.className !== styles.contenedorSelecioneColaborador
          &&
          e.target.parentNode.className !== ''
          &&
          e.target.parentNode.className !== styles.divtotalesUniverso
          &&
          e.target.parentNode.className !== styles.textoDivTotales
        ){
          setShowE(false);
        }
    } 

  return(
    <Dialog open={state.createEditEventModal} aria-labelledby="form-dialog-title" maxWidth="sm" fullWidth={true}
    onClick={(e) => {
        showHiddenAreas(e)
    }}
    >
        <div className={styles.dialogContainer}>
            <div className={styles.centerContainer}>
                <h2 id="form-dialog-title" data-testid="TitleModal">Editar evento</h2>
            </div>
            <DialogContent>
            <Formik
            initialValues={initialValues()}
            validationSchema={validationSchema}
            onSubmit={formData => {
                const idEvent = Number(state.idAux)
                upDateNewEvent({
                    variables: {
                        upDateEventId:idEvent,
                        input: {
                            name: formData.titulo,
                            calendarId: formData.calendarId,
                            description:formData.descripcion,
                            initDate:stateCalendario[0].startDate,
                            endDate:stateCalendario[0].endDate,
                            projectId:parseInt(formData.area),
                            eventType:formData.tipo
                        },
                    },
                }).then(({data})=>{
                    let selectedProject : string = '0'
                    if(seleccionados.length > 0){
                        seleccionados?.forEach((lis: any) =>{
                            selectedProject = selectedProject +','+ lis.id
                        })
                    }else{
                        estadoFiltrado?.forEach((lis: any) =>{
                            selectedProject = selectedProject +','+ lis.id
                        })
                    }

                    upDataProjectEvent({
                        variables: {
                            input: {
                                eventid: idEvent,
                                userAdd: adminState?.Usuario,
                                projects: selectedProject,
                            },
                        },
                    })
                    
                    
                    SuccessfulAlert({text:"Se edito evento"});
                });
                handleClose();
            }}
        >
            {({
                values,
                errors,
                touched,
                handleChange,
                handleSubmit,
            }) => (
            <Form onSubmit = {handleSubmit}>
                <FormControl
                    fullWidth
                    size="small"
                    variant="outlined"
                    className={styles.dialogFields}
                    error={touched.tipo && Boolean(errors.tipo)}
                >
                    <InputLabel 
                    id="demo-simple-select-label"
                    style={{
                        backgroundColor : "#FFFFFF"
                    }}
                    >Tipo de evento</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        name="tipo"
                        value={values.tipo}
                        onChange={handleChange}
                    >
                        <MenuItem value="evento">Evento</MenuItem>
                        <MenuItem value="tarea">Tarea</MenuItem>
                    </Select>
                    <FormHelperText>{touched.tipo && errors.tipo}</FormHelperText>
                </FormControl>
                <TextField
                    fullWidth
                    className={styles.dialogFields}
                    name="titulo"
                    label="Titulo"
                    variant="outlined"
                    value={values.titulo}
                    onChange={handleChange}
                    error={touched.titulo && Boolean(errors.titulo)}
                    helperText={touched.titulo && errors.titulo}
                    size="small" />
                <TextField
                    fullWidth
                    className={styles.dialogFields}
                    name="descripcion"
                    label="Descripción"
                    variant="outlined"
                    value={values.descripcion}
                    multiline
                    rows={4}
                    onChange={handleChange}
                    error={touched.descripcion && Boolean(errors.descripcion)}
                    helperText={touched.descripcion && errors.descripcion}
                    size="small" />
                <DateRangeField setDate={setStateCalendario}  containerClass={"fieldsetInput"} daySelected1={state.date1} daySelected2={state.date2}  ></DateRangeField>
                <div className={styles.contenedorColaborator}>
                    <div className={styles.contenedorSelecioneColaborador}>
                        <button
                            className={styles.divtotalesUniverso}
                            onClick={(editar) => {
                                editar.preventDefault()
                                setShowE(!showE);
                            }}
                        >
                            <span className={styles.textoDivTotales}>
                
                            &Aacute;rea disponibles ({estadoFiltrado?.length})
                            </span>
                        </button>
                    
                    
                    </div>
                    <div className={styles.contenedortotalesAreas}>
                        <button className={styles.divtotalesyellow} onClick={(e)=>{
                            AllSelect()
                            e.preventDefault()
                        }}>
                            {" "}
                            +{" "}
                        </button>
                        <div className={styles.contenedorTotalesText}>
                            <button className={styles.divtotalesSelecionados} 
                            onClick={(e) => {
                                e.preventDefault()
                                setShowColaboradoresSeleccionado(!showSeleccionados);
                            }}
                            >
                            <span className={styles.textoDivTotalesSelecionados}>
                            {" "}Ver Seleccionados ({seleccionados?.length}){" "}
                            </span>
                            </button>
                        </div>
                        
                        <button className={styles.divtotalesyellowl} onClick={(e)=>{
                            AllDelete()
                            e.preventDefault()
                        }}>
                            {" "}
                            -{" "}
                        </button>
                    
                    </div>
                    {showE && estadoFiltrado?.length > 0 ? (
                        <div className={styles.DivSeleccionadosUniverso}>
                            <ul className={styles.contenedorListaAvailableCollaborators}>
                                {estadoFiltrado?.map((lis: any) => (
                                <li className={styles.listaNombresAvailableCollaborators}>
                                    <div id="Universo" className={styles.checkboxitemAvailableCollaborators}>
                                        <input
                                            id={lis.id}
                                            key={lis.id}
                                            type="checkbox"
                                            value={lis.id}
                                            checked={isCheck.includes(lis.id)}
                                            className={styles.userName}
                                            onClick={(e) => handleClick(e)}     
                                        ></input>
                                        <label htmlFor={lis.id} className={styles.proyectName}> {lis.proyectName}</label>
                                    </div>
                                </li>
                                ))}
                            </ul>
                            <br></br>
                            <button className={styles.botonAgregar} onClick={(e)=>{
                                AgregarLista()
                                e.preventDefault()
                            }}>
                                
                                <span className={styles.textoAgregar}>Agregar</span>
                            </button>
                        </div>
                    ) : (
                        ""
                    )}
                    {showSeleccionados ? (
                        <div className={styles.DivSeleccionadosCollaborators}>
                            {seleccionados?.length > 0 ? (
                                <div className={styles.checkboxitemSelecionados}>
                                <input
                                    type="checkbox"
                                    id="eliminarTodos"
                                    className={styles.inputSelecionados}
                                    onChange={(e) => handleSelectEliminar(e)}
                                ></input>
                                <label htmlFor="eliminarTodos" className={styles.labelSelecionados}> Seleccionar Todos </label>
                                </div>
                            ) : (
                                ""
                            )}

                            <ul className={styles.contenedorLista}>
                                {seleccionados?.map((lis: any) => (
                                <li className={styles.listaNombres}>
                                    <div id="colaboladores" className={styles.checkboxitemSelecionados}>
                                    <input
                                        id={lis.id}
                                        key={lis.id}
                                        checked={isCheckE.includes(lis.id)}
                                        type="checkbox"
                                        value={lis.id}
                                        onClick={(e) => handleClickE(e)}
                                        className={lis.proyectName}
                                    ></input>
                                    <label htmlFor={lis.id} className={styles.labelSelecionados} > {lis.proyectName}</label>
                                    </div>
                                </li>
                                ))}
                            </ul>
        
                            <button
                            className={styles.botonEliminarList}
                            onClick={(e) => {
                                EliminardeLista()
                                e.preventDefault()
                            }}
                            >
                            <img
                                src="/assets/icons/eliminar-lista.svg"
                                alt="Eliminar"
                                className={styles.EliminarImg}
                            />
                            Eliminar
                            </button>

                        </div>
                    ) : (
                        ""
                    )}
                    
                </div>
                
                <div className={styles.centerContainer}>
                    <Button className={styles.buttonCancelCalendar} onClick={() => handleClose()}>
                        <b className={styles.buttonCancelCalendarText}
                        style={{
                            textTransform: "capitalize"
                        }}
                        >Cancelar</b>
                    </Button>
                    <div className={styles.conteinerSave}>
                        <button type="submit" className={styles.buttonSaveCalendar2}>
                        <b className={styles.buttonSaveCalendarText}
                        style={{
                            textTransform: "capitalize"
                        }}
                        >Editar
                        </b>
                        </button>
                    </div>
                        
                </div>

                </Form>
            )}
            </Formik>
            </DialogContent>
        </div>
    </Dialog>
  )
}


// @ts-ignore
const validationSchema = Yup.object().shape({
    tipo: Yup.string().required("El tipo de evento es requerido"),
    titulo:Yup.string().required("El titulo es requerido"),
    //descripcion : Yup.string().required("La descripcion es requerido"),
    //area : Yup.string().required("El area es requerido")  
})
// @ts-ignore


export default CreateEventEditModal2