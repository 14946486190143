import { useState, useEffect, useContext } from "react";
import EliminaEventualPayroll from "./Modals/EliminaEventualPayroll";
import CrearEventualPayrollsModal from "./Modals/CrearEventualPayrollsModal";
import {
  createEventualnominaModal,
  deleteEventualProcess,
} from "../../context/PayrollProcess/EventualActions";
import EventualPayrollProcessContext from "../../context/PayrollProcess/EventualPayrollProcessContext";
import { useHistory } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import {
  GET_ALL_EVENTUALPAYROLL,
  UPDATE_EVENTUALPAYROLL_STATE,
} from "../../Querys/querys";
import styles from "./PayrollStyles.module.css";
import { getDateDay } from "../../helpers/Payroll/Payroll";
import { formatter } from "../../helpers/formatoMoneda";
import CheckPermission from "../../helpers/Administradores/Permissions";

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
  sistemaActivo: boolean;
}

const ActivePayrollTable = (props: TabPanelProps) => {
  const { sistemaActivo } = props;
  const {data: resultPayroll, startPolling, stopPolling} = useQuery(GET_ALL_EVENTUALPAYROLL);
  const allPayroll = resultPayroll?.GET_ALL_EVENTUALPAYROLL;
  const history = useHistory();
  const { dispatch } = useContext(EventualPayrollProcessContext);
  const [save, setSave] = useState(true);

  useEffect(() => {
    obtenerDatos();
  }, []);

  useEffect(() => {

    startPolling(1000);
    return () =>{
      stopPolling()
    }
  }, [allPayroll,startPolling, stopPolling]);

  const obtenerDatos = async () => {};

  const [updatePayroll] = useMutation(UPDATE_EVENTUALPAYROLL_STATE);

  const updateState = (id: any) => {
    updatePayroll({
      variables: {
        input: {
          id: id,
          statusProgress: "Calcular",
        },
      },
    });
  };

  const createModal = () => {
    createEventualnominaModal({ createEventual: true }, dispatch);
  };

  const eliminaEventual = (id: any) => {
    deleteEventualProcess({ _id: id, deleteModal: true }, dispatch);
  };

  const deletePayroll = (payroll: any) => {
    return <>

      
      {
      
      payroll.payrollCompleted === 0? 
        <div
          className={styles.pp_contenedorIconoEliminar}
          onClick={() => eliminaEventual(payroll.id)}
        >
          <img
            className={styles.pp_iconoEliminar}
            src="/assets/svg/icono_eliminar-nomina.svg"
            alt = ""
          ></img>
          <span className={styles.pp_textoEliminar}>Eliminar</span>
        </div>
      : <div></div>
      
      }
    </>
    
  }

  return (
    <>
      <div className={styles.pp_contenedor}>
        <div className={styles.pp_contenedorBoton}>
          {(CheckPermission("Nominas.Eventuales.Crear") && sistemaActivo) && (
            <button
              className={styles.pp_botonCrear}
              onClick={() => createModal()}
            >
              <div>
                <span className={styles.pp_signo}>+</span>
              </div>
              <div>Nueva Nómina Eventual</div>
            </button>
          )}
        </div>
      </div>
      <div className={styles.pp_contenedorLista}>
        {allPayroll?.map((lis: any) => (
          <div className={styles.pp_lista}>

            <div className={styles.pp_ColumnaUnoLista}>
              <div className={styles.pp_tituloGrupo}>
                <span>{lis.group_name}</span>
              </div>
              <div className={styles.pp_tituloGrupo}>
                {lis.payroll_type === "PTU" ? (
                  <span>
                    {lis.payroll_type} {lis.AnioPTU}
                  </span>
                ) : null }
                {lis.payroll_type === "Fondo Ahorro" ? (
                  <span>
                    {lis.payroll_type} {getDateDay(lis?.init_date)}{" "}
                    {getDateDay(lis?.end_date)}
                  </span>
                ) : null }
                {lis.payroll_type === "Aguinaldo" ? (
                  <span>
                    {lis.payroll_type}  {lis.AguinaldoPeriod}
                  </span>
                ) : null }
                {lis.payroll_type === "Prima Vacacional" ? (
                  <span>
                    {lis.payroll_type} 
                  </span>
                ) : null }
                {lis.payroll_type === "Vales de Despensa" ? (
                  <span>
                    {lis.payroll_type} {lis.pantryVouchersMonth}
                  </span>
                ) : null }


              </div>
              <div className={styles.pp_procesoCalcular}>
                <span>{lis.statusProgress}</span>
              </div>


              {lis.statusProgress === "Calcular" ? (
 
                <div className={styles.pp_contenedorBarras}>
                  <div className={styles.pc_circuloAmarillo}>
                    <div className={styles.pc_circuloBlanco}></div>
                  </div>
                  <div className={styles.pc_lineaSeguimientoPrincipal} ></div>
                  <div className={styles.pc_circuloGris}></div>
                  <div className={styles.pc_lineaSeguimientoPrincipal}></div>
                  <div className={styles.pc_circuloGris}></div>
                  <div className={styles.pc_lineaSeguimientoPrincipal}></div>
                  <div className={styles.pc_circuloGris}></div>
                </div>

              ): null}

              {lis.statusProgress === "Dispersar" ? (
              
              <div className={styles.pp_contenedorBarras}>
                <div className={styles.pc_circuloAmarillo}>
                  <div className={styles.pd_iconoCompleto}></div>
                </div>
                <div className={styles.pc_lineaSeguimientoPrincipalCompleto} ></div>
                <div className={styles.pc_circuloAmarillo}>
                  <div className={styles.pc_circuloBlanco}></div>
                </div>
                <div className={styles.pc_lineaSeguimientoPrincipal}></div>
                <div className={styles.pc_circuloGris}></div>
                <div className={styles.pc_lineaSeguimientoPrincipal}></div>
                <div className={styles.pc_circuloGris}></div>
              </div>

              ): null}

              {lis.statusProgress === "Timbrar" ? (
              
                <div className={styles.pp_contenedorBarras}>
                  <div className={styles.pc_circuloAmarillo}>
                    <div className={styles.pd_iconoCompleto}></div>
                  </div>
                  <div className={styles.pc_lineaSeguimientoPrincipalCompleto} ></div>
                  <div className={styles.pc_circuloAmarillo}>
                    <div className={styles.pd_iconoCompleto}></div>
                  </div>
                  <div className={styles.pc_lineaSeguimientoPrincipalCompleto}></div>
                  <div className={styles.pc_circuloAmarillo}>
                  <div className={styles.pc_circuloBlanco}></div>
                  </div>
                  <div className={styles.pc_lineaSeguimientoPrincipal}></div>
                  <div className={styles.pc_circuloGris}></div>
                </div>

              ): null}
            </div>

            <div className={styles.pp_ColumnaDosLista}>
              <div
                className={`${styles.pp_columnaInterna} ${styles.pp_internaUno}`}
              >
                <div>
                  <span className={styles.pp_textoInterno}>Empleados</span>
                </div>
                <div>
                  <span className={styles.pp_valorInterno}>
                    {lis.employees}
                  </span>
                </div>
              </div>
              <div
                className={`${styles.pp_columnaInterna} ${styles.pp_internaUno}`}
              >
                <div>
                  <span className={styles.pp_textoInterno}>Percepciones</span>
                </div>
                <div>
                  <span className={styles.pp_valorInterno}>
                    {formatter(lis.perception)}
                  </span>
                </div>
              </div>
              <div
                className={`${styles.pp_columnaInterna} ${styles.pp_internaUno}`}
              >
                <div>
                  <span className={styles.pp_textoInterno}>Deducciones</span>
                </div>
                <div>
                  <span className={styles.pp_valorInterno}>
                    {formatter(lis.deduction)}
                  </span>
                </div>
              </div>
              <div
                className={`${styles.pp_columnaInterna} ${styles.pp_internaUno}`}
              >
                <div>
                  <span className={styles.pp_textoInterno}>Total</span>
                </div>
                <div>
                  <span className={styles.pp_valorInterno}>
                    {formatter(lis.total)}
                  </span>
                </div>
              </div>
            </div>
            <div className={styles.pp_ColumnaTresLista}>
              {CheckPermission("Nominas.Eventuales.Ver") && (
                <div>
                  <button
                    className={styles.pp_botonComenzar}
                    onClick={() => {
                      updateState(lis.id);
                      history.push(
                        `/InitEventualPayroll/${lis.id}/${lis.group_name}`
                      );
                    }}
                  >
                    Continuar
                  </button>
                </div>
              )}
              {(CheckPermission("Nominas.Eventuales.Eliminar") && sistemaActivo) && (
                deletePayroll(lis)
              )}
            </div>
          </div>
        ))}
      </div>

      <CrearEventualPayrollsModal
        getDatos={obtenerDatos}
        save={save}
        setSave={setSave}
      />
      <EliminaEventualPayroll getDatos={obtenerDatos} />
    </>
  );
};

export default ActivePayrollTable;
