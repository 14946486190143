import { Avatar,Grid } from "@material-ui/core";
import Box from '@mui/material/Box';
import { useEffect, useState } from "react";
import { useLazyQuery } from '@apollo/client';
import { GET_ALL_NOTICES } from '../../../Querys/querys';
import { LoadingBits } from "../../../alerts/LoadingBits";
import CollaboratorIconText from "../../CollaboratorIcon/CollaboratorIconText";
import '../RigthSideBar.css'

const CurrentNews = ({isOpen}:{isOpen:boolean}) => {
    const [list, setList] = useState<Array<any>>([]);
    const [getNotices,{data:resultNotices}] = useLazyQuery(GET_ALL_NOTICES,{
        fetchPolicy: 'no-cache'
    });
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        if(isOpen === false){
            getNotices();
        }
    },[isOpen]);

    useEffect(() => {
        if(resultNotices && resultNotices.GET_ALL_NOTICES){
            setList( resultNotices.GET_ALL_NOTICES );
            setLoading(false);
        }
    }, [resultNotices]);

    return (
        <Box mt={2}>
            {
                loading ? (
                <div className="divLoadingNotices">
                    <LoadingBits></LoadingBits>
                </div>
                ) : (
                    <>
                        {
                            list.length > 0 && (
                                <>
                                    <Box mr={1} mt={2}>
                                        <Grid direction="row" container item className="Rectangle">
                                            <Grid xs item>
                                                <Box m={2}>
                                                    <h4 className="color">Últimas publicaciones</h4>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    {
                                        list.map((noticia: any, index: number) => (
                                            <div key={index} className="CurrentNews__Component">
                                                <Grid direction="row" container item className="CurrentNew">
                                                    <Grid xs={2} item>
                                                        <Box m={2}>
                                                            {
                                                                noticia.user && noticia.user.img ? (
                                                                    <Avatar src={ noticia.user.img }></Avatar>
                                                                ) : (
                                                                    <CollaboratorIconText
                                                                    Nombre={noticia.user && noticia.user.Fullname && noticia.user.FullName.split(' ')[0] ? noticia.user.FullName.split(' ')[0] : noticia.autor && noticia.autor.split(' ')[0] ? noticia.autor.split(' ')[0] : ''}
                                                                    Apellido={noticia.user && noticia.user.Fullname && noticia.user.FullName.split(' ')[1] ? noticia.user.FullName.split(' ')[1] : noticia.autor && noticia.autor.split(' ')[1] ? noticia.autor.split(' ')[1] : ''}
                                                                    width="33px"
                                                                    height="33px"
                                                                    />
                                                                )
                                                            }
                                                        </Box>
                                                    </Grid>
                                                    <Grid xs item>
                                                        <Box m={2} mb={0}>
                                                            <div className="ItsMyBirthday__person">
                                                                <span>{noticia.tittle}</span>
                                                                <span>@{noticia.user && noticia.user.Fullname ? noticia.user.Fullname : noticia.autor}</span>
                                                            </div>
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                                <Grid direction="row" container item className="CurrentNew" justify="center">
                                                    <div className="ItsMyBirthday__person" style={{paddingBottom: '20px'}}>
                                                        <span className="color" dangerouslySetInnerHTML={{ __html: noticia.Descripcion }}></span>
                                                    </div>
                                                </Grid>
                                                {
                                                    (noticia.Archivo) && (
                                                        <Grid direction="row" container item className="CurrentNew" justify="center">
                                                            <div className="Image__person">
                                                                {
                                                                    !noticia.Archivo.includes('undefined') && (
                                                                        <img src={
                                                                            noticia.Archivo
                                                                        } alt="Noticia Imagen" />
                                                                    )
                                                                }
                                                            </div>
                                                        </Grid>
                                                    )
                                                }
                                            </div>
                                        ))
                                    }
                                </>
                            )
                        }
                    </>
                )
            }
        </Box>
    )
}

export default CurrentNews;