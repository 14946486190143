import { useReducer } from "react"
import ObjectiveContext, { initialState } from "./ObjectiveContext"
import ObjectiveReducer from "./ObjectiveReducer"

const ObjectiveState = (props: any) => {
  const [state, dispatch] = useReducer(ObjectiveReducer, initialState)
  return (
    <ObjectiveContext.Provider value={{ state, dispatch }}>
      {props.children}
    </ObjectiveContext.Provider>
  )
}

export default ObjectiveState