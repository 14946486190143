import { MouseEvent, useContext, useState, useEffect } from 'react';
import { Tooltip,Menu, MenuItem, Grid, IconButton } from '@material-ui/core';
import Box from '@mui/material/Box';
import { INews, News } from '../../../interfaces/News.interfaces';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { NewsContext, Types } from '../../../context/NewContext/NewContext';
import { WarningAlert } from '../../../alerts/WarningAlert';
import { deletetNew } from '../../../services/newService';
import { AdminContext } from '../../../context/AdminContext/AdminContext';
import CheckPermission from '../../../helpers/Administradores/Permissions';
import { DELETE_NOTICES, GET_ALL_NOTICES } from '../../../Querys/querys';
import { useMutation, useQuery } from "@apollo/client";

const NewsOptionsField = (props: News) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const {adminState} = useContext(AdminContext)
    const { dispatch } = useContext(NewsContext);
    const [deleteNotice, {data:responseDelete}] = useMutation(DELETE_NOTICES, {
        refetchQueries: [
            { query: GET_ALL_NOTICES }
        ],
    });

    const handleOpen = (e: MouseEvent<HTMLElement>): void => {
        dispatch({ type: "DELETE_NEW" });
        setAnchorEl(e.currentTarget);
    };

    const handleClose = (): void => setAnchorEl(null);

    const deleteNew = async (): Promise<void> => {
        handleClose();
        let response = await WarningAlert({ text: "¿Deseas eliminar la noticia?", showDenyButton: true, confirmButtonText: "Eliminar" });
        if (response.isConfirmed) {
            await deleteNotice({
                variables:{
                    deleteNoticesId: props.id
                }
            });
        }
    }
    
    useEffect(()=>{
        if(responseDelete){
            dispatch({ type: Types.CLOSE_MODAL });
        }
    }, [responseDelete]);

    const showNew = (): void => {
        handleClose();
        let values = {
            _id: props.id,
            titulo: props.tittle,
            BodyHTML: props.Descripcion,
            Archivo: props.Archivo,
            Autor: props.autor,
            projects: props.projects,
            ImageUri: props.Archivo,
            Fecha: null
        }
        dispatch({ type: Types.OPEN_MODAL_SHOW, payload: { values } });
    }

    const resendNew = (): void => {
        handleClose();
        let values = {
            _id: props.id,
            titulo: props.tittle,
            BodyHTML: props.Descripcion,
            Autor: props.autor,
            Archivo: props.Archivo,
            projects: props.projects,
            ImageUri: props.Archivo,
            Fecha: props.fechaAdd
        }
        dispatch({ type: Types.OPEN_RESEND, payload: { values } });
    }

    return (
        <div>
            <Box className="IconButtonPoints">
                <Tooltip title="Opciones" placement="right">
                    <IconButton onClick={handleOpen} aria-controls="fade-menu" aria-haspopup="true">
                        <MoreVertIcon style={{ color: "#fabb00" }} />
                    </IconButton>
                </Tooltip>
            </Box>
            <Menu
                id="fade-menu"
                anchorEl={anchorEl}
                transformOrigin={{ vertical: "top", horizontal: "right" }}
                keepMounted
                open={open}
                onClose={handleClose}
            >
                {CheckPermission("Noticias.Ver") && (
                    <MenuItem divider onClick={showNew}>
                        Ver&nbsp;
                        <Grid container item justify="flex-end">
                            <img src="assets/svg/icono-ver.svg" alt="Ver" />
                        </Grid>
                    </MenuItem>
                )}
                {CheckPermission("Noticias.Editar") && (
                    <MenuItem divider onClick={resendNew}>
                        Editar&nbsp;
                        <Grid container item justify="flex-end">
                            <img src="assets/svg/icono-editar.svg" alt="Ver" />
                        </Grid>
                     </MenuItem>
                )}
                {CheckPermission("Noticias.Eliminar") && (
                    <MenuItem onClick={deleteNew}>
                        Eliminar&nbsp;
                        <Grid container item justify="flex-end">
                            <img src="assets/svg/icono-eliminar.svg" alt="Eliminar" />
                        </Grid>
                    </MenuItem>
                )}
            </Menu>
        </div>
    )
}

export default NewsOptionsField;
