import {useQuery, useMutation, useLazyQuery} from "@apollo/client";
import styles from '../../styles/Header/headerStyles.module.css';
import MenuListPrincipal from './MenuListPrincipal';
import MenuListSecundario from './MenuListSecundario';
import {GET_NOTIFICATIONS, MARCAR_LEIDAS} from '../../Querys/querys';
import { useEffect, useState, useContext } from "react";
import { AdminContext } from "../../context/AdminContext/AdminContext";
import { LoadingSmallBits } from "../../alerts/LoadingBits";

const Notifications = () =>{
  const [getNotifications,{data:resultNotifications, loading: loadingNotifications}] = useLazyQuery(GET_NOTIFICATIONS,{
    fetchPolicy: 'no-cache'
  });
  const [notifications, setNotifications] = useState([]);
  const [checkRead] = useMutation(MARCAR_LEIDAS,{});
  const {adminState} = useContext(AdminContext);
  const [erased, setErased] = useState(false);

  useEffect(()=>{
    if(adminState && adminState.id){
      getNotifications({
        variables:{
          id: adminState.id
        }
      });
    }
  },[adminState,erased]);

  useEffect(()=>{
    if(resultNotifications && resultNotifications.GET_NOTIFICATIONS && adminState && adminState.id){
      setNotifications(resultNotifications.GET_NOTIFICATIONS);
      checkRead({
        variables:{
          id: adminState.id
        }
      });
    }
  },[resultNotifications, adminState]);

  const toggleList = () => {
    setErased(!erased);
  }

    return(
      <div className={styles.n_contenedor}>
        {
          loadingNotifications ? (
            <div>
              <div className={styles.n_contenedorTitulo}>
                <div className={styles.n_titulo}>
                  Notificaciones
                </div>
                <div className={styles.n_menu}>
                  <MenuListPrincipal toggleList={toggleList}/>
                </div>
              </div>
              <div style={{position:'relative', height:'auto'}}>
                <LoadingSmallBits></LoadingSmallBits>
              </div>
              <div className={styles.n_sinNotificaciones}>
                Cargando notificationes.
              </div>
            </div>
          ) : (
            <>
              {
                notifications.length > 0 ? (
                  <div>
                    <div className={styles.n_contenedorTitulo}>
                      <div className={styles.n_titulo}>
                        Notificaciones
                      </div>
                      <div className={styles.n_menu}>
                        <MenuListPrincipal toggleList={toggleList}/>
                      </div>
                    </div>
                    <div className={styles.n_contenedorPrincipal}>
                      {notifications.map((lis:any)=>(
                        (lis?.tipo === "Correcto")?
                        <div className={styles.n_notificacion}>
                          <div className={`${styles.n_flex} ${styles.n_iconoCorrecto}`}>
                            <div className={styles.n_iconoCorrectoInterno}></div>
                          </div>
                          <div className={styles.n_textoNotificacion}>
                            {lis?.mensaje}
                          </div>
                          <div className={styles.n_menuLista}>
                            <MenuListSecundario idNotificacion={lis?.id} toggleList={toggleList} Desde={lis.Desde} idRegistro={lis.idRegistro} payrollGroup={lis.payrollGroup} eventualGroup={lis.eventualGroup} collaborator={lis.collaborator}/>
                          </div>
                        </div>
                        :
                        (lis?.tipo === "Fallo")?
                        <div className={styles.n_notificacion}>
                        <div className={`${styles.n_flex} ${styles.n_iconoFallo}`}>
                          </div>
                          <div className={styles.n_textoNotificacion}>
                            {lis?.mensaje}  
                          </div>
                          <div className={styles.n_menuLista}>
                            <MenuListSecundario idNotificacion={lis?.id} toggleList={toggleList} Desde={lis.Desde} idRegistro={lis.idRegistro} payrollGroup={lis.payrollGroup} eventualGroup={lis.eventualGroup} collaborator={lis.collaborator}/>
                          </div>
                        </div>
                        :
                        (lis?.tipo === "Calendario")?
                        <div className={styles.n_notificacion}>
                        <div className={`${styles.n_flex} ${styles.n_iconoCalendario}`}>
                          </div>
                          <div className={styles.n_textoNotificacion}>
                            {lis?.mensaje}  
                          </div>
                          <div className={styles.n_menuLista}>
                            <MenuListSecundario idNotificacion={lis?.id} toggleList={toggleList} Desde={lis.Desde} idRegistro={lis.idRegistro} payrollGroup={lis.payrollGroup} eventualGroup={lis.eventualGroup} collaborator={lis.collaborator}/>
                          </div>
                        </div>
                        :
                        (lis?.tipo === "AtencionAzul")?
                        <div className={styles.n_notificacion}>
                          <div className={`${styles.n_flex} ${styles.n_iconoAtencionAzul}`}>
                            <div className={styles.n_iconoAtencionAzulInterno}></div>
                          </div>
                          <div className={styles.n_textoNotificacion}>
                            {lis?.mensaje}
                          </div>
                          <div className={styles.n_menuLista}>
                            <MenuListSecundario 
                              idNotificacion={lis?.id}
                              Desde={lis.Desde}
                              toggleList={toggleList}
                              idRegistro={lis.idRegistro}
                              payrollGroup={lis.payrollGroup} eventualGroup={lis.eventualGroup} collaborator={lis.collaborator}
                              />
                          </div>
                        </div>
                        : 
                        (lis?.tipo === "AtencionAmarillo")?
                        <div className={styles.n_notificacion}>
                        <div className={styles.n_iconoNotificacion}>
                          <div className={styles.n_iconoInternoNotificacion}></div>
                        </div>
                        <div className={styles.n_textoNotificacion}>
                          {lis?.mensaje}
                        </div>
                        <div className={styles.n_menuLista}>
                          <MenuListSecundario idNotificacion={lis?.id} toggleList={toggleList} Desde={lis.Desde} idRegistro={lis.idRegistro} payrollGroup={lis.payrollGroup} eventualGroup={lis.eventualGroup} collaborator={lis.collaborator}/>
                        </div>
                      </div>:
                        null
                      ))}        
                    </div>
                  </div>
                ) : (
                  <div>
                    <div className={styles.n_contenedorTitulo}>
                      <div className={styles.n_titulo}>
                        Notificaciones
                      </div>
                      <div className={styles.n_menu}>
                        <MenuListPrincipal toggleList={toggleList}/>
                      </div>
                    </div>
                    <div className={styles.n_sinNotificaciones}>
                      No tienes noficiaciones pendientes.
                    </div>
                  </div>
                )
              }
            </>
          )
        }
      </div>
    )    
}

export default Notifications