import Box from '@mui/material/Box';
import { useContext, useEffect, useState } from "react";
import ModalContext from "../../context/ModalContext/ModalContext";
import TableBits from "../TableBits/TableBits";
import { openModal } from "../Team/Modals/Modal";
import MenuList from "./Modals/MenuList";
import columns from "./NotificationColumns";
import NotificationField from "./Fields/NotificationField";
import ProjectsField from "./Fields/ProjectsField";
import DateField from "./Fields/DateField";
import SearcherTable from "../Collaborators/CollaboratorTab/SearcherTable";
import AddTable from "../Collaborators/CollaboratorTab/AddTable";
import {AdminContext} from '../../context/AdminContext/AdminContext'
import CheckPermission from "../../helpers/Administradores/Permissions";
import { GET_NOTIFICATIONS_MOBILE } from '../../Querys/querys';
import { useQuery } from "@apollo/client";

const Body = () => {
    const [notificationList, setNotificationList] = useState<any>([]);
    const [notificationListFilter, setNotificationListFilter] = useState<any>([]);
    const {  dispatch } = useContext(ModalContext);
    const {adminState} = useContext(AdminContext)
    const { data: resultNotifications } = useQuery(GET_NOTIFICATIONS_MOBILE);

    useEffect(() => {
        if(resultNotifications && resultNotifications.GET_NOTIFICATIONS_MOBILE && adminState && adminState.Proyectos) {
            const response = resultNotifications.GET_NOTIFICATIONS_MOBILE;
            setNotificationList(response);
            setNotificationListFilter(response);
        }
    },[resultNotifications, adminState]);
    return (
        <Box display="flex" flexDirection="column" p={2}>
            <Box p={1} pb={4} display="flex" flexDirection="row" >
                <Box display="flex" justifyContent="flex-start">
                    <SearcherTable initState={notificationList} setState={setNotificationListFilter} fields={["Titulo"]} label={"Buscar por título"}/>
                </Box>
                <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="flex-end"
                    style={{ width: "100%", }}
                >
                    {CheckPermission("Notificaciones.Crear") && (
                        <AddTable func={() => openModal({ title: "Agregar" }, dispatch)} text="Crear nueva notificación" img="icono-notificaciones.svg" />                   
                   )}
                </Box>
            </Box>
            <TableBits
                columns={columns}
                rows={notificationListFilter}
                components={[DateField, NotificationField, '',ProjectsField]}
                componentOptions={MenuList}
            />
        </Box>
    )
}

export default Body;