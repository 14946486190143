import { useContext, useEffect, useState } from "react";
import CampaignContext from "../../../context/CampaignsContext/CampaignContext";
import { clearCampaignsModal } from "../../../context/CampaignsContext/Actions"
import { Dialog, DialogContent } from "@material-ui/core"
import { Formik, Form } from "formik";
import styles from '../../../components/NewCalendar/Calendars.module.css'
import { useLazyQuery } from "@apollo/client";
import { GET_CAMPAIGNS_BY_DATE, GET_HISTORICAL_CAMPAIGNS_BY_DATE } from "../../../Querys/campaigns/querys";
import { addDays } from 'date-fns';
import { subMonths } from 'date-fns';
import DateRangeField from "../../MyMood/Fields/DateRangeField";
import { GET_CAMPAIGN_BY_TOKEN_AND_DATE } from "../../../Querys/querys";

const FechaRango = ({
  setState,
  setStepFilter,
  historicalCampaigns = false,
  byLink = false,
  campaignId = 0,
  userId = 0,
  token = ""
}: any) => {
  const { state, dispatch } = useContext(CampaignContext)
  const [stateCalendario, setStateCalendario] = useState([
    {
      startDate: subMonths(new Date(), 0),
      endDate: addDays(new Date(), 0),
      key: "selection"
    }]
  );

  const [findByDate] = useLazyQuery(GET_CAMPAIGNS_BY_DATE)
  const [findHistoricalByDate] = useLazyQuery(GET_HISTORICAL_CAMPAIGNS_BY_DATE)
  const [findCampaignByTokenDate] = useLazyQuery(GET_CAMPAIGN_BY_TOKEN_AND_DATE)

  const handleClose = () => {
    clearCampaignsModal(false, dispatch)
  }
  const initialValues = () => {
    return {
      name: ""
    }
  }

  return (
    <Dialog
      open={state.findModalCampaigns}
      aria-labelledby="form-dialog-title" maxWidth="sm" fullWidth={true}>
      <div className={styles.dialogContainer}>
        <div className={styles.containerTitle}>
          <div className={styles.centerContainer}
            style={{
              marginLeft: 'auto',
              marginRight: 'auto'
            }}
          >
            <h2 id="form-dialog-title" data-testid="TitleModal">Seleccione el rango</h2>
          </div>
          <div className={styles.contenedorCerrarModal}>
            <div className={styles.iconoCerrarModal} onClick={(() => handleClose())}></div>
          </div>
        </div>
        <DialogContent>
          <Formik
            initialValues={initialValues()}
            onSubmit={async () => {
              setStepFilter([])
              if (byLink) {
                let { data: campaignFilter } = await findCampaignByTokenDate({
                  variables: {
                    startCampaign: stateCalendario[0].startDate,
                    endCampaign: stateCalendario[0].endDate,
                    campaignId: campaignId,
                    userId: userId,
                    token: token
                  },
                })
                if (campaignFilter && campaignFilter.GET_CAMPAIGN_BY_TOKEN_AND_DATE?.campaigns) {
                  setStepFilter(campaignFilter.GET_CAMPAIGN_BY_TOKEN_AND_DATE?.campaigns)
                }
              } else if (historicalCampaigns) {
                let { data: campaignFilter } = await findHistoricalByDate({
                  variables: {
                    startCampaign: stateCalendario[0].startDate,
                    endCampaign: stateCalendario[0].endDate
                  },
                })
                if (campaignFilter && campaignFilter.GET_HISTORICAL_CAMPAIGNS_BY_DATE) {
                  setStepFilter(campaignFilter.GET_HISTORICAL_CAMPAIGNS_BY_DATE)
                }
              } else {
                let { data: campaignFilter } = await findByDate({
                  variables: {
                    startCampaign: stateCalendario[0].startDate,
                    endCampaign: stateCalendario[0].endDate
                  },
                })
                if (campaignFilter && campaignFilter.GET_CAMPAIGNS_BY_DATE) {
                  setStepFilter(campaignFilter.GET_CAMPAIGNS_BY_DATE)
                }
              }
              handleClose();
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleSubmit,
            }) => (
              <Form onSubmit={handleSubmit}>
                <DateRangeField setDate={setStateCalendario} containerClass={"fieldsetInput"}></DateRangeField>
                <br></br>
                <div className={styles.centerContainer}>
                  <button className={styles.buttonCancelCalendar}
                  type="button" 
                   onClick={() => handleClose()}>
                    <b className={styles.buttonCancelCalendarText}
                      style={{
                        textTransform: "capitalize"
                      }}
                    >Salir</b>
                  </button>
                  <div className={styles.conteinerSave}>
                    <button type="submit" className="buttonSaveRange">
                      <b className={styles.buttonSaveCalendarText}
                        style={{
                          textTransform: "capitalize"
                        }}
                      >Filtrar
                      </b>
                    </button>
                  </div>

                </div>

              </Form>
            )}
          </Formik>
        </DialogContent>
      </div>
    </Dialog>
  )
}

export default FechaRango