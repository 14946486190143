import HistoricoOutsourcersTable from "./HistoricoOutsourcersTable";

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

const HistoricoOutsorcersTab = (props: TabPanelProps) =>{
  const { children, value, index, ...other } = props;
  return(
    <HistoricoOutsourcersTable />
  )
}

export default HistoricoOutsorcersTab