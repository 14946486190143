import { Grid } from '@material-ui/core';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Rating from '@material-ui/lab/Rating';
import "../Prestaciones/css/PrestacionCard.css";
import { MenuPrestacion } from "./MenuListPrestacion";
import GreenSwitch from "../ConfigPayroll/GreenSwitch";
import { GET_ALL_PRESTACIONES,UPDATE_PRESTACION_STATUS } from '../../Querys/prestaciones/querys';
import { useMutation } from "@apollo/client";

export const PrestacionInfoCard = ({prestacion}:any) => {

    const [updateStatus] = useMutation(UPDATE_PRESTACION_STATUS,{
        refetchQueries: [{query: GET_ALL_PRESTACIONES}]
    });  
  
    const updatePrestacionStatus = async (id:any) =>{
       await updateStatus({
          variables:{
              id: id
          }
      });
    }
          
    return (
        <Grid>
                 <Card className='containerCardPrestacion'>                
                    <CardContent className='contedorGeneralPrestacion'>
                           <div className='containerInfoimg'>
                                <div className='containerImagenPrestacion'>
                                     <img src={prestacion.URL} alt="prestacion" className='imgPrestacion' />
                                 </div>
                                 <div className='containertipoPrestacion'>
                                    <span className='tipoPrestacion'>{prestacion.TipoPrestacion}</span>                                
                                </div>
                            </div> 
                            <div className='infoCardPrestacion' >
                              <div className='superiorPrestacion'> 
                                <div className='containertitlePrestacion'>
                                    <span className='titlePrestacionCard'>{prestacion.Titulo}</span> 
                                </div>
                                <div className='switchPrestacion'> 
                                <span className='actPrestacion'>{prestacion.Activa}</span> 
                                <GreenSwitch updatePayrollStatus={updatePrestacionStatus}
                                             id= {prestacion.id} 
                                             status={prestacion.Activa}/>
                                </div>
                                <div className='crudPrestacion'>
                                  <MenuPrestacion 
                                     id={prestacion.id}                                      
                                    />  
                                </div>
                              </div> 
                              <div className='containerDescripcionPrestacion'>
                                    <span className='descripcionPrestacion'>{prestacion.Descripcion}</span> 
                               </div>     
                               { prestacion.promedio>0?   
                                 <div className='star'> 
                                  <Rating name="half-rating-read" defaultValue={prestacion.promedio} precision={0.5}  readOnly />
                                 </div>                                         
                                 :<></>
                                }
                              <div className='countersPrestacion'>                                
                                <div className="containerPrestacion">
                                    <span className='countPrestacion'>{prestacion.countColaboradores}</span> 
                                    <span className='meensajePrestacion'>Colaboradores</span> 
                                </div>  
                                <div className="pv-line"></div>
                                
                                <div className="containerPrestacion">
                                    <span className='countPrestacion'>{prestacion.countRestricciones}</span> 
                                    <span className='meensajePrestacion'>Restricciones</span> 
                                     
                                </div>
                                <div className="pv-line"></div>
                                <div className="containerPrestacion">
                                    <span className='countPrestacion'>{prestacion.countComentarios}</span> 
                                    <span className='meensajePrestacion'>Comentarios</span>                                      
                                </div>    
                                
                              </div>                                         
                            </div>                                       
                    </CardContent>
                </Card>            
        </Grid>
    )
}



