import { useState ,useEffect } from "react";
import Table from '@mui/material/Table';
import Box from '@mui/material/Box';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import "../css/TableDynamic.css";


const SquareReport = ({report}:any) => {
  const [valueReport,setValueReport]=useState([]); 
  
  useEffect(() => {
     const valuesRep= report.map((data:any) => {
       return Object.values(data)      
    })  
    valuesRep.length >0? setValueReport(valuesRep):setValueReport([])
  }, [report]);

  return (
    <>
    <Box className="containerTableDynamic">
    <TableContainer component={Paper}>
    <Table sx={{ minWidth: 650 }} aria-label="caption table" className="squareReport">
      <TableBody>
         {
          valueReport.length >0 ?
           valueReport.map((row:any) => {       
            return(
            <TableRow >
              {
                row.map((cell:any, i:any) => (
                    <TableCell align="left"  className="squareReportCell"> {cell}</TableCell>                
                 ))
              }  
            </TableRow>  
            )            
           }):<></>
          } 
          </TableBody>
    </Table>
    </TableContainer>
    </Box>  
    </>
  )
}

export default SquareReport
