import { useContext, useEffect, useState } from "react";
import { Grid, Tooltip } from "@material-ui/core";
import CollaboratorContext from "../../../../context/CollaboratorContext/CollaboratorContext";
import { withStyles, Theme } from "@material-ui/core/styles";
import EyeIcon from "../../../../assets/svg/eye.svg";
import { useQuery } from "@apollo/client";
import { GET_ALL_FILES } from "../../../../Querys/querys";

const PersonalDocumentsInactivos = () => {
  
  const { state } = useContext(CollaboratorContext);
  const [archives, setArchives] = useState([]);
  const [files, setFiles] = useState([]);
  const { data: resultFiles } = useQuery(GET_ALL_FILES);
  const allFiles = resultFiles?.GET_ALL_FILES;
  const [isThereFiles, setIsThereFiles] = useState(false);

  useEffect(() => {
    if (state.collaborator?.archive) {
      setArchives(state.collaborator.archive);
    }
  }, [state.collaborator]);

  useEffect(() => {
    if (allFiles) {
      setFiles(allFiles);
    }
  }, [allFiles, archives]);

  withStyles((theme: Theme) => ({
    tooltip: {
      backgroundColor: "#f5f5f9",
      color: "rgba(0, 0, 0, 0.87)",
      maxWidth: 1000,
      border: "1px solid #dadde9",
    },
  }))(Tooltip);

  const FilesReady = (file: any, archive: any) => {
    if (isThereFiles === false) setIsThereFiles(true);
    return (
      <div className="flex-container">

        <div className="flex-child">
          <label>{file.name}</label>
          <span className="span-file">
            <a className="view-document" rel="noreferrer" target="_blank" href={archive.URL}>
              <img
                style={{ height: "10px", marginTop: "5px" }}
                src={EyeIcon}
                alt=""
              />
              Ver documento
            </a>
          </span>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="novalidate__border" style={{ width: "fit-content" }}>
        <Grid direction="row" container>
          <Grid xs={12} item>
            <div className="">
              {!isThereFiles && (
                <p>
                  A�n no hay documentos cargados en el expediente de este colaborador.
                </p>
              )}
              {files.length > 0 &&
                files?.map((file: any) =>
                  archives?.map(
                    (archive: any, index: number) =>
                      String(archive?.idTypeArchive) === String(file.id) && FilesReady(file, archive)
                  )
                )}

            </div>
          </Grid>
        </Grid>
      </div>
      <div></div>
    </>
  );
};

export default PersonalDocumentsInactivos;
