import { useContext, useState } from "react";
import CalendarsContext from "../../context/NewCalendarContext/CalendarsContext";
import {updateCalendarsModal, deleteCalendarsModal, createIncidentModal} from '../../context/NewCalendarContext/Actions'
import { IconButton, Menu, MenuItem } from "@material-ui/core";
import Box from '@mui/material/Box';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import AddIcon from '@mui/icons-material/Add';
import styles from './Calendars.module.css'
import CheckPermission from "../../helpers/Administradores/Permissions";

export const MenuCalendar = (props: any) => {
    const {dispatch} = useContext(CalendarsContext)
    const [anchorEl, setAnchorEl] = useState<any>(null);
    const open = Boolean(anchorEl);
    const openMenu = (e: any): void => {
      setAnchorEl(e.currentTarget);
    };
    const updateModal = (id:string, updateModal: boolean) =>{
      setAnchorEl(null);
      updateCalendarsModal(id, updateModal, dispatch)
    }
    const nonWorkingModal = (id:string, createIncidentModalB: boolean) =>{

        setAnchorEl(null);
        createIncidentModal(id, createIncidentModalB, dispatch)
      }
  
    const deleteModal = (id:string, deleteModal: boolean) =>{
      setAnchorEl(null);
      deleteCalendarsModal(id, deleteModal, dispatch)
    }


    const menuDelete = ()=>{
        if(props.showDelete === 1){
            return <MenuItem onClick={() => deleteModal(props._id, true)}>
                <div className={styles.contenedorTextoMenu}>
                    <span className={styles.textoMenuEliminar}>Eliminar calendario</span>
                </div>
                <div>
                    <img className={styles.iconoMenuEliminar} src="/assets/svg/icono-eliminar-admin.svg" alt="Editar" />
                </div>
            </MenuItem>
        }else{
            return <MenuItem 
                style={{
                    opacity: "0.5",
                    cursor: "context-menu"
                }}
            >
                <div className={styles.contenedorTextoMenu}>
                    <span className={styles.textoMenuEliminar}>Eliminar calendario</span>
                </div>
                <div>
                    <img className={styles.iconoMenuEliminar} src="/assets/svg/icono-eliminar-admin.svg" alt="Editar" />
                </div>
            </MenuItem>
        }
    }
  
    return (
        <>

                <Box mr={2} className={styles.menuListNewCalendar}>
                    <IconButton onClick={openMenu}
                    className={styles.menuListOptionNewCalendar}
                    >
                        <MoreVertIcon className={styles.moreOptionNewCalendar} 

                        />
                    </IconButton>
                </Box>

            <Menu
                className="MoreVerIcon"
                anchorEl={anchorEl}
                open={open}
                transformOrigin={{ vertical: "top", horizontal: "right" }}
                onClose={() => setAnchorEl(null)}>
                {CheckPermission("Calendario.AgregarDiaInhabil") && (
                <MenuItem onClick={() => nonWorkingModal(props._id, true)}>
                    <div className={styles.contenedorTextoMenu}>
                        <span className={styles.textoMenuEditar}>Agregar día inhábil</span>
                    </div>
                    <div>
                        <AddIcon className={styles.addIconMenu}/>
                    </div>
                </MenuItem>
                )}
                {/*}<NonWorkingDayTabAllCalendar idCalendar={props._id} />{*/}
                {CheckPermission("Calendario.EditarCalendario") && (
                <MenuItem onClick={() => updateModal(props._id, true)}>
                    <div className={styles.contenedorTextoMenu}>
                        <span className={styles.textoMenuEditar}>Editar calendario</span>
                    </div>
                    <div>
                        <img className={styles.iconoMenu} src="/assets/svg/icono-editar.svg" alt="Editar" />
                    </div>
                </MenuItem>
                )}
                {CheckPermission("Calendario.EliminarCalendario") && ( 
                    
                   
                    menuDelete()
                    
                    
                 )}
            </Menu>
            
        </>
    )
}