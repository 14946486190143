import Box from '@mui/material/Box';
import BodyInactivos from '../components/Collaborator/BodyInactivos';

import '../components/Collaborator/Collaborator.css';

const InactivosCollaboratorScreen = () => {
    return (
        <Box boxShadow={0}>
            <BodyInactivos />
        </Box>
    );
}

export default InactivosCollaboratorScreen;