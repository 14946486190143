import { useMutation } from "@apollo/client";
import { Grid } from "@material-ui/core";
import { ErrorAlert } from "../../../../alerts/errorAlert";
import { SuccessfulAlert } from "../../../../alerts/successAlerts";
import { WarningAlert } from "../../../../alerts/WarningAlert";
import {
  GET_ARCHIVE,
  DELETE_ARCHIVE,
} from "../../../../Querys/querys";
import DefaultImage from "../../../../assets/svg/icono-subir-archivo-azul.svg";
import "./styles/styles.css";

const File_Helper_Incident = ({
  name,
  accept = ".pdf",
  idArchive = "",
  image = DefaultImage,
  setArchives,
  style = "",
}: any) => {

  const [deleteArchive] = useMutation(DELETE_ARCHIVE, {
    refetchQueries: [{ query: GET_ARCHIVE }],
  });

  if (accept === ".jpg" || accept === ".png" || accept === ".jpeg") {
    accept = "image/*";
  }

  const uploadFileIncidentAws = async (e: any): Promise<void> => {
    if (e) {
      setArchives(e);
      await SuccessfulAlert({ text: "Archivo cargado exitosamente" });
    }
  };

  const deleteFile_2 = async (): Promise<void> => {
    let result = await WarningAlert({
      text: "¿Seguro que deseas eliminar el archivo?",
      showDenyButton: true,
    });
    if (result.isConfirmed) {
      if (result) {
        deleteArchive({
          variables: {
            idArchive: idArchive,
          },
        });

        await SuccessfulAlert({ text: "Archivo eliminado exitosamente" });
      } else await ErrorAlert({ text: "Archivo no eliminado" });
    }
  };

  return (
    <Grid>
      {idArchive === "" ? (
        style === "CambiarArchivo" ? (
          <label className="">
            <div className="botonSeleccionarArchivo">
              <div className="iconoSubirArchivos"></div>
              <div>
                <input
                  type="file"
                  name={name}
                  accept={accept}
                  onChange={(e) => uploadFileIncidentAws(e)}
                />
                Cambiar archivo
              </div>
            </div>
          </label>
        ) : style === "SeleccionarArchivo" ? (
          <label className="">
            <div className="botonsubirArchivos">
              <div className="iconoSubirArchivos"></div>
              <div>
                <input
                  type="file"
                  name={name}
                  accept={accept}
                  onChange={(e) => uploadFileIncidentAws(e)}
                />
                Seleccionar archivo
              </div>
            </div>
          </label>
        ) : (
          <label className="">
            <input
              type="file"
              name={name}
              accept={accept}
              onChange={(e) => uploadFileIncidentAws(e)}
            />
            <img src={image} className="image-file"
            style={{ height: "36px"}}
            />
          </label>
        )
      ) : (
        <>
          <span
            className="text-cancel-file-expediente"
            onClick={() => deleteFile_2()}
          >
            -
          </span>
        </>
      )}
    </Grid>
  );
};

export default File_Helper_Incident;
