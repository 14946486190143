import { useContext, useState } from "react";
import PayrollProcessContext from "../../../../context/PayrollProcess/PayrollProcessContext";
import {clearPayrollProcess} from "../../../../context/PayrollProcess/Actions";
import { Dialog, } from "@material-ui/core"
import styles from '../../Modals/CancelTimbre/DataCancelTimbre.module.css'
import { useHistory } from "react-router-dom";

export const DataCancelTimbre = () => {
    const history = useHistory();
    const {state, dispatch} = useContext(PayrollProcessContext)
    const [radio, setRadio] = useState(false)
    const [checkbox, setCheckbox] = useState(false)
    const handleClose = ()=>{
        setRadio(false)
        setCheckbox(false)
        clearPayrollProcess({}, dispatch);
    }

    const openCancelaTimbre = ()=>{
        const radio1 = radio? 1: 0
        const checkbox1 = checkbox? 1: 0
        
        if (String(state?.typeCancelTimbre) === 'Nomina' || String(state?.typeCancelTimbre) === 'Periodica') {
            history.push(`/PayrollCancelTimbrar/${state._id}/${radio1}/${checkbox1}`)
            clearPayrollProcess({}, dispatch);
        }

        if (String(state?.typeCancelTimbre) === 'Eventual') {
            history.push(`/PayrollCancelTimbrarEventual/${state._id}/${radio1}/${checkbox1}`)
            clearPayrollProcess({}, dispatch);
        }

        if (String(state?.typeCancelTimbre) === 'Finiquito') {
            history.push(`/PayrollCancelTimbrarSettlement/${state._id}/${radio1}/${checkbox1}`)
            clearPayrollProcess({}, dispatch);
        }
        
    }

    return(
    <Dialog open={state.showDataCancelTimbre} aria-labelledby="form-dialog-title" maxWidth="xs" fullWidth={true}>
        <div className={styles.dialogContainer}>
            <div className={styles.centerContainer}>
                <h2 id="form-dialog-title" data-testid="TitleModal">Cancelar timbrado</h2>
            </div>
            <div className={styles.divRadio}>
                <div className={styles.radioitem}>
                    <input
                    type="radio"
                    name="radio"
                    id="radio"
                    checked={radio}
                    onChange={(e) => setRadio(!radio)}
                    />
                    <label htmlFor="radio">Cancelar el timbrado de todos los colaboradores</label>
                </div>
                <div className={styles.radioitem}>
                    <input
                    type="radio"
                    name="radio"
                    id="radio2"
                    checked={!radio}
                    onChange={(e) => setRadio(!radio)}
                    />
                    <label htmlFor="radio2">Elegir colaboradores para cancelar timbrado </label>
                </div>
            </div>
            <div className={styles.divRadio}>
                <div className={styles.titleNotificar}>
                    <p>Notificar</p>
                </div>
                <div className={styles.checkboxitem}>
                    <input
                    id="checkbox"
                    type="checkbox"
                    value="Selecionado"
                    checked={checkbox}
                    onChange={(e) =>{
                        setCheckbox(!checkbox)
                    } }
                    ></input>
                    <label htmlFor="checkbox">Enviar correo de notificación a colaboradores</label>
                </div>
            </div>
            <div className={styles.contenedorBotones}>
                <button className={styles.botonCancelar}  onClick={handleClose}>
                Cancelar
                </button>
                <button 
                className={styles.botonSiguiente}
                onClick={(e) => openCancelaTimbre()}
                >
                <div>
                Continuar 
                </div>
                </button>
            </div>
        </div>
    </Dialog>
  )
}
