import { createContext, Dispatch } from 'react';

type initialStateType = {
  _id: string,
  createModalReports: boolean,
  findModalReports: boolean,
  deleteModalReports: boolean,
  report: any
  editModalReports: boolean,
  previewData: any,
}

type ModalPartial = {
  state: initialStateType,
  dispatch: Dispatch<any>
}

export const initialState = {
  _id: '',
  createModalReports: false,
  findModalReports: false,
  editModalReports: false,
  deleteModalReports: false,
  report: null,
  previewData: null,
}

export enum Types {
  CREATE_REPORTS = "CREATE_REPORTS",
  EDIT_REPORTS = "EDIT_REPORTS",
  CLEAR_REPORTS = "CLEAR_REPORTS",
  DELETE_REPORTS = "DELETE_REPORTS",
  FIND_DATE_REPORTS = "FIND_DATE_CAMPAIGNS",
  CLOSE_CREATE_REPORTS = "CLOSE_CREATE_REPORTS",
  PREVIEW_DATA = "PREVIEW_DATA",
}

const ModalInitialState = {
  state: initialState,
  dispatch: () => null
}

const ReportContext = createContext<ModalPartial>(ModalInitialState)

export default ReportContext; 
