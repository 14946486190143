import Box from '@mui/material/Box';
import CollaboratorIconText from '../../CollaboratorIcon/CollaboratorIconText';
import CollaboratorIconIMG from '../../CollaboratorIcon/CollaboratorIconIMG';
const UserField = (props: any) => {

    return (
        <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
        >
            <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                {props.idUsuario?.ImagenUsuario === undefined || props.idUsuario?.ImagenUsuario === '' || props.idUsuario?.ImagenUsuario === null ? (
                    <CollaboratorIconText
                    Nombre={props.idUsuario?.name}
                    Apellido={props.idUsuario?.lastName}
                    width="33px"
                    height="33px"
                    />
                ) : (
                        
                    <CollaboratorIconIMG
                    ImagenUsuario={props.idUsuario?.ImagenUsuario}
                    width="33px"
                    height="33px"
                    widthImg="31px"
                    heightImg="31px"

                    />
                )}

            </Box>

            <Box
                display="flex"
                flexDirection="column"
                justifyContent="flex-start"
                className="boxData__userField"
            >
                <label className="name__userField">{props.idUsuario.name ?  `${props.idUsuario.name} ${props.idUsuario.firstName} ${props.idUsuario.lastName}` : props.idUsuario}</label>
            </Box>
        </Box>
    )
}

export default UserField;