import { useState, useEffect, ChangeEvent } from 'react';
import Box from '@mui/material/Box';
import { Grid } from '@material-ui/core';
import Schedule2 from '../components/Schedule/Schedule2';
import Clock from '../components/Schedule/Clock';
import {
    GET_CALENDAR
} from "../Querys/querys";
import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import CustomTabs from '../components/Collaborators/Tab/CustomTabs';
import CustomTab from '../components/Collaborators/Tab/CustomTabMain';

const ScheduleScreenScreenBase = () => {
    const { id } = useParams<any>()
    const [filtrado, setFiltrado] = useState([])
    const resultSchedule = useQuery(GET_CALENDAR, {
        variables: { id: id },
    });

    const [tab, setTab] = useState(0);
    const handleChange = (event: ChangeEvent<{}>, newValue: number) => {
        setTab(newValue)
        localStorage.setItem('currentPill', String(newValue));
    };

    useEffect(() => {
        if(resultSchedule.data?.GET_CALENDAR && resultSchedule.data?.GET_CALENDAR && resultSchedule.data?.GET_CALENDAR){
            const allSchedule = resultSchedule.data?.GET_CALENDAR;
            setFiltrado(allSchedule)
        }
    }, [resultSchedule]);

    return (
        <div>
            <Box mt={3} ml={5} className="Title">
                <Grid container direction="row">
                    <Grid xs={8}>Calendarios</Grid>
                </Grid>
                <Grid container direction="row">
                    <Grid xs={12}>
                    <CustomTabs
                        value={tab}
                        onChange={handleChange}
                        aria-label="simple tabs example"
                    > 
                        <CustomTab label="Eventos" value={0}/>
                        <CustomTab label="Registros de tiempos" value={1}/>
                    </CustomTabs>
                    </Grid>
                </Grid>
                
            </Box>

            {
                tab === 0 && (
                    <div>
                        {filtrado?.map((Schedule:any, index:any) =>(
                            <Schedule2 idCalendar={id} nameCalendar={Schedule.name} />
                        ))}
                    </div>
                )
            }
            {
                tab === 1 && (
                    <div>
                        {filtrado?.map((Schedule:any, index:any) =>(
                            <Clock idCalendar={id} nameCalendar={Schedule.name} />
                        ))}
                    </div>
                )
            }

        </div>

    )

}

export default ScheduleScreenScreenBase