import {
    Grid
} from '@material-ui/core';
import Box from '@mui/material/Box';
import { styleFind as style } from '../css/styleSheet';
import { Dispatch, SetStateAction } from 'react';

interface Props<T> {
    initState: Array<T>;
    setState: Dispatch<SetStateAction<T[]>> | ((a: T[]) => void);
    stateInitial: Array<T>;
    setStepFilter:Dispatch<SetStateAction<T[]>> | ((a: T[]) => void);
    stepCampaignFilter: Array<T>;    
    label?: string;
    fields?: Array<string>;
    width?: number,   
}

const SearcherCard = <T extends Object>({
    initState,
    setState,
    stateInitial,
    setStepFilter,
    stepCampaignFilter,
    
    label = "Buscar",   
    width = 420,    
}: Props<T>) => {

    const handleChange = (e: any) => {          
    const array=[...initState]    
    if (e !== '') {           
            const nuevoFiltrado =array.filter((lis: any) => lis.nameCampaign.toUpperCase().includes(e.toUpperCase()))
            // setState(nuevoFiltrado)         
            setStepFilter(nuevoFiltrado)              
        }else{
            setStepFilter(stepCampaignFilter);   
       }               
    };      

    return (
        <Grid item>
            <Box >
                <Grid
                    container
                    direction="row"
                    style={{...style.containerSearcher, width: width}}
                >
                    <Grid item >
                        <input
                            style={{...style.inputSearcher, width: (width - 100)}}
                            className="collaboratorTab__searcher"
                            placeholder={label}
                            onChange={(e) => handleChange(e.target.value)}

                        />
                    </Grid>

                    <Grid item style={{
                        width: "20px",
                        padding: "0 20px 0 40px",
                        color: "#7f7f81",
                        fontStyle: "bold",
                    }}>
                        <img
                            src="/assets/icons/icono-buscar.svg"
                            alt="Buscar"
                        />
                    </Grid>

                </Grid>
            </Box>
        </Grid>

    )
};

export default SearcherCard
