import PayrollState from "../../context/PayrollContext/PayrollState";
import { NuevaNomina } from "./NuevaNomina";
import ActivePayrollTable from "./ActivePayrollTable";
import { useQuery } from "@apollo/client";
import { GET_ALL_PAYROLL } from "../../Querys/querys";
import "../Payroll/Payroll.css";

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
  sistemaActivo: boolean;
}

const PayrollTab = (props: TabPanelProps) => {
  const resultPayroll = useQuery(GET_ALL_PAYROLL);
  const allPayroll = resultPayroll.data?.GET_ALL_PAYROLL;

    return (
      <>
        {
          (!allPayroll || allPayroll.length === 0) ? (
            <PayrollState>
                <NuevaNomina {...props} />
            </PayrollState>
          ) : (
            <PayrollState>
              <ActivePayrollTable {...props} />
            </PayrollState>
          )
       }
      </>
    );
};

export default PayrollTab;
