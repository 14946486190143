import { Dispatch } from "react";
import {Types} from "./CollaboratorContext";

export const addSalaryHistory = (
                                    userAddSalaryHistory = "", 
                                    grossSalaryHistory = "", 
                                    DSHistory = "",
                                    recordableSalaryHistory = "",
                                    SDGHistory = "",
                                    ISDHistory = "",
                                    tipoEsquemaHistory = "",
                                    formDataHistory = {},
                                    dispatch: Dispatch<any>
                                ):void =>{
                                        
    dispatch({
        type: Types.ADD_SALARYHISTORY,
        payload: {
            userAddSalaryHistory,
            grossSalaryHistory,
            DSHistory, 
            recordableSalaryHistory,
            SDGHistory,
            ISDHistory,
            tipoEsquemaHistory,
            formDataHistory
        }
    })

}

export const clearSalaryHistory = (dispatch: Dispatch<any>):void =>{
    
    dispatch({
        type: Types.CLEAR_SALARYHISTORY,
    })
}
