import { useContext, useEffect, useState } from "react";
import AdministratrosContext from "../../../context/AdministratorsContext/AdministratorsContext";
import {
  clearAdministratorsModal,
} from "../../../context/AdministratorsContext/Actions";
import { Dialog, DialogContent } from "@material-ui/core";
import Switch from "../Switch";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import BpCheckbox from "../../Admin/AdministratorPermissions/BpCheckbox";

import styles from "../Administrators.module.css";
import { useLazyQuery } from "@apollo/client";
import { GET_PERMISSIONS_USER } from "../../../Querys/querys";
import PermissionAccordion from "./permissionAccordion";

export const UpdateAdministrators = () => {
  const { state, dispatch } = useContext(AdministratrosContext);
  const [tablaDos, setTablaDos] = useState(true);
  const [tablaTres, setTablaTres] = useState(true);
  const [tablaCinco, setTablaCinco] = useState(true);
  const [tablaSeis, setTablaSeis] = useState(true);
  const [tablaSiete, setTablaSiete] = useState(true);
  const [tablaOcho, setTablaOcho] = useState(true);
  const [tablaNueve, setTablaNueve] = useState(true);
  const [tablaDiez, setTablaDiez] = useState(true);
  const [tablaDoce, setTablaDoce] = useState(true);
  const [tablaTrece, setTablaTrece] = useState(true);
  const [tablaCatorce, setTablaCatorce] = useState(true);
  const [tablaQuince, setTablaQuince] = useState(true);
  const [tablaDieciseis, setTablaDieciseis] = useState(true);
  const [tablaDiecisiete, setTablaDiecisiete] = useState(true);
  const [tablaDieciocho, setTablaDieciocho] = useState(true);
  const [tablaVeinte, setTablaVeinte] = useState(true);
  const [tablaVeintiuno, setTablaVeintiuno] = useState(true);
  const [tablaVeintidos, setTablaVeintidos] = useState(true);
  const [tablaVeintitres, setTablaVeintitres] = useState(true);
  const [tablaVeinticuatro, setTablaVeinticuatro] = useState(true);
  const [tablaVeintiseis, setTablaVeintiseis] = useState(true);
  const [tablaVeintisiete, setTablaVeintisiete] = useState(true);
  const [tablaVeintiocho, setTablaVeintiocho] = useState(true);
  const [tablaVeintinueve, setTablaVeintinueve] = useState(true);
  const [tablaTreinta, setTablaTreinta] = useState(true);
  const [tablaTreintaiuno, setTablaTreintaiuno] = useState(true);
  const [tablaTreintaidos, setTablaTreintaidos] = useState(true);
  const [tablaTreintaitres, setTablaTreintaitres] = useState(true);
  const [tablaTreintaicuatro, setTablaTreintaicuatro] = useState(true);
  const [tablaTreintaicinco, setTablaTreintaicinco] = useState(true);
  const [tablaTreintaiseis, setTablaTreintaiseis] = useState(true);
  const [tablaTreintaisiete, setTablaTreintaisiete] = useState(true);
  const [tablaTreintaiocho, setTablaTreintaiocho] = useState(true);
  const [tablaTreintainueve, setTablaTreintainueve] = useState(true);
  const [tablaCuarenta, setTablaCuarenta] = useState(true);
  const [tablaCuarentaiuno, setTablaCuarentaiuno] = useState(true);
  const [tablaCuarentaidos, setTablaCuarentaidos] = useState(true);
  const [tablaCuarentaitres, setTablaCuarentaitres] = useState(true);
  const [tablaCuarentaicuatro, setTablaCuarentaicuatro] = useState(true);

  const [getPermissions, { loading, data: permisos1 }] = useLazyQuery(
    GET_PERMISSIONS_USER,
    {
      variables: {
        getUsersId: state?._id,
      },
    }
  );
  const permisos = permisos1?.GET_PERMISSIONS_USER;

  useEffect(() => {
    if (Number(state._id)) {
      getPermissions();
    }
  }, [state?._id,getPermissions]);

  const handleClose = () => {
    clearAdministratorsModal({}, dispatch);
  };

  const handleConfirmPermission = (value: string) => {
    if (permisos) {
      const findPermisos = permisos.find((permiso: any) => {
        return permiso.name === value;
      });
      return findPermisos?.status;
    }
  };

  if (loading) return null;
  return (
    <>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={state.updateModal}
        fullWidth={false}
        maxWidth={"md"}
      >
        <DialogContent className={styles.contenedorUpdate}>
          <div className={styles.contenedorSuperior}>
            <div className={styles.columnaSuperiorUno}>
              <div className={styles.contenedorColumnaUnoUpdate}>
                <div>
                  <img
                    className={styles.iconoUpdate}
                    src={state?.img && state?.img !== "" ? state?.img : "/assets/svg/user-avatar.svg"}
                    alt="img"
                  />
                </div>
                <div className={styles.contenedorDatosUpdate}>
                  <div>
                    <span className={styles.tituloDatosUpdate}>
                      Administrador
                    </span>
                  </div>
                  <div>
                    <span className={styles.tituloNombreUpdate}>
                      {state?.FullName}
                    </span>
                  </div>
                </div>
              </div>
              <div className={styles.contenedorInputsUpdate}>
                <fieldset className={styles.fieldsetUpdate}>
                  <legend className={styles.textoFieldsetUpdate}>Nombre</legend>
                  <input
                    type="text"
                    className={styles.inputUpdate}
                    value={state?.FullName}
                    disabled={true}
                  />
                </fieldset>
              </div>
              <div>
                <fieldset className={styles.fieldsetUpdate}>
                  <legend className={styles.textoFieldsetUpdate}>Correo</legend>
                  <input
                    type="text"
                    className={styles.inputUpdate}
                    value={state?.Usuario}
                    disabled={true}
                  />
                </fieldset>
              </div>
            </div>
            <div className={styles.columnaSuperiorDos}>
              {/* <div>
                <span className={styles.tituloDatosUpdate}>
                  Proyectos que administra
                </span>
              </div>
              <div>
                <input type="text" className={styles.selectProyectos} />
              </div> */}
            </div>

            <div>
              <div className={styles.contenedorCerrarModal}>
                <div className={styles.iconoCerrarModal} onClick={(() => handleClose())}></div>
              </div>
            </div>

          </div>

          <div className={styles.contenedorPermisos}>
            <div className={`${styles.tablaPermisos} ${styles.centrado}`}>
              <div className={styles.contenedorAcordion}>
                <Accordion
                  className={
                    tablaDos === true
                      ? `${styles.acordionBase} `
                      : `${styles.acordionBase}`
                  }
                >
                  <div className={styles.contenedorTitulosAccordion}>
                    <div>
                      <AccordionSummary onClick={() => setTablaDos(!tablaDos)}>
                        <div className={`${styles.contenedorIconoExpande}`}>
                          {tablaDos === true ? (
                            <img
                              className={`${styles.iconoExpande}`}
                              src={`/assets/svg/icono-expande-arriba.svg`}
                              alt="imagen"
                            />
                          ) : (
                            <img
                              className={`${styles.iconoExpande} ${styles.iconoAbajo}`}
                              src={`/assets/svg/icono-expande.svg`}
                              alt="imagen"
                            />
                          )}
                        </div>
                      </AccordionSummary>
                    </div>
                    <div className={styles.titulosAccordion}>
                      <span className={styles.tituloAccordion}>
                        Colaboradores
                      </span>
                    </div>
                    <div className={styles.contenedorSwitch}>
                      <Switch
                        NombreCampo={"Sidebar.Colaboradores"}
                        Value={() =>
                          handleConfirmPermission("Sidebar.Colaboradores")
                        }
                      />
                    </div>
                  </div>
                  {/* Inicia acordeon Nuevo ingreso */}
                  <Accordion
                    className={
                      tablaTres === true
                        ? `${styles.acordionBase} $ ${styles.BackgrounNivelDos} `
                        : `${styles.acordionBase} $ ${styles.BackgrounNivelDos}`
                    }
                  >
                    <div
                      className={`${styles.contenedorTitulosAccordion} ${styles.nivelDosMenu}`}
                    >
                      <div>
                        <AccordionSummary
                          onClick={() => setTablaTres(!tablaTres)}
                        >
                          <div className={`${styles.contenedorIconoExpande}`}>
                            {tablaTres === true ? (
                              <img
                                className={`${styles.iconoExpande}`}
                                src={`/assets/svg/icono-expande-arriba.svg`}
                                alt="imagen"
                              />
                            ) : (
                              <img
                                className={`${styles.iconoExpande} ${styles.iconoAbajo}`}
                                src={`/assets/svg/icono-expande.svg`}
                                alt="imagen"
                              />
                            )}
                          </div>
                        </AccordionSummary>
                      </div>
                      <div className={styles.titulosAccordion}>
                        <span className={styles.tituloAccordion}>
                          Nuevo ingreso
                        </span>
                      </div>
                      <div className={styles.contenedorSwitch}>
                        <Switch
                          NombreCampo={"Colaboradores.NuevoIngreso"}
                          Value={() =>
                            handleConfirmPermission(
                              "Colaboradores.NuevoIngreso"
                            )
                          }
                        />
                      </div>
                    </div>

                    <AccordionDetails style={{ padding: "8px 16px 0px" }}>
                      <div
                        className={`${styles.filaChecks} ${styles.nivelDos}`}
                      >
                        <div
                          className={`${styles.contenedorChecks}  ${styles.nivelTresChecks}`}
                        >
                          <div className={styles.contenedorPrueba}>
                            <span className={styles.subTituloAccordion}>
                              Acciones
                            </span>
                          </div>
                          <div className={styles.contenedorPrueba}>
                            <BpCheckbox
                              NombreCampo={"Colaboradores.NuevoIngreso.Agregar"}
                              Value={() =>
                                handleConfirmPermission(
                                  "Colaboradores.NuevoIngreso.Agregar"
                                )
                              }
                            />
                            <span className={styles.subTituloAccordion2}>
                              Agregar
                            </span>
                            <BpCheckbox
                              NombreCampo={"Colaboradores.NuevoIngreso.Ver"}
                              Value={() =>
                                handleConfirmPermission(
                                  "Colaboradores.NuevoIngreso.Ver"
                                )
                              }
                            />
                            <span className={styles.subTituloAccordion2}>
                              Ver
                            </span>
                            <BpCheckbox
                              NombreCampo={
                                "Colaboradores.NuevoIngreso.Convertir"
                              }
                              Value={() =>
                                handleConfirmPermission(
                                  "Colaboradores.NuevoIngreso.Convertir"
                                )
                              }
                            />
                            <span className={styles.subTituloAccordion2}>
                              Dar de alta
                            </span>
                            <BpCheckbox
                              NombreCampo={
                                "Colaboradores.NuevoIngreso.Declinar"
                              }
                              Value={() =>
                                handleConfirmPermission(
                                  "Colaboradores.NuevoIngreso.Declinar"
                                )
                              }
                            />
                            <span className={styles.subTituloAccordion2}>
                              Declinar
                            </span>
                          </div>
                        </div>
                      </div>
                    </AccordionDetails>
                    {/* Inicia acordion Menús NuevoIngreso */}

                    {/* Inicia  acordion informacion Alta/ menus*/}
                    <Accordion
                      className={
                        tablaCinco === true
                          ? `${styles.acordionBase} `
                          : `${styles.acordionBase}`
                      }
                    >
                      <div
                        className={`${styles.contenedorTitulosAccordion} ${styles.NivelCuatroMenu}`}
                      >
                        <div>
                          <AccordionSummary
                            onClick={() => setTablaCinco(!tablaCinco)}
                          >
                            <div className={`${styles.contenedorIconoExpande}`}>
                              {tablaCinco === true ? (
                                <img
                                  className={`${styles.iconoExpande}`}
                                  src={`/assets/svg/icono-expande-arriba.svg`}
                                  alt="imagen"
                                />
                              ) : (
                                <img
                                  className={`${styles.iconoExpande} ${styles.iconoAbajo}`}
                                  src={`/assets/svg/icono-expande.svg`}
                                  alt="imagen"
                                />
                              )}
                            </div>
                          </AccordionSummary>
                        </div>
                        <div className={styles.titulosAccordion}>
                          <span className={styles.tituloAccordion}>
                            Información de alta
                          </span>
                        </div>
                        <div className={styles.contenedorSwitch}>
                          <Switch
                            NombreCampo={
                              "Colaboradores.NuevoIngreso.Ver.InformacionAlta"
                            }
                            Value={() =>
                              handleConfirmPermission(
                                "Colaboradores.NuevoIngreso.Ver.InformacionAlta"
                              )
                            }
                          />
                        </div>
                      </div>
                      <AccordionDetails style={{ padding: "8px 16px 0px" }}>
                        <div
                          className={`${styles.filaChecks} ${styles.nivelDos}`}
                        >
                          <div
                            className={`${styles.contenedorChecks} ${styles.nivelCuatroChecks}`}
                          >
                            <div className={styles.contenedorPrueba}>
                              <span className={styles.subTituloAccordion}>
                                Información personal
                              </span>
                            </div>
                            <div className={styles.contenedorPrueba}>
                              <BpCheckbox
                                NombreCampo={
                                  "Colaboradores.NuevoIngreso.Ver.InformacionAlta.InformacionPersonal"
                                }
                                Value={() =>
                                  handleConfirmPermission(
                                    "Colaboradores.NuevoIngreso.Ver.InformacionAlta.InformacionPersonal"
                                  )
                                }
                              />
                              <span className={styles.subTituloAccordion}>
                                Ver
                              </span>
                              <BpCheckbox
                                NombreCampo={
                                  "Colaboradores.NuevoIngreso.Ver.InformacionAlta.InformacionPersonal.Guardar"
                                }
                                Value={() =>
                                  handleConfirmPermission(
                                    "Colaboradores.NuevoIngreso.Ver.InformacionAlta.InformacionPersonal.Guardar"
                                  )
                                }
                              />
                              <span className={styles.subTituloAccordion}>
                                Editar
                              </span>
                            </div>
                          </div>
                        </div>
                      </AccordionDetails>
                      <AccordionDetails style={{ padding: "8px 16px 0px" }}>
                        <div
                          className={`${styles.filaChecks} ${styles.nivelDos}`}
                        >
                          <div
                            className={`${styles.contenedorChecks} ${styles.nivelCuatroChecks}`}
                          >
                            <div className={styles.contenedorPrueba}>
                              <span className={styles.subTituloAccordion}>
                                Datos de ingreso
                              </span>
                            </div>
                            <div className={styles.contenedorPrueba}>
                              <BpCheckbox
                                NombreCampo={
                                  "Colaboradores.NuevoIngreso.Ver.InformacionAlta.DatosIngreso"
                                }
                                Value={() =>
                                  handleConfirmPermission(
                                    "Colaboradores.NuevoIngreso.Ver.InformacionAlta.DatosIngreso"
                                  )
                                }
                              />
                              <span className={styles.subTituloAccordion}>
                                Ver
                              </span>
                              <BpCheckbox
                                NombreCampo={
                                  "Colaboradores.NuevoIngreso.Ver.InformacionAlta.DatosIngreso.Guardar"
                                }
                                Value={() =>
                                  handleConfirmPermission(
                                    "Colaboradores.NuevoIngreso.Ver.InformacionAlta.DatosIngreso.Guardar"
                                  )
                                }
                              />
                              <span className={styles.subTituloAccordion}>
                                Editar
                              </span>
                            </div>
                          </div>
                        </div>
                      </AccordionDetails>
                      <AccordionDetails style={{ padding: "8px 16px 0px" }}>
                        <div
                          className={`${styles.filaChecks} ${styles.nivelDos}`}
                        >
                          <div
                            className={`${styles.contenedorChecks} ${styles.nivelCuatroChecks}`}
                          >
                            <div className={styles.contenedorPrueba}>
                              <span className={styles.subTituloAccordion}>
                                Datos de contratación
                              </span>
                            </div>
                            <div className={styles.contenedorPrueba}>
                              <BpCheckbox
                                NombreCampo={
                                  "Colaboradores.NuevoIngreso.Ver.InformacionAlta.DatosContratacion"
                                }
                                Value={() =>
                                  handleConfirmPermission(
                                    "Colaboradores.NuevoIngreso.Ver.InformacionAlta.DatosContratacion"
                                  )
                                }
                              />
                              <span className={styles.subTituloAccordion}>
                                Ver
                              </span>
                              <BpCheckbox
                                NombreCampo={
                                  "Colaboradores.NuevoIngreso.Ver.InformacionAlta.DatosContratacion.Guardar"
                                }
                                Value={() =>
                                  handleConfirmPermission(
                                    "Colaboradores.NuevoIngreso.Ver.InformacionAlta.DatosContratacion.Guardar"
                                  )
                                }
                              />
                              <span className={styles.subTituloAccordion}>
                                Editar
                              </span>
                            </div>
                          </div>
                        </div>
                      </AccordionDetails>
                    </Accordion>
                    {/* Finaliza  acordion informacion Alta/ menus*/}
                    {/* Inicia Acordion  Datos Personales / menus*/}
                    <Accordion
                      className={
                        tablaSeis === true
                          ? `${styles.acordionBase} `
                          : `${styles.acordionBase}`
                      }
                    >
                      <div
                        className={`${styles.contenedorTitulosAccordion} ${styles.NivelCuatroMenu}`}
                      >
                        <div>
                          <AccordionSummary
                            onClick={() => setTablaSeis(!tablaSeis)}
                          >
                            <div className={`${styles.contenedorIconoExpande}`}>
                              {tablaSeis === true ? (
                                <img
                                  className={`${styles.iconoExpande}`}
                                  src={`/assets/svg/icono-expande-arriba.svg`}
                                  alt="imagen"
                                />
                              ) : (
                                <img
                                  className={`${styles.iconoExpande} ${styles.iconoAbajo}`}
                                  src={`/assets/svg/icono-expande.svg`}
                                  alt="imagen"
                                />
                              )}
                            </div>
                          </AccordionSummary>
                        </div>
                        <div className={styles.titulosAccordion}>
                          <span className={styles.tituloAccordion}>
                            Datos personales
                          </span>
                        </div>
                        <div className={styles.contenedorSwitch}>
                          <Switch
                            NombreCampo={
                              "Colaboradores.NuevoIngreso.Ver.DatosPersonales"
                            }
                            Value={() =>
                              handleConfirmPermission(
                                "Colaboradores.NuevoIngreso.Ver.DatosPersonales"
                              )
                            }
                          />
                        </div>
                      </div>
                      <AccordionDetails style={{ padding: "8px 16px 0px" }}>
                        <div
                          className={`${styles.filaChecks} ${styles.nivelDos}`}
                        >
                          <div
                            className={`${styles.contenedorChecks} ${styles.nivelCuatroChecks}`}
                          >
                            <div className={styles.contenedorPrueba}>
                              <span className={styles.subTituloAccordion}>
                                Datos del domicilio
                              </span>
                            </div>
                            <div className={styles.contenedorPrueba}>
                              <BpCheckbox
                                NombreCampo={
                                  "Colaboradores.NuevoIngreso.Ver.DatosPersonales.DatosDomicilio"
                                }
                                Value={() =>
                                  handleConfirmPermission(
                                    "Colaboradores.NuevoIngreso.Ver.DatosPersonales.DatosDomicilio"
                                  )
                                }
                              />
                              <span className={styles.subTituloAccordion}>
                                Ver
                              </span>
                              <BpCheckbox
                                NombreCampo={
                                  "Colaboradores.NuevoIngreso.Ver.DatosPersonales.DatosDomicilio.Guardar"
                                }
                                Value={() =>
                                  handleConfirmPermission(
                                    "Colaboradores.NuevoIngreso.Ver.DatosPersonales.DatosDomicilio.Guardar"
                                  )
                                }
                              />
                              <span className={styles.subTituloAccordion}>
                                Editar
                              </span>
                            </div>
                          </div>
                        </div>
                      </AccordionDetails>
                      <AccordionDetails style={{ padding: "8px 16px 0px" }}>
                        <div
                          className={`${styles.filaChecks} ${styles.nivelDos}`}
                        >
                          <div
                            className={`${styles.contenedorChecks} ${styles.nivelCuatroChecks}`}
                          >
                            <div className={styles.contenedorPrueba}>
                              <span className={styles.subTituloAccordion}>
                                Datos personales
                              </span>
                            </div>
                            <div className={styles.contenedorPrueba}>
                              <BpCheckbox
                                NombreCampo={
                                  "Colaboradores.NuevoIngreso.Ver.DatosPersonales.DatosPersonales"
                                }
                                Value={() =>
                                  handleConfirmPermission(
                                    "Colaboradores.NuevoIngreso.Ver.DatosPersonales.DatosPersonales"
                                  )
                                }
                              />
                              <span className={styles.subTituloAccordion}>
                                Ver
                              </span>
                              <BpCheckbox
                                NombreCampo={
                                  "Colaboradores.NuevoIngreso.Ver.DatosPersonales.DatosPersonales.Guardar"
                                }
                                Value={() =>
                                  handleConfirmPermission(
                                    "Colaboradores.NuevoIngreso.Ver.DatosPersonales.DatosPersonales.Guardar"
                                  )
                                }
                              />
                              <span className={styles.subTituloAccordion}>
                                Editar
                              </span>
                            </div>
                          </div>
                        </div>
                      </AccordionDetails>
                      <AccordionDetails style={{ padding: "8px 16px 0px" }}>
                        <div
                          className={`${styles.filaChecks} ${styles.nivelDos}`}
                        >
                          <div
                            className={`${styles.contenedorChecks} ${styles.nivelCuatroChecks}`}
                          >
                            <div className={styles.contenedorPrueba}>
                              <span className={styles.subTituloAccordion}>
                                Datos de identidad
                              </span>
                            </div>
                            <div className={styles.contenedorPrueba}>
                              <BpCheckbox
                                NombreCampo={
                                  "Colaboradores.NuevoIngreso.Ver.DatosPersonales.DatosIdentidad"
                                }
                                Value={() =>
                                  handleConfirmPermission(
                                    "Colaboradores.NuevoIngreso.Ver.DatosPersonales.DatosIdentidad"
                                  )
                                }
                              />
                              <span className={styles.subTituloAccordion}>
                                Ver
                              </span>
                              <BpCheckbox
                                NombreCampo={
                                  "Colaboradores.NuevoIngreso.Ver.DatosPersonales.DatosIdentidad.Guardar"
                                }
                                Value={() =>
                                  handleConfirmPermission(
                                    "Colaboradores.NuevoIngreso.Ver.DatosPersonales.DatosIdentidad.Guardar"
                                  )
                                }
                              />
                              <span className={styles.subTituloAccordion}>
                                Editar
                              </span>
                            </div>
                          </div>
                        </div>
                      </AccordionDetails>
                    </Accordion>
                    {/* Finaliza Acordion  Datos Personales / menus*/}
                    {/* Inicia acordion Salud Emergencias / Menú */}
                    <Accordion
                      className={
                        tablaSiete === true
                          ? `${styles.acordionBase} `
                          : `${styles.acordionBase}`
                      }
                    >
                      <div
                        className={`${styles.contenedorTitulosAccordion} ${styles.NivelCuatroMenu}`}
                      >
                        <div>
                          <AccordionSummary
                            onClick={() => setTablaSiete(!tablaSiete)}
                          >
                            <div className={`${styles.contenedorIconoExpande}`}>
                              {tablaSiete === true ? (
                                <img
                                  className={`${styles.iconoExpande}`}
                                  src={`/assets/svg/icono-expande-arriba.svg`}
                                  alt="imagen"
                                />
                              ) : (
                                <img
                                  className={`${styles.iconoExpande} ${styles.iconoAbajo}`}
                                  src={`/assets/svg/icono-expande.svg`}
                                  alt="imagen"
                                />
                              )}
                            </div>
                          </AccordionSummary>
                        </div>
                        <div className={styles.titulosAccordion}>
                          <span className={styles.tituloAccordion}>
                            Salud y emergencias
                          </span>
                        </div>
                        <div className={styles.contenedorSwitch}>
                          <Switch
                            NombreCampo={
                              "Colaboradores.NuevoIngreso.Ver.SaludEmergencias"
                            }
                            Value={() =>
                              handleConfirmPermission(
                                "Colaboradores.NuevoIngreso.Ver.SaludEmergencias"
                              )
                            }
                          />
                        </div>
                      </div>
                      <AccordionDetails style={{ padding: "8px 16px 0px" }}>
                        <div
                          className={`${styles.filaChecks} ${styles.nivelDos}`}
                        >
                          <div
                            className={`${styles.contenedorChecks} ${styles.nivelCuatroChecks}`}
                          >
                            <div className={styles.contenedorPrueba}>
                              <span className={styles.subTituloAccordion}>
                                Beneficiario
                              </span>
                            </div>
                            <div className={styles.contenedorPrueba}>
                              <BpCheckbox
                                NombreCampo={
                                  "Colaboradores.NuevoIngreso.Ver.SaludEmergencias.Beneficiario"
                                }
                                Value={() =>
                                  handleConfirmPermission(
                                    "Colaboradores.NuevoIngreso.Ver.SaludEmergencias.Beneficiario"
                                  )
                                }
                              />
                              <span className={styles.subTituloAccordion}>
                                Ver
                              </span>
                              <BpCheckbox
                                NombreCampo={
                                  "Colaboradores.NuevoIngreso.Ver.SaludEmergencias.Beneficiario.Guardar"
                                }
                                Value={() =>
                                  handleConfirmPermission(
                                    "Colaboradores.NuevoIngreso.Ver.SaludEmergencias.Beneficiario.Guardar"
                                  )
                                }
                              />
                              <span className={styles.subTituloAccordion}>
                                Editar
                              </span>
                            </div>
                          </div>
                        </div>
                      </AccordionDetails>
                      <AccordionDetails style={{ padding: "8px 16px 0px" }}>
                        <div
                          className={`${styles.filaChecks} ${styles.nivelDos}`}
                        >
                          <div
                            className={`${styles.contenedorChecks} ${styles.nivelCuatroChecks}`}
                          >
                            <div className={styles.contenedorPrueba}>
                              <span className={styles.subTituloAccordion}>
                                Contacto de emergencia
                              </span>
                            </div>
                            <div className={styles.contenedorPrueba}>
                              <BpCheckbox
                                NombreCampo={
                                  "Colaboradores.NuevoIngreso.Ver.SaludEmergencias.ContactoEmergencia"
                                }
                                Value={() =>
                                  handleConfirmPermission(
                                    "Colaboradores.NuevoIngreso.Ver.SaludEmergencias.ContactoEmergencia"
                                  )
                                }
                              />
                              <span className={styles.subTituloAccordion}>
                                Ver
                              </span>
                              <BpCheckbox
                                NombreCampo={
                                  "Colaboradores.NuevoIngreso.Ver.SaludEmergencias.ContactoEmergencias.Guardar"
                                }
                                Value={() =>
                                  handleConfirmPermission(
                                    "Colaboradores.NuevoIngreso.Ver.SaludEmergencias.ContactoEmergencias.Guardar"
                                  )
                                }
                              />
                              <span className={styles.subTituloAccordion}>
                                Editar
                              </span>
                            </div>
                          </div>
                        </div>
                      </AccordionDetails>
                      <AccordionDetails style={{ padding: "8px 16px 0px" }}>
                        <div
                          className={`${styles.filaChecks} ${styles.nivelDos}`}
                        >
                          <div
                            className={`${styles.contenedorChecks} ${styles.nivelCuatroChecks}`}
                          >
                            <div className={styles.contenedorPrueba}>
                              <span className={styles.subTituloAccordion}>
                                Información médica
                              </span>
                            </div>
                            <div className={styles.contenedorPrueba}>
                              <BpCheckbox
                                NombreCampo={
                                  "Colaboradores.NuevoIngreso.Ver.SaludEmergencias.InformacionMedica"
                                }
                                Value={() =>
                                  handleConfirmPermission(
                                    "Colaboradores.NuevoIngreso.Ver.SaludEmergencias.InformacionMedica"
                                  )
                                }
                              />
                              <span className={styles.subTituloAccordion}>
                                Ver
                              </span>
                              <BpCheckbox
                                NombreCampo={
                                  "Colaboradores.NuevoIngreso.Ver.SaludEmergencias.InformacionMedica.Guardar"
                                }
                                Value={() =>
                                  handleConfirmPermission(
                                    "Colaboradores.NuevoIngreso.Ver.SaludEmergencias.InformacionMedica.Guardar"
                                  )
                                }
                              />
                              <span className={styles.subTituloAccordion}>
                                Editar
                              </span>
                            </div>
                          </div>
                        </div>
                      </AccordionDetails>
                    </Accordion>
                    {/* Finaliza acordion Salud Emergencias / Menú */}
                    {/* Inica acordion Datos para pago / Menu */}
                    <Accordion
                      className={
                        tablaOcho === true
                          ? `${styles.acordionBase} `
                          : `${styles.acordionBase}`
                      }
                    >
                      <div
                        className={`${styles.contenedorTitulosAccordion} ${styles.NivelCuatroMenu}`}
                      >
                        <div>
                          <AccordionSummary
                            onClick={() => setTablaOcho(!tablaOcho)}
                          >
                            <div className={`${styles.contenedorIconoExpande}`}>
                              {tablaOcho === true ? (
                                <img
                                  className={`${styles.iconoExpande}`}
                                  src={`/assets/svg/icono-expande-arriba.svg`}
                                  alt="imagen"
                                />
                              ) : (
                                <img
                                  className={`${styles.iconoExpande} ${styles.iconoAbajo}`}
                                  src={`/assets/svg/icono-expande.svg`}
                                  alt="imagen"
                                />
                              )}
                            </div>
                          </AccordionSummary>
                        </div>
                        <div className={styles.titulosAccordion}>
                          <span className={styles.tituloAccordion}>
                            Datos para pago
                          </span>
                        </div>
                        <div className={styles.contenedorSwitch}>
                          <Switch
                            NombreCampo={
                              "Colaboradores.NuevoIngreso.Ver.DatosParaPago"
                            }
                            Value={() =>
                              handleConfirmPermission(
                                "Colaboradores.NuevoIngreso.Ver.DatosParaPago"
                              )
                            }
                          />
                        </div>
                      </div>
                      <AccordionDetails style={{ padding: "8px 16px 0px" }}>
                        <div
                          className={`${styles.filaChecks} ${styles.nivelDos}`}
                        >
                          <div
                            className={`${styles.contenedorChecks} ${styles.nivelCuatroChecks}`}
                          >
                            <div className={styles.contenedorPrueba}>
                              <span className={styles.subTituloAccordion}>
                                Datos bancarios
                              </span>
                            </div>
                            <div className={styles.contenedorPrueba}>
                              <BpCheckbox
                                NombreCampo={
                                  "Colaboradores.NuevoIngreso.Ver.DatosParaPago.DatosBancarios"
                                }
                                Value={() =>
                                  handleConfirmPermission(
                                    "Colaboradores.NuevoIngreso.Ver.DatosParaPago.DatosBancarios"
                                  )
                                }
                              />
                              <span className={styles.subTituloAccordion}>
                                Ver
                              </span>
                              <BpCheckbox
                                NombreCampo={
                                  "Colaboradores.NuevoIngreso.Ver.DatosParaPago.DatosBancarios.Guardar"
                                }
                                Value={() =>
                                  handleConfirmPermission(
                                    "Colaboradores.NuevoIngreso.Ver.DatosParaPago.DatosBancarios.Guardar"
                                  )
                                }
                              />
                              <span className={styles.subTituloAccordion}>
                                Editar
                              </span>
                            </div>
                          </div>
                        </div>
                      </AccordionDetails>
                    </Accordion>
                    {/* Finaliza acordion Datos para pago / Menu */}
                    {/* Inicia el acordion expediente / menu */}
                    <Accordion
                      className={
                        tablaVeintinueve === true
                          ? `${styles.acordionBase} `
                          : `${styles.acordionBase}`
                      }
                    >
                      <div
                        className={`${styles.contenedorTitulosAccordion} ${styles.NivelCuatroMenu}`}
                      >
                        <div>
                          <AccordionSummary
                            onClick={() => setTablaNueve(!tablaNueve)}
                          >
                            <div className={`${styles.contenedorIconoExpande}`}>
                              {tablaNueve === true ? (
                                <img
                                  className={`${styles.iconoExpande}`}
                                  src={`/assets/svg/icono-expande-arriba.svg`}
                                  alt="imagen"
                                />
                              ) : (
                                <img
                                  className={`${styles.iconoExpande} ${styles.iconoAbajo}`}
                                  src={`/assets/svg/icono-expande.svg`}
                                  alt="imagen"
                                />
                              )}
                            </div>
                          </AccordionSummary>
                        </div>
                        <div className={styles.titulosAccordion}>
                          <span className={styles.tituloAccordion}>
                            Expediente
                          </span>
                        </div>
                        <div className={styles.contenedorSwitch}>
                          <Switch
                            NombreCampo={
                              "Colaboradores.NuevoIngreso.Ver.Expediente"
                            }
                            Value={() =>
                              handleConfirmPermission(
                                "Colaboradores.NuevoIngreso.Ver.Expediente"
                              )
                            }
                          />
                        </div>
                      </div>
                      <AccordionDetails style={{ padding: "8px 16px 0px" }}>
                        <div
                          className={`${styles.filaChecks} ${styles.nivelDos}`}
                        >
                          <div
                            className={`${styles.contenedorChecks} ${styles.nivelCuatroChecks}`}
                          >
                            <div className={styles.contenedorPrueba}>
                              <span className={styles.subTituloAccordion}>
                                Documentos personales
                              </span>
                            </div>
                            <div className={styles.contenedorPrueba}>
                              <BpCheckbox
                                NombreCampo={
                                  "Colaboradores.NuevoIngreso.Ver.Expediente.DocumentosPersonales"
                                }
                                Value={() =>
                                  handleConfirmPermission(
                                    "Colaboradores.NuevoIngreso.Ver.Expediente.DocumentosPersonales"
                                  )
                                }
                              />
                              <span className={styles.subTituloAccordion}>
                                Ver
                              </span>
                              <BpCheckbox
                                NombreCampo={
                                  "Colaboradores.NuevoIngreso.Ver.Expediente.DocumentosPeronales.Guardar"
                                }
                                Value={() =>
                                  handleConfirmPermission(
                                    "Colaboradores.NuevoIngreso.Ver.Expediente.DocumentosPeronales.Guardar"
                                  )
                                }
                              />
                              <span className={styles.subTituloAccordion}>
                                Editar
                              </span>
                            </div>
                          </div>
                        </div>
                      </AccordionDetails>
                      <AccordionDetails style={{ padding: "8px 16px 0px" }}>
                        <div
                          className={`${styles.filaChecks} ${styles.nivelDos}`}
                        >
                          <div
                            className={`${styles.contenedorChecks} ${styles.nivelCuatroChecks}`}
                          >
                            <div className={styles.contenedorPrueba}>
                              <span className={styles.subTituloAccordion}>
                                Documentos opcionales
                              </span>
                            </div>
                            <div className={styles.contenedorPrueba}>
                              <BpCheckbox
                                NombreCampo={
                                  "Colaboradores.NuevoIngreso.Ver.Expediente.DocumentosOpcionales"
                                }
                                Value={() =>
                                  handleConfirmPermission(
                                    "Colaboradores.NuevoIngreso.Ver.Expediente.DocumentosOpcionales"
                                  )
                                }
                              />
                              <span className={styles.subTituloAccordion}>
                                Ver
                              </span>
                              <BpCheckbox
                                NombreCampo={
                                  "Colaboradores.NuevoIngreso.Ver.Expediente.DocumentosOpcionales.Guardar"
                                }
                                Value={() =>
                                  handleConfirmPermission(
                                    "Colaboradores.NuevoIngreso.Ver.Expediente.DocumentosOpcionales.Guardar"
                                  )
                                }
                              />
                              <span className={styles.subTituloAccordion}>
                                Editar
                              </span>
                            </div>
                          </div>
                        </div>
                      </AccordionDetails>
                      <AccordionDetails style={{ padding: "8px 16px 0px" }}>
                        <div
                          className={`${styles.filaChecks} ${styles.nivelDos}`}
                        >
                          <div
                            className={`${styles.contenedorChecks} ${styles.nivelCuatroChecks}`}
                          >
                            <div className={styles.contenedorPrueba}>
                              <span className={styles.subTituloAccordion}>
                                Documentos para firma
                              </span>
                            </div>
                            <div className={styles.contenedorPrueba}>
                              <BpCheckbox
                                NombreCampo={
                                  "Colaboradores.NuevoIngreso.Ver.Expediente.OfertaLaboral"
                                }
                                Value={() =>
                                  handleConfirmPermission(
                                    "Colaboradores.NuevoIngreso.Ver.Expediente.OfertaLaboral"
                                  )
                                }
                              />
                              <span className={styles.subTituloAccordion}>
                                Ver
                              </span>
                              <BpCheckbox
                                NombreCampo={
                                  "Colaboradores.NuevoIngreso.Ver.Expediente.OfertaLaboral.Guardar"
                                }
                                Value={() =>
                                  handleConfirmPermission(
                                    "Colaboradores.NuevoIngreso.Ver.Expediente.OfertaLaboral.Guardar"
                                  )
                                }
                              />
                              <span className={styles.subTituloAccordion}>
                                Editar
                              </span>
                            </div>
                          </div>
                        </div>
                      </AccordionDetails>
                    </Accordion>
                    {/* Inicia el acordion expediente / menu */}

                    {/* Inicia acordion Menús NuevoIngreso */}
                  </Accordion>
                  {/* Finaliza acordeon Nuevo ingreso */}
                  {/* Inicia acordeon Colaboradores */}
                  <Accordion
                    className={
                      tablaDiez === true
                        ? `${styles.acordionBase} $ ${styles.BackgrounNivelDos} `
                        : `${styles.acordionBase} $ ${styles.BackgrounNivelDos}`
                    }
                  >
                    <div
                      className={`${styles.contenedorTitulosAccordion} ${styles.nivelDosMenu}`}
                    >
                      <div>
                        <AccordionSummary
                          onClick={() => setTablaDiez(!tablaDiez)}
                        >
                          <div className={`${styles.contenedorIconoExpande}`}>
                            {tablaDiez === true ? (
                              <img
                                className={`${styles.iconoExpande}`}
                                src={`/assets/svg/icono-expande-arriba.svg`}
                                alt="imagen"
                              />
                            ) : (
                              <img
                                className={`${styles.iconoExpande} ${styles.iconoAbajo}`}
                                src={`/assets/svg/icono-expande.svg`}
                                alt="imagen"
                              />
                            )}
                          </div>
                        </AccordionSummary>
                      </div>
                      <div className={styles.titulosAccordion}>
                        <span className={styles.tituloAccordion}>
                          Colaboradores
                        </span>
                      </div>
                      <div className={styles.contenedorSwitch}>
                        <Switch
                          NombreCampo={"Colaboradores.Colaboradores"}
                          Value={() =>
                            handleConfirmPermission(
                              "Colaboradores.Colaboradores"
                            )
                          }
                        />
                      </div>
                    </div>
                    <AccordionDetails style={{ padding: "8px 16px 0px" }}>
                      <div
                        className={`${styles.filaChecks} ${styles.nivelDos}`}
                      >
                        <div
                          className={`${styles.contenedorChecks}  ${styles.nivelTresChecks}`}
                        >
                          <div className={styles.contenedorPrueba}>
                            <span className={styles.subTituloAccordion}>
                              Acciones
                            </span>
                          </div>
                          <div className={styles.contenedorPrueba}>
                            <BpCheckbox
                              NombreCampo={
                                "Colaboradores.Colaboradores.Agregar"
                              }
                              Value={() =>
                                handleConfirmPermission(
                                  "Colaboradores.Colaboradores.Agregar"
                                )
                              }
                            />
                            <span className={styles.subTituloAccordion}>
                              Agregar
                            </span>
                            <BpCheckbox
                              NombreCampo={"Colaboradores.Colaboradores.Editar"}
                              Value={() =>
                                handleConfirmPermission(
                                  "Colaboradores.Colaboradores.Editar"
                                )
                              }
                            />
                            <span className={styles.subTituloAccordion}>
                              Editar
                            </span>
                            <BpCheckbox
                              NombreCampo={
                                "Colaboradores.Colaboradores.Inactivar"
                              }
                              Value={() =>
                                handleConfirmPermission(
                                  "Colaboradores.Colaboradores.Inactivar"
                                )
                              }
                            />
                            <span className={styles.subTituloAccordion}>
                              Dar de baja
                            </span>
                          </div>
                        </div>
                      </div>
                    </AccordionDetails>
                    {/* Inicia acordion Menús  */}

                    {/* Inicia acordion informacion Alta / menu / colaboradores */}
                    <Accordion
                      className={
                        tablaDoce === true
                          ? `${styles.acordionBase} `
                          : `${styles.acordionBase}`
                      }
                    >
                      <div
                        className={`${styles.contenedorTitulosAccordion} ${styles.NivelCuatroMenu}`}
                      >
                        <div>
                          <AccordionSummary
                            onClick={() => setTablaDoce(!tablaDoce)}
                          >
                            <div className={`${styles.contenedorIconoExpande}`}>
                              {tablaDoce === true ? (
                                <img
                                  className={`${styles.iconoExpande}`}
                                  src={`/assets/svg/icono-expande-arriba.svg`}
                                  alt="imagen"
                                />
                              ) : (
                                <img
                                  className={`${styles.iconoExpande} ${styles.iconoAbajo}`}
                                  src={`/assets/svg/icono-expande.svg`}
                                  alt="imagen"
                                />
                              )}
                            </div>
                          </AccordionSummary>
                        </div>
                        <div className={styles.titulosAccordion}>
                          <span className={styles.tituloAccordion}>
                            Información de alta
                          </span>
                        </div>
                        <div className={styles.contenedorSwitch}>
                          <Switch
                            NombreCampo={
                              "Colaboradores.Colaboradores.Editar.InformacionAlta"
                            }
                            Value={() =>
                              handleConfirmPermission(
                                "Colaboradores.Colaboradores.Editar.InformacionAlta"
                              )
                            }
                          />
                        </div>
                      </div>
                      <AccordionDetails style={{ padding: "8px 16px 0px" }}>
                        <div
                          className={`${styles.filaChecks} ${styles.nivelDos}`}
                        >
                          <div
                            className={`${styles.contenedorChecks} ${styles.nivelCuatroChecks}`}
                          >
                            <div className={styles.contenedorPrueba}>
                              <span className={styles.subTituloAccordion}>
                                Información personal
                              </span>
                            </div>
                            <div className={styles.contenedorPrueba}>
                              <BpCheckbox
                                NombreCampo={
                                  "Colaboradores.Colaboradores.Editar.InformacionAlta.InformacionPersonal.Ver"
                                }
                                Value={() =>
                                  handleConfirmPermission(
                                    "Colaboradores.Colaboradores.Editar.InformacionAlta.InformacionPersonal.Ver"
                                  )
                                }
                              />
                              <span className={styles.subTituloAccordion}>
                                Ver
                              </span>
                              <BpCheckbox
                                NombreCampo={
                                  "Colaboradores.Colaboradores.Editar.InformacionAlta.InformacionPersonal.Guardar"
                                }
                                Value={() =>
                                  handleConfirmPermission(
                                    "Colaboradores.Colaboradores.Editar.InformacionAlta.InformacionPersonal.Guardar"
                                  )
                                }
                              />
                              <span className={styles.subTituloAccordion}>
                                Editar
                              </span>
                            </div>
                          </div>
                        </div>
                      </AccordionDetails>
                      <AccordionDetails style={{ padding: "8px 16px 0px" }}>
                        <div
                          className={`${styles.filaChecks} ${styles.nivelDos}`}
                        >
                          <div
                            className={`${styles.contenedorChecks} ${styles.nivelCuatroChecks}`}
                          >
                            <div className={styles.contenedorPrueba}>
                              <span className={styles.subTituloAccordion}>
                                Datos de ingreso
                              </span>
                            </div>
                            <div className={styles.contenedorPrueba}>
                              <BpCheckbox
                                NombreCampo={
                                  "Colaboradores.Colaboradores.Editar.InformacionAlta.DatosIngreso.Ver"
                                }
                                Value={() =>
                                  handleConfirmPermission(
                                    "Colaboradores.Colaboradores.Editar.InformacionAlta.DatosIngreso.Ver"
                                  )
                                }
                              />
                              <span className={styles.subTituloAccordion}>
                                Ver
                              </span>
                              <BpCheckbox
                                NombreCampo={
                                  "Colaboradores.Colaboradores.Editar.InformacionAlta.DatosIngreso.Guardar"
                                }
                                Value={() =>
                                  handleConfirmPermission(
                                    "Colaboradores.Colaboradores.Editar.InformacionAlta.DatosIngreso.Guardar"
                                  )
                                }
                              />
                              <span className={styles.subTituloAccordion}>
                                Editar
                              </span>
                            </div>
                          </div>
                        </div>
                      </AccordionDetails>
                      <AccordionDetails style={{ padding: "8px 16px 0px" }}>
                        <div
                          className={`${styles.filaChecks} ${styles.nivelDos}`}
                        >
                          <div
                            className={`${styles.contenedorChecks} ${styles.nivelCuatroChecks}`}
                          >
                            <div className={styles.contenedorPrueba}>
                              <span className={styles.subTituloAccordion}>
                                Datos de contratación
                              </span>
                            </div>
                            <div className={styles.contenedorPrueba}>
                              <BpCheckbox
                                NombreCampo={
                                  "Colaboradores.Colaboradores.Editar.informcaionAlta.DatosContratacion.Ver"
                                }
                                Value={() =>
                                  handleConfirmPermission(
                                    "Colaboradores.Colaboradores.Editar.informcaionAlta.DatosContratacion.Ver"
                                  )
                                }
                              />
                              <span className={styles.subTituloAccordion}>
                                Ver
                              </span>
                              <BpCheckbox
                                NombreCampo={
                                  "Colaboradores.Colaboradores.Editar.InformacionAlta.DatosContratacion.Guardar"
                                }
                                Value={() =>
                                  handleConfirmPermission(
                                    "Colaboradores.Colaboradores.Editar.InformacionAlta.DatosContratacion.Guardar"
                                  )
                                }
                              />
                              <span className={styles.subTituloAccordion}>
                                Editar
                              </span>
                            </div>
                          </div>
                        </div>
                      </AccordionDetails>
                    </Accordion>
                    {/* Finaliza acordion informacion Alta / menu / colaboradores */}
                    {/* Inicia acordion Datos personales / menu / colaboradores */}
                    <Accordion
                      className={
                        tablaTrece === true
                          ? `${styles.acordionBase} `
                          : `${styles.acordionBase}`
                      }
                    >
                      <div
                        className={`${styles.contenedorTitulosAccordion} ${styles.NivelCuatroMenu}`}
                      >
                        <div>
                          <AccordionSummary
                            onClick={() => setTablaTrece(!tablaTrece)}
                          >
                            <div className={`${styles.contenedorIconoExpande}`}>
                              {tablaTrece === true ? (
                                <img
                                  className={`${styles.iconoExpande}`}
                                  src={`/assets/svg/icono-expande-arriba.svg`}
                                  alt="imagen"
                                />
                              ) : (
                                <img
                                  className={`${styles.iconoExpande} ${styles.iconoAbajo}`}
                                  src={`/assets/svg/icono-expande.svg`}
                                  alt="imagen"
                                />
                              )}
                            </div>
                          </AccordionSummary>
                        </div>
                        <div className={styles.titulosAccordion}>
                          <span className={styles.tituloAccordion}>
                            Datos personales
                          </span>
                        </div>
                        <div className={styles.contenedorSwitch}>
                          <Switch
                            NombreCampo={
                              "Colaboradores.Colaboradores.Editar.DatosPersonales"
                            }
                            Value={() =>
                              handleConfirmPermission(
                                "Colaboradores.Colaboradores.Editar.DatosPersonales"
                              )
                            }
                          />
                        </div>
                      </div>
                      <AccordionDetails style={{ padding: "8px 16px 0px" }}>
                        <div
                          className={`${styles.filaChecks} ${styles.nivelDos}`}
                        >
                          <div
                            className={`${styles.contenedorChecks} ${styles.nivelCuatroChecks}`}
                          >
                            <div className={styles.contenedorPrueba}>
                              <span className={styles.subTituloAccordion}>
                                Datos del domicilio
                              </span>
                            </div>
                            <div className={styles.contenedorPrueba}>
                              <BpCheckbox
                                NombreCampo={
                                  "Colaboradores.Colaboradores.Editar.DatosPersonales.DatosDomicilio.Ver"
                                }
                                Value={() =>
                                  handleConfirmPermission(
                                    "Colaboradores.Colaboradores.Editar.DatosPersonales.DatosDomicilio.Ver"
                                  )
                                }
                              />
                              <span className={styles.subTituloAccordion}>
                                Ver
                              </span>
                              <BpCheckbox
                                NombreCampo={
                                  "Colaboradores.Colaboradores.Editar.DatosPersonales.DatosDomicilio.Guardar"
                                }
                                Value={() =>
                                  handleConfirmPermission(
                                    "Colaboradores.Colaboradores.Editar.DatosPersonales.DatosDomicilio.Guardar"
                                  )
                                }
                              />
                              <span className={styles.subTituloAccordion}>
                                Editar
                              </span>
                            </div>
                          </div>
                        </div>
                      </AccordionDetails>
                      <AccordionDetails style={{ padding: "8px 16px 0px" }}>
                        <div
                          className={`${styles.filaChecks} ${styles.nivelDos}`}
                        >
                          <div
                            className={`${styles.contenedorChecks} ${styles.nivelCuatroChecks}`}
                          >
                            <div className={styles.contenedorPrueba}>
                              <span className={styles.subTituloAccordion}>
                                Datos personales
                              </span>
                            </div>
                            <div className={styles.contenedorPrueba}>
                              <BpCheckbox
                                NombreCampo={
                                  "Colaboradores.Colaboradores.Editar.DatosPersonales.DatosPersonales.Ver"
                                }
                                Value={() =>
                                  handleConfirmPermission(
                                    "Colaboradores.Colaboradores.Editar.DatosPersonales.DatosPersonales.Ver"
                                  )
                                }
                              />
                              <span className={styles.subTituloAccordion}>
                                Ver
                              </span>
                              <BpCheckbox
                                NombreCampo={
                                  "Colaboradores.Colaboradores.Editar.DatosPersonales.DatosPersonales.Guardar"
                                }
                                Value={() =>
                                  handleConfirmPermission(
                                    "Colaboradores.Colaboradores.Editar.DatosPersonales.DatosPersonales.Guardar"
                                  )
                                }
                              />
                              <span className={styles.subTituloAccordion}>
                                Editar
                              </span>
                            </div>
                          </div>
                        </div>
                      </AccordionDetails>
                      <AccordionDetails style={{ padding: "8px 16px 0px" }}>
                        <div
                          className={`${styles.filaChecks} ${styles.nivelDos}`}
                        >
                          <div
                            className={`${styles.contenedorChecks} ${styles.nivelCuatroChecks}`}
                          >
                            <div className={styles.contenedorPrueba}>
                              <span className={styles.subTituloAccordion}>
                                Datos de identidad
                              </span>
                            </div>
                            <div className={styles.contenedorPrueba}>
                              <BpCheckbox
                                NombreCampo={
                                  "Colaboradores.Colaboradores.Editar.DatosPersonales.DatosIdentidad.Ver"
                                }
                                Value={() =>
                                  handleConfirmPermission(
                                    "Colaboradores.Colaboradores.Editar.DatosPersonales.DatosIdentidad.Ver"
                                  )
                                }
                              />
                              <span className={styles.subTituloAccordion}>
                                Ver
                              </span>
                              <BpCheckbox
                                NombreCampo={
                                  "Colaboradores.Colaboradores.Editar.DatosPersonales.DatosIdentidad.Guardar"
                                }
                                Value={() =>
                                  handleConfirmPermission(
                                    "Colaboradores.Colaboradores.Editar.DatosPersonales.DatosIdentidad.Guardar"
                                  )
                                }
                              />
                              <span className={styles.subTituloAccordion}>
                                Editar
                              </span>
                            </div>
                          </div>
                        </div>
                      </AccordionDetails>
                    </Accordion>
                    {/* Finaliza acordion Datos personales / menu / colaboradores */}
                    {/* Inicia acordeon Salud Emergencias */}
                    <Accordion
                      className={
                        tablaCatorce === true
                          ? `${styles.acordionBase} `
                          : `${styles.acordionBase}`
                      }
                    >
                      <div
                        className={`${styles.contenedorTitulosAccordion} ${styles.NivelCuatroMenu}`}
                      >
                        <div>
                          <AccordionSummary
                            onClick={() => setTablaCatorce(!tablaCatorce)}
                          >
                            <div className={`${styles.contenedorIconoExpande}`}>
                              {tablaCatorce === true ? (
                                <img
                                  className={`${styles.iconoExpande}`}
                                  src={`/assets/svg/icono-expande-arriba.svg`}
                                  alt="imagen"
                                />
                              ) : (
                                <img
                                  className={`${styles.iconoExpande} ${styles.iconoAbajo}`}
                                  src={`/assets/svg/icono-expande.svg`}
                                  alt="imagen"
                                />
                              )}
                            </div>
                          </AccordionSummary>
                        </div>
                        <div className={styles.titulosAccordion}>
                          <span className={styles.tituloAccordion}>
                            Salud y emergencias
                          </span>
                        </div>
                        <div className={styles.contenedorSwitch}>
                          <Switch
                            NombreCampo={
                              "Colaboradores.Colaboradores.Editar.SaludEmergencias"
                            }
                            Value={() =>
                              handleConfirmPermission(
                                "Colaboradores.Colaboradores.Editar.SaludEmergencias"
                              )
                            }
                          />
                        </div>
                      </div>
                      <AccordionDetails style={{ padding: "8px 16px 0px" }}>
                        <div
                          className={`${styles.filaChecks} ${styles.nivelDos}`}
                        >
                          <div
                            className={`${styles.contenedorChecks} ${styles.nivelCuatroChecks}`}
                          >
                            <div className={styles.contenedorPrueba}>
                              <span className={styles.subTituloAccordion}>
                                Beneficiarios
                              </span>
                            </div>
                            <div className={styles.contenedorPrueba}>
                              <BpCheckbox
                                NombreCampo={
                                  "Colaboradores.Colaboradores.Editar.SaludEmergencias.Beneficiario.Ver"
                                }
                                Value={() =>
                                  handleConfirmPermission(
                                    "Colaboradores.Colaboradores.Editar.SaludEmergencias.Beneficiario.Ver"
                                  )
                                }
                              />
                              <span className={styles.subTituloAccordion}>
                                Ver
                              </span>
                              <BpCheckbox
                                NombreCampo={
                                  "Colaboradores.Colaboradores.Editar.SaludEmergencias.Beneficiario.Guardar"
                                }
                                Value={() =>
                                  handleConfirmPermission(
                                    "Colaboradores.Colaboradores.Editar.SaludEmergencias.Beneficiario.Guardar"
                                  )
                                }
                              />
                              <span className={styles.subTituloAccordion}>
                                Editar
                              </span>
                            </div>
                          </div>
                        </div>
                      </AccordionDetails>
                      <AccordionDetails style={{ padding: "8px 16px 0px" }}>
                        <div
                          className={`${styles.filaChecks} ${styles.nivelDos}`}
                        >
                          <div
                            className={`${styles.contenedorChecks} ${styles.nivelCuatroChecks}`}
                          >
                            <div className={styles.contenedorPrueba}>
                              <span className={styles.subTituloAccordion}>
                                Contacto de emergencia
                              </span>
                            </div>
                            <div className={styles.contenedorPrueba}>
                              <BpCheckbox
                                NombreCampo={
                                  "Colaboradores.Colaboradores.Editar.SaludEmergencias.ContactoEmergencia.Ver"
                                }
                                Value={() =>
                                  handleConfirmPermission(
                                    "Colaboradores.Colaboradores.Editar.SaludEmergencias.ContactoEmergencia.Ver"
                                  )
                                }
                              />
                              <span className={styles.subTituloAccordion}>
                                Ver
                              </span>
                              <BpCheckbox
                                NombreCampo={
                                  "Colaboradores.Colaboradores.Editar.SaludEmergencias.ContactoEmergencia.Guardar"
                                }
                                Value={() =>
                                  handleConfirmPermission(
                                    "Colaboradores.Colaboradores.Editar.SaludEmergencias.ContactoEmergencia.Guardar"
                                  )
                                }
                              />
                              <span className={styles.subTituloAccordion}>
                                Editar
                              </span>
                            </div>
                          </div>
                        </div>
                      </AccordionDetails>
                      <AccordionDetails style={{ padding: "8px 16px 0px" }}>
                        <div
                          className={`${styles.filaChecks} ${styles.nivelDos}`}
                        >
                          <div
                            className={`${styles.contenedorChecks} ${styles.nivelCuatroChecks}`}
                          >
                            <div className={styles.contenedorPrueba}>
                              <span className={styles.subTituloAccordion}>
                                Información medica
                              </span>
                            </div>
                            <div className={styles.contenedorPrueba}>
                              <BpCheckbox
                                NombreCampo={
                                  "Colaboradores.Colaboradores.Editar.SaludEmergencias.InformacionMedica.Ver"
                                }
                                Value={() =>
                                  handleConfirmPermission(
                                    "Colaboradores.Colaboradores.Editar.SaludEmergencias.InformacionMedica.Ver"
                                  )
                                }
                              />
                              <span className={styles.subTituloAccordion}>
                                Ver
                              </span>
                              <BpCheckbox
                                NombreCampo={
                                  "Colaboradores.Colaboradores.Editar.SaludEmergencias.InformacionMedica.Guardar"
                                }
                                Value={() =>
                                  handleConfirmPermission(
                                    "Colaboradores.Colaboradores.Editar.SaludEmergencias.InformacionMedica.Guardar"
                                  )
                                }
                              />
                              <span className={styles.subTituloAccordion}>
                                Editar
                              </span>
                            </div>
                          </div>
                        </div>
                      </AccordionDetails>
                    </Accordion>
                    {/* Finaliza acordeon Salud emergencias */}
                    {/* Inicia acordeon datos para pago */}
                    <Accordion
                      className={
                        tablaQuince === true
                          ? `${styles.acordionBase} `
                          : `${styles.acordionBase}`
                      }
                    >
                      <div
                        className={`${styles.contenedorTitulosAccordion} ${styles.NivelCuatroMenu}`}
                      >
                        <div>
                          <AccordionSummary
                            onClick={() => setTablaQuince(!tablaQuince)}
                          >
                            <div className={`${styles.contenedorIconoExpande}`}>
                              {tablaQuince === true ? (
                                <img
                                  className={`${styles.iconoExpande}`}
                                  src={`/assets/svg/icono-expande-arriba.svg`}
                                  alt="imagen"
                                />
                              ) : (
                                <img
                                  className={`${styles.iconoExpande} ${styles.iconoAbajo}`}
                                  src={`/assets/svg/icono-expande.svg`}
                                  alt="imagen"
                                />
                              )}
                            </div>
                          </AccordionSummary>
                        </div>
                        <div className={styles.titulosAccordion}>
                          <span className={styles.tituloAccordion}>
                            Datos para pago
                          </span>
                        </div>
                        <div className={styles.contenedorSwitch}>
                          <Switch
                            NombreCampo={
                              "Colaboradores.Colaboradores.Editar.DatosParaPago"
                            }
                            Value={() =>
                              handleConfirmPermission(
                                "Colaboradores.Colaboradores.Editar.DatosParaPago"
                              )
                            }
                          />
                        </div>
                      </div>
                      <AccordionDetails style={{ padding: "8px 16px 0px" }}>
                        <div
                          className={`${styles.filaChecks} ${styles.nivelDos}`}
                        >
                          <div
                            className={`${styles.contenedorChecks} ${styles.nivelCuatroChecks}`}
                          >
                            <div className={styles.contenedorPrueba}>
                              <span className={styles.subTituloAccordion}>
                                Datos bancarios
                              </span>
                            </div>
                            <div className={styles.contenedorPrueba}>
                              <BpCheckbox
                                NombreCampo={
                                  "Colaboradores.Colaboradores.Editar.DatosParaPago.DatosBancarios.Ver"
                                }
                                Value={() =>
                                  handleConfirmPermission(
                                    "Colaboradores.Colaboradores.Editar.DatosParaPago.DatosBancarios.Ver"
                                  )
                                }
                              />
                              <span className={styles.subTituloAccordion}>
                                Ver
                              </span>
                              <BpCheckbox
                                NombreCampo={
                                  "Colaboradores.Colaboradores.Editar.DatosParaPago.DatosBancarios.Guardar"
                                }
                                Value={() =>
                                  handleConfirmPermission(
                                    "Colaboradores.Colaboradores.Editar.DatosParaPago.DatosBancarios.Guardar"
                                  )
                                }
                              />
                              <span className={styles.subTituloAccordion}>
                                Editar
                              </span>
                            </div>
                          </div>
                        </div>
                      </AccordionDetails>
                    </Accordion>
                    {/* Finaliza acordeon datos para pago */}
                    {/* Inicia acordion expediente / menu/ colaboradores */}
                    <Accordion
                      className={
                        tablaDieciseis === true
                          ? `${styles.acordionBase} `
                          : `${styles.acordionBase}`
                      }
                    >
                      <div
                        className={`${styles.contenedorTitulosAccordion} ${styles.NivelCuatroMenu}`}
                      >
                        <div>
                          <AccordionSummary
                            onClick={() => setTablaDieciseis(!tablaDieciseis)}
                          >
                            <div className={`${styles.contenedorIconoExpande}`}>
                              {tablaDieciseis === true ? (
                                <img
                                  className={`${styles.iconoExpande}`}
                                  src={`/assets/svg/icono-expande-arriba.svg`}
                                  alt="imagen"
                                />
                              ) : (
                                <img
                                  className={`${styles.iconoExpande} ${styles.iconoAbajo}`}
                                  src={`/assets/svg/icono-expande.svg`}
                                  alt="imagen"
                                />
                              )}
                            </div>
                          </AccordionSummary>
                        </div>
                        <div className={styles.titulosAccordion}>
                          <span className={styles.tituloAccordion}>
                            Expediente
                          </span>
                        </div>
                        <div className={styles.contenedorSwitch}>
                          <Switch
                            NombreCampo={
                              "Colaboradores.Colaboradores.Editar.Expediente"
                            }
                            Value={() =>
                              handleConfirmPermission(
                                "Colaboradores.Colaboradores.Editar.Expediente"
                              )
                            }
                          />
                        </div>
                      </div>
                      <AccordionDetails style={{ padding: "8px 16px 0px" }}>
                        <div
                          className={`${styles.filaChecks} ${styles.nivelDos}`}
                        >
                          <div
                            className={`${styles.contenedorChecks} ${styles.nivelCuatroChecks}`}
                          >
                            <div className={styles.contenedorPrueba}>
                              <span className={styles.subTituloAccordion}>
                                Documentos personales
                              </span>
                            </div>
                            <div className={styles.contenedorPrueba}>
                              <BpCheckbox
                                NombreCampo={
                                  "Colaboradores.Colaboradores.Editar.Expediente.DatosPersonales.Ver"
                                }
                                Value={() =>
                                  handleConfirmPermission(
                                    "Colaboradores.Colaboradores.Editar.Expediente.DatosPersonales.Ver"
                                  )
                                }
                              />
                              <span className={styles.subTituloAccordion}>
                                Ver
                              </span>
                              <BpCheckbox
                                NombreCampo={
                                  "Colaboradores.Colaboradores.Editar.Expediente.DatosPersonales.Guardar"
                                }
                                Value={() =>
                                  handleConfirmPermission(
                                    "Colaboradores.Colaboradores.Editar.Expediente.DatosPersonales.Guardar"
                                  )
                                }
                              />
                              <span className={styles.subTituloAccordion}>
                                Editar
                              </span>
                            </div>
                          </div>
                        </div>
                      </AccordionDetails>
                      <AccordionDetails style={{ padding: "8px 16px 0px" }}>
                        <div
                          className={`${styles.filaChecks} ${styles.nivelDos}`}
                        >
                          <div
                            className={`${styles.contenedorChecks} ${styles.nivelCuatroChecks}`}
                          >
                            <div className={styles.contenedorPrueba}>
                              <span className={styles.subTituloAccordion}>
                                Documentos opcionales
                              </span>
                            </div>
                            <div className={styles.contenedorPrueba}>
                              <BpCheckbox
                                NombreCampo={
                                  "Colaboradores.Colaboradores.Editar.Expediente.DocumentosOpcionales.Ver"
                                }
                                Value={() =>
                                  handleConfirmPermission(
                                    "Colaboradores.Colaboradores.Editar.Expediente.DocumentosOpcionales.Ver"
                                  )
                                }
                              />
                              <span className={styles.subTituloAccordion}>
                                Ver
                              </span>
                              <BpCheckbox
                                NombreCampo={
                                  "Colaboradores.Colaboradores.Editar.Expediente.DocumentosOpcionales.Guardar"
                                }
                                Value={() =>
                                  handleConfirmPermission(
                                    "Colaboradores.Colaboradores.Editar.Expediente.DocumentosOpcionales.Guardar"
                                  )
                                }
                              />
                              <span className={styles.subTituloAccordion}>
                                Editar
                              </span>
                            </div>
                          </div>
                        </div>
                      </AccordionDetails>
                      <AccordionDetails style={{ padding: "8px 16px 0px" }}>
                        <div
                          className={`${styles.filaChecks} ${styles.nivelDos}`}
                        >
                          <div
                            className={`${styles.contenedorChecks} ${styles.nivelCuatroChecks}`}
                          >
                            <div className={styles.contenedorPrueba}>
                              <span className={styles.subTituloAccordion}>
                                Documentos para firma
                              </span>
                            </div>
                            <div className={styles.contenedorPrueba}>
                              <BpCheckbox
                                NombreCampo={
                                  "Colaboradores.Colaboradores.Editar.Expediente.OfertaLaboral.Ver"
                                }
                                Value={() =>
                                  handleConfirmPermission(
                                    "Colaboradores.Colaboradores.Editar.Expediente.OfertaLaboral.Ver"
                                  )
                                }
                              />
                              <span className={styles.subTituloAccordion}>
                                Ver
                              </span>
                              <BpCheckbox
                                NombreCampo={
                                  "Colaboradores.Colaboradores.Editar.Expediente.OfertaLaboral.Guardar"
                                }
                                Value={() =>
                                  handleConfirmPermission(
                                    "Colaboradores.Colaboradores.Editar.Expediente.OfertaLaboral.Guardar"
                                  )
                                }
                              />
                              <span className={styles.subTituloAccordion}>
                                Editar
                              </span>
                            </div>
                          </div>
                        </div>
                      </AccordionDetails>
                    </Accordion>
                    {/* Finaliza acordion expediente / menu/ colaboradores */}

                    {/* Finaliza acordion Menús  */}
                  </Accordion>
                  {/* Finaliza acordeon Colaboradores */}
                  {/* Inicia acordeon Declinados */}
                  <Accordion
                    className={
                      tablaDiecisiete === true
                        ? `${styles.acordionBase} $ ${styles.BackgrounNivelDos} `
                        : `${styles.acordionBase} $ ${styles.BackgrounNivelDos}`
                    }
                  >
                    <div
                      className={`${styles.contenedorTitulosAccordion} ${styles.nivelDosMenu}`}
                    >
                      <div>
                        <AccordionSummary
                          onClick={() => setTablaDiecisiete(!tablaDiecisiete)}
                        >
                          <div className={`${styles.contenedorIconoExpande}`}>
                            {tablaDiecisiete === true ? (
                              <img
                                className={`${styles.iconoExpande}`}
                                src={`/assets/svg/icono-expande-arriba.svg`}
                                alt="imagen"
                              />
                            ) : (
                              <img
                                className={`${styles.iconoExpande} ${styles.iconoAbajo}`}
                                src={`/assets/svg/icono-expande.svg`}
                                alt="imagen"
                              />
                            )}
                          </div>
                        </AccordionSummary>
                      </div>
                      <div className={styles.titulosAccordion}>
                        <span className={styles.tituloAccordion}>
                          Declinados
                        </span>
                      </div>
                      <div className={styles.contenedorSwitch}>
                        <Switch
                          NombreCampo={"Colaboradores.Declinados"}
                          Value={() =>
                            handleConfirmPermission("Colaboradores.Declinados")
                          }
                        />
                      </div>
                    </div>
                  </Accordion>
                  {/* Finaliza acordeon Declinados */}
                  {/* Inica acordeon Inactivos */}
                  <Accordion
                    className={
                      tablaDieciocho === true
                        ? `${styles.acordionBase} $ ${styles.BackgrounNivelDos} `
                        : `${styles.acordionBase} $ ${styles.BackgrounNivelDos}`
                    }
                  >
                    <div
                      className={`${styles.contenedorTitulosAccordion} ${styles.nivelDosMenu}`}
                    >
                      <div>
                        <AccordionSummary
                          onClick={() => setTablaDieciocho(!tablaDieciocho)}
                        >
                          <div className={`${styles.contenedorIconoExpande}`}>
                            {tablaDieciocho === true ? (
                              <img
                                className={`${styles.iconoExpande}`}
                                src={`/assets/svg/icono-expande-arriba.svg`}
                                alt="imagen"
                              />
                            ) : (
                              <img
                                className={`${styles.iconoExpande} ${styles.iconoAbajo}`}
                                src={`/assets/svg/icono-expande.svg`}
                                alt="imagen"
                              />
                            )}
                          </div>
                        </AccordionSummary>
                      </div>
                      <div className={styles.titulosAccordion}>
                        <span className={styles.tituloAccordion}>
                          Inactivos
                        </span>
                      </div>
                      <div className={styles.contenedorSwitch}>
                        <Switch
                          NombreCampo={"Colaboradores.Inactivos"}
                          Value={() =>
                            handleConfirmPermission("Colaboradores.Inactivos")
                          }
                        />
                      </div>
                    </div>

                    <AccordionDetails style={{ padding: "8px 16px 0px" }}>
                      <div
                        className={`${styles.filaChecks} ${styles.nivelDos}`}
                      >
                        <div
                          className={`${styles.contenedorChecks}  ${styles.nivelTresChecks}`}
                        >
                          <div className={styles.contenedorPrueba}>
                            <span className={styles.subTituloAccordion}>
                              Acciones
                            </span>
                          </div>
                          <div className={styles.contenedorPrueba}>
                            <BpCheckbox
                              NombreCampo={"Colaboradores.Inactivos.Activar"}
                              Value={() =>
                                handleConfirmPermission(
                                  "Colaboradores.Inactivos.Activar"
                                )
                              }
                            />
                            <span className={styles.subTituloAccordion}>
                              Activar
                            </span>
                            <BpCheckbox
                              NombreCampo={"Colaboradores.Inactivos.Ver"}
                              Value={() =>
                                handleConfirmPermission(
                                  "Colaboradores.Inactivos.Ver"
                                )
                              }
                            />
                            <span className={styles.subTituloAccordion}>
                              Ver
                            </span>
                          </div>
                        </div>
                      </div>
                    </AccordionDetails>
                    {/* Inicia acordeon menu / inactivos */}

                    {/* Inicia acordion Información Alta / menu/ inactivos */}
                    <Accordion
                      className={
                        tablaVeinte === true
                          ? `${styles.acordionBase} `
                          : `${styles.acordionBase}`
                      }
                    >
                      <div
                        className={`${styles.contenedorTitulosAccordion} ${styles.NivelCuatroMenu}`}
                      >
                        <div>
                          <AccordionSummary
                            onClick={() => setTablaVeinte(!tablaVeinte)}
                          >
                            <div className={`${styles.contenedorIconoExpande}`}>
                              {tablaVeinte === true ? (
                                <img
                                  className={`${styles.iconoExpande}`}
                                  src={`/assets/svg/icono-expande-arriba.svg`}
                                  alt="imagen"
                                />
                              ) : (
                                <img
                                  className={`${styles.iconoExpande} ${styles.iconoAbajo}`}
                                  src={`/assets/svg/icono-expande.svg`}
                                  alt="imagen"
                                />
                              )}
                            </div>
                          </AccordionSummary>
                        </div>
                        <div className={styles.titulosAccordion}>
                          <span className={styles.tituloAccordion}>
                            Información de alta
                          </span>
                        </div>
                        <div className={styles.contenedorSwitch}>
                          <Switch
                            NombreCampo={
                              "Colaboradores.Inactivos.Ver.InformacionAlta"
                            }
                            Value={() =>
                              handleConfirmPermission(
                                "Colaboradores.Inactivos.Ver.InformacionAlta"
                              )
                            }
                          />
                        </div>
                      </div>
                      <AccordionDetails style={{ padding: "8px 16px 0px" }}>
                        <div
                          className={`${styles.filaChecks} ${styles.nivelDos}`}
                        >
                          <div
                            className={`${styles.contenedorChecks} ${styles.nivelCuatroChecks}`}
                          >
                            <div className={styles.contenedorPrueba}>
                              <span className={styles.subTituloAccordion}>
                                Información personal
                              </span>
                            </div>
                            <div className={styles.contenedorPrueba}>
                              <BpCheckbox
                                NombreCampo={
                                  "Colaboradores.Inactivos.Ver.InformacionAlta.InformacionPersonal"
                                }
                                Value={() =>
                                  handleConfirmPermission(
                                    "Colaboradores.Inactivos.Ver.InformacionAlta.InformacionPersonal"
                                  )
                                }
                              />
                              <span className={styles.subTituloAccordion}>
                                Ver
                              </span>
                            </div>
                          </div>
                        </div>
                      </AccordionDetails>
                      <AccordionDetails style={{ padding: "8px 16px 0px" }}>
                        <div
                          className={`${styles.filaChecks} ${styles.nivelDos}`}
                        >
                          <div
                            className={`${styles.contenedorChecks} ${styles.nivelCuatroChecks}`}
                          >
                            <div className={styles.contenedorPrueba}>
                              <span className={styles.subTituloAccordion}>
                                Datos de ingreso
                              </span>
                            </div>
                            <div className={styles.contenedorPrueba}>
                              <BpCheckbox
                                NombreCampo={
                                  "Colaboradores.Inactivos.Ver.InformacionAlta.DatosIngreso"
                                }
                                Value={() =>
                                  handleConfirmPermission(
                                    "Colaboradores.Inactivos.Ver.InformacionAlta.DatosIngreso"
                                  )
                                }
                              />
                              <span className={styles.subTituloAccordion}>
                                Ver
                              </span>
                            </div>
                          </div>
                        </div>
                      </AccordionDetails>
                      <AccordionDetails style={{ padding: "8px 16px 0px" }}>
                        <div
                          className={`${styles.filaChecks} ${styles.nivelDos}`}
                        >
                          <div
                            className={`${styles.contenedorChecks} ${styles.nivelCuatroChecks}`}
                          >
                            <div className={styles.contenedorPrueba}>
                              <span className={styles.subTituloAccordion}>
                                Datos de contratación
                              </span>
                            </div>
                            <div className={styles.contenedorPrueba}>
                              <BpCheckbox
                                NombreCampo={
                                  "Colaboradores.Inactivos.Ver.InformacionAlta.DatosContratacion"
                                }
                                Value={() =>
                                  handleConfirmPermission(
                                    "Colaboradores.Inactivos.Ver.InformacionAlta.DatosContratacion"
                                  )
                                }
                              />
                              <span className={styles.subTituloAccordion}>
                                Ver
                              </span>
                            </div>
                          </div>
                        </div>
                      </AccordionDetails>
                    </Accordion>
                    {/* Finaliza acordion Información Alta / menu/ inactivos */}
                    {/* Inicia acordion Datos Personales / menu / inactivos */}
                    <Accordion
                      className={
                        tablaVeintiuno === true
                          ? `${styles.acordionBase} `
                          : `${styles.acordionBase}`
                      }
                    >
                      <div
                        className={`${styles.contenedorTitulosAccordion} ${styles.NivelCuatroMenu}`}
                      >
                        <div>
                          <AccordionSummary
                            onClick={() => setTablaVeintiuno(!tablaVeintiuno)}
                          >
                            <div className={`${styles.contenedorIconoExpande}`}>
                              {tablaVeintiuno === true ? (
                                <img
                                  className={`${styles.iconoExpande}`}
                                  src={`/assets/svg/icono-expande-arriba.svg`}
                                  alt="imagen"
                                />
                              ) : (
                                <img
                                  className={`${styles.iconoExpande} ${styles.iconoAbajo}`}
                                  src={`/assets/svg/icono-expande.svg`}
                                  alt="imagen"
                                />
                              )}
                            </div>
                          </AccordionSummary>
                        </div>
                        <div className={styles.titulosAccordion}>
                          <span className={styles.tituloAccordion}>
                            Datos personales
                          </span>
                        </div>
                        <div className={styles.contenedorSwitch}>
                          <Switch
                            NombreCampo={
                              "Colaboradores.Inactivos.Ver.DatosPersonales"
                            }
                            Value={() =>
                              handleConfirmPermission(
                                "Colaboradores.Inactivos.Ver.DatosPersonales"
                              )
                            }
                          />
                        </div>
                      </div>
                      <AccordionDetails style={{ padding: "8px 16px 0px" }}>
                        <div
                          className={`${styles.filaChecks} ${styles.nivelDos}`}
                        >
                          <div
                            className={`${styles.contenedorChecks} ${styles.nivelCuatroChecks}`}
                          >
                            <div className={styles.contenedorPrueba}>
                              <span className={styles.subTituloAccordion}>
                                Datos del domicilio
                              </span>
                            </div>
                            <div className={styles.contenedorPrueba}>
                              <BpCheckbox
                                NombreCampo={
                                  "Colaboradores.Inactivos.Ver.DatosPersonales.DatosDomicilio"
                                }
                                Value={() =>
                                  handleConfirmPermission(
                                    "Colaboradores.Inactivos.Ver.DatosPersonales.DatosDomicilio"
                                  )
                                }
                              />
                              <span className={styles.subTituloAccordion}>
                                Ver
                              </span>
                            </div>
                          </div>
                        </div>
                      </AccordionDetails>
                      <AccordionDetails style={{ padding: "8px 16px 0px" }}>
                        <div
                          className={`${styles.filaChecks} ${styles.nivelDos}`}
                        >
                          <div
                            className={`${styles.contenedorChecks} ${styles.nivelCuatroChecks}`}
                          >
                            <div className={styles.contenedorPrueba}>
                              <span className={styles.subTituloAccordion}>
                                Datos personales
                              </span>
                            </div>
                            <div className={styles.contenedorPrueba}>
                              <BpCheckbox
                                NombreCampo={
                                  "Colaboradores.Inactivos.Ver.DatosPersonales.DatosPersonales"
                                }
                                Value={() =>
                                  handleConfirmPermission(
                                    "Colaboradores.Inactivos.Ver.DatosPersonales.DatosPersonales"
                                  )
                                }
                              />
                              <span className={styles.subTituloAccordion}>
                                Ver
                              </span>
                            </div>
                          </div>
                        </div>
                      </AccordionDetails>
                      <AccordionDetails style={{ padding: "8px 16px 0px" }}>
                        <div
                          className={`${styles.filaChecks} ${styles.nivelDos}`}
                        >
                          <div
                            className={`${styles.contenedorChecks} ${styles.nivelCuatroChecks}`}
                          >
                            <div className={styles.contenedorPrueba}>
                              <span className={styles.subTituloAccordion}>
                                Datos de identidad
                              </span>
                            </div>
                            <div className={styles.contenedorPrueba}>
                              <BpCheckbox
                                NombreCampo={
                                  "Colaboradores.Inactivos.Ver.DatosPersonales.DatosIdentidad"
                                }
                                Value={() =>
                                  handleConfirmPermission(
                                    "Colaboradores.Inactivos.Ver.DatosPersonales.DatosIdentidad"
                                  )
                                }
                              />
                              <span className={styles.subTituloAccordion}>
                                Ver
                              </span>
                            </div>
                          </div>
                        </div>
                      </AccordionDetails>
                    </Accordion>
                    {/* Finaliza acordion Datos Personales / menu / inactivos */}
                    {/* Inicia acordion Salud emergencias/ menus / inactivos */}
                    <Accordion
                      className={
                        tablaVeintidos === true
                          ? `${styles.acordionBase} `
                          : `${styles.acordionBase}`
                      }
                    >
                      <div
                        className={`${styles.contenedorTitulosAccordion} ${styles.NivelCuatroMenu}`}
                      >
                        <div>
                          <AccordionSummary
                            onClick={() => setTablaVeintidos(!tablaVeintidos)}
                          >
                            <div className={`${styles.contenedorIconoExpande}`}>
                              {tablaVeintidos === true ? (
                                <img
                                  className={`${styles.iconoExpande}`}
                                  src={`/assets/svg/icono-expande-arriba.svg`}
                                  alt="imagen"
                                />
                              ) : (
                                <img
                                  className={`${styles.iconoExpande} ${styles.iconoAbajo}`}
                                  src={`/assets/svg/icono-expande.svg`}
                                  alt="imagen"
                                />
                              )}
                            </div>
                          </AccordionSummary>
                        </div>
                        <div className={styles.titulosAccordion}>
                          <span className={styles.tituloAccordion}>
                            Salud y emergencias
                          </span>
                        </div>
                        <div className={styles.contenedorSwitch}>
                          <Switch
                            NombreCampo={
                              "Colaboradores.Inactivos.Ver.SaludEmergencias"
                            }
                            Value={() =>
                              handleConfirmPermission(
                                "Colaboradores.Inactivos.Ver.SaludEmergencias"
                              )
                            }
                          />
                        </div>
                      </div>
                      <AccordionDetails style={{ padding: "8px 16px 0px" }}>
                        <div
                          className={`${styles.filaChecks} ${styles.nivelDos}`}
                        >
                          <div
                            className={`${styles.contenedorChecks} ${styles.nivelCuatroChecks}`}
                          >
                            <div className={styles.contenedorPrueba}>
                              <span className={styles.subTituloAccordion}>
                                Beneficiario
                              </span>
                            </div>
                            <div className={styles.contenedorPrueba}>
                              <BpCheckbox
                                NombreCampo={
                                  "Colaboradores.Inactivos.Ver.SaludEmergencias.Beneficiario"
                                }
                                Value={() =>
                                  handleConfirmPermission(
                                    "Colaboradores.Inactivos.Ver.SaludEmergencias.Beneficiario"
                                  )
                                }
                              />
                              <span className={styles.subTituloAccordion}>
                                Ver
                              </span>
                            </div>
                          </div>
                        </div>
                      </AccordionDetails>
                      <AccordionDetails style={{ padding: "8px 16px 0px" }}>
                        <div
                          className={`${styles.filaChecks} ${styles.nivelDos}`}
                        >
                          <div
                            className={`${styles.contenedorChecks} ${styles.nivelCuatroChecks}`}
                          >
                            <div className={styles.contenedorPrueba}>
                              <span className={styles.subTituloAccordion}>
                                Contacto de emergencia
                              </span>
                            </div>
                            <div className={styles.contenedorPrueba}>
                              <BpCheckbox
                                NombreCampo={
                                  "Colaboradores.Inactivos.Ver.SaludEmergencias.ContactoEmergencia"
                                }
                                Value={() =>
                                  handleConfirmPermission(
                                    "Colaboradores.Inactivos.Ver.SaludEmergencias.ContactoEmergencia"
                                  )
                                }
                              />
                              <span className={styles.subTituloAccordion}>
                                Ver
                              </span>
                            </div>
                          </div>
                        </div>
                      </AccordionDetails>
                      <AccordionDetails style={{ padding: "8px 16px 0px" }}>
                        <div
                          className={`${styles.filaChecks} ${styles.nivelDos}`}
                        >
                          <div
                            className={`${styles.contenedorChecks} ${styles.nivelCuatroChecks}`}
                          >
                            <div className={styles.contenedorPrueba}>
                              <span className={styles.subTituloAccordion}>
                                Información médica
                              </span>
                            </div>
                            <div className={styles.contenedorPrueba}>
                              <BpCheckbox
                                NombreCampo={
                                  "Colaboradores.Inactivos.Ver.SaludEmergencias.InformacionMedica"
                                }
                                Value={() =>
                                  handleConfirmPermission(
                                    "Colaboradores.Inactivos.Ver.SaludEmergencias.InformacionMedica"
                                  )
                                }
                              />
                              <span className={styles.subTituloAccordion}>
                                Ver
                              </span>
                            </div>
                          </div>
                        </div>
                      </AccordionDetails>
                    </Accordion>
                    {/* Finaliza acordion Salud emergencias/ menus / inactivos */}
                    {/* Inicia acordeon Datos para Pago / menu / inactivos */}
                    <Accordion
                      className={
                        tablaVeintitres === true
                          ? `${styles.acordionBase} `
                          : `${styles.acordionBase}`
                      }
                    >
                      <div
                        className={`${styles.contenedorTitulosAccordion} ${styles.NivelCuatroMenu}`}
                      >
                        <div>
                          <AccordionSummary
                            onClick={() => setTablaVeintitres(!tablaVeintitres)}
                          >
                            <div className={`${styles.contenedorIconoExpande}`}>
                              {tablaVeintitres === true ? (
                                <img
                                  className={`${styles.iconoExpande}`}
                                  src={`/assets/svg/icono-expande-arriba.svg`}
                                  alt="imagen"
                                />
                              ) : (
                                <img
                                  className={`${styles.iconoExpande} ${styles.iconoAbajo}`}
                                  src={`/assets/svg/icono-expande.svg`}
                                  alt="imagen"
                                />
                              )}
                            </div>
                          </AccordionSummary>
                        </div>
                        <div className={styles.titulosAccordion}>
                          <span className={styles.tituloAccordion}>
                            Datos para pago
                          </span>
                        </div>
                        <div className={styles.contenedorSwitch}>
                          <Switch
                            NombreCampo={
                              "Colaboradores.Inactivos.Ver.DatosParaPago"
                            }
                            Value={() =>
                              handleConfirmPermission(
                                "Colaboradores.Inactivos.Ver.DatosParaPago"
                              )
                            }
                          />
                        </div>
                      </div>
                      <AccordionDetails style={{ padding: "8px 16px 0px" }}>
                        <div
                          className={`${styles.filaChecks} ${styles.nivelDos}`}
                        >
                          <div
                            className={`${styles.contenedorChecks} ${styles.nivelCuatroChecks}`}
                          >
                            <div className={styles.contenedorPrueba}>
                              <span className={styles.subTituloAccordion}>
                                Datos bancarios
                              </span>
                            </div>
                            <div className={styles.contenedorPrueba}>
                              <BpCheckbox
                                NombreCampo={
                                  "Colaboradores.Inactivos.Ver.DatosParaPago.DatosBancarios"
                                }
                                Value={() =>
                                  handleConfirmPermission(
                                    "Colaboradores.Inactivos.Ver.DatosParaPago.DatosBancarios"
                                  )
                                }
                              />
                              <span className={styles.subTituloAccordion}>
                                Ver
                              </span>
                            </div>
                          </div>
                        </div>
                      </AccordionDetails>
                    </Accordion>
                    {/* Finaliza acordeon Datos para Pago / menu / inactivos */}
                    {/* Inicia acordeon expediente / menu / inactivos */}
                    <Accordion
                      className={
                        tablaVeinticuatro === true
                          ? `${styles.acordionBase} `
                          : `${styles.acordionBase}`
                      }
                    >
                      <div
                        className={`${styles.contenedorTitulosAccordion} ${styles.NivelCuatroMenu}`}
                      >
                        <div>
                          <AccordionSummary
                            onClick={() =>
                              setTablaVeinticuatro(!tablaVeinticuatro)
                            }
                          >
                            <div className={`${styles.contenedorIconoExpande}`}>
                              {tablaVeinticuatro === true ? (
                                <img
                                  className={`${styles.iconoExpande}`}
                                  src={`/assets/svg/icono-expande-arriba.svg`}
                                  alt="imagen"
                                />
                              ) : (
                                <img
                                  className={`${styles.iconoExpande} ${styles.iconoAbajo}`}
                                  src={`/assets/svg/icono-expande.svg`}
                                  alt="imagen"
                                />
                              )}
                            </div>
                          </AccordionSummary>
                        </div>
                        <div className={styles.titulosAccordion}>
                          <span className={styles.tituloAccordion}>
                            Expediente
                          </span>
                        </div>
                        <div className={styles.contenedorSwitch}>
                          <Switch
                            NombreCampo={
                              "Colaboradores.Inactivos.Ver.Expediente"
                            }
                            Value={() =>
                              handleConfirmPermission(
                                "Colaboradores.Inactivos.Ver.Expediente"
                              )
                            }
                          />
                        </div>
                      </div>
                      <AccordionDetails style={{ padding: "8px 16px 0px" }}>
                        <div
                          className={`${styles.filaChecks} ${styles.nivelDos}`}
                        >
                          <div
                            className={`${styles.contenedorChecks} ${styles.nivelCuatroChecks}`}
                          >
                            <div className={styles.contenedorPrueba}>
                              <span className={styles.subTituloAccordion}>
                                Documentos personales
                              </span>
                            </div>
                            <div className={styles.contenedorPrueba}>
                              <BpCheckbox
                                NombreCampo={
                                  "Colaboradores.Inactivos.Ver.Expediente.DatosPersonales"
                                }
                                Value={() =>
                                  handleConfirmPermission(
                                    "Colaboradores.Inactivos.Ver.Expediente.DatosPersonales"
                                  )
                                }
                              />
                              <span className={styles.subTituloAccordion}>
                                Ver
                              </span>
                            </div>
                          </div>
                        </div>
                      </AccordionDetails>
                      <AccordionDetails style={{ padding: "8px 16px 0px" }}>
                        <div
                          className={`${styles.filaChecks} ${styles.nivelDos}`}
                        >
                          <div
                            className={`${styles.contenedorChecks} ${styles.nivelCuatroChecks}`}
                          >
                            <div className={styles.contenedorPrueba}>
                              <span className={styles.subTituloAccordion}>
                                Documentos opcionales
                              </span>
                            </div>
                            <div className={styles.contenedorPrueba}>
                              <BpCheckbox
                                NombreCampo={
                                  "Colaboradores.Inactivos.Ver.Expediente.DocumentosOpcionales"
                                }
                                Value={() =>
                                  handleConfirmPermission(
                                    "Colaboradores.Inactivos.Ver.Expediente.DocumentosOpcionales"
                                  )
                                }
                              />
                              <span className={styles.subTituloAccordion}>
                                Ver
                              </span>
                            </div>
                          </div>
                        </div>
                      </AccordionDetails>
                      <AccordionDetails style={{ padding: "8px 16px 0px" }}>
                        <div
                          className={`${styles.filaChecks} ${styles.nivelDos}`}
                        >
                          <div
                            className={`${styles.contenedorChecks} ${styles.nivelCuatroChecks}`}
                          >
                            <div className={styles.contenedorPrueba}>
                              <span className={styles.subTituloAccordion}>
                                Documentos para firma
                              </span>
                            </div>
                            <div className={styles.contenedorPrueba}>
                              <BpCheckbox
                                NombreCampo={
                                  "Colaboradores.Inactivos.Ver.Expediente.OfertaLaboral"
                                }
                                Value={() =>
                                  handleConfirmPermission(
                                    "Colaboradores.Inactivos.Ver.Expediente.OfertaLaboral"
                                  )
                                }
                              />
                              <span className={styles.subTituloAccordion}>
                                Ver
                              </span>
                            </div>
                          </div>
                        </div>
                      </AccordionDetails>
                    </Accordion>
                    {/* Finaliza acordeon expediente / menu / inactivos */}

                    {/* Finaliza acordeon menu / inactivos */}
                  </Accordion>
                  {/* Finaliza acordeon Inactivos */}
                  
                </Accordion>
                {/* Finaliza colaboradores general */}
                {/* Inicia acordeon My Mod */}
                <Accordion
                  className={
                    tablaVeintiseis === true
                      ? `${styles.acordionBase} `
                      : `${styles.acordionBase}`
                  }
                >
                  <div className={styles.contenedorTitulosAccordion}>
                    <div>
                      <AccordionSummary
                        onClick={() => setTablaVeintiseis(!tablaVeintiseis)}
                      >
                        <div className={`${styles.contenedorIconoExpande}`}>
                          {tablaVeintiseis === true ? (
                            <img
                              className={`${styles.iconoExpande}`}
                              src={`/assets/svg/icono-expande-arriba.svg`}
                              alt="imagen"
                            />
                          ) : (
                            <img
                              className={`${styles.iconoExpande} ${styles.iconoAbajo}`}
                              src={`/assets/svg/icono-expande.svg`}
                              alt="imagen"
                            />
                          )}
                        </div>
                      </AccordionSummary>
                    </div>
                    <div className={styles.titulosAccordion}>
                      <span className={styles.tituloAccordion}>My Mood</span>
                    </div>
                    <div className={styles.contenedorSwitch}>
                      <Switch
                        NombreCampo={"Sidebar.MyMood"}
                        Value={() => handleConfirmPermission("Sidebar.MyMood")}
                      />
                    </div>
                  </div>
                  <AccordionDetails style={{ padding: "8px 16px 0px" }}>
                    <div className={`${styles.filaChecks} ${styles.nivelDos}`}>
                      <div className={styles.contenedorChecks}>
                        <div className={styles.contenedorPrueba}>
                          <span className={styles.subTituloAccordion}>
                            Acciones
                          </span>
                        </div>
                        <div className={styles.contenedorPrueba}>
                          <BpCheckbox
                            NombreCampo={"MyMood.Estadisticas"}
                            Value={() =>
                              handleConfirmPermission("MyMood.Estadisticas")
                            }
                          />
                          <span className={styles.subTituloAccordion}>
                            Estadísticas
                          </span>
                        </div>
                      </div>
                    </div>
                  </AccordionDetails>
                </Accordion>
                {/* Finaliza acordeon My Mod */}
                {/* Inicia acordeon Notificaciones */}
                <Accordion
                  className={
                    tablaVeintisiete === true
                      ? `${styles.acordionBase} `
                      : `${styles.acordionBase}`
                  }
                >
                  <div className={styles.contenedorTitulosAccordion}>
                    <div>
                      <AccordionSummary
                        onClick={() => setTablaVeintisiete(!tablaVeintisiete)}
                      >
                        <div className={`${styles.contenedorIconoExpande}`}>
                          {tablaVeintisiete === true ? (
                            <img
                              className={`${styles.iconoExpande}`}
                              src={`/assets/svg/icono-expande-arriba.svg`}
                              alt="imagen"
                            />
                          ) : (
                            <img
                              className={`${styles.iconoExpande} ${styles.iconoAbajo}`}
                              src={`/assets/svg/icono-expande.svg`}
                              alt="imagen"
                            />
                          )}
                        </div>
                      </AccordionSummary>
                    </div>
                    <div className={styles.titulosAccordion}>
                      <span className={styles.tituloAccordion}>
                        Notificaciones
                      </span>
                    </div>
                    <div className={styles.contenedorSwitch}>
                      <Switch
                        NombreCampo={"Sidebar.Notificaciones"}
                        Value={() =>
                          handleConfirmPermission("Sidebar.Notificaciones")
                        }
                      />
                    </div>
                  </div>
                  <AccordionDetails style={{ padding: "8px 16px 0px" }}>
                    <div className={`${styles.filaChecks} ${styles.nivelDos}`}>
                      <div className={styles.contenedorChecks}>
                        <div className={styles.contenedorPrueba}>
                          <span className={styles.subTituloAccordion}>
                            Acciones
                          </span>
                        </div>
                        <div className={styles.contenedorPrueba}>
                          <BpCheckbox
                            NombreCampo={"Notificaciones.Ver"}
                            Value={() =>
                              handleConfirmPermission("Notificaciones.Ver")
                            }
                          />
                          <span className={styles.subTituloAccordion2}>
                            Ver
                          </span>
                          <BpCheckbox
                            NombreCampo={"Notificaciones.Crear"}
                            Value={() =>
                              handleConfirmPermission("Notificaciones.Crear")
                            }
                          />
                          <span className={styles.subTituloAccordion2}>
                            Agregar
                          </span>
                          <BpCheckbox
                            NombreCampo={"Notificaciones.Editar"}
                            Value={() =>
                              handleConfirmPermission("Notificaciones.Editar")
                            }
                          />
                          <span className={styles.subTituloAccordion2}>
                            Editar
                          </span>

                          <BpCheckbox
                            NombreCampo={"Notificaciones.Reenviar"}
                            Value={() =>
                              handleConfirmPermission("Notificaciones.Reenviar")
                            }
                          />
                          <span className={styles.subTituloAccordion2}>
                            Reenviar
                          </span>
                          <BpCheckbox
                            NombreCampo={"Notificaciones.Eliminar"}
                            Value={() =>
                              handleConfirmPermission("Notificaciones.Eliminar")
                            }
                          />
                          <span className={styles.subTituloAccordion2}>
                            Eliminar
                          </span>
                        </div>
                      </div>
                    </div>
                  </AccordionDetails>
                </Accordion>
                {/* Finaliza acordeon Notificaciones */}
                {/* Inicia acordeon Noticias */}
                <Accordion
                  className={
                    tablaVeintiocho === true
                      ? `${styles.acordionBase} `
                      : `${styles.acordionBase}`
                  }
                >
                  <div className={styles.contenedorTitulosAccordion}>
                    <div>
                      <AccordionSummary
                        onClick={() => setTablaVeintiocho(!tablaVeintiocho)}
                      >
                        <div className={`${styles.contenedorIconoExpande}`}>
                          {tablaVeintiocho === true ? (
                            <img
                              className={`${styles.iconoExpande}`}
                              src={`/assets/svg/icono-expande-arriba.svg`}
                              alt="imagen"
                            />
                          ) : (
                            <img
                              className={`${styles.iconoExpande} ${styles.iconoAbajo}`}
                              src={`/assets/svg/icono-expande.svg`}
                              alt="imagen"
                            />
                          )}
                        </div>
                      </AccordionSummary>
                    </div>
                    <div className={styles.titulosAccordion}>
                      <span className={styles.tituloAccordion}>Noticias</span>
                    </div>
                    <div className={styles.contenedorSwitch}>
                      <Switch
                        NombreCampo={"Sidebar.Noticias"}
                        Value={() =>
                          handleConfirmPermission("Sidebar.Noticias")
                        }
                      />
                    </div>
                  </div>
                  <AccordionDetails style={{ padding: "8px 16px 0px" }}>
                    <div className={`${styles.filaChecks} ${styles.nivelDos}`}>
                      <div className={styles.contenedorChecks}>
                        <div className={styles.contenedorPrueba}>
                          <span className={styles.subTituloAccordion}>
                            Acciones
                          </span>
                        </div>
                        <div className={styles.contenedorPrueba}>
                          <BpCheckbox
                            NombreCampo={"Noticias.Ver"}
                            Value={() =>
                              handleConfirmPermission("Noticias.Ver")
                            }
                          />
                          <span className={styles.subTituloAccordion}>Ver</span>
                          <BpCheckbox
                            NombreCampo={"Noticias.Crear"}
                            Value={() =>
                              handleConfirmPermission("Noticias.Crear")
                            }
                          />
                          <span className={styles.subTituloAccordion}>
                            Agregar
                          </span>
                          <BpCheckbox
                            NombreCampo={"Noticias.Editar"}
                            Value={() =>
                              handleConfirmPermission("Noticias.Editar")
                            }
                          />
                          <span className={styles.subTituloAccordion}>
                            Editar
                          </span>
                          <BpCheckbox
                            NombreCampo={"Noticias.Eliminar"}
                            Value={() =>
                              handleConfirmPermission("Noticias.Eliminar")
                            }
                          />
                          <span className={styles.subTituloAccordion}>
                            Eliminar
                          </span>
                        </div>
                      </div>
                    </div>
                  </AccordionDetails>
                </Accordion>
                {/* Finaliza acordeon Noticias */}
                {/* Inicia acordeon Calendario */}

                <Accordion
                  className={
                    tablaVeintinueve === true
                      ? `${styles.acordionBase} `
                      : `${styles.acordionBase}`
                  }
                >
                  <div className={styles.contenedorTitulosAccordion}>
                    <div>
                      <AccordionSummary
                        onClick={() => setTablaVeintinueve(!tablaVeintinueve)}
                      // expandIcon={<ExpandMoreIcon />}
                      >
                        <div className={`${styles.contenedorIconoExpande}`}>
                          {tablaVeintinueve === true ? (
                            <img
                              className={`${styles.iconoExpande}`}
                              src={`/assets/svg/icono-expande-arriba.svg`}
                              alt="imagen"
                            />
                          ) : (
                            <img
                              className={`${styles.iconoExpande} ${styles.iconoAbajo}`}
                              src={`/assets/svg/icono-expande.svg`}
                              alt="imagen"
                            />
                          )}
                        </div>
                      </AccordionSummary>
                    </div>
                    <div className={styles.titulosAccordion}>
                      <span className={styles.tituloAccordion}>Calendario</span>
                    </div>
                    <div className={styles.contenedorSwitch}>
                      <Switch
                        NombreCampo={"Sidebar.Calendario"}
                        Value={() =>
                          handleConfirmPermission("Sidebar.Calendario")
                        }
                      />
                    </div>
                  </div>
                  <AccordionDetails style={{ padding: "8px 16px 0px" }}>
                    <div className={`${styles.filaChecks} ${styles.nivelDos}`}>
                      <div className={styles.contenedorChecks}>
                        <div className={styles.contenedorPrueba}>
                          <span className={styles.subTituloAccordion}>
                            Acciones
                          </span>
                        </div>
                        <div className={styles.contenedorPrueba}>
                          <BpCheckbox
                            NombreCampo={"Calendario.Crear"}
                            Value={() =>
                              handleConfirmPermission("Calendario.Crear")
                            }
                          />
                          <span className={styles.subTituloAccordion2}>
                            Agregar
                          </span>
                          <BpCheckbox
                            NombreCampo={"Calendario.AgregarDiaInhabil"}
                            Value={() =>
                              handleConfirmPermission(
                                "Calendario.AgregarDiaInhabil"
                              )
                            }
                          />
                          <span className={styles.subTituloAccordion2}>
                            Día inhabil
                          </span>
                          <BpCheckbox
                            NombreCampo={"Calendario.EditarCalendario"}
                            Value={() =>
                              handleConfirmPermission(
                                "Calendario.EditarCalendario"
                              )
                            }
                          />
                          <span className={styles.subTituloAccordion2}>
                            Editar
                          </span>
                          <BpCheckbox
                            NombreCampo={"Calendario.EliminarCalendario"}
                            Value={() =>
                              handleConfirmPermission(
                                "Calendario.EliminarCalendario"
                              )
                            }
                          />
                          <span className={styles.subTituloAccordion2}>
                            Eliminar
                          </span>
                        </div>
                      </div>
                    </div>
                  </AccordionDetails>
                  <Accordion
                    className={
                      tablaTreinta === true
                        ? `${styles.acordionBase} $ ${styles.BackgrounNivelDos} `
                        : `${styles.acordionBase} $ ${styles.BackgrounNivelDos}`
                    }
                  >
                    <div
                      className={`${styles.contenedorTitulosAccordion} ${styles.nivelDosMenu}`}
                    >
                      <div>
                        <AccordionSummary
                          onClick={() => setTablaTreinta(!tablaTreinta)}
                        // expandIcon={<ExpandMoreIcon />}
                        >
                          <div className={`${styles.contenedorIconoExpande}`}>
                            {tablaTreinta === true ? (
                              <img
                                className={`${styles.iconoExpande}`}
                                src={`/assets/svg/icono-expande-arriba.svg`}
                                alt="imagen"
                              />
                            ) : (
                              <img
                                className={`${styles.iconoExpande} ${styles.iconoAbajo}`}
                                src={`/assets/svg/icono-expande.svg`}
                                alt="imagen"
                              />
                            )}
                          </div>
                        </AccordionSummary>
                      </div>
                      <div className={styles.titulosAccordion}>
                        <span className={styles.tituloAccordion}>
                          Ver Calendario
                        </span>
                      </div>
                      <div className={styles.contenedorSwitch}>
                        <Switch
                          NombreCampo={"Calendario.Ver "}
                          Value={() =>
                            handleConfirmPermission("Calendario.Ver")
                          }
                        />
                      </div>
                    </div>
                    <AccordionDetails style={{ padding: "8px 16px 0px" }}>
                      <div
                        className={`${styles.filaChecks} ${styles.nivelDos}`}
                      >
                        <div className={styles.contenedorChecks}>
                          <div className={styles.contenedorPrueba}>
                            <span className={styles.subTituloAccordion}>
                              Acciones
                            </span>
                          </div>
                          <div className={styles.contenedorPrueba}>
                            <BpCheckbox
                              NombreCampo={"Calendario.Ver.AgregarDiaInhabil"}
                              Value={() =>
                                handleConfirmPermission(
                                  "Calendario.Ver.AgregarDiaInhabil"
                                )
                              }
                            />
                            <span className={styles.subTituloAccordion2}>
                              Día inhabil
                            </span>
                            <BpCheckbox
                              NombreCampo={"Calendario.Ver.AgregarIncidencias"}
                              Value={() =>
                                handleConfirmPermission(
                                  "Calendario.Ver.AgregarIncidencias"
                                )
                              }
                            />
                            <span className={styles.subTituloAccordion2}>
                              Incidencias
                            </span>
                            <BpCheckbox
                              NombreCampo={"Calendario.Ver.Editar"}
                              Value={() =>
                                handleConfirmPermission("Calendario.Ver.Editar")
                              }
                            />
                            <span className={styles.subTituloAccordion2}>
                              Editar
                            </span>
                            <BpCheckbox
                              NombreCampo={"Calendario.Ver.Eliminar"}
                              Value={() =>
                                handleConfirmPermission(
                                  "Calendario.Ver.Eliminar"
                                )
                              }
                            />
                            <span className={styles.subTituloAccordion2}>
                              Eliminar
                            </span>
                          </div>
                        </div>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </Accordion>

                {/* Finaliza acordeon Calendario */}
                {/* Inicia acordeon Organigrama */}
                <Accordion
                  className={
                    tablaTreintaiuno === true
                      ? `${styles.acordionBase} `
                      : `${styles.acordionBase}`
                  }
                >
                  <div className={styles.contenedorTitulosAccordion}>
                    <div>
                      <AccordionSummary
                        onClick={() => setTablaTreintaiuno(!tablaTreintaiuno)}
                      >
                        <div className={`${styles.contenedorIconoExpande}`}>
                          {tablaTreintaiuno === true ? (
                            <img
                              className={`${styles.iconoExpande}`}
                              src={`/assets/svg/icono-expande-arriba.svg`}
                              alt="imagen"
                            />
                          ) : (
                            <img
                              className={`${styles.iconoExpande} ${styles.iconoAbajo}`}
                              src={`/assets/svg/icono-expande.svg`}
                              alt="imagen"
                            />
                          )}
                        </div>
                      </AccordionSummary>
                    </div>
                    <div className={styles.titulosAccordion}>
                      <span className={styles.tituloAccordion}>
                        Organigrama
                      </span>
                    </div>
                    <div className={styles.contenedorSwitch}>
                      <Switch
                        NombreCampo={"Sidebar.Organigrama"}
                        Value={() =>
                          handleConfirmPermission("Sidebar.Organigrama")
                        }
                      />
                    </div>
                  </div>
                  <AccordionDetails style={{ padding: "8px 16px 0px" }}>
                    <div className={`${styles.filaChecks} ${styles.nivelDos}`}>
                      <div className={styles.contenedorChecks}>
                        <div className={styles.contenedorPrueba}>
                          <span className={styles.subTituloAccordion}>
                            Puesto
                          </span>
                        </div>
                        <div className={styles.contenedorPrueba}>
                          <BpCheckbox
                            NombreCampo={"Organigrama.CrearPuesto"}
                            Value={() =>
                              handleConfirmPermission("Organigrama.CrearPuesto")
                            }
                          />
                          <span className={styles.subTituloAccordion}>
                            Crear
                          </span>
                          <BpCheckbox
                            NombreCampo={"Organigrama.EditarPuesto"}
                            Value={() =>
                              handleConfirmPermission(
                                "Organigrama.EditarPuesto"
                              )
                            }
                          />
                          <span className={styles.subTituloAccordion}>
                            Editar
                          </span>
                          <BpCheckbox
                            NombreCampo={"Organigrama.EliminarPuesto"}
                            Value={() =>
                              handleConfirmPermission(
                                "Organigrama.EliminarPuesto"
                              )
                            }
                          />
                          <span className={styles.subTituloAccordion}>
                            Eliminar
                          </span>
                        </div>
                      </div>
                    </div>
                  </AccordionDetails>
                </Accordion>
                {/* Finaliza acordeon Organigrama */}
                {/* Inicia acordeon Nominas */}
                <Accordion
                  className={
                    tablaTreintaidos === true
                      ? `${styles.acordionBase} `
                      : `${styles.acordionBase}`
                  }
                >
                  <div className={styles.contenedorTitulosAccordion}>
                    <div>
                      <AccordionSummary
                        onClick={() => setTablaTreintaidos(!tablaTreintaidos)}
                      >
                        <div className={`${styles.contenedorIconoExpande}`}>
                          {tablaTreintaidos === true ? (
                            <img
                              className={`${styles.iconoExpande}`}
                              src={`/assets/svg/icono-expande-arriba.svg`}
                              alt="imagen"
                            />
                          ) : (
                            <img
                              className={`${styles.iconoExpande} ${styles.iconoAbajo}`}
                              src={`/assets/svg/icono-expande.svg`}
                              alt="imagen"
                            />
                          )}
                        </div>
                      </AccordionSummary>
                    </div>
                    <div className={styles.titulosAccordion}>
                      <span className={styles.tituloAccordion}>Nominas</span>
                    </div>
                    <div className={styles.contenedorSwitch}>
                      <Switch
                        NombreCampo={"Sidebar.Nominas"}
                        Value={() => handleConfirmPermission("Sidebar.Nominas")}
                      />
                    </div>
                  </div>

                  <Accordion
                    className={
                      tablaTreintaitres === true
                        ? `${styles.acordionBase} `
                        : `${styles.acordionBase}`
                    }
                  >
                    <div
                      className={`${styles.contenedorTitulosAccordion} ${styles.nivelDosMenu}`}
                    >
                      <div>
                        <AccordionSummary
                          onClick={() =>
                            setTablaTreintaitres(!tablaTreintaitres)
                          }
                        >
                          <div className={`${styles.contenedorIconoExpande}`}>
                            {tablaTreintaitres === true ? (
                              <img
                                className={`${styles.iconoExpande}`}
                                src={`/assets/svg/icono-expande-arriba.svg`}
                                alt="imagen"
                              />
                            ) : (
                              <img
                                className={`${styles.iconoExpande} ${styles.iconoAbajo}`}
                                src={`/assets/svg/icono-expande.svg`}
                                alt="imagen"
                              />
                            )}
                          </div>
                        </AccordionSummary>
                      </div>
                      <div className={styles.titulosAccordion}>
                        <span className={styles.tituloAccordion}>
                          Pre-nómina
                        </span>
                      </div>
                      <div className={styles.contenedorSwitch}>
                        <Switch
                          NombreCampo={"Nominas.PreNomina"}
                          Value={() =>
                            handleConfirmPermission("Nominas.PreNomina")
                          }
                        />
                      </div>
                    </div>

                    <AccordionDetails style={{ padding: "8px 16px 0px" }}>
                      <div
                        className={`${styles.filaChecks} ${styles.nivelDos}`}
                      >
                        <div className={styles.contenedorChecks}>
                          <div className={styles.contenedorPrueba}>
                            <span className={styles.subTituloAccordion}>
                              Pre-nómina
                            </span>
                          </div>
                          <div className={styles.contenedorPrueba}>
                            <BpCheckbox
                              NombreCampo={"Nominas.PreNomina.Crear"}
                              Value={() =>
                                handleConfirmPermission(
                                  "Nominas.PreNomina.Crear"
                                )
                              }
                            />
                            <span className={styles.subTituloAccordion}>
                              Crear
                            </span>
                            <BpCheckbox
                              NombreCampo={"Nominas.PreNomina.Ver"}
                              Value={() =>
                                handleConfirmPermission("Nominas.PreNomina.Ver")
                              }
                            />
                            <span className={styles.subTituloAccordion}>
                              Ver
                            </span>
                            <BpCheckbox
                              NombreCampo={"Nominas.PreNomina.Eliminar"}
                              Value={() =>
                                handleConfirmPermission(
                                  "Nominas.PreNomina.Eliminar"
                                )
                              }
                            />
                            <span className={styles.subTituloAccordion}>
                              Eliminar
                            </span>
                          </div>
                        </div>
                      </div>
                    </AccordionDetails>

                    <AccordionDetails style={{ padding: "8px 16px 0px" }}>
                      <div
                        className={`${styles.filaChecks} ${styles.nivelDos}`}
                      >
                        <div className={styles.contenedorChecks}>
                          <div className={styles.contenedorPrueba}>
                            <span className={styles.subTituloAccordion}>
                              Calcular
                            </span>
                          </div>
                          <div className={styles.contenedorPrueba}>
                            <BpCheckbox
                              NombreCampo={
                                "Nominas.PreNomina.Ver.Calcular.Calcular"
                              }
                              Value={() =>
                                handleConfirmPermission(
                                  "Nominas.PreNomina.Ver.Calcular.Calcular"
                                )
                              }
                            />
                            <span className={styles.subTituloAccordion}>
                              Calcular
                            </span>
                            <BpCheckbox
                              NombreCampo={
                                "Nominas.PreNomina.Ver.Calcular.AgregarIncidencia"
                              }
                              Value={() =>
                                handleConfirmPermission(
                                  "Nominas.PreNomina.Ver.Calcular.AgregarIncidencia"
                                )
                              }
                            />
                            <span className={styles.subTituloAccordion}>
                              Agregar incidencia
                            </span>
                            <BpCheckbox
                              NombreCampo={
                                "Nominas.PreNomina.Ver.Calcular.IrADispersar"
                              }
                              Value={() =>
                                handleConfirmPermission(
                                  "Nominas.PreNomina.Ver.Calcular.IrADispersar"
                                )
                              }
                            />
                            <span className={styles.subTituloAccordion}>
                              Ir a dispersar
                            </span>
                          </div>
                        </div>
                      </div>
                    </AccordionDetails>
                    <AccordionDetails style={{ padding: "8px 16px 0px" }}>
                      <div
                        className={`${styles.filaChecks} ${styles.nivelDos}`}
                      >
                        <div className={styles.contenedorChecks}>
                          <div className={styles.contenedorPrueba}>
                            <span className={styles.subTituloAccordion}>
                              Dispersar
                            </span>
                          </div>
                          <div className={styles.contenedorPrueba}>
                            <BpCheckbox
                              NombreCampo={
                                "Nominas.PreNomina.Ver.Dispersar.Outsourcers"
                              }
                              Value={() =>
                                handleConfirmPermission(
                                  "Nominas.PreNomina.Ver.Dispersar.Outsourcers"
                                )
                              }
                            />
                            <span className={styles.subTituloAccordion}>
                              Outsourcers
                            </span>
                            <BpCheckbox
                              NombreCampo={
                                "Nominas.PreNomina.Ver.Dispersar.Dispersar"
                              }
                              Value={() =>
                                handleConfirmPermission(
                                  "Nominas.PreNomina.Ver.Dispersar.Dispersar"
                                )
                              }
                            />
                            <span className={styles.subTituloAccordion}>
                              Timbrar
                            </span>
                            <BpCheckbox
                              NombreCampo={
                                "Nominas.PreNomina.Ver.Dispersar.IrATimbrar"
                              }
                              Value={() =>
                                handleConfirmPermission(
                                  "Nominas.PreNomina.Ver.Dispersar.IrATimbrar"
                                )
                              }
                            />
                            <span className={styles.subTituloAccordion}>
                              Ir a Timbrar
                            </span>
                          </div>
                        </div>
                      </div>
                    </AccordionDetails>
                    <AccordionDetails style={{ padding: "8px 16px 0px" }}>
                      <div
                        className={`${styles.filaChecks} ${styles.nivelDos}`}
                      >
                        <div className={styles.contenedorChecks}>
                          <div className={styles.contenedorPrueba}>
                            <span className={styles.subTituloAccordion}>
                              Timbrar
                            </span>
                          </div>
                          <div className={styles.contenedorPrueba}>
                            <BpCheckbox
                              NombreCampo={
                                "Nominas.PreNomina.Ver.Timbrar.Timbrar"
                              }
                              Value={() =>
                                handleConfirmPermission(
                                  "Nominas.PreNomina.Ver.Timbrar.Timbrar"
                                )
                              }
                            />
                            <span className={styles.subTituloAccordion}>
                              Timbrar
                            </span>
                            <BpCheckbox
                              NombreCampo={
                                "Nominas.PreNomina.Ver.Timbrar.Terminar"
                              }
                              Value={() =>
                                handleConfirmPermission(
                                  "Nominas.PreNomina.Ver.Timbrar.Terminar"
                                )
                              }
                            />
                            <span className={styles.subTituloAccordion}>
                              Terminar
                            </span>
                          </div>
                        </div>
                      </div>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion
                    className={
                      tablaTreintaicuatro === true
                        ? `${styles.acordionBase} `
                        : `${styles.acordionBase}`
                    }
                  >
                    <div
                      className={`${styles.contenedorTitulosAccordion} ${styles.nivelDosMenu}`}
                    >
                      <div>
                        <AccordionSummary
                          onClick={() =>
                            setTablaTreintaicuatro(!tablaTreintaicuatro)
                          }
                        >
                          <div className={`${styles.contenedorIconoExpande}`}>
                            {tablaTreintaicuatro === true ? (
                              <img
                                className={`${styles.iconoExpande}`}
                                src={`/assets/svg/icono-expande-arriba.svg`}
                                alt="imagen"
                              />
                            ) : (
                              <img
                                className={`${styles.iconoExpande} ${styles.iconoAbajo}`}
                                src={`/assets/svg/icono-expande.svg`}
                                alt="imagen"
                              />
                            )}
                          </div>
                        </AccordionSummary>
                      </div>
                      <div className={styles.titulosAccordion}>
                        <span className={styles.tituloAccordion}>
                          Finiquito/Liquidación
                        </span>
                      </div>
                      <div className={styles.contenedorSwitch}>
                        <Switch
                          NombreCampo={"Nominas.FiniquitoLiquidacion"}
                          Value={() =>
                            handleConfirmPermission(
                              "Nominas.FiniquitoLiquidacion"
                            )
                          }
                        />
                      </div>
                    </div>

                    <AccordionDetails style={{ padding: "8px 16px 0px" }}>
                      <div
                        className={`${styles.filaChecks} ${styles.nivelDos}`}
                      >
                        <div className={styles.contenedorChecks}>
                          <div className={styles.contenedorPrueba}>
                            <span className={styles.subTituloAccordion}>
                              Acciones
                            </span>
                          </div>
                          <div className={styles.contenedorPrueba}>
                            <BpCheckbox
                              NombreCampo={"Nominas.FiniquitoLiquidacion.Crear"}
                              Value={() =>
                                handleConfirmPermission(
                                  "Nominas.FiniquitoLiquidacion.Crear"
                                )
                              }
                            />
                            <span className={styles.subTituloAccordion}>
                              Crear
                            </span>
                            <BpCheckbox
                              NombreCampo={"Nominas.FiniquitoLiquidacion.Ver"}
                              Value={() =>
                                handleConfirmPermission(
                                  "Nominas.FiniquitoLiquidacion.Ver"
                                )
                              }
                            />
                            <span className={styles.subTituloAccordion}>
                              Ver
                            </span>
                            <BpCheckbox
                              NombreCampo={
                                "Nominas.FiniquitoLiquidacion.Eliminar"
                              }
                              Value={() =>
                                handleConfirmPermission(
                                  "Nominas.FiniquitoLiquidacion.Eliminar"
                                )
                              }
                            />
                            <span className={styles.subTituloAccordion}>
                              Eliminar
                            </span>
                          </div>
                        </div>
                      </div>
                    </AccordionDetails>
                    <AccordionDetails style={{ padding: "8px 16px 0px" }}>
                      <div
                        className={`${styles.filaChecks} ${styles.nivelDos}`}
                      >
                        <div className={styles.contenedorChecks}>
                          <div className={styles.contenedorPrueba}>
                            <span className={styles.subTituloAccordion}>
                              Calcular
                            </span>
                          </div>
                          <div className={styles.contenedorPrueba}>
                            <BpCheckbox
                              NombreCampo={
                                "Nominas.FiniquitoLiquidacion.Ver.Calcular.Calcular"
                              }
                              Value={() =>
                                handleConfirmPermission(
                                  "Nominas.FiniquitoLiquidacion.Ver.Calcular.Calcular"
                                )
                              }
                            />
                            <span className={styles.subTituloAccordion}>
                              Calcular
                            </span>
                            <BpCheckbox
                              NombreCampo={
                                "Nominas.FiniquitoLiquidacion.Ver.Calcular.IrADispersar"
                              }
                              Value={() =>
                                handleConfirmPermission(
                                  "Nominas.FiniquitoLiquidacion.Ver.Calcular.IrADispersar"
                                )
                              }
                            />
                            <span className={styles.subTituloAccordion}>
                              Ir a dispersar
                            </span>
                          </div>
                        </div>
                      </div>
                    </AccordionDetails>
                    <AccordionDetails style={{ padding: "8px 16px 0px" }}>
                      <div
                        className={`${styles.filaChecks} ${styles.nivelDos}`}
                      >
                        <div className={styles.contenedorChecks}>
                          <div className={styles.contenedorPrueba}>
                            <span className={styles.subTituloAccordion}>
                              Dispersar
                            </span>
                          </div>
                          <div className={styles.contenedorPrueba}>
                            <BpCheckbox
                              NombreCampo={
                                "Nominas.FiniquitoLiquidacion.Ver.Dispersar.Dispersar"
                              }
                              Value={() =>
                                handleConfirmPermission(
                                  "Nominas.FiniquitoLiquidacion.Ver.Dispersar.Dispersar"
                                )
                              }
                            />
                            <span className={styles.subTituloAccordion}>
                              Dispersar
                            </span>
                            <BpCheckbox
                              NombreCampo={
                                "Nominas.FiniquitoLiquidacion.Ver.Dispersar.IrATimbrar"
                              }
                              Value={() =>
                                handleConfirmPermission(
                                  "Nominas.FiniquitoLiquidacion.Ver.Dispersar.IrATimbrar"
                                )
                              }
                            />
                            <span className={styles.subTituloAccordion}>
                              Ir a timbrar
                            </span>
                          </div>
                        </div>
                      </div>
                    </AccordionDetails>
                    <AccordionDetails style={{ padding: "8px 16px 0px" }}>
                      <div
                        className={`${styles.filaChecks} ${styles.nivelDos}`}
                      >
                        <div className={styles.contenedorChecks}>
                          <div className={styles.contenedorPrueba}>
                            <span className={styles.subTituloAccordion}>
                              Timbrar
                            </span>
                          </div>
                          <div className={styles.contenedorPrueba}>
                            <BpCheckbox
                              NombreCampo={
                                "Nominas.FiniquitoLiquidacion.Ver.Timbrar.Timbrar"
                              }
                              Value={() =>
                                handleConfirmPermission(
                                  "Nominas.FiniquitoLiquidacion.Ver.Timbrar.Timbrar"
                                )
                              }
                            />
                            <span className={styles.subTituloAccordion}>
                              Timbrar
                            </span>
                            <BpCheckbox
                              NombreCampo={
                                "Nominas.FiniquitoLiquidacion.Ver.Timbrar.Timbrar.Terminar"
                              }
                              Value={() =>
                                handleConfirmPermission(
                                  "Nominas.FiniquitoLiquidacion.Ver.Timbrar.Timbrar.Terminar"
                                )
                              }
                            />
                            <span className={styles.subTituloAccordion}>
                              Terminar
                            </span>
                          </div>
                        </div>
                      </div>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion
                    className={
                      tablaTreintaicinco === true
                        ? `${styles.acordionBase} `
                        : `${styles.acordionBase}`
                    }
                  >
                    <div
                      className={`${styles.contenedorTitulosAccordion} ${styles.nivelDosMenu}`}
                    >
                      <div>
                        <AccordionSummary
                          onClick={() =>
                            setTablaTreintaicinco(!tablaTreintaicinco)
                          }
                        >
                          <div className={`${styles.contenedorIconoExpande}`}>
                            {tablaTreintaicinco === true ? (
                              <img
                                className={`${styles.iconoExpande}`}
                                src={`/assets/svg/icono-expande-arriba.svg`}
                                alt="imagen"
                              />
                            ) : (
                              <img
                                className={`${styles.iconoExpande} ${styles.iconoAbajo}`}
                                src={`/assets/svg/icono-expande.svg`}
                                alt="imagen"
                              />
                            )}
                          </div>
                        </AccordionSummary>
                      </div>
                      <div className={styles.titulosAccordion}>
                        <span className={styles.tituloAccordion}>
                          Eventuales
                        </span>
                      </div>
                      <div className={styles.contenedorSwitch}>
                        <Switch
                          NombreCampo={"Nominas.Eventuales"}
                          Value={() =>
                            handleConfirmPermission("Nominas.Eventuales")
                          }
                        />
                      </div>
                    </div>
                    <AccordionDetails style={{ padding: "8px 16px 0px" }}>
                      <div
                        className={`${styles.filaChecks} ${styles.nivelDos}`}
                      >
                        <div className={styles.contenedorChecks}>
                          <div className={styles.contenedorPrueba}>
                            <span className={styles.subTituloAccordion}>
                              Acciones
                            </span>
                          </div>
                          <div className={styles.contenedorPrueba}>
                            <BpCheckbox
                              NombreCampo={"Nominas.Eventuales.Crear"}
                              Value={() =>
                                handleConfirmPermission(
                                  "Nominas.Eventuales.Crear"
                                )
                              }
                            />
                            <span className={styles.subTituloAccordion}>
                              Crear
                            </span>
                            <BpCheckbox
                              NombreCampo={"Nominas.Eventuales.Ver"}
                              Value={() =>
                                handleConfirmPermission(
                                  "Nominas.Eventuales.Ver"
                                )
                              }
                            />
                            <span className={styles.subTituloAccordion}>
                              Ver
                            </span>
                            <BpCheckbox
                              NombreCampo={"Nominas.Eventuales.Eliminar"}
                              Value={() =>
                                handleConfirmPermission(
                                  "Nominas.Eventuales.Eliminar"
                                )
                              }
                            />
                            <span className={styles.subTituloAccordion}>
                              Eliminar
                            </span>
                          </div>
                        </div>
                      </div>
                    </AccordionDetails>
                    <AccordionDetails style={{ padding: "8px 16px 0px" }}>
                      <div
                        className={`${styles.filaChecks} ${styles.nivelDos}`}
                      >
                        <div className={styles.contenedorChecks}>
                          <div className={styles.contenedorPrueba}>
                            <span className={styles.subTituloAccordion}>
                              Calcular
                            </span>
                          </div>
                          <div className={styles.contenedorPrueba}>
                            <BpCheckbox
                              NombreCampo={
                                "Nominas.Eventuales.Calcular.Calcular"
                              }
                              Value={() =>
                                handleConfirmPermission(
                                  "Nominas.Eventuales.Calcular.Calcular"
                                )
                              }
                            />
                            <span className={styles.subTituloAccordion}>
                              Calcular
                            </span>
                            <BpCheckbox
                              NombreCampo={"Nominas.Eventuales.IrADispersar"}
                              Value={() =>
                                handleConfirmPermission(
                                  "Nominas.Eventuales.IrADispersar"
                                )
                              }
                            />
                            <span className={styles.subTituloAccordion}>
                              Ir a dispersar
                            </span>
                          </div>
                        </div>
                      </div>
                    </AccordionDetails>
                    <AccordionDetails style={{ padding: "8px 16px 0px" }}>
                      <div
                        className={`${styles.filaChecks} ${styles.nivelDos}`}
                      >
                        <div className={styles.contenedorChecks}>
                          <div className={styles.contenedorPrueba}>
                            <span className={styles.subTituloAccordion}>
                              Dispersar
                            </span>
                          </div>
                          <div className={styles.contenedorPrueba}>
                            <BpCheckbox
                              NombreCampo={
                                "Nominas.Eventuales.Dispersar.Dispersar"
                              }
                              Value={() =>
                                handleConfirmPermission(
                                  "Nominas.Eventuales.Dispersar.Dispersar"
                                )
                              }
                            />
                            <span className={styles.subTituloAccordion}>
                              Dispersar
                            </span>
                            <BpCheckbox
                              NombreCampo={
                                "Nominas.Eventuales.Dispersar.IrATimbrar"
                              }
                              Value={() =>
                                handleConfirmPermission(
                                  "Nominas.Eventuales.Dispersar.IrATimbrar"
                                )
                              }
                            />
                            <span className={styles.subTituloAccordion}>
                              Ir a timbrar
                            </span>
                          </div>
                        </div>
                      </div>
                    </AccordionDetails>
                    <AccordionDetails style={{ padding: "8px 16px 0px" }}>
                      <div
                        className={`${styles.filaChecks} ${styles.nivelDos}`}
                      >
                        <div className={styles.contenedorChecks}>
                          <div className={styles.contenedorPrueba}>
                            <span className={styles.subTituloAccordion}>
                              Timbrar
                            </span>
                          </div>
                          <div className={styles.contenedorPrueba}>
                            <BpCheckbox
                              NombreCampo={"Nominas.Eventuales.Timbrar.Timbrar"}
                              Value={() =>
                                handleConfirmPermission(
                                  "Nominas.Eventuales.Timbrar.Timbrar"
                                )
                              }
                            />
                            <span className={styles.subTituloAccordion}>
                              Timbrar
                            </span>
                            <BpCheckbox
                              NombreCampo={
                                "Nominas.Eventuales.Timbrar.Terminar"
                              }
                              Value={() =>
                                handleConfirmPermission(
                                  "Nominas.Eventuales.Timbrar.Terminar"
                                )
                              }
                            />
                            <span className={styles.subTituloAccordion}>
                              Terminar
                            </span>
                          </div>
                        </div>
                      </div>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion
                    className={
                      tablaTreintaiseis === true
                        ? `${styles.acordionBase} `
                        : `${styles.acordionBase}`
                    }
                  >
                    <div
                      className={`${styles.contenedorTitulosAccordion} ${styles.nivelDosMenu}`}
                    >
                      <div>
                        <AccordionSummary
                          onClick={() =>
                            setTablaTreintaiseis(!tablaTreintaiseis)
                          }
                        >
                          <div className={`${styles.contenedorIconoExpande}`}>
                            {tablaTreintaiseis === true ? (
                              <img
                                className={`${styles.iconoExpande}`}
                                src={`/assets/svg/icono-expande-arriba.svg`}
                                alt="imagen"
                              />
                            ) : (
                              <img
                                className={`${styles.iconoExpande} ${styles.iconoAbajo}`}
                                src={`/assets/svg/icono-expande.svg`}
                                alt="imagen"
                              />
                            )}
                          </div>
                        </AccordionSummary>
                      </div>
                      <div className={styles.titulosAccordion}>
                        <span className={styles.tituloAccordion}>
                          Históricas
                        </span>
                      </div>
                      <div className={styles.contenedorSwitch}>
                        <Switch
                          NombreCampo={"Nominas.Historicas"}
                          Value={() =>
                            handleConfirmPermission("Nominas.Historicas")
                          }
                        />
                      </div>
                    </div>
                    <AccordionDetails style={{ padding: "8px 16px 0px" }}>
                      <div
                        className={`${styles.filaChecks} ${styles.nivelDos}`}
                      >
                        <div className={styles.contenedorChecks}>
                          <div className={styles.contenedorPrueba}>
                            <span className={styles.subTituloAccordion}>
                              Detalle de nómina
                            </span>
                          </div>
                          <div className={styles.contenedorPrueba}>
                            <BpCheckbox
                              NombreCampo={
                                "Nominas.Historicas.DetalleNomina.Nomina"
                              }
                              Value={() =>
                                handleConfirmPermission(
                                  "Nominas.Historicas.DetalleNomina.Nomina"
                                )
                              }
                            />
                            <span className={styles.subTituloAccordion}>
                              Nómina
                            </span>
                          </div>
                        </div>
                      </div>
                    </AccordionDetails>
                    <AccordionDetails style={{ padding: "8px 16px 0px" }}>
                      <div
                        className={`${styles.filaChecks} ${styles.nivelDos}`}
                      >
                        <div className={styles.contenedorChecks}>
                          <div className={styles.contenedorPrueba}>
                            <span className={styles.subTituloAccordion}>
                              Reportes
                            </span>
                          </div>
                          <div className={styles.contenedorPrueba}>
                            <BpCheckbox
                              NombreCampo={"Nominas.Historicas.ReporteNomina"}
                              Value={() =>
                                handleConfirmPermission(
                                  "Nominas.Historicas.ReporteNomina"
                                )
                              }
                            />
                            <span className={styles.subTituloAccordion2}>
                              Nomina
                            </span>
                            <BpCheckbox
                              NombreCampo={
                                "Nominas.Histroricas.ReporePolizaContable"
                              }
                              Value={() =>
                                handleConfirmPermission(
                                  "Nominas.Histroricas.ReporePolizaContable"
                                )
                              }
                            />
                            <span className={styles.subTituloAccordion2}>
                              Póliza contable
                            </span>
                            <BpCheckbox
                              NombreCampo={"Nominas.Historicas.ReporteSTP"}
                              Value={() =>
                                handleConfirmPermission(
                                  "Nominas.Historicas.ReporteSTP"
                                )
                              }
                            />
                            <span className={styles.subTituloAccordion2}>
                              STP
                            </span>
                            <BpCheckbox
                              NombreCampo={"Nominas.Historicas.ReciboNominaZIP"}
                              Value={() =>
                                handleConfirmPermission(
                                  "Nominas.Historicas.ReciboNominaZIP"
                                )
                              }
                            />
                            <span className={styles.subTituloAccordion2}>
                              ZIP
                            </span>
                            <BpCheckbox
                              NombreCampo={"Nominas.Historicas.ReporteCEP"}
                              Value={() =>
                                handleConfirmPermission(
                                  "Nominas.Historicas.ReporteCEP"
                                )
                              }
                            />
                            <span className={styles.subTituloAccordion2}>
                              CEP
                            </span>
                          </div>
                        </div>
                      </div>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion
                    className={
                      tablaTreintaisiete === true
                        ? `${styles.acordionBase} `
                        : `${styles.acordionBase}`
                    }
                  >
                    <div
                      className={`${styles.contenedorTitulosAccordion} ${styles.nivelDosMenu}`}
                    >
                      <div>
                        <AccordionSummary
                          onClick={() =>
                            setTablaTreintaisiete(!tablaTreintaisiete)
                          }
                        >
                          <div className={`${styles.contenedorIconoExpande}`}>
                            {tablaTreintaisiete === true ? (
                              <img
                                className={`${styles.iconoExpande}`}
                                src={`/assets/svg/icono-expande-arriba.svg`}
                                alt="imagen"
                              />
                            ) : (
                              <img
                                className={`${styles.iconoExpande} ${styles.iconoAbajo}`}
                                src={`/assets/svg/icono-expande.svg`}
                                alt="imagen"
                              />
                            )}
                          </div>
                        </AccordionSummary>
                      </div>
                      <div className={styles.titulosAccordion}>
                        <span className={styles.tituloAccordion}>
                          Calculadora
                        </span>
                      </div>
                      <div className={styles.contenedorSwitch}>
                        <Switch
                          NombreCampo={"Nominas.Calculadora"}
                          Value={() =>
                            handleConfirmPermission("Nominas.Calculadora")
                          }
                        />
                      </div>
                    </div>
                  </Accordion>

                  <Accordion
                    className={
                      tablaTreintaiocho === true
                        ? `${styles.acordionBase} `
                        : `${styles.acordionBase}`
                    }
                  >
                    <div
                      className={`${styles.contenedorTitulosAccordion} ${styles.nivelDosMenu}`}
                    >
                      <div>
                        <AccordionSummary
                          onClick={() =>
                            setTablaTreintaiocho(!tablaTreintaiocho)
                          }
                        >
                          <div className={`${styles.contenedorIconoExpande}`}>
                            {tablaTreintaiocho === true ? (
                              <img
                                className={`${styles.iconoExpande}`}
                                src={`/assets/svg/icono-expande-arriba.svg`}
                                alt="imagen"
                              />
                            ) : (
                              <img
                                className={`${styles.iconoExpande} ${styles.iconoAbajo}`}
                                src={`/assets/svg/icono-expande.svg`}
                                alt="imagen"
                              />
                            )}
                          </div>
                        </AccordionSummary>
                      </div>
                      <div className={styles.titulosAccordion}>
                        <span className={styles.tituloAccordion}>Reportes</span>
                      </div>
                      <div className={styles.contenedorSwitch}>
                        <Switch
                          NombreCampo={"Nominas.Reportes"}
                          Value={() =>
                            handleConfirmPermission("Nominas.Reportes")
                          }
                        />
                      </div>
                    </div>
                  </Accordion>
                </Accordion>

                <PermissionAccordion title="Outsourcers" permissions={permisos} permissionName="Outsourcers"></PermissionAccordion>
                <PermissionAccordion title="Central de Pagos" permissions={permisos} permissionName="CentralPago"></PermissionAccordion>
                
                <Accordion
                  className={
                    tablaTreintainueve === true
                      ? `${styles.acordionBase} `
                      : `${styles.acordionBase}`
                  }
                >
                  <div className={styles.contenedorTitulosAccordion}>
                    <div>
                      <AccordionSummary
                        onClick={() =>
                          setTablaTreintainueve(!tablaTreintainueve)
                        }
                      >
                        <div className={`${styles.contenedorIconoExpande}`}>
                          {tablaTreintainueve === true ? (
                            <img
                              className={`${styles.iconoExpande}`}
                              src={`/assets/svg/icono-expande-arriba.svg`}
                              alt="imagen"
                            />
                          ) : (
                            <img
                              className={`${styles.iconoExpande} ${styles.iconoAbajo}`}
                              src={`/assets/svg/icono-expande.svg`}
                              alt="imagen"
                            />
                          )}
                        </div>
                      </AccordionSummary>
                    </div>
                    <div className={styles.titulosAccordion}>
                      <span className={styles.tituloAccordion}>
                        Configuración de nóminas
                      </span>
                    </div>
                    <div className={styles.contenedorSwitch}>
                      <Switch
                        NombreCampo={"Sidebar.ConfiguracionNominas"}
                        Value={() =>
                          handleConfirmPermission(
                            "Sidebar.ConfiguracionNominas"
                          )
                        }
                      />
                    </div>
                  </div>

                  <Accordion
                    className={
                      tablaCuarenta === true
                        ? `${styles.acordionBase} `
                        : `${styles.acordionBase}`
                    }
                  >
                    <div
                      className={`${styles.contenedorTitulosAccordion} ${styles.nivelDosMenu}`}
                    >
                      <div>
                        <AccordionSummary
                          onClick={() => setTablaCuarenta(!tablaCuarenta)}
                        >
                          <div className={`${styles.contenedorIconoExpande}`}>
                            {tablaCuarenta === true ? (
                              <img
                                className={`${styles.iconoExpande}`}
                                src={`/assets/svg/icono-expande-arriba.svg`}
                                alt="imagen"
                              />
                            ) : (
                              <img
                                className={`${styles.iconoExpande} ${styles.iconoAbajo}`}
                                src={`/assets/svg/icono-expande.svg`}
                                alt="imagen"
                              />
                            )}
                          </div>
                        </AccordionSummary>
                      </div>
                      <div className={styles.titulosAccordion}>
                        <span className={styles.tituloAccordion}>Nómina</span>
                      </div>
                      <div className={styles.contenedorSwitch}>
                        <Switch
                          NombreCampo={"ConfiguracionNominas.Nomina"}
                          Value={() =>
                            handleConfirmPermission(
                              "ConfiguracionNominas.Nomina"
                            )
                          }
                        />
                      </div>
                    </div>

                    <AccordionDetails style={{ padding: "8px 16px 0px" }}>
                      <div
                        className={`${styles.filaChecks} ${styles.nivelDos}`}
                      >
                        <div className={styles.contenedorChecks}>
                          <div className={styles.contenedorPrueba}>
                            <span className={styles.subTituloAccordion}>
                              Acciones
                            </span>
                          </div>
                          <div className={styles.contenedorPrueba}>
                            <BpCheckbox
                              NombreCampo={"ConfiguracionNominas.Nomina.Crear"}
                              Value={() =>
                                handleConfirmPermission(
                                  "ConfiguracionNominas.Nomina.Crear"
                                )
                              }
                            />
                            <span className={styles.subTituloAccordion}>
                              Crear
                            </span>
                            <BpCheckbox
                              NombreCampo={"ConfiguracionNominas.Nomina.Editar"}
                              Value={() =>
                                handleConfirmPermission(
                                  "ConfiguracionNominas.Nomina.Editar"
                                )
                              }
                            />
                            <span className={styles.subTituloAccordion}>
                              Editar
                            </span>
                            <BpCheckbox
                              NombreCampo={
                                "ConfiguracionNominas.Nomina.Eliminar"
                              }
                              Value={() =>
                                handleConfirmPermission(
                                  "ConfiguracionNominas.Nomina.Eliminar"
                                )
                              }
                            />
                            <span className={styles.subTituloAccordion}>
                              Eliminar
                            </span>
                          </div>
                        </div>
                      </div>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion
                    className={
                      tablaCuarentaiuno === true
                        ? `${styles.acordionBase} `
                        : `${styles.acordionBase}`
                    }
                  >
                    <div
                      className={`${styles.contenedorTitulosAccordion} ${styles.nivelDosMenu}`}
                    >
                      <div>
                        <AccordionSummary
                          onClick={() =>
                            setTablaCuarentaiuno(!tablaCuarentaiuno)
                          }
                        >
                          <div className={`${styles.contenedorIconoExpande}`}>
                            {tablaCuarentaiuno === true ? (
                              <img
                                className={`${styles.iconoExpande}`}
                                src={`/assets/svg/icono-expande-arriba.svg`}
                                alt="imagen"
                              />
                            ) : (
                              <img
                                className={`${styles.iconoExpande} ${styles.iconoAbajo}`}
                                src={`/assets/svg/icono-expande.svg`}
                                alt="imagen"
                              />
                            )}
                          </div>
                        </AccordionSummary>
                      </div>
                      <div className={styles.titulosAccordion}>
                        <span className={styles.tituloAccordion}>
                          Póliticas
                        </span>
                      </div>
                      <div className={styles.contenedorSwitch}>
                        <Switch
                          NombreCampo={"ConfiguracionNominas.Polticas"}
                          Value={() =>
                            handleConfirmPermission(
                              "ConfiguracionNominas.Polticas"
                            )
                          }
                        />
                      </div>
                    </div>

                    <AccordionDetails style={{ padding: "8px 16px 0px" }}>
                      <div
                        className={`${styles.filaChecks} ${styles.nivelDos}`}
                      >
                        <div className={styles.contenedorChecks}>
                          <div className={styles.contenedorPrueba}>
                            <span className={styles.subTituloAccordion}>
                              Acciones
                            </span>
                          </div>
                          <div className={styles.contenedorPrueba}>
                            <BpCheckbox
                              NombreCampo={
                                "ConfiguracionNominas.Politicas.Crear"
                              }
                              Value={() =>
                                handleConfirmPermission(
                                  "ConfiguracionNominas.Politicas.Crear"
                                )
                              }
                            />
                            <span className={styles.subTituloAccordion}>
                              Crear
                            </span>
                            <BpCheckbox
                              NombreCampo={
                                "ConfiguracionNominas.Politicas.Editar"
                              }
                              Value={() =>
                                handleConfirmPermission(
                                  "ConfiguracionNominas.Politicas.Editar"
                                )
                              }
                            />
                            <span className={styles.subTituloAccordion}>
                              Editar
                            </span>
                            <BpCheckbox
                              NombreCampo={
                                "ConfiguracionNominas.Politicas.Eliminar"
                              }
                              Value={() =>
                                handleConfirmPermission(
                                  "ConfiguracionNominas.Politicas.Eliminar"
                                )
                              }
                            />
                            <span className={styles.subTituloAccordion}>
                              Eliminar
                            </span>
                          </div>
                        </div>
                      </div>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion
                    className={
                      tablaCuarentaidos === true
                        ? `${styles.acordionBase} `
                        : `${styles.acordionBase}`
                    }
                  >
                    <div
                      className={`${styles.contenedorTitulosAccordion} ${styles.nivelDosMenu}`}
                    >
                      <div>
                        <AccordionSummary
                          onClick={() =>
                            setTablaCuarentaidos(!tablaCuarentaidos)
                          }
                        >
                          <div className={`${styles.contenedorIconoExpande}`}>
                            {tablaCuarentaidos === true ? (
                              <img
                                className={`${styles.iconoExpande}`}
                                src={`/assets/svg/icono-expande-arriba.svg`}
                                alt="imagen"
                              />
                            ) : (
                              <img
                                className={`${styles.iconoExpande} ${styles.iconoAbajo}`}
                                src={`/assets/svg/icono-expande.svg`}
                                alt="imagen"
                              />
                            )}
                          </div>
                        </AccordionSummary>
                      </div>
                      <div className={styles.titulosAccordion}>
                        <span className={styles.tituloAccordion}>
                          Conceptos
                        </span>
                      </div>
                      <div className={styles.contenedorSwitch}>
                        <Switch
                          NombreCampo={"ConfiguracionNominas.Conceptos"}
                          Value={() =>
                            handleConfirmPermission(
                              "ConfiguracionNominas.Conceptos"
                            )
                          }
                        />
                      </div>
                    </div>

                    <AccordionDetails style={{ padding: "8px 16px 0px" }}>
                      <div
                        className={`${styles.filaChecks} ${styles.nivelDos}`}
                      >
                        <div className={styles.contenedorChecks}>
                          <div className={styles.contenedorPrueba}>
                            <span className={styles.subTituloAccordion}>
                              Percepciones
                            </span>
                          </div>
                          <div className={styles.contenedorPrueba}>
                            <BpCheckbox
                              NombreCampo={
                                "ConfiguracionNominas.Conceptos.Percepciones.Crear"
                              }
                              Value={() =>
                                handleConfirmPermission(
                                  "ConfiguracionNominas.Conceptos.Percepciones.Crear"
                                )
                              }
                            />
                            <span className={styles.subTituloAccordion}>
                              Crear
                            </span>
                            <BpCheckbox
                              NombreCampo={
                                "ConfiguracionNominas.Conceptos.Percepciones.Editar"
                              }
                              Value={() =>
                                handleConfirmPermission(
                                  "ConfiguracionNominas.Conceptos.Percepciones.Editar"
                                )
                              }
                            />
                            <span className={styles.subTituloAccordion}>
                              Editar
                            </span>
                            <BpCheckbox
                              NombreCampo={
                                "ConfiguracionNominas.Conceptos.Percepciones.Eliminar"
                              }
                              Value={() =>
                                handleConfirmPermission(
                                  "ConfiguracionNominas.Conceptos.Percepciones.Eliminar"
                                )
                              }
                            />
                            <span className={styles.subTituloAccordion}>
                              Eliminar
                            </span>
                          </div>
                        </div>
                      </div>
                    </AccordionDetails>
                    <AccordionDetails style={{ padding: "8px 16px 0px" }}>
                      <div
                        className={`${styles.filaChecks} ${styles.nivelDos}`}
                      >
                        <div className={styles.contenedorChecks}>
                          <div className={styles.contenedorPrueba}>
                            <span className={styles.subTituloAccordion}>
                              Deducciones
                            </span>
                          </div>
                          <div className={styles.contenedorPrueba}>
                            <BpCheckbox
                              NombreCampo={
                                "ConfiguracionNominas.Conceptos.Deducciones.Crear"
                              }
                              Value={() =>
                                handleConfirmPermission(
                                  "ConfiguracionNominas.Conceptos.Deducciones.Crear"
                                )
                              }
                            />
                            <span className={styles.subTituloAccordion}>
                              Crear
                            </span>
                            <BpCheckbox
                              NombreCampo={
                                "ConfiguracionNominas.Conceptos.Deducciones.Editar"
                              }
                              Value={() =>
                                handleConfirmPermission(
                                  "ConfiguracionNominas.Conceptos.Deducciones.Editar"
                                )
                              }
                            />
                            <span className={styles.subTituloAccordion}>
                              Editar
                            </span>
                            <BpCheckbox
                              NombreCampo={
                                "ConfiguracionNominas.Conceptos.Deducciones.Eliminar"
                              }
                              Value={() =>
                                handleConfirmPermission(
                                  "ConfiguracionNominas.Conceptos.Deducciones.Eliminar"
                                )
                              }
                            />
                            <span className={styles.subTituloAccordion}>
                              Eliminar
                            </span>
                          </div>
                        </div>
                      </div>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion
                    className={
                      tablaCuarentaitres === true
                        ? `${styles.acordionBase} `
                        : `${styles.acordionBase}`
                    }
                  >
                    <div
                      className={`${styles.contenedorTitulosAccordion} ${styles.nivelDosMenu}`}
                    >
                      <div>
                        <AccordionSummary
                          onClick={() =>
                            setTablaCuarentaitres(!tablaCuarentaitres)
                          }
                        >
                          <div className={`${styles.contenedorIconoExpande}`}>
                            {tablaCuarentaitres === true ? (
                              <img
                                className={`${styles.iconoExpande}`}
                                src={`/assets/svg/icono-expande-arriba.svg`}
                                alt="imagen"
                              />
                            ) : (
                              <img
                                className={`${styles.iconoExpande} ${styles.iconoAbajo}`}
                                src={`/assets/svg/icono-expande.svg`}
                                alt="imagen"
                              />
                            )}
                          </div>
                        </AccordionSummary>
                      </div>
                      <div className={styles.titulosAccordion}>
                        <span className={styles.tituloAccordion}>
                          Tablas valores
                        </span>
                      </div>
                      <div className={styles.contenedorSwitch}>
                        <Switch
                          NombreCampo={"ConfiguracionNominas.TablasValores"}
                          Value={() =>
                            handleConfirmPermission(
                              "ConfiguracionNominas.TablasValores"
                            )
                          }
                        />
                      </div>
                    </div>

                    <AccordionDetails style={{ padding: "8px 16px 0px" }}>
                      <div
                        className={`${styles.filaChecks} ${styles.nivelDos}`}
                      >
                        <div className={styles.contenedorChecks}>
                          <div className={styles.contenedorPrueba}>
                            <span className={styles.subTituloAccordion}>
                              Acciones
                            </span>
                          </div>
                          <div className={styles.contenedorPrueba}>
                            <BpCheckbox
                              NombreCampo={
                                "ConfiguracionNominas.TablasValores.ValoresReferencia"
                              }
                              Value={() =>
                                handleConfirmPermission(
                                  "ConfiguracionNominas.TablasValores.ValoresReferencia"
                                )
                              }
                            />
                            <span className={styles.subTituloAccordion2}>
                              Valores de referencia
                            </span>
                            <BpCheckbox
                              NombreCampo={
                                "ConfiguracionNominas.TablasValores.TablaISR"
                              }
                              Value={() =>
                                handleConfirmPermission(
                                  "ConfiguracionNominas.TablasValores.TablaISR"
                                )
                              }
                            />
                            <span className={styles.subTituloAccordion2}>
                              ISR
                            </span>
                            <BpCheckbox
                              NombreCampo={
                                "ConfiguracionNominas.TablasValores.TablaSubsidio"
                              }
                              Value={() =>
                                handleConfirmPermission(
                                  "ConfiguracionNominas.TablasValores.TablaSubsidio"
                                )
                              }
                            />
                            <span className={styles.subTituloAccordion2}>
                              Subsidio
                            </span>
                            <BpCheckbox
                              NombreCampo={
                                "ConfguracionNominas.TablasValores.ISN"
                              }
                              Value={() =>
                                handleConfirmPermission(
                                  "ConfguracionNominas.TablasValores.ISN"
                                )
                              }
                            />
                            <span className={styles.subTituloAccordion2}>
                              ISN
                            </span>
                          </div>
                        </div>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </Accordion>

                <Accordion
                  className={
                    tablaCuarentaicuatro === true
                      ? `${styles.acordionBase} `
                      : `${styles.acordionBase}`
                  }
                >
                  <div className={styles.contenedorTitulosAccordion}>
                    <div>
                      <AccordionSummary
                        onClick={() =>
                          setTablaCuarentaicuatro(!tablaCuarentaicuatro)
                        }
                      >
                        <div className={`${styles.contenedorIconoExpande}`}>
                          {tablaCuarentaicuatro === true ? (
                            <img
                              className={`${styles.iconoExpande}`}
                              src={`/assets/svg/icono-expande-arriba.svg`}
                              alt="imagen"
                            />
                          ) : (
                            <img
                              className={`${styles.iconoExpande} ${styles.iconoAbajo}`}
                              src={`/assets/svg/icono-expande.svg`}
                              alt="imagen"
                            />
                          )}
                        </div>
                      </AccordionSummary>
                    </div>
                    <div className={styles.titulosAccordion}>
                      <span className={styles.tituloAccordion}>IDSE</span>
                    </div>
                    <div className={styles.contenedorSwitch}>
                      <Switch
                        NombreCampo={"Sidebar.IDSE"}
                        Value={() => handleConfirmPermission("Sidebar.IDSE")}
                      />
                    </div>
                  </div>

                  <AccordionDetails style={{ padding: "8px 16px 0px" }}>
                    <div className={`${styles.filaChecks} ${styles.nivelDos}`}>
                      <div className={styles.contenedorChecks}>
                        <div className={styles.contenedorPrueba}>
                          <span className={styles.subTituloAccordion}>
                            Acciones
                          </span>
                        </div>
                        <div className={styles.contenedorPrueba}>
                          <BpCheckbox
                            NombreCampo={"IDSE.IDSE.Enviar"}
                            Value={() =>
                              handleConfirmPermission("IDSE.IDSE.Enviar")
                            }
                          />
                          <span className={styles.subTituloAccordion2}>
                            Enviar
                          </span>
                          <BpCheckbox
                            NombreCampo={"IDSE.IDSE.Descartar"}
                            Value={() =>
                              handleConfirmPermission("IDSE.IDSE.Descartar")
                            }
                          />
                          <span className={styles.subTituloAccordion2}>
                            Descartar
                          </span>
                        </div>
                      </div>
                    </div>
                  </AccordionDetails>
                </Accordion>
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};
