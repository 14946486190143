import { Grid } from '@material-ui/core';
import { useQuery } from "@apollo/client";
import { GET_ALL_CALENDARS, GET_COUNT_EVENT_NONWORKINGDAY} from "../../Querys/querys";
import {createCalendarsModal} from '../../context/NewCalendarContext/Actions'
import CalendarsContext from '../../context/NewCalendarContext/CalendarsContext';
import {CreateCalendars} from './Modals/createCalendar'
import {UpdateCalendars} from './Modals/updateCalendar'
import {DeleteCalendars} from './Modals/deleteCalendar'
import CreateNonWorkingDayCalendarModal from './Modals/NonWorking/CreateNonWorkingDayCalendarModal'
import styles from './Calendars.module.css'
import { CalendarInfo } from './CalendarInfo';
import { useContext } from 'react';
import CheckPermission from '../../helpers/Administradores/Permissions';

export const  AllCalendars = () => {
    const {state,dispatch} = useContext(CalendarsContext)

    
    const resultCalendar = useQuery(GET_ALL_CALENDARS);
    const allCalendar = resultCalendar.data?.GET_ALL_CALENDARS
    

    const activos = allCalendar?.map((cal:any) => cal.status);
    const result = [activos?.filter((status:any) => status.length === 6)];



    const alleventNonWorkingDay = useQuery(GET_COUNT_EVENT_NONWORKINGDAY);
    const eventNonWorkingDay = alleventNonWorkingDay.data?.GET_COUNT_EVENT_NONWORKINGDAY
    
    
    const createModal = (id:string, createModal:boolean) => {
        createCalendarsModal({id, createModal},dispatch)
    }
    
    const GetEventNonWorkingDay = (idCalendar: any) =>{
        const result = eventNonWorkingDay?.filter( (e:any) =>{
            return String(e.calendarId) === String(idCalendar)
        } )
        return result
    }
    
    return (
        <div >
            <Grid container className = {styles.calendarContainerName}
            style={{marginTop:'33px'}}
            >
                {CheckPermission("Calendario.Ver") && (
                <Grid item xs={8}  className="Title" >
                    <span style={{marginLeft : "30px"}}>Calendario</span>
                </Grid>
                )}
            </Grid>
            <Grid container className = {styles.calendarContainerName}
            style={{marginTop:'55.5px'}}
            >
                <Grid item xs={8}  className={styles.titleCalendar}>
                    <span style={{marginLeft : "30px", marginTop:'30px'}}>Calendarios activos</span>
                </Grid>

                <Grid item xs={4}>
                    <Grid item xs={8}>
                        <div className={styles.conteinerbotonAgregar}>
                            {CheckPermission("Calendario.Crear") && (
                                <button
                                className={`${styles.botonAgregarNewCalendar2} ${styles.flex}`}
                                onClick={() => createModal('1', true)}
                                >
                                    <span className={styles.textoBotonn}> Nuevo calendario</span>
                                </button>
                            )}
                        </div>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container spacing = {8} className={styles.calendarContainer}>
                {result?.length >= 1 ? (
                    allCalendar?.map((cal:any) => {
                        return (
                            cal.status === "Activo" &&
                            <CalendarInfo
                                _id = {cal.id}
                                name = {cal.name}
                                enterprise = {cal.NameEnterprise}
                                eventNonworkingday = {GetEventNonWorkingDay(cal.id)}
                            />
                        )
                    })
                ):(
                    <span>Aún no tienes calendarios activos</span> 
                )}
            </Grid>
            {state.createModal ? <CreateCalendars/>: null}
            {state.updateModal ? <UpdateCalendars/>: null}
            {state.deleteModal ? <DeleteCalendars/>: null}
            {/*state.createIncidentModal? <CreateNonWorkinDay/>: null*/}
            {state.createIncidentModal? <CreateNonWorkingDayCalendarModal/>: null}
            
        </div>
    )
}