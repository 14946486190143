import { ElementType, useState, useContext, useCallback } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  Card,
  Grid,
  Step,
  StepLabel,
  Stepper,
  Button,
} from "@material-ui/core";
import Box from '@mui/material/Box';
import CircularProgress from "@material-ui/core/CircularProgress";
import CheckIcon from "@material-ui/icons/Check";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import CollaboratorContext from "../../context/CollaboratorContext/CollaboratorContext";
import { IPROPS } from "../../interfaces/Collaborator";
import { putCandidate } from "../../services/candidateService";
import { useEffect } from "react";
import { SuccessfulAlert } from "../../alerts/successAlerts";
import { WarningAlert } from "../../alerts/WarningAlert";
import { validAllData } from "../../helpers/Collaborator/validAllData";
import ArrowLeft from "../../assets/svg/blue_arrow_left.svg";
import ArrowRight from "../../assets/svg/blue_arrow_right.svg";
import {
  handleSetActiveStep
} from "../../helpers/Collaborator/Collaborator";
import { LoadingBits } from "../../alerts/LoadingBits";

// import from
const List = ({
  tab = "Nuevos Ingresos",
  title,
  labels = [],
  components = [],
  back = "/collaborators/id/0",
  process = [],
}: IPROPS) => {
  const [activeStep, setActiveStep] = useState<number>(0);
  const [returnPill, setAReturnPill] = useState<number>(0);
  const [tabText, setTabText] = useState<string>(tab);
  const history:any = useHistory();
  const params = useParams<any>();
  const { state, dispatch } = useContext(CollaboratorContext);
  const [flag, setFlag] = useState(false);
  const [showLoading, setLoading] = useState(true);

  useEffect(()=>{
    if(state?.collaborator?.new){
      setLoading(false);
      setFlag(true);
    }
    if(state.collaborator && Object.keys(state.collaborator).length > 1 && !state?.collaborator?.new){
      setLoading(false);
      setFlag(true);
    }
  }, [state.collaborator]);
  
  useEffect(() => {
    if(state.loading !== null){
      setLoading(state.loading);
    }
  },[state.loading]);

  const handleNext = useCallback( (index: number) => {
    handleSetActiveStep(index, dispatch);
  },[dispatch]);

  useEffect(() => {
    const element = document.getElementById('contentBody');
    if (state.activeStep !== null && state.activeStep !== undefined) {
      setActiveStep(state.activeStep);
    }
    if(localStorage.nextStep){
      const value = Number(localStorage.nextStep);
      setActiveStep(value);
      setTimeout(()=>{
        if(element){
          element.scrollTo(0,0);
        }
      }, 500)
      setTimeout(()=>{
        handleNext(value);
        delete localStorage.nextStep;
      }, 3000)
    }
  }, [state.activeStep, handleNext]);

  useEffect(() => {
    if (params.id !== "id" && params.id !== "new" && !("register" in params)){
      //LoadingUser();
    }
    let pill = localStorage.getItem("currentPill");
    if (pill) {
      setAReturnPill(Number(pill));
      if (pill === "1") setTabText("Colaboradores");
      if (pill === "3") setTabText("Inactivos");
    }
  }, [params]);


  const onBack = () => {
    if (returnPill === 0) localStorage.setItem("currentPill", "0");
    if (returnPill === 1) localStorage.setItem("currentPill", "1");
    if (returnPill === 3) localStorage.setItem("currentPill", "3");
    history.push(back);
  };

  const FinishingRegister = async () => {
    let result = await validAllData(state);

    if (typeof result === "boolean") {
      let result2 = await WarningAlert({
        title: "Aceptar información ",
        text: "¿Estas seguro de finalizar el proceso?",
        showDenyButton: true,
        confirmButtonText: "De Acuerdo",
      });

      if (result2.isConfirmed) {
        state.collaborator.done = true;
        let result3 = await putCandidate(
          state.collaborator,
          state.collaborator.Estatus === "sent" ? "si" : "no"
        );
        if (result3 === true) {
          await SuccessfulAlert({ text: "Datos enviados correctamente." });
          let materno;
          if (state.collaborator.ApellidoMaterno === undefined) {
            materno = "";
          } else {
            materno = state.collaborator.ApellidoMaterno;
          }
          let userName =
            state.collaborator.Nombre +
            " " +
            state.collaborator.ApellidoPaterno +
            " " +
            materno;
          localStorage.setItem("user", userName);
          localStorage.setItem("reCheck", "true");
          window.location.hash = "no-back-button";
          window.location.hash = "Again-No-back-button"; //esta linea es necesaria para chrome
          window.onhashchange = function () {
            window.location.hash = `${state.collaborator.Nombre}-${state.collaborator.ApellidoPaterno}`;
          };
          history.push("/Bienvenido");
        }
      }
    } else {
      localStorage.setItem("reCheck", "false");
      return WarningAlert({ text: result });
    }
  };

  const handleChange = (e: any, side: string) => {
    e.preventDefault();

    let numtab = parseInt(params.tab);
    if (side === "left") {
      if (numtab > 1) {
        history.push(`${numtab - 1}`);
      }
    }
    if (side === "right") {
      if (numtab < 5) {
        history.push(`${numtab + 1}`);
      }
    }
  };

  const backContracts = () => {
    delete localStorage.lastSection;
    history.push('/contracts');
  }

  return (
    <Box style={{position:'relative'}}>
      {
        showLoading && (
          <LoadingBits></LoadingBits>
        )
      }
          <Box>
            <Grid direction="row" container justify="flex-end">
              <Grid xs={4} item>
                <Card>
                  <Stepper activeStep={activeStep} orientation="vertical">
                    <Step key="asd">
                      <StepLabel
                        icon={
                          <CircularProgress
                            variant="determinate"
                            value={process?.percent == null ? 100 : process?.percent}
                            size={25}
                            style={{
                              color: `${process?.percent == null ? "#dfdfdf" : "#0bc763"
                                }`,
                            }}
                          />
                        }
                      >
                        <span className="title__list">{title}</span>
                      </StepLabel>
                    </Step>
                    {labels.map((label: string, index: number) => (
                      <Step key={label}>
                        <StepLabel
                          onClick={() => handleNext(index)}
                          icon={
                            process?.[index + 1] === true ? (
                              <CheckIcon style={{ color: "#0bc763" }} />
                            ) : (
                              <FiberManualRecordIcon
                                style={{
                                  color: index === activeStep ? "#fabb00" : "#dfdfdf",
                                  fontSize: "25px",
                                }}
                              />
                            )
                          }
                        >
                          <Grid direction="row" container>
                            <Grid xs={11} item>
                              <span
                                className={
                                  index === activeStep
                                    ? "selected__label"
                                    : "noselected__label"
                                }
                              >
                                {label}
                              </span>
                            </Grid>
                            {index === activeStep ? (
                              <Grid xs item>
                                <Box pt={0}>
                                  <ArrowForwardIosIcon
                                    style={{ fontSize: "14px", color: "#093c5d" }}
                                  />
                                </Box>
                              </Grid>
                            ) : null}
                          </Grid>
                        </StepLabel>
                      </Step>
                    ))}
                  </Stepper>
                </Card>
              </Grid>
              <Grid xs item>
                <Grid
                  xs={12}
                  item
                  direction="row"
                  container
                  justify="flex-end"
                  alignItems="center"
                >
                   {
                    history.location.state && history.location.state.from && (
                      <Grid direction="row" container>
                        <Grid xs item>
                          <Grid
                            direction="row"
                            container
                            justify="flex-end"
                            alignItems="center"
                          >
                            <div>
                              <ArrowBackIcon
                                fontSize="small"
                                style={{ color: "#fabb00" }}
                              />
                            </div>
                            <span className="Return" style={{marginTop:'0px'}} onClick={backContracts}>
                              Regresar a "Contratos"
                            </span>
                          </Grid>
                        </Grid>
                      </Grid>
                    )
                  }
                  {components === undefined || components === null
                    ? null
                    : components?.map((Component: ElementType, index: number) => (
                      <div key={index} hidden={index === activeStep ? false : true}>
                        {state.collaborator === undefined ||
                          state.collaborator === null ? (
                          // <div style={{ color: "red" }}>Error</div>
                          <> </>
                        ) : (
                          flag && <Component />
                        )}
                      </div>
                    ))}
                </Grid>
              </Grid>
            </Grid>
            <Grid direction="row" container>
              <Grid xs item>
                <Grid
                  direction="row"
                  container
                  justify="flex-start"
                  alignItems="center"
                >
                  {!state?.band && (
                    <>
                      <Box mt={5} pt={1}>
                        <ArrowBackIcon
                          fontSize="small"
                          style={{ color: "#fabb00" }}
                        />
                      </Box>
                      <span className="Return" onClick={onBack}>
                        Regresar a "{tabText}"
                      </span>
                    </>
                  )}
                </Grid>
              </Grid>

              <Grid xs item>
                <Grid
                  direction="row"
                  container
                  justify="flex-end"
                  alignItems="center"
                >
                  <Box mt={5} pt={1} pr={8}>
                    {
                      params?.tab !== "1" ?
                        <Button
                          type="button"
                          onClick={(e) => handleChange(e, "left")}
                          style={{ color: "#093C5D" }}
                          className="button-arrow-tabs"
                          disabled={activeStep === 1}
                        >
                          <img
                            src={ArrowLeft}
                            alt="ArrowLeft"
                            style={{ width: "20px", height: "20px" }}
                          />
                          &nbsp; regresar
                        </Button>
                        : null
                    }

                    {
                      params?.tab !== "5" && (state.collaborator && (state.collaborator.accept_info !== null || state.collaborator.done === true || state.collaborator.own_register === true))  ?
                        <Button
                          type="button"
                          onClick={(e) => handleChange(e, "right")}
                          style={{ color: "#093C5D" }}
                          className="button-arrow-tabs"
                          disabled={
                            (!state.collaborator?.own_register &&
                              "register" in params &&
                              !state.collaborator?.accept_info) ||
                            activeStep === 5
                          }
                        >
                          Siguiente &nbsp;
                          <img
                            src={ArrowRight}
                            alt="ArrowRight"
                            style={{ width: "20px", height: "20px" }}
                          />
                        </Button>
                        : null
                    }

                  </Box>
                  <Box mt={0} pt={1}>
                    {
                      "register" in params && state.band && params.tab === "5" ? (
                        state.collaborator?.Archivos ? (
                          state.collaborator?.Archivos.CartaOfertaFirmada &&
                            state.collaborator?.Archivos.AGREEMENT_PDF ? (
                            <>
                              &nbsp;
                              <Button
                                type="submit"
                                className="buttonFinishRegister"
                                onClick={FinishingRegister}
                              >
                                Enviar&nbsp;
                                <CheckIcon style={{ color: "#fff" }} />
                              </Button>
                            </>
                          ) : null
                        ) : null
                      ) : null
                    }
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Box>
    </Box>
  );
};

export default List;