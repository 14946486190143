import { useEffect, useState, ChangeEvent, useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Grow, Paper, Tab, Tabs } from "@material-ui/core";
import { TabContext, TabPanel } from "@material-ui/lab";
import Contacts from "./Contacts/Contacts";
import Expedient from "./Expedient/Expedient";
import Information from "./Information/Information";
import PayData from "./PayData/PayData";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CollaboratorContext, {
  Types,
} from "../../context/CollaboratorContext/CollaboratorContext";
import { updateCollaborator } from "../../helpers/Collaborator/Collaborator";
import PersonalData from "./PersonalData/PersonalData";
import { useQuery } from "@apollo/client";
import { GET_USER_NUEVOINGRESO_REGISTER } from "../../Querys/querys";
import { ErrorAlert } from "../../alerts/errorAlert";

const BodyCandidate = () => {
  const [value, setValue] = useState<string>("1");
  const params = useParams<any>();
  const history = useHistory();
  const { dispatch } = useContext(CollaboratorContext);
  const handleChange = (event: ChangeEvent<{}>, newValue: string) => {
    setValue(newValue);
    history.push(`${newValue}`);
  };

  const [processTab1, setProcessTab1] = useState<any>();
  const [processTab2, setProcessTab2] = useState<any>();
  const [processTab3, setProcessTab3] = useState<any>();
  const [processTab4, setProcessTab4] = useState<any>();
  const [processTab5, setProcessTab5] = useState<any>();
  const [collaborator, setCollaborator] = useState<any>();

  const { data: resultCollaborator, error } = useQuery(
    GET_USER_NUEVOINGRESO_REGISTER,
    {
      variables: { token: params.token },
    }
  );
  const allCollaborator = resultCollaborator?.GET_USER_NUEVOINGRESO_REGISTER[0];

  if (error) {
    ErrorAlert({text: 'Este enlace ya no es válido'});
    history.push("/login");
  }

  useEffect(() => {
    if (allCollaborator) {
      setCollaborator(allCollaborator);
    }

    async function fetchingData() {
      let result: any = { new: true };
      if (
        params.id !== "id" &&
        params.id !== "new" &&
        !("register" in params)
      ) {
        if (typeof allCollaborator == "undefined") {
        } else {
          result = JSON.parse(JSON.stringify(allCollaborator));
          result.new = true;
          setCollaborator(allCollaborator);
        }
      }
      if ("register" in params) {
        result = allCollaborator; //await getCandidate(params.id, false, params.token); // From Candidate
        if (result === null) {
          return history.push("/Bienvenido"); // validate if the user completed the register
        }
      }

      await updateCollaborator(result, dispatch); //Update collaborator state

      setValue(params.tab);

      if ("register" in params) {
        dispatch({ type: Types.UPDATE_BAND });
        document.documentElement.style.setProperty("--background", "#093c5d");
      }

      if ("register" in params) {
        result = allCollaborator; // From Candidate
        if (result === null) {
          return history.push("/Bienvenido"); // validate if the user completed the register
        }
      }
    }
    fetchingData();
  }, [dispatch, params, history, allCollaborator]);

  useEffect(() => {
    if (collaborator) {
      
      collaborator.register_process?.forEach((element: any) => {
        switch (element.tab) {
          case "information":
            setProcessTab1({
              "1": element.section_1,
              "2": element.section_2,
              "3": element.section_3,
              percent: element.percent,
              completed: element.completed,
            });
            break;
          case "personalData":
            setProcessTab2({
              "1": element.section_1,
              "2": element.section_2,
              "3": element.section_3,
              percent: element.percent,
              completed: element.completed,
            });
            break;
          case "emergency":
            setProcessTab3({
              "1": element.section_1,
              "2": element.section_2,
              "3": element.section_3,
              percent: element.percent,
              completed: element.completed,
            });
            break;
          case "payData":
            setProcessTab4({
              "1": element.section_1,
              "2": element.section_2,
              "3": element.section_3,
              percent: element.percent,
              completed: element.completed,
            });
            break;
          case "expedient":
            setProcessTab5({
              "1": element.section_1,
              "2": element.section_2,
              "3": element.section_3,
              percent: element.percent,
              completed: element.completed,
            });
            break;
          default:
            break;
        }
      });
    }
  }, [collaborator]);

  return (
    <TabContext value={value}>
      <Tabs
        value={value}
        onChange={handleChange}
        className={
          processTab1?.completed === true
            ? `collaborator filledAll`
            : `collaborator`
        }
        scrollButtons="auto"
        variant="fullWidth"
      >
        <Tab
          icon={processTab1?.completed === true ? <CheckCircleIcon /> : ""}
          label={"Información de alta"}
          value="1"
          className={
            value === "1"
              ? "Tab__SelectedFirst Tab__Selected"
              : processTab1?.completed === true
              ? "Tab__SelectedFirst Tab__Selected Tab__background filledAll"
              : "Tab__NoSelected Tab__NoSelectedFirst"
          }
        />
        <Tab
          icon={processTab2?.completed === true ? <CheckCircleIcon /> : ""}
          label="Datos Personales"
          value="2"
          className={
            value === "2"
              ? "Tab__Selected"
              : processTab2?.completed === true
              ? "Tab__Selected Tab__background filledAll"
              : "Tab__NoSelected"
          }
          disabled={collaborator?.accept_info === null}
        />
        <Tab
          icon={processTab3?.completed === true ? <CheckCircleIcon /> : ""}
          label="Salud y Emergencias"
          value="3"
          className={
            value === "3"
              ? "Tab__Selected"
              : processTab3?.completed === true
              ? "Tab__Selected Tab__background filledAll"
              : "Tab__NoSelected"
          }
          disabled={collaborator?.accept_info === null}
        />
        <Tab
          icon={processTab4?.completed === true ? <CheckCircleIcon /> : ""}
          label="Datos para el pago"
          value="4"
          className={
            value === "4"
              ? "Tab__Selected"
              : processTab4?.completed === true
              ? "Tab__Selected Tab__background filledAll"
              : "Tab__NoSelected"
          }
          disabled={collaborator?.accept_info === null}
        />
        <Tab
          icon={processTab5?.completed === true ? <CheckCircleIcon /> : ""}
          label="Expediente"
          value="5"
          className={
            value === "5"
              ? "Tab__Selected Tab__Selectedlast"
              : processTab5?.completed === true
              ? "Tab__Selected Tab__background filledAll Tab__NoSelectedLast"
              : "Tab__NoSelected Tab__NoSelectedLast"
          }
          disabled={collaborator?.accept_info === null}
        />
      </Tabs>
      <div className="collaborator__candidate__container" style={{"overflowY":"auto"}}>
        <Grow in={true}>
          <Paper>
            <TabPanel value="1">
              {value === "1" && <Information process={processTab1} />}
            </TabPanel>

            <TabPanel value="2">
              {value === "2" && <PersonalData process={processTab2} />}
            </TabPanel>

            <TabPanel value="3">
              {value === "3" && <Contacts process={processTab3} />}
            </TabPanel>

            <TabPanel value="4">
              {value === "4" && <PayData process={processTab4} />}
            </TabPanel>

            <TabPanel value="5">
              {value === "5" && <Expedient process={processTab5} />}
            </TabPanel>
          </Paper>
        </Grow>
      </div>
    </TabContext>
  );
};

export default BodyCandidate;
