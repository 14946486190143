import { useEffect, useState } from 'react';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import MuiDialogActions from '@material-ui/core/DialogActions';
import { GET_USERS_BY_ID, GET_USER_HOLIDAYS } from "../../../Querys/querys";
import { useQuery } from "@apollo/client";
import { CollaboratorHistory, Timeline, Holidays} from '../../../interfaces/TabCollaborator.interfaces';
import './History.css';
import CollaboratorHolidays from './CollaboratorHolidays';
import CollaboratorTimeline from './CollaboratorTimeline';

function HistoryModal({showModal, setModal, id}:any){
  const { data: resultCollaborator } = useQuery(GET_USERS_BY_ID, {
    variables: { getUsersId: id },
  });
  const { data: resultHolidays } = useQuery(GET_USER_HOLIDAYS, {
    variables: { id: id },
    fetchPolicy: 'no-cache'
  });
  const [collaborator, setCollaborator] = useState<Partial<CollaboratorHistory>>({});
  const [holidays, setHolidays] = useState<Holidays>({
    holidays_total: 0,
    holidays_used: 0,
    economic_total: 0,
    economic_used: 0
  });

  useEffect(()=>{
    if(resultCollaborator && resultCollaborator.GET_USERS){
      setCollaborator( resultCollaborator.GET_USERS[0] );
    }
  }, [resultCollaborator])

  useEffect(()=>{
    if(resultHolidays && resultHolidays.GET_USER_HOLIDAYS){
      setHolidays( resultHolidays.GET_USER_HOLIDAYS );
    }
  }, [resultHolidays])

  return (
    <Dialog aria-labelledby="customized-dialog-title" className='history-modal' open={showModal} fullWidth={true} maxWidth="sm">
      <DialogTitle>
        <h2 id="form-dialog-title" className="text-center" style={{ marginTop: '20px' }}>Línea de tiempo</h2>
        <h3 id="form-dialog-subtitle">{ `${collaborator.name} ${collaborator.firstName} ${collaborator.lastName}` }</h3>
        <span id="closemodalx" onClick={() => { setModal(false) }}><img alt="close" src="/assets/svg/CloseM.svg" /></span>
      </DialogTitle>
      <DialogContent>
        <CollaboratorHolidays holidays={holidays}></CollaboratorHolidays>
        <CollaboratorTimeline id={id}></CollaboratorTimeline>
      </DialogContent>
      <MuiDialogActions>
          <button className="btnCancel" onClick={() => { setModal(false) }}>
              Cerrar
          </button>
      </MuiDialogActions>
    </Dialog>
  )
}

export default HistoryModal;