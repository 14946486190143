import * as React from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Avatar from '@mui/material/Avatar';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { v4 as uuidv4} from 'uuid'
import Rating from '@material-ui/lab/Rating';
import { MenuPrestacion } from "./MenuListPrestacion";
import "../Prestaciones/css/TablePrestacion.css";
import GreenSwitch from "../ConfigPayroll/GreenSwitch";
import { GET_ALL_PRESTACIONES,UPDATE_PRESTACION_STATUS } from '../../Querys/prestaciones/querys';
import { useMutation } from "@apollo/client";


  function Row(props: { row: any }) {
    const [valueSecondTable,setValueSecondTable ] =React.useState<any[]>([]);
    const { row } = props; 
    
    const [updateStatus] = useMutation(UPDATE_PRESTACION_STATUS,{
      refetchQueries: [{query: GET_ALL_PRESTACIONES}]
  });


  const updatePrestacionStatus = async (id:any) =>{
     await updateStatus({
        variables:{
            id: id
        }
     });
   }

React.useEffect(() => {
         if(row.sedes.length > 0){
          
            let arrayTable:any=[];
            let objtable={};              
            let arrayAreas:any=[];                  
            let area=row.areas;           
           area.map((are:any) => {
                arrayAreas.push(are.Area )                  
            }) 
             row.sedes.map((sede:any) => {
                            objtable={
                                ...objtable,
                                id:row.id, 
                               // empresa:sede.name,
                                sedes:sede.sede,
                                evaluable:row.evaluable,
                                areas:arrayAreas.toString(),
                                countColaboradores:row.countColaboradores,
                                countRestricciones:row.countRestricciones,          
                                countComentarios: row.countComentarios,
                                promedio:row.promedio      
                          }                                               
                        arrayTable.push(objtable)                                 
                                          
                })
              
             setValueSecondTable(arrayTable)                
          }                 
    }, []);
 
    
    const [open, setOpen] = React.useState(false);  
    return (
      <React.Fragment>
        <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
          <TableCell className="cellSpanPrincipalPrestacion">
            <IconButton
              className='btnViewInfoPrestacion'
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell className='tableImgPrincipalPrestacion' align="left"> <Avatar alt={row.Titulo} src={row.URL} /></TableCell>
          <TableCell className='tableTitlePrincipalPrestacion' align="left">{row.Titulo}  </TableCell>
          <TableCell className='TableheaderPrincipalPrestacion' align="left">| {row.TipoPrestacion} </TableCell>
          <TableCell className='TableheaderPrincipalPrestacion' align="left"></TableCell>
          <TableCell className='TableheaderPrincipalPrestacion' align="left"></TableCell>
          <TableCell className='tableActivaPrincipalPrestacion' align="right"> 
           <div className='switchPrestacion'> 
                               <span className='actPrestacion'>{row.Activa}</span> 
                                <GreenSwitch updatePayrollStatus={updatePrestacionStatus}
                                             id= {row.id} 
                                             status={row.Activa}/>
            </div>
          </TableCell>
          <TableCell className='tableMenuPrincipalPrestacion' align="right"> <MenuPrestacion id={row.id}/>  </TableCell>
        </TableRow >
        <TableRow key ={ uuidv4()}  className='headerTable2Prestaciones'>
          <TableCell style={{ padding: 0}} colSpan={10} >
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box>               
                <Table>
                  <TableHead className='pd_contenedorTitulos'>
                    <TableRow key ={ uuidv4()}>
                      {/* <TableCell>Empresa</TableCell> */}
                      <TableCell>Sede</TableCell>
                      <TableCell>Área</TableCell>
                      <TableCell>Colaboradores</TableCell>
                      <TableCell>Restriciones</TableCell>
                      <TableCell>Comentarios</TableCell>
                      <TableCell>Evaluable</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                  {valueSecondTable &&
                    valueSecondTable.map((info:any )=>{
                    return(
                    <>
                     <TableRow  key ={ uuidv4()}>
                      {/* <TableCell className='headerSecondTablePrestacion'>{info.empresa} </TableCell> */}
                      <TableCell>{info.sedes} </TableCell>
                      <TableCell  align="left"> <div className='tableAreaTablePrestacion'> <span>{info.areas}</span></div> </TableCell>
                      <TableCell  align="left"> <span className='tableCountSecondTablePrestacion'>{info.countColaboradores}</span> </TableCell>
                      <TableCell  align="left"> <span className='tableCountSecondTablePrestacion'>{info.countRestricciones}</span> </TableCell>
                      <TableCell  align="left"><span  className='tableCountSecondTablePrestacion'>{info.countComentarios}</span> </TableCell>
                      {
                       info.evaluable !== true ?
                        <TableCell align="left">
                        <Rating name="half-rating-read" defaultValue= {info.promedio}  precision={0.5}  size="small" readOnly />                       
                       </TableCell>
                      :<TableCell  align="left"><span  className='tableCountSecondTablePrestacion'>Privada</span> </TableCell>
                    }
                      </TableRow>
                     </>)
                     })
                    }                    
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }
  
 

const TablePrestacion = ({prestaciones}:any ) => {
  const rows =prestaciones
   return (
        <TableContainer component={Paper}>
          <Table className='tablePrestacion' aria-label="collapsible table">
            <TableBody>
              {rows.map((row:any) => (
                <Row key={row.id} row={row} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      );
}

export default TablePrestacion