import {useReducer} from 'react';
import PrestacionContext, {initialState} from "./PrestacionContext";
import PrestacionReducer from './PrestacionReducer';

const PrestacionState = (props:any) => {
    const [state,dispatch] = useReducer(PrestacionReducer, initialState)
    return (
        <PrestacionContext.Provider value={{state,dispatch}}>
                  {props.children}
        </PrestacionContext.Provider>
    )
}

export default PrestacionState;