
import { Grid, Menu, MenuItem } from '@material-ui/core'
import "../styles.css"
import { useContext, useState } from "react";
import {
    createEditEventModal,
    deleteEditEventModal
} from '../../../context/ScheduleContext/Actions'
import CalendarsContext from "../../../context/NewCalendarContext/CalendarsContext";
import CheckPermission from '../../../helpers/Administradores/Permissions';

interface TabMeasures {
    children?: React.ReactNode;
    measures: any;
    idEvent: any;
    calendar: any;
    EventName: any;
    eventType: any;
    initDate: any;
    setInitDateRecords: any;
    endDate: any;
    setEndDateRecords: any;
    type: String
    setTypeRecords: any;
};

function EventDivModal(props: TabMeasures){

    const { measures, EventName, calendar, idEvent, eventType, initDate, setInitDateRecords, endDate, setEndDateRecords, type, setTypeRecords } = props;
    const [anchorEl, setAnchorEl] = useState<any>(null);
    const open = Boolean(anchorEl);
    const {dispatch} = useContext(CalendarsContext)
    
    
    const createModal = (id:string, createModal:boolean) => {
        setAnchorEl(null);
        createEditEventModal(calendar, id, createModal, initDate, endDate, dispatch);
    }
    
    const deleteModal = (id:string, createModal:boolean) =>{
        setAnchorEl(null);
        deleteEditEventModal(calendar, id, createModal, dispatch)
    }

    if(idEvent === "0"){
        return<div 
            className="pruebasEvento" 
            style={
                {
                    width: measures.width,
                    left: measures.left,
                    backgroundColor: eventType === 'evento'? '#FABB00': '#46CB2C'
                }
            }
            onClick={(e) => {
                setInitDateRecords(initDate)
                setEndDateRecords(endDate)
                setTypeRecords(type)
            }}
            >
            <div
            className="textCalendarDay"
            >
            {EventName}
            </div>
        </div>
    }else{
        return <>
            <div 
                className="pruebasEvento" 
                style={
                    {
                        width: measures.width,
                        left: measures.left,
                        backgroundColor: eventType === 'evento'? '#FABB00': '#46CB2C'
                    }
                }
                onClick={(e) => {
                    setAnchorEl(e.currentTarget)
                }}
                ><div
                className="textCalendarDay"
                >
                {EventName}
                </div>
            </div>
            <Menu
                className="MoreVerIcon"
                anchorEl={anchorEl}
                open={open}
                transformOrigin={{ vertical: -40, horizontal: "left" }}
                onClose={() => setAnchorEl(null)}
            >
                {
                    CheckPermission("Calendario.Ver.Editar") && (
                        <MenuItem divider button 
                        onClick={() => createModal(idEvent, true)}
                        >
                            Editar&nbsp;
                            <Grid container item justify="flex-end">
                                <img src={`/assets/svg/icono-editar.svg`} alt="Editar" />
                            </Grid>
                        </MenuItem>
                    )
                }
                {
                    CheckPermission('Calendario.Ver.Eliminar') && (
                        <MenuItem divider button 
                        onClick={() => deleteModal(idEvent, true)}
                        >
                            Eliminar&nbsp;
                            <Grid container item justify="flex-end" >
                                <img src={`/assets/svg/icono-eliminar.svg`} alt="Eliminar" />
                            </Grid>
                        </MenuItem>
                    )
                }
            </Menu >
            
        </>
    }

    

}

export default EventDivModal