import {Types} from './ContractsContext'

const ModalReducer = (state:any, action:any) => {
    const {type} = action;
    switch(type){
        case Types.OPENEXPIREDCONTRACTS:
            return {
                ...state,
                openExpiredContracts:true
            }
        case Types.CLOSEEXPIREDCONTRACTS:
            return {
                ...state,
                openExpiredContracts:false
            }
        default:
        return state;
    }
}

export default ModalReducer;