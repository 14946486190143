import { useContext, useEffect, useState } from "react";
import {
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  Select,
  TextField,
  Button,
} from "@material-ui/core";
import Box from '@mui/material/Box';
import CollaboratorContext from "../../../../context/CollaboratorContext/CollaboratorContext";
import { paisesEstados } from "../../../../helpers/Json/paises-estados";
import {
  getMunicipios,
  getStates,
} from "../../../../helpers/Json/getStatesAndMunicipios";
import { SuccessfulAlert } from "../../../../alerts/successAlerts";
import InputMask from "react-input-mask";
import { useFormik } from "formik";
import { useMutation } from "@apollo/client";
import * as Yup from "yup";
import { GET_USERS_BY_ID, UPDATE_USERS } from "../../../../Querys/querys";
import { Form } from "semantic-ui-react";
import SaveIcon from "@material-ui/icons/Save";
import { handleSetActiveStep } from "../../../../helpers/Collaborator/Collaborator";
import { validatePostalCode } from "../../../../helpers/Collaborator/Fields";

const AddressDataColaboradores = () => {
  const { state, dispatch } = useContext(CollaboratorContext);
  const [states, setState] = useState<Array<string>>([]);
  const [municipios, setMunicipios] = useState<Array<string>>([]);
  const [estado, setEstado] = useState<string>("");
  const [municipio, setMunicipio] = useState<string>("");
  const [cpMask, setCPMask] = useState("");

  const [updateColaboradores] = useMutation(UPDATE_USERS, {
    refetchQueries: [
      {
        query: GET_USERS_BY_ID,
        variables: { getUsersId: state.collaborator.id },
      },
    ],
  });

  useEffect(() => {
    if (state.collaborator?.country)
      setState(getStates(state.collaborator?.country));
    if (state.collaborator?.state)
      setMunicipios(getMunicipios(state.collaborator?.state));

    if (state.collaborator) {
      if (state.collaborator?.municipality) {
        setMunicipio(state.collaborator?.municipality);
      }
      if (state.collaborator?.state) {
        setEstado(state.collaborator?.state);
      }
    }
  }, [state.collaborator]);


  const onChangeCountry = async (e: any) => {
    setEstado("");
    setMunicipio("");
    setState(getStates(e.target.value));
  };

  const onChangeState = async (e: any) => {
    setMunicipio("");
    let value = e.target.value;
    setEstado(value);
    setMunicipios(getMunicipios(value));
  };

  const initialValues = () => {
    return {
      address: state.collaborator?.address ?? "",
      suburb: state.collaborator?.suburb ?? "",
      country: state.collaborator?.country ?? "",
      state: state.collaborator?.state ?? "",
      municipality: state.collaborator?.municipality ?? "",
      ZC: state.collaborator?.ZC ?? "",
      nacionality: state.collaborator?.nacionality ?? "",
    };
  };

  const formik = useFormik({
    initialValues: initialValues(),
    validationSchema: Yup.object(validationSchema()),

    onSubmit: async (formData) => {
      updateColaboradores({
        variables: {
          updateUsersId: state.collaborator?.id,
          input: formData,
          process: {
            tab: "personalData",
            section: "section_1",
          },
        },
      }).then((res) => {
        SuccessfulAlert({ text: "Se actualizó correctamente" });
        handleSetActiveStep(1, dispatch);
      });
    },
  });

  return (
    <Form onSubmit={formik.submitForm}>
      <div className="novalidate__border">
        <Box mb={2}>
          <TextField
            type="text"
            name="address"
            defaultValue={state.collaborator?.address}
            label="Calle y número"
            variant="outlined"
            size="small"
            fullWidth={true}
            error={formik.touched.address && Boolean(formik.errors.address)}
            
            onChange={formik.handleChange}
            helperText={formik.touched.address && formik.errors.address}
            InputProps={{
              readOnly: false,
            }}
          />
        </Box>
        <Grid direction="row" container spacing={2}>
          <Grid xs item>
            <TextField
              name="suburb"
              defaultValue={state.collaborator?.suburb || ""}
              label="Colonia"
              variant="outlined"
              size="small"
              fullWidth={true}
              
              onChange={formik.handleChange}
              error={formik.touched.suburb && Boolean(formik.errors.suburb)}
              helperText={formik.touched.suburb && formik.errors.suburb}
              InputProps={{
                readOnly: false,
              }}
            />
          </Grid>
          <Grid xs item>
            <FormControl variant="outlined" fullWidth={true} size="small">
              <InputLabel htmlFor="outlined-age-native-simple">País</InputLabel>
              <Select
                native
                onChange={(e) => onChangeCountry(e)}
                onBlur={formik.handleChange}
                error={formik.touched.country && Boolean(formik.errors.country)}
                label={"Pais"}
                name="country"
                defaultValue={state.collaborator?.country || ""}
              >
                <option
                  value={state.collaborator?.country || ""}
                  disabled={true}
                >
                  {state.collaborator?.country || ""}
                </option>
                {paisesEstados.map((country: any, index: number) => (
                  <option key={index} value={country.country}>
                    {country.country}
                  </option>
                ))}
              </Select>
              <FormHelperText style={{color: "red"}}>
                {formik.touched.country && formik.errors.country}
              </FormHelperText>
            </FormControl>
          </Grid>
        </Grid>
        <Grid direction="row" container spacing={2}>
          <Grid xs item>
            <FormControl variant="outlined" fullWidth={true} size="small">
              <InputLabel htmlFor="outlined-age-native-simple">
                Estado
              </InputLabel>
              <Select
                native
                onChange={(e) => onChangeState(e)}
                onBlur={formik.handleChange}
                error={formik.touched.state && Boolean(formik.errors.state)}
                label={"Estado"}
                name="state"
                value={estado}
              >
                <option value={estado} disabled={true}>
                  {estado}
                </option>
                {states.map((state: string, index: number) => (
                  <option key={index} value={`${state}`}>
                    {state}
                  </option>
                ))}
              </Select>
              <FormHelperText style={{color: "red"}}>{formik.touched.state && !estado && "Obligatorio"}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid xs item>
            <FormControl variant="outlined" fullWidth={true} size="small">
              <InputLabel htmlFor="outlined-age-native-simple">
                Municipio
              </InputLabel>
              <Select
                native
                onBlur={formik.handleChange}
                onChange={(e: any) => setMunicipio(e.target.value)}
                error={formik.touched.municipality && Boolean(formik.errors.municipality)}
                label={"Municipio"}
                name="municipality"
                value={municipio}
              >
                <option value={municipio} disabled={true}>
                  {municipio}
                </option>
                {municipios.map((municipio: any, index: number) => (
                  <option key={index} value={municipio}>
                    {municipio}
                  </option>
                ))}
              </Select>
              <FormHelperText style={{color: "red"}}>{formik.touched.municipality && formik.errors.municipality}</FormHelperText>
            </FormControl>
          </Grid>
        </Grid>
        <Grid direction="row" container spacing={2}>
          <Grid xs item>
            <InputMask
              mask="99999"
              defaultValue={state.collaborator?.ZC || cpMask}
              onChange={(e) => {
                setCPMask(e.target.value);
              }}
              disabled={false}
              onBlur={formik.handleChange}
            >
                <TextField
                  name="ZC"
                  error={formik.touched.ZC && Boolean(formik.errors.ZC)}
                  helperText={formik.touched.ZC && formik.errors.ZC}
                  label="Código postal"
                  variant="outlined"
                  size="small"
                  fullWidth={true}
              />
            </InputMask>
          </Grid>
          <Grid xs item>
            <TextField
              name="nacionality"
              defaultValue={state.collaborator?.nacionality || ""}
              onChange={formik.handleChange}
              error={formik.touched.nacionality && Boolean(formik.errors.nacionality)}
              helperText={formik.touched.nacionality && formik.errors.nacionality}
              label="Nacionalidad"
              variant="outlined"
              size="small"
              fullWidth={true}
              
              InputProps={{
                readOnly: false,
              }}
            />
          </Grid>
        </Grid>
      </div>

      <Grid
        direction="row"
        container
        justify="flex-end"
        alignItems="center"
        style={{ marginTop: "20px" }}
      >
        <Button type="submit" className="buttonSave">
          <SaveIcon />
          &nbsp; Guardar
        </Button>
      </Grid>
    </Form>
  );
};

const validationSchema = () => {
  return {
    address: Yup.string().required("Obligatorio"),
    suburb: Yup.string().required("Obligatorio"),
    country: Yup.string().required("Obligatorio"),
    state: Yup.string().required("Obligatorio"),
    municipality: Yup.string().test(
      "check_mun",
      "Obligatorio",
      function (municipality: string | undefined): boolean {
        return this.parent.country !== 'México' ? true : 
        this.parent.country === 'México' && municipality && municipality !== '' ? true : false
      }
    ),
    ZC: Yup.string()
      .required("Obligatorio")
      .min(5, "El código postal debe tener 5 dígitos")
      .test(
        "check_postal",
        "El código postal debe tener 5 digitos válidos",
        function (ZC:string|undefined): boolean {
          return (ZC && validatePostalCode(ZC)) ? true : false;
        }
      ),
    nacionality: Yup.string().required("Obligatorio"),
  };
};

export default AddressDataColaboradores;
