function CustomBarGraph({number, month, total, totalAnswered}:any){
  return (
    <div className="barGraphMonth">
        <div className="barGraphLabels">
          <p className="barGraphMonthLegend">{month}</p>
          {
            total > 0 ? (
              <p className="barGraphTotal"><span className={"barGraphBlue-"+number}>{totalAnswered}</span> / {total}</p>
            ) : (
              <p className="barGraphTotal"><span className={"barGraphBlue-"+number}>0</span> / 0</p>
            )
          }
        </div>
        <div className="barGraphLine">
          {
            totalAnswered > 0 && (
              <div className= {`barGraphhLineColor barGraphhLineBlue-${number}`} style={{width: ((totalAnswered/total) * 100)+"%"}}></div>
            )
          }
        </div>
    </div>
  )
}

export default CustomBarGraph;