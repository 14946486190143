import { Grid } from '@material-ui/core';
import Box from '@mui/material/Box';
import ContractsContext from '../../context/ContractsContext/ContractsContext';
import { useContext, useState, ChangeEvent } from 'react';
import CustomTabs from "../../components/Collaborators/Tab/CustomTabs";
import CustomTab from "../../components/Collaborators/Tab/CustomTabMain";
import {Contract} from '../../components/Contracts/Contract'
import styles from './Contracts.module.css'
import {openExpiredContracts} from '../../context/ContractsContext/Actions'
import {ExpiredContracts} from '../../components/Contracts/Modal/ExpiredContracts'
export const  ShowContracts = () => {
    const [tab, setTab] = useState(0);
    const handleChange = (event: ChangeEvent<{}>, newValue: number) => {
      setTab(newValue);
      localStorage.setItem("currentPill", String(newValue));
    };
    const {dispatch} = useContext(ContractsContext)
    
    return (
        <div>
            <Box mt={3} ml={5} className="Title">
                Contratos
            </Box>
            <Box p={5} pb={3} pt={0}>
                <div
                style={{
                    display: "flex",
                }}
                >
                    <Grid container justify="flex-start">
                        <CustomTabs
                        value={tab}
                        onChange={handleChange}
                        aria-label="simple tabs example"
                        >
                            <CustomTab label="Generación de contratos" value={0} />
                        
                        </CustomTabs>
                    </Grid>
                    <div>
                        <button
                        className={styles.botonAgregar}
                        onClick={() => openExpiredContracts({},dispatch)}
                        >
                        <div className={styles.iconoAgegar}></div>
                        <div className={styles.textoBotonAgregar}>
                        Contratos vencidos/por vencer
                        </div>            
                        </button>
                    </div>
                </div>
                    
            </Box>
            <div className="contenedor2">
                {tab === 0 && <Contract value={tab} index={0} /> }
            </div>
            <ExpiredContracts/>
        </div>
    )
}