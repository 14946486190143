import axios from "axios";
import { ErrorAlert } from "../alerts/errorAlert";
const serverError = "Ocurrió un error con el servidor.";

export const postFileProcessIncident = async (e: any) => {
  try {
    let file = new FormData();
    file.append("document", e.target.files[0]);
    file.append("type", "incidencia");

      const result = await axios.post(`/Confronta/ProcessIncidentReport`, file, {
        headers: {
          "Content-Type": `multipart/form-data;boundary=${file}`,
        },
      });

      return result.data;
    
  } catch (err) {
    console.log(err);
    return ErrorAlert({ text: serverError });
  }
};
