import {useState, useContext} from "react";
import {
    TextField
  } from '@material-ui/core';
import { Dialog, DialogContent } from "@material-ui/core"
import Box from '@mui/material/Box';
import '../Payroll.css'
import SettlementPayrollProcessContext from "../../../context/PayrollProcess/SettlementPayrollProcessContext";
import { clearSettlementProcess } from "../../../context/PayrollProcess/SettlementActions";
import { EXTERNO_SETTLEMENTPAYROLL, GET_ALL_SETTLEMENTPAYROLL,GET_ALL_USERS_SETTLEMENT } from "../../../Querys/querys";
import { useMutation } from "@apollo/client";

const ExternoSettlement = (props: any) =>{
    const {state, dispatch} = useContext(SettlementPayrollProcessContext)
    const [activo, setactivo] = useState(false)

    const [externoPayroll] = useMutation(EXTERNO_SETTLEMENTPAYROLL, {
      refetchQueries: [{ 
        query: GET_ALL_SETTLEMENTPAYROLL 
    },
    { 
        query: GET_ALL_USERS_SETTLEMENT 
    }],
    });

    const handleChange = (e: any) =>{ 
      if(e.target.value === 'EXTERNO'){
          setactivo(true)
      }else{
          setactivo(false)
      }
    }

    const handleDelete = async () =>{ 
      externoPayroll({
        variables:{
            externoSettlementPayrollId:state._id
        }
      })
      handleClose()
    }

    const handleClose = () =>{
      clearSettlementProcess({}, dispatch)
    }

    return(
        <Dialog aria-labelledby='costumized-dialog-title' open={state.externoModal} fullWidth={true}  onClose={handleClose} maxWidth={"md"}>
            <Box>
                <div className="contenedorCentrado">
                    <p className="titulo">¿Confirmas marcar como externo esta baja?</p>
                </div>
                <div className="contenedorCentrado">
                    <span className="textoEliminar">Una vez marcado como externo no podrás recuperar la información</span>
                </div>
                <div className="contenedorCentrado">
                    <span className="textoEliminar" >Escribe EXTERNO para confirmar</span>
                </div>
                <div className="contenedorCentrado">
                    <TextField
                        error={false}
                        type="text"
                        variant="outlined"
                        size="small"
                        className="inputEliminar"
                        onChange={(e) => handleChange(e)}
                />
                </div>
                {(activo === true)?
                <div className="contenedorCentrado">
                    <button className="botonEliminarPuesto" onClick = {handleDelete} >
                        Confirmar
                    </button>                            
                </div>
                :
                <div className="contenedorCentrado">
                    <button className="botonEliminarPuestoFalse">
                        Confirmar
                    </button>
                </div>
                }
            </Box>
            <DialogContent>

            </DialogContent>
        </Dialog>
    )
}

export default ExternoSettlement