import { Types } from './PayrollProcessContext'


const ModalReducer = (state: any, action: any) => {
  const { payload, type } = action;
  switch (type) {
    case Types.CREATE_PRENOMINA:
      return {
        ...state,
        createPrenomina: payload.createPrenomina
      }
    case Types.CREATE_INCIDENCIA:
      return {
        ...state,
        createIncidencia: true
      }
    case Types.CLEAR_PAYROLL:
      return {
        ...state,
        _id: '',
        createPrenomina: false,
        createIncidencia: false,
        deleteModal: false,
        fondosInsuficientes: false,
        aceptarDispersar: false,
        sinNominasSeleccionadas: false,
        alertaReporte: false,
        alertaEnviarIDSE: false,
        showDataCancelTimbre: false,
        cancelAllTimbres: false,
        sendMeilCancelaTimbre: false,
        showIncident: false,
        idPayroll: '',
        idCollaborator: '',
        showDeleteIncident: false,
        idDeleteIncident: '',
        varDelete: true,
        showFinish: false,
        alertaEnvioIDSEDos: false,
        alertaEnvioIDSEExterno: false,
        alertaNoProcesado: false,
        Comentario: '',
        aceptarDispersarIndividual: false,
        aceptarDispersarFiniquito: false,
        aceptarDispersarEventual: false,
        aceptarDispersarIndividualEventual: false,
        varDeleteIncuidentSettlement: false,
        showIncidentSettlement: false,
        aceptarDispersarSettlement: false,
        aceptarDispersarFacturacionPagos: false,
        showApproveIncidentPeriodic: false,
        openModalOutsourcersResumen: false,
        openDispersionEnProceso: false,
        openAlertaNoGravable: false
      }
    case Types.DELETE_PAYROLL:
      return {
        ...state,
        _id: payload._id,
        deleteModal: true
      }
    case Types.FONDOS_INSUFICIENTES:
      return {
        ...state,
        fondosInsuficientes: true
      }
    case Types.ACEPTAR_DISPERSAR:
      return {
        ...state,
        aceptarDispersar: true
      }
    case Types.OPEN_ENVIOIDSE_DOS:
      return {
        ...state,
        alertaEnvioIDSEDos: true,
        _id: payload.idMovimiento
      }
    case Types.SIN_NOMINAS:
      return {
        ...state,
        sinNominasSeleccionadas: true
      }
    case Types.ALERTA_REPORTE:
      return {
        ...state,
        alertaReporte: true
      }
    case Types.ALERTA_ENVIARIDSE:
      return {
        ...state,
        alertaEnviarIDSE: true
      }
    case Types.ALERTA_DESCARTARIDSE:
      return {
        ...state,
        alertaDescartarIDSE: true
      }
    case Types.IS_ONBOARDING:
      return {
        ...state,
        isOnboarding: true
      }
    case Types.CLOSE_ONBOARDING:
      return {
        ...state,
        isOnboarding: false
      }
    case Types.OPEN_PAYROLL_DATA_CANCEL_TIMBRE:
      return {
        ...state,
        _id: payload._id,
        typeCancelTimbre: payload.typeCancelTimbre,
        showDataCancelTimbre: true
      }
    case Types.OPEN_SHOW_INCIDENT:
      return {
        ...state,
        idPayroll: payload.idPayroll,
        idCollaborator: payload.idCollaborator,
        showIncident: true,
        varDelete: payload.varDelete
      }
    case Types.OPEN_SHOW_INCIDENT_SETTLEMENT:
      return {
        ...state,
        idPayroll: payload.idPayroll,
        idCollaborator: payload.idCollaborator,
        showIncidentSettlement: true,
        varDeleteIncuidentSettlement: payload.varDeleteIncuidentSettlement
      }
    case Types.OPEN_DELETE_INCIDENT:
      return {
        ...state,
        idDeleteIncident: payload.idDeleteIncident,
        showDeleteIncident: true
      }
    case Types.OPEN_DELETE_INCIDENT_SETTLEMENT:
      return {
        ...state,
        idDeleteIncidentSettlement: payload.idDeleteIncidentSettlement,
        showDeleteIncidentSettlement: true
      }
    case Types.CLEAR_DELETE_INCIDENT:
      return {
        ...state,
        idDeleteIncident: '',
        showDeleteIncident: false,
        varDelete: true
      }
    case Types.CLEAR_DELETE_INCIDENT_SETTLEMENT:
      return {
        ...state,
        idDeleteIncidentSettlement: '',
        showDeleteIncidentSettlement: false,
        varDeleteIncuidentSettlement: true
      }
    case Types.OPEN_FINISH:
      return {
        ...state,
        idPayroll: payload.idPayroll,
        showFinish: true
      }
    case Types.OPEN_MOVIMIENTO_EXTERNO:
      return {
        ...state,
        alertaEnvioIDSEExterno: true,
        _id: payload.idMovimiento
      }
    case Types.OPEN_NO_PROCESADO:
      return {
        ...state,
        alertaNoProcesado: true,
        Comentario: payload.Comentario
      }
    case Types.OPEN_DISPERSAR_INDIVIDUAL:
      return {
        ...state,
        _id: payload.id,
        aceptarDispersarIndividual: true
      }
    case Types.OPEN_DISPERSAR_FINIQUITO:
      return {
        ...state,
        aceptarDispersarFiniquito: true
      }
    case Types.ACEPTAR_DISPERSAR_EVENTUAL:
      return {
        ...state,
        aceptarDispersarEventual: true
      }
    case Types.OPEN_DISPERSAR_INDIVIDUAL_EVENTUAL:
      return {
        ...state,
        aceptarDispersarIndividualEventual: true
      }
    case Types.OPEN_DELETE_INCIDENCIAS_PERIODICAS:
      return {
        ...state,
        _id: payload._id,
        showDeleteIncidentPeriodic: true
      }
    case Types.OPEN_APPROVE_INCIDENCIAS_PERIODICAS:
      return {
        ...state,
        _id: payload._id,
        showApproveIncidentPeriodic: true
      }
    case Types.CLEAR_DELETE_INCIDENCIAS_PERIODICAS:
      return {
        ...state,
        _id: payload._id,
        showDeleteIncidentPeriodic: false,
        showApproveIncidentPeriodic: false
      }
    case Types.ACEPTAR_DISPERSAR_SETTLEMENT:
      return {
        ...state,
        aceptarDispersarSettlement: true
      }
    case Types.ACEPTAR_DISPERSAR_FACTURACION_PAGOS:
      return {
        ...state,
        aceptarDispersarFacturacionPagos: true,
        _id: payload.id,
      }
    case Types.OPEN_OUTSOURCERS_MODAL_RESUMEN:
      return {
        ...state,
        openModalOutsourcersResumen: true
      }
    case Types.OPEN_DISPERSIONENPROCESO:
      return {
        ...state,
        openDispersionEnProceso: true
      }
    case Types.OPEN_ALERTANOGRAVABLE:
      return {
        ...state,
        openAlertaNoGravable: true
      }
    case Types.ADD_IN_PROGRESS_PAYROLL:
      return {
        ...state,
        inProgressPayroll: [...payload, ...state.inProgressPayroll]
      }
    case Types.UPDATE_IN_PROGRESS_PAYROLL:
      return {
        ...state,
        inProgressPayroll: [...payload]
      }
    default:
      return state
  }
}

export default ModalReducer;