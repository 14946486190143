const Excel = require("exceljs");

export const CreateExcelFilePayrollPNT = (data: any) => {
  const workbook = new Excel.Workbook();
  const ReporteTimbrado = workbook.addWorksheet("Reporte timbrar");

  const width = 40;

  const header = {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: "002060" },
  };
  const headerFont = {
    name: "Arial",
    color: { argb: "FFFFFF" },
    family: 2,
    size: 11,
    bold: true,
  };

  ReporteTimbrado.columns = [
    { header: "NOMBRE", key: "name", width },
    { header: "PERCEPCION", key: "percep", width },
    { header: "DEDUCCION", key: "dedu", width },
    { header: "NETO GRAVABLE", key: "NetoG", width },
    { header: "UUID", key: "UUID", width },
  ];

  

  let letter = 65;
  ReporteTimbrado.columns.forEach(() => {
    ReporteTimbrado.getCell(`${String.fromCharCode(letter)}1`).fill = header;
    ReporteTimbrado.getCell(`${String.fromCharCode(letter)}1`).font = headerFont;
    ReporteTimbrado.getCell(`${String.fromCharCode(letter)}1`).alignment = {
      horizontal: "center",
    };
    letter++;
  });

  

  data.forEach((item: any) => {


    ReporteTimbrado.addRow({
    
      name: item.Colaborador,
      percep: item.Percepcion,
      dedu: item.Deduccion,
      NetoG: item.NetoGravable,
      UUID: item.UUID,
      
    });
  });

  workbook.xlsx.writeBuffer().then(function (data: Blob) {
    const blob = new Blob([data],
      { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const url = window.URL.createObjectURL(blob);
    const anchor = document.createElement('a');
    anchor.href = url;
    anchor.download = 'ReporteTimbrado.xlsx';
    anchor.click();
    window.URL.revokeObjectURL(url);
  });
};