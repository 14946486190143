import {useContext} from "react";
import {
    Dialog,
    DialogContent,
    DialogActions,
} from '@material-ui/core';
import { closeDeduccionModal } from "../../Team/Modals/ModalDeduccionModal";
import DeduccionContext from "../../../context/ConfigPayrollContext/DeduccionContext";
import IOSSwitch from "../Switch";

const InfoDeduction = () =>{
    const {state, dispatch} = useContext(DeduccionContext)
     
    const handleClose = async () => {
        await closeDeduccionModal(dispatch)
    }

    return(
        <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={state.showInformacion}
        fullWidth={false}
        maxWidth={"md"}
      >
        <div className="contenedorModalPerceptions">
          <p className="tituloModalPerceptions">Información de la deducción</p>
        </div>
        <DialogContent className="ml32ModalPerceptions mr32ModalPerceptions">
          <div className="flexModalPerceptions mb24ModalPerceptions">
            <div>
              <span className="textoModal">Nombre</span>
            </div>
            <div>
              <input
                name="ConceptName"
                type="text"
                className="Mask"
                placeholder="Concepto *"
                defaultValue={state.ConceptName}
                readOnly
                maxLength={100}
                disabled
              />
            </div>
          </div>
          <div className="flexModalPerceptions mb24ModalPerceptions">
            <div>
              <span className="textoModal">Clave SAT</span>
            </div>
            <div>
              <input
                name="ConceptName"
                type="text"
                className="Mask"
                placeholder="Clave SAT *"
                defaultValue={state.SATKey}
                readOnly
                disabled
              />
            </div>
          </div>
          <div className="flexModalPerceptions mb24ModalPerceptions">
            <div>
              <span className="textoModal">Grava ISR</span>
            </div>
            <div>
              <div className="mr10ModalPerceptions">
                <IOSSwitch NombreCampo={"gravaISR"} Value={state.ISRTax} disabled={true}/>
              </div>
            </div>
          </div>
          <div className="flexModalPerceptions mb24ModalPerceptions">
            <div>
              <span className="textoModal">Clasificación</span>
            </div>
            <div>
            <select
                name="Classification"
                className="Mask"
                disabled
                style={{ width: "170px" }}
              >
                <option value="Dinero" selected={ state.isMoney } >Dinero</option>
                <option value="Tiempo" selected={ state.time } >Tiempo</option>
              </select>
            </div>
          </div>
          <div className="flexModalPerceptions mb24ModalPerceptions">
            <div>
              <span className="textoModal">Muestra Calendario</span>
            </div>
            <div>
              <div className="mr10ModalPerceptions">
                <IOSSwitch NombreCampo={"muestraCalendario"} Value={state.muestraCalendario} disabled={true}/>
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <div className="contenedorBotonesPoliticsModal">
            <button
              autoFocus
              onClick={handleClose}
              className="botonbotonGuardar"
            >
              Cerrar
            </button>
          </div>
        </DialogActions>
      </Dialog>
    </div>
    )
}


export default InfoDeduction