
const Excel = require("exceljs");

export const CreateExcelFileDispersionAceptada = (data: any) => {
    
    const workbook = new Excel.Workbook();
    const ReporteCalculoE= workbook.addWorksheet("Reporte Dispersion");

    const width = 45;

    const header = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "002060" },
    };
    const headerFont = {
        name: "Arial",
        color: { argb: "FFFFFF" },
        family: 2,
        size: 11,
        bold: true,
    };

    ReporteCalculoE.columns = [
        { header: "ORDEN", key: "Orden", width },
        { header: "FECHA OPERACION", key: "fechaOperacion", width },
        { header: "FOLIO", key: "Folio", width },
        { header: "TIPO PAGO", key: "tipoPago", width },
        { header: "PRIORIDAD", key: "Prioridad", width },
        { header: "USUARIO", key: "Usuario", width },
        { header: "CONTRAPARTE", key: "Contraparte", width },
        { header: "ESTADO", key: "Estado", width },
        { header: "IMPORTE", key: "Importe", width },
        { header: "ORDENANTE", key: "nombreOrdenante", width },
        { header: "RFC/CURP", key: "rfcCurpOrdenante", width },
        { header: "TIPO CUENTA", key: "tipoCuentaOrdenante", width },
        { header: "CUENTA", key: "cuentaOrdenante", width },
        { header: "BENEFICIARIO", key: "nombreBeneficiario", width },
        { header: "RFC/CURP BENEFICIARIO", key: "rfcCurpBeneficiario", width },
        { header: "TIPO CUENTA BENEFICIARIO", key: "tipoCuentaBeneficiario", width },
        { header: "CUENTA BENEFICIARIO", key: "cuentaBeneficiario", width },
        { header: "REFERENCIAS NUMERICA", key: "referenciaNumerica", width },
        { header: "DEVOLUCION", key: "causaDevolucion", width },
        
    ];
    
    let numletter = 65;
    let numletter2 = 65;
    let letter = "";
    ReporteCalculoE.columns.forEach(() => {
        
        
        if(numletter > 90){
            letter = 'A'+String.fromCharCode(numletter2)
            numletter2++;
        }else{
            letter = String.fromCharCode(numletter)
        }

        

        ReporteCalculoE.getCell(`${letter}1`).fill = header;
        ReporteCalculoE.getCell(`${letter}1`).font = headerFont;
        ReporteCalculoE.getCell(`${letter}1`).alignment = {
        horizontal: "center",
        };
    
        
        numletter++;
        
    });

    data.forEach((item: any) => {

        ReporteCalculoE.addRow({
            Orden: item?.Orden,
            fechaOperacion: item?.fechaOperacion,
            Folio: item?.Folio,
            tipoPago: item?.tipoPago,
            Prioridad: item?.Prioridad,
            Usuario: item?.Usuario,
            Contraparte: item?.Contraparte,
            Estado: item?.Estado,
            Importe: item?.Importe,
            nombreOrdenante: item?.nombreOrdenante,
            rfcCurpOrdenante: item?.rfcCurpOrdenante,
            tipoCuentaOrdenante: item?.tipoCuentaOrdenante,
            cuentaOrdenante: item?.cuentaOrdenante,
            nombreBeneficiario: item?.nombreBeneficiario,
            rfcCurpBeneficiario: item?.rfcCurpBeneficiario,
            tipoCuentaBeneficiario: item?.tipoCuentaBeneficiario,
            cuentaBeneficiario: item?.cuentaBeneficiario,
            referenciaNumerica: item?.referenciaNumerica,
            causaDevolucion: item?.causaDevolucion,

        });
    });

    workbook.xlsx.writeBuffer().then(function (data: Blob) {
        const blob = new Blob([data],
        { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement('a');
        anchor.href = url;
        anchor.download = 'ReporteDispersion.xlsx';
        anchor.click();
        window.URL.revokeObjectURL(url);
    });
};