import {createContext, Dispatch} from 'react';

type initialStateType = {
    openExpiredContracts:boolean,
}

type ModalPartial = {
    state:initialStateType,
    dispatch: Dispatch<any>
}

export const initialState = {
    openExpiredContracts:false,
}

export enum Types {
    OPENEXPIREDCONTRACTS = "OPENEXPIREDCONTRACTS",
    CLOSEEXPIREDCONTRACTS = "CLOSEEXPIREDCONTRACTS",
}

const ModalInitialState = {
    state:initialState,
    dispatch:() => null
}

const ContractsContext = createContext<ModalPartial>(ModalInitialState)

export default ContractsContext; 