import { Types } from "./ObjectiveContext";

const ObjectiveReducer = (state: any, action: any) => {
  const { payload, type } = action;
  switch (type) {
    case Types.CREATE_MODAL:
      return {
        ...state,
        createModal: true,
      };
    case Types.CANCEL_CREATE_MODAL:
      return {
        ...state,
        createModal: false,
      };
    case Types.EDIT_MODAL:
      return {
        ...state,
        editModal: true,
      };
    case Types.CANCEL_EDIT_MODAL:
      return {
        ...state,
        editModal: false,
      };
    case Types.ADD_EVIDENCE_MODAL:
      return {
        ...state,
        addEvidenceModal: true,
      };
    case Types.CANCEL_ADD_EVIDENCE_MODAL:
      return {
        ...state,
        addEvidenceModal: false,
      };
    case Types.SELECT_COLLABORATOR_MODAL:
      return {
        ...state,
        selectCollaboratorModal: true,
      };
    case Types.CANCEL_SELECT_COLLABORATOR_MODAL:
      return {
        ...state,
        selectCollaboratorModal: false,
      };
    case Types.SET_SELECTED_OBJECTIVE:
      return {
        ...state,
        selectedObjective: payload,
      };
    case Types.CLEAR_SELECTED_OBJECTIVE:
      return {
        ...state,
        selectedObjective: null,
      };
    case Types.DUPLICATE_MODAL:
      return {
        ...state,
        duplicateModal: true,
      };
    case Types.CANCEL_DUPLICATE_MODAL:
      return {
        ...state,
        duplicateModal: false,
      };
    case Types.SET_CAMPAIGN_ID:
      return {
        ...state,
        campaignId: payload,
      };
    case Types.SET_CAMPAIGN_INFO:
      return {
        ...state,
        campaign: payload,
      };
    case Types.SET_COLLABORATOR_ID:
      return {
        ...state,
        collaboratorId: payload,
      };
    case Types.SET_ACTIVE_TABLE:
      return {
        ...state,
        activeTable: payload,
      };
    case Types.EVALUATE_OBJECTIVE_MODAL:
      return {
        ...state,
        evaluateObjectiveModal: true,
      };
    case Types.CANCEL_EVALUATE_OBJECTIVE_MODAL:
      return {
        ...state,
        evaluateObjectiveModal: false,
      };
    case Types.SET_COLLABORATOR_NAME:
      return {
        ...state,
        collaboratorName: payload,
      };
    case Types.SET_COLLABORATORS_IDS:
      return {
        ...state,
        collaboratorsIds: payload,
      };
    case Types.CLEAR_OBJECTIVE_STATE:
      return {
        createModal: false,
        editModal: false,
        duplicateModal: false,
        selectCollaboratorModal: false,
        addEvidenceModal: false,
        objectives: [],
        campaignId: 0,
        collaboratorId: 0,
        collaboratorsIds: [],
        collaboratorName: "",
        selectedObjective: null,
        campaign: null,
        activeTable: "campaign",
        evaluateObjectiveModal: false,
      }
    case Types.SET_COLLABORATOR_OBJECTIVES_COUNT:
      return {
        ...state,
        collaboratorObjectivesCount: payload,
      };
    case Types.SET_USER_FROM_LINK:
      return {
        ...state,
        userFromLink: payload,
      };
    case Types.SET_IS_FROM_LINK:
      return {
        ...state,
        isFromLink: payload,
      };
    case Types.SET_ROL:
      return {
        ...state,
        rol: payload,
      };
    case Types.SET_DISPLAY_NOTIFY_LEADER_BUTTON:
      return {
        ...state,
        displayNotifyLeaderButton: payload,
      };
    case Types.SET_DISPLAY_APPROVE_OBJECTIVE_BUTTONS:
      return {
        ...state,
        displayApproveObjectiveButtons: payload,
      };
    case Types.RESET_OBJECTIVE_STATE:
      return {
        ...state,
        createModal: false,
        editModal: false,
        duplicateModal: false,
        selectCollaboratorModal: false,
        addEvidenceModal: false,
        objectives: [],
        collaboratorsIds: [],
        collaboratorName: "",
        selectedObjective: null,
        campaign: null,
        activeTable: "campaign",
        evaluateObjectiveModal: false,
        collaboratorObjectivesCount: 0,
        isFromLink: false,
        displayNotifyLeaderButton: false,
        displayApproveObjectiveButtons: false,
      };
    default:
      return state;
  }
};

export default ObjectiveReducer;
