import { createContext,Dispatch } from "react"

type initialStateType  ={
  _id: string,
  createOutsourcers: boolean,
  editOutsourcers: boolean,
  deleteOutsourcers: boolean
}

type ModalPartial = {
  state: initialStateType,
  dispatch: Dispatch<any>
}

export const initialState ={
  _id: '',
  createOutsourcers: false,
  editOutsourcers: false,
  deleteOutsourcers: false
}

export enum Types{
  CREATE_OUTSOURCERS = "CREATE_OUTSOURCERS",
  CLEAR_OUTSOURCERS = "CLEAR_OUTSOURCERS",
  CREATE_EDITOUTSOURCERS = "CREATE_EDITOUTSOURCERS",
  CREATE_DELETEOUTSOURCERS = "CREATE_DELETEOUTSOURCERS",
}

const ModalInitialState ={
  state: initialState,
  dispatch:() => null
}

const OutsourcersContext = createContext<ModalPartial>(ModalInitialState)
export default OutsourcersContext