import { Theme, Dialog, withStyles } from "@material-ui/core";
import "../Modal/CrearPolitic.css";
import MuiDialogContent from "@material-ui/core/DialogContent";
import { useEffect, useState, useContext } from "react";
import PoliticContext from "../../../context/ConfigPayrollContext/PoliticContext";
import { AdminContext } from '../../../context/AdminContext/AdminContext';
import { closePoliticModal } from "../../Team/Modals/ModalPoliticModal";
import "../Politic.css";
import { GET_ALL_HOLIDAYS, GET_HOLIDAYS_TEMPLATE, UPDATE_HOLIDAYS } from '../../../Querys/querys'
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import HolidaysList from "./HolidaysList";
import SaveIcon from "@material-ui/icons/Save";
import { ErrorAlert } from "../../../alerts/errorAlert";
import { SuccessfulAlert } from "../../../alerts/successAlerts";

interface Holidays {
  days: string;
  year: string;
  changed: boolean;
  error: string;
  id?: string;
  _id: string;
}

interface HolidayTemplate {
  AniosTrabajados: number;
  vacaciones: number;
  id: number;
}

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const PoliticHolidaysModal = (props: any) => {
  const { state, dispatch } = useContext(PoliticContext);
  const { adminState } = useContext(AdminContext);
  const [close, setClose] = useState(false);
  const [holidays, setHolidays] = useState<Holidays[]>([]);
  const [holidaysTemplate, setHolidayTemplate] = useState<HolidayTemplate[]>([]);
  const [checkHolidays, { data:resultHolidays }] = useLazyQuery(GET_ALL_HOLIDAYS,{
    fetchPolicy: 'no-cache',
  });
  const { data:ResultHolidaysTemplate } = useQuery(GET_HOLIDAYS_TEMPLATE);
  const [updateHoliday] = useMutation(UPDATE_HOLIDAYS, {
    refetchQueries: [{ query: GET_ALL_HOLIDAYS, variables:{id:state._id} }],
  });

  const handleClose = async () => {
    await closePoliticModal(dispatch);
    setClose(true);
  };

  useEffect(() => {
    if (close) {
      setTimeout(() => {
        setClose(false);
      }, 1000);
    }
  }, [close]);

  useEffect(()=>{
    if(resultHolidays && resultHolidays.GET_ALL_HOLIDAYS){
      setHolidays(resultHolidays.GET_ALL_HOLIDAYS.filter((x: any) => x.year !== 0));
    }
  }, [resultHolidays]);

  useEffect(()=>{
    if(ResultHolidaysTemplate && ResultHolidaysTemplate.GET_HOLIDAYS_TEMPLATE){
      setHolidayTemplate(ResultHolidaysTemplate.GET_HOLIDAYS_TEMPLATE);
    }
  }, [ResultHolidaysTemplate]);

  useEffect(()=>{
    if(state && state._id !== ''){
      checkHolidays({
        variables:{
          id: state._id
        }
      });
    }
  }, [state, checkHolidays]);

  const setError = (year:string, error: string) => {
    for(const holiday of holidays){
      if(Number(holiday.year) === Number(year)){
        holiday.error = error;
      }
    }
    setHolidays([...holidays]);
  }

  const onSubmit = async () => {
    try{
      let saved = 0;
      const changed_holidays = holidays.filter((holiday:Holidays)=>{
        return holiday.changed;
      })
      for(const holidayData of changed_holidays){
        const holidayYear = holidaysTemplate.filter((item:HolidayTemplate)=>{
          return Number(item.AniosTrabajados) === Number(holidayData.year);
        });
        setError(holidayData.year, '');
        if(
          holidayData.year === null || 
          String(holidayData.year) === '' ||
          holidayData.days === null ||
          String(holidayData.days) === '' 
        ){
          ErrorAlert({
            title: "¡Error!",
            text: "Es necesario completar los campos",
          });
          setError(holidayData.year, "Es necesario completar los campos");
          break;
        }else if(Number(holidayData.days) <= 0){
          ErrorAlert({
            title: "¡Error!",
            text: "Un año no puede tener días de vacaciones menores o iguales a 0",
          });
          setError(holidayData.year,"Un año no puede tener días de vacaciones menores o iguales a 0");
          break;
        }else if(holidayYear[0] && Number(holidayYear[0].vacaciones) > Number(holidayData.days) ){
          ErrorAlert({
            title: "¡Error!",
            text: `Los días de vacaciones no pueden ser menores a ${holidayYear[0].vacaciones} días en el año ${ holidayData.year }`,
          });
          setError(holidayData.year,`Los días de vacaciones no pueden ser menores a ${holidayYear[0].vacaciones} días en el año ${ holidayData.year }`);
          break;
        }else{
          if(holidayData.id){
            let { data } = await updateHoliday({
              variables: {
                id: holidayData.id,
                input: {
                  year: String(holidayData.year),
                  days: String(holidayData.days),
                  User: adminState?.Usuario,
                  idPolitic: state._id
                },
              },
            });
        
            if (data) {
              saved++;
            } else {
              ErrorAlert({
                title: "¡Error!",
                text: "¡No se han podido actualizar los días de vacaciones!",
              });
              break;
            }
          }
        }
      }
      if( saved === changed_holidays.length ){
        SuccessfulAlert({
          title: "¡Exito!",
          text: "¡Se han actualizado los días de vacaciones correctamente!",
        });
        handleClose();
      }
    }catch(e){
      ErrorAlert({
        title: "¡Error!",
        text: "¡No se han podido añadir los días de vacaciones!",
      });
    }
  }

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={state.showHolidays}
      fullWidth={true}
      maxWidth={"xs"}
    >
      <div className="holidays-modal-title">
        <p className="tituloModalPolitics">Editar d&iacute;as de vacaciones</p>
      </div>
      <DialogContent className="holidays-modal-content">       
        <HolidaysList holidays={holidays} politic={state}></HolidaysList>
        <div className="contenedorBotonesPoliticsModal">
          <button onClick={handleClose} className="botonbotonCancelar">
            Cancelar
          </button>
          <button onClick={onSubmit} className="botonbotonGuardar botonDoble">
            <div className="contenedorIconoBotonModal">
              <SaveIcon fontSize="small" />
            </div>
            <div>Guardar</div>
          </button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default PoliticHolidaysModal;
