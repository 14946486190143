/* eslint-disable array-callback-return */
import { useContext, useEffect, useState } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  Dialog,
  DialogContent,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  Select,
  TextField,
} from "@material-ui/core";

import styles from '../Objectives.module.css';
import Button from '../../../components/Button/Button';
import ObjectivesContext, { Types } from "../../../context/ObjectiveContext/ObjectiveContext";
import AddTable from "../../../screens/Colaboradores/Colaboradores/CollaboratorTab/AddTable";
import {
  CREATE_CAMPAIGN_OBJECTIVE_BY_USER,
  UPDATE_CAMPAIGN_OBJECTIVE,
  CREATE_CAMPAIGN_OBJECTIVE_BY_LEADER,
  GET_ALL_USER_CAMPAIGN_OBJECTIVES
} from "../../../Querys/querys";
import { Objective } from "../../../interfaces/Objective.interface";
import { AdminContext } from "../../../context/AdminContext/AdminContext";

const CreateObjectiveModal = () => {
  const { state, dispatch } = useContext(ObjectivesContext);
  const { adminState } = useContext(AdminContext);
  const [createObjectiveByUser] = useMutation(CREATE_CAMPAIGN_OBJECTIVE_BY_USER);
  const [createObjectiveByLeader] = useMutation(CREATE_CAMPAIGN_OBJECTIVE_BY_LEADER);
  const [getCollaboratorInfo] = useLazyQuery(GET_ALL_USER_CAMPAIGN_OBJECTIVES);
  const [editObjective] = useMutation(UPDATE_CAMPAIGN_OBJECTIVE);
  const [form, setForm] = useState<any>({
    name: "",
    nameIndicator: "",
    metricObjective: "",
    metricIndicator: "",
    weight: "",
    description: "",
  });
  const [formError, setFormError] = useState<any>({
    name: false,
    nameIndicator: false,
    metricObjective: false,
    metricIndicator: false,
    weight: false,
    description: false,
  });

  const openCollaboratorModal = () => {
    dispatch({ type: Types.SELECT_COLLABORATOR_MODAL })
  }

  useEffect(() => {
    if (state.selectedObjective) {
      setForm({
        name: state.selectedObjective.ObjectiveName,
        nameIndicator: state.selectedObjective.nameIndicator,
        metricObjective: state.selectedObjective.metricObjective,
        metricIndicator: state.selectedObjective.metricIndicator,
        weight: state.selectedObjective.weight?.toString(),
        description: state.selectedObjective.descriptionIndicator || "",
      });
    } else {
      setForm({
        name: "",
        nameIndicator: "",
        metricObjective: "",
        metricIndicator: "",
        weight: "",
        description: "",
      });
    }
  }, [state.selectedObjective]);

  const createObjectiveButton = async () => {
    let hasError = false;
    let newFormError = { ...formError };
    if (form.name === "") {
      newFormError = { ...newFormError, name: "Obligatorio" };
      hasError = true;
    } else {
      newFormError = { ...newFormError, name: false };
    }
    if (form.nameIndicator === "") {
      newFormError = { ...newFormError, nameIndicator: "Obligatorio" };
      hasError = true;
    } else {
      newFormError = { ...newFormError, nameIndicator: false };
    }
    if (form.metricObjective === "") {
      newFormError = { ...newFormError, metricObjective: "Obligatorio" };
      hasError = true;
    } else {
      newFormError = { ...newFormError, metricObjective: false };
    }
    if (form.metricIndicator === "") {
      newFormError = { ...newFormError, metricIndicator: "Obligatorio" };
      hasError = true;
    } else {
      newFormError = { ...newFormError, metricIndicator: false };
    }
    if (form.weight === "") {
      newFormError = { ...newFormError, weight: "Obligatorio" };
      hasError = true;
    } else {
      newFormError = { ...newFormError, weight: false };
    }

    setFormError(newFormError);

    if (hasError) return;

    if (state?.rol === 'leader') {
      if (state.activeTable === "campaign") {
        for await (const collaborator of state.collaboratorsIds) {
          const { data } = await getCollaboratorInfo({
            variables: {
              input: {
                campaignId: state.campaignId,
                collaboratorId: collaborator,
              }
            }
          })
          const collaboratorInfo: Objective[] = data?.GET_ALL_USER_CAMPAIGN_OBJECTIVES;
          const weightCount = collaboratorInfo.reduce((acc, curr) => acc + curr.weight, 0);
          if (weightCount + parseInt(form.weight, 10) > 100) {
            setFormError({ ...formError, weight: "El peso de los objetivos no puede ser mayor a 100" });
            return;
          }
          createObjectiveByLeader({
            variables: {
              input: {
                idCampaign: state.campaignId,
                idCollaborator: collaborator,
                objectiveName: form.name,
                nameIndicator: form.nameIndicator,
                metricObjective: parseInt(form.metricObjective, 10),
                metricIndicator: form.metricIndicator,
                weight: parseInt(form.weight, 10),
                descriptionIndicator: form.description,
                idLeader: parseInt(adminState?.id as any || state?.userFromLink?.id as any, 10)
              }
            }
          })
        }
      } else {
        const { data } = await getCollaboratorInfo({
          variables: {
            input: {
              campaignId: state.campaignId,
              collaboratorId: state.collaboratorId,
            }
          }
        })
        const collaboratorInfo: Objective[] = data?.GET_ALL_USER_CAMPAIGN_OBJECTIVES;
        const weightCount = collaboratorInfo.reduce((acc, curr) => acc + curr.weight, 0);
        if (weightCount + parseInt(form.weight, 10) > 100) {
          setFormError({ ...formError, weight: "El peso de los objetivos no puede ser mayor a 100" });
          return;
        }
        createObjectiveByLeader({
          variables: {
            input: {
              idCampaign: state.campaignId,
              idCollaborator: state.collaboratorId,
              objectiveName: form.name,
              nameIndicator: form.nameIndicator,
              metricObjective: parseInt(form.metricObjective, 10),
              metricIndicator: form.metricIndicator,
              weight: parseInt(form.weight, 10),
              descriptionIndicator: form.description,
              idLeader: parseInt(adminState?.id as any || state?.userFromLink?.id as any, 10)
            }
          }
        });
      }
    } else {
      const { data } = await getCollaboratorInfo({
        variables: {
          input: {
            campaignId: state.campaignId,
            collaboratorId: state.collaboratorId,
          }
        }
      })
      const collaboratorInfo: Objective[] = data?.GET_ALL_USER_CAMPAIGN_OBJECTIVES;
      const weightCount = collaboratorInfo.reduce((acc, curr) => acc + curr.weight, 0);
      if (weightCount + parseInt(form.weight, 10) > 100) {
        setFormError({ ...formError, weight: "El peso de los objetivos no puede ser mayor a 100" });
        return;
      }
      createObjectiveByUser({
        variables: {
          input: {
            idCampaign: state.campaignId,
            idCollaborator: state.collaboratorId,
            objectiveName: form.name,
            nameIndicator: form.nameIndicator,
            metricObjective: parseInt(form.metricObjective, 10),
            metricIndicator: form.metricIndicator,
            weight: parseInt(form.weight, 10),
            descriptionIndicator: form.description,
          }
        }
      });
    }
    dispatch({ type: Types.CANCEL_CREATE_MODAL })
    dispatch({ type: Types.CLEAR_SELECTED_OBJECTIVE })
    setForm({
      name: "",
      nameIndicator: "",
      metricObjective: "",
      metricIndicator: "",
      weight: "",
      description: "",
    });
  }

  const closeModal = () => {
    if (state.editModal) dispatch({ type: Types.CANCEL_EDIT_MODAL })
    if (state.createModal) dispatch({ type: Types.CANCEL_CREATE_MODAL })
    if (state.duplicateModal) dispatch({ type: Types.CANCEL_DUPLICATE_MODAL })
    dispatch({ type: Types.CLEAR_SELECTED_OBJECTIVE })
    setForm({
      name: "",
      nameIndicator: "",
      metricObjective: "",
      metricIndicator: "",
      weight: "",
      description: "",
    });
  }

  const editObjectiveButton = async () => {
    let hasError = false;
    let newFormError = { ...formError };
    if (form.name === "") {
      newFormError = { ...newFormError, name: "Obligatorio" };
      hasError = true;
    } else {
      newFormError = { ...newFormError, name: false };
    }
    if (form.nameIndicator === "") {
      newFormError = { ...newFormError, nameIndicator: "Obligatorio" };
      hasError = true;
    } else {
      newFormError = { ...newFormError, nameIndicator: false };
    }
    if (form.metricObjective === "") {
      newFormError = { ...newFormError, metricObjective: "Obligatorio" };
      hasError = true;
    } else {
      newFormError = { ...newFormError, metricObjective: false };
    }
    if (form.metricIndicator === "") {
      newFormError = { ...newFormError, metricIndicator: "Obligatorio" };
      hasError = true;
    } else {
      newFormError = { ...newFormError, metricIndicator: false };
    }
    if (form.weight === "") {
      newFormError = { ...newFormError, weight: "Obligatorio" };
      hasError = true;
    } else {
      newFormError = { ...newFormError, weight: false };
    }

    setFormError(newFormError);

    if (hasError) return;

    const { data } = await getCollaboratorInfo({
      variables: {
        input: {
          campaignId: state.campaignId,
          collaboratorId: state.collaboratorId,
        }
      }
    })
    const collaboratorInfo: Objective[] = data?.GET_ALL_USER_CAMPAIGN_OBJECTIVES;
    const weightCount = collaboratorInfo.reduce((acc, curr) => {
      if (parseInt(curr.id as any, 10) === parseInt(state?.selectedObjective?.id as any, 10)) {
        return acc;
      } else {
        return acc + curr.weight;
      }
    }, 0);
    if (weightCount + parseInt(form.weight, 10) > 100) {
      setFormError({ ...formError, weight: "El peso de los objetivos no puede ser mayor a 100" });
      return;
    }

    editObjective({
      variables: {
        input: {
          id: parseInt(state?.selectedObjective?.id as any, 10),
          ObjectiveName: form.name,
          nameIndicator: form.nameIndicator,
          metricObjective: parseInt(form.metricObjective, 10),
          metricIndicator: form.metricIndicator,
          weight: parseInt(form.weight, 10),
          descriptionIndicator: form.description,
        }
      }
    });
    dispatch({ type: Types.CANCEL_EDIT_MODAL });
    dispatch({ type: Types.CLEAR_SELECTED_OBJECTIVE });
    setForm({
      name: "",
      nameIndicator: "",
      metricObjective: "",
      metricIndicator: "",
      weight: "",
      description: "",
    });
  }

  const duplicateObjectiveButton = async () => {
    let hasError = false;
    let newFormError = { ...formError };
    if (form.name === "") {
      newFormError = { ...newFormError, name: "Obligatorio" };
      hasError = true;
    } else {
      newFormError = { ...newFormError, name: false };
    }
    if (form.nameIndicator === "") {
      newFormError = { ...newFormError, nameIndicator: "Obligatorio" };
      hasError = true;
    } else {
      newFormError = { ...newFormError, nameIndicator: false };
    }
    if (form.metricObjective === "") {
      newFormError = { ...newFormError, metricObjective: "Obligatorio" };
      hasError = true;
    } else {
      newFormError = { ...newFormError, metricObjective: false };
    }
    if (form.metricIndicator === "") {
      newFormError = { ...newFormError, metricIndicator: "Obligatorio" };
      hasError = true;
    } else {
      newFormError = { ...newFormError, metricIndicator: false };
    }
    if (form.weight === "") {
      newFormError = { ...newFormError, weight: "Obligatorio" };
      hasError = true;
    } else {
      newFormError = { ...newFormError, weight: false };
    }

    setFormError(newFormError);

    if (hasError) return;

    const { data } = await getCollaboratorInfo({
      variables: {
        input: {
          campaignId: state.campaignId,
          collaboratorId: state.collaboratorId,
        }
      }
    })
    const collaboratorInfo: Objective[] = data?.GET_ALL_USER_CAMPAIGN_OBJECTIVES;
    const weightCount = collaboratorInfo.reduce((acc, curr) => acc + curr.weight, 0);
    if (weightCount + parseInt(form.weight, 10) > 100) {
      setFormError({ ...formError, weight: "El peso de los objetivos no puede ser mayor al 100%" });
      return;
    }
    if (state?.rol === 'leader') {
      createObjectiveByLeader({
        variables: {
          input: {
            idCampaign: state.campaignId,
            idCollaborator: state.collaboratorId,
            objectiveName: form.name,
            nameIndicator: form.nameIndicator,
            metricObjective: parseInt(form.metricObjective, 10),
            metricIndicator: form.metricIndicator,
            weight: parseInt(form.weight, 10),
            descriptionIndicator: form.description,
            idLeader: parseInt(adminState?.id || "0", 10)
          }
        }
      });
    } else {
      createObjectiveByUser({
        variables: {
          input: {
            idCampaign: state.campaignId,
            idCollaborator: state.collaboratorId,
            objectiveName: form.name,
            nameIndicator: form.nameIndicator,
            metricObjective: parseInt(form.metricObjective, 10),
            metricIndicator: form.metricIndicator,
            weight: parseInt(form.weight, 10),
            descriptionIndicator: form.description,
          }
        }
      });
    }
    dispatch({ type: Types.CANCEL_DUPLICATE_MODAL });
    dispatch({ type: Types.CLEAR_SELECTED_OBJECTIVE });
    setForm({
      name: "",
      nameIndicator: "",
      metricObjective: "",
      metricIndicator: "",
      weight: "",
      description: "",
    });
  }

  const updateWeight = (e: any) => {
    if (parseInt(e.target.value, 10) > 100) {
      setForm({ ...form, weight: "100" });
      return;
    }
    if (parseInt(e.target.value, 10) < 0) {
      setForm({ ...form, weight: "0" });
      return;
    }
    setForm({ ...form, weight: e.target.value });
  }

  return (
    <Dialog
      aria-labelledby="customized-dialog-title"
      open={state.createModal || state.editModal || state.duplicateModal}
      fullWidth={true}
    >
      <h2 id="form-dialog-title" className="text-center">
        {state.editModal ? "Editar objetivo" : "Crear objetivo"}
      </h2>

      <DialogContent>
        <Grid xs item>
          <TextField
            name="name"
            value={form?.name}
            label="Nombre del objetivo *"
            variant="outlined"
            size="small"
            fullWidth
            onChange={(e) => setForm({ ...form, name: e.target.value })}
            InputProps={{
              readOnly: false,
            }}
            disabled={false}
            error={formError.name}
            helperText={formError.name}
          />
        </Grid>
      </DialogContent>

      <DialogContent>
        <Grid direction="row" container spacing={2}>
          <Grid xs item>
            <TextField
              name="nameIndicator"
              value={form?.nameIndicator}
              label="Nombre del indicador *"
              variant="outlined"
              size="small"
              fullWidth
              onChange={(e) => setForm({ ...form, nameIndicator: e.target.value })}
              InputProps={{
                readOnly: false,
              }}
              error={formError.nameIndicator}
              helperText={formError.nameIndicator}
            />
          </Grid>
          <Grid xs item>
            <TextField
              name="metricObjective"
              value={form?.metricObjective}
              label="Métrica objetivo *"
              variant="outlined"
              size="small"
              fullWidth={true}
              onChange={(e) => setForm({ ...form, metricObjective: e.target.value })}
              InputProps={{
                readOnly: false,
              }}
              error={formError.metricObjective}
              helperText={formError.metricObjective}
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogContent className={styles.twoInputsContainer}>
        <FormControl
          fullWidth
          size="small"
          variant="outlined"
          className="question"
          error={formError.metricIndicator}
        >
          <InputLabel id="demo-simple-select-label"
            className={styles.selectLabel}>Tipo del indicador *</InputLabel>
          <Select
            className={styles.select}
            value={form.metricIndicator}
            onChange={(e) => setForm({ ...form, metricIndicator: e.target.value })}
            labelId="demo-simple-select-label"
            id="metricIndicator"
            name="metricIndicator"
            error={formError.metricIndicator}
          >
            <option value="Pesos">Pesos</option>
            <option value="Porcentaje">Porcentaje</option>
            <option value="Unidades">Unidades</option>
          </Select>
          {formError.metricIndicator && (
            <FormHelperText>{formError.metricIndicator}</FormHelperText>
          )}
        </FormControl>
        <div style={{
          display: 'flex',
          alignItems: 'center',
          // justifyContent: 'space-between',
          width: '49%',
          gap: '10px'
        }}>
          <TextField
            name="weight"
            value={form?.weight}
            label="Peso *"
            variant="outlined"
            size="small"
            type="number"
            fullWidth
            onChange={updateWeight}
            InputProps={{
              readOnly: false,
            }}
            disabled={false}
            error={formError.weight}
            helperText={formError.weight}
          />
          <span style={{
            fontFamily: 'Roboto',
            fontSize: '14px',
            // fontWeight: '700',
            lineHeight: '16px',
            letterSpacing: '0px',
            color: '#093c5d'
          }}>%</span>
        </div>
      </DialogContent>

      <DialogContent>
        <Grid xs item>
          <TextField
            label="Descripción del objetivo"
            variant="outlined"
            value={form?.description}
            onChange={(e) => setForm({ ...form, description: e.target.value })}
            fullWidth
            multiline
            size="small"
            rows={2}
          />
        </Grid>
      </DialogContent>

      {
        state?.rol === 'leader' && state.activeTable === "campaign" && (
          <DialogContent>
            <button onClick={openCollaboratorModal} className={styles.selectCollaboratorsButton}>
              Administrar colaboradores {state.collaboratorsIds.length > 0 && `(${state.collaboratorsIds.length})`}
            </button>
          </DialogContent>
        )
      }

      <div className={styles.createOptionsContainer}>
        <button
          onClick={closeModal}
          className={state.editModal ? styles.secondarySmallButton : styles.secondaryButton}
        >Cancelar</button>
        {state.editModal && (
          <Button text="Aceptar" func={editObjectiveButton} />
        )}
        {(state.createModal || state.duplicateModal) && (
          <AddTable
            func={state.createModal ? createObjectiveButton : duplicateObjectiveButton}
            text="Crear Objetivo"
            img="add-goal.svg"
          />
        )}
      </div>
    </Dialog>
  );
};

export default CreateObjectiveModal;
