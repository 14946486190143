import { useReducer } from "react"
import OutsorcersNominaContext,{initalState} from "./OutsorcersNominaContext"
import OutsorcersNominaReducer from "./OutsorcersNominaReducer"

const OutsorcersNominaState = (props:any) =>{
  const [state, dispatch] = useReducer(OutsorcersNominaReducer, initalState)
  return(
    <OutsorcersNominaContext.Provider value={{state, dispatch}}>
      {props.children}
    </OutsorcersNominaContext.Provider>
  )
}

export default OutsorcersNominaState