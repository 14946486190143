import Box from '@mui/material/Box';
import BodyNuevoIngreso from '../components/Collaborator/BodyNuevoIngreso';

import '../components/Collaborator/Collaborator.css';

const NuevoIngresoCollaboratorScreen = () => {
    return (
        <Box boxShadow={0}>
            <BodyNuevoIngreso />
        </Box>
    );
}

export default NuevoIngresoCollaboratorScreen;