import { Dispatch } from "react"

import { DeduccionModalTypes } from "../../../context/ConfigPayrollContext/DeduccionModalTypes";

export const openDeduccionModal = ({ 
        _id = '',
        SATKey = '',
        ConceptName = '',
        showInformacion=false,
        showEliminar = false, 
        showEdit = false,
        ISRTax=false,
        TaxBoth=false,
        time = false,
        isMoney = false,
        muestraCalendario = false,
}: any, dispatch: Dispatch<any>): void =>{
    dispatch({
        type: DeduccionModalTypes.OPEN_DEDUCCION_MODAL,
        payload: {
            _id,
            SATKey,
            ConceptName,
            showEliminar,
            showEdit,
            ISRTax,
            TaxBoth,
            showInformacion,
            time,
            isMoney,
            muestraCalendario,
        }
    });
}

export const deleteDeduccionModal = ({ _id, value }: any, dispatch: Dispatch<any>): void =>{
    dispatch({
        type: DeduccionModalTypes.CLOSE_DEDUCCION_MODAL
    });
};

export const closeDeduccionModal = (dispatch: Dispatch<any>): void =>{
    dispatch({
        type: DeduccionModalTypes.CLOSE_DEDUCCION_MODAL 
    })
}