import '../components/Calendar/calendar.css';
import { AllCalendars } from '../components/NewCalendar/AllCalendars';
import CalendarsContext from '../context/NewCalendarContext/CalendarsState';

const CalendarNewScreen = () => {
    return (
    <div>
        <CalendarsContext>
            <AllCalendars/>
        </CalendarsContext>
    </div>
        

    )
};

export default CalendarNewScreen;