import { useContext } from "react";
import AdministratrosContext from "../../../context/AdministratorsContext/AdministratorsContext";
import { clearAdministratorsModal } from "../../../context/AdministratorsContext/Actions";
import {
  Dialog,
  DialogContent,
  TextField,
  DialogActions,
} from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useMutation } from "@apollo/client";
import { CREATE_ADMIN_USERS, GET_ALL_USER_ADMIN } from "../../../Querys/querys";
import { SuccessfulAlert } from "../../../alerts/successAlerts";
import { WarningAlert } from "../../../alerts/WarningAlert";
import styles from "../Administrators.module.css";
import { firebase } from './../../../firebase/firebase-config';

interface IAddRegisterModal {
  addFunc?: any;
}

export const CreateAdministrators = ({ addFunc }: IAddRegisterModal) => {
  const { state, dispatch } = useContext(AdministratrosContext);
  const handleClose = () => {
    clearAdministratorsModal({}, dispatch);
  };

  const [createUserAdmin] = useMutation(CREATE_ADMIN_USERS, {
    refetchQueries: [{ query: GET_ALL_USER_ADMIN }],
  });

  const initialValues = () => {
    return {
      Fullname: "",
      Usuario: "",
      Password: "",
    };
  };

  return (
    <div>
      <Dialog
        open={state.createModal}
        aria-labelledby="form-dialog-title"
        maxWidth="xs"
        fullWidth={true}
      >

        <div>
          <h2 id="form-dialog-title" data-testid="TitleModal">
            Agregar administrador
          </h2>
        </div>

        <div>
          <div className={styles.contenedorCerrarModal}>
            <div className={styles.iconoCerrarModal} onClick={(() => handleClose())}></div>
          </div>
        </div>

        <DialogContent>
          <Formik
            initialValues={initialValues()}
            validationSchema={validationSchema}
            onSubmit={async (formData: any) => {
              await createUserAdmin({
                variables: {
                  input: {
                    Fullname: formData.Fullname,
                    Usuario: formData.Usuario,
                    tempPassword: formData.Password,
                  },
                },
              })
                .then(() => {
                  if (formData.Fullname !== "" && formData.Usuario !== "" && formData.Password !== "") {
                    firebase.auth().createUserWithEmailAndPassword(formData.Usuario,formData.Password)
                    .then((result) => {
                        SuccessfulAlert({ text: "Usuario creado correctamente" });
                        handleClose();
                    })
                    .catch((error) => {
                      if(error.code === "auth/email-already-in-use" || error.code === 400){
                        WarningAlert({ text: `El usuario ya está registrado en nuestro sistema. Si olvidó su contraseña, puede restablecerla haciendo clic en '¿Olvidó su contraseña? en la ventana de inicio de sesión`});
                        handleClose();
                      }else{
                        WarningAlert({ text: 'El usuario no se ha podido registar, intente más tarde' });
                      }
                    });
                  } else {
                    WarningAlert({ text: 'El usuario no se ha podido registar, intente más tarde' });
                  }
                })
                .catch((err) => {
                  if (err.graphQLErrors[0].message) {
                    WarningAlert({ text: err.graphQLErrors[0].message });
                  }
 
                });
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleSubmit,
            }) => (
              <Form onSubmit={handleSubmit}>

                <TextField
                  type="text"
                  name="Fullname"
                  defaultValue={values?.Fullname}
                  label="Nombre completo"
                  variant="outlined"
                  size="small"
                  fullWidth={true}
                  onChange={handleChange}
                  error={Boolean(errors.Fullname) && touched.Fullname}
                  onBlur={(e) => handleChange(e)}
                  InputProps={{
                    readOnly: false,
                  }}
                  disabled={false}
                  helperText={
                    (!values?.Fullname && "Obligatorio") ||
                    (errors.Fullname === "error1" && "Ingrese un nombre válido")
                  }
                  style={{marginBottom: '10px'}}
                />
                <TextField
                  type="text"
                  name="Usuario"
                  defaultValue={values?.Usuario}
                  label="Correo electrónico"
                  variant="outlined"
                  size="small"
                  fullWidth={true}
                  onChange={handleChange}
                  error={Boolean(errors.Usuario) && touched.Usuario}
                  onBlur={(e) => handleChange(e)}
                  InputProps={{
                    readOnly: false,
                  }}
                  disabled={false}
                  helperText={
                    (!values?.Usuario && "Obligatorio") ||
                    (errors.Usuario === "error1" && "Ingrese un correo válido")
                  }
                  style={{marginBottom: '10px'}}
                />
                <TextField
                  type="password"
                  name="Password"
                  defaultValue={values?.Password}
                  label="Contraseña"
                  variant="outlined"
                  size="small"
                  fullWidth={true}
                  onChange={handleChange}
                  error={Boolean(errors.Password) && touched.Password}
                  onBlur={(e) => handleChange(e)}
                  InputProps={{
                    readOnly: false,
                  }}
                  inputProps={{
                    min: 6
                  }}
                  disabled={false}
                  helperText={
                    (!values?.Password && "Obligatorio") ||
                    (errors.Password)
                  }
                  style={{marginBottom: '10px'}}
                />
                <DialogActions>
                  <div className="contenedorBotonesPoliticsModal">
                    <button
                      onClick={handleClose}
                      className="botonbotonCancelar"
                      style={{cursor: "pointer"}}
                    >
                      Cancelar
                    </button>
                    <button
                      type="submit"
                      className="botonbotonGuardar botonDoble"
                      style={{cursor: "pointer"}}
                    >
                      <div className="contenedorIconoBotonModal">
                        <SaveIcon fontSize="small" />
                      </div>
                      <div>Guardar</div>
                    </button>
                  </div>
                </DialogActions>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </div>
  );
};

const validationSchema = Yup.object().shape({
  Fullname: Yup.string().required(),
  Usuario: Yup.string().email("error1").required("Campo requerido"),
  Password: Yup.string().min(6,"Tamaño mínimo de 6 caracteres").required("Campo requerido"),
});
