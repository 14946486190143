import {useState, useContext, useEffect} from 'react';
import {Dialog, DialogContent, withStyles, Theme  } from "@material-ui/core";
import { Grid, Rating, Alert, Button } from '@mui/material'
import '../MyMood.css';
import MuiDialogActions from "@material-ui/core/DialogActions";
import MyMoodContext from "../../../context/MyMoodContext/MyMoodContext";
import {closeQuestion} from '../../../context/MyMoodContext/Actions';
import { useLazyQuery } from '@apollo/client';
import TestComment from './TestComment';
import { GET_RESULTADO_SURVEY, GET_COMENTARIOS_SURVEY } from '../../../Querys/querys';
import ArrowRight from '../../../assets/svg/blue_arrow_right.svg';
import ArrowLeft from '../../../assets/svg/blue_arrow_left.svg';

type ResultSurvey = {
    Cinco: number;
    Cuatro: number;
    Dos: number;
    Pregunta: string;
    Promedio: number;
    Tres: number;
    Uno: number;
    [key:string]: any;
}

const DialogActions = withStyles((theme: Theme) => ({
  root: {
      margin: 0,
      padding: theme.spacing(1),
  },
}))(MuiDialogActions);

function TestCommentsDetail(){
  
  const {state,dispatch} = useContext(MyMoodContext);
  const [getResults, {data:resultData}] = useLazyQuery(GET_RESULTADO_SURVEY, {
    fetchPolicy: 'no-cache'
  }); 
  const [getComments, {data:resultComments}] = useLazyQuery(GET_COMENTARIOS_SURVEY, {
    fetchPolicy: 'no-cache'
  }); 
  const [comments, setComments] = useState([]);
  const [graphData, setGraphData] = useState<ResultSurvey[]>([]);
  const [currentQuestion, setCurrentQuestion] = useState(0);

  const averageAnswers = (answer:string) => {
    const total = graphData[currentQuestion].Uno + graphData[currentQuestion].Dos + graphData[currentQuestion].Tres + graphData[currentQuestion].Cuatro + graphData[currentQuestion].Cinco;
    const value = graphData[currentQuestion][answer] ? graphData[currentQuestion][answer]/total * 100 : 0;
    return String(value+"%");
  }

  const closeModal = () => {
    setComments([]);
    setGraphData([]);
    setCurrentQuestion(0);
    closeQuestion({}, dispatch);
  }

  useEffect(()=>{
    if(state.idSurvey){
        getData();
    }
  }, [state]);

  const getData = async () => {
    await getResults({
        variables:{
            id: state.idSurvey
        }
    });
    await getComments({
        variables:{
            id: state.idSurvey
        }
    });
  }

  useEffect(()=>{
    if(resultData && resultData.GET_RESULTADO_SURVEY){
        setGraphData( resultData.GET_RESULTADO_SURVEY );
    }
  },[resultData])

  useEffect(()=>{
    if(resultComments && resultComments.GET_COMENTARIOS_SURVEY){
        setComments( resultComments.GET_COMENTARIOS_SURVEY );
    }
  },[resultComments])

  const changeQuestion = (count:number) => {
    if(count === -1){
        if( currentQuestion + count >= 0 ){
            setCurrentQuestion( currentQuestion + count );
        }
    }else{
        if( currentQuestion + count < graphData.length ){
            setCurrentQuestion( currentQuestion + count );
        }
    }
  }

  return (
    <Dialog open={state.openComments} aria-labelledby="form-dialog-title" maxWidth="sm" fullWidth={true}>
        <div className="dialogContainer">
            <div className="containerTitle">
                <div
                style={{
                    marginLeft:'auto',
                    marginRight:'auto'
                }}
                >
                    <h2 id="form-dialog-title" data-testid="TitleModal" className="testTitle">{state.Encuestas}</h2>
                    <Grid container alignItems={'center'}>
                        <Grid xs={1}>
                            {
                                ( currentQuestion - 1 >= 0 ) && (
                                    <Button onClick={()=>{changeQuestion(-1)}}>
                                        <img src={ArrowLeft}></img>
                                    </Button>
                                )
                            }
                        </Grid>
                        <Grid xs={10}>
                            <p className="testMainSubtitle">{graphData[currentQuestion] ? graphData[currentQuestion].Pregunta : ''}</p>
                        </Grid>
                        <Grid xs={1}>
                            {
                                (currentQuestion + 1 < graphData.length) && (
                                    <Button onClick={()=>{changeQuestion(1)}}>
                                        <img src={ArrowRight}></img>
                                    </Button>
                                )
                            }
                        </Grid>
                    </Grid>
                </div>
                <div className="contenedorCerrarModal">
                    <div className="iconoCerrarModal" onClick={(()=>closeModal())}></div>      
                </div> 
            </div>
            <DialogContent className="dialogoContent"> 
                <Grid container>
                    <Grid xs={12} md={12}>
                        <Grid container alignItems={'center'}>
                            <Grid xs={12} md={1}>
                            </Grid>
                            <Grid xs={12} md={4}>
                                <div>
                                    <p className="testSubtitle">Promedio</p>
                                    <p className="testAverage">{ graphData[currentQuestion] ? graphData[currentQuestion].Promedio : '' }</p>
                                    <div className="testStarGraph">
                                        <Rating name="read-only" value={ graphData[currentQuestion] ? graphData[currentQuestion].Promedio : 0 } readOnly  precision={0.5}/>
                                    </div>
                                </div>
                            </Grid>
                            <Grid xs={12} md={1}>
                            </Grid>
                            <Grid xs={12} md={5}>
                                <div className="testBarContainer">
                                    <Grid container alignItems={'center'}>
                                        <Grid xs={1}>
                                            <p className="testTextbold">1</p>
                                        </Grid>
                                        <Grid xs={11}>
                                            <div className="testBar">
                                                <div className="testBarFilled" style={{ width: graphData[currentQuestion] ? averageAnswers('Uno') : '0px' }}></div>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </div>
                                <div className="testBarContainer">
                                    <Grid container alignItems={'center'}>
                                        <Grid xs={1}>
                                            <p className="testTextbold">2</p>
                                        </Grid>
                                        <Grid xs={11}>
                                            <div className="testBar">
                                                <div className="testBarFilled" style={{ width: graphData[currentQuestion] ? averageAnswers('Dos') : '0px' }}></div>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </div>
                                <div className="testBarContainer">
                                    <Grid container alignItems={'center'}>
                                        <Grid xs={1}>
                                            <p className="testTextbold">3</p>
                                        </Grid>
                                        <Grid xs={11}>
                                            <div className="testBar">
                                                <div className="testBarFilled" style={{ width: graphData[currentQuestion] ? averageAnswers('Tres') : '0px' }}></div>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </div>
                                <div className="testBarContainer">
                                    <Grid container alignItems={'center'}>
                                        <Grid xs={1}>
                                            <p className="testTextbold">4</p>
                                        </Grid>
                                        <Grid xs={11}>
                                            <div className="testBar">
                                                <div className="testBarFilled" style={{ width: graphData[currentQuestion] ? averageAnswers('Cuatro') : '0px' }}></div>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </div>
                                <div className="testBarContainer">
                                    <Grid container alignItems={'center'}>
                                        <Grid xs={1}>
                                            <p className="testTextbold">5</p>
                                        </Grid>
                                        <Grid xs={11}>
                                            <div className="testBar">
                                                <div className="testBarFilled" style={{ width: graphData[currentQuestion] ? averageAnswers('Cinco') : '0px' }}></div>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid xs={12} md={12}>
                        <p className="testSubtitleBlack">Comentarios</p>
                        <div className="testCommentList">
                            {
                                comments.map((comment:any, index:number)=>(
                                    <TestComment comment={comment}></TestComment>
                                ))
                            }
                            {
                                comments.length === 0 && (
                                    <Alert severity="info"> No hay comentarios para esta pregunta</Alert>
                                )
                            }
                        </div>
                    </Grid>
                </Grid>
            </DialogContent>
            
            <DialogActions>
                <button className="botonCancelar" onClick={
                    (()=>closeModal())
                }>
                    Cerrar
                </button>
            </DialogActions>
        </div>
    </Dialog>
  )
}

export default TestCommentsDetail;