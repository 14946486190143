import { useContext, useEffect, useState, useCallback } from "react";
import { useQuery } from "@apollo/client";
import Box from '@mui/material/Box';
import { useHistory, useParams } from "react-router-dom";
import { Grid } from "semantic-ui-react"
import CustomTab from "../components/Collaborators/Tab/CustomTabMain"
import CustomTabs from "../components/Collaborators/Tab/CustomTabs"
import styles from "../../src/components/Payroll/PayrollStyles.module.css";
import { GET_SETTLEMENTDISPERSION, GET_SETTLEMENTCOLLABORATOR_DISPERSION, GET_SETTLEMENTCOLLABORATOR_DISPERSION_EXCELL} from "../Querys/querys";
import { formatter } from "../helpers/formatoMoneda";
import { CreateExcelFilePayrollEvDL } from "../components/Payroll/Modals/ExcelPayrollEvDL";
import { CreateExcelFilePayrollEvD } from "../components/Payroll/Modals/ExcelPayrollEvD";
import { openaceptarDispersarSettlement, openfondosInsuficientes, openSinNominasSeleccionadas } from "../context/PayrollProcess/Actions";
import PayrollProcessContext from "../context/PayrollProcess/PayrollProcessContext";
import SinNominasModal from "../components/Payroll/Modals/SinNominasModal";
import FondosInsufucientesModal from '../components/Payroll/Modals/FondosInsuficientesModal';
import AceptarDispersarSettlement from "../components/Payroll/Modals/AceptarDispersarSettlement";
import CheckPermission from "../helpers/Administradores/Permissions";

const SettlementDispersion = () => {
  const {dispatch} = useContext(PayrollProcessContext);
  const { id, tab } = useParams<any>();
  const history = useHistory();
  const [cargandoPrincipal, setCargandoPrincipal] = useState(true);
  const [inicio, setInicio] = useState(0);
  const [total, setTotal] = useState(0);
  const [disponibleDispersar, setDisponibleDispersar] = useState(true);
  const [diferencia, setDiferencia] = useState(0);
  const [filtrado, setFiltrado] = useState<any[]>([]);
  const [datosTablas, setDatosTablas] = useState<any[]>([]);
  const [search, setSearch] = useState('');
  const [isCheck, setIsCheck] = useState<any[]>([]);
  const [procesoDispersion, setProcesoDispersion] = useState(false);
  const [dispersados,setDispersados] = useState(0);

  //Datos principales y headers
  const {loading:loadingPrincipal, data:dataPrincipal} = useQuery(GET_SETTLEMENTDISPERSION,{
    variables:{getSettlementdispersionId:id}
  })

  const PayrollData = dataPrincipal?.GET_SETTLEMENTDISPERSION;

  //Datos de tablas
  const {data:dataDipsersion, loading:loadingTabla, startPolling, stopPolling} = useQuery(GET_SETTLEMENTCOLLABORATOR_DISPERSION,{
    variables: { getSettlementcollaboratorDispersionId: id },
  });

  const allPayrollDispersion = dataDipsersion?.GET_SETTLEMENTCOLLABORATOR_DISPERSION;


  const {data:dataDipsersionExcell, loading:loadingTablaExcell} = useQuery(GET_SETTLEMENTCOLLABORATOR_DISPERSION_EXCELL,{
    variables: { getSettlementcollaboratorDispersionId: id },
  });

  const allPayrollDispersionExcell = dataDipsersionExcell?.GET_SETTLEMENTCOLLABORATOR_DISPERSION_EXCELL;

  const datosIniciales = useCallback(() =>{
    setCargandoPrincipal((current) => current = false);
    setInicio((current) =>current = PayrollData?.saldoCuenta);
  },[PayrollData]);

  const busqueda = (e:any)=>{
    setSearch((current) => current = e.target.value);
    guardaPaginado();
  }

  const getDatosTabla = useCallback(async() =>{
    setDatosTablas([...allPayrollDispersion]);
    let registrosLiquidado = (allPayrollDispersion.filter((e:any) => e.dispersionStatus ==='LIQUIDADO' )).length
    setDispersados((current) => current = registrosLiquidado);
  }, [allPayrollDispersion]);

  const guardaPaginado = useCallback(() =>{
    const results = !search ? datosTablas : datosTablas?.filter(
      (dato) => (dato.colaborator.toLowerCase().includes(search.toLocaleLowerCase())
      ))
      setFiltrado((current) => current = results)
  },[datosTablas, search]);

  const handleSelectAll = (e:any) =>{
    if(!e.target.checked){
      setIsCheck([])
    }
    let filtradoSeleccionado = (allPayrollDispersion.filter((lis:any)=>{
      return e.target.checked && !isCheck.includes(lis.id) && (lis.dispersionStatus === "EnEspera")
    }).map((lis:any) =>(lis.id)))
    setIsCheck(filtradoSeleccionado)
  }

  const handleClick =  (e:any) => {
    const {id, checked} = e.target
    setIsCheck([...isCheck, id])
    if(!checked){
      setIsCheck(isCheck.filter((lis:any) => lis !== id))
    }
  }

  const actualizaSaldos = useCallback(() =>{
    let arrayTotales = (allPayrollDispersion?.filter((lis:any)=>{
      return isCheck.includes(lis.id)
    }).map((lis:any) =>(lis.netIncomeTaxable)))

    if(arrayTotales?.length > 0) {
      const suma = (acc:any, curr:any) => acc +curr

      setTotal(arrayTotales.reduce(suma))
      setDiferencia(inicio - arrayTotales.reduce(suma))

      if((inicio - arrayTotales.reduce(suma)) >= 0){
        setDisponibleDispersar(true)
      }else{
        setDisponibleDispersar(false)
      }

    }else{
      setTotal(0)
      setDiferencia(0)
      setDisponibleDispersar(true)
    }
  }, [allPayrollDispersion, inicio, isCheck]);

  const reinicioDatos = () =>{
    setIsCheck([])
    actualizaSaldos()
  }

  const handleOnExport = (Tipo: any) => {
    if (Tipo === "Layout") {
      CreateExcelFilePayrollEvDL(allPayrollDispersionExcell);
    } else {
      CreateExcelFilePayrollEvD(allPayrollDispersion);
    }
  };

    //Alertas
    const openFondosInsuficientes = () => {
      openfondosInsuficientes({aceptarDisperar:true}, dispatch)
    }
  
    const openSinSeleccion = () => {
      openSinNominasSeleccionadas({sinNominasSeleccionadas:true }, dispatch);
    }
  
    const openAceptaDispersar = () => {
      openaceptarDispersarSettlement({ aceptarDispersarSettlement: true }, dispatch);
    };
  
  
  useEffect(()=>{
    if(dataPrincipal){
      datosIniciales()
      actualizaSaldos()
    }
  },[dataPrincipal, actualizaSaldos, datosIniciales]);

  useEffect(()=>{
    if(datosTablas){
      guardaPaginado()
    }
  },[datosTablas, search, guardaPaginado]);

  useEffect(()=>{
    if(allPayrollDispersion){
      getDatosTabla();
      actualizaSaldos();
    }
    startPolling(1000);
    return () =>{
      stopPolling()
    }
  },[allPayrollDispersion, startPolling, stopPolling, isCheck, actualizaSaldos, getDatosTabla]);

  return (
    <>
      <Box mt={3} ml={5} className="Title">
        Nóminas
      </Box>
      <Box p={5} pb={3} pt={0}>
        <Grid
          container
          justify="flex-start"
        >
          <CustomTabs
            value={tab}
            onChange={()=>{}}
            aria-label="simple tabs example"
          >
            <CustomTab label="Incidencias" value={0} />
            {CheckPermission("Nominas.PreNomina") && (
              <CustomTab label="Pre-Nómina" value={1} />
            )}
            {CheckPermission("Nominas.FiniquitoLiquidacion") && (
            <CustomTab label="Finiquito / Liquidación" value={2} /> )}
            {CheckPermission("Nominas.Eventuales") && (
            <CustomTab label="Eventuales" value={3} />)}
             {CheckPermission("Nominas.Historicas") && (
            <CustomTab label="Históricas" value={4} />)}
             {CheckPermission("Nominas.Calculadora") && (
            <CustomTab label="Calculadora" value={5} />)}
            {CheckPermission("Nominas.Reportes") && (
            <CustomTab label="Reportes" value={6} />)}
          </CustomTabs>
        </Grid>
      </Box>
      <div className={styles.pc_contenedorTitulo}>
        <div className={styles.pc_contenedorProceso}>
          <div>
            <span className={styles.pc_tituloProceso}>Calcular</span>
            <span className={styles.pc_tituloProceso}>Dispersar</span>
            <span className={styles.pc_tituloProceso}>Timbrar</span>
            <span className={styles.pc_tituloProceso}>Terminar</span>
          </div>
          <div className={styles.pc_contenedorIconoSeguimiento}>
            <div className={styles.pc_circuloAmarillo}>
              <div className={styles.pd_iconoCompleto}>
              </div>
            </div>
            <div className={styles.pc_lineaSeguimientoCompleto}>
            </div>
            <div className={styles.pc_circuloAmarillo}>
              <div className={styles.pc_circuloBlanco}>
              </div>
            </div>
            <div className={styles.pc_lineaSeguimiento}>
            </div>
            <div className={styles.pc_circuloGris}>
            </div>
            <div className={styles.pc_lineaSeguimiento}>
            </div>
            <div className={styles.pc_circuloGris}>
            </div>
          </div>
        </div>
        {(cargandoPrincipal === false)?
          <div className={styles.pc_contenedorBotones}>
            {
              CheckPermission("Nominas.FiniquitoLiquidacion.Ver.Dispersar.Dispersar") && (
                <>
                  {
                  (procesoDispersion === false)?
                    (isCheck.length > 0)?
                      (disponibleDispersar)?
                        <button
                          className={styles.pd_botonDispersarDos}
                          onClick={()=> openAceptaDispersar()}
                          >
                          <div className={styles.pd_iconoDispersarDos}></div>
                            Dispersar
                        </button>
                      :
                      <button
                        className={styles.pd_botonDispersarDosInactivo}
                        onClick={()=> openFondosInsuficientes()}
                      >
                        <div className={styles.pd_iconoDispersarDos}></div>
                          Dispersar
                      </button>
                    :
                    <button
                      className={styles.pd_botonDispersarDosInactivo}
                      onClick={()=> openSinSeleccion()}
                    >
                      <div className={styles.pd_iconoDispersarDos}></div>
                        Dispersar
                    </button>
                    :
                    <button
                    className={styles.pd_botonDispersarDosInactivo}
                    onClick={()=> openSinSeleccion()}
                  >
                    <div className={styles.pd_iconoDispersarDos}></div>
                      Dispersar
                  </button>
                  }
                </>
              )
            }
            {
              CheckPermission("Nominas.FiniquitoLiquidacion.Ver.Dispersar.IrATimbrar") && (
                <>
                  {(dispersados > 0)?
                    <button
                      className={styles.pd_botonDispersarDos}
                      onClick={() => history.push(`/SettlementTimbrar/${id}/${tab}`)}
                      >
                      <div>Ir a Timbrar</div>
                      <div className={styles.pc_iconoDispersar}></div>
                    </button>
                  :
                    <button
                      className={styles.pd_botonDispersarDos}
                      style={{opacity:0.5}}
                      >
                      <div>Ir a Timbrar</div>
                      <div className={styles.pc_iconoDispersar}></div>
                    </button>
                  }
                </>
              )
            }
          </div>
          :
          null
        }
      </div>
      {( loadingPrincipal === true)?
          <div className={styles.pc_contenedorPrincipal}>
            <div className={styles.contenedorLoading}>
              <div className={styles.loading}></div>
            </div>
          </div>
          :
          <div className={styles.pc_contenedorPrincipal}>
            <div className={styles.pc_tituloContenedorPrincipal}>
              <div className={styles.pc_contenedorNombre}>
                <div>
                  <span className={styles.pc_tituloPrincipal}>{tab}</span>
                </div>
                <div>
                </div>
              </div>
              <div className={styles.pd_contenedorTituloSaldos}>
                <div className={styles.pd_cuentaBancaria}>
                  <div className={styles.pd_textoCuentaBancaria}>
                    Cuenta Bancaria
                  </div>
                  <div className={styles.pd_contenedorCuentaBancaria}>
                    {PayrollData?.cuentaBancaria}
                  </div>
                </div>
                <div className={styles.pd_contenedorSaldos}>
                  <div className={styles.pd_contenedorInterno}>
                    <div className={styles.pd_tituloSaldos}>
                      Saldo disponible
                    </div>
                    <div className={styles.pd_saldoInterno}>
                      {formatter(inicio)}
                    </div>
                  </div>
                <div className={styles.pd_contenedorInterno}>
                  <div className={styles.pd_tituloSaldos}>
                    Monto a pagar
                  </div>
                  <div className={styles.pd_saldoInterno}>
                    {formatter(total)}
                  </div>
                </div>
                <div className={styles.pd_contenedorInterno}>
                  <div className={styles.pd_tituloSaldos}>
                    Diferencia
                  </div>
                    {(disponibleDispersar)?
                      <div className={styles.pd_saldoInterno}>
                        {formatter(diferencia)}
                      </div>
                    :
                      <div className={styles.pd_saldoInternoNegativo}>
                        {formatter(diferencia)}
                      </div>
                    }
                </div>
              </div>
            </div>
          </div>
          <div className={styles.pc_contenedorBuscadorDescargar}>
            <div className={styles.pc_contenedorBuscador}>
              <input
                value={search}
                className={styles.pc_inputBuscador}
                type="text"
                placeholder="Ingresa el nombre del colaborador"
                onChange={busqueda}
              />
              <div className={styles.pc_iconoBuscador}></div>
            </div>
            <div className={styles.contenedorBotonesDescargas}>
              <button
                className={styles.pc_botonDescargar}
                onClick={()=> handleOnExport("Layout")}
                >
                <div className={styles.pc_iconoDescargar}></div>
                <div>
                  Descargar layout
                </div>
              </button>
              <button
                className={styles.pc_botonDescargar}
                onClick={()=> handleOnExport("Reporte")}>
                <div className={styles.pc_iconoDescargar}></div>
                <div>
                  Descargar reporte
                </div>
              </button>
            </div>
          </div>
          <div className={styles.p_contenedorTablaDispersion}>
            <table className={styles.pd_tablaPrincipal}>
              <thead className={styles.pd_contenedorTitulos}>
                <td className={`${styles.pd_columnaTitulos} ${styles.pd_primerColumnaTitulo}`}>
                  <div id="colaboladores" className={styles.checkboxitem}>
                    <input
                      id="check"
                      type="checkbox"
                      onChange={(e) => handleSelectAll(e)}
                      disabled={loadingTabla ? true : false}
                    ></input>
                      <label htmlFor="check"> Colaboradores </label>
                    </div>
                </td>
                <td className={styles.pd_columnaTitulos}>Tipo</td>
                <td className={styles.pd_columnaTitulos}>RFC</td>
                <td className={styles.pd_columnaTitulos}>Banco</td>
                <td className={styles.pd_columnaTitulos}>Cuenta bancaria</td>
                <td className={styles.pd_columnaTitulos}>CLABE</td>
                <td className={styles.pd_columnaTitulos}>Gravable</td>
                <td className={styles.pd_columnaTitulos}>No Gravable</td>
                <td className={styles.pd_columnaTitulos}>Total</td>
                <td id="payroll_9" className={styles.pd_columnaTitulos}>Estatus</td>
              </thead>
              <tbody className={styles.pc_contenedorTitulos}>
                {filtrado?.map((lis:any)=>(
                  <tr className={styles.pc_bordePrimeraColumna}>
                    <td className={`${styles.pd_primerColumnaTitulo}`}>
                      <div id="colaboladores" className={styles.checkboxitem}>
                        <input
                          id={lis?.id}
                          key={lis?.id}
                          type="checkbox"
                          checked ={isCheck.includes(lis?.id)}
                          onChange={(e) => handleClick(e)}
                          value={lis?.id}
                          disabled={(lis?.dispersionStatus !== "EnEspera")?true:false}
                          ></input>
                        <label htmlFor={lis?.id} className={styles.datosLabel}>  {lis?.colaborator} </label>
                      </div>
                    </td> 
                    <td className={styles.pc_columnaDatos}>{lis?.Tipo}</td>
                    <td className={styles.pc_columnaDatos}>{lis?.RFC}</td>
                    <td className={styles.pc_columnaDatos}>{lis?.bank}</td>
                    <td className={styles.pc_columnaDatos}>{lis?.accountNumber}</td>
                    <td className={styles.pc_columnaDatos}>{lis?.clabeNum}</td>
                    <td className={styles.pc_columnaDatos}>{formatter(lis?.percepcion)}</td>
                    <td className={styles.pc_columnaDatos}>{formatter(lis?.deduccion)}</td>
                    <td className={styles.pc_columnaDatos}>{formatter(lis?.total)}</td>
                    <td className={styles.pc_columnaDatos}>
                      {(lis?.dispersionStatus === "ENPROCESO")?
                        <div className={`${styles.pd_botonEstatus} ${styles.pd_botonEstatusDos}`}>
                          <div className={styles.pd_textoBotonEstatusDos}>
                            En proceso...
                          </div>
                        </div>
                      : null
                      }
                      {(lis?.dispersionStatus === "ERROR")?
                        <div
                          className={styles.pd_botonEstatusTres}>
                          <div className={styles.pd_contenedorEstatusTres}>
                            <div className={styles.pd_contEstatusTres}>
                              <div className={styles.pd_iconoError}></div>
                                <div className={styles.pd_textoBotonError}>
                                  Error al dispersar
                                </div>
                              </div>
                            <div className={styles.pd_textoBotonErrorDos}>
                              {lis.mensajeDispersion}
                            </div>
                          </div>
                          <div className ={styles.pd_botonInternoDispersar}>
                            <div className={styles.pd_iconoIntentar}></div>
                          </div>
                        </div>
                      : null
                      }
                      {(lis?.dispersionStatus === "LIQUIDADO")?
                        <div className={`${styles.pd_botonEstatus} ${styles.pd_botonEstatusCuatro}`}>
                          <div className={styles.pd_textoBotonEstatusCuatro}>
                            Finalizado
                          </div>
                          <div className={styles.pd_iconoEstatusCuatro}></div>
                        </div>
                      : null
                      }
                    </td>
                  </tr>
                  ))
                }
              </tbody>
            </table>
          </div>
          <div className={styles.pd_contenedorBarraEstado}>
            <div className={styles.pd_barraEstado} >
              <div className={styles.pd_contenedorRegreso}
                onClick={() =>history.push(`/InitSettlement/${id}/${tab}`)}
              >
              <div className={styles.pd_botonRegreso}></div>
              <div>Regresar a "Calcular"</div>
              </div>
                <button className={styles.pd_botonSalir}
                  onClick={() => history.push(`/payroll`)}
                >
                  Salir
                </button>
            </div>
          </div>

          </div>
      }
      <SinNominasModal/>
      <FondosInsufucientesModal />
      <AceptarDispersarSettlement 
        totalesDispersar ={isCheck?.length}
        totales={allPayrollDispersion?.length}
        seleccionados={isCheck}
        setProcesoDispersion={setProcesoDispersion}
        reinicia={() => reinicioDatos()}
        idRegistro={id}
      />
  </>
  )
}

export default SettlementDispersion