export interface TabCollaboratorinterfaces {
    data?: Collaborator[];
    error?: string
}

export interface Collaborator {
    _id: string;
    Proyecto: string;
    Sede: string;
    Cliente: string;
    email: string;
    FechaIngreso: string;
    Puesto?: string;
    FullName: string;
    FechaBaja?: string;
    netSalary?: number;
    totalSalary?: number;
    grossSalary?: number;
    reasonCollaborator?: string;
    typeDrop?: string;
    img?: string;
    baja?: boolean;
    done?: boolean;
    bussinesName: string;
    terminationDate: string;
    Nombre?: string;
    ApellidoPaterno?: string;
    ApellidoMaterno?: string;
    FechaNacimiento?: string;
    ImagenUsuario?: string;
    lastName?: string;
  
}

export interface CollaboratorModal {
    Nombre: string;
    ApellidoPaterno: string;
    ApellidoMaterno: string;
    FechaNacimiento: string;
    FechaIngreso: string;
    email: string;
    bussinesName: string;
    terminationDate: string;
    Sede: string;
    Cliente: string;
    Proyecto: string;
    Anfitrion?: string;
}

export enum TabCollaboratorTypes {
    init = '[Tab Collaborator] Init',
    add = '[Tab Collaborator] Add',
    substract = '[Tab Collaborator] Substract',
    filter = '[Tab Collaborator] Filter',
    scheduleDrop = '[Tab Collaborator] Scuedule Drop',
    setCollaborator = '[Tab Collaborator] Set Colaborator',
    cleanCollaborator = '[Tab Collaborator] Clean Colaborator',
}

export type ActionTabCollaboratorReducer = {
    type: TabCollaboratorTypes,
    payload?: any
};
export interface StateTabCollaboratorInterface {
    loading: boolean;
    collaborators: Collaborator[];
    collaboratorsFilter?: Collaborator[];
    collaborator?: Collaborator | null;
};

export interface ScheduleDropCollaborator {
    FechaBaja: string;
    reasonCollaborator: string;
    typeDrop: string;
}

export interface Holidays {
    holidays_total: number;
    economic_total: number;
    economic_used: number;
    holidays_used: number;
}

export interface Timeline{
    year: number;
    events: Event[];
}

export interface Event{
    event: string;
    title: string;
    date: string;
    amount: number;
    previousAmount: number;
    datePrevious?: string;
    dateCurrent?: string;
    previousPosition: string;
    currentPosition: string;
}

export interface CollaboratorHistory {
    id: number;
    name: string;
    firstName: string;
    lastName: string;
    fullName: string;
    dateOfBirth: string;
    email: string;
    dateOfAdmission: string;
    userType: string;
    project: string;
    campus: string;
    client: string;
    password: string;
    active: boolean;
    token: string;
    linkedAccount: string;
    cellphone: string;
    area: string;
    tittle: string;
    officePlace: string;
    bussinesName: string;
    contractName: string;
    netSalary: number;
    grossSalary: number;
    DS: number;
    ISD: number;
    nominesPayroll: string;
    paymentPeridicity: string;
    journey: string;
    comment: string;
    userStatus: string;
    expiredAlta: boolean;
    expiredPersonales: boolean;
    currentToken: string;
    host: string;
    address: string;
    municipality: string;
    ZC: string;
    state: string;
    country: string;
    placeOfBirth: string;
    nacionality: string;
    gender: string;
    civilStatus: string;
    children: number;
    educationalLevel: string;
    ClaveElectoralPasaporte: string;
    IMSS: string;
    CURP: string;
    workPermission: string;
    creditoInfonavit: string;
    benefitiary: string;
    emergencyContact: string;
    diseases: string;
    bank: string;
    accountNumber: string;
    clabeNum: string;
    status: string;
    RFC: string;
    id_payroll_group: number;
    SDG: number;
    id_User: number;
    id_Bank: number;
    done: boolean;
    collaborator: boolean;
    drop: boolean;
    baja: boolean;
    Puesto: string;
    Company: string;
    new: boolean;
    suburb: string;
    benefitiaryCountry: string;
    benefitiaryState: string;
    benefitiaryMunicipality: string;
    benefitiaryCURP: string;
    ParentescoB: string;
    benefitiaryDateOfBirth: string;
    benefitiaryZC: string;
    benefitiaryAddress: string;
    benefitiarySuburb: string;
    ParentescoCE: string;
    cellphoneCE: string;
    phoneCE: string;
    suburbCE: string;
    addressCE: string;
    Alergias: string;
    PadEnfer: string;
    Cirugias: string;
    TraMeRe: string;
    dropDate: string;
    dropType: string;
    FullName: string;
    ImagenUsuario: string;
    terminationDate: string;
    puestos: any;
    archive: any;
    FechaIngreso: string;
    FechaBaja: string;
    typeDrop: string;
    reasonCollaborator: string;
    dateContractDate: string;
    EmployerRegister: string;
    recordableSalary: number;
    notRecordableSalary: number;
    totalSalary: number;
    typeContract: string;
    typeSalary: string;
    idEnterprise: number;
    enterprise: any;
    CuentaBancaria_PDF: number;
    ActaNacimiento_PDF: number;
    ComprobanteEstudios_PDF: number;
    ComprobanteDomicilio_PDF: number;
    IdentificacionOficial_PDF: number;
    RFC_PDF: number;
    CURP_PDF: number;
    ComprobanteNSS_PDF: number;
    Foto_IMG: number;
    AvisoRetencion_PDF: number;
    CartaGMM_PDF: number;
    CartaOfertaFirmada: number;
    AGREEMENT_PDF: number;
    CartaOferta: number;
    isAvisoRetencion: boolean;
    isCartaGMM: boolean;
    register_process: any;
    own_register: boolean;
    userAdd: string;
    accept_info: boolean;
    tokenNominaEventual: string;
    puestoId: number;
    idJourney: string;
    unionized: boolean;
    calendarId: number;
    INFONAVIT: number;
    Periodicity_INFONAVIT: string;
    typeWorker: string;
    typeSalaryN: string;
    weekPartTimeId: number;
    weekPartTime: string;
    ZCSAT: string;
    idProject: number;
    isFondoAhorro: boolean;
    PorcentajeFondoAhorro: number;
    
}