import {useContext} from "react";
import {
    Dialog,
    DialogContent,
    DialogActions,
} from '@material-ui/core';
import { closePerceptionModal } from "../../Team/Modals/ModalPerceptionModal";
import PerceptionContext  from "../../../context/ConfigPayrollContext/PerceptionContext"
import IOSSwitch from "../Switch";

const InfoPerception = () =>{
    const {state, dispatch} = useContext(PerceptionContext)
     
    const handleClose = async () => {
        await closePerceptionModal(dispatch)
    }

    return(
        <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={state.showInformacion}
        fullWidth={false}
        maxWidth={"md"}
      >
        <div className="contenedorModalPerceptions">
          <p className="tituloModalPerceptions">Información de la percepción</p>
        </div>
        <DialogContent className="ml32ModalPerceptions mr32ModalPerceptions">
          <div className="flexModalPerceptions mb24ModalPerceptions">
            <div>
              <span className="textoModal">Nombre</span>
            </div>
            <div>
              <input
                name="ConceptName"
                type="text"
                className="Mask"
                placeholder="Concepto *"
                defaultValue={state.ConceptName}
                readOnly
                maxLength={100}
                disabled
              />
            </div>
          </div>
          <div className="flexModalPerceptions mb24ModalPerceptions">
            <div>
              <span className="textoModal">Clave SAT</span>
            </div>
            <div>
              <input
                name="ConceptName"
                type="text"
                className="Mask"
                placeholder="Clave SAT *"
                defaultValue={state.SATKey}
                readOnly
                disabled
              />
            </div>
          </div>
          <div className="flexModalPerceptions mb24ModalPerceptions">
            <div>
              <span className="textoModal">Cuenta Contable</span>
            </div>
            <div>
              <input
                name="AccountingAccount"
                type="text"
                className="Mask"
                defaultValue={state.AccountingAccount}
                maxLength={100}
                disabled
              />
            </div>
          </div>
          <div className="flexModalPerceptions mb24ModalPerceptions">
            <div>
              <span className="textoModal">Tipo de pago</span>
            </div>
            <div>
              <select
                name="PayType"
                className="selectModalPolitics"
                disabled
              >
                <option
                  value="Selecciona una de las opciones"
                  selected
                  disabled
                >
                  Selecciona una de las opciones
                </option>
                <option
                  value="Transferencia"
                  selected={String(state.PayType) === "Transferencia" ?? true}
                >
                  Transferencia
                </option>
                <option
                  value="Tarjeta empresarial"
                  selected={String(state.PayType) === "Tarjeta empresarial" ?? true}
                >
                  Tarjeta empresarial
                </option>
                <option
                  value="No aplica"
                  selected={String(state.PayType) === "No aplica" ?? true}
                >
                  No aplica
                </option>
              </select>
            </div>
          </div>
          <div className="flexModalPerceptions mb24ModalPerceptions">
            <div>
              <span className="textoModal">Grava ISR</span>
            </div>
            <div>
              <div className="mr10ModalPerceptions">
                <IOSSwitch NombreCampo={"gravaISR"} Value={state.gravaISR} disabled={true}/>
              </div>
            </div>
          </div>
          <div className="flexModalPerceptions mb24ModalPerceptions">
            <div>
              <span className="textoModal">Clasificación</span>
            </div>
            <div>
              <select
                name="Classification"
                className="selectModalPolitics"
                disabled
              >
                <option value="Dinero" selected={ state.isMoney } >Dinero</option>
                <option value="Tiempo" selected={ state.time } >Tiempo</option>
              </select>
            </div>
          </div>
          <div className="flexModalPerceptions mb24ModalPerceptions">
            <div>
              <span className="textoModal">Muestra Calendario</span>
            </div>
            <div>
              <div className="mr10ModalPerceptions">
                <IOSSwitch NombreCampo={"muestraCalendario"} Value={state.muestraCalendario} disabled={true}/>
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <div className="contenedorBotonesPoliticsModal">
            <button
              autoFocus
              onClick={handleClose}
              className="botonbotonGuardar"
            >
              Cerrar
            </button>
          </div>
        </DialogActions>
      </Dialog>
    </div>
    )
}


export default InfoPerception