import {
  Theme,
  Dialog,
  withStyles,
  Select,
} from "@material-ui/core";
import "../Modal/CrearPayrollGroup.css";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import { useEffect, useState, useContext } from "react";
import { SuccessfulAlert } from "../../../alerts/successAlerts";
import PerceptionContext from "../../../context/ConfigPayrollContext/PerceptionContext";
import { closePerceptionModal } from "../../Team/Modals/ModalPerceptionModal";
import "../PayrollGroup.css";
import "./ModalPerceptions.css";
import IOSSwitch from "../../ConfigPayroll/Switch";
import { updatePerception } from "../../../helpers/Nomina/Perceptions";

import SaveIcon from "@material-ui/icons/Save";

import { useMutation } from "@apollo/client";
import {
  GET_ALL_PERCEPTIONS,
  UPDATE_PERCEPTIONS,
  GET_ALL_PERCEPTIONS_CALENDAR
} from "../../../Querys/querys";

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const EditaPerception = (props: any) => {
  const [updatePerceptions] = useMutation(UPDATE_PERCEPTIONS, {
    refetchQueries: [
                      { query: GET_ALL_PERCEPTIONS },
                      {query: GET_ALL_PERCEPTIONS_CALENDAR},
                    ],
  });
  
  const { state, dispatch } = useContext(PerceptionContext);
  const [classification, setClassification] = useState('Dinero');

  useEffect(() => {
    async function fetchData() {
    }

    fetchData();
    return () => {
      fetchData();
    };
  }, [state._id]);

  useEffect(() => {
    if(state.time && !state.isMoney){
      setClassification('Tiempo')
    }else{
      setClassification('Dinero')
    }
  }, [state.time, state.isMoney])

  const onChange = async (e: any) => {
    let nuevoValor = e.target.value;
    if(e.target.name !== 'Classification'){
      await updatePerception(e, state, dispatch, e.target.name, nuevoValor);
    }else{
      setClassification(nuevoValor)
    }
    
  };

  const onSumbit = async () => {
    updatePerceptions({
      variables: {
        updatePerceptionsId: state._id,
        input: {
          ConceptName: state.ConceptName?? "",
          SATKey: state.SATKey?? "",
          AccuntingAccount: state.AccountingAccount ?? "",
          PayType: state.PayType ?? "",
          gravaISR: state.gravaISR ?? false,
          classification: classification,
          muestraCalendario: state.muestraCalendario  ?? false,
        },
      },
    }).then((res) => {
      SuccessfulAlert({
        title: "¡Exito!",
        text: "¡Se ha actualizado la percepción correctamente!",
      });
  
      closePerceptionModal(dispatch);
  
      dispatch({ type: "CLOSE_PERCEPTION_MODAL" });
    })

    
  };

  const handleClose = async () => {
    await closePerceptionModal(dispatch);
    dispatch({ type: "CLOSE_PERCEPTION_MODAL" });
  };

  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={state.showEdit}
        fullWidth={false}
        maxWidth={"md"}
      >
        <div className="contenedorModalPerceptions">
          <p className="tituloModalPerceptions">Edita tu percepción</p>
        </div>
        <DialogContent className="ml32ModalPerceptions mr32ModalPerceptions">
          <div className="flexModalPerceptions mb24ModalPerceptions">
            <div>
              <span className="textoModal">Nombre</span>
            </div>
            <div>
              <input
                name="ConceptName"
                type="text"
                className="Mask"
                placeholder="Concepto *"
                defaultValue={state.ConceptName}
                
                maxLength={100}
                onChange={(e) => onChange(e)}
              />
            </div>
          </div>
          <div className="flexModalPerceptions mb24ModalPerceptions">
            <div>
              <span className="textoModal">Clave SAT</span>
            </div>
            <div>
              <input
                name="ConceptName"
                type="text"
                className="Mask"
                placeholder="Clave SAT *"
                defaultValue={state.SATKey}
                
                disabled
                onChange={(e) => onChange(e)}
              />
            </div>
          </div>
          <div className="flexModalPerceptions mb24ModalPerceptions">
            <div>
              <span className="textoModal">Cuenta Contable</span>
            </div>
            <div>
              <input
                name="AccountingAccount"
                type="text"
                className="Mask"
                defaultValue={state.AccountingAccount}
                maxLength={100}
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                onChange={(e) => onChange(e)}
              />
            </div>
          </div>
          <div className="flexModalPerceptions mb24ModalPerceptions">
            <div>
              <span className="textoModal">Tipo de pago</span>
            </div>
            <div
             style={{
              width: "40%",
            }}
            >
              
              <Select
                  native
                  variant="outlined"
                  style={{
                    width: "100%",
                  }}
                  name = "PayType"
                  value={state.PayType}
                  onChange={(e) => onChange(e)}
              >
                <option
                  value="Selecciona una de las opciones"
                  selected
                  disabled
                >
                  Selecciona una de las opciones
                </option>
                <option value="Transferencia">Transferencia</option>
                <option value="Tarjeta empresarial">Tarjeta empresarial</option>
                <option value="No aplica">No aplica</option>
              </Select>
            </div>
          </div>
          <div className="flexModalPerceptions mb24ModalPerceptions">
            <div>
              <span className="textoModal">Grava ISR</span>
            </div>
            <div>
              <div className="mr10ModalPerceptions">
                <IOSSwitch NombreCampo={"gravaISR"} Value={state.gravaISR} />
              </div>
            </div>
          </div>
          <div className="flexModalPerceptions mb24ModalPerceptions">
            <div>
              <span className="textoModal">Clasificación</span>
            </div>
            <div
             style={{
              width: "40%",
            }}
            >
              <Select
                  native
                  variant="outlined"
                  style={{
                    width: "100%",
                  }}
                  name = "Classification"
                  value={classification}
                  onChange={(e) => onChange(e)}
              >
                <option value="Dinero">Dinero</option>
                <option value="Tiempo">Tiempo</option>
              </Select>
            </div>
          </div>
          <div className="flexModalPerceptions mb24ModalPerceptions">
            <div>
            <span className="textoModal">Muestra Calendario</span>
            </div>
            <div>
              <div className="mr10ModalPerceptions">
                <IOSSwitch NombreCampo={"muestraCalendario"} Value={state.muestraCalendario} />
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <div className="contenedorBotonesPoliticsModal">
            <button
              autoFocus
              onClick={handleClose}
              className="botonbotonCancelar"
            >
              Cancelar
            </button>
            {String(state.SATKey) === '016'?   
              <button
                autoFocus
                onClick={onSumbit}
                className="botonbotonGuardar botonDoble"
              >
                <div className="contenedorIconoBotonModal">
                  <SaveIcon fontSize="small" />
                </div>
                <div>Guardar</div>
              </button>
            : null  }
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default EditaPerception;
