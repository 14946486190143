import Swal from "sweetalert2";
import loadingGif from '../../src/assets/loading.gif'
export const LoadingAlert = ({ title = 'Creando al candidato...' }) => Swal.fire({
    title,
    showConfirmButton: false,
    allowOutsideClick: false,
    didOpen: () => {
        Swal.showLoading()
    }
});

export const LoadingUser = () => Swal.fire({
    title: 'Cargando Datos...',
    timerProgressBar: true,
    didOpen: () => {
        Swal.showLoading()
    }
});

export const LoadingFile = () => Swal.fire({
    title: 'Subiendo archivo...',
    didOpen: () => {
        Swal.showLoading()
    }
});

export const LoadingAlertGiff = ({
    title = 'Creando al candidato...',
}) => Swal.fire({
    html: `<label> ${title} </label>`,
    
    imageUrl: loadingGif,
    imageWidth: "150px",
    imageHeight: "150px",
    customClass: {
        title: 'Title',
        htmlContainer: 'text-color-sweetalert',
    },
    allowOutsideClick: false,
    showCloseButton: false,
    focusConfirm: false,
    focusDeny: false,
    focusCancel: false,
    showConfirmButton: false,
});