import { useContext, useState, useEffect } from "react"
import EmpresaContext from "../../../context/Empresa/EmpresaContext"
import {Dialog} from "@material-ui/core"
import { clearEmpresaModal } from "../../../context/Empresa/Actions"
import styles from "../../../styles/MiEmpresa/CrearEmpresa.module.css"
import TabsUnstyled from "@mui/base/TabsUnstyled";
import TabsListUnstyled from "@mui/base/TabsListUnstyled";
import TabUnstyled from "@mui/base/TabUnstyled";
import IdentidadLegal from "./subMenus/IdentidadLegal"
import CertificadosLlaves from "./subMenus/CertificadosLlaves"
import InformacionBancaria from "./subMenus/InformacionBancaria"


const CrearEmpresa = () => {
  const {state, dispatch} = useContext(EmpresaContext)
  const [activo, setActivo] = useState('1')
  const [fileLogo, setFileLogo] = useState<any>();
  const [fileIMSS, setFileIMSS] = useState<any>();
  const [fileSello, setFileSello] = useState<any>();
  const [fileKeyTimbre, setFileKeyTimbre] = useState<any>();
  const [fileCerTimbre, setFileCerTimbre] = useState<any>();
  const [regimenFiscalState, setRegimenFiscalState] = useState<any>(state.identidadLegal.regimenFiscal)
  const [estadoState, setEstadoState] = useState<any>(state.identidadLegal.estado)
  const [municipioState, setMunicipioState] = useState<any>(state.identidadLegal.municipio)
  const [bankState, setBankState] = useState<any>(state.informacionBancaria.idBank)  
  
  const [riskPlaced, setRiskPlaced] = useState<any>('');
  const [outsourcers, setOutsourcers] = useState<any>(false);
  const handleClose = () => {
    setActivo("1")
    setFileLogo(undefined)
    setFileIMSS(undefined)
    setFileSello(undefined)
    clearEmpresaModal({}, dispatch)
  } 

  useEffect(()=>{
    setActivo(activo)
  },[activo])

  const certificadoLlaves = ()=>{

    if(state.tabUno === true){
      if(state.tabDos === true){
        return <TabUnstyled className={`${styles.tab} ${activo === '2'? styles.tabCompletaSeleccionado : styles.tabCompletaSinSeleccion}`} onClick={(e)=> setActivo('2')}>
        <div>
          Certificados y llaves
        </div>
        <div className={styles.circuloCompletoSeleccionado}>
        <div className={styles.iconoCompleto}></div>
        </div>
        </TabUnstyled>
      }else{
        return <TabUnstyled className={`${styles.tab} ${activo === '2'? styles.tabSeleccionadoSinTerminar : styles.tabSinSeleccionSinTerminar}`} onClick={(e)=> setActivo('2')}>
        <div>
          Certificados y llaves
        </div>
        <div className={styles.circuloSinSeleccionSinTerminar}></div>
        </TabUnstyled>
      }
        
    }else{
      return <TabUnstyled className={`${styles.tab} ${activo === '2'? styles.tabSeleccionadoSinTerminar : styles.tabSinSeleccionSinTerminar}`} >
      <div>
        Certificados y llaves
      </div>
      <div className={styles.circuloSinSeleccionSinTerminar}></div>
      </TabUnstyled>
    }
    

  }

  return(
    <Dialog open={state.createEmpresa} aria-labelledby="form-dialog-title" maxWidth={"md"}
    >
      <div className={styles.contenedorTitulo}>
        <div className={styles.tituloCrearEmpresa}>
          Agregar nueva empresa
        </div>
        <div className={styles.contenedorCerrarModal}>
          <div className={styles.iconoCerrarModal} onClick={(()=>handleClose())}></div>      
        </div>
      </div>
      <div className={styles.contenedorTabs}>
        <TabsUnstyled defaultValue={0} >
          <TabsListUnstyled className={styles.tabs}>
            {(state.tabUno === true)?
              <TabUnstyled className={`${styles.tab} ${activo === '1'? styles.tabCompletaSeleccionado : styles.tabCompletaSinSeleccion}`} onClick={(e)=> setActivo('1')}>
                <div>
                  Identidad legal
                </div>
                <div className={styles.circuloCompletoSeleccionado}>
                  <div className={styles.iconoCompleto}></div>
                </div>
              </TabUnstyled>            
            :
              <TabUnstyled className={`${styles.tab} ${activo === '1'? styles.tabSeleccionadoSinTerminar : styles.tabSinSeleccionSinTerminar}`} onClick={(e)=> setActivo('1')}>
                <div>
                  Identidad legal
                </div>
                <div className={styles.circuloSinSeleccionSinTerminar}>
                </div>
              </TabUnstyled>    
            }
            {certificadoLlaves()}
           
            {(state.tabDos === true)?
              <TabUnstyled className={`${styles.tab} ${activo === '3'? styles.tabSeleccionadoSinTerminar : styles.tabSinSeleccionSinTerminar}`} onClick={(e)=> setActivo('3')}>
                <div>
                  Informacion bancaria
                </div>
                <div className={styles.circuloSinSeleccionSinTerminar}></div>
              </TabUnstyled>
              :
              <TabUnstyled className={`${styles.tab} ${activo === '3'? styles.tabSeleccionadoSinTerminar : styles.tabSinSeleccionSinTerminar}`}>
                <div>
                  Informacion bancaria
                </div>
                <div className={styles.circuloSinSeleccionSinTerminar}></div>
              </TabUnstyled>
            }
            
          </TabsListUnstyled>
          {(activo === '1')?
            <IdentidadLegal 
            setActivo={setActivo} 
            fileLogo={fileLogo} 
            setFileLogo={setFileLogo} 
            fileIMSS={fileIMSS} 
            setFileIMSS={setFileIMSS} 
            fileSello={fileSello} 
            setFileSello={setFileSello} 
            setFileKeyTimbre={setFileKeyTimbre}
            fileKeyTimbre={fileKeyTimbre}
            setFileCerTimbre={setFileCerTimbre}
            fileCerTimbre={fileCerTimbre}
            regimenFiscalState = {regimenFiscalState}
            setRegimenFiscalState = {setRegimenFiscalState}
            setEstadoState = {setEstadoState}
            estadoState = {estadoState}
            setMunicipioState= {setMunicipioState}
            municipioState= {municipioState}
            bankState= {bankState} 
            setBankState= {setBankState} 
            riskPlaced={riskPlaced}
            setRiskPlaced={setRiskPlaced}     
            outsourcers={outsourcers}
            setOutsourcers={setOutsourcers}
            />
          :
          null
          } 
          {(activo === '2')?
          <CertificadosLlaves 
          setActivo={setActivo} 
          fileLogo={fileLogo} 
          setFileLogo={setFileLogo} 
          fileIMSS={fileIMSS} 
          setFileIMSS={setFileIMSS} 
          fileSello={fileSello} 
          setFileSello={setFileSello} 
          setFileKeyTimbre={setFileKeyTimbre}
          fileKeyTimbre={fileKeyTimbre}
          setFileCerTimbre={setFileCerTimbre}
          fileCerTimbre={fileCerTimbre}
          regimenFiscalState = {regimenFiscalState}
          setRegimenFiscalState = {setRegimenFiscalState}
          setEstadoState = {setEstadoState}
          estadoState = {estadoState}
          setMunicipioState= {setMunicipioState}
          municipioState= {municipioState}
          bankState= {bankState} 
          setBankState= {setBankState} 
          riskPlaced={riskPlaced}
          setRiskPlaced={setRiskPlaced}
          outsourcers={outsourcers}
          setOutsourcers={setOutsourcers}
          />
          :
          null
          } 
          {(activo === '3')?
            <InformacionBancaria 
            setActivo={setActivo} 
            fileLogo={fileLogo} 
            setFileLogo={setFileLogo} 
            fileIMSS={fileIMSS} 
            setFileIMSS={setFileIMSS} 
            fileSello={fileSello} 
            setFileSello={setFileSello} 
            setFileKeyTimbre={setFileKeyTimbre}
            fileKeyTimbre={fileKeyTimbre}
            setFileCerTimbre={setFileCerTimbre}
            fileCerTimbre={fileCerTimbre}
            regimenFiscalState = {regimenFiscalState}
            setRegimenFiscalState = {setRegimenFiscalState}
            setEstadoState = {setEstadoState}
            estadoState = {estadoState}
            setMunicipioState= {setMunicipioState}
            municipioState= {municipioState}
            bankState= {bankState} 
            setBankState= {setBankState} 
            riskPlaced={riskPlaced}
            setRiskPlaced={setRiskPlaced}
            outsourcers={outsourcers}
            setOutsourcers={setOutsourcers}
            />
          :
          null
          } 
        </TabsUnstyled>
      </div>
    </Dialog>
  )
}

export default CrearEmpresa