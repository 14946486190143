import { Dispatch } from "react";
import {Types} from "./PayrollProcessContext"

export const createPrenominaModal = ({_id = "", createPrenomina = false}:any, dispatch: Dispatch<any>): void=>{
  dispatch({
    type: Types.CREATE_PRENOMINA,
    payload:{
      createPrenomina
    }
  })
}

export const clearPayrollProcess = ({_id = "", createPrenomina = false, createIncidencia = false, deleteModal = false, showDataCancelTimbre = false, showIncident = false }:any, dispatch: Dispatch<any>): void=>{
  dispatch({
    type: Types.CLEAR_PAYROLL,
    payload:{
      _id,
      createPrenomina,
      createIncidencia,
      deleteModal,
      showDataCancelTimbre,
      showIncident,
      alertaEnvioIDSEDos:false,
      alertaEnvioIDSEExterno:false
    }
  })
}

export const clearIncidentPeriodicProcess = ({_id = "", showDeleteIncidentPeriodic = false, showApproveIncidentPeriodic = false }:any, dispatch: Dispatch<any>): void=>{
  dispatch({
    type: Types.CLEAR_DELETE_INCIDENCIAS_PERIODICAS,
    payload:{
      _id,
      showDeleteIncidentPeriodic,
      showApproveIncidentPeriodic
    }
  })
}


export const createincidenciaModal = ({_id = "", createIncidencia = true}:any, dispatch: Dispatch<any>): void=>{

  dispatch({
    type: Types.CREATE_INCIDENCIA,
    payload:{
      _id,
      createIncidencia
    }
  })
}

export const deletePayrollProcess= ({_id = "", deleteModal = true}:any, dispatch: Dispatch<any>): void=>{

  dispatch({
    type: Types.DELETE_PAYROLL,
    payload:{
      _id,
      deleteModal
    }
  })
}

export const deleteIncidentPeriodicProcess= ({_id = "", showDeleteIncidentPeriodic = true}:any, dispatch: Dispatch<any>): void=>{
  dispatch({
    type: Types.OPEN_DELETE_INCIDENCIAS_PERIODICAS,
    payload:{
      _id,
      showDeleteIncidentPeriodic
    }
  })
}

export const approveIncidentPeriodicProcess= ({_id = "", showApproveIncidentPeriodic = true}:any, dispatch: Dispatch<any>): void=>{
  dispatch({
    type: Types.OPEN_APPROVE_INCIDENCIAS_PERIODICAS,
    payload:{
      _id,
      showApproveIncidentPeriodic
    }
  })
}

export const openfondosInsuficientes = ({fondosInfucientes = true}:any, dispatch: Dispatch<any>): void =>{

  dispatch({
    type: Types.FONDOS_INSUFICIENTES,
    payload:{
      fondosInfucientes
    }
  })
}

export const openSinNominasSeleccionadas = ({sinNominasSeleccionadas = true}:any, dispatch: Dispatch<any>): void =>{

  dispatch({
    type: Types.SIN_NOMINAS,
    payload:{
      sinNominasSeleccionadas
    }
  })
}
export const openaceptarDispersar = ({aceptarDispersar = true}:any, dispatch: Dispatch<any>): void =>{

  dispatch({
    type: Types.ACEPTAR_DISPERSAR,
    payload:{
      aceptarDispersar
    }
  })
}

export const openaceptarDispersarEventual = ({aceptarDispersarEventual = true}:any, dispatch: Dispatch<any>): void =>{

  dispatch({
    type: Types.ACEPTAR_DISPERSAR_EVENTUAL,
    payload:{
      aceptarDispersarEventual
    }
  })
}

export const openaceptarDispersarSettlement = ({aceptarDispersarSettlement = true}:any, dispatch: Dispatch<any>): void =>{

  dispatch({
    type: Types.ACEPTAR_DISPERSAR_SETTLEMENT,
    payload:{
      aceptarDispersarSettlement
    }
  })
}

export const openAceptarDispersarIndividual = ({aceptarDispersarIndividual = true, id=''}:any, dispatch: Dispatch<any>): void =>{

  dispatch({
    type: Types.OPEN_DISPERSAR_INDIVIDUAL,
    payload:{
      aceptarDispersarIndividual,
      id
    }
  })
}

export const openAceptarDispersarIndividualEventual = ({aceptarDispersarIndividualEventual = true, id=''}:any, dispatch: Dispatch<any>): void =>{

  dispatch({
    type: Types.OPEN_DISPERSAR_INDIVIDUAL_EVENTUAL,
    payload:{
      aceptarDispersarIndividualEventual,
      id
    }
  })
}

export const openalertaReporte = ({alertaReporte = true}:any, dispatch: Dispatch<any>): void =>{
  dispatch({
    type: Types.ALERTA_REPORTE,
    payload:{
      alertaReporte
    }
  })
}

export const openalertaEnviarIDSE = ({alertaEnviarIDSE = true}:any, dispatch: Dispatch<any>): void =>{
  dispatch({
    type: Types.ALERTA_ENVIARIDSE,
    payload:{
      alertaEnviarIDSE
    }
  })
}

export const opendispersarFiniquito = ({aceptarDispersarFiniquito = true}:any, dispatch: Dispatch<any>): void =>{
  dispatch({
    type: Types.OPEN_DISPERSAR_FINIQUITO,
    payload:{
      aceptarDispersarFiniquito
    }
  })
}

export const openAlertaEnvioIDSEDos = ({alertaEnvioIDSEDos = true, idMovimiento=''}:any, dispatch: Dispatch<any>): void =>{
  dispatch({
    type: Types.OPEN_ENVIOIDSE_DOS,
    payload:{
      alertaEnvioIDSEDos,
      idMovimiento
    }
  })
}

export const openDescartarIDSE = ({descartarIDSE = true}:any, dispatch: Dispatch<any>): void =>{
  dispatch({
    type: Types.ALERTA_DESCARTARIDSE,
    payload:{
      descartarIDSE
    }
  })
}

export const openMovimientoIDSEEXTERNO = ({ alertaEnvioIDSEExterno = true, idMovimiento=''}:any, dispatch: Dispatch<any>): void =>{
  dispatch({
    type: Types.OPEN_MOVIMIENTO_EXTERNO,
    payload:{
      alertaEnvioIDSEExterno,
      idMovimiento
    }
  })
}

export const openMensajeNoProcesado = ({alertaNoProcesado = true, Comentario= ''}:any, dispatch:Dispatch<any>): void =>{
  dispatch({
    type: Types.OPEN_NO_PROCESADO,
    payload:{
      alertaNoProcesado,
      Comentario
    }
  })
}
export const openOnboarding = ({isOnboarding = true}:any, dispatch: Dispatch<any>): void =>{
  
  dispatch({
    type: Types.IS_ONBOARDING,
    payload:{
      isOnboarding
    }
  })
}
export const closeOnboarding = ({isOnboarding = false}:any, dispatch: Dispatch<any>): void =>{
  
  dispatch({
    type: Types.CLOSE_ONBOARDING,
    payload:{
      isOnboarding
    }
  })
}

export const openCancelTimbre= ({_id = "", typeCancelTimbre = "", showDataCancelTimbre = true}:any, dispatch: Dispatch<any>): void=>{
  
  dispatch({
    type: Types.OPEN_PAYROLL_DATA_CANCEL_TIMBRE,
    payload:{
      _id,
      typeCancelTimbre
    }
  })
}

export const openShowIncident= ({idPayroll = "", idCollaborator = "", showIncident = true, varDelete = true}:any, dispatch: Dispatch<any>): void=>{
  
  dispatch({
    type: Types.OPEN_SHOW_INCIDENT,
    payload:{
      idPayroll,
      idCollaborator,
      varDelete
    }
  })
}

export const openShowIncidentSettlement= ({idPayroll = "", idCollaborator = "", varDeleteIncuidentSettlement = true}:any, dispatch: Dispatch<any>): void=>{
  
  dispatch({
    type: Types.OPEN_SHOW_INCIDENT_SETTLEMENT,
    payload:{
      idPayroll,
      idCollaborator,
      varDeleteIncuidentSettlement
    }
  })
}

export const openDeleteIncident= ({idDeleteIncident = "", showDeleteIncident = true}:any, dispatch: Dispatch<any>): void=>{
  
  dispatch({
    type: Types.OPEN_DELETE_INCIDENT,
    payload:{
      idDeleteIncident
    }
  })
}

export const openDeleteIncidentSettlement= ({idDeleteIncidentSettlement = "", showIncidentSettlement = true}:any, dispatch: Dispatch<any>): void=>{
  
  dispatch({
    type: Types.OPEN_DELETE_INCIDENT_SETTLEMENT,
    payload:{
      idDeleteIncidentSettlement
    }
  })
}

export const closeDeleteIncident= ({idDeleteIncident = "", showDeleteIncident = false}:any, dispatch: Dispatch<any>): void=>{
  
  dispatch({
    type: Types.CLEAR_DELETE_INCIDENT,
    payload:{
      idDeleteIncident
    }
  })
}

export const closeDeleteIncidentSettlement= ({idDeleteIncidentSettlement = ""}:any, dispatch: Dispatch<any>): void=>{
  
  dispatch({
    type: Types.CLEAR_DELETE_INCIDENT_SETTLEMENT,
    payload:{
      idDeleteIncidentSettlement
    }
  })
}

export const openFinish= ({idPayroll = "", showFinish = true}:any, dispatch: Dispatch<any>): void=>{
  
  dispatch({
    type: Types.OPEN_FINISH,
    payload:{
      idPayroll
    }
  })
}

export const openAceptarDispersarFacturacionPagos = ({aceptarDispersarFacturacionPagos = true, id=''}:any, dispatch: Dispatch<any>): void =>{

  dispatch({
    type: Types.ACEPTAR_DISPERSAR_FACTURACION_PAGOS,
    payload:{
      aceptarDispersarFacturacionPagos,
      id
    }
  })
}

export const openModalOutsorcersResumenActivo = ({openModalOutsourcersResumen = true}:any, dispatch: Dispatch<any>): void =>{
  dispatch({
    type: Types.OPEN_OUTSOURCERS_MODAL_RESUMEN,
    payload:{
      openModalOutsourcersResumen
    }
  })
}

export const openModalDispersionEnProceso = ({openDispersionEnProceso = true}:any, dispatch: Dispatch<any>): void =>{
  dispatch({
    type: Types.OPEN_DISPERSIONENPROCESO,
    payload:{
      openDispersionEnProceso
    }
  })
}

export const openModalAlertaNoGravable =({openAlertaNoGravable = true}:any, dispatch:Dispatch<any>): void =>{
  dispatch({
    type: Types.OPEN_ALERTANOGRAVABLE,
    payload:{
      openAlertaNoGravable
    }
  })
}