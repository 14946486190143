export const formatter = (numero:number) => {
  let formato = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 2
  })
  let resultado = formato.format(numero)
  return resultado
} 

export const formatterMxn = (numero: any) => {
  if (!numero || numero === "NaN") return "nan";
  return new Intl.NumberFormat("es-MX", {
    style: "currency",
    currency: "MXN",
    maximumFractionDigits: 2
  }).format(numero);
};

export const cleanFormatter = (valor:string) =>{
  let valorLimpio = valor.replace(/[^0-9]+/g,"")
  return valorLimpio
}

    

