import {Dialog} from "@material-ui/core"
import PayrollProcessContext from "../../../context/PayrollProcess/PayrollProcessContext";
import {useState, useContext} from "react";
import { clearPayrollProcess } from "../../../context/PayrollProcess/Actions";
import styles from '../../Payroll/Modals/PayrollModals.module.css'
import { useMutation } from "@apollo/client";
import { MOVIMIENTO_EXTERNO,GET_MOVIMIENTOSIDSE } from "../../../Querys/querys";
import { SuccessfulAlert } from "../../../alerts/successAlerts";

const EnvioMovimientoDescartado = () =>{
  const {state, dispatch} = useContext(PayrollProcessContext)
  const [activo, setactivo] = useState(false)
  const [envioMovimiento] = useMutation(MOVIMIENTO_EXTERNO,{
    refetchQueries:[{query:GET_MOVIMIENTOSIDSE}]
  })

  const handleClose = () =>{
    clearPayrollProcess({}, dispatch)
  
  }

  const handleChange = (e: any) =>{ 
    if(e.target.value === 'CONFIRMAR'){
        setactivo(true)
    }else{
        setactivo(false)
    }
  }

  const enviarDescartado = async() =>{
    envioMovimiento({
      variables:{
        movimientoExternoId:state._id
      }
    })
    handleClose()
    return SuccessfulAlert({
      text: "Solcitudes enviadas, por favor espere su respuesta.",
    });
}

  return(
    <Dialog aria-labelledby='costumized-dialog-title' open={state.alertaEnvioIDSEExterno} fullWidth={false}  onClose={handleClose} maxWidth={"md"}>
      <div className={styles.contenedorPrincipal}>
        <div className={styles.contenedorCerrarModal}>
          <div 
            className={styles.cerrarModal}
            onClick={()=> handleClose()}
          >
          </div>
        </div>
        <div className={styles.contenedorPrincipal}>
          <div className={styles.fim_iconoPrincipal}> 
            <div className={styles.fim_iconoComplemento}>
            </div>
          </div>
        </div>
        <div className={styles.tituloAtencion}>
          ¡Atenci&oacute;n!
        </div>
        <div className={styles.em_mensajePrtincipal}>
          Est&aacute;s por descartar el registro
          </div>
          <div className={styles.em_mensajePrtincipal}>
            Escribe CONFIRMAR para continuar
          </div>
          <div>
            <input 
              className={styles.input}
              type="text"
              onChange={(e) => handleChange(e)}
            />
          </div>
        <div className={styles.em_contenedorBotones}>
          <button 
                className={styles.em_botonCancelar}
                onClick={()=> handleClose()}
              >
                Cancelar
              </button>
              {(activo === true ? 
                <button className={`${styles.botonesAtencion} ${styles.continuarAtencion}`}
                onClick={()=> enviarDescartado()}
                >
                    <div>
                      Confirmar
                    </div>
                </button>
              :
                <button 
                  className={`${styles.botonesAtencion} ${styles.continuarAtencionInactivo}`}
                  >
                    <div>
                      Confirmar
                    </div>
                </button>
              )}
        </div>
      </div>
    </Dialog>
  )
}
export default EnvioMovimientoDescartado 