import { firebase, googleAuthProvider } from '../../firebase/firebase-config';
import { AttentionAlert2 } from "../../alerts/attentionAlert2";

const GoogleButton = () => {

    const createModal = (Message: string) => {
        AttentionAlert2({text:Message})
        

    }


    const singIn = async () => {
        await firebase.auth().signInWithPopup(googleAuthProvider)
        .then((result) => {

        })
        .catch((error) => {

            if(error.code === 'auth/wrong-password'){
                createModal('Contraseña inválida.')
            }

            if(error.code === 'auth/too-many-requests'){
                createModal('Acceso inhabilitado temporalmente debido a intentos fallidos de inicio de sesión. Puede restableciendo la contraseña o intentar más tarde.')
            }

            if(error.code === 'auth/invalid-email'){
                createModal('Dirección de correo electrónico no existe.')
            }

            if(error.code === 'auth/user-not-found'){
                createModal('Usuario incorrecto.')
            }

            if(error.code === 'auth/account-exists-with-different-credential'){
                createModal('Existe cuenta con la dirección de correo electrónico con diferentes credenciales de inicio de sesión. Inicie sesión con un proveedor de dirección de correo electrónico diferente.')
            }
            
        });
    };
    return (
        <>
        <button id="GoogleButton" onClick={singIn}><b>INICIAR SESIÓN CON GOOGLE</b></button>
        </>
        
    );
};

export default GoogleButton;