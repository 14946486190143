import {
  Dialog,
} from '@material-ui/core';
import '../Modal/CrearPayrollGroup.css';
import { useEffect, useContext, useState, useRef } from 'react';
import { SuccessfulAlert } from '../../../alerts/successAlerts';
import PayrollGroupContext from "../../../context/ConfigPayrollContext/PayrollGroupContext";
import SaveIcon from '@material-ui/icons/Save';
import { closePayrollGroupModal } from '../../Team/Modals/ModalPayrollGroupModal';
import { useMutation, useQuery, useLazyQuery } from "@apollo/client";
import { GET_ALL_PAYROLL_GROUP, UPDATE_PAYROLL_GROUP, GET_ENTERPRISE_SELECT, GET_PAYROLL_GROUP } from "../../../Querys/querys";
import { Formik, Form} from "formik";
import * as Yup from "yup";
import styles from '../../../styles/grupoNomina/CrearGrupoNomina.module.css'


const EditaPayrollGroups = (props: any) => {
  const [getEnterprise, {data}] = useLazyQuery(GET_ENTERPRISE_SELECT,{})
  const [company, setCompany] = useState([]);
  const [outsourcers, setOutsourcers] = useState([]);
  const {state, dispatch} = useContext(PayrollGroupContext)
  const [updatePayroll] = useMutation(UPDATE_PAYROLL_GROUP, {
      refetchQueries: [{ query: GET_ALL_PAYROLL_GROUP },{query:GET_PAYROLL_GROUP, variables:{getPayrollGroupId: state._id}}],
  });

  const [getGroup, { loading, data: dataGroup }] = useLazyQuery(
    GET_PAYROLL_GROUP,
    {
      variables: {
        getPayrollGroupId: state._id
      },
      fetchPolicy:'no-cache'
    }
  );

  useEffect(()=>{
    getGroup()
  },[state._id, getGroup])

  useEffect(()=>{
    if(state.showEdit){
      getEnterprise();
    }
  },[state.showEdit]);

  useEffect(()=>{
    if(data && data.GET_ENTERPRISE_SELECT){
      setCompany(data.GET_ENTERPRISE_SELECT);
      setOutsourcers(data.GET_ENTERPRISE_SELECT.filter((enterprise:any)=>{
        return enterprise.Outsourcer;
      }));
    }
  },[data]);

  const formikRef:any = useRef();

  useEffect(()=>{
    if(formikRef.current){
      formikRef.current.setFieldValue('GroupName', dataGroup?.GET_PAYROLL_GROUP?.group_name);
      formikRef.current.setFieldValue('CompanyName', dataGroup?.GET_PAYROLL_GROUP?.company_name);
      formikRef.current.setFieldValue('PayrollPeriod', dataGroup?.GET_PAYROLL_GROUP?.payroll_period);
      formikRef.current.setFieldValue('outsourcer', dataGroup?.GET_PAYROLL_GROUP?.idOutsourcer);
    }
  }, [dataGroup])

  const initialValues = () =>{
    return{
      GroupName: "",
      CompanyName: "",
      PayrollPeriod: "",
      outsourcer: ""
    }
  }

  const handleClose = async () => {
      await closePayrollGroupModal(dispatch);
      dispatch({ type: "CLOSE_PAYROLLGROUP_MODAL" });
  }

  const validationSchema = Yup.object().shape({
      GroupName: Yup.string().required("El nombre es requerido").max(100,'Maximo 100 caracteres'),
      CompanyName: Yup.string().required("Por favor seleccione una opción"),
      PayrollPeriod: Yup.string().required("Por favor seleccione una opción")
  })


  return(
    <div>
      <Formik
        initialValues={initialValues()}
        innerRef={formikRef}
        validationSchema={validationSchema}
        onSubmit={(formData, { resetForm }) =>{
          const iDoc = Number(state._id)
          updatePayroll({
            variables:{
              updatePayrollGroupId:iDoc,
              input:{
                group_name: formData.GroupName,
                company_name: formData.CompanyName,
                payroll_period: formData.PayrollPeriod,
                idOutsourcer: formData.outsourcer && formData.outsourcer !== '' ? Number(formData.outsourcer) : null
              }
            }
          }).then(()=>{
            resetForm();
            SuccessfulAlert({text: "Registro creado con éxito"})
            handleClose();
          });
        }}
      >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleSubmit,
        handleBlur
      }) => (
      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={state.showEdit} fullWidth={false} maxWidth={"md"}>
      <Form onSubmit = {handleSubmit}>
        <div className={styles.titulo}>
          <p>Editar grupo de nómina</p>
        </div>

          <div className={styles.fila}>
            <div className={styles.tituloFila}>
              <label htmlFor="GroupName">Nombre de grupo de nómina</label>
            </div>
            <div>
              <input
                name="GroupName"
                className={styles.input}
                value={values.GroupName}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <div className={styles.contenedorError}>
                <span>{touched.GroupName && errors.GroupName}</span>
              </div>
            </div>
          </div>
          <div className={styles.fila}>
            <div className={styles.tituloFila}>
              <span>Razón social</span>
            </div>
            <div>
              <select
                name="CompanyName"
                value={values.CompanyName || ''}
                onChange={handleChange}
                onBlur={handleBlur}
                className={styles.select}>
                  <option value="" className="optionSelect" disabled>Selecciona una empresa</option>
                    {
                     company?.map((company:any) => <option className="optionSelect" key={company?.id} value={company?.name}>{company?.name}</option>)
                    }
                </select>
               <div className={styles.contenedorErrorSelect}>                    
                  <span>{touched.CompanyName && errors.CompanyName}</span>
              </div>
            </div>
          </div>
          <div className={styles.fila}>
            <div className={styles.tituloFila}>
              <span>Outsourcer</span>
            </div>
            <div>
              <select
                name="outsourcer"
                value={String(values.outsourcer) || ''}
                onChange={handleChange}
                onBlur={handleBlur}
                className={styles.select}>
                  <option value="" className="optionSelect">Selecciona una empresa</option>
                    {
                     outsourcers?.map((company:any) => <option className="optionSelect" key={company?.id} value={company?.id}>{company?.name}</option>)
                    }
                </select>
               <div className={styles.contenedorErrorSelect}>                    
                  <span>{touched.outsourcer && errors.outsourcer}</span>
              </div>
            </div>
          </div>
          <div className={styles.fila}>
            <div className={styles.tituloFila}>
              <span>Periodo de nómina</span>
            </div>
            <div>
              <select
                name="PayrollPeriod"
                value={values.PayrollPeriod}
                onChange={handleChange}
                onBlur={handleBlur}
                className={styles.select}>
                  <option value=""  className="optionSelect" disabled>Selecciona el periodo</option>
                  <option value="Semanal">Semanal</option>
                  <option value="Catorcenal">Catorcenal</option>
                  <option value="Quincenal">Quincenal</option>
                  <option value="Mensual">Mensual</option>
                </select>
                <div className={styles.contenedorErrorSelect}>
                  <span>{touched.PayrollPeriod && errors.PayrollPeriod}</span>
                </div>
            </div>
          </div>         
        <div >
          <div className={styles.contenedorBotones}>
              <button autoFocus onClick={handleClose} className={styles.botonCancelar} type="button">
                  Cancelar
              </button>
              <button className={styles.botonGuardar} type="submit">
              <div className="contenedorIconoBotonModal">
                <SaveIcon fontSize="small"/>
              </div>
              <div>
                Guardar
              </div>
              </button>
          </div>
          </div>
          </Form>
      </Dialog>
            )}
      </Formik>
    </div>
  )
}

export default EditaPayrollGroups

