import axios from "axios";
import { ErrorAlert } from "../alerts/errorAlert";
const serverError = "Ocurrió un error con el servidor.";

export const postFileProcessConfronta = async (e: any, name: string, Empresa: string, Bimestre: string) => {
  try {
    // LoadingAlert({ title: "Subiendo Archivo..." });
    let file = new FormData();
    file.append("document", e.target.files[0]);
    file.append("type", "confronta");
    file.append("idEmpresa", Empresa);
    file.append("Bimestre", Bimestre);

    if (name === "IDSE") {
      const result = await axios.post(`/Confronta/processIDSE`, file, {
        headers: {
          "Content-Type": `multipart/form-data;boundary=${file}`,
        },
      });

      return result.data;
    } else if (name === "SUA") {
      const result = await axios.post(`/Confronta/processSUA`, file, {
        headers: {
          "Content-Type": `multipart/form-data;boundary=${file}`,
        },
      });
      return result.data;
    }
    // const result = await axios.post(
    //     `/candidates/postFileHelper/file/${"desde"}/${1}/${e.target.name}`,
    //     file,
    //     {
    //       headers: {
    //         "Content-Type": `multipart/form-data;boundary=${file}`,
    //       },
    //     }
    //   );
  } catch (err) {
    console.log(err);
    return ErrorAlert({ text: serverError });
  }
};
