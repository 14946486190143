import { Grid, Box, FormControl, Select, MenuItem, InputLabel, Accordion, AccordionSummary, AccordionDetails, Alert } from "@mui/material";
import React, { useState, useEffect } from 'react';
import CollaboratorTimelineRow from './CollaboratorTimelineRow';
import { Event, Timeline } from "../../../interfaces/TabCollaborator.interfaces";
import SortAscIcon from '../../../assets/svg/icono-ordenar-asc.svg';
import SortDescIcon from '../../../assets/svg/icono-ordenar-desc.svg';
import { GET_TIMELINE_EVENTS } from "../../../Querys/querys";
import { useQuery } from "@apollo/client";

function CollaboratorTimeline({id}:{id:string}){

  const [year, setYear] = useState( new Date().getFullYear() );
  const [orderDate, setOrderDate] = useState('DESC');
  const [workYears, setWorkYears] = useState<string[]>([]);
  const [expanded, setExpanded] = useState<number | false>(new Date().getFullYear());
  const [selectedEvents, setSelectedEvents] = useState<any>([]);
  const { data: resultEvents } = useQuery(GET_TIMELINE_EVENTS, {
    variables: { id: id },
    fetchPolicy: 'no-cache'
  });
  const [events, setEvents] = useState<Timeline[]>([]);

  useEffect(()=>{
    setWorkYears( events.map((event:Timeline)=>{ return String(event.year) }) );
  }, [events]);

  useEffect(()=>{
    if(resultEvents && resultEvents.GET_TIMELINE_EVENTS){
      setEvents( resultEvents.GET_TIMELINE_EVENTS );
      setSelectedEvents(resultEvents.GET_TIMELINE_EVENTS);
    }
  }, [resultEvents])

  const onChangeYear = (e:any) => {
    setYear(e.target.value);
    setExpanded( Number(e.target.value) );
  }

  const onChangeOrder = () => {
    const newOrder = orderDate === 'DESC' ? 'ASC' : 'DESC' ;
    setOrderDate( newOrder );
    let newOrderEvents:Timeline[] = [];
    if( newOrder === 'DESC' ){
      newOrderEvents = events;
    }else{
      for(let i = selectedEvents.length-1; i>=0; i--){
        const newSubEvents = [];
        for(let j = selectedEvents[i].events.length-1; j>=0; j--){
          newSubEvents.push(selectedEvents[i].events[j]);
        }
        newOrderEvents.push({
          year: events[i].year,
          events: newSubEvents
        });
      }
    }
    setSelectedEvents(newOrderEvents);
  }

  const handleChange = (panel: number) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <Box id="timeline">
      <Grid container>
        <Grid item xs={4} md={6}></Grid>
        <Grid item xs={4} md={3}>
          <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
            <InputLabel id="selectlabel">Año</InputLabel>
            <Select
              labelId="selectlabel"
              id="selectid"
              value={year}
              onChange={onChangeYear}
              label="Año"
              className="select-field"
            >
              <MenuItem value="">
              </MenuItem>
              {
                workYears.map((year:string,key:number)=>(
                  <MenuItem value={year} key={key}>{year}</MenuItem>
                ))
              }
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={4} md={3}>
            <p onClick={onChangeOrder} className="order-field">Fecha { orderDate === 'DESC' ? (<span><img alt="desc" src={SortDescIcon}></img></span>) : (<span><img alt="asc" src={SortAscIcon}></img></span>) }</p>
        </Grid>
      </Grid>
      {
        selectedEvents.map((timelineEvent:Timeline, key:number)=>(
          <Accordion expanded={expanded === timelineEvent.year} onChange={handleChange(timelineEvent.year)} key={timelineEvent.year}>
            <AccordionSummary id={`panel-${timelineEvent.year}`}>
              <h4>{ timelineEvent.year }</h4>
            </AccordionSummary>
            <AccordionDetails>
              {
                timelineEvent.events.map((event:Event, key:number)=>(
                  <CollaboratorTimelineRow event={event} key={key} color={ key % 2 === 0 ? 'blue' : 'dark-blue' }></CollaboratorTimelineRow>
                ))
              }
              {
                timelineEvent.events.length === 0 && (
                  <Alert severity="info">No hay movimientos registrados para este año</Alert>
                )
              }
            </AccordionDetails>
          </Accordion>
        ))
      }
    </Box>
  );
}

export default CollaboratorTimeline;