import { useState, useContext } from "react";
import { Dialog, } from "@material-ui/core";
import '../Politic.css'
import PoliticContext from "../../../context/ConfigPayrollContext/PoliticContext";
import { closePoliticModal } from "../../Team/Modals/ModalPoliticModal";
import { useMutation } from "@apollo/client";
import styles from "../../Payroll/Modals/PayrollModals.module.css";
import {  GET_ALL_POLITIC,DELETE_POLITIC } from "../../../Querys/querys";

const EliminaPolitic = (props: any) =>{

    const [deletePolitic] = useMutation(DELETE_POLITIC, {
        refetchQueries: [{ query: GET_ALL_POLITIC }],
      });

    const {state, dispatch} = useContext(PoliticContext)
    const [activo, setactivo] = useState(false)
    const handleChange = (e: any) =>{ 
        if(e.target.value === 'ELIMINAR'){
            setactivo(true)
        }else{
            setactivo(false)
        }
    }

    const handleDelete = async () =>{
        try{

            deletePolitic({
                variables: {
                    deletePoliticId: state._id,
                },
              });
                await closePoliticModal(dispatch);
                setactivo(false)
                await props.getDatos();
        }
        catch{

        }
    }

    const handleClose =  async () => {
        state.showEliminar = false;
        await closePoliticModal(dispatch);
    }



    return(
        <Dialog
      aria-labelledby="costumized-dialog-title"
      open={state.showEliminar}
      fullWidth={false}
      onClose={handleClose}
      maxWidth={"md"}
    >
      <div className={styles.em_container}>
        <div className={styles.contenedorCerrarModal}>
          <div
            className={styles.cerrarModal}
            onClick={() => handleClose()}
          ></div>
        </div>
        <div className={styles.contenedorPrincipal}>
          <div className={styles.iconoEliminar}></div>
          <div className={styles.em_titulo}>
            ¿Est&aacute;s seguro de que quieres eliminar la política?
          </div>
          <div className={styles.em_mensajePrtincipal}>
            <span>
              Una vez eliminado no podr&aacute;s recuperar la informaci&oacute;n
            </span>
            <br />
            <span className={styles.em_textoPrincipal}>
              Escribe ELIMINAR para confirmar
            </span>
          </div>
          <div>
            <input
              className={styles.input}
              type="text"
              onChange={(e) => handleChange(e)}
            />
          </div>
        </div>
      </div>
      <div className={styles.em_contenedorBotones}>
        <button
          className={styles.em_botonCancelar}
          onClick={() => handleClose()}
        >
          Cancelar
        </button>
        {activo === true ? (
          <button
            className={styles.em_botonConfirmarActivo}
            onClick={() => handleDelete()}
          >
            <div className={styles.em_iconoConfirmarEliminar}></div>
            <div>Confirmar</div>
          </button>
        ) : (
          <button className={styles.em_botonConfirmarInactivo}>
            <div className={styles.em_iconoConfirmarEliminar}></div>
            <div>Confirmar</div>
          </button>
        )}
      </div>
    </Dialog>
    )
}

export default EliminaPolitic