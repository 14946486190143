import {useReducer} from 'react'
import OutsourcersContext, {initialState}  from './OutsourcersContext'
import  OutsourcersReducer  from './OutsourcersReducer'

const OutsourcersState = (props:any) => {
  const [state, dispatch] = useReducer(OutsourcersReducer, initialState)
  return(
    <OutsourcersContext.Provider value={{state, dispatch}}>
      {props.children}
    </OutsourcersContext.Provider>
  )
}

export default OutsourcersState