import { useContext} from "react"
import PayrollProcessContext from "../../../../context/PayrollProcess/PayrollProcessContext";
import {Dialog} from "@material-ui/core"
import {
    clearPayrollProcess
} from "../../../../context/PayrollProcess/Actions";
import styles from './ShowTerminar.module.css'
import { 
  DALETE_PAYROLL, 
  GET_ALL_PAYROLL, 
  GET_PAYROLLCOLLABORATOR, 
  GET_INCIDENT_PAYROLL, 
  GET_PAYROLLYEAR,
  FINISH_SETTLEMENTPAYROLL,
  FINISH_EVENTUALPAYROLL,
  GET_PAYROLLCOLLABORATOR_TIMBRADO,
  GET_ALL_PAYROLL_ACTIVE,
  GET_PAYROLLHISTORIC,
  GET_ALL_PAYROLLCOLLABORATOR_EXCELL,
  GET_REPORTEPOLIZA_CONTABLE,
  GET_DISPERSIONACEPTADA_ZIP
} from "../../../../Querys/querys";
import { useMutation } from "@apollo/client"
import { SuccessfulAlert } from "../../../../alerts/successAlerts"
import { AdminContext } from "../../../../context/AdminContext/AdminContext";
import { useHistory, useParams } from "react-router-dom";
const ShowTerminar = (props: any) =>{
    const history = useHistory();
    const { tab } = useParams<any>();
    const {state, dispatch} = useContext(PayrollProcessContext)

    const {adminState} = useContext(AdminContext)

    const [updatePayroll] = useMutation(DALETE_PAYROLL,{
    refetchQueries:[{ query: GET_ALL_PAYROLL},
                    {
                        query: GET_PAYROLLCOLLABORATOR,
                        variables: { getPayrollcollaboratorId: state.idPayroll },
                    },
                    { 
                      query: GET_INCIDENT_PAYROLL
                      , variables: {idPayroll: state.idPayroll }
                    },
                    { query: GET_PAYROLLYEAR},
                    { query: GET_PAYROLLCOLLABORATOR_TIMBRADO, variables: {getPayrollcollaboratorTimbradoId: state.idPayroll}},
                    { query: GET_ALL_PAYROLL_ACTIVE },
                    { query: GET_PAYROLLHISTORIC, variables: {init_date: props.year} },
                    { query: GET_ALL_PAYROLLCOLLABORATOR_EXCELL, variables: {getAllPayrollcollaboratorExcellId: 1} },
                    { query: GET_REPORTEPOLIZA_CONTABLE, variables: {getReportepolizaContableId: 11} },
                    { query: GET_DISPERSIONACEPTADA_ZIP, variables: {id_payroll: parseInt(state.idPayroll)} },
                ]
    })

    const [updatePayrollSettlement] = useMutation(FINISH_SETTLEMENTPAYROLL,{
      refetchQueries:[{ query: GET_ALL_PAYROLL},
                      {
                          query: GET_PAYROLLCOLLABORATOR,
                          variables: { getPayrollcollaboratorId: state.idPayroll },
                      },
                      { 
                        query: GET_INCIDENT_PAYROLL
                        , variables: {idPayroll: state.idPayroll }
                      },
                      { query: GET_PAYROLLYEAR},
                      { query: GET_PAYROLLHISTORIC, variables: {init_date: props.year} },
                      { query: GET_ALL_PAYROLLCOLLABORATOR_EXCELL, variables: {getAllPayrollcollaboratorExcellId: 1} },
                      { query: GET_REPORTEPOLIZA_CONTABLE, variables: {getReportepolizaContableId: 11} },
                      { query: GET_DISPERSIONACEPTADA_ZIP, variables: {id_payroll: parseInt(state.idPayroll)} },
                  ]
    })
    const [updatePayrollEventual] = useMutation(FINISH_EVENTUALPAYROLL,{
      refetchQueries:[{ query: GET_ALL_PAYROLL},
                      {
                          query: GET_PAYROLLCOLLABORATOR,
                          variables: { getPayrollcollaboratorId: state.idPayroll },
                      },
                      { 
                        query: GET_INCIDENT_PAYROLL
                        , variables: {idPayroll: state.idPayroll }
                      },
                      { query: GET_PAYROLLYEAR},
                      { query: GET_PAYROLLHISTORIC, variables: {init_date: props.year} },
                      { query: GET_ALL_PAYROLLCOLLABORATOR_EXCELL, variables: {getAllPayrollcollaboratorExcellId: 1} },
                      { query: GET_REPORTEPOLIZA_CONTABLE, variables: {getReportepolizaContableId: 11} },
                      { query: GET_DISPERSIONACEPTADA_ZIP, variables: {id_payroll: parseInt(state.idPayroll)} },
                  ]
      })
  
    const handleClose = () => {
        clearPayrollProcess({}, dispatch)
    } 

    const handleDelete = () =>{

      if (props.type === 'PAYROLL') {

        updatePayroll({
            variables: {
                input: {
                    id: state.idPayroll,
                    userFinish: adminState?.Usuario
                }
            }
        }).then(()=>{
            SuccessfulAlert({text:'Registro Actualizado'})
            handleClose() 
            history.push(`/PayrollTerminar/${state.idPayroll}/${tab}`)
        })
      }

      if (props.type === 'SETTLEMENT') {
        updatePayrollSettlement({
            variables: {
                input: {
                    id: state.idPayroll,
                    userFinish: adminState?.Usuario
                }
            }
        }).then(()=>{
            SuccessfulAlert({text:'Registro Actualizado'})
            handleClose() 
            history.push(`/PayrollTerminarFiniquito/${state.idPayroll}/${tab}`)
        })
      }

      if (props.type === 'EVENTUAL') {
        updatePayrollEventual({
            variables: {
                input: {
                    id: state.idPayroll,
                    userFinish: adminState?.Usuario
                }
            }
        }).then(()=>{
            SuccessfulAlert({text:'Registro Actualizado'})
            handleClose() 
            history.push(`/PayrollEventualTerminar/${state.idPayroll}/${tab}`)
        })
      }

       
    }

  return(
    <Dialog open={state.showFinish} aria-labelledby="form-dialog-title" onClose={handleClose} maxWidth={"md"}>
        <div className={styles.em_container}>
          <div className={styles.contenedorCerrarModal}>
            <div 
              className={styles.cerrarModal}
              onClick={()=> handleClose()}>
            </div>
          </div>
          <div className={styles.contenedorPrincipal}>
            <div className={styles.iconoEliminar}>
            </div>
            <div className={styles.em_titulo}>
              ¿Est&aacute;s seguro de que quieres terminar la nomina?
            </div>
            <div className={styles.em_mensajePrtincipal}>
                <span>Una vez terminado no podr&aacute;s editar la informaci&oacute;n</span>
                <br />
            </div>
          </div>
        </div>
        <div className={styles.em_contenedorBotones}>
            <button 
            className={styles.em_botonCancelar}
            onClick={()=> handleClose()}
            >
            Cancelar
            </button>

            <button 
            className={styles.em_botonConfirmarActivo}
            onClick={() => handleDelete()}
            >
                
                
                Confirmar
                
            </button>

        </div>
    </Dialog>
  )
}

export default ShowTerminar