import {Types} from './PrestacionContext'

const ModalReducer = (state:any, action:any) => {
    const {payload,type} = action;
    
    switch(type){
        case Types.CREATE_BENEFITS:
            return {
                ...state,
                createModalBenefits:true
            }
        case Types.UPDATE_BENEFITS:
            return {
                ...state,
                _id:payload._id,
                updateModalBenefits:true
            }
      
        case Types.DELETE_BENEFITS:
            return{
                ...state,
                _id:payload._id,
                deleteModalBenefits:true
            }
        case Types.CREATE_USER_BENEFITS:
                return {
                    ...state,
                    createModalUserBenefits:true
                }   
        case Types.CLOSE_USER_BENEFITS_MODAL:
                 return{
                     ...state,
                     createModalUserBenefits:false
                 }         
        case Types.CLEAR_BENEFITS:
            return {
                ...state,
                _id:'',
                createModalBenefits:payload.createModalBenefits,
                updateModalBenefits:payload.updateModalBenefits,
                deleteModalBenefits:payload.deleteModalBenefits               
            }    
        default:
        return state;
    }
}

export default ModalReducer;