import {Types} from './CampaignContext'

const ModalReducer = (state:any, action:any) => {
    const {payload,type} = action;
    
     switch(type){
        case Types.CREATE_CAMPAIGNS:
            return {
                ...state,
                _id:payload._id,
                createModalCampaigns:true
            }
        case Types.FIND_DATE_CAMPAIGNS:
                return {
                    ...state,
                    _id:payload._id,
                    findModalCampaigns:true
                }   
       case Types.CLEAR_CAMPAIGNS:
            return {
                ...state,
                _id:'',
                createModalCampaigns:payload.createModalCampaigns, 
                findModalCampaigns:payload.findModalCampaigns,
                createDuplicateCampaigns: payload.createDuplicateCampaigns         
            }
        case Types.CREATE_DUPLICATE_CAMPAIGNS:
            return {
                ...state,
                _id: payload._id,
                createDuplicateCampaigns: true,
                campaign: payload.campaign
                }   
        default:
        return state;
    }
}

export default ModalReducer;