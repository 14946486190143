
export const NumberToLetterInt = (num: any) => {

    let ImpLetra = ''
    let lnEntero = 0
    let lcRetorno = ''
    let lnTerna = 1
    let lcCadena = ''
    let lnUnidades = 0
    let lnDecenas = 0
    let lnCentenas = 0

    lnEntero = parseInt(num)

    while(lnEntero > 0) {
        
        lcCadena = ''
        lnUnidades = lnEntero % 10
        lnEntero =  Math.floor(lnEntero / 10)
        lnDecenas = lnEntero % 10
        lnEntero =  Math.floor(lnEntero / 10)
        lnCentenas = lnEntero % 10
        lnEntero =  Math.floor(lnEntero / 10)

        switch (lnUnidades) {
            case 1:
                lcCadena = 'UN '+lcCadena
                break;
            case 2:
                 lcCadena = 'DOS '+lcCadena
                break;
            case 3:
                 lcCadena = 'TRES '+lcCadena
                break;
            case 4:
                 lcCadena = 'CUATRO '+lcCadena
                break;
            case 5:
                 lcCadena = 'CINCO '+lcCadena
                break;
            case 6:
                 lcCadena = 'SEIS '+lcCadena
                break;
            case 7:
                 lcCadena = 'SIETE '+lcCadena
                break;
            case 8:
                 lcCadena = 'OCHO '+lcCadena
                break;
            case 9:
                 lcCadena = 'NUEVE '+lcCadena
                break;
            default:
                break;

        }

        if(lnDecenas === 1) {
            switch (lnUnidades) {
                case 0:
                    lcCadena = 'DIEZ '
                    break;
                case 1:
                    lcCadena = 'ONCE '
                    break;
                case 2:
                    lcCadena = 'DOCE '
                    break;
                case 3:
                    lcCadena = 'TRECE '
                    break;
                case 4:
                    lcCadena = 'CATORCE '
                    break;
                case 5:
                    lcCadena = 'QUINCE '
                    break;
                case 6:
                    lcCadena = 'DIECISEIS '
                    break;
                case 7:
                    lcCadena = 'DIECISIETE '
                    break;
                case 8:
                    lcCadena = 'DIECIOCHO '
                    break;
                case 9:
                    lcCadena = 'DIECINUEVE '
                    break;
                default:
                    break;
            }

        } else {
            if(lnDecenas === 2){
                switch(lnUnidades) {
                    case 0:
                        lcCadena = 'VEINTE '
                        break;
                    default:
                        lcCadena = 'VEINTI '+lcCadena
                        break;
                }
            }else{
                if(lnDecenas === 3){
                    switch(lnUnidades) {
                        case 0:
                            lcCadena = 'TREINTA '
                            break;
                        default:
                            lcCadena = 'TREINTA Y '+lcCadena
                            break;
                    }
                }else{
                    if(lnDecenas === 4){
                        switch(lnUnidades) {
                            case 0:
                                lcCadena = 'CUARENTA '
                                break;
                            default:
                                lcCadena = 'CUARENTA Y '+lcCadena
                                break;
                        }
                    }else{
                        if(lnDecenas === 5){
                            switch(lnUnidades) {
                                case 0:
                                    lcCadena = 'CINCUENTA '
                                    break;
                                default:
                                    lcCadena = 'CINCUENTA Y '+lcCadena
                                    break;
                            }
                        }else{
                            if(lnDecenas === 6){
                                switch(lnUnidades) {
                                    case 0:
                                        lcCadena = 'SESENTA '
                                        break;
                                    default:
                                        lcCadena = 'SESENTA Y '+lcCadena
                                        break;
                                }
                            }else{
                                if(lnDecenas === 7){
                                    switch(lnUnidades) {
                                        case 0:
                                            lcCadena = 'SETENTA '
                                            break;
                                        default:
                                            lcCadena = 'SETENTA Y '+lcCadena
                                            break;
                                    }
                                }else{
                                    if(lnDecenas === 8){
                                        switch(lnUnidades) {
                                            case 0:
                                                lcCadena = 'OCHENTA '
                                                break;
                                            default:
                                                lcCadena = 'OCHENTA Y '+lcCadena
                                                break;
                                        }
                                    }else{
                                        if(lnDecenas === 9){
                                            switch(lnUnidades) {
                                                case 0:
                                                    lcCadena = 'NOVENTA '
                                                    break;
                                                default:
                                                    lcCadena = 'NOVENTA Y '+lcCadena
                                                    break;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                
            }
        }

        switch (lnCentenas) {
            case 1:
                lcCadena = 'CIEN '+lcCadena
                break;
            case 2:
                lcCadena = 'DOSCIENTOS '+lcCadena
                break;
            case 3:
                lcCadena = 'TRESCIENTOS '+lcCadena
                break;
            case 4:
                lcCadena = 'CUATROCIENTOS '+lcCadena
                break;
            case 5:
                lcCadena = 'QUINIENTOS '+lcCadena
                break;
            case 6:
                lcCadena = 'SEISCIENTOS '+lcCadena
                break;
            case 7:
                lcCadena = 'SETECIENTOS '+lcCadena
                break;
            case 8:
                lcCadena = 'OCHOCIENTOS '+lcCadena
                break;
            case 9:
                lcCadena = 'NOVECIENTOS '+lcCadena
                break;
            default:
                break;
        }

        switch (lnTerna) {
            case 1:
                break;
            case 2:
                lcCadena = lcCadena+'MIL '
                break;
            case 3:
                lcCadena = lcCadena+'MILLONES '
                break;
            case 4:
                lcCadena = lcCadena+'MIL '
                break;
            default:
                break;
        }

        lcRetorno = lcCadena+lcRetorno
        lnTerna = lnTerna + 1
    
    }

    if(lnTerna === 1){
        lcRetorno = 'CERO'
    }

    ImpLetra = lcRetorno.trim()
    return ImpLetra
}
