import { useContext, useEffect, useState } from "react";
import styles from "../../../../styles/MiEmpresa/informacionBancaria.module.css"
import EmpresaContext from "../../../../context/Empresa/EmpresaContext";
import { updateState, clearEmpresaModal } from "../../../../context/Empresa/Actions";
import { useFormik } from "formik"
import * as Yup from "yup"
import { Form } from "semantic-ui-react";
import { TextField, FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";
import { useMutation, useQuery } from "@apollo/client";
import { 
        GET_ALL_ENTERPRISE, 
        CREATE_ENTERPRISE, 
        CREATE_REGISTROPATRONAL, 
        GET_ALL_BANK 
      } from "../../../../Querys/querys";
import { SuccessfulAlert } from "../../../../alerts/successAlerts";
import { postEnterprisAWS } from "../../../../services/candidateService";
import InputMask from "react-input-mask";
import moment from "moment";


const InformacionBancaria = (props:any) => {
  const {state, dispatch} = useContext(EmpresaContext)
  const [bancos, setBancos] = useState<any>([]);
  const [createNewCompany] = useMutation(CREATE_ENTERPRISE, {
    refetchQueries: [{ query: GET_ALL_ENTERPRISE }],
  });

  const [createRegistroPatronal] = useMutation(CREATE_REGISTROPATRONAL,
    {
      refetchQueries: [{ query: GET_ALL_ENTERPRISE }],
    }
    );

  const {data: dataBank} = useQuery(GET_ALL_BANK);
  useEffect(() => {
    if(dataBank){
      setBancos(dataBank.GET_ALL_BANK);
    }
  }, [dataBank]);
  const insertArchive = async (idEmpresa: any) =>{
    if(props.fileSello !== undefined || props.fileIMSS !== undefined || props.fileLogo !== undefined || props.fileKeyTimbre !== undefined || props.fileCerTimbre !== undefined){
      await postEnterprisAWS(
                              idEmpresa, 
                              props.fileLogo, props.fileIMSS, props.fileSello, props.fileKeyTimbre, props.fileCerTimbre,
                              "LogoEmpresa", "IMSSEmpresa", "SELLOEmpresa", "KeyTimbreEmpresa", "CerTimbreEmpresa",
                              "LogoEmpresa", "IMSSEmpresa", "SELLOEmpresa", "KeyTimbreEmpresa", "CerTimbreEmpresa"
                              );
    }

    handleClose();

    SuccessfulAlert({
      title: "¡Exito!",
      text: "¡Se agregó la empresa!",
    });

  }

  const insertRegistroPatronal = (idEmpresa: any) =>{
    state.registroPatronal?.forEach((reg:any) =>{
      if(reg.RegistroPatronal !== "" ||  reg.PrimaRiesgo !== ""){
         createRegistroPatronal({
          variables: {
            input: {
              enterpriseId: parseInt(idEmpresa),
              registroPatronal: reg.RegistroPatronal,
              primaRiesgo: reg.PrimaRiesgo,
              riskPlaced: reg.riskPlaced,
            },
          },
        })
      }
      
    })

  }

  const cambioEstado = async(event:any, value:{}) =>{
    await updateState(event, state, dispatch, 'informacionBancaria', value,'tabTres')
    createNewCompany({
      variables: {
        input: {
          name: state.identidadLegal.nombre,
          logo: '',
          taxRegime: state.identidadLegal.rfc,
          propertyRegime: state.identidadLegal.regimenFiscal,
          surcharge: '',
          state: state.identidadLegal.estado,
          address: state.identidadLegal.direccion,
          zipCode: String(state.identidadLegal.codigoPostal),
          bankaccount: String(state.informacionBancaria.cuentaBancaria),
          IMSSSubdelegationKey:'',
          fileCER: '',
          extrahours: false,
          automaticCalculationsVariables: false,
          useSTPaspaymentmethod: false,
          STPaccount: String(state.informacionBancaria.cuentaClabeSTP),
          stpCLABE: String(state.informacionBancaria.cuentaSTP),
          IMSSminimumwage: false,
          operationsIMSSSender: false,
          CertificateOfUserIMSS: "NA",
          CertificatePaswordIMSS:state.certificadoLlaves.password,
          IMSSCertificate: "NA",
          FIELCertificate: "NA",
          FIELPrivateKey: "NA",
          RazonSocial: state.identidadLegal.razonSocial,
          CertificatePaswordTimbre: state.certificadoLlaves.passwordTimbre,
          interbankAccount: String(state.informacionBancaria.cuantaInterbancaria),
          municipio: state.identidadLegal.municipio,
          NoCertificado: String(state.certificadoLlaves.NoCertificado),
          userIMSS: String(state.certificadoLlaves.usuarioIMSS),
          idBank: parseInt(state.informacionBancaria.idBank),
          outsourcers: state.identidadLegal.outsourcers,
          legalRepresentative: state.identidadLegal.legalRepresentative,
          notarialTestimony: state.identidadLegal.notarialTestimony,
          notarialTestimonyVolume: state.identidadLegal.notarialTestimonyVolume,
          notarialTestimonyDate: moment(state.identidadLegal.notarialTestimonyDate).format(),
          notaryPublicNumber: state.identidadLegal.notaryPublicNumber,
          notaryPublic: state.identidadLegal.notaryPublic,
          judicialDistrict: state.identidadLegal.judicialDistrict,
          addressCourt: state.identidadLegal.addressCourt,
        },
      },
    }).then((data)=>{
      insertRegistroPatronal(data?.data.CREATE_ENTERPRISE?.id)

      if(props.fileSello !== undefined || props.fileIMSS !== undefined || props.fileLogo !== undefined || props.fileKeyTimbre !== undefined || props.fileCerTimbre !== undefined){
        insertArchive(data?.data.CREATE_ENTERPRISE.id)

      }else{
        handleClose();
        SuccessfulAlert({
            title: "¡Exito!",
            text: "¡Se agregó la empresa!",
        });
      }
    });
  }

  const initialValues = () => {
    return{
      cuentaBancaria: state.informacionBancaria.cuentaBancaria,
      cuentaSTP: state.informacionBancaria.cuentaSTP,
      cuentaClabeSTP:state.informacionBancaria.cuentaClabeSTP,
      cuantaInterbancaria:state.informacionBancaria.cuantaInterbancaria,
      idBank:state.informacionBancaria.idBank
    }
  }
  
  const formik = useFormik({
    initialValues: initialValues(),
    validationSchema: Yup.object(validationSchema()),
    onSubmit:(formData) => {
      
      let bandera = true
      if(String(props.bankState) === ""){
        bandera = false
        removeError()
        const errorBank = document.querySelectorAll('#errorBank');
        
        errorBank.forEach((list) => {
          const span = document.createElement('span')
          span.textContent = "Obligatorio."
          span.id="alertBank"
          list.appendChild(span)

        });
      }
      if(bandera){
        state.informacionBancaria.idBank = props.bankState
        formData.idBank = props.bankState
        cambioEstado('', formData)
      }
      
    }
  })

  const handleClose = () => {
    props.setFileLogo(undefined)
    props.setFileIMSS(undefined)
    props.setFileSello(undefined)
    props.setFileKeyTimbre(undefined)
    props.setFileCerTimbre(undefined)
    props.setActivo('1')
    props.setRegimenFiscalState('')
    props.setEstadoState('')
    props.setMunicipioState('')
    props.setBankState('')
    props.setRiskPlaced('')
    props.setOutsourcers(false)
    clearEmpresaModal({}, dispatch);
  };

  const removeError = () =>{
    const errorBank = document.querySelectorAll('#errorBank');
    errorBank.forEach((list) => {
        const span = document.querySelector('#alertBank')
        if(span){
          list.removeChild(span)
        }
        
    });

  }

  return(
    <>
    <Form onSubmit={formik.handleSubmit}>
      <div className={styles.contenedorInformacionBancaria}>
        <div className={styles.contenedorInput}>

          <InputMask
            maskPlaceholder={null}
            mask="99999999999"
            defaultValue={String(state.informacionBancaria.cuentaBancaria)}
            onChange={formik.handleChange}
            disabled={false}
            onBlur={formik.handleBlur}
          >
            <TextField
              name="cuentaBancaria"
              error={
                formik.touched.cuentaBancaria && Boolean(formik.errors.cuentaBancaria)
              }
              label="Número de cuenta bancaria*"
              variant="outlined"
              size="small"
              fullWidth={true}
              id="codigoPostal"
              onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
            />
          </InputMask>

        </div>
        <div className={styles.contenedorError}>
          <span>{formik.touched.cuentaBancaria && Boolean(formik.errors.cuentaBancaria) && formik.errors.cuentaBancaria}</span>
        </div>
        <div className={styles.contenedorInput}>
          <FormControl 
            fullWidth 
            size="small"
            variant="outlined" 
            className={styles.dialogFields} 
            error={formik.touched.idBank && Boolean(formik.errors.idBank)}
          >
            <InputLabel id="demo-simple-select-label"
            style={{
              backgroundColor: "#FFFFFF"
            }}
            >Banco*</InputLabel>
            <Select
            style={{
              textOverflow: "ellipsis" ,
              whiteSpace: "nowrap",
              overflow: "hidden",
              width: "420px",
              
            }}
                
              labelId="demo-simple-select-label"
              id="idBank"
              name = "idBank"
              value = {props.bankState}
              onChange={(e)=>{
                removeError()
                props.setBankState(e.target.value)
              }}
            >
              {bancos?.map((banco:any, index:number) => {
                  return <MenuItem value={banco.id} key={index}>{banco.name}</MenuItem>
              })}
            </Select>
            <div className={styles.contenedorError} id="errorBank">
          
            </div>
          </FormControl>
        </div>
        
        <div className={styles.contenedorInput}>
          

          <InputMask
            maskPlaceholder={null}
            mask="999999999999999999"
            defaultValue={String(state.informacionBancaria.cuantaInterbancaria)}
            onChange={formik.handleChange}
            disabled={false}
            onBlur={formik.handleBlur}
          >
              <TextField
              name="cuantaInterbancaria"
              label="Número de CLABE interbancaria*"
              variant="outlined"
              size="small"
              fullWidth={true}
              error={
                formik.touched.cuantaInterbancaria && Boolean(formik.errors.cuantaInterbancaria)
              }
              onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
              />
          </InputMask>



        </div>
        <div className={styles.contenedorError}>
          <span>{formik.touched.cuantaInterbancaria && Boolean(formik.errors.cuantaInterbancaria) && formik.errors.cuantaInterbancaria}</span>
        </div>
        <div className={styles.contenedorInput}>

          <InputMask
            maskPlaceholder={null}
            mask="999999999999999999"
            defaultValue={String(state.informacionBancaria.cuentaSTP)}
            onChange={formik.handleChange}
            disabled={false}
            onBlur={formik.handleBlur}
          >
              <TextField
              name="cuentaSTP"
              label="Cuenta STP*"
              variant="outlined"
              size="small"
              fullWidth={true}
              error={
                formik.touched.cuentaSTP && Boolean(formik.errors.cuentaSTP)
              }
              onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
              />
          </InputMask>


        </div>
        <div className={styles.contenedorError}>
          <span>{formik.touched.cuentaSTP && Boolean(formik.errors.cuentaSTP) && formik.errors.cuentaSTP}</span>
        </div>
      </div>
      <div className={styles.contenedorBotones}>
        <div>
          <button className={styles.botonCancelarModal} onClick={handleClose}>
            Cancelar
          </button>
        </div>
        <div>
          <button className={styles.botonCrearEmpresaModal} type="submit">
            <div className={styles.iconoCrearEmpresaModal}></div>
            <div>
              Crear
            </div>
          </button>
        </div>
      </div>
    </Form>    
    </>
  )
}


const validationSchema = () => {
  return{
    cuentaBancaria: Yup.string().required("Obligatorio"),
    cuentaSTP: Yup.string().required("Obligatorio"),
    cuantaInterbancaria: Yup.string().required("Obligatorio"),
  }
}


export default InformacionBancaria