import { useEffect, useState, useContext } from "react";
import { TabCollaboratorContext } from "../../../../context/TabCollaboratorContext/TabCollaboratorContext";
import { DialogActions,
         TextField,
         Dialog,
         Table,
         TableBody,
         TableCell,
         TableContainer,
         TableHead,
         TableRow
         } from "@material-ui/core";
import {
  CREATE_SETTLEMENTPAYROLL,
  GET_ALL_SETTLEMENTPAYROLL,
  GET_ALL_DEDUCTIONS,
  GET_ALL_PERCEPTIONS,
  CREATE_SETTLEMENT_INCIDENT,
  DEACTIVATE_USERS,
  GET_ALL_USERS_COLLABORATOR,
  GET_ALL_USERS_INACTIVOS
} from "../../../../Querys/querys";
import { useMutation, useQuery } from "@apollo/client";
import { SuccessfulAlert } from "../../../../alerts/successAlerts";
import {WarningAlert} from "../../../../alerts/WarningAlert";
import { useForm } from "../../../../hooks/useForm";
import { SettlementPayrollModal } from "../../../../interfaces/TabSettlementPayroll.interfaces";
import SaveIcon from "@material-ui/icons/Save";
import SettlementPayrollProcessContext from "../../../../context/PayrollProcess/SettlementPayrollProcessContext";
import {
  clearSettlementProcess,
  createIncident,
} from "../../../../context/PayrollProcess/SettlementActions";
import InputAdornment from "@mui/material/InputAdornment";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Form } from "semantic-ui-react";
import style from "../../../Payroll/Modals/CrearSettlementModal.module.css";
import { cleanCollaborator } from "../../../../actions/tabColabortor";
import { ErrorAlert } from "../../../../alerts/errorAlert";
import moment from "moment";

const InactiveCollaboratorModal = ()  => {
  
  const {inactiveOpen,setInactiveOpen,collaboratorState,collaboratorDispatch} = useContext(TabCollaboratorContext);
  const { collaborator } = collaboratorState;

  const { state: SettlementPayrollState, dispatch: SettlementPayrollDispatch } =
  useContext(SettlementPayrollProcessContext);
  const [incidenceTaxable, setIncidenceTaxable] = useState(false);
  const [incidenceNotTaxable, setIncidenceNotTaxable] = useState(false);
  const [disableNomine, setDisableNomine] = useState(false);
  const [tx, setTx] = useState(false);
  const [txNot, setTxNot] = useState(false);
  const [tipoIncP, setTipoIncP] = useState(false);
  const [tipoIncC, setTipoIncC] = useState(false);
  const [allDeduction, setAllDeduction] = useState<any[]>([]);
  const [dot, setDot] = useState(0)
  const [zero, setZero] = useState(0)
  const [totalCantidad, setTotalCantidad] = useState("");
  const [conceptIncident, setConceptIncident] = useState('')
  const [viewTable, setViewTable] = useState(false);


  const [seleccionadosConcepto, setSeleccionadosConcepto] = useState<any[]>([]);

  const { data: resultDeductions } = useQuery(GET_ALL_DEDUCTIONS);
  const resultallDeductions = resultDeductions?.GET_ALL_DEDUCTIONS;
  const resultPerceptions = useQuery(GET_ALL_PERCEPTIONS);
  const resultallPerceptions = resultPerceptions.data?.GET_ALL_PERCEPTIONS;


  
  const [createSettlementPayRoll] = useMutation(CREATE_SETTLEMENTPAYROLL, {
    refetchQueries: [
      { query: GET_ALL_SETTLEMENTPAYROLL },
    ],
  });
  
  const [incidenceValue, setIncidenceValue] = useState('');
  const [deactivateUsers, {data:resultDeactivate}] = useMutation(DEACTIVATE_USERS, {
    refetchQueries: [
      { query: GET_ALL_USERS_COLLABORATOR, fetchPolicy:'no-cache'},
      { query: GET_ALL_USERS_INACTIVOS },
    ],
  });

  const [createSettlementIncident] = useMutation(CREATE_SETTLEMENT_INCIDENT, {
    refetchQueries: [{ query: GET_ALL_SETTLEMENTPAYROLL }],
  });


  const {
    onChange,
    formulario,
  } = useForm<SettlementPayrollModal>({
    Total: 0,
    Taxable: false,
    NotTaxable: false,
    Mixed: false,
    idConcept: 0,
    dischargeDate: "",
    dischargeType: "",
    reason: "",
    recessionJob: "",
    idCollaborator: 0,
    Concepts: "",
    IncidenceTaxable: false,
    IncidenceNotTaxable: false,
  });

  const initialValues = () => {
    return {
      Total1: "",
      Taxable: false,
      NotTaxable: false,
      Mixed: false,
      idConcept: 0,
      dischargeDate: "",
      dischargeType: "",
      reason: "",
      recessionJob: "",
      idCollaborator: "",
      Concepts: "",
      IncidenceTaxable: false,
      IncidenceNotTaxable: false,
    };
  };

  const validationSchema = () => {
    return {
      recessionJob: Yup.string().required("Por favor seleccione una opción"),
      dischargeType: Yup.string().required("Por favor seleccione una opción"),
    };
  };

  const formik = useFormik({
    initialValues: initialValues(),
    validationSchema: Yup.object(validationSchema()),

    onSubmit: (formData) => {
      console.log('Entra');
    
      let updatedTotal1 = formData.Total1; // Copia el valor actual
    
      if (formData.Total1.indexOf(".") > -1) {
        updatedTotal1 = formData.Total1.substring(
          0,
          formData.Total1.indexOf(".")
        );
      }
      
      updatedTotal1 = updatedTotal1.replace(/\./g, "");
      updatedTotal1 = updatedTotal1.replace(/[^0-9]/g, "");
      if (collaborator) {
      let id = collaborator._id;

    
      createSettlementPayRoll({
        variables: {
          input: {
            dischargeDate: formData.dischargeDate,
            dischargeType: formData.dischargeType,
            reason: formData.reason,
            recessionJob: formData.recessionJob,
            Taxable: formulario.Taxable.toString() === "true" ? true : false,
            NotTaxable:
              formulario.NotTaxable.toString() === "true" ? true : false,
            Mixed: formulario.Mixed.toString() === "true" ? true : false,
            idConcept: parseInt(formData.idConcept.toString()),
            Total: 0,
            idCollaborator: parseInt(id),
          },
        },
      }).then((data: any) => {
        const settlementResult = data?.data.CREATE_SETTLEMENTPAYROLL;
    
        SettlementPayrollState?.incident?.forEach((lis: any) => {
          createSettlementIncident({
            variables: {
              input: {
                idSettlement: parseInt(settlementResult?.id),
                idCollaborator: parseInt(id),
                Incident_type: 'Dinero',
                Total: parseFloat(lis?.Importe),
                Taxable: lis.IncidenceTaxable,
                NotTaxable: lis.IncidenceNotTaxable,
                Mixed: false,
                idConcept: parseInt(lis?.Concepto),
                TypeConcept: lis?.Tipo === 'Deducción' ? 'Deduccion' : 'Percepcion'
              },
            },
          })
        });
    
        deactivateUsers({
          variables: {
            id,
            input: {
              FechaBaja: formData.dischargeDate,
            },
          },
        });
    
        SuccessfulAlert({ text: "Registro creado con éxito" });
    
        // Actualiza el objeto formData con el nuevo valor de Total1
        formik.setValues({
          ...formData,
          Total1: updatedTotal1,
        });
    
        setIncidenceTaxable(false);
        setTx(false);
        setTxNot(false);
        setIncidenceNotTaxable(false);
        setDisableNomine(false);
        clearSettlementProcess({}, SettlementPayrollDispatch);
        setInactiveOpen();
      });
    } else {
      return ErrorAlert({ text: "No existe el colaborador" });
    }
    },
  });

  const cerrarModal = () => {
    setViewTable(false);
    setConceptIncident("")
    setTotalCantidad("")
    setTipoIncP(false);
    setTipoIncC(false);
    setDot(0)
    clearSettlementProcess({}, SettlementPayrollDispatch);
    collaboratorDispatch(cleanCollaborator());
    setInactiveOpen();
  };

  
  useEffect(()=>{
    if(resultDeactivate){
      clearSettlementProcess({}, SettlementPayrollDispatch);
      SuccessfulAlert({
        text: "¡Se ha desactivado al colaborador!",
      });
  
      SuccessfulAlert({
        text: "¡Se ha añadido la solicitud de baja!",
      });
    }
  }, [resultDeactivate]);


  const filtrarConceptoPercepcionDeduccion = (type: any) => {
    
    
    if (type === "Percepción") {
      setTipoIncP(true);
      setTipoIncC(false);
      const reg: any = [];
      resultallPerceptions?.forEach((item: any) => {
        const concep: any = {
          'id': item?.id,
           'concept_type': item?.ConceptName,
        };
        reg.push(concep);
      });

      setAllDeduction(reg);
    }

    if (type === "Deducción") {
      setTipoIncC(true);
      setTipoIncP(false);
      
      const reg: any = [];
      resultallDeductions?.forEach((item: any) => {
        const concep: any = {
          'id': item?.id,
            'concept_type': item?.concept_type,
        };
        reg.push(concep);
      });
      setAllDeduction(reg);
    }
  };

  const deleteReg = async(id: any, type: any) => {
    const newReg: any[]= []
      SettlementPayrollState?.incident?.forEach((reg:any) =>{
       if(reg.Concepto !== id || reg.Tipo !== type){
         newReg.push(reg)
       }
     })    
     SettlementPayrollState.incident = newReg    
     await createIncident(SettlementPayrollState, SettlementPayrollDispatch)
     SettlementPayrollState.incident.length > 0 ? setViewTable(true) :setViewTable(false)   
   }

  const upDateTotal = ((total:any)=>{
    const digitNew = total.replace(totalCantidad, "")
    
    let totalWith = ""
    if(total !== ""){
      if(total.length > totalCantidad.length  ){
        if(totalCantidad.length === 0){

          totalWith = total.replace(/\D/g, "")

        }else{
    
          if(digitNew === "."){
            if(dot === 0){
              setDot(1)
              totalWith = total
            }else{
              totalWith = totalCantidad+digitNew.replace(/\D/g, "")
            }
          }else{

            if(totalCantidad === "0"){
              if(digitNew != "."){
                totalWith = totalCantidad
              }else{
                totalWith = total
              }
            }else{
              totalWith = totalCantidad+digitNew.replace(/\D/g, "")
            }
          }
          
        }
      }else{
        if(totalCantidad.replace(total, "") == "."){
          setDot(0)
          setZero(0)
        }
        totalWith = total
      }

    }else{
      setDot(0)
      setZero(0)
    }
    setTotalCantidad(totalWith)
  })

  const addIncident = async()=>{

    if(conceptIncident !== '' && totalCantidad !== '' && (tipoIncP || tipoIncC) && (incidenceTaxable || incidenceNotTaxable) ){
      setViewTable(true)
        const newReg= {
          "Importe": totalCantidad,
          "Concepto": conceptIncident,
          "Tipo": tipoIncP? "Percepción": "Deducción",
          "IncidenceTaxable": incidenceTaxable,
          "IncidenceNotTaxable": incidenceNotTaxable,
        }
        
        if(SettlementPayrollState.incident === undefined){

          SettlementPayrollState.incident = [newReg]
        }else{
          SettlementPayrollState.incident.push(newReg)
        }
        
        await createIncident(SettlementPayrollState, SettlementPayrollDispatch)
        
        setConceptIncident("")
        setTotalCantidad("")
        setTipoIncP(false);
        setTipoIncC(false);
        setDot(0);
        setIncidenceTaxable( false);
        setIncidenceNotTaxable(false)

    }else{
      WarningAlert({text: "Seleccione un concepto, agregue un importe y seleccione si es percepción o deducción, y gravable o no gravable"})
    }
    
  }

  return (
      <Dialog
        onClose={cerrarModal}
        open={inactiveOpen}
        fullWidth={false}
        maxWidth={"sm"}
      >
      <Form onSubmit={formik.handleSubmit}>
          <div className={style.contenedorTitulo}>
            <span className={style.titulo}>Solicitud de baja</span>
          </div>
        <div className={style.contenedorPrincipal}>
          
        <div>
            <div className={style.fechas}>
              <div>
                <span className={style.textoFecha}>Fecha *</span>
              </div>
              <div>
                <input
                  name="dischargeDate"
                  type="date"
                  className={style.inputFecha}
                  value={formik.values.dischargeDate}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </div>
            </div>
          </div>

          <fieldset className={style.fieldsetNombre}>
            <legend className={style.tituloFieldsetNombre}>
              Tipo de baja *
            </legend>
            <select
              className={style.selectNombre}
              value={formik.values.dischargeType}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name="dischargeType"
            >
              <option value={"" || ""} disabled>
                Seleccione...
              </option>
              <option value="ABANDONO DE EMPLEO">
              ABANDONO DE EMPLEO
              </option>
              <option value="AUSENTISMO">
              AUSENTISMO
              </option>
              <option value="CLAUSURA">
              CLAUSURA
              </option>
              <option value="DEFUNCION">
              DEFUNCION
              </option>
              <option value="JUBILACION">
              JUBILACION
              </option>
              <option value="PENSION">
              PENSION
              </option>
              <option value="RESCISION DE CONTRATO">
              RESCISION DE CONTRATO
              </option>
              <option value="SEPARACION VOLUNTARIA">
              SEPARACION VOLUNTARIA
              </option>
              <option value="TERMINO DE CONTRATO">
              TERMINO DE CONTRATO
              </option>
              <option value="OTRA">
              OTRA
              </option>
            </select>
          </fieldset>
          <div className={style.contenedorError}>
            <span className={style.errorInput}>
              {formik.touched.dischargeType && formik.errors.dischargeType}
            </span>
          </div>

          <fieldset className={style.fieldsetUpdate}>
            <legend className={style.tituloFieldsetNombre}>Razón</legend>
            <input
              className={style.inputUpdate}
              name="reason"
              type="text"
              value={formik.values.reason}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </fieldset>
          <fieldset className={style.fieldsetNombre}>
            <legend className={style.tituloFieldsetNombre}>
              Tipo de recisión para cálculo *
            </legend>
            <select
              className={style.selectNombre}
              value={formik.values.recessionJob}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name="recessionJob"
            >
              <option value={"" || ""} disabled>
                Seleccione...
              </option>
              <option value="Finiquito">Finiquito</option>
              <option value="Liquidación">Liquidación</option>
            </select>
          </fieldset>
          <div className={style.contenedorError}>
            <span className={style.errorInput}>
              {formik.touched.recessionJob && formik.errors.recessionJob}
            </span>
          </div>

          <div className={style.radioitem}>
            <input
              type="radio"
              name="tx"
              id="ritema"
              onChange={(e) => {setTx(false);setTxNot(false); onChange(e.target.checked.toString(), "Taxable")}}
            />
            <label htmlFor="ritema"> Gravable </label>
          </div>

          <div className={style.radioitem+" "+(disableNomine ? style.disabled : '')}>
            <input
              type="radio"
              name="tx"
              id="ritemc"
              checked={tx}
              onChange={(e) => {
                setTx(true);
                setTxNot(false);
                onChange(e.target.checked.toString(), "Mixed")
              }}
              disabled={disableNomine}
            />
            <label htmlFor="ritemc"> Total </label>
          </div>

          <hr></hr>
          <div className={style.radioitem}>
            <input
              type="radio"
              name="concepto"
              id="typeconcepta"
              checked={tipoIncP}
              onChange={(e) => {
                
                filtrarConceptoPercepcionDeduccion('Percepción')
              }}
            />
            <label htmlFor="typeconcepta"> Percepci&oacute;n</label>
          </div>
          
          <div className={style.radioitem}>
            <input
              type="radio"
              name="concepto"
              id="typeconceptb"
              checked={tipoIncC}
              onChange={(e) => {         
                filtrarConceptoPercepcionDeduccion('Deducción')
              }}
            />
            <label htmlFor="typeconceptb"> Deducci&oacute;n </label>
          </div>
          <fieldset className={style.fieldsetUpdate}>
            <legend className={style.tituloFieldsetNombre}>
              Importe de incidencias
            </legend>
            <TextField
              style={{ width: "100%" }}
              name="Total1"
              type="text"
              value={totalCantidad}
              onChange={({ target }) => {
                upDateTotal(target.value);
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
            />
          </fieldset>

          <fieldset className={style.fieldsetNombre}>
            <legend className={style.tituloFieldsetNombre}>Concepto *</legend>
            <select
              className={style.selectNombre}
              value={conceptIncident}
              onChange={({ target }) => {
                setConceptIncident(target.value);
              }}
              name="idConcept"
            >
              <option value={"" || ""}>{""}</option>
              {allDeduction?.map((lis: any) => (
                <option key={lis?.id} value={lis?.id}>
                  {
                    lis?.concept_type
                  }
                </option>
              ))}
            </select>
          </fieldset>
          <div className={style.radioitem}>
            <input
              type="radio"
              name="IncidenceTaxable"
              id="ritemai"
              checked={incidenceTaxable}
              onChange={(e) => {
                setIncidenceTaxable(true);
                setIncidenceNotTaxable(false);
              }}
            />
            <label htmlFor="ritemai"> Incidencia gravable </label>
          </div>
          <div className={style.radioitem+" "+(disableNomine ? style.disabled : '')}>
            <input
              type="radio"
              name="IncidenceTaxable"
              id="ritembi"
              checked={incidenceNotTaxable}
              disabled={disableNomine}
              onChange={(e) =>{
                setIncidenceTaxable(false);
                setIncidenceNotTaxable(true);
              }
              }
            />
            <label htmlFor="ritembi" > Incidencia no gravable </label>
          </div>
          <div>
            <ul className={style.contenedorLista}>
              {seleccionadosConcepto.map((lis: any) => (
                <li className={style.listaNombres}>
                  <div className={style.nombre}> {lis.nombre} </div>
                </li>
              ))}
            </ul>
          </div>
          <div className={style.contenedorError}>
            <span className={style.errorInput}>
              {formik.touched.idConcept && formik.errors.idConcept}
            </span>
          </div>
          <div className={style.inputRegistroPatronalRiesgoPuesto}> 
            <button 
                className={style.botonAgregarRegistroPatronal}
                onClick={((e)=>{
                  e.preventDefault()
                  addIncident()
                }
                )}
                >
                Agregar Incidencia
            </button>
          </div>
          {viewTable &&
          <div className={style.divIncident} >
            <div
            style={
              {
                width: "100%",
                marginTop:"2px",
                marginLeft: "auto",
                marginRight: "auto",                
              }
            }
            >
            <div
                style={{ 
                  display: "flex",
                  alignContent:  "center"                  
                }}
                >
                <TableContainer >
                 <Table size="small" stickyHeader aria-label="sticky table">
                   <TableHead>
                    <TableRow>
                     <TableCell style={{ fontSize:'11px' }}>Concepto</TableCell>
                     <TableCell style={{ fontSize:'11px' }}>Tipo</TableCell>
                     <TableCell style={{ fontSize:'11px' }}>Incidencia</TableCell>
                     <TableCell style={{ fontSize:'11px' }}>Importe($)</TableCell>
                     <TableCell> </TableCell>
                   </TableRow>
                  </TableHead>
               <TableBody>               
                { SettlementPayrollState?.incident?.map((lis: any,index:any) => (
                  <TableRow key={index}>                  
                  <TableCell align="left" style={{ fontSize:'10px' }}>
                  {lis?.Tipo === 'Deducción' && resultallDeductions.filter((item: any) => item.id === lis?.Concepto)[0]?.concept_type
                    || resultallPerceptions.filter((item: any) => item.id === lis?.Concepto)[0]?.ConceptName
                    || 'No se encontró ningún concepto'
                  }
                 </TableCell>
                  <TableCell align="left" style={{ fontSize:'10px' }}>{lis?.Tipo}</TableCell>
                  <TableCell align="left" style={{ fontSize:'10px' }}>{lis?.IncidenceTaxable ? 'Gravable' : lis.IncidenceNotTaxable ? 'No gravable' : ''}</TableCell>
                  <TableCell align="left" style={{ fontSize:'10px' }}>{`${parseFloat(lis?.Importe).toFixed(2)}` }</TableCell>
                  <TableCell align="left" className={style.lineaContenedorEliminar} onClick={()=>deleteReg(lis?.Concepto, lis?.Tipo)}>
                     <div className={style.iconoLineaPrimaRiesgo}></div>
                  </TableCell>
                 </TableRow>
                ))}
                 </TableBody>
                 </Table>
                </TableContainer>
                </div>
            
              </div>            
          </div>
          }
           <DialogActions className={style.contenedorAcciones}>
            <div className={style.conteinerFile}>
              <button className={style.botonCancelar} onClick={cerrarModal} type="button">
                Cancelar
              </button>

              <button className={style.botonAgregar} type="submit">
                <div className={style.iconoAgregar}>
                  <SaveIcon />
                </div>
                <div>
                  <span className={style.textoAgregar}>+ Solicitar</span>
                </div>
              </button>
            </div>
          </DialogActions>
        </div>
        </Form>
      </Dialog>
  );
};

export default InactiveCollaboratorModal;
