import { Dispatch } from "react";
import { Types } from "./OutsorcersNominaContext";

export const openAutorizaOutsorcers = ({openAutoriza = true}:any, dispatch: Dispatch<any>):void=>{
  console.log('Si esta entrando')
  dispatch({
    type: Types.OPEN_AUTORIZA,
    payload:{
      openAutoriza
    }
  })
}

export const openDispersionConfirmaOutsorcers = ({openDispersionOutsorcer = true}:any, dispatch: Dispatch<any>):void=>{
  console.log('Si esta entrando')
  dispatch({
    type: Types.OPEN_DISPERSION_OUTSORCERS,
    payload:{
      openDispersionOutsorcer
    }
  })
}


export const clearOutsorcers = ({}:any, dispatch: Dispatch<any>):void=>{
  console.log('Si esta entrando')
  dispatch({
    type: Types.CLEAR_OUTSORCERS,
    payload:{
      openAutoriza: false
    }
  })
}


export const openEliminaOutsorcer = ({openDescartarOutsorcer = true, _id= ""}:any, dispatch: Dispatch<any>):void=>{
  console.log('Si esta entrando', _id)
  dispatch({
    type: Types.OPEN_DESCARTAR_OUTSORCERS,
    payload:{
      _id,
      openDescartarOutsorcer: false
    }
  })
}

export const abreModalSinSeleccion = ({openSinSeleccion = true}:any, dispatch: Dispatch<any>):void=>{
  console.log('Si esta entrando')
  dispatch({
    type: Types.OPEN_SIN_SELECCION,
    payload:{
      openSinSeleccion: false
    }
  })
}

export const abreModalAceptarRechazado = ({_id="", openAceptarRechazado =true, nombreColaborador = "", conceptoDescartado=""}:any, dispatch: Dispatch<any>):void=>{
  console.log('Si esta entrando')
  dispatch({
    type: Types.OPEN_ACEPTAR_RECHAZADO,
    payload:{
      _id,
      openAceptarRechazado,
      nombreColaborador,
      conceptoDescartado
    }
  })
}

export const abreModalRechazarRechazado = ({_id="", openRechazarRechazado = true, nombreColaborador = "", conceptoDescartado=""}:any, dispatch: Dispatch<any>):void=>{
  console.log('Si esta entrando')
  dispatch({
    type: Types.OPEN_RECHAZAR_RECHAZADO,
    payload:{
      _id,
      openRechazarRechazado,
      nombreColaborador,
      conceptoDescartado
    }
  })
}


export const abreModalDescartarTodas = ({ openDescartaTodas = true,_id=""}:any, dispatch: Dispatch<any>):void=>{
  console.log('Si esta entrando')
  dispatch({
    type: Types.OPEN_DESCARTA_TODAS,
    payload:{
      _id,
      openDescartaTodas
    }
  })
}