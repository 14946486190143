import { MouseEvent, useContext, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import TableBits from '../TableBits/TableBits';
import columns from './Columns';
import ModalContext from '../../context/ModalContext/ModalContext';
import { TabPanelProps } from '../../interfaces/TabPanelProps';
import { getInactiveCollaborators } from '../../services/InactivesService';
import UserField from '../TableBits/UserField';
import FilterOptions from './FilterOptions';
import ReportTable from '../Collaborators/CollaboratorTab/ReportTable';
import SearcherTable from '../Collaborators/CollaboratorTab/SearcherTable';
import ModalReactive from './Modals/ModalReactive';
import FilterTable from '../Collaborators/CollaboratorTab/FilterTable';
import MenuList from './Modals/MenuList';
import FormatDate from './Fields/FormatDate';
import FormatDateIngreso from './Fields/FormatDateIngreso';
import FieldTipoBaja from './Fields/FieldTipoBaja';
import FieldReasonCollaborator from './Fields/FieldReasonCollaborator';
import { AdminContext } from '../../context/AdminContext/AdminContext'

import { useLazyQuery, useQuery } from "@apollo/client";
import { GET_ALL_USERS_INACTIVOS, GET_EXCEL_INACTIVE_USER } from "../../Querys/querys";
import { ConstructionOutlined } from '@mui/icons-material';

const InactivesTable = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;
  const { state } = useContext(ModalContext);
  const [inactiveList, setInactiveList] = useState<any[]>([]);
  const [filerInactiveList, setFilterInactiveList] = useState<any[]>([]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { adminState } = useContext(AdminContext)

  const [getCollaborators, { data: resultCollaborator }] = useLazyQuery(
    GET_ALL_USERS_INACTIVOS,
    {
      fetchPolicy: 'no-cache',
    }
  );

  useEffect(() => {
    if (resultCollaborator && resultCollaborator.GET_ALL_USERS_INACTIVOS) {
      setInactiveList(resultCollaborator.GET_ALL_USERS_INACTIVOS);
      setFilterInactiveList(resultCollaborator.GET_ALL_USERS_INACTIVOS);
    }
  }, [resultCollaborator]);

  const updateData = async () => {
    const data = await getCollaborators({
      variables: {
        rowsPerPage: paginationObj.rowsOfPage,
        pageNumber: paginationObj.pageNumber,
        searchTerm: paginationObj.searchTerm,
        order: paginationObj.order,
        orderBy: paginationObj.orderBy
      },
      // fetchPolicy: 'no-cache',
    })
  }

  useEffect(() => {
    if (state.open === false) {
      updateData()
    }
  }, [state]);

  const { data: resultCollaboratorE } = useQuery(GET_EXCEL_INACTIVE_USER);
  const allCollaboratorExcel = resultCollaboratorE?.GET_EXCEL_INACTIVE_USER;

  const [paginationObj, setPaginationObj] = useState({
    rowsOfPage: 10,
    pageNumber: 0,
    searchTerm: "",
    order: "desc",
    orderBy: "FechaIngreso"
  });

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      {...other}
    >
      {value === index && (
        <Box display="flex" flexDirection="column" p={2}>
          <Box p={1} pb={3} display="flex" flexDirection="row">
            <Box display="flex" justifyContent="flex-start">
              <SearcherTable
                initState={inactiveList}
                setState={setFilterInactiveList}
                fields={["bussinesName", "project"]}
                customSearch={(searchTerm: string) => {
                  getCollaborators({
                    variables: {
                      rowsPerPage: paginationObj.rowsOfPage,
                      pageNumber: 0,
                      searchTerm,
                      order: paginationObj.order,
                      orderBy: paginationObj.orderBy
                    },
                    // fetchPolicy: 'no-cache',
                  })
                  setPaginationObj({ ...paginationObj, pageNumber: 0, searchTerm });
                }}
              />
            </Box>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="flex-end"
              style={{ width: "100%" }}
            >
              <ReportTable link={allCollaboratorExcel} type="inactive" />
            </Box>
          </Box>
          <TableBits
            columns={columns}
            rows={filerInactiveList}
            components={[UserField, null, null, null, FormatDateIngreso, FormatDate]}
            componentOptions={MenuList}
            totalRecords={filerInactiveList?.length > 0 ? filerInactiveList[0]?.totalRows : 0}
            getData={async (paginationObj_) => {
              setPaginationObj((val) => ({
                ...val,
                rowsPerPage: paginationObj_.rowsOfPage,
                pageNumber: paginationObj_.pageNumber,
              }))

              getCollaborators({
                variables: {
                  rowsPerPage: paginationObj_.rowsOfPage,
                  pageNumber: paginationObj_.pageNumber,
                  searchTerm: paginationObj.searchTerm,
                  order: paginationObj.order,
                  orderBy: paginationObj.orderBy
                },
                // fetchPolicy: 'no-cache',
              })
            }}
            updatePaginationObj={(paginationObj_) => {
              setPaginationObj((val) => ({
                ...val,
                order: paginationObj_.order,
                orderBy: paginationObj_.orderBy
              }));
              getCollaborators({
                variables: {
                  rowsPerPage: paginationObj.rowsOfPage,
                  pageNumber: paginationObj.pageNumber,
                  searchTerm: paginationObj.searchTerm,
                  order: paginationObj_.order,
                  orderBy: paginationObj_.orderBy
                },
                // fetchPolicy: 'no-cache',
              })
            }}
            customInitialOrderBy="FechaIngreso"
            customInitialOrder="desc"
            customRowsPerPage={10}
            updatePageNumber={paginationObj.pageNumber}
          />
        </Box>
      )}
      <ModalReactive />
    </div>
  )
}

export default InactivesTable;