import { Dispatch } from "react";
import {Types} from "./PrestacionContext";

export const createBenefitsModal = ({_id = "",createModalBenefits = true}:any, dispatch: Dispatch<any>):void =>{
    dispatch({
       type:Types.CREATE_BENEFITS,
       payload:{
         createModalBenefits
       } 
    })
}

export const updateBenefitsModal = (_id = "", updateModalBenefits = true, dispatch: Dispatch<any>):void => {
    dispatch({
        type:Types.UPDATE_BENEFITS,
        payload:{
            _id,
            updateModalBenefits,
        }
    })
}

export const deleteBenefitsModal = (_id="", deleteModalBenefits=true, dispatch:Dispatch<any>):void =>{
    dispatch({
        type: Types.DELETE_BENEFITS,
        payload:{
            _id,
            deleteModalBenefits
        }
    })
}


export const createUserBenefitsModal = ({_id = "",createModalUserBenefits = true}:any, dispatch: Dispatch<any>):void =>{
    dispatch({
       type:Types.CREATE_USER_BENEFITS,
       payload:{
        createModalUserBenefits
       } 
    })
}

export const closeUserBenefitsModal = ({_id="", createModalUserBenefits = false}:any, dispatch: Dispatch<any>):void => {
    dispatch({
        type: Types.CLOSE_USER_BENEFITS_MODAL,
        payload: {
            createModalUserBenefits           
        }
    })
}


export const clearBenefitsModal = ({_id="", createModalBenefits = false, updateModalBenefits = false, deleteModalBenefits = false,}:any, dispatch: Dispatch<any>):void => {
    dispatch({
        type: Types.CLEAR_BENEFITS,
        payload: {
            _id,
            createModalBenefits,
            updateModalBenefits,
            deleteModalBenefits
           
        }
    })
}

// export const closeBenefits = (openNewWorkSchedule=false, dispatch:Dispatch<any>):void =>{
//     dispatch({
//         type: Types.CLOSE_NEW_WORK_SCHEDULE,
//         payload:{
//             openNewWorkSchedule
//         }
//     })
// }

// export const closeListWorkSchedule = (openListSchedule=false, dispatch:Dispatch<any>):void =>{
//     dispatch({
//         type: Types.CLOSE_LIST_WORK_SCHEDULE,
//         payload:{
//             openListSchedule
//         }
//     })
// }

