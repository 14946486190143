import { useContext } from "react";
import mayor from "../../../assets/svg/mayor.svg";
import ReportContext from "../../../context/ReportsContext/ReportContext"
import { findReportsModal } from "../../../context/ReportsContext/Actions"

const BtnFilterReport = ({title,width,type,tab,setAllReports,initAllReports,initMyReports,setMyReports}:any) => {
  const { dispatch } = useContext(ReportContext);
  const HandleFiltrar=(tab:any)=>{
    const tabReport=[0,1];
    const typeReport=["typeReport","date"];
    if(tab === tabReport[0] && type===typeReport[0]){
      const reports = [...initAllReports]      
      const filterTipeReport = reports.sort((x: any, y: any) => x.ReportType.localeCompare(y.ReportType));
      setAllReports(filterTipeReport);
    }
    if(tab === tabReport[1] && type===typeReport[0]){
      const reports = [...initMyReports]
      const filterTipeReport = reports.sort((x: any, y: any) => x.ReportType.localeCompare(y.ReportType));
      setMyReports(filterTipeReport);
    }

    if((tab === tabReport[0] ||tab === tabReport[1])&& type===typeReport[1]){
       findReportsModal("0", true, dispatch)      
    }
  }
  
  return (
    <div className="containerBtnFiltrar">
        <button
        id="addPrestacion"
        onClick={() => HandleFiltrar(tab)}
        className="btnFiltrarReports"
        style={{width: width}}
        >
        <div className="reportBtn">
            <div className="nombrePrestacion">
            <b>
                <span>{title}</span>
            </b>
            </div>
            <img src={mayor} alt="filtrar" />
        </div>
        </button>
  </div>
  )
}

export default BtnFilterReport
