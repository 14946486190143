import { useReducer } from "react";
import "../ConfigPayroll/PayrollGroup.css";
import { Grid } from "@material-ui/core";
import { useToggle } from "../../hooks/useToggle";
import MenuListDeduccion from "./Modals/MenuListDeduccion";
import InfoDeduction from '../ConfigPayroll/Modals/InfoDeductionModal';
import EliminaDeduccionModales from "./Modals/EliminaDeduccionModales";
import EditaDeduccion from "./Modals/EditaDeduccion";
import { TabDeduccionContext } from "../../context/ConfigPayrollContext/TabDeduccionContext";
import CrearDeduccionModal from "./Modals/CreateDeduccionModal";
import { TabDeduccionReducer } from "../../context/ConfigPayrollContext/TabDeduccionReducer"
import { useQuery, useMutation } from "@apollo/client";
import { GET_ALL_DEDUCTIONS,UPDATE_DEDUCTION_STATUS,GET_DEDUCTIONS_CALENDAR,GET_ALL_DEDUCTIONS_INCIDENCIAS } from "../../Querys/querys";
import GreenSwitch from './GreenSwitch'
import CheckPermission from "../../helpers/Administradores/Permissions";


interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

const DeduccionTable = (props: TabPanelProps) => {
  const resultDeductions = useQuery(GET_ALL_DEDUCTIONS);
  const allDeductions = resultDeductions.data?.GET_ALL_DEDUCTIONS;
  const [DeduccionState, DeduccionDispatch] = useReducer(TabDeduccionReducer, {
    loading: true,
    Deducciones: [],
    DeduccionesFilter: [],
  });
  const [inactiveOpen, setInactiveOpen] = useToggle(false);
  const [addDeduccionOpen, setAddDeduccionOpen] = useToggle(false);
  const [updateStatus] = useMutation(UPDATE_DEDUCTION_STATUS,{
    refetchQueries:[
                    {query: GET_ALL_DEDUCTIONS},
                    {query: GET_DEDUCTIONS_CALENDAR},
                    {query: GET_ALL_DEDUCTIONS_INCIDENCIAS}
                  ]
  })

  const updatePayrollStatus = (id:any) =>{
    updateStatus({
      variables:{
        updateDeductionStatusId: id
      }
    })
  }

  return (
    <>
      <EliminaDeduccionModales />
      <div className="contenedorHeader">
        <div>
          <span className="tituloHeader">Deducciones</span>
        </div>
        <div>
          <TabDeduccionContext.Provider
            value={{
              inactiveOpen,
              setInactiveOpen,
              addDeduccionOpen,
              setAddDeduccionOpen,
              DeduccionState,
              DeduccionDispatch,
            }}
          >
            {CheckPermission("ConfiguracionNominas.Conceptos.Deducciones.Crear") && ( 
            <button onClick={setAddDeduccionOpen} className="botonHeader">
              <div className="contenedorBotonHeader">
                <div className="textoBotonHeader">
                  <span>+ Nueva deducción</span>
                </div>
              </div>
            </button>)}
            <CrearDeduccionModal  />
          </TabDeduccionContext.Provider>
        </div>
      </div>
      <div className="contenedorTabla">
        <div className="contenedorTituloTabla">
              <Grid container spacing={1}>
                  <Grid item xs={4} className="tituloTabla">
                    <span className="textotituloTabla">Nombre</span>
                  </Grid>
                  <Grid item xs={3} className="tituloTabla">
                    <span className="textotituloTabla">Clave SAT</span>
                  </Grid>
                  <Grid item xs={4} className="tituloTabla">
                    <span className="textotituloTabla">Estatus</span>
                  </Grid>
                  <Grid item xs={1} className="tituloTabla">
                    <span className="textotituloTabla">Más información</span>
                  </Grid>
                </Grid>
          </div>
      </div>
      <div>
        <ul>
          {allDeductions?.map((lis: any) => (
            <li className="listaTabla">
              <Grid container spacing={1}>
                <Grid item xs={4} className="tituloTabla">
                  <div className="tablaColumnaFlex">
                    <span className="textoTabla">{lis.concept_type}{lis.SATKey !== "004" && ' *'}</span>
                  </div>
                </Grid>
                <Grid item xs={3} className="tituloTabla">
                  <div className="tablaColumnaFlex">
                    <span className="textoTabla">{lis.SATKey}</span>
                  </div>
                </Grid>
                <Grid item xs={4} className="tituloTabla">
                  <div className="tablaColumnaFlex">
                  <div className="tablaColumnaFlex">
                      <div className="columnaEstatus">
                        <div>
                          <span className="textoTabla">{lis.statusDeduction}</span>
                        </div>
                        <div>
                          <GreenSwitch updatePayrollStatus={updatePayrollStatus} id={lis.id} status={lis.statusDeduction}/>
                        </div>
                      </div>
                    </div>
                    <div className="menuTabla">
                    <MenuListDeduccion
                      {...{
                        _id: lis.id,
                        ConceptName: lis.concept_type,
                        SATKey: lis.SATKey,
                        ISRTax: lis.ISRTax,
                        TaxBoth: lis.TaxBoth,
                        time : lis.time,
                        isMoney : lis.isMoney,
                        muestraCalendario: lis.muestraCalendario,
                      }}
                    />
                    </div>
                  </div>
                </Grid>
              </Grid>
            </li>
          ))}
        </ul>
      </div>
      <InfoDeduction/>
      <EditaDeduccion  />
    </>
  );
};

export default DeduccionTable;
