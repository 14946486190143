import Box from '@mui/material/Box';
import { Collaborator } from '../../interfaces/TabCollaborator.interfaces';
import CollaboratorIconText from '../CollaboratorIcon/CollaboratorIconText';
import CollaboratorIconIMG from '../CollaboratorIcon/CollaboratorIconIMG';

const UserField = ({ bussinesName, email, img, done, ImagenUsuario, lastName }: Collaborator) => {
    return (
        <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            
        >
            <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                {ImagenUsuario === undefined || ImagenUsuario === '' || ImagenUsuario === null ? (
                    <CollaboratorIconText
                    Nombre={bussinesName}
                    Apellido={lastName}
                    width="33px"
                    height="33px"
                    />
                ) : (
                        
                    <CollaboratorIconIMG
                    ImagenUsuario={ImagenUsuario}
                    width="33px"
                    height="33px"
                    widthImg="31px"
                    heightImg="31px"

                    />
                )}
            </Box>

            <Box
                display="flex"
                flexDirection="column"
                justifyContent="flex-start"
                className="boxData__userField"
            >
                <label className="name__userField">{bussinesName}</label>
                <label className="email__userField">
                    {
                        done ? <span className="dot-green"></span> : <span className="dot-wait"></span>
                    }
                    {email}
                </label>
            </Box>
        </Box>
    )
}

export default UserField
