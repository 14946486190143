import style from "../../screens/BitsScreen.module.css";

export const  GeneralPieChartFivePath = ({ 
    percentage = []
    , tipo = ''
    , percentageSymbol = ''
    , number = ''
    , textSize='20px'
    , y=50 
}) => {
    const strokeWidth = 10;
    const radius = 50 - strokeWidth / 2;
    const pathDescription = `
        M 50,50 m 0,-${radius}
        a ${radius},${radius} 0 1 1 0,${2 * radius}
        a ${radius},${radius} 0 1 1 0,-${2 * radius}
        `;

    const diameter = Math.PI * 2 * radius;

    const percentageOrder = percentage?.sort((a: any, b: any) => (
    a.percentage > b.percentage ? 1 : -1
    ) )

    let progressStyle: any = []
    let total = 0

    if(percentageOrder){
        percentageOrder.forEach((item: any, index: any) => {
            
            total = total + item.percentage
          
                progressStyle.push({
                    stroke: item.color,
                    strokeDasharray: `${diameter}px ${diameter}px`,
                    strokeDashoffset: `${((100 - total) / 100) * diameter}px`,
                })
            
            
        })
    }

    return (
        <svg
        id="progressbarcircle"
        className={style.CircularProgressbar}
        viewBox="0 0 100 100"
        width={170}
        height={170}
        >
            <path
                className="CircularProgressbar-trail"
                d={pathDescription}
                strokeWidth={strokeWidth}
                fillOpacity={0}
                style={{
                stroke: '#D8DCE6',
                }}
            />

            {
                percentageOrder?
                    percentageOrder?.map((item: any, index: any) => {
                        return (
                            <path
                                className="CircularProgressbar-path"
                                d={pathDescription}
                                strokeWidth={strokeWidth}
                                fillOpacity={0}
                                style={progressStyle[4 - index]}
                            />
                        )
                    })

                :null
            }


        <text
            className="CircularProgressbar-text"
            x={50}
            y={y}
            style={{
            fill: "#093C5D",
            fontSize: textSize,
            textAnchor: "middle",
            stroke: "1px",
            color: "#093C5D"
            }}
        >
            {`${number}${percentageSymbol}`}
            {/* salto de linea */}
            <tspan x="50" dy="1.5em" style={{fontSize: "10px",fill: "#093C5D"}}>{tipo}</tspan>
            
            
        
        </text>
        </svg>
    );
};
