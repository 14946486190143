import {
  Theme,
  Dialog,
  withStyles,
} from "@material-ui/core";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import { confirmationInfoEmail } from "../../../../../services/candidateService";
import {
  GET_USER_NUEVOINGRESO_REGISTER,
  UPDATE_USERS,
} from "../../../../../Querys/querys";
import { useMutation } from "@apollo/client";
import AceptInfoLogo from "../../../../../assets/svg/AceptInfoLogo.svg";
import shapeBlueCheck from "../../../../../assets/svg/shapeBlueCheck.svg";
import { useState } from "react";
import ReadyModal from "./ReadyModal";

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const AcceptInfoModal = (props: any) => {
  let { collaborator, handleClose, isOpen, token } = props;
  const [isOpenReady, setIsOpenReady] = useState<boolean>(false);

  const handleCloseReadyModal = () => setIsOpenReady(false);

  const [updateColaboradores] = useMutation(UPDATE_USERS, {
    refetchQueries: [
      {
        query: GET_USER_NUEVOINGRESO_REGISTER,
        variables: { token: token },
      },
    ],
  });

  const handleAcceptInfo = async () => {
    handleClose();
    await updateColaboradores({
      variables: {
        updateUsersId: collaborator?.id,
        input: {
          accept_info: true,
        },
      },
    });
    await confirmationInfoEmail(
      collaborator?.userAdd,
      collaborator?.fullName
    ).then(() => {
      setIsOpenReady(true);
    });
  };

  return (
    <div>
      <ReadyModal isOpen={isOpenReady} handleClose={handleCloseReadyModal} />

      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={isOpen}
        fullWidth={false}
        maxWidth={"md"}
        style={{ borderRadius: "3%" }}
      >
        <div className="contenedorModalPerceptions2">
          <img
            src={AceptInfoLogo}
            alt="Aceptar logo"
            style={{ paddingTop: "5%" }}
          />
        </div>
        <div>
          <div className="contenedorModalPerceptions2">
            <p className="tituloModalPerceptions">Mi Información es correcta</p>
          </div>
          <DialogContent className="ml32ModalPerceptions mr32ModalPerceptions">
            <div className="flexModalPerceptions mb24ModalPerceptions">
              <span style={{ textAlign: "center", color: "#222222" }}>
                Acepto que la información mostrada es correcta y no necesita
                modificaciones
              </span>
            </div>
          </DialogContent>
          <DialogActions>
            <div className="contenedorBotonesPoliticsModal">
              <button
                autoFocus
                onClick={handleClose}
                className="botonbotonCancelar cursor"
              >
                Cancelar
              </button>
              <button
                autoFocus
                onClick={handleAcceptInfo}
                className="botonbotonGuardar botonDoble cursor"
              >
                <div className="contenedorIconoBotonModal"></div>
                <div>
                  {" "}
                  <img src={shapeBlueCheck} alt="" /> Aceptar
                </div>
              </button>
            </div>
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
};

export default AcceptInfoModal;
