import { Grid } from '@material-ui/core';
import { styleCollaboratorTab as style } from './styleSheet';

interface IAddTable {
    func?: any;
    img?: string;
    text?: string;
    noIcon?: boolean;
}
const AddTable = ({ func, img = "icono-agregar-nuevo.svg", text = "AGREGAR", noIcon }: IAddTable) => {
    return (
        <button className="buttonContent" onClick={func}>
            <Grid
                container
                direction="row"
                style={{
                    ...style.containerFilter,
                    margin: "0",
                    width: "auto"
                }}
                justify="space-around"
                className="btnY"
            >
                {
                    !noIcon && (
                        <Grid item
                            style={{
                                margin: "10px 6px 10px 20px"
                            }}
                        >
                            <img src={`/assets/icons/${img}`} alt="Descargar Reporte" />
                        </Grid>
                    )
                }

                <Grid
                    item
                    style={{
                        alignSelf: "center",
                        fontSize: "14px",
                        margin: !noIcon ? "10px 20px 10px 0px" : "10px 20px 10px 20px",
                        color: "#093c5d",
                        fontWeight: "bold",
                        width: "auto"
                    }}
                >
                    {text}
                </Grid>

            </Grid>
        </button>
    )
}

export default AddTable
