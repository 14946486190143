import {useContext} from "react";
import {  Dialog } from "@material-ui/core"
import '../Payroll.css'
import styles from './PayrollModals.module.css'
import PayrollProcessContext from "../../../context/PayrollProcess/PayrollProcessContext";
import { clearIncidentPeriodicProcess } from "../../../context/PayrollProcess/Actions";
import { APPROVE_PAYROLL_INCIDENTPERIODIC, GET_ALL_PAYROLL_INCIDENTPERIODIC } from "../../../Querys/querys";
import { useMutation } from "@apollo/client";


const ApproveIncidentPeriodic = (props: any) =>{
    const {state, dispatch} = useContext(PayrollProcessContext);

    const [approvePayroll] = useMutation(APPROVE_PAYROLL_INCIDENTPERIODIC, {
      refetchQueries: [{ query: GET_ALL_PAYROLL_INCIDENTPERIODIC }],
    });

    const handleApprove = async () =>{ 
      approvePayroll({
        variables:{
          approvePayrollIncidentperiodicId:state._id
        }
      })
      handleClose()
    }

    const handleClose = () =>{
        clearIncidentPeriodicProcess({}, dispatch)
    }

    
    return(
      <Dialog aria-labelledby='costumized-dialog-title' open={state.showApproveIncidentPeriodic} fullWidth={false}  onClose={handleClose} maxWidth={"md"}>
        <div className={styles.em_container}>
          <div className={styles.contenedorCerrarModal}>
            <div 
              className={styles.cerrarModal}
              onClick={()=> handleClose()}>
            </div>
          </div>
          <div className={styles.contenedorPrincipal}>
            <div className={styles.fim_iconoPrincipal}>
              <div className={styles.fim_iconoComplemento}></div>
            </div>
            <div className={styles.em_titulo}>
              <div className={styles.tituloAtencion}>
                ¡Atención!
              </div>
              ¿Est&aacute;s seguro de que quieres aprobar la incidencia?
            </div>
          </div>
        </div>
        <div className={styles.em_contenedorBotones}>
              <button 
                className={styles.em_botonCancelar}
                onClick={()=> handleClose()}
              >
                Cancelar
              </button>
              <button 
                className={styles.em_botonConfirmarAction}
                onClick={()=> handleApprove()}
                >
                <div></div>
                  <div>
                    Confirmar
                  </div>
              </button>
            </div>
      </Dialog>
    )
}

export default ApproveIncidentPeriodic