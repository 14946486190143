import { useContext, Fragment } from "react";
import { TextField, Tooltip, Grid, Button } from "@material-ui/core";
import Box from '@mui/material/Box';
import { withStyles, Theme } from "@material-ui/core/styles";
import CollaboratorContext from "../../../../context/CollaboratorContext/CollaboratorContext";
import {
  handleSetActiveStep,
  updateData,
} from "../../../../helpers/Collaborator/Collaborator";
import { SuccessfulAlert } from "../../../../alerts/successAlerts";
import SaveIcon from "@material-ui/icons/Save";
import { useMutation } from "@apollo/client";
import { GET_USERS_BY_ID, UPDATE_USERS, GET_ALL_USERS_NUEVOINGRESO } from "../../../../Querys/querys";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Form } from "semantic-ui-react";
import CheckPermission from "../../../../helpers/Administradores/Permissions";
import { useHistory } from "react-router-dom";

const HtmlTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    maxWidth: 1000,
    fontSize: theme.typography.pxToRem(14),
  },
}))(Tooltip);


const EnfermedadNuevoIngreso = () => {
  const { state, dispatch } = useContext(CollaboratorContext);
  const history = useHistory();

  const [updateColaboradores] = useMutation(UPDATE_USERS, {
    refetchQueries: [
      {
        query: GET_USERS_BY_ID,
        variables: { getUsersId: state.collaborator.id },
      },
      {query: GET_ALL_USERS_NUEVOINGRESO}
    ],
  });

  const handleChange = async (e: any) => {
    await updateData(e, state, dispatch, 0);
  };

  const initialValues = () => {
    return {
      Alergias: state.collaborator?.Alergias ?? "",
      PadEnfer: state.collaborator?.PadEnfer ?? "",
      Cirugias: state.collaborator?.Cirugias ?? "",
      TraMeRe: state.collaborator?.TraMeRe ?? "",
    };
  };

  const formik = useFormik({
    initialValues: initialValues(),
    validationSchema: Yup.object(validationSchema()),

    onSubmit: async (formData) => {
      updateColaboradores({
        variables: {
          updateUsersId: state.collaborator?.id,
          input: formData,
          process: {
            tab: "emergency",
            section: "section_3",
          },
        },
      }).then((res) => {
        SuccessfulAlert({ text: "Se actualizó correctamente" }).then(() => {
          history.push("4");
          handleSetActiveStep(0, dispatch);
        });
      });
    },
  });

  return (
    <Form onSubmit={formik.handleSubmit}>
      <div className="novalidate__border">
        <h6 id="form-dialog-title" className="form-dialog-title-class">
          Declaro a continuación mi estado de salud actual…
          <HtmlTooltip
            title={
              <Fragment>
                En caso de no contar con alguno escribe 'Ninguno'
              </Fragment>
            }
          >
            <img
              src="/assets/icons/PreguntaAzul.png"
              alt="Question"
              height="15"
            />
          </HtmlTooltip>
        </h6>
        <Box mt={2} mb={2}>
          <TextField
            name="Alergias"
            defaultValue={state.collaborator?.Alergias}
            autoFocus={true}
            label="Alergías"
            variant="outlined"
            size="small"
            fullWidth={true}
            onBlur={(e) => handleChange(e)}
            InputProps={{
              readOnly: false,
            }}
            helperText={formik.touched.Alergias && formik.errors.Alergias}
            error={formik.touched.Alergias && Boolean(formik.errors.Alergias)}
            onChange={formik.handleChange}
          />
        </Box>

        <Box mt={2} mb={2}>
          <TextField
            name="PadEnfer"
            defaultValue={state.collaborator?.PadEnfer}
            autoFocus={true}
            label="Padecimientos / Enfermedades físicos y mentales"
            variant="outlined"
            size="small"
            fullWidth={true}
            onBlur={(e) => handleChange(e)}
            InputProps={{
              readOnly: false,
            }}
            helperText={formik.touched.PadEnfer && formik.errors.PadEnfer}
            error={formik.touched.PadEnfer && Boolean(formik.errors.PadEnfer)}
            onChange={formik.handleChange}
          />
        </Box>

        <Box mt={2} mb={2}>
          <TextField
            name="Cirugias"
            defaultValue={state.collaborator?.Cirugias}
            autoFocus={true}
            label="Cirugías"
            variant="outlined"
            size="small"
            fullWidth={true}
            onBlur={(e) => handleChange(e)}
            InputProps={{
              readOnly: false,
            }}
            helperText={formik.touched.Cirugias && formik.errors.Cirugias}
            error={formik.touched.Cirugias && Boolean(formik.errors.Cirugias)}
            onChange={formik.handleChange}
          />
        </Box>

        <Box mt={2} mb={2}>
          <TextField
            name="TraMeRe"
            defaultValue={state.collaborator?.TraMeRe}
            autoFocus={true}
            label="Tratamientos médicos o rehabilitaciones"
            variant="outlined"
            size="small"
            fullWidth={true}
            onBlur={(e) => handleChange(e)}
            InputProps={{
              readOnly: false,
            }}
            helperText={formik.touched.TraMeRe && formik.errors.TraMeRe}
            error={formik.touched.TraMeRe && Boolean(formik.errors.TraMeRe)}
            onChange={formik.handleChange}
          />
        </Box>
      </div>
      {CheckPermission(
        "Colaboradores.NuevoIngreso.Ver.SaludEmergencias.InformacionMedica.Guardar"
      ) && (
        <Grid
          direction="row"
          container
          justify="flex-end"
          alignItems="center"
          style={{ marginTop: "20px" }}
        >
          <Button type="submit" className="buttonSave">
            <SaveIcon />
            &nbsp; Guardar
          </Button>
        </Grid>
      )}
    </Form>
  );
};

const validationSchema = () => {
  return {
    Alergias: Yup.string().required("Obligatorio"),
    PadEnfer: Yup.string().required("Obligatorio"),
    Cirugias: Yup.string().required("Obligatorio"),
    TraMeRe: Yup.string().required("Obligatorio"),
  };
};

export default EnfermedadNuevoIngreso;
