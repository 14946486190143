import { useState } from "react";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { TableHead } from "@material-ui/core";
import { formatterMxn } from "../../helpers/formatoMoneda";

function TablePaginationActions(props: any) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event: any) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event: any) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event: any) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event: any) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

export default function TableComponent({ rows }: any) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
        <TableHead>
          <TableRow>
            <TableCell>
              {" "}
              <strong> NSS </strong>{" "}
            </TableCell>
            <TableCell>
              {" "}
              <strong> Nombre </strong>{" "}
            </TableCell>
            <TableCell>
              {" "}
              <strong> Días </strong>{" "}
            </TableCell>
            <TableCell>
              {" "}
              <strong> Salario Diario </strong>{" "}
            </TableCell>
            <TableCell>
              {" "}
              <strong> Cuota Fija </strong>{" "}
            </TableCell>
            <TableCell>
              {" "}
              <strong> Exedente Patronal </strong>{" "}
            </TableCell>
            <TableCell>
              {" "}
              <strong> Exedente Obrero </strong>{" "}
            </TableCell>
            <TableCell>
              {" "}
              <strong> Prestaciones en Dinero Patronal </strong>{" "}
            </TableCell>
            <TableCell>
              {" "}
              <strong> Prestaciones en Dinero Obrero </strong>{" "}
            </TableCell>
            <TableCell>
              {" "}
              <strong> Gastos Medicos y Pensionados Patronal </strong>{" "}
            </TableCell>
            <TableCell>
              {" "}
              <strong> Gastos Medicos y Pensionados Obrero </strong>{" "}
            </TableCell>
            <TableCell>
              {" "}
              <strong> Riesgos de Trabajo </strong>{" "}
            </TableCell>
            <TableCell>
              {" "}
              <strong> Invalidez y Vida Patronal </strong>{" "}
            </TableCell>
            <TableCell>
              {" "}
              <strong> Invalidez y Vida Obrero </strong>{" "}
            </TableCell>
            <TableCell>
              {" "}
              <strong> Guarderias y Prestaciones Sociales </strong>{" "}
            </TableCell>
            <TableCell>
              {" "}
              <strong> Total </strong>{" "}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(rowsPerPage > 0
            ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : rows
          ).map((row: any, key: number) => (
            <TableRow key={key}>
              <TableCell>{row.NSS}</TableCell>
              <TableCell>{row.Nombre}</TableCell>
              <TableCell>{row.Dias}</TableCell>
              <TableCell>
                {formatterMxn(row.SalarioDiario) === "nan"
                  ? "$0.00"
                  : formatterMxn(row.SalarioDiario)}
              </TableCell>
              <TableCell>
                {formatterMxn(row.CuotaFija) === "nan"
                  ? "$0.00"
                  : formatterMxn(row.CuotaFija)}
              </TableCell>
              <TableCell>
                {formatterMxn(row.ExcedentePatronal) === "nan"
                  ? "$0.00"
                  : formatterMxn(row.ExcedentePatronal)}
              </TableCell>
              <TableCell>
                {formatterMxn(row.ExcedenteObrero) === "nan"
                  ? "$0.00"
                  : formatterMxn(row.ExcedenteObrero)}
              </TableCell>
              <TableCell>
                {formatterMxn(row.PrestacionesenDineroPatronal) === "nan"
                  ? "$0.00"
                  : formatterMxn(row.PrestacionesenDineroPatronal)}
              </TableCell>
              <TableCell>
                {formatterMxn(row.PrestacionesenDineroObrero) === "nan"
                  ? "$0.00"
                  : formatterMxn(row.PrestacionesenDineroObrero)}
              </TableCell>
              <TableCell>
                {formatterMxn(row.GastosMedicosyPensionadosPatronal) === "nan"
                  ? "$0.00"
                  : formatterMxn(row.GastosMedicosyPensionadosPatronal)}
              </TableCell>
              <TableCell>
                {formatterMxn(row.GastosMedicosyPensionadosObrero) === "nan"
                  ? "$0.00"
                  : formatterMxn(row.GastosMedicosyPensionadosObrero)}
              </TableCell>
              <TableCell>
                {formatterMxn(row.RiesgodeTrabajo) === "nan"
                  ? "$0.00"
                  : formatterMxn(row.RiesgodeTrabajo)}
              </TableCell>

              <TableCell>
                {formatterMxn(row.InvalidezyVidaPatronal) === "nan"
                  ? "$0.00"
                  : formatterMxn(row.InvalidezyVidaPatronal)}
              </TableCell>

              <TableCell>
                {formatterMxn(row.InvalidezyVidaObrero) === "nan"
                  ? "$0.00"
                  : formatterMxn(row.InvalidezyVidaObrero)}
              </TableCell>

              <TableCell>
                {formatterMxn(row.GuarderiasyPrestacionesSociales) === "nan"
                  ? "$0.00"
                  : formatterMxn(row.GuarderiasyPrestacionesSociales)}
              </TableCell>
              <TableCell>
                {formatterMxn(row.Total) === "nan"
                  ? "$0.00"
                  : formatterMxn(row.Total)}
              </TableCell>
            </TableRow>
          ))}

          {emptyRows > 0 && (
            <TableRow style={{ height: 53 * emptyRows }}>
              <TableCell colSpan={6} />
            </TableRow>
          )}
        </TableBody>
      </Table>
      <TableFooter>
        <TableRow>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, { label: "Todos", value: -1 }]}
            colSpan={1}
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            labelRowsPerPage="Filas por página"
            SelectProps={{
              native: true,
            }}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
          />
        </TableRow>
      </TableFooter>
    </TableContainer>
  );
}
