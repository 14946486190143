

export default function CalendarName ({name}:any){
    let nameWithSpace= ''
    let i2 = 0
    for(let i = 0; i<name.length; i++){
        if(name[i] === " "){
            i2 = 0

        }

        if(i2 === 15){
            
                nameWithSpace = nameWithSpace + " "
                nameWithSpace = nameWithSpace + name[i]

            i2 = 0
        }else{
            nameWithSpace = nameWithSpace + name[i]
            i2++
        }
        
    }


    return (
        <p
        style={{
            marginBottom: "3.38px",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
        }}
        >{nameWithSpace}</p> 

    )

}