import { useContext, useState } from "react";
import { Grid, IconButton, Menu, MenuItem, Tooltip } from "@material-ui/core";
import Box from '@mui/material/Box';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { closeModal, openModal } from "../../Team/Modals/Modal";
import ModalContext from "../../../context/ModalContext/ModalContext";
import CheckPermission from '../../../helpers/Administradores/Permissions';
import { DELETE_NOTIFICATION_MOBILE, GET_NOTIFICATIONS_MOBILE } from '../../../Querys/querys';
import { useMutation } from "@apollo/client";
import { ErrorAlert } from "../../../alerts/errorAlert";
import { WarningAlert } from "../../../alerts/WarningAlert";

const MenuList = (props: any) => {
    const [anchorEl, setAnchorEl] = useState<any>(null);
    const open = Boolean(anchorEl);
    const { dispatch } = useContext(ModalContext);
    const [deleteNotification] = useMutation(DELETE_NOTIFICATION_MOBILE,{
        refetchQueries: [
            { query: GET_NOTIFICATIONS_MOBILE }
        ],
    })

    const onDelete = async () => {
        try{
            let response = await WarningAlert({ text: "¿Deseas eliminar la notificación?", showDenyButton: true, confirmButtonText: "Eliminar" });
            if (response.isConfirmed) {
                await deleteNotification({
                    variables:{
                        id: props.id
                    }
                });
                await closeModal(dispatch);
                setAnchorEl(null);
            }
        }catch(e){
            ErrorAlert({text:"Ocurrió un error al eliminar la notificación"});
        }
    }
    const onEditNotification = () => {
        openModal({ _id: props.id }, dispatch);
        setAnchorEl(null);
    }
    return (
        <>
            <Tooltip title="Editar" placement="right">
                <Box className="IconButtonPoints" mr={2}>
                    <IconButton onClick={(e) => setAnchorEl(e.currentTarget)}>
                        <MoreVertIcon style={{ color: "#fabb00" }} />
                    </IconButton>
                </Box>
            </Tooltip>
            <Menu
                className="MoreVerIcon"
                anchorEl={anchorEl}
                open={open}
                transformOrigin={{ vertical: "top", horizontal: "right" }}
                onClose={() => setAnchorEl(null)}
            >
                            {CheckPermission("Notificaciones.Reenviar") && (
                            <MenuItem divider button onClick={onEditNotification}>
                        Reenviar&nbsp;
                        <Grid container item justify="flex-end">
                            <img src={`/assets/svg/icono-reenviar.svg`} alt="Reenviar" />
                        </Grid>
                    </MenuItem>
                            )}
                            {CheckPermission("Notificaciones.Editar") && (
                            <MenuItem divider button onClick={onEditNotification}>
                        Editar&nbsp;
                        <Grid container item justify="flex-end">
                            <img src={`/assets/svg/icono-Editar.svg`} alt="Editar" />
                        </Grid>
                    </MenuItem>
                            )}
                            {CheckPermission("Notificaciones.Eliminar") && (
                <MenuItem button onClick={() => onDelete()}>
                    Eliminar&nbsp;
                    <Grid container item justify="flex-end">
                        <img src="/assets/svg/icono-eliminar.svg" alt="Eliminar" />
                    </Grid>
                </MenuItem>
                )}
            </Menu >
        </>
    )
}

export default MenuList;