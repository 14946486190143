/* eslint-disable array-callback-return */
import { useContext,useState,useEffect, } from "react";
import { useQuery,useMutation } from "@apollo/client";
import CampaignContext from "../../../context/CampaignsContext/CampaignContext";
import {clearCampaignsModal} from "../../../context/CampaignsContext/Actions"
import {CREATE_CAMPAIGN,GET_ALL_CAMPAIGNS} from "../../../Querys/campaigns/querys";
import {GET_ALL_PROJECT, GET_ALL_SEDE } from "../../../Querys/querys";     
import {
     Dialog,
     DialogTitle,
     DialogContent,  
     TextField,
     FormControl,
     FormHelperText,
     InputLabel,
     Select ,
     Checkbox,
     ListItemText,
     MenuItem ,     
  } from '@material-ui/core';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Form } from "semantic-ui-react";
import Add from "../../../assets/svg/addPrestacion.svg";
import { useFormik } from "formik";
import GreenSwitchPrestacion from "../../Prestaciones/modals/GreenSwitchPrestacion";
import * as Yup from "yup";
import { SuccessfulAlert } from "../../../alerts/successAlerts";
import { ErrorAlert } from "../../../alerts/errorAlert";
import DateRangeField from "../../MyMood/Fields/DateRangeField";
import { addDays,subMonths,format,isEqual,parseISO  } from 'date-fns';
import "../css/ModalCampaign.css"

const CreateDuplicateCampaigns = () => {
  const {state, dispatch} = useContext(CampaignContext);    
  const [sedes, setSedes] = useState<any>([])
  const [projects, setProjects] = useState<Array<any>>([]);
  const [selectedAllSedes, setSelectedAllSedes] = useState<String[]>([]);
  const [selectedAllProjects, setSelectedAllProjects] = useState<String[]>([]); 
  const [campaignBond,setCampaignBond]=useState(false)
  const [stateCalendario, setStateCalendario] = useState([
    {
      startDate: subMonths(new Date(), 0),
      endDate: addDays(new Date(), 0),
      key: "selection"
    }]
  );
  const[initialValuesForm, setInitialValuesForm] = useState({
    nameCampaign:"",
    sedes:"",
    areas: "",
    minObjectives: "",
    maxObjectives: "",
    minQualification: "",
    descriptionBond: "",
    descriptionCampaign: ""
  })
  const [startDate, setStartDate] = useState(subMonths(new Date(),0).toString())
  const [endDate, setEndDate] = useState(subMonths(new Date(), 0).toString())
  const { data: resultSede } = useQuery(GET_ALL_SEDE);
  const allSede = resultSede?.GET_ALL_SEDE;
  const { data: resultProject } = useQuery(GET_ALL_PROJECT);
  const allProject = resultProject?.GET_ALL_PROJECT;
      

  const validationSchema = () =>{
    return{
        nameCampaign: Yup.string().required("Obligatorio"),
        sedes: Yup.array().required("Obligatorio").test("sedes", "Es necesario seleccionar un proyecto", function(value){
          if((value === undefined || value.length === 0)){
              return false;
          }else{
              return true;
          }
         }),       
         areas: Yup.array().required("Obligatorio").test("areas", "Es necesario seleccionar una sede", function(value){
          if((value === undefined || value.length === 0)){
              return false;
          }else{
              return true;
          }        
          }),
          minObjectives: Yup.number().required("Obligatorio")
                            .positive("Tiene que ser un número positivo")
                            .min(0,"Debe ser un número positivo")
                            .max(99,"No puede ser mayor a 99"),
          maxObjectives: Yup.number().required("Obligatorio")
                            .positive("Tiene que ser un número positivo")
                            .max(99,"No puede ser mayor a 99"),
          minQualification: Yup.number().required("Obligatorio")
                            .positive("Tiene que ser un número positivo")
                            .max(100,"No puede ser mayor a 100"),  
          descriptionBond: Yup.string().required("Obligatorio")          
    }
  }

  const [createCampaign] = useMutation(CREATE_CAMPAIGN, {
    refetchQueries: [
        { query: GET_ALL_CAMPAIGNS }
    ],
  });



   useEffect(() => {
      if(allSede){
        setSedes(allSede)
      }
  }, [allSede]);    

  useEffect(() => {
    if (allProject){ 
       setProjects(allProject);}
}, [allProject]);

  const onChangeLocation = (e:any) => {
    if(e.target.value.indexOf('Todas') > -1){
        if(sedes.length === selectedAllSedes.length){
            setSelectedAllSedes([]);
        }else{
            let filtered = sedes.map((item:any)=> item.sedeName);
            setSelectedAllSedes(filtered);
        }
    }else{
        setSelectedAllSedes(e.target.value);
    }
   }

   const onChangeProject = (e:any) => {
      if(e.target.value.indexOf('Todas') > -1){
        if(projects.length === selectedAllProjects.length){
            setSelectedAllProjects([]);
        }else{
          let filtered = projects.map((item:any)=> item.proyectName);
             setSelectedAllProjects(filtered);
        }
    }else{
      setSelectedAllProjects(e.target.value);      
    }
}

   const resetStates=()=>{
        setSelectedAllSedes([]);
        setSelectedAllProjects([]);            
        setCampaignBond(false)      
    }
 
    useEffect(()=>{
      const arraySedes: any=[];
      state.campaign?.sedes.map((sede:any)=>{
        arraySedes.push(sede?.sede)
      })
      setSelectedAllSedes(arraySedes)
      const arrayProjects: any=[];
      state.campaign?.areas.map((area:any)=>{
        arrayProjects.push(area.area)
      })
      setSelectedAllProjects(arrayProjects)
      setInitialValuesForm({
        nameCampaign:state.campaign?.nameCampaign,
        sedes:arraySedes,
        areas: arrayProjects,
        minObjectives: state.campaign?.minObjectives,
        maxObjectives: state.campaign?.maxObjectives,
        minQualification: state.campaign?.minQualification,
        descriptionBond: state.campaign?.descriptionBond,
        descriptionCampaign: state.campaign?.descriptionCampaign
      })
      const bolCmapaignBond = state.campaign?.campaignBond ==='true'?true:false
      setCampaignBond(bolCmapaignBond)
      setStartDate(addDays(new Date(state.campaign?.startCampaign),1).toString())
      setEndDate(addDays(new Date(state.campaign?.endCampaign),1).toString())
    },[state.createDuplicateCampaigns])
  

    const formik = useFormik({
      initialValues: initialValuesForm,
      enableReinitialize: true,
      validationSchema: Yup.object(validationSchema()),
      onSubmit: async (formData, { resetForm }) =>{
        
      const initDate:any = format(stateCalendario[0].startDate, 'yyyy-MM-dd')
      const endDate:any  = format(stateCalendario[0].endDate, 'yyyy-MM-dd')
      const dateEqual = isEqual(parseISO(initDate) , parseISO(endDate))
      console.log('calendario', stateCalendario)
      console.log('Fecha Inicio', parseISO(initDate), parseISO(endDate))

      const  variablesCampaign={...formData,
                        campaignBond, 
                        sedes:selectedAllSedes,
                        areas:selectedAllProjects,
                        startCampaign: initDate,
                        endCampaign: endDate
                      } 
           try{
              console.log('Hay un error', variablesCampaign)
              let { data } = await createCampaign({
                variables: {
                  input: variablesCampaign,
                },
              });                       
            setSelectedAllSedes([]);
            setSelectedAllProjects([]);            
            setCampaignBond(false)   
            resetForm()
            //clearCampaignsModal(false, dispatch)  
            //console.log(data)     
            SuccessfulAlert({ text: "Campaña creada correctamente." });    
           }catch(e){
            ErrorAlert({text:'Ocurrió un error al crear la campaña'});
            console.log("error")
          }
        }          
    });

    // console.log('Este es el valor', campaignBond)
  return (
    <>
    <Dialog open={state.createDuplicateCampaigns} 
       aria-labelledby="form-dialog-title" 
     >
      <IconButton aria-label="close" onClick={(()=> {  formik.resetForm();
                                                          resetStates();
                                                          clearCampaignsModal(false, dispatch)} )}
        sx={{position: 'absolute', right: 8, top: 8,color: (theme) => theme.palette.grey[500], }}
      >
      <CloseIcon />
      </IconButton>     
     <DialogContent  >
       <Form onSubmit={formik.handleSubmit} className="containerMdalPrestacion" >
         <DialogTitle ><span className="titlePrestacion">Crear campaña</span></DialogTitle> 
         <DialogContent className="oneComponent">
            <TextField
                      type="text"
                      name="nameCampaign"
                      label="Nombre de la campaña*"
                      variant="outlined"
                      size="small"
                      fullWidth={true}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.nameCampaign}
                      inputProps={{ maxLength: 100 }}
                      onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                      error={formik.touched.nameCampaign && Boolean(formik.errors.nameCampaign)}
                      helperText={formik.touched.nameCampaign && formik.errors.nameCampaign} 
                    />         
         </DialogContent>
        <DialogContent className="twoComponent" >
                <FormControl 
                   fullWidth 
                   size="small"
                   variant="outlined" 
                   className="question" 
                  >
                 <InputLabel id="demo-simple-select-label"  style={{backgroundColor: "#FFFFFF"}}>
                   Sede*
                  </InputLabel>
                  <Select
                   style={{
                    textOverflow: "ellipsis" ,
                    whiteSpace: "nowrap",
                    overflow: "hidden",   
                    width:"220px"  , 
                    height:"40px"                            
                    }}     
                     value={selectedAllSedes}
                     onChange={(e) => {
                               formik.handleChange(e);
                                onChangeLocation(e);
                               }
                              }
                     labelId="demo-simple-select-label"
                     id="sedes"
                     name = "sedes"
                     multiple
                     renderValue={(selected:any) => selected.join(', ')}
                     error={formik.touched.sedes && Boolean(formik.errors.sedes)}
                    >
                     <MenuItem key={0} value={'Todas'}>
                        <Checkbox checked={selectedAllSedes.length === sedes.length} style ={{
                                      color: "#FABB00",
                                    }} />
                        <ListItemText primary={"Todas"} />
                        </MenuItem>
                            {
                              sedes.map((item:any) => (
                               <MenuItem key={item.id} value={item.sedeName}>
                                <Checkbox 
                                     checked={selectedAllSedes.indexOf(item.sedeName) > -1} 
                                     style ={{
                                      color: "#FABB00",
                                    }}
                                     />
                                  <ListItemText primary={item.sedeName} />
                                </MenuItem>
                              ))
                            }                                           
                        </Select>
                          <FormHelperText style={{color: "red"}}>
                             {formik.touched.sedes && formik.errors.sedes}
                          </FormHelperText>
                    </FormControl>               
                   <FormControl 
                    fullWidth 
                    size="small"
                    variant="outlined" 
                    className="question" 
                     error={formik.touched.areas && Boolean(formik.errors.areas)}
                     >
                    <InputLabel id="demo-simple-select-label"
                     style={{ backgroundColor: "#FFFFFF"}}
                    >&Aacute;rea *</InputLabel>
                    <Select
                     style={{
                      textOverflow: "ellipsis" ,
                      whiteSpace: "nowrap",
                      overflow: "hidden",   
                      width:"232px"  , 
                      height:"40px"                            
                      }}     
                      value={selectedAllProjects}
                      onChange={
                              (e) => {
                                   formik.handleChange(e);
                                    onChangeProject(e);
                                    }
                               }
                      labelId="demo-simple-select-label"
                      id="areas"
                      name = "areas"
                      multiple
                      renderValue={(selected:any) => selected.join(',')}
                      error={formik.touched.areas && Boolean(formik.errors.areas)}
                    >
                    <MenuItem key={0} value={'Todas'}>
                     <Checkbox 
                          checked={selectedAllProjects.length === projects.length} 
                          style ={{
                            color: "#FABB00",
                          }}
                          />
                       <ListItemText primary={"Todas"} />
                       </MenuItem>
                       {
                         projects.map((project:any) => (
                          <MenuItem key={project.id} value={project.proyectName}>
                           <Checkbox checked={selectedAllProjects.indexOf(project.proyectName) > -1} style ={{
                                      color: "#FABB00",
                                    }}/>
                           <ListItemText primary={project.proyectNameAndClient} />
                           </MenuItem>
                          ))
                      }
                      </Select>
                      <FormHelperText style={{color: "red"}}>
                       {formik.touched.areas && formik.errors.areas}
                      </FormHelperText>
                   </FormControl>               
        </DialogContent>      
         <DialogContent className="twoComponent">
            <TextField
                      type="number"
                      name="minObjectives"
                      label="Mínimo de objetivos *"
                      variant="outlined"
                      size="small"                      
                      onBlur={formik.handleBlur}
                      defaultValue={state.campaign?.minObjectives}
                      onChange={formik.handleChange}                     
                      onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                     error={formik.touched.minObjectives && Boolean(formik.errors.minObjectives)}
                      helperText={formik.touched.minObjectives && formik.errors.minObjectives} 
                      inputProps={{
                        maxLength: 100,
                        min: 0,
                        max:99,
                        style: {
                          width: "120px",
                        },
                      }}
                      InputLabelProps={{style: {fontSize: 12}}}
                    />  
              <TextField
                      type="number"
                      name="maxObjectives"
                      label="Máximo de objetivos *"
                      variant="outlined"
                      size="small"                    
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}      
                      defaultValue={state.campaign?.maxObjectives}              
                      onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                      error={formik.touched.maxObjectives && Boolean(formik.errors.maxObjectives)}
                      helperText={formik.touched.maxObjectives && formik.errors.maxObjectives} 
                      inputProps={{
                        maxLength: 100,
                        min: 0,
                        max:99,
                        style: {
                          width: "120px",
                        },
                      }}
                      InputLabelProps={{style: {fontSize: 12}}}
                    />   
               <TextField
                      type="number"
                      name="minQualification"
                      label="Calificación mínima *"
                      variant="outlined"
                      size="small"                     
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}    
                      defaultValue={state.campaign?.minQualification}                                    
                      onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                      error={formik.touched.minQualification && Boolean(formik.errors.minQualification)}
                      helperText={formik.touched.minQualification && formik.errors.minQualification} 
                      inputProps={{
                        maxLength: 100,
                        min: 0,
                        max:100,
                        style: {                          
                          width: "120px",
                        },
                      }}
                      InputLabelProps={{style: {fontSize: 12}}}
                    />         
            </DialogContent>                
            <DialogContent className="twoComponent">
                <div className="twoComponentBono">
                  <div className="bonoCampaign"><span className="spanBonoCampaign">Bono de Campaña</span></div>
                  <GreenSwitchPrestacion  valor={campaignBond} setValor={setCampaignBond} />               
                </div>
                <div className="contTxtBono">
                 <TextField
                      type="text"
                      name="descriptionBond"
                      label="Escribir Bono*"
                      variant="outlined"
                      size="small"
                      fullWidth={true}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}                     
                      defaultValue={state.campaign?.descriptionBond}
                      onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                      error={formik.touched.descriptionBond && Boolean(formik.errors.descriptionBond)}
                      helperText={formik.touched.descriptionBond && formik.errors.descriptionBond} 
                      inputProps={{
                        maxLength: 120,
                        style: {                          
                          width: "120px",
                        },
                      }}  
                    />             
                   </div> 
            </DialogContent>
            <DialogContent className="oneComponent">
            <DateRangeField setDate={setStateCalendario} 
                              containerClass={"fieldsetInput"}                             
                              daySelected1={startDate} 
                              daySelected2={endDate}                         
                              >
              </DateRangeField>
            </DialogContent> 
            <DialogContent className="oneComponent">
              <TextField
                  id="outlined-multiline-static"
                  label="Descripción de la campaña"
                  multiline
                  defaultValue={state.campaign?.descriptionCampaign}                 
                  name="descriptionCampaign"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  fullWidth={true}
                  rows={3}
                  variant="outlined"
                  inputProps={{ maxLength: 100 }}   
                /> 
              </DialogContent>
              <DialogContent className="twoComponentBtns">
              <div className="containerButtons">
                <div className="containerCancelPreestacion">
                    <button className="btnCancelPrestacion" type="button" 
                    onClick={(() =>{
                        formik.resetForm();
                        resetStates();
                        clearCampaignsModal(false, dispatch); 
                            }
                            )}>
                        Cancelar
                    </button>             
                </div>    
              <div className="containerAddPrestacion">
                <button className="btnAddPrestacion" type="submit" >
                      <div className="infobtnAddRestriccion">
                        <div><img src={Add} alt="add"/></div>            
                        <div><span className="txtBtnAdd">Crear Campaña </span> </div> 
                     </div>                   
              </button>             
            </div>    
          </div> 
              </DialogContent>              
           </Form>                  
        </DialogContent>       
    </Dialog>        
    </>  
  )
}

export default CreateDuplicateCampaigns

