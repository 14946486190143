/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { MouseEvent, useState, useEffect } from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import _ from 'lodash';
import THeaderBits from './THeaderBits';
import { TBColumn, TBData, TBOrder, TBuseStyles } from '../../interfaces/TableBits.interfaces';

import './tableBits.css';
import CheckPermission from '../../helpers/Administradores/Permissions';

interface Props {
  className?: string,
  options?: boolean;
  children?: JSX.Element;
  rows: any[];
  columns: TBColumn[];
  components?: Array<any>;
  componentOptions?: any;
  updatePaginationObj?: (paginationObj: any) => any
  getData?: (paginationObj: any) => Promise<any>,
  paginationObj?: any,
  totalRecords?: number,
  skipPermission?: boolean,
  skipSort?: boolean,
  customInitialOrderBy?: string,
  customInitialOrder?: TBOrder,
  customRowsPerPage?: number,
  updatePageNumber?: number
};

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator<Key extends keyof any>(
  order: TBOrder,
  orderBy: Key,
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  if (!!!array) return [];
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const TableBits = ({
  className,
  options = true,
  rows = [],
  columns = [],
  components = [],
  componentOptions: ComponentOptions,
  updatePaginationObj,
  getData,
  paginationObj = {},
  totalRecords,
  skipPermission = false,
  skipSort = false,
  customInitialOrderBy,
  customInitialOrder,
  customRowsPerPage,
  updatePageNumber,
}: Props) => {
  const classes = TBuseStyles();
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState<TBOrder>(customInitialOrder ?? 'asc');
  const [orderBy, setOrderBy] = useState<keyof TBData>(customInitialOrderBy ?? columns[0].id);
  const [rowsPerPage, setRowsPerPage] = useState(customRowsPerPage ?? 10);
  const [newColumns, setNewColumns] = useState(columns);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (updatePageNumber !== page) setPage(updatePageNumber ?? 0);
  }, [updatePageNumber]);

  useEffect(() => {
    if (options) {

      let newData = columns;
      const existOptions = newData.find(col => col.type === 'options')

      if (!existOptions) {
        newData.push({
          id: "OptionsBITS",
          label: 'Opciones',
          align: 'right',
          type: 'options',
        })
      } else {
        const isOptions = (col: TBColumn) => col.type === 'options' ? { ...existOptions, ...col } : col;
        newData = columns.map(isOptions);
      };      
      setNewColumns(newData);
    }

    setLoading(false);

  }, [columns, options]);

  useEffect(() => {
    const pagination = {
      ...paginationObj,
      pageNumber: page,
      rowsOfPage: rowsPerPage,
      order,
      orderBy
    };
    getRows(pagination).then((data: any) => {
      return;
    }).catch((err: any) => {
    });
  }, [page, rowsPerPage, order, orderBy])

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    updatePagination({
      pageNumber: newPage
    });
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    updatePagination({
      rowsOfPage: +event.target.value,
      pageNumber: 0
    });

  };

  const handleRequestSort = (event: MouseEvent<unknown>, property: keyof TBData) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    updatePagination({
      order: isAsc ? 'desc' : 'asc',
      orderBy: property,
    });
  };

  const updatePagination = (paginationObj: any): any => {
    if (updatePaginationObj) {
      updatePaginationObj(paginationObj);
    }
  }

  const _getRows = (): any => {
    if (skipSort) return rows;

    let data = stableSort(rows, getComparator(order, orderBy))
      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    if (getData) {
      data = rows;
    }
    
    // if( newColumns[0].id ==='bussinesName' && newColumns[1].id ==='campus') {
    //   const copyData = [...data]
    //   const arrayData:any= []
    //   copyData.map((copyData:any)=>{
    //    if(copyData?.client ===''){
    //     const objcolaborator={...copyData,client:copyData.project}
    //      arrayData.push(objcolaborator)
    //     }else{
    //       arrayData.push(copyData)
    //     }
    //   })      
    //   return _.isNil(arrayData) ? [] : arrayData;
    //  }
    return _.isNil(data) ? [] : data;
  }

  const getRows: any = async (paginationObj?: any) => {
    if (getData) {
      await getData(paginationObj);
    }
  }

  const getTotalAvailableRecords = () => {
    let _count = _.get(rows, 'length', 0);
    if (getData && !_.isNil(totalRecords)) {
      _count = totalRecords;
    }
    return _.isNil(_count) ? 0 : _count;
  }

  if (loading) return <span>Cargando...</span>

  return (
    <div>
      {(skipPermission || CheckPermission("Notificaciones.Ver")) && (
        <Paper className={classes.root}>
          <TableContainer className={classes.paper} >
            <Table stickyHeader aria-label="sticky table">

              <THeaderBits
                columns={newColumns}
                classes={classes}
                options={options}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
              />

              <TableBody>

                {
                  _getRows().map((row: any, i: any) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={i}
                        className="tableRow__BITS"
                      >
                        {newColumns.map((col, i) => {

                          // Render Cell options
                          if (i === newColumns.length - 1 && options) {

                            if (ComponentOptions) return (
                              <TableCell key={"OPTIONSBITS2"} align={'right'}>
                                <ComponentOptions {...row} />
                              </TableCell>
                            )

                            return (
                              <TableCell key={"OPTIONSBITS2"} align={'left'} >
                                <button>{col.id}</button>
                              </TableCell>
                            );
                          };

                          // Check if components were sended
                          if (components.length > 0) {
                            // Check if component was declared
                            if (components[i]) {
                              return (
                                <TableCell key={col.id} align={'left'}>
                                  {components[i](row)}
                                </TableCell>
                              )
                            };
                          };

                          const value: { [unit: string]: any } = row;

                          return (
                            <TableCell key={col.id} align={'left'}>
                              {value[col.id]}
                            </TableCell>
                          );

                        })}

                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={getTotalAvailableRecords()}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            labelRowsPerPage={"Número de filas"}
          />
        </Paper >)}
    </div>
  )
}

export default TableBits;
