/* eslint-disable array-callback-return */
import { useContext } from "react";
import FiltrarIcon from "../../../assets/svg/Filtrar.svg";
import CampaignContext from "../../../context/CampaignsContext/CampaignContext"
import { findCampaignsModal } from "../../../context/CampaignsContext/Actions"


const Btnfilter = ({
  title,
  option,
  setState,
  campaignFilter,
  stateInitial,
  setStepFilter,
  filterCollaborators = false
}: any) => {
  const { dispatch } = useContext(CampaignContext);

  const resetFilter = () => {
    if (campaignFilter.length > 0) {
      setState(stateInitial);
    }
  }

  const HandleFiltrar = (option: any) => {
    switch (option) {
      case "btnFecha":
        resetFilter();
        findCampaignsModal("0", true, dispatch)
        break;
      case "status":
        const campaignsStatus = [...stateInitial]
        setStepFilter([])
        const filterStatus = campaignsStatus.sort((x: any, y: any) => x.active.localeCompare(y.active));
        setState(filterStatus)
        return
      case "company":
        if (filterCollaborators) {
          const sorted = [...stateInitial].sort((a: any, b: any) => a.entrepise.localeCompare(b.entrepise))
          setState(sorted);
        }
        break;
      case "metricIndicator":
        if (filterCollaborators) {
          console.log("entro", stateInitial)
          const sorted = [...stateInitial].sort((a: any, b: any) => a.metricIndicator.localeCompare(b.metricIndicator))
          console.log("sorted", sorted)
          setState(sorted);
        }
        break;
      case "campus":
        setStepFilter([])
        const campaignsCampus = [...stateInitial];
        let objSedeCampaign = {}
        const array: any = []
        if (filterCollaborators) {
          const sorted = [...stateInitial].sort((a: any, b: any) => a.sede.localeCompare(b.sede))
          setState(sorted);
        } else {
          campaignsCampus.map((campaignCampu) => {
            if( Array.isArray(campaignCampu.sedes)){
            const copySede = [...campaignCampu.sedes]
            objSedeCampaign = { ...campaignCampu, sedes: copySede.sort((x: any, y: any) => x.sede.localeCompare(y.sede)) }
            array.push(objSedeCampaign)
            }
          })
          const filterCampus = array.sort((x: any, y: any) => x.sedes[0].sede.localeCompare(y.sedes[0].sede));
          setState(filterCampus);
        }
        break;
      case "area":
        setStepFilter([])
        const campaignsArea = [...stateInitial];
        let objAreaCampaign = {}
        const arrayAreaCampaign: any = []
        if (filterCollaborators) {
          const sorted = [...stateInitial].sort((a: any, b: any) => a.area.localeCompare(b.area))
          setState(sorted);
        } else {

          campaignsArea.map((campaignArea) => {
            if( Array.isArray(campaignArea.areas)){
            const copyArea = [...campaignArea.areas]
            objAreaCampaign = { ...campaignArea, areas: copyArea.sort((x: any, y: any) => x.area.localeCompare(y.area)) }
            arrayAreaCampaign.push(objAreaCampaign)
            }
          })
          const filterArea = arrayAreaCampaign.sort((x: any, y: any) => x.areas[0].area.localeCompare(y.areas[0].area));
          setState(filterArea);
        }
        break;
    }
  }

  return (
    <div className="containerBtnFiltrar">
      <button
        id="addPrestacion"
        onClick={() => HandleFiltrar(option)}
        className="btnFiltrar"
        style={{
          ...(option === "metricIndicator" && { width: "180px" })
        }}
      >
        <div className="alineacionBoton">
          <img src={FiltrarIcon} alt="filtrar" />
          <div className="nombrePrestacion">
            <b>
              <span>{title}</span>
            </b>
          </div>
        </div>
      </button>
    </div>
  )
}

export default Btnfilter
