import { useState, ChangeEvent, useEffect } from "react";
import { useQuery } from "@apollo/client";
import {
  GET_ALL_DATAREPORTS
} from "../../Querys/reports/querys";
import { Grid } from "@material-ui/core";
import Box from '@mui/material/Box';
import DateRangeReport from "./modals/DateRangeReport";
import { DeleteReport } from "./modals/DeleteReport";
import EditReport from "./modals/EditReport";
import CustomTabs from "../Collaborators/Tab/CustomTabs";
import CustomTab from '../Collaborators/Tab/CustomTabMain';
import BtnFilterReport from "./components/BtnFilterReport";
import BtnCreateReport from "./components/BtnCreateReport";
import SearcherTable from "./components/SearcherTable";
import AllReports from "./AllReports";
import MyReports from './MyReports';
import "./css/Reports.css";
import CreateReportModal from "./components/CreateReportModal";
import { GET_ALL_CUSTOMREPORTS } from "../../Querys/querys";

const ReportContent = () => {
  const [tab, setTab] = useState(0);
  const [allReports, setAllReports] = useState<any[]>([]);
  const [initAllReports, setInitAllReports] = useState<any[]>([]);
  const [myReports, setMyReports] = useState([]);
  const [initMyReports, setInitMyReports] = useState([]);
  const { data: reportsInfo, loading } = useQuery(GET_ALL_DATAREPORTS);
  const { data: customReportsInfo, loading: customReportLoading } = useQuery(GET_ALL_CUSTOMREPORTS, {
    fetchPolicy: 'no-cache',
    pollInterval: 3000
  });

  const filterMyReports = (arrayReports: any) => {
    let reports = arrayReports.filter((report: any) => {
      return report.userAdd !== null
    }
    )
    return reports
  }

  // useEffect(() => {
  //   console.log(reportsInfo)
  //     if (reportsInfo && reportsInfo.GET_ALL_DATAREPORTS) {
  //       setAllReports(reportsInfo.GET_ALL_DATAREPORTS);
  //       setInitAllReports(reportsInfo.GET_ALL_DATAREPORTS)
  //       setMyReports(filterMyReports(reportsInfo.GET_ALL_DATAREPORTS))      
  //       setInitMyReports(filterMyReports(reportsInfo.GET_ALL_DATAREPORTS))
  //     }
  //   }, [reportsInfo]);

  useEffect(() => {
    if (reportsInfo && reportsInfo.GET_ALL_DATAREPORTS && customReportsInfo && customReportsInfo.GET_ALL_CUSTOMREPORTS) {
      const customReports = customReportsInfo.GET_ALL_CUSTOMREPORTS.map((customReport: any) => ({
        ReportDate: customReport.dateAdd,
        ReportDescription: "",
        ReportName: customReport.name,
        ReportSQL: customReport.sqlQueryRows + ';' + customReport.sqlQueryColumns,
        ReportType: "Personalizado",
        id: customReport.id,
        userAdd: "custom_report"
      }))
      setAllReports([...reportsInfo.GET_ALL_DATAREPORTS, ...customReports]);
      setInitAllReports([...reportsInfo.GET_ALL_DATAREPORTS, ...customReports])
      setMyReports(filterMyReports(reportsInfo.GET_ALL_DATAREPORTS))
      setInitMyReports(filterMyReports(reportsInfo.GET_ALL_DATAREPORTS))
    }
  }, [reportsInfo, customReportsInfo]);

  const handleChange = (event: ChangeEvent<{}>, newValue: number) => {
    setTab(newValue)
    setAllReports(reportsInfo.GET_ALL_DATAREPORTS);
    setMyReports(filterMyReports(reportsInfo.GET_ALL_DATAREPORTS));
    localStorage.setItem('currentPill', String(newValue));
  };

  return (
    <div>
      <Box mt={3} ml={5} className="Title" mb={2}>
        Informes
      </Box>
      <Box p={5} pb={3} pt={0}>
        <Grid
          container
          justify="flex-start"
          alignItems="center"
        >
          <Grid item xs={12} md={6}>
            <CustomTabs
              value={tab}
              onChange={handleChange}
              aria-label="simple tabs example"
            >
              <CustomTab label="Todos" value={0} />
              <CustomTab label="Mis Informes" value={1} />
            </CustomTabs>
          </Grid>
          <Grid item xs={12} md={6}>
            {
              tab === 1 && (
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="flex-end"
                  style={{ width: "100%" }}
                >
                </Box>
              )
            }
          </Grid>
          <Grid className="btnsInformes">
            <Grid className="btnsInformesFiltros">
              <BtnFilterReport
                title={`Por tipo de informe`}
                width={196}
                type={'typeReport'}
                tab={tab}
                setAllReports={setAllReports}
                initAllReports={initAllReports}
                setMyReports={setMyReports}
                initMyReports={initMyReports}
              />
              <BtnFilterReport
                title={`Por fecha`}
                width={132}
                type={'date'}
                tab={tab}
              />
              <SearcherTable
                initState={allReports}
                setState={setAllReports}
                stateInitial={initAllReports}
                initState1={myReports}
                setState1={setMyReports as any}
                stateInitial1={initMyReports}
                tabReport={tab}
                label={"Buscar informe"}
                fields={['titulo']}
                width={400}
              />
            </Grid>
            <Grid>
              <BtnCreateReport />
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <div className="contenedor2">
        {tab === 0 && <AllReports
          reports={allReports}
          loading={loading}
        />}
        {tab === 1 && <MyReports
          reports={myReports}
          loading={loading}
        />}
      </div>
      <DateRangeReport
        tabSelect={tab}
        initAllReports={initAllReports}
        setAllReports={setAllReports}
        initMyReports={initMyReports}
        setMyReports={setMyReports}
      />
      <DeleteReport />
      <EditReport />
      <CreateReportModal />
    </div>
  )
}

export default ReportContent
