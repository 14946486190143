const Excel = require("exceljs");

export const CreateExcelIDSE = (data:any) =>{
  const workbook = new Excel.Workbook();
  const reporteIDSE= workbook.addWorksheet("Reporte Dispersion");

  const width = 40;

  const header = {
    type: "pattern",
    pattern: "solid",
    fgColor: {argb: "002060" }
  };
  const headerFont = {
    name: "Arial",
    color: {argb: "FFFFFF"},
    family: 2,
    size: 11,
    bold: true
  }

  reporteIDSE.columns =[
    {header: "NOMBRE", key:"Nombre", width},
    {header: "SBC", key:"SBC", width },
    {header: "MOVIMIENTO", key:"Movimiento", width},
    {header: "Fecha", key:"Fecha", width},
    {header: "Respuesta", key:"Respuesta", width}
  ]

  let letter = 65;
  reporteIDSE.columns.forEach(() =>{
    reporteIDSE.getCell(`${String.fromCharCode(letter)}1`).fill = header;
    reporteIDSE.getCell(`${String.fromCharCode(letter)}1`).font = headerFont;
    reporteIDSE.getCell(`${String.fromCharCode(letter)}1`).alignment = {
      horizontal: "center"
    };
    letter++;
  })

  data.forEach((item:any)=>{
    reporteIDSE.addRow({
      Nombre: item.Nombre,
      SBC: item.SBC,
      Movimiento: item.Movimiento,
      Fecha: item.Fecha,
      Respuesta: item.Respuesta
    })
  })

  workbook.xlsx.writeBuffer().then(function (data: Blob) {
    const blob = new Blob([data],
      { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const url = window.URL.createObjectURL(blob);
    const anchor = document.createElement('a');
    anchor.href = url;
    anchor.download = 'ReporteIDSE.xlsx';
    anchor.click();
    window.URL.revokeObjectURL(url);
  });

}