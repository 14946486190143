import { useEffect, useContext} from "react";
import EliminaSettlement from "./Modals/EliminaSettlement";
import ExternoSettlement from "./Modals/ExternoSettlement";
import CrearSettlementModal from "./Modals/CrearSettlementModal";
import { createSettlementModal, deleteSettlementProcess,ExternoSettlementProcess } from "../../context/PayrollProcess/SettlementActions";
import SettlementPayrollProcessContext from "../../context/PayrollProcess/SettlementPayrollProcessContext";
import { useHistory } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { GET_ALL_SETTLEMENTPAYROLL } from "../../Querys/querys";
import styles from './PayrollStyles.module.css'
import { getDateComplete } from "../../helpers/Payroll/Payroll";
import CheckPermission from "../../helpers/Administradores/Permissions";
import { Alert } from "@material-ui/lab";

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
    sistemaActivo: boolean;
};

const ActivePayrollTable = (props: TabPanelProps) =>{
    const {sistemaActivo} = props;
    const {data: resultPayroll, startPolling, stopPolling} = useQuery(GET_ALL_SETTLEMENTPAYROLL);
    const allPayroll = resultPayroll?.GET_ALL_SETTLEMENTPAYROLL;


    const history = useHistory();
    const { dispatch} = useContext(SettlementPayrollProcessContext)

    
    useEffect(() => {
      startPolling(1000);
      return () =>{
      stopPolling()
      }
    }, [allPayroll,startPolling, stopPolling]);

    const obtenerDatos = () => {};

    const createModal = () =>{
      createSettlementModal({createSettlement: true}, dispatch)
  }

    const eliminaSettlement = (id:any) =>{
      deleteSettlementProcess({_id: id, deleteModal: true}, dispatch)
    }

    const MarcarExternoSettlement = (id:any) =>{
      ExternoSettlementProcess({_id: id, externoModal: true}, dispatch)
    }

    const filterPayroll = (payroll:any,type:string) => {
      return payroll.recessionJob === type
    }

    return(
      <>
        <div className={styles.pp_contenedor}>
          <div className={styles.pp_contenedorBoton}>
          {(CheckPermission("Nominas.FiniquitoLiquidacion.Crear") && sistemaActivo) && ( 
              <button className={styles.pp_botonCrear}
                onClick={()=> createModal()}
                >
                <div>
                  <span className={styles.pp_signo}>+</span>
                </div> 
                <div>
                 Cálculo
                </div>
              </button>
              )}
          </div>
        </div>
        <div className={styles.pp_contenedorLista}>
          
          <div className={styles.pc_contenedorNombre}>
          <div>
            <h2 className={styles.pc_tituloPrincipal}>Finiquitos</h2>
          </div>
        </div>
          {allPayroll?.map((lis:any,key: any)=>
              lis.recessionJob === "Finiquito" &&(
            <div className={styles.pp_lista}>
              <div className={styles.pp_ColumnaUnoLista}>
                <div className={styles.pp_tituloGrupo}>
                  <span>{lis.Collaborator}</span>
                </div>
                {lis.statusProgress === "Calcular" ? (
                    <>
                           <div className={styles.pp_procesoCalcular}>
                          <span>{lis.statusProgress}</span>
                          </div>
                          <div
                              id={key === 0 ? "payroll_4" : ""}
                              className={styles.pp_contenedorBarras}
                            >
                            <div className={styles.pc_circuloAmarillo}>
                              <div
                                className={styles.pc_circuloBlanco}
                              ></div>
                            </div>
                            <div
                              className={styles.pc_lineaSeguimiento}
                            ></div>
                            <div
                              className={styles.pc_circuloGris}
                            ></div>
                            <div
                              className={styles.pc_lineaSeguimiento}
                            ></div>
                            <div
                              className={styles.pc_circuloGris}
                            ></div>
                           <div className={styles.pc_lineaSeguimientoPrincipal}></div>
                           <div className={styles.pc_circuloGris}></div>
                          </div>
                          </>
                      ) : null}
                      {lis.statusProgress === "Dispersar" ? (
                        <>
                           <div className={styles.pp_procesoCalcular}>
                          <span>{lis.statusProgress}</span>
                          </div>
                          <div
                              id={key === 0 ? "payroll_4" : ""}
                              className={styles.pp_contenedorBarras}
                            >
                            <div className={styles.pc_circuloAmarillo}>
                              <div
                                className={styles.pd_iconoCompleto}
                              ></div>
                            </div>
                            <div
                              className={
                                styles.pc_lineaSeguimientoCompleto
                              }
                            ></div>
                            <div className={styles.pc_circuloAmarillo}>
                              <div
                                className={styles.pc_circuloBlanco}
                              ></div>
                            </div>
                            <div
                              className={styles.pc_lineaSeguimiento}
                            ></div>
                            <div
                              className={styles.pc_circuloGris}
                            ></div>
                           <div className={styles.pc_lineaSeguimientoPrincipal}></div>
                           <div className={styles.pc_circuloGris}></div>
                          </div>
                          </>
                      ) : null}
                      {lis.statusProgress === "Timbrar" ? (
                        <>
                           <div className={styles.pp_procesoCalcular}>
                          <span>{lis.statusProgress}</span>
                          </div>
                          <div
                              id={key === 0 ? "payroll_4" : ""}
                              className={styles.pp_contenedorBarras}
                            >
                            <div className={styles.pc_circuloAmarillo}>
                              <div
                                className={styles.pd_iconoCompleto}
                              ></div>
                            </div>
                            <div
                              className={
                                styles.pc_lineaSeguimientoCompleto
                              }
                            ></div>
                            <div className={styles.pc_circuloAmarillo}>
                              <div
                                className={styles.pd_iconoCompleto}
                              ></div>
                            </div>
                            <div
                              className={
                                styles.pc_lineaSeguimientoCompleto
                              }
                            ></div>
                            <div className={styles.pc_circuloAmarillo}>
                              <div
                                className={styles.pc_circuloBlanco}
                              ></div>
                            </div>
                            <div className={styles.pc_lineaSeguimientoPrincipal}></div>
                           <div className={styles.pc_circuloGris}></div>
                        </div>
                        </>
                      ) : null}
                       {lis.statusProgress === "Terminado" ? (
                        <>
                           <div className={styles.pp_procesoCalcular}>
                          <span>{lis.statusProgress}</span>
                          </div>
                          <div
                              id={key === 0 ? "payroll_4" : ""}
                              className={styles.pp_contenedorBarras}
                            >
                            <div className={styles.pc_circuloAmarillo}>
                              <div
                                className={styles.pd_iconoCompleto}
                              ></div>
                            </div>
                            <div
                              className={
                                styles.pc_lineaSeguimientoCompleto
                              }
                            ></div>
                            <div className={styles.pc_circuloAmarillo}>
                              <div
                                className={styles.pd_iconoCompleto}
                              ></div>
                            </div>
                            <div
                              className={
                                styles.pc_lineaSeguimientoCompleto
                              }
                            ></div>
                             <div className={styles.pc_circuloAmarillo}>
                              <div
                                className={styles.pd_iconoCompleto}
                              ></div>
                            </div>
                           <div className={styles.pc_lineaSeguimientoCompleto}></div>
                           <div className={styles.pc_circuloAmarillo}>
                              <div
                                className={styles.pc_circuloBlanco}
                              ></div>
                            </div>
                          </div>
                          </>
                      ) : null}  
              </div>
              <div className={styles.pp_ColumnaDosLista}>
                <div className={`${styles.pp_columnaInterna} ${styles.pp_internaUno}`}>
                  <div>
                    <span className={styles.pp_textoInterno}>RFC</span>
                  </div>
                  <div>
                    <span className={styles.pp_valorInterno}>{lis.RFC}</span>
                  </div>
                </div>
                <div className={`${styles.pp_columnaInterna} ${styles.pp_internaUno}`}>
                  <div>
                    <span className={styles.pp_textoInterno}>Empresa</span>
                  </div>
                  <div>
                    <span className={styles.pp_valorInterno}>{lis.Company}</span>
                  </div>
                </div>
                <div className={`${styles.pp_columnaInterna} ${styles.pp_internaUno}`}>
                  <div>
                    <span className={styles.pp_textoInterno}>Fecha de baja</span>
                  </div>
                  <div>
                    <span className={styles.pp_valorInterno}>{getDateComplete(lis.dischargeDate)}</span>
                  </div>
                </div>
                <div className={`${styles.pp_columnaInterna} ${styles.pp_internaUno}`}>
                  <div>
                    <span className={styles.pp_textoInterno}>Fecha de alta</span>
                  </div>
                  <div>
                    <span className={styles.pp_valorInterno}>{getDateComplete(lis.dateOfAdmission)}</span>
                  </div>
                </div>
              </div>
              <div className={styles.pp_ColumnaTresLista}>
                <div>
                  {CheckPermission("Nominas.FiniquitoLiquidacion.Ver") && ( 
                    <button className={styles.pp_botonComenzar}  onClick={() => history.push(`/InitSettlement/${lis.id}/${lis.Collaborator}`)}>
                      Continuar
                    </button>
                  )}
                </div>
                {CheckPermission("Nominas.FiniquitoLiquidacion.Eliminar")  && lis.payrollCompleted === 0 && sistemaActivo ? 
                  <div 
                  className={styles.pp_contenedorIconoEliminar}
                  onClick={() => eliminaSettlement(lis.id)}
                  >
                    <img className={styles.pp_iconoEliminar} src='/assets/svg/icono_eliminar-nomina.svg' alt=""></img>
                    <span className={styles.pp_textoEliminar}>Eliminar</span>
                  </div>
                : null
                }
                {
                  sistemaActivo ?(
                    <div 
                    className={styles.pp_contenedorMarcarExterno}
                    onClick={() => MarcarExternoSettlement(lis.id)}
                    >
                      <span className={styles.pp_textoMarcarExterno}>Marcar Externo</span>
                    </div>
                  ): null 
                }
              </div>
            </div>
          ))}
          {
            allPayroll?.filter((payroll:any)=>filterPayroll(payroll, 'Finiquito')).length === 0 && (
              <Alert severity="info">No hay finiquitos registrados.</Alert>
            )
          }

        <div className={styles.pc_contenedorNombre}>
          <div>
            <h2 className={styles.pc_tituloPrincipal}>Liquidación</h2>
          </div>
       </div>
           {allPayroll?.map((lis:any)=>
            lis.recessionJob === "Liquidación" &&(
            <div className={styles.pp_lista}>
              <div className={styles.pp_ColumnaUnoLista}>
                <div className={styles.pp_tituloGrupo}>
                  <span>{lis.Collaborator}</span>
                </div>
                <div className={styles.pp_procesoCalcular}>
                  <span>Calcular</span>
                </div>
                <div className={styles.pp_contenedorBarras}>
                  <div className={styles.pc_circuloAmarillo}>
                    <div className={styles.pd_iconoCompleto}></div>
                  </div>
                  <div className={styles.pc_lineaSeguimientoPrincipalCompleto}></div>
                  <div className={styles.pc_circuloAmarillo}>
                    <div className={styles.pc_circuloBlanco}></div>
                  </div>
                  <div className={styles.pc_lineaSeguimientoPrincipal}></div>
                  <div className={styles.pc_circuloGris}></div>
                  <div className={styles.pc_lineaSeguimientoPrincipal}></div>
                  <div className={styles.pc_circuloGris}></div>
                </div>
              </div>
              <div className={styles.pp_ColumnaDosLista}>
                <div className={`${styles.pp_columnaInterna} ${styles.pp_internaUno}`}>
                  <div>
                    <span className={styles.pp_textoInterno}>RFC</span>
                  </div>
                  <div>
                    <span className={styles.pp_valorInterno}>{lis.RFC}</span>
                  </div>
                </div>
                <div className={`${styles.pp_columnaInterna} ${styles.pp_internaUno}`}>
                  <div>
                    <span className={styles.pp_textoInterno}>Empresa</span>
                  </div>
                  <div>
                    <span className={styles.pp_valorInterno}>{lis.Company}</span>
                  </div>
                </div>
                <div className={`${styles.pp_columnaInterna} ${styles.pp_internaUno}`}>
                  <div>
                    <span className={styles.pp_textoInterno}>Fecha de Baja</span>
                  </div>
                  <div>
                    <span className={styles.pp_valorInterno}>{getDateComplete(lis.dischargeDate)}</span>
                  </div>
                </div>
                <div className={`${styles.pp_columnaInterna} ${styles.pp_internaUno}`}>
                  <div>
                    <span className={styles.pp_textoInterno}>Fecha de alta</span>
                  </div>
                  <div>
                    <span className={styles.pp_valorInterno}>{getDateComplete(lis.dateOfAdmission)}</span>
                  </div>
                </div>
              </div>
              <div className={styles.pp_ColumnaTresLista}>
                <div>
                  <button className={styles.pp_botonComenzar}  onClick={() => history.push(`/InitSettlement/${lis.id}/${lis.Collaborator}`)}>
                    Continuar
                  </button>
                </div>
                {CheckPermission("Nominas.FiniquitoLiquidacion.Eliminar")  && lis.payrollCompleted === 0 && sistemaActivo ? 
                  <div 
                    className={styles.pp_contenedorIconoEliminar}
                    onClick={() => eliminaSettlement(lis.id)}
                    >
                    <img className={styles.pp_iconoEliminar} src='/assets/svg/icono_eliminar-nomina.svg' alt=""></img>
                    <span className={styles.pp_textoEliminar}>Eliminar</span>
                  </div>
                : null
                }
                {
                  sistemaActivo ?(
                    <div 
                    className={styles.pp_contenedorMarcarExterno}
                    onClick={() => MarcarExternoSettlement(lis.id)}
                    >
                      <span className={styles.pp_textoMarcarExterno}>Marcar Externo</span>
                    </div>
                  ) : null

                }
                
              </div>
            </div>
          ))}
          {
            allPayroll?.filter((payroll:any)=>filterPayroll(payroll, 'Liquidación')).length === 0 && (
              <Alert severity="info">No hay liquidaciones registradas.</Alert>
            )
          }
        </div>

        <CrearSettlementModal getDatos={obtenerDatos}/>
        <EliminaSettlement getDatos={obtenerDatos}/>
        <ExternoSettlement getDatos={obtenerDatos}/>
      </>
    )
}

export default ActivePayrollTable