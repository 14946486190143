import { useContext, useState, useEffect } from "react";
import CalendarsContext from "../../../../context/NewCalendarContext/CalendarsContext";
import {Dialog, DialogContent, DialogActions, Select, FormControl, FormHelperText, InputLabel, Grid } from "@material-ui/core"
import { useFormik } from "formik";
import * as Yup from "yup";
import { closeUpdateWorkSchedule } from "../../../../context/NewCalendarContext/Actions";
import { Form } from "semantic-ui-react";
import { GET_ALL_TIPOJORNADA, GET_ALL_WEEKPARTTIME, GET_WORKINGHOURS, UPDATE_WORKINGHOURS ,GET_ALL_WORKINGHOURS} from "../../../../Querys/querys";
import {  useLazyQuery, useMutation } from '@apollo/client';
import '../../ClockCalendar.css';
import { DesktopTimePicker  } from '@mui/x-date-pickers/DesktopTimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import TextField from '@mui/material/TextField';
import { ErrorAlert } from "../../../../alerts/errorAlert";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { SuccessfulAlert } from "../../../../alerts/successAlerts";
import {differenceInMinutes } from "date-fns";

function UpdateWorkSchedule(){

  const {state, dispatch} = useContext(CalendarsContext);
  const [days, setDays] = useState<String[]>([]);
  const [selectedDays, setSelectedDays] = useState<any[]>([]);
  const [getJourneys, {data: resultJourneys}] = useLazyQuery(GET_ALL_TIPOJORNADA);
  const [getWeeks, {data: resultWeeks}] = useLazyQuery(GET_ALL_WEEKPARTTIME);
  const [getWorkinHours, {data: resultData}] = useLazyQuery(GET_WORKINGHOURS,{
    fetchPolicy: 'no-cache'
  });
  const [createWeek, {data: resultCreateWeeks}] = useMutation(UPDATE_WORKINGHOURS, {
    refetchQueries: [
        { query: GET_ALL_WORKINGHOURS }
    ],
  });
  const [weeks, setWeeks] = useState([]);
  const [journeys, setJourneys] = useState([]);
  const daysOfWeek = ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo'];
  const [showDays, setShowDays] = useState(false);
  const [workingHour, setWorkingHour] = useState({});
  const [valueWeek, setValueWeek] = useState("");
  const [errorWeek, setErrorWeek] = useState(false);
  const [txtErrorWeek, setTxtErrorWeek] = useState("");

  useEffect(()=>{
    if(state.openUpdateSchedule){
      getWeeks();
      getJourneys();
      getWorkinHours({
        variables:{
          id: state._id
        }
      })
    }
  }, [state.openUpdateSchedule, state._id]);

  useEffect(()=>{
    if(resultData && resultData.GET_WORKINGHOURS){
      const data = resultData.GET_WORKINGHOURS;
      formik.setFieldValue('Name', data.Name);
      formik.setFieldValue('idTypeJourney', data.idTypeJourney);
      formik.setFieldValue('idWeek', data.idWeek);
      setSelectedDays(data.days);
      setWorkingHour(data);
      setValueWeek(data.idWeek)
      const daysData = [];
      for(const item of data.days){
        daysData.push(item.day)
      }
      setDays(daysData);
    }
  }, [resultData]);

  useEffect(()=>{
    if(resultWeeks && resultWeeks.GET_ALL_WEEKPARTTIME){
      setWeeks(resultWeeks.GET_ALL_WEEKPARTTIME);
    }
  },[resultWeeks]);

  useEffect(()=>{
    if(resultJourneys && resultJourneys.GET_ALL_TIPOJORNADA){
      setJourneys(resultJourneys.GET_ALL_TIPOJORNADA);
    }
  },[resultJourneys]);

  const initalValues = () =>{
    return{
      Name: "",
      idTypeJourney: "",
      idWeek: ""
    }
  }

  const validationSchema = () =>{

    return{
      Name: Yup.string().required("Obligatorio"),
      idTypeJourney: Yup.number().required("Obligatorio"),
      idWeek: Yup.number().required("Obligatorio"),
    }
  }

  const formik = useFormik({
    initialValues: initalValues(),
    validationSchema: Yup.object(validationSchema()),
    onSubmit:async (formData) =>{
      let flag = false;
      for(const selectedDay of selectedDays){
        if(!selectedDay.start || !selectedDay.end || selectedDay.start === '' || selectedDay.end === ''){
          flag = true;
        }
      }
      if(flag){
        ErrorAlert({text:'Es necesario completar todos los campos'});
      }else{
        try{
          const data = [...selectedDays];
        
          for(const item of data){
            delete item.id;
            delete item.__typename;
          }          
          await createWeek({
            variables:{
              id: state._id,
              input:{
                idTypeJourney: Number(formData.idTypeJourney),
                idWeek: Number(formData.idWeek),
                name: formData.Name,
                days: data
              }
            }
          });
        }catch(e){
          ErrorAlert({text:'Ocurrió un error al crear el horario'});
        }
      }
      
    }
  });

  useEffect(()=>{
    if(resultCreateWeeks && resultCreateWeeks.UPDATE_WORKINGHOURS){
      SuccessfulAlert({text:'Se ha creado el horario laboral'})
      closeUpdateWorkSchedule(false, dispatch);
      formik.resetForm();
      setSelectedDays([]);
      setDays([]);
    }
  }, [resultCreateWeeks])

  const addDay = (item:string) => {
    const data = [...days];
    if(data.includes(item)){
      data.splice( data.indexOf(item), 1);
    }else{
      data.push(item);
    }
    setDays(data);
  }

  const handleChangeWeek=(e: any)=>{
    setValueWeek(e.target.value);
    setSelectedDays([]);    
}

  const addSelectedDays = () => {
    const daysSelect = days.length;
    const semanaSeleccionada= parseInt(valueWeek);
    if(!semanaSeleccionada){
      setErrorWeek(true)
      setTxtErrorWeek(`Seleccione la semana `)
      return
    }   
    if(semanaSeleccionada !== daysSelect ){
   
      ErrorAlert({text:`Los días seleccionados no corresponden al número de dias (${semanaSeleccionada}) de la semana`});
      return
    }
    else{
      setErrorWeek(false);  
    setShowDays(false);
    const data = [];
    for(const day of days){
      data.push({
        'start': '',
        'end': '',
        'day': day,
        id: Math.random()
      });
      const inputStart = document.getElementById('input-start-'+day) as HTMLInputElement;
      const inputEnd = document.getElementById('input-end-'+day) as HTMLInputElement;
      if(inputStart){
        console.log(1)
        inputStart.value = '';
      }
      if(inputEnd){
        console.log(2)
        inputEnd.value = '';
      }
    }
    setSelectedDays(data);
   }
  }

  const onChangeDays = (e:any, field:string, index:number) => {
    const data = [...selectedDays];
    data[index][field] = e && e['$d'] && e['$d'].getDate() ? e.toISOString() : '';
    let pos:number =index;
    let hrInicio:any=""
    let hrFin:any=""
    const menHrInicio:number=0
    if(index === pos)
     {
      hrInicio=data[index]?.start;
      hrFin=data[index]?.end;    
     }
     if(hrInicio !== "" && hrFin !== ""){
     const result = differenceInMinutes(new Date(hrFin),new Date(hrInicio));
      if(result < menHrInicio){
        ErrorAlert({text:`La hora de salida debe ser mayor que la de Entrada`});
        return
      }
     }    
    setSelectedDays(data);    
  }

  const toggleAll = () => {
    if( days.length === 7 ){
      setDays([]);
    }else{
      setDays( daysOfWeek );
    }
  }

  return (
    <Dialog open={state.openUpdateSchedule} aria-labelledby="form-dialog-title" maxWidth="sm" fullWidth={true}>
      <Form onSubmit={formik.handleSubmit}>
        <div className="dialogContainer">
          <div className="containerTitle">
              <div className="centerContainer"
              style={{
                  marginLeft:'auto',
                  marginRight:'auto'
              }}
              >
                  <h2 id="form-dialog-title" data-testid="TitleModal">Actualizar horario laboral</h2>
                  
              </div>
              <div className="contenedorCerrarModal">
                  <div className="iconoCerrarModal" onClick={(()=>closeUpdateWorkSchedule(false, dispatch))}></div>      
              </div> 
          </div>
          <DialogContent>
            <div className="clockQuestionContainer">
              <TextField
                type="text"
                name="Name"
                label="Nombre del nuevo horario"
                variant="outlined"
                size="small"
                fullWidth={true}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                className="question"
                inputProps={{ maxLength: 100 }}
                onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                error={formik.touched.Name && Boolean(formik.errors.Name)}
                helperText={formik.touched.Name && formik.errors.Name}
                value={formik.values.Name || ''}
                />
            </div>
            <div className="clockQuestionContainer">
              <FormControl
                  variant="outlined"
                  fullWidth={true}
                  size="small"
                >
                  <InputLabel
                  style={{
                    backgroundColor: "white",
                  }}
                  
                  >Tipo de jornada</InputLabel>
                  <Select
                    native
                    onChange={formik.handleChange}
                    IconComponent={(props) => (<KeyboardArrowDownIcon {...props} style={{ color: '#000000' }}></KeyboardArrowDownIcon>)}
                    name="idTypeJourney"
                    variant="outlined"
                    error={
                      formik.touched.idTypeJourney && Boolean(formik.errors.idTypeJourney)
                    }
                    value={formik.values.idTypeJourney}
                  >
                    <option>
                    </option>
                    {journeys.map((item:any, index: number) => (
                      <option key={index} value={item.id}>
                        {item.Descripcion}
                      </option>
                      )
                    )}
                  </Select>
                  <FormHelperText style={{color: "red"}}>
                    {formik.touched.idTypeJourney && formik.errors.idTypeJourney}
                  </FormHelperText>
                </FormControl>
            </div>
            <div className="clockQuestionContainer">
              <FormControl
                  variant="outlined"
                  fullWidth={true}
                  size="small"
                >
                  <InputLabel
                  style={{
                    backgroundColor: "white",
                  }}
                  
                  >Semana</InputLabel>
                  <Select
                    native
                    onChange={(e) => { handleChangeWeek(e); formik.handleChange(e)}}
                    IconComponent={(props) => (<KeyboardArrowDownIcon {...props} style={{ color: '#000000' }}></KeyboardArrowDownIcon>)}
                    name="idWeek"
                    variant="outlined"
                    error={
                      formik.touched.idWeek && Boolean(formik.errors.idWeek)
                    }
                    value={formik.values.idWeek}
                  >
                    <option>
                    </option>
                    {weeks.map((item:any, index: number) => (
                        <option key={index} value={item.id}>
                          {item.name}
                        </option>
                      )
                    )}
                  </Select>
                  <FormHelperText style={{color: "red"}}>
                    {formik.touched.idWeek && formik.errors.idWeek}
                  </FormHelperText>
                </FormControl>
            </div>
            {
              errorWeek && (
                <div>
                  <span className="errorSemanaText"> {txtErrorWeek}</span>
                </div>
              )
            }
            <div className="clockQuestionContainer">
              <button
                id="payroll_7"
                className={'daysOfWeek'}
                type={"button"}
                onClick={()=>{setShowDays(!showDays)}}
              >
                <span className={'daysOfWeekText'}>
                  Días de la semana
                </span>
                <div className="iconSelect"></div>
              </button>
              {
                showDays && (
                  <div className={'daysList'}>
                    <ul className={'dayListContainer'}>
                      <li className={'dayListName'} key={"todos-option"}>
                        <div id="UniversoI" className={'checkboxDays'}>
                          <input
                            key={"todos"}
                            id={"todos"}
                            type="checkbox"
                            checked={days.length === 7}
                            onClick={(e) => toggleAll()}
                          ></input>
                          <label htmlFor="todos">Todos</label>
                        </div>
                      </li>
                      {daysOfWeek.map((lis: string, key:number) => (
                        <li className={'dayListName'} key={key}>
                          <div id="UniversoI" className={'checkboxDays'}>
                            <input
                              key={lis}
                              id={lis}
                              type="checkbox"
                              value={lis}
                              checked={days.includes(lis)}
                              onClick={(e) => addDay(lis)}
                            ></input>
                            <label htmlFor={lis}> {lis}</label>
                          </div>
                        </li>
                      ))}
                    </ul>
                    <div className="daysListButtonContainer">
                      <button className={'addDaysButton'} onClick={addSelectedDays} type={"button"}>
                        <div className={'addDaysButtonIcon'}></div>
                        <span className={'addDaysButtonText'}>Guardar selección</span>
                      </button>
                    </div>
                  </div>
                )
              }
            </div>
            {
              selectedDays.map((item:any, key:number)=>(
                <div className="clockQuestionContainer" key={item.id}>
                  <p style={{paddingBottom:'5px', paddingTop:'0px', marginBottom:'0px', marginTop:'0px'}}>{item.day}</p>
                  <Grid container spacing={1}>
                    <Grid item xs={12} md={6}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DesktopTimePicker  
                          label="Hora de entrada"
                          onChange={(e:any)=>{onChangeDays(e, 'start', key)}}
                          value={item.start}
                          renderInput={(params:any) => <TextField {...params} style={{height:'43px'}} size="small" id={'input-start-'+item.id}/>}
                          />
                      </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DesktopTimePicker  
                          label="Hora de salida"
                          onChange={(e:any)=>{onChangeDays(e, 'end', key)}}
                          value={item.end}
                          renderInput={(params:any) => <TextField {...params} style={{height:'43px'}} size="small" id={'input-end-'+item.id}/>}
                          className="timePickerInput"
                          />
                      </LocalizationProvider>
                    </Grid>
                  </Grid>
                </div>
              ))
            }
          </DialogContent>
          <DialogActions>
              <button className="botonCancelar" type="button" onClick={
                  (()=>closeUpdateWorkSchedule(false, dispatch))
              }>
                  Cancelar
              </button>
              <button 
                className="botonSiguiente"
                type="submit"
                >
                  <div className="iconSave"></div>
                  <div>
                  Editar horario
                  </div>
              </button>
          </DialogActions>
        </div>
      </Form>
    </Dialog>
  )
}

export default UpdateWorkSchedule;