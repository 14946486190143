import { Types } from "./CollaboratorContext";

const ModalReducer = (state: any, action: any) => {
  const { payload, type } = action;
  switch (type) {
    case Types.INGRESE_PROGRESS:
      return {
        ...state,
        progress: payload.progress,
      };
    case Types.LOAD_DATA:
      return {
        ...state,
        loading: payload.loading,
      };
    case Types.VALIDATE_SECTION:
      return {
        ...state,
        sections: payload.sections,
      };
    case Types.UPDATE_COLLABORATOR:
      return {
        ...state,
        collaborator: payload.collaborator,
        loading: payload.loading
      };
    case Types.SET_COLLABORATOR:
      return {
        ...state,
        progress: payload.progress,
        sections: payload.sections,
      };
    case Types.UPDATE_BAND:
      return {
        ...state,
        band: true,
      };
    case Types.REJECT_DATA:
      return {
        ...state,
        rejectModal: true,
      };
    case Types.CANCEL_REJECT_DATA:
      return {
        ...state,
        rejectModal: false,
      };
    case Types.DECLINE_MODAL:
      return {
        ...state,
        _id: payload._id,
        declineModal: true,
      };
    case Types.CANCEL_DECLINE_MODAL:
      return {
        ...state,
        _id: "",
        declineModal: false,
      };
    case Types.CONVERT_MODAL:
      return {
        ...state,
        _id: payload._id,
        convertModal: true,
      };
    case Types.CANCEL_CONVERT_MODAL:
      return {
        ...state,
        _id: "",
        convertModal: false,
      };
    case Types.SET_ACTIVESTEP:
      return {
        ...state,
        activeStep: payload.activeStep,
      };
    case Types.VIEW_COLLABORATOR:
      return {
        ...state,
        viewProfile: true,
        collaborator: payload.collaborator
      };
    case Types.ADD_SALARYHISTORY:
      return {
        ...state,
        addSalaryHistory: true,
        userAddSalaryHistory: payload.userAddSalaryHistory,
        grossSalaryHistory: payload.grossSalaryHistory,
        DSHistory: payload.DSHistory,
        recordableSalaryHistory: payload.recordableSalaryHistory,
        SDGHistory: payload.SDGHistory,
        ISDHistory: payload.ISDHistory,
        tipoEsquemaHistory: payload.tipoEsquemaHistory,
        formDataHistory: payload.formDataHistory,
      };
    case Types.CLEAR_SALARYHISTORY:
      return {
        ...state,
        addSalaryHistory: false,
        userAddSalaryHistory: "",
        grossSalaryHistory: "",
        DSHistory: "",
        recordableSalaryHistory: "",
        SDGHistory: "",
        ISDHistory: "",
        tipoEsquemaHistory: "",
        formDataHistory: {},
      };
    default:
      return state;
  }
};

export default ModalReducer;
