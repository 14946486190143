import {useEffect,useState  } from 'react';
import OrganigramaModalState from "../context/OrganigramaContext/OrganigramaModalState";
import Body from "../components/Organigrama/Body"
import '../components/Organigrama/Organigrama.css'

const OrganigramaScreen = () =>{

  useEffect(()=>{
  },[])

  const [tab, setTab] = useState(0);

  useEffect(() => {
      let pill = localStorage.getItem('currentPill');
      if (pill) setTab(Number(pill));
      else setTab(0);
  }, [tab]);

  return(
    <div>
      <div className="contenedor2">
        <OrganigramaModalState>
          <Body />
        </OrganigramaModalState>
      </div>
    </div>
  )
}

export default OrganigramaScreen;