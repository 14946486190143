
const Excel = require("exceljs");

export const CreateExcelPayrollReportISN = (data: any, TipoReporte: any) => {
    const workbook = new Excel.Workbook();
    const ReporteCalculoE= workbook.addWorksheet("Reporte reportes nomina");

    const width = 45;

    const header = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "002060" },
    };
    const headerFont = {
        name: "Arial",
        color: { argb: "FFFFFF" },
        family: 2,
        size: 11,
        bold: true,
    };
    // strint to mayusculas
    if (TipoReporte === 'ISR'){
        ReporteCalculoE.columns = [
            { header: "TIPO", key: "tipo", width },
            { header: "GRUPO NOMINA", key: "grupo", width },
            { header: "MES", key: "mes", width },
            { header: "TOTAL "+TipoReporte.toUpperCase(), key: "total", width },
            
        ];
    }else{
        ReporteCalculoE.columns = [
            { header: "TIPO", key: "tipo", width },
            { header: "GRUPO NOMINA", key: "grupo", width },
            { header: "MES", key: "mes", width },
            { header: "TOTAL EROGACIONES", key: "erogacion", width },
            { header: "TOTAL PAGAR", key: "total", width },
            
        ];
    }
    
    
    let numletter = 65;
    let numletter2 = 65;
    let letter = "";
    ReporteCalculoE.columns.forEach(() => {
        
        
        if(numletter > 90){
            letter = 'A'+String.fromCharCode(numletter2)
            numletter2++;
        }else{
            letter = String.fromCharCode(numletter)
        }

        

        ReporteCalculoE.getCell(`${letter}1`).fill = header;
        ReporteCalculoE.getCell(`${letter}1`).font = headerFont;
        ReporteCalculoE.getCell(`${letter}1`).alignment = {
        horizontal: "center",
        };
    
        
        numletter++;
        
    });

    data.forEach((item: any) => {
        // let name = item?.Colaborador.toUpperCase() || "";
        if (TipoReporte === 'ISR'){
            ReporteCalculoE.addRow({
                tipo: item?.Tipo,
                grupo: item?.group_name,
                mes: item?.Mes,
                total: item?.MontoImpuesto,
            });
        }else{
            ReporteCalculoE.addRow({
                tipo: item?.Tipo,
                grupo: item?.group_name,
                mes: item?.Mes,
                total: item?.MontoImpuesto,
                erogacion: item?.grossSalary
            });
        }
        
    });

    const nameReport = TipoReporte === 'IMSS' ? 'RetencionesIMSS.xlsx' : 'ReporteNomina'+TipoReporte+'.xlsx'

    workbook.xlsx.writeBuffer().then(function (data: Blob) {
        const blob = new Blob([data],
        { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement('a');
        anchor.href = url;
        anchor.download = nameReport;
        anchor.click();
        window.URL.revokeObjectURL(url);
    });
};

export const CreateExcelPayrollReportINFONAVIT = (data: any, TipoReporte: any) => {
    const workbook = new Excel.Workbook();
    const ReporteCalculoE= workbook.addWorksheet("Reporte reportes nomina");

    const width = 45;

    const header = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "002060" },
    };
    const headerFont = {
        name: "Arial",
        color: { argb: "FFFFFF" },
        family: 2,
        size: 11,
        bold: true,
    };

    ReporteCalculoE.columns = [
        { header: "TIPO", key: "tipo", width },
        { header: "GRUPO NOMINA", key: "grupo", width },
        { header: "COLABORADOR", key: "colaborador", width },
        { header: "MES", key: "mes", width },
        { header: "TOTAL DESCONTADO", key: "total", width },
        
    ];
    
    let numletter = 65;
    let numletter2 = 65;
    let letter = "";
    ReporteCalculoE.columns.forEach(() => {
        
        
        if(numletter > 90){
            letter = 'A'+String.fromCharCode(numletter2)
            numletter2++;
        }else{
            letter = String.fromCharCode(numletter)
        }

        

        ReporteCalculoE.getCell(`${letter}1`).fill = header;
        ReporteCalculoE.getCell(`${letter}1`).font = headerFont;
        ReporteCalculoE.getCell(`${letter}1`).alignment = {
        horizontal: "center",
        };
    
        
        numletter++;
        
    });
    let sumAll = 0
    data.forEach((item: any) => {
        // let name = item?.Colaborador.toUpperCase() || "";
        sumAll += item?.MontoImpuesto
        ReporteCalculoE.addRow({
            tipo: item?.Tipo,
            grupo: item?.group_name,
            mes: item?.Mes,
            total: item?.MontoImpuesto,
            colaborador: item?.colaborator,
        });
    });

    ReporteCalculoE.addRow({
        total: sumAll
    });
    
    const nameReport = TipoReporte === 'IMSS' ? 'RetencionesIMSS.xlsx' : 'ReporteNomina'+TipoReporte+'.xlsx'

    workbook.xlsx.writeBuffer().then(function (data: Blob) {
        const blob = new Blob([data],
        { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement('a');
        anchor.href = url;
        anchor.download = nameReport;
        anchor.click();
        window.URL.revokeObjectURL(url);
    });
};

export const CreateExcelPayrollINCIDENCIAS = (data: any, TipoReporte: any) => {
    
    const workbook = new Excel.Workbook();
    const ReporteCalculoE= workbook.addWorksheet("Reporte Incidencias");
    const width = 45;

    const header = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "002060" },
    };
    const headerFont = {
        name: "Arial",
        color: { argb: "FFFFFF" },
        family: 2,
        size: 11,
        bold: true,
    };

    ReporteCalculoE.columns = [
        { header: "NOMBRE", key: "nombre", width },
        { header: "PUESTO", key: "puesto", width },
        { header: "AREA", key: "area", width },
        { header: "TIPO INCIDENCIA", key: "tipoIncidencia", width },
        { header: "COMENTARIOS", key: "comentarios", width },
        { header: "CONCEPTO", key: "concepto", width },
        { header: "NOMBRE CONCEPTO", key: "nombreConcepto", width },
        { header: "TOTAL", key: "monto", width },
        
    ];
    
    let numletter = 65;
    let numletter2 = 65;
    let letter = "";
    ReporteCalculoE.columns.forEach(() => {
        if(numletter > 90){
            letter = 'A'+String.fromCharCode(numletter2)
            numletter2++;
        }else{
            letter = String.fromCharCode(numletter)
        }
        ReporteCalculoE.getCell(`${letter}1`).fill = header;
        ReporteCalculoE.getCell(`${letter}1`).font = headerFont;
        ReporteCalculoE.getCell(`${letter}1`).alignment = {
        horizontal: "center",
        };        
        numletter++;        
    });
    let sumAll = 0
    data.forEach((item: any) => {
        sumAll += item?.Monto
        ReporteCalculoE.addRow({
            nombre: item?.nombre,
            puesto: item?.puesto,
            area: item?.area,
            tipoIncidencia: item?.tipoIncidencia,
            comentarios: item?.comentarios,
            concepto: item?.concepto,
            nombreConcepto: item?.nombreConcepto,
            monto: item?.monto,
        });
    });
    ReporteCalculoE.addRow({
        total: sumAll
    });
    
    const nameReport = TipoReporte; 

    workbook.xlsx.writeBuffer().then(function (data: Blob) {
        const blob = new Blob([data],
        { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement('a');
        anchor.href = url;
        anchor.download = nameReport;
        anchor.click();
        window.URL.revokeObjectURL(url);
    });
};