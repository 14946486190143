import AdminPermisos from '../components/Admin/AdministratorPermissions/Body'
import { IPROPS } from "../interfaces/Collaborator";
import {Grid} from '@material-ui/core'
import Box from '@mui/material/Box';


const AdministratorScreen = ({
  back= '/collaborators/id/0',
}: IPROPS) =>{

    return(
      <div>
        <Box mt={3} ml={5} className="Title">
          Colaboradores
        </Box>
        <Box p={5} pb={3} pt={0}>
          <Grid
            container
            justify="flex-start"
          >
          </Grid>
        </Box>
        <AdminPermisos back={back}/>
      </div>
    )
}

export default AdministratorScreen;