import {useState, useContext} from "react";
import {Dialog} from "@material-ui/core"
import styles from './PayrollModals.module.css'
import { clearPayrollProcess } from "../../../context/PayrollProcess/Actions";
import PayrollProcessContext from "../../../context/PayrollProcess/PayrollProcessContext";
import { useMutation } from "@apollo/client";
import { CREACION_TOKEN_DISPERSION, SEND_DISPERSION_FACTURACION_PAGOS,GET_ALL_PAYMENTS } from "../../../Querys/querys";
import { SuccessfulAlert } from "../../../alerts/successAlerts";
import { v4 as uuidv4} from 'uuid'

const AceptarDispersarFacturacionPagos = (props:any) =>{
  const {totalesDispersar, totales, seleccionados, idRegistro} = props
  const {state, dispatch} = useContext(PayrollProcessContext)
  const [activo, setactivo] = useState(false)
  const [activoMensajeDos, setactivoMensajeDos] = useState(false)
  const [mensaje, setMensaje] = useState('Uno')
  const [token, setToken] = useState('')
  const [sendDispersion] = useMutation(SEND_DISPERSION_FACTURACION_PAGOS,{
    refetchQueries:[
      {query: GET_ALL_PAYMENTS}
    ]
  })
  const [creacionToken] = useMutation(CREACION_TOKEN_DISPERSION,{
  })

  const handleClose = () =>{
    clearPayrollProcess({}, dispatch)
  }

  const handleChange = (e: any) =>{
    if(e.target.value === 'CONFIRMAR'){
        setactivo(true)
    }else{
        setactivo(false)
    }
  }

  const handleChangenMensajeDos = (e: any) =>{
    if(e.target.value === 'CONFIRMAR'){
        setactivoMensajeDos(true)
    }else{
        setactivoMensajeDos(false)
    }
  }

  const handelMensajeDos = async()=>{
    const tokenCreado = uuidv4();
    await creacionToken({
      variables:{
        input:{
          Desde: "FacturacionPagos",
          idRegistro: parseInt(idRegistro),
          token: tokenCreado,
          numeroRegistros: parseInt(totalesDispersar)
        }
      }
    })
    setMensaje((current) => current = 'Dos')
  }


  const agregaSeleccionados = async() =>{
    setMensaje((current) => current = 'Tres')
    let Array:any = []
    Array.push({ id: parseInt(state._id) })
    await sendDispersion({
      variables:{
        sendDispersionFacturacionPagosId: Array
      }
    })
    setactivo(false)
    setactivoMensajeDos((current) => current = false)
    SuccessfulAlert({text: "Registros enviados, por favor espera la respuesta"})
    setMensaje((current) => current ='Uno' )
    handleClose()
  }

  return(
    <Dialog aria-labelledby='costumized-dialog-title' open={state.aceptarDispersarFacturacionPagos} fullWidth={false} maxWidth={"md"}>
      <div className={styles.contenedorPrincipal}>
        <div className={styles.contenedorCerrarModal}>
          {mensaje === 'Tres'?
          null
          :
          <div
          className={styles.cerrarModal}
          onClick={()=> handleClose()}
        >
        </div>
          }

        </div>
        <div className={styles.contenedorPrincipal}>
          <div className={styles.fim_iconoPrincipal}>
            <div className={styles.fim_iconoComplemento}>
            </div>
          </div>
        </div>
        <div className={styles.tituloAtencion}>
          ¡Atenci&oacute;n!
        </div>
        {mensaje === 'Uno'?
          <>
            <div className={styles.em_mensajePrtincipal}>
              Est&aacute;s por dispersar n&oacute;mina a {totalesDispersar}/{totales} colaboradores
            </div>
          <div className={styles.em_mensajePrtincipal}>
            Escribe CONFIRMAR para continuar
          </div>
          <div>
              <input
                className={styles.input}
                type="text"
                onChange={(e) => handleChange(e)}
              />
          </div>
          </>
          :null
          }
          {mensaje === 'Dos'?
          <>
            <div className={styles.em_mensajePrtincipal}>
              Recuerda que este movimiento genera un costo de servicio de
            </div>
            <div className={styles.em_mensajePrtincipal}>
            $0.00+IVA
            </div>
                <div className={styles.em_mensajePrtincipal}>
                  Escribe CONFIRMAR para continuar
                </div>
            <div>
              <input
                className={styles.input}
                type="text"
                onChange={(e) => handleChangenMensajeDos(e)}
              />
            </div>
          </>
          : null
          }
          {mensaje === 'Tres'?
            <>
              <div className={styles.em_mensajePrtincipal}>
               Dispersi&oacute;n en proceso, no actualices ni cierres la pantalla
              </div>
              <div className={styles.em_mensajePrtincipal}>
              hasta que el proceso haya terminado.
              </div>
              <div className={styles.contenedorLoadingDispersion}>
                <div className={styles.loading}></div>
              </div>
            </>
          : null
          }
        <div className={styles.em_contenedorBotones}>
              {mensaje === 'Tres'?
              null
              :
              <button
                className={styles.em_botonCancelar}
                onClick={()=> handleClose()}
              >
                Cancelar
              </button>
              }
              {mensaje === 'Uno'?
                (activo === true ?
                  <button 
                    className={`${styles.botonesAtencion} ${styles.continuarAtencion}`}
                    onClick={()=>handelMensajeDos()}
                  >
                    <div>
                      Confirmar
                    </div>
                  </button>
                  :
                  <button
                    className={`${styles.botonesAtencion} ${styles.continuarAtencionInactivo}`}
                  >
                    <div>
                      Confirmar
                    </div>
                  </button>
                )
                :null
              }
              {mensaje === 'Dos'?
                (activoMensajeDos === true ?
                  <button className={`${styles.botonesAtencion} ${styles.continuarAtencion}`}
                    onClick={()=> agregaSeleccionados()}
                  >
                    <div>
                      Confirmar
                    </div>
                  </button>
                :
                  <button
                    className={`${styles.botonesAtencion} ${styles.continuarAtencionInactivo}`}
                  >
                    <div>
                      Confirmar
                    </div>
                  </button>
                )
              :null
              }
            </div>
      </div>
    </Dialog>
  )
}

export default AceptarDispersarFacturacionPagos