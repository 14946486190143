import style from "../../screens/BitsScreen.module.css";

export const  GeneralPieChart = ({ percentage = 0, tipo = '', color = '#4FAEEB', percentageSymbol = '', number = '', textSize='20px', y=50, color2='#D8DCE6' }) => {
  const strokeWidth = 10;
  const radius = 50 - strokeWidth / 2;
  const pathDescription = `
      M 50,50 m 0,-${radius}
      a ${radius},${radius} 0 1 1 0,${2 * radius}
      a ${radius},${radius} 0 1 1 0,-${2 * radius}
    `;

  const diameter = Math.PI * 2 * radius;
  const progressStyle = {
    stroke: color,

    strokeDasharray: `${diameter}px ${diameter}px`,
    strokeDashoffset: `${((100 - percentage) / 100) * diameter}px`,
  };

  return (
    <svg
      id="progressbarcircle"
      className={style.CircularProgressbar}
      viewBox="0 0 100 100"
      width={150}
      height={150}
    >
      <path
        className="CircularProgressbar-trail"
        d={pathDescription}
        strokeWidth={strokeWidth}
        fillOpacity={0}
        style={{
          stroke: color2,
        }}
      />

      <path
        className="CircularProgressbar-path"
        d={pathDescription}
        strokeWidth={strokeWidth}
        fillOpacity={0}
        style={progressStyle}
      />

      <text
        className="CircularProgressbar-text"
        x={50}
        y={y}
        style={{
          fill: "#4FAEEB",
          fontSize: textSize,
          textAnchor: "middle",
          stroke: "1px",
        }}
      >
        {`${number}${percentageSymbol}`}
        {/* salto de linea */}
        <tspan x="50" dy="1.5em" style={{fontSize: "10px",fill: "#303030", opacity: "0.6"}}>{tipo}</tspan>
        
        
       
      </text>
    </svg>
  );
};
