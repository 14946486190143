import { Grid, Box } from "@mui/material";
import { Holidays } from "../../../interfaces/TabCollaborator.interfaces";

function CollaboratorHolidays({holidays}:{holidays:Holidays}) {

  return (
    <Box id="holidays">
      <Box className="title">
        <h4>Días de descanso</h4>
      </Box>
      <Box className="content">
        <div className="table-header">
          <Grid container>
            <Grid item xs={4}>
              <Box className="subtitle">
                Días
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box className="subtitle">
                Vacaciones
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box className="subtitle no-border">
                Económicos
              </Box>
            </Grid>
          </Grid>
        </div>
        <Box className="table">
          <Box className="table-container">
            <Grid container>
              <Grid item xs={4}>
                <Box className="indicator column">
                  Totales
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box className="line-below column">
                  { holidays.holidays_total }
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box className="line-below column">
                  { holidays.economic_total }
                </Box>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={4}>
                <Box className="indicator column">
                  Utilizados
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box className="line-below column">
                  { holidays.holidays_used }
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box className="line-below column">
                  { holidays.economic_used }
                </Box>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={4}>
                <Box className="indicator column">
                  Restantes
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box className="column">
                  { holidays.holidays_total ? holidays.holidays_total - holidays.holidays_used : 0 }
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box className="column">
                  { holidays.economic_total ? holidays.economic_total - holidays.economic_used : 0 }
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default CollaboratorHolidays;