import { useEffect, useContext, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import styles from '../../styles/Header/headerStyles.module.css';
import { GET_NOTIFICATIONS_SINVER } from '../../Querys/querys';
import { AdminContext } from "../../context/AdminContext/AdminContext";

const ContadorNotificaciones = ({cambioEstatus, status}:any) =>{

  const {adminState} = useContext(AdminContext);
  const [ getNotifications,{data:resultNotifications}] = useLazyQuery(GET_NOTIFICATIONS_SINVER,{
    fetchPolicy: 'no-cache'
  });
  const [notificationsNumber, setNotificationsNumber] = useState([]);

  useEffect(()=> {
    if(adminState && adminState.id){
      const interval = setInterval(()=>{
        getNotifications({
          variables:{
            id: adminState.id
          }
        });
      }, 1000 * 60 * 1);
      return () => clearInterval(interval);
    }
  },[adminState]);

  useEffect(()=> {
    if(adminState && adminState.id){
        getNotifications({
          variables:{
            id: adminState.id
          }
        });
    }
  },[adminState, status]);

  useEffect(()=>{
    if(resultNotifications && resultNotifications.GET_NOTIFICATIONS_SINVER){
      setNotificationsNumber(resultNotifications.GET_NOTIFICATIONS_SINVER);
    }
  },[resultNotifications]);

  return(
    <div>
      {
        notificationsNumber && notificationsNumber.length > 0 ? (
        <div 
          className={styles.nc_contenedor}
          onClick={()=> cambioEstatus()}
          >
          {notificationsNumber.length}
        </div>
        ) : (
          <></>
        )
      }
    </div>
  )
}


export default ContadorNotificaciones
