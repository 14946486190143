import moment from "moment";
import "./Schedule.css"

moment.locale('es-MX');

export default function ReactBigCalendar() {
  return (
    
    <div className="App">
    </div>
  );
}