import { useLazyQuery, useQuery } from "@apollo/client";
import { useEffect, useState, useCallback } from "react";
import {GET_COUNT_COMMENT_15 } from "../../Querys/querys";
import {
  BarChart,
  Bar,
  ResponsiveContainer,
} from "recharts";
import moment from "moment";
import style from "../../screens/BitsScreen.module.css";

const BarChartData = ({ mood_type = "", color = "" }) => {
  const [getDatos, {data:resultMood, startPolling, stopPolling}] = useLazyQuery(GET_COUNT_COMMENT_15,{
    fetchPolicy:'no-cache'
  })

  const [resultAllMood, setResultAllMood] = useState([]);
  const [dataGrafica, setDataGrafica] = useState([]);
  const [data, setData]:any = useState([]);
  const [percent, setPorcent] = useState("");
  const [fechas, setFechas]:any = useState([]);
  const [dataNew, setDataNew] = useState([]);

  const actualizaTotales = useCallback(() => {
    if (resultAllMood === undefined || dataGrafica === undefined) {
      setPorcent("0");
    } else {
      let porcentaje = parseFloat(
        ((dataGrafica.length / resultAllMood.length) * 100).toString()
      ).toFixed(2);
      if (porcentaje === "NaN") {
        setPorcent("0");
      } else {
        setPorcent(porcentaje);
      }
    }
  },[dataGrafica, resultAllMood]);

  useEffect(()=>{
    const startDay = moment().subtract(14, "day");
    const endDay = moment();
    const day = startDay.clone().subtract(1, "day");
    const dataGraph = [];

    while (day.isBefore(endDay, "day")) {
      let dia = day.add(1, "day").clone().format("YYYY-MM-DD");
      if (!fechas.includes(dia)) {
        dataGraph?.push({
          __typename: "MoodScoreByType15",
          name: mood_type,
          value: 0.1,
          fecha: dia,
        });
      }else{
        dataGraph?.push({
          __typename: "MoodScoreByType15",
          name: mood_type,
          value: 1,
          fecha: dia,
        });
      }
    }
    setData( dataGraph );
  }, [fechas])

  useEffect(()=>{
    if(resultMood && resultMood.GET_COUNT_COMMENT_15){
      setResultAllMood( resultMood.GET_COUNT_COMMENT_15);
    }
  }, [resultMood]);

  useEffect(() => {
    setDataGrafica( resultAllMood.filter((lis:any) => lis?.name === mood_type) );
  }, [resultAllMood]);
  
  useEffect(() => {  
    setFechas( dataGrafica?.map((d:any) => moment(d.fecha).format("YYYY-MM-DD")) );
    actualizaTotales();
  }, [dataGrafica]);

  useEffect(()=>{
    setDataNew( data?.sort((a:any, b:any) => {
      return new Date(a.fecha).getTime() - new Date(b.fecha).getTime();
    }) );
  },[data]);

  useEffect(() => {
    startPolling(2000)
  return () =>{
      stopPolling()
  }
}, [startPolling]);

  return (
    <>
      <ResponsiveContainer width={100} height={30}>
        <BarChart data={dataNew}>
          <Bar dataKey="value" fill={color} />
        </BarChart>
      </ResponsiveContainer>
      <div className={style.detallegrupor}>
        <span className={style.textognralmingray}>{percent + "%"} </span>
      </div>
    </>
  );
};

export default BarChartData;
