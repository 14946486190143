import { Grid, Box, Input, Select, FormControl, TextField, MenuItem, SelectChangeEvent } from "@mui/material";
import { SuccessfulAlert } from "../../alerts/successAlerts";
import { ErrorAlert } from "../../alerts/errorAlert";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Form } from "semantic-ui-react";
import { useMutation, useQuery } from "@apollo/client";
import { ICompany } from "../../interfaces/Company";
import { GET_ALL_ENTERPRISE, CREATE_TRANSACTION, GET_ALL_TRANSACTION, GET_ALL_COMPANY ,GET_COMPANY_VALUES, GET_ALL_TRANSACTION_BY_ENTERPRISE } from "../../Querys/querys";
import { useContext } from 'react';
import { AdminContext } from "../../context/AdminContext/AdminContext";

type Field = {
  label: string;
  name: string;
  type: string;
}

function PaymentsCentralForm({company, enterprise}:{
  company:ICompany, 
  enterprise:string
}) {
  const {adminState} = useContext(AdminContext);
  const fields = [
    { label: 'Nombre completo', name: 'fullname', type:'text'},
    { label: 'Clabe interbancaria', name: 'clabe', type:'text'},
    { label: 'Banco', name: 'bank', type:'text'},
    { label: 'Total a transferir', name: 'transactionAmount', type:'number'},
  ];
  const { data: resultCompanyValues } = useQuery(GET_COMPANY_VALUES, {
    variables: { companyId: company.id },
  });
  const companyValues = resultCompanyValues?.GET_COMPANY_VALUES;
  const initialValues = () => {
    return {
      fullname: "",
      clabe: "",
      bank: "",
      transactionAmount: 0
    };
  };
  const validationSchema = () => {
    return {
      fullname: Yup.string().required("El nombre es requerido"),
      clabe: Yup.string().length(18, "La clabe debe ser de 18 dígitos").required("La clabe interbancaria es requerida"),
      bank: Yup.string().required("El nombre del banco es requerido"),
      transactionAmount: Yup.number().positive("El número debe ser positivo").required("La cantidad a transferir es requerida")
    };
  };
  const [createTransaction] = useMutation(CREATE_TRANSACTION, {
    refetchQueries: [{ query: GET_ALL_TRANSACTION_BY_ENTERPRISE }],
  });
  const formik = useFormik({
    initialValues: initialValues(),
    validationSchema: Yup.object(validationSchema()),
    onSubmit: (formData) => {
      if(enterprise == ''){
        ErrorAlert({text:"Es necesario seleccionar una empresa"});
      }else{
        createTransaction({
          variables: {
            input: {
              fullname: formData.fullname,
              clabe: formData.clabe,
              bank: formData.bank,
              transactionAmount: formData.transactionAmount,
              comission: companyValues ? companyValues.totalDispersar : 0,
              transactionTotal: companyValues ? companyValues.totalDispersar + formData.transactionAmount : formData.transactionAmount,
              enterpriseId: Number(enterprise),
              companyId: Number(company.id),
              User: adminState?.Usuario
            },
          },
        }).then(()=>{
          SuccessfulAlert({text:"Beneficiario agregado con éxito"});
        }).catch((e)=>{
          ErrorAlert({text:"Ocurrió un error en el servidor"});
        });
      }
    },
  });

  return (
      <Form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Box className="beneficiary-form-container">
              <p className="header-title">Datos del beneficiario:</p>
              <Grid container spacing={2} alignItems="center">
                {
                  fields.map((field:Field, key:number)=>(
                    <PaymentCentralInput field={field} key={key} formik={formik}></PaymentCentralInput>
                  ))
                }
                <Grid item xs={12} md={2}>
                  <span>Comisión</span>
                </Grid>
                <Grid item xs={12} md={10}>
                  <p>$ { companyValues ? companyValues.totalDispersar.toFixed(2) : '0.00' }</p>
                </Grid>
                <Grid item xs={12} md={2}>
                  <span>Monto a descontar</span>
                </Grid>
                <Grid item xs={12} md={10}>
                <p>$ { companyValues ? (companyValues.totalDispersar + formik.values['transactionAmount'] ).toFixed(2) : '0.00' } </p>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={6} md={10}></Grid>
          <Grid item xs={6} md={2}>
            <button className="primary-button" type="submit">Guardar</button>
          </Grid>
        </Grid>
      </Form>
  )
}

function PaymentCentralInput({field, formik}: {field:Field, formik:any}){
  return (
    <>
      <Grid item xs={12} md={2}>
        <span>{field.label}</span>
      </Grid>
      <Grid item xs={12} md={10}>
        <FormControl fullWidth={true}>
          <TextField
            id={field.name}
            name={field.name}
            variant="outlined"
            type={field.type}
            onChange={formik.handleChange}
            error={formik.touched[field.name] && Boolean(formik.errors[field.name])}
            helperText={formik.touched[field.name] && formik.errors[field.name]}
          /> 
        </FormControl>
      </Grid>
    </>
  )
}

export default PaymentsCentralForm;