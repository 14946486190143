import {Dialog} from "@material-ui/core"
import PayrollProcessContext from "../../../context/PayrollProcess/PayrollProcessContext";
import {useContext} from "react";
import { clearPayrollProcess } from "../../../context/PayrollProcess/Actions";
import styles from '../../Payroll/Modals/PayrollModals.module.css'

const MensajeNoProcesado = () =>{
  const {state, dispatch} = useContext(PayrollProcessContext)

  const handleClose = () =>{
    clearPayrollProcess({}, dispatch)
  
  }

  return(
    <Dialog aria-labelledby='costumized-dialog-title' open={state.alertaNoProcesado} fullWidth={false}  onClose={handleClose} maxWidth={"md"}>
      <div className={styles.contenedorPrincipal}>
        <div className={styles.contenedorCerrarModal}>
          <div
            className={styles.cerrarModal}
            onClick={()=> handleClose()}
          >
          </div>
        </div>
        <div className={styles.contenedorPrincipal}>
          <div className={styles.fim_iconoPrincipal}>
            <div className={styles.fim_iconoComplemento}>
            </div>
          </div>
        </div>
        <div className={styles.tituloAtencion}>
          ¡Atenci&oacute;n!
        </div>
        <div className={styles.em_mensajePrtincipal}>
         El movimiento no se proceso por el siguiente motivo
          </div>
          <div className={styles.em_mensajePrtincipal}>
            {state.Comentario}
          </div>
        <div className={styles.em_contenedorBotones}>
              <button className={`${styles.botonesAtencion} ${styles.continuarAtencion}`}
                onClick={()=> handleClose()}
              >
                Aceptar
              </button>

        </div>
      </div>
    </Dialog>
  )
}
export default MensajeNoProcesado