
export const styleCollaboratorTab = {
    containerSearcher: {
        width: "400px",
        height: "40px",
        margin: "0 59px 0 0",
        padding: "8px 12px 8px 15px",
        border: "0.8px solid #c7ccdc",
        borderRadius: "8px",
    },
    inputSearcher: {
        border: 0,
        outline: "none",
        width: "400px",
        margin: "-6px 0px 0 0",
        fontSize: "14px",
        color: "#838383",
        fontStyle: "bold",
    },
    containerFilter: {
        width: "130px",
        height: "40px",
        margin: "0 16px 0 0",
        borderRadius: "4px",
        padding: "8px",
        alignContent: "center"
    }
};