import { useState, ChangeEvent, useContext } from "react";
import { Grid } from "@material-ui/core";
import Box from '@mui/material/Box';
import './MyMood.css';
import MyMood from "./MyMood";
import Surveys from "./Surveys";
import RiskSurveys from "./RiskSurveys";
import CustomTabs from "../Collaborators/Tab/CustomTabs";
import CustomTab from '../Collaborators/Tab/CustomTabMain';
import AddTable from "..//Collaborators/CollaboratorTab/AddTable";
import {openSurvey,openRiskSurvey } from '../../context/MyMoodContext/Actions';
import MyMoodContext from "../../context/MyMoodContext/MyMoodContext";

function MyMoodContent (){

  const [tab, setTab] = useState(0);
    const handleChange = (event: ChangeEvent<{}>, newValue: number) => {
        setTab(newValue)
        localStorage.setItem('currentPill', String(newValue));
    };
    const {dispatch} = useContext(MyMoodContext);

  return (
    <div>
      <Box mt={3} ml={5} className="Title" mb={2}>
          My Mood
      </Box>
      <Box p={5} pb={3} pt={0}>

          <Grid
              container
              justify="flex-start"
              alignItems="center"
          >   
              <Grid item xs={12} md={6}>
                  <CustomTabs
                      value={tab}
                      onChange={handleChange}
                      aria-label="simple tabs example"
                  > 
                      <CustomTab label="My Mood" value={0}/>
                      <CustomTab label="Encuestas" value={1}/>
                      <CustomTab label="Riesgo psicosocial" value={2}/>
                  </CustomTabs>
              </Grid>
              <Grid item xs={12} md={6}>
                  {
                      tab === 1 && (
                          <Box
                              display="flex"
                              flexDirection="row"
                              justifyContent="flex-end"
                              style={{ width: "100%" }}
                          >  
                              <AddTable
                              func={()=>openSurvey({newSurvey:true}, dispatch)}
                              img={"icono_agregar_blue_2.svg"}
                              text={"Nueva encuesta"}
                              noIcon={true}
                              />
                          </Box>
                      )
                  }
                  {
                      tab === 2 && (
                          <Box
                              display="flex"
                              flexDirection="row"
                              justifyContent="flex-end"
                              style={{ width: "100%" }}
                          >  
                              <AddTable
                              func={()=>openRiskSurvey({newRiskSurvey:true}, dispatch)}
                              img={"icono_agregar_blue_2.svg"}
                              text={"Nuevo test"}
                              noIcon={true}
                              />
                          </Box>
                      )
                  }
              </Grid>
          </Grid>

      </Box>
      <div className="contenedor2">
          {tab === 0 && <MyMood value={tab} index={0} />}
          {tab === 1 && <Surveys value={tab} index={0} />}
          {tab === 2 && <RiskSurveys value={tab} index={0} />}
      </div>
    </div>
  )
}

export default MyMoodContent;