import { useContext, useEffect, useState } from "react";
import { CreateAdministrators } from "./Modals/createAdministrators";
import { UpdateAdministrators } from "./Modals/updateAdministrators";
import { createAdministratorsModal } from "../../context/AdministratorsContext/Actions";
import {DeleteAdministrators2} from "./Modals/deleteAdministrators2";
import AdministratorsContext from "../../context/AdministratorsContext/AdministratorsContext";
import { MenuAdministrators } from "./MenuListAdministrators";
import styles from "./Administrators.module.css";
import { useQuery } from "@apollo/client";
import { GET_ALL_USER_ADMIN } from "../../Querys/querys";
import CollaboratorIconText from "../CollaboratorIcon/CollaboratorIconText";

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

export const Administrators = (props: TabPanelProps) => {
  const { dispatch } = useContext(AdministratorsContext);
  const [adminList, setAdminList] = useState([]);

  const createModal = (id: string, createModal: boolean) => {
    createAdministratorsModal({ id, createModal }, dispatch);
  };

  const {data: adminListData} = useQuery(GET_ALL_USER_ADMIN);
  const adminListQuery = adminListData?.GET_ALL_USER_ADMIN;

  useEffect(() => {
    
    if (adminListQuery) {
      setAdminList(adminListQuery);
    }
  }, [adminListQuery]);


  return (
    <div className={styles.contenedorPrincipal}>
      <div className={styles.flex}>
        <div className={styles.textoTitulo}>Administradores</div>
        <div>
          <button
            className={`${styles.botonAgregar} ${styles.flex}`}
            onClick={() => createModal("1", true)}
          >
            <img
              className={styles.iconoBoton}
              src={`/assets/svg/icono-addPerson.svg`}
              alt="icono"
            />
            <div>
              <span className={styles.textoBoton}>Agregar administrador</span>
            </div>
          </button>
        </div>
      </div>
      <div className={styles.contenedorTabla}>
        <div className={`${styles.contenedorTituloTabla} ${styles.flex}`}>
          <div className={styles.columnaUnoTabla}>
            <span>Nombre</span>
          </div>
          <div className={styles.columnaDosTabla}>
            <span>Opciones</span>
          </div>
        </div>
        <div>
          <ul>
            {adminList.map((lis: any, index:number) => (
              <li className={`${styles.filaContenidoTabla} ${styles.flex}`}
              key={index}
              >
                <div className={styles.columnaUnoFlex}>
                  {
                    lis.img && lis.img !== ""  ? (
                      <img
                        src={lis.img}
                        alt="img"
                        className={styles.imagenColaborador}
                      />
                    ) : (
                      <CollaboratorIconText
                       Nombre={lis.FullName && lis.FullName.split(' ')[0] ? lis.FullName.split(' ')[0] : ''}
                       Apellido={lis.FullName && lis.FullName.split(' ')[1] ? lis.FullName.split(' ')[1] : ''}
                       width="33px"
                       height="33px"
                       />
                    )
                  }
                  <div className={styles.contenedorDatos}>
                    <div className={styles.nombreAdmin}>
                      <span>{lis.FullName}</span>
                    </div>
                    <div className={styles.correoAdmin}>
                      <span>{lis.Usuario}</span>
                    </div>
                  </div>
                </div>

                <div>
                  <MenuAdministrators _id={lis._id} FullName={lis.FullName} Usuario={lis.Usuario} img={lis.img}/>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <CreateAdministrators />
      <UpdateAdministrators />
      <DeleteAdministrators2 />
    </div>
  );
};
