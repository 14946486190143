import CampaignContent from "../components/Campaigns/CampaignContent"
import CampaignState from "../context/CampaignsContext/CampaignState"

const CampaignScreen = () => {
    return (
        <CampaignState>
          <CampaignContent />
        </CampaignState>
      )
}

export default CampaignScreen