import { useContext } from "react";
import CalendarsContext from "../../../context/NewCalendarContext/CalendarsContext";
import { clearDeleteEditEventModall } from "../../../context/NewCalendarContext/Actions";
import { Button, Dialog, DialogContent, TextField  } from "@material-ui/core"
import styles from '../Calendars.module.css'
import { Formik, Form} from "formik";
import * as Yup from "yup";
import { useMutation, useQuery } from "@apollo/client";
import { GET_EVENT, DELETE_EVENT, GET_COUNT_EVENT_NONWORKINGDAY, GET_ALL_EVENT_CALENDAR_BY_MONTH_YRAR } from "../../../Querys/querys";
import { SuccessfulAlert } from "../../../alerts/successAlerts";
import moment from "moment";


const DeleteEvent = ({calendarDate, date}:any) => {
   const {state, dispatch} = useContext(CalendarsContext)
   const [deleteCalendars] = useMutation(DELETE_EVENT,{
        refetchQueries:[
                    {query:GET_COUNT_EVENT_NONWORKINGDAY},
                    {query:GET_ALL_EVENT_CALENDAR_BY_MONTH_YRAR, variables:{calendarId: parseInt(state._id), month: parseInt(moment(date).format('M')), year: parseInt(moment(date).format('YYYY'))}},
                ],
    })
   const handleClose = ()=>{
        clearDeleteEditEventModall({}, dispatch);
   }
   const { loading, data } =  useQuery(GET_EVENT, {
       variables: {
               id: state.idAux,
           }
   });
   
   if(loading) return null;

   const event = data?.GET_EVENT;

   const initialValues = () => {
       return {
           mensaje: ""
       }
   }

   return (
       <div>
       <Formik
         initialValues={initialValues()}
         validationSchema={validationSchema}
         onSubmit={formData => {
           const idDoc = Number(state.idAux) 
           deleteCalendars({
               variables:{
                deleteEventId:idDoc
               },
           }).then(()=>{
            //calendarDate(moment())
            SuccessfulAlert({text:"Evento eliminado con éxito"});
        });
           handleClose();
         }}
       >
         {({
           values,
           errors,
           touched,
           handleChange,
           handleSubmit,
         }) => (
           <Dialog open={state.deleteEditEventModal} aria-labelledby="form-dialog-title" maxWidth="sm" fullWidth={true}>
               <div className={styles.dialogContainer}>
                   <div className={styles.centerContainer}>
                       <h3 id="form-dialog-title" data-testid="TitleModal">¿Confirmas eliminar el d&iacute;a Evento?</h3>
                   </div>
                   <div className={styles.centerContainer}>
                       <span className={styles.tituloDatosUpdate}>{event?.name}</span>
                   </div>
                   <div className={styles.centerContainer}>
                       <span className={styles.tituloNombreUpdate}>Una vez eliminado no podr&aacute;s recuperar la informaci&oacute;n</span>
                   </div>
                   <div className={styles.centerContainer}>
                       <span className={styles.tituloNombreUpdate}>Escribe ELIMINAR en may&uacute;sculas para confirmar</span>
                   </div>
                   <DialogContent>
                   <Form onSubmit = {handleSubmit}>
                       <TextField 
                           fullWidth 
                           className={styles.dialogFields} 
                           name="mensaje" 
                           label="Mensaje" 
                           variant="outlined" 
                           value = {values.mensaje}
                           onChange={handleChange}
                           error={touched.mensaje && Boolean(errors.mensaje)}
                           helperText = {touched.mensaje && errors.mensaje}
                           size="small" />
                       <div className={styles.centerContainer}>
                           <Button className="buttonCancel" onClick={() => handleClose()}>
                                   Cancelar
                           </Button>
                           <Button type="submit" className={styles.botonEliminar} style={{marginLeft: "20px", fontWeight:"bold"}}>
                               <span>Confirmar</span> 
                           </Button>
                       </div>
                       </Form>
                   </DialogContent>
               </div>
           </Dialog>
         )}
       </Formik>
     </div>

   )
}


const validationSchema = Yup.object().shape({
   mensaje: Yup.string().required("El  mensaje de confirmacion es requerido").oneOf(['ELIMINAR'], "El mensaje debe coincidir")
})

export default DeleteEvent