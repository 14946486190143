/* eslint-disable array-callback-return */
import { useState,useEffect } from "react";
import { Grid } from "@material-ui/core";
import Box from '@mui/material/Box';
import SelectIcon from "../../../assets/icons/reportSelect.svg";
import selSelectIcon from "../../../assets/icons/selReportSelect.svg";
import SeeIcon from "../../../assets/icons/reportSee.svg";
import selSeeIcon from "../../../assets/icons/selReportSee.svg";
import aplicar from "../../../assets/icons/aplicar.svg";
import Filter from "../../../assets/icons/filterReport.svg";
import SaveIcon from '@mui/icons-material/Save';
import TreeView from "./TreeView";
import FilesTable from "./FilesTable";

const FilterContainer = ({report,setReport,deleteColumns,setDeleteColumns,inpuntValues,setInpuntValues}:any) => {
    const [selSelect    ,setSelSelect  ]  = useState(false);
    const [selView      ,setSelView    ]  = useState(false);    
    const [valueFilter  ,setValueFilter]  = useState(0);
    const [nameColumns  ,setNameColumns]  = useState([]);
    
  
    const handleSelect=()=>{   
        setSelView(false)
        setValueFilter(0)     
        selSelect===false?setSelSelect(true):setSelSelect(false)        
    }

    const handleSee=()=>{   
        setSelSelect(false)   
        setValueFilter(1)       
        selView===false?setSelView(true):setSelView(false)        
    }

    useEffect(() => {
      const columns= report.map((re:any) => {
         return(Object.keys(re))
      })    
      columns.length >0? setNameColumns(columns[0]):setNameColumns([])  
     
    }, [report]);

   const handleAplicar=()=>{
        const copyObj=[...report] 
        
        if(valueFilter===0){
          const valInputs=Object.keys(inpuntValues).length
            if(valInputs>0){ 
              let arrayFilter:any=[];
                Object.entries(inpuntValues).map( ([k,values]:any,indexVal) => {
                  if(indexVal !== 0){                
                    const copyArray=[...arrayFilter]
                    arrayFilter=[];
                    copyArray.map((newObj:any)=>{
                    if(((typeof newObj[k] === "string") && (typeof values === "string")))
                      {
                        if(newObj[k].toUpperCase().includes(values.toUpperCase())) {
                          arrayFilter.push(newObj)                     
                        }                         
                      }else{
                        if(newObj[k] === parseInt(values))
                            {
                              arrayFilter.push(newObj)
                          }
                      }                
                    setReport(arrayFilter)
                    })
                  }
                  else{
                    copyObj.map((objRep:any,index:any) => {
                      if(((typeof objRep[k] === "string") && (typeof values === "string")))
                      {
                        if(objRep[k] !== null){
                          if(objRep[k].toUpperCase().includes(values.toUpperCase())) {
                                arrayFilter.push(objRep)                     
                            }                               
                        }
                      }
                      else{
                        if(objRep[k] === parseInt(values))
                            {
                              arrayFilter.push(objRep)
                          }    
                        } 
                      setReport(arrayFilter)                        
                    }) 
                  }               
              })              
            }    

            if((deleteColumns.length >0 ) && (deleteColumns.length < nameColumns.length )){
              const arrayRep:any=[];
              copyObj.map((objRep:any)=>{ 
                  deleteColumns.map((column:any)=>{
                      delete objRep[column]; 
                      arrayRep.push(objRep)     
                  })              
              })
              setReport(arrayRep)          
            }
      }
    }

  return (
    <Box className='containerFilterInforme'>
      <Grid className="buttonsReportSingle">   
      {selSelect === false?
      <img  className='select' 
            src={SelectIcon} alt="select" 
            onClick={handleSelect}
        />
        :<img  className='select' 
          src={selSelectIcon} alt="select" 
          onClick={handleSelect}
       />
      }
      {selView=== false ?
       <img  
        className='see' 
        src={SeeIcon} alt="see"
        onClick={handleSee} />
      :
      <img  
       className='see' 
       src={selSeeIcon} alt="see"
       onClick={handleSee} /> 
      }      
      </Grid>
      <Grid className="separatorReportSingle">   
          <div className="imgAplicar">
            {selSelect === true? 
                <img  
                className='aplicar' 
                src={aplicar} alt="aplicar"
                onClick={()=> handleAplicar()}
                />:<></> 
            }
            </div>
            <div className="containerFilter">
            {selSelect === true?   
                <img  
                className='filter' 
                src={Filter} alt="filter"
                // onClick={handleSee} 
                />:
                <></> 
             }
           {selSelect === true || selView=== true?         
             <SaveIcon  className='save' fontSize="small" color="disabled" />:<></>
            }
            </div>       
      </Grid>
      <Grid className="filtersReport">
      {
       selSelect === false && selView=== true?   
        <TreeView/>
       :selSelect === true && selView=== false?
         nameColumns.length >0 ?
          nameColumns.map((column:any)=>{
            return (
                <FilesTable 
                       type             = {'text'}
                       name             = {column}
                       label            = {column}
                       width            = {'250px'}
                       nameColumns      = {nameColumns}
                       setNameColumns   = {setNameColumns}
                       deleteColumns    = {deleteColumns}
                       setDeleteColumns = {setDeleteColumns}
                       inpuntValues     = {inpuntValues}
                       setInpuntValues  = {setInpuntValues}
                />)      
          })            
         :<></>        
         :<></>        
        }
      </Grid>    
    </Box>
  )
}

export default FilterContainer


