import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import TablesValueTable from "./TablesValueTable";
import TableISR from "./TableISR";
import TableSubsidy from "./TableSubsidy";
import TableISN from "./TableISN";
import TabsUnstyled from "@mui/base/TabsUnstyled";
import TabsListUnstyled from "@mui/base/TabsListUnstyled";
import TabPanelUnstyled from "@mui/base/TabPanelUnstyled";
import TabUnstyled from "@mui/base/TabUnstyled";
import './TablesValues.css'
import CheckPermission from "../../helpers/Administradores/Permissions";

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
};

const TablesValueTab = (props: TabPanelProps) => {
    
    const [activo, setActivo] = useState('1')
    const params = useParams<any>();
    useEffect(() => {
    }, [params]);

    return (
      <>
        <TabsUnstyled defaultValue={0} className="mainConceptsHeader">
        <div className="tablesValuesHeader">
          <TabsListUnstyled>
          {CheckPermission("ConfiguracionNominas.TablasValores.ValoresReferencia") && ( 
            <TabUnstyled  className={`tableValuesBotonHeader1 ${activo === '1' ? "activo" : "desactivo"}`} onClick={(e)=> setActivo('1')}>
              <span className="conceptstextoBotonHeader">Valores de referencia</span>
            </TabUnstyled>)}
            {CheckPermission("ConfiguracionNominas.TablasValores.TablaISR") && ( 
            <TabUnstyled className={`tableValuesBotonHeader2 ${ activo === '2' ? "activo" : "desactivo"}`} onClick={(e)=> setActivo('2')}>
              <span className="conceptstextoBotonHeader" >Tablas periódicas de ISR</span>
            </TabUnstyled>)}
            {CheckPermission("ConfiguracionNominas.TablasValores.TablaSubsidio") && (
            <TabUnstyled  className={`tableValuesBotonHeader3 ${ activo === '3' ? "activo" : "desactivo"}`} onClick={(e)=> setActivo('3')}>
              <span className="conceptstextoBotonHeader" >Tablas periódicas de subsidio</span>
            </TabUnstyled> )}
            {CheckPermission("ConfguracionNominas.TablasValores.ISN") && ( 
            <TabUnstyled className={`tableValuesBotonHeader4 ${ activo === '4' ? "activo" : "desactivo"}`} onClick={(e)=> setActivo('4')}>
              <span className="conceptstextoBotonHeader" >Impuesto sobre nómina</span>
            </TabUnstyled>)}
          </TabsListUnstyled>
          </div>
          <div className="contenedorPrincipal">
            {CheckPermission("ConfiguracionNominas.TablasValores.ValoresReferencia") && ( 
              <TabPanelUnstyled value={0}><TablesValueTable {...props}  /></TabPanelUnstyled>
            )}
            {CheckPermission("ConfiguracionNominas.TablasValores.TablaISR") && (
              <TabPanelUnstyled value={1}>< TableISR {...props}  /></TabPanelUnstyled>
            )}
            {CheckPermission("ConfiguracionNominas.TablasValores.TablaSubsidio") && (
              <TabPanelUnstyled value={2}>< TableSubsidy {...props}  /></TabPanelUnstyled>
            )}
            {CheckPermission("ConfguracionNominas.TablasValores.ISN") && ( 
              <TabPanelUnstyled value={3}>< TableISN {...props}  /></TabPanelUnstyled>
            )}

          </div>
        </TabsUnstyled>
      </>
    )
}

export default TablesValueTab;
