import { useContext, useEffect, useState, useCallback } from "react";
import {
  FormControl,
  Grid,
  TextField,
  Button,
} from "@material-ui/core";
import Box from '@mui/material/Box';
import { Form } from "semantic-ui-react";
import CollaboratorContext from "../../../context/CollaboratorContext/CollaboratorContext";
import { handleSetActiveStep, updateData } from "../../../helpers/Collaborator/Collaborator";
import { useFormik } from "formik";
import * as Yup from "yup";

import {
  GET_ALL_PROJECT,
  UPDATE_USERS,
  GET_USERS_BY_ID,
  GET_ALL_PUESTOS,
  GET_ALL_USERS_COLLABORATOR,
  GET_EXCEL_ACTIVE_USER,
  CREATE_POSITION_HISTORY,
  GET_TIMELINE_EVENTS,
  GET_USER_MOVEMENT_ALTA
} from "../../../Querys/querys";
import { useMutation, useQuery } from "@apollo/client";
import moment from "moment";
import { SuccessfulAlert } from "../../../alerts/successAlerts";
import SaveIcon from "@material-ui/icons/Save";
import { IProject } from "../../../interfaces/Project";
import CheckPermission from "../../../helpers/Administradores/Permissions";
import { Autocomplete } from "@mui/material";
import { AdminContext } from "../../../context/AdminContext/AdminContext";

const InputData = () => {
  const { state, dispatch } = useContext(CollaboratorContext);
  const { adminState } = useContext(AdminContext);
  const [projects, setProjects] = useState<Array<IProject>>([]);
  const disabled = false;
  const [puestos, setPuestos] = useState<Array<any>>([]);
  const [filteredPuestos, setFilteredPuestos] = useState<Array<any>>([]);
  const [selectedProject, setSelectedProject]:any = useState(null);
  const [selectedPosition, setSelectedPosition]:any = useState(null);
  const [updateColaboradores] = useMutation(UPDATE_USERS, {
    refetchQueries: [
      {
        query: GET_USERS_BY_ID,
        variables: { getUsersId: state.collaborator.id },
      },
      {
        query: GET_TIMELINE_EVENTS,
        variables: { id: state.collaborator.id },
      },
      { query: GET_ALL_USERS_COLLABORATOR },
      { query: GET_EXCEL_ACTIVE_USER }
    ],
  });
  const { data: resultMovement } = useQuery(GET_USER_MOVEMENT_ALTA,{
    variables: { id: Number(state.collaborator.id) },
    fetchPolicy: 'no-cache'
  });
  const [createPositionHistory] = useMutation(CREATE_POSITION_HISTORY);

  const { data: resultProject } = useQuery(GET_ALL_PROJECT,{
    fetchPolicy: 'no-cache'
  });
  const allProject = resultProject?.GET_ALL_PROJECT;

  const { data: resultPuestos } = useQuery(GET_ALL_PUESTOS,{
    fetchPolicy: 'no-cache'
  });
  const allPuestos = resultPuestos?.GET_ALL_PUESTOS;

  useEffect(() => {
    if (allProject) setProjects(allProject);
    if (allPuestos) setPuestos(allPuestos);
  }, [allProject, allPuestos]);

  const [isMovementDone, setIsMovementDone] = useState(false);

  useEffect(()=>{
    if(resultMovement && resultMovement.GET_USER_MOVEMENT_ALTA && resultMovement.GET_USER_MOVEMENT_ALTA[0]){
      const data = resultMovement.GET_USER_MOVEMENT_ALTA[0];
      if(data.Externo || data.solicitudIDSE){
        setIsMovementDone(true);
      }
    }
  }, [resultMovement]);

  const getDefaultValue = useCallback(() => {
    if(state.collaborator.idProject){
      const selected:any = projects.filter((project)=>{
        return Number(project.id) === Number(state.collaborator.idProject)
      });
      if(selected.length > 0){
        return { label: selected[0].proyectNameAndClient, id: selected[0].id }
      }else{
        return null;
      }
    }else{
      return null;
    }
  },[projects, state.collaborator]);

  const getDefaultValuePosition = useCallback(() => {
    if(state.collaborator.puestoId){
      const selected:any = puestos.filter((puesto)=>{
        return Number(puesto.idPuesto) === Number(state.collaborator.puestoId)
      });
      if(selected.length > 0){
        return { label: selected[0].NombrePuesto, id: selected[0].idPuesto }
      }else{
        return null;
      }
    }else{
      return null;
    }
  },[puestos, state.collaborator]);

  useEffect(()=>{
    if(state.collaborator.idProject){
      setSelectedProject( getDefaultValue()?.label );
    }
  }, [state, projects, getDefaultValue]);

  useEffect(()=>{
    if(state.collaborator.puestoId){
      setSelectedPosition( getDefaultValuePosition()?.label );
      if(state.collaborator.idProject){
        const filteredPositions = puestos.filter((item:any)=>{
          return Number(item.projectId) === Number(state.collaborator.idProject);
        });
        setFilteredPuestos(filteredPositions);
      }
    }
  }, [state, puestos, getDefaultValuePosition]);

  const onChangeProject = (e:any, value:any) => {
    setSelectedProject( value.label ); 
    formik.setFieldValue("idProject", value.id);
    const filteredPositions = puestos.filter((item:any)=>{
      return Number(item.projectId) === Number(value.id);
    });
    setFilteredPuestos(filteredPositions);
  }


  const handleChange = async (e: any) => {
    await updateData(e, state, dispatch, 0);
  };

  const initialValues = () => {
    return {
      dateOfAdmission: state.collaborator?.dateOfAdmission,
      area: state.collaborator?.area,
      Puesto: state.collaborator?.Puesto,
      puestoId: state.collaborator?.puestoId,
      officePlace: state.collaborator?.officePlace,
      accept_info: state.collaborator?.accept_info,
      idProject: state.collaborator?.idProject,
      project: state.collaborator?.project,
    };
  };

  const formik = useFormik({
    initialValues: initialValues(),
    validationSchema: Yup.object(validationSchema()),
    onSubmit: (formData) => {
      
      formData.accept_info = state.collaborator?.accept_info;
      formData.puestoId = parseInt(formData.puestoId);
      formData.idProject = parseInt(formData.idProject);
      formData.dateOfAdmission = moment(formData.dateOfAdmission).toISOString();
      //get NombrePuesto from puestos by id
      const puesto = puestos.find((p) => {
          return String(p.idPuesto) === String(formData.puestoId);
      });
      formData.Puesto = puesto.NombrePuesto;
      
      const project = projects.find((p) => {
        return String(p.id) === String(formData.idProject);
      });
      formData.area = project?.client;
      formData.project = project?.proyectName;
      if( state.collaborator && state.collaborator.puestoId && Number(state.collaborator.puestoId) !== Number(formData.puestoId) ){
        createPositionHistory({
          variables:{
            id: state.collaborator?.id,
            currentPosition: formData.puestoId,
            previousPosition: state.collaborator.puestoId,
            userAdd: adminState ? adminState.Usuario : ''
          }
        })
      }

      updateColaboradores({
        variables: {
          updateUsersId: state.collaborator?.id,
          input: formData,
          process: {
            tab: "information",
            section: "section_2",
          },
        },
      }).then(() => {
        handleSetActiveStep(2, dispatch);
        SuccessfulAlert({ text: "Se actualizó correctamente" });
      });
    },
  });

  return (
    <Form onSubmit={formik.handleSubmit}>
      <div
        className={
          state.sections[1] >= 100 ? `validate__border` : `novalidate__border`
        }
      >
        <Grid
          direction="row"
          container
          justify="flex-start"
          alignItems="center"
        >
          <Grid xs item>
            <span className="Fecha-de-nacimiento">Fecha de ingreso</span>
          </Grid>
          <Grid xs item container justify="flex-end" alignItems="center">
            <TextField
              type="date"
              defaultValue={
                state.collaborator
                  ? moment(state.collaborator.dateOfAdmission).format(
                      "YYYY-MM-DD"
                    )
                  : ""
              }
              autoFocus={true}
              name="dateOfAdmission"
              variant="outlined"
              size="small"
              onChange={formik.handleChange}
              error={Boolean(formik.errors.dateOfAdmission)}
              onBlur={(e) => handleChange(e)}
              InputProps={{
                readOnly: false,
              }}
              disabled={disabled || isMovementDone}
            />
          </Grid>
        </Grid>

        <Box mb={2} mt={2}>
          <FormControl variant="outlined" fullWidth={true} size="small">
            <TextField
              type="text"
              name="officePlace"
              label="Lugar de trabajo"
              defaultValue={state.collaborator?.officePlace}
              variant="outlined"
              size="small"
              fullWidth={true}
              onChange={formik.handleChange}
              error={formik.touched.officePlace && Boolean(formik.errors.officePlace)}
              onBlur={(e) => handleChange(e)}
              InputProps={{
                readOnly: false,
              }}
              disabled={disabled}
              helperText={formik.touched.officePlace &&!state.collaborator?.officePlace && "Obligatorio"}
            />
          </FormControl>
        </Box>

        <Grid direction="row" container spacing={2}>
          <Grid xs item>
            <FormControl variant="outlined" fullWidth={true} size="small">
              <Autocomplete
                disablePortal
                id="idProject"
                options={projects.map((project:any)=>{
                  return {  label: project.proyectNameAndClient, id: project.id, value:project.id}
                })}
                onBlur={() => formik.setTouched({ 'idProject': true })}
                onChange={(e,value) => {onChangeProject(e,value)}}
                value={ selectedProject }
                renderInput={(params) => <TextField {...params} 
                  name="idProject"
                  variant="outlined" 
                  size="small" 
                  label="Selecciona área o proyecto" 
                  helperText={formik.touched.idProject && formik.errors.idProject}
                  error={formik.touched.idProject && Boolean(formik.errors.idProject)} />
                }
              />
            </FormControl>
          </Grid>
          <Grid xs item>
            <FormControl variant="outlined" fullWidth={true} size="small">
              <Autocomplete
                disablePortal
                noOptionsText={'No se encontró ningun puesto'}
                id="puestoId"
                options={filteredPuestos.map((puesto:any)=>{
                  return {  label: puesto.NombrePuesto, id: puesto.idPuesto, value:puesto.idPuesto}
                })}
                onBlur={() => formik.setTouched({ 'puestoId': true })}
                onChange={(e,value) => {setSelectedPosition( value.label ); formik.setFieldValue("puestoId", value.id)}}
                value={ selectedPosition }
                renderInput={(params) => <TextField {...params} 
                  name="puestoId"
                  variant="outlined" 
                  size="small" 
                  label="Puesto" 
                  helperText={formik.touched.puestoId && formik.errors.puestoId}
                  error={formik.touched.puestoId && Boolean(formik.errors.puestoId)} />
                }
              />
            </FormControl>
          </Grid>
        </Grid>
      </div>
      {CheckPermission(
        "Colaboradores.Colaboradores.Editar.InformacionAlta.DatosIngreso.Guardar"
      ) && (
        <Grid
          direction="row"
          container
          justify="flex-end"
          alignItems="center"
          style={{ marginTop: "20px" }}
        >
          <Button type="submit" className="buttonSave">
            <SaveIcon />
            &nbsp; Guardar
          </Button>
        </Grid>
      )}
    </Form>
  );
};

const validationSchema = () => {
  return {
    dateOfAdmission: Yup.string().required("Obligatorio"),
    idProject: Yup.string().nullable().required("Obligatorio"),
    puestoId: Yup.string().nullable().required("Obligatorio"),
    officePlace: Yup.string().required("Obligatorio"),
  };
};

export default InputData;
