import { Types } from "./OutsourcersContext";

const OutsourcersReducer = (state:any, action: any) =>{
  const {payload, type} = action
  switch(type){
    case Types.CREATE_OUTSOURCERS:

      return {
        ...state,
        createOutsourcers: true
      }
      
    case Types.CREATE_EDITOUTSOURCERS:
  
        return {
          ...state,
          _id: payload._id,
          editOutsourcers: true
        }
        
    case Types.CREATE_DELETEOUTSOURCERS:
  
        return {
          ...state,
          _id: payload._id,
          deleteOutsourcers: true
        }
        
    case Types.CLEAR_OUTSOURCERS:
      return{
        ...state,
        _id:'',
        createOutsourcers: false,
        editOutsourcers: false,
        deleteOutsourcers: false
      }
      

    
    default:
    return state 
  }
}

export default OutsourcersReducer

