import { useMutation, useQuery } from "@apollo/client";
import { Grid } from "@material-ui/core";
import Box from '@mui/material/Box';
import { useHistory, useParams } from "react-router-dom";
import { useState, useEffect, useContext, useCallback } from "react";
import { getDateDay, getDateYear } from "../helpers/Payroll/Payroll";
import styles from "../../src/components/Payroll/PayrollStyles.module.css"
import { 
        GET_PAYROLLCOLLABORATOR_CANCEL_TIMBRE, 
        GET_PAYROLL, 
        UPDATE_PAYROLL_COLLABORATOR_TOKENCANCELATIMBRE,
        UPDATE_PAYROLL_COLLABORATOR_XML_ACUSE,
        GET_ALL_COMPANY,
        GET_PAYROLLCOLLABORATOR_TIMBRADO
      } from "../Querys/querys";
import CustomTabs from '../components/Collaborators/Tab/CustomTabs';
import CustomTab from '../components/Collaborators/Tab/CustomTabMain';
import {formatter} from "../helpers/formatoMoneda"
import { v4 as uuid } from "uuid";
import {
  postCancelarTimbrarNomina
} from "../services/cfdiService";
import {
  postMailPayrollAcuse,
  postXMLAcuseAWSDate
} from "../services/candidateService";
import { WarningAlertTimbre } from "../alerts/WarningAlertTimbre";
import descargaXml from "../assets/svg/icono_descarga_xml.svg";
import descargaAcuse from "../assets/svg/AcuseXML.svg";
import iconoPDF from "../assets/svg/icono_descargar_pdf.svg";
import Cancel from "../assets/svg/Cancel.svg";
import { AdminContext } from "../context/AdminContext/AdminContext";
import LockedSystem from "../alerts/LockedSystem";
import CheckPermission from "../helpers/Administradores/Permissions";

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

const PayrollCancelTimbrar = (props: TabPanelProps) => {
  const { id, all, mail } = useParams<any>();
  const [filtrado, setFiltrado] =  useState<any[]>([])
  const [isCheck, setIsCheck] =  useState<any[]>([])
  const [timbrar, setTimbrar] =  useState(true)
  const history = useHistory();
  const {adminState} = useContext(AdminContext)

  const {loading, data} = useQuery(GET_PAYROLL,{
    variables:{getPayrollId: id}
  })
  
  const PayrollData = data?.GET_PAYROLL

  const resultPayrollTimbrado = useQuery(GET_PAYROLLCOLLABORATOR_CANCEL_TIMBRE, {
    variables: { getPayrollcollaboratorTimbradoId: id },
  });

  const allPayrollTimbrado = resultPayrollTimbrado.data?.GET_PAYROLLCOLLABORATOR_CANCEL_TIMBRE;

  const handleChange = () =>{
  }

  /*--------------------------sistemaActivo--------------------*/
  const { data: resultCompany, startPolling, stopPolling } = useQuery(GET_ALL_COMPANY);
  const myCompany = resultCompany?.GET_ALL_COMPANY;
  const [sistemaActivo, setSistemaActivo] = useState<any>(true);
  useEffect(()=>{
    startPolling(1000);
    return () =>{
      stopPolling()
    }
  },[startPolling,stopPolling])

  useEffect(() => {
    if(myCompany){
      setSistemaActivo(myCompany[0]?.SistemaActivo)
    }
  }, [myCompany]);
  /*--------------------------sistemaActivo--------------------*/

  const addAll = useCallback(()=>{
    let filtradoSeleccionado = (allPayrollTimbrado.filter((lis:any)=>{
      return (!lis.CancelaSATfecha || lis.CancelaSATfecha === undefined)
    }).map((lis:any) =>(lis.id)))
    setIsCheck(filtradoSeleccionado)
  },[allPayrollTimbrado])

  useEffect(() => {
    if(allPayrollTimbrado){
      setFiltrado(allPayrollTimbrado)
      if(String(all) === '1'){
        addAll();
      }
    }
  }, [allPayrollTimbrado, all, addAll]);

  const datosFiltrados = (e:any) =>{
    if(e !== '') {
      let expresion = new RegExp(`${e}.*`, "i")
      const nuevoFiltrado = filtrado.filter((lis:any) => expresion.test(lis.colaborator))
      setFiltrado(nuevoFiltrado)
    }else{
      setFiltrado(allPayrollTimbrado)
    }
  }

  const [updateTokenTimbre] = useMutation(UPDATE_PAYROLL_COLLABORATOR_TOKENCANCELATIMBRE);

  const [updateXML] = useMutation(UPDATE_PAYROLL_COLLABORATOR_XML_ACUSE,
    {
      refetchQueries: [
        {query:GET_PAYROLLCOLLABORATOR_CANCEL_TIMBRE, variables:{getPayrollcollaboratorTimbradoId: id}},
        {query:GET_PAYROLL, variables:{getPayrollId: id}},
        {query:GET_PAYROLLCOLLABORATOR_TIMBRADO, variables:{getPayrollcollaboratorTimbradoId: id}}
        ],
    }
    );

  const handleSelectAll = (e:any) =>{

    if(!e.target.checked){
      setIsCheck([])
    }

    let filtradoSeleccionado = (allPayrollTimbrado.filter((lis:any)=>{
      return e.target.checked && !isCheck.includes(lis.id) && (!lis.CancelaSATfecha || lis.CancelaSATfecha === undefined)
    }).map((lis:any) =>(lis.id)))

    setIsCheck(filtradoSeleccionado)
  }

  const handleClick =  (e:any) => {
    
    const {id, checked} = e.target
    setIsCheck([...isCheck, id])
    if(!checked){
      setIsCheck(isCheck.filter((lis:any) => lis !== id))
    }

  }

  const cancelarNomina = async () =>{
    
    setTimbrar(false)
    let tokenTimbre = uuid();

    if(isCheck?.length > 0){

      for (const payrollColaboration of isCheck){
        await updateTokenTimbre({
          variables: {
            id: String(payrollColaboration),
            tokenTimbre: tokenTimbre
          },
        })
      }

      if(tokenTimbre !== undefined){
        if( String(tokenTimbre) !== "" ){
          
          const restultSELLO = await postCancelarTimbrarNomina(tokenTimbre);
          
          let text = ""
          let textTimbre = ""
          
          if(restultSELLO !== undefined){
  
            for (const sello of restultSELLO){
              if (String(sello.CodEstatus) === "" || sello.CodEstatus === undefined || sello.CodEstatus === null){
  
                const restultfile = await postXMLAcuseAWSDate(sello.Folio, sello.Acuse, sello.Colaborador, sello.Fecha);
                updateXML
                ({
                  variables: {
                    id:String(sello.Folio),
                    input: {
                      AcuseCancelacion: restultfile,
                      userCancelaTimbre: adminState?.Usuario
                    },
                  },
                }).then(()=>{
                    
                    if(parseInt(mail) === 1){
                      postMailPayrollAcuse(sello.Folio)
                    }
                })
                textTimbre = textTimbre +"<b>Documento cancelado:</b><br/><b> "+sello.Colaborador+":</b> "+ sello.UUID+"<br/>"
  
              }else{
  
                text = text +"<b>Problema Colaborador: </b><br/><b> "+sello.Colaborador+":</b> "+ sello.CodEstatus+"<br/>"
              }
            }
    
    
            if(text !== "" || textTimbre !== ""){
              WarningAlertTimbre({
                text: textTimbre+text,
              });
            }
  
          }
          
          setTimbrar(true)
          setIsCheck([])
          
        }
      }

    }
      
  }

  return (
    <>
      {!sistemaActivo ? (
        <LockedSystem/>
      ) : null}
      <Box mt={3} ml={5} className="Title">
        Nóminas
      </Box>
      <Box p={5} pb={3} pt={0}>
        <Grid container justify="flex-start">
          <CustomTabs
            //Tiene que ser 1 para que se muestre la pestaña de pre-nómina
            value={1}
            onChange={handleChange}
            aria-label="simple tabs example"
          >
            <CustomTab label="Incidencias" value={0} />
            {CheckPermission("Nominas.PreNomina") && (
              <CustomTab label="Pre-Nómina" value={1} />
            )}
            {CheckPermission("Nominas.FiniquitoLiquidacion") && (
            <CustomTab label="Finiquito / Liquidación" value={2} /> )}
            {CheckPermission("Nominas.Eventuales") && (
            <CustomTab label="Eventuales" value={3} />)}
             {CheckPermission("Nominas.Historicas") && (
            <CustomTab label="Históricas" value={4} />)}
             {CheckPermission("Nominas.Calculadora") && (
            <CustomTab label="Calculadora" value={5} />)}
            {CheckPermission("Nominas.Reportes") && (
            <CustomTab label="Reportes" value={6} />)}
          </CustomTabs>
        </Grid>
      </Box>
     <div className={styles.pc_contenedorPrincipal}>
        <div className={styles.pc_tituloContenedorPrincipal}>
            <div className={styles.pc_contenedorNombre}>
            <div>
                <span className={styles.pc_tituloPrincipal}>{PayrollData?.group_name}</span>
            </div>
            {(loading)?
                null
                :
                <div>
                <span className={styles.pc_tituloPeriodo}>Periódo: &nbsp;{getDateDay(PayrollData?.init_date)}</span>&nbsp;-&nbsp;
                <span className={styles.pc_tituloPeriodo}>{getDateDay(PayrollData?.end_date)}</span>&nbsp; 
                <span className={styles.pc_tituloPeriodo}>{getDateYear(PayrollData?.end_date)}</span>
                </div>
            }
            </div>

        </div>
        <div className={styles.pc_contenedorBuscadorDescargar}>
            <div className={styles.pc_contenedorBuscador}>
                <input 
                type="text" 
                placeholder="Buscar al colaborador por nombre"
                className={styles.pc_inputBuscador}
                onChange={(e) => datosFiltrados(e.target.value)}
                >
                </input>
                <div className={styles.pc_iconoBuscador}></div>
            </div>
        </div>
        <div className={styles.p_contenedorTablaDispersion}>
          <table className={styles.pd_tablaPrincipal}>
            <thead className={styles.pd_contenedorTitulos}>
              <td className={`${styles.pt_columna} ${styles.pt_primerColumna}`}>
                <div id="colaboladores" className={styles.checkboxitem}>
                    <input
                    id="check"
                    type="checkbox"
                    onChange={(e) => handleSelectAll(e)}
                    ></input>
                    <label htmlFor="check"> Colaboradores </label>
                </div>
              </td>
              <td className={`${styles.pt_columna} ${styles.pt_columnaTitulos}`}>Razón social emisora</td>
              <td className={`${styles.pt_columna} ${styles.pt_columnaTitulos}`}>Percepciones</td>
              <td className={`${styles.pt_columna} ${styles.pt_columnaTitulos}`}>Deducciones</td>
              <td className={`${styles.pt_columna} ${styles.pt_columnaTitulos}`}>Total</td>
              <td className={`${styles.pt_columna} ${styles.pt_columnaTitulos}`}></td>
            </thead>
            <tbody className={styles.pc_contenedorTitulos}>
              {filtrado?.map((payroll:any)=>(
              <tr>
                <td className={`${styles.pt_columnaDatos} ${styles.pt_primerColumna}`}>
                    
                  <div id="colaboladores" className={styles.checkboxitem}>
                    <input
                        id={payroll.id}
                        key={payroll.id}
                        type="checkbox"
                        checked ={isCheck?.includes(payroll.id)}
                        onChange={(e) => handleClick(e)}
                        value={payroll.id}
                        disabled={!payroll.CancelaSATfecha || payroll.CancelaSATfecha === undefined? false: true}
                    ></input>
                    <label htmlFor={payroll.id}>  {payroll.colaborator} </label>
                    
                  </div>
                    
                </td>
                <td className={styles.pt_columnaDatos}>{payroll?.enterprise?.RazonSocial}</td>
                <td className={styles.pt_columnaDatos}>{formatter(payroll.perception)}</td>
                <td className={styles.pt_columnaDatos}>{formatter(payroll.deduction)}</td>
                <td className={styles.pt_columnaDatos}>{formatter(payroll.netIncomeTaxable)}</td>
                <td className={`${styles.pt_columnaDatos} ${styles.pt_columnaIconos}`}>
                  <td >
                    {payroll.xml && payroll.xml !== ""?
                          
                      <a
                          style={{ marginRight: "5px", marginLeft: "5px" }}
                          target="_blank"
                          href={payroll?.xml}
                          rel="noopener noreferrer"
                      >
                        <img
                        src={descargaXml}
                        style={{ marginRight: "auto", marginLeft: "auto" }}
                        className="cursor"
                        alt="Descargar XML"
                        />
                      </a>
                          
                    : "" }
                  </td>
                  <td >
                    {payroll.xml && payroll.xml !== ""?
                      <a
                      style={{ marginRight: "5px", marginLeft: "5px" }}
                      target="_blank"
                      href={payroll?.LinkPDF}
                      rel="noopener noreferrer"
                      >
                        <img
                            src={iconoPDF}
                            style={{ marginRight: "auto", marginLeft: "auto" }}
                            className="cursor"
                            alt="DownloadFile"
                        />
                      </a>
                        : ""
                    }
                  </td>
                  <td >
                    {payroll.AcuseCancelacion && payroll.AcuseCancelacion !== ""?
                        <a
                        style={{ marginRight: "5px", marginLeft: "5px" }}
                        target="_blank"
                        href={payroll?.AcuseCancelacion}
                        rel="noopener noreferrer"
                      >
                        <img
                        src={descargaAcuse}
                        style={{ marginRight: "auto", marginLeft: "auto" }}
                        className="cursor"
                        alt="Descargar Acuse"
                        />
                      </a>
                        : ""
                    }
                  </td>
                        
                </td>
              </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div>
          <div className={styles.pd_contenedorBarraCancel}>
            <div className={styles.pd_barraEstado} >
              {
                sistemaActivo?
                  (isCheck?.length > 0) && timbrar?
                  <button className={styles.pd_botonCancelar}
                  onClick={() =>{
                    cancelarNomina()
                  } }
                  style={{
                      marginLeft:"auto"
                  }}
                  >
                    <img
                      src={Cancel}
                      alt="Cancelar"
                    />
                    <p>Cancelar</p>
                  </button>
                  :
                  <button className={styles.pd_botonCancelar}
                    style={{
                        marginLeft:"auto",
                        opacity:"0.5"
                    }}
                    >
                      <img
                        src={Cancel}
                        alt="Cancelar"
                      />
                      <p>Cancelar</p>
                    </button>
                : null
              }
                  
              </div>
            </div>
          </div>
        <div>
          <div className={styles.pd_contenedorBarraEstado}>
              <div className={styles.pd_barraEstado} >
  
                  <button className={styles.pd_botonSalir}
                  onClick={() => history.push(`/payroll`)}
                  style={{
                      marginLeft:"auto"
                  }}
                  >
                  Salir
                  </button>
              </div>
          </div>
        </div>
      </div>

    </>
  );
};

export default PayrollCancelTimbrar;