import axios from "axios";
import { ErrorAlert } from "../alerts/errorAlert";
const serverError = "Ocurrió un error con el servidor.";

export const uploadEvidenceFile = async (
  e: any,
  objectiveId: string,
  collaboratorId: string,
  name: string
) => {
  try {
    console.log(e)
    let mediaType = '1'
    switch (e.type) {
      case 'application/pdf':
        mediaType = '3';
        break;
      case 'image/jpeg':
      case 'image/png':
        mediaType = '1';
        break;
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        mediaType = '4';
        break;
      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        mediaType = '5';
        break;
      case 'video/mp4':
        mediaType = '2';
        break;
      case 'application/zip':
        mediaType = '6';
        break;
    }
    if (e !== undefined) {
      const formData = new FormData();
      formData.append("document", e);
      formData.append("name", name.split(".").slice(0, -1).join("."));
      formData.append("objectiveId", objectiveId);
      formData.append("collaboratorId", collaboratorId);
      formData.append("mediaType", mediaType);
      console.log(formData);

      const result = await axios.post('/objective/evidence', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      console.log(result);

      return result;
    }
  } catch (err) {
    return ErrorAlert({ text: serverError });
  }
};