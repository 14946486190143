import { Types } from './ReportContext'

const ModalReducer = (state: any, action: any) => {
  const { payload, type } = action;

  switch (type) {
    case Types.CREATE_REPORTS:
      return {
        ...state,
        createModalReports: true
      }
    case Types.EDIT_REPORTS:
      return {
        ...state,
        _id: payload._id,
        editModalReports: true
      }
    case Types.FIND_DATE_REPORTS:
      return {
        ...state,
        _id: payload._id,
        findModalReports: true
      }
    case Types.DELETE_REPORTS:
      return {
        ...state,
        _id: payload._id,
        deleteModalReports: true
      }
    case Types.CLEAR_REPORTS:
      return {
        ...state,
        _id: '',
        createModalReports: payload.createModalReports,
        editModalReports: payload.editModalReports,
        findModalReports: payload.findModalReports,
        deleteModalReports: payload.deleteModalReports
      }
    case Types.CLOSE_CREATE_REPORTS:
      return {
        ...state,
        createModalReports: false
      }
    case Types.PREVIEW_DATA:
      return {
        ...state,
        previewData: payload
      }
    default:
      return state;
  }
}

export default ModalReducer;