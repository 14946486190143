import { useContext, useEffect, useState } from 'react'
import { useQuery } from '@apollo/client'
import { formatter } from '../../helpers/formatoMoneda'
import { GET_ENTERPRISE_SELECT,GET_HISTORICOS_OUTSORCERS } from '../../Querys/querys'
import styles from '../../styles/CentralPagos/HistoricoCentralPagos.module.css'
import { server } from "../../global/server";
import axios from 'axios'


const HistoricoOutsourcersTable =()=>{
  const [inputTotalValue, setinputTotalValue] = useState(0);
  const [empresa, setEmpresa] =  useState("")
  const [filtro, setFiltro] =  useState("")
  const {loading:loadingListaEmpresa, error:errorListaEmpresa, data:dataListaEmpresa} = useQuery(GET_ENTERPRISE_SELECT,{})
  const dataEmpresaSelect = dataListaEmpresa?.GET_ENTERPRISE_SELECT.filter((enterprise:any)=>{return enterprise.Outsourcer})

  const {loading:loadingHistorico, error:errorHistorico, data: dataHistorico,refetch} = useQuery(GET_HISTORICOS_OUTSORCERS,{
    variables:{
      input:{
        filtro: filtro
      }
    }
  })
  const datosHistorico = dataHistorico?.GET_HISTORICOS_OUTSORCERS
  
  const datosIniciales = () =>{
    if(empresa === "" && dataEmpresaSelect.length > 0){
       setEmpresa((current) => current = dataEmpresaSelect["0"]["id"]);    
    }
  }

  const cambioEstado = (estado:any) =>{
    setEmpresa((current) => current = estado)
  }

  const cambioFiltro = (e:any) =>{
    setFiltro((current) => current = e.target.value)
  }

  useEffect(()=>{
    if(dataHistorico){
      datosIniciales()
    }
  },[dataHistorico])

  useEffect(()=>{
  },[empresa, filtro])

  const exportarPDF = async (id: any) => {

    const PDF: any = await axios.get(`${server}/report/cepDispersion/${id}`)
    const link = document.createElement("a");
    link.href = PDF?.data?.urlPDF;
    link.setAttribute("download", "downloadtesting.PDF");
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  return(
    <>
      <div className={styles.contenedorFiltros}>
        <div className={styles.contenedorFiltro}>
          <input 
            placeholder='Buscar'
            className={styles.filtro}
            onChange={(e) => cambioFiltro(e)}
            />
          <div className={styles.iconoBuscador}></div>
        </div>
        <div>
        </div>
      </div>
      <div className={styles.contenedorCentral}>
        <table className={styles.tabla}>
          <thead>
            <tr className={styles.tituloTabla}>
              <td>Colaboradores</td>
              <td>RFC</td>
              <td>Banco</td>
              <td>Cuenta Bancaria</td>
              <td>CLABE</td>
              <td>Total a dispersar no gravable</td>
              <td>Accion</td>
            </tr>
          </thead>
          <tbody>
            {datosHistorico?.map((datos:any, index:any)=>(
            <tr className={styles.datosTabla}>
              <td>{datos?.colaborator}</td>
              <td>{datos?.RFC}</td>
              <td>{datos?.bank}</td>
              <td>{datos?.accountNumber}</td>
              <td>{datos?.clabeNum}</td>
              <td>{formatter(datos?.TotalIncomeNotTaxable)}</td>
              <td>
                <a
                  target="_blank"
                  //href={`${server}/report/cepDispersion/${datos.idDispersion}`}
                  onClick={() => {
                    exportarPDF(datos.idDispersion);
                  }}
                  style={{ cursor: "pointer" }}
                >                  
                  <div className={styles.iconopdf}></div>
                </a>               
              </td>
          </tr>        
            ))}
          </tbody>
        </table>
      </div>
    </>
  )
}

export default HistoricoOutsourcersTable
