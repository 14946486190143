import { useQuery } from "@apollo/client";
import { Grid } from "@material-ui/core";
import Box from '@mui/material/Box';
import { useHistory, useParams } from "react-router-dom";
import { useState, useEffect, useCallback } from "react";
import styles from "../../src/components/Payroll/PayrollStyles.module.css"
import { 
        GET_PAYROLL, 
        GET_PAYROLLGRAPHIC_FINALBALANCES,
        GET_PAYROLLGRAPHIC_TAXESCONTRIBUTIONS,
        GET_PAYROLLGRAPHIC_INCIDENTS,
        GET_PAYROLLGRAPHIC_COLLABORATOR,
        GET_ALL_COMPANY
      } from "../Querys/querys";
import CustomTabs from '../components/Collaborators/Tab/CustomTabs';
import CustomTab from '../components/Collaborators/Tab/CustomTabMain';
import EndingBalances from "../components/Dashboard/EndingBalances";
import Incidents from "../components/Dashboard/Incidents";
import Taxes from "../components/Dashboard/Taxes";
import { CancellationCollaborators, IncidentsCollaborators } from "../components/Dashboard/CollaboratorsPayroll";
import LockedSystem from "../alerts/LockedSystem";
import { getDateDay, getDateYear } from "../helpers/Payroll/Payroll";
import { formatterMxn } from "../helpers/formatoMoneda";
import CheckPermission from "../helpers/Administradores/Permissions";

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

const PayrollTerminar = (props: TabPanelProps) => {
  const { id, tab } = useParams<any>();
  const history = useHistory();
  const [dataTableTaxes, setDataTableTaxes] = useState<any[]>([])

  const {loading, data} = useQuery(GET_PAYROLL,{
    variables:{getPayrollId: id}
  })
  
  const PayrollData = data?.GET_PAYROLL

  const {data: dataFinalBalances } = useQuery(GET_PAYROLLGRAPHIC_FINALBALANCES, {
    variables: { getPayrollgraphicFinalbalancesId: id },
  });

  const allPayrollFinalBalances = dataFinalBalances?.GET_PAYROLLGRAPHIC_FINALBALANCES;
  const [balances, setBalances] = useState<any>()

  const {data: dataTaxesContributions } = useQuery(GET_PAYROLLGRAPHIC_TAXESCONTRIBUTIONS, {
    variables: { getPayrollgraphicTaxescontributionsId: id },
  });

  const allPayrollTaxesContributions = dataTaxesContributions?.GET_PAYROLLGRAPHIC_TAXESCONTRIBUTIONS;
  const [taxes, setTaxes] = useState<any>()
  
  const {data: dataIncidents } = useQuery(GET_PAYROLLGRAPHIC_INCIDENTS, {
    variables: { getPayrollgraphicIncidentsId: id },
  });

  const allPayrollIncidents = dataIncidents?.GET_PAYROLLGRAPHIC_INCIDENTS;
  const [incidents, setIncidents] = useState<any>()
  const [incidentsGraphic, setIncidentsGraphic] = useState<any>()

  const {data: dataCollaborator } = useQuery(GET_PAYROLLGRAPHIC_COLLABORATOR, {
    variables: { getPayrollgraphicCollaboratorId: id },
  });

  const allPayrollCollaborators = dataCollaborator?.GET_PAYROLLGRAPHIC_COLLABORATOR;
  const [collaborators, setCollaborators] = useState<any>()

  const handleChange = () =>{
  }

  /*--------------------------sistemaActivo--------------------*/
  const { data: resultCompany, startPolling: startPollingSistemaActivo, stopPolling: stopPollingSistemaActivo } = useQuery(GET_ALL_COMPANY);
  const myCompany = resultCompany?.GET_ALL_COMPANY;
  const [sistemaActivo, setSistemaActivo] = useState<any>(true);

  useEffect(()=>{
    startPollingSistemaActivo(1000);
    return () =>{
      stopPollingSistemaActivo()
    }
  },[startPollingSistemaActivo,stopPollingSistemaActivo])

  useEffect(() => {
    if(myCompany){
      setSistemaActivo(myCompany[0]?.SistemaActivo)
    }
  }, [myCompany]);
  /*--------------------------sistemaActivo--------------------*/

  const dataForTaxes = useCallback(() =>{
    let array: any[] = []
      
    array.push(allPayrollTaxesContributions?.Total)
    array.push(allPayrollTaxesContributions?.IMSS)
    array.push(allPayrollTaxesContributions?.Infonavit)
    array.push(allPayrollTaxesContributions?.ISR)
    setDataTableTaxes(array)

  },[allPayrollTaxesContributions])

  useEffect(() => {
    if(allPayrollTaxesContributions){
      dataForTaxes()
    }
  }, [allPayrollTaxesContributions, dataForTaxes]);

  const obtenerDatosBalances = useCallback(() =>{
    const data ={ 
      "MontoDispersado": allPayrollFinalBalances?.MontoDispersado.toFixed(2),
      "PorcentajeFinal": allPayrollFinalBalances?.PorcentajeFinal,
      "SaldoFinal": allPayrollFinalBalances?.SaldoFinal.toFixed(2),
      "SaldoInicial": allPayrollFinalBalances?.SaldoInicial.toFixed(2),
    }
    setBalances(data)
  }, [allPayrollFinalBalances])

  const obtenerDatosImpuestos = useCallback(() =>{
    const data ={ 
      "Total": allPayrollTaxesContributions?.Total.toFixed(2),
      "IMSS": allPayrollTaxesContributions?.IMSS.toFixed(2),
      "ISR": allPayrollTaxesContributions?.ISR.toFixed(2),
      "Infonavit": allPayrollTaxesContributions?.Infonavit.toFixed(2),
    }
    setTaxes(data)

  }, [allPayrollTaxesContributions])

  const obtenerDatosIncidentes = useCallback(() =>{
    const data ={ 
      "DeduccionMesActual": allPayrollIncidents?.DeduccionMesActual.toFixed(2),
      "PercepcionMesActual": allPayrollIncidents?.PercepcionMesActual.toFixed(2),
      "DeduccionUnMes": allPayrollIncidents?.DeduccionUnMes.toFixed(2),
      "PercepcionUnMes": allPayrollIncidents?.PercepcionUnMes.toFixed(2),
      "UnMes": allPayrollIncidents?.UnMes,
      "DeduccionDosMeses": allPayrollIncidents?.DeduccionDosMeses.toFixed(2),
      "PercepcionDosMeses": allPayrollIncidents?.PercepcionDosMeses.toFixed(2),
      "DosMeses": allPayrollIncidents?.DosMeses,
    }
    setIncidents(data)
    setIncidentsGraphic(allPayrollIncidents)
  },  [allPayrollIncidents])


  useEffect(() => {
    if(allPayrollFinalBalances){
      obtenerDatosBalances()
    }

  }, [allPayrollFinalBalances, obtenerDatosBalances])

  useEffect(() => {
    if(allPayrollTaxesContributions){
      obtenerDatosImpuestos()
    }

  }, [allPayrollTaxesContributions, obtenerDatosImpuestos])

  useEffect(() => {
    if(allPayrollIncidents){
      obtenerDatosIncidentes()
    }

  }, [allPayrollIncidents, obtenerDatosIncidentes])

  useEffect(() => {
    if(allPayrollCollaborators){
      setCollaborators(allPayrollCollaborators)
    }

  }, [allPayrollCollaborators])

  
  return (
    <>
      {!sistemaActivo ? (
        <LockedSystem/>
      ) : null}
      <Box mt={3} ml={5} className="Title">
        Nóminas
      </Box>
      <Box p={5} pb={3} pt={0}>
        <Grid container justify="flex-start">
          <CustomTabs
            //Tiene que ser 1 para que se muestre la pestaña de pre-nómina
            value={1}
            onChange={handleChange}
            aria-label="simple tabs example"
          >
            <CustomTab label="Incidencias" value={0} />
            {CheckPermission("Nominas.PreNomina") && (
              <CustomTab label="Pre-Nómina" value={1} />
            )}
            {CheckPermission("Nominas.FiniquitoLiquidacion") && (
            <CustomTab label="Finiquito / Liquidación" value={2} /> )}
            {CheckPermission("Nominas.Eventuales") && (
            <CustomTab label="Eventuales" value={3} />)}
             {CheckPermission("Nominas.Historicas") && (
            <CustomTab label="Históricas" value={4} />)}
             {CheckPermission("Nominas.Calculadora") && (
            <CustomTab label="Calculadora" value={5} />)}
            {CheckPermission("Nominas.Reportes") && (
            <CustomTab label="Reportes" value={6} />)}
          </CustomTabs>
        </Grid>
      </Box>
      <div className={styles.pc_contenedorTitulo}>
        <div className={styles.pc_contenedorProceso}>
          <div>
            <span className={styles.pc_tituloProceso}>Calcular</span>
            <span className={styles.pc_tituloProceso}>Dispersar</span>
            <span className={styles.pc_tituloProceso}>Timbrar</span>
            <span className={styles.pc_tituloProceso}>Terminar</span>
          </div>
          <div className={styles.pc_contenedorIconoSeguimiento}>
            <div className={styles.pc_circuloAmarillo}>
              <div className={styles.pd_iconoCompleto}></div>
            </div>
            <div className={styles.pc_lineaSeguimientoCompleto}></div>
            <div className={styles.pc_circuloAmarillo}>
              <div className={styles.pd_iconoCompleto}></div>
            </div>
            <div className={styles.pc_lineaSeguimientoCompleto}></div>
            <div className={styles.pc_circuloAmarillo}>
              <div className={styles.pd_iconoCompleto}></div>    
            </div>
            <div className={styles.pc_lineaSeguimientoCompleto}></div>
            <div className={styles.pc_circuloAmarillo}>
            <div className={styles.pc_circuloBlanco}></div> 
            </div>
          </div>
        </div>
        <div className={styles.pc_contenedorBotones}></div>
      
      </div>
      <div className={styles.pc_tituloContenedorPrincipal}>
        <div className={styles.pc_contenedorNombre}
        style={{
          marginRight: "auto",
          marginLeft: "auto",
          textAlign: "center",
        }}
        >
        <div>
          <span className={styles.pc_tituloPrincipal}>{tab}</span>
        </div>
        {(loading)?
          null
        :
          <div>
            <span className={styles.pc_tituloPeriodo}>Periódo: &nbsp;{getDateDay(PayrollData?.init_date)}</span>&nbsp;-&nbsp;
            <span className={styles.pc_tituloPeriodo}>{getDateDay(PayrollData?.end_date)}</span>&nbsp; 
            <span className={styles.pc_tituloPeriodo}>{getDateYear(PayrollData?.end_date)}</span>
          </div>
        }
          </div>
      </div>
      <div className={styles.p_contenedorTablaDispersion}>
        <>
          <Grid direction="row" container justify="flex-start">
            <Grid item xs={6} sm={6} md={6} lg={6} spacing={5}>
              <div className={styles.divGraphicConteiner}>
                <Box
                className={styles.divGraphic}
                style={{
                  paddingTop: "60px",
                  height: "345px",
                }}
                >
                  <h1 className={styles.alineacionTituloGraficas}>
                    Resumen de dispersi&oacute;n de n&oacute;minas
                  </h1>
                  <Grid direction="row" container justify="flex-start">
                    <Grid
                      className={styles.alineacionTituloGraficas}
                      item
                      xs={6}
                      sm={6}
                      md={6}
                      lg={6}
                      xl={6}
                    >
                      <div className={styles.divTextGraphic}>
                        <h1 className={styles.textGraphic}>Total N&oacute;minas del Periodo</h1>
                        <h1 className={styles.textNumberGraphicGreen}>{formatterMxn(balances?.SaldoInicial)}</h1>
                        <p>
                          <span className={styles.textGraphic}>N&oacute;minas dispersadas</span>
                        </p>
                        <p className={styles.textNumberGraphicBlue}>{formatterMxn(balances?.MontoDispersado)}</p>
                        <p>
                          <span className={styles.textGraphic}>N&oacute;mina por dispersar</span>
                        </p>
                        <p className={styles.textNumberGraphicBlue}>{formatterMxn(balances?.SaldoFinal)}</p>
                      </div>
                    </Grid>
                    <Grid
                      className={styles.alineacionTituloGraficas}
                      item
                      xs={6}
                      sm={6}
                      md={6}
                      lg={6}
                      xl={6}
                    >
                      <EndingBalances percentage={balances?.PorcentajeFinal} />
                    </Grid>
                  </Grid>
                </Box>
              </div>
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6} spacing={5}>
              <div className={styles.divGraphicConteiner}>
                <Box
                 className={styles.divGraphic}
                >
                  <h1 className={styles.alineacionTituloGraficas}>
                    Impuestos y aportaciones sociales
                  </h1>
                  <Grid
                    direction="row"
                    container
                    justify="flex-start"
                    alignItems="center"
                  >
                    <Grid
                      className={styles.alineacionTituloGraficas}
                      item
                      xs={3}
                      sm={3}
                      md={3}
                      lg={3}
                      xl={3}
                    >
                      <div className="text-align-center">
                        <h3 className={styles.textNumberGraphicBlue}>{formatterMxn(taxes?.Total)}</h3>
                      </div>
                    </Grid>
                    <Grid
                      className={styles.alineacionTituloGraficas}
                      item
                      xs={3}
                      sm={3}
                      md={3}
                      lg={3}
                      xl={3}
                    >
                      <div className="text-align-center">
                        <h3 className={styles.textNumberGraphicBlue}>{formatterMxn(taxes?.IMSS)}</h3>
                      </div>
                    </Grid>
                    <Grid
                      className={styles.alineacionTituloGraficas}
                      item
                      xs={3}
                      sm={3}
                      md={3}
                      lg={3}
                      xl={3}
                    >
                      <div className="text-align-center">
                        <h3 className={styles.textNumberGraphicBlue}>{formatterMxn(taxes?.Infonavit)}</h3>
                      </div>
                    </Grid>
                    <Grid
                      className={styles.alineacionTituloGraficas}
                      item
                      xs={3}
                      sm={3}
                      md={3}
                      lg={3}
                      xl={3}
                    >
                      <div className="text-align-center">
                        <h3 className={styles.textNumberGraphicBlue}>{formatterMxn(taxes?.ISR)}</h3>
                      </div>
                    </Grid>
                    <Grid
                      className={styles.alineacionTituloGraficas}
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                    >
                      <Taxes dataTableTaxes={dataTableTaxes} />
                    </Grid>
                  </Grid>
                </Box>
              </div>
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6} spacing={5}>
              <div className={styles.divGraphicConteiner}>
                <Box
                className={styles.divGraphic}
                >
                  <h1 className={styles.alineacionTituloGraficas}>Comportamiento incidencias</h1>
                  <Grid
                    direction="row"
                    container
                    justify="flex-start"
                    alignItems="center"
                  >
                    <Grid
                      className={styles.alineacionTituloGraficas}
                      item
                      xs={4}
                      sm={4}
                      md={4}
                      lg={4}
                      xl={4}
                    >
                      <div className={styles.pp_tituloLista}>
                        <span className={styles.textGraphic}>Total {incidents?.DosMeses}</span>
                        <p className={styles.textNumberGraphicBlue}>{formatterMxn(incidents?.PercepcionDosMeses)}</p>
                        <p className={styles.textNumberGraphicYellow}>{formatterMxn(incidents?.DeduccionDosMeses)}</p>
                      </div>
                    </Grid>
                    <Grid
                      className={styles.alineacionTituloGraficas}
                      item
                      xs={4}
                      sm={4}
                      md={4}
                      lg={4}
                      xl={4}
                    >
                      <div className={styles.pp_tituloLista}>
                        <span className={styles.textGraphic}> Total {incidents?.UnMes}</span>
                        <p className={styles.textNumberGraphicBlue}>{formatterMxn(incidents?.PercepcionUnMes)}</p>
                        <p className={styles.textNumberGraphicYellow}>{formatterMxn(incidents?.DeduccionUnMes)}</p>
                      </div>
                    </Grid>
                    <Grid
                      className={styles.alineacionTituloGraficas}
                      item
                      xs={4}
                      sm={4}
                      md={4}
                      lg={4}
                      xl={4}
                    >
                      <div className={styles.pp_tituloLista}>
                        <span className={styles.textGraphic}> Total actual </span>
                        <p className={styles.textNumberGraphicBlue}>{formatterMxn(incidents?.PercepcionMesActual)}</p>
                        <p className={styles.textNumberGraphicYellow}>{formatterMxn(incidents?.DeduccionMesActual)}</p>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Incidents
                        labels={[incidentsGraphic?.DosMeses, incidentsGraphic?.UnMes, incidentsGraphic?.MesActual]}
                        data1={[incidentsGraphic?.DeduccionDosMeses, incidentsGraphic?.DeduccionUnMes, incidentsGraphic?.DeduccionMesActual]}
                        data2={[incidentsGraphic?.PercepcionDosMeses, incidentsGraphic?.PercepcionUnMes, incidentsGraphic?.PercepcionMesActual]}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </div>
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6} spacing={5}>
              <div className={styles.divGraphicConteiner}>
                <Box
                className={styles.divGraphic}
                style={{
                  paddingTop: "60px",
                  height: "345px",
                }}
                >
                  <h1 className={styles.alineacionTituloGraficas}>Colaboradores</h1>
                  <Grid
                    direction="row"
                    container
                    justify="flex-start"
                    alignItems="center"
                  >
                    <Grid
                      className={styles.alineacionTituloGraficas}
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                    >
                      <span
                        className={styles.textGraphic}
                        style={{ marginRight: "8px" }}
                      >
                        Total actual{" "}
                      </span>
                      <span className={styles.textNumberGraphicBlue}>{collaborators?.TotalColaboradores}</span>
                    </Grid>
                    <Grid
                      className={styles.alineacionTituloGraficasCirculo}
                      item
                      xs={6}
                      sm={6}
                      md={6}
                      lg={6}
                      xl={6}
                    >
                      <div>
                        <CancellationCollaborators percentage={collaborators?.PorcentajeBajas} tipo='Rotaci&oacute;n'/>
                        <p className={styles.textGraphic}
                        style={{margin: "0px"}}
                        >Rotaci&oacute;n</p>
                        <p className={styles.textGraphic}
                        style={{margin: "0px"}}
                        >{collaborators?.ColaboradoresBaja} colaboradores</p>
                        
                      </div>
                    </Grid>
                    <Grid
                      className={styles.alineacionTituloGraficasCirculo}
                      item
                      xs={6}
                      sm={6}
                      md={6}
                      lg={6}
                      xl={6}
                    >
                      <div>
                        <CancellationCollaborators percentage={collaborators?.PorcentajeAlta} tipo='Alta'/>
                        <p className={styles.textGraphic}
                        style={{margin: "0px"}}
                        >{collaborators?.ColaboradoresAlta} colaboradores</p>
                        <p className={styles.textGraphic}
                        style={{margin: "0px"}}
                        >dados de alta</p>
                      </div>
                    </Grid>
                    <Grid
                      className={styles.alineacionTituloGraficasCirculo}
                      item
                      xs={6}
                      sm={6}
                      md={6}
                      lg={6}
                      xl={6}
                    >
                      <div>
                        <IncidentsCollaborators percentage={collaborators?.PorcentajeIncidencias}/>
                        <p className={styles.textGraphic}
                        style={{margin: "0px"}}
                        >{collaborators?.ColaboradoresIncidencias} colaboradores</p>
                        <p className={styles.textGraphic}
                        style={{margin: "0px"}}
                        >con incidencias</p>
                      </div>
                    </Grid>
                  </Grid>
                </Box>
              </div>
            </Grid>
          </Grid>
        
        </>
       
      </div>
      <div>
        <div className={styles.pd_contenedorBarraEstado}>
          <div className={styles.pd_barraEstado} >
            <div className={styles.pd_contenedorRegreso}
              onClick={() => history.push(`/payrolltimbrar/${id}/${tab}`)}>
              <div className={styles.pd_botonRegreso}></div>
              <div>Regresar a "Timbrar"</div>
            </div>
            <button className={styles.pd_botonSalir}
              onClick={() => history.push(`/payroll`)}
            >
              Salir
            </button>
          </div>
        </div>
      </div>

    </>
  );
};

export default PayrollTerminar;
