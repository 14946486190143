import {Dialog, DialogContent, Grid, withStyles, Theme  } from "@material-ui/core"
import MyMoodContext from '../../../context/MyMoodContext/MyMoodContext';
import { useContext, useEffect, useState, useCallback} from 'react';
import {closeQuestion} from '../../../context/MyMoodContext/Actions';
import '../MyMood.css';
import MuiDialogActions from "@material-ui/core/DialogActions";
import { GeneralPieChartFivePath } from "../../Dashboard/GeneralPieChartFivePath";
import { useQuery } from "@apollo/client";
import {GET_RESULTADO_ENCUESTA} from "../../../Querys/querys";

const DialogActions = withStyles((theme: Theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

export const  GraphicalQuestions = () => {

    const[resultData, setResultData] = useState<any>([])
    const[data, setData] = useState<any>([])
    const getData = useCallback(() => {
        let initData: any
        if (resultData) {
            initData = [
                
                {
                    "percentage": resultData.Motivados,
                    "color": "#367eea",
                    "text": "Motivados"
                },
                {
                    "percentage": resultData.Productivos,
                    "color": "#42dbbe",
                    "text": "Productivos"
                    
                },
                {
                    "percentage": resultData.Aburridos,
                    "color": "#9c54e5",
                    "text": "Aburridos"
                },
                {
                    "percentage": resultData.Presionados,
                    "color": "#fec104",
                    "text": "Presionados"
                },
                {
                    "percentage": resultData.Enfadados,
                    "color": "#fb337b",
                    "text": "Enfadados"
                },
                
                
            ]

            
        }else{
            initData = [
                {
                    "percentage": 0,
                    "color": "#277DA1",
                    "text": "Motivados"
                },
                {
                    "percentage": 0,
                    "color": "#43AA9E",
                    "text": "Productivos"
                    
                },
                {
                    "percentage": 0,
                    "color": "#C57CCB",
                    "text": "Aburridos"
                },
                {
                    "percentage": 0,
                    "color": "#F9C74F",
                    "text": "Presionados"
                },
                {
                    "percentage": 0,
                    "color": "#FF6863",
                    "text": "Enfadados"
                },
            ]
        }
        setData(initData)
    }, [resultData])

    const {state,dispatch} = useContext(MyMoodContext)

    const {data: result, startPolling, stopPolling} = useQuery(GET_RESULTADO_ENCUESTA, {
        variables: { id: state.idEncuestas_colaborador === ''? 0: state.idEncuestas_colaborador }
    });

    useEffect(() => {
        if(result){
            setResultData(result.GET_RESULTADO_ENCUESTA)
        }
    }, [result])

    useEffect(() => {
        if(resultData){
            getData()
        }
    }, [resultData, getData])

    useEffect(() => {
        startPolling(1000)
        return () => {
            stopPolling()
        }
    }, [startPolling, stopPolling])


    return (
        <Dialog open={state.openQuestion} aria-labelledby="form-dialog-title" maxWidth="xs" fullWidth={true}>
            <div className="dialogContainer">
                <div className="containerTitle">
                    <div className="centerContainer"
                    style={{
                        marginLeft:'auto',
                        marginRight:'auto'
                    }}
                    >
                        <h2 id="form-dialog-title" data-testid="TitleModal">{state.Encuestas}</h2>
                        
                    </div>
                    <div className="contenedorCerrarModal">
                        <div className="iconoCerrarModal" onClick={(()=>closeQuestion({}, dispatch))}></div>      
                    </div> 
                </div>
                    
                <DialogContent className="dialogoContento">
                    
                    <>
                        <div 
                        style={{
                            display:'flex',
                            width:'80%',
                        }}
                        >
                            <Grid item xs={12} className="ColumnaEditarPuesto">
                                <div className="pc_contenedorTabla">
                                    <GeneralPieChartFivePath 
                                    percentage={data}
                                    tipo="respuestas" 
                                    percentageSymbol="" 
                                    number={resultData.TotalRespuestas} 
                                    textSize="20px" 
                                    y={50} />
                                </div>
                            </Grid>
                            <div>
                                {
                                    data.map((item: any, index: any) => (
                                        <div className="containerAnswers" >
                                            <div className="containerCircle">
                                                <div className="circleName">
                                                    <div className="circle"
                                                    style={{
                                                        backgroundColor: item.color,
                                                    }}
                                                    ></div>
                                                    <div className="circleText"><p>{item.text}</p></div>
                                                    <div className="circleText"
                                                    style={{
                                                        marginLeft:'auto',
                                                        opacity: '0.5'
                                                    }}
                                                    ><p>{item.percentage}%</p></div>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
    
                        
                    </>
                    
        
                </DialogContent>
                
                <DialogActions>
                    <button className="botonCancelar" onClick={
                        (()=>closeQuestion({}, dispatch))
                    }>
                        Cerrar
                    </button>

                </DialogActions>
            </div>
        </Dialog>
    )

}