import styles from '../styles/AvisoPrivacidad/avisoPrivacidad.module.css'

const AvisoPrivacidadScreen = () =>{
  return(
    <>
      <div className={styles.header}>
          <div className={styles.contenedorLogo}>
            <img
              src="/assets/logo_bits_header.svg"
              alt="BITS"
              className={styles.imagenLogo}
            />
          </div>
      </div>
      <div className={styles.contenedorPrincipal}>
        <div className={styles.contenedorInterno}>
          <div className={styles.headerInterno}>
            <div ></div>
            <div className={styles.logoDos}></div>
          </div>
          <div className={`${styles.contenedorTexto} ${styles.tituloInterno}`}>
            <div className={styles.textotTitulo}>
              Aviso de Privacidad Integral para sitio web y aplicaci&oacute;n M&oacute;vil.
            </div>
          </div>
          <div className={styles.contenedorTexto}>
            <p className={styles.texto}>
              El presente documento constituye el aviso de privacidad para efectos de lo dispuesto en la
              Ley Federal de Protecci&oacute;n de Datos Personales en Posesi&oacute;n de los Particulares (la LFPDPPP")
              y las disposiciones que emanan de ella o se relacionan con la misma. Este Aviso
              de privacidad aplica a la informacion personal recopilada sobre el Titular por parte
              de <span className={styles.negritas}> BITSCRUM TECHNOLOGIES, S.A.P.I DE C.V.</span> (en adelante <span className={styles.negritas}> BITSCRUM</span>) en su car&aacute;cter de
              Responsable.
            </p>
            <p className={styles.texto}>
              Para los efectos del presente Aviso de privacidad, los t&eacute;rminos que no es&eacute;n
              contemplados en el mismo tendr&aacute;n el significado que se les atribuye en la Ley Federal de
              Protecci&oacute;n de Datos Personales en Posesi&oacute;n de los Particulares (LFPDPPP). El presente
              Aviso de privacidad tiene por objeto establecer t&eacute;rminos y condiciones por medio de los
              cuales <span className={styles.negritas}>BITSCRUM</span> (o el Encargado que designe <span className={styles.negritas}>BITSCRUM</span>) recibir&aacute; y proteger&aacute; los Datos
              Personales del Titular, a efecto de proteger su privacidad; utilizar&aacute; los Datos Personales
              del Titular, y/o realizar&aacute; en su caso las transferencias sus Datos Personales a terceros. 
            </p>
          </div>
          <div className={`${styles.contenedorTexto} ${styles.segundaLinea}`}>
            <div className={styles.textoSubtitulo}>
              1.- IDENTIDAD Y DOMICILIO DEL RESPONSABLE
            </div>
            <p className={styles.texto}>
              <span className={styles.negritas}>BITSCRUM TECHNOLOGIES, SOCIEDAD ANONIMA PROMOTORA DE INVERS&Oacute;N DE CAPITAL VARIABLE</span>.,
               es responsable del uso y protecci&oacute;n de sus datos personales, en este
               sentido y atendiendo las obligaciones establecidas en la Ley Federal de Protecci&oacute;n de Datos
               Personales en Posesi&oacute;n de Particulares, a trav&eacute;s de este instrumento se informa a los
               titulares de los datos, la informacion que de ellos se recabo y fines que se le dar&aacute;n a dicha
               informacion.
            </p>
            <p className={styles.texto}>
              Adicional a lo anterior, informamos a ustedes que BITSCRUM TECHNOLOGIES, S.A.P.I. DE
              C.V. tiene su domicilio en: Avenida Paseo de los Chicahuales N&uacute;mero 1024, Colonia Corral
              de Barrancos, C&oacute;digo Postal 20900, Jes&uacute;s Mar&iacute;a, Aguascalientes.
            </p>
            <p className={styles.texto}>
              BITSCRUM recaba los Datos Personales a trav&eacute;s de los siguientes medios: (i) de manera
              personal, cuando el Titular lo proporciona a trav&eacute;s de nuestras oficinas, (ii) de manera,
              directiva, cuando el Titular los ingresa a trav&eacute;s del portal <span className={styles.link}><a href='http://www.bitsmexico.mx' rel="noreferrer" target="_blank">http://www.bitsmexico.mx</a></span> (el "Sitio
              Web"), la aplicaci&oacute;n m&oacute;vil de BITSCRUM denominada "BITS" ("BITS") (iii) de manera
              directa, cuando el Titular los proporciona v&iacute;a telef&oacute;nica o correo electr&oacute;nico, (iv) de
              manera indirecta, cuando otras empresas nos transfieren Datos Personales, y (v) de
              manera indirecta, cuando se obtienen a trav&eacute;s de fuentes de acceso p&uacute;blico permitidas
              por la LFPDPPP; pone a disposici&oacute;n del Titular el presente aviso de privacidad integral (el
              "Aviso de Privacidad") previo a la obtenci&oacute;n de los Datos Personales- en estricto apego a
              los-principios de informaci&oacute;n, licitud, consentimiento, calidad, finalidad, lealtad,
              proporcionalidad y responsabilidad contemplados en la LFPDPPP y su reglamento.
            </p>
          </div>
          <div className={`${styles.contenedorTexto} ${styles.segundaLinea}`}>
            <div className={styles.textoSubtitulo}>
              2.-RECOLECCI&Oacute;N DE LOS DATOS PERSONALES.
            </div>
            <p className={styles.texto}>
              La recolecci&oacute;n de Datos Personales podr&aacute;n efectuarse, por comunicaci&oacute;n v&iacute;a telef&oacute;nica con
              el Responsable o con sus Encargados, empleados o bien, mediante entrega directa al
              Responsable o mediante el uso de correos electr&oacute;nicos y/o mensajes cortos de texto, o 
              mediante la utilizaci&oacute;n de la aplicaci&oacute;n para tel&eacute;fonos m&oacute;viles y tabletas denominada 
              "BITS" (en adelante BITS), a trav&eacute;s de su sitio Web, mediante el uso de herramientas de
              captura autom&aacute;tica de datos. Las cuales permiten recolectar la infomaci&oacute;n que env&iacute;a su
              navegador a dichos sitios Web, tales como el tipo de navegador que utiliza, el idioma de
              usuario, y la direcci&oacute;n IP de sitios Web que utiliz&oacute; para acceder a los sitios del Responsable
              o Encargados. 
            </p>
            <p className={styles.texto}>
              2.1. <span className={styles.negritas}>Datos Personales</span>: Los siguientes son ejemplos, a t&iacute;tulo enunciativo mas no limitativo,
              de informac&iacute;on que el Responsable puede recopilar: nombre y apellidos; fecha de
              nacimiento; sexo; estado civil; domicilio, sea particular, del trabajo, o fiscal; direcci&oacute;n de
              correo electr&oacute;nico, personal o del trabajo; nombre de identificaci&oacute;n en redes sociales;
              n&uacute;mero telefonico, particular o del trabajo; n&uacute;mero de telefono celular; clave del Registro
              Federal de Contribuyentes (RFC); Clave &Uacute;nuca de Registro de Poblaci&oacute;n (CURP); nombre
              de su beneficiarios, nombre de Antiguedad en el puesto en el empleo.
            </p>
            <p className={styles.texto}>
              2.2. <span className={styles.negritas}>Datos financieros</span>: cuando realizas operaciones, inscripciones a eventos o para participar en promociones, 
              mediante sitios, micrositios web y nuestras aplicaciones, adem&aacute;s de los datos generales, se considerar&aacute;n Datos 
              Personales los relacionados con el salario  o ingresos y percepciones del Titular; informaci&oacute;n relacionada con 
              tarjetas bancarias, tales como nombre del tarjetahabiente, n&uacute;mero de tarjeta, fecha de vencimiento de la tarjeta, 
              tipo de tarjeta y cualquier otra informaci&oacute;n necesaria para realizar la transacci&oacute;n en l&iacute;nea. Tambi&eacute;n se consideran 
              datos personales los relacionados con cuentas bancarias, tales como el nombre del titular, nombre del banco, 
              Clabe Bancaria Estandarizada (CLABE) y cualquier otra informaci&oacute;n necesaria para realizar pagos o transacciones.
            </p>
            <p className={styles.texto}>
              2.3. <span className={styles.negritas}>Datos Personales Sensibles</span>: En adici&oacute;n a tus datos generales y con el fin de que puedas acceder a una mejor experiencia 
              con <span className={styles.negritas}>BITSCRUM </span> y su aplicaci&oacute;n <span className={styles.negritas}>BITS</span>, ocasionalmente podremos recabar datos que son considerados sensibles, tales como tu 
              identidad a trav&eacute;s de una fotograf&iacute;a, tus h&aacute;bitos de consumo, tu geolocalizaci&oacute;n en tiempo real, as&iacute; como capturar el horario
              de conexi&oacute;n y desconexi&oacute;n a la aplicaci&oacute;n.
            </p>
            <p className={styles.texto}>
              2.4. <span className={styles.negritas}>Documentaci&oacute;n</span>: Dentro de la documentaci&oacute;n que puede ser recolectada por <span className={styles.negritas}>BITSCRUM</span> para la verificaci&oacute;n de la 
              identidad del Titular de los Datos Personales se encuentra la credencial de elector; la cartilla de identidad del 
              servicio militar nacional liberada; la c&eacute;dula profesional; pasaporte; licencia de manejo; Forma migratoria; la 
              c&eacute;dula de registro en el RFC; la c&eacute;dula de registro con la CURP; el comprobante de domicilio; podr&iacute;a tambi&eacute;n solicitarse, 
              entre otros, el acta de nacimiento; comprobantes de estudios; el estado de cuenta de la Administradora de Fondos para 
              el retiro (Afore); la hoja de retenci&oacute;n de impuestos, as&iacute; como de los estudios psicom&eacute;tricos, m&eacute;dico y socio-econ&oacute;mico 
              efectuados por <span className={styles.negritas}>BITSCRUM</span>. Tambi&eacute;n le podr&iacute;an ser solicitados los Datos Personales de sus familiares, dependientes o 
              beneficiarios y la documentaci&oacute;n comprobatoria conducente.  Los Datos Personales mencionados en los apartados 2.1. a 2.3. del 
              presente Aviso de Privacidad podr&aacute;n ser recabados mediante el llenado de formularios, formatos diversos ya sean f&iacute;sicos o 
              digitales y/o mediante la recopilaci&oacute;n de informaci&oacute;n o documentaci&oacute;n requerida por <span className={styles.negritas}>BITSCRUM</span>, ya sea de manera personal, o 
              por cualquier otra tecnolog&iacute;a, as&iacute; como tambi&eacute;n, a trav&eacute;s de las aplicaciones disponibles para tel&eacute;fono celular o por v&iacute;a 
              electr&oacute;nica en cualquiera de las p&aacute;ginas de Internet de <span className={styles.negritas}>BITSCRUM</span> presentes o futuras (“P&aacute;ginas de internet de <span className={styles.negritas}>BITSCRUM</span> ”), 
              entre las que destacan: <span className={styles.link}><a href='http://www.bitsmexico.mx' rel="noreferrer" target="_blank">http://www.bitsmexico.mx</a></span>, 
              as&iacute; como tambi&eacute;n se podr&aacute; dar la recopilaci&oacute;n de datos en las aplicaciones de <span className={styles.negritas}>BITSCRUM</span> tal como lo es ‘’BITS’’.
            </p>
            <p className={styles.texto}>
              2.5. Las cookies son archivos de texto que son descargados autom&aacute;ticamente y almacenados en un disco duro del 
              equipo de c&oacute;mputo o del tel&eacute;fono celular del usuario al navegar en una determinada p&aacute;gina de Internet o aplicaci&oacute;n y que 
              permiten al servidor de Internet recordar algunos datos del usuario. Por medio de este Aviso de Privacidad se le informa 
              que es posible que <span className={styles.negritas}>BITSCRUM</span> use las cookies para identificar cuando un usuario regrese a la p&aacute;gina de Internet o a la 
              aplicaci&oacute;n de BITS con la finalidad de proporcionarle una mejor experiencia de navegaci&oacute;n. Las cookies de <span className={styles.negritas}>BITSCRUM</span> no 
              contienen informaci&oacute;n personal de identificaci&oacute;n alguna.
            </p>
          </div>
            <div className={`${styles.contenedorTexto} ${styles.segundaLinea}`}>
              <div className={styles.textoSubtitulo}>
                3.- FINALIDAD DE LA RECOPILACI&Oacute;N DE LOS DATOS PERSONALES.
              </div>
              <p className={styles.texto}>
                Los Datos Personales del Titular son recolectados y tratados por el Responsable o sus Encargados con la finalidad de permitirle al 
                Titular, llevar a cabo las siguientes actividades con el Responsable:
              </p>
              <p className={styles.texto}>
                <ul>
                  <li className={styles.inicioLista}>3.1. Finalidades primarias.</li>
                  <li className={styles.lista}>A) Solicitar, contratar, cambiar o cancelar servicios, ofrecidos por <span className={styles.negritas}>BITSCRUM</span>.</li>
                  <li className={styles.lista}>B) Efectuar pagos en l&iacute;nea.</li>
                  <li className={styles.lista}>C) Solicitar factura o comprobante fiscal digital.</li>
                  <li className={styles.lista}>D) Solicitar una cotizaci&oacute;n, sobre servicios.</li>
                  <li className={styles.lista}>E) Realizar reportes y gr&aacute;ficos personalizados sobre gesti&oacute;n de capital humano.</li>
                  <li className={styles.lista}>F) Contactar el Servicio de Atenci&oacute;n a Clientes.</li>
                  <li className={styles.lista}>G) Participar en encuestas.</li>
                  <li className={styles.lista}>H) Utilizar los distintos servicios de sus correspondientes Sitios Web incluyendo la descarga de contenidos y formatos.</li>
                  <li className={styles.lista}>I) Utilizar los distintos servicios de sus correspondientes aplicaciones m&oacute;viles incluyendo la descarga de contenidos y formatos.</li>
                  <li className={styles.lista}>J) Compartir sus comentarios o sugerencias sobre los servicios.</li>
                  <li className={styles.lista}>K) Procesar pagos.</li>
                  <li className={styles.lista}>L) Cualquier otra actividad de naturaleza an&aacute;loga a las descritas en los incisos previamente citados.</li>
                </ul>
              </p>
            </div>
            <div className={`${styles.contenedorTexto} ${styles.segundaLinea}`}>
              <p className={styles.texto}>
                3.2.- Finalidades Secundarias.
              </p>
              <p className={styles.texto}>
                Asimismo, el Responsable, directamente o a trav&eacute;s de sus Encargados, puede utilizar sus Datos Personales con la finalidad de:
              </p>
              <p className={styles.texto}>
                <ul>
                  <li className={styles.lista}>A) Realizar estudios sobre intereses y comportamiento de sus usuarios, clientes, consumidores, proveedores, y de aquellos terceros con los que trate.</li>
                  <li className={styles.lista}>B) Enviar comunicados con ofertas,s relacionado mensajes, promocionales, comunicados con fines mercadot&eacute;cnicos, publicitarios y de prospecci&oacute;n comercial respecto de productos o servicios, nuevos o existentes;</li>
                  <li className={styles.lista}>C) Efectuar estudios de mercado y de consumo a efecto de adquirir y ofrecer productos y servicios personalizados, as&iacute; como publicidad y contenidos m&aacute;s adecuados a las necesidades de sus clientes, consumidores, proveedores y otros terceros con los que trate.</li>
                  <li className={styles.lista}>D) Aplicar encuestas, estudios de mercado, participar en eventos, concursos, trivias, juegos y sorteos, participar en redes sociales, chats e informaci&oacute;n que nos permita evaluar la calidad de los productos y/o servicios.</li>
                </ul>
              </p>
            </div>
            <div className={`${styles.contenedorTexto} ${styles.segundaLinea}`}>
              <div className={styles.textoSubtitulo}>
                4.- RECOLECCI&Oacute;N DE DATOS AL NAVEGAR EN APLICACIONES, SITIOS Y P&Aacute;GINAS WEB DE BITSCRUM.
              </div>
              <p className={styles.texto}>
                Dentro de las herramientas de captura autom&aacute;tica de datos utilizadas por <span className={styles.negritas}>BITSCRUM</span> en 
                sus sitios y p&aacute;ginas web se encuentran las cookies, los Web beacons, y los enlaces en los correos electr&oacute;nicos.
              </p>
              <p className={styles.texto}>
                V&iacute;nculos en los correos electr&oacute;nicos de <span className={styles.negritas}>BITSCRUM</span>.- Los correos electr&oacute;nicos que incluyen 
                v&iacute;nculos que permiten a <span className={styles.negritas}>BITSCRUM</span> saber si usted activ&oacute; dicho v&iacute;nculo y visit&oacute; la p&aacute;gina web de 
                destino, pudiendo esta informaci&oacute;n ser incluida en su perfil. En caso de que usted prefiera 
                que <span className={styles.negritas}>BITSCRUM</span> no recolecte informaci&oacute;n sobre su interacci&oacute;n con dichos v&iacute;nculos, usted puede 
                optar por modificar el formato de las comunicaciones de <span className={styles.negritas}>BITSCRUM</span> (por ejemplo, que el mensaje 
                sea recibido en formato de texto y no en formato HTML) o puede hacer caso omiso del v&iacute;nculo y no acceder a su contenido.
              </p>
            </div>
            <div className={`${styles.contenedorTexto} ${styles.segundaLinea}`}>
              <div className={styles.textoSubtitulo}>
                5.-TRANSFERENCIAS DE DATOS.
              </div>
              <p className={styles.texto}>
                Tus datos personales ser&aacute;n compartidos cuando nos los requiera formalmente alguna autoridad en M&eacute;xico o 
                en el extranjero y a cualquier otra persona autorizada por la Ley Federal de Protecci&oacute;n de Datos 
                Personales en Posesi&oacute;n de los Particulares y/o su Reglamento (en adelante como “Ley de Datos Personales”). 
                Salvo que la Ley nos exija lo contrario, solamente compartimos informaci&oacute;n de manera disociada, esto es: de 
                forma an&oacute;nima, sin identificarte, a efecto de brindarte mayor protecci&oacute;n.
              </p>
              <p className={styles.texto}>
              “BITSCRUM TECHNOLOGIES, S.A.P.I DE C.V.”, podr&aacute; compartir tu informaci&oacute;n cuando subcontrate a terceros, sean prestadores de 
              servicios de cobranza, abogados, auditores, contadores o encargados de sistemas y plataformas digitales que 
              requieran procesar su informaci&oacute;n por cuenta de <span className={styles.link}><a href='http://www.bitsmexico.mx' rel="noreferrer"  target="_blank">http://www.bitsmexico.mx</a></span> o por medio de la aplicaci&oacute;n para tel&eacute;fonos 
              inteligentes y tabletas <span className={styles.subrayado}>‘’BITS’’</span> y bajo las instrucciones de “BITSCRUM TECHNOLOGIES, S.A.P.I DE C.V” (“Encargados”), por ejemplo, 
              al tercero encargado de la operaci&oacute;n y mantenimiento de la plataforma de denominada BITS®”, tomando como referencia 
              los datos personales que “BITSCRUM TECHNOLOGIES, S.A.P.I DE C.V.” tuviera recolectados.
              Adicionalmente y en atenci&oacute;n a lo dispuesto en la Ley de Datos Personales, <span className={styles.negritas}>BITSCRUM</span> podr&aacute; transferir y/o compartir 
              los Datos Personales Recabados de los Usuarios, en los siguientes casos:
              </p>
              <p className={styles.texto}>
                <ol className={styles.listadecoracion}>
                  <li className={styles.listadecoracion}>Cuando la transferencia sea requerida para mantener o cumplir con las obligaciones derivadas de una obligaci&oacute;n jur&iacute;dica entre el Responsable y el Usuario.</li>
                  <li className={styles.listadecoracion}>Transferencia de los Datos Personales Recabados a sociedades controladoras, subsidiarias o afiliadas bajo el control de “BITSCRUM TECHNOLOGIES, S.A.P.I DE C.V.” </li>
                  <li className={styles.listadecoracion}>En los dem&aacute;s supuestos establecidos en la Ley de Datos Personales, para los cuales no se requiere consentimiento de su titular.</li>
                </ol>
              </p>
              <p className={styles.texto}>
                Para el caso en que <span className={styles.subrayado}>BITS</span> sea adquirido por un tercero, sus Datos Personales ser&aacute;n transferidos a &eacute;ste. El adquirente, 
                como nuevo Responsable del tratamiento de sus Datos Personales, seguir&aacute; manteniendo las medidas de seguridad administrativas, 
                t&eacute;cnicas y f&iacute;sicas necesarias que permitan garantizar la privacidad y protecci&oacute;n de sus Datos Personales contra daño, p&eacute;rdida, 
                alteraci&oacute;n, destrucci&oacute;n o el uso, acceso o tratamiento no autorizado de los mismos de conformidad con lo establecido en el presente Aviso de Privacidad.
              </p>
            </div>
            <div className={`${styles.contenedorTexto} ${styles.segundaLinea}`}>
              <div className={styles.textoSubtitulo}>
                6.-LIMITACI&Oacute;N DE USO Y DIVULGACI&Oacute;N DE LA INFORMACI&Oacute;N.
              </div>
              <p className={styles.texto}>
                El Responsable y/o sus Encargados conservar&aacute;n los Datos Personales del Titular durante el tiempo que sea necesario para 
                procesar sus solicitudes de informaci&oacute;n, productos y/o servicios, as&iacute; como para mantener los registros contables, financieros 
                y de auditoria en t&eacute;rminos de la LFPDPPP y de la legislaci&oacute;n mercantil, fiscal y administrativa vigente. Adem&aacute;s de controlar 
                la divulgaci&oacute;n de dichos datos o informaci&oacute;n de socios comerciales.
              </p>
              <p className={styles.texto}>
                Los Datos Personales del Titular recolectados por el Responsable y/o sus Encargados se encontrar&aacute;n protegidos por medidas de 
                seguridad administrativas, t&eacute;cnicas y f&iacute;sicas adecuadas contra el daño, p&eacute;rdida, alteraci&oacute;n, destrucci&oacute;n o uso, acceso o trata
                miento no autorizados, de conformidad con lo dispuesto en la LFPDPPP y de la regulaci&oacute;n administrativa derivada de la misma. 
                No obstante lo señalado anteriormente, <span className={styles.negritas}>BITSCRUM</span> no garantiza que terceros no autorizados no puedan tener acceso a los sistemas 
                f&iacute;sicos o l&oacute;gicos de los Titulares o del Responsable o en los documentos electr&oacute;nicos y ficheros almacenados en sus sistemas. 
                En consecuencia, <span className={styles.negritas}>BITSCRUM</span> no ser&aacute; en ning&uacute;n caso responsable de los daños y perjuicios que pudieran derivarse de dicho acceso no autorizado.
              </p>
            </div>
            <div className={`${styles.contenedorTexto} ${styles.segundaLinea}`}>
              <div className={styles.textoSubtitulo}>
                7.-DEPARTAMENTO DE DATOS PERSONALES; DOMICILIO.
              </div>
              <p className={styles.texto}>
                Para cualquier comunicaci&oacute;n acerca de nuestro Aviso de privacidad de Privacidad, por favor contacte a nuestro Departamento de 
                Datos Personales:Correo de contacto: <span className={styles.link}><a href="mailto:contacto@bitsmexico.mx">contacto@bitsmexico.mx</a>. </span>
              </p>
              <p className={styles.texto}>
                Domicilio: Avenida Paseo de los Chicahuales N&uacute;mero 1024, Colonia Corral de Barrancos, C&oacute;digo Postal 20900, Jes&uacute;s Mar&iacute;a, Aguascalientes.
              </p>
            </div>
            <div className={`${styles.contenedorTexto} ${styles.segundaLinea}`}>
              <div className={styles.textoSubtitulo}>
                8.-CONSENTIMIENTO DEL TITULAR.
              </div>
              <p className={styles.texto}>
                Para efectos de lo dispuesto en la LFPDP y en particular en su Art&iacute;culo 17, el Titular manifiesta que:
              </p>
              <p className={styles.texto}>
                <ul>
                  <li className={styles.lista}>A) El presente Aviso de privacidad le ha sido dado a conocer por el Responsable.</li>
                  <li className={styles.lista}>B) Que ha le&iacute;do, entendido y acordado los t&eacute;rminos señalados en este Aviso de privacidad, 
                  por lo que otorga su consentimiento respecto al tratamiento de sus Datos Personales conforme lo establece la LFPDPPP y legislaci&oacute;n aplicable.</li>
                  <li className={styles.lista}>C) Que en caso de que los Datos Personales recopilados incluyan Datos Personales sensibles 
                  o financieros, sean estos proporcionados en formato impreso, o utilizando medios electr&oacute;nicos, son actos que constituyen su consentimiento expreso como su titular en t&eacute;rminos del segundo p&aacute;rrafo del art&iacute;culo 8 de la LFPDPPP y dem&aacute;s legislaci&oacute;n aplicable.</li>
                  <li className={styles.lista}>D) Que otorga su consentimiento para que BITSCRUM o sus Encargados realicen transferencias 
                  de Datos Personales a terceros nacionales o extranjeros, en el entendido de que el tratamiento que dichos terceros den a sus Datos 
                  Personales deber&aacute; ajustarse a lo establecido en este Aviso de privacidad.</li>
                  <li className={styles.lista}>E) Que en caso de que el Titular no se oponga a los t&eacute;rminos del Aviso de privacidad se considerar&aacute; 
                  acordado y consentido su contenido y que el consentimiento del Titular podr&aacute; ser revocado en cualquier momento por &eacute;ste.</li>
                  <li className={styles.lista}>F) No obstante cualquier disposici&oacute;n de este Aviso de privacidad, el Titular reconoce que no se requerir&aacute; de 
                  su consentimiento para el tratamiento de Datos Personales por parte del Responsable o de terceros en cualquiera de los casos señalados en el Art&iacute;culo 10 de la LFPDPPP.</li>
                </ul>
              </p>
            </div>
            <div className={`${styles.contenedorTexto} ${styles.segundaLinea}`}>
              <div className={styles.textoSubtitulo}>
                9.-PROCEDIMIENTO PARA EJERCER LOS DERECHOS COMO TITULAR DE DATOS PERSONALES
              </div>
              <p className={styles.texto}>
                Usted tiene, en todo momento, derecho de acceder, rectificar y cancelar sus datos personales, as&iacute; como de oponerse al 
                tratamiento de los mismos o revocar el consentimiento que para tal fin nos haya proporcionado presentando una solicitud con la informaci&oacute;n y documentaci&oacute;n siguiente:
              </p>
              <p className={styles.texto}>
                1. Nombre del Titular, domicilio, tel&eacute;fono y direcci&oacute;n de correo electr&oacute;nico.
              </p>
              <p className={styles.texto}>
                2. Los documentos que acrediten su identidad (copia simple en formato impreso o 
                electr&oacute;nico de su credencial de elector, c&eacute;dula profesional, pasaporte o Forma Migratoria) o, 
                en su caso, la representaci&oacute;n legal del Titular copia simple en formato impreso o electr&oacute;nico de la 
                carta poder simple con firma aut&oacute;grafa del Titular, el mandatario y sus correspondientes identificaciones
              </p>
              <p className={styles.texto}>
                3. La descripci&oacute;n clara y precisa de los Datos Personales respecto de los que busca ejercer alguno de los Derechos.
              </p>
              <p className={styles.texto}>
                4. Cualquier otro elemento o documento que facilite la localizaci&oacute;n de los Datos Personales del Titular.
              </p>
              <p className={styles.texto}>
                En el caso de las solicitudes de rectificaci&oacute;n de Datos Personales, el Titular respectivo deber&aacute; tambi&eacute;n indicar las modificaciones a realizarse y aportar la documentaci&oacute;n que sustente su petici&oacute;n.
              </p>
              <p className={styles.texto}>
                El Responsable o sus Encargados le responder&aacute;n al Titular respectivo en un plazo m&aacute;ximo de veinte d&iacute;as h&aacute;biles, 
                contados desde la fecha en que se recibi&oacute; la solicitud. Los plazos antes referidos podr&aacute;n ser ampliados s&oacute;lo en t&eacute;rminos de la LFPDPPP.
              </p>
              <p className={styles.texto}>
                La entrega de los Datos Personales ser&aacute; gratuita, solamente le corresponder&aacute; cubrir los gastos justificados de env&iacute;o 
                o el costo de reproducci&oacute;n en copias u otros formatos. En el caso de que el Titular reitere su solicitud en un periodo 
                menor a doce meses, deber&aacute; cubrir los costos correspondientes equivalentes a 1.5 d&iacute;as de Salario M&iacute;nimo Vigente en la 
                Ciudad de M&eacute;xico en t&eacute;rminos de la LFPDPPP, a menos que existan modificaciones sustanciales al Aviso de privacidad que motiven nuevas consultas.
              </p>
              <p className={styles.texto}>
                Para efectos de las solicitudes de cancelaci&oacute;n de Datos Personales, adem&aacute;s de lo dispuesto por el presente Aviso de privacidad, 
                se estar&aacute; a lo dispuesto en el Art&iacute;culo 26 de la LFPDPPP, incluyendo los casos de excepci&oacute;n de cancelaci&oacute;n de Datos Personales ah&iacute; señalados.
              </p>
              <p className={styles.texto}>
                La presentaci&oacute;n de una solicitud de oposici&oacute;n del uso de Datos Personales por el Titular de los mismos, 
                dar&aacute; al Responsable la facultad de oponerse al uso de los Datos Personales que como Titular haya entregado al oponente.
              </p>
            </div>
            <div className={`${styles.contenedorTexto} ${styles.segundaLinea}`}>
              <div className={styles.textoSubtitulo}>
                10.-CAMBIOS AL AVISO DE PRIVACIDAD.
              </div>
              <p className={styles.texto}>
                <span className={styles.negritas}>BITSCRUM</span> se reserva el derecho de actualizar peri&oacute;dicamente el presente Aviso de privacidad para reflejar los cambios en 
                nuestras pr&aacute;cticas de informaci&oacute;n. Es responsabilidad del Titular revisar peri&oacute;dicamente el contenido del Aviso de privacidad 
                en el sitio <span className={styles.link}><a href='http://www.bitsmexico.mx' rel="noreferrer" target="_blank">http://www.bitsmexico.mx</a></span> en donde se publicar&aacute;n los cambios realizados conjuntamente con la fecha de la &uacute;ltima 
                actualizaci&oacute;n. El Responsable entender&aacute; que de no expresar lo contrario, significa que el Titular ha le&iacute;do, entendido y acordado 
                los t&eacute;rminos ah&iacute; expuestos, lo que constituye su consentimiento a los cambios y/o actualizaciones respecto al tratamiento de sus Datos Personales.
              </p>
            </div>
        </div>
      </div>
    </>
  )
}

export default AvisoPrivacidadScreen