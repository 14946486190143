import { useEffect, useReducer } from "react";
import "../ConfigPayroll/Politic.css";
import { Grid } from "@material-ui/core";
import { useToggle } from "../../hooks/useToggle";
import MenuListPolitic from "./Modals/MenuListPolitic";
import EliminaPolitic from "./Modals/EliminaPolitic";
import EditaPolitic from "./Modals/EditaPolitic";
import { TabPoliticContext } from "../../context/ConfigPayrollContext/TabPoliticContext";
import CrearPoliticModal from "./Modals/CrearPoliticModal";
import { tabPoliticReducer } from "../../context/ConfigPayrollContext/TabPoliticReducer";
import { useQuery } from "@apollo/client";
import { GET_ALL_POLITIC } from "../../Querys/querys";
import { formatterMxn } from "../../helpers/formatoMoneda";
import CheckPermission from "../../helpers/Administradores/Permissions";
import PoliticHolidaysModal from "./Modals/PoliticHolidaysModal";

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

const PoliticTable = (props: TabPanelProps) => {
  const resultPolitic = useQuery(GET_ALL_POLITIC);
  const allPolitic = resultPolitic.data?.GET_ALL_POLITIC;

  const [PoliticState, PoliticDispatch] = useReducer(tabPoliticReducer, {
    loading: true,
    Politics: [],
    PoliticsFilter: [],
  });
  const [inactiveOpen, setInactiveOpen] = useToggle(false);
  const [addPoliticOpen, setAddPoliticOpen] = useToggle(false);

  useEffect(() => {
    obtenerDatos();
  }, []);

  const obtenerDatos = async () => {};

  const handleSetFormat = (value: any) => {
    //parse value to string if is number
    if (typeof value === "number") {
      value = value.toString();
    }

    if (typeof value === "string") {
      // eslint-disable-next-line
      let valueFormatted = value.replace(/\,/g, "");

      valueFormatted = parseFloat(valueFormatted).toFixed(2);

      let x = formatterMxn(valueFormatted);
      if (x === "nan") return false;
      return x;
      // formik.setFieldValue(field, x);
    }
  };

  return (
    <>
      <div className="contenedorPrincipal">
        <div className="contenedorHeader">
          <div>
            <span className="tituloHeader">Políticas</span>
          </div>
          <div>
            <TabPoliticContext.Provider
              value={{
                inactiveOpen,
                setInactiveOpen,
                addPoliticOpen,
                setAddPoliticOpen,
                PoliticState,
                PoliticDispatch,
              }}
            >
              {CheckPermission("ConfiguracionNominas.Politicas.Crear") && (
                <button onClick={setAddPoliticOpen} className="botonHeader">
                  <div className="contenedorBotonHeader">
                    <div className="textoBotonHeader">
                      <span>+ Nueva Política</span>
                    </div>
                  </div>
                </button>
              )}
              <CrearPoliticModal getDatos={obtenerDatos} />
            </TabPoliticContext.Provider>
          </div>
        </div>
        <div className="contenedorTabla">
          <div className="contenedorTituloTabla">
            <Grid container spacing={1}>
              <Grid item xs={2} className="tituloTabla">
                <span className="textotituloTabla">Nombre de la política</span>
              </Grid>
              <Grid item xs={1} className="tituloTabla">
                <span className="textotituloTabla">Grupo de Nómina</span>
              </Grid>
              <Grid item xs={1} className="tituloTabla">
                <span className="textotituloTabla">Días económicos</span>
              </Grid>
              <Grid item xs={1} className="tituloTabla">
                <span className="textotituloTabla">Días Aguinaldo</span>
              </Grid>
              <Grid item xs={2} className="tituloTabla">
                <span className="textotituloTabla">Vales de despensa</span>
              </Grid>
              <Grid item xs={2} className="tituloTabla">
                <span className="textotituloTabla">Fondo de ahorro</span>
              </Grid>
              <Grid item xs={1} className="tituloTabla">
                <span className="textotituloTabla">Vales de restaurante</span>
              </Grid>
              <Grid item xs={1} className="tituloTabla">
                <span className="textotituloTabla">Prima vacacional</span>
              </Grid>
              <Grid item xs={1} className="tituloTabla">
                <span className="textotituloTabla">Más información</span>
              </Grid>
            </Grid>
          </div>
          <div>
            <ul>
              {allPolitic?.map((lis: any, index: number) => (
                <li className="listaTabla"
                key={index}
                >
                  <Grid container spacing={1}>
                    <Grid item xs={2} className="tituloTabla">
                      <div className="tablaColumnaFlex">
                        <span className="textoTabla">{lis.policy_name}</span>
                      </div>
                    </Grid>
                    <Grid item xs={1} className="tituloTabla">
                      <div className="tablaColumnaFlex">
                        <span className="textoTabla">{lis.group_name}</span>
                      </div>
                    </Grid>
                    <Grid item xs={1} className="tituloTabla">
                      <div className="tablaColumnaFlex">
                        <span className="textoTabla">{lis.economic_days}</span>
                      </div>
                    </Grid>
                    <Grid item xs={1} className="tituloTabla">
                      <div className="tablaColumnaFlex">
                        <span className="textoTabla">{lis.DiasAguinaldo}</span>
                      </div>
                    </Grid>
                    <Grid item xs={2} className="tituloTabla">
                      <div className="tablaColumnaFlex">
                        <span className="textoTabla">
                          {String(lis.pantry_value_type) === "Porcentual" &&
                            lis.pantry_value_cap + "%"}
                          {String(lis.pantry_value_type) === "Fijo" &&
                            handleSetFormat(lis.pantry_value_cap)}
                          {String(lis.pantry_value_type) === "No Aplica" && "No Aplica"}
                        </span>
                      </div>
                    </Grid>
                    <Grid item xs={2} className="tituloTabla">
                      <div className="tablaColumnaFlex">
                        <span className="textoTabla">
                          {String(lis.saving_fund_type) === "Porcentual" &&
                            lis.saving_fund_cap + "%"}
                          {String(lis.saving_fund_type)  === "Fijo" &&
                            handleSetFormat(lis.saving_fund_cap)}
                          {String(lis.saving_fund_type)  === "No Aplica" && "No Aplica"}
                        </span>
                      </div>
                    </Grid>
                    <Grid item xs={1} className="tituloTabla">
                      <div className="tablaColumnaFlex">
                        <span className="textoTabla">
                          {String(lis.restaurant_value_type) === "Porcentual" &&
                            lis.restaurant_value_cap + "%"}
                          {String(lis.restaurant_value_type) === "Fijo" &&
                            handleSetFormat(lis.restaurant_value_cap)}
                          {String(lis.restaurant_value_type) === "No Aplica" && "No Aplica"}
                        </span>
                      </div>
                    </Grid>
                    <Grid item xs={1} className="tituloTabla">
                      <div className="tablaColumnaFlex">
                        <span className="textoTabla">
                          {String(lis.vacation_bonus_type) === "Porcentual" && lis.vacation_bonus_cap + "%"}
                          {(String(lis.vacation_bonus_type) === "No Aplica" || !lis.vacation_bonus_type) && "No Aplica"}
                        </span>
                      </div>
                    </Grid>
                    <Grid item xs={1} className="tituloTabla">
                      <div className="tablaColumnaFlex">
                        <MenuListPolitic
                          {...{
                            _id: lis.id,
                            PolicyName: lis.policy_name,
                            EconomicDays: lis.economic_days,
                            AnniversaryVacationPremium:
                            lis.anniversary_vacation_premium,
                            PantryValueType: lis.pantry_value_type,
                            PantryValueCap: lis.pantry_value_cap,
                            PantryValue: lis.pantry_value,
                            SavingsFundType: lis.saving_fund_type,
                            SavingsFundCap: lis.saving_fund_cap,
                            SavingsFund: lis.saving_fund,
                            RestaurantValue: lis.restaurant_value,
                            RestaurantValueType: lis.restaurant_value_type,
                            RestaurantValueCap: lis.restaurant_value_cap,
                            AbsenceDiscount: lis.absence_discount,
                            DisabilityDiscount: lis.disability_discount,
                            VoucherCost: lis.voucher_cost,
                            DiscountDay: lis.discount_day,
                            SeniorityDate: lis.seniority_date,
                            ContractStartDate: lis.contract_start_date,
                            PantryType: lis.pantry_type,
                            SavingsType: lis.saving_type,
                            RestaurantType: lis.restaurant_type,
                            idPayrollGroup: lis.idPayrollGroup,
                            VacationBonusType: lis.vacation_bonus_type,
                            VacationBonus: lis.vacation_bonus,
                            VacationBonusCap: lis.vacation_bonus_cap,
                            DiasAguinaldo: lis.DiasAguinaldo
                          }}
                        />
                      </div>
                    </Grid>
                  </Grid>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <EditaPolitic getDatos={obtenerDatos} />
        <EliminaPolitic getDatos={obtenerDatos} />
        <PoliticHolidaysModal></PoliticHolidaysModal>
      </div>
    </>
  );
};

export default PoliticTable;
