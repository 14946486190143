import { useState,useContext, useEffect} from "react"
import styles from "../../../../styles/MiEmpresa/CertificadosLlaves.module.css"
import EmpresaContext from "../../../../context/Empresa/EmpresaContext"
import { useFormik } from "formik"
import * as Yup from "yup"
import { Form } from "semantic-ui-react";
import { updateState, clearEmpresaModal, addRegistroPatronal, updateRegistroPatronal } from "../../../../context/Empresa/Actions"
import FileHelperIncident from "../../../Collaborator/Expedient/Fields/FileHelperIncident";
import InputMask from "react-input-mask";
import { ErrorAlert } from '../../../../alerts/errorAlert';
import { GET_ALL_RIESGOPUESTO } from "../../../../Querys/querys";
import { useQuery } from "@apollo/client";
import { TextField, FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";

const CertificadosLlaves = (props:any) =>{
  
  const {state, dispatch} =useContext(EmpresaContext)
  const [showPassword, setShowPassword] = useState(false)
  const [showPasswordTimbre, setShowPasswordTimbre] = useState(false)
  const [uno, setUno] = useState("")
  const [dos, setDos] = useState("")
  const [dot, setDot] = useState(0)
  const [allRiesgoPuesto, setAllRiesgoPuesto] = useState<any>([])
  const {data} = useQuery(GET_ALL_RIESGOPUESTO);
  useEffect(() => {
    if(data){
      setAllRiesgoPuesto(data.GET_ALL_RIESGOPUESTO);
    }
  }, [data]);
  const initialValues = () =>{
    return{
      password: state.certificadoLlaves.password,
      passwordTimbre: state.certificadoLlaves.passwordTimbre,
      NoCertificado: state.certificadoLlaves.NoCertificado,
      usuarioIMSS: state.certificadoLlaves.usuarioIMSS
    }
  }

  const cambioEstado = async(event:any, value:{}) =>{
    await updateState(event, state, dispatch,'certificadoLlaves',value, 'tabDos')
  }

  const formik = useFormik({
    initialValues: initialValues(),
    validationSchema: Yup.object(validationSchema()),
    onSubmit:(formData) =>{

      if(props.fileSello === undefined){
        ErrorAlert({ text: "Agregue sello digital IMSS." });
      }else{
        if(props.fileIMSS === undefined){
          ErrorAlert({ text: "Agregue llave certificado IMSS." });
        }else{
          if(props.fileCerTimbre === undefined){
            ErrorAlert({ text: "Agregue sello digital SAT." });
          }else{
            if(props.fileKeyTimbre === undefined){
              ErrorAlert({ text: "Agregue llave certificado SAT." });
            }else{


                let total = 0
                state.registroPatronal?.forEach((reg:any) =>{
                  total = total + 1
                })

                if(total === 0){
                  ErrorAlert({ text: "Agregue registro patronal." });
                }else{
                  props.setActivo('3')
                  cambioEstado('', formData)
                }
              
            }
            
          }
          
        }
        
      }
      
    },

  })

  const inputFile = () => {
  
    if(props.fileIMSS === undefined){
      
        return <div >
            <FileHelperIncident
            name="ArchivoIMSSEmpresa"
            parametrofrom="ArchivoIMSSEEmpresa"
            setArchives={props.setFileIMSS}
            archives={props.fileIMSS}
            accept = ".key, .KEY"
            styleF="SeleccionarArchivo"
            />
        </div>
    }else{
        return <div>
            <FileHelperIncident
                name="ArchivoIMSSEmpresa"
                parametrofrom="ArchivoIMSSEEmpresa"
                setArchives={props.setFileIMSS}
                archives={props.fileIMSS}
                accept = ".key, .KEY"
                styleF="CambiarArchivo"
            />
        </div>
    }

    
  }

  const inputFileSello = () => {
  
    if(props.fileSello === undefined){
      
        return <div >
            <FileHelperIncident
            name="ArchivoSelloEmpresa"
            parametrofrom="ArchivoSelloEmpresa"
            setArchives={props.setFileSello}
            archives={props.fileSello}
            accept = ".PFX, .pfx"
            styleF="SeleccionarArchivo"
            />
        </div>
    }else{
        return <div>
            <FileHelperIncident
                name="ArchivoSelloEmpresa"
                parametrofrom="ArchivoSelloEmpresa"
                setArchives={props.setFileSello}
                archives={props.fileSello}
                accept = ".PFX, .pfx"
                styleF="CambiarArchivo"
            />
        </div>
    }

    
  }

  const inputFileLlave = () => {
  
    if(props.fileKeyTimbre === undefined){
      
        return <div >
            <FileHelperIncident
            name="ArchivoKeyTimbreEmpresa"
            parametrofrom="ArchivoKeyTimbreEmpresa"
            setArchives={props.setFileKeyTimbre}
            archives={props.fileKeyTimbre}
            accept = ".key, .KEY"
            styleF="SeleccionarArchivo"
            />
        </div>
    }else{
        return <div>
            <FileHelperIncident
                name="ArchivoKeyTimbreEmpresa"
                parametrofrom="ArchivoKeyTimbreEmpresa"
                setArchives={props.setFileKeyTimbre}
                archives={props.fileKeyTimbre}
                accept = ".key, .KEY"
                styleF="CambiarArchivo"
            />
        </div>
    }

    
  }
  const inputFileCerTimbre = () => {
  
    if(props.fileCerTimbre === undefined){
      
        return <div >
            <FileHelperIncident
            name="ArchivoCerTimbreEmpresa"
            parametrofrom="ArchivoCerTimbreEmpresa"
            setArchives={props.setFileCerTimbre}
            archives={props.fileCerTimbre}
            accept = ".cer, .CER"
            styleF="SeleccionarArchivo"
            />
        </div>
    }else{
        return <div>
            <FileHelperIncident
                name="ArchivoCerTimbreEmpresa"
                parametrofrom="ArchivoCerTimbreEmpresa"
                setArchives={props.setFileCerTimbre}
                archives={props.fileCerTimbre}
                accept = ".cer, .CER"
                styleF="CambiarArchivo"
            />
        </div>
    }

    
  }

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleClickShowPasswordTimbre = () => {
    setShowPasswordTimbre(!showPasswordTimbre);
  };

  const handleClose = () => {
    props.setFileLogo(undefined)
    props.setFileIMSS(undefined)
    props.setFileSello(undefined)
    props.setFileKeyTimbre(undefined)
    props.setFileCerTimbre(undefined)
    props.setActivo('1')
    props.setRegimenFiscalState('')
    props.setEstadoState('')
    props.setMunicipioState('')
    props.setBankState('')
    props.setRiskPlaced('')
    props.setOutsourcers(false)
    clearEmpresaModal({}, dispatch);
  };

  const deleteReg = async(PrimaRiesgo: any, RegistroPatronal: any) => {
    const newReg: any[]= []
    
    state.registroPatronal?.forEach((reg:any) =>{
      if(reg.PrimaRiesgo !== PrimaRiesgo || reg.RegistroPatronal !== RegistroPatronal){
        newReg.push(reg)
      }
    })
    
    await addRegistroPatronal(newReg, dispatch)
  }
  const muestraRegistroPatronal = (reg: any, index: number) =>{
    const textRiskPlaced = allRiesgoPuesto?.filter((item: any) => item.code === reg.riskPlaced)

    return<div className={styles.lineaRegistro} key={index}>
      <div className={styles.lineanombreRegistro}>{reg.RegistroPatronal}</div>
      <div className={styles.lineaDivisionRegistro}></div>
      <div className={styles.lineaprimaRiesgo}>{reg.PrimaRiesgo}</div>
      <div className={styles.lineaDivisionRegistro}></div>
      <div className={styles.lineaprimaRiesgo}
      style={{width:"95px"}}
      >{textRiskPlaced[0]?.name}</div>
      <div className={styles.lineaContenedorEliminar} onClick={()=>deleteReg(reg.PrimaRiesgo, reg.RegistroPatronal)}>
        <div className={styles.iconoLineaPrimaRiesgo}></div>
      </div>
    </div>
  }

  const addNewRegistroPatronal = async()=>{
 
    const registro = document.querySelector("#uno")
    const riesgo = document.querySelector("#dos")
    const placed = document.querySelector("#demo-simple-select-label")
    const contenedorInputsRegistro = document.querySelector("#contenedorInputsRegistro")
    const conteinerTextAlert = document.querySelector("#conteinerTextAlert")
    const contenedorRegistroPatronal = document.querySelector("#contenedorRegistroPatronal")
    let addReg = 1
    
    if(contenedorInputsRegistro && conteinerTextAlert && contenedorRegistroPatronal){
      
      if(registro && riesgo && placed){

        if(registro.getAttribute('value') === "" || riesgo.getAttribute('value') === "" || props.riskPlaced === ""){
          contenedorRegistroPatronal.className=styles.contenedorRegistroRed
          addReg = 0
        }else{
          if(String(registro.getAttribute('value')).length < 11 ){

            contenedorInputsRegistro.className=styles.contenedorInputsRegistroRed
            const p = document.createElement('p')
            p.textContent = "Regimen patronal 11 caracteres obligatorios."
            p.className=styles.validateText
            p.id="alertComent"
            conteinerTextAlert.appendChild(p)
            addReg = 0
  
          }else{
            const patt = new RegExp(/^[a-zA-Z]{1}$/);
            const patt2 = new RegExp(/^[0-9]{5}$/);
            const patt3 = new RegExp(/^[0-9]{2}$/);
            const patt4 = new RegExp(/^[0-9]{1}$/);
  
            if( !patt.test(String(registro.getAttribute('value')).substring(0,1)) ){
  
              contenedorInputsRegistro.className=styles.contenedorInputsRegistroRed
              const p = document.createElement('p')
              p.textContent = "Verifique registro patronal."
              p.className=styles.validateText
              p.id="alertComent"
              conteinerTextAlert.appendChild(p)
              addReg = 0
  
              
            }else{
              if( !patt3.test(String(registro.getAttribute('value')).substring(1,3)) ){
  
                contenedorInputsRegistro.className=styles.contenedorInputsRegistroRed
                const p = document.createElement('p')
                p.textContent = "Verifique registro patronal."
                p.className=styles.validateText
                p.id="alertComent"
                conteinerTextAlert.appendChild(p)
                addReg = 0
    
                
              }else{
                if( !patt2.test(String(registro.getAttribute('value')).substring(3,8)) ){
  
                  contenedorInputsRegistro.className=styles.contenedorInputsRegistroRed
                  const p = document.createElement('p')
                  p.textContent = "Verifique registro patronal."
                  p.className=styles.validateText
                  p.id="alertComent"
                  conteinerTextAlert.appendChild(p)
                  addReg = 0
                }else{
  
  
                  if( !patt3.test(String(registro.getAttribute('value')).substring(8,10)) ){
  
                    contenedorInputsRegistro.className=styles.contenedorInputsRegistroRed
                    const p = document.createElement('p')
                    p.textContent = "Verifique registro patronal."
                    p.className=styles.validateText
                    p.id="alertComent"
                    conteinerTextAlert.appendChild(p)
                    addReg = 0
                  }else{
  
  
  
                    if( !patt4.test(String(registro.getAttribute('value')).substring(10,11)) ){
  
                      contenedorInputsRegistro.className=styles.contenedorInputsRegistroRed
                      const p = document.createElement('p')
                      p.textContent = "Verifique registro patronal."
                      p.className=styles.validateText
                      p.id="alertComent"
                      conteinerTextAlert.appendChild(p)
                      addReg = 0
                    }
  
                  }
                }
              }
            }
          }
        }

        if(addReg === 1){
          let riesgoNumber: any
          if(riesgo){
            riesgoNumber = riesgo.getAttribute('value')
          }
          const newReg= {
            "RegistroPatronal": registro.getAttribute('value'),
            "PrimaRiesgo": String(parseFloat(riesgoNumber)),
            "riskPlaced": props.riskPlaced
          }
          
          if(state.registroPatronal === undefined){

            state.registroPatronal = [newReg]
          }else{
            state.registroPatronal.push(newReg)
          }
          await updateRegistroPatronal(state, dispatch)
          
          setUno("")
          setDos("")
          setDot(0)
          props.setRiskPlaced("")
          const select = document.getElementById("demo-simple-select-label")
          select?.setAttribute("style", "top: -3px; background-color: white; font-size: 13px;")
        }
        

      }

    }
    
  }
  const deleteStyle = ()=>{
    const contenedorInputsRegistro = document.querySelector("#contenedorInputsRegistro")
    const contenedorRegistroPatronal = document.querySelector("#contenedorRegistroPatronal")
    const conteinerTextAlert = document.querySelector("#conteinerTextAlert")
    
    if(contenedorInputsRegistro && conteinerTextAlert){
      contenedorInputsRegistro.className=styles.contenedorInputsRegistro
      const p = document.querySelector('#alertComent')
      if(p){
        conteinerTextAlert.removeChild(p)
      }
    }
    
    if(contenedorRegistroPatronal && conteinerTextAlert){
      contenedorRegistroPatronal.className=styles.contenedorRegistro
      const p = document.querySelector('#alertComent')
      if(p){
        conteinerTextAlert.removeChild(p)
      }
    }

  }

  const upDateTotal = ((total:any)=>{

    const digitNew = total.replace(dos, "")
    
    let totalWith = ""
    if(total !== ""){
      if(total.length > dos.length  ){
        if(dos.length === 0){

          totalWith = total.replace(/\D/g, "")

        }else{
    
          if(String(digitNew) === "."){
            if(dot === 0){
              setDot(1)
              totalWith = total
            }else{
              totalWith = dos+digitNew.replace(/\D/g, "")
            }
          }else{

            if(String(dos) === "0"){
              if(String(digitNew) !== "."){
                totalWith = dos
              }else{
                totalWith = total
              }
            }else{
              totalWith = dos+digitNew.replace(/\D/g, "")
            }
          }
          
        }
      }else{
        if(String(dos.replace(total, "")) === "."){
          setDot(0)
          
        }
        totalWith = total
      }

    }else{
      setDot(0)
      
    }
    
    

    setDos(totalWith)
  })

  const positionsSelect = ()=>{
    const select = document.getElementById("demo-simple-select-label")
    select?.setAttribute("style", "top: 0px; background-color: white;")

  }

  return(
    <div>
      <Form onSubmit={formik.handleSubmit}>
        
        <div className={styles.contenedorLlaves}>
          <div className={styles.tituloLlaves}>
          Certificado SAT
          </div>
          <div className={`${styles.contenedorArchivos} ${styles.contenedorArchivosDos}`}>
            <div className={styles.textoArchivos}>
              Certificado de sello digital
            </div>
            {inputFileCerTimbre()}
          </div>
          <div className={styles.contenedorArchivos}>
            <div>
              <div className={styles.textoArchivos}>
              Llave del certificado de sello digital
              </div>
              <div className={styles.contenedorDocumento}>
                <div className={styles.textoVerDocumento}>
                </div>
              </div>
            </div>
            {inputFileLlave()}
          </div> 
          
          <div className={styles.contenedorContrasena}>
            <TextField
              type={showPasswordTimbre? "text" : "password"} 
              name="passwordTimbre"
              defaultValue={state.certificadoLlaves.passwordTimbre}
              label="Contrase&ntilde;a certificado de sello digital*"
              variant="outlined"
              size="small"
              fullWidth={true}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              className={styles.password}
              error={
                formik.touched.passwordTimbre && Boolean(formik.errors.passwordTimbre)
              }
              InputProps={{
                readOnly: false,
                endAdornment: <div className={styles.iconoVerContrasena} onClick={handleClickShowPasswordTimbre} ></div>,
              }}
              style={{
                marginLeft: '26px',
                marginRight: '26px'
              }}
              onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
              />
              
          </div>
          <div className={styles.contenedorContrasena}>
            <InputMask
              mask="99999999999999999999"
              defaultValue={state.certificadoLlaves.NoCertificado}
              onChange={formik.handleChange}
              disabled={false}
              onBlur={formik.handleBlur}
            >
              <TextField
                name="NoCertificado"
                error={
                  formik.touched.NoCertificado && Boolean(formik.errors.NoCertificado)
                }
                label="Número certificado*"
                variant="outlined"
                size="small"
                fullWidth={true}
                id="NoCertificado"
                onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                style={{
                  marginLeft: '26px',
                  marginRight: '26px'
                }}
              />
            </InputMask>
              
          </div>
        </div>
        <div className={styles.contenedorLlaves}>
          <div className={styles.tituloLlaves}>
            Certificados IMSS
          </div>
          <div className={`${styles.contenedorArchivos} ${styles.contenedorArchivosDos}`}>
            <div className={styles.textoArchivos}>
              Certificado de sello digital
            </div>
            {inputFileSello()}
          </div>
          <div className={styles.contenedorArchivos}>
            <div>
              <div className={styles.textoArchivos}>
              Llave del certificado de sello digital
              </div>
              <div className={styles.contenedorDocumento}>
                <div className={styles.textoVerDocumento}>
                </div>
              </div>
            </div>
            {inputFile()}
          </div> 
          <div className={styles.contenedorContrasena}>
            <TextField
              type="text"
              name="usuarioIMSS"
              defaultValue={state.certificadoLlaves.usuarioIMSS}
              label="Usuario IMSS*"
              variant="outlined"
              size="small"
              fullWidth={true}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              error={
                formik.touched.usuarioIMSS && Boolean(formik.errors.usuarioIMSS)
              }
              style={{
                marginLeft: '26px',
                marginRight: '26px'
              }}
              onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
              />
              
          </div>
          <div className={styles.contenedorContrasena}>
            <TextField
              type={showPassword? "text" : "password"} 
              name="password"
              defaultValue={state.certificadoLlaves.password}
              label="Contrase&ntilde;a certificado de sello digital*"
              variant="outlined"
              size="small"
              fullWidth={true}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              className={styles.password}
              error={
                formik.touched.password && Boolean(formik.errors.password)
              }
              InputProps={{
                readOnly: false,
                endAdornment: <div className={styles.iconoVerContrasena} onClick={handleClickShowPassword} ></div>,
              }}
              style={{
                marginLeft: '26px',
                marginRight: '26px'
              }}
              onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
              />
              
          </div>
        </div>
        <div className={styles.contenedorRegistro} id="contenedorRegistroPatronal">
          <div className={styles.contenedorInputsRegistro} id="contenedorInputsRegistro">
            <div>
              <input 
                type="text" 
                placeholder="Registro patronal"
                name="uno"
                id="uno"
                value={uno}
                onChange={((e) =>{
                  setUno(e.target.value)
                  deleteStyle()
                } )}
                className={styles.inputRegistroPatronal}
                onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                maxLength={11}
                />
            </div>
            <div className={styles.lineaInputs}></div>
            <div>
              <input 
                type="text" 
                placeholder="Prima de riesgo"
                name="dos"
                id="dos"
                value={dos}
                onChange={((e) =>{
                  
                  upDateTotal(e.target.value)
                  deleteStyle()
                } )}
                className={styles.inputPrimaRiesgo}
                onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
              />
                
            </div>
            
          </div>
          <div className={styles.contenedorInputsRegistro2} >
          <div className={styles.inputRegistroPatronalRiesgoPuesto}
          > 
            <FormControl 
                fullWidth 
                size="small"
                variant="outlined" 
            >
              <InputLabel id="demo-simple-select-label"
                style={{
                  backgroundColor: "#FFFFFF",
                  fontSize: "13px",
                  position: "absolute",
                  top: "-3px",
                }}
              >Riesgo puesto*</InputLabel>
              <Select
                style={{
                  textOverflow: "ellipsis" ,
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  width: "180px",
                  height: "27px",
                  
                }}
                    
                labelId="demo-simple-select-label"
                id="estado"
                name = "estado"
                value = {props.riskPlaced}
                onChange={((e)=>{
                  positionsSelect()
                  props.setRiskPlaced(e.target.value)
                  deleteStyle()
                })}
                
              >
                  {allRiesgoPuesto?.map((RiesgoPuesto:any,index:number) => {
                      return <MenuItem value={RiesgoPuesto?.code} key={index}>{RiesgoPuesto?.name}</MenuItem>
                  })}
              </Select>
            </FormControl>
          </div>
          <div className={styles.inputRegistroPatronalRiesgoPuesto}> 
            <button 
                className={styles.botonAgregarRegistroPatronal}
                onClick={((e)=>{
                  e.preventDefault()
                  deleteStyle()
                  addNewRegistroPatronal()
                }
                )}
                >
                Agregar
            </button>
          </div>
        </div>
          
      </div>
        
        <div id="conteinerTextAlert"
        style={{
          marginBottom:"10px"
        }}
        ></div>
        <div className={styles.contenedorRegistros}>
          <div>
              {
                
              state.registroPatronal?.map((reg:any, index: number)=>(
                
                muestraRegistroPatronal(reg, index)
                
              ))}
              
          </div>
        </div>
        <div className={styles.contenedorBotones}>
          <button className={styles.botonCancelar} onClick={handleClose}>
            Cancelar
          </button>
          <button 
          className={styles.botonSiguiente}
          type="submit"
          >
            <div>
              Siguiente 
            </div>
            <div className={styles.iconoSiguiente}></div>
          </button>
        </div>
      </Form>
    </div>
  )
}



const validationSchema = () =>{
  return{
    password: Yup.string().required("Obligatorio"),
    passwordTimbre: Yup.string().required("Obligatorio"),
    NoCertificado: Yup.string().required("Obligatorio"),
    usuarioIMSS: Yup.string().required("Obligatorio")
  }
}


export default CertificadosLlaves