import PrestacionContent from '../components/Prestaciones/PrestacionContent';
import PrestacionState from '../context/PrestacionesContext/PrestacionState';

const PrestacionesScreen = () => {
  return (
    <PrestacionState>
      <PrestacionContent></PrestacionContent>   
    </PrestacionState>
  )
}

export default PrestacionesScreen
