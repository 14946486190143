import style from "../../screens/BitsScreen.module.css";

const  EndingBalances = ({ percentage = 0 }) => {
  const strokeWidth = 10;
  const radius = 50 - strokeWidth / 2;
  const pathDescription = `
      M 50,50 m 0,-${radius}
      a ${radius},${radius} 0 1 1 0,${2 * radius}
      a ${radius},${radius} 0 1 1 0,-${2 * radius}
    `;

  const diameter = Math.PI * 2 * radius;
  const progressStyle = {
    stroke: "#2186C6",

    strokeDasharray: `${diameter}px ${diameter}px`,
    strokeDashoffset: `${((100 - percentage) / 100) * diameter}px`,
  };

  return (
    <svg
      id="progressbarcircle"
      className={style.CircularProgressbar}
      viewBox="0 0 100 100"
      width={150}
      height={150}
    >
      <path
        className="CircularProgressbar-trail"
        d={pathDescription}
        strokeWidth={strokeWidth}
        fillOpacity={0}
        style={{
          stroke: "#11CA73",
        }}
      />

      <path
        className="CircularProgressbar-path"
        d={pathDescription}
        strokeWidth={strokeWidth}
        fillOpacity={0}
        style={progressStyle}
      />

      <text
        className="CircularProgressbar-text"
        x={50}
        y={50}
        style={{
          fill: "#2186C6",
          fontSize: "20px",
          dominantBaseline: "central",
          textAnchor: "middle",
          stroke: "1px",
        }}
      >
        {`${percentage}`}%
      </text>
    </svg>
  );
};

export default EndingBalances;
