import { useQuery } from "@apollo/client";
import { useContext, useEffect, useState, useCallback } from "react";
import BarChartData from "../components/Dashboard/BarChart";
import "../components/Dashboard/Dashboard.css";
import PieChart from "../components/Dashboard/PieChart";
import WelcomeUser from "../components/Dashboard/WelcomeUser";
import { AdminContext } from "../context/AdminContext/AdminContext";
import stylesPayrroll from "../../src/components/Payroll/PayrollStyles.module.css";
import {
  GET_ALL_PAYROLL,
  GET_COUNT_COMMENT,
  GET_USER_CONTRACT_CAL,
  GET_USER_TEAM,
  GET_ALL_SCHEDULE_BY_MONTH_YEAR,
  GET_NEXTPAYROLL_DAY,
  GET_USER_COUNT,
  GET_ALL_BIRTHDAY_BY_MONTH
} from "../Querys/querys";
import style from "./BitsScreen.module.css";
import moment from "moment";
import Progress from "../components/Dashboard/ProgressDashborad";
import CircularProgressBar from "../components/Dashboard/CircularProgressBar";
import {
  createincidenciaModal,
} from "../context/PayrollProcess/Actions";
import PayrollProcessContext from "../context/PayrollProcess/PayrollProcessContext";
import { useHistory } from "react-router-dom";
import CrearIncidenciasDashboard from "../components/Schedule/Modals/CrearIncidenciasDashboard";
import MyTeamIcon from '../assets/svg/MyTeam.svg';
import ContractList from "../components/Dashboard/ContractList";
import CollaboratorIconText from "../components/CollaboratorIcon/CollaboratorIconText";
import CollaboratorIconIMG from "../components/CollaboratorIcon/CollaboratorIconIMG";
import FechaDashboard from "../components/Dashboard/FechaDashboard";

const BitsScreen = () => {
  localStorage.setItem("currentModule", "Dashboard");
  
  const { adminState } = useContext(AdminContext);
  const resultDias = useQuery(GET_NEXTPAYROLL_DAY);
  const dataDias = resultDias.data?.GET_NEXTPAYROLL_DAY;
  const [saveIncident, setSaveIncident] = useState(true);
  const resultTeam = useQuery(GET_USER_TEAM, {
    variables: { id: adminState?.id },
    fetchPolicy: 'no-cache'
  });
  const resultallTeam = resultTeam.data?.GET_USER_TEAM;
  const [showC, setShow] = useState(false);
  const [showRed, setShowRed] = useState(false);
  const [showYellow, setShowYellow] = useState(false);
  
  const resultFechas = useQuery(GET_ALL_SCHEDULE_BY_MONTH_YEAR, {
    variables: { month: parseInt(moment().format('MM')), year: parseInt(moment().format('YYYY')) },
    fetchPolicy: 'no-cache'
  });
  const resultallFechas = resultFechas.data?.GET_ALL_SCHEDULE_BY_MONTH_YEAR;

 const dataMes = resultallFechas?.filter( (list: {initDate: any }) => { 
  return moment(list.initDate).format('MM') !==  moment().format('YYYY') && moment(list.initDate).format('MM') ===  moment().format('MM')
  });
  const resultnomina = useQuery(GET_ALL_PAYROLL,{
    fetchPolicy: 'no-cache'
  });
  const datanomina = resultnomina.data?.GET_ALL_PAYROLL;

  const resultCumpleaños= useQuery(GET_ALL_BIRTHDAY_BY_MONTH,{
    fetchPolicy: 'no-cache'
  });
  const dataCumpleaños = resultCumpleaños.data?.GET_ALL_BIRTHDAY_BY_MONTH;

  const resultColaboladores = useQuery(GET_USER_CONTRACT_CAL, {
    fetchPolicy: 'no-cache'
  });
  const dataContratos = resultColaboladores.data?.GET_USER_CONTRACT_CAL;

  const resultadoConteo = useQuery(GET_USER_COUNT, {
    fetchPolicy: 'no-cache'
  });
  const dataConteo = resultadoConteo.data?.GET_USER_COUNT;

  const { dispatch: payrollDispatch } = useContext(
    PayrollProcessContext
  );

  
  const createModal = (id: any) => {
    createincidenciaModal({ _id: '0', createIncidencia: true }, payrollDispatch);
  };

  const [totalColaboladores, setTotalColaboladores] = useState(0);
  const resultMood = useQuery(GET_COUNT_COMMENT);
  const data = resultMood.data?.GET_COUNT_COMMENT;
  const [total, setTotal] = useState(0);
  const [totalDias, setTotalDias] = useState(0);
  const [done, setDone] = useState(0);
  const history = useHistory();

  
  const actualizaTotal = useCallback(() => {
    setTotalColaboladores(dataConteo?.[0]?.UsuarioTotal);
    let arrayTotales: any[] = [];
    arrayTotales = data?.map((lis: any) => lis.value);
    const suma = (acc: any, curr: any) => acc + curr;
    if (arrayTotales !== undefined) {
      if (arrayTotales.length > 0) {
        setTotal(arrayTotales?.reduce(suma));
      }
    }
    
    if (dataDias !== undefined && dataDias[0]) {
      let diff = dataDias[0]?.DiasFaltantes;
      setTotalDias(diff);
      let progreso = (diff / 15) * 100;
      setDone(progreso);
    }
  },[dataConteo, data, dataDias]);
  
  useEffect(() => {
    actualizaTotal();

    return () => {
      actualizaTotal();
    };
  }, [actualizaTotal, dataConteo, data, dataDias, total]);

  const checkContratos = (color:string) => {
    const rows = dataContratos.filter((lis:any)=>{
      return color === 'blue' ? lis.numberday >= 8 : color === 'yellow' ? lis.numberday >= 4 && lis.numberday <= 7 : lis.numberday <= 3
    });
    return rows.length;
  }

  const getBarContent = (data:any, type:string) => {
    const filtered = data.filter((item:any)=>{
      return item.name === type;
    });
    return filtered[0];
  }

  return (
    <>
      {/* Cambiar a Admin */}
      <div className={style.principal}>
      <CrearIncidenciasDashboard saveIncident={saveIncident} setSaveIncident={setSaveIncident}/>
        <div>
          <div className={style.botonesAction}>
            <WelcomeUser />
            <button
              onClick={() => createModal(1)}
              className={style.botonaction}
              type="button"
            >
              <span className={style.iconoAgregar}>+</span>
              <span className={style.textoAgregar}> Agregar incidencias</span>
            </button>
          </div>
        </div>

        <div className={style.wrapper}>
          <FechaDashboard />
          <div className={style.wrappertwo}>
            <div className={style.detalleSup}>
              <div className={style.divSupBorder}>
                <div className={style.center}>
                  <span className={style.textognralmayor}>{totalDias}</span>
                  <span className={style.textognral}>
                    &nbsp; días para la siguiente nómina
                  </span>
                </div>

                <div className={style.center}>
                  <Progress done={done} />
                </div>
              </div>
              <div>
                <div className={style.center}>
                  <span className={style.textognralmayor}>
                    {totalColaboladores}
                  </span>
                  <span className={style.textognral}>
                    &nbsp; Colaboradores activos
                  </span>
                </div>
                <div className={style.center}>
                  <img
                    className={style.usr}
                    src="/assets/svg/grupo_usr.svg"
                    alt="Grupo"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className={style.divContratos}>
            {dataContratos?.length === 0 ? (
              <div className={style.center}>
                <div>
                  <span className={style.iconoContrato}>
                    <img src="/assets/svg/check_contr.svg" alt="contract"/>
                  </span>
                  <br></br>
                  <br></br>
                  <span className={style.textognral}>
                    No hay ningún contrato cerca de vencer
                  </span>
                </div>
              </div>
            ) : null}
            {dataContratos?.length === 1 ? (
              <>
                <div>
                  <div className={style.espaciotexto}>
                    <span className={style.textognral}>El contrato de</span>
                  </div>
                  <div className={style.espaciotexto}>
                    <span
                      className={`${style.textognral} ${style.espaciotexto}`}
                    >
                      <b>{dataContratos?.[0].bussinesName}</b>
                    </span>
                  </div>
                  <div>
                    <span className={style.textognralmayor}>Vence pronto</span>
                  </div>
                  <CircularProgressBar
                    strokeWidth={12}
                    percentage={dataContratos?.[0].percentage}
                    numberday={dataContratos?.[0].numberday}
                  />
                  <button
                    onClick={() => {
                      localStorage.setItem("currentPill", "1");
                      localStorage.setItem("currentModule", "Colaboradores");
                      localStorage.setItem("nextStep", "2");
                      history.push(`/collaborators/${dataContratos?.[0].id}/1`);
                    }}
                    className={style.boton}
                    type="button"
                  >
                    <span className={style.textoAgregar}>
                      Ir a renovar &nbsp;{" "}
                    </span>
                    <span className={style.iconoAgregar}>
                      <img src="/assets/svg/direc.svg" alt="add"/>
                    </span>
                  </button>
                </div>
              </>
            ) : null}

            {dataContratos?.length > 1 ? (
              <>
                <div>
                  <br></br> <br></br>
                  <div className={style.espaciotexto}>
                    <span className={style.textognral}>El contrato de</span>
                  </div>
                  <div className={style.espaciotexto}>
                    <span
                      className={`${style.textognral} ${style.espaciotexto}`}
                    >
                      <b>{dataContratos?.length}</b>
                    </span>
                    <span className={style.textognral}>colaboladores</span>
                  </div>
                  <div className={style.espaciotexto}>
                    <span className={style.textognralmayor}>{ dataContratos.length === 1 ? 'Vence pronto' : 'Vencen pronto'}</span>
                  </div>
                  <div className={style.semaforo}>
                    <div>
                      <button
                      title="Mayor a 8 días"
                      onClick={() => {
                        setShow(!showC);
                        setShowYellow(false);
                        setShowRed(false);
                      }}
                      className={style.semaforo_1}
                    >
                    </button>
                    </div>
                    <div> 
                    <button
                    title="Entre 3 y 8 días"
                      onClick={() => {
                        setShowYellow(!showYellow);
                        setShow(false);
                        setShowRed(false);
                      }}
                      className={style.semaforo_2}
                    >
                    </button>
                    </div>
                    <div>
                    <button
                    title="Menor a 3 días"
                      onClick={() => {
                        setShowRed(!showRed);
                        setShowYellow(false);
                        setShow(false);
                      }}
                      className={style.semaforo_3}
                    >
                    </button>
                    </div>
                  </div>
                  <button
                        onClick={() => {
                          localStorage.setItem("currentPill", "1");
                          localStorage.setItem(
                            "currentModule",
                            "Colaboradores"
                          );
                          localStorage.setItem("nextStep", "2");
                          history.push(`/collaborators/id/0`);
                        }}
                        className={style.boton}
                        type="button"
                      >
                        <span className={style.textoAgregar}>
                          Ir a renovar &nbsp;
                        </span>
                        <span className={style.iconoAgregar}>
                          <img src="/assets/svg/direc.svg" alt="add"/>
                        </span>
                      </button>
                  {showC ? (
                    <ContractList checkContratos={checkContratos} history={history} color='blue' dataContratos={dataContratos} setShow={setShow} setShowYellow={setShowYellow} setShowRed={setShowRed}></ContractList>
                  ) : (
                    ""
                  )}

                  {showYellow ? (
                    <ContractList checkContratos={checkContratos} history={history} color='yellow' dataContratos={dataContratos} setShow={setShow} setShowYellow={setShowYellow} setShowRed={setShowRed}></ContractList>
                  ) : (
                    ""
                  )}

                  {showRed ? (
                    <ContractList checkContratos={checkContratos} history={history} color='red' dataContratos={dataContratos} setShow={setShow} setShowYellow={setShowYellow} setShowRed={setShowRed}></ContractList>
                  ) : (
                    ""
                  )}
                </div>
              </>
            ) : null}
          </div>

          <div className={style.div}>
            <span className={style.textognralmayor}>My mood</span>
            <div className={style.divGrafica}>
              <div>
                <PieChart />
              </div>
              <div>
                <br></br>
                <div className={style.moods}>
                  <div className={style.mood1}></div>
                  <div className={style.texxt}>
                    <span className={style.textognralmin}>Motivados</span>
                  </div>
                  <div>
                    <span className={style.textognralmingray}>
                      { ((data === undefined || data?.length === 0) || (!getBarContent(data, 'Motivado')))
                        ? "0%"
                        : getBarContent(data, 'Motivado').percentage + "%"}
                    </span>
                  </div>
                </div>

                <div className={style.moods}>
                  <div className={style.mood2}></div>
                  <div className={style.texxt}>
                    <span className={style.textognralmin}>Productivos</span>
                  </div>
                  <div>
                    <span className={style.textognralmingray}>
                      { ((data === undefined || data?.length === 0) || (!getBarContent(data, 'Productivo')))
                        ? "0%"
                        : getBarContent(data, 'Productivo').percentage + "%"}
                    </span>
                  </div>
                </div>

                <div className={style.moods}>
                  <div className={style.mood3}></div>
                  <div className={style.texxt}>
                    <span className={style.textognralmin}>Aburridos</span>
                  </div>
                  <div>
                    <span className={style.textognralmingray}>
                      { ((data === undefined || data?.length === 0) || ( !getBarContent(data, 'Aburrido') ))
                        ? "0%"
                        : getBarContent(data, 'Aburrido')?.percentage + "%"}
                    </span>
                  </div>
                </div>

                <div className={style.moods}>
                  <div className={style.mood4}></div>
                  <div className={style.texxt}>
                    <span className={style.textognralmin}>Presionados</span>{" "}
                  </div>
                  <div>
                    <span className={style.textognralmingray}>
                      { ((data === undefined || data?.length === 0) || !getBarContent(data, 'Presionado'))
                        ? "0%"
                        : getBarContent(data, 'Presionado')?.percentage + "%"}
                    </span>{" "}
                  </div>
                </div>

                <div className={style.moods}>
                  <div className={style.mood5}></div>
                  <div className={style.texxt}>
                    <span className={style.textognralmin}>Enfadados</span>{" "}
                  </div>
                  <div>
                    <span className={style.textognralmingray}>
                      { ((data === undefined || data?.length === 0) || !getBarContent(data, 'Enfadado'))
                        ? "0%"
                        : getBarContent(data, 'Enfadado')?.percentage + "%"}
                    </span>{" "}
                  </div>
                </div>
              </div>
              <div className={style.moodsGrafica}>
                <span className={style.textognralmingray}>Últimos 15 días</span>
                <BarChartData mood_type="Motivado" color="#367EEA" />
                <BarChartData mood_type="Productivo" color="#42DBBE" />
                <BarChartData mood_type="Aburrido" color="#9C54E5" />
                <BarChartData mood_type="Presionado" color="#FEC104" />
                <BarChartData mood_type="Enfadado" color="#FB337B" />
              </div>
            </div>
          </div>

          <div
            className={
              dataCumpleaños?.length > 0 ? style.divFelicitar : style.divContratos
            }
          >
            <div className={style.center}>
              {dataCumpleaños?.length > 0 ? (
                <div>
                  <span className={style.textognralmayor}>Festejos de Hoy</span>
                  {dataCumpleaños?.map((lis: any) => (
                    <div key={lis.id} className={style.detalle}>
                      <div className={style.detallegrupor}>
                        {lis?.ImagenUsuario === undefined || lis?.ImagenUsuario === '' || lis?.ImagenUsuario === null ? (
                          <CollaboratorIconText
                          Nombre={lis?.name}
                          Apellido={lis?.firstName}
                          width="30px"
                          height="30px"
                          />
                        ) : (
                              
                            <CollaboratorIconIMG
                            ImagenUsuario={lis?.ImagenUsuario}
                            width="30px"
                            height="30px"
                            widthImg="28px"
                            heightImg="28px"

                            />
                        )}
                      </div>
                      <div className={style.detallegrupo}>
                        <div>
                          <span className={style.dia}>
                            {moment(lis.initDate).locale("es").format("dddd") +
                              " " +
                              moment(lis.initDate).locale("es").format("DD")}
                          </span>
                        </div>
                        <div>
                          <span className={style.textognralmin}>{lis.name}</span>
                        </div>
                        <div>
                          <span
                            className={
                              lis.name === "Cumpleaños"
                                ? style.aniversario
                                : style.cumple
                            }
                          >
                            {lis.Tipo}
                          </span>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div>
                  <span className={style.iconoContrato}>
                    <img src="/assets/svg/celebration.svg" alt="Contrato"/>
                  </span>
                  <br></br>
                  <br></br>
                  <span className={style.textognral}>
                    No hay ningún evento el día de hoy
                  </span>
                </div>
              )}
            </div>
          </div>

          <div className={style.div}>
            <div>
              <span className={style.textognralmayor}>My team</span>
            </div>
            <div className={`${style.center} ${style.myTeamIconContainer}`}>
              <div className={style.divscrollcon}>
              {resultallTeam?.length === 0 ? (
                
                <div className={style.centerIconoMyTeam}>
                  <div>
                    <span className={style.iconoContrato}>
                      <img src={MyTeamIcon} alt="Contrato"/>
                    </span>
                    <br></br>
                    <br></br>
                    <span className={style.textognral}>
                      No tienes gente a cargo
                    </span>
                  </div>
                </div>
              ) : null}

                {resultallTeam?.map((lis: any) => (
                  <div key={lis.id} className={style.detalle}>
                    <div className={style.detallegrupor}>
                      <CollaboratorIconText
                      Nombre={lis.bussinesName && lis.bussinesName.split(' ')[0] ? lis.bussinesName.split(' ')[0] : ''}
                      Apellido={lis.bussinesName && lis.bussinesName.split(' ')[1] ? lis.bussinesName.split(' ')[1] : ''}
                      width="40px"
                      height="40px">
                      </CollaboratorIconText>
                    </div>
                    <div className={style.myteam}>
                      <span className={style.textognralmin}>
                        {lis.bussinesName}
                      </span>
                      <br></br>
                      <span className={style.textognralmingray}>{lis.email}</span>
                      <br></br>
                      <span className={style.textognralmingray}>
                        {lis.cellphone}
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div
            className={datanomina?.length > 0 ? style.div : style.divContratos}
          >
            {datanomina?.length > 0 ? (
              <div>
                <span className={style.textognralmayor}>
                  Estatus de pre-nómina
                </span>

                <br></br>
                <br></br>
                <div className={style.divscrollcon}>
                  {datanomina?.map((lis: any) => (
                    <div key={lis.id} className={style.estatuspre}>
                      <span className={style.textognral}>{lis.group_name}</span>

                      {lis.statusProgress === "Calcular" ? (
                        <div>
                          <span className={style.textognralmingray}>
                            {lis.statusProgress}
                          </span>
                          <br></br>
                          <div className={style.pc_contenedorIconoSeguimiento}>
                            <div className={stylesPayrroll.pc_circuloAmarillo}>
                              <div
                                className={stylesPayrroll.pc_circuloBlanco}
                              ></div>
                            </div>
                            <div
                              className={stylesPayrroll.pc_lineaSeguimiento}
                            ></div>
                            <div
                              className={stylesPayrroll.pc_circuloGris}
                            ></div>
                            <div
                              className={stylesPayrroll.pc_lineaSeguimiento}
                            ></div>
                            <div
                              className={stylesPayrroll.pc_circuloGris}
                            ></div>
                          </div>
                        </div>
                      ) : null}
                      {lis.statusProgress === "Dispersar" ? (
                        <div>
                          <span className={style.textognralmingray}>
                            {lis.statusProgress}
                          </span>
                          <br></br>
                          <div className={style.pc_contenedorIconoSeguimiento}>
                            <div className={stylesPayrroll.pc_circuloAmarillo}>
                              <div
                                className={stylesPayrroll.pd_iconoCompleto}
                              ></div>
                            </div>
                            <div
                              className={
                                stylesPayrroll.pc_lineaSeguimientoCompleto
                              }
                            ></div>
                            <div className={stylesPayrroll.pc_circuloAmarillo}>
                              <div
                                className={stylesPayrroll.pc_circuloBlanco}
                              ></div>
                            </div>
                            <div
                              className={stylesPayrroll.pc_lineaSeguimiento}
                            ></div>
                            <div
                              className={stylesPayrroll.pc_circuloGris}
                            ></div>
                          </div>
                        </div>
                      ) : null}
                      {lis.statusProgress === "Timbrar" ? (
                        <div>
                          <span className={style.textognralmingray}>
                            {lis.statusProgress}
                          </span>
                          <br></br>
                          <div className={style.pc_contenedorIconoSeguimiento}>
                            <div className={stylesPayrroll.pc_circuloAmarillo}>
                              <div
                                className={stylesPayrroll.pd_iconoCompleto}
                              ></div>
                            </div>
                            <div
                              className={
                                stylesPayrroll.pc_lineaSeguimientoCompleto
                              }
                            ></div>
                            <div className={stylesPayrroll.pc_circuloAmarillo}>
                              <div
                                className={stylesPayrroll.pd_iconoCompleto}
                              ></div>
                            </div>
                            <div
                              className={
                                stylesPayrroll.pc_lineaSeguimientoCompleto
                              }
                            ></div>
                            <div className={stylesPayrroll.pc_circuloAmarillo}>
                              <div
                                className={stylesPayrroll.pc_circuloBlanco}
                              ></div>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      {lis.statusProgress === "Terminar" ? (
                        <div className={style.img}>
                          <img src="/assets/svg/finally.svg" alt="Terminar"/>
                        </div>
                      ) : null}
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              <div className={style.center}>
                <div>
                  <span className={style.iconoContrato}>
                    <img src="/assets/svg/countnomina.svg" alt="Contrato"/>
                  </span>
                  <br></br>
                  <br></br>
                  <span className={style.textognral}>
                    No hay ningún proceso de dispersión pendiente
                  </span>
                </div>
              </div>
            )}
          </div>
          <div className={style.div}>
            <div>
              <span className={style.textognralmayor}>
                Eventos de {moment().format("MMMM")}
              </span>
            </div>
            <div className={style.divscrollcon}>
              {dataMes?.map((lis: any) => (
                <div key={lis.id} className={style.detalle}>
                  <div className={style.detallegrupor}>
                    {lis?.ImagenUsuario === undefined || lis?.ImagenUsuario === '' || lis?.ImagenUsuario === null ? (
                      <CollaboratorIconText
                      Nombre={lis?.name}
                      Apellido={lis?.firstName}
                      width="30px"
                      height="30px"
                      />
                      ) : (   
                        <CollaboratorIconIMG
                        ImagenUsuario={lis?.ImagenUsuario}
                        width="30px"
                        height="30px"
                        widthImg="28px"
                        heightImg="28px"
                        />
                    )}
                  </div>
                  <div className={style.detallegrupo}>
                    <div>
                      <span className={style.dia}>
                        {moment(lis.initDate).locale("es").format("dddd") +
                          " " +
                          moment(lis.initDate).locale("es").format("DD")}
                      </span>
                    </div>
                    <div>
                      <span className={style.textognralmin}>{lis.name}</span>
                    </div>
                    <div>
                      <span
                        className={
                          lis.name === "Cumpleaños"
                            ? style.aniversario
                            : style.cumple
                        }
                      >
                        {lis.Tipo}
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BitsScreen;