import { TBColumn } from "../../../interfaces/TableBits.interfaces";

const campaignUsersTableColumns: TBColumn[] = [
  {
    id: 'name',
    label: 'Nombre',
    align: 'left',
  },
  {
    id: 'objectivesCount',
    label: 'Objetivos',
    align: 'left',
  },
  {
    id: 'enterprise',
    label: 'Empresa',
    align: 'left',
  },
  {
    id: 'sede',
    label: 'Sede',
    align: 'left',
  },
  {
    id: 'area',
    label: 'Área',
    align: 'left',
  }
]

export default campaignUsersTableColumns;