import { ChangeEvent, useContext, useEffect, useState } from "react";
import {
     Dialog,
    DialogActions, DialogContent,
    FormControl, TextField, Box, Grid
} from "@material-ui/core";
import { IProject } from '../../../interfaces/Project';
import moment from "moment";
import ModalContext from "../../../context/ModalContext/ModalContext";
import { closeModal } from "../../Team/Modals/Modal";
import { ErrorAlert } from "../../../alerts/errorAlert";
import SendIcon from '@material-ui/icons/Send';
import { GET_ALL_PROJECT, CREATE_NOTIFICATION_MOBILE, GET_NOTIFICATIONS_MOBILE, GET_NOTIFICATION_MOBILE, UPDATE_NOTIFICATION_MOBILE } from "../../../Querys/querys";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import {AdminContext} from '../../../context/AdminContext/AdminContext';
import { SuccessfulAlert } from "../../../alerts/successAlerts";

interface IValues {
    Titulo: string;
    desc: string;
    proyectos: string | undefined;
    fecha: string;
    scheduled: boolean;
    scheduledDate: string;
}

let initialState = {
    Titulo: '',
    desc: '',
    proyectos: '',
    fecha: moment().format(),
    scheduled: false,
    scheduledDate: ''
}

const ModalNotification = () => {
    const { state, dispatch } = useContext(ModalContext);
    const [values, setValues] = useState<IValues>(initialState);
    const [projects, setProjects] = useState<Array<IProject>>([]);
    const [projectName, setProjectName] = useState<string[]>([]);

    const [getProjects, {data:resultProject}] = useLazyQuery(GET_ALL_PROJECT,{
        fetchPolicy: 'no-cache'
    });
    const [createNotification, {data:resultCreate}] = useMutation(CREATE_NOTIFICATION_MOBILE,{
        refetchQueries: [
          { query: GET_NOTIFICATIONS_MOBILE }
        ],
    });
    const [updateNotification, {data:resultUpdate}] = useMutation(UPDATE_NOTIFICATION_MOBILE,{
        refetchQueries: [
          { query: GET_NOTIFICATIONS_MOBILE }
        ],
    });
    const {adminState} = useContext(AdminContext);
    const [getNotificationData, { data:resultNotification }] = useLazyQuery(GET_NOTIFICATION_MOBILE,{
        fetchPolicy: 'no-cache'
    });

    useEffect(()=>{
        if(state.open){
            getProjects();
        }
    }, [state.open]);

    useEffect(() => {
        if(resultProject && resultProject.GET_ALL_PROJECT){
            setProjects(resultProject.GET_ALL_PROJECT);
        }
    }, [resultProject]);

    useEffect(() => {
        if (state._id !== '') { /* Editing some notification */
            getNotificationData({variables:{id:state._id}});
        }
    }, [state._id]);

    useEffect(()=>{
        if( (resultCreate && resultCreate.CREATE_NOTIFICATION_MOBILE) ||  (resultUpdate && resultUpdate.UPDATE_NOTIFICATION_MOBILE)){
            setValues(initialState);
            setProjectName([]);
        }
    },[resultCreate, resultUpdate]);

    useEffect(()=>{
        if(resultNotification && resultNotification.GET_NOTIFICATION_MOBILE){
            const notification = resultNotification.GET_NOTIFICATION_MOBILE;
            setProjectName([...notification.projects.split(',')]);
            setValues({
                Titulo: notification.Titulo,
                desc: notification.Descripcion,
                proyectos: notification.projects,
                fecha: notification.Fecha,
                scheduled: notification.scheduleDate ? true : false,
                scheduledDate: notification.scheduleDate ? moment(notification.scheduleDate).format('YYYY-MM-DDTHH:mm') : ''
            });
        }
    },[resultNotification])

    const handleProjectsChange = (e: ChangeEvent<{ value: any }>) => {
        let tempArray = [...projectName];
        if (e.target.value === "Todos") {
            let existTodos = projectName.indexOf(e.target.value);
            if (existTodos > -1) tempArray = [];
            else {
                tempArray = projects?.map(({ proyectName }) => proyectName);
                tempArray.push("Todos")
            };
        } else {
            let exist = projectName.indexOf("Todos");
            if (exist > -1) tempArray.splice(exist, 1);
            exist = projectName.indexOf(e.target.value);
            if (exist > -1) tempArray.splice(exist, 1);
            else tempArray.push(e.target.value);
        }
        setProjectName(tempArray);
        setValues({ ...values, proyectos: tempArray.join(',') });
    };

    const newNotification = async () => {
        setValues({ ...values, fecha: moment().format() });
        
        if (values.Titulo && values.Titulo.trim().length > 1 && values.proyectos && values.desc && values.desc.trim().length > 1) {
            if(values.Titulo.replace(/([0-9])/g, '') !== ''){
                if(values.desc.replace(/([0-9])/g, '') !== ''){
                    if (values.scheduledDate !== '' && values.scheduled && !values.scheduledDate){
                        return ErrorAlert({ text: "Ingrese la fecha para programar la notificación." });
                    }else if (values.scheduledDate !== '' && values.scheduled && moment(values.scheduledDate).isSameOrBefore(moment())) {
                        return ErrorAlert({ text: "La fecha programada debe de ser mayor a la de este momento." });
                    }else{
                        try{
                            if(resultNotification && resultNotification.GET_NOTIFICATION_MOBILE){
                                await updateNotification({
                                    variables:{
                                        id: resultNotification.GET_NOTIFICATION_MOBILE.id,
                                        input: {
                                            User: adminState?.Usuario,
                                            Titulo: values.Titulo,
                                            Descripcion: values.desc,
                                            projects: values.proyectos,
                                            Fecha: values.fecha ? values.fecha : moment().format(),
                                            scheduleDate: values.scheduledDate,
                                            idUser: adminState?.id
                                        }
                                    }
                                });
                                await SuccessfulAlert({ text: "Notificación actualizada correctamente." });
                            }else{
                                await createNotification({
                                    variables:{
                                        input: {
                                            User: adminState?.Usuario,
                                            Titulo: values.Titulo,
                                            Descripcion: values.desc,
                                            projects: values.proyectos,
                                            Fecha: moment().format(),
                                            scheduleDate: values.scheduledDate,
                                            idUser: adminState?.id
                                        }
                                    }
                                });
                                await SuccessfulAlert({ text: "Notificación creada correctamente." });
                            }
                            setValues( initialState );
                            setProjectName([]);
                            await closeModal(dispatch);
                        }catch(e){
                            ErrorAlert({text: "Ocurrió un error al crear la notificación."});
                        }
                    }
                }else{
                    return ErrorAlert({ text: "Descripción no debe llevar solo numeros." })
                }
            }else{
                return ErrorAlert({ text: "Título no debe llevar solo numeros." })
            }
        } else ErrorAlert({ text: "Ingrese todos los datos." });
        
    }

    const changeValues = (e: ChangeEvent<{ name: string, value: string }>): void => {
        setValues({
            ...values,
            [e.target.name]: e.target.value
        });
    }

    const handleScheduleDate = () => {
        setValues({
            ...values,
            scheduled: true,
        });
    }

    const clearModal = () => {
        setValues(initialState); 
        setProjectName([]); 
        closeModal(dispatch);
    }

    return (
        <Dialog
            open={state.open}
            onClose={() => { clearModal() }}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            fullWidth={true}
            maxWidth="md"
        > 

            <Grid direction="row" justify="flex-start" alignItems="center" container>
                <Grid xs={11} item style={{ marginLeft: '50px', marginTop: '4px' }} >
                    <h1 id="form-dialog-title" className="text-center">{ !state._id ? 'Nueva' : 'Editar' } notificación</h1>
                </Grid>
            </Grid>
            <DialogContent>

            <span className="text-closemodalx" onClick={() => { clearModal() }}><img src="assets/svg/CloseM.svg" alt=""/></span>
                
                <Grid direction="row" container>
                    <Grid xs item>
                        <FormControl fullWidth={true}>
                            <TextField 
                                label="Título" 
                                name="Titulo" 
                                type="text"
                                value={values.Titulo} 
                                onChange={changeValues} 
                                inputProps={{ maxLength: 30 }}
                                variant="outlined"
                                size="small"
                            />
                        </FormControl>
                        <Box mt={3}>
                            <FormControl fullWidth={true}>
                                <span className="sheduledDate__Question">Descripción</span>
                                <TextField
                                    id="standard-multiline-static"
                                    multiline
                                    rows={5}
                                    name="desc"
                                    placeholder='Ingresa tu noticia'
                                    onChange={changeValues}
                                    value={values.desc}
                                    inputProps={{ maxLength: 200 }}
                                    variant="outlined"
                                />
                            </FormControl>
                        </Box>
                        <Box mt={3}>
                            <Grid direction="row" container>
                                <Grid xs={11} item>
                                    <FormControl fullWidth={true}>
                                        <span className="sheduledDate__Question">¿Deseas programar la notificación?</span>
                                        <TextField
                                            id="datetime-local"
                                            name=""
                                            placeholder="Seleccione fecha y hora"
                                            type={values.scheduled ? 'datetime-local' : values.scheduledDate !== '' ? 'datetime-local' : 'text'}
                                            value={values.scheduled ? values.scheduledDate !== '' ? values.scheduledDate : '' : ''}
                                            onFocus={handleScheduleDate}
                                            onChange={(e) => setValues({ ...values, scheduledDate: e.target.value })}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            variant="outlined"
                                            size="small"
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <span className="warning__date">Si no eliges fecha, la notificación se enviará al instante*</span>
                        </Box>
                    </Grid>
                    <Grid xs item>
                        <Box>
                            <FormControl fullWidth={true}>
                                <span className="seletion__projects">Seleccione las areas y proyectos a notificar</span>
                                <div className="grid__projects" style={{ alignItems: 'center'}}>   
                                  <span className="span-notification">
                                      <input type="checkbox" value="Todos" checked={projectName.indexOf("Todos") === -1 ? false : true} onChange={(e) => handleProjectsChange(e)}/>
                                      Todos
                                  </span>
                             
                              {
                                  projects?.map(({ proyectName }: IProject,i) => (
                                          
                                          <span key={i} className="span-notification">
                                              <input type="checkbox"  checked={projectName.indexOf(proyectName) === -1 ? false : true} value={proyectName} onChange={(e) => handleProjectsChange(e)} />
                                              {proyectName}
                                          </span>
                                  ))
                              }
                                </div>
                            </FormControl>
                        </Box>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <button className="btnCancel" onClick={() => closeModal(dispatch)}>
                    Cerrar
                </button>
                <button className="btnSave" onClick={newNotification}>
                    Enviar <SendIcon fontSize="small" style={{marginLeft:"5px"}}/>
                </button>
            </DialogActions>
        </Dialog>
    )
}
export default ModalNotification;