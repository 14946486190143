import { useEffect, useState } from "react";
import { Grid, TextField } from "@material-ui/core";
import Box from '@mui/material/Box';
import { useParams } from "react-router-dom";
import { SuccessfulAlert } from "../../../../alerts/successAlerts";
import { useMutation, useQuery } from "@apollo/client";
import {
  GET_USERS_BY_ID,
  GET_USER_NUEVOINGRESO_REGISTER,
  UPDATE_USERS,
} from "../../../../Querys/querys";
import * as Yup from "yup";
import { Formik, Form } from "formik";

const PersonalDataColaboradores = () => {
  const params = useParams<any>();
  const [collaborator, setCollaborator] = useState<any>([]);

  const { loading, data: resultCollaborator } = useQuery(
    GET_USER_NUEVOINGRESO_REGISTER,
    {
      variables: { token: params.token },
    }
  );
  const MyCollaborator = resultCollaborator?.GET_USER_NUEVOINGRESO_REGISTER[0];

  const [updateColaboradores] = useMutation(UPDATE_USERS, {
    refetchQueries: [
      {
        query: GET_USERS_BY_ID,
        variables: { getUsersId: collaborator?.id },
      },
    ],
  });

  useEffect(() => {
    if (MyCollaborator) {
      setCollaborator(MyCollaborator);
    }
  }, [MyCollaborator]);

  if (loading) return null;

  const initialValues = () => {
    return {
      name: MyCollaborator?.name,
      firstName: MyCollaborator?.firstName,
      lastName: MyCollaborator?.lastName,
      email: MyCollaborator?.email,
      cellphone: MyCollaborator?.cellphone,
    };
  };

  return (
    <>
      <Formik
        initialValues={initialValues()}
        validationSchema={Yup.object(validationSchema())}
        onSubmit={(formData) => {
          updateColaboradores({
            variables: {
              updateUsersId: collaborator?.id,
              input: formData,
              process: {
                tab: "information",
                section: "section_1",
              },
            },
          }).then((res) => {
            SuccessfulAlert({ text: "Se actualizó correctamente" });
          });
        }}
      >
        {({ values, errors, touched, handleChange, handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <div className="novalidate__border">
              <Box mb={2}>
                <TextField
                  type="text"
                  name="name"
                  value={values.name}
                  label="Nombre(s)"
                  variant="outlined"
                  size="small"
                  fullWidth={true}
                  onChange={handleChange}
                  error={Boolean(errors.name)}
                  onBlur={(e) => handleChange(e)}
                  InputProps={{
                    readOnly: false,
                  }}
                  helperText={!values?.name && "Obligatorio"}
                  disabled={true}
                />
              </Box>
              <Grid direction="row" container spacing={2}>
                <Grid xs item>
                  <TextField
                    name="firstName"
                    value={values?.firstName}
                    label="Primer apellido"
                    variant="outlined"
                    size="small"
                    fullWidth={true}
                    onChange={handleChange}
                    error={Boolean(errors.firstName)}
                    onBlur={(e) => handleChange(e)}
                    InputProps={{
                      readOnly: false,
                    }}
                    helperText={!values?.firstName && "Obligatorio"}
                    disabled={true}
                  />
                </Grid>
                <Grid xs item>
                  <TextField
                    name="lastName"
                    value={values?.lastName}
                    label="Segundo apellido"
                    variant="outlined"
                    size="small"
                    fullWidth={true}
                    onChange={handleChange}
                    onBlur={(e) => handleChange(e)}
                    InputProps={{
                      readOnly: true,
                    }}
                    disabled={true}
                  />
                </Grid>
              </Grid>
              <Box mt={2}>
                <TextField
                  type="email"
                  name="email"
                  value={values?.email}
                  label="Email"
                  variant="outlined"
                  size="small"
                  fullWidth={true}
                  error={Boolean(errors.email)}
                  onChange={handleChange}
                  onBlur={(e) => handleChange(e)}
                  InputProps={{
                    readOnly: true,
                  }}
                  helperText={!values?.email && "Obligatorio"}
                  disabled={true}
                />
              </Box>
              <Box mt={2}>
                <TextField
                  id="cellphone"
                  type="string"
                  name="cellphone"
                  value={values?.cellphone || ""}
                  label="Teléfono celular profesional"
                  variant="outlined"
                  size="small"
                  fullWidth={true}
                  error={Boolean(errors.cellphone)}
                  helperText={!values?.cellphone && "Obligatorio"}
                  disabled={true}
                />
              </Box>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

const validationSchema = () => {
  return {
    name: Yup.string().required("El name es requerido"),
    firstName: Yup.string().required("El apellido es un campo requerido"),
    email: Yup.string().email().required("El email es un campo obligatorio"),
    cellphone: Yup.number()
      .required("El celular es un campo obligatorio")
      .min(13, "El celular debe tener al menos 10 dígitos"),
  };
};

export default PersonalDataColaboradores;
