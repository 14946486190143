import { NuevaEventual } from "./NuevaEventual";
import EventualTable from "./eventualTable";

import "../Payroll/Payroll.css";

import { useQuery } from "@apollo/client";
import { GET_ALL_EVENTUALPAYROLL } from "../../Querys/querys";
import { useEffect, useState } from "react";

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
  sistemaActivo: boolean;
}

const EventualTab = (props: TabPanelProps) => {
  const [isPayRoll, setIsPayRoll] = useState(false);
  const { data: resultPayroll } = useQuery(GET_ALL_EVENTUALPAYROLL);
  const allPayroll = resultPayroll?.GET_ALL_EVENTUALPAYROLL;

  useEffect(() => {
    if (allPayroll || allPayroll === 0) {
      setIsPayRoll(true);
    }
  }, [allPayroll]);

  return (
    <>
      {
        (!isPayRoll) ? (
          <NuevaEventual {...props} />
        ) : (
          <EventualTable {...props} />
        )
      }
    </>
  )
};

export default EventualTab;
