import { useContext, useEffect, useState } from "react";
import {
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  Select,
  TextField,
} from "@material-ui/core";
import Box from '@mui/material/Box';
import { Form } from "semantic-ui-react";
import CollaboratorContext from "../../../../context/CollaboratorContext/CollaboratorContext";
import { updateData } from "../../../../helpers/Collaborator/Collaborator";
import { IProject } from "../../../../interfaces/Project";
import { useFormik } from "formik";
import * as Yup from "yup";

import {
  GET_ALL_ENTERPRISE,
  GET_ALL_SEDE,
  GET_ALL_PROJECT,
  GET_ALL_PUESTOS,
} from "../../../../Querys/querys";
import { useQuery } from "@apollo/client";
import moment from "moment";


const InputDataRegister = () => {
  const { state, dispatch } = useContext(CollaboratorContext);
  const [projects, setProjects] = useState<Array<IProject>>([]);
  const disabled = false;
  const [puestos, setPuestos] = useState<Array<any>>([]);
  const maxDate = moment().format("YYYY-MM-DD");

  const { data: resultProject } = useQuery(GET_ALL_PROJECT);
  const allProject = resultProject?.GET_ALL_PROJECT;

  const { data: resultPuestos } = useQuery(GET_ALL_PUESTOS);
  const allPuestos = resultPuestos?.GET_ALL_PUESTOS;

  const { data: dataSedes } = useQuery(GET_ALL_SEDE, {
    fetchPolicy: 'no-cache'
  });
  const allCampus = dataSedes?.GET_ALL_SEDE;

  useEffect(() => {
    if (allProject) {
      setProjects(allProject);
    }
    if (allPuestos) setPuestos(allPuestos);
  }, [allProject, allPuestos]);

  const handleChange = async (e: any) => {
    await updateData(e, state, dispatch, 0);
  };

  const formik = useFormik({
    initialValues: initialValues(),
    validationSchema: Yup.object(validationSchema()),
    onSubmit: (formData) => {
    },
  });

  useEffect(() => {
    if (Object.keys(formik.errors).length > 0) {
    }
  }, [formik.errors]);

  return (
    <Form onSubmit={formik.handleSubmit}>
      <div className="novalidate__border">
        <Grid
          direction="row"
          container
          justify="flex-start"
          alignItems="center"
        >
          <Grid xs item>
            <span className="Fecha-de-nacimiento">Fecha de ingreso</span>
          </Grid>
          <Grid xs item container justify="flex-end" alignItems="center">
            <TextField
              type="date"
              autoFocus={true}
              name="dateOfAdmission"
              variant="outlined"
              size="small"
              onChange={formik.handleChange}
              error={
                formik.touched.dateOfAdmission &&
                Boolean(formik.errors.dateOfAdmission)
              }
              helperText={
                formik.touched.dateOfAdmission &&
                !state.collaborator?.dateOfAdmission &&
                "Obligatorio"
              }
              onBlur={(e) => handleChange(e)}
              InputProps={{
                readOnly: false,
                inputProps: {
                  max: { maxDate },
                },
              }}
              disabled={disabled}
            />
          </Grid>
        </Grid>

        <Box mb={2} mt={2}>
          <FormControl variant="outlined" fullWidth={true} size="small">
            <TextField
              type="text"
              label="Lugar de trabajo"
              name="officePlace"
              variant="outlined"
              size="small"
              fullWidth={true}
              InputProps={{
                readOnly: false,
              }}
            />
          </FormControl>
        </Box>

        <Grid direction="row" container spacing={2}>
          <Grid xs item>
            <FormControl variant="outlined" fullWidth={true} size="small">
              <InputLabel htmlFor="outlined-age-native-simple">
                Selecciona una sede
              </InputLabel>
              <Select
                native
                onBlur={(e) => handleChange(e)}
                onChange={formik.handleChange}
                error={formik.touched.campus && Boolean(formik.errors.campus)}
                label={"Selecciona una sede"}
                name="campus"
                defaultValue={""}
                disabled={disabled}
              >
                <option value={""} disabled={true}>
                  {""}
                </option>
                {allCampus?.map(({ id, sedeName }: any) => (
                  <option key={id} value={id}>
                    {sedeName}
                  </option>
                ))}
              </Select>
              <FormHelperText error={Boolean(formik.errors.campus)}>
                {formik.touched.campus &&
                  !state.collaborator?.campus &&
                  "Obligatorio"}
              </FormHelperText>
            </FormControl>
          </Grid>
        </Grid>

        <Grid direction="row" container spacing={2}>
          <Grid xs item>
            <FormControl variant="outlined" fullWidth={true} size="small">
              <InputLabel htmlFor="outlined-age-native-simple">
                Selecciona área o proyecto
              </InputLabel>
              <Select
                native
                onBlur={(e) => handleChange(e)}
                onChange={formik.handleChange}
                error={formik.touched.idProject && Boolean(formik.errors.idProject)}
                label={"Selecciona área o proyecto"}
                name="idProject"
                defaultValue={""}
                autoFocus={true}
                disabled={disabled}
              >
                <option value={""} disabled={true}>
                  {""}
                </option>
                {projects.map(({ id, proyectNameAndClient }: any, index: number) => (
                  <option key={index} value={id}>
                    {proyectNameAndClient}
                  </option>
                ))}
              </Select>
              <FormHelperText error={Boolean(formik.errors.idProject)}>
                {formik.touched.idProject &&
                  !state.collaborator?.idProject &&
                  "Obligatorio"}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid xs item>
            <FormControl variant="outlined" fullWidth={true} size="small">
              <InputLabel htmlFor="outlined-age-native-simple">
                Puesto
              </InputLabel>
              <Select
                native
                onBlur={(e) => handleChange(e)}
                onChange={formik.handleChange}
                label={"Puesto"}
                defaultValue={""}
                name="puestoId"
                autoFocus={true}
                disabled={disabled}
                error={
                  formik.touched.puestoId && Boolean(formik.errors.puestoId)
                }
              >
                <option value="" selected disabled>
                  {" "}
                </option>

                {puestos.map(
                  ({ idPuesto, NombrePuesto }: any, index: number) => (
                    <option key={index} value={idPuesto}>
                      {NombrePuesto}
                    </option>
                  )
                )}
              </Select>
              <FormHelperText style={{ color: "red" }}>
                {formik.touched.puestoId &&
                  !state.collaborator?.puestoId &&
                  "Obligatorio"}
              </FormHelperText>
            </FormControl>
          </Grid>
        </Grid>
      </div>
    </Form>
  );
};

const initialValues = () => {
  return {
    dateOfAdmission: "",
    client: "",
    area: "",
    Puesto: "",
    puestoId: "",
    idProject: "",
    campus: "",
  };
};

const validationSchema = () => {
  return {
    dateOfAdmission: Yup.string().required("La fecha es un campo requerido"),
    client: Yup.string().required("La fecha es un campo requerido"),
    idProject: Yup.string().required("Seleccione un área de trabajo"),
    Puesto: Yup.string().required("Seleccione un puesto de trabajo"),
    campus: Yup.string().required("Seleccione una sede"),
  };
};

export default InputDataRegister;
