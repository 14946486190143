import { useReducer } from "react"
import CentralPagosContext,{initalState} from "./CentralPagosContext"
import CentralPagosReducer from "./CentralPagosReducer"

const CentralPagosState = (props:any) =>{
  const [state, dispatch] = useReducer(CentralPagosReducer, initalState)
  return(
    <CentralPagosContext.Provider value={{state, dispatch}}>
      {props.children}
    </CentralPagosContext.Provider>
  )
}

export default CentralPagosState