import { useState, useContext } from 'react';
import {Dialog, DialogContent, withStyles, Theme  } from "@material-ui/core";
import MuiDialogActions from "@material-ui/core/DialogActions";
import MyMoodContext from '../../../context/MyMoodContext/MyMoodContext';
import {closeRiskSurvey} from '../../../context/MyMoodContext/Actions';
import {TextField,Select, InputLabel,FormControl,MenuItem, ListItemText, FormHelperText } from "@material-ui/core"
import { Form } from "semantic-ui-react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { CREATE_SURVEY } from  "../../../Querys/querys";
import { SuccessfulAlert } from "../../../alerts/successAlerts";
import { useMutation } from "@apollo/client";
import Checkbox from '@mui/material/Checkbox';

const DialogActions = withStyles((theme: Theme) => ({
  root: {
      margin: 0,
      padding: theme.spacing(1),
  },
}))(MuiDialogActions);

function NewRiskSurvey({projects, locations}:any){
  const {state,dispatch} = useContext(MyMoodContext);
  const [createNewSurvey] = useMutation(CREATE_SURVEY);
  const [selectedAllSedes, setSelectedAllSedes] = useState<String[]>([]);
  const [selectedAllProjects, setSelectedAllProjects] = useState<String[]>([]);

  const initalValues = () =>{
      return{
        displayText: "",
        area: "",
        sede: ""
      }
  }

  const validationSchema = () =>{

    return{
      displayText: Yup.string().required("Obligatorio"),
        area: Yup.array().required("Obligatorio").test("area", "Es necesario seleccionar una sede", function(value){
            if((value === undefined || value.length === 0)){
                return false;
            }else{
                return true;
            }
        }),
        sede: Yup.array().required("Obligatorio").test("area", "Es necesario seleccionar un proyecto", function(value){
            if((value === undefined || value.length === 0)){
                return false;
            }else{
                return true;
            }
        }),
    }
  }

  const formik = useFormik({
    initialValues: initalValues(),
    validationSchema: Yup.object(validationSchema()),
    onSubmit:(formData, {resetForm}) =>{
      
        createNewSurvey({
            variables:{
                input: {
                    displayText: formData.displayText,
                    project: selectedAllProjects.join(','),
                    campus: selectedAllSedes.join(',')
                }
            }
        }).then((res:any) =>{
            resetForm();
            SuccessfulAlert({
                title: "¡Exito!",
                text: "¡Registro creado!",
            });
            resetForm();
            setSelectedAllProjects([]);
            setSelectedAllSedes([]);
            closeRiskSurvey({}, dispatch);
        })
      
    }
})

const onChangeLocation = (e:any) => {
    if(e.target.value.indexOf('Todas') > -1){
        if(locations.length === selectedAllSedes.length){
            setSelectedAllSedes([]);
        }else{
            let filtered = locations.map((item:any)=> item.sedeName);
            setSelectedAllSedes(filtered);
        }
    }else{
        setSelectedAllSedes(e.target.value);
    }
}

const onChangeProject = (e:any) => {
    if(e.target.value.indexOf('Todas') > -1){
        if(projects.length === selectedAllProjects.length){
            setSelectedAllProjects([]);
        }else{
            let filtered = projects.map((item:any)=> item.proyectName);
            setSelectedAllProjects(filtered);
        }
    }else{
        setSelectedAllProjects(e.target.value);
    }
}

  return (
    <Dialog open={state.newRiskSurvey} aria-labelledby="form-dialog-title" maxWidth="xs" fullWidth={true}>
        <Form onSubmit={formik.handleSubmit}>
            <div className="dialogContainer">
                <div className="containerTitle">
                    <div className="centerContainer"
                    style={{
                        marginLeft:'auto',
                        marginRight:'auto'
                    }}
                    >
                        <h2 id="form-dialog-title" data-testid="TitleModal">Nuevo test de riesgo psicosocial</h2>
                        
                    </div>
                    <div className="contenedorCerrarModal">
                        <div className="iconoCerrarModal" onClick={(()=>closeRiskSurvey({}, dispatch))}></div>      
                    </div> 
                </div>
                    
                <DialogContent className="dialogoContento">
                    <div className="containerSurvey" >
                            <div>
                                <div className="conteinerQuestion" >
                                    <TextField
                                    type="text"
                                    name="displayText"
                                    label="Nombre"
                                    variant="outlined"
                                    size="small"
                                    fullWidth={true}
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    className="question"
                                    inputProps={{ maxLength: 100 }}
                                    onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                    error={formik.touched.displayText && Boolean(formik.errors.displayText)}
                                    helperText={formik.touched.displayText && formik.errors.displayText}
                                    />
                                </div>
                                <div className="conteinerQuestion" >
                                    <FormControl 
                                        fullWidth 
                                        size="small"
                                        variant="outlined" 
                                        className="question" 
                                    >
                                        <InputLabel id="demo-simple-select-label"
                                        style={{
                                            backgroundColor: "#FFFFFF"
                                        }}
                                        >Sede *</InputLabel>
                                        <Select
                                            style={{
                                                textOverflow: "ellipsis" ,
                                                whiteSpace: "nowrap",
                                                overflow: "hidden",   
                                                                                         
                                            }}
                                            value={
                                                selectedAllSedes
                                            }
                                            onChange={
                                                (e) => {
                                                    formik.handleChange(e);
                                                    onChangeLocation(e);
                                                }
                                            }
                                            labelId="demo-simple-select-label"
                                            id="sede"
                                            name = "sede"
                                            multiple
                                            renderValue={(selected:any) => selected.join(', ')}
                                            
                                            error={formik.touched.sede && Boolean(formik.errors.sede)}
                                        >
                                            <MenuItem key={0} value={'Todas'}>
                                                <Checkbox checked={selectedAllSedes.length === locations.length} />
                                                <ListItemText primary={"Todas"} />
                                            </MenuItem>
                                            {
                                                locations.map((item:any) => (
                                                    <MenuItem key={item.id} value={item.sedeName}>
                                                        <Checkbox checked={selectedAllSedes.indexOf(item.sedeName) > -1} />
                                                        <ListItemText primary={item.sedeName} />
                                                    </MenuItem>
                                                ))
                                            }
                                            
                                        </Select>
                                        <FormHelperText style={{color: "red"}}>
                                            {formik.touched.sede && formik.errors.sede}
                                        </FormHelperText>
                                    </FormControl>
                                </div>
                                <div className="conteinerQuestion" >
                                    <FormControl 
                                        fullWidth 
                                        size="small"
                                        variant="outlined" 
                                        className="question" 
                                        error={formik.touched.area && Boolean(formik.errors.area)}
                                    >
                                        <InputLabel id="demo-simple-select-label"
                                        style={{
                                            backgroundColor: "#FFFFFF"
                                        }}
                                        >&Aacute;rea o Proyecto*</InputLabel>
                                        <Select
                                            style={{
                                                textOverflow: "ellipsis" ,
                                                whiteSpace: "nowrap",
                                                overflow: "hidden",                                            
                                            }}
                                            value={
                                                selectedAllProjects
                                            }
                                            onChange={
                                                (e) => {
                                                    formik.handleChange(e);
                                                    onChangeProject(e);
                                                }
                                            }
                                            labelId="demo-simple-select-label"
                                            id="area"
                                            name = "area"
                                            multiple
                                            renderValue={(selected:any) => selected.join(', ')}
                                            error={formik.touched.area && Boolean(formik.errors.area)}
                                        >
                                            <MenuItem key={0} value={'Todas'}>
                                                <Checkbox checked={selectedAllProjects.length === projects.length} />
                                                <ListItemText primary={"Todas"} />
                                            </MenuItem>
                                            {
                                                projects.map((project:any) => (
                                                    <MenuItem key={project.id} value={project.proyectName}>
                                                        <Checkbox checked={selectedAllProjects.indexOf(project.proyectName) > -1} />
                                                        <ListItemText primary={project.proyectNameAndClient} />
                                                    </MenuItem>
                                                ))
                                            }
                                            
                                        </Select>
                                        <FormHelperText style={{color: "red"}}>
                                            {formik.touched.area && formik.errors.area}
                                        </FormHelperText>
                                    </FormControl>
                                </div>
                                
                            </div>
            
                    </div>      
                </DialogContent>
                <DialogActions>
                    <button className="botonCancelar" onClick={
                        (()=>closeRiskSurvey({}, dispatch))
                    }>
                        Cerrar
                    </button>
                    <button 
                        className="botonSiguiente"
                        type="submit"
                        >
                        <div>
                        Enviar
                        </div>
                        <div className="iconoSiguiente"></div>
                    </button>
                </DialogActions>
            </div>
        </Form>
    </Dialog>
  )
}

export default NewRiskSurvey;