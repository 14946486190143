import styles from '../../styles/CentralPagos/CentralPagos.module.css'
import { GET_ENTERPRISE_SELECT, GET_ENTERPRISE, GET_ALL_BANK, POST_TRANSACTION, GET_TRANSACTION, GET_ENTERPRISE_CENTRAL_PAGOS} from '../../Querys/querys'
import { useContext, useEffect, useState } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { useFormik } from "formik";
import * as Yup from "yup";
import { Form } from "semantic-ui-react";
import { SuccessfulAlert } from '../../alerts/successAlerts';
import CentralPagosContext from '../../context/CentralPagos/CentralPagosContext';
import { abreSinFondos, 
  openDispersarCentralPagos, 
  openEliminaRegistro, 
  openSinRegistros,
  openErrorMensaje } from '../../context/CentralPagos/Actions';
import AceptarDispersarCentalPagos from './Modal/AceptaDispersarCentralPagos';
import { cleanFormatter, formatter, formatterMxn } from '../../helpers/formatoMoneda';
import SinRegistrosCentralPagos from './Modal/SinRegistrosCentralPagos';
import EliminarCentralPagos from './Modal/EliminarCentralPagos';
import { openEditOutsourcerModal } from '../../context/Outsourcers/Actions';
import SinFondosCentralPagos from './Modal/SinFondosCentralPagos';
import ModalMensajeError from './Modal/ModalMensajeError';
import { WarningAlert } from '../../alerts/WarningAlert';

const CentralPagosTable = () =>{
  const [tagInputVal, setTagInputVal] = useState("");
  const [inputTotalValue, setinputTotalValue] = useState(0);
  const [empresa, setEmpresa] = useState("")
  const {state, dispatch} = useContext(CentralPagosContext)
  const [isCheck, setIsCheck] = useState<any[]>([])
  const [cargandoPrincipal, setCargandoPrincipal] = useState(true)
  const [total, setTotal] = useState(0)
  const [totalComission, setTotalComission] = useState(0)
  const [totalMontoDescontar, setTotalMontoDescontar] = useState(0)
  const [diferencia, setDiferencia] = useState(0)
  const [disponibleDispersar, setDisponibleDispersar] = useState(true)
  const [inicio, setInicio] = useState(0)
  const [inputTotal, setInputTotal] = useState('')
  const [inputNombre, setInputNombre] = useState('')
  const [inputConcepto, setInputConcepto] = useState('');
  const [bankClabe, setClabe] = useState('');

  const {loading:loadingListaEmpresa, error:errorListaEmpresa, data:dataListaEmpresa} = useQuery(GET_ENTERPRISE_SELECT,{})
  const dataEmpresaSelect = dataListaEmpresa?.GET_ENTERPRISE_SELECT

  const {loading:loadingEmpresa, error:errorEmpresa, data: dataEmpresa,refetch} = useQuery(GET_ENTERPRISE_CENTRAL_PAGOS,{
    variables:{
      getEnterpriseCentralPagosId: empresa
    }
  })

  const empresaSeleccionada = dataEmpresa?.GET_ENTERPRISE_CENTRAL_PAGOS

  const {loading:loadingBanco, error:errorBanco, data:dataBanco} = useQuery(GET_ALL_BANK,{})

  const bancoSelect = dataBanco?.GET_ALL_BANK

  const [createTransactions] = useMutation(POST_TRANSACTION,{
    refetchQueries:[
      {query: GET_TRANSACTION, variables:{ getTransactionsId: empresa}}
    ]
  })

  const {loading: loadingDatosTabla, error: errorDatosTabla, data: dataDatosTabla, refetch: refetchTransactions, startPolling, stopPolling} = useQuery(GET_TRANSACTION,{
    variables:{
      getTransactionsId: empresa
    }
  })

  const datosTabla = dataDatosTabla?.GET_TRANSACTIONS

  const datosIniciales = () =>{
    setCargandoPrincipal((current) => current = false)
    setInicio((current) =>current = empresaSeleccionada?.saldo)
    if(empresa === "" && dataEmpresaSelect && dataEmpresaSelect.length > 0){
      setEmpresa((current) => current = dataEmpresaSelect[0]["id"])
    }
    setIsCheck((current) => current = [])
  }

  const cambioEstado = (estado:any) =>{
    setEmpresa((current) => current = estado)
  }

  const limpiaEstadoGuardar = () =>{
    setInputNombre((current) => current = '')
    setInputTotal((current) => current = '')
    setTagInputVal((current) => current = '')
    setInputConcepto((current) => current = '')
  }

  const initialValues = () => {
    return{
      nombre: "",
      banco: "",
      numeroCuenta: "",
      total: "",
      enterpriseId: "",
      Concepto: ""
    }
  }

   const validationSchema = () => {
    return{
      nombre: Yup.string()
                 .required("El nombre es requerido")
                 .max(40,"Este texto sobrepasa los 40 caracteres"),
      banco: Yup.string().required("El banco es requerido"),
      numeroCuenta: Yup.string().test('bankTest', 'Es requerido seleccionar un banco primero',function (numeroCuenta){
        // console.log(this.parent.banco);
        return (!this.parent.banco || this.parent.banco === '') ? false : true;
      }).required("El numero de cuenta CLABE es requerido").min(18,"Por favor ingresa una cuenta de 18 dígitos")
        .test(
        'compare_clabe',
        'El formato de tu CLABE no es la correcta',
        function(numeroCuenta:string | undefined): boolean {
            return numeroCuenta ? numeroCuenta.substring(0, 3) === bankClabe : false
        }
      ),
      total: Yup.string()
                .required("El total es requerido"),
                //.matches(/\s([|(]?(((\$|(\US\$))\s*\d+)([.|,]?\d{2})?)[|)]?\s)/g,"Solo permiten caracteres numéricos"),
      Concepto: Yup.string()
                  .required("El concepto es requerido")
                  .max(40,"Este texto sobrepasa los 40 caracteres")
                  .matches(/^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$/g,"No se permiten caracteres especiales")
    }
  }

  const handleBank = (e:any) => {
    const result = bancoSelect.filter(
      (object: any) => e.target.value === object.id
    );
    setClabe(result[0].clabe);
  }

  const formik = useFormik({
    initialValues: initialValues(),
    validationSchema: Yup.object(validationSchema()),
    onSubmit:(formData) =>{
      createTransactions({
        variables:{
          input:{
            nombre: inputNombre,
            clabe: tagInputVal,
            idBank: parseInt(formData.banco),
            transactionTotal: parseFloat(cleanFormatter(inputTotal)),
            enterpriseId:parseInt(empresa),
            Concepto: inputConcepto
          }
        }
      })
      SuccessfulAlert({text:'Registro Actualizado'})
      limpiaEstadoGuardar()
    }
  })

  const handleSelectAll = (e:any) =>{
    if(!e.target.checked){
      setIsCheck([])
    }
    let filtradoSeleccionado = (datosTabla.filter((lis:any)=>{
      return e.target.checked && !isCheck.includes(lis.id) && (lis.dispersionStatus === "EnEspera") 
    }).map((lis:any) =>(lis.id)))
    setIsCheck(filtradoSeleccionado)
  }

  const handleClick =  (e:any) => {
    const {id, checked} = e.target
    setIsCheck([...isCheck, id])
    if(!checked){
      setIsCheck(isCheck.filter((lis:any) => lis !== id))
    }
  }

  const actualizaSaldos = ( ) =>{
    let arrayTotales = (datosTabla?.filter((lis:any)=>{
      return isCheck.includes(lis.id)
    }).map((lis:any) =>(lis.transactionTotal)))

    let arrayTotalesComission = (datosTabla?.filter((lis:any)=>{
      return isCheck.includes(lis.id)
    }).map((lis:any) =>(lis.comission)))

    let arrayTotalesMontoDescontar = (datosTabla?.filter((lis:any)=>{
      return isCheck.includes(lis.id)
    }).map((lis:any) =>(lis.montoDescontar)))
    
    let arrayLiquidadas = (datosTabla?.filter((lis:any) =>lis?.dispersionStatus === 'LIQUIDADO'))

    if(arrayTotales?.length > 0) {
      const suma = (acc:any, curr:any) => acc +curr

      setTotal(arrayTotales.reduce(suma))
      setTotalComission(arrayTotalesComission.reduce(suma))
      setTotalMontoDescontar(arrayTotalesMontoDescontar.reduce(suma))
      setDiferencia(inicio - arrayTotalesMontoDescontar.reduce(suma))

      if((inicio - arrayTotales.reduce(suma)) >= 0){
        setDisponibleDispersar(true)
      }else{
        setDisponibleDispersar(false)
      }

    }else{
      setTotal(0)
      setTotalComission(0)
      setDiferencia(0)
      setTotalMontoDescontar(0)
      setDisponibleDispersar(true)
    }
  }

  const guardaEstadoTotal = (e:any) =>{
    const valor= e.target.value;
    if(valor==="$"){
      setInputTotal((current) => current = e.target.value.replace(/^\$[0-9]{1,3}([\\.][0-9]{3})/ig,""));  
    } else if(valor ==="," ||valor ==="." ){
    setInputTotal((current) => current = e.target.value.replace(/[0-9]{1,3}([\\.][0-9]{3})/ig,""));
    }else{
      setInputTotal((current) => current = e.target.value.replace(/[^0-9\d.]/ig,""));
    }
    
   }

  const formatoEstadoTotal = (valor:any) =>{ 
    if(valor === ""){
      valor = "0"
    }
    if(valor.includes('$')){
      let valorLimpio = cleanFormatter(valor) 
      let estadoFormato = formatterMxn(valorLimpio)   
      setInputTotal((current) => current = estadoFormato)
    } else{
      let  estadoFormato = formatterMxn(valor)
      setInputTotal((current) => current = estadoFormato)
    }
  }

  function onChangeTagInput(e:any) {
    setTagInputVal(e.target.value.replace(/[^0-9\d.]/ig, ""));

  }

  const onChangeInputNombre = (e:any) =>{
    setInputNombre((current) => current = e)
  }

  const onChangeInputConcepto = (e:any)=>{
    setInputConcepto((current) => current = e)   
  }

  const abreDispersar = () =>{
    openDispersarCentralPagos({openDispersar: true}, dispatch)
  }

  const abreSinRegistros = () =>{
    openSinRegistros({openSinRegistros: true}, dispatch)
  }

  const abreAlertaSinFondos = () =>{
    abreSinFondos({openSinFondos: true}, dispatch)
  }

  const abreEliminaRegistro = (id:any) =>{
    openEliminaRegistro({openEliminarRegistro: true,_id:id}, dispatch)
  }

  const abreMensajeError =(id:any) =>{
    openErrorMensaje({openMensajeError: true,_id:id}, dispatch)
  }


  useEffect(()=>{
    refetch({
      getEnterpriseCentralPagosId: empresa
    })
    refetchTransactions({
      getTransactionsId: empresa
    })
  },[empresaSeleccionada])

  useEffect(()=>{
    if(datosTabla){
      datosIniciales()
      actualizaSaldos()
    }
  },[datosTabla, empresaSeleccionada])


  useEffect(()=>{
    if(datosTabla){
        actualizaSaldos()
    }
    startPolling(1000);
    return () =>{
      stopPolling()
    }
  },[datosTabla, isCheck, startPolling, stopPolling])

  return(
    <>
      <div className={styles.contenedorHeader}>
        <div>
          <select
            className={styles.selectEmpresa}
            onChange={(e) => cambioEstado(e.target.value)}
          >
            {dataEmpresaSelect?.map((empresa:any)=>(
              <option 
                className={styles.optionEmpresa} 
                value={empresa?.id}
                >{empresa?.name}</option>
            ))}
          </select>
        </div>
          {isCheck.length === 0?
            <button
              className={styles.botonDispersarInactivo}
              onClick={() => abreSinRegistros()}
              >
                <div className={styles.iconoDispersar}></div>
                  Dispersar
            </button>
          :
          (disponibleDispersar)?
            <button
              className={styles.botonDispersar}
              onClick={() => abreDispersar()}
              >
                <div className={styles.iconoDispersar}></div>
                Dispersar
            </button>
            :
            <button
            className={styles.botonDispersarInactivo}
            onClick={() => abreAlertaSinFondos()}
            >
              <div className={styles.iconoDispersar}></div>
              Dispersar
          </button>
          }
      </div>
      { loadingEmpresa || loadingListaEmpresa ?
        <div className={styles.contenedorCentral}>
          <div className={styles.contenedorLoading}>
            <div className={styles.loading}></div>
          </div>
        </div>
        :
      <>
      <div className={styles.contenedorCentral}>
        <div className={styles.contenedorTitulos}>
          <div className={styles.titulo}>Datos del beneficiario</div>
          <div className={styles.contenedorSaldos}>
            <div className={styles.contenedorSaldoDisponible}>
              <div className={styles.tituloSaldo}>Cuenta Bancaria</div>
              <div className={styles.contenedorInternoSaldo}>{empresaSeleccionada?.cuentaBancaria}</div>
            </div>
            <div className={styles.contenedorSaldoDisponible}>
              <div className={styles.tituloSaldo}>Saldo disponible</div>
              <div className={styles.contenedorInternoSaldo}>{formatter(empresaSeleccionada?.saldo)}</div>
            </div>
            <div className={styles.contenedorTotales}>
              <div>
                <div className={styles.tituloTotales}>Dispersar</div>
                <div className={styles.datoTotales}>{formatter(total)}</div>
              </div>
              <div>
                <div className={styles.tituloTotales}>Comisi&oacute;n</div>
                <div className={styles.datoTotales}>{formatter(totalComission)}</div>
              </div>
              <div>
                <div className={styles.tituloTotales}>Monto a pagar</div>
                <div className={styles.datoTotales}>{formatter(totalMontoDescontar)}</div>
              </div>
              <div>
                <div className={styles.tituloTotales}>Diferencia</div>
                <div className={styles.datoTotales}>{formatter(diferencia)}</div>
              </div>
            </div>
          </div>
        </div>
        <Form onSubmit={formik.handleSubmit}>
          <div className={styles.contenedorFormulario}>
            <div className={styles.filaFormulario}>
              <div>
                <input 
                  className={styles.inputFormulario} 
                  placeholder='Nombre'
                  name="nombre"
                  value={inputNombre}
                  onChange={(e) => {onChangeInputNombre(e.target.value)}}
                  onKeyDown={formik.handleChange}
                  onFocus={formik.handleChange}
                  onBlur={formik.handleBlur}
                  type="text"
                  />
                <div className={styles.contenedorError}>
                  <span className={styles.spanError}>{formik.touched.nombre && formik.errors.nombre}</span>
                </div>
              </div>
              <div>
                <input 
                  className={styles.inputFormulario} 
                  placeholder='Concepto'
                  name="Concepto"
                  value={inputConcepto}
                  onChange={(e) => {onChangeInputConcepto(e.target.value)}}
                  onKeyDown={formik.handleChange}
                  onFocus={formik.handleChange}
                  onBlur={formik.handleBlur}
                  type="text"                  
                  />
                <div className={styles.contenedorError}>
                  <span className={styles.spanError}>{formik.touched.Concepto && formik.errors.Concepto}</span>
                </div>
              </div>
            </div>
            <div className={styles.filaFormulario}>
              <div>
                <select
                  className={styles.selectFormulario}
                  name="banco"
                  onChange={ (e) => { formik.handleChange(e); handleBank(e) } }
                  onBlur={formik.handleBlur}
                >
                  <option 
                      className={styles.inputFormulario} 
                      value=""
                    >Seleccione banco...</option>
                  {bancoSelect?.map((banco:any)=>(
                    <option 
                      className={styles.inputFormulario} 
                      value={banco?.id}
                    >{banco?.name}</option>
                  ))}
                </select>
                <div className={styles.contenedorError}>
                  <span className={styles.spanError}>{formik.touched.banco && formik.errors.banco}</span>
                </div>  
              </div>
              <div>
                <input 
                className={styles.inputFormulario}
                placeholder='Número Cuenta CLABE'
                name="numeroCuenta"
                maxLength={18}
                id="tag-input"
                value={tagInputVal}
                disabled={formik.values.banco === ''}
                onChange={(e) => {onChangeTagInput(e)}}
                onKeyDown={formik.handleChange}
                onFocus={formik.handleChange}
                onBlur={formik.handleBlur}
                type="text"
                />
                <div className={styles.contenedorError}>
                  <span className={styles.spanError}>{formik.touched.numeroCuenta && formik.errors.numeroCuenta}</span>
                </div>
              </div>
            </div>
            <div className={styles.filaFormulario}>
              <div>
              <input 
                className={styles.inputFormulario}
                placeholder='Total'
                name="total"
                value={inputTotal}
                onChange={(e)=> guardaEstadoTotal(e)}       
                onBlur={(e) =>formatoEstadoTotal(e.target.value)}   
                onKeyDown={formik.handleChange}
                onFocus={formik.handleChange}      
                />
                <div className={styles.contenedorError}>
                  <span className={styles.spanError}>{formik.touched.total && formik.errors.total}</span>
                </div>  
              </div>
              <div className={styles.filaTresFormulario}></div>
            </div>
          </div>
          <div className={styles.contenedorBoton}>
           <button
            className={styles.botonDispersar}
            type="submit"
           >
            <div className={styles.iconoGuardar}></div>
            Guardar
            </button> 
          </div>
        </Form>
          <table className={styles.tabla}>
            <thead>
              <tr className={styles.tituloTabla}>
                <td className={styles.pd_primerColumnaTitulo}>                  
                  <div id="colaboladores" className={styles.checkboxitem}>
                    <input
                      id="check"
                      type="checkbox"
                      onChange={(e) => handleSelectAll(e)}
                    ></input>
                      <label htmlFor="check"> Ordenante </label>
                    </div></td>
                <td>Concepto</td>
                <td>Beneficiario</td>
                <td>CLABE</td>
                <td>Banco</td>
                <td>Dispersi&oacute;n</td>
                <td>Comisi&oacute;n</td>
                <td>Monto a descontar</td>
                <td>Estatus</td>
                <td>Acci&oacute;n</td>
              </tr>
            </thead>
            <tbody>
              {datosTabla?.map((datos:any)=>(
              <tr className={styles.datosTabla}>
                <td className={styles.pd_primerColumnaTitulo}>
                  <div id="colaboladores" className={styles.checkboxitem}>
                    <input
                      id={datos?.id}
                      key={datos?.id}
                      type="checkbox"
                      value={datos?.id}
                      checked ={isCheck.includes(datos?.id)}
                      onChange={(e) => handleClick(e)}
                      disabled={(datos?.dispersionStatus !== "EnEspera")?true:false}
                    ></input>
                   <label htmlFor={datos?.id} className={styles.datosLabel}>  {datos?.enterprise} </label>
                  </div></td>
                <td>{datos.Concepto}</td>
                <td>{datos.fullname}</td>
                <td>{datos.clabe}</td>
                <td>{datos.Banco}</td>
                <td>{formatter(datos.transactionTotal)}</td>
                <td>{formatter(datos.comission)}</td>
                <td>{formatter(datos.montoDescontar)}</td>
                <td>
                {(datos?.dispersionStatus === "ENPROCESO")?
                        <div className={`${styles.pd_botonEstatus} ${styles.pd_botonEstatusDos}`}>
                          <div className={styles.pd_textoBotonEstatusDos}>
                            En proceso...
                          </div>
                        </div>
                      : null
                      }
                      {(datos?.dispersionStatus === "ERROR")?
                        <div
                          onClick={()=> abreMensajeError(datos.id)}
                          className={styles.pd_botonEstatusTres}>
                          <div className={styles.pd_contenedorEstatusTres}>
                            <div className={styles.pd_contEstatusTres}>
                              <div className={styles.pd_iconoError}></div>
                                <div className={styles.pd_textoBotonError}>
                                  Error al dispersar
                                </div>
                              </div>
                            <div className={styles.pd_textoBotonErrorDos}>
                              {datos.mensajeDispersion}
                            </div>
                          </div>
                          <div className ={styles.pd_botonInternoDispersar}>
                            <div className={styles.pd_iconoIntentar}></div>
                          </div>
                        </div>
                      : null
                      }
                      {(datos?.dispersionStatus === "LIQUIDADO")?
                        <div className={`${styles.pd_botonEstatus} ${styles.pd_botonEstatusCuatro}`}>
                          <div className={styles.pd_textoBotonEstatusCuatro}>
                            Finalizado
                          </div>
                          <div className={styles.pd_iconoEstatusCuatro}></div>
                        </div>
                      : null
                      }
                </td>
                <td>
                  {(datos?.dispersionStatus ==='EnEspera')?
                  <>
                    <div 
                      className={styles.contenedorBotonEliminar}
                      onClick={() => abreEliminaRegistro(datos?.id)}
                      >
                      <div className={styles.iconoEliminarInterno}></div>
                      <div>
                        Eliminar
                      </div>
                    </div>
                  </>
                  :null
                  }
                </td>
              </tr>          
              ))}
            </tbody>
          </table>
      </div>
      </>
      }
      <AceptarDispersarCentalPagos 
        totalesDispersar={isCheck?.length} 
        totales={datosTabla?.length}
        seleccionados={isCheck}
      />
      <SinRegistrosCentralPagos />
      <EliminarCentralPagos empresa={empresa}/>
      <SinFondosCentralPagos />
      <ModalMensajeError />
    </>
  )
};

const validationSchema = () => {
  return{
    nombre: Yup.string().required("El nombre es requerido"),
    banco: Yup.string().required("El banco es requerido"),
    numeroCuenta: Yup.string().test('bankTest', 'Es requerido seleccionar un banco primero',function (numeroCuenta){
      console.log(this.parent.banco);
      return (!this.parent.banco || this.parent.banco === '') ? false : true;
    }).required("El numero de cuenta CLABE es requerido").min(18,"Por favor ingresa una cuenta de 18 dígitos"),
    total: Yup.string().required("El total es requerido"),
    Concepto: Yup.string().required("El concepto es requerido")
  }
}

export default CentralPagosTable