import { useContext, useEffect, useState } from "react";
import {
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  Select,
  TextField
} from "@material-ui/core";
import Box from '@mui/material/Box';
import CollaboratorContext from "../../../../context/CollaboratorContext/CollaboratorContext";
import { paisesEstados } from "../../../../helpers/Json/paises-estados";
import {
  getMunicipios,
  getStates,
} from "../../../../helpers/Json/getStatesAndMunicipios";
import InputMask from "react-input-mask";

const AddressDataInactivos = () => {
  const { state } = useContext(CollaboratorContext);
  const [states, setState] = useState<Array<string>>([]);
  const [municipios, setMunicipios] = useState<Array<string>>([]);
  const [estado, setEstado] = useState<string>("");
  const [municipio, setMunicipio] = useState<string>("");
  const cpMask = "";

  useEffect(() => {
    if (state.collaborator?.country)
      setState(getStates(state.collaborator?.country));
    if (state.collaborator?.state)
      setMunicipios(getMunicipios(state.collaborator?.state));

    if (state.collaborator) {
      if (state.collaborator?.municipality) {
        setMunicipio(state.collaborator?.municipality);
      }
      if (state.collaborator?.state) {
        setEstado(state.collaborator?.state);
      }
    }
  }, [state.collaborator]);

  return (
    <div className="novalidate__border">
      <Box mb={2}>
        <TextField
          type="text"
          name="address"
          defaultValue={state.collaborator?.address}
          label="Calle y número"
          variant="outlined"
          size="small"
          fullWidth={true}
          InputProps={{
            readOnly: true,
          }}
          style={{
            opacity: '0.5',
          }}
        />
      </Box>
      <Grid direction="row" container spacing={2}>
        <Grid xs item>
          <TextField
            name="suburb"
            defaultValue={state.collaborator?.suburb || ""}
            label="Colonia"
            variant="outlined"
            size="small"
            fullWidth={true}
            InputProps={{
              readOnly: true,
            }}
            style={{
              opacity: '0.5',
            }}
          />
        </Grid>
        <Grid xs item>
          <FormControl variant="outlined" fullWidth={true} size="small">
            <InputLabel htmlFor="outlined-age-native-simple"
            style={{
              opacity: '0.5',
            }}
            >País</InputLabel>
            <Select
              native
              label={"Pais"}
              name="country"
              defaultValue={state.collaborator?.country || ""}
              disabled={true}
            >
              <option value={state.collaborator?.country || ""} disabled={true}>
                {state.collaborator?.country || ""}
              </option>
              {paisesEstados.map((country: any, index: number) => (
                <option key={index} value={country.country}>
                  {country.country}
                </option>
              ))}
            </Select>
            <FormHelperText>
              {!state.collaborator?.country && "Obligatorio"}
            </FormHelperText>
          </FormControl>
        </Grid>
      </Grid>
      <Grid direction="row" container spacing={2}>
        <Grid xs item>
          <FormControl variant="outlined" fullWidth={true} size="small">
            <InputLabel htmlFor="outlined-age-native-simple"
            style={{
              opacity: '0.5',
            }}
            >Estado</InputLabel>
            <Select
              native
              label={"Estado"}
              name="state"
              value={estado}
              disabled={true}
            >
              <option value={estado} disabled={true}>
                {estado}
              </option>
              {states.map((state: string, index: number) => (
                <option key={index} value={`${state}`}>
                  {state}
                </option>
              ))}
            </Select>
            <FormHelperText>{!estado && "Obligatorio"}</FormHelperText>
          </FormControl>
        </Grid>
        <Grid xs item>
          <FormControl variant="outlined" fullWidth={true} size="small">
            <InputLabel htmlFor="outlined-age-native-simple"
            style={{
              opacity: '0.5',
            }}
            >
              Municipio
            </InputLabel>
            <Select
              native
              onChange={(e: any) => setMunicipio(e.target.value)}
              label={"Municipio"}
              name="municipality"
              value={municipio}
              disabled={true}
            >
              <option value={municipio} disabled={true}>
                {municipio}
              </option>
              {municipios.map((municipio: any, index: number) => (
                <option key={index} value={municipio}>
                  {municipio}
                </option>
              ))}
            </Select>
            <FormHelperText>{!municipio && "Obligatorio"}</FormHelperText>
          </FormControl>
        </Grid>
      </Grid>
      <Grid direction="row" container spacing={2}>
        <Grid xs item>
          <InputMask
            mask="99999"
            defaultValue={state.collaborator?.ZC || cpMask}
            disabled={false}
          >
              <TextField
                name="ZC"
                label="Código postal"
                variant="outlined"
                size="small"
                fullWidth={true}
                InputProps={{
                    readOnly: true,
                }}
                style={{
                  opacity: '0.5',
                }}
              />
          </InputMask>
        </Grid>
        <Grid xs item>
          <TextField
            name="nacionality"
            defaultValue={state.collaborator?.nacionality || ""}
            label="Nacionalidad"
            variant="outlined"
            size="small"
            fullWidth={true}
            InputProps={{
              readOnly: true,
            }}

            style={{
              opacity: '0.5',
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default AddressDataInactivos;
