import {Dialog, DialogContent, withStyles, Theme  } from "@material-ui/core"
import MyMoodContext from '../../../context/MyMoodContext/MyMoodContext';
import { useContext} from 'react';
import {closeQuestion} from '../../../context/MyMoodContext/Actions';
import '../MyMood.css';
import MuiDialogActions from "@material-ui/core/DialogActions";
import {TextField,Select, InputLabel,FormControl,MenuItem } from "@material-ui/core"
import moment from "moment";

const DialogActions = withStyles((theme: Theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

export const  FilterQuestions = (props:any) => {

    const {state,dispatch} = useContext(MyMoodContext)

    const sendFilter = ()=>{
        let filtrados: any
        let filtrados2: any
        let filtrados3: any
        let filtrados4: any
        
        if(props.filterArea !== ''){
            filtrados = props.surveySinFiltroStart.filter((lis: any) =>
                String(lis?.idArea) ===  String(props.filterArea)
            );
        }else{
            filtrados = props.surveySinFiltroStart
        }

        if(props.filterSede !== ''){
            filtrados2 = filtrados.filter((lis: any) =>
                String(lis?.campus) ===  String(props.filterSede)
            );
        }else{
            filtrados2 = filtrados
        }

        if(props.filterEstatus !== ''){
            filtrados3 = filtrados2.filter((lis: any) =>
                String(lis?.Estatus) ===  String(props.filterEstatus)
            );
        }else{
            filtrados3 = filtrados2
        }

        if(props.filterFecha !== ''){
            filtrados4 = filtrados3.filter((lis: any) =>
                moment(lis?.Fecha).isSame(moment(props.filterFecha))  
            );
        }else{
            filtrados4 = filtrados3
        }

        if (props.filterArea === '' && props.filterSede === '' && props.filterEstatus === '' && props.filterFecha === '') {
            filtrados4 = props.surveySinFiltroStart;
            props.setSurveyConFiltro([]);
        }else{
  
            props.setSurveyConFiltro(
                filtrados4.map((lis: any) => lis.Pregunta)
            )
        }
        
        props.setSurvey(filtrados4);
        props.setSurveySinFiltro(filtrados4);
        
        closeQuestion({}, dispatch)

    }

    return (
        <Dialog open={state.openFilter} aria-labelledby="form-dialog-title" maxWidth="xs" fullWidth={true}>
            <div className="dialogContainer">
                        
                <DialogContent className="dialogoContento">
                    
                    <div className="conteinerAllQuestion">
                    
                        <FormControl 
                            fullWidth 
                            size="small"
                            variant="outlined" 
                            className="question" 
                        >
                            <InputLabel id="demo-simple-select-label"
                            style={{
                                backgroundColor: "#FFFFFF"
                            }}
                            >&Aacute;rea o sub &aacute;rea</InputLabel>
                            <Select
                                style={{
                                    textOverflow: "ellipsis" ,
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",                                            
                                }}
                                value={
                                    props.filterArea
                                }
                                onChange={
                                    (e:any)=>{
                                        
                                        props.setFilterArea(e.target.value)
                                    }
                                }
                                labelId="demo-simple-select-label"
                                id="filterArea"
                                name = "filterArea"
                            >
                                <MenuItem value="" key="" >Seleccione</MenuItem>
                                {
                                    props.projects.map((project:any)=>{
                                        return <MenuItem value={project.id} key={project.id} >{project.proyectNameAndClient}</MenuItem>
                                    })
                                }
                                
                            </Select>
                        </FormControl>
                    </div>
                    <div className="conteinerAllQuestion">
                        <FormControl 
                            fullWidth 
                            size="small"
                            variant="outlined" 
                            className="question" 
                        >
                            <InputLabel id="demo-simple-select-label"
                            style={{
                                backgroundColor: "#FFFFFF"
                            }}
                            >Sede</InputLabel>
                            <Select
                                style={{
                                    textOverflow: "ellipsis" ,
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",   
                                                                                
                                }}
                                value={
                                    props.filterSede
                                }
                                onChange={
                                    (e:any)=>{
                                        
                                        props.setFilterSede(e.target.value)
                                    }
                                }
                                labelId="demo-simple-select-label"
                                id="filterSede"
                                name = "filtersede"
                            >
                                <MenuItem value="" key=""  >Seleccione</MenuItem>
                                {
                                    props.sede.map((item:any)=>{
                                        return <MenuItem value={item.sedeName} key={item.id} >{item.sedeName}</MenuItem>
                                    })
                                }
                                
                            </Select>
                        </FormControl>
                    </div>
                    <div className="conteinerAllQuestion">
                        <FormControl 
                            fullWidth 
                            size="small"
                            variant="outlined" 
                            className="question" 
                        >
                            <InputLabel id="demo-simple-select-label"
                            style={{
                                backgroundColor: "#FFFFFF"
                            }}
                            >Estatus</InputLabel>
                            <Select
                                style={{
                                    textOverflow: "ellipsis" ,
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",   
                                                                                
                                }}
                                value={
                                    props.filterEstatus
                                }
                                onChange={
                                    (e:any)=>{
                                        
                                        props.setFilterEstatus(e.target.value)
                                    }
                                }
                                labelId="demo-simple-select-label"
                                id="filterEstatus"
                                name = "filterEstatus"
                            >
                                <MenuItem value="" key=""  >Seleccione</MenuItem>
                                <MenuItem value="Activo" key="1"  >Activo</MenuItem>
                                <MenuItem value="Inactivo" key="2"  >Inactivo</MenuItem>
                                
                            </Select>
                        </FormControl>
                    </div>
                    <div className="conteinerAllQuestion">
                        <TextField
                            className="question"
                            type="date"
                            name="filterFecha"
                            value={
                                props.filterFecha
                            }
                            variant="outlined"
                            size="small"
                            fullWidth={true}
                            onChange={(e:any)=>{
                                
                                props.setFilterFecha(e.target.value)
                            }}
                            inputProps={{ maxLength: 100 }}
                            onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                        />
                    </div>
               
                </DialogContent>
                
                <DialogActions>
                    <div
                    style={{
                        marginLeft: "auto",
                        marginRight: "auto",
                    }}
                    >
                        <button className="botonSiguiente"onClick={(()=>sendFilter())}>
                            <div>
                                Aplicar
                            </div>
                        </button>
                    </div>
                        
                    

                </DialogActions>
            </div>
        </Dialog>
    )

}