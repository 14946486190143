import {useState, useContext} from "react";
import { Dialog } from "@material-ui/core"
import styles from '../../Payroll/Modals/PayrollModals.module.css'
import CentralPagosContext from "../../../context/CentralPagos/CentralPagosContext";
import { clearDispersar } from "../../../context/CentralPagos/Actions";

 

const SinRegistrosCentralPagos = (props: any) =>{
  const {state, dispatch} = useContext(CentralPagosContext)
  
  const handleClose = () =>{
    clearDispersar({}, dispatch)
  }

  return(
    <Dialog aria-labelledby='costumized-dialog-title' open={state.openSinRegistros} fullWidth={false}  onClose={handleClose} maxWidth={"md"}>
      <div className={styles.contenedorPrincipal}>
        <div className={styles.contenedorCerrarModal}>
          <div 
            className={styles.cerrarModal}
            onClick={()=> handleClose()}
          >
          </div>
        </div>
        <div className={styles.contenedorPrincipal}>
          <div className={styles.fim_iconoPrincipal}> 
            <div className={styles.fim_iconoComplemento}>
            </div>
          </div>
        </div>
        <div className={styles.tituloAtencion}>
          ¡Atenci&oacute;n!
        </div>
        <div className={styles.em_mensajePrtincipal}>
          No tienes registros seleccionados
        </div>
        <div className={styles.em_contenedorBotones}>
              <button 
                className={styles.em_botonCancelar}
                onClick={()=> handleClose()}
              >
                Cancelar
              </button>
                <button 
                  className={`${styles.botonesAtencion} ${styles.continuarAtencion}`}
                  onClick={()=> handleClose()}
                  >
                    <div>
                      Continuar
                    </div>
                </button>
            </div>
      </div>
    </Dialog>
  )

}


export default SinRegistrosCentralPagos