
import { Grid } from "@material-ui/core";
import Box from '@mui/material/Box';
import CustomTabs from '../../components/Collaborators/Tab/CustomTabs';
import styles from "./DispersionGraphs.module.css"
import CustomTab from '../../components/Collaborators/Tab/CustomTabMain';
import { Graphic } from "./Graphic";

const DispersionGraphs = () => {

    const handleChange = () =>{
      }
    
    const porcentaje = 80;

    return (
        <>
            <Box mt={3} ml={5} className="Title">
                Terminar
            </Box>
            <Box p={5} pb={3} pt={0}>
                <Grid container justify="flex-start">
                <CustomTabs
                    //Tiene que ser 1 para que se muestre la pestaña de pre-nómina
                    value={0}
                    onChange={handleChange}
                    aria-label="simple tabs example"
                >
                    <CustomTab label="outsourcers" value={0} />
                    <CustomTab label="Históricas" value={1} />
                </CustomTabs>
                </Grid>
            </Box>
            <div
                style={{
                    display: "flex",
                }}
            >
                <div className={styles.p_contenedorTablaDispersion}>
                    <div>
                        <h1 className={styles.alineacionTituloGraficas}>Pagos</h1>

                    </div>
                    <div
                    style={{
                        display: "flex",
                    }}
                    >
                        <div
                            style={{
                                marginLeft: "30px",
                            }}
                        >
                            <Graphic percentage={porcentaje}/>
                        </div>
                        <div
                            style={{
                                marginLeft: "15px",
                            }}
                        >
                            <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                marginTop: "auto",
                            }}
                            >
                                <div
                                style={{
                                    backgroundColor: "rgb(33, 134, 198)",
                                    width: "20px",
                                    height: "20px",
                                    marginRight: "10px",
                                }}
                                ></div>
                                <p
                                    className={styles.alineacionTituloGraficas}
                                >
                                    Otros pagos
                                </p>
                            </div>
                            <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                marginBottom: "auto",
                            }}
                            >
                                <div
                                style={{
                                    backgroundColor: "rgb(79, 174, 235)",
                                    width: "20px",
                                    height: "20px",
                                    marginRight: "10px",
                                }}
                                ></div>
                                <p
                                    className={styles.alineacionTituloGraficas}
                                >
                                    
                                    Pagos
                                </p>
                            </div>

                        </div>
                    </div>

                </div>
                <div className={styles.p_contenedorTablaDispersion}>
                    <div>
                        <h1 className={styles.alineacionTituloGraficas}>Pagos Realizador</h1>

                    </div>
                    <div>
                        <div
                            className={styles.alineacionTituloGraficas}
                        >
                            <p>Dispersiones Canceladas</p>
                        </div>
                        <div
                        style={{
                            backgroundColor: "#F1F2F5",
                            borderRadius: "8px",
                            width: "200px",
                            height: "100px",
                            marginLeft: "auto",
                            marginRight: "auto",
                        }}

                        >
                        </div>
                    </div>

                </div>
                <div className={styles.p_contenedorTablaDispersion}>
                    <div>
                        <div
                        style={{
                            backgroundColor: "#F1F2F5",
                            borderRadius: "8px",
                            width: "200px",
                            height: "200px",
                            marginLeft: "auto",
                            marginRight: "auto",
                        }}
                            
                        >
                        
                        </div>
                    </div>

                </div>
            </div>
            

    
        </>
    );



}

export default DispersionGraphs;