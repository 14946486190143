import moment from 'moment';

const ScheduleTuesdayField = ({HorarioMartes}:any) => {
    const dateSplit = HorarioMartes ? HorarioMartes.split(' - ') : null;
    return (
        <div>
            <label>{ dateSplit ? moment(dateSplit[0]).format('hh:mm A') +" - "+moment(dateSplit[1]).format('hh:mm A') : ''}</label>
        </div>
    )
}

export default ScheduleTuesdayField
