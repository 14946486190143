import { useContext, useState } from "react";
import CalendarsContext from "../../../../context/NewCalendarContext/CalendarsContext";
import { clearCalendarsModal } from "../../../../context/NewCalendarContext/Actions";
import {Dialog, DialogContent, TextField  } from "@material-ui/core"
import { Formik, Form} from "formik";
import styles from '../../../../components/NewCalendar/Calendars.module.css';
import * as Yup from "yup";
import { useMutation } from "@apollo/client";
import {  
    CREATE_NON_WORKING,
    GET_COUNT_EVENT_NONWORKINGDAY,
    GET_NON_WORKING_BY_MONTH_YEAR
} from "../../../../Querys/querys";
import { SuccessfulAlert } from "../../../../alerts/successAlerts";
import { subMonths } from 'date-fns';
import moment from 'moment';
import DateRangeField from "../../../MyMood/Fields/DateRangeField";

const CreateNonWorkingDayModal = ({setDate, date, setNonWorkingDays, nonWorkingDays, daySelectedMonth}:any) => { 

    const {state, dispatch} = useContext(CalendarsContext)
    const [showCalendario, setShowCalendario] = useState(false);
    const [stateCalendario, setStateCalendario] = useState([
        {
          startDate: subMonths(new  Date(parseInt(moment(daySelectedMonth).format('YYYY')), parseInt(moment(daySelectedMonth).format('MM')), parseInt(moment(daySelectedMonth).format('DD'))), 1),
          endDate: subMonths(new Date(parseInt(moment(daySelectedMonth).format('YYYY')), parseInt(moment(daySelectedMonth).format('MM')), parseInt(moment(daySelectedMonth).format('DD'))), 1),
          key: "selection"
        }]
      );
    const [createNewNonWorkingDay] = useMutation(CREATE_NON_WORKING,{
      refetchQueries:[
                        {query:GET_NON_WORKING_BY_MONTH_YEAR, variables:{
                                                                            calendarId: parseInt(state._id), 
                                                                            month: parseInt(moment(stateCalendario[0].startDate).format('M')), 
                                                                            year: parseInt(moment(stateCalendario[0].startDate).format('YYYY'))
                                                                        }},
                        {query:GET_NON_WORKING_BY_MONTH_YEAR, variables:{
                                                                            calendarId: parseInt(state._id), 
                                                                            month: parseInt(moment(stateCalendario[0].endDate).format('M')), 
                                                                            year: parseInt(moment(stateCalendario[0].endDate).format('YYYY'))
                                                                        }},
                        {query:GET_COUNT_EVENT_NONWORKINGDAY}
                    ]
    })

    const handleClose = ()=>{
        clearCalendarsModal({}, dispatch);
    }
    const initialValues = () => {
        return {
            name: ""
        }
    }
    const toggleCalendar = () => {
        if(showCalendario === false){
          setShowCalendario(true);
        }
    }
    const rangeSelection = (selection: any)=>{
        setStateCalendario((current)=>current= selection);
        setShowCalendario(!showCalendario);
      }

  return(
    <Dialog open={state.createIncidentModal} aria-labelledby="form-dialog-title" maxWidth="sm" fullWidth={true}>
        <div className={styles.dialogContainer}>
            <div className={styles.containerTitle}>
                <div className={styles.centerContainer}
                style={{
                    marginLeft:'auto',
                    marginRight:'auto'
                }}
                >
                    <h2 id="form-dialog-title" data-testid="TitleModal">Agregar día inhábil</h2>
                    
                </div>
                <div className={styles.contenedorCerrarModal}>
                    <div className={styles.iconoCerrarModal} onClick={(()=>handleClose())}></div>      
                </div> 
            </div>
            <DialogContent>
            <Formik
            initialValues={initialValues()}
            validationSchema={validationSchema}
            onSubmit={formData => {
                createNewNonWorkingDay({
                    variables: {
                        input: {
                            name: formData.name,
                            calendarId: parseInt(state._id),
                            initDate: stateCalendario[0].startDate,
                            endDate: stateCalendario[0].endDate
                        },
                    },
                }).then(({data})=>{
                  SuccessfulAlert({text:"Calendario creado con éxito"});
                });
                handleClose();
            }}
        >
            {({
                values,
                errors,
                touched,
                handleChange,
                handleSubmit,
            }) => (
            <Form onSubmit = {handleSubmit}>
                <TextField 
                    fullWidth 
                    className={styles.dialogFields} 
                    name="name" 
                    label="Título*" 
                    variant="outlined" 
                    value = {values.name}
                    onChange={handleChange}
                    error={touched.name && Boolean(errors.name)}
                    helperText = {touched.name && errors.name}
                    size="small" 
                    inputProps={{maxLength: 30}}
                    onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                    />
                <DateRangeField setDate={setStateCalendario} containerClass={"fieldsetInput"} daySelected1={daySelectedMonth} daySelected2={daySelectedMonth}></DateRangeField>
                <br></br>
                <div className={styles.centerContainer}>
                    <button className={styles.buttonCancelCalendar} onClick={() => handleClose()}>
                        <b className={styles.buttonCancelCalendarText}
                        style={{
                            textTransform: "capitalize"
                        }}
                        >Cancelar</b>
                    </button>
                    <div className={styles.conteinerSave}>
                        <button type="submit" className={styles.buttonSaveCalendar2}>
                        <b className={styles.buttonSaveCalendarText}
                        style={{
                            textTransform: "capitalize"
                        }}
                        >Crear
                        </b>
                        </button>
                    </div>
                        
                </div>

                </Form>
            )}
            </Formik>
            </DialogContent>
        </div>
    </Dialog>
  )
}


// @ts-ignore
const validationSchema = Yup.object().shape({
        name: Yup.string().required("El título es requerido")
})
// @ts-ignore


export default CreateNonWorkingDayModal