import styles from "../../styles/IDSE/IDSE.module.css"
import {useQuery} from "@apollo/client"
import { GET_MOVIMIENTOS_SUA } from "../../Querys/querys";
import { useEffect, useState } from "react";
import {formatter} from "../../helpers/formatoMoneda"
import {saveAs} from 'file-saver'
import { WarningAlert } from "../../alerts/WarningAlert";


interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
};

const SUATable = (props: TabPanelProps) =>{
  const [isCheck, setIsCheck] =  useState<any[]>([])
  const [filtrado, setFiltrado] =  useState<any[]>([])
  const [textoTXT, setTextoTXT] = useState("")

  const {data} = useQuery(GET_MOVIMIENTOS_SUA,{
    variables:{
      input:{
        filtro: ''
      }
    }
  })
  

  const datosFiltrados = (e:any) =>{
    if(e !== '') {
      let expresion = new RegExp(`${e}.*`, "i")
      const nuevoFiltrado = filtrado?.filter((lis:any) => expresion.test(lis.Nombre) || expresion.test(lis.empresa))
      setFiltrado(nuevoFiltrado)
    }else{
      obtenerDatos()
    }
  }

  const resultado = data?.GET_MOVIMIENTOS_SUA

  const obtenerDatos = async () => {
    await setFiltrado(resultado)
  };


  const handleSelectAll = (e:any) =>{
    if(!e.target.checked){
      setIsCheck([])
    }
    let filtradoSeleccionado = (resultado.filter((lis:any)=>{
      return e.target.checked && !isCheck.includes(lis.idMovimiento)
    }).map((lis:any) =>(lis.idMovimiento)))
    setIsCheck(filtradoSeleccionado)
  }

  const handleClick =  (e:any) => {
    const {id, checked} = e.target
    setIsCheck([...isCheck, parseInt(id)])
    if(!checked){
      setIsCheck(isCheck.filter((lis:any) => lis !== parseInt(id)))
    }
  }

  const pruebaTexto = () =>{
    if(isCheck.length === 0){
      WarningAlert({ text: 'No tienes registros seleccionados' })
    } else {
    const blob = new Blob([textoTXT], {type:'text/plain;charset=utf-8'})
    saveAs(blob, 'Movimientos.txt')
    }
  }

  const actualizaTexto = () =>{
    let arrayDatos = (resultado?.filter((lis:any)=>{
      return isCheck.includes(lis.idMovimiento)
    }).map((lis:any)=>(lis.respuestaSua)))
    let arrayConvertidoATexto  = arrayDatos?.toString();
    let textoConSaltosLinea = arrayConvertidoATexto?.replace(/,/g, "\n");
    setTextoTXT ((current) => current = textoConSaltosLinea)
  }



  useEffect(()=>{
    setFiltrado(current => current = resultado)
    actualizaTexto()
  },[resultado, isCheck,])

  
  return(
    <div className={styles.contenedor}>
      <div className={styles.contenedorTitulo}>
        Movimientos SUA
      </div>
      <div className={styles.contenedorAccionesTitulo}>
        <div className={styles.contenedorInput}>
          <div>
            <input 
              type="text" 
              className={styles.input} 
              placeholder="Buscar al colaborador por nombre"
              onChange={(e) => datosFiltrados(e.target.value)}/>
        </div>  
        <div className={styles.iconoBuscar}></div>
      </div>
      <button 
        className={styles.botonDescargar}
        onClick={pruebaTexto}
        >
          <div className={styles.iconoDescargar}></div>
          <div>
            Descargar reporte
          </div>
        </button>
      </div>
      <div className={styles.contenedorTabla}>
        <table className={styles.tabla}>
          <thead className={styles.thead}>
            <tr>
              <td className={`${styles.tituloTabla} ${styles.primeraColumna}`}>
                <div id="colaboladores" className={styles.checkboxitem}>
                  <input
                    id="check"
                    type="checkbox"
                    onChange={(e) => handleSelectAll(e)}
                    ></input>
                      <label htmlFor="check"> Colaboradores </label>
                </div>
              </td>
              <td className={styles.tituloTabla}>Empresa</td>
              <td className={styles.tituloTabla}>Registro Patronal</td>
              <td className={styles.tituloTabla}>SBC</td>
              <td className={styles.tituloTabla}>Movimiento</td>
              <td className={styles.tituloTabla}>Fecha del movimiento</td>
              <td className={styles.tituloTabla}>Estatus</td>
            </tr>
          </thead>
          <tbody>
            {filtrado?.map((lis:any)=>(
             <tr className={styles.datoTabla}>
              <td className={styles.primeraColumna}>
                <div id="colaboladores" className={styles.checkboxitem}>
                      <input
                        id={lis.idMovimiento}
                        key={lis.idMovimiento}
                        type="checkbox"
                        value={lis.idMovimiento}
                        checked ={isCheck.includes(lis.idMovimiento)}
                        onChange={(e) => handleClick(e)}
                        disabled={false}
                      ></input>
                      <label htmlFor={lis.idMovimiento}>  {lis.Nombre} </label>
                    </div>
                </td>
              <td>{lis.empresa}</td>
              <td>{lis.registroPatronal}</td>
              <td>{formatter(lis.SBC)}</td>
              <td>{lis.Movimiento}</td>
              <td>{lis.fechaMovimiento}</td>
              <td>{lis.estatusMovimiento}</td>
             </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default SUATable