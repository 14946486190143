import {
  Theme,
  Dialog,
  Button,
  withStyles,
  Select,
  Grid,
  TextField,
} from "@material-ui/core";
import Box from '@mui/material/Box';
import "../Modal/CrearPuesto.css";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import { OutlinedInput } from "@mui/material";
import { useEffect, useState, useContext } from "react";
import { SuccessfulAlert } from "../../../alerts/successAlerts";
import { PuestoModal } from "../../../interfaces/TabPuesto.interfaces";
import { useForm } from "../../../hooks/useForm";
import { TabPuestoContext } from "../../../context/TabPuestosContext/TabPuestosContext";
import { IProject } from "../../../interfaces/Project";
import PuestoContext from "../../../context/PuestoContext/PuestoContext";
import ImagenColaborador from "../ImagenColaborador";
import { ErrorAlert } from "../../../alerts/errorAlert";
import "../Organigrama.css";
import { useMutation, useQuery } from "@apollo/client";
import {
  ADD_USER_PUESTO,
  CREATE_PUESTO,
  GET_ALL_PROJECT,
  GET_USER_ADMIN,
  GET_USER_AVAILABLE,
  GET_ALL_PUESTOS_USERS_PROJECT,
  GET_ALL_USERS_COLLABORATOR,
  GET_EXCEL_ACTIVE_USER
} from "../../../Querys/querys";

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const CrearPuestoModal = (props: any) => {
  const { state } = useContext(PuestoContext);
  const [estadoInicial, setEstadoInicial] = useState<any[]>([]);
  const [seleccionados, setSeleccionados] = useState<any[]>([]);
  const [Superior, setPuestoSuperior] = useState<any[]>([]);

  const [proyectos, setProyectos] = useState<IProject[]>([]);

  const { addPuestoOpen, setAddPuestoOpen } = useContext(TabPuestoContext);
  const [filtrado, setFiltrado] = useState<any[]>([]);
  const { data: resultAreaProyecto } = useQuery(GET_ALL_PROJECT);
  const projectsFetch = resultAreaProyecto?.GET_ALL_PROJECT;

  const { data: resultPuestos } = useQuery(GET_ALL_PUESTOS_USERS_PROJECT);
  const puestosFetch = resultPuestos?.GET_ALL_PUESTOS_USERS_PROJECT;

  useEffect(() => {

    if (puestosFetch) {
      setPuestoSuperior(puestosFetch);
    }
    if (projectsFetch) {
      setProyectos(projectsFetch);
    }
    if (props.disponibles) {
      setFiltrado(props.disponibles);
    }
    if (props.disponibles) {
      setEstadoInicial(props.disponibles);
    }
  }, [projectsFetch, puestosFetch, props.disponibles]);

  const {
    NombrePuesto,
    AreaProyecto,
    PuestoSuperior,
    Descripcion,
    onChange,
    formulario,
    reset,
  } = useForm<PuestoModal>({
    NombrePuesto: "",
    AreaProyecto: "",
    PuestoSuperior: "",
    Descripcion: "",
    NombreUsuario: "",
    idUsuario: "",
  });

  const handleClose = () => {
    reset();
    setAddPuestoOpen();
    setEstadoInicial(props.disponibles)
    setSeleccionados([])
    setFiltrado(props.disponibles)
    props.setSave(true)
  };

  const [createPuesto] = useMutation(CREATE_PUESTO, {
    refetchQueries: [
      { query: GET_ALL_PUESTOS_USERS_PROJECT },
      { query: GET_USER_AVAILABLE, variables: { puesto: 0 } },

    ],
  });

  const [UserPuestosAdd] = useMutation(ADD_USER_PUESTO, {
    refetchQueries: [
      { query: GET_USER_AVAILABLE, variables: { puesto: 0 } },
      { query: GET_USER_ADMIN, variables: { puesto: state._id } },
      { query: GET_ALL_PUESTOS_USERS_PROJECT },
      { query: GET_ALL_USERS_COLLABORATOR },
      { query: GET_EXCEL_ACTIVE_USER },
      { query: GET_ALL_PROJECT },
    ],
  });

  const datosFiltrados = (e: any) => {
    if (e !== "") {
      let expresion = new RegExp(`${e}.*`, "i");
      const nuevoFiltrado = estadoInicial.filter((lis: any) =>
        expresion.test(lis.NombreUsuario)
      );

      setFiltrado(nuevoFiltrado);
    } else {

      setFiltrado(estadoInicial);
    }
  };

  const handleAdd = async () => {

    if (NombrePuesto !== "" && AreaProyecto !== "") {

      const puestoSuperior: any = Superior.filter(
        (lis: any) => lis.idPuesto === formulario.PuestoSuperior
      );
      const namePuestoSuperior = puestoSuperior[0]?.NombrePuesto;
      const area = proyectos.filter(
        (lis: any) => lis.id === formulario.AreaProyecto
      );
      const nameArea = area[0]?.proyectName;

      await createPuesto({
        variables: {
          input: {
            NombrePuesto: formulario.NombrePuesto,
            AreaProyecto: nameArea,
            PuestoSuperior: namePuestoSuperior,
            Descripcion: formulario.Descripcion,
            idPuestoSuperior: parseInt(PuestoSuperior),
            idArea: parseInt(AreaProyecto),
          },
        },
      }).then((data) => {
        if (data) {
          seleccionados?.forEach((lis: any) => {
            UserPuestosAdd({
              variables: {
                addUserPuestoId: lis._id,
                puestoId: data?.data.CREATE_PUESTO.id,
              },
            });
          });
        }

        handleClose()


        SuccessfulAlert({ text: "Registro creado con éxito" });
      });
    } else {
      if (NombrePuesto === "") {
        ErrorAlert({ text: "Nombre de puesto es requerido." });
        props.setSave(true)
      } else {
        if (AreaProyecto === "") {
          ErrorAlert({ text: "Área es requerida." });
          props.setSave(true)
        }
      }

    }
  };

  function UserPuestoLibre() {

    const agregaSeleccionadas = (objeto: any) => {
      setSeleccionados([
        ...seleccionados,
        {
          _id: objeto._id,
          ImagenUsuario: objeto.ImagenUsuario,
          NombreUsuario: objeto.NombreUsuario,
          ApellidoUsuario: objeto.ApellidoUsuario,
          PrimerNombre: objeto.PrimerNombre,
          Usuario: objeto.Usuario,
        },
      ]);

      const usersWithoutOneStart = estadoInicial.filter(
        (lis: any) => lis?._id !== objeto._id
      );
      setEstadoInicial(usersWithoutOneStart);

      const usersWithoutOne = filtrado.filter(
        (lis: any) => lis?._id !== objeto._id
      );
      setFiltrado(usersWithoutOne)

    };

    const sidebar = (
      <Box className="contenedorUsuarios">
        <ul className="ulLista">
          {filtrado?.map((lis: any) => (
            <li key={lis._id} className="contenedorLista">
              <div className="alineacionPuesto">
                {lis.ImagenUsuario === null ? (
                  <ImagenColaborador
                    Nombre={lis.NombreUsuario}
                    Apellido={lis.ApellidoUsuario}
                  />
                ) : (
                  <img
                    src={lis.ImagenUsuario}
                    alt="img"
                    className="imgCollaborator__BITSss"
                  />
                )}
                <div>
                  <div className="nombrePuesto">
                    <span className="textoListaUsuario">
                      {lis.PrimerNombre} {lis.ApellidoUsuario}
                    </span>
                  </div>
                  <div className="nombrePuesto">
                    <span className="correoListaUsuario">{lis.Usuario}</span>
                  </div>
                </div>
              </div>
              <Box>
                <button
                  className="bottonAgregarUsuario"
                  onClick={() =>
                    agregaSeleccionadas({
                      _id: lis._id,
                      ImagenUsuario: lis.ImagenUsuario,
                      NombreUsuario: lis.NombreUsuario,
                      ApellidoUsuario: lis.ApellidoUsuario,
                      PrimerNombre: lis.PrimerNombre,
                      Usuario: lis.Usuario,
                    })
                  }
                >
                  <div>
                    <span className="textoBotonAgregaUsuario">Agregar +</span>
                  </div>
                </button>
              </Box>
            </li>
          ))}
        </ul>
      </Box>
    );
    return <div>{sidebar}</div>;
  }

  function UserAsignadoPuesto() {
    const eliminaSeleccionada = (objeto: any) => {
      const agregaSeleccionadas = props.disponibles.filter(
        (lis: any) => lis?._id === objeto._id
      );
      agregaSeleccionadas.forEach((lis: any) => {
        setEstadoInicial([...estadoInicial, lis]);
        setFiltrado([...filtrado, lis]);
      });
      const eliminaDisponibles = seleccionados.filter(
        (lis: any) => lis?._id !== objeto._id
      );
      setSeleccionados(eliminaDisponibles);
    };

    const sidebar = (
      <Box className="contenedorUsuariosPuesto">
        <ul className="ulLista">
          {seleccionados?.map((lis: any) => (
            <li key={lis._id} className="contenedorListaUsuariosPuesto">
              <div className="alineacionPuestoUsuario">
                <div className="contenedorListaUsuarioBoton">
                  <div>
                    {lis.ImagenUsuario === null ? (
                      <ImagenColaborador
                        Nombre={lis.NombreUsuario}
                        Apellido={lis.ApellidoUsuario}
                      />
                    ) : (
                      <img
                        src={lis.ImagenUsuario}
                        alt="img"
                        className="imgCollaborator__BITSss"
                      />
                    )}
                  </div>
                  <div>
                    <div className="nombrePuesto">
                      <span className="textoListaUsuario">
                        {lis.PrimerNombre} {lis.ApellidoUsuario}
                      </span>
                    </div>
                    <div className="nombrePuesto">
                      <span className="correoListaUsuario">{lis.Usuario}</span>
                    </div>
                  </div>
                </div>
                <Box className="iconoEliminaUsuario">
                  <button className="iconoEliminaUsuarioIMG"
                    onClick={() =>
                      eliminaSeleccionada({
                        _id: lis._id,
                      })
                    }
                  >
                    <img src="/assets/icons/eliminar-colaborador.svg" alt="" />
                  </button>
                </Box>
              </div>
            </li>
          ))}
        </ul>
      </Box>
    );
    return <div>{sidebar}</div>;
  }

  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={addPuestoOpen}
        fullWidth={true}
        maxWidth={"md"}
      >
        <div className="contenedorTituloModale">
          <p className="titulo">Crear Puesto</p>
        </div>

        <DialogContent className="dialogoContento">
          <Grid item xs={6} className="ColumnaEditarPuesto">
            <Grid className="titulos">
              <span>Información general del puesto</span>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={3}>
                <div className="espacioTitulos">
                  <span>Nombre Puesto: </span>
                </div>
              </Grid>
              <Grid item xs={9}>
                <div>
                  <TextField
                    error={false}
                    type="text"
                    variant="outlined"
                    size="small"
                    className="Mask"
                    value={NombrePuesto}
                    onChange={({ target }) =>
                      onChange(target.value as string, "NombrePuesto")
                    }
                    inputProps={{ maxLength: 50 }}
                  />
                </div>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={3}>
                <div className="espacioTitulos">
                  <span>Área: </span>
                </div>
              </Grid>
              <Grid item xs={9}>
                <div className="">
                  <Select
                    native
                    value={AreaProyecto}
                    onChange={({ target }) =>
                      onChange(target.value as string, "AreaProyecto")
                    }
                    input={<OutlinedInput />}
                    className="inputSelect"
                  >
                    <option value="" className="optionSelect">
                      Selecciona Area/Proyecto
                    </option>
                    {proyectos?.map((proyectos: any) => (
                      <option
                        className="optionSelect"
                        key={proyectos.id}
                        value={proyectos.id}
                      >
                        {proyectos.proyectNameAndClient}
                      </option>
                    ))}
                  </Select>
                </div>
              </Grid>
            </Grid>

            <Grid container className="filaInput" spacing={2}>
              <Grid item xs={3}>
                <div className="espacioTitulos">
                  <span>Puesto superior: </span>
                </div>
              </Grid>
              <Grid item xs={9}>
                <div>
                  <Select
                    native
                    input={<OutlinedInput />}
                    className="inputSelect"
                    onChange={({ target }) =>
                      onChange(target.value as string, "PuestoSuperior")
                    }
                  >
                    <option value="" className="optionSelect">
                      Busca o elige un puesto
                    </option>
                    {Superior?.map((superior_) => (
                      <option
                        className="optionSelect"
                        key={superior_?.idUsuario}
                        id={superior_?.idUsuario}
                        value={superior_?.idPuesto}
                      >
                        {superior_?.NombrePuesto +
                          (superior_?.NombreUsuarioPuestoSuperior ? " - " : '') +
                          (superior_?.NombreUsuarioPuestoSuperior ?? '')}
                      </option>
                    ))}
                  </Select>
                </div>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <div className="espacioTitulos">
                  <span className="tituloDescripcion">Descripción:</span>
                </div>
              </Grid>
              <Grid item xs={9}>
                <div className="espacioTitulosTextarea">
                  <TextField
                    error={false}
                    type="text"
                    variant="outlined"
                    multiline
                    rows={4}
                    size="small"
                    value={Descripcion}
                    className="espacioTextArea"
                    placeholder="Escribe lo que se debe realizar en el puesto..."
                    onChange={({ target }) =>
                      onChange(target.value as string, "Descripcion")
                    }
                    inputProps={{ maxLength: 100 }}
                  />
                </div>
              </Grid>

              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <div className="espacioTitulosEdit">
                    <span>Colaborador:</span>
                  </div>
                </Grid>
                <Grid item xs={9}>
                  <UserAsignadoPuesto />
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={6} className="ColumnaEditarPuesto">
            <Grid className="titulos">
              <span>¿Deseas agregar un colaborador al puesto?</span>
            </Grid>

            <Grid className="titulos">
              <input
                type="text"
                placeholder="Buscar Colaborador"
                className="pc_inputBuscador"
                onChange={(e) => datosFiltrados(e.target.value)}
              ></input>
            </Grid>

            <Grid className="titulos">
              <span className="titulos">Colaborador sin puesto asignado</span>
            </Grid>
            <UserPuestoLibre />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} className="buttonCancel">
            Cancelar
          </Button>

          {props.save ?
            <Button autoFocus onClick={() => {
              props.setSave(false)
              handleAdd()
            }} className="buttonSavePuesto">
              Guardar
            </Button>
            :
            <Button autoFocus className="buttonSavePuesto"
              style={{
                opacity: "0.5"
              }}
            >
              Guardar
            </Button>
          }
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CrearPuestoModal;
