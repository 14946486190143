import moment from "moment";
moment.locale('es-mx');

const FormatDate = ({ FechaBaja }: any) => {
    return (
        <span>
            {moment.utc(FechaBaja).format('L')}
        </span>
    )
}

export default FormatDate;




