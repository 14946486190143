import { ChangeEvent, useState, useEffect } from "react";
import Box from '@mui/material/Box';
import { Grid } from "@material-ui/core";
import CustomTabs from "../components/Collaborators/Tab/CustomTabs";
import CustomTab from "../components/Collaborators/Tab/CustomTabMain";
import "../components/Collaborators/collaborator.css";
import "../components/PaymentsCentral/PaymentsCentral.css";
import PaymentsCentralTransfers from "../components/PaymentsCentral/PaymentsCentralTransaction";
import PaymentsCentralHistory from "../components/PaymentsCentral/PaymentsCentralHistory";

const PaymentsCentral = () => {
  const [tab, setTab] = useState(0);

  const handleChange = (event: ChangeEvent<{}>, newValue: number) => {
    setTab(newValue);
    localStorage.setItem("currentPill", String(newValue));
  };

  useEffect(() => {
    let pill = localStorage.getItem("currentPill");
    if (pill) setTab(Number(pill));
    else setTab(0);
  }, [tab]);


  return (
    <div>
      <Box mt={3} ml={5} className="Title">
        Central de Pagos
      </Box>
      <Box p={5} pb={3} pt={0}>
        <Grid container justify="flex-start">
          <CustomTabs
            value={tab}
            onChange={handleChange}
            aria-label="Payments Central Menu"
          >
            <CustomTab label="Transferencias" value={0} />
            <CustomTab label="Históricas" value={1} />
          </CustomTabs>
        </Grid>
      </Box>
      <div className="contenedor2">
        {tab === 0 && <PaymentsCentralTransfers />}
        {tab === 1 && <PaymentsCentralHistory />}
      </div>
    </div>
  );
};

export default PaymentsCentral;
