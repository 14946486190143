import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export default function Incidents({labels,data1,data2}:any) {
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "bottom" as const,
      },
    },
    interaction: {
      intersect: false,
    },
    scales: {
      x: {
        display: true,
        title: {
          display: true,
        },
      },
      y: {
        display: true,
        title: {
          display: true,
        },
        suggestedMin: 0
      },
    },
  };



  const data = {
    labels,
    datasets: [
      {
        label: "Deducciones",
        data: data1,
        borderColor: "#FABB00",
        fill: false,
        tension: 0.4,
      },
      {
        label: "Percepciones",
        data: data2,
        borderColor: "#2186C6",
        fill: false,
        tension: 0.4,
      },
    ],
  };

  return <Line type="line" data={data} options={options} />;
}