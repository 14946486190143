import { Theme, Dialog, withStyles, Select } from "@material-ui/core";
import "../Modal/CrearPayrollGroup.css";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import { useEffect, useContext } from "react";
import { SuccessfulAlert } from "../../../alerts/successAlerts";
import PerceptionContext from "../../../context/ConfigPayrollContext/PerceptionContext";
import { PerceptionModal } from "../../../interfaces/TabPerceptions.interfaces";
import { useForm } from "../../../hooks/useForm";
import SaveIcon from "@material-ui/icons/Save";
import { ErrorAlert } from "../../../alerts/errorAlert";
import { TabPerceptionContext } from "../../../context/ConfigPayrollContext/TabPerceptionContext";
import "../PayrollGroup.css";
import "./ModalPerceptions.css";
import IOSSwitch from "../../ConfigPayroll/Switch";

import { useMutation } from "@apollo/client";
import {
  GET_ALL_PERCEPTIONS,
  CREATE_PERCEPTIONS,
  GET_ALL_PERCEPTIONS_CALENDAR
} from "../../../Querys/querys";

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const CreaPerception = (props: any) => {
  const [createNewPerceptions] = useMutation(CREATE_PERCEPTIONS, {
    refetchQueries: [
                      { query: GET_ALL_PERCEPTIONS },
                      { query: GET_ALL_PERCEPTIONS_CALENDAR }
                    ],
  });

  const { state, dispatch } = useContext(PerceptionContext);

  const { addPerceptionOpen, setAddPerceptionOpen } = useContext(TabPerceptionContext);

  const {
    ConceptName,
    SATKey,
    ConceptType,
    AccountingAccount,
    PayType,
    ISRTax,
    ISNTax,
    SocialSecurity,
    IntegratesIMSS,
    TaxBoth,
    onChange,
    formulario,
    Classification,
    muestraCalendario,
    reset,
  } = useForm<PerceptionModal>({
    ConceptName: "",
    SATKey: "016",
    ConceptType: "",
    AccountingAccount: "",
    PayType: "",
    ISRTax: false,
    ISNTax: "",
    SocialSecurity: "",
    TaxBoth: false,
    IntegratesIMSS: "",
    Classification: "Dinero",
    muestraCalendario: false
  });

  useEffect(() => {
    async function fetchData() {
    }
    fetchData();
    return () => {
      fetchData();
    };
  }, [state]);

  const onSumbit = async () => {

    if (
      formulario.ConceptName.length > 100 ||
      formulario.ConceptType.length > 100 ||
      formulario.AccountingAccount.length > 100 ||
      formulario.PayType.length > 100
    ) {
      return ErrorAlert({ text: "Tienes datos con más de 100 caracteres." });
    }

    if (formulario.ConceptName !== "") {
      
      createNewPerceptions({
        variables: {
          input: {
            ConceptName: formulario.ConceptName,
            SATKey: formulario.SATKey,
            AccuntingAccount: formulario.AccountingAccount,
            PayType: formulario.PayType,
            gravaISR: state.gravaISR === undefined? false: state.gravaISR,
            Classification: formulario.Classification,
            muestraCalendario: state.muestraCalendario === undefined? false: state.muestraCalendario,
          },
        },
      }).then((res) => {
        SuccessfulAlert({
          title: "¡Éxito!",
          text: "¡Se ha creado la percepción correctamente!",
        });

        dispatch({ type: "CLOSE_PERCEPTION_MODAL" });
        setAddPerceptionOpen();
        reset();
      })

      
    } else {
      return ErrorAlert({ text: "Ingrese todos los datos." });
    }
  };

  const handleClose = async () => {
    dispatch({ type: "CLOSE_PERCEPTION_MODAL" });
    setAddPerceptionOpen();
  };

  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={addPerceptionOpen}
        fullWidth={false}
        maxWidth={"md"}
      >
        <div className="contenedorModalPerceptions">
          <p className="tituloModalPerceptions">Crea tu percepción</p>
        </div>
        <DialogContent className="ml32ModalPerceptions mr32ModalPerceptions">
          <div className="flexModalPerceptions mb24ModalPerceptions">
            <div>
              <span className="textoModal">Nombre</span>
            </div>
            <div>
              <input
                name="ConceptName"
                type="text"
                className="Mask"
                placeholder="Concepto *"
                defaultValue={ConceptName}
                maxLength={100}
                onChange={({ target }) =>
                  onChange(target.value as string, "ConceptName")
                }
              />
            </div>
          </div>
          <div className="flexModalPerceptions mb24ModalPerceptions">
            <div>
              <span className="textoModal">Clave SAT</span>
            </div>
            <div>
              <input
                name="SATKey"
                type="text"
                className="Mask"
                placeholder="Clave SAT *"
                defaultValue={SATKey}
                readOnly
                disabled
                onChange={({ target }) =>
                  onChange(target.value as string, "SATKey")
                }
              />
            </div>
          </div>
          <div className="flexModalPerceptions mb24ModalPerceptions">
            <div>
              <span className="textoModal">Cuenta contable</span>
            </div>
            <div>
              <input
                name="AccountingAccount"
                type="text"
                className="Mask"
                defaultValue={AccountingAccount}
                maxLength={100}
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                onChange={({ target }) =>
                  onChange(target.value as string, "AccountingAccount")
                }
              />
            </div>
          </div>
          <div className="flexModalPerceptions mb24ModalPerceptions">
            <div>
              <span className="textoModal">Tipo de pago</span>
            </div>
            <div
            style={{
              width: "40%",
            }}
            >
              <Select
                style={{
                  textOverflow: "Roboto" ,
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                }}
                  native
                  name = "PayType"
                  variant="outlined"
                  value={PayType}
                  onChange={({ target }) =>
                    onChange(target.value as string, "PayType")
                  }
              >
                <option
                  value="Selecciona una de las opciones"
                  selected
                  disabled
                >
                  Selecciona una de las opciones
                </option>
                <option value="Transferencia">Transferencia</option>
                <option value="Tarjeta empresarial">Tarjeta empresarial</option>
                <option value="No aplica">No aplica</option>
              </Select>
            </div>
          </div>
          <div className="flexModalPerceptions mb24ModalPerceptions">
            <div>
              <span className="textoModal">Grava ISR</span>
            </div>
            <div className="mr10ModalPerceptions">
              <IOSSwitch NombreCampo={"gravaISR"} Value={state.gravaISR} />
            </div>
          </div>
          <div className="flexModalPerceptions mb24ModalPerceptions">
            <div>
              <span className="textoModal">Clasificación</span>
            </div>
            <div
            style={{
              width: "40%",
            }}
            >
              <Select
                  style={{
                    textOverflow: "Roboto" ,
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                  }}
                  native
                  variant="outlined"
                  className="textoModal"
                  name = "Classification"
                  value={Classification}
                  onChange={({ target }) =>
                    onChange(target.value as string, "Classification")
                  }
              >
                <option value="Dinero">Dinero</option>
                <option value="Tiempo">Tiempo</option>
                
              </Select>
            </div>
          </div>
          <div className="flexModalPerceptions mb24ModalPerceptions">
            <div>
              <span className="textoModal">Muestra Calendario</span>
            </div>
            <div className="mr10ModalPerceptions">
              <IOSSwitch NombreCampo={"muestraCalendario"} Value={state.muestraCalendario} />
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <div className="contenedorBotonesPoliticsModal">
            <button
              autoFocus
              onClick={handleClose}
              className="botonbotonCancelar"
            >
              Cancelar
            </button>
            <button
              autoFocus
              onClick={onSumbit}
              className="botonbotonGuardar botonDoble"
            >
              <div className="contenedorIconoBotonModal">
                <SaveIcon fontSize="small" />
              </div>
              <div>Guardar</div>
            </button>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CreaPerception;
