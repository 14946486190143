import {useState, useContext} from "react";
import { Dialog } from "@material-ui/core"
import styles from '../../Payroll/Modals/PayrollModals.module.css'
import CentralPagosContext from "../../../context/CentralPagos/CentralPagosContext";
import { clearDispersar } from "../../../context/CentralPagos/Actions";
import { useMutation } from "@apollo/client";
import { ELIMINA_TRANSACTION, GET_TRANSACTION } from "../../../Querys/querys";

 

const EliminarCentralPagos = (props: any) =>{
  const {state, dispatch} = useContext(CentralPagosContext)  
  const handleClose = () =>{
    clearDispersar({}, dispatch)
  }

  const [eliminaTransaction] = useMutation(ELIMINA_TRANSACTION,{
    refetchQueries:[
      {query: GET_TRANSACTION, variables:{ getTransactionsId: props.empresa}}
    ]
  })

  const [activo, setactivo] = useState(false)
  const handleChange = (e: any) =>{ 
      if(e.target.value === 'ELIMINAR'){
          setactivo(true)
      }else{
          setactivo(false)
      }
  }

  const handleDelete = async () =>{
      try{
          eliminaTransaction({
              variables: {
                eliminaTransactionId: state._id,
              },
            });
              // await inactivePayrollGroup(state._id);
            handleClose()
          setactivo(false)
          await props.getDatos();
      }
      catch{

      }
  }



  return(
    <Dialog aria-labelledby='costumized-dialog-title' open={state.openEliminarRegistro} fullWidth={false}  onClose={handleClose} maxWidth={"md"}>
        <div className={styles.em_container}>
          <div className={styles.contenedorCerrarModal}>
            <div 
              className={styles.cerrarModal}
              onClick={()=> handleClose()}>
            </div>
          </div>
          <div className={styles.contenedorPrincipal}>
            <div className={styles.iconoEliminar}>
            </div>
            <div className={styles.em_titulo}>
              ¿Est&aacute;s seguro de que quieres eliminar el grupo de n&oacute;mina?
            </div>
            <div className={styles.em_mensajePrtincipal}>
                <span>Una vez eliminado no podr&aacute;s recuperar la informaci&oacute;n</span>
                <br />
                <span className={styles.em_textoPrincipal}>Escribe ELIMINAR para confirmar</span>
            </div>
            <div>
              <input 
                className={styles.input}
                type="text"
                onChange={(e) => handleChange(e)}
              />
            </div>
          </div>
        </div>
        <div className={styles.em_contenedorBotones}>
              <button 
                className={styles.em_botonCancelar}
                onClick={()=> handleClose()}
              >
                Cancelar
              </button>
              {(activo === true ? 
                <button 
                  className={styles.em_botonConfirmarActivo}
                  onClick={()=> handleDelete()}
                  >
                  <div className={styles.em_iconoConfirmarEliminar}></div>
                    <div>
                      Confirmar
                    </div>
                </button>
              :
                <button className={styles.em_botonConfirmarInactivo}>
                  <div className={styles.em_iconoConfirmarEliminar}></div>
                    <div>
                      Confirmar
                    </div>
                </button>
              )}

            </div>
        </Dialog>
  );
};


export default EliminarCentralPagos