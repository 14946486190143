
const Excel = require("exceljs");

export const CreateExcelFilePayrollEv = (data: any) => {
  const workbook = new Excel.Workbook();
  const ReporteCalculoE= workbook.addWorksheet("Reporte Caclulo");

  const width = 45;

  const header = {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: "002060" },
  };
  const headerFont = {
    name: "Arial",
    color: { argb: "FFFFFF" },
    family: 2,
    size: 11,
    bold: true,
  };

  ReporteCalculoE.columns = [
    { header: "ID", key: "Id", width },
    { header: "ID COLABORADOR", key: "id_Colaborator", width },
    { header: "COLABORADOR", key: "colaborador", width },
    { header: "AREA/PROYECTO", key: "area", width },
    { header: "PERCEPCION", key: "percepsion", width },
    { header: "DEDUCCION", key: "deduccion", width },
    { header: "TOTAL", key: "total", width },
    { header: "ID DISPERSION", key: "IdDispersion", width },
    { header: "ESTATUS DISPERSION", key: "EstatusD", width },
    { header: "SD", key: "sd", width },
    { header: "INIT_DATEPTU", key: "InitDateP", width },
    { header: "END_DATEPTU", key: "EndDateP", width },
    { header: "DIAS LABORALES PTU", key: "DiasLaboralesP", width },
    { header: "INGRESO ANUAL PTU", key: "IngresoAnualP", width },
    { header: "MONTO INGRESO PTU", key: "MontoIngresoP", width },
    { header: "DIAS INGRESO PTU", key: "DiasIngresoP", width },
    { header: "TOTAL DEAL PTU", key: "TotalDealP", width },
    { header: "NO CONTRIBUSIONES", key: "NoContribusiones", width },
    { header: "PORCENTAJE CONTRIBUSIONES", key: "PorcentajeC", width },
    { header: "WORKER CONTRIBUTIONS", key: "WorkerC", width },
    { header: "EMPLOYER CONTRIBUTIONS", key: "EmployerC", width },
    { header: "AGUINALDO PERIODO", key: "AguinaldoP", width },
    { header: "ANTIGUEDAD PRIMA VACACIONAL", key: "AntiguedadPV", width },
    { header: "VACACIONES PRIMA VACACIONAL", key: "VacacionesPV", width },
    { header: "TASA PRIMA VACACIONAL", key: "TasaPV", width },
    { header: "DIAS DE AGUINALDO", key: "diasA", width },
  ];

  let letter = 65;
  ReporteCalculoE.columns.forEach(() => {
    ReporteCalculoE.getCell(`${String.fromCharCode(letter)}1`).fill = header;
    ReporteCalculoE.getCell(`${String.fromCharCode(letter)}1`).font = headerFont;
    ReporteCalculoE.getCell(`${String.fromCharCode(letter)}1`).alignment = {
      horizontal: "center",
    };
    letter++;
  });

  data.forEach((item: any) => {

    ReporteCalculoE.addRow({

      Id: item.id,
      id_Colaborator: item.id_Collaborator,
      colaborador: item.colaborator,
      area: item.project,
      percepsion: item.perception,
      deduccion: item.deduction,
      total: item.total,
      IdDispersion: item.idDispersion,
      EstatusD: item.dispersionStatus,
      sd: item.SD,
      InitDateP: item.init_datePTU,
      EndDateP: item.end_datePTU,
      DiasLaboralesP: item.workingDaysPTU,
      IngresoAnualP: item.AnnualIncomePTU,
      MontoIngresoP: item.IncomeAmountPTU,
      DiasIngresoP: item.IncomeDaysPTU,
      TotalDealP: item.TotalDealPTU,
      NoContribusiones: item.NoContributions,
      PorcentajeC: item.PercentageContributions,
      WorkerC: item.WorkerContributions,
      EmployerC: item.EmployerContributions,
      AguinaldoP: item.AguinaldoPeriod,
      AntiguedadPV: item.AntiguedadPrimaVacacional,
      VacacionesPV: item.VacacionesPrimaVacacional,
      TasaPV: item.TasaPrimaVacacional,
      diasA: item.AguinaldoDays,
    });
  });

  workbook.xlsx.writeBuffer().then(function (data: Blob) {
    const blob = new Blob([data],
      { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const url = window.URL.createObjectURL(blob);
    const anchor = document.createElement('a');
    anchor.href = url;
    anchor.download = 'ReporteCalculoEventual.xlsx';
    anchor.click();
    window.URL.revokeObjectURL(url);
  });
};


export const CreateExcelFilePayrollAguinaldo = (data: any) => {
  const workbook = new Excel.Workbook();
  const ReporteCalculoE= workbook.addWorksheet("Reporte Aguinaldo");

  const width = 45;

  const header = {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: "002060" },
  };
  const headerFont = {
    name: "Arial",
    color: { argb: "FFFFFF" },
    family: 2,
    size: 11,
    bold: true,
  };

  ReporteCalculoE.columns = [
    { header: "COLABORADOR", key: "colaborador", width },
    { header: "FECHA ALTA", key: "fechaAlta", width },
    { header: "DIAS LABORADOS", key: "DiasLaborados", width },
    { header: "DIAS AGUINALDO", key: "AguinaldoDays", width },
    { header: "FACTOR AGUINALDO", key: "FactorAguinaldo", width },
    { header: "SD COMPLETO", key: "SD", width },
    { header: "TOTAL A RECIBIR", key: "netIncomeAguinaldo", width },
    { header: "SDG", key: "SDG", width },
    { header: "AGUINALDO GRAVABLE", key: "perception", width },
    { header: "EXCENTO", key: "Excento", width },
    { header: "GRAVADO", key: "Gravado", width },
    { header: "RETENCION IMPUESTO", key: "deduction", width },
    { header: "NETO GRAVABLE", key: "AguinaldoGravable", width },
    { header: "NETO NO GRAVABLE", key: "AguinaldoNoGravable", width }
  ];

  let letter = 65;
  ReporteCalculoE.columns.forEach(() => {
    ReporteCalculoE.getCell(`${String.fromCharCode(letter)}1`).fill = header;
    ReporteCalculoE.getCell(`${String.fromCharCode(letter)}1`).font = headerFont;
    ReporteCalculoE.getCell(`${String.fromCharCode(letter)}1`).alignment = {
      horizontal: "center",
    };
    letter++;
  });

  data.forEach((item: any) => {

    ReporteCalculoE.addRow({

      colaborador: item.colaborator,
      fechaAlta: item.fechaAlta,
      DiasLaborados: item.DiasLaborados,
      AguinaldoDays: item.AguinaldoDays,
      FactorAguinaldo: item.FactorAguinaldo,
      SD: item.SD,
      netIncomeAguinaldo: item.netIncomeAguinaldo,
      SDG: item.SDG,
      perception: item.perception,
      Excento: item.Excento,
      Gravado: item.Gravado,
      deduction: item.deduction,
      AguinaldoGravable: item.AguinaldoGravable,
      AguinaldoNoGravable: item.AguinaldoNoGravable
    });
  });

  workbook.xlsx.writeBuffer().then(function (data: Blob) {
    const blob = new Blob([data],
      { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const url = window.URL.createObjectURL(blob);
    const anchor = document.createElement('a');
    anchor.href = url;
    anchor.download = 'ReporteCalculoAguinaldo.xlsx';
    anchor.click();
    window.URL.revokeObjectURL(url);
  });
};


export const CreateExcelFilePayrollPrimaVacacional = (data: any) => {
  const workbook = new Excel.Workbook();
  const ReporteCalculoE= workbook.addWorksheet("Reporte Prima Vacacional");

  const width = 45;

  const header = {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: "002060" },
  };
  const headerFont = {
    name: "Arial",
    color: { argb: "FFFFFF" },
    family: 2,
    size: 11,
    bold: true,
  };

  ReporteCalculoE.columns = [
    { header: "COLABORADOR", key: "colaborador", width },
    { header: "FECHA INGRESO", key: "FechaIngreso", width },
    { header: "SALARIO DIARIO GRAVABLE", key: "SD", width },
    { header: "SALARIO DIARIO NO GRAVABLE", key: "SDNoGravablePrimaVacacional", width },
    { header: "ANTIGUEDAD", key: "AntiguedadPrimaVacacional", width },
    { header: "VACACIONES", key: "VacacionesPrimaVacacional", width },
    { header: "TASA PRIMA VACACIONAL", key: "TasaPrimaVacacional", width },
    { header: "PRIMA VACACIONAL", key: "perception", width },
    { header: "ISR", key: "deduction", width },
    { header: "TOTAL GRAVABLE", key: "total", width },
    { header: "TOTAL NO GRAVABLE", key: "TotalNoGravablePrimaVacacional", width }
  ];

  let letter = 65;
  ReporteCalculoE.columns.forEach(() => {
    ReporteCalculoE.getCell(`${String.fromCharCode(letter)}1`).fill = header;
    ReporteCalculoE.getCell(`${String.fromCharCode(letter)}1`).font = headerFont;
    ReporteCalculoE.getCell(`${String.fromCharCode(letter)}1`).alignment = {
      horizontal: "center",
    };
    letter++;
  });

  data.forEach((item: any) => {

    ReporteCalculoE.addRow({

      colaborador: item.colaborator,
      FechaIngreso: item.FechaIngreso,
      SD: item.SD,
      SDNoGravablePrimaVacacional: item.SDNoGravablePrimaVacacional,
      AntiguedadPrimaVacacional: item.AntiguedadPrimaVacacional,
      VacacionesPrimaVacacional: item.VacacionesPrimaVacacional,
      TasaPrimaVacacional: item.TasaPrimaVacacional,
      perception: item.perception,
      deduction: item.deduction,
      total: item.total,
      TotalNoGravablePrimaVacacional: item.TotalNoGravablePrimaVacacional
    });
  });

  workbook.xlsx.writeBuffer().then(function (data: Blob) {
    const blob = new Blob([data],
      { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const url = window.URL.createObjectURL(blob);
    const anchor = document.createElement('a');
    anchor.href = url;
    anchor.download = 'ReporteCalculoPrimaVacacional.xlsx';
    anchor.click();
    window.URL.revokeObjectURL(url);
  });
};



export const CreateExcelFilePayrollFondoAhorro = (data: any) => {
  const workbook = new Excel.Workbook();
  const ReporteCalculoE= workbook.addWorksheet("Reporte Fondo Ahorro");

  const width = 45;

  const header = {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: "002060" },
  };
  const headerFont = {
    name: "Arial",
    color: { argb: "FFFFFF" },
    family: 2,
    size: 11,
    bold: true,
  };

  ReporteCalculoE.columns = [
    { header: "COLABORADOR", key: "colaborador", width },
    { header: "FECHA INGRESO", key: "FechaIngreso", width },
    { header: "SALARIO DIARIO ACTUAL", key: "SD", width },
    { header: "NO. APORTACIONES", key: "NoContributions", width },
    { header: "PORCENTAJE APORTACIONES", key: "PercentageContributions", width },
    { header: "APORTACI�N TRABAJADOR", key: "WorkerContributions", width },
    { header: "APORTACI�N PATR�N", key: "EmployerContributions", width },
    { header: "TOTAL", key: "total", width },
  ];

  let letter = 65;
  ReporteCalculoE.columns.forEach(() => {
    ReporteCalculoE.getCell(`${String.fromCharCode(letter)}1`).fill = header;
    ReporteCalculoE.getCell(`${String.fromCharCode(letter)}1`).font = headerFont;
    ReporteCalculoE.getCell(`${String.fromCharCode(letter)}1`).alignment = {
      horizontal: "center",
    };
    letter++;
  });

  data.forEach((item: any) => {

    ReporteCalculoE.addRow({

      colaborador: item.colaborator,
      FechaIngreso: item.FechaIngreso,
      SD: item.SD,
      NoContributions: item.NoContributions,
      PercentageContributions: item.PercentageContributions,
      WorkerContributions: item.WorkerContributions,
      EmployerContributions: item.EmployerContributions,
      total: item.total,
    });
  });

  workbook.xlsx.writeBuffer().then(function (data: Blob) {
    const blob = new Blob([data],
      { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const url = window.URL.createObjectURL(blob);
    const anchor = document.createElement('a');
    anchor.href = url;
    anchor.download = 'ReporteCalculoFondoAhorro.xlsx';
    anchor.click();
    window.URL.revokeObjectURL(url);
  });
};




export const CreateExcelFileDesgloce = (data: any) => {
  const workbook = new Excel.Workbook();
  const ReporteCalculoE= workbook.addWorksheet("Reporte Fondo Ahorro");

  const width = 45;

  const header = {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: "002060" },
  };
  const headerFont = {
    name: "Arial",
    color: { argb: "FFFFFF" },
    family: 2,
    size: 11,
    bold: true,
  };

  ReporteCalculoE.columns = [
    { header: "COLABORADOR", key: "colaborador", width },
    { header: "FECHA INICIO", key: "fechaInicio", width },
    { header: "FECHA FIN", key: "fechaFin", width },
    { header: "TOTAL", key: "total", width },
  ];

  let letter = 65;
  ReporteCalculoE.columns.forEach(() => {
    ReporteCalculoE.getCell(`${String.fromCharCode(letter)}1`).fill = header;
    ReporteCalculoE.getCell(`${String.fromCharCode(letter)}1`).font = headerFont;
    ReporteCalculoE.getCell(`${String.fromCharCode(letter)}1`).alignment = {
      horizontal: "center",
    };
    letter++;
  });

  data.forEach((item: any) => {

    ReporteCalculoE.addRow({

      colaborador: item.colaborador,
      fechaInicio: item.fechaInicio,
      fechaFin: item.fechaFin,
      total: item.total
    });
  });

  workbook.xlsx.writeBuffer().then(function (data: Blob) {
    const blob = new Blob([data],
      { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const url = window.URL.createObjectURL(blob);
    const anchor = document.createElement('a');
    anchor.href = url;
    anchor.download = 'ReporteCalculodDesgloce.xlsx';
    anchor.click();
    window.URL.revokeObjectURL(url);
  });
};
