const Excel = require("exceljs");

export const CreateExcelReporteNomina = (data: any) => {
  const workbook = new Excel.Workbook();
  const ReporteNomina= workbook.addWorksheet("Reporte Nomina");

  const width = 40;

  const header = {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: "002060" },
  };
  const headerFont = {
    name: "Arial",
    color: { argb: "FFFFFF" },
    family: 2,
    size: 11,
    bold: true,
  };

  ReporteNomina.columns = [
    { header: "NOMBRE", key: "name", width },
    { header: "SALARIO NETO", key: "SalarioNeto", width },
    { header: "SALARIO DIARIO", key: "SalarioDiario", width },
    { header: "SALARIO DIARIO GRAVABLE", key: "SalarioDiarioGravable", width },
    { header: "DÍAS PERIODO", key: "DiasPeriodo", width },
    { header: "DÍAS TRABAJADOS", key: "DiasTrabajados", width },
    { header: "SALARIO BRUTO", key: "SalarioBruto", width },
    { header: "SUBSIDIO", key: "subsidio", width },
    { header: "ISR", key: "isr", width },
    { header: "IMSS", key: "imss", width },
    { header: "FONDO DE AHOROO", key: "FondoAhorro", width },
    { header: "INFONAVIT", key: "infonavit", width },
    { header: "INCIDENCIAS", key: "incidencias", width },
    { header: "NETO GRAVABLE", key: "NetoGravable", width },
    { header: "NETO NO GRAVABLE", key: "NetoNoGravable", width },
  ];

  let letter = 65;
  ReporteNomina.columns.forEach(() => {
    ReporteNomina.getCell(`${String.fromCharCode(letter)}1`).fill = header;
    ReporteNomina.getCell(`${String.fromCharCode(letter)}1`).font = headerFont;
    ReporteNomina.getCell(`${String.fromCharCode(letter)}1`).alignment = {
      horizontal: "center",
    };
    letter++;
  });

  data.forEach((item: any) => {

    ReporteNomina.addRow({
      name: item.colaborator,
      SalarioNeto: item.netIncome,
      SalarioDiario: item.DS,
      SalarioDiarioGravable: item.GDS,
      DiasPeriodo: item.periodDays,
      DiasTrabajados: item.workingDays,
      SalarioBruto: item.grossSalary,
      subsidio: item.Subsidy,
      isr: item.ISR,
      imss: item.IMSS,
      FondoAhorro: item.SavingsFund,
      infonavit: item.INFONAVIT,
      incidencias: item.incident,
      NetoGravable: item.netIncomeTaxable,
      NetoNoGravable: item.TotalIncomeNotTaxable
    });
  });

  workbook.xlsx.writeBuffer().then(function (data: Blob) {
    const blob = new Blob([data],
      { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const url = window.URL.createObjectURL(blob);
    const anchor = document.createElement('a');
    anchor.href = url;
    anchor.download = 'ReporteNomina.xlsx';
    anchor.click();
    window.URL.revokeObjectURL(url);
  });
};