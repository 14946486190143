import { Box, Grid } from "@mui/material";
import moment from "moment";
import { Event } from "../../../interfaces/TabCollaborator.interfaces";

function CollaboratorTimelineRow({event, color}:{event:Event, color: string}){
  return (
    <Box id="timeline-row">
      <Grid container alignItems={'center'}>
        <Grid item xs={4} md={3}>
          <Box className="date-container">
            <p className="date-label">{ moment(event.date).format('DD MMM').toUpperCase().replace('.','') }</p>
            <p className="hour-label">{ moment(event.date).format('hh:mm A') }</p>
          </Box>
        </Grid>
        <Grid item xs={8} md={9}>
          <Box className="description-container">
            <div className={`dot dot-${color}`}></div>
            <Box className="description-card">
                { event.event === 'Renovacion de contrato' ? ( 
                  <p className="description-title">
                    Renovaci&oacute;n de contrato
                  </p>
                ) : ( 

                  <p className="description-title">
                    { event.title }
                  </p>
                )}
              {
                event.event === 'Cambio de sueldo' && (
                  <>
                    <p className="description-text">De ${event.previousAmount.toLocaleString('en-US')  } a ${event.amount.toLocaleString('en-US')  }</p>
                    <p className="description-text">Aplica desde - {moment(event.date).format('DD/MM/YYYY')}</p>
                  </>
                )
              }
              {
                event.event === 'Cambio de puesto' && (
                  <>
                    <p className="description-text">De { event.previousPosition } a {event.currentPosition }</p>
                    <p className="description-text">Aplica desde - {moment(event.date).format('DD/MM/YYYY')}</p>
                  </>
                )
              }
              {
                event.event === 'Renovacion de contrato' && (
                  <>
                    <p className="description-text">De { moment(event.datePrevious).format('DD/MM/YYYY')  } a { moment(event.dateCurrent).format('DD/MM/YYYY') }</p>
                    <p className="description-text">Aplica desde - {moment(event.date).format('DD/MM/YYYY')}</p>
                  </>
                )
              }
              {
                (event.event === 'Incidencia')  && (
                  <>
                    <p className="description-text">Fecha - { moment(event.date).format('DD/MM/YYYY') }</p>
                  </>
                )
              }
              {
                (event.event === 'Alta')  && (
                  <>
                    <p className="description-text">Fecha de inicio - { moment(event.date).format('DD/MM/YYYY') }</p>
                  </>
                )
              }
              {
                (event.event === 'Baja')  && (
                  <>
                    <p className="description-text">Fecha de término - { moment(event.date).format('DD/MM/YYYY') }</p>
                  </>
                )
              }
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export default CollaboratorTimelineRow;