import { useEffect, useContext, useReducer, useState } from "react";
import "../Payroll/Payroll.css";
import { useToggle } from "../../hooks/useToggle";
import { TabPayrollContext } from "../../context/PayrollContext/TabPayrollContext";
import CrearIncidenciasDashboard from "../../components/Schedule/Modals/CrearIncidenciasDashboard";
import { tabPayrollReducer } from "../../context/PayrollContext/TabPayrollReducer";
import { createincidenciaModal } from "../../context/PayrollProcess/Actions";
import PayrollProcessContext from "../../context/PayrollProcess/PayrollProcessContext";
import AddIcon from "../../assets/svg/icono_agregar_blue.svg";

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
  sistemaActivo: boolean;
}

export const NewIncidence = (props: TabPanelProps) => {
  const { sistemaActivo } = props;
  const [PayrollState, PayrollDispatch] = useReducer(tabPayrollReducer, {
    loading: true,
    Payrolls: [],
    PayrollFilter: [],
  });
  const [inactiveOpen, setInactiveOpen] = useToggle(false);
  const [addPayrollOpen, setAddPayrollOpen] = useToggle(false);
  const { dispatch } = useContext(PayrollProcessContext);
  const [saveIncident, setSaveIncident] = useState(true);

  useEffect(() => {
    obtenerDatos();
  }, []);

  const obtenerDatos = async () => {};

  const openCreateIncidence = () => {
    createincidenciaModal({ id_: "", createIncidencia: true }, dispatch);
  };

  return (
    <div className="MsjNuevaPrenomina">
      <div className="Title">
        Tu colaboradores no tienen
        <p className="continueparagraph">ninguna incidencia activa</p>
      </div>

      <p>
        Puedes <b>agregar una incidencia</b> cuando lo requieras
      </p>
      {sistemaActivo? (
        <TabPayrollContext.Provider
          value={{
            inactiveOpen,
            setInactiveOpen,
            addPayrollOpen,
            setAddPayrollOpen,
            PayrollState,
            PayrollDispatch,
          }}
        >
          <button
            id="payroll_1"
            onClick={openCreateIncidence}
            className="botonNuevaPrenomina"
          >
            <div className="alineacionBoton">
              <img src={AddIcon} alt="Agregar" />
              <div className="nombrePuesto">
                <b>
                  <span>Agregar incidencias</span>
                </b>
              </div>
            </div>
          </button>
          <CrearIncidenciasDashboard saveIncident={saveIncident} setSaveIncident={setSaveIncident}/>
        </TabPayrollContext.Provider>
      ): null}
    </div>
  );
};
