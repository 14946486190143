import { Types } from "./EmpresaContext";

const EmpresaReducer = (state:any, action: any) =>{
  const {payload, type} = action
  switch(type){
    case Types.CREATE_EMPRESA:

      return {
        ...state,
        createEmpresa: true
      }
      
    case Types.CLEAR_EMPRESA:
      return{
        ...state,
        _id:'',
        updateSede: false,
        createEmpresa: false,
        createSede: false,
        nombreSede: '',
        deleteSede: false,
        createProject: false,
        updateProject: false,
        nombreProject: '',
        clienteProject: '',
        deleteProject: false,
        updateEmpresa: false,
        identidadLegal:payload.state.identidadLegal,
        certificadoLlaves:payload.state.certificadoLlaves,
        informacionBancaria: payload.state.informacionBancaria,
        registroPatronal: payload.registroPatronal,
        tabUno:false,
        tabDos:false,
        tabTres:false,
        deleteEmpresa: false
      }
      
    case Types.UPDATE_TAB_UNO:
      return{
        ...state,
        identidadLegal: payload.state.identidadLegal,
        tabUno: true
      }
      
       case Types.UPDATE_TAB_DOS:
        return{
          ...state,
          certificadoLlaves: payload.state.certificadoLlaves,
          tabDos: true
        }
        
    case Types.UPDATE_REGISTRO_PATRONAL:
      return{
        ...state,
        registroPatronal: payload.state
      }
      
      case Types.ADD_REGISTRO_PATRONAL:
        return{
          ...state,
          registroPatronal: payload.state.registroPatronal
        }
        
    case Types.UPDATE_TAB_TRES:
        return{
          ...state,
          informacionBancaria: payload.state.informacionBancaria
        }
        
    case Types.UPDATE_EMPRESA:
      return{
        ...state,
        _id: payload._id,
        updateEmpresa: true
      }
      
    case Types.CREATE_SEDE:
      return{
        ...state,
        createSede: true
      }
      
    case Types.UPDATE_SEDE:
      return{
        ...state,
        _id: payload._id,
        updateSede: true,
        nombreSede: payload.nombreSede
      }
    case Types.DELETE_SEDE:
      return{
        ...state,
        _id: payload._id,
        deleteSede: true
      }
      
    case Types.CREATE_PROJECT:
      return{
        ...state,
        createProject: true
      }
      
    case Types.UPDATE_PROJECT:
      return{
        ...state,
        _id: payload._id,
        updateProject: true,
        nombreProject: payload.nombreProject,
        clienteProject: payload.clienteProject
      }
      
    case Types.DELETE_PROJECT:
      return{
        ...state,
        _id: payload._id,
        deleteProject: true
      }
    case Types.DELETE_EMPRESA:
      return{
        ...state,
        _id: payload._id,
        deleteEmpresa: true
      }
      
    default:
      return state 
  }
}

export default EmpresaReducer

