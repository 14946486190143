import {Dialog, DialogContent, Grid, withStyles, Theme  } from "@material-ui/core"
import ContractsContext from '../../../context/ContractsContext/ContractsContext';
import { useContext, useEffect, useState} from 'react';
import {colseExpiredContracts} from '../../../context/ContractsContext/Actions';
import styles from '../Contracts.module.css'
import MuiDialogActions from "@material-ui/core/DialogActions";
import { useHistory } from "react-router-dom";
import {GET_CONTRACT_STATUS} from "../../../Querys/querys";
import { useLazyQuery } from "@apollo/client";
import moment from "moment";


const DialogActions = withStyles((theme: Theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

export const  ExpiredContracts = () => {
    const history = useHistory();

    const {state,dispatch} = useContext(ContractsContext)

    const returnRenew = (idCollaborator: any) => {
        localStorage.currentPill = 1;
        localStorage.nextStep = 2;
        localStorage.lastSection = 'contracts';
        history.push(`/collaborators/${idCollaborator}/1`, { from: "/contracts" });
    }

    const [getContracts, {data: resultContractStatus, startPolling, stopPolling}] = useLazyQuery(GET_CONTRACT_STATUS,{
        fetchPolicy: 'no-cache'
    });

    const [contracts, setContracts] = useState<any>([])
    
    useEffect(()=>{
        if(state.openExpiredContracts){
            getContracts();
        }else{
            stopPolling()
        }
    }, [state.openExpiredContracts]);
    
    useEffect(()=>{
        if(resultContractStatus && resultContractStatus.GET_CONTRACT_STATUS){
            setContracts(resultContractStatus.GET_CONTRACT_STATUS)
            startPolling(10000);
            return () =>{
                stopPolling()
            }
        }
    }, [resultContractStatus,startPolling,stopPolling]);


    return (
        <Dialog open={state.openExpiredContracts} aria-labelledby="form-dialog-title" maxWidth="lg" fullWidth={true}>
            <div className={styles.dialogContainer}>
                <div className={styles.containerTitle}>
                    <div className={styles.centerContainer}
                    style={{
                        marginLeft:'auto',
                        marginRight:'auto'
                    }}
                    >
                        <h2 id="form-dialog-title" data-testid="TitleModal">Contratos vencidos/por vencer</h2>
                        
                    </div>
                    <div className={styles.contenedorCerrarModal}>
                        <div className={styles.iconoCerrarModal} onClick={(()=>colseExpiredContracts({}, dispatch))}></div>      
                    </div> 
                </div>
                    
                <DialogContent className={styles.dialogoContento}>
                    
                    <>
                        <Grid item xs={12} className={styles.ColumnaEditarPuesto}>
                            <div className={styles.pc_contenedorTabla}>
                                <table className={styles.pc_tablaPrincipal}>
                                    <thead className={styles.pd_contenedorTitulos}>
                                        <td className={styles.pc_columnaTitulos}>
                                            <p style={{
                                                marginLeft: '30.61px'
                                            }}>Nombre</p>
                                        </td>
                                        <td className={styles.pc_columnaTitulos}>Fecha inicial</td>
                                        <td className={styles.pc_columnaTitulos}>Fecha final</td>
                                        <td className={styles.pc_columnaTitulos}>Estatus</td>
                                        <td className={styles.pc_columnaTitulos}>Días</td>
                                        <td className={styles.pc_columnaTitulos}></td>
                                    </thead>
                                    <tbody>
                                        {
                                            contracts?.map((item:any) => (
                                                <tr>
                                        
                                                    <td className={styles.pc_columnaDatos}>
                                                        <p style={{
                                                        marginLeft: '30.61px'
                                                        }}>{item?.Collaborator}</p>
                                                    </td>

                                                    <td className={styles.pc_columnaDatos}>
                                                    { item?.dateOfAdmission? moment(item?.dateOfAdmission).format('DD/MM/YYYY') : '' }
                                                    </td>

                                                    <td className={styles.pc_columnaDatos}  >
                                                        {item?.FechaBaja? moment(item?.FechaBaja).format('DD/MM/YYYY') : ''}
                                                    </td>

                                                    <td className={styles.pc_columnaDatos}>
                                                        {item?.contractStatus}
                                                    
                                                    </td>

                                                    <td className={styles.pc_columnaDatos}>
                                                        {item?.days}
                                                    
                                                    </td>

                                                    <td  className={styles.pc_columnaDatos}>
                                                        <div >
                                                            <div className={styles.containerOptions}>
                                                                <button className={styles.botonrRegresar} 
                                                                onClick={
                                                                    (()=>returnRenew(item?.idCollaborator))
                                                                }
                                                                >
                                                                    <p>Renovar</p>
                                                                </button>
                                                            </div>
                                                        </div>
                                                        
                                                    </td>

                                                </tr>

                                            ))
                                        }
            
                                            
                            
                                    </tbody>
                                </table>
                            </div>
                        </Grid>
    
                        
                    </>
                    
        
                </DialogContent>
                
                <DialogActions>
                    <button className={styles.botonCancelar} onClick={
                        (()=>colseExpiredContracts({}, dispatch))
                    }>
                        Cerrar
                    </button>

                </DialogActions>
            </div>
        </Dialog>
    )

}