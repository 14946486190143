import { ChangeEvent, useContext, useState, useEffect } from "react";
import {
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  Select,
  TextField,
  Button,
} from "@material-ui/core";
import Box from '@mui/material/Box';
import CollaboratorContext from "../../../../context/CollaboratorContext/CollaboratorContext";
import { handleSetActiveStep, updateData } from "../../../../helpers/Collaborator/Collaborator";
import { paisesEstados } from "../../../../helpers/Json/paises-estados";
import {
  getMunicipios,
  getStates,
} from "../../../../helpers/Json/getStatesAndMunicipios";
import moment from "moment";
import { WarningAlert } from "../../../../alerts/WarningAlert";
import { SuccessfulAlert } from "../../../../alerts/successAlerts";
import SaveIcon from "@material-ui/icons/Save";
import { useMutation } from "@apollo/client";
import { GET_USERS_BY_ID, UPDATE_USERS, GET_ALL_USERS_NUEVOINGRESO } from "../../../../Querys/querys";
import InputMask from "react-input-mask";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Form } from "semantic-ui-react";
import CheckPermission from "../../../../helpers/Administradores/Permissions";
import { validateCURPFormat } from "../../../../helpers/Collaborator/Fields";

const BeneficiarioNuevoIngreso = () => {
  const { state, dispatch } = useContext(CollaboratorContext);
  const [states, setState] = useState<Array<string>>([]);
  const [municipios, setMunicipios] = useState<Array<string>>([]);
  const [estado, setEstado] = useState<string>("");
  const [municipio, setMunicipio] = useState<string>("");
  const [benefitiaryCURP, setBenefitiaryCurp] = useState("");
  const [cpMask, setCPMask] = useState("");

  const [updateColaboradores] = useMutation(UPDATE_USERS, {
    refetchQueries: [
      {
        query: GET_USERS_BY_ID,
        variables: { getUsersId: state.collaborator.id },
      },
      {query: GET_ALL_USERS_NUEVOINGRESO}
    ],
  });

  const currentDate = moment();
  const validYears = 18;
  const isAdult = (birthday: string) => {
    if (birthday !== "") {
      return currentDate.year() - moment(birthday).year() >= validYears;
    } else {
      return "empty";
    }
  };

  useEffect(() => {
    if (state.collaborator?.benefitiaryCountry) {
      setState(getStates(state.collaborator?.benefitiaryCountry));
    }
    if (state.collaborator?.benefitiaryState) {
      setMunicipios(getMunicipios(state.collaborator?.benefitiaryState));
    }
    if (state.collaborator) {
      if (state.collaborator.benefitiaryMunicipality) {
        setMunicipio(state.collaborator.benefitiaryMunicipality);
      }
      if (state.collaborator.benefitiaryState) {
        setEstado(state.collaborator.benefitiaryState);
      }
    }
  }, [state.collaborator]);

  const handleChange = async (e: any) => {
    await updateData(e, state, dispatch, 0);
  };

  const handleChangeCurp = async (
    e: ChangeEvent<{ name: string; value: unknown }>
  ) => {
    await updateData(e, state, dispatch, 0);
    if (state.collaborator?.benefitiaryCURP !== undefined) {
      const re =
        /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/;
      //const validado = state.collaborator?.DatosPersonales?.CURP.toLocaleUpperCase().match(re);
      const validado = re.test(state.collaborator?.benefitiaryCURP);
      if (!validado) {
        return await WarningAlert({
          text: "El formato de tu CURP no es la correcta.",
        });
      } else {
      }
    }
  };

  const validDate = async (e: any) => {
    if (!isAdult(e.target.value)) {
      return WarningAlert({
        title: "¡Error!",
        text: `¡El beneficiario debe ser mayor a ${validYears} años!`,
      }).then(() => false);
    } else if (isAdult(e.target.value) === "empty") {
      return "";
    } else {
      await updateData(e, state, dispatch, 0);
    }
  };

  const onChangeCountry = async (e: any) => {
    setState(getStates(e.target.value));
    await updateData(e, state, dispatch, 0);
  };

  const onChangeState = (e: any) => {
    setMunicipio("");
    let value = e.target.value;
    setEstado(value);
    setMunicipios(getMunicipios(value));
  };

  const initialValues = () => {
    return {
      benefitiary: state.collaborator?.benefitiary ?? "",
      ParentescoB: state.collaborator?.ParentescoB ?? "",
      benefitiaryDateOfBirth: state.collaborator?.benefitiaryDateOfBirth ?? "",
      benefitiaryCURP: state.collaborator?.benefitiaryCURP ?? "",
      benefitiaryZC: state.collaborator?.benefitiaryZC ?? "",
      benefitiaryAddress: state.collaborator?.benefitiaryAddress ?? "",
      benefitiarySuburb: state.collaborator?.benefitiarySuburb ?? "",
      benefitiaryCountry: state.collaborator?.benefitiaryCountry ?? "",
      benefitiaryState: state.collaborator?.benefitiaryState ?? "",
      benefitiaryMunicipality:
        state.collaborator?.benefitiaryMunicipality ?? "",
    };
  };

  const formik = useFormik({
    initialValues: initialValues(),
    validationSchema: Yup.object(validationSchema()),

    onSubmit: async (formData) => {
      formData.benefitiaryCURP = formData.benefitiaryCURP.toUpperCase();
      updateColaboradores({
        variables: {
          updateUsersId: state.collaborator?.id,
          input: formData,
          process: {
            tab: "emergency",
            section: "section_1",
          },
        },
      }).then((res) => {
        SuccessfulAlert({ text: "Se actualizó correctamente" });
        handleSetActiveStep(1, dispatch);
      });
    },
  });

  return (
    <Form onSubmit={formik.handleSubmit}>
      <div className="novalidate__border">
        <Grid direction="row" container spacing={2}>
          <Grid xs item>
            <TextField
              type="text"
              name="benefitiary"
              defaultValue={state.collaborator?.benefitiary}
              label="Nombre del beneficiario del seguro"
              variant="outlined"
              size="small"
              fullWidth={true}
              onBlur={(e) => handleChange(e)}
              onChange={formik.handleChange}
              InputProps={{
                readOnly: false,
              }}
              error={formik.touched.benefitiary && Boolean(formik.errors.benefitiary)}
              helperText={formik.touched.benefitiary && formik.errors.benefitiary}
            />
          </Grid>
          <Grid xs item>
            <FormControl variant="outlined" fullWidth={true} size="small">
              <InputLabel htmlFor="outlined-age-native-simple">
                Parentesco
              </InputLabel>
              <Select
                native
                onBlur={(e) => handleChange(e)}
                label="Parentesco"
                name="ParentescoB"
                defaultValue={state.collaborator?.ParentescoB || ""}
                autoFocus={true}
                error={formik.touched.ParentescoB && Boolean(formik.errors.ParentescoB)}
                onChange={formik.handleChange}
              >
                <option
                  value={state.collaborator?.ParentescoB || ""}
                  disabled={true}
                >
                  {state.collaborator?.ParentescoB || ""}
                </option>
                <option value="Madre">Madre</option>
                <option value="Padre">Padre</option>
                <option value="Hijo(a)">Hijo(a)</option>
                <option value="Esposo(a)">Esposo(a)</option>
                <option value="Concubino(a)">Concubino(a)</option>
                <option value="Hermano(a)">Hermano(a)</option>
                <option value="Novio">Novio</option>
                <option value="Novia">Novia</option>
                <option value="Amigo(a)">Amigo(a)</option>
              </Select>
              <FormHelperText style={{color: "red"}}>
                {formik.touched.ParentescoB && formik.errors.ParentescoB}
              </FormHelperText>
            </FormControl>
          </Grid>
        </Grid>

        <Box mt={2}>
          <Grid
            direction="row"
            container
            justify="flex-start"
            alignItems="center"
          >
            <Grid xs item>
              <span className="Fecha-de-nacimiento">Fecha de nacimiento</span>
            </Grid>
            <Grid xs item container justify="flex-end" alignItems="center">
              {/* { (reCheck == "true" || reCheck == null) && */}
              <TextField
                type="date"
                defaultValue={
                  moment(state.collaborator?.benefitiaryDateOfBirth).format(
                    "YYYY-MM-DD"
                  ) ?? ""
                }
                name="benefitiaryDateOfBirth"
                variant="outlined"
                size="small"
                onBlur={(e) => validDate(e)}
                //onChange={(e) => validDate(e)}
                InputProps={{
                  readOnly: false,
                  inputProps: {
                    max: moment().format("YYYY-MM-DD"),
                  },
                }}
                error={formik.touched.benefitiaryDateOfBirth && Boolean(formik.errors.benefitiaryDateOfBirth)}
                helperText={
                  formik.touched.benefitiaryDateOfBirth &&
                  formik.errors.benefitiaryDateOfBirth
                }
                onChange={formik.handleChange}
              />
            </Grid>
          </Grid>
        </Box>

        <Box mt={2}>
          <Grid direction="row" container spacing={2}>
            <Grid xs item>
              <InputMask
                  mask="aaaa999999aaa*****"
                  defaultValue={state.collaborator?.benefitiaryCURP || benefitiaryCURP}
                  onChange={(e) => {
                    setBenefitiaryCurp(e.target.value);
                  }}
                  disabled={false}
                  onBlur={(e) => { handleChangeCurp(e); formik.handleChange(e)}}
              >
                <TextField
                  name="benefitiaryCURP"
                  label="CURP"
                  variant="outlined"
                  size="small"
                  fullWidth={true}
                  error={formik.touched.benefitiaryCURP && Boolean(formik.errors.benefitiaryCURP)}
                  helperText={
                    (formik.touched.benefitiaryCURP && formik.errors.benefitiaryCURP)
                  }
                />
              </InputMask>
            </Grid>
            <Grid xs item>
              {/* { (reCheck == "true" || reCheck == null) && */}
              <InputMask
                mask="99999"
                defaultValue={state.collaborator?.benefitiaryZC || cpMask}
                onChange={(e) => {
                  setCPMask(e.target.value);
                  handleChange(e);
                }}
                disabled={false}
                onBlur={formik.handleChange}
              >
                <TextField
                  name="benefitiaryZC"
                  label="Código postal"
                  variant="outlined"
                  size="small"
                  fullWidth={true}
                  error={formik.touched.benefitiaryZC && Boolean(formik.errors.benefitiaryZC)}
                  helperText={
                    formik.touched.benefitiaryZC &&
                    formik.errors.benefitiaryZC
                  }
                />
              </InputMask>
            </Grid>
          </Grid>
        </Box>

        <Box mt={2} mb={2}>
          {/* { (reCheck == "true" || reCheck == null) && */}
          <TextField
            name="benefitiaryAddress"
            defaultValue={state.collaborator?.benefitiaryAddress}
            autoFocus={true}
            label="Dirección, calle y número"
            variant="outlined"
            size="small"
            fullWidth={true}
            onBlur={(e) => handleChange(e)}
            InputProps={{
              readOnly: false,
            }}
            error={formik.touched.benefitiaryAddress && Boolean(formik.errors.benefitiaryAddress)}
            helperText={
              formik.touched.benefitiaryAddress &&
              formik.errors.benefitiaryAddress
            }
            onChange={formik.handleChange}
          />
        </Box>

        <Grid direction="row" container spacing={2}>
          <Grid xs item>
            {/* { (reCheck == "true" || reCheck == null) && */}
            <TextField
              name="benefitiarySuburb"
              defaultValue={state.collaborator?.benefitiarySuburb}
              autoFocus={true}
              label="Colonia"
              variant="outlined"
              size="small"
              fullWidth={true}
              onBlur={(e) => handleChange(e)}
              InputProps={{
                readOnly: false,
              }}
              error={formik.touched.benefitiarySuburb && Boolean(formik.errors.benefitiarySuburb)}
              helperText={
                formik.touched.benefitiarySuburb &&
                formik.errors.benefitiarySuburb
              }
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid xs item>
            {/* { (reCheck == "true" || reCheck == null) && */}
            <FormControl variant="outlined" fullWidth={true} size="small">
              <InputLabel htmlFor="outlined-age-native-simple">País</InputLabel>
              <Select
                native
                onChange={(e) => onChangeCountry(e)}
                label={"País"}
                name="benefitiaryCountry"
                defaultValue={state.collaborator?.benefitiaryCountry || ""}
                autoFocus={true}
                error={formik.touched.benefitiaryCountry && Boolean(formik.errors.benefitiaryCountry)}
                onBlur={formik.handleChange}
              >
                <option
                  value={state.collaborator?.benefitiaryCountry || ""}
                  disabled={true}
                >
                  {state.collaborator?.benefitiaryCountry || ""}
                </option>
                {paisesEstados.map(({ country }: any, index: number) => (
                  <option key={index} value={country}>
                    {country}
                  </option>
                ))}
              </Select>
              <FormHelperText style={{color: "red"}}>
                {formik.touched.benefitiaryCountry && formik.errors.benefitiaryCountry}
              </FormHelperText>
            </FormControl>
          </Grid>
        </Grid>

        <Box mt={2}>
          <Grid direction="row" container spacing={2}>
            <Grid xs item>
              {/* { (reCheck == "true" || reCheck == null) && */}
              <FormControl variant="outlined" fullWidth={true} size="small">
                <InputLabel htmlFor="outlined-age-native-simple">
                  Estado
                </InputLabel>
                <Select
                  native
                  onChange={(e) => onChangeState(e)}
                  onBlur={formik.handleChange}
                  label={"Estado"}
                  name="benefitiaryState"
                  // defaultValue={state.collaborator?.benefitiaryState || ''}
                  value={estado}
                  error={formik.touched.benefitiaryState && Boolean(formik.errors.benefitiaryState)}
                >
                  <option
                    value={state.collaborator?.benefitiaryState || ""}
                    disabled={true}
                  >
                    {state.collaborator?.benefitiaryState || ""}
                  </option>
                  {/* <option value={estado} disabled={true}>{estado}</option> */}
                  {states.map((state: string, index: number) => (
                    <option key={index} value={`${state}`}>
                      {state}
                    </option>
                  ))}
                </Select>
                <FormHelperText style={{color: "red"}}>
                  {formik.touched.benefitiaryState && formik.errors.benefitiaryState}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid xs item>
              {/* { (reCheck == "true" || reCheck == null) && */}
              <FormControl variant="outlined" fullWidth={true} size="small">
                <InputLabel htmlFor="outlined-age-native-simple">
                  Municipio
                </InputLabel>
                <Select
                  native
                  label={"Municipio"}
                  name="benefitiaryMunicipality"
                  value={municipio}
                  defaultValue={
                    state.collaborator?.benefitiaryMunicipality || ""
                  }
                  autoFocus={true}
                  onBlur={(e) => handleChange(e)}
                  error={formik.touched.benefitiaryMunicipality && Boolean(formik.errors.benefitiaryMunicipality)}
                  onChange={(e:any)=> { setMunicipio(e.target.value); formik.handleChange(e)}}
                >
                  <option
                    value={state.collaborator?.benefitiaryMunicipality || ""}
                    disabled={true}
                  >
                    {state.collaborator?.benefitiaryMunicipality || ""}
                  </option>
                  {municipios?.map((municipio: any, index: number) => (
                    <option key={index} value={municipio}>
                      {municipio}
                    </option>
                  ))}
                </Select>
                <FormHelperText style={{color: "red"}}>
                  {formik.touched.benefitiaryMunicipality && formik.errors.benefitiaryMunicipality}
                </FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
        </Box>
        
      </div>
      {CheckPermission(
        "Colaboradores.NuevoIngreso.Ver.SaludEmergencias.Beneficiario.Guardar"
      ) && (
        <Grid
          direction="row"
          container
          justify="flex-end"
          alignItems="center"
          style={{ marginTop: "20px" }}
        >
          <Button type="submit" className="buttonSave">
            <SaveIcon />
            &nbsp; Guardar
          </Button>
        </Grid>
      )}
    </Form>
  );
};

const validationSchema = () => {
  return {
    benefitiary: Yup.string().required("Obligatorio"),
    ParentescoB: Yup.string().required("Obligatorio"),
    benefitiaryDateOfBirth: Yup.string().required(
      "Obligatorio"
    ),
    benefitiaryCURP: Yup.string()
      .required("Obligatorio")
      .min(18, "CURP debe contener 18 caracteres")
      .max(18, "CURP no puede tener más de 18 caracteres")
      .test(
        "check_curp",
        "El formato del CURP no es correcto",
        function (benefitiaryCURP: string | undefined): boolean {
          return validateCURPFormat(benefitiaryCURP || '') ? true : false
        }
      ),
    benefitiaryZC: Yup.number()
      .required("Obligatorio")
      .min(5, "El código postal debe tener 5 dígitos"),
    benefitiaryAddress: Yup.string().required("Obligatorio"),
    benefitiarySuburb: Yup.string().required("Obligatorio"),
    benefitiaryCountry: Yup.string().required("Obligatorio"),
    benefitiaryState: Yup.string().required("Obligatorio"),
    benefitiaryMunicipality: Yup.string().test(
      "check_mun",
      "Obligatorio",
      function (benefitiaryMunicipality: string | undefined): boolean {
        return this.parent.benefitiaryCountry !== 'México' ? true : 
                this.parent.benefitiaryCountry === 'México' && benefitiaryMunicipality && benefitiaryMunicipality !== '' ? true : false
      }
    ),
  };
};

export default BeneficiarioNuevoIngreso;
