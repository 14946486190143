import { Dispatch } from "react";
import {Types} from "./ContractsContext";

export const openExpiredContracts = ({openExpiredContracts = true}:any, dispatch: Dispatch<any>):void =>{
    
    dispatch({
        type:Types.OPENEXPIREDCONTRACTS,
        payload:{
            openExpiredContracts
        } 
    })
}

export const colseExpiredContracts = ({openExpiredContracts = false}:any, dispatch: Dispatch<any>):void =>{
    
    dispatch({
        type:Types.CLOSEEXPIREDCONTRACTS,
        payload:{
            openExpiredContracts
        } 
    })
}