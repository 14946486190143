 import { useContext,useState } from "react";
 import PrestacionContext from '../../context/PrestacionesContext/PrestacionContext';
 import { createBenefitsModal } from "../../context/PrestacionesContext/Actions"; 
 import { Grid } from "@material-ui/core";
 import {Box} from '@mui/material';
 import BodyPrestacion from "./BodyPrestacion";
 import AddIcon from "../../assets/svg/icono_agregar_blue.svg";
 import Cuadricula from "../../assets/svg/Cuadricula.svg";
 import lista from "../../assets/svg/Lista.svg";
import CreateBenefits from "./modals/CreateBenefits";
import UpdateBenefits from "./modals/UpdateBenefits";
import "../Prestaciones/css/Prestaciones.css";

const PrestacionContent = () => {

  const {state, dispatch} = useContext(PrestacionContext);  
  const [viewSquare,setViewSquare]=useState(true);
  const [viewList,setViewList]=useState(false);
  const [infoImg, setInfoImg] = useState({});
  
  const handleViewSquare=()=>{
    setViewSquare(true)
    setViewList(false)
  }
  const handleViewList=()=>{
    setViewSquare(false)
    setViewList(true)
  }

  return (
  <>
  <div className="contenedorHead" >
    <Box mt={3} ml={5} className="Title" mb={2}>
      Prestaciones
    </Box>  
    <div  className="conteinderBtnsPrestaciones">
        <div   className="squareView" onClick={() => handleViewSquare()}  ><img src={Cuadricula} alt="Cuadricula"/> </div>
        <div   className="squareList"onClick={() => handleViewList()}>    <img src={lista} alt="Cuadricula"/> </div>
        <div>
        <button
          id="addPrestacion"
          onClick={() => createBenefitsModal(true, dispatch)}     
          className="btnNuevaPrestacion"
        >
          <div className="alineacionBoton">
            <img src={AddIcon} alt="Agregar Prestación" />
                  <div className="nombrePrestacion">
                    <b>
                      <span>Nueva Prestación</span>
                    </b>
                  </div>
                </div>
        </button>
        </div>
    </div>
  </div> 
  <CreateBenefits
    setInfoImg={setInfoImg}
  />
  {state._id !=='' &&
   <UpdateBenefits/> 
  }
  
      <Grid  className="containerBodyPrestacion">
        <BodyPrestacion
          infoImg={infoImg}             
          viewSquare={viewSquare}
          viewList={viewList}
        /> 
      </Grid>
  
  </>
  )
}

export default PrestacionContent