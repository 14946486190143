import IncidentPayrollTable from './IncidentPayrollTable';
import { useQuery } from '@apollo/client';
import { GET_ALL_PAYROLL_INCIDENTPERIODIC } from '../../Querys/querys';
import { useEffect, useState } from 'react';
import { NewIncidence } from './NewIncidence';

interface TabPanelProps{
  children?: React.ReactNode;
  index: any;
  value: any;
  sistemaActivo: boolean;
}

const IncidentTab  = (props:TabPanelProps) =>{
  const {data: resultPayroll} = useQuery(GET_ALL_PAYROLL_INCIDENTPERIODIC);
  const [allPayroll, setAllPayroll] = useState([]);

  useEffect(()=>{
    if(resultPayroll && resultPayroll.GET_ALL_PAYROLL_INCIDENTPERIODIC){
      setAllPayroll(  resultPayroll.GET_ALL_PAYROLL_INCIDENTPERIODIC );
    }
  }, [resultPayroll]);

  return(
    <>
      {
        allPayroll.length == 0 ? (
          <NewIncidence {...props}/>
        ) : (
          <IncidentPayrollTable {...props}/>
        )
      }
    </>
  )
}

export default IncidentTab