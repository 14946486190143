import {createContext, Dispatch} from 'react'

type InitialStateType = {
  _id: string,
  openDispersar: boolean,
  openSinRegistros: boolean,
  openEliminarRegistro: boolean,
  openSinFondos:boolean,
  openMensajeError: boolean
};

type ModalPartial = {
  state: InitialStateType,
  dispatch: Dispatch<any>,
}

export const initalState = {
  _id: '',
  openDispersar: false,
  openSinRegistros: false,
  openEliminarRegistro: false,
  openSinFondos: false,
  openMensajeError: false
}

export enum Types{
  OPEN_DISPERSAR = "OPEN_DISPERSAR",
  CLEAR_DISPERSAR = "CLEAR_DISPERAR",
  SIN_REGISTROS = "SIN_REGISTROS",
  ELIMINAR_REGISTRO = "ELIMINAR_REGISTRO",
  OPEN_SIN_FONDOS= "OPEN_SIN_FONDOS",
  OPEN_MENSAJE_ERROR= "OPEN_MENSAJE_ERROR"
}

const ModalInitialState = {
  state: initalState,
  dispatch: () => null
}

const CentralPagosContext = createContext<ModalPartial>(ModalInitialState);

export default CentralPagosContext