import axios from "axios";
import moment from "moment";
import { ErrorAlert } from "../alerts/errorAlert";
import { LoadingAlert, LoadingUser } from "../alerts/loadingAlerts";
import { SuccessfulAlert } from "../alerts/successAlerts";
import { WarningAlert } from "../alerts/WarningAlert";
import { validData } from "../helpers/Collaborator/validateInput";
const serverError = "Ocurrió un error con el servidor.";
const serverErrorMail = "Correo en proceso de enviarse.";

export const getCandidates = async () => {
  try {
    let result = await axios.get(`/candidates`);
    return result.data.data;
  } catch {
    return ErrorAlert({ text: serverError });
  }
};

export const getCandidate = async (
  id: string,
  band: boolean,
  token: string = ""
) => {
  try {
    let result: any = {};
    if (band) result = await axios.get(`/candidates/${id}`);
    else result = await axios.get(`/register/${token}`);
    return result?.data?.data;
  } catch {
    return ErrorAlert({ text: serverError });
  }
};

export const postCandidate = async (data: any) => {
  try {
    let band = await validData(data);
    if (band === true) {
      LoadingAlert({});
      let CartaOferta = data.Archivos.CartaOferta;
      delete data.Archivos;
      let result = await axios.post(`/candidates`, data);
      if (result.data.data) {
        const formData = new FormData();
        formData.append("CartaOferta", CartaOferta);
        result = await axios.post(
          `/candidates/${result.data.data._id}/${result.data.data.CurrentToken}`,
          formData,
          {
            headers: {
              "Content-Type": `multipart/form-data;boundary=${formData}`,
            },
          }
        );
        if (result.data.data) {
          return true;
        } else return false;
      } else {
        await ErrorAlert({ text: "El correo ya existe en la base de datos." });
        return WarningAlert({
          text: "Por motivos de seguridad corrige los cambios y vuelve a subir la Carta Oferta del nuevo ingreso",
        });
      }
    } else return ErrorAlert({ text: band });
  } catch (err) {
    return ErrorAlert({ text: serverError });
  }
};

export const putCandidate = async (data: any, emailSent: string = "no") => {
  if (typeof data.Archivos.CartaOferta === "object") {
    try {
      let band = await validData(data);
      if (band === true) {
        LoadingUser();
        let CartaOferta = data.Archivos.CartaOferta;
        let result = await axios.put(`/candidates/${data._id}`, {
          data,
          emailSent,
        });

        if (result.data.message === "ok") {
          const formData = new FormData();
          formData.append("CartaOferta", CartaOferta);
          result = await axios.post(
            `/candidates/${result.data.data._id}/${result.data.data.CurrentToken}`,
            formData,
            {
              headers: {
                "Content-Type": `multipart/form-data;boundary=${formData}`,
              },
            }
          );

          if (result.data.data) {
            return true;
          } else return false;
        } else {

          await ErrorAlert({
            text: "El correo ya existe en la base de datos.",
          });
          return WarningAlert({
            text: "Por motivos de seguridad corrige los cambios y vuelve a subir la Carta Oferta del nuevo ingreso",
          });
        }
      } else return ErrorAlert({ text: band });
    } catch (err) {

      return ErrorAlert({ text: serverError });
    }
  } else {
    try {
      let band = await validData(data);
      if (band === true) {
        LoadingUser();

        let result = await axios.put(`/candidates/${data._id}`, {
          data,
          emailSent,
        });

        if (result.data.data) {
          if (result.data.data.codeName) {
            if (result.data.data.codeName === "DuplicateKey") {
              await ErrorAlert({
                text: "El correo ya existe en la base de datos.",
              });
              return WarningAlert({
                text: "Por motivos de seguridad corrige los cambios y vuelve a subir la Carta Oferta del nuevo ingreso",
              });
            } else {
              await ErrorAlert({ text: "Ocurrio algun error con el servidor" });
              return WarningAlert({
                text: "Por motivos de seguridad corrige los cambios y vuelve a subir la Carta Oferta del nuevo ingreso",
              });
            }
          } else {
            return true;
          }
        }
      } else return ErrorAlert({ text: band });
    } catch (err: any) {

      if (err.codeName === "DuplicateKey") {
        await ErrorAlert({ text: "El correo ya existe en la base de datos." });
        return WarningAlert({
          text: "Por motivos de seguridad corrige los cambios y vuelve a subir la Carta Oferta del nuevo ingreso",
        });
      }
      return ErrorAlert({ text: serverError });
    }

  }
};

export const endRegister = async (data: any) => {
  try {
    let band = await validData(data);
    if (band === true) {
      LoadingUser();
      let result = await axios.put(`/register/end/register/${data._id}`, data);
      if (result.data.data) return true;
    } else return ErrorAlert({ text: band });
  } catch (err) {

    return ErrorAlert({ text: serverError });
  }
};

export const rejectInformation = async (token: string, Comentario: string) => {
  try {
    LoadingAlert({ title: "Enviando Comentario..." });
    let result = await axios.patch(`/register/reject/${token}`, { Comentario });
    if (result.data.data) {
      return SuccessfulAlert({
        text: "Se envió tu comentario, puedes seguir con tu registro.",
      });
    } else
      ErrorAlert({
        text: "Tu sesion ha expirado favor de solicitarlo a tu reclutador.",
      });
  } catch (err) {
    return ErrorAlert({ text: serverError });
  }
};

export const acceptInformation = async (token: string) => {
  try {
    LoadingAlert({ title: "Enviando Comentario..." });
    let result = await axios.patch(`/register/accept/${token}`);
    if (result.data.data) {
      await SuccessfulAlert({
        text: "Puedes seguir con tu registro futuro seeker.",
      });
    } else
      ErrorAlert({
        text: "Tu sesión ha expirado favor de solicitarlo a tu reclutador.",
      });
  } catch (err) {
    return ErrorAlert({ text: serverError });
  }
};

export const postFileAWS = async (
  e: any,
  id: string,
  desde: string,
  name?: string,
  remove_file?: boolean,
  section?: string
) => {
  try {
    let file = new FormData();
    file.append("document", e.target.files[0]);
    if (name) file.append("name", name);
    if (remove_file) file.append("remove_file", JSON.stringify(remove_file));
    if (section) file.append("section", section);

    if (!remove_file) {
      const result = await axios.post(
        `/candidates/postFileHelper/file/${desde}/${id}/${e.target.name}`,
        file,
        {
          headers: {
            "Content-Type": `multipart/form-data;boundary=${file}`,
          },
        }
      );
      return result.data.data;
    } else {
      const result = await axios.post(
        `/candidates/deleteFileHelper/file/${desde}/${id}/${e.target.name}`,
        file,
        {
          headers: {
            "Content-Type": `multipart/form-data;boundary=${file}`,
          },
        }
      );
      return result.data.data;
    }
  } catch (err) {
    return ErrorAlert({ text: serverError });
  }
};

export const postFileAWSIdTypeArchive = async (
  e: any,
  id: string,
  desde: string,
  name?: string,
  remove_file?: boolean,
  section?: string,
  idTypeArchive?: string
) => {
  try {

    /*if (!remove_file) {
      LoadingAlert({ title: "Subiendo Archivo..." });
    } else {
      LoadingAlert({ title: "Eliminando Archivo..." });
    }*/
    let file = new FormData();
    file.append("document", e.target.files[0]);
    if (name) file.append("name", name);
    if (remove_file) file.append("remove_file", JSON.stringify(remove_file));
    if (section) file.append("section", section);

    if (!remove_file) {
      const result = await axios.post(
        `/candidates/postFileHelperIdArchive/file/${desde}/${id}/${e.target.name}/${idTypeArchive}`,
        file,
        {
          headers: {
            "Content-Type": `multipart/form-data;boundary=${file}`,
          },
        }
      );
      return result.data.data;
    } else {
      const result = await axios.post(
        `/candidates/deleteFileHelperIdArchive/file/${desde}/${id}/${e.target.name}/${idTypeArchive}`,
        file,
        {
          headers: {
            "Content-Type": `multipart/form-data;boundary=${file}`,
          },
        }
      );
      return result.data.data;
    }
  } catch (err) {

    return ErrorAlert({ text: serverError });
  }
};

export const postFileIncidentAWS = async (
  e: any,
  id: string,
  desde: string,
  name?: string,
  remove_file?: boolean
) => {
  try {
    LoadingAlert({ title: "Cargando..." });
    let file = new FormData();
    file.append("document", e.target.files[0]);
    if (name) file.append("name", name);
    if (remove_file) file.append("remove_file", JSON.stringify(remove_file));

    const result = await axios.post(
      `/candidates/postFileIncidentHelper/file/${desde}/${id}/${e.target.name}`,
      file,
      {
        headers: {
          "Content-Type": `multipart/form-data;boundary=${file}`,
        },
      }
    );
    return result.data.data;
  } catch (err) {

    return ErrorAlert({ text: serverError });
  }
};

export const postFileNoticeAWS = async (
  e: any,
  id: string,
  desde: string,
  name?: string,
  remove_file?: boolean
) => {
  try {
    
    LoadingAlert({ title: "Cargando..." });
    let file = new FormData();
    file.append("document", e);
    if (name) file.append("name", name);
    if (remove_file) file.append("remove_file", JSON.stringify(remove_file));

    const result = await axios.post(
      `/candidates/postFileNoticeHelper/file/${desde}/${id}/${e.name}`,
      file,
      {
        headers: {
          "Content-Type": `multipart/form-data;boundary=${file}`,
        },
      }
    );
    return result.data.data;
  } catch (err) {
    return ErrorAlert({ text: serverError });
  }
};

export const postFileCloudPrestacion = async (
  e: any,
  id: string,
  desde: string,
  name?: string,
  remove_file?: boolean
) => {
  try {
    LoadingAlert({ title: "Cargando..." });
    let file = new FormData();   
    file.append("document", e);
    if (name) file.append("name", name);
    if (remove_file) file.append("remove_file", JSON.stringify(remove_file));
    let  ruta=`/candidates/postFilePrestacionHelper/file/${desde}/${id}/${e.name}`
    const result = await axios.post(
      ruta,
      file,
      {
        headers: {
          "Content-Type": `multipart/form-data;boundary=${file}`,
        },
      }
    );
    return result.data.data;
  } catch (err) {
    return ErrorAlert({ text: serverError });
  }
};


export const postFilesEnterprisAWS = async (
  e: any,
  id: string,
  desde: string,
  name?: string,
  remove_file?: boolean
) => {
  try {
    LoadingAlert({ title: "Cargando..." });
    let file = new FormData();
    file.append("document", e.target.files[0]);

    if (name) file.append("name", name);
    if (remove_file) file.append("remove_file", JSON.stringify(remove_file));

    if (name === "LogoEmpresa") {
      const result = await axios.post(
        `/candidates/postFileLogoEnterpriseHelper/file/${desde}/${id}/${e.target.name}`,
        file,
        {
          headers: {
            "Content-Type": `multipart/form-data;boundary=${file}`,
          },
        }
      );
      return result.data.data;
    } else {
      if (name === "IMSSEmpresa") {
        const result = await axios.post(
          `/candidates/postFileIMSSEnterpriseHelper/file/${desde}/${id}/${e.target.name}`,
          file,
          {
            headers: {
              "Content-Type": `multipart/form-data;boundary=${file}`,
            },
          }
        );
        return result.data.data;
      } else {
        if (name === "SELLOEmpresa") {
          const result = await axios.post(
            `/candidates/postFileSELLOEnterpriseHelper/file/${desde}/${id}/${e.target.name}`,
            file,
            {
              headers: {
                "Content-Type": `multipart/form-data;boundary=${file}`,
              },
            }
          );
          return result.data.data;
        } else {
          return ErrorAlert({ text: serverError });
        }
      }
    }
  } catch (err) {
    return ErrorAlert({ text: serverError });
  }
};

export const postEnterprisAWS = async (
  id: string,
  e?: any,
  e2?: any,
  e3?: any,
  e4?: any,
  e5?: any,
  desde?: string,
  desde2?: string,
  desde3?: string,
  desde4?: string,
  desde5?: string,
  name?: string,
  name2?: string,
  name3?: string,
  name4?: string,
  name5?: string
) => {
  try {
    LoadingAlert({ title: "Cargando..." });

    if (e !== undefined) {
      let file = new FormData();
      file.append("document", e.target.files[0]);
      if (name) file.append("name", name);
      await axios.post(
        `/candidates/postFileLogoEnterpriseHelper/file/${desde}/${id}/${e.target.name}`,
        file,
        {
          headers: {
            "Content-Type": `multipart/form-data;boundary=${file}`,
          },
        }
      );
    }

    if (e2 !== undefined) {
      let file = new FormData();
      file.append("document", e2.target.files[0]);
      if (name2) file.append("name", name2);
      await axios.post(
        `/candidates/postFileIMSSEnterpriseHelper/file/${desde2}/${id}/${e2.target.name}`,
        file,
        {
          headers: {
            "Content-Type": `multipart/form-data;boundary=${file}`,
          },
        }
      );
    }

    if (e3 !== undefined) {
      let file = new FormData();
      file.append("document", e3.target.files[0]);
      if (name3) file.append("name", name3);
        await axios.post(
        `/candidates/postFileSELLOEnterpriseHelper/file/${desde3}/${id}/${e3.target.name}`,
        file,
        {
          headers: {
            "Content-Type": `multipart/form-data;boundary=${file}`,
          },
        }
      );
    }

    if (e4 !== undefined) {
      let file = new FormData();
      file.append("document", e4.target.files[0]);
      if (name4) file.append("name", name4);
        await axios.post(
        `/candidates/postFileKeyTimbreEnterpriseHelper/file/${desde4}/${id}/${e4.target.name}`,
        file,
        {
          headers: {
            "Content-Type": `multipart/form-data;boundary=${file}`,
          },
        }
      );
    }

    if (e5 !== undefined) {
      let file = new FormData();
      file.append("document", e5.target.files[0]);

      if (name5) file.append("name", name5);
        await axios.post(
        `/candidates/postFileCerTimbreEnterpriseHelper/file/${desde5}/${id}/${e5.target.name}`,
        file,
        {
          headers: {
            "Content-Type": `multipart/form-data;boundary=${file}`,
          },
        }
      );
    }
  } catch (err) {

    return ErrorAlert({ text: serverError });
  }
};

export const postFilesOutsourcesAWS = async (
  id: string,
  desde: string,
  desde2: string,
  e?: any,
  e2?: any,
  name?: string,
  name2?: string
) => {
  try {
    LoadingAlert({ title: "Cargando..." });

    if (e !== undefined) {
      let file = new FormData();
      file.append("document", e.target.files[0]);
      if (name) file.append("name", name);

      await axios.post(
        `/candidates/postFileLogoOutsourcersHelper/file/${desde}/${id}/${e.target.name}`,
        file,
        {
          headers: {
            "Content-Type": `multipart/form-data;boundary=${file}`,
          },
        }
      );

    }

    if (e2 !== undefined) {
      let file2 = new FormData();
      file2.append("document", e2.target.files[0]);

      if (name2) file2.append("name", name2);
      await axios.post(
        `/candidates/postFileRepseOutsourcersHelper/file/${desde2}/${id}/${e2.target.name}`,
        file2,
        {
          headers: {
            "Content-Type": `multipart/form-data;boundary=${file2}`,
          },
        }
      );

    }

    return "Registro agregado";
  } catch (err) {
    return ErrorAlert({ text: serverError });
  }
};

export const postLogoCompanyAWS = async (
  e: any,
  id: string,
  desde: string,
  name?: string,
  remove_file?: boolean
) => {
  try {
    LoadingAlert({ title: "Cargando..." });
    let file = new FormData();
    file.append("document", e);
    if (name) file.append("name", name);
    if (remove_file) file.append("remove_file", JSON.stringify(remove_file));

    const result = await axios.post(
      `/candidates/postLogoCompanyHelper/file/${desde}/${id}/${e.name}`,
      file,
      {
        headers: {
          "Content-Type": `multipart/form-data;boundary=${file}`,
        },
      }
    );
    return result.data.data;
  } catch (err) {
    return ErrorAlert({ text: serverError });
  }
};

export const postFileAWS2 = async (e: any, id: string) => {
  try {
    LoadingAlert({ title: "Subiendo Archivo..." });
    let file = new FormData();
    file.append("document", e.target.files[0]);
    const result = await axios.post(
      `/candidates/upload/file/${id}/AvisoRetencion_PDF`,
      "",
      {
        headers: {
          "Content-Type": `multipart/form-data;boundary=NA`,
        },
      }
    );
    return result.data.data;
  } catch (err) {

    return ErrorAlert({ text: serverError });
  }
};

export const deleteFileAWS = async (id: string, type: string) => {
  try {
    LoadingAlert({ title: "Eliminado Archivo..." });
    const result = await axios.delete(`/candidates/delete/file/${id}`, {
      data: { type },
    });
    return result.data.data;
  } catch (err) {
    return ErrorAlert({ text: serverError });
  }
};

export const declineCandidate = async (id: string, reason: string) => {
  try {
    LoadingAlert({ title: "Declinando Candidato..." });
    const result = await axios.put(`/candidates/decline/${id}`, { reason });
    if (result.data.data) {
      await SuccessfulAlert({
        text: "Se ha declinado al nuevo ingreso correctamente.",
      });
    } else ErrorAlert({ text: serverError });
  } catch (err) {
    return ErrorAlert({ text: serverError });
  }
};

export const convertCandidate = async (
  id: string,
  FechaIngreso: string,
  Name: string
) => {
  try {
    LoadingAlert({ title: "Convirtiendo Candidato..." });
    const result = await axios.put(`/candidates/convert/${id}`, {
      FechaIngreso,
    });
    if (result.data.band) {
      await SuccessfulAlert({
        title: "¡Conversión exitosa!",
        text: "Acabas de sumar a un nuevo Seeker",
      });
    } else ErrorAlert({ text: result.data.data });
  } catch (err) {
    return ErrorAlert({ text: serverError });
  }
};

export const updatePasswordCandidate = async (
  email: string,
  password: string
) => {
  try {
    LoadingAlert({ title: "Guardando Datos..." });
    const result = await axios.put(
      `/register/update/password/candidate/${email}`,
      { password }
    );
    if (result.data.data) {
      return result.data.data;
    } else ErrorAlert({ text: result.data.data });
  } catch (err) {
    return ErrorAlert({ text: serverError });
  }
};

export const postMeilCandidate = async (email: string, token: string) => {
  try {


    await axios.post(
      `/candidates/postMeilCandidate/body/${email}/${token}`
    );
  } catch (err) {
    return ErrorAlert({ text: serverError });
  }
};

export const postFisnishMeilCandidate = async (email: string) => {
  try {


    await axios.post(
      `/candidates//postFisnishMeilCandidate/body/${email}`
    );
  } catch (err) {
    return ErrorAlert({ text: serverError });
  }
};

export const postMailPayroll = async (idpayroll: any) => {
  try {


    await axios.post(
      `/candidates/postMailPayroll/body/${idpayroll}`
    );
  } catch (err) {
    return WarningAlert({ text: serverErrorMail });
  }
};

export const postMailPayrollUAT = async (idpayroll: any) => {
  try {
    await axios.post(
      `/candidates/postMailPayrollUAT/body/${idpayroll}`
    );
  } catch (err) {
    return WarningAlert({ text: serverErrorMail });
  }
};

export const postMailSettlement = async (idpayroll: any) => {
  try {


    await axios.post(
      `/candidates/postMailSettlement/body/${idpayroll}`
    );
  } catch (err) {
    return WarningAlert({ text: serverErrorMail });
  }
};

export const postMailEvent = async (idpayroll: any) => {
  try {


    await axios.post(
      `/candidates/postMailEvent/body/${idpayroll}`
    );
  } catch (err) {
    return WarningAlert({ text: serverErrorMail });
  }
};

export const postMailPayrollAcuse = async (idpayroll: any) => {
  try {


    await axios.post(
      `/candidates/postMailPayrollAcuse/body/${idpayroll}`
    );
  } catch (err) {
    return WarningAlert({ text: serverErrorMail });
  }
};

export const postMailEventualAcuse = async (idpayroll: any) => {
  try {


    await axios.post(
      `/candidates/postMailEventualAcuse/body/${idpayroll}`
    );
  } catch (err) {
    return WarningAlert({ text: serverErrorMail });
  }
};

export const postMailSettlementAcuse = async (idpayroll: any) => {
  try {


    await axios.post(
      `/candidates/postMailSettlementAcuse/body/${idpayroll}`
    );
  } catch (err) {
    return WarningAlert({ text: serverErrorMail });
  }
};

export const endRegisterEmail = async (email: string, name: string) => {
  try {
    LoadingAlert({ title: "Cargando..." });

    await axios.post(
      `/candidates/endRegisterEmail/body/${email}/${name}`
    );
  } catch (err) {
    return ErrorAlert({ text: serverError });
  }
};

export const confirmationInfoEmail = async (email: string, name: string) => {
  try {


    await axios.post(
      `/candidates/confirmationInfoEmail/body/${email}/${name}`
    );
  } catch (err) {
    return ErrorAlert({ text: serverError });
  }
};

export const rejectInfoEmail = async (
  email: string,
  name: string,
  id: any,
  comment: string
) => {
  try {
    LoadingAlert({ title: "Cargando..." });

    await axios.post(
      `/candidates/rejectInfoEmail/body/${email}/${id}/${name}/${comment}`
    );
  } catch (err) {
    return ErrorAlert({ text: serverError });
  }
};

export const postXMLReciboAWS = async (
  id?: string,
  e?: any,
  Colaborador?: any
) => {
  try {
    if (e !== undefined) {
      let file = new FormData();
      const blob = new Blob([e], { type: "text/xml" });
      const desde = "Recibo" + Colaborador + id;
      file.append("document", blob);

      if (Colaborador) file.append("name", Colaborador);

      const result = await axios.post(
        `/candidates/postFileXMLReciboHelper/file/${desde}/${id}/${Colaborador}`,
        file,
        {
          headers: {
            "Content-Type": `multipart/form-data;boundary=${file}`,
          },
        }
      );

      return result.data.data;
    }
  } catch (err) {
    return ErrorAlert({ text: serverError });
  }
};

export const postXMLReciboAWSDate = async (
  id?: string,
  e?: any,
  Colaborador?: any,
  dateTimbre?: any
) => {
  try {
    if (e !== undefined) {
      let file = new FormData();

      const blob = new Blob([e], { type: "text/xml" });
      const hour = moment(dateTimbre).format("HH");
      const minute = moment(dateTimbre).format("mm");
      const second = moment(dateTimbre).format("ss");
      const desde = "Recibo"+ id + Colaborador + hour + minute + second;
      file.append("document", blob);

      if (Colaborador) file.append("name", Colaborador);

      const result = await axios.post(
        `/candidates/postFileGeneral/file/${desde}/${id}/${Colaborador}`,
        file,
        {
          headers: {
            "Content-Type": `multipart/form-data;boundary=${file}`,
          },
        }
      );

      return result.data.data;
    }
  } catch (err) {
    return ErrorAlert({ text: serverError });
  }
};

export const postFileGeneral = async (
  id: string,
  file: any,
  name: any,
  desde: any
) => {
  try {
    if (file !== undefined) {
      let fileData = new FormData();
      fileData.append("document", file);
      const result = await axios.post(
        `/candidates/postFileGeneral/file/${desde}/${id}/${name}`,
        fileData,
        {
          headers: {
            "Content-Type": `multipart/form-data;boundary=${fileData}`,
          },
        }
      );

      return result.data.data;
    }
  } catch (err) {
    return ErrorAlert({ text: serverError });
  }
};

export const postPDFReciboAWS = async (idpayroll?: any, Colaborador?: any, dateTimbre?: any) => {
  try {
    const hour = moment(dateTimbre).format("HH");
    const minute = moment(dateTimbre).format("mm");
    const second = moment(dateTimbre).format("ss");
    const desde = "PDFNomina" + idpayroll + Colaborador + hour + minute + second;
    const result = await axios.post(
      `/candidates/postPDFPayRoll/body/${idpayroll}/${desde}`
    );

    return result.data.data;
  } catch (err) {
    return ErrorAlert({ text: serverError });
  }
};

export const postPDFReciboSettlementAWS = async (
  idpayroll?: any,
  Colaborador: any = '',
  dateTimbre: any = '',
  Tipo: any = ''
  ) => {
  try {
    debugger
    const hour = moment(dateTimbre).format("HH");
    const minute = moment(dateTimbre).format("mm");
    const second = moment(dateTimbre).format("ss");
    const desde = Tipo+ idpayroll + Colaborador + hour + minute + second;

    const result = await axios.post(
      `/candidates/postPDFSettlement/body/${idpayroll}/${desde}`
    );

    return result.data.data;
  } catch (err) {
    return ErrorAlert({ text: serverError });
  }
};

export const postPDFReciboEventualAWS = async (
  idpayroll?: any,
  Colaborador: any = '',
  dateTimbre: any = '',
  Tipo: any = ''
  ) => {
  try {
    const hour = moment(dateTimbre).format("HH");
    const minute = moment(dateTimbre).format("mm");
    const second = moment(dateTimbre).format("ss");
    const desde = Tipo + idpayroll + Colaborador + hour + minute + second;
    const result = await axios.post(
      `/candidates/postPDFEventual/body/${idpayroll}/${desde}`
    );

    return result.data.data;
  } catch (err) {
    return ErrorAlert({ text: serverError });
  }
};

export const postXMLAcuseAWS = async (
  id?: string,
  e?: any,
  Colaborador?: any
) => {
  try {
    if (e !== undefined) {
      let file = new FormData();
      const blob = new Blob([e], { type: "text/xml" });
      const desde = "Acuse" + Colaborador + id;
      file.append("document", blob);

      if (Colaborador) file.append("name", Colaborador);

      const result = await axios.post(
        `/candidates/postFileXMLReciboHelper/file/${desde}/${id}/${Colaborador}`,
        file,
        {
          headers: {
            "Content-Type": `multipart/form-data;boundary=${file}`,
          },
        }
      );

      return result.data.data;
    }
  } catch (err) {
    return ErrorAlert({ text: serverError });
  }
};

export const postXMLAcuseAWSDate = async (
  id?: string,
  e?: any,
  Colaborador?: any,
  dateTimbre?: any
) => {
  try {
    if (e !== undefined) {
      
      let file = new FormData();
      const blob = new Blob([e], { type: "text/xml" });
      const hour = moment(dateTimbre).format("HH");
      const minute = moment(dateTimbre).format("mm");
      const second = moment(dateTimbre).format("ss");
      const desde = "Acuse" + id + Colaborador + hour + minute + second;
      file.append("document", blob);

      if (Colaborador) file.append("name", Colaborador);

      const result = await axios.post(
        `/candidates/postFileGeneral/file/${desde}/${id}/${Colaborador}`,
        file,
        {
          headers: {
            "Content-Type": `multipart/form-data;boundary=${file}`,
          },
        }
      );

      return result.data.data;
    }
  } catch (err) {
    return ErrorAlert({ text: serverError });
  }
};

export const postFilesPDFPayroll = async (
  id?: string,
  e?: any,
  Colaborador?: any
) => {
  try {
    if (e !== undefined) {
      let file = new FormData();
      let data = new Uint8Array(e);
      const blob = new Blob([data], { type: "application/pdf" });
      file.append("document", blob);
      const desde = "PDFNomina" + Colaborador + id;
  
      if (Colaborador) file.append("name", Colaborador);

      const result = await axios.post(
        `/candidates/postFilePDFPayroll/file/${desde}/${id}/${Colaborador}`,
        file,
        {
          headers: {
            "Content-Type": `multipart/form-data;boundary=${file}`,
          },
        }
      );

      return result.data.data;
    }
  } catch (err) {
    return ErrorAlert({ text: serverError });
  }
};

export const postFilesPDFPayrolldate = async (
  id?: string,
  e?: any,
  Colaborador?: any,
  dateTimbre?: any
) => {
  try {
    if (e !== undefined) {
      let file = new FormData();
      let data = new Uint8Array(e);
      const blob = new Blob([data], { type: "application/pdf" });
      file.append("document", blob);
      const hour = moment(dateTimbre).format("HH");
      const minute = moment(dateTimbre).format("mm");
      const second = moment(dateTimbre).format("ss");
      const desde = "PDFNomina" + id + Colaborador + hour + minute + second;

      if (Colaborador) file.append("name", Colaborador);

      const result = await axios.post(
        `/candidates/postFilePDFPayroll/file/${desde}/${id}/${Colaborador}`,
        file,
        {
          headers: {
            "Content-Type": `multipart/form-data;boundary=${file}`,
          },
        }
      );

      return result.data.data;
    }
  } catch (err) {
    return ErrorAlert({ text: serverError });
  }
};

export const postFilesPDFSettlement = async (
  id?: string,
  e?: any,
  Colaborador?: any
) => {
  try {
    if (e !== undefined) {
      let file = new FormData();
      let data = new Uint8Array(e);
      const blob = new Blob([data], { type: "application/pdf" });
      file.append("document", blob);
      const desde = "PDFSettlement" + Colaborador + id;

      if (Colaborador) file.append("name", Colaborador);

      const result = await axios.post(
        `/candidates/postFilePDFPayroll/file/${desde}/${id}/${Colaborador}`,
        file,
        {
          headers: {
            "Content-Type": `multipart/form-data;boundary=${file}`,
          },
        }
      );

      return result.data.data;
    }
  } catch (err) {
    return ErrorAlert({ text: serverError });
  }
};

export const postFilesPDFEventual = async (
  id?: string,
  e?: any,
  Colaborador?: any
) => {
  try {
    if (e !== undefined) {
      let file = new FormData();
      let data = new Uint8Array(e);
      const blob = new Blob([data], { type: "application/pdf" });
      file.append("document", blob);
      const desde = "PDFEventual" + Colaborador + id;

      if (Colaborador) file.append("name", Colaborador);

      const result = await axios.post(
        `/candidates/postFilePDFPayroll/file/${desde}/${id}/${Colaborador}`,
        file,
        {
          headers: {
            "Content-Type": `multipart/form-data;boundary=${file}`,
          },
        }
      );

      return result.data.data;
    }
  } catch (err) {
    return ErrorAlert({ text: serverError });
  }
};

export const postXMLRecibo2AWS = async (
  id?: string,
  e?: any,
  Colaborador?: any,
  Tipo?: any
) => {
  try {
    if (e !== undefined) {
      let file = new FormData();
      const blob = new Blob([e], { type: "text/xml" });
      const desde = Tipo + Colaborador + id;
      file.append("document", blob);

      if (Colaborador) file.append("name", Colaborador);

      const result = await axios.post(
        `/candidates/postFileXMLReciboHelper/file/${desde}/${id}/${Colaborador}`,
        file,
        {
          headers: {
            "Content-Type": `multipart/form-data;boundary=${file}`,
          },
        }
      );

      return result.data.data;
    }
  } catch (err) {

    return ErrorAlert({ text: serverError });
  }
};

export const postXMLReciboAWSTypeDate = async (
  id?: string,
  e?: any,
  Colaborador?: any,
  dateTimbre?: any,
  Tipo?: any
) => {
  try {
    if (e !== undefined) {
      let file = new FormData();
      const blob = new Blob([e], { type: "text/xml" });
      const hour = moment(dateTimbre).format("HH");
      const minute = moment(dateTimbre).format("mm");
      const second = moment(dateTimbre).format("ss");
      const desde = Tipo+ id + Colaborador + hour + minute + second;
      file.append("document", blob);

      if (Colaborador) file.append("name", Colaborador);

      const result = await axios.post(
        `/candidates/postFileGeneral/file/${desde}/${id}/${Colaborador}`,
        file,
        {
          headers: {
            "Content-Type": `multipart/form-data;boundary=${file}`,
          },
        }
      );

      return result.data.data;
    }
  } catch (err) {
    return ErrorAlert({ text: serverError });
  }
};

export const postFilesPDFPayrollTypedate = async (
  id: string,
  e: any,
  Colaborador: any,
  dateTimbre: any,
  Tipo: any
) => {
  try {
    if (e !== undefined) {
      let file = new FormData();
      let data = new Uint8Array(e);
      const blob = new Blob([data], { type: "application/pdf" });
      file.append("document", blob);
      const hour = moment(dateTimbre).format("HH");
      const minute = moment(dateTimbre).format("mm");
      const second = moment(dateTimbre).format("ss");
      const desde = Tipo + id + Colaborador + hour + minute + second;

      if (Colaborador) file.append("name", Colaborador);

      const result = await axios.post(
        `/candidates/postFilePDFPayroll/file/${desde}/${id}/${Colaborador}`,
        file,
        {
          headers: {
            "Content-Type": `multipart/form-data;boundary=${file}`,
          },
        }
      );

      return result.data.data;
    }
  } catch (err) {
    return ErrorAlert({ text: serverError });
  }
};