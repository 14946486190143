import { useContext, useEffect, useState } from "react";
import {
  IconButton,
  Tooltip,
  Menu,
  MenuItem,
  Grid,
} from "@material-ui/core";
import Box from '@mui/material/Box';
import MoreVertIcon from "@material-ui/icons/MoreVert";

import ObjectivesContext, { Types } from "../../../context/ObjectiveContext/ObjectiveContext";
import { WarningAlert } from "../../../alerts/WarningAlert";
import { useMutation } from "@apollo/client";
import { DELETE_CAMPAIGN_OBJECTIVE } from "../../../Querys/querys";

const ObjectiveRowOptions = (props: any) => {
  const { state, dispatch } = useContext(ObjectivesContext);
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const [deleteObjective] = useMutation(DELETE_CAMPAIGN_OBJECTIVE);
  const open = Boolean(anchorEl);

  const openAddEvidenceModal = () => {
    dispatch({ type: Types.SET_SELECTED_OBJECTIVE, payload: props });
    dispatch({ type: Types.ADD_EVIDENCE_MODAL });
    setAnchorEl(null);
  };

  const openDuplicateModal = () => {
    dispatch({ type: Types.SET_SELECTED_OBJECTIVE, payload: props });
    dispatch({ type: Types.DUPLICATE_MODAL });
    setAnchorEl(null);
  };

  const openEditModal = () => {
    dispatch({ type: Types.SET_SELECTED_OBJECTIVE, payload: props });
    dispatch({ type: Types.EDIT_MODAL });
  }

  const deleteObjectiveAction = async (): Promise<void> => {
    setAnchorEl(null);
    let response = await WarningAlert({ text: "¿Deseas eliminar el objetivo?", showDenyButton: true, confirmButtonText: "Eliminar" });
    if (response.isConfirmed) {
      await deleteObjective({
        variables: {
          input: {
            id: parseInt(props.id, 10)
          }
        }
      });
    }
  }

  const evaluateObjective = () => {
    dispatch({ type: Types.SET_SELECTED_OBJECTIVE, payload: props });
    dispatch({ type: Types.EVALUATE_OBJECTIVE_MODAL });
    setAnchorEl(null);
  }

  return (
    <>
      <Tooltip title="Editar" placement="right">
        <Box className="IconButtonPoints" mr={2}>
          <IconButton onClick={(e) => setAnchorEl(e.currentTarget)}>
            <MoreVertIcon style={{ color: "#fabb00" }} />
          </IconButton>
        </Box>
      </Tooltip>
      <Menu
        className="MoreVerIcon"
        anchorEl={anchorEl}
        open={open}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem
          divider
          button
          onClick={evaluateObjective}
        >
          Información&nbsp;
          <Grid container item justify="flex-end">
            <img src={`/assets/svg/info-icon.svg`} alt="Información" />
          </Grid>
        </MenuItem>
        {
          state?.campaign?.status !== 'finalizado' && (
            <>
              {
                props.objetiveSteps !== 'Aprobada' && (
                  <MenuItem
                    divider
                    button
                    onClick={openDuplicateModal}
                  >
                    Duplicar&nbsp;
                    <Grid container item justify="flex-end">
                      <img src={`/assets/svg/copy-icon.svg`} alt="Duplicar" />
                    </Grid>
                  </MenuItem>
                )
              }

              {
                (((state.rol === 'leader' || state.rol === 'admin') && props.objetiveSteps !== 'Aprobada')
                  || (state.rol !== 'leader' && props.IdLider === null && props.objetiveSteps !== 'Aprobada'))
                && (
                  <MenuItem
                    button
                    divider
                    onClick={openEditModal}
                  >
                    Editar&nbsp;
                    <Grid container item justify="flex-end">
                      <img src="/assets/svg/btn-editar.svg" alt="Editar" />
                    </Grid>
                  </MenuItem>
                )
              }
              {
                (state.campaign?.step !== 'crear' && state.campaign?.step !== 'definicion') && (
                  <MenuItem
                    button
                    divider
                    onClick={openAddEvidenceModal}
                  >
                    Evidencias&nbsp;
                    <Grid container item justify="flex-end">
                      <img src="/assets/svg/upload-file.svg" alt="Evidencias" />
                    </Grid>
                  </MenuItem>
                )
              }

              {
                (((state.rol === 'leader' || state.rol === 'admin') && props.objetiveSteps !== 'Aprobada')
                  || (state.rol !== 'leader' && props.IdLider === null && props.objetiveSteps !== 'Aprobada'))
                && (
                  <MenuItem
                    button
                    onClick={deleteObjectiveAction}
                  >
                    Eliminar&nbsp;
                    <Grid container item justify="flex-end">
                      <img src="/assets/svg/delete-icon.svg" alt="Eliminar" />
                    </Grid>
                  </MenuItem>
                )
              }
            </>
          )
        }
      </Menu>
    </>
  );
};

export default ObjectiveRowOptions;
