import {useReducer} from 'react';
import ContractsContext, {initialState} from "./ContractsContext";
import ContractsReducer from './ContractsReducer';

const ContractsState = (props:any) => {
    const [state,dispatch] = useReducer(ContractsReducer, initialState)
    return (
        <ContractsContext.Provider value={{state,dispatch}}>
                  {props.children}
        </ContractsContext.Provider>
    )
}

export default ContractsState;