import { useContext, useEffect, useState } from "react";
import { Grid, Button, FormHelperText } from "@material-ui/core";
import CollaboratorContext from "../../../context/CollaboratorContext/CollaboratorContext";
import EyeIcon from "../../../assets/svg/eye.svg";
import RemoveFile from "../../../assets/svg/remove_file.svg";
import { useMutation, useQuery } from "@apollo/client";
import {
  GET_ALL_FILES,
  GET_USERS_BY_ID,
  UPDATE_USERS,
  GET_ALL_USERS_COLLABORATOR,
  GET_EXCEL_ACTIVE_USER
} from "../../../Querys/querys";
import CheckPermission from "../../../helpers/Administradores/Permissions";
import { handleSetActiveStep } from "../../../helpers/Collaborator/Collaborator";
import FileHelperIdTypeArchive from "./Fields/File_Helper_IdTypeArchive";
import SaveIcon from "@material-ui/icons/Save";

const PersonalDocuments = () => {
  const { state, dispatch } = useContext(CollaboratorContext);
  const [archives, setArchives]:any = useState([]);
  const [files, setFiles]:any = useState([]);
  const [filesFilter, setFilesFilter] = useState<any>([]);

  let count = 0;

  const { data: resultFiles } = useQuery(GET_ALL_FILES);
  const allFiles = resultFiles?.GET_ALL_FILES;
  
  const [uploadError, setUploadError] = useState(0);

  const [updateColaboradores] = useMutation(UPDATE_USERS, {
    refetchQueries: [
      {
        query: GET_USERS_BY_ID,
        variables: { getUsersId: state.collaborator.id },
      },
      { query: GET_ALL_USERS_COLLABORATOR },
      { query: GET_EXCEL_ACTIVE_USER }
    ],
  });

  useEffect(() => {
    if (state.collaborator?.archive) {
      setArchives(state.collaborator.archive);
    }
  }, [state.collaborator]);

  useEffect(() => {
    if (resultFiles && resultFiles.GET_ALL_FILES) {
      setFiles(resultFiles.GET_ALL_FILES);

      //create a new array with the files that are not in the archives array
      const filesFiltered = resultFiles.GET_ALL_FILES.filter(
        (file: any) =>
          !archives.some((archive: any) => String(archive.idTypeArchive) === String(file.id))
      );
      //set the filesFilter with the filesFiltered with spread operator
      setFilesFilter([...filesFiltered]);
    }
  }, [resultFiles, archives]);

  const nextStep = () => {
    let uploaded = 0;
    let obligatory = 0;
    for(const file of files){
      if(file.obligatory){
        obligatory++;
        for(const archive of archives){
          if( String(archive?.idTypeArchive) === String(file.id) ){
            uploaded++;
          }
        }
      }
    }
    if(uploaded >= obligatory){
      handleSetActiveStep(1, dispatch);
      setUploadError(0);
    }else{
      setUploadError(obligatory - uploaded);
    }
  }

  const FilesReady = (file: any, archive: any) => {
    if (!state?.collaborator?.register_process[4].section_1) {
      count++;
      if (count === files.length) {
        updateColaboradores({
          variables: {
            updateUsersId: state.collaborator?.id,
            input: {},
            process: {
              tab: "expedient",
              section: "section_1",
            },
          },
        }).then((res) => {
          handleSetActiveStep(1, dispatch);
        });
      }
    }
    return (
      <div className="flex-container">
        {CheckPermission(
          "Colaboradores.Colaboradores.Editar.Expediente.DatosPersonales.Guardar"
        ) && (
          <>
            <div className="flex-child-2">
              <FileHelperIdTypeArchive
                name={file.name}
                idTypeArchive={file.id}
                accept={`.${file.documentType}`}
                parametrofrom="collaborator"
                section="1"
                idUsr={state.collaborator?.id}
                className="image-file"
                setArchives={setArchives}
                archives={archives}
                image={RemoveFile}
                remove_file={true}
              />
            </div>
          </>
        )}
        <div className="flex-child">
          <label>{file.name}</label>
          <span className="span-file">
            <a className="view-document" rel="noreferrer"  target="_blank" href={archive.URL}>
              <img
                style={{ height: "10px", marginTop: "5px" }}
                src={EyeIcon}
                alt=""
              />
              Ver documento
            </a>
          </span>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="novalidate__border" style={{ width: "fit-content" }}>
        <Grid direction="row" container>
          <Grid xs={12} item>
            <div className="">
              {files.length > 0 &&
                files?.map((file: any) =>
                  archives?.map(
                    (archive: any, index: number) =>
                      String(archive?.idTypeArchive) === String(file.id) && FilesReady(file, archive)
                  )
                )}

              {CheckPermission(
                "Colaboradores.Colaboradores.Editar.Expediente.DatosPersonales.Guardar"
              ) && (
                <>
                  {filesFilter?.map((file: any) => (
                    <div className="flex-container">
                      <div className="flex-child-2">
                        <FileHelperIdTypeArchive
                          name={file.name}
                          idTypeArchive={file.id}
                          accept={`.${file.documentType}`}
                          parametrofrom="collaborator"
                          section="1"
                          idUsr={state.collaborator?.id}
                          className="image-file"
                          setArchives={setArchives}
                          archives={archives}
                        />
                      </div>

                      <div className="flex-child">
                        <label>
                          {file.name}{" "}
                          {file.obligatory && (
                            <label style={{ color: "red" }}>*</label>
                          )}
                        </label>
                      </div>
                    </div>
                  ))}
                </>
              )}
            </div>
            {
              uploadError > 0 && (
                <FormHelperText style={{color: 'red'}}>
                  Es requerido subir todos los archivos obligatorios (*). Faltan { uploadError } archivo(s).
                </FormHelperText>
              )
            }
          </Grid>
        </Grid>
      </div>
      <Grid
          direction="row"
          container
          justify="flex-end"
          alignItems="center"
          style={{ marginTop: "20px" }}
        >
          {
            CheckPermission('Colaboradores.Colaboradores.Editar.Expediente.DatosPersonales.Guardar') && (
            <Button type="submit" className="buttonSave" onClick={nextStep}>
              <SaveIcon />
              &nbsp; Guardar
            </Button>
            )
          }
      </Grid>
      <div></div>
    </>
  );
};

export default PersonalDocuments;
