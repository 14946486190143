import SettlementPayrollState from "../../context/PayrollContext/SettlementPayrollState";
import { NuevoFiniquito } from "./NuevoFiniquito";
import ActiveSettlementTable from "./ActiveSettlementTable";
import "../Payroll/Payroll.css";
import { useQuery } from "@apollo/client";
import { GET_ALL_SETTLEMENTPAYROLL } from "../../Querys/querys";
import { useEffect, useState } from "react";

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
  sistemaActivo: boolean;
}

const SettlementPayrollTab = (props: TabPanelProps) => {
  const { data: resultSettlement } = useQuery(GET_ALL_SETTLEMENTPAYROLL);
  const allSettlement = resultSettlement?.GET_ALL_SETTLEMENTPAYROLL;
  const [allSettlementState, setAllSettlementState] = useState<any>(allSettlement);

  useEffect(() => {
    if (allSettlement) {
      setAllSettlementState(allSettlement);
    }
  }, [allSettlement]);

    return (
      <>
        {
          (!allSettlementState || allSettlement.length === 0) ? (
            <SettlementPayrollState>
              <NuevoFiniquito {...props} />
            </SettlementPayrollState>
          ) : (
            <SettlementPayrollState>
              <ActiveSettlementTable {...props} />
            </SettlementPayrollState>
          )
        }
      </>
    );
};

export default SettlementPayrollTab;
