import {
    Grid
} from '@material-ui/core';
import Box from '@mui/material/Box';
import { styleCollaboratorTab as style } from '../../Prestaciones/css/styleSheet';
import { Dispatch, SetStateAction } from 'react';

interface Props<T> {
    initState: Array<T>;
    setState: Dispatch<SetStateAction<T[]>> | ((a: T[]) => void);
    stateInitial: Array<T>;
    initState1: Array<T>;
    setState1: Dispatch<SetStateAction<T[]>> | ((a: T[]) => void);
    stateInitial1: Array<T>;
    tabReport?:number;
    label?: string;
    fields?: Array<string>;
    width?: number,   
}

const SearcherTable =<T extends Object>({
    initState,
    setState,
    stateInitial,
    initState1,
    setState1,
    stateInitial1,
    tabReport,
    label = "Buscar",   
    width = 420,    
}: Props<T>) => {

    const handleChange = (e: any) => {
        const tabsReport=[0,1];
        if(tabReport === tabsReport[0] ){
           const array=[...initState]   
           if (e !== '') {           
                 const nuevoFiltrado =array.filter((lis: any) => lis.ReportName.toUpperCase().includes(e.toUpperCase()))
                 setState(nuevoFiltrado)                        
             }else{
                setState(stateInitial);
            }               
         }
         if(tabReport === tabsReport[1] ){
            const array=[...initState1]   
            if (e !== '') {           
                  const nuevoFiltrado =array.filter((lis: any) => lis.ReportName.toUpperCase().includes(e.toUpperCase()))
                setState1(nuevoFiltrado)                        
              }else{
                setState1(stateInitial1);
             }               
          }
        
        };        


  return (
    <Grid item>
            <Box >
                <Grid
                    container
                    direction="row"
                    style={{...style.containerSearcher, width: width, background:"white"}}
                >
                    <Grid item >
                        <input
                            style={{...style.inputSearcher, width: (width - 100)}}
                            className="collaboratorTab__searcher"
                            placeholder={label}
                            onChange={(e) => handleChange(e.target.value)}

                        />
                    </Grid>

                    <Grid item style={{
                        width: "20px",
                        padding: "0 20px 0 40px",
                        color: "#7f7f81",
                        fontStyle: "bold",
                    }}>
                        <img
                            src="/assets/icons/icono-buscar.svg"
                            alt="Buscar"
                        />
                    </Grid>

                </Grid>
            </Box>
        </Grid>
  )
}

export default SearcherTable
